<template>
    <div>
        <div class="container">
            <header class="header">
                <div class="header-left">
                    <h2>{{ this.$route.params.id ? `Updating Skill Set - ${llm_skill_set_name}` : 'Creating Skill Set' }}</h2>
                </div>
            </header>

            <div class="content-container">
                <div class="row">
                    <div class="col-6">
                        <form @submit.prevent="create_or_update_llm_skill_set">
                            <div class="form-group">
                                <label for="skillSetName">Skill Set Name</label>
                                <input type="text" class="form-control" id="skillSetName" v-model="llm_skill_set_name">
                            </div>
                            <div class="skill-container">
                                <h3>Primary Skill</h3>
                                <draggable class="list-group" :list="primary_skill" group="skills" @add="checkPrimarySkill">
                                    <div class="list-group-item" v-for="(element, index) in primary_skill" :key="index">
                                        {{ element.skill_name }}
                                    </div>
                                </draggable>
                            </div>
                            <div class="skills-container">
                                <h3>Sub Skills</h3>
                                <nested-draggable class="list-group skill-container" :nested_skills="sub_skills" :key="nestedKey"
                                    group="skills" id="sub_skills" itemKey="name">
                                </nested-draggable>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-6">
                        <h3>All Skills</h3>
                        <draggable class="list-group" :list="all_skills" id="all_skills" group="skills" @add="handleSkillRemoval">
                            <div class="list-group-item" v-for="(element, index) in all_skills" :key="index">
                                {{ element.skill_name }}
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import draggable from 'vuedraggable';
import { EventBus } from "@/utils/eventBus";
import NestedDraggable from "../components/nestedDraggable.vue";

export default {
    name: "CreateLlmSkillSet",
    components: {
        draggable,
        NestedDraggable
    },
    data() {
        return {
            llm_skill_set_name: "",
            primary_skill: [],
            sub_skills: [],
            all_skills: [],
            skill_set_items_attributes: [],
            skills_to_delete: [],
            nestedKey: 0,
        };
    },
    methods: {
        create_or_update_llm_skill_set() {
            if (this.$route.params.id) {
                this.updateLlmSkillSet();
            } else {
                this.createLlmSkillSet();
            }
        },
        createLlmSkillSet() {
            utilFunctionService.showLoader();
            this.primary_skill.forEach((item) => {
                this.skill_set_items_attributes.push({ llm_skill_id: item.id, primary: true })
            })
            this.sub_skills.forEach((skill, index) => {
                console.log("*** skill", skill.children)
                if (skill.children) {
                    skill.children.forEach((child, index) => {
                        console.log("*** child", child)
                        skill.children[index] = { llm_skill_id: child.id, llm_skill_name: child.skill_name, parent_id: child.parent_id }
                    })
                }
                this.skill_set_items_attributes.push({ llm_skill_id: skill.id, primary: false, order: index, children_attributes: skill.children })
            })
            let payload = {
                name: this.llm_skill_set_name,
                skill_set_items_attributes: this.skill_set_items_attributes
            }
            console.log(payload);
            api.createLlmSkillSet(payload).then((response) => {
                console.log(response);
                this.$toast.open({
                    message: 'Skill Set Saved',
                    type: 'success',
                    duration: 2000,
                    dismissible: true,
                    position: 'bottom-right',
                })
                utilFunctionService.hideLoader();
            })
                .catch((error) => {
                    utilFunctionService.hideLoader();
                    console.log(error);
                });
        },
        updateLlmSkillSet() {
            utilFunctionService.showLoader();
            if (this.primary_skill.length > 0 && this.primary_skill[0].llm_skill_id) {
                // There is an existing skill in the primary skill list
                this.skill_set_items_attributes.push({ id: this.primary_skill[0].id, llm_skill_id: this.primary_skill[0].llm_skill_id, primary: true, llm_skill_set_id: this.$route.params.id })
            } else if (this.primary_skill.length > 0 && !this.primary_skill[0].skill_id) {
                // There is a new skill in the primary skill list
                this.skill_set_items_attributes.push({ llm_skill_id: this.primary_skill[0].id, primary: true, llm_skill_set_id: this.$route.params.id })
            }
            this.sub_skills.forEach((sub_skill_item, index) => {
                console.log("*** skill", sub_skill_item)
                if (sub_skill_item.llm_skill_id) {
                    console.log("*** sub_skill_item in postSkillForm sub_skills forEach", sub_skill_item)
                    let existing_skill_set_item = { id: sub_skill_item.id, llm_skill_id: sub_skill_item.llm_skill_id, primary: false, llm_skill_set_id: this.$route.params.id, order: index, children_attributes: sub_skill_item.children }
                    console.log("*** existing_skill_set_item in postSkillForm sub_skills forEach", existing_skill_set_item)
                    console.log("*** sub_skill_item.children in postSkillForm sub_skills forEach", sub_skill_item.children)
                    let children_attributes = this.fix_the_children(existing_skill_set_item, sub_skill_item)
                    console.log("children_attributes in postSkillForm sub_skills forEach", children_attributes)
                    this.skill_set_items_attributes.push(existing_skill_set_item)
                } else {
                    console.log("*** new skill", sub_skill_item)
                    this.skill_set_items_attributes.push({ llm_skill_id: sub_skill_item.id, primary: false, llm_skill_set_id: this.$route.params.id, order: index })
                }
            })
            this.skills_to_delete.forEach((skill) => {
                this.skill_set_items_attributes.push({ id: skill.id, _destroy: true })
            })
            let payload = {
                name: this.llm_skill_set_name,
                skill_set_items_attributes: this.skill_set_items_attributes
            }
            api.updateLlmSkillSet(payload, this.$route.params.id).then((response) => {
                console.log(response);
                this.$toast.open({
                    message: 'Skill Set Saved',
                    type: 'success',
                    duration: 2000,
                    dismissible: true,
                    position: 'bottom-right',
                })
                utilFunctionService.hideLoader();
            })
            .catch((error) => {
                utilFunctionService.hideLoader();
                this.$toast.open({
                    message: 'An error has occurred',
                    type: 'error',
                    duration: 2000,
                    dismissible: true,
                    position: 'bottom-right',
                })
                console.log(error);
            });
        },
        checkPrimarySkill(event) {
            if (this.primary_skill.length > 1) {
                this.$toast.open({
                    message: 'You can only have one primary skill',
                    type: 'error',
                    duration: 2000,
                    dismissible: true,
                    position: 'bottom-right',
                })
                this.all_skills[this.all_skills.length] = this.primary_skill[event.newIndex]
                this.primary_skill.splice(event.newIndex, 1)
            }
        },
        handleRemoveChildID(id) {
            console.log("*** remove child id", id)
            this.skills_to_delete.push(this.all_skills[id])
            this.getAllSkills();
        },
        handleUpdateParentId(id) {
            console.log(id.id);
            // Update the appropriate sub_skill with the new parent_id
            this.sub_skills.forEach((skill) => {
                console.log("*** skill", skill)
                console.log("*** skill.children", skill.children)
                // Iterate over the children
                if (skill.children) {
                    skill.children.forEach((child) => {
                        console.log(child)
                        if (child.id == id.id) {
                            child.parent_id = skill.id
                        }
                    })
                } else {
                    console.log(skill);
                    this.forceRerender();
                    skill.children = [];
                }
            })
            this.forceRerender();
        },
        getLlmSkillSetData() {
            api.getLlmSkillSet(this.$route.params.id).then((response) => {
                console.log(response);
                const LLM_SKILL_SET_DATA = response.data.data;
                console.log(LLM_SKILL_SET_DATA.attributes.name)
                this.llm_skill_set_name = LLM_SKILL_SET_DATA.attributes.name;
                this.primary_skill = LLM_SKILL_SET_DATA.attributes.llm_skill_set_items.filter((item) => item.primary == true);
                this.sub_skills = LLM_SKILL_SET_DATA.attributes.llm_skill_set_items.filter((item) => item.primary == false && item.parent_id === null);
            });
        },
        getAllSkills() {
            api.getAllLlmSkills().then((response) => {
                this.all_skills = response.data.data.map((item) => {
                    return {
                        id: item.id,
                        skill_name: item.attributes.name
                    }
                });
            });
        },
        handleSkillRemoval(event) {
            console.log("*** in handleSkillRemoval")
            console.log("*** event", event.from.id)
            let from_list = event.from.id
            let to_list = event.to.id
            console.log(from_list)
            console.log(to_list)
            if (from_list == "primary_skill") {
                if (to_list == 'all_skills') {
                    this.skills_to_delete.push(this.all_skills[event.newIndex])
                }
                if (to_list == 'sub_skills') {
                    this.skills_to_delete.push(this.sub_skills[event.newIndex])
                    this.checkIfSkillExist(event.newIndex)
                }
            } else if (from_list == "sub_skills") {
                if (to_list == 'primary_skill' && this.primary_skill.length == 0) {
                    this.skills_to_delete.push(this.primary_skill[event.newIndex])
                }
                if (to_list == 'all_skills') {
                    console.log("deleting")
                    this.skills_to_delete.push(this.all_skills[event.newIndex])
                    this.getAllSkills();
                }
            }
            else {
                console.log("Skill Removal has run into an error")
            }
        },
        fix_the_children(skill_set_item, skill) {
            console.log("*** skill_set_item in fix_the_children", skill_set_item)
            console.log("*** skill in fix_the_children", skill)
            // Recursive function to change how the child attributes are sent to the API
            skill_set_item.children_attributes = skill_set_item.children_attributes.map((child, index) => {
                if (child.llm_skill_id) {
                    return { id: child.id, llm_skill_id: child.llm_skill_id, parent_id: skill_set_item.id, llm_skill_set_id: this.$route.params.id, llm_skill_name: child.skill_name, order: index }
                } else {
                    return { llm_skill_id: child.id, parent_id: skill_set_item.id, llm_skill_set_id: this.$route.params.id, llm_skill_name: child.skill_name, order: index }
                }
            })
            console.log("*** skill_set_item in fix_the_children", skill_set_item)
            return skill_set_item
        },
        forceRerender() {
            this.nestedKey += 1;
        },
    },
    created() {
        this.getAllSkills();
        if (this.$route.params.id) {
            this.getLlmSkillSetData();
        }
        EventBus.$on('updateParentId', this.handleUpdateParentId)
        EventBus.$on('removeChildID', this.handleRemoveChildID)
    },
};
</script>
  
<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.list-group {
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ffbaba;

    .list-group-item {
        cursor: pointer;
        margin-bottom: 5px;
    }
}
</style>
  