<template>
  <div id="QaEmail">
    <div>
      <div>
        <div id="QaMessageContainer" v-if="emailLists.length > 0">
          <div
            class="learn-object"
            v-for="(email, i) in emailLists"
            :key="email.id"
          >
            <div class="learn-object-forward">
              <div class="learn-object-tag">
                Subject: {{ filterEmailIndex[i].sub }}
              </div>
              <div class="learn-object-paragraph">
                <div class="learn-object-desc">
                  <p v-html="filterEmailIndex[i].msg"></p>
                </div>
              </div>
              <div class="learn-object-paragraph">
                <div class="learn-object-desc my-4 details">
                  <p>Iteration: {{ filterEmailIndex[i].iteration }}</p>
                  <p>
                    QA condition hit ID(s):
                    {{ emailIterationDetails.qa_condition_hits }}
                  </p>
                  <p>Date Sent: {{ emailIterationDetails.date_sent }}</p>
                </div>
              </div>
            </div>
            <div
              class="learn-object-editable"
              v-for="item in filterEmailIndex[i].arr"
              :key="item.id"
            >
              <div class="learn-reply-head">
                <span
                  >Reply from
                  {{
                    item.attributes.character.attributes.char_full_name
                  }}</span
                >
              </div>
              <div class="learn-object-tag">
                Reply: {{ email.attributes.email_subject }}
              </div>
              <div
                class="learn-object-editor"
                v-if="
                  item.attributes.qa_fix_required === true &&
                  item.attributes.qa_fixed === false
                "
              >
                <div class="form-group">
                  <vue-editor
                    class="form-control"
                    :editorToolbar="fullToolbar"
                    v-model="item.attributes.response"
                  ></vue-editor>
                </div>
              </div>
              <div v-else>
                <div class="learn-object-paragraph">
                  <div class="learn-object-desc">
                    <p v-html="replaceLearnerToken(item.attributes.response)"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="admin-feedback-content"  v-if="emailfeedbackResponse !== undefined">
            <div>
              <div class="d-flex flex-wrap w-100 justify-content-between">
                <h3>Feedback</h3>
                <div class="d-flex">
                  <p>
                    Number of iterations:{{
                      emailfeedbackResponse.attributes.iteration
                    }}
                  </p>
                </div>
              </div>

              <div
                class="feedback-items mastery"
                v-for="values in emailfeedbackResponse.attributes
                  .learner_debrief_items"
                :key="values.id"
              >
                <div class="feedback-icon">
                  <img
                    :src="
                      values.attributes.email_assessment_item.attributes
                        .assessment_icon_url
                    "
                    alt="suggestion"
                  />
                </div>
                <div class="feedback-content">
                  <div
                    class="feedback-head d-flex"
                  >
                    {{values.attributes.email_assessment_item.attributes.assessment_label}}
                    <button
                      v-if="
                        values.attributes.email_skill &&
                        values.attributes.email_skill.eval_explanation
                      "
                      :content="
                        values.attributes.email_skill.eval_explanation
                      "
                      v-tippy="{ trigger: 'click', arrow: true }"
                      class="display-inline info-icon no-style-btn"
                    >
                      i
                    </button>
                  </div>
                  <h4>
                    {{ values.attributes.global_skill.name }}
                  </h4>
                  <div v-html="replaceLearnerToken(values.attributes.debrief_content)"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "LearnerEmail",
  props: ["userLearnObj"],
  metaInfo: {
    title: "Learner Email LO Details",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      id: this.$route.params.id,
      emailIterationDetails: {},
      evaluationId: this.userLearnObj.attributes.current_evaluation_id,
      editWorldDetails: false,
      emailLists: [],
      emailfeedbackResponse: "",
      activeResponseId: null,
      activeResponse: null,
      fullToolbar: [],
      filterEmailIndex: {
        0: { msg: "", arr: [], sub: "", iteration: "" },
        1: { msg: "", arr: [], sub: "", iteration: "" },
        2: { msg: "", arr: [], sub: "", iteration: "" },
        3: { msg: "", arr: [], sub: "", iteration: "" },
        4: { msg: "", arr: [], sub: "", iteration: "" },
      },
    };
  },
  methods: {
    setActiveResponse(item) {
      this.activeResponseId = item.id;
      this.activeResponse = item.attributes.response;
    },
    userEmailIterationDetails() {
      utilFunctionService.showLoader();
      api
        .getUserEmailIterationDetails(this.id)
        .then((res) => {
          utilFunctionService.hideLoader();
          this.emailIterationDetails = {};
          utilFunctionService.hideLoader();
          this.emailIterationDetails = res.data.data.attributes;
          console.log(this.emailIterationDetails, "this.emailIterationDetails");
          this.evaluationId = this.emailIterationDetails.evaluation_id;
          this.$refs.sideBar.getQaFormulas(
            this.emailIterationDetails.lo_qa_condition_ids
          );
          this.getEmailLists();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    getEmailLists(id) {
      api.getEmailList(id).then((res) => {
        this.emailLists = res.data.reverse();
        this.emailLists.forEach((z, i) => {
          let filterEmailList = [];
          z.attributes.responses.forEach((x) => {
            let a = z.attributes.responses.filter((o) => {
              if (
                x.attributes.character.attributes.character_id ==
                o.attributes.character.attributes.character_id
              ) {
                return o;
              }
            });
            if (a.length > 1) {
              let q = filterEmailList.find(
                (x) =>
                  a[0].attributes.character.attributes.character_id ==
                  x.attributes.character.attributes.character_id
              );
              if (q) {
                return;
              }
              let p = a.find((e) => e.attributes.is_ooto_response == false);
              filterEmailList.push(p);
            } else {
              filterEmailList.push(a[0]);
            }
          });
          this.filterEmailIndex[i] = {
            arr: filterEmailList,
            sub: z.attributes.email_subject,
            msg: z.attributes.email,
            iteration: z.attributes.iteration,
          };
        });
      });
    },
    getFeedback(id) {
      api.getEmailFeedbackResponse(id).then((res) => {
        this.emailfeedbackResponse = res.data.data;
      });
    },
    replaceLearnerToken(text) {
      return text.replace(
        "[learner-name]",
        this.$attrs.adminLearnerLoDetails.attributes.first_name
      );
    },
  },
  created() {
    this.getEmailLists(this.evaluationId);
    this.getFeedback(this.evaluationId);
  },
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.learn-object {
  margin: 35px -20px 0;
  padding: 30px 20px 40px;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06);
  background: white;
  @include media-breakpoint(md) {
    margin: 50px 0 0;
    padding: 40px 47px 60px;
  }
  .learn-object-caption {
    font-size: 12px;
    margin: 0 15px 0 0;
  }
}
#QaMessageContainer {
  margin-bottom: 2em;
}
.learn-object-tag {
  @include font-bold;
  font-size: 17px;
  line-height: 1.47;
  margin: 0 0 12px;
}
.details {
  // background-color: $white;
  padding: 25px 25px 25px;
  border-top: 1px dashed #ccc;
  // box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
  text-align: right;
}
</style>
