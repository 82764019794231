<template>
  <div id="ELMBuild">
    <div class="create-elm-container">
      <router-link to="/learning-modules" class="elm-back-btn">
        <span class="icon-back"></span>
      </router-link>
      <div class="elm-header-main">
        <div class="header-left">
          <h2>{{ elmData.elm_name }}</h2>
          <div class="elm-archived">
            <p v-if="elmData && elmData.active && elmData.active.length > 1">
              {{ elmData.active.length }} Learning Objects in this ELM
            </p>
            <p
              v-else-if="
                elmData && elmData.active && elmData.active.length == 1
              "
            >
              {{ elmData.active.length }} Learning Object in this ELM
            </p>
            <p
              v-else-if="
                elmData && elmData.active && elmData.active.length == 0
              "
            >
              0 Learning Objects in this ELM
            </p>
            <ul>
              <li>
                <a @click="$modal.show('archivedObjects')"
                  ><span
                    >{{
                      elmData.archived && elmData.archived.length
                    }}
                    Archived</span
                  >
                </a>
              </li>
              <!-- <li class="list-inline-item">
                <a @click="exportElm()"
                ><span> Export</span>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <a
            href="javascript:void(0);"
            class="btn header-btn primary big"
            @click="publishElm()"
            v-if="elmData.elm_status == 'drafted'"
            >Publish ELM</a
          >
        </div>
      </div>
      <!-- configure Grading Start -->
      <div class="elm-header-grading" v-if="enableGrading">
        <div class="align-items-right">
          <a
            href="javascript:void(0);"
            class="btn secondary big"
            @click="showGradeElm()"
            >Configure ELM Grading</a
          >
        </div>
      </div>
      <!-- configure Grading end -->
      <LoMenu
        v-bind:elmMenu="{
          card_type: card_type,
          learning_object_type: learning_object_type,
          video: video,
          newLo: newLo,
        }"
      />
    </div>
    <CreateLO
      v-on:listenerChild="listenerChild"
      @selectedVideo="selectedVideo($event)"
      v-bind:archivedRecord="{ archived: elmData.archived }"
    />

    <modal
      name="GradingSetupModal"
      height="auto"
      :width="1400"
      :scrollable="true"
    >
      <div class="hide-modal" @click="$modal.hide('GradingSetupModal')"></div>
      <div class="modal-content modal-default">
        <div class="row d-inline-flex" style="float: right">
          <div class="col-3"></div>
          <div class="col-2"></div>
          <div class="col-2"></div>
          <div class="col-1"></div>
          <!-- <div class="col-3"> -->
          <div class="col-2">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>ELM Highest Grade</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <span class="pr-4">
              <input
                v-model="allOverallWeight"
                class="custom-input-style-grade-number2"
                type="text"
              />
            </span>
          </div>
        </div>

        <div class="row d-inline-flex" style="float: right">
          <div class="col-3"></div>
          <div class="col-2"></div>
          <div class="col-2"></div>
          <div class="col-1"></div>
          <!-- <div class="col-3"> -->

          <div class="col" v-if="overallWeightExceede">
            <span class="pr-4">
              <div class="invalid text-right w-100 mr-2">
                The ELM grade should not excced 100
              </div>
            </span>
          </div>
        </div>

        <div class="modal-body question-modal-body-grading">
          <div class="row">
            <!-- <div v-for="(ro,inx) in learnObjGradingWeights"  :key="inx"> -->
            <div
              class="col-4"
              v-for="(ro, inx) in learnObjGradingWeights"
              :key="inx"
            >
              <a class="add-question-link-grading">
                <span>{{ ro.learning_object_name }}</span>
                <div class="row d-inline-flex" style="float: right">
                  <div class="custom-span-style-grade-number2">
                    <div class="modal-title" id="exampleModalLabel">
                      <span>ILO Overall Weight</span>
                    </div>
                  </div>

                  <span class="pr-4">
                    <input
                      v-model="ro.ilo_weight"
                      class="custom-input-style-grade-number2"
                      type="text"
                      v-on:change="changeIloWeight"
                    />
                  </span>
                </div>

                <div
                  class="row d-inline-flex"
                  style="float: right"
                  v-if="gradingType === 'combination'"
                >
                  <div class="custom-span-style-grade-number2">
                    <div>
                      <span>Assessment</span>
                    </div>
                  </div>

                  <span class="pr-4">
                    <input
                      v-model="ro.assessment_weight"
                      class="custom-input-style-grade-number2"
                      v-on:change="changeIloAssessment(inx)"
                      type="text"
                    />
                  </span>
                </div>

                <div
                  class="row d-inline-flex"
                  style="float: right"
                  v-if="gradingType === 'combination'"
                >
                  <div class="custom-span-style-grade-number2">
                    <div>
                      <span>Completion</span>
                    </div>
                  </div>

                  <span class="pr-4">
                    <input
                      v-model="ro.completion_weight"
                      class="custom-input-style-grade-number2"
                      v-on:change="changeIloCompletion(inx)"
                      type="text"
                    />
                  </span>
                </div>
              </a>

              <!-- </div> -->
            </div>
            <!-- 
                <div class="col-4" >
                  <a class="add-question-link-grading">

                    <span>LO 4 - Complete Task ABC (Quiz)</span>
                    <div class="row d-inline-flex" style="float: right;">

                        <div class="custom-span-style-grade-number2" >
                          <div class="modal-title" id="exampleModalLabel">
                          
                            <span >ILO Overall Weight</span>
                          </div>
                        </div> 

                        <span class="pr-4 "> 
                            <input placeholder="100%"
                                class="custom-input-style-grade-number2"
                                type="text"
                                v-on:change="checkCorrectRowsPass" />
                            </span>

                   </div>
                  </a>
                  
                </div>
                <div class="col-4" >
                  <a class="add-question-link-grading">
                    
                    <span>LO 4 - Complete Task ABC (Quiz)</span>
                    <div class="row d-inline-flex" style="float: right;">
 
                        <div class="custom-span-style-grade-number2" >
                          <div class="modal-title" id="exampleModalLabel">
                          
                            <span >ILO Overall Weight</span>
                          </div>
                        </div> 

                        <span class="pr-4 "> 
                            <input placeholder="100%"
                                class="custom-input-style-grade-number2"
                                type="text"
                                v-on:change="checkCorrectRowsPass" />
                            </span>

                   </div>
                  </a>
                  
                </div>
                <div class="col-4" >
                  <a class="add-question-link-grading">
                    
                    <span>LO 4 - Complete Task ABC (Quiz)</span>
                    <div class="row d-inline-flex" style="float: right;">

                        <div class="custom-span-style-grade-number2" >
                          <div class="modal-title" id="exampleModalLabel">
                          
                            <span >ILO Overall Weight</span>
                          </div>
                        </div> 

                        <span class="pr-4 "> 
                            <input placeholder="100%"
                                class="custom-input-style-grade-number2"
                                type="text"
                                v-on:change="checkCorrectRowsPass" />
                            </span>

                   </div>
                  </a>
                  
                </div>
                <div class="col-4" >
                  <a class="add-question-link-grading">
                    
                    <span>LO 4 - Complete Task ABC (Quiz)</span>
                    <div class="row d-inline-flex" style="float: right;">

                        <div class="custom-span-style-grade-number2" >
                          <div class="modal-title" id="exampleModalLabel">
                          
                            <span >ILO Overall Weight</span>
                          </div>
                        </div> 

                        <span class="pr-4 "> 
                            <input placeholder="100%"
                                class="custom-input-style-grade-number2"
                                type="text"
                                v-on:change="checkCorrectRowsPass" />
                            </span>

                   </div>
                  </a>
                  
                </div>
                <div class="col-4" >
                  <a class="add-question-link-grading">
                    
                    <span>LO 4 - Complete Task ABC (Quiz)</span>
                    <div class="row d-inline-flex" style="float: right;">

                        <div class="custom-span-style-grade-number2" >
                          <div class="modal-title" id="exampleModalLabel">
                          
                            <span >ILO Overall Weight</span>
                          </div>
                        </div> 

                        <span class="pr-4 "> 
                            <input placeholder="100%"
                                class="custom-input-style-grade-number2"
                                type="text"
                                v-on:change="checkCorrectRowsPass" />
                            </span>

                   </div>
                  </a>
                  
                </div>
                <div class="col-4" >
                  <a class="add-question-link-grading">
                    
                    <span>LO 4 - Complete Task ABC (Quiz)</span>
                    <div class="row d-inline-flex" style="float: right;">

                        <div class="custom-span-style-grade-number2" >
                          <div class="modal-title" id="exampleModalLabel">
                          
                            <span >ILO Overall Weight</span>
                          </div>
                        </div> 

                        <span class="pr-4 "> 
                            <input placeholder="100%"
                                class="custom-input-style-grade-number2"
                                type="text"
                                v-on:change="checkCorrectRowsPass" />
                            </span>

                   </div>
                  </a>
                  
                </div> -->
          </div>
        </div>
        <div class="form-group pt-3 text-right">
          <a
            href="javascript:void(0);"
            class="btn secondary"
            @click="saveElmGrade"
            >Save</a
          >
        </div>
      </div>
    </modal>
    <!-- grading modal start end-->

    <modal
      name="saveTotalWeightIncorrect"
      height="auto"
      :width="350"
      :scrollable="true"
    >
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          The ELM grade should not excced 100
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('saveTotalWeightIncorrect')"
            class="btn medium default"
          >
            OK
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import CreateLO from "../components/modal/CreateLO.vue";
import LoMenu from "../components/LoMenu.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "ELMBuild",
  components: {
    CreateLO,
    LoMenu,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      enableGrading: true,
      gradingType: "assessment",
      overallWeightExceede: false,
      allOverallWeight: 0,
      learnObjGradingWeights: [],
      elmWeights: [],
      id: "",
      elmData: {
        active: [],
        archived: [],
        elm_name: [],
        worldId: "",
        archivedCount: "",
      },
      card_type: "",
      learning_object_type: "",
      video: "",
      selectedMenu: "",
      worldId: "",
      count: 3,
      newLo: false,
    };
  },
  methods: {
    changeIloCompletion(inx) {
      this.learnObjGradingWeights[inx].assessment_weight =
        100 - this.learnObjGradingWeights[inx].completion_weight;
    },
    changeIloAssessment(inx) {
      this.learnObjGradingWeights[inx].completion_weight =
        100 - this.learnObjGradingWeights[inx].assessment_weight;
    },
    changeIloWeight() {
      this.allOverallWeight = 0.0;
      for (let i = 0; i < this.learnObjGradingWeights.length; i++) {
        this.allOverallWeight =
          parseFloat(this.allOverallWeight) +
          parseFloat(this.learnObjGradingWeights[i].ilo_weight);
      }
      if (this.allOverallWeight > 100) {
        this.overallWeightExceede = true;
      }
      if (this.allOverallWeight <= 100) {
        this.overallWeightExceede = false;
      }
    },
    saveElmGrade() {
      if (this.overallWeightExceede) {
        this.$modal.show("saveTotalWeightIncorrect");
      } else {
        const fb = new FormData();
        for (let i = 0; i < this.learnObjGradingWeights.length; i++) {
          fb.append(
            `learn_mod[learn_obj_grading_weights_attributes][${i}][id]`,
            this.learnObjGradingWeights[i].id
          );
          fb.append(
            `learn_mod[learn_obj_grading_weights_attributes][${i}][ilo_weight]`,
            this.learnObjGradingWeights[i].ilo_weight
          );
          fb.append(
            `learn_mod[learn_obj_grading_weights_attributes][${i}][assessment_weight]`,
            this.learnObjGradingWeights[i].assessment_weight
          );
          fb.append(
            `learn_mod[learn_obj_grading_weights_attributes][${i}][completion_weight]`,
            this.learnObjGradingWeights[i].completion_weight
          );
        }
        api
          .updateLearnObjGradingWeights(this.id, fb)
          .then(() => {})
          .catch(() => {});
        this.$modal.hide("GradingSetupModal");
      }
    },
    getGradeElmDetails() {
      this.allOverallWeight = 0.0;
      api
        .getGradingElm(this.id)
        .then((res) => {
          this.enableGrading = res.data.enable_grading;
          this.gradingType = res.data.grading_type;
          this.elmWeights = res.data.learn_obj_grading_weights;
          console.log(this.elmWeights);
          this.learnObjGradingWeights = [];
          for (let i = 0; i < this.elmWeights.length; i++) {
            this.allOverallWeight =
              parseFloat(this.allOverallWeight) +
              parseFloat(this.elmWeights[i].attributes.ilo_weight);
            const rowx = {
              id: this.elmWeights[i].id,
              learning_object_id:
                this.elmWeights[i].attributes.learning_object_id,
              learning_object_name:
                this.elmWeights[i].attributes.learning_object_name,
              ilo_weight: this.elmWeights[i].attributes.ilo_weight,
              completion_weight:
                this.elmWeights[i].attributes.completion_weight,
              assessment_weight:
                this.elmWeights[i].attributes.assessment_weight,
            };
            this.learnObjGradingWeights.push(rowx);
          }

          if (this.allOverallWeight > 100) {
            this.overallWeightExceede = true;
          }
          if (this.allOverallWeight <= 100) {
            this.overallWeightExceede = false;
          }
        })
        .catch(() => {});
    },
    showGradeElm() {
      this.getGradeElmDetails();
      this.$modal.show("GradingSetupModal");
    },
    setMenu(menu) {
      if (menu && menu.id) {
        this.selectedMenu = menu;
        let card = this.selectedMenu.attributes
          ? this.selectedMenu.attributes.card_type
          : "";
        this.card_type = card;
        this.learning_object_type = this.selectedMenu.attributes
          ? this.selectedMenu.attributes.learning_object_type
          : "";
      }
    },
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    getElmDetails() {
      this.elmData = {};
      utilFunctionService.showLoader();
      api
        .getElmDetails(this.id)
        .then((res) => {
          if (res && res.data) {
            this.elmData.active = res.data.active;
            // this.elmData.archived = res.data.archived;
            this.elmData.elm_name = res.data.elm_name;
            this.elmData.worldId = res.data.world_id;
            // this.elmData.archivedCount = res.data.archived_count;
            this.worldId = res.data.world_id;
            this.elmData = res.data;
            if (res.data.archived_count > 0) {
              this.getArchivedLo();
            } else {
              utilFunctionService.hideLoader();
            }
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            utilFunctionService.showErr(commonConfig.appMessages.sessionExpire);
            utilFunctionService.hideLoader();
            utilFunctionService.removeLocalStorageService("user");
            this.$router.push({ name: "login" });
          }
        });
    },
    getArchivedLo() {
      api
        .fetchArchivedLos(this.id)
        .then((res) => {
          if (res && res.data) {
            this.elmData.archived = res.data.archived;
          }
          utilFunctionService.hideLoader();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            utilFunctionService.showErr(commonConfig.appMessages.sessionExpire);
            utilFunctionService.hideLoader();
            utilFunctionService.removeLocalStorageService("user");
            this.$router.push({ name: "login" });
          }
        });
    },
    listenerChild(selectData) {
      this.card_type = "";
      console.log(selectData);
      console.log("hello");
      this.newLo = true;
      this.$store.dispatch("updateNewLo", true);
      this.$store.dispatch("updateLlmData", {})
      var res = selectData.split("-");
      this.card_type = res[1];
      this.learning_object_type = res[0];
      console.log(this.newLo);
      console.log(this.$store.state.newLo);
      this.$router.push({ query: { id: "" } });
      this.video = "";
      this.count = 1;
    },
    getLoDetails(id) {
      return id;
    },
    publishElm() {
      api
        .publishElm(this.id)
        .then(() => {
          utilFunctionService.showSuccess(commonConfig.appMessages.elmPublish);
          this.$router.push("/learning-modules");
        })
        .catch();
    },
    exportElm() {
      utilFunctionService.showLoader();
      api
        .exportElm(this.id)
        .then((response) => {
          utilFunctionService.hideLoader();
          let res = JSON.stringify(response.data.data);
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/plain" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log("error::" + error);
        });
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getElmDetails();
    this.getGradeElmDetails();
  },
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.learning-module-container {
  .step-container {
    width: 294px;
    flex-shrink: 0;
    .header-btn {
      &:hover {
        color: $white;
      }
    }
    .step-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      max-height: 545px;
      li {
        display: flex;
        align-items: center;
        background: $white;
        padding: 19px 20px;
        position: relative;
        margin-bottom: 15px;
        &:last-child {
          margin: 0;
        }
        a {
          display: flex;
          align-items: center;
        }
        .number-box {
          width: 37px;
          height: 37px;
          background-color: $theme-main-background;
          border-radius: 50%;
          font-size: 14px;
          text-align: center;
          color: $theme-color;
          @include font-medium;
          line-height: 37px;
          flex-shrink: 0;
        }
        .small-text {
          font-size: 11px;
          color: $list-color;
        }
        .heading {
          font-size: 14px;
          color: $theme-color;
          @include font-medium;
        }
        &.active {
          border: 2px solid $primary-button-color;
          .number-box {
            background-color: $primary-button-color;
            color: $white;
          }
          .heading {
            color: $primary-button-color;
          }
        }
        .icon-carrot-right {
          position: absolute;
          right: 10px;
          top: 33px;
          font-size: 8px;
          &::before {
            color: #858585;
          }
        }
        .tag {
          @include draft;
        }
      }
    }
    .btn {
      margin: 15px 0 0;
    }
  }
  .learning-form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .learning-preview {
    @include flexProperty(flex-end, null);
    margin: 30px 0 0;
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .btn {
      flex: 1;
      margin: 0 10px 10px;
      padding: 6px;
    }
    .pdf-button {
      flex: none;
      min-width: 150px;
      width: auto;
      .pin-image {
        width: 15px;
        height: auto;
        margin-right: 10px;
      }
    }
  }
  .col-sm-5 {
    .btn-container {
      margin: 0 -10px;
    }
    .btn {
      margin: 0 10px 10px !important;
    }
  }
  .form-section {
    margin-left: 20px;
    @include media-breakpoint(xl) {
      margin-left: 40px;
    }
    .form-pdf-group {
      min-height: 400px;
      margin-top: 10px;
    }
    .form-pdf-item {
      @include flexProperty(center, center);
      width: 167px;
      height: 169px;
      border: solid 0.5px #e2e2e2;
      position: relative;
      .close-pdf {
        width: 18px;
        height: 18px;
        @include position(absolute, -8px, -8px);
        @include flexProperty(center, center);
        border-radius: 100%;
        color: $white;
        font-size: 10px;
        background-color: #e02020;
      }
    }
  }
  .delete-box {
    width: 36px;
    height: 36px;
    background-color: rgba($error-button-color, 0.09);
    display: none;
    border-radius: 50%;
    text-align: center;
    line-height: 37px;
    margin-right: 5px;
    .icon-delete {
      padding-left: 2px;
    }
  }
  .delete-btn {
    color: $error-button-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin: 10px 0 0;
    &:hover {
      color: $error-button-color;
    }
    .text {
      text-decoration: underline;
    }
  }
  .small-btn-width {
    width: 180px;
    padding: 6px;
  }
  .img-section {
    width: 100%;
    height: 350px;
    background: #858585;
  }
  .setup-container {
    margin: 30px 0 50px;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      @include flexProperty(center, center);
      li {
        font-size: 13px;
        display: flex;
        text-align: center;
        color: $theme-color;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        padding: 0 20px;
        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background: #eaeaea;
          position: absolute;
          top: 12px;
          right: -52%;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
        .check-box {
          width: 26px;
          height: 26px;
          background-color: $theme-main-background;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 10px;
          position: relative;
          z-index: 1;
          cursor: pointer;
          &:before {
            content: "";
            background: url("../assets/images/svg/round-check.svg") no-repeat;
            width: 26px;
            height: 26px;
            display: none;
            background-size: 100%;
          }
        }
        &.active {
          .check-box {
            &:before {
              display: block;
            }
          }
        }
      }
      .setup-dropdown {
        @include flexProperty(center, center, column);
      }
      .dropdown-menu {
        min-width: 600px;
        transform: translate(-55%, 50px) !important;
        padding-top: 30px;
        box-shadow: none;
        @include media-breakpoint(xl) {
          transform: translate(-50%, 50px) !important;
        }
        ul {
          flex-direction: column;
        }
        li {
          width: 100%;
          align-items: flex-start;
          text-align: left;
          &:after {
            display: none;
          }
        }
        .setup-dropdown-head {
          width: 100%;
          @include flexProperty(space-between, center);
          p {
            color: $primary-button-color;
            font-size: 13px;
            @include font-medium;
          }
        }
        .setup-dropdown-list {
          width: 100%;
        }
        .setup-dropdown-item {
          padding: 15px 0;
          border-bottom: #eaeaea 1px solid;
          &:last-child {
            border: 0;
          }
          p {
            font-size: 13px;
            color: $list-color;
            margin: 0 0 2px;
          }
          h4 {
            @include font-medium;
            font-size: 14px;
            color: $theme-color;
          }
        }
        .setup-btn {
          padding: 5px;
          font-size: 13px;
          margin: 0 0 0 10px;
        }
      }
    }
  }
  .search-input {
    position: relative;
    .form-control {
      padding-right: 30px;
    }
    .icon-search {
      position: absolute;
      top: 45px;
      right: 15px;
      &:before {
        color: #a3bab2;
      }
    }
  }
}
.user-row-scroll {
  max-height: 500px;
  overflow: auto;
}
.user-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .img-container {
    display: flex;
    .img-box {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 100%;
      background: url("../assets/images/image-placeholder.svg") no-repeat;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .user-info {
      padding-left: 10px;
      .head {
        font-size: 13px;
        color: $theme-color;
        @include font-medium;
      }
      .text {
        font-size: 12px;
      }
      p {
        @include font-italic;
        font-size: 11px;
        color: #606060;
      }
    }
  }
  .form-checkboxes {
    flex-shrink: 0;
    .custom-checkbox {
      font-size: 11px;
      margin: 0;
      padding-left: 30px;
      @include font-medium;
      color: $black;
      line-height: 21px;
      .checkmark {
        height: 21px;
        width: 21px;
        border-radius: 50px;
      }
    }
    .custom-checkbox input:checked ~ .checkmark:after {
      left: 7px;
      top: 4px;
    }
  }
}

.video-group {
  @include flexProperty(space-between, null, null, wrap);
}
.video-preview {
  @include font-medium;
  font-size: 12px;
  line-height: 1.67;
  color: $primary-button-color;
  &:hover {
    color: $primary-button-color;
  }
  span {
    text-decoration: underline;
  }
  em {
    font-size: 8px;
    margin: 0 0 0 5px;
  }
}

.group-editor {
  .ql-editor {
    min-height: 350px;
  }
}
.grading {
  color: #606060;
}
.custom-input-style-grade-number2 {
  border: 1px solid #ccc;
  background-color: #f2f8f6;
  border-radius: 3px;
  display: inline;
  width: 80px !important;
  height: 30px;
  padding-left: 1em;
  padding-right: 1em;
}
.custom-span-style-grade-number2 {
  border-radius: 3px;
  display: inline;
  width: 200px !important;
  height: 30px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
}

.elm-header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 50px;
  border-bottom: 1px;
  h2 {
    font-size: 35px;
  }
  .header-btn {
    min-width: 262px;
  }
  .elm-archived {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
    }
    ul {
      li {
        list-style: disc inside;
        font-size: 12px;
        margin: 0 0 0 20px;
        padding: 0;
        span {
          color: $input-error-color;
          text-decoration: underline;
          font-size: 16px;
        }
      }
    }
  }
}
.elm-header-grading {
  text-align: right;
  display: block;
  align-items: right;
  justify-content: space-between;
  // h2 {
  //   font-size: 35px;
  // }
  // .header-btn {
  //   min-width: 262px;
  // }
  // .elm-archived {
  //   display: flex;
  //   align-items: center;
  //   p {
  //     font-size: 16px;
  //   }
  //   ul {
  //     li {
  //       list-style: disc inside;
  //       font-size: 12px;
  //       margin: 0 0 0 20px;
  //       padding: 0;
  //       span {
  //         color: $input-error-color;
  //         text-decoration: underline;
  //         font-size: 16px;
  //       }
  //     }
  //   }
  // }
}

.question-modal-body-grading {
  margin: 20px 0 0;
  .add-question-link-grading {
    border: 5px solid #f2f7f5;
    @include flexProperty(center, center, column);
    border-radius: 12px;
    //padding: 36px 20px;
    padding: 20px 20px 20px 20px;
    margin: 10px 0 0;
    em {
      width: 100px;
      height: 50px;
      @include flexProperty(center, center);
      text-align: center;
      font-size: 50px;
      color: #959595;
    }
    &.add-question-small {
      em {
        font-size: 40px;
      }
    }
    span {
      @include font-bold;
      font-size: 17px;
      color: $theme-color;
      margin: 10px 0 0;
    }
    &:hover {
      background-color: #f2f7f5;
      color: $primary-button-color;
      em {
        color: $primary-button-color;
      }
    }
  }
}
</style>
