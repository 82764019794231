<template>
  <div id="CharacterRoles">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Character Roles</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchCharacterRoles"
                placeholder="Search"
                v-model="searchCharacterRoles"
                v-debounce:400ms="search"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <a
            @click="$modal.show('createCharacterRole')"
            class="btn header-btn primary"
          >Add a new Character Role</a>
        </div>
        <p
          v-if="totalCharacterRoles > 1 && !searchCharacterRoles"
        >You have {{totalCharacterRoles}} Character Roles in the system.</p>
        <p
          v-else-if="totalCharacterRoles <= 1 && !searchCharacterRoles"
        >You have {{totalCharacterRoles}} Character Role in the system.</p>
        <p
          v-else-if="totalCharacterRoles > 1 && searchCharacterRoles"
        >You have {{totalCharacterRoles}} Character Roles in the search result.</p>
        <p v-else>You have {{totalCharacterRoles}} Character Role in the search result.</p>
      </header>
       <div class="data-content">
        <div class="data-table data-column-3">
          <div class="data-head">
            <div class="data-col">Name
             <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
              </div>
            <div class="data-col">Created on
                <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('created_at','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('created_at','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Actions</div>
          </div>
          <div class="data-items" v-for="(role) in characterRolesList" :key="role.id">
            <div class="data-row">
              <div class="data-col">
                <div>{{role.attributes.name}}</div>
              </div>
               <div class="data-col">
                <div>{{role.attributes.created_on}}</div>
              </div>

              <div class="data-col data-action">
                <div class="data-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="$modal.show('editCharacterRole', {role_id: role.id, page: page })"
                  >
                    <em class="icon-edit"></em>
                  </button>
                </div>
                <div class="data-delete-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-delete"></em>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div
                      class="data-popper-title"
                    >Are you sure you want to delete this character Role?</div>
                    <div class="data-popper-footer">
                      <a class="btn default">cancel</a>
                      <a class="btn primary" @click="deletecharacterRole(role.id)">Ok</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
         <div class="data-footer" v-if="totalPages.length > 1">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getCharacterRoles(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      <div class="data-no-result" v-if="characterRolesList.length === 0 && !searchStatus && display">
        <h3>There’s nothing here!</h3>
        <p>You have no character Roles in the system yet.</p>
        <div>
          <a
            @click="show('createCharacterRole')"
            class="btn header-btn primary"
          >Add a character Roles</a>
        </div>
      </div>
      <div class="data-no-result" v-if="characterRolesList.length === 0 && searchStatus">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>

    <modal name="createCharacterRole" height="auto" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">

            <h5>Create a new Character Role</h5>
          </div>
          <button type="button" class="close" @click="hide('createCharacterRole')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="form">
            <form class="modal-form scheme-form">
              <div class="form-group">
                <label class="form-label" for="name">Name</label>
                <ValidationProvider name="Name" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="name"
                    class="form-control"
                    id="name"
                    aria-describedby="emailHelp"
                    v-model="name"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn default"
                  data-dismiss="modal"
                  @click="hide('createCharacterRole')"
                >Cancel</button>
                <button
                  type="button"
                  class="btn primary"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#addOrganisation"
                  v-on:click="saveCharacterRole"
                >Save</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </modal>
    </div>
    <editCharacterRole />>
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import editCharacterRole from "../components/modal/EditCharacterRole.vue";

export default {
  name: "CharacterRoles",
  components: {
    ValidationProvider,
    ValidationObserver,
    editCharacterRole
  },
  metaInfo: {
    title: "All Character Roles",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  filters: {
    upperCase: function(value, onlyFirstChar) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyFirstChar) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      selectedFile: null,
      name: "",
      characterRolesList: [],
      totalPages: [],
      page: 1,
      totalCharacterRoles: "",
      searchCharacterRoles: "",
      sortOrder: "desc",
      sortColumn: "id",
      searchStatus: false,
      isModalVisible: false,
      isActive: false,
      display: false,
    };
  },
  methods: {
    show() {
      this.$modal.show();
      this.isModalVisible = true;
    },
    hide(modal) {
      this.clearFormData();
      this.$modal.hide(modal);
      this.isModalVisible = false;
    },
    clearFormData() {
      this.name = "";
    },
    saveCharacterRole() {
      this.$refs.form
        .validate()
        .then(success => {
          this.isActive = true;
          if (!success) {
            return;
          } else {
            utilFunctionService.showLoader();
            const fb = new FormData();
            fb.append("world_role[name]", this.name);
            api
              .saveCharacterRole(fb)
              .then(() => {
                this.clearFormData();
                utilFunctionService.hideLoader();
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.characterRoleCreate
                );
                this.getCharacterRoles(this.page);
                this.hide("createCharacterRole");
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showErr(commonConfig.appMessages.error);
              });
          }
        })
        .catch();
    },
    getCharacterRoles(page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getAllCharacterRoles(
          page,
          this.searchCharacterRoles,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.searchStatus = false;
          this.characterRolesList = res.data.data;
          this.totalCharacterRoles = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    search(value) {
      api
        .getAllCharacterRoles(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.searchStatus = true;
          this.characterRolesList = res.data.data;
          this.totalCharacterRoles = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    nextPage() {
      this.page = this.page + 1;
      this.getCharacterRoles(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getCharacterRoles(this.page);
    },
    deletecharacterRole(id) {
      utilFunctionService.showLoader();
      api
        .deleteCharacterRole(id)
        .then(() => {
          utilFunctionService.hideLoader();
          let page;
          if (this.characterRolesList.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getCharacterRoles(page);
            } else {
              page = this.page - 1;
              this.getCharacterRoles(page);
            }
          } else {
            const page = this.page;
            this.getCharacterRoles(page);
          }
          utilFunctionService.showSuccess(
            commonConfig.appMessages.deletecharacterRole
          );
        })
        .catch(error => {
          utilFunctionService.showErr(
            error.response.data.errors
          );
          utilFunctionService.hideLoader();
           if (error.response.status == 401) {
            utilFunctionService.showErr(commonConfig.appMessages.sessionExpire);
            utilFunctionService.hideLoader();
            utilFunctionService.removeLocalStorageService("user");
            this.$router.push({ name: "login" });
          }
        });
    },
    toggleSort(colName, sortType) {
      let _colName = colName;
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getCharacterRoles(this.page);
      this.sortColumn = _colName;
    }
  },
  created() {
    this.getCharacterRoles(this.page);
    utilFunctionService.getImageUrl();
  }
};
</script>
