<template>
  <div>
    <div class="learning-form-header flex-wrap">
      <h4>Interaction (Coding)</h4>
      <div class="learning-quiz-btn">
          <a
            href="javascript:void(0);"
            @click="restore('published')"
            class="btn header-btn secondary"
            v-if="status == 'published'"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            @click="restore('published')"
            class="btn header-btn primary"
          >Publish LO</a>
          <a
            href="javascript:void(0);"
            @click="$modal.show('cloneConfirmationModal')"
            class="btn header-btn ml-2 secondary"
          >Clone LO</a>
      </div>
    </div>

    <!-- LO navigation tabs start here -->
    <div class="learning-lo-tabs">
      <div class="setup-container">
        <ul>
          <li @click="currentStep('basic-setup', 0)" :class="{ active: isActiveTab[0] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Basic Information</span>
            </a>
          </li>
          
          <li @click="currentStep('code-setup', 1)" :class="{ active: isActiveTab[1] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Code</span>
            </a>
          </li>

          <li @click="currentStep('tests-setup', 2)" :class="{ active: isActiveTab[2] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Tests</span>
            </a>
          </li>

          <li @click="currentStep('concepts-setup', 3)" :class="{ active: isActiveTab[3] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Concepts</span>
            </a>
          </li>

          <li @click="currentStep('admin-testing', 4)" :class="{ active: isActiveTab[4] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Testing</span>
            </a>
          </li>
          
          <li
            v-if="overallInteraction"
            :class="{ active: isActiveTab[5] }"
            @click="currentStep('overall-assessment', 5)"
          >
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Overall Assessment</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Content of tabs -->
    <div class="learning-quiz-content pt-4">
      <div class="setup-content" v-if="step == 'basic-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Basic Information</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Interaction title</label>
                  <ValidationProvider
                    name="Interaction Title"
                    rules="required|max:100"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="name"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Administrative notes</label>
                  <ValidationProvider
                    name="Administrative Notes"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <textarea
                      type="Description"
                      class="form-control"
                      id="notes"
                      rows="4"
                      v-model="adminNotes"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group mb-2">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="userOverall">
                      <input
                        type="checkbox"
                        name="userOverall"
                        v-model="overallInteraction"
                        v-bind:value="'userOverall'"
                      />
                      <span for="userOverall" class="checkmark"></span>
                      Use overall interaction assessment
                    </label>
                  </div>
                </div>

                <div class="form-group pt-1 mb-2" v-if="overallInteraction">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="quizAssessment">
                      <input
                        type="checkbox"
                        name="quizAssessment"
                        v-model="enableLearnerIterations"
                        v-bind:value="'enableLearnerIterations'"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Enable learner iteration
                    </label>
                  </div>
                </div>

                <div class="form-group mb-2" v-if="enableLearnerIterations && overallInteraction">
                  <div class="d-flex align-items-center">
                    <label>Max number of iterations</label>
                    <div class="w-80 ml-3">
                      <input
                        type="number"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                        min="1"
                        max="5"
                        class="form-control"
                        v-model="maxIterations"
                        @blur="displayReduceIterationModal()"
                      />
                    </div>
                  </div>
                </div>
                
                <div class="form-group" v-if="enableLearnerIterations && overallInteraction">
                  <label>Learner iteration explanation</label>
                  <textarea
                    type="Description"
                    class="form-control"
                    id="explanation"
                    rows="4"
                    v-model="learnerExplanation"
                  ></textarea>
                </div>

                
              </div>
              <div class="col-sm-7">
                 
                <div class="form-group">
                  <label>Select assessment scheme</label>
                  <ValidationProvider name="Assessment Scheme">
                    <div class="quiz-dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="entity"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :class="[(isActive && !assessmentListKey.id) ? 'invalid' : '']"
                      >
                        {{assessmentListKey.name}}
                        <em class="icon-carrot-down"></em>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="entity">
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          value="0"
                        >None</option>
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          :value="`${assessment.id}*${assessment.attributes.name}`"
                          v-for="assessment of assessmentLists"
                          :key="assessment.id"
                        >{{assessment.attributes.name}}</option>
                      </div>
                    </div>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="!assessmentListKey.id"
                    >Assessment scheme field is required</span>
                  </ValidationProvider>
                </div>
                
                <div class="form-group">
                  <label>Learner Description</label>
                  <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                   <vue-editor
                                :class="[(isActive && errors[0]) ? 'form-control invalid' : 'form-control']"
                                id="Description"
                                :editorToolbar="fullTool"
                                 placeholder="Add a Description"
                               v-model="description"
                            ></vue-editor>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group pt-1 mb-3">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="learnerModifiesProvidedCode">
                      <input
                        type="checkbox"
                        name="learnerModifiesProvidedCode"
                        v-model="learnerModifiesProvidedCode"
                        v-bind:value="'learnerModifiesProvidedCode'"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Learner modifies provided code
                    </label>
                  </div>
                </div>

                <div class="form-group pt-1 mb-3" v-if="overallInteraction">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="displayOverallAssessment">
                      <input
                        type="checkbox"
                        name="displayOverallAssessment"
                        v-model="displayOverallAssessment"
                        v-bind:value="'userOverall'"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Display overall assessment in learner feedback
                    </label>
                  </div>
                </div>

              </div>
              <div class="col-12 pt-5">
                <div class="d-flex justify-content-end mb-4">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="saveCodingLO('drafted', 1)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Basic Information end here -->
      
      <!-- Optimal and provided code setup start  -->
      <div class="setup-content" v-if="step === 'code-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Code</h4>
        </div>
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-3">
                <div class="form-group">
                  <ul class="form-entity-tabs">
                    <li
                      @click="selectedOptimalCode(true)"
                      :class="optimalCodeSelected === true ? 'active' : ''"
                    >
                      <a>Optimal Code</a>
                    </li>
                    <li v-if="learnerModifiesProvidedCode === true"
                      @click="selectedOptimalCode(false)"
                      :class="optimalCodeSelected === false ? 'active' : ''"
                    >
                      <a>Provided Code</a>
                    </li>
                  </ul>
                </div>
                
                
              </div>
              <div class="col-sm-9">
                <div v-if="optimalCodeSelected === true">
                  <div class="form-group">
                      <prism-editor
                        class="my-editor height-200"
                        v-model="optimalCode"
                        :highlight="highlighter" line-numbers
                        :tabSize=4
                      ></prism-editor>
                  </div>

                  <div class="form-group justify-content-end d-flex">
                    <button
                      href="javascript:void(0);"
                      @click="updateOptimalCode"
                      class="add-more-btn"
                    >Save Optimal Code</button>
                  </div>
                </div>

                <div v-else>
                  <div v-if="learnerModifiesProvidedCode === true">
                    <div class="form-group">
                        <prism-editor
                          class="my-editor height-200"
                          v-model="providedCode"
                          :highlight="highlighter" line-numbers
                          :tabSize=4
                        ></prism-editor>
                    </div>

                    <div class="form-group justify-content-end d-flex">
                      <button
                        href="javascript:void(0);"
                        @click="updateProvidedCode"
                        class="add-more-btn"
                      >Save Provided Code</button>
                    </div>
                  </div>
                </div>
                
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="next('tests-setup', 2)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
      </div>
      <!-- Optimal and provided code setup end  -->
      
      
      <!-- Coding tests setup start  -->
      <div class="setup-content" v-if="step === 'tests-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Tests</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <ul class="form-entity-tabs">
                    <li
                      v-for="(item, ind) in codingTestBlocks"
                      :key="item.id"
                      @click="selectedCodingTests(item, ind)"
                      :class="item.codingTestId == selectedCodingTest.codingTestId ? 'active' :''"
                    >
                      <a>{{item.codingTestName}}</a>
                    </li>
                  </ul>
                </div>
                <div class="form-group pt-4">
                  <label>Test name</label>
                  <input
                    class="form-control"
                    id="admin_notes"
                    v-model="newCodingTest"
                    :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                  />
                </div>
                <div class="form-group">
                  <div class="form-btn-group">
                    <a
                      href="javascript:void(0);"
                      class="btn w-100 primary"
                      @click="addNewCodingTest"
                    >Add test</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="skill-title my-4">
                <h5>{{this.selectedCodingTest.codingTestName}}</h5>
                    <a
                      href="javascript:void(0);"
                      class="email-delete-btn"
                      v-if="this.selectedCodingTest.id"
                      @click="$modal.show('deleteCodingTestBlockConfirmationModal')"
                      ><em class="icon-delete"></em>
                      Delete test
                    </a>
                  </div>

                <div class="form-group" v-if="this.selectedCodingTest.id">
                  <label>Learner explanation</label>
                  <ValidationProvider name="Learner Explanation" v-slot="{ errors }">
                    <textarea
                      class="form-control"
                      id="admin_notes"
                      rows="8"
                      v-model="codeTestExplanation"
                      :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive && errors[0] ? 'invalid' : '']"
                      :style="[isActive && errors[0] ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group" v-if="this.selectedCodingTest.id">
                  <prism-editor
                      class="my-editor height-200"
                      v-model="codeTestCode"
                      :highlight="highlighter" line-numbers
                      :tabSize=4
                    ></prism-editor>
                </div>

                <div class="form-group justify-content-end d-flex" v-if="this.selectedCodingTest.id">
                  <button
                    href="javascript:void(0);"
                    @click="updateCodingTests"
                    class="add-more-btn"
                  >Save</button>
                </div>
                
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="next('concepts-setup', 3)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Coding tests setup end  -->

      <!-- Concepts setup start  -->
      <div class="setup-content" v-if="step === 'concepts-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Concepts</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <ul class="form-entity-tabs">
                    <li
                      v-for="(item, ind) in conceptBlocks"
                      :key="item.id"
                      @click="selectedConceptBlocks(item, ind)"
                      :class="item.codingConceptId == selectedConcept.codingConceptId ? 'active' :''"
                    >
                      <a>{{item.codingConceptName}}</a>
                    </li>
                  </ul>
                </div>
                <div class="form-group pt-4">
                  <label>Concept name</label>
                  <input
                    class="form-control"
                    id="admin_notes"
                    v-model="newConcept"
                    :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                  />
                </div>
                <div class="form-group">
                  <div class="form-btn-group">
                    <a
                      href="javascript:void(0);"
                      class="btn w-100 primary"
                      @click="addNewConcept"
                    >Add concept</a>
                  </div>
                </div>
              </div>

              <div class="col-sm-7">
                <div class="skill-title my-4">
                <h5>{{this.selectedConcept.codingConceptName}}</h5>
                    <a
                      href="javascript:void(0);"
                      class="email-delete-btn"
                      v-if="this.selectedConcept.id"
                      @click="$modal.show('deleteCodingTestBlockConfirmationModal')"
                      ><em class="icon-delete"></em>
                      Delete concept
                    </a>
                  </div>

                <div class="form-group" v-if="this.selectedConcept.id">
                  <label>Learner explanation</label>
                  <ValidationProvider name="Learner Explanation" v-slot="{ errors }">
                    <textarea
                      class="form-control"
                      id="admin_notes"
                      rows="8"
                      v-model="conceptExplanation"
                      :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive && errors[0] ? 'invalid' : '']"
                      :style="[isActive && errors[0] ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group justify-content-end d-flex" v-if="this.selectedConcept.id">
                  <button
                    href="javascript:void(0);"
                    @click="openConceptTestsModal"
                    class="add-more-btn-other"
                  >Add / Edit Tests</button>
                </div>

                <div class="form-group">
                  <label>Tests associated with this concept:</label>
                  
                  <div class="chip-container entity-chip" v-if="codingConceptTests.length > 0">
                    <div
                      class="chip"
                      v-for="(test) in codingConceptTests"
                      :key="test.id"
                    >
                      <span>{{test.attributes.test.attributes.name}}</span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Assessments</label>
                    
                    <span
                      v-for="(assmnt) in codingConceptAssmntItems"
                      :key="assmnt.id"
                    >
                      <div class="row concept-assess-item">
                        <div class="form-col-3">
                          <span class="concept-assess-label">{{assmnt.attributes.assessment_label}}</span>
                        </div>
                        <div class="form-col-9">
                          <span>Min: </span>
                          <input  
                            type="text"
                            class="concept-form-item"  
                            v-model="assmnt.attributes.value_count_min"
                          />
                          <span>Max: </span>
                          <input  
                            type="text"
                            class="concept-form-item"  
                            v-model="assmnt.attributes.value_count_max"
                          />
                          <span>Points: </span>
                          <input  
                            type="text"
                            class="concept-form-item"  
                            v-model="assmnt.attributes.points"
                          />
                        </div>

                      </div>
                    </span>
                </div>

                <div class="form-group justify-content-end d-flex" v-if="this.selectedConcept.id">
                  <button
                    href="javascript:void(0);"
                    @click="updateCodingConcepts"
                    class="add-more-btn"
                  >Save</button>
                </div>
                
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="next('admin-testing', 4)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Concepts  setup end  -->

      <!-- Testing start -->
      <div class="setup-content" v-if="step == 'admin-testing'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Testing</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-8">
                <div class="form-group">
                  <prism-editor
                    class="my-editor height-200"
                    v-model="adminTestCode"
                    :highlight="highlighter" line-numbers
                    :tabSize=4
                  ></prism-editor>
                </div>
               
                <div class="form-group">
                  <div class="form-testing-group">
                    <a
                      href="javascript:void(0);"
                      class="btn default"
                      @click="clearTesting"
                    >Clear</a>
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="submitTesting"
                    >Submit</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-4" v-if="testEvalJsonScheme.attributes">
                <div
                  class="modal-test-mail"
                  v-if="testEvalJsonScheme.attributes.responses.length > 0"
                >
                  <h4>Character Response</h4>
                  <p
                    v-for="(scheme,index) in testEvalJsonScheme.attributes.responses"
                    :key="index"
                    v-html="scheme.attributes.response"
                  ></p>
                </div>
                <div class="modal-test-tabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="Test-tab"
                        data-toggle="tab"
                        href="#Test"
                        role="tab"
                        aria-controls="Test"
                        aria-selected="true"
                      >Test input Return Data</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="json-tab"
                        data-toggle="tab"
                        href="#json"
                        role="tab"
                        aria-controls="json"
                        aria-selected="false"
                      >View JSON</a>
                    </li>
                  </ul>
                  <div class="tab-content pt-2" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="Test"
                      role="tabpanel"
                      aria-labelledby="Test-tab"
                    >
                      <div class="test-items">
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.asst_entity_value_list.length">
                          <label>Assistant Entities Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.asst_entity_value_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.asst_intent_list.length">
                          <label>Assistant Intents Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.asst_intent_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.keyword_list.length">
                          <label>NLU Keywords Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.keyword_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.concept_list.length">
                          <label>NLU Concepts Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.concept_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                         <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.nlu_entities_list.length">
                          <label>NLU Entities Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.nlu_entities_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3">

                          <div class="test-chips-container">
                            <label>NLU Emotion Scores:</label>
                            <span class="test-chips">
                              JOY: {{testEmailEnrichmentItems.attributes.joy_score}},
                              ANGER: {{testEmailEnrichmentItems.attributes.anger_score}},
                              DISGUST: {{testEmailEnrichmentItems.attributes.disgust_score}},
                              SADNESS: {{testEmailEnrichmentItems.attributes.sadness_score}},
                              FEAR: {{testEmailEnrichmentItems.attributes.fear_score}}
                            </span>
                          </div>
                        </div>
                         <div class="mt-3">
                          <label>NLU Sentiment Score:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              Sentiment: {{testEmailEnrichmentItems.attributes.sentiment}},
                              Score: {{testEmailEnrichmentItems.attributes.sentiment_score}}
                            </span>
                          </div>
                        </div>

                      </div>
                      <div v-if="testEvalJsonScheme && testEvalJsonScheme.attributes && testEvalJsonScheme.attributes.response_formula_hits">
                        <label
                            class="text-label"
                          >Email Response hit:</label>
                        <div
                          class="test-items"
                          v-for="(formula,index) in testEvalJsonScheme.attributes.response_formula_hits"
                          :key="index"
                        >
                          <div class="test-chips-container">
                            <span
                              class="test-chips"
                            >{{formula.attributes.email_response.name}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="json" role="tabpanel" aria-labelledby="json-tab">
                      <div class="enrichment-concept-view p-0">
                        <div class="enrichment-concept-editor">
                          <div class="enrichment-concept-code">
                            <json-viewer :value="testEvalJsonScheme" :expand-depth="5"></json-viewer>
                          </div>
                          <div class="enrichment-code-copy">
                            <copy-to-clipboard
                              :text="JSON.stringify(testEvalJsonScheme)"
                              @copy="handleCopy"
                            >
                              <a href="javascript:void(0);" class="copy-icon">Copy</a>
                            </copy-to-clipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary btn-large"
                    @click="next('qa-and-interstitial', 8)"
                  >Next</a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Testing end -->
      
      <!-- Overall assessment -->
      <div class="setup-content" id="overall-assessment" v-if="step === 'overall-assessment'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Overall Assessment</h4>
        </div>
        <ValidationObserver ref="form" v-if="overallAssmntItem">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Overall Interaction Assessment</label>
                  <ul class="form-entity-tabs">
                    <li
                      v-for="assmntItem of overallAssmntItem"
                      :key="assmntItem.id"
                      :class="assmntItem.id == selectedOverallAssmntItem.id ? 'active' : ''"
                      @click="handleOverallAssmntItem(assmntItem)"
                    >
                      <a href="javascript:void(0);">{{assmntItem.attributes.assessment_label}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="question-content" id="number-range">
                  <div class="question-row">
                    <div class="form-group">
                      <label>Evaluation Text</label>
                      <ValidationProvider name="Evaluation Text" v-slot="{ errors }">
                        <vue-editor
                          id="Descriptiontext"
                          placeholder="Add a Description"
                          :editorToolbar="fullTool"
                          v-model="overallAssmntItemFeedback"
                        ></vue-editor>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group pt-2">
                      <label>Evaluation range:</label>
                      <ValidationProvider name="Evaluation range" v-slot="{ errors }">
                        <div class="row mt-2">
                          <div class="col-6">
                            <label>Minimum</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="overallAssmntItemMinScore"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                          </div>
                          <div class="col-6">
                            <label>Maximum</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="overallAssmntItemMaxScore"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                          </div>
                        </div>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 pt-5">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a class="btn primary" @click="restore('published')">Save & Publish</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    


    <!-- Modals -->

    

    <modal name="deleteResponseModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">Are you sure you want to delete?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteResponseModal')"
            class="btn medium default"
          >Cancel</button>
          
           
          <button
            v-if="responseFormulaId.name === 'externalApiAssmntFormula'"
            type="button"
            @click="deleteAssessmentResponseFormula(),$modal.hide('deleteResponseModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
    
    
    <modal name="deleteCodingTestBlockConfirmationModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
        >Are you sure you want to delete this test?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteCodingTestBlockConfirmationModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteCodingTest(),$modal.hide('deleteCodingTestBlockConfirmationModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>

    <modal name="ReduceIteration" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
          >Reducing the iteration level removes the respective iteration responses added, if any.
          Are you sure you want to reduce the iteration level? </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="changeBackIteration(),$modal.hide('ReduceIteration')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="$modal.hide('ReduceIteration')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>

    <modal name="cloneConfirmationModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
        >Are you sure you want to clone this learning object?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('cloneConfirmationModal')"
            class="btn medium default"
          >Cancel</button>
          <button type="button" @click="cloneLo(),$modal.hide('cloneConfirmationModal')" class="btn medium primary">Ok</button>
        </div>
      </div>
    </modal>

    <modal name="conceptTestsModal" height="auto" :width="700" :scrollable="true">
      <div class="modal-content modal-default">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Add tests to concept</h5>
          </div>
        </div>
        <div class="modal-body">
          <form class="modal-form modal-min-height">
            <div class="modal-formula-content">
              <!-- formulas-tabs-list start -->

              <div class="formulas-list-title">
                <div class="formulas-left">
                  All available tests:
                </div>
              </div>
              <div class="formulas-tabs-list">
                
                <!-- formulas-tabs-content start -->
                <div class="formulas-tabs-content">
                  
                  <!-- tests start -->
                    <div class="formula-tab-pane active" id="concept">
                      <div class="formula-chips-list">
                        <span class="formula-chips" v-for="(ctest) in allCodingTests" :key="ctest.id">
                          <drag class="drag" :transfer-data="ctest">{{ctest.testName}}</drag>
                        </span>
                      </div>
                      
                      
                      
                    </div>
                    <!-- tests end -->
                  
                  
                </div>
                <!-- formulas-tabs-content end -->
              </div>
              <!-- formulas-tabs-list end -->
              <!-- formulas-data-list start -->
              <div class="formulas-data-list">
                <div class="formulas-list">
                  <drop class="drop" @drop="handleEvaluationDrop($event)">
                    <div class="formulas-list-title">
                      <div class="formulas-left">
                        Tests on this concept:
                      </div>
                    </div>
                    <div class="formulas-list-item">
                      <div
                        class="formula-item-flex"
                        v-for="(ctest, index) in codingConceptTests"
                        :key="index"
                      >
                        <div class="formula-name">
                          {{ctest.attributes.test.attributes.name}}:
                          
                          <a
                            href="javascript:void(0);"
                            @click="removeConceptTest(index)"
                            class="formula-cross"
                          >
                            <em class="icon-close"></em>
                          </a>
                        </div>
                        <span
                          class="formula-and"
                          v-if="Object.keys(codingConceptTests).length -1 !== index"
                        >and</span>
                      </div>
                    </div>
                    <div
                      class="formulas-list-placeholder"
                      v-if="Object.keys(codingConceptTests).length === 0"
                    >Drag and Drop here</div>
                  </drop>
                </div>
              </div>
              <!-- formulas-data-list end -->
            </div>
            <div class="modal-footer mt-4">
              <button
                type="button"
                @click="cancelAddEditConceptTests(),$modal.hide('conceptTestsModal')"
                data-dismiss="modal"
                class="btn default"
              >Cancel</button>
              <button
                type="button"
                class="btn primary"
                @click="handleAddEditConceptTests(),$modal.hide('conceptTestsModal')"
              >Done</button>
              
            </div>
          </form>
        </div>
      </div>
    </modal>

    

  </div>
</template>

<script>

import CopyToClipboard from "vue-copy-to-clipboard";
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism-solarizedlight.css'; 
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import "vue-slider-component/theme/antd.css";
import { VueEditor } from "vue2-editor";
import { Drag, Drop } from "vue-drag-drop";

export default {
  name: "CodingLO",
  props: [
    "selectedMenuItem",
    "assessmentLists",
    "id",
    "type"
  ],
  components: {
    ValidationProvider,
    ValidationObserver,
    CopyToClipboard,
    VueEditor,
    PrismEditor,
    Drag,
    Drop
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
  data() {
    return {
      step: "basic-setup",
      isActive: false,
      overallInteraction: true,
      maxIterations: 1,
      savedIterationLevel: 1,
      enableLearnerIterations: true,
      displayOverallAssessment: false,
      learnerModifiesProvidedCode: false,
      learnerExplanation: '',

      name: "",
      adminNotes: "",
      editEmailloId: "",
      description: "",
      url: utilFunctionService.getImageUrl(),
      assessmentListKey: { name: "None", id: null },
      
      activeTab: "",
      message: "",
      index: 0,
      objectableId: "",
      isActiveTab: ["active"],
      value: "",
      activeEnrichmentTab: "concept",
      emailEntityName: "",

      optimalCode: "",
      providedCode: "",
      adminTestCode: "",
      
      activeState: "",
      loId: this.$route.query.id,
      emailResFormulas: null,
      selEmailResFormulas: null,
      emailSelChar: null,
      responseName: "",
      selVariationList: null,
      variationName: "",
      storeTab: "",
      setSelFormulaField: "nlu_concepts",
      selectedEvalVal: null,
      selIterationLevel: 1,
      selectedVariation: null,
      presentMinKeyword: 0,
      absentMinKeyword: 0,

      
      assmntFormulaResultSection: '',
      assmntFormulaIncludes: '',
      assmntFormulaLessThan: '',
      assmntFormulaGreaterThan: '',
      assmntFormulaAbsent: false,
      
      overallAssmntItem: [],
      selectedOverallAssmntItem: [],
      assessmentSchemeId: "",
      overallAssmntItemFeedback: "",
      overallAssmntItemMaxScore: "",
      overallAssmntItemMinScore: "",
      overallAssessmentRange: "percentage",
      fullTool: [],
      interstitialLists: [],
      qaConditions: [],
      imagePreview: "",
      contentDescription: "",
      selDesPresentFormula: [],
      selDesAbsentFormula: [],
      editFormulaIndex: null,
      selectedTabChar: null,
      allEmailResFormulas: null,
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      
      testInput: "",
      test_access_key: '',
      test_secret_access_key: '',
      skillLists: [],
      //globalSkills: [],
      optimalCodeSelected: true,
      emailSkills: [],
      
      codingTestBlocks: [],
      allCodingTests: [],
      conceptBlocks: [],
      selectedConcept: {},
      codingConceptId: "",
      codeTestExplanation: "",
      conceptExplanation: "",
      newConcept: "",
      codingConceptTests: [],
      deletedConceptTests: [],
      addedConceptTests: [],
      unchangedConceptTests: [],
      codingConceptAssmntItems: [],

      selectedPresentFormula: {},

      newCodingTest: "",
      codingTestId: "",
      selectedCodingTest: {},
      codeTestCode: "",
      assessmentFormula: false,
      externalApiAssessmentBlockItems: [],
      selectedAssmntItem: "",
      selectBlock: "",
      adaptiveContentList: [],
      testEvalJsonScheme: "",
      testEmailEnrichmentItems: "",
      variations_briefattributes: [],
      variationsSelect: 1,
      assessmentPoints: 1,
      assessmentCode: '',
      testEvaulationScheme: [],
      emailQaResFormulas: [],
      emailSelCharId: "",
      responseFormulaId: "",
      status: 'drafted',
      updatedData: ""
    };
  },
  async created() {
    console.log(this.$route.query.id, "*** this.$route.query.id in async created in CodingLO.vue");
    console.log(this, "*** this in async created in CodingLO.vue");
    if (!this.$route.query.id) {
      this.clearForm();
    }
    else {
      this.setFieldValue();
    }
  },
  methods: {
    log({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      console.log(relatedElement);
      console.log(draggedElement);
    },
    cloneLo() {
      utilFunctionService.showLoader();
      api
        .cloneObj(this.$route.params.id, this.$route.query.id)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(
            commonConfig.appMessages.loCloned
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showErr(commonConfig.appMessages.cloneLoError);
        });
    },
    
    restore() {
      if (this.step === 'overall-assessment') {
          this.updateOverallAssessment();
      }
      const fb = new FormData();
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("learning_object[status]", "published");
      fb.append("id", this.$route.query.id);
      api
        .restoreLo(this.$route.params.id, this.$route.query.id, fb)
        .then(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        });

    },
    
    clearTesting() {
      this.adminTestCode = "";
      this.testEvalJsonScheme = "";
    },
    
    
    highlighter(code) {
      return Prism.highlight(code, Prism.languages.python, 'py');
    },

    openConceptTestsModal() {
      this.$modal.show("conceptTestsModal");
    },

    submitTesting() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("external_api_learn_obj_id", this.objectableId);
          fb.append("access_key_id", this.test_access_key);
          fb.append("secret_access_key", this.test_secret_access_key);
          api
            .externalApiTesting(fb)
            .then(res => {
              utilFunctionService.hideLoader();
              this.testEvalJsonScheme = res.data.data;
              utilFunctionService.showSuccess(
              commonConfig.appMessages.externalApiTestSuccess
            );
            })
            .catch(() => {
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    },
    
    
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },

    next(name, key) {
      this.currentStep(name, key);
      this.step = name;
      
    },

    handleCopy() {
      utilFunctionService.showSuccess(commonConfig.appMessages.jsonCopied);
     },
  

    displayReduceIterationModal() {
      if( this.maxIterations < this.savedIterationLevel) {
          this.$modal.show("ReduceIteration");
      }
    },
    
    changeBackIteration(){
      this.maxIterations = this.savedIterationLevel;
     },

    currentStep(e, key) {
      this.step = e;
      this.assessmentFormula = false;
      this.activeState = e;
      this.activeCodingStep = key;
      this.loId = this.$route.query.id;
      if (e === "basic-setup") {
        if (this.storeTab > this.activeCodingStep) {
          this.step = e;
          this.setActive(this.storeTab);
        } else {
          this.step = e;
          this.setActive(this.activeCodingStep);
        }
      } else if (e === "overall-assessment") {
        this.codeTestExplanation = "";
        this.setActive(key);
        this.getOverAllAssessments(true);
      } else if (e === "admin-testing") {
        this.testInput = "";
        this.setActive(key);
      } else if (e === "code-setup") {
        
        this.setActive(key);
      } else if (e === "tests-setup") {
        this.getCodingTestBlocks();
        this.setActive(key);
      } else if (e === "concepts-setup") {
        this.getCodingConceptBlocks();
        this.setActive(key);
      }
    },
    
    selectedOptimalCode(value) {
      if (value === true ) {
        this.optimalCodeSelected = true;
      } else {
        this.optimalCodeSelected = false;
      }
    },

    getCodingConceptBlocks() {
      // console.log("*** hit getCodingConceptBlocks ");
      api.getCodingConceptBlocks(this.objectableId).then(res => {
        console.log(res)
        this.conceptBlocks = res.data.data.map(x => ({
          id: x.id,
          codingConceptName: x.attributes.name,
          codingConceptId: x.id,
          conceptExplanation: x.attributes.explanation, 
          // codingConceptTests: x.attributes.coding_concept_tests.data,
          codingConceptTests: x.attributes.coding_concept_tests,
          // codingConceptAssmntItems: x.attributes.coding_assmnt_items.data
          codingConceptAssmntItems: x.attributes.coding_assmnt_items
        }));
        //console.log(this.conceptBlocks, "*** this.conceptBlocks");
        //console.log(res.data.data, "*** res.data.data");
        
        if (this.conceptBlocks.length > 0) {
          this.selectedConcept = this.conceptBlocks[0];
          this.conceptExplanation = this.selectedConcept.conceptExplanation;
          this.codingTestId = this.selectedConcept.codingConceptId;
          this.codingConceptTests = this.selectedConcept.codingConceptTests;
          this.codingConceptAssmntItems = this.selectedConcept.codingConceptAssmntItems;
          this.index = 0;
          this.step = "concepts-setup";
          this.storeTab = 3;
          if (this.storeTab > this.activeCodingStep) {
            this.setActive(this.storeTab);
          } else {
            this.storeTab = 6;
            this.setActive(this.activeCodingStep);
          }
          this.getAllCodingTests();
        }
      });
    },

    getCodingTestBlocks() {
      console.log("*** hit getCodingTestBlocks ");
      api.getCodingTestBlocks(this.objectableId).then(res => {
        this.codingTestBlocks = res.data.data.map(x => ({
          id: x.id,
          codingTestName: x.attributes.name,
          codingTestId: x.id,
          codeTestExplanation: x.attributes.explanation, 
          codeTestCode: x.attributes.test_code
        }));
        console.log(this.codingTestBlocks, "*** this.codingTestBlocks");
        console.log(res.data.data, "*** res.data.data");
        
        if (this.codingTestBlocks.length > 0) {
          this.selectedCodingTest = this.codingTestBlocks[0];
          this.codeTestExplanation = this.selectedCodingTest.codeTestExplanation;
          this.codingTestId = this.selectedCodingTest.codingTestId;
          this.codeTestCode = this.selectedCodingTest.codeTestCode;
          this.index = 0;
          this.step = "tests-setup";
          this.storeTab = 2;
          if (this.storeTab > this.activeCodingStep) {
            this.setActive(this.storeTab);
          } else {
            this.storeTab = 6;
            this.setActive(this.activeCodingStep);
          }
        }
      });
    },

    getAllCodingTests() {
      console.log("*** hit getAllCodingTests ");
      api.getCodingTestBlocks(this.objectableId).then(res => {
        this.allCodingTests = res.data.data.map(x => ({
          id: x.id,
          testName: x.attributes.name
        }));
        
        
      });
    },

    addNewCodingTest() {
      if (!this.newCodingTest) {
        utilFunctionService.showErr(commonConfig.appMessages.assestSkillErr);
      } else {
        const fb = new FormData();
        fb.append("coding_test[name]", this.newCodingTest);
        //fb.append("coding_test[test_code]", this.codeTestCode);
        fb.append("coding_test[coding_learn_obj_id]", this.objectableId)
        console.log(this.objectableId, "*** this.objectableId");
        api.postCodingTestBlock(this.objectableId, fb).then(res => {
          this.newCodingTest = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.newCodingTestAdded
          );
          this.codingTestBlocks.push({
            codingTestId: res.data.data.id,
            codingTestName: res.data.data.attributes.name,
            blockTestCode: res.data.data.attributes.test_code,
            explanation: res.data.data.attributes.explanation,
            id: res.data.data.id
          });
        });
      }
    },

    addNewConcept() {
      if (!this.newConcept) {
        utilFunctionService.showErr(commonConfig.appMessages.assestSkillErr);
      } else {
        const fb = new FormData();
        fb.append("coding_concept[name]", this.newConcept);
        //fb.append("coding_test[test_code]", this.codeTestCode);
        fb.append("coding_concept[coding_learn_obj_id]", this.objectableId)
        console.log(this.objectableId, "*** this.objectableId");
        api.postCodingConceptBlock(this.objectableId, fb).then(res => {
          this.newConcept = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.newCodingConceptAdded
          );
          this.conceptBlocks.push({
            codingConceptId: res.data.data.id,
            codingConceptName: res.data.data.attributes.name,
            conceptExplanation: res.data.data.attributes.explanation,
            id: res.data.data.id
          });
        });
      }
    },

    selectedCodingTests(item, ind) {
      console.log('hii', item , ind, item.codingTestId)
      //this.updateCodingTests();
      setTimeout(() => {
        this.codeTestExplanation = item.codeTestExplanation;
        this.codingTestId = item.codingTestId;
        this.selectedCodingTest = item;
        this.codeTestCode = item.codeTestCode;
        this.index = ind;
      }, 400);
    },

    selectedConceptBlocks(item, ind) {
      console.log('hii concept blocks', item , ind, item.codingConceptId)
      //this.updateCodingTests();
      setTimeout(() => {
        this.conceptExplanation = item.conceptExplanation;
        this.codingConceptId = item.codingConceptId;
        this.selectedConcept = item;
        this.index = ind;
        this.codingConceptTests = item.codingConceptTests;
        this.codingConceptAssmntItems = item.codingConceptAssmntItems;
      }, 400);
    },

    updateCodingTests() {
      let fb = new FormData();
      fb.append("coding_test[coding_learn_obj_id]", this.objectableId);
      fb.append("coding_test[explanation]", this.codeTestExplanation);
      fb.append("coding_test[test_code]", this.codeTestCode);
      //console.log(this.objectableId, "*** this.objectableId in updateCodingTests");
      if (this.selectedCodingTest && this.selectedCodingTest.id) {
        api.updateCodingTests(this.selectedCodingTest.id, fb).then(res => {
          const i = this.codingTestBlocks.findIndex(
            x => x.codingTestId == res.data.data.id
          );
          this.codingTestBlocks[i].codeTestExplanation = res.data.data.attributes.explanation;
          this.codingTestBlocks[i].codeTestCode = res.data.data.attributes.test_code;
          //if (this.assessmentFormula) {
            //this.selectBlock = res.data.data;
            //this.codingTestSetup(true);
          //}
          utilFunctionService.showSuccess(
            commonConfig.appMessages.codingTestUpdated
          );
        });
      } else {
        api.postCodingTestData(this.objectableId, fb).then(res => {
          const ind = this.codingTestBlocks.findIndex(
            x => x.codingTestId == res.data.data.id
          );
          this.codingTestBlocks[ind].codeTestExplanation = res.data.data.attributes.explanation;
          this.codingTestBlocks[ind].codeTestCode = res.data.data.attributes.test_code;
          this.codingTestBlocks[ind].id = res.data.data.id;
          //if (this.assessmentFormula) {
           // this.selectBlock = res.data.data;
            //this.codingTestSetup(true);
          //}
          utilFunctionService.showSuccess(
            commonConfig.appMessages.codingTestUpdated2
          );
        });
      }
    },

    updateCodingConcepts() {
      let fb = new FormData();
      fb.append("coding_concept[coding_learn_obj_id]", this.objectableId);
      fb.append("coding_concept[explanation]", this.conceptExplanation);
      this.codingConceptAssmntItems.forEach((e, i) => {
        fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][id]`, e.id);
        fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][value_count_min]`, e.attributes.value_count_min);
        fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][value_count_max]`, e.attributes.value_count_max);
        fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][points]`, e.attributes.points);
      });

      if (this.selectedConcept && this.selectedConcept.id) {
        api.updateCodingConcepts(this.selectedConcept.id, fb).then(res => {
          const i = this.conceptBlocks.findIndex(
            x => x.codingConceptId == res.data.data.id
          );
          this.conceptBlocks[i].conceptExplanation = res.data.data.attributes.explanation;
          //if (this.assessmentFormula) {
            //this.selectBlock = res.data.data;
            //this.codingTestSetup(true);
          //}
          utilFunctionService.showSuccess(
            commonConfig.appMessages.codingConceptUpdated
          );
        });
      } 
    },

    updateOptimalCode() {
      let fb = new FormData();
      fb.append("coding_learn_obj[optimal_code]", this.optimalCode);
      api
        .updateCodingLO(this.objectableId, fb).then(
          );
          utilFunctionService.showSuccess(
            commonConfig.appMessages.optimalCodeUpdated
          );

    },

    updateProvidedCode() {
      let fb = new FormData();
      fb.append("coding_learn_obj[provided_code]", this.providedCode);
      api
        .updateCodingLO(this.objectableId, fb).then(
          );
          utilFunctionService.showSuccess(
            commonConfig.appMessages.providedCodeUpdated
          );
    },
    

    getOverAllAssessments(isUpdated = true) {
      console.log(this.$route.query.id, "*** this.$route.query.id in getOverAllAssessments");
      console.log(this.loId, "*** this.loId");
      api
        .getOverallAssmntItem(this.loId)
        .then(res => {
          this.overallAssmntItem = res.data.data.sort(this.sortOrder);
          if (this.overallAssmntItem.length === 0) {
            this.assessment_scheme_id &&
              api
                .getAssessmentScheme(this.assessmentSchemeId)
                .then(res => {
                  this.generateOverAllAssessment(
                    res.data.data.attributes.assessment_labels
                  );
                  api
                    .getOverallAssmntItem(this.$route.query.id)
                    .then(res2 => {
                      this.overallAssmntItem = res2.data.data.sort(
                        this.sortOrder
                      );
                      this.selectedOverallAssmntItem = this.overallAssmntItem[0];
                      this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
                      this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
                      this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
                    })
                    .catch(() => {
                      utilFunctionService.hideLoader();
                    });
                })
                .catch(() => {
                  utilFunctionService.hideLoader();
                });
          } else {
            if (isUpdated) {
              this.selectedOverallAssmntItem = this.overallAssmntItem[0];
              this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
              this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
              this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
            }
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    sortOrder(a, b) {
      if (a > b) return 1;
      if (b > a) return -1;

      return 0;
    },
    handleOverallAssmntItem(data) {
      this.updateOverallAssessment();
      setTimeout(() => {
        this.selectedOverallAssmntItem = data;
        this.overallAssmntItemFeedback = this.selectedOverallAssmntItem.attributes.feedback;
        this.overallAssmntItemMaxScore = this.selectedOverallAssmntItem.attributes.max_score;
        this.overallAssmntItemMinScore = this.selectedOverallAssmntItem.attributes.min_score;
      }, 200);
    },
    updateOverallAssessment() {
      this.$refs.form.validate().then(success => {
        if (
          !this.overallAssmntItemFeedback &&
          !this.overallAssmntItemMaxScore &&
          !this.overallAssmntItemMaxScore
        ) {
          this.isActive = true;
          return;
        }
        if (!success) {
          this.isActive = true;
          return;
        } else {
          let fb = new FormData();
          fb.append(
            "overall_assmnt_item[feedback]",
            this.overallAssmntItemFeedback
          );
          fb.append(
            "overall_assmnt_item[max_score]",
            this.overallAssmntItemMaxScore
          );
          fb.append(
            "overall_assmnt_item[min_score]",
            this.overallAssmntItemMinScore
          );
          api
            .updateOverallAssesScheme(this.selectedOverallAssmntItem.id, fb)
            .then(() => {
              this.getOverAllAssessments(false);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.feedbackUpdateErr
              );
            });
        }
      });
    },
    generateOverAllAssessment(data) {
      data.forEach(ele => {
        let fb = new FormData();
        fb.append("overall_assmnt_item[assessment_label_id]", ele.id);
        fb.append("overall_assmnt_item[feedback]", "");
        fb.append("overall_assmnt_item[max_score]", 0);
        fb.append("overall_assmnt_item[min_score]", 0);
        api
          .createOverallAssesScheme(this.$route.query.id, fb)
          .then(() => {})
          .catch(() => {
            utilFunctionService.showErr();
          });
      });
    },

    setActive(key) {
      this.isActiveTab = [];
      for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
    },
    
    basicSetupUpdate() {
      console.log("*** hit basicSetupUpdate in CodingLO.vue");
      console.log(this, "*** this in basicSetupUpdate");
      console.log(this.$route.query.id, "*** this.$route.query.id in basicSetupUpdate");
      if (!this.$route.query.id) {
        this.clearForm();
      } else {
        this.setActive(0);
        this.step = "basic-setup";
        this.setFieldValue();
      }
    },
    
    setFieldValue() {
      console.log(this.$route.query.id, "*** this.$route.query.id in setFieldValue");
      console.log(this.selectedMenuItem.id, "*** this.selectedMenuItem.id");
      if (
        this.selectedMenuItem &&
        this.$route.query.id === this.selectedMenuItem.id
      ) {
        console.log("*** his setFieldValue");
        this.status = this.selectedMenuItem.attributes.status;
        this.name = this.selectedMenuItem.attributes.name;
        this.getOverAllAssessments(true);
        this.description = this.selectedMenuItem.attributes.description;
        this.adminNotes = this.selectedMenuItem.attributes.admin_notes;
        this.isActive = false;
        this.overallInteraction = this.selectedMenuItem.attributes.overall_assessment_required;
        this.assessmentListKey = {
          name: "",
          id: this.selectedMenuItem.attributes.assessment_scheme_id,
        };
        this.getSelectedAssessment(
          this.selectedMenuItem.attributes.assessment_scheme_id
        );
        this.assessmentSchemeId = this.selectedMenuItem.attributes.assessment_scheme_id,
        this.assessment_scheme_id = this.selectedMenuItem.attributes.assessment_scheme_id;
        this.maxIterations = this.selectedMenuItem.attributes.card_detail.attributes.max_iterations;
        this.enableLearnerIterations = this.selectedMenuItem.attributes.card_detail.attributes.iteration_enabled;
        this.learnerExplanation = this.selectedMenuItem.attributes.card_detail.attributes.iteration_explanation;
        this.displayOverallAssessment = this.selectedMenuItem.attributes.card_detail.attributes.display_overall_assessment_in_feedback;
        this.learnerModifiesProvidedCode = this.selectedMenuItem.attributes.card_detail.attributes.learner_modifies_provided_code;
        this.optimalCode = this.selectedMenuItem.attributes.card_detail.attributes.optimal_code;
        this.providedCode = this.selectedMenuItem.attributes.card_detail.attributes.provided_code;
        this.objectableId = this.selectedMenuItem.attributes.objectable_id;
        if(this.selectedOverallAssmntItem) {
            this.activeLength = 3;
        }
      } else {
        this.clearForm();
      }
    },

    selectedAssessment(event) {
      let value = event.target.value.split("*");
      this.assessmentListKey = { name: value[1], id: value[0] };
    },

    saveCodingLO(type, key) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        console.log(this.overallInteraction, "*** this.overallInteraction in CodingLO.vue line 4685 in saveCodingLO method");
        console.log(this.id, "*** this.id boo yah in saveCodingLO");
        console.log(this, "*** this ya");
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("learning_object[status]", type);
          fb.append("learning_object[description]", this.description);
          fb.append(
            "learning_object[overall_assessment_required]",
            this.overallInteraction
          );
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[admin_notes]", this.adminNotes);
          fb.append("card_type", "coding");
          fb.append("learning_object[learning_object_type]", "interaction");
          fb.append(
            "learning_object[assessment_scheme_id]",
            this.assessmentListKey.id
          );
          fb.append("card[iteration_enabled]", this.enableLearnerIterations);
          fb.append("card[max_iterations]", this.maxIterations);
          fb.append("card[iteration_explanation]", this.learnerExplanation);
          fb.append("card[display_overall_assessment_in_feedback]", this.displayOverallAssessment);
          fb.append("card[learner_modifies_provided_code]", this.learnerModifiesProvidedCode);
          fb.append("card[optimal_code]", this.optimalCode);
          fb.append("card[provided_code]", this.providedCode);
          
          if (this.$route.query.id) {
            api
              .updateLo(this.id, this.loId, fb)
              .then(res => {
                this.loId = res.data.data.id;
                utilFunctionService.hideLoader();
                this.objectableId = res.data.data.attributes.objectable_id;
                this.activeCodingStep = key;
                
                this.assessmentSchemeId =
                  res.data.data.attributes.assessment_scheme_id;
                
                
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showErr("Internal Server Error");
              });
          } else {
            api
              .createLearningObject(this.id, fb)
              .then(res => {
                utilFunctionService.hideLoader();
                this.storeTab = 1;
                this.loId = res.data.data.id;
                
                this.$route.query.id = this.loId;
                this.objectableId = res.data.data.attributes.objectable_id;
                this.assessmentSchemeId =
                  res.data.data.attributes.assessment_scheme_id;
                this.activeCodingStep = key;
                
                api.createCodingOverAllAssessment(this.objectableId).then(() => {});
                
              })
              .catch(() => {
                utilFunctionService.hideLoader();
              });
          }
          this.next('code-setup', 1);
        }
      });
    },

    getSelectedAssessment(id) {
      if (this.assessmentLists) {
        this.assessmentLists.forEach(e => {
          if (id == e.id) {
            this.assessmentListKey = { name: e.attributes.name, id: e.id };
          }
        });
      }
    },
    
    clearForm() {
      console.log("*** hit clearForm in CodingLO.vue");
      console.log(this, "*** this in clearForm");
      this.status = 'drafted';
      this.name = "";
      this.adminNotes = "";
      this.description = "";
      this.overallInteraction = false;
      this.learnerExplanation =
        "This coding assessment provided multiple opportunities to achieve the highest overall assessment. This number indicates how many iterations you went through on this interaction.";
      this.step = "basic-setup";
      this.isActive = false;
      this.assessmentListKey = { name: "None", id: null };
      
      this.activeTab = "";
      this.message = "";
      this.index = 0;
      this.objectableId = "";
      this.isActiveTab = ["active"];
      this.value = "";
      this.activeState = "";
      this.activeKey = "";
      this.codeTestExplanation = "";
      this.setActive(0);
    },
    
    handleResFormula(field) {
      this.setSelFormulaField = field;
      
    },

    handleEvaluationDrop(data) {
      console.log(data)
      console.log(this.codingConceptTests)

      // console.log("*** hit handleEvaluationDrop");
      // console.log(data, "*** data in handleEvaluationDrop");
      // console.log(data.testName, "*** data.testName in handleEvaluationDrop");
      let b = true
      for (let i = 0; i<this.codingConceptTests.length;i++)
      {
        if (this.codingConceptTests[i].attributes.test.attributes.name ===data.testName)
        {
          b =false
        }
      }
      if (b)
      {
        let a1 = {name: data.testName}
        let a2 = {attributes: a1}
        let a3 = {test: a2}
        this.codingConceptTests.push({
          id: data.id,
          attributes: a3 
        });
        this.addedConceptTests.push({
          id: data.id,
          attributes: a3,
          added:true
        });
      }
    },

    getSelectedConceptUnchangedConceptTests() {
      api
        .getSelectedConceptUnchangedConceptTests(this.selectedConcept.id)
        .then(res => {
          this.unchangedConceptTests = res.data.data;
        });
    },

    getSelectedCodingTest() {
      api
        .getCodingConceptTests(this.selectedConcept.id)
        .then(res => {
          this.unchangedConceptTests = res.data.data.map(x => ({
            id: x.id, 
            codingConceptName: x.attributes.name,
            codingConceptId: x.id, 
            conceptExplanation: x.attributes.explanation,
            // codingConceptTests: x.attributes.coding_concept_tests.data,
            // codingConceptAssmntItems: x.attributes.coding_assmnt_items.data
            codingConceptTests: x.attributes.coding_concept_tests,
            codingConceptAssmntItems: x.attributes.coding_assmnt_items
          }))
        });
    },

    removeConceptTest(index) {
      console.log(index, "*** index in removeConceptTest");
      console.log(this.codingConceptTests, "*** this.codingConceptTests in removeConceptTest");
      // This just removes the concept test from the modal window; doesn't actually delete the Rails record
      let b = true
      for (let i =0 ; i<this.addedConceptTests.length; i++)
      {
        if (this.addedConceptTests[i].attributes.test.attributes.name === this.codingConceptTests[index].attributes.test.attributes.name)
        {
          console.log("ok")
          this.addedConceptTests.splice(i, 1)
          b = false
        }
      }
      if (b)
      {
        let a1 = {name: this.codingConceptTests[index].attributes.test.attributes.name}
        let a2 = {attributes: a1}
        let a3 = {test: a2}
        this.addedConceptTests.push({
            id: this.codingConceptTests[index].id,
            attributes: a3,
            added:false
          });
      }
      this.deletedConceptTests.push(this.codingConceptTests.splice(index, 1))
      console.log(this.deletedConceptTests, "*** this.deletedConceptTests");
            
    },

    handleAddEditConceptTests() {
      console.log(this.addedConceptTests)

      for (let i = 0; i<this.addedConceptTests.length; i++)
      {
        if (!this.addedConceptTests[i].added)
        {

          let fb = new FormData();
          // fb.append("coding_concept[coding_learn_obj_id]", this.objectableId);
          fb.append("coding_concept[explanation]", this.conceptExplanation);

          fb.append("coding_concept[coding_concept_tests_attributes][0][id]", this.addedConceptTests[i].id);
          fb.append("coding_concept[coding_concept_tests_attributes][0][_destroy]", true);

          if (this.selectedConcept && this.selectedConcept.id) {
            api.updateCodingConcepts(this.selectedConcept.id, fb).then(res => {
              const i = this.conceptBlocks.findIndex(
                x => x.codingConceptId == res.data.data.id
              );
              this.conceptBlocks[i].conceptExplanation = res.data.data.attributes.explanation;

              utilFunctionService.showSuccess(
                commonConfig.appMessages.codingConceptUpdated
              );
            })
            .catch(e => {console.log(e)});
          } 
        }

        else
        {
          let fb = new FormData();
          fb.append("coding_concept[coding_learn_obj_id]", this.objectableId);
          fb.append("coding_concept[explanation]", this.conceptExplanation);
          fb.append("coding_concept[coding_concept_tests_attributes][0][coding_test_id]", this.addedConceptTests[i].id);
          this.codingConceptAssmntItems.forEach((e, i) => {
            fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][id]`, e.id);
            fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][value_count_min]`, e.attributes.value_count_min);
            fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][value_count_max]`, e.attributes.value_count_max);
            fb.append(`coding_concept[coding_assmnt_items_attributes][${i}][points]`, e.attributes.points);
          });

          if (this.selectedConcept && this.selectedConcept.id) {
            api.updateCodingConcepts(this.selectedConcept.id, fb).then(res => {
              this.codingConceptTests = res.data.data.attributes.coding_concept_tests
              const i = this.conceptBlocks.findIndex(
                x => x.codingConceptId == res.data.data.id
              );
              this.conceptBlocks[i].conceptExplanation = res.data.data.attributes.explanation;

              utilFunctionService.showSuccess(
                commonConfig.appMessages.codingConceptUpdated
              );
            });
          } 
        }


      }


      this.addedConceptTests = []
      

      
      
    },

    cancelAddEditConceptTests() {
      for (let i = 0; i<this.addedConceptTests.length; i++)
      {
        console.log(i)
        if (this.addedConceptTests[i].added)
        {
          for (let j = 0 ; j <this.codingConceptTests.length;j++)
          {
            if (this.codingConceptTests[j].id === this.addedConceptTests[i].id)
            {
              this.codingConceptTests.splice(j, 1)
              break
            }
          }
          
          // for (let j = 0 ; j <this.codingConceptTests.length;j++)
          // {
          //   if (this.codingConceptTests[j].id === this.addedConceptTests[i].id)
          //   {
          //     indexes.push(j)
          //   }
          // }
          // console.log(indexes)
          // for (let j = 0 ; j <indexes.length;j++)
          // {
          //   this.codingConceptTests.splice(indexes[j], 1)
          // }
          
        }
        else{
          this.codingConceptTests.push({
            id: this.addedConceptTests[i].id,
            attributes: this.addedConceptTests[i].attributes
          });
          
        }
        

      }
      this.addedConceptTests = []

      // console.log("*** hit cancelAddEditConceptTests");
      // console.log(this.codingConceptTests, "*** this.codingConceptTests in cancelAddEditConceptTests");
      // console.log(this.deletedConceptTests, "*** this.deletedConceptTests in cancelAddEditConceptTests");
      //this.getSelectedCodingTest();
      //console.log(this.unchangedConceptTests, "*** this.deletedConceptTests in cancelAddEditConceptTests");
      // Code to restore original value of this.codingConceptTests
      //this.codingConceptTests.push(this.deletedConceptTests);
      //setTimeout(() => {
        //this.codingConceptTests = this.unchangedConceptTests;
      //}, 400);
    },

    setDestroyId(res, formulaName) {
      this.responseFormulaId = { id: res.ques_id, name: formulaName };
    },

    deleteResponseFormula() {
      utilFunctionService.showLoader();
      api.destroyResFormula(this.responseFormulaId.id).then(() => {
        utilFunctionService.hideLoader();
        this.getEmailResList(false);
      });
    },
    
    deleteCodingTest() {
    utilFunctionService.showLoader();
    api.deleteCodingTest(this.selectedCodingTest.id).then(() => {
      utilFunctionService.hideLoader();
      this.getCodingTestBlocks();
    });
    },

    deleteAssessmentResponseFormula() {
      utilFunctionService.showLoader();
      api.destroyExternalApiAssmntFormula(this.selectedCodingTest.id, this.selectedAssmntItem.id, this.responseFormulaId.id).then(() => {
        utilFunctionService.hideLoader();
        this.codingTestSetup(false);
      });
    },
    

    
  }
};
</script>
<style lang="scss">
  @import "../../styles/variables.scss";
  @import "../../styles/mixin.scss";
  .my-editor {
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 1.2em 0.5em;
    min-height: 400px;
    border: 1px solid #ccc;
  }

  .prism-editor__textarea:focus {
    outline: none;
  }

  .form-testing-group {
    text-align: right;
  }
</style>
