var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "dragArea",
      attrs: { tag: "ul", list: _vm.nested_skills, group: { name: "skills" } },
      on: { add: _vm.addParentId, remove: _vm.removeChildID },
    },
    _vm._l(_vm.nested_skills, function (el) {
      return _c(
        "li",
        { key: el.name },
        [
          _c("p", [_vm._v(_vm._s(el.skill_name))]),
          _c("p", [_vm._v(_vm._s(el.llm_skill_name))]),
          _c("nested-draggable", { attrs: { nested_skills: el.children } }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }