var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown-menu data-popover-dropdown dropdown-menu-right",
      attrs: { "aria-labelledby": "menu1" },
    },
    [
      _c("div", { staticClass: "data-popover" }, [
        _vm._m(0),
        _c("form", { staticClass: "data-popover-form" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.docData.notes,
                  expression: "docData.notes",
                },
              ],
              staticClass: "form-control",
              attrs: { rows: "4" },
              domProps: { value: _vm.docData.notes },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.docData, "notes", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group-button" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-remove",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onSubmit("remove")
                  },
                },
              },
              [_vm._v("Remove")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-save",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onSubmit("save")
                  },
                },
              },
              [_vm._v("Save")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-popover-title" }, [
      _c("span", [_vm._v("Add Tags")]),
      _c("a", { staticClass: "data-popover-close" }, [
        _c("em", { staticClass: "icon-close" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }