var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Characters" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchCharacters,
                      expression: "searchCharacters",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:400ms",
                      value: _vm.search,
                      expression: "search",
                      arg: "400ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "aria-describedby": "searchCharacters",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchCharacters },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchCharacters = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("createCharacter")
                  },
                },
              },
              [_vm._v("Add a new Character")]
            ),
          ]),
          _vm.totalCharacters > 1 && !_vm.searchCharacters
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCharacters) +
                    " Characters in the system."
                ),
              ])
            : _vm.totalCharacters <= 1 && !_vm.searchCharacters
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCharacters) +
                    " Character in the system."
                ),
              ])
            : _vm.totalCharacters > 1 && _vm.searchCharacters
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCharacters) +
                    " Characters in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCharacters) +
                    " Character in the search result."
                ),
              ]),
        ]),
        _vm.charactersList.length !== 0
          ? _c("div", { staticClass: "data-content" }, [
              _c(
                "div",
                { staticClass: "data-table data-column-7" },
                [
                  _c("div", { staticClass: "data-head" }, [
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Name "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "first_name" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("first_name", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "first_name" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("first_name", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Created on "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "created_at" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("created_at", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "created_at" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("created_at", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Age "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "age" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("age", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "age" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("age", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Gender "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "gender" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("gender", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "gender" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("gender", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v("Assigned to"),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v("Organisation"),
                    ]),
                    _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
                  ]),
                  _vm._l(_vm.charactersList, function (character) {
                    return _c(
                      "div",
                      { key: character.id, staticClass: "data-items" },
                      [
                        _c("div", { staticClass: "data-row" }, [
                          _c("div", { staticClass: "data-col" }, [
                            _c("div", { staticClass: "data-image" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "data-pic circle cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show(
                                        "characterdetail",
                                        { character_id: character.id }
                                      )
                                    },
                                  },
                                },
                                [
                                  character.attributes.photo_url &&
                                  character.attributes.photo_url.thumbnail
                                    ? _c("img", {
                                        attrs: {
                                          src: character.attributes.photo_url
                                            .thumbnail,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "data-text cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show(
                                        "characterdetail",
                                        { character_id: character.id }
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(character.attributes.full_name))]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(character.attributes.created_on)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(character.attributes.age)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(character.attributes.gender.name)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "customer-dropdown customer-organise-dropdown",
                              },
                              [
                                character.attributes.organizations.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            character.attributes
                                              .organizations[0].attributes.name
                                          ) +
                                          " "
                                      ),
                                      character.attributes.organizations
                                        .length > 1
                                        ? _c("span", [
                                            _vm._v(
                                              ", + " +
                                                _vm._s(
                                                  character.attributes
                                                    .organizations.length - 1
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu dropdown-menu-right",
                                  },
                                  [
                                    _c("h3", [
                                      _vm._v(
                                        "Organization(" +
                                          _vm._s(
                                            character.attributes.organizations
                                              .length
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                    _c(
                                      "VuePerfectScrollbar",
                                      {
                                        staticClass: "scroll-area",
                                        attrs: { settings: _vm.settings },
                                      },
                                      [
                                        _c(
                                          "ul",
                                          { staticClass: "dropdown-list" },
                                          _vm._l(
                                            character.attributes.organizations,
                                            function (organizationcharacters) {
                                              return _c(
                                                "li",
                                                {
                                                  key: organizationcharacters.id,
                                                  staticClass: "dropdown-items",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-pic",
                                                    },
                                                    [
                                                      organizationcharacters
                                                        .attributes.photo_url &&
                                                      organizationcharacters
                                                        .attributes.photo_url
                                                        .thumbnail
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: organizationcharacters
                                                                .attributes
                                                                .photo_url
                                                                .thumbnail,
                                                              alt: "",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-content",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              organizationcharacters
                                                                .attributes.name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-role",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              organizationcharacters
                                                                .attributes
                                                                .industry_name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _c(
                              "a",
                              {
                                staticClass: "data-button",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.show(
                                      "assignOrganisation",
                                      { character_id: character.id }
                                    )
                                  },
                                },
                              },
                              [_vm._v("Add to Organization")]
                            ),
                          ]),
                          _c("div", { staticClass: "data-col data-action" }, [
                            _c("div", { staticClass: "data-dropdown" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn dropdown-toggle",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                  },
                                },
                                [
                                  _c("em", {
                                    staticClass: "icon-edit",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$modal.show(
                                          "characterdetail",
                                          { character_id: character.id }
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "data-delete-dropdown" }, [
                              _vm._m(1, true),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "data-popper-title" },
                                    [
                                      _vm._v(
                                        "Are you sure you want to delete this character?"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "data-popper-footer" },
                                    [
                                      _c("a", { staticClass: "btn default" }, [
                                        _vm._v("cancel"),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteCharacter(
                                                character.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Ok")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (page) {
                            return _c(
                              "li",
                              { key: page, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link active",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getCharacters(page)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(page))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.charactersList.length === 0 && !_vm.searchStatus && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no Characters in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("createCharacter")
                      },
                    },
                  },
                  [_vm._v("Add a new Character")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.charactersList.length === 0 && _vm.searchStatus
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          attrs: { name: "createCharacter", height: "auto", scrollable: true },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Create a new Character")])]
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide("createCharacter")
                    },
                  },
                },
                [_c("em", { staticClass: "icon-close" })]
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "modal-form organisation-form" }, [
                    _c("div", { staticClass: "organisation-image-group" }, [
                      _c(
                        "div",
                        {
                          staticClass: "organisation-charcter-pic cursor",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.fileInput.click()
                            },
                          },
                        },
                        [
                          _vm.image
                            ? _c("img", {
                                attrs: { src: _vm.image, alt: "images" },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "organisation-image-remove" }, [
                        _c("input", {
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "image/*" },
                          on: { change: _vm.onFileSelected },
                        }),
                        !_vm.image
                          ? _c(
                              "a",
                              {
                                staticClass: "btn add",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.fileInput.click()
                                  },
                                },
                              },
                              [_vm._v("Add Photo")]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "btn delete",
                                attrs: { href: "#" },
                                on: { click: _vm.uploadImageRemoved },
                              },
                              [_vm._v("Remove Photo")]
                            ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "first_name" },
                          },
                          [_vm._v("First Name")]
                        ),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "First Name",
                            rules: "required|max:100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.first_name,
                                        expression: "first_name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "first_name",
                                      id: "first_name",
                                      "aria-describedby": "emailHelp",
                                    },
                                    domProps: { value: _vm.first_name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.first_name = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "last_name" },
                          },
                          [_vm._v("Last Name")]
                        ),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "Last Name",
                            rules: "required|max:100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.last_name,
                                        expression: "last_name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "last_name",
                                      id: "last_name",
                                      "aria-describedby": "emailHelp",
                                    },
                                    domProps: { value: _vm.last_name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.last_name = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row mb-4" }, [
                      _c("div", { staticClass: "form-group col" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "exampleInputEmail1" },
                          },
                          [_vm._v("Gender")]
                        ),
                        _c("div", { staticClass: "form-gender-dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn dropdown-toggle",
                              attrs: {
                                type: "button",
                                id: "dropdownMenuButton",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("upperCharCase")(_vm.gender, true)
                                  ) +
                                  " "
                              ),
                              _c("em", { staticClass: "icon-carrot-down" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: {
                                "aria-labelledby": "dropdownMenuButton",
                              },
                            },
                            _vm._l(_vm.gender_list, function (gen) {
                              return _c(
                                "a",
                                {
                                  key: gen.id,
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setGender(
                                        gen.attributes.name,
                                        gen.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("upperCharCase")(
                                        gen.attributes.name,
                                        true
                                      )
                                    )
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group col" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "Age" },
                            },
                            [_vm._v("Age")]
                          ),
                          _c("ValidationProvider", {
                            attrs: { name: "Age", rules: "required|numeric" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.age,
                                          expression: "age",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: [
                                        _vm.isActive && errors[0]
                                          ? "invalid"
                                          : "",
                                      ],
                                      attrs: {
                                        type: "age",
                                        id: "age",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.age },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.age = _vm._n($event.target.value)
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    errors[0]
                                      ? _c(
                                          "span",
                                          {
                                            class: [
                                              _vm.isActive ? "invalid" : "",
                                            ],
                                            style: [
                                              _vm.isActive
                                                ? { display: "block" }
                                                : { display: "none" },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                errors[0].replace("The ", "")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn default",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: {
                            click: function ($event) {
                              return _vm.hide("createCharacter")
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn primary",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "data-toggle": "modal",
                            "data-target": "#addOrganisation",
                          },
                          on: { click: _vm.saveCharacter },
                        },
                        [_vm._v("Save")]
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("assignOrganisation"),
      _c("characterdetail"),
      _c("editCharacter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("All Characters")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-delete" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }