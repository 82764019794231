<template>
  <div>
    <modal
      name="uploadInterstitialModal"
      height="auto"
      :width="850"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <div class="modal-content modal-default">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5 v-if="content != 'adaptive'">Upload Interstitial Content</h5>
            <h5 v-if="content == 'adaptive'">Upload Adaptive Content</h5>
          </div>
        </div>
        <div class="modal-body modal-image-body">
          <form class="modal-form mt-0">
            <div class="modal-images-tab">
              <a
                href="javascript:void(0);"
                @click="tabChange('video')"
                :class="tab == 'video' ? 'active' :''"
              >Video</a>
              <a
                href="javascript:void(0);"
                @click="tabChange('slide')"
                :class="tab == 'slide' ? 'active' :''"
              >Slide</a>
              <a
                href="javascript:void(0);"
                @click="tabChange('file')"
                :class="tab == 'file' ? 'active' :''"
              >PDF</a>
              <a
                href="javascript:void(0);"
                @click="tabChange('text')"
                :class="tab == 'text' ? 'active' :''"
              >Rich Text</a>
            </div>
            <div class="modal-interstitial-content">
              <!-- video tab pane start -->
              <div id="Video" class="modal-interstitial-pane" v-if="tab == 'video'">
                <div class="row">
                  <!-- col-6 left column start -->
                  <div class="col-6">
                    <div class="form-group">
                      <label>Title</label>
                      <ValidationProvider
                        name="Card Title"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          aria-describedby="emailHelp"
                          v-model="name"
                          :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <ValidationProvider name="Description">
                        <textarea
                          type="Description"
                          class="form-control"
                          id="Description"
                          rows="5"
                          v-model="description"
                        ></textarea>
                      </ValidationProvider>
                    </div>
                    <div class="btn-container">
                      <a
                        href="javascript:void(0);"
                        class="btn mb-2 secondary medium"
                        @click="show('AddVideo')"
                      >Add a new video</a>
                      <a
                        href="javascript:void(0);"
                        @click="show('uploadVideo')"
                        class="btn mb-2 secondary medium"
                      >Select from Library</a>
                    </div>
                  </div>
                  <!-- col-6 left column end -->
                  <!-- col-6 right column start -->
                  <div class="col-6">
                    <div class="form-group video-group">
                      <div class="video-section">
                        <!-- video empty state  -->
                        <div class="video-empty" v-if="!video">
                          <img alt="images" src="../../assets/images/video-icon.png" />
                        </div>
                        <!-- video player state  -->
                        <div class="video-player" v-else>
                          <div class="learning-data-list" v-if="video && video.attributes">
                            <a class="close-pdf" @click="removeVideo()">
                              <em class="icon-close"></em>
                            </a>
                            <div
                              class="wistia_responsive_wrapper data-video"
                              style="height:100%;left:0;position:absolute;top:0;width:100%;"
                            >
                              <iframe
                                :src="preIframUrl+video.attributes.wistia_code+postIframUrl"
                                title="Video.mp4"
                                class="wistia_embed"
                                name="wistia_embed"
                                allowtransparency="true"
                                scrolling="no"
                                allowfullscreen
                                mozallowfullscreen
                                webkitallowfullscreen
                                oallowfullscreen
                                msallowfullscreen
                                width="100%"
                                height="100%"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- col-6 right column end -->
                </div>
              </div>
              <!-- video tab pane end -->
              <!-- slide tab pane start -->
              <div id="slide" class="modal-interstitial-pane" v-if="tab == 'slide'">
                <div class="row">
                  <!-- col-6 left column start -->
                  <div class="col-6">
                    <div class="form-group">
                      <label>Title</label>
                      <ValidationProvider
                        name="Card Title"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          aria-describedby="emailHelp"
                          v-model="name"
                          :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <ValidationProvider name="Description">
                        <textarea
                          type="Description"
                          class="form-control"
                          id="Description"
                          rows="5"
                          v-model="description"
                        ></textarea>
                      </ValidationProvider>
                    </div>
                    <div class="btn-container">
                      <input
                        style="display: none;"
                        ref="fileInput"
                        type="file"
                        @change="onFileSelect"
                        accept="image/jpeg"
                      />
                      <a
                        href="javascript:void(0);"
                        class="btn mb-2 secondary medium"
                        @click="$refs.fileInput.click()"
                      >Upload new images</a>
                      <a
                        href="javascript:void(0);"
                        @click="show('sliderImages')"
                        class="btn mb-2 secondary medium"
                      >Select from Library</a>
                    </div>
                  </div>
                  <!-- col-6 left column end -->
                  <!-- col-6 right column start -->
                  <div class="col-6">
                    <!-- video empty state  -->
                    <div class="slide-empty" v-if="!imagePreview">
                      <img alt="images" src="../../assets/images/svg/image-placeholder.svg" />
                      <!-- <div class="slide-input">
                        <input type="file" />
                        <span>
                          Drag & Drop your file here or
                          <span>browse</span> for a file.
                        </span>
                      </div>-->
                    </div>

                    <div class="col-12 px-0">
                      <div class v-if="imagePreview">
                        <img alt="images" :src="imagePreview" />
                      </div>
                      <div class="form-group mt-3 mb-3">
                        <div class="d-flex">
                          <label>Image Caption</label>
                          <label class="switch ml-auto">
                            <input type="checkbox" v-model="imageCaption" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="imageTitle"
                          v-on:input="getTitle"
                          placeholder="Add Caption"
                        />
                      </div>
                      <div v-if="slider.length" class="plot-thumbs">
                        <VueSlickCarousel v-bind="settings">
                          <div class="owl-item" v-for="(slide,i) in slider" :key="slide.id">
                            <div
                              class="item"
                              :class="[i == index ? 'active' : '']"
                              v-if="!slide.destroy"
                            >
                              <a
                                class="plot-btn"
                                @click="removeSliderImage(slide.id, slide.sliderId)"
                              >
                                <em class="icon-close"></em>
                              </a>
                              <img
                                alt="images"
                                :src="slide.image"
                                @click="slideImage(i, slide.title, slide.image)"
                              />
                            </div>
                          </div>
                        </VueSlickCarousel>
                      </div>
                    </div>

                    <!-- video player state  -->
                    <div class="plot-slider" style="display: none">
                      <div class="item">
                        <img alt="images" :src="imagePreview" v-if="imagePreview != false" />
                        <div class="plot-slider-title"></div>
                      </div>
                    </div>
                  </div>
                  <!-- col-6 right column end -->
                </div>
              </div>
              <!-- slide tab pane end -->
              <!-- PDF tab pane start -->
              <div id="pdf" class="modal-interstitial-pane" v-if="tab == 'file'">
                <div class="row">
                  <!-- col-6 left column start -->
                  <div class="col-6">
                    <div class="form-group">
                      <label>Title</label>
                      <ValidationProvider
                        name="Card Title"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          aria-describedby="emailHelp"
                          v-model="name"
                          :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <ValidationProvider name="Description">
                        <textarea
                          type="Description"
                          class="form-control"
                          id="Description"
                          rows="5"
                          v-model="description"
                        ></textarea>
                      </ValidationProvider>
                    </div>
                    <div class="btn-container">
                      <input
                        style="display: none;"
                        ref="fileInput"
                        type="file"
                        @change="onDocSelect"
                        accept=".pdf, .doc"
                      />
                      <a
                        class="btn mb-2 secondary medium"
                        @click="$refs.fileInput.click()"
                      >Upload new Pdf</a>
                      <a
                        href="javascript:void(0);"
                        @click="show('uploadDocument')"
                        class="btn mb-2 secondary medium"
                      >Select from Library</a>
                    </div>
                  </div>
                  <!-- col-6 left column end -->
                  <!-- col-6 right column start -->
                  <div class="col-6" v-if="doc.attributes">
                    <div class="form-pdf-group">
                      <div class="form-pdf-item">
                        <a data-v-248432b2>
                          <img data-v-248432b2 src="@/assets/images/pdf-big-icon.png" alt="pdf" />
                        </a>
                        <a
                          data-v-248432b2
                          href="javascript:void(0);"
                          class="close-pdf"
                          @click="removePdf()"
                        >
                          <em data-v-248432b2 class="icon-close"></em>
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- col-6 right column end -->
                </div>
              </div>
              <!-- PDF tab pane end -->
              <!-- Rich text tab pane start -->
              <div id="rich-text" class="modal-interstitial-pane" v-if="tab == 'text'">
                <div class="row">
                  <!-- col-6 left column start -->
                  <div class="col-6">
                    <div class="form-group">
                      <label>Title</label>
                      <ValidationProvider
                        name="Card Title"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          aria-describedby="emailHelp"
                          v-model="name"
                          :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <ValidationProvider name="Description">
                        <textarea
                          type="Description"
                          class="form-control"
                          id="Description"
                          rows="9"
                          v-model="description"
                        ></textarea>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- col-6 left column end -->
                  <!-- col-6 right column start -->
                  <div class="col-6">
                    <div class="form-group group-editor">
                      <label>Add Description</label>
                      <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                        <vue-editor
                          id="Descriptiontext"
                          placeholder="Add a Description"
                          :editorToolbar="fullTool"
                          v-model="contentDescription"
                        ></vue-editor>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <!-- col-6 right column end -->
                </div>
              </div>
              <!-- Rich text tab pane end -->
            </div>
          </form>
          <div class="modal-footer mt-4">
            <button
              type="button"
              @click="hide('uploadInterstitialModal')"
              data-dismiss="modal"
              class="btn default"
            >Cancel</button>
            <button
              type="button"
              class="btn primary"
              @click="saveContent('uploadInterstitialModal')"
              v-if="content === 'adaptive'"
            >Save Adaptive Content
            </button>
             <button
              type="button"
              class="btn primary"
              @click="saveContent('uploadInterstitialModal')"
              v-if="content != 'adaptive'"
            >Done
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal name="uploadDocument" height="auto" :width="800" :scrollable="true">
      <div class="modal-content modal-pdf-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Upload a Pdf</h5>
          </div>
        </div>
        <div class="modal-body modal-image-body">
          <form class="header-form">
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchDoc"
                v-debounce:700ms="searchDocument"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <div class="modal-images-content" v-if="docLists.length > 0">
            <v-infinite-scroll @bottom="nextPage" :offset="20" class="modal-content-scroll">
              <div class="modal-images-library">
                <div class="modal-images-row" v-if="docLists.length">
                  <div class="modal-images-col" v-for="doc in docLists" :key="doc.id">
                    <div
                      class="modal-images-checkbox"
                      @click="$modal.hide('uploadDocument');saveDoc(doc)"
                    >
                      <label class="custom-checkbox">
                        <input type="checkbox" name="image" v-model="doc.attributes.status" />
                        <span class="checkmark circle"></span>
                        <div class="modal-images-box">
                          <img
                            alt="pdf"
                            src="@/assets/images/pdf-big-icon.png"
                            class="modal-pdf-icon"
                          />
                        </div>
                        <div class="modal-images-info">
                          <h4>{{doc.attributes.file_name}}</h4>
                          <p v-if="doc.attributes.tag_list">{{doc.attributes.tag_list}}</p>
                        </div>
                        <div class="checked"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </v-infinite-scroll>
          </div>
          <div class="data-no-result" v-if="docLists.length === 0">
            <h3>No results found</h3>
            <p>Try using a different keyword</p>
          </div>
        </div>
        <div class="modal-footer" v-if="docLists.length > 0">
          <button type="button" @click="$modal.hide('uploadDocument');" class="btn default">Cancel</button>
          <button type="button" class="btn primary" @click="saveDoc()">Save</button>
        </div>
      </div>
    </modal>
    <modal name="sliderImages" height="auto" :width="800" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Upload Images</h5>
          </div>
        </div>
        <div class="modal-body modal-image-body">
          <div class="modal-images-tab">
            <a href class="active">Library</a>
          </div>
          <form class="header-form">
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchImg"
                v-debounce:700ms="searchImage"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <div class="modal-images-content">
            <v-infinite-scroll @bottom="nextImagesPage" :offset="20" class="modal-content-scroll">
              <div class="modal-images-library">
                <div class="modal-images-row">
                  <div class="modal-images-col" v-for="images in imageLists" :key="images.id">
                    <div class="modal-images-checkbox">
                      <label class="custom-checkbox">
                        <input type="checkbox" v-model="images.attributes.status" />
                        <span class="checkmark circle"></span>
                        <div class="modal-images-box">
                          <img
                            :src="images.attributes.resource_url.large_version"
                            v-if="images.attributes.resource_url.large_version"
                            alt="image"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </v-infinite-scroll>
            <div class="data-no-result" v-if="imageLists.length === 0">
              <h3>No results found</h3>
              <p>Try using a different keyword</p>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="imageLists.length > 0">
          <button @click="hide('sliderImages')" type="button" class="btn default">Cancel</button>
          <button
            type="button"
            @click="$modal.hide('sliderImages');saveImages()"
            class="btn primary"
          >Save</button>
        </div>
      </div>
    </modal>
    <modal name="cropperImages" height="auto" :width="800" :scrollable="true">
      <div class="modal-content modal-cropper-content">
        <div class="modal-body">
          <div class="modal-images-cropper">
            <div class="modal-cropper-large" v-if="image">
              <cropper :src="image" :stencil-props="{ aspectRatio: 16/9 }" ref="cropper"></cropper>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="$modal.hide('cropperImages')" type="button" class="btn default">Cancel</button>
          <button type="button" @click="crop()" class="btn primary">Save</button>
        </div>
      </div>
    </modal>
    <AddVideo />
  </div>
</template>

<script>
import api from "../../services/api";
import { ValidationProvider } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { VueEditor } from "vue2-editor";
import AddVideo from "@/components/modal/AddVideo.vue";
import VueSlickCarousel from "vue-slick-carousel";
import axios from "axios";

export default {
  name: "addInterstitialContent",
  components: {
    VueSlickCarousel,
    ValidationProvider,
    VueEditor,
    AddVideo
  },
  data() {
    return {
      imagePreview: false,
      contentDescription: "",
      adminNotes: "",
      description: "",
      fullTool: [],
      isActive: false,
      name: "",
      tab: "text",
      docLists: [],
      sortOrder: "desc",
      sortColumn: "id",
      searchDoc: "",
      searchDocument: "",
      doc: {},
      selectedFile: "",
      video: {},
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      searchImg: "",
      imageLists: [],
      imageTitle: "",
      slider: [],
      index: 0,
      imageCaption: false,
      searchImage: "",
      fileName: "",
      image: "",
      url: utilFunctionService.getImageUrl(),
      settings: {
        dots: false,
        infinite: false,
        dotsClass: "slick-dots custom-dot-class",
        speed: 200,
        slidesToShow: 7,
        slidesToScroll: 1,
        maxScrollbarLength: 30
      },
      editData: "",
      selectTab: "text",
      edit: false,
      content: ""
    };
  },
  methods: {
    beforeOpen(event) {
      if (event.params.create) {
        this.edit = false;
        this.content = event.params.content;
        this.clearForm();
      } else {
        this.editData = event.params.edit;
        this.edit = true;
        this.content = event.params.content;
        this.name = this.editData.attributes.title;
        this.description = this.editData.attributes.description;
        this.selectTab = this.editData.attributes.content_details.type;
        this.contentDescription = this.editData.attributes.content_details.attributes.title;
        if (this.selectTab === "file_learn_obj") {
          this.tab = "file";
          this.doc = {
            attributes: this.editData.attributes.content_details.attributes,
            id: this.editData.attributes.content_details.attributes
              .global_resource_id
          };
        } else if (this.selectTab === "text_learn_obj") {
          this.tab = "text";
        } else if (this.selectTab === "video_learn_obj") {
          this.tab = "video";
          this.video = {
            id: this.editData.attributes.content_details.attributes
              .global_video_id,
            attributes: this.editData.attributes.content_details.attributes
              .global_video.attributes
          };
        } else if (this.selectTab === "slide_learn_obj") {
          this.tab = "slide";
          this.slider = [];
          this.editData.attributes.content_details.attributes.slider_images.map(
            x => {
              this.slider.push({
                image: x.attributes.resource_url.large_version,
                title: x.attributes.caption,
                sliderId: x.attributes.global_resource_id,
                id: x.id,
                destroy: false
              });
            }
          );
          if (this.slider.length) {
            this.imagePreview = this.slider[this.index].image;
            this.imageTitle = this.slider[this.index].title;
          }
        }
      }
    },
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.editData = "";
      this.$modal.hide(modal);
    },
    tabChange(tab) {
      this.tab = tab;
      this.contentDescription = "";
      this.video = {};
      this.slider = [];
      this.doc = {};
      this.imagePreview = false;
    },
    removeVideo() {
      this.video = {};
    },
    onFileSelect(event) {
      this.selectedFile = "";
      this.fileName = "";
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.image = e.target.result;
      };
      this.$modal.show("cropperImages");
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.image = canvas.toDataURL();
      this.$modal.hide("cropperImages");
      let accessToken = utilFunctionService.getAuthToken();
      const fb = new FormData();
      fb.append("global_resource[attachment]", this.image);
      fb.append("global_resource[resource_type]", "image");
      fb.append("global_resource[tag_list]", "");
      fb.append("global_resource[private]", false);
      fb.append("global_resource[attachment_name]", this.fileName);
      axios
        .post(`${this.url}/api/admin/v1/global_resources.json`, fb, {
          headers: {
            Authorization: `${accessToken}`,
            "content-type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage =
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ) !== 100
                ? parseInt(
                    Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  )
                : 0;
          }.bind(this)
        })
        .then(res => {
          utilFunctionService.showSuccess(commonConfig.appMessages.addImage);
          this.slider.push({
            image: res.data.data.attributes.resource_url.large_version,
            title: "",
            sliderId: res.data.data.id,
            destroy: false
          });
          if (this.slider.length) {
            this.imagePreview = this.slider[this.index].image;
            this.imageTitle = this.slider[this.index].title;
          }
        })
        .catch(() => {
          utilFunctionService.showErr(commonConfig.appMessages.errImageUpload);
        });
    },
    slideImage(i, title, imageUrl) {
      this.imageTitle = title;
      this.imagePreview = imageUrl;
      this.index = i;
    },
    getTitle() {
      this.slider[this.index].title = this.imageTitle;
    },
    removeSliderImage(id, sliderId) {
      if (this.slider.length) {
        const sliderIndex = this.slider.findIndex(
          elem => parseInt(elem.sliderId) === parseInt(sliderId)
        );
        if (sliderIndex !== -1) {
          this.slider[sliderIndex].destroy = true;
        }
      }

      if (this.slider.length === 0) {
        this.imageTitle = "";
        this.imagePreview = false;
      } else {
        this.imagePreview = this.slider[0].image;
        this.imageTitle = this.slider[0].title;
      }
    },
    saveContent(modal) {
      let data = {
        name: this.name,
        desc: this.description,
        text: this.contentDescription,
        video: this.video,
        tab: this.tab,
        doc: this.doc,
        slider: this.slider,
        edit: this.edit,
        id: this.editData.id,
        content: this.content,
        imageCaption: this.imageCaption
      };
      this.hide(modal);
      if (data.content == "adaptive") {
        this.$emit("adaptiveContents", data);
      } else {
        this.$emit("interstitialContents", data);
      }
      this.clearForm();
    },
    selectedVideo(e) {
      this.video = e;
    },
    clearForm() {
      this.editData = "";
      this.contentDescription = "";
      this.description = "";
      this.fullTool = [];
      this.isActive = false;
      this.name = "";
      this.tab = "text";
      this.video = {};
      this.doc = {};
      this.slider = [];
    },
    getDoc(page) {
      this.page = page;
      if (this.page === 1) {
        this.docLists = [];
      }
      api
        .getAllDoc(page, this.searchDoc, this.sortColumn, this.sortOrder)
        .then(res => {
          this.docLists = [...this.docLists, ...res.data.data];
          this.totalDocs = res.data.filtered_count;
        })
        .catch();
    },
    nextPage() {
      ++this.page;
      if (this.page <= this.totalDocs) {
        this.getDoc(this.page);
      } else {
        return;
      }
    },
    saveDoc(doc) {
      this.doc = doc;
    },
    removePdf() {
      this.doc = {};
    },
    onDocSelect() {
      utilFunctionService.showLoader();
      for (var i = 0; i < event.target.files.length; i++) {
        this.selectedFile = event.target.files[i];
        const fb = new FormData();
        if (this.selectedFile) {
          fb.append("global_resource[attachment]", this.selectedFile);
          fb.append("global_resource[private]", false);
          fb.append("global_resource[resource_type]", "document");
          fb.append("global_resource[tag_list]", "");
          api.saveglobalResource(fb).then(res => {
            this.doc = res.data.data;
            utilFunctionService.hideLoader();
          });
        }
      }
    },
    getImages(page) {
      this.page = page;
      api
        .getAllImages(page, this.searchImg, this.sortColumn, this.sortOrder)
        .then(res => {
          this.display = true;
          this.searchStatus = false;
          if (page === 1) this.imageLists = res.data.data;
          else this.imageLists = [...this.imageLists, ...res.data.data];
          this.totalImages = res.data.filtered_count;
          this.totalPages = res.data.total_pages;
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    saveImages() {
      const selectedImages = this.imageLists.filter(
        x => x.attributes.status === true
      );

      if (this.$route.query.id) {
        selectedImages.map(x => {
          const a = this.slider.findIndex(y => y.sliderId === x.id);
          if (a === -1) {
            this.slider.push({
              image: x.attributes.resource_url.large_version,
              title: "",
              sliderId: x.id
            });
          }
        });
      } else {
        this.slider = selectedImages.map(element => {
          return {
            image: element.attributes.resource_url.large_version,
            title: "",
            sliderId: element.id
          };
        });
      }

      if (this.slider.length) {
        this.imagePreview = this.slider[this.index].image;
        this.imageTitle = this.slider[this.index].title;
      }
    },
    nextImagesPage() {
      ++this.page;
      if (this.page <= this.totalDocs) {
        this.getImages(this.page);
      } else {
        return;
      }
    }
  },
  created() {
    this.getDoc(1);
    this.getImages(1);
  }
};
</script>

<style lang="scss">
.customer-scroll-area {
  max-height: 180px;
}
.activePage {
  background-color: #007bff;
  border-color: #007bff;
}
</style>
