var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "pdfModal",
        height: "auto",
        width: 1000,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "modal-header modal-print-header" }, [
            _c("div", { staticClass: "modal-print-row" }, [
              _c("div", { staticClass: "modal-print-left" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.page,
                      expression: "page",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { maxlength: "6", type: "number" },
                  domProps: { value: _vm.page },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.page = _vm._n($event.target.value)
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" /" + _vm._s(_vm.numPages) + " "),
              ]),
              _c("div", { staticClass: "modal-print-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-focus loader",
                    on: {
                      click: function ($event) {
                        _vm.rotate += 90
                      },
                    },
                  },
                  [_vm._v("⟳")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-focus rotate",
                    on: {
                      click: function ($event) {
                        _vm.rotate -= 90
                      },
                    },
                  },
                  [_vm._v("Rotate")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.pdf.print()
                      },
                    },
                  },
                  [_vm._v("Print")]
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide("pdfModal")
                  },
                },
              },
              [_c("em", { staticClass: "icon-close" })]
            ),
          ]),
          _c(
            "div",
            { staticStyle: { width: "100%", "align-items": "center" } },
            [
              _vm.loadedRatio > 0 && _vm.loadedRatio < 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "green",
                        color: "white",
                        "text-align": "center",
                      },
                      style: { width: _vm.loadedRatio * 100 + "%" },
                    },
                    [_vm._v(_vm._s(Math.floor(_vm.loadedRatio * 100)) + "%")]
                  )
                : _vm._e(),
              _c("PDFViewer", {
                staticStyle: { display: "inline-block", width: "100%" },
                attrs: { source: _vm.src },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }