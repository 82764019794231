import Vue from 'vue'
import Router from 'vue-router'
import World from '../views/World'
import LearningModules from '../views/LearningModules'
import Organisation from '../views/Organisation'
import WorldDetail from '../views/WorldDetail'
import Characters from '../views/Characters'
import CharacterRoles from '../views/CharacterRoles'
import ContentDocument from '../views/ContentDocument'
import ContentImages from '../views/ContentImages'
import ContentVideos from '../views/ContentVideos'
import CreateELM from '../views/CreateELM'
import ELMBuild from '../views/ELMBuild'
import Login from '../views/Login'
import auth from './middleware/auth'
import isLoggedin from './middleware/isLoggedIn'
import middlewarePipeline from './middlewarePipeline'
import layout from '../components/Layout.vue'
import Assessment from '../views/Assessment.vue'
import DashboardContent from '../views/DashboardContent.vue'
import Users from '../views/Users'
import LearnerCentral from '../views/LearnerCentral'
import QaEmailList from '../views/QaEmailList'
import WatsonAdmin from '../views/WatsonAdmin'
import LlmPrompts from '../views/LlmPrompts'
import LlmSkills from '../views/LlmSkills'
import CreateLlmSkill from '../views/CreateLlmSkill'
import CreateLlmSkillSet from '../views/CreateLlmSkillSet'
import LlmSkillSets from '../views/LlmSkillSets'
import QaEmail from '../views/QaEmail'
import LearnerLO from '../views/LearnerLO'
import LearnerLOList from '../views/LearnerLOList'
import AssistantDetails from '../views/AssistantDetails'
import Customers from '../views/Customers'
import Courses from '../views/Courses'
import Provision from '../views/Provision'

Vue.use(Router)

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                middleware: [
                    isLoggedin
                ]
            },
            alias: '/'

        },
        {
            path: "/learner_central",
            name: "learner-central",
            component: LearnerCentral,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/worlds",
            name: "worlds",
            component: World,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/learning-modules",
            name: "learning-modules",
            component: LearningModules,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/assessment",
            name: "assessment",
            component: Assessment,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/organisations",
            name: "organisation",
            component: Organisation,
            meta: {
                layout: layout,
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "/world-detail/:id",
            name: "world-detail",
            component: WorldDetail,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/characters",
            name: "characters",
            component: Characters,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/character-roles",
            name: "character-roles",
            component: CharacterRoles,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/users",
            name: "users",
            component: Users,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },

        {
            path: "/content-document",
            name: "content-document",
            component: ContentDocument,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/content-images",
            name: "content-images",
            component: ContentImages,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/content-videos",
            name: "content-videos",
            component: ContentVideos,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/dashboard-content",
            name: "dashboard-content",
            component: DashboardContent,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/qa-emails",
            name: "qa-emails",
            component: QaEmailList,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/watson-admin",
            name: "watson-admin",
            component: WatsonAdmin,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/llm-prompts",
            name: "llm-prompts",
            component: LlmPrompts,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/llm-skills",
            name: "llm-skills",
            component: LlmSkills,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/create-llm-skill",
            name: "create-llm-skill",
            component: CreateLlmSkill,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/edit-llm-skill/:id",
            name: "edit-llm-skill",
            component: CreateLlmSkill,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/llm-skill-sets",
            name: "llm-skill-sets",
            component: LlmSkillSets,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/create-llm-skill-set",
            name: "create-llm-skill-set",
            component: CreateLlmSkillSet,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/edit-llm-skill-set/:id",
            name: "edit-llm-skill-set",
            component: CreateLlmSkillSet,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/qa-email/:id",
            name: "qa-email",
            component: QaEmail,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/learner-lo-list/:id",
            name: "learner-lo-list",
            component: LearnerLOList,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/learner-lo/:id",
            name: "learner-lo",
            component: LearnerLO,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/create-elm/:id?",
            name: "createELM",
            component: CreateELM,
            meta: {
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "/assistant-details/:id?",
            name: "assistantDetails",
            component: AssistantDetails,
            meta: {
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "/edit-elm/:id",
            name: "edit-elm",
            component: ELMBuild,
            props: (route) => ({ id: route.params.id, loId: route.params.id }),
            meta: {
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "/customers",
            name: "customers",
            component: Customers,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/courses",
            name: "courses",
            component: Courses,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        },
        {
            path: "/provision",
            name: "provision",
            component: Provision,
            meta: {
                middleware: [
                    auth
                ],
                layout: layout
            }
        }
    ],
    linkActiveClass: "active"
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next
    }


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

})

export default router
