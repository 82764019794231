var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "characterdetail",
        width: 430,
        height: "auto",
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content modal-edit-character" }, [
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "modal-edit-image" }, [
            _vm.photo_url
              ? _c("img", {
                  attrs: { src: _vm.photo_url.large_version, alt: "image" },
                })
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide("characterdetail")
                  },
                },
              },
              [_c("em", { staticClass: "icon-close" })]
            ),
          ]),
          _c("div", { staticClass: "modal-edit-title" }, [
            _vm._v(_vm._s(_vm.full_name)),
          ]),
          _c("div", { staticClass: "modal-edit-text" }, [
            _vm._v(
              _vm._s(_vm._f("uppercase")(_vm.gender, true)) +
                ", " +
                _vm._s(_vm.age)
            ),
          ]),
          _c("p", [_vm._v("Created on " + _vm._s(_vm.created_on))]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c("div", { staticClass: "data-delete-dropdown" }, [
            _c(
              "button",
              {
                staticClass: "btn danger dropdown-toggle",
                attrs: {
                  type: "button",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                },
              },
              [_vm._v(" Delete ")]
            ),
            _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
              _c("div", { staticClass: "data-popper-title" }, [
                _vm._v(" Are you sure you want to delete this character? "),
              ]),
              _c("div", { staticClass: "data-popper-footer" }, [
                _c("a", { staticClass: "btn default" }, [_vm._v("cancel")]),
                _c(
                  "a",
                  {
                    staticClass: "btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.deleteCharacter(_vm.character_id)
                      },
                    },
                  },
                  [_vm._v("Ok")]
                ),
              ]),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.show("editCharacter", {
                    character_id: _vm.character_id,
                  })
                },
              },
            },
            [_vm._v("Edit Character")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }