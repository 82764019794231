<template>
  <modal
    name="resetLO"
    height="auto"
    :width="600"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="hide-modal" @click="$modal.hide('resetLO')"></div>
    <div class="modal-content modal-default">
      <div class="modal-header">
        <div class="modal-title" id="exampleModalLabel">
          <h5>Reset Learning Object</h5>
        </div>
      </div>
      <div class="modal-body question-modal-body">
        <div class="row">
          <div class="col">
            <h6>Number of Resets:</h6>
          </div>
          <div class="col text-right">
            <h6>
              {{ this.resetCount }}
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6>Last Reset Date:</h6>
          </div>
          <div class="col text-right">
            <h6>
              {{ this.resetDate }}
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6>Reset by:</h6>
          </div>
          <div class="col text-right">
            <h6>{{ this.resetUser }}</h6>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col text-center mb-3">
            <h6>This cannot be undone. Are you sure you want to reset?</h6>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-primary" @click="resetLO">Yes</button>
          </div>
          <div class="col text-right">
            <button class="btn btn-secondary" @click="$modal.hide('resetLO')">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import moment from "moment-timezone";

export default {
  name: "ResetLO",
  data() {
    return {
      selectedLearnData: {},
      resetDate: "N/A",
      resetCount: 0,
      resetUser: "N/A",
      userData: {}
    };
  },
  methods: {
    beforeOpen(event) {
      this.selectedLearnData = event.params.selectedLearnData;
      this.userData = event.params.userData;
      if (this.selectedLearnData.attributes.user_learn_obj_reset.length) {
        this.resetCount =
          this.selectedLearnData.attributes.user_learn_obj_reset.length;
        this.resetDate =
          moment(
            this.selectedLearnData.attributes.user_learn_obj_reset[
              this.selectedLearnData.attributes.user_learn_obj_reset.length - 1
            ].reset_date
          ).format("MMMM Do YYYY, h:mm:ss a");
        this.resetUser = 
          this.selectedLearnData.attributes.user_learn_obj_reset[
            this.selectedLearnData.attributes.user_learn_obj_reset.length - 1
          ].name
      } else {
        this.resetCount = 0;
        this.resetDate = "N/A";
        this.resetUser = "N/A"
      }
    },
    beforeClose() {
      (this.selectedLearnData = {}), (this.resetCount = 0);
      this.resetDate = "N/A";
      this.resetUser = "N/A";
    },
    resetLO() {
      const cardType =
        this.selectedLearnData.attributes.learning_object.attributes.card_type;
      let loID;
      console.log(cardType);
      if (cardType === "dialogic") {
        loID = this.selectedLearnData.attributes.current_evaluation_id;
        api
          .resetDialogic(loID)
          .then(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showSuccess("Dialogic Reset was Successful");
            location.reload();
          })
          .catch(() => {
            utilFunctionService.showErr("Dialogic Reset was unsuccessful. Please reach out to Ametros Learning for support.");
          });
      } else if (cardType === "submission_email") {
        loID = this.selectedLearnData.id;
        api
          .resetEmailSubmission(loID)
          .then(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showSuccess(
              "Submission Email Reset was Successful"
            );
            location.reload();
          })
          .catch(() => {
            utilFunctionService.showErr("Submission Email Reset was unsuccessful. Please reach out to Ametros Learning for support.");
          });
      } else if (cardType === "submission") {
        loID = this.selectedLearnData.id;
        api
          .resetSubmission(loID)
          .then(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showSuccess(
              "Submission Grading Reset was Successful"
            );
            location.reload();
          })
          .catch(() => {
            utilFunctionService.showErr("Submission Grading Reset was unsuccessful. Please reach out to Ametros Learning for support.");
          });
      } else if (cardType === "email") {
        loID = this.selectedLearnData.attributes.current_evaluation_id;
        api
          .resetEmail(loID)
          .then(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showSuccess(
              "Email Reset was Successful"
            );
            location.reload();
          })
          .catch(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showErr("Email Reset was unsuccessful. Please reach out to Ametros Learning for support.");
          });
      } else if (cardType === "quiz") {
        loID = this.selectedLearnData.attributes.current_evaluation_id;
        api
          .resetQuiz(loID)
          .then(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showSuccess(
              "Quiz Reset was Successful. The page will refresh in 5 seconds."
            );
            location.reload();
          })
          .catch(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showErr("Quiz Reset was unsuccessful. Please reach out to Ametros Learning for support.");
          });
      } else if (cardType === "coding") {
        loID = this.selectedLearnData.attributes.current_evaluation_id;
        api
          .resetCode(loID)
          .then(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showSuccess(
              "Coding Evaluation Reset was Successful. The page will refresh in 5 seconds."
            );
            location.reload();
          })
          .catch(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showErr("Coding Evaluation Reset was unsuccessful. Please reach out to Ametros Learning for support.");
          });
      } else if (cardType === "llm_chat" || cardType === "llm_dialogic" || cardType === "llm_email") {
        loID = this.selectedLearnData.id;
        console.log(loID, "loID")
        let payload = {
          student_id: this.userData.attributes.user_id,
          lo_id: this.selectedLearnData.attributes.learning_object_id,
          user_learn_obj_id: this.selectedLearnData.id,
          id: this.selectedLearnData.id
        };
        api
          .resetLlmLo(payload)
          .then(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showSuccess(
              `LLM Reset was Successful. The page will refresh in 5 seconds.`
            );
            location.reload();
          })
          .catch(() => {
            this.$modal.hide("resetLO");
            utilFunctionService.showErr("LLM Chat Reset was unsuccessful. Please reach out to Ametros Learning for support.");
          });
      } else {
        this.$modal.hide("resetLO");
        utilFunctionService.showErr("An error has occurred. Please reach out to Ametros Learning for support.");
      }
    },
  },
};
</script>
