<template>
  <modal
    name="createOrganisation"
    height="auto"
    :width="500"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title" id="exampleModalLabel">
          <h5 v-if="organisation_id">Edit Organization</h5>
          <h5 v-else>Create an Organization</h5>
        </div>
        <button type="button" class="close" @click="hide('createOrganisation')">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <ValidationObserver ref="form">
          <form class="modal-form organisation-form">
            <div class="organisation-image-group">
              <div class="organisation-image-pic cursor"  @click="$refs.fileInput.click()">
                <img :src="image" v-if="image" alt="images"/>
              </div>
              <div class="organisation-image-remove">
                <input style="display: none;" ref="fileInput" type="file" @change="onFileSelect" accept="image/*"/>
                <a class="btn add" @click="$refs.fileInput.click()" v-if="!image">Add Photo</a>
                <a class="btn delete" @click="imageRemoved" v-else>Remove Photo</a>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="exampleInputEmail1">Name</label>
              <ValidationProvider name="Name" rules="required|max:100" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  v-model="name"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label" for="exampleInputEmail1">Description</label>
              <textarea class="form-control" rows="4" v-model="description"></textarea>
            </div>
            <div class="form-group">
              <label class="form-label" for="exampleInputEmail1">Industry Type</label>
              <ValidationProvider name="Industry" rules="required|max:500" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchIndustry"
                  v-on:input="getIndustry"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>

              <div v-if="industryLists.length">
                <div class="dropdown-menu" :class="[industryLists.length !==0 ? 'show' : '']">
                  <ul class="dropdown-list">
                    <li class="dropdown-items" v-for="industry in industryLists" :key="industry.id">
                      <div class="dropdown-content">
                        <h4>{{industry.attributes.name}}</h4>
                      </div>
                      <div class="dropdown-action">
                        <a
                          class="add-action"
                          @click="setIndustry(industry.id,industry.attributes.name)"
                        >+</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="exampleInputEmail1">Add a Character</label>
              <input
                type="text"
                class="form-control"
                v-model="searchCharacters"
                v-on:input="getCharacters"
              />
              <div v-if="charactersList.length">
                <div class="dropdown-menu" :class="[charactersList.length !== 0 ? 'show' : '']">
                  <ul class="dropdown-list">
                    <li
                      class="dropdown-items"
                      v-for="characters in charactersList"
                      :key="characters.id"
                    >
                      <div class="dropdown-content">
                        <h4>{{characters.attributes.full_name}}</h4>
                      </div>
                      <div class="dropdown-action" v-if="!characters.status">
                        <a @click="addCharacters(characters)" class="add-action">+</a>
                      </div>
                      <div class="dropdown-action" v-if="characters.status">
                        <em class="icon-check"></em>
                        <span>Added</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="customer-dropdown" v-if="addedCharacters.length">
              <ul class="dropdown-list mt-0 mb-4">
                <li
                  class="dropdown-items"
                  v-for="(characters, index) in addedCharacters"
                  :key="characters.id"
                >
                  <div class="dropdown-pic">
                    <img
                      :src="characters.attributes.photo_url.thumbnail"
                      alt="image2"
                      v-if="characters.attributes.photo_url.thumbnail"
                    />
                  </div>
                  <div class="dropdown-content">
                    <div class="user-name">{{characters.attributes.full_name}}</div>
                    <div
                      class="user-age"
                    >{{characters.attributes.age}}, {{characters.attributes.gender | toUpperCase(true)}}</div>
                    <a class="btn delete" @click="removeCharcter(index)">Remove</a>
                  </div>
                  <div class="dropdown-form">
                    <div class="form-group m-0">
                      <ValidationProvider
                        name="searchIndustry"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="characters.attributes.roleType"
                          v-on:input="getRole(characters.attributes.roleType,index)"
                          class="form-control"
                          placeholder="Enter Role"
                          :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >Role is required</span>
                      </ValidationProvider>
                      <div v-if="roles.length && showRole == index">
                        <div
                          class="dropdown-menu assign-role-menu"
                          :class="[roles.length !== 0 ? 'show' : '']"
                        >
                          <ul class="dropdown-list">
                            <li class="dropdown-items" v-for="role in roles" :key="role.id">
                              <div class="dropdown-content">
                                <h4>{{role.attributes.name}}</h4>
                              </div>
                              <div class="dropdown-action">
                                <a
                                  class="add-action"
                                  @click="addRole(index, role.id, role.attributes.name)"
                                >+</a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn default"
          data-dismiss="modal"
          @click="hide('createOrganisation')"
        >Cancel</button>
        <button type="button" class="btn primary" @click="saveOrg" data-dismiss="modal">Save</button>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "createOrganisation",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      selectedFile: null,
      image: null,
      name: "",
      description: "",
      industry: "",
      industryId: "",
      industryLists: [],
      searchIndustry: "",
      searchCharacters: "",
      charactersList: [],
      addedCharacters: [],
      removeCharacters: [],
      searchRole: "",
      characters: [],
      roles: [],
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      organisation_id: "",
      showRole: ""
    };
  },
  filters: {
    toUpperCase : function(val, character) {
      if (!val) {
        return "";
      }
      val = val.toString();
      if (character) {
        return val.charAt(0).toUpperCase() + val.slice(1);
      } else {
        return val.toUpperCase();
      }
    }
  },
  methods: {
    getIndustry() {
      if (this.searchIndustry.length === 0) {
        this.industryLists = [];
      } else {
        api
          .getIndustry(this.searchIndustry)
          .then(res => {
            this.industryLists = res.data.data;
          })
          .catch();
      }
    },
    setIndustry(industryId, industry) {
      this.industry = industry;
      this.industryId = industry;
      this.searchIndustry = industry;
      this.industryLists = [];
    },
    getCharacters() {
      const page = 1;
      if (this.searchCharacters.length === 0) {
        this.charactersList = [];
      } else {
        api
          .getAllCharacters(page, this.searchCharacters)
          .then(res => {
            this.charactersList = res.data.data;
            if (this.addedCharacters.length > 0) {
              this.addedCharacters.forEach(element => {
                const index = this.charactersList.findIndex(
                  (elem) => {
                   return parseInt(elem.id) === parseInt(element.id)
                  }
                );
                if (index !== -1) {
                  this.charactersList[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    imageRemoved: function() {
      if (this.organisation_id)
        api.removeOrganizationPhoto(this.organisation_id);
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
      this.selectedFile = null;
      this.image = null;
    },
    saveOrg() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          if (this.selectedFile)
          fb.append("organization[photo]", this.selectedFile);
          fb.append("organization[name]", this.name);
          fb.append("organization[description]", this.description);
          fb.append(
            "organization[industry_attributes][name]",
            this.searchIndustry
          );
          var key = 0;
          this.addedCharacters.map((x) => {
            if (!x.attributes.word_id) {
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][character_id]",
                parseInt(x.id)
              );
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][world_role_id]",
                parseInt(x.attributes.roleId)
              );
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][_destroy]",
                false
              );
              key++;
            } else {
               fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][id]",
                parseInt(x.attributes.editId)
              );
               fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][character_id]",
                parseInt(x.id)
              );
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][world_role_id]",
                parseInt(x.attributes.roleId)
              );
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][_destroy]",
                false
              );
              key++;
            }
          });
          this.removeCharacters.map((x) => {
            if (x.attributes.word_id) {
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][character_id]",
                parseInt(x.id)
              );
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][world_role_id]",
                parseInt(x.attributes.roleId)
              );
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][_destroy]",
                true
              );
              fb.append(
                "organization[organization_characters_attributes][" +
                  key +
                  "][id]",
                x.attributes.word_id
              );
              key++;
            }
          });
          if (this.organisation_id) {
            fb.append("id", this.organisation_id);
            api.editOrganisation(this.organisation_id, fb).then(() => {
              utilFunctionService.showSuccess(commonConfig.appMessages.editOrg);
              this.hide("createOrganisation");
            });
          } else {
            api.saveOrganisation(fb).then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.orgCreate
              );
              this.hide("createOrganisation");
            });
          }
        }
      });
    },
    clearForm() {
      this.selectedFile = null;
      this.image = null;
      this.name = "";
      this.description = "";
      this.industryId = "";
      this.industryLists = [];
      this.searchIndustry = [];
      this.searchCharacters = "";
      this.charactersList = [];
      this.addedCharacters = [];
      this.removeCharacters = [];
      this.characters = [];
      this.roles = [];
      this.organisation_id = "";
      this.searchRole = "";
    },
    hide() {
      this.clearForm();
      this.$parent.hide("createOrganisation");
    },
    addCharacters(e) {
      this.charactersList = [];
      this.addedCharacters.push(e);
    },
    removeCharcter(e) {
      if (this.addedCharacters[e].id) {
        this.removeCharacters.push(this.addedCharacters[e]);
      }
      this.addedCharacters.splice(e, 1);
    },
    getRole(role, index) {
      this.showRole = index;
      if (role.length === 0) {
        this.roles = [];
      } else {
        api
          .getRoles(role)
          .then(res => {
            this.roles = res.data.data;
          })
          .catch();
      }
    },
    addRole(i, e, role) {
      this.addedCharacters[i].attributes.roleId = e;
      this.addedCharacters[i].attributes.roleType = role;
      this.roles = [];
    },
    beforeOpen(event) {
      this.clearForm();
      this.organisation_id = event.params.organisation_id;
      this.getOrganisationDetails(this.organisation_id);
    },
    getOrganisationDetails(organisation_id) {
      api
        .getOrganisationDetails(organisation_id)
        .then(res => {
          this.name = res.data.data.attributes.name;
          this.description = res.data.data.attributes.description;
          this.searchIndustry = res.data.data.attributes.industry_name;
          this.image = res.data.data.attributes.photo_url.thumbnail
            ? res.data.data.attributes.photo_url.thumbnail
            : "";
          let characters = [];
          if (res.data.data.attributes.organization_characters) {
            characters = res.data.data.attributes.organization_characters.map(
              y => {
                y.attributes.characters.attributes.editId = y.id;
                y.attributes.characters.attributes.roleId =
                  y.attributes.world_role.id;
                y.attributes.characters.attributes.roleType =
                  y.attributes.world_role.attributes.name;
                y.attributes.characters.attributes.word_id = y.id;
                y.attributes.characters.attributes._destroy = false;
                return y.attributes.characters;
              }
            );
          }
          this.addedCharacters = characters;
        })
        .catch();
    },
    onFileSelect(event) {
      this.selectedFile = event.target.files[0];
      let reader = null;
      reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = e => {
        this.image = e.target.result;
      };
    }
  }
};
</script>
