<template>
  <div class="qa-sidebar">
    <div class="iteration-items">
      <div class="iteration-detail-title">Administrative Notes</div>
      <div class="detail-content my-3">{{ iterationDetails.note }}</div>
      <div class="iteration-detail-title">ELM</div>
      <div class="detail-content my-3">
        {{ iterationDetails.learn_mod_name }}
      </div>
      <div class="iteration-detail-title">Learning Object</div>
      <div class="detail-content my-3">
        {{ iterationDetails.learning_object_name }}
      </div>
      <div class="iteration-detail-title">Learner</div>
      <div class="detail-content my-3">{{ iterationDetails.learner_name }}</div>
      <div class="iteration-detail-title">Iterations enabled</div>
      <div class="detail-content my-3">
        {{ iterationDetails.iterations_enabled }}
      </div>
      <div class="iteration-detail-title" v-if="!iterationDetails.qa_resolved">
        Iteration requiring QA
      </div>
      <div class="iteration-detail-title" v-if="iterationDetails.qa_resolved">
        Iteration resolved
      </div>
      <div class="detail-content my-3">
        iteration - {{ iterationDetails.iteration }}
      </div>
      <div class="iteration-detail-title margin-title">LO QA conditions</div>
      <div v-if="charQaConditions.length > 0">
        <div
          class="row custom-row"
          v-for="(qaCondition, i) in charQaConditions"
          :key="i"
        >
          <div
            class="tab-content col-sm-12"
            v-if="qaCondition.responseFormula.length > 0"
          >
            <div class="detail-content mt-2">
              {{ qaCondition.character }}'s QA Condition Formula(s)
            </div>
            <ul class="formulas-data-list">
              <li v-for="(res, i) of qaCondition.responseFormula" :key="i">
                <div class="detail-content mt-2 formulas-left">
                  Formula {{ i + 1 }} (ID: {{ res.ques_id }})
                </div>
                <div class="formulas-list" v-if="res.present">
                  <div class="formulas-list-title">
                    <div class="detail-content">
                      Present:
                      <span class="keywords"
                        >Keyword: at-least {{ res.present_min }}</span
                      >
                    </div>
                  </div>
                  <div class="formulas-list-item" v-if="res.present">
                    <div
                      class="formula-item-flex email-formula-item"
                      v-for="(formula, key, index) in res.present"
                      :key="index"
                    >
                      <div class="formula-name">
                        {{ key }}:
                        <span v-for="entity in formula" :key="entity.id"
                          >{{ entity.value }} ,</span
                        >
                      </div>
                      <span
                        class="formula-and"
                        v-if="Object.keys(res.present).length - 1 !== index"
                        >and</span
                      >
                    </div>
                  </div>
                </div>
                <!-- formulas preset end  -->
                <!-- formulas absent start  -->
                <div class="formulas-list" v-if="res.absent">
                  <div class="formulas-list-title">
                    <div class="detail-content">
                      Absent:
                      <span class="keywords"
                        >Keyword: at-least {{ res.absent_min }}</span
                      >
                    </div>
                  </div>
                  <div class="formulas-list-item">
                    <div
                      class="formula-item-flex email-formula-item"
                      v-for="(formula, key, index) in res.absent"
                      :key="index"
                    >
                      <div class="formula-name">
                        {{ key }}:
                        <span v-for="entity in formula" :key="entity.id"
                          >{{ entity.value }} ,</span
                        >
                      </div>
                      <span
                        class="formula-and"
                        v-if="Object.keys(res.absent).length - 1 !== index"
                        >and</span
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="lo-entities-title">LO Entities</div>
      <div class="row entity-main-row">
        <div
          class="row entity-row"
          v-for="(details, i) in iterationDetails.asst_entities"
          :key="i"
        >
          <div
            class="tab-content col-sm-12"
            v-if="iterationDetails.asst_entities.length > 0"
          >
            <ul class="formulas-data-list">
              <li>
                <div class="formulas-list">
                  <div class="formulas-list-title">
                    <div class="detail-content">
                      {{ details.attributes.name }}
                      <li class="sticky-note">
                        <a
                          class="btn dropdown-toggle"
                          id="menu1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            v-if="details.attributes.note"
                            alt="images"
                            class="sticky-image"
                            src="../assets/images/sticky-yellow.png"
                          />
                          <img
                            v-else
                            alt="images"
                            class="sticky-image"
                            src="../assets/images/sticky-white.png"
                          />
                        </a>
                        <div
                          class="
                            dropdown-menu
                            data-popover-dropdown
                            dropdown-menu-right
                          "
                          aria-labelledby="menu1"
                        >
                          <div class="data-popover">
                            <div class="data-popover-title">
                              <span>Add Note</span>
                              <a class="data-popover-close">
                                <em class="icon-close"></em>
                              </a>
                            </div>
                            <form class="data-popover-form">
                              <div class="form-group">
                                <textarea
                                  class="form-control small-textarea-height"
                                  rows="4"
                                  v-model="details.attributes.note"
                                ></textarea>
                              </div>
                              <div class="form-group-button">
                                <a
                                  class="btn btn-remove"
                                  @click="deleteNoteInEntities(i, details.id)"
                                  >Remove Note</a
                                >
                                <a
                                  class="btn btn-save"
                                  @click="
                                    saveNoteInEntities(
                                      i,
                                      details.id,
                                      details.attributes.note
                                    )
                                  "
                                  >Save</a
                                >
                              </div>
                            </form>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                  <div class="formulas-list-item">
                    <div class="formula-item-flex email-formula-item">
                      <div class="name-formula">
                        <span
                          class="sticky-wrapper"
                          v-for="(detail, j) in details.attributes
                            .asst_entity_values"
                          :key="j"
                        >
                          <drag
                            class="drag"
                            :transfer-data="{
                              ententName: details.attributes.name,
                              entityId: detail.id,
                              ententValueName: detail.attributes.value,
                              type: 'entent',
                            }"
                          >
                            <img
                              alt="images"
                              class="nav-tab-image"
                              src="../assets/images/tag-icon.svg"
                            />
                            {{ detail.attributes.value }}
                            <button
                              @click="showModal(detail)"
                              class="entities-plus-button"
                              style="background-color: black"
                            >
                              <img
                                alt="images"
                                class="plus-image"
                                src="../assets/images/entities-plus-icon.svg"
                              />
                            </button>
                          </drag>
                          <li class="sticky-note">
                            <a
                              class="btn dropdown-toggle"
                              id="menu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                v-if="detail.attributes.note"
                                alt="images"
                                class="sticky-image"
                                src="../assets/images/sticky-yellow.png"
                              />
                              <img
                                v-else
                                alt="images"
                                class="sticky-image"
                                src="../assets/images/sticky-white.png"
                              />
                            </a>
                            <div
                              class="
                                dropdown-menu
                                data-popover-dropdown
                                dropdown-menu-right
                              "
                              aria-labelledby="menu1"
                            >
                              <div class="data-popover">
                                <div class="data-popover-title">
                                  <span>Add Note</span>
                                  <a class="data-popover-close">
                                    <em class="icon-close"></em>
                                  </a>
                                </div>
                                <form class="data-popover-form">
                                  <div class="form-group">
                                    <textarea
                                      class="form-control small-textarea-height"
                                      rows="4"
                                      v-model="detail.attributes.note"
                                    ></textarea>
                                  </div>
                                  <div class="form-group-button">
                                    <a
                                      class="btn btn-remove"
                                      @click="removeEntentNote(i, j, detail.id)"
                                      >Remove Note</a
                                    >
                                    <a
                                      class="btn btn-save"
                                      @click="
                                        saveNoteInValues(
                                          i,
                                          j,
                                          detail.id,
                                          detail.attributes.note
                                        )
                                      "
                                      >Save</a
                                    >
                                  </div>
                                </form>
                              </div>
                            </div>
                          </li>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="lo-entities-title">LO Intents</div>
      <div class="entity-main-row">
        <div
          class="row entity-row"
          v-for="(intentDetails, j) in iterationDetails.asst_intents"
          :key="j"
        >
          <div
            class="tab-content col-sm-12"
            v-if="iterationDetails.asst_intents.length > 0"
          >
            <ul class="formulas-data-list" id="lo-intents-wrapper">
              <li>
                <div class="formulas-list">
                  <div class="formulas-list-title">
                    <div class="detail-content">
                      <drag
                        class="drag"
                        :transfer-data="{
                          intentName: intentDetails.attributes.name,
                          intentId: intentDetails.id,
                          type: 'intent',
                        }"
                      >
                        <img
                          alt="images"
                          class="nav-tab-image"
                          src="../assets/images/tag-icon.svg"
                        />
                        {{ intentDetails.attributes.name }}
                      </drag>
                    </div>
                  </div>
                </div>
              </li>
              <li class="sticky-note">
                <a
                  class="btn dropdown-toggle"
                  id="menu1"
                  :class="[
                    intentDetails.attributes.description ? 'active' : '',
                  ]"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    v-if="intentDetails.attributes.note"
                    alt="images"
                    class="sticky-image"
                    src="../assets/images/sticky-yellow.png"
                  />
                  <img
                    v-else
                    alt="images"
                    class="sticky-image"
                    src="../assets/images/sticky-white.png"
                  />
                </a>
                <div
                  class="
                    dropdown-menu
                    data-popover-dropdown
                    dropdown-menu-right
                  "
                  aria-labelledby="menu1"
                >
                  <div class="data-popover">
                    <div class="data-popover-title">
                      <span>Add Note</span>
                      <a class="data-popover-close">
                        <em class="icon-close"></em>
                      </a>
                    </div>
                    <form class="data-popover-form">
                      <div class="form-group">
                        <textarea
                          class="form-control small-textarea-height"
                          rows="4"
                          v-model="intentDetails.attributes.note"
                        ></textarea>
                      </div>
                      <div class="form-group-button">
                        <a
                          class="btn btn-remove"
                          @click="
                            removeIntentNote(
                              j,
                              intentDetails.id,
                              intentDetails.attributes.note,
                              intentDetails.attributes.name
                            )
                          "
                          v-if="intentDetails.attributes.note"
                          >Remove Note</a
                        >
                        <a
                          class="btn btn-save"
                          @click="
                            addIntentNote(
                              j,
                              intentDetails.id,
                              intentDetails.attributes.note,
                              intentDetails.attributes.name
                            )
                          "
                          >Save</a
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <modal name="enityValueModal" height="auto" :width="500" :scrollable="true">
      <div
        class="modal-content modal-default"
        v-if="selectedSynonymList && selectedSynonymList.attributes"
      >
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Entity value:{{ selectedSynonymList.attributes.value }}</h5>
          </div>
        </div>
        <div class="modal-body">
          <form
            class="modal-form modal-min-height"
            v-on:submit.prevent="updateSynonymValues('add')"
          >
            <div class="form-group mt-2">
              <label>Synonyms</label>
              <input
                type="text"
                class="form-control"
                v-model="entitySynonymName"
              />
              <div class="chip-container entity-chip">
                <div
                  class="chip"
                  v-for="synonym in selectedSynonymList.attributes
                    .asst_entity_val_synonyms"
                  :key="synonym.id"
                >
                  <span>{{ synonym.attributes.synonym }}</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                @click="$modal.hide('enityValueModal')"
                data-dismiss="modal"
                class="btn default"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn primary"
                @click="updateSynonymValues('done')"
              >
                Done
              </button>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import api from "../services/api";
import { Drag } from "vue-drag-drop";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "EmailQaSidebar",
  props: ["iterationDetails"],
  components: {
    Drag,
  },
  data() {
    return {
      selPresentResFormula: [],
      charQaConditions: [],
      asstName: "",
      asstSynonyms: "",
      selectedAsstEntityid: "",
      isActive: false,
      entitySynonymName: "",
      selectedSynonymList: {},
      iterationDetailsData: ""
    };
  },
  created() {
    this.iterationDetailsData = this.iterationDetails;
  },
  methods: {
    updateSynonymValues(status) {
      if (status === "add") {
        const index =
          this.selectedSynonymList.attributes.asst_entity_val_synonyms.findIndex(
            (elem) =>
              elem.attributes.synonym.toUpperCase() ===
              this.entitySynonymName.toUpperCase()
          );
        if (index === -1) {
          this.selectedSynonymList.attributes.asst_entity_val_synonyms.push({
            attributes: {
              synonym: this.entitySynonymName,
              asst_entity_value_id: null,
            },
          });
          this.entitySynonymName = "";
        } else {
          utilFunctionService.showErr(
            commonConfig.appMessages.sameSynonymNameError
          );
        }
      }

      if (status === "done") {
        let data = {
          asst_entity_value: {
            asst_entity_val_synonyms_attributes: [],
            value: this.selectedSynonymList.attributes.value,
          },
        };
        this.selectedSynonymList.attributes.asst_entity_val_synonyms.forEach(
          (ele) => {
            if (!ele.attributes.asst_entity_value_id) {
              data.asst_entity_value.asst_entity_val_synonyms_attributes.push({
                synonym: ele.attributes.synonym,
              });
            }
          }
        );

        api
          .updateAssessmentValues(this.selectedSynonymList.id, data)
          .then(() => {
            this.selectedSynonymList = "";
            data = {};
            this.$parent.userEmailIterationDetails();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.assestEntityUpdated
            );
          })
          .catch((error) => {
            if (error.response.status == 422) {
              if (error.response.data && error.response.data.error) {
                utilFunctionService.showErr(error.response.data.error);
              }
            }
            utilFunctionService.hideLoader();
          });
        this.$modal.hide("enityValueModal");
      }
    },
    showModal(value) {
      this.selectedSynonymList = {};
      this.selectedSynonymList = value;
      this.$modal.show("enityValueModal");
    },
    saveNoteInEntities(index, id, note) {
      const formData = new FormData();
      formData.append("note", note);
      api.addNoteInEntent(id, formData).then((res) => {
        this.iterationDetailsData.asst_entities[index].attributes.note =
          res.data.data.attributes.note;
      });
    },
    deleteNoteInEntities(index, id) {
      const formData = new FormData();
      formData.append("asst_entity[note]", "");
      api.addNoteInEntent(id, formData).then(() => {
        this.iterationDetailsData.asst_entities[index].attributes.note = "";
      });
    },
    saveNoteInValues(index, j, id, note) {
      const formData = new FormData();
      formData.append("note", note);
      api.addNoteInEntentValues(id, formData).then((res) => {
        this.iterationDetailsData.asst_entities[
          index
        ].attributes.asst_entity_values[j].attributes.note =
          res.data.data.attributes.note;
      });
    },
    addIntentNote(index, id, note, name) {
      const formData = new FormData();
      formData.append("asst_intent[name]", name);
      formData.append("asst_intent[note]", note);
      api.addNoteInIntent(id, formData).then((res) => {
        this.iterationDetailsData.asst_intents[index].attributes.note =
          res.data.data.attributes.note;
      });
    },
    removeIntentNote(index, id, note, name) {
      const formData = new FormData();
      formData.append("asst_intent[name]", name);
      formData.append("asst_intent[note]", "");
      api.addNoteInIntent(id, formData).then(() => {
        this.iterationDetailsData.asst_intents[index].attributes.note = "";
      });
    },
    removeEntentNote(index, j, id) {
      const formData = new FormData();
      formData.append("asst_entity[note]", "");
      api.addNoteInEntentValues(id, formData).then(() => {
        this.iterationDetailsData.asst_entities[
          index
        ].attributes.asst_entity_values[j].attributes.note = "";
      });
    },
    getQaFormulas(qaConditionIds) {
      this.charQaConditions = [];
      qaConditionIds.forEach((e) => {
        api.getQaCondition(e).then((res) => {
          this.setQaResFormula(res.data.data.attributes);
        });
      });
    },
    setQaResFormula(attr) {
      let data = attr.qa_formulas;
      this.selPresentResFormula = [];
      data.forEach((e, index) => {
        if (!this.selPresentResFormula[index])
          this.selPresentResFormula[index] = {};
        this.selPresentResFormula[index]["ques_id"] = e.id;
        this.selPresentResFormula[index]["absent_min"] =
          e.attributes.absent_cond_keyword_min;
        this.selPresentResFormula[index]["present_min"] =
          e.attributes.present_cond_keyword_min;
        this.selPresentResFormula[index]["sentiment_enabled"] =
          e.attributes.sentiment_enabled;
        this.selPresentResFormula[index]["emotion_enabled"] =
          e.attributes.emotion_enabled;
        this.selPresentResFormula[index]["present"] = {};
        this.selPresentResFormula[index]["absent"] = {};
        e.attributes.qa_formula_nlu_entities &&
          this.setFormFieldValues(
            e.attributes.qa_formula_nlu_entities,
            index,
            "entity",
            "nlu_entity"
          );
        e.attributes.qa_formula_nlu_keywords &&
          this.setFormFieldValues(
            e.attributes.qa_formula_nlu_keywords,
            index,
            "keyword",
            "nlu_keyword"
          );
        e.attributes.qa_formula_nlu_concepts &&
          this.setFormFieldValues(
            e.attributes.qa_formula_nlu_concepts,
            index,
            "concept",
            "nlu_concept"
          );
        e.attributes.qa_formula_asst_intents &&
          this.setFormFieldValues(
            e.attributes.qa_formula_asst_intents,
            index,
            "intent",
            "asst_intent"
          );
        e.attributes.qa_formula_asst_entity_values &&
          this.setFormFieldValues(
            e.attributes.qa_formula_asst_entity_values,
            index,
            "Assistant-Entity",
            "asst_intent"
          );
        this.setFormFieldValues(
          e.attributes.qa_formula_emotions,
          index,
          "emotion",
          "",
          true
        );
        this.setFormFieldValues(
          e.attributes.qa_formula_sentiments,
          index,
          "sentiment",
          "",
          true
        );
      });
      let qaCondition = {
        character: attr.character.attributes.char_full_name,
        responseFormula: this.selPresentResFormula,
      };
      this.charQaConditions.push(qaCondition);
    },
    setFormFieldValues(data, index, name, key, status = false) {
      if (status) {
        if (!this.selPresentResFormula[index]["present"][name])
          this.selPresentResFormula[index]["present"][name] = [];
        this.selPresentResFormula[index]["present"][name][0] = {
          id: 0,
          name: name,
          value: data ? "enabled" : "disabled",
          isAdded: true,
          data,
        };
      } else {
        data.forEach((ele) => {
          if (!ele.attributes.present_cond) {
            if (!this.selPresentResFormula[index]["absent"][name])
              this.selPresentResFormula[index]["absent"][name] = [];
            this.selPresentResFormula[index]["absent"][name].push({
              id: ele.id,
              name: key,
              value: ele.attributes[key],
              isAdded: true,
              data,
            });
          } else {
            if (!this.selPresentResFormula[index]["present"][name])
              this.selPresentResFormula[index]["present"][name] = [];
            this.selPresentResFormula[index]["present"][name].push({
              id: ele.id,
              name: key,
              value: ele.attributes[key],
              isAdded: true,
              data,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.qa-sidebar {
  width: 220px;
  @include position(fixed, 0, 0, 0);
  min-height: 100%;
  padding: 20px 10px 0;
  background-color: $theme-color;
  &:after {
    content: "";
    display: block;
    @include position(fixed, 0, 0, 0);
    width: 220px;
    background-color: $theme-color;
  }
  @include media-breakpoint(xl) {
    width: 313px;
    padding: 30px 30px 10px;
    overflow: auto;
    &:after {
      width: 313px;
    }
  }

  .iteration-detail-title {
    @include font-bold;
    font-size: 18px;
    line-height: 1.18;
    color: $white;
    margin-top: 25px;
  }

  .detail-content {
    font-size: 14px;
    line-height: 1.18;
    color: $white;
  }

  .iteration-items {
    border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
    margin: 30px 0 0;
    padding: 0 0 33px;
    position: relative;
    z-index: 50;
    &:last-child {
      border: 0;
    }
    .lo-entities-title {
      @include font-bold;
      line-height: 1;
      color: #fff;
      font-size: 20px;
      margin-bottom: 28px;
    }
    .entity-main-row {
      width: 100%;
      margin: 0 0 30px;
      .entity-row {
        width: 100%;
        margin: 0;
        .tab-content {
          padding: 0;
          .formulas-data-list {
            display: flex;
            align-items: flex-start;
            .nav-image {
              width: 15px;
              height: auto;
              margin-right: 10px;
            }
            li {
              width: 100%;
              border: 0;
              padding: 0 0 20px;
              .formulas-list {
                margin: 0;
                .formulas-list-title {
                  padding: 0;
                  justify-content: flex-start;
                  .detail-content {
                    @include font-regular;
                    font-size: 16px;
                    line-height: 1;
                    margin-bottom: 7px;
                    .drag {
                      display: flex;
                      align-item: center;
                      background: #212121;
                      .nav-tab-image {
                        width: 5px;
                        height: auto;
                        margin-right: 5px;
                      }
                    }
                  }
                  button {
                    display: flex;
                    margin-top: 3px;
                    margin-left: 6px;
                    border: 0;
                    outline: 0;
                    background: transparent;
                    &:focus {
                      box-shadow: none;
                      border: 0;
                      outline: 0;
                    }
                    .plus-image {
                      width: 11px;
                      height: auto;
                      cursor: pointer;
                    }
                  }
                }
                .formulas-list-item {
                  .formula-item-flex {
                    .name-formula {
                      line-height: 1;
                      span {
                        @include font-regular;
                        font-size: 13px;
                        line-height: 1.23;
                        color: rgba(255, 255, 255, 0.5);
                      }
                    }
                  }
                  .email-formula-item {
                    display: flex;
                    width: 100%;
                    .name-formula {
                      display: flex;
                      flex-direction: column;
                      span {
                        .drag {
                          @include font-regular;
                          font-size: 13px;
                          line-height: 1.23;
                          color: white;
                          width: auto;
                          height: 22px;
                          padding: 3px 5px;
                          margin: 0 12px 12px 0;
                          background: #373737;
                          border-radius: 1px;
                          .nav-tab-image {
                            width: 5px;
                            height: auto;
                            margin-right: 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .sticky-note {
              width: auto;
              position: relative;
              margin-top: 6px;
              .sticky-image {
                width: 16px;
                height: auto;
                cursor: pointer;
              }
              .dropdown-menu {
                width: 292px;
                left: 0 !important;
                top: 12px !important;
                &:before {
                  content: "";
                  bottom: 100%;
                  left: 25%;
                  position: absolute;
                  margin-left: 30px;
                  border: 14px solid transparent;
                  border-bottom-color: #fff;
                }
              }
            }
          }
        }
      }
    }
    .margin-title {
      margin-bottom: 60px;
    }
  }
}
.sticky-wrapper {
  display: flex;
  align-items: center;
}
entities-plus-button {
  background: tranparent;
  border: 0;
  outline: 0;
  .plus-image {
    width: 11px;
    height: auto;
    cursor: pointer;
  }
}
entities-plus-button:focus {
  box-shadow: none;
  border: 0;
  outline: 0;
}
</style>
