<template>
  <div id="LearningModules">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All ELMs</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                v-model="searchElm"
                v-debounce:700ms="search"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Search"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <router-link class="btn header-btn primary" to="/create-elm">Add a new ELM</router-link>
           <div class="ml-2 btn header-btn primary btn-type" v-if="!this.$store.state.importElmStatus">
            <input type="file" @change="importElm($event)"/>
            Import ELM
          </div>
          <div class="data-popper-dropdown" v-if="showNotification()">
              <button
                  type="button"
                  class="btn dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >
                  <span class="clone-notification"> <em class="icon-bell"></em></span>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div v-if="this.$store.state.cloningStatus === true">
                  <div>
                    <span>
                      Cloning progress of {{this.$store.state.cloningElm.attributes.name}}:
                    </span>
                  </div>
                  <div>
                    <div class="progress-bar-container">
                        <progress-bar :options="options" :value=this.$store.state.cloneProgress />
                    </div> 
                  </div>
                </div>
                <div v-if="this.$store.state.importElmStatus === true">
                  <div>
                    <span>
                      Import progress of {{this.$store.state.importingElm}}:
                    </span>
                  </div>
                  <div>
                    <div class="progress-bar-container">
                        <progress-bar :options="options" :value=this.$store.state.elmImportProgress />
                    </div> 
                  </div>
                </div>
              </div>
          </div>
        </div>
        <p v-if="totalElms > 1 && !searchElm">You have {{totalElms}} Learning Modules in the system.</p>
        <p
          v-else-if="totalElms <= 1 && !searchElm"
        >You have {{totalElms}} Learning Module in the system.</p>
        <p
          v-else-if="totalElms > 1 && searchElm"
        >You have {{totalElms}} Learning Modules in the search result.</p>
        <p v-else>You have {{totalElms}} Learning Module in the search result.</p>
      </header>
      <div class="data-content" v-if="elms.length">
        <div class="data-table data-column-9">
          <div class="data-head">
            <div class="data-col">
              Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'learn_mods.name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('learn_mods.name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'learn_mods.name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('learn_mods.name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Created on
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('created_at','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('created_at','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Unique code</div>
            <div class="data-col">
              LO
              <span class="text-lowercase">s</span>
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'learning_objects_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('learning_objects_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'learning_objects_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('learning_objects_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Expected hours
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'time_to_complete' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('time_to_complete','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'time_to_complete' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('time_to_complete','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">ELM ID</div>
            <div class="data-col">Grading</div>
            <div class="data-col">Actions</div>
          </div>
          <div class="data-items">
            <div class="data-row" v-for="elm in elms" :key="elm.id">
              <div class="data-col">
                <div class="data-image">
                  <div class="data-pic square">
                    <router-link :to="`/edit-elm/${elm.id}`">
                    <img
                      :src="elm.attributes.photo_url.thumbnail"
                      v-if="elm.attributes.photo_url.thumbnail"
                      alt="image"
                    />
                    </router-link>
                  </div>
                  <div class="data-text data-image-text">
                    <div class="data-draft-head">
                      <div class="data-draft-title">
                        <div v-if="isCloningElm(elm.id)">
                          {{elm.attributes.name}}
                        </div>
                        <div v-else>
                          <router-link :to="`/edit-elm/${elm.id}`">{{elm.attributes.name}}</router-link>
                        </div>  
                      </div>
                      <span class="data-tag" v-if="elm.attributes.status === 'drafted'">Draft</span>
                    </div>
                    <p>{{elm.attributes.world_name}}</p>
                  </div>
                </div>
              </div>
              <div class="data-col">{{elm.attributes.created_on}}</div>
              <div class="data-col">{{elm.attributes.unique_code}}</div>
              <div class="data-col">
                <span class="elms">{{elm.attributes.learning_objects_count}}</span>
              </div>
              <div class="data-col">{{elm.attributes.time_to_complete}} hrs</div>
              <div class="data-col">
                <!--<div class="data-skills">
                  <div v-if="elm.attributes.skills.length <= 2">
                    <span
                      v-for="(skill, ind) in elm.attributes.skills"
                      :key="skill.id"
                    >{{skill.attributes.name}}{{ind == elm.attributes.skills.length - 1 ? '' : ', '}}</span>
                  </div>
                  <div v-if="elm.attributes.skills.length > 2">
                    <span>{{elm.attributes.skills[0].attributes.name}},{{elm.attributes.skills[1].attributes.name}}</span>
                    <span>+{{elm.attributes.skills.length - 2}}</span>
                  </div>
                  <span v-if="elm.attributes.skills.length === 0">-</span>

                  <a
                    href="#"
                    class="btn dropdown-toggle"
                    :class="elm.attributes.notes != '' ? 'active' : ''"
                    id="menu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="../assets/images/icon-yellownote.svg" alt="yellow" class="imgGray" />
                  </a>
                  <div
                    class="dropdown-menu data-popover-dropdown dropdown-menu-right"
                    aria-labelledby="menu1"
                  >
                    <div class="data-popover">
                      <div class="data-popover-title">
                        <span>Note for admin</span>
                        <a class="data-popover-close">
                          <em class="icon-close"></em>
                        </a>
                      </div>
                      <div class="data-popover-form">
                        <div class="form-group">
                         <textarea class="form-control" v-model="elm.attributes.notes" rows="4"></textarea>
                        </div>
                        <div class="form-group-button">
                          <button
                            v-if="elm.attributes.notes"
                            class="btn btn-remove"
                            @click="saveNotes('cancel', elm.id, elm.attributes.notes)"
                          >Remove Note</button>
                          <button
                            class="btn btn-save"
                            @click="saveNotes('save', elm.id, elm.attributes.notes)"
                          >Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
                {{elm.id}}
              </div>
              <div class="data-col">{{elm.attributes.enable_grading}}</div>
              <div class="data-col data-action">           
                <div class="data-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-menu"></em>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" v-if="isCloningElm(elm.id)">
                    <div class="data-popper-title">
                        This ELM is currently being cloned and can be edited once cloning is complete.
                    </div>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right" v-else>
                    <ul>
                      <li>
                        <router-link :to="`/assistant-details/${elm.id}`">Watson Details</router-link>
                      </li>
                      <li>
                        <router-link :to="`/create-elm/${elm.id}`">Edit Description</router-link>
                      </li>
                      <li>
                        <router-link :to="`/edit-elm/${elm.id}`">Edit LOs</router-link>
                      </li>
                       <li v-if="!$store.state.cloningStatus">
                          <a @click="sendInfo(elm);$modal.show('cloneConfirmationModal')">Clone ELM</a>
                      </li>
                      <li>
                         <a @click="exportElm(elm);">Export ELM</a>
                      </li>
                       <li>
                          <a @click="sendInfo(elm);$modal.show('deleteElmModal')" class="dropdown-error">Delete</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{pages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in pages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getElm(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < pages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="elms.length === 0 && !searchStatus && display">
        <h3>There’s nothing here!</h3>
        <p>You have no modules in the system yet.</p>
        <div>
          <router-link class="btn primary" to="/create-elm">Add a new ELM</router-link>
        </div>
      </div>
      <div class="data-no-result" v-if="elms.length === 0 && searchStatus">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
     <modal name="deleteElmModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          Are you sure you want to delete this ELM?
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteElmModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteElm(selectedElm.id),$modal.hide('deleteElmModal')"
            class="btn medium primary"
          >ok</button>
        </div>
      </div>
    </modal>
    <modal name="cloneConfirmationModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content">
        <div class="modal-body">
          Are you sure you want to clone this ELM?
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('cloneConfirmationModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="cloneElm(selectedElm.id),$modal.hide('cloneConfirmationModal')"
            class="btn medium primary"
          >Yes</button>
        </div>
      </div>
    </modal>
    <modal name="elmImportConfirmationModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">This ELM already exists! Do you want to overwrite it?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('elmImportConfirmationModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="importElm(),$modal.hide('elmImportConfirmationModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import 'es6-promise/auto'

export default {
  name: "LearningModules",
  components: {},
  data() {
    return {
      page: 1,
      elms: [],
      totalElms: "",
      searchElm: "",
      sortOrder: "desc",
      sortColumn: "id",
      pages: [],
      searchStatus: false,
      display: false,
      noteData: "",
      selectedElm: null,
      importedFile: null,
      importingWorldName: null,
      url: utilFunctionService.getImageUrl(),
      options: {
      text: {
        color: "#FFF",
        shadowEnable: true,
        fontSize: 12,
        fontFamily: "Helvetica",
        dynamicPosition: false,
        hideText: false
      },
      progress: {
        color: "#2dbd2d",
        backgroundColor: "rgba(0, 0, 0, 0.09)"
      },
      layout: {
        height: 15,
        width: 400,
        verticalTextAlign: 80,
        horizontalTextAlign: 43,
        zeroOffset: 0,
        strokeWidth: 15,
        progressPadding: 0,
        type: "line"
      }
      }
    };
  },
  methods: {
    showNotification(){
      return (this.$store.state.cloningStatus === true || 
             this.$store.state.importElmStatus === true || 
             this.$store.state.exportElmStatus === true)
    },
    isCloningElm(elmId){
     let res = this.$store.state.cloningStatus === true && this.$store.state.cloningElm &&
      this.$store.state.cloningElm.id === elmId
     return res;
    },
    sendInfo(elm) {
        this.selectedElm = elm;
    },
    importElm(e){
      console.log("** importElm method");
      let overwriteConfirmed = false;
      utilFunctionService.showLoader();
      if (e) {
        this.importedFile = e.target.files[0];
      } else {
        overwriteConfirmed = true;
      }
      const fb = new FormData();
      fb.append("file", this.importedFile);
      fb.append("confirmed", overwriteConfirmed);
      console.log(fb);
      api
        .importElm(fb)
        .then((res) => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(commonConfig.appMessages.uploadQueued);
          this.importedFile = null;
          this.$store.dispatch('startElmImport', res.data.elm);
        })
        .catch((error) => {
         if (error.response.data.elm_exists == true){
          utilFunctionService.hideLoader();
          this.$modal.show("elmImportConfirmationModal");
         }
         else if (error.response.data.world_not_exists_err == true){
          utilFunctionService.showErr(commonConfig.appMessages.worldNotFound);
          utilFunctionService.hideLoader();
         }
         else{
            utilFunctionService.showErr(commonConfig.appMessages.invalidJson);
            utilFunctionService.hideLoader(); 
         }         
        });
    },
    exportElm(elm) {
      api
        .exportElm(elm.id)
        .then(() => {
          utilFunctionService.showSuccess(commonConfig.appMessages.ElmExportQueued);
        })
        .catch((error) => {
          console.log("error::" + error)
        });
    },
    cloneElm(elmId){
      utilFunctionService.showLoader();
      api
        .cloneElm(elmId)
        .then((response) => {
          utilFunctionService.hideLoader();
          console.log("response::" + response)
          utilFunctionService.showSuccess(commonConfig.appMessages.elmCloned);
          this.$store.dispatch('startClone', this.selectedElm);
        })
        .catch((error) => {
          utilFunctionService.showErr(commonConfig.appMessages.cloneErr );
          console.log("error::" + error)
        });

    },
    saveNotes(save, id, note) {
      utilFunctionService.showLoader();
      this.noteData = new FormData();
      if (save === "save") {
        this.noteData.append("learn_mod[notes]", note);
      } else {
        this.noteData.append("learn_mod[notes]", "");
      }
      api
        .updateNote(id, this.noteData)
        .then(() => {
          if (save === "save") {
            utilFunctionService.showSuccess(commonConfig.appMessages.addNote);
          } else {
            utilFunctionService.showSuccess(
              commonConfig.appMessages.removeNote
            );
          }
          utilFunctionService.hideLoader();
          this.getElm(this.page);
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    toggleSort(colName, sortType) {
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getElm(this.page);
    },
    nextPage() {
      this.page = this.page + 1;
      this.getElm(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getElm(this.page);
    },
    getElm(page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getAllElms(page, this.searchElm, this.sortColumn, this.sortOrder)
        .then(res => {
          utilFunctionService.hideLoader();
          this.display = true;
          this.searchStatus = false;
          this.elms = res.data.data;
          this.elms.forEach(ele => {
            if (ele.attributes.notes == 'null' || ele.attributes.notes == null)
               (ele.attributes.notes = '')
          });
          this.totalElms = res.data.filtered_count;
          this.pages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.pages.push(ind);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            utilFunctionService.showErr(commonConfig.appMessages.sessionExpire);
            utilFunctionService.hideLoader();
            utilFunctionService.removeLocalStorageService("user");
            this.$router.push({ name: "login" });
          }
        });
    },
    search(value) {
      api
        .getAllElms(1, value, this.sortColumn, this.sortOrder)
        .then(res => {
          this.searchStatus = true;
          this.elms = res.data.data;
          this.totalElms = res.data.filtered_count;
          this.pages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.pages.push(ind);
          }
        })
        .catch();
    },
    deleteElm(id) {
      utilFunctionService.showLoader();
      api
        .deleteElm(id)
        .then(() => {
          utilFunctionService.showSuccess(commonConfig.appMessages.deleteElm);
          utilFunctionService.hideLoader();
          let page;
          if (this.elms.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getElm(page);
            } else {
              page = this.page - 1;
              this.getElm(page);
            }
          } else {
            const page = this.page;
            this.getElm(page);
          }
        })
        .catch(error => {
          utilFunctionService.showErr(
            error.response.data.errors
          );
          utilFunctionService.hideLoader();
        });
    }
  },
  mounted(){      
  },
  created() {
    this.getElm(this.page);
  }
};
</script>

<style lang="scss">
  @import "../styles/clone-notification";
</style>
