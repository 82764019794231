<template>
  <div id="ContentImages">
    <modal name="cropperImages" height="auto" :width="800" :scrollable="true">
      <div class="modal-content modal-cropper-content">
        <div class="modal-body">
          <div class="modal-images-cropper">
            <div class="modal-cropper-large" v-if="image">
              <cropper :src="image" :stencil-props="{ aspectRatio: 16/9 }" ref="cropper"></cropper>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button @click="$modal.hide('cropperImages')" type="button" class="btn default">Cancel</button>
          <button type="button" @click="crop()" class="btn primary">Save</button>
        </div>
      </div>
    </modal>
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Images</h2>
          <div class="progress-bar-overlay" v-if="uploadPercentage > 0">
            <div class="progress-bar-container">
              <progress-bar :options="options" :value="getPercentage" v-if="uploadPercentage > 0" />
            </div>
          </div>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchImage"
                v-debounce:700ms="search"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <input
            style="display: none;"
            ref="fileInput"
            type="file"
            @change="onFileSelect"
            accept="image/jpeg"
          />
          <a class="btn header-btn primary" @click="$refs.fileInput.click()">Upload Image</a>
        </div>
        <p v-if="totalImages > 1 && !searchImage">You have {{totalImages}} images in the system.</p>
        <p
          v-else-if="totalImages <= 1 && !searchImage"
        >You have {{totalImages}} image in the system.</p>
        <p
          v-else-if="totalImages > 1 && searchImage"
        >You have {{totalImages}} images in the search result.</p>
        <p v-else>You have {{totalImages}} image in the search result.</p>
      </header>
      <div class="data-content">
        <!-- <v-infinite-scroll @bottom="nextPage" class="data-content-scroll"> -->
        <div >
          <div class="data-image-row">
            <div class="data-image-col col-3" v-for="(images, i) in imageLists" :key="i">
              <div class="data-image">
                <img
                  :src="images.attributes.attachment_variations.large_version"
                  v-if="images.attributes.attachment_variations.large_version"
                  alt="image"
                />
              </div>
              <div class="data-image-content">
                <h4 class="text-truncate">{{images.attributes.file_name}}</h4>
                <h5>
                  <span v-if="images.attributes.tag_list">{{images.attributes.tag_list}}</span>
                  <a
                    href
                    class="data-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"

                  >{{images.attributes.tag_list ? 'Tag' : 'Add Tag'}}</a>
                  <notesModal
                    v-bind:doc="{id : images.id , type : 'image' , notes : images.attributes.tag_list, page :i}"
                    :method="addTag"
                  />
                </h5>
                <p>{{images.attributes.created_on}}</p>
                <a class="data-image-delete" @click="deleteImage(images.id)">
                  <em class="icon-delete"></em>
                </a>
              </div>
            </div>
          </div>
        <!-- </v-infinite-scroll> -->
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPagesPagination.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPagesPagination.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getAllImages(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPagesPagination.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="imageLists.length === 0 && searchStatus">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
      <div class="data-no-result" v-if="imageLists.length === 0 && !searchStatus && display">
        <h3>There’s nothing here!</h3>
        <p>You have no Images in the system yet.</p>
        <div>
          <a @click="$refs.fileInput.click()" class="btn header-btn primary">Add a new Image</a>
        </div>
      </div>
    </div>
    <modal name="deleteModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">Are you sure you want to delete this image?</div>
        <div class="modal-footer justify-content-center">
          <button type="button" @click="hide('deleteModal')" class="btn medium default">Cancel</button>
          <button type="button" class="btn medium primary" @click="removeImage()">ok</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue"></script>
<script src="https://unpkg.com/vue-advanced-cropper@latest/dist/index.umd.js"></script>
<script>
import { commonConfig } from "@/utils/commonConfig";
import { utilFunctionService } from "@/utils/utils.service";
import notesModal from "../components/modal/Note.vue";
import { Cropper } from "vue-advanced-cropper";
import api from "../services/api";
import axios from "axios";

export default {
  name: "Organisation",
  components: {
    notesModal,
    Cropper
  },
  data() {
    return {
      totalPagesPagination:[],
      selectedFile: "",
      page: 1,
      imageLists: [],
      totalImages: "",
      totalPages: "",
      fileName: "",
      searchImage: "",
      sortOrder: "desc",
      sortColumn: "id",
      searchStatus: false,
      display: false,
      url: utilFunctionService.getImageUrl(),
      image: "",
      imageId: "",
      uploadPercentage: 0,
      blobUrl: "",
      options: {
        text: {
          color: "#FFF",
          shadowEnable: true,
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "rgba(0, 0, 0, 0.09)"
        },
        layout: {
          height: 15,
          width: 200,
          verticalTextAlign: 80,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "line"
        }
      }
    };
  },
  methods: {
    hide(modal) {
      this.$modal.hide(modal);
    },
    show(modal) {
      this.$modal.show(modal);
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      const img = canvas.toDataURL();
      this.image = img.replace("data:image/png;base64,", "");
      this.$modal.hide("cropperImages");
      var b64toBlob = require("b64-to-blob");
      var contentType = "image/jpeg";
      var blob = b64toBlob(this.image, contentType);
      blob.name = this.fileName;

      let accessToken = utilFunctionService.getAuthToken();
      const fb = new FormData();
      fb.append("global_resource[attachment]", img);
      fb.append("global_resource[resource_type]", "image");
      fb.append("global_resource[tag_list]", "");
      fb.append("global_resource[private]", false);
      fb.append("global_resource[attachment_name]", this.fileName);
      axios
        .post(`${this.url}/api/admin/v1/global_resources.json`, fb, {
          headers: {
            Authorization: `${accessToken}`,
            "content-type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage =
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ) !== 100
                ? parseInt(
                    Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  )
                : 0;
          }.bind(this)
        })
        .then(() => {
          utilFunctionService.showSuccess(commonConfig.appMessages.addImage);
          this.getAllImages(this.page);
        })
        .catch(() => {
          utilFunctionService.showErr(commonConfig.appMessages.errImageUpload);
        });
    },
    nextPage() {
      ++this.page;
      if (this.page <= this.totalPages) {
        this.getAllImages(this.page);
      } else {
        return;
      }
    },
    prevPage() {
      this.page = this.page - 1;
      if (this.page <= this.totalPages) {
        this.getAllImages(this.page);
      } else {
        return;
      }
    },
    onFileSelect(event) {
      this.selectedFile = "";
      this.fileName = "";
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.image = e.target.result;
      };
      this.$modal.show("cropperImages");
    },
    onFileSelected(event) {
      utilFunctionService.showLoader();
      for (var i = 0; i < event.target.files.length; i++) {
        this.selectedFile = event.target.files[i];
        let accessToken = utilFunctionService.getAuthToken();
        const fb = new FormData();
        if (this.selectedFile) {
          fb.append("global_resource[attachment]", this.selectedFile);
          fb.append("global_resource[resource_type]", "image");
          fb.append("global_resource[tag_list]", "");
          fb.append("global_resource[private]", false);
          axios
            .post(`${this.url}/api/admin/v1/global_resources.json`, fb, {
              headers: {
                Authorization: `${accessToken}`,
                "content-type": "multipart/form-data"
              },
              onUploadProgress: function(progressEvent) {
                this.uploadPercentage =
                  parseInt(
                    Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  ) !== 100
                    ? parseInt(
                        Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        )
                      )
                    : 0;
              }.bind(this)
            })
            .then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.addImage
              );
              this.getAllImages(this.page);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.errImageUpload
              );
            });
        }
      }
      utilFunctionService.hideLoader();
    },
    search() {
      api
        .getAllImages(
          commonConfig.setting.searchPage,
          this.searchImage,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.imageLists = res.data.data;
          this.totalImages = res.data.filtered_count;
          this.totalPages = res.data.total_pages;
          this.searchStatus = true;
          this.totalPagesPagination = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPagesPagination.push(ind);
          }
        })
        .catch();
    },
    deleteImage(id) {
      this.imageId = id;
      this.show("deleteModal");
    },
    removeImage() {
      utilFunctionService.showLoader();
      api
        .deleteGlobalResource(this.imageId)
        .then(() => {
          this.searchStatus = false;
          this.hide("deleteModal");
          utilFunctionService.hideLoader();
          this.getAllImages(this.page);
          utilFunctionService.showSuccess(commonConfig.appMessages.removeImage);
        })
        .catch(() => {
          this.hide("deleteModal");
          utilFunctionService.hideLoader();
        });
    },
    getAllImages(page) {
      utilFunctionService.showLoader();
      this.page = page;
      api
        .getAllImages(page, this.searchImage, this.sortColumn, this.sortOrder)
        .then(res => {
          this.display = true;
          this.searchStatus = false;
          utilFunctionService.hideLoader();
          this.imageLists = res.data.data;
          this.totalImages = res.data.filtered_count;
          this.totalPages = res.data.total_pages;
          this.totalPagesPagination = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPagesPagination.push(ind);
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    // getAllImages(page) {
    //   console.log("ok")
    //   utilFunctionService.showLoader();
    //   this.page = page;
    //   api
    //     .getAllImages(page, this.searchImage, this.sortColumn, this.sortOrder)
    //     .then(res => {
    //       console.log(res)
    //       this.display = true;
    //       this.searchStatus = false;
    //       utilFunctionService.hideLoader();
    //       if (page === 1) this.imageLists = res.data.data;
    //       else this.imageLists = [...this.imageLists, ...res.data.data];
    //       this.totalImages = res.data.filtered_count;
    //       this.totalPages = res.data.total_pages;
    //       this.totalPagesPagination = [];
    //       for (let ind = 1; ind <= res.data.total_pages; ind++) {
    //         this.totalPagesPagination.push(ind);
    //       }
    //     })
    //     .catch(() => {
    //       utilFunctionService.hideLoader();
    //     });
    // },
    addTag(i, tag) {
      this.imageLists[i].attributes.tag_list = tag;
    }
  },
  created() {
    this.getAllImages(this.page);
  },
  computed: {
    getPercentage() {
      return this.uploadPercentage;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
#progress-bar-line {
  display: inline-block;
  width: auto;
}
.data-image {
  width: 100%;
  img {
    width: 100vw;
    height: 13vw;
    object-fit: cover;
  }
}
.manage-scroller {
  max-height: 60vh;
  overflow: auto;
  @include media-breakpoint(xl) {
    margin: 0 -20px;
  }
}
</style>
