<template>
  <div id="Assessment">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>Assessment Schemes</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
          </form>
          <a @click="show('addScheme')" class="btn header-btn primary">Add Scheme</a>
        </div>
        <p>You have {{schemeLists.length}} Assessment Schemes in the system.</p>
      </header>
      <div class="data-content">
        <div class="data-table data-column-3">
          <div class="data-head">
            <div class="data-col">Name</div>
            <div class="data-col">Labels</div>

            <div class="data-col">Actions</div>
          </div>
          <div class="data-items" v-for="(scheme, index) in schemeLists" :key="scheme.id">
            <div class="data-row">
              <div class="data-col">
                <div>{{scheme.attributes.name}}</div>
              </div>
              <div class="data-col">
                <div
                  class="data-assement-icons"
                  v-for="schemeLabel in scheme.attributes.assessment_labels"
                  track-by="id"
                  :key="schemeLabel.id"
                >
                  <div class="data-icons" v-if="schemeLabel.attributes.icon_url">
                    <img :src="schemeLabel.attributes.icon_url" alt="strong" />
                  </div>
                  {{schemeLabel.attributes.name}}
                </div>
              </div>

              <div class="data-col data-action">
                <div class="data-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="editScheme(scheme.id, index)"
                  >
                    <em class="icon-edit"></em>
                  </button>
                </div>
                <div class="data-delete-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-delete"></em>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div
                      class="data-popper-title"
                    >Are you sure you want to delete this Assessment Scheme?</div>
                    <div class="data-popper-footer">
                      <a class="btn default">cancel</a>
                      <a class="btn primary" @click="deleteAssessment(scheme.id)">Ok</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li
                class="page-item"
                v-for="pages in totalPages.slice(page - 1, page + 3)"
                :key="pages"
              >
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getAssessment(pages)"
                >{{ pages }}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="schemeLists.length === 0 && !searchSchemes && display">
        <h3>There’s nothing here!</h3>
        <p>You have no Assessment in the system yet.</p>
        <div>
          <a
            @click="show('addScheme')"
            class="btn header-btn primary"
          >Add a Assessment</a>
        </div>
      </div>
      <div class="data-no-result" v-if="schemeLists.length === 0 && searchSchemes">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <!-- Add Scheme Modal here -->
    <modal name="addScheme" :width="500" height="auto" :scrollable="true">
      <div class="hide-modal" @click="cancelModal(false)"></div>
      <div class="modal-content scheme-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>{{isEditScheme ? 'Edit' :'Create new'}} assessment scheme</h5>
          </div>
        </div>
        <div class="modal-body mt-3">
          <ValidationObserver ref="form">
            <form class="modal-form scheme-form">
              <div class="form-group">
                <label class="form-label">Scheme Name</label>
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    maxlength="30"
                    v-model="schemeName"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group-list">
                <div class="form-group-heading">Evaluation Label</div>
                <label class="form-label mb-3">(Highest assessment level label should be first; lowest should be last)</label>
                <draggable v-model="inputs"
                 @change="onUnpublishedChange">
                  <div class="inputArea" v-for="(input, index) in inputs" :key="input.id">
                    <div class="form-group row" :for="input.id">
                      <input
                            id="order"
                            type="number"
                            class="form-control form-control-too-small mr-4"
                            disabled="true"
                            v-model="input.order"
                          />
                      <div class="form-group-scheme">
                        <div class="scheme-placeholder">
                          <ValidationProvider rules="required" v-slot="{ errors }">
                            <input
                              id="Label Name"
                              type="text"
                              placeholder="Label Name"
                              class="form-control"
                              v-model="input.name"
                              :class="[(isActive && errors[0] ) || isError ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0] || isError"
                            >{{ isError ? 'At least one label should be there for a scheme.' :errors[0].replace("The ", "") }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="scheme-upload" v-if="!input.url">
                          <input
                            type="file"
                            class="upload"
                            :id="index"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="onFileChange($event, input.labelId)"
                          />
                          <span>+ Select Icon</span>
                        </div>
                        <div class="scheme-icon" v-if="input.url">
                          <img
                            :src="isEditScheme && !input.icon ? input.url :input.url"
                            alt="mastery"
                          />
                          <input
                            :type="isEditScheme ? 'text' :'file'"
                            class="upload"
                            :id="index"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="onFileChange($event, input.labelId)"
                          />
                        </div>
                        <a class="scheme-delete" v-if="input.name">
                          <em
                            class="icon-close"
                            :id="input.id"
                            @click="deleteLabel($event, input.labelId, index)"
                          ></em>
                        </a>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div class="form-group" >
                  <a v-if="!isEditScheme" href="#" class="add-scheme-btn" @click="addLabel">+ Add More Labels</a>
                </div>
                <label v-if="isLabelDragged">Note: you have to click save to see the updated order.</label>
              </div>

              <div class="scheme-footer modal-footer">
                <button
                  type="button"
                  class="btn default"
                  data-dismiss="modal"
                  @click="cancelModal(false)"
                >Cancel</button>
                <button
                  type="button"
                  class="btn primary"
                  data-dismiss="modal"
                  data-toggle="modal"
                  @click="createScheme"
                >{{isEditScheme ? 'Save': 'Create'}}</button>
              </div>
              <modal name="deleteWorldModal" height="auto" :width="350" :scrollable="true">
                <div class="modal-content modal-delete-content">
                  <div class="modal-body modal-delete-body">Are you sure you want to discard this?</div>
                  <div class="modal-footer justify-content-center">
                    <button
                      type="button"
                      @click="$modal.hide('deleteWorldModal')"
                      class="btn medium default"
                    >Cancel</button>
                    <button
                      type="button"
                      @click="cancelModal(true)"
                      class="btn medium primary"
                    >Ok</button>
                  </div>
                </div>
              </modal>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { commonConfig } from "@/utils/commonConfig";
import { utilFunctionService } from "@/utils/utils.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import draggable from "vuedraggable";
import api from "../services/api";

export default {
  name: "Assessment-component",
  components: {
    ValidationProvider,
    ValidationObserver,
    draggable
  },
  metaInfo: {
    title: "Assessment",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  ready: {
    handleValueChange: function() {
      this.isNameEdited = true;
    }
  },
  filters: {
    upperCaseChar: function(value, onlyCharacterFirst) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyCharacterFirst) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      schemeName: "",
      searchRole: "",
      characters: [],
      roles: [],
      page: 1,
      schemeLists: [],
      totalScheme: "",
      totalPages: [],
      searchSchemes: "",
      sortOrder: "desc",
      sortColumn: "organizations.id",
      isActive: false,
      display: false,
      inputs: [
        {
          id: 0,
          name: "",
          icon: null,
          url: null,
          order: 1
        }
      ],
      counter: 0,
      isEditScheme: false,
      isEditId: "",
      isError: false,
      isNameEdited: false,
      url: utilFunctionService.getImageUrl(),
      selectedAssessmentScheme: null,
      destroyedLabel: [],
      isLabelDragged: false
    };
  },
  methods: {
    show(e) {
      this.destroyedLabel = [];
      this.$modal.show(e);
    },
    hide(modal) {
      this.clearForm();
      this.$modal.hide(modal);
    },
    searchAssessment(value) {
      this.display = false;
      api
        .getAllAssessment(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.schemeLists = res.data.data;
          this.totalScheme = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    createScheme() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else
        // if (!this.inputs[0].url) {
        //   this.isError = true;
        // } else
        {
          this.createAssessmentScheme();
        }
      });
    },
    createAssessmentScheme() {
      utilFunctionService.showLoader();
      const fb = new FormData();
      if (!this.isEditScheme) {
        fb.append("assessment_scheme[name]", this.schemeName);
        this.inputs.forEach((e, i) => {
          fb.append(
            `assessment_scheme[assessment_labels_attributes][${i}][name]`,
            e.name
          );
          if (e.icon != null) {
            fb.append(
              `assessment_scheme[assessment_labels_attributes][${i}][icon]`,
              e.icon
            );
          }
          fb.append(
            `assessment_scheme[assessment_labels_attributes][${i}][order]`,
            i + 1
          );
        });
        api
          .createAssessmentScheme(fb)
          .then(() => {
            utilFunctionService.hideLoader();
            this.getAssessment(this.page);
            utilFunctionService.showSuccess(
              commonConfig.appMessages.createAssessment
            );
          })
          .catch(() => {
            utilFunctionService.showErr(
                commonConfig.appMessages.nameTakenErr
              );
            utilFunctionService.hideLoader();
          });
      } else {
        let { attributes } = this.selectedAssessmentScheme,
          isValueAdded = false;
        if (attributes.name !== this.schemeName) {
          isValueAdded = true
          fb.append("assessment_scheme[name]", this.schemeName);
        }
        this.inputs.forEach((e, i) => {
          attributes.assessment_labels.forEach(ele => {
            if (ele.id === e.labelId && ele.attributes.name !== e.name) {
              fb.append(
                `assessment_scheme[assessment_labels_attributes][${i}][name]`,
                e.name
              );
              fb.append(
                `assessment_scheme[assessment_labels_attributes][${i}][id]`,
                e.labelId
              );
              isValueAdded = true;
            }
          });
          if (this.isLabelDragged) {
            fb.append(
              `assessment_scheme[assessment_labels_attributes][${i}][id]`,
              e.labelId
            );
            fb.append(
              `assessment_scheme[assessment_labels_attributes][${i}][order]`,
              i + 1
            );
            isValueAdded = true;
          }
          if (e.icon) {
           e.labelId && fb.append(
              `assessment_scheme[assessment_labels_attributes][${i}][id]`,
               e.labelId
            );
            fb.append(
              `assessment_scheme[assessment_labels_attributes][${i}][icon]`,
              e.icon
            );
            fb.append(
                `assessment_scheme[assessment_labels_attributes][${i}][name]`,
                e.name
              );
            isValueAdded = true;
          }
        });
        this.destroyedLabel.length > 0 &&
          this.destroyedLabel.forEach((e, i) => {
            if (e) {
              fb.append(
                `assessment_scheme[assessment_labels_attributes][${i}][_destroy]`,
                true
              );
              fb.append(
                `assessment_scheme[assessment_labels_attributes][${i}][id]`,
                e
              );
              isValueAdded = true;
            }
          });
        isValueAdded &&
          api
            .updateAssessmentScheme(this.isEditId, fb)
            .then(() => {
              utilFunctionService.hideLoader();
              this.getAssessment(this.page);
              utilFunctionService.showSuccess(
                commonConfig.appMessages.updatedAssessment
              );
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.nameTakenErr
              );
              utilFunctionService.hideLoader();
            });
      }
      utilFunctionService.hideLoader();
      this.hide("addScheme");
    },
    getAssessment(page) {
      utilFunctionService.showLoader();
      this.page = page;
      api
        .getAllAssessment(
          page,
          '',
          '',
          '',
        )
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          res.data.data.forEach(ele => {
            this.schemeLists.push({
              attributes: {
                assessment_labels: ele.attributes.assessment_labels.sort( this.compareObjects ),
                name: ele.attributes.name
              },
              id: ele.id,
              type: "assessment_scheme"
            });
          });
          this.schemeLists = res.data.data;
          this.totalScheme = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    editScheme(id, index) {
      let { name, assessment_labels } = this.schemeLists[index].attributes;
      this.selectedAssessmentScheme = this.schemeLists[index];
      this.inputs = assessment_labels.map((e, i) => {
        return {
          id: i,
          name: e.attributes.name,
          icon: null,
          url: e.attributes.icon_url,
          labelId: e.id,
          order: e.attributes.order
        };
      });
      this.inputs = this.inputs.sort( this.compare );
      this.schemeName = name;
      this.isEditScheme = true;
      this.isEditId = id;
      this.show("addScheme");
    },
    toggleSort(colName, sortType) {
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getAssessment(this.page);
    },
    deleteAssessment(id) {
      utilFunctionService.showLoader();
      api
        .deleteAssessment(id)
        .then(() => {
          utilFunctionService.hideLoader();
          let page;
          if (this.schemeLists.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getAssessment(page);
            } else {
              page = this.page - 1;
              this.getAssessment(page);
            }
          } else {
            const page = this.page;
            this.getAssessment(page);
          }
          utilFunctionService.showSuccess(
            commonConfig.appMessages.deleteAssessment
          );
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    addLabel() {
      this.inputs.push({
        id: ++this.counter,
        name: "",
        url: null,
        icon: null,
        order: this.inputs.length + 1
      });
    },
    clearForm() {
      this.inputs = [
        {
          id: 0,
          name: "",
          icon: null,
          url: null,
          order: 1
        }
      ];
      this.schemeName = "";
      this.counter = 0;
      this.selectedAssessmentScheme = null;
      this.isEditScheme = false;
      this.isLabelDragged = false;
    },
    onFileChange(e) {
      this.isError = false;
      let target = e.target,
        files = target.files || e.dataTransfer.files,
        id = target.id;
      this.inputs[id].icon = files[0];
      this.inputs[id].url = URL.createObjectURL(files[0]);
      if (this.isEditScheme) this.inputs[id].isIconUpdate = true;
    },
    deleteLabel(e, labelId, index) {
      // let index = e.target.id;
      if (this.isEditScheme) this.destroyedLabel.push(labelId);
      this.inputs.splice(index, 1);
    },
    nextPage() {
      this.page = this.page + 1;
      this.getAssessment(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getAssessment(this.page);
    },
    onUnpublishedChange() {
    this.isLabelDragged = true;
  },
  cancelModal(status){
      this.$modal.show('deleteWorldModal')
      if(status){
        this.$modal.hide('addScheme');
        this.$modal.hide('deleteWorldModal')
        this.clearForm();
      }
    },
    compare( a, b ) {
      if ( a.order < b.order ){
        return -1;
      }
      if ( a.order > b.order ){
        return 1;
      }
      return 0;
    }
  },
  created() {
    this.getAssessment(this.page);
  },
  compareObjects(a, b){
    if ( a.attributes.order < b.attributes.order ){
      return -1;
    }
    if ( a.attributes.order > b.attributes.order ){
      return 1;
    }
    return 0;
  }
};
</script>
