var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "learning-form-header flex-wrap" }, [
        _c("h4", [_vm._v("Submission (Grading)")]),
        _c("div", { staticClass: "learning-quiz-btn" }, [
          _vm.status == "published"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn secondary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.publishLo("published")
                    },
                  },
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _vm.status == "drafted"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn primary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.publishLo("published")
                    },
                  },
                },
                [_vm._v("Publish LO")]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "btn header-btn ml-2 secondary",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.$modal.show("cloneConfirmationModal")
                },
              },
            },
            [_vm._v("Clone LO")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "learning-lo-tabs" }, [
        _c("div", { staticClass: "setup-container" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[0] },
                on: {
                  click: function ($event) {
                    return _vm.currentForm("basic-setup", 0)
                  },
                },
              },
              [_vm._m(0)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[1] },
                on: {
                  click: function ($event) {
                    return _vm.currentForm("configure-rubric", 1)
                  },
                },
              },
              [_vm._m(1)]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "learning-quiz-content" }, [
        _vm.step == "basic-setup"
          ? _c(
              "div",
              { staticClass: "setup-content" },
              [
                _c("ValidationObserver", { ref: "subform" }, [
                  _c(
                    "form",
                    {
                      staticClass: "w-100",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitLo("drafted")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row custom-row pt-4" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Interaction Title")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Interaction Title",
                                  rules: "required|max:100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: { type: "text" },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  268414483
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Administrative Notes")]),
                              _c(
                                "ValidationProvider",
                                { attrs: { name: "Description" } },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.adminNotes,
                                        expression: "adminNotes",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "Description",
                                      id: "Description",
                                      rows: "8",
                                    },
                                    domProps: { value: _vm.adminNotes },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.adminNotes = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c(
                            "div",
                            { staticClass: "form-group submission-group" },
                            [
                              _c("label", [_vm._v("Learner Description")]),
                              _c(
                                "span",
                                [
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { name: "Description" } },
                                    [
                                      _c("vue-editor", {
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "Add a Description",
                                        },
                                        model: {
                                          value: _vm.description,
                                          callback: function ($$v) {
                                            _vm.description = $$v
                                          },
                                          expression: "description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "row pb-5" }, [
                            _c("div", { staticClass: "form-group col-5" }, [
                              _c("label", [_vm._v("Submission type:")]),
                              _c(
                                "div",
                                { staticClass: "form-checkboxes mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox",
                                      attrs: { name: "quizAssessment" },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "public",
                                        },
                                        domProps: {
                                          value: "has_text",
                                          checked: _vm.textSubmission,
                                        },
                                        on: { click: _vm.handleCheckboxChange },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        attrs: { for: "quizAssessment" },
                                      }),
                                      _vm._v("Text "),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-checkboxes mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox",
                                      attrs: { name: "quizAssessment" },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "private",
                                        },
                                        domProps: {
                                          value: "has_file_upload",
                                          checked: _vm.fileUpload,
                                        },
                                        on: { click: _vm.handleCheckboxChange },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        attrs: { for: "quizAssessment" },
                                      }),
                                      _vm._v("File upload "),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "form-group col-7" }, [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("Allow instructor to edit rubric:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-checkboxes mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox",
                                      attrs: { name: "yes" },
                                    },
                                    [
                                      _vm._v(" Yes "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.editPermission,
                                            expression: "editPermission",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "yes",
                                          value: "true",
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.editPermission,
                                            "true"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editPermission = "true"
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark circle",
                                        attrs: { for: "yes" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-checkboxes mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox",
                                      attrs: { name: "no" },
                                    },
                                    [
                                      _vm._v(" No "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.editPermission,
                                            expression: "editPermission",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "no",
                                          value: "false",
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.editPermission,
                                            "false"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editPermission = "false"
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark circle",
                                        attrs: { for: "no" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "form-group col-7" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox",
                                  attrs: { name: "writingAssessment" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.enableWritingAssessment,
                                        expression: "enableWritingAssessment",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "enableWritingAssessment",
                                      value: "false",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.enableWritingAssessment
                                      )
                                        ? _vm._i(
                                            _vm.enableWritingAssessment,
                                            "false"
                                          ) > -1
                                        : _vm.enableWritingAssessment,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.enableWritingAssessment,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "false",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.enableWritingAssessment =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.enableWritingAssessment = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.enableWritingAssessment = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    attrs: { for: "writingAssessment" },
                                  }),
                                  _vm._v(
                                    "Enable Writing Assessment [Coherence] "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-end mb-4" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitLo("drafted")
                                    },
                                  },
                                },
                                [_vm._v("Save")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: { click: _vm.archiveSubLo },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.step == "configure-rubric"
          ? _c(
              "div",
              [
                _c("Rubric", {
                  ref: "rubricForm",
                  attrs: { updatedData: _vm.updatedData },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "cloneConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to clone this learning object?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.cloneLo(), _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption w-auto" }, [
        _vm._v("Basic Information"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption w-auto" }, [
        _vm._v("Configure Rubric Feedback"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }