import Vue from 'vue';
import App from './App.vue';
import router from './router/router'
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import Toasted from 'vue-toasted';
import VueToast from 'vue-toast-notification';
import ReadMore from 'vue-read-more';
import vueDebounce from 'vue-debounce';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import InfiniteScroll from 'v-infinite-scroll'
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import 'v-infinite-scroll/dist/v-infinite-scroll.css';
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import vuescroll from "vuescroll/dist/vuescroll-native";
// import the css file.
import "vuescroll/dist/vuescroll.css";
import JsonViewer from 'vue-json-viewer'
import VueSanitize from "vue-sanitize";
let defaults = VueSanitize.defaults;
import Vuex from 'vuex'
import { Notifier } from '@airbrake/browser';
import vueCountryRegionSelect from 'vue-country-region-select'
import VueTippy, { TippyComponent } from "vue-tippy";
// import bootstrap from 'bootstrap';

// Add additional themes.
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
import "tabulator-tables/dist/css/tabulator.min.css";
import 'vue-toast-notification/dist/theme-bootstrap.css';


Vue.use(vueCountryRegionSelect)
Vue.use(VueSanitize,defaults);
Vue.use(JsonViewer);
Vue.use(vuescroll);
Vue.config.productionTip = false;
Vue.use(Toasted);
Vue.use(ReadMore);
Vue.use(vueDebounce);
Vue.use(VueSanitize);
Vue.use(vueDebounce, {
  defaultTime: '1200ms'
})
Vue.use(InfiniteScroll);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.config.productionTip = false
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
Vue.use(VueSlickCarousel)
Vue.use(Vuex)
Vue.use(VueToast);

Vue.use(VueTippy);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("tippy", TippyComponent);

const store = new Vuex.Store({
  state: {
    cloningStatus: false,
    cloningElm: null,
    cloneProgress: 0,
    importElmStatus: false,
    importingElm: null,
    elmImportProgress: 0,
    importWorldstatus: false,
    importingWorld: null,
    worldImportProgress: 0,
    exportElmStatus: false,
    exportingElm: null,
    elmExportProgress: 0,
    llmData: {},
    newLo: false
  },
  mutations: {
    SET_ELM_CLONING(state, elm){
      state.cloningElm = elm;
    },
    SET_CLONING_STATUS(state, status){
      state.cloningStatus = status;
    },
    SET_CLONING_PROGRESS(state, progress){
      state.cloneProgress = progress;
    },
    SET_ELM_IMPORT(state, elm){
      state.importingElm = elm;
    },
    SET_ELM_IMPORT_STATUS(state, status){
      state.importElmStatus = status;
    },
    SET_ELM_IMPORT_PROGRESS(state, progress){
      state.elmImportProgress = progress;
    },
    SET_WORLD_IMPORT(state, elm){
      state.importingWorld = elm;
    },
    SET_WORLD_IMPORT_STATUS(state, status){
      state.importWorldstatus = status;
    },
    SET_WORLD_IMPORT_PROGRESS(state, progress){
      state.worldImportProgress  = progress;
    },
    SET_ELM_EXPORT(state, elm){
      state.exportingElm = elm;
    },
    SET_ELM_EXPORT_STATUS(state, status){
      state.importWorldstatus = status;
    },
    SET_ELM_EXPORT_PROGRESS(state, progress){
      state.elmExportProgress  = progress;
    },
    SET_LLM_DATA(state, data) {
      state.llmData = data
    },
    SET_NEW_LO(state, data) {
      state.newLo = data
    }
  },
  actions: {
    startClone({commit}, elm){
      commit('SET_CLONING_STATUS', true)
      commit('SET_ELM_CLONING', elm)
    },
    endClone({commit}){
      commit('SET_CLONING_STATUS', false)
      commit('SET_ELM_CLONING', null)
      commit('SET_CLONING_PROGRESS', 0)
    },
    updateCloneProgress({commit}, progress){
      commit('SET_CLONING_PROGRESS', progress)
    },
    startElmImport({commit}, elm){
      commit('SET_ELM_IMPORT_STATUS', true)
      commit('SET_ELM_IMPORT', elm)
    },
    endElmImport({commit}){
      commit('SET_ELM_IMPORT_STATUS', false)
      commit('SET_ELM_IMPORT', null)
      commit('SET_ELM_IMPORT_PROGRESS', 0)
    },
    updateElmImportProgress({commit}, progress){
      commit('SET_ELM_IMPORT_PROGRESS', progress)
    },
    startWorldImport({commit}){
      commit('SET_WORLD_IMPORT_STATUS', true)
      // commit('SET_WORLD_IMPORT', elm)
    },
    endWorldImport({commit}){
      commit('SET_WORLD_IMPORT_STATUS', false)
      commit('SET_WORLD_IMPORT', null)
      commit('SET_WORLD_IMPORT_PROGRESS', 0)
    },
    updateWorldlmImportProgress({commit}, progress){
      commit('SET_WORLD_IMPORT_PROGRESS', progress)
    },
    updateLlmData({commit}, data) {
      commit('SET_LLM_DATA', data)
    },
    updateNewLo({commit}, data) {
      commit('SET_NEW_LO', data)
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

Vue.mixin({
  created() {
    //console.log('[created] ' + this.$options.name)
  },
})
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});



localize('en', en);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Airbrake code ..
if (process.env.NODE_ENV === 'production') {
  var airbrake = new Notifier({
    environment: 'production',
    projectId: process.env.VUE_APP_AIRBRAKE_PRODUCT_ID,
    projectKey: process.env.VUE_APP_AIRBRAKE_PRODUCT_KEY
  });
  console.log(process.env);
  Vue.config.errorHandler = function (err, vm, info) {
    airbrake.notify({
      error: err,
      params: {info: info}
    });
  }
}
