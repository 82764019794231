<template>
  <div>
    <llmChat v-if="llmType === 'llm_chat'" :llmChatData="llmData" :llmType="llmType" v-on:archiveLO="archiveLO" />
    <llmDialogic v-if="llmType === 'llm_dialogic'" :llmDialogicData="llmData" :llmType="llmType" v-on:archiveLO="archiveLO" />
    <llmEmail v-if="llmType === 'llm_email'" :llmEmailData="llmData" :llmType="llmType" v-on:archiveLO="archiveLO" />
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../../services/api";
import { commonConfig } from "@/utils/commonConfig";
import llmChat from './LlmChat.vue';
import llmDialogic from './LlmDialogic.vue';
import llmEmail from './LlmEmail.vue';
export default {
  name: "llm-view",
  components: {
    llmChat,
    llmDialogic,
    llmEmail
  },
  props: ["llmData", "llmCardType", "newLo"],
  data() {
    return {
      status: "drafted",
      isActiveTab: "basic-information",
      chatContentId: "",
      errors: "",
      isActive: false,
      name: "",
      adminNotes: "",
      description: "",
      displayOverallAssessment: false,
      userAnswer: "",
      optionsPassFail: [
        { text: "Pass/Fail", value: "pass_fail" },
        { text: "Threshold", value: "threshold" },
      ],
      optionsIndependentDependent: [
        { text: "Independent", value: true },
        { text: "Dependent", value: false },
      ],
      optionsCharacteristics: [{ text: "Open" }, { text: "Closed" }],
      dialogicQuestions: [],
      numberOfAttempts: 0,
      attemptsLimit: 3,
      chosenPrompt: {},
      chosenPromptIndex: 0,
      generalKnowledgeBase: "",
      narrativeKnowledgeBase: "",
      newAssKB: "",
      promptData: [],
      finishedLoading: false,
      chosenPromptKey: "",
      messages: [
        {
          question: "",
          answer: "",
          response: "",
        },
      ],
      loading: false,
      assessmentLoading: false,
      feedback: "",
      hasStarted: false,
      latestResponse: {},
      showPrompts: false,
      characterName: "",
      characterPronouns: "",
      characterAge: "",
      characterJob: "",
      characterCharacteristics: "",
      promptReactionSentiment: "",
      promptReactionEmotion: "",
      narrativeKnowledgeBaseAmount: 0,
      chosenPassFail: "Pass",
      dialogicQuestionRequired: false,
      llmChatImageEnabled: false,
      llmEmailIterations: 0,
      lastMessage: "",
      lastResponse: "",
      llmType: "",
      emailBody: "",
      emailSubject: "",
      emailChain: [],
      emailToCharacter: "",
      emailFromCharacter: "",
      dialogicQuestionKey: 0,
      llmChatImage: "",
      llmChatImageURL: "",
      assessmentItems: {},
      assessmentItemsKey: 0,
      feedback_assessment_pass_data: {
        label: "",
        description: "",
      },
      feedback_assessment_fail_data: {
        label: "",
        description: "",
      },
      newItem: {
        label: "",
        description: "",
        assessment_level: 0,
      },
      addingNewAssessmentItem: false,
      ccCharacters: [],
      newccCharacter: "",
    };
  },
  methods: {
    archiveLO() {
      this.$emit("archiveLO");
    },
    addNewccCharacter() {
      this.ccCharacters.push(this.newccCharacter,);
      this.newccCharacter = "";
    },
    removeccCharacter(index) {
      this.ccCharacters.splice(index, 1);
    },
    sendEmail() {
      this.submitQuestion();
      this.emailChain.push({
        fromCharacter: this.studentName,
        toCharacter: this.characterName,
        response: this.emailBody,
        id: this.emailChain.length + 1,
      });
      this.emailBody = "";
    },
    onEnd() {
      const fd = new FormData();
      this.promptData.map((data, index) => {
        fd.append("prompt[][id]", data.id);
        fd.append("prompt[][prompt_order]", index + 1);
      });
      api
        .reorderPrompts(this.llmData.attributes.card_detail.id, fd)
        .then(() => {})
        .catch();
    },
    switchTab(tab) {
      this.isActiveTab = tab;
      this.chatContentId = tab;
    },
    addKnowledgeBaseItem() {
      this.chosenPrompt.knowledge_base_items.push(this.newAssKB);
    },
    addPrompt() {
      let newPrompt = {
        new_prompt: true,
        title: "",
        prompt: "",
        assessment_knowledgebase_name: "",
        knowledge_base_items: [],
        threshold: 0,
        mandatory: false,
        question_needed: false,
        task: "",
        narrative_knowledge_base: "",
        admin_notes: "",
        pass_fail: true,
        assessment_items: {},
      };
      this.promptData.push(newPrompt);
      this.chosenPromptIndex = this.promptData.length - 1;
      this.chosenPrompt = this.promptData[this.chosenPromptIndex];
      this.showPrompts = true;
    },
    choosePrompt(index) {
      this.chosenPromptIndex = index;
      this.chosenPrompt = this.promptData[this.chosenPromptIndex];
      this.assessmentItems = this.chosenPrompt.assessment_items;
      if (this.chosenPrompt.pass_fail === true) {
        this.feedback_assessment_pass_data =
          this.chosenPrompt.assessment_items[0] || {};
        this.feedback_assessment_fail_data =
          this.chosenPrompt.assessment_items[1] || {};
        this.chosenPassFail = { text: "Pass/Fail", value: "pass_fail" };
      } else {
        this.chosenPassFail = { text: "Threshold", value: "threshold" };
      }
      this.showPrompts = true;
    },
    deletePrompt(index) {
      api
        .deletePrompt(index)
        .then((res) => {
          console.log(res);
          utilFunctionService.hideLoader();
        })
        .catch();
    },
    getPrompts() {
      this.finishedLoading = false;
      api
        .getPrompts(this.llmChatData.attributes.card_detail.id)
        .then((res) => {
          console.log(res);
          res.data.data.forEach((prompt) => {
            console.log(prompt.attributes);
            this.promptData.push(prompt.attributes);
          });
          this.promptData = res.data.data;
          if (this.promptData.length > 0) {
            this.chosenPrompt = this.promptData[0];
            this.showPrompts = true;
          }
          this.finishedLoading = true;
          utilFunctionService.hideLoader();
        })
        .catch();
    },
    savePrompt() {
      const loID = this.llmData.attributes.card_detail.id;
      const promptID = this.promptData[this.chosenPromptIndex].id;
      if (this.chosenPrompt.pass_fail === true) {
        this.assessmentItems = {
          0: this.feedback_assessment_pass_data,
          1: this.feedback_assessment_fail_data,
        };
      }
      console.log(typeof this.assessmentItems);
      let prompt_data = {
        title: this.chosenPrompt.title,
        prompt: this.chosenPrompt.prompt,
        assessment_knowledgebase_name:
          this.chosenPrompt.assessment_knowledgebase_name,
        knowledge_base: this.chosenPrompt.knowledge_base,
        pass_fail: this.chosenPrompt.pass_fail,
        threshold: this.chosenPrompt.threshold,
        mandatory: this.chosenPrompt.mandatory,
        task: this.chosenPrompt.task,
        question_needed: this.chosenPrompt.question_needed,
        knowledge_base_items: this.chosenPrompt.knowledge_base_items,
        narrative_knowledge_base: this.chosenPrompt.narrative_knowledge_base,
        admin_notes: this.chosenPrompt.admin_notes,
        trigger_fail: this.chosenPrompt.trigger_fail,
        trigger_pass: this.chosenPrompt.trigger_pass,
        trigger_response: this.chosenPrompt.trigger_response,
        independent_prompt: this.chosenPrompt.independent_prompt,
        feedback_assessment_type: this.chosenPrompt.feedback_assessment_type,
        assessment_items: this.assessmentItems,
        optional_assessment: this.chosenPrompt.optional_assessment,

      };
      if (this.chosenPrompt.new_prompt) {
        api
          .addPrompt(loID, prompt_data)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess("Prompt saved successfully");
          })
          .catch();
      } else {
        api
          .updatePrompt(promptID, prompt_data)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess("Prompt saved successfully");
          })
          .catch();
      }
    },
    setCharacteristics(e) {
      console.log(e.target.value);
      this.characterCharacteristics = e.target.value;
    },
    saveLO(type) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("learning_object[status]", type);
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[admin_notes]", this.adminNotes);
      fb.append("learning_object[description]", this.description);
      // llm_chat as a card type needs to change on the backend model
      // To be more general
      fb.append("card_type", "llm_chat");
      fb.append("learning_object[learning_object_type]", `llm_chat`);
      fb.append("card[llm_type]", `${this.llmType}`);
      fb.append("card[name]", this.name);
      fb.append("card[description]", this.description);
      fb.append("card[admin_notes]", this.adminNotes);
      fb.append("card[general_knowledge_base]", this.generalKnowledgeBase);
      fb.append("card[narrative_knowledge_base]", this.narrativeKnowledgeBase);
      fb.append("card[character_name]", this.characterName);
      fb.append("card[character_age]", this.characterAge);
      fb.append("card[character_job]", this.characterJob);
      fb.append("card[characteristics]", this.characterCharacteristics);
      fb.append(
        "card[prompt_reaction_sentiment]",
        this.promptReactionSentiment
      );
      if (
        (this.llmType === "email" || this.llmType === "llm_email")
      ) {
        fb.append("card[email_iteration_limit]", this.llmEmailIterations);
        fb.append("card[cc_characters][]", this.ccCharacters);
      } else {
        fb.append("card[email_iteration_limit]", this.llmEmailIterations);
        fb.append("card[cc_characters][]", []);
      }
      if (
        (this.llmType === "chat" || this.llmType === "llm_chat") &&
        this.llmChatImageEnabled === true
      ) {
        fb.append("card[llm_chat_image_enabled]", this.llmChatImageEnabled);
        fb.append("card[llm_chat_image]", this.llmChatImage);
      } else {
        fb.append("card[llm_chat_image_enabled]", false);
      }
      if (this.llmType === "dialogic" || this.llmType === "llm_dialogic") {
        fb.append("card[dialogic_switch]", true);
        if (
          this.dialogicQuestionRequired &&
          this.chatContentId === "basic-information"
        ) {
          let dialogicQuestionsArr =
            document.querySelectorAll(".dialogic-question");
          dialogicQuestionsArr.forEach((question) => {
            fb.append("card[dialogic_questions][]", question.innerText);
          });
        } else {
          this.dialogicQuestions.forEach((question) => {
            fb.append("card[dialogic_questions][]", question);
          });
        }
      }
      fb.append("card[prompt_reaction_emotion]", this.promptReactionEmotion);
      if (this.$route.query.id) {
        api
          .updateLo(this.$route.params.id, this.$route.query.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "drafted"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
          })
          .catch();
      } else {
        api
          .createLearningObject(this.$route.params.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "drafted"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
          })
          .catch();
      }
    },
    publishLO(type) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("learning_object[status]", type);
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[admin_notes]", this.adminNotes);
      fb.append("learning_object[description]", this.description);
      fb.append("card_type", "llm_chat");
      fb.append("card[llm_type]", `${this.llmType}`);
      fb.append("learning_object[learning_object_type]", "llm_chat");
      fb.append("card[name]", this.name);
      fb.append("card[description]", this.description);
      fb.append("card[admin_notes]", this.adminNotes);
      fb.append("card[general_knowledge_base]", this.generalKnowledgeBase);
      fb.append("card[narrative_knowledge_base]", this.narrativeKnowledgeBase);
      fb.append("card[character_name]", this.characterName);
      fb.append("card[character_age]", this.characterAge);
      fb.append("card[character_job]", this.characterJob);
      fb.append("card[characteristics]", this.characterCharacteristics);
      if (
        (this.llmType === "email" || this.llmType === "llm_email")
      ) {
        fb.append("card[email_iteration_limit]", this.llmEmailIterations);
        fb.append("card[cc_characters][]", this.ccCharacters);
      } else {
        fb.append("card[email_iteration_limit]", this.llmEmailIterations);
        fb.append("card[cc_characters][]", []);
      }
      if (this.llmType === "dialogic" || this.llmType === "llm_dialogic") {
        fb.append("card[dialogic_switch]", true);
        if (
          this.dialogicQuestionRequired &&
          this.chatContentId === "basic-information"
        ) {
          let dialogicQuestionsArr =
            document.querySelectorAll(".dialogic-question");
          dialogicQuestionsArr.forEach((question) => {
            fb.append("card[dialogic_questions][]", question.innerText);
          });
        } else {
          this.dialogicQuestions.forEach((question) => {
            fb.append("card[dialogic_questions][]", question);
          });
        }
      }
      if ((this.llmType === "chat" || this.llmType === "llm_chat") && this.llmChatImageEnabled === true) {
        fb.append("card[llm_chat_image_enabled]", this.llmChatImageEnabled);
        fb.append("card[llm_chat_image]", this.llmChatImage);
      } else {
        fb.append("card[llm_chat_image_enabled]", false);
      }
      fb.append(
        "card[prompt_reaction_sentiment]",
        this.promptReactionSentiment
      );
      fb.append("card[prompt_reaction_emotion]", this.promptReactionEmotion);
      if (this.$route.query.id) {
        api
          .updateLo(this.$route.params.id, this.$route.query.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.showSuccess(
              type === "published"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            utilFunctionService.hideLoader();
            if (type == "published") {
              window.location.reload();
            }
          });
      } else {
        api
          .saveLo(this.$route.params.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.showSuccess(
              type === "published"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            utilFunctionService.hideLoader();
            if (type == "published") {
              window.location.reload();
            }
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      }
    },
    submitMessage() {
      this.messages[this.messages.length - 1].answer = this.userAnswer;
      this.submitQuestion();
      this.userAnswer = "";
    },
    populateNarrativeKnowledgeBase() {
      let populatedNarrativeKnowledgeBase = [];
      populatedNarrativeKnowledgeBase.push(this.narrativeKnowledgeBase);
      this.promptData.forEach((prompt) => {
        populatedNarrativeKnowledgeBase.push(prompt.narrative_knowledge_base);
      });
      return populatedNarrativeKnowledgeBase;
    },
    submitQuestion() {
      utilFunctionService.showLoader();
      let question = this.messages[this.messages.length - 1].question;
      const answer = this.messages[this.messages.length - 1].answer;
      if (this.llmType === "llm_email") question = this.emailBody;
      this.lastQuestion = question;
      this.lastAnswer = answer;
      let dialogicQuestionsObj = [];
      this.dialogicQuestions.forEach((dialogicQuestion) => {
        dialogicQuestionsObj.push({ question: dialogicQuestion });
      });
      let genKnowledgeBase = [];
      genKnowledgeBase.push(this.generalKnowledgeBase);
      let narrKnowledgeBase = this.populateNarrativeKnowledgeBase();
      this.loading = true;
      this.hasStarted = true;
      this.promptData[0].threshold = 0;
      let payload = {
        character_data: {
          age: this.characterAge,
          name: this.characterName,
          job: this.characterJob,
          characteristic: this.characterCharacteristics,
          student_name: "Admin User",
        },
        student_id: 1,
        lo_id: 1,
        data: {
          narrative_knowledge_base: narrKnowledgeBase,
          general_knowledge_base: genKnowledgeBase,
          prompt_data: this.promptData,
          question: question,
          student_answer: answer,
          number_attempts: this.numberOfAttempts,
          attempt_limit: this.attemptsLimit,
          prompt_reaction_sentiment: this.promptReactionSentiment,
          prompt_reaction_emotion: this.promptReactionEmotion,
          dialogic_questions: dialogicQuestionsObj,
        },
        dialogic: this.dialogicQuestionRequired,
      };
      if (this.llmType === "llm_email") {
        api
          .submitLlmEmailResponse(payload)
          .then((res) => {
            console.log(res);
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      } else {
        api
          .submitLlmChatQuestion(payload)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      }
    },
    submitAssessmentRequest() {
      let dialogicQuestionsObj = [];
      this.dialogicQuestions.forEach((dialogicQuestion) => {
        dialogicQuestionsObj.push({ question: dialogicQuestion });
      });
      this.assessmentLoading = true;
      let payload = {
        student_id: 1,
        lo_id: 1,
        data: {
          narrative_knowledge_base: this.narrativeKnowledgeBase,
          general_knowledge_base: this.generalKnowledgeBase,
          prompt_data: this.promptData,
          number_attempts: this.numberOfAttempts,
          attempt_limit: this.attemptsLimit,
          dialogic_questions: dialogicQuestionsObj,
        },
        dialogic: this.dialogicQuestionRequired,
      };
      api.submitLlmChatAssessment(payload).then((res) => {
        console.log(res);
        this.assessmentLoading = false;
      });
    },
    saveNarrativeKnowledgeBase() {
      let narrativeKnowledgeBaseTiers = document.querySelectorAll(
        ".narrativeKnowledgeBaseItem"
      );
      this.narrativeKnowledgeBase = [];
      narrativeKnowledgeBaseTiers.forEach((tier) => {
        this.narrativeKnowledgeBase.push(tier.value);
      });
      this.saveLO("drafted", 1);
    },
    editKnowledgeBaseItem(index) {
      const kbItem = this.$refs[`kbitem-${index}`][0];
      const kbItemButton = this.$refs[`kbedit-${index}`][0];
      kbItemButton.classList.add("kb-edit-active");
      kbItemButton.innerText = "Save Edit";
      kbItem.contentEditable = true;
      kbItem.focus();
      kbItemButton.addEventListener("click", () => {
        kbItem.contentEditable = false;
        kbItemButton.innerText = "Edit";
        kbItemButton.classList.remove("kb-edit-active");
        this.chosenPrompt.knowledge_base_items[index] = kbItem.innerText;
        this.savePrompt();
      });
    },
    deleteKnowledgeBaseItem(index) {
      this.chosenPrompt.knowledge_base_items.splice(index, 1);
    },
    addNarrativeKnowledgeBase() {
      let narrativeKnowledgeBaseTiers = document.querySelectorAll(
        ".narrativeKnowledgeBaseItem"
      );
      this.narrativeKnowledgeBase = [];
      narrativeKnowledgeBaseTiers.forEach((tier) => {
        this.narrativeKnowledgeBase.push(tier.value);
      });
      this.narrativeKnowledgeBase.push("");
    },
    addDialogicQuestion() {
      this.dialogicQuestions.push("");
      this.dialogicQuestionKey++;
    },
    removeDialogicQuestion() {
      this.dialogicQuestions.pop();
      this.dialogicQuestionKey++;
    },
    resetLlmConversation() {
      let payload = {
        student_id: 1,
        lo_id: 1,
      };
      api.resetLlmConversation(payload).then((res) => {
        console.log(res);
        this.assessmentLoading = false;
      });
    },
    uploadImage(event) {
      this.uploadImageStatus = true;
      console.log(event.target.files);
      this.llmChatImage = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.llmChatImage);
      _reader.onload = (e) => {
        this.image = e.target.result;
      };
    },
    refreshData() {
      const cardDetail = this.llmData.attributes.card_detail.attributes;
      this.name = cardDetail.title;
      this.adminNotes = cardDetail.admin_notes;
      this.description = cardDetail.description;
      this.llmType = this.llmCardType;
      this.narrativeKnowledgeBase = cardDetail.narrative_knowledge_base;
      this.narrativeKnowledgeBaseAmount = this.narrativeKnowledgeBase.length;
      this.generalKnowledgeBase = cardDetail.general_knowledge_base;
      this.characterName = cardDetail.character_name;
      this.characterAge = cardDetail.character_age;
      this.characterJob = cardDetail.character_job;
      this.characterCharacteristics = cardDetail.characteristics;
      this.promptReactionSentiment = cardDetail.prompt_reaction_sentiment;
      this.promptReactionEmotion = cardDetail.prompt_reaction_emotion;
      this.emailToCharacter = this.characterName;
      this.emailFromCharacter = this.studentName;
      this.llmChatImageEnabled = cardDetail.llm_chat_image_enabled;
      this.llmChatImageURL = cardDetail.chat_image_url;
      this.llmEmailIterations = cardDetail.email_iteration_limit;
      if (this.llmType === 'email' || this.llmType === 'llm_email') {
        this.ccCharacters = cardDetail.cc_character[0].split(",") || [];
      }
      this.messages = [
        {
          question: "",
          answer: "",
          response: "",
        },
      ];
      if (this.llmType === "dialogic" || this.llmType === "llm_dialogic") {
        this.dialogicQuestionRequired = cardDetail.dialogic_switch;
        this.dialogicQuestions = cardDetail.dialogic_questions || [];
        if (
          this.dialogicQuestionRequired &&
          this.messages.length > 0 &&
          this.dialogicQuestions.length > 0
        ) {
          this.messages[this.messages.length - 1].question =
            this.dialogicQuestions[0];
        }
      }
      if (cardDetail.prompts) {
        this.promptData = cardDetail.prompts;
      } else {
        this.promptData = [];
      }
      this.switchTab("basic-information");
    },
    editAssessmentItem(index) {
      // This method needs to be worked around as the event listener
      // Removes the one already attached to the edit button
      const assessmentItemLabel = this.$refs[`assessment-item-label-${index}`][0];
      const assessmentItemDescription = this.$refs[`assessment-item-description-${index}`][0];
      const assessmentItemLevel = this.$refs[`assessment-item-level-${index}`][0];
      const assessmentItemButton = this.$refs[`assessment-item-${index}`][0];
      assessmentItemButton.classList.add("kb-edit-active");
      assessmentItemButton.innerText = "Save Edit";
      assessmentItemLabel.contentEditable = true;
      assessmentItemDescription.contentEditable = true;
      assessmentItemLevel.contentEditable = true;
      assessmentItemLabel.focus();
      assessmentItemButton.addEventListener("click", () => {
        assessmentItemLabel.contentEditable = false;
        assessmentItemDescription.contentEditable = false;
        assessmentItemLevel.contentEditable = false;
        assessmentItemButton.innerText = "Edit";
        assessmentItemButton.classList.remove("kb-edit-active");
        this.savePrompt();
      }, { once: true });
    },
    deleteAssessmentItem(index) {
      delete this.assessmentItems[index]
      this.assessmentItemsKey++;
      this.savePrompt();
    },
    saveAssessmentItem() {
      let assessmentItemLength = Object.keys(this.assessmentItems).length;
      this.assessmentItems[assessmentItemLength] = this.newItem;
      this.newItem = {
        label: "",
        description: "",
        assessment_level: 0,
      }
      this.addingNewAssessmentItem = false;
      this.savePrompt();
    },
    cancelAssessmentItem() {
      this.newItem = {
        label: "",
        description: "",
        assessment_level: 0,
      }
      this.addingNewAssessmentItem = false;
    },
  },
  mounted() {
    console.log(this.llmData)
    if (this.llmData !== "" && this.$store.state.newLo === false) {
      // this.refreshData();
    }
    document.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && this.userAnswer !== "" && !this.loading) {
        this.submitMessage();
      }
    });
    if (this.llmCardType && this.llmType === "") {
      console.log("llm type is empty. Setting it now.");
      this.llmType = this.llmCardType;
      // this.refreshData();
    }
  },
};
</script>

<style lang="scss">
.knowledge-base-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}
.knowledge-base-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.knowledge-base-item h5 {
  font-weight: bold;
}

.kb-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-area {
  width: 400px;
  font-family: sans-serif;
  height: 200px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}
.prompts-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.side-menu {
  display: flex;
  flex-direction: column;
  width: 20%;
  border-right: 1px solid black;
}
.menu-item {
  width: 85%;
  border: 1px solid black;
  border-radius: 5px;
  list-style-type: none;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
}
.menu-item:hover {
  background: black;
  color: white;
}
.prompts-edit {
  width: 80%;
  padding: 16px;
}
.prompts-container {
  width: 100%;
}
.prompts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.kb_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
}
.kb_form--item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}
.kb_form--itemhori {
  flex-direction: row;
}
.kb_form--item label,
p {
  margin-bottom: 8px;
  font-weight: bold;
}
.kb_form--item textarea {
  width: 100%;
  font-family: sans-serif;
  height: 100px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}
.kb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.kb-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}
.kb-input input {
  width: 50%;
  padding: 8px;
  border-radius: 5px;
}
.btn {
  border-radius: 5px;
  border: 1px solid black;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  height: 50px;
}
.btn:hover {
  background: black;
  color: white;
}
.side-prompt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 0 0;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}
.delete-button {
  background: rgb(255, 80, 80);
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid black;
  line-height: 0;
}
.delete-button:hover {
  background: white;
  color: rgb(199, 61, 61);
  border: 1px solid rgb(199, 61, 61);
}
.kb_item--column {
  display: flex;
  flex-direction: column;
}

.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
}
.bubble {
  border-radius: 15px;
  padding: 8px;
  margin-bottom: 16px;
  width: 50%;
}
.learner-bubble {
  background: green;
  color: white;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
}
.robot-bubble {
  background: blue;
  color: white;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
}
.input-area {
  color: black;
  width: 400px;
}
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}
.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}
.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.up-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}
.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}

.prompt-reaction-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.prompt-reaction-container .prompts-body {
  justify-content: flex-start;
  gap: 16px;
}

.prompt-reaction-container button {
  width: 250px;
}

.kb-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.entry-kb {
  width: 75%;
}

.edit-kb {
  background: rgb(37, 61, 240);
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.delete-kb {
  background: rgb(255, 80, 80);
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.kb-edit-active {
  background: rgb(37, 145, 240) !important;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.kb-edit-active:hover {
  background: rgb(13, 128, 228) !important;
  color: white;
  border: 1px solid rgb(13, 128, 228) !important;
}
.form-checkbox {
  flex-direction: row;
}

.kb-feedback-request {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.kb-feedback-request-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 32px;
  margin: 16px 0 16px 16px;
}

.feedback-item-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  .btn {
    margin-right: 16px;
  }
}

.feedback-request-help-container {
  display: flex;
  flex-direction: column;
}

.feedback-request-help-container .feedback-request-help {
  line-height: 1.4;
}

.new-assessment-item-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 12px;
}

.new-assessment-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.divider {
  width: 100%;
  height: 2px;
  margin: 24px 0;
  opacity: 0.5;
  background: #212121;
}

.assessmentKBTitle {
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 2px solid black;
}

.assessmentFeedbackTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 8px;
  padding-bottom: 32px;
  border-bottom: 2px solid black;
}

.assessment-feedback-empty p {
  line-height: 1.5;
}

.cc-character {
  display: flex;
  flex-direction: row;
}

.cc-character span {
  margin-left: 16px;
  cursor:pointer;
}

.cc-character span:hover {
  font-weight: bold;
}
</style>



