var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Organisation" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchCust,
                      expression: "searchCust",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:400ms",
                      value: _vm.searchCustomer,
                      expression: "searchCustomer",
                      arg: "400ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "aria-describedby": "searchCust",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchCust },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchCust = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("createProvision", {
                      customer_id: "",
                      tab: "address",
                    })
                  },
                },
              },
              [_vm._v("Add New Section")]
            ),
          ]),
          _vm.totalCustomers > 1 && !_vm.searchCust
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " sections in the system."
                ),
              ])
            : _vm.totalCustomers <= 1 && !_vm.searchCust
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " section in the system."
                ),
              ])
            : _vm.totalCustomers > 1 && _vm.searchCust
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " sections in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " sections in the search result."
                ),
              ]),
        ]),
        _vm.coursesLists.length !== 0
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", { staticClass: "data-table data-column-5" }, [
                _c("div", { staticClass: "data-head provision-data-head" }, [
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Section "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "sections.name" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("sections.name", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "sections.name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("sections.name", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Total Learners "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "sections.user_sections_count" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.user_sections_count",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "sections.user_sections_count" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.user_sections_count",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Learner Done "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "sections.elm_completed_count" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.elm_completed_count",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "sections.elm_completed_count" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.elm_completed_count",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Instructors "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn ===
                              "sections.section_instructors_count" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.section_instructors_count",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn ===
                              "sections.section_instructors_count" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.section_instructors_count",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Start Date "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "sections.start_date" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.start_date",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "sections.start_date" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "sections.start_date",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" End Date "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "sections.end_date" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("sections.end_date", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "sections.end_date" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("sections.end_date", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Customer "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "customers.name" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("customers.name", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "customers.name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("customers.name", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Course "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "customer_courses.name" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "customer_courses.name",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "customer_courses.name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "customer_courses.name",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("ACTIONS")]),
                ]),
                _c(
                  "div",
                  { staticClass: "data-items" },
                  _vm._l(_vm.coursesLists, function (course) {
                    return _c(
                      "div",
                      {
                        key: course.id,
                        staticClass: "data-row provision-data-row",
                      },
                      [
                        _c("div", { staticClass: "data-col" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$modal.show("createProvision", {
                                    customer_id: course.id,
                                    tab: "address",
                                  })
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(course.attributes.name) +
                                  " [ID: " +
                                  _vm._s(course.id) +
                                  "]"
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(_vm._s(course.attributes.user_sections_count)),
                        ]),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(_vm._s(course.attributes.elm_completed_count)),
                        ]),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(
                            _vm._s(course.attributes.section_instructors_count)
                          ),
                        ]),
                        course.attributes.start_date == false
                          ? _c("div", { staticClass: "data-col text-center" }, [
                              _vm._v("-"),
                            ])
                          : _vm._e(),
                        course.attributes.end_date == false
                          ? _c("div", { staticClass: "data-col text-center" }, [
                              _vm._v("-"),
                            ])
                          : _vm._e(),
                        course.attributes.start_date != false
                          ? _c("div", { staticClass: "data-col text-center" }, [
                              _vm._v(_vm._s(course.attributes.start_date)),
                            ])
                          : _vm._e(),
                        course.attributes.end_date != false
                          ? _c("div", { staticClass: "data-col text-center" }, [
                              _vm._v(_vm._s(course.attributes.end_date)),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(_vm._s(course.attributes.customer_name)),
                        ]),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(_vm._s(course.attributes.course_name)),
                        ]),
                        _c("div", { staticClass: "data-col data-action" }, [
                          _c("div", { staticClass: "data-dropdown" }, [
                            _vm._m(1, true),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                              },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show(
                                              "createProvision",
                                              {
                                                customer_id: course.id,
                                                tab: "address",
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-error",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteProvision(
                                              course.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Delete")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (pages) {
                            return _c(
                              "li",
                              { key: pages, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    class: [pages === _vm.page ? "active" : ""],
                                    on: {
                                      click: function ($event) {
                                        return _vm.getProvisions(pages)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(pages))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.coursesLists.length === 0 && !_vm.searchCust && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no customer in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("createProvision", {
                          customer_id: "",
                        })
                      },
                    },
                  },
                  [_vm._v("Add a new customer")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.coursesLists.length === 0 && _vm.searchCust
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c("createProvision"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("Provisioning")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-menu" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }