var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "learner-container" }, [
    _c("div", { staticClass: "header" }, [
      _c("h2", {
        staticClass: "learn-info",
        domProps: {
          innerHTML: _vm._s(
            _vm.userLearnObj.attributes.learning_object.attributes.description
          ),
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "messages-container" },
      _vm._l(_vm.messages, function (message, index) {
        return _c(
          "div",
          { key: index, staticClass: "messages-area" },
          [
            _c("transition", { attrs: { name: "up" } }, [
              message.question
                ? _c("div", { staticClass: "bubble robot-bubble" }, [
                    _c("div", { staticClass: "character-container" }, [
                      _vm._v(" " + _vm._s(_vm.characterName) + " "),
                    ]),
                    _c("div", { staticClass: "character-response" }, [
                      _vm._v(" " + _vm._s(message.question) + " "),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("transition", { attrs: { name: "up" } }, [
              message.answer
                ? _c("div", { staticClass: "bubble learner-bubble" }, [
                    _vm._v(" " + _vm._s(message.answer) + " "),
                  ])
                : _vm._e(),
            ]),
            _c("transition", { attrs: { name: "up" } }, [
              message.response
                ? _c(
                    "div",
                    { staticClass: "bubble robot-bubble" },
                    [
                      _c("div", { staticClass: "character-container" }, [
                        _c("h4", { staticClass: "character-name" }, [
                          _vm._v(_vm._s(_vm.characterName)),
                        ]),
                      ]),
                      _vm._l(
                        message.response,
                        function (characterResponse, index) {
                          return _c("div", { key: index }, [
                            _c("div", { staticClass: "character-response" }, [
                              _vm._v(
                                " " + _vm._s(characterResponse.response) + " "
                              ),
                            ]),
                          ])
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "modal-body m-auto" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "container" },
        _vm._l(_vm.feedbackData, function (feedback, name, index) {
          return _c("div", { key: feedback[index], staticClass: "row" }, [
            _c("h2", [_vm._v(_vm._s(name))]),
            _c("p", { staticClass: "feedback-result" }, [
              _vm._v(_vm._s(feedback.result)),
            ]),
            _c("p", [_vm._v(_vm._s(feedback.feedback))]),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "modal-btn pb-5" }, [
        _c(
          "button",
          {
            staticClass: "btn secondary",
            on: {
              click: function ($event) {
                return _vm.$modal.hide("llmFeedbackModal")
              },
            },
          },
          [_vm._v(" Close ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title" }, [
      _c("div", { staticClass: "final-feedback-title" }, [
        _c("h1", [_vm._v("Conversation Assessment")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }