var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lo-sidebar" }, [
    _c("div", { staticClass: "iteration-items" }, [
      _c("div", { staticClass: "iteration-detail-title" }, [
        _vm._v(
          _vm._s(_vm.adminLearnerLODetails.attributes.last_name) +
            ", " +
            _vm._s(_vm.adminLearnerLODetails.attributes.first_name)
        ),
      ]),
      _c(
        "div",
        { staticClass: "detail-content my-3" },
        [
          _c(
            "router-link",
            {
              attrs: { to: `/learner-lo-list/${_vm.adminLearnerLODetails.id}` },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn secondary btn-small",
                  attrs: { type: "button" },
                },
                [_vm._v("back to LO list ")]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }