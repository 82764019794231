import axios from 'axios'
import { utilFunctionService } from "@/utils/utils.service"
const SERVER_URL = process.env.VUE_APP_URL
const instance = axios.create({
  baseURL: SERVER_URL
});

export default {

  async execute(method, resource, data, config) {
    let accessToken = utilFunctionService.getAuthToken();

    return instance({
      method: method,
      url: resource,
      data,
      headers: {
        Authorization: `${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token"
      },
      ...config
    })
  },
  async executeFormData(method, resource, data, config) {
    let accessToken = utilFunctionService.getAuthToken();

    return instance({
      method: method,
      url: resource,
      data,
      headers: {
        Authorization: `${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      ...config
    })
  },

  loginUser(user) {
    let _result = this.execute('POST', '/v1/users/login.json', { user })
    const result = JSON.stringify(_result["data"])
    utilFunctionService.setLocalStorageService("user", result)
    return _result
  },

  saveCharacter(character) {
    return this.executeFormData('POST', `/admin/v1/characters.json`, character);
  },

  saveOrganisation(organization) {
    return this.executeFormData('POST', `/admin/v1/organizations.json`, organization);
  },

  getCustomers(name) {
    return this.execute('GET', `/admin/v1/customers/auto_comp_data.json?page=1&search=${name}`, null);
  },

  saveWorld(name, desc, type, customerId, world_organizations_attributes) {
    if (type) {
      const world = {
        name: name,
        description: desc,
        customer_id: customerId,
        is_private: type,
        world_organizations_attributes: world_organizations_attributes
      }
      return this.execute('POST', `/admin/v1/worlds.json`, { world });
    } else {
      const world = {
        name: name,
        description: desc,
        is_private: type,
        world_organizations_attributes: world_organizations_attributes
      }
      return this.execute('POST', `/admin/v1/worlds.json`, { world });
    }

  },

  editWorld(id, name, desc, type, customerId, world_organizations_attributes) {
    if (type) {
      const world = {
        id: parseInt(id),
        name: name,
        description: desc,
        customer_id: customerId,
        is_private: type,
        world_organizations_attributes: world_organizations_attributes
      }
      return this.execute('PATCH', `/admin/v1/worlds/${id}.json`, { world });
    } else {
      const world = {
        id: parseInt(id),
        name: name,
        description: desc,
        is_private: type,
        world_organizations_attributes: world_organizations_attributes
      }
      return this.execute('PATCH', `/admin/v1/worlds/${id}.json`, { world });
    }

  },

  getAllWorlds(page, search, colName, order) {
    return this.execute('GET', `/admin/v1/worlds.json?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
  },

  deleteWorld(id) {
    return this.execute('DELETE', `/admin/v1/worlds/${id}.json`)
  },

  getWorldDetails(id) {
    return this.execute('GET', `/admin/v1/worlds/${id}.json`);
  },

  getWorldOrganisation(name) {
    return this.execute('GET', `/admin/v1/organizations.json?page=1&search=${name}`, null);
  },

  getRoles(name) {
    return this.execute('GET', `/admin/v1/world_roles/auto_comp_data.json?page=1&search=${name}`, null);
  },

  getIndustry(name) {
    return this.execute('GET', `/admin/v1/industries/auto_comp_data.json?page=1&search=${name}`, null);
  },

  getAllCharacters(page, search, colName, order) {
    return this.execute('GET', `/admin/v1/characters.json?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
  },

  deleteCharacter(id) {
    return this.execute('DELETE', `/admin/v1/characters/${id}.json`)
  },

  getOrganizations(name, character_id) {
    return this.execute('GET', `/admin/v1/organizations/assign_org_list.json?page=1&search=${name}&character_id=${character_id}`, null);
  },
  assign_organization_role(id, organization_character) {
    return this.executeFormData('POST', `/admin/v1/characters/${id}/assign_organization_role.json`, organization_character);
  },
  getAllOrganization(page, name, colName, order) {
    return this.execute('GET', `/admin/v1/organizations.json?page=${page}&search=${name}&sort_column=${colName}&sort_order=${order}`);
  },

  deleteOrg(id) {
    return this.execute('DELETE', `/admin/v1/organizations/${id}.json`)
  },

  deleteWorldOrg(id, worldOrgId) {
    return this.execute('DELETE', `/admin/v1/worlds/${id}/world_organizations/${worldOrgId}.json`)
  },

  getCharactersDetails(id) {
    return this.execute('GET', `/admin/v1/characters/${id}.json`);
  },

  updateCharacter(id, character) {
    return this.executeFormData('PATCH', `/admin/v1/characters/${id}.json`, character)
  },

  getOrganisationDetails(id) {
    return this.execute('GET', `/admin/v1/organizations/${id}.json`)
  },

  editOrganisation(id, organization) {
    return this.executeFormData('PATCH', `/admin/v1/organizations/${id}.json`, organization);
  },

  removeCharactersPhoto(id) {
    return this.executeFormData('DELETE', `/admin/v1/characters/${id}/remove_photo.json`, id);
  },

  removeOrganizationPhoto(id) {
    return this.executeFormData('DELETE', `/admin/v1/organizations/${id}/remove_photo.json`, id);
  },

  getWorld(search) {
    return this.execute('GET', `/admin/v1/worlds.json?search=${search}`);
  },

  getElmWorlds(search) {
    return this.execute('GET', `/admin/v1/worlds/lite_list.json?search=${search}`);
  },

  getDesigners(name, userType) {
    return this.execute('GET', `/admin/v1/users/users_list.json?page=1&user_type=${userType}&search=${name}`, null);
  },

  getSkills() {
    return this.execute('GET', `/admin/v1/global_skills.json`);
  },

  saveElm(data) {
    return this.execute('POST', `/admin/v1/learn_mods.json`, data);
  },

  editElm(data, id) {
    return this.execute('PATCH', `/admin/v1/learn_mods/${id}.json`, data);
  },

  getAllElms(page, search, colName, order) {
    return this.execute('GET', `/admin/v1/learn_mods.json?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
  },

  deleteElm(id) {
    return this.execute('DELETE', `/admin/v1/learn_mods/${id}.json`)
  },

  getAllDoc(page, name, colName, order, resource_type, pdf_only, csv_only) {
    resource_type = 'document'
    return this.execute('GET', `/admin/v1/global_resources.json?page=${page}&resource_type=${resource_type}&search=${name}&sort_column=${colName}&sort_order=${order}&pdf_only=${pdf_only}&csv_only=${csv_only}`);
  },
  saveglobalResource(character) {
    return this.executeFormData('POST', `/admin/v1/global_resources.json`, character);
  },
  deleteGlobalResource(id) {
    return this.execute('DELETE', `/admin/v1/global_resources/${id}.json`)
  },
  editGlobalResource(id, data) {
    return this.execute('PATCH', `/admin/v1/global_resources/${id}.json`, data)
  },
  saveGlobalVideo(Video) {
    return this.executeFormData('POST', `/admin/v1/global_videos.json`, Video);
  },
  editGlobalVideo(id, data) {
    return this.execute('PATCH', `/admin/v1/global_videos/${id}.json`, data)
  },
  deleteGlobalVideo(id) {
    return this.execute('DELETE', `/admin/v1/global_videos/${id}.json`)
  },
  getGlobalVideo(id) {
    return this.execute('GET', `/admin/v1/global_videos/${id}.json`)
  },
  getAllVideo(page, name, colName, order) {
    return this.execute('GET', `/admin/v1/global_videos.json?page=${page}&search=${name}&sort_column=${colName}&sort_order=${order}`);
  },
  getAssociateOrg(id) {
    return this.execute('GET', `/admin/v1/worlds/${id}/world_orgs.json`);
  },
  getElmLists(id, page) {
    return this.execute('GET', `/admin/v1/worlds/${id}/elm_lists.json?page=${page}`);
  },
  updateNote(id, data) {
    return this.execute('PATCH', `/admin/v1/learn_mods/${id}.json`, data);
  },
  getAllImages(page, name, colName, order, resource_type) {
    resource_type = 'image'
    return this.execute('GET', `/admin/v1/global_resources.json?page=${page}&resource_type=${resource_type}&search=${name}&sort_column=${colName}&sort_order=${order}`);
  },
  getElmDetails(id) {
    return this.execute('GET', `/admin/v1/learn_mods/${id}/learning_objects.json`);
  },
  saveLo(id, data) {
    return this.execute('POST', `/admin/v1/learn_mods/${id}/learning_objects.json`, data);
  },
  restoreLo(learn_mod_id, id, data) {
    return this.execute('POST', `/admin/v1/learn_mods/${learn_mod_id}/learning_objects/${id}/update_status.json`, data);
  },
  cloneObj(learn_mod_id, id) {
    return this.execute('POST', `/admin/v1/learn_mods/${learn_mod_id}/learning_objects/${id}/clone.json`);
  },
  updateLo(learn_mod_id, id, data) {
    return this.execute('PATCH', `/admin/v1/learn_mods/${learn_mod_id}/learning_objects/${id}.json`, data);
  },
  createLearningObject(id, data) {
    return this.execute('POST', `/admin/v1/learn_mods/${id}/learning_objects.json`, data);
  },
  getWorldCharacters(id) {
    return this.execute('GET', `/admin/v1/worlds/${id}/characters.json`);
  },
  getWorldCharactersLite(id) {
    return this.execute('GET', `/admin/v1/worlds/${id}/characters_lite.json`);
  },
  videoDetail(wistiaCode) {
    return this.execute('GET', `/admin/v1/global_videos/video_detail.json?wistia_code=${wistiaCode}`);
  },
  reorderCards(id, record) {
    return this.execute('POST', `/admin/v1/learn_mods/${id}/reorder_cards.json`, record);
  },
  reorderQuizQuestions(id, record) {
    return this.execute('POST', `/admin/v1/quiz_learn_objs/${id}/reorder_questions.json`, record);
  },
  deleteSliderImage(elmId, loId, sliderId) {
    return this.execute('DELETE', `/admin/v1/learn_mods/${elmId}/learning_objects/${loId}/remove_slider_image.json?slider_id=${sliderId}`)
  },
  publishElm(id) {
    return this.execute('POST', `/admin/v1/learn_mods/${id}/update_status.json`)
  },
  getElmDetail(id) {
    return this.execute('GET', `/admin/v1/learn_mods/${id}.json`)
  },
  getAllAssessment(page, name, colName, order) {
    return this.execute('GET', `/admin/v1/assessment_schemes.json?page=${page}&search=${name}&sort_column=${colName}&sort_order=${order}`);
  },
  createAssessmentScheme(data) {
    return this.executeFormData('POST', `/admin/v1/assessment_schemes.json`, data);
  },
  updateAssessmentScheme(id, data) {
    return this.execute('PATCH', `/admin/v1/assessment_schemes/${id}.json`, data);
  },
  deleteAssessment(id){
    return this.execute('DELETE', `admin/v1/assessment_schemes/${id}.json`);
  },
  getQuizQuestion(id){
    return this.execute('GET', `admin/v1/quiz_learn_objs/${id}.json`);
  },
  getAsstEntities(loId){
    return this.execute('GET', `admin/v1/learning_objects/${loId}/asst_entities.json`);
  },
  addQuizEntity(loId, data){
    return this.executeFormData('POST', `admin/v1/learning_objects/${loId}/asst_entities.json`, data);
  },
  genderList() {
    return this.execute('GET', `/admin/v1/genders.json`);
  },
  getQuizQuestionList(quiz_learn_obj_id){
    return this.execute('GET', `/admin/v1/quiz_learn_objs/${quiz_learn_obj_id}/quiz_questions.json`);
  },
  removeQuizQuestion(id){
    return this.execute('DELETE', `admin/v1/quiz_questions/${id}.json`);
  },
  addAssessmentValues(loId, data){
    return this.execute('POST', `admin/v1/asst_entities/${loId}/asst_entity_values.json`, data);
  },
  importCsv(id, file){
    return this.executeFormData('POST', `admin/v1/learning_objects/${id}/asst_entities/upload_csv.json`, file);
  },
  syncAssistantEntities(id){
    return this.executeFormData('POST', `admin/v1/learning_objects/${id}/asst_entities/sync_with_assistant.json`);
  },
  linkToDialogSkill(id){
   return this.execute('GET', `admin/v1/learning_objects/${id}/asst_entities/link_to_dialog_skill.json`);
  },
  exportCsv(id){
    return this.executeFormData('POST', `admin/v1/learning_objects/${id}/asst_entities/export_csv.json`);
  },
  updateAssessmentValues(id, data){
    return this.execute('PATCH', `admin/v1/asst_entity_values/${id}.json`, data);
  },
  createQuestion(id, data){
    return this.executeFormData('POST', `admin/v1/quiz_learn_objs/${id}/quiz_questions.json`, data);
  },
  setQuizQuestionFeedbacks(id, data){
    return this.executeFormData('POST', `admin/v1/quiz_questions/${id}/quiz_feedbacks.json`, data);
  },
  getOverallAssmntItem(id){
    return this.execute('GET', `admin/v1/learning_objects/${id}/overall_assmnt_items.json`);
   },
   updateQuizQuestion(id, data){
    return this.executeFormData('PATCH', `admin/v1/quiz_questions/${id}.json`, data);
  },
  watsonSetUp(data, id) {
  return this.executeFormData('PATCH', `admin/v1/asst_assistant_shells/${id}.json`, data);
  },
  watsonAssistantStatusCheck(form) {
  return this.execute('POST', `/admin/v1/watson_admin/watson_assistant_check`, form);
  },
  removeElmPhoto(id) {
    return this.executeFormData('DELETE', `/admin/v1/learn_mods/${id}/remove_photo.json`, id);
  },
  deleteBrandImage(id) {
    return this.executeFormData('DELETE', `/admin/v1/learn_mods/${id}/remove_brand_image.json`, id);
  },
  removeAssesstEntity(id){
  return this.execute('DELETE', `admin/v1/asst_entities/${id}.json`);
  },
  updateQuizFeedabck(id, data){
  return this.executeFormData('PATCH', `admin/v1/quiz_feedbacks/${id}.json`, data);
  },
  saveDashboardConfig(data) {
  return this.executeFormData('POST', `admin/v1/learner_dashboards.json`, data);
  },
  getDashboardConfig() {
  return this.executeFormData('GET', `admin/v1/learner_dashboards.json`);
  },
  editDashboardConfig(id, data) {
  return this.executeFormData('PATCH', `admin/v1/learner_dashboards/${id}.json`, data);
  },
  getAssessmentScheme(id){
  return this.execute('GET', `admin/v1/assessment_schemes/${id}.json`);
  },
  createOverallAssesScheme(id, data){
  return this.executeFormData('POST', `admin/v1/learning_objects/${id}/overall_assmnt_items.json`, data);
  },
  updateOverallAssesScheme(id, data){
  return this.executeFormData('PATCH', `admin/v1/overall_assmnt_items/${id}.json`, data);
  },
  removeAssesSchemeValues(id){
  return this.execute('DELETE', `admin/v1/asst_entity_values/${id}.json`);
  },
  createEvaluationScheme(id, data){
  return this.execute('POST', `admin/v1/quiz_questions/${id}/entity_evaluations.json`,data);
  },
  getQuizQuestionEntityEvalList(id){
  return this.execute('GET', `admin/v1/quiz_questions/${id}/entity_evaluations.json`);
  },
  updateEvalationList(id){
  return this.execute('PATCH', `admin/v1/entity_evaluations/${id}.json`);
  },
  deleteEvalationList(id){
  return this.execute('DELETE', `admin/v1/entity_evaluations/${id}.json`);
  },
  getTrainingInputs(id){
  return this.execute('GET', `admin/v1/learning_objects/${id}/nlu_training_inputs.json`);
  },
  updateTrainingInputs(id, data){
  return this.execute('PATCH', `admin/v1/nlu_training_inputs/${id}.json`, data);
  },
  analyseTrainingInput(id) {
  return this.execute('POST', `admin/v1/email_learn_objs/${id}/nlu_analysis.json`);
  },
  getNluEnrichments(id) {
  return this.execute('GET', `admin/v1/email_learn_objs/${id}/nlu_enrichment_items.json`);
  },
  addEmailIntent(id , data) {
  return this.execute('POST', `admin/v1/learning_objects/${id}/asst_intents.json`, data);
  },
  getIntentLists(id) {
  return this.execute('GET', `admin/v1/learning_objects/${id}/asst_intents.json`);
  },
  destroyIntent(id) {
  return this.execute('DELETE', `admin/v1/asst_intents/${id}.json`);
  },
  addIntentData(id, data) {
  return this.executeFormData('PATCH', `admin/v1/asst_intents/${id}.json`, data);
  },
  importIntentCsv(id, file) {
  return this.executeFormData('POST', `admin/v1/learning_objects/${id}/asst_intents/upload_csv.json`, file);
  },
  syncAssistantIntenties(id) {
  return this.executeFormData('POST', `admin/v1/learning_objects/${id}/asst_intents/sync_with_assistant.json`);
  },
  intentLinkToDialogSkill(id) {
  return this.executeFormData('GET', `admin/v1/learning_objects/${id}/asst_intents/link_to_dialog_skill.json`);
  },
  addEmailEntity(id, data) {
  return this.execute('POST', `admin/v1/learning_objects/${id}/asst_entities.json`, data);
  },
  entityLists(id) {
  return this.execute('GET', `admin/v1/learning_objects/${id}/asst_entities.json`);
  },
  destroyEntent(id) {
  return this.execute('DELETE', `admin/v1/asst_entities/${id}.json`);
  },
  importSkillFromAssistant(id){
  return this.execute('GET', `admin/v1/chat_learn_objs/${id}/import_skills.json`);
  },
  getChatSkillList(id){
  return this.execute('GET', `admin/v1/learning_objects/${id}/chat_skills.json`);
  },
  getAssessmentSkill(id){
  return this.execute('GET', `admin/v1/assessment_schemes/${id}.json`);
  },
  getSkillItemContent(id){
    return this.execute('GET', `admin/v1/chat_skill_assmnt_items/${id}.json`);
  },
  updatedSkillItems(id, data){
  return this.execute('PATCH', `admin/v1/chat_skill_assmnt_items/${id}.json`, data);
  },
  updatedMissedSkillItems(id, data){
  return this.execute('PATCH', `admin/v1/chat_skill_assmnt_misseds/${id}.json`, data);
  },
  addIntroductionDialogic(id, data) {
  return this.execute('POST', `admin/v1/dialogic_learn_objs/${id}/add_introduction.json`,data);
  },
  addConclusionDialogic(id, data) {
  return this.execute('POST', `admin/v1/dialogic_learn_objs/${id}/add_conclusion.json`,data);
  },
  getDialogicList(id) {
  return this.execute('GET', `admin/v1/dialogic_learn_objs/${id}.json`);
  },
  addQuestions (id , data) {
  return this.execute('POST', `admin/v1/dialogic_learn_objs/${id}/dialogic_questions.json`,data);
  },
  updateQuestions (id , data) {
  return this.execute('PATCH', `admin/v1/dialogic_questions/${id}.json`,data);
  },
  getQuestions (id) {
  return this.execute('GET', `admin/v1/dialogic_learn_objs/${id}/dialogic_questions.json`);
  },
  submitLlmChatQuestion(data) {
    return this.execute('POST', `admin/v1/submit_llm_answer`, data);
  },
  submitLlmChatAssessment(data) {
    return this.execute('POST', `admin/v1/submit_llm_assessment`, data);
  },
  submitLlmEmailResponse(data) {
    return this.execute('POST', `admin/v1/submit_email_llm_answer`, data);
  },
  resetLlmConversation(data) {
    return this.execute('POST', `admin/v1/reset_llm_conversation`, data);
  },
  addPrompt(id, data) {
    return this.execute('POST', `admin/v1/llm_chat_learn_objs/${id}/llm_prompts.json`, data);
  },
  deletePrompt(id) {
    return this.execute('DELETE', `admin/v1/llm_prompts/${id}.json`);
   },
  updatePrompt(id, data) {
    return this.execute('PATCH', `admin/v1/llm_prompts/${id}.json`, data);
  },
  getPrompts(id) {
    return this.execute('GET', `admin/v1/llm_chat_learn_objs/${id}/llm_prompts.json`);
  },
  reorderDialogicQuestions(id, record) {
    return this.execute('POST', `/admin/v1/dialogic_learn_objs/${id}/reorder_questions.json`, record);
  },
  getMissedAssessmentSkill (id) {
  return this.execute('GET', `admin/v1/chat_skills/${id}/chat_skill_assmnt_misseds.json`);
  },
  getSkillAsstItemList(id){
    return this.execute('GET', `admin/v1/chat_skills/${id}/chat_skill_assmnt_items.json`);
  },
  createsDialogicAssessment (id,fb) {
    return this.execute('POST', `admin/v1/key_topics/${id}/dialogic_assmnt_items.json`,fb);
  },
  getDialogicAssessment (id) {
    return this.execute('GET', `admin/v1/key_topics/${id}/dialogic_assmnt_items.json`);
  },
  updateDialogicAssessment (id,fb) {
    return this.execute('PATCH', `admin/v1/dialogic_assmnt_items/${id}.json`,fb);
  },
  createsMissedAssessment (id , fb) {
    return this.execute('POST', `admin/v1/key_topics/${id}/missed_assmnt_items.json`,fb);
  },
  updatedMissedAssessment (id , fb) {
    return this.execute('PATCH', `admin/v1/missed_assmnt_items/${id}.json`,fb);
  },
  getMissedAssessment (id) {
    return this.execute('GET', `admin/v1/key_topics/${id}/missed_assmnt_items.json`);
  },
  getEmailResList(id){
  return this.execute('GET', `admin/v1/email_learn_objs/${id}/email_responses.json`);
  },
  postEmailResName(id, data){
  return this.executeFormData('POST', `admin/v1/email_learn_objs/${id}/email_responses.json`, data);
  },
  addEmailVariation(id, data){
  return this.execute('PATCH', `admin/v1/email_responses/${id}.json`, data);
  },
  updateEvaluationScheme(id, data){
  return this.execute('PATCH', `admin/v1/entity_evaluations/${id}.json`, data);
  },
  deleteEvaluationScheme(id){
  return this.execute('DELETE', `admin/v1/entity_evaluations/${id}.json`);
  },
  getAssesSchemeValues(id){
  return this.execute('GET', `admin/v1/asst_entity_values/${id}.json`);
  },
  getChatLearingObj(id){
  return this.execute('GET', `admin/v1/chat_learn_objs/${id}.json`);
  },
  postResFormula(id, data){
  return this.execute('POST', `admin/v1/email_responses/${id}/response_formulas.json`, data);
  },
  interstitialContent(id) {
  return this.execute('GET', `admin/v1/email_learn_objs/${id}/interstitial_contents.json`);
  },
  deleteInterstitialContent(id) {
  return this.execute('DELETE', `admin/v1/interstitial_contents/${id}.json`);
  },
  saveInterstitialContent(data, id) {
  return this.execute('POST', `admin/v1/email_learn_objs/${id}/interstitial_contents.json`, data);
  },
  testQuizQuestion(data) {
  return this.executeFormData('POST', `admin/v1/test_quizzes.json`, data);
  },
  postTestChat(id, data) {
  return this.executeFormData('POST', `admin/v1/learning_objects/${id}/test_chats.json`, data);
  },
  postTestQuestion(id, data){
  return this.executeFormData('POST', `admin/v1/learning_objects/${id}/test_chat_messages.json`, data);
  },
  updateResFormulas(id, data){
  return this.execute('PATCH', `admin/v1/response_formulas/${id}.json`, data);
  },
  postQaResFormula(id, data){
  return this.execute('POST', `admin/v1/qa_conditions/${id}/qa_formulas.json`, data);
  },
  getQuizEvaluationId(id){
  return this.execute('GET', `admin/v1/test_quizzes/evaluation.json?lo_id=${id}`, null);
  },
  postQaCondition(id, data){
  return this.execute('PATCH', `admin/v1/qa_conditions/${id}.json`, data);
  },
  getQaCondition(id) {
  return this.execute('GET', `admin/v1/qa_conditions/${id}.json`);
  },
  editResponseFormula(id, data) {
  return this.execute('PATCH', `admin/v1/qa_formulas/${id}.json`, data);
  },
  saveQuizAdaptiveContent(data) {
  return this.execute('POST', `admin/v1/adaptive_contents.json`, data);
  },
  deleteAdaptiveContent(id) {
  return this.execute('DELETE', `admin/v1/adaptive_contents/${id}.json`);
  },
  emailTesting(data) {
  return this.execute('POST', `admin/v1/test_email_iterations.json`, data);
  },
  getExternalApiAssmntBlocks(id) {
    return this.execute('GET', `admin/v1/external_api_learn_objs/${id}/external_api_assmnt_blocks.json`);
  },
  deleteExternalApiAssmntBlock(id) {
    return this.execute('DELETE', `admin/v1/external_api_assmnt_blocks/${id}.json`);
  },
  postNewExternalApiAssmntBlocks(data) {
    return this.execute('POST', `/admin/v1/external_api_assmnt_blocks.json`, data);
  },
  getExternalApiAssessItemsBlock(id) {
    return this.execute('GET', `admin/v1/external_api_assmnt_blocks/${id}/external_api_assmnt_items.json`);
  },
  updateExternalApiAssmntBlocks(data, id) {
    return this.execute('PATCH', `admin/v1/external_api_assmnt_blocks/${id}.json`, data);
  },
  postAssmntBlockDesc( data, id) {
    return this.execute('POST', `admin/v1/external_api_learn_objs/${id}/external_api_assmnt_blocks.json`, data);
  },
  postExternalApiAssmntFormula(id, assmntItemId, data) {
    return this.execute('POST', `admin/v1/external_api_assmnt_blocks/${id}/external_api_assmnt_items/${assmntItemId}/external_api_assmnt_item_formulas.json`, data);
  },
  destroyExternalApiAssmntFormula(id, assmntItemId, assmntFormulaId) {
    return this.execute('DELETE', `admin/v1/external_api_assmnt_blocks/${id}/external_api_assmnt_items/${assmntItemId}/external_api_assmnt_item_formulas/${assmntFormulaId}.json`);
  },
  externalApiTesting(data) {
    return this.execute('POST', `admin/v1/test_external_api_evaluations.json`, data);
  },
  updateExternalApiAssessmentVariation(id, assmntItemId, data) {
    return this.execute('PATCH', `admin/v1/external_api_assmnt_blocks/${id}/external_api_assmnt_items/${assmntItemId}.json`, data);
  },
  getCodingTestBlocks(id) {
    return this.execute('GET', `admin/v1/coding_learn_objs/${id}/coding_tests.json`);
  },
  postCodingTestBlock(id, data) {
    return this.execute('POST', `admin/v1/coding_learn_objs/${id}/coding_tests.json`, data);
  },
  postCodingTestData( id, data) {
    return this.execute('POST', `admin/v1/coding_learn_objs/${id}/coding_tests.json`, data);
  },
  updateCodingTests(id, data) {
    return this.execute('PATCH', `admin/v1/coding_tests/${id}.json`, data)
  },
  deleteCodingTest(id) {
    return this.execute('DELETE', `admin/v1/coding_tests/${id}.json`);
  },
  updateCodingLO(id, data) {
    return this.execute('PATCH', `admin/v1/coding_learn_objs/${id}.json`, data)
  },
  postCodingConceptBlock(id, data) {
    return this.execute('POST', `admin/v1/coding_learn_objs/${id}/coding_concepts.json`, data);
  },
  getCodingConceptBlocks(id) {
    return this.execute('GET', `admin/v1/coding_learn_objs/${id}/coding_concepts.json`);
  },
  getSelectedConceptUnchangedConceptTests(id) {
    return this.execute('GET', `admin/v1/coding_concepts/${id}/coding_concept_tests.json`);
  },
  getCodingConceptTests(id) {
    return this.execute('GET', `admin/v1/coding_concepts/${id}.json`);
  },
  updateCodingConcepts(id, data) {
    return this.execute('PATCH', `admin/v1/coding_concepts/${id}.json`, data)
  },
  deleteCodingConceptTest(id) {
    return this.execute('DELETE', `admin/v1/coding_concept_tests/${id}.json`)
  },
  getEmailSkills(id) {
    return this.execute('GET', `admin/v1/email_learn_objs/${id}/email_skills.json`);
  },
  deleteEmailSKill(id) {
  return this.execute('DELETE', `admin/v1/email_skills/${id}.json`);
  },
  postNewSkills(data) {
  return this.execute('POST', `/admin/v1/global_skills.json`, data);
  },
  getEmailAssessmentSkill(id) {
    return this.execute('GET', `admin/v1/email_skills/${id}/email_assessment_items.json`);
  },
  editQuizAdaptiveContent(data, id) {
    return this.execute('PATCH', `admin/v1/adaptive_contents/${id}.json`, data);
  },
  editInterstitialContent(data, id) {
    return this.execute('PATCH', `admin/v1/interstitial_contents/${id}.json`, data);
  },
  updateNewSkills(data, id) {
    return this.execute('PATCH', `admin/v1/email_skills/${id}.json`, data);
  },
  postSkillDesc( data, id) {
    return this.execute('POST', `admin/v1/email_learn_objs/${id}/email_skills.json`, data);
  },
  postAssessmentResFormula(id , data) {
    return this.execute('POST', `admin/v1/email_assessment_items/${id}/assessment_formulas.json`, data);
  },
  updateAssessmentFormula(id, data) {
    return this.execute('PATCH', `admin/v1/assessment_formulas/${id}.json`, data);
  },
  updateAssessmentVariation(id ,data) {
    return this.execute('PATCH', `admin/v1/email_assessment_items/${id}.json`, data);
  },
  specificQuestionTest(id,data) {
    return this.execute('POST', `admin/v1/dialogic_learn_objs/${id}/dialogic_tests/specific_question_test.json`, data);
  },
  getEvaluationId(dialogic_learn_obj_id){
    return this.execute('POST', `admin/v1/dialogic_learn_objs/${dialogic_learn_obj_id}/dialogic_tests.json`);
   },
   dialogicTestAnswers(id,fb) {
    return this.execute('POST', `admin/v1/dialogic_tests/${id}/dialogic_test_answers.json`,fb);
   },
   qaFormula(id, charId) {
    return this.execute('GET', `admin/v1/email_learn_objs/${id}/qa_conditions/character_qa_condition.json?qa_condition[character_id]=${charId}`);
   },
   createOverAllAssessment(id) {
    return this.execute('POST', `admin/v1/email_learn_objs/${id}/create_overall_assmnt_items.json`);
   },
   createCodingOverAllAssessment(id) {
    return this.execute('POST', `admin/v1/coding_learn_objs/${id}/create_overall_assmnt_items.json`);
   },
   destroyResFormula(id) {
    return this.execute('DELETE', `admin/v1/response_formulas/${id}.json`);
   },
   destroyQaFormula(id) {
    return this.execute('DELETE', `admin/v1/qa_formulas/${id}.json`);
   },
   destroyAssessmentFormula(id) {
    return this.execute('DELETE', `admin/v1/assessment_formulas/${id}.json`);
   },
   getAllUsers(page, search, colName, order) {
    return this.execute('GET', `/admin/v1/users.json?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
   },
   getAllLearners(page, search, colName, order) {
    return this.execute('GET', `/admin/v1/user_sections.json?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
   },
   getUserSectionDetails(id) {
     return this.execute('GET', `/admin/v1/user_sections/${id}.json`);
   },
   getUserInteractionLOs(id) {
    return this.execute('GET', `/admin/v1/user_learn_objs/learn_int_lo_list.json?user_section_id=${id}`);
  },
  getAssistantDetails(id) {
    return this.execute('GET', `/admin/v1/learn_mods/${id}/assistant_details.json`);
  },
  getUserLearningObject(id) {
    return this.execute('GET', `/admin/v1/user_learn_objs/${id}.json`);
  },
   getAllInstructorLearnerCentral(page, search, colName, order) {
    return this.execute('GET', `/admin/v1/users.json?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
   },
   saveUser(user) {
    return this.executeFormData('POST', `/admin/v1/users.json`, user);
  },
  getUsersDetails(id) {
    return this.execute('GET', `/admin/v1/users/${id}.json`);
  },
  updateUser(id, user) {
    return this.executeFormData('PATCH', `/admin/v1/users/${id}.json`, user)
  },
  getAllRoles(search) {
    return this.execute('GET', `/admin/v1/roles.json?search=${search}`);
  },
  getAllCharacterRoles(page, search, colName, order){
    return this.execute('GET', `/admin/v1/world_roles.json?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
  },
  saveCharacterRole(characteRole) {
    return this.executeFormData('POST', `/admin/v1/world_roles.json`, characteRole);
  },
  deleteCharacterRole(id) {
    return this.execute('DELETE', `/admin/v1/world_roles/${id}.json`)
  },
  getRoleDetails(id) {
    return this.execute('GET', `/admin/v1/world_roles/${id}.json`);
  },
  updateCharacterRole(id, role) {
    return this.executeFormData('PATCH', `/admin/v1/world_roles/${id}.json`, role)
  },
  getEmailCharacterIntroVariations(id, charId){
    return this.execute('GET', `admin/v1/email_learn_objs/${id}/character_intro_variations/character_introductions.json?character_id=${charId}`);
  },
  addIntroVariation(id, data){
    return this.execute('PATCH', `admin/v1/character_intro_variations/${id}.json`, data);
   },
   getEmailCharacterConclVariations(id, charId){
    return this.execute('GET', `admin/v1/email_learn_objs/${id}/character_conclusion_variations/character_conclusions.json?character_id=${charId}`);
  },
  addConclusionVariation(id, data){
    return this.execute('PATCH', `admin/v1/character_conclusion_variations/${id}.json`, data);
   },
  deleteEmailResponse(id){
    return this.execute('DELETE', `admin/v1/email_responses/${id}.json`);
  },
  reorderResponses(id, record) {
    return this.execute('POST', `/admin/v1/email_learn_objs/${id}/reorder_responses.json`, record);
  },
  getRubricData(id) {
    return this.execute('GET', `admin/v1/rubrics.json?learning_object_id=${id}`);
  },
  editRubric(id, data) {
    return this.execute('PATCH', `admin/v1/rubrics/${id}.json`, data);
  },
  createRubric(data) {
    return this.execute('POST', `admin/v1/rubrics.json`, data);
  },
  removeRubric(id) {
    return this.execute('DELETE', `admin/v1/rubrics/${id}.json`);
  },
  distributeWeight(data) {
    return this.execute('POST', `admin/v1/rubrics/update_lo_detail.json`, data);
  },
  getCharacterResponseAffixes(id) {
   return this.execute('GET', `admin/v1/character_response_affixes?submission_email_learn_obj_id=${id}`);
  },
  postCharacterResponseAffixes(data) {
    return this.execute('POST', `admin/v1/character_response_affixes.json`, data);
  },
  patchCharacterResponseAffixes(id,data) {
    return this.execute('PATCH', `admin/v1/character_response_affixes/${id}.json`, data);
  },
  updateInterimResp(data) {
    return this.execute('POST', `admin/v1/rubrics/update_lo_detail.json`, data);
  },
  getRubricCharacterResponse(id) {
    return this.execute('GET', `/admin/v1/rubric_character_responses.json?gradable_id=${id}`);
  },
  patchRubricCharacterResponse(id ,data) {
    return this.execute('PATCH', `/admin/v1/rubric_character_responses/${id}.json`,data);
  },
  updateEntityLabel(id, entity) {
    return this.executeFormData('POST', `/admin/v1/asst_entities/${id}/update_label.json`, entity)
  },
  getAllQAEmails(page, search, colName, order){
    return this.execute('GET', `admin/v1/user_email_iterations/all_qa_emails?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
  },
  getResolvedQAEmails(page, search, colName, order){
    return this.execute('GET', `admin/v1/user_email_iterations/emails_resolved_by_qa?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
  },
  getQAEmailsToResolve(page, search, colName, order){
    return this.execute('GET', `admin/v1/user_email_iterations/emails_require_qa?page=${page}&search=${search}&sort_column=${colName}&sort_order=${order}`);
  },
  getUserEmailIterationDetails(id){
    return this.execute('GET', `admin/v1/user_email_iterations/${id}`);
  },
  getEmailList(id) {
    return this.execute('GET', `admin/v1/user_email_iterations?user_email_evaluation_id=${id}`);
  },
  updateEmailIterationResponse(id, fb){
    return this.execute('PATCH', `admin/v1/user_email_iteration_responses/${id}.json`,fb);
  },
  getEmailFeedbackResponse(id) {
    return this.execute('GET', `/admin/v1/user_email_evaluations/${id}.json`);
  },
  deleteDialogicQuestion(id) {
    return this.execute('DELETE', `/admin/v1/dialogic_questions/${id}.json`)
  },
  getDialogicAnswers(id) {
    return this.execute('GET', `/admin/v1/dialogic_evaluations/${id}/dialogic_answers.json`);
  },
  getdialogicQuestions(dialogic_learn_obj_id, user_lo_id) {
    return this.execute('GET', `/admin/v1/dialogic_questions_variations.json?dialogic_learn_obj_id=${dialogic_learn_obj_id}&user_lo_id=${user_lo_id}`);
  },
  dialogicEvaluations(id) {
    return this.execute('GET', `/admin//v1/dialogic_evaluations/${id}.json`);
  },
  quizQuestions(id, loId) {
    return this.execute('GET', `/admin/v1/quiz_questions_admin_review.json?id=${id}&user_lo_id=${loId}`);
  },
  downloadUserScore(){
    return this.executeFormData('GET', `/admin/v1/user_sections/download_score_csv.json`);
  },
  exportWorld(id){
    return this.executeFormData('GET', `admin/v1/worlds/${id}/export.json`);
  },
  importWorld(data){
    return this.executeFormData('POST', `admin/v1/worlds/import.json`, data);
  },
  exportElm(id){
    return this.executeFormData('GET', `admin/v1/learn_mods/${id}/export.json`);
  },
  importElm(data){
    return this.executeFormData('POST', `admin/v1/learn_mods/import.json`, data);
  },
  cloneElm(id){
    return this.executeFormData('POST', `admin/v1/learn_mods/${id}/clone.json`);
  },
  // ****************************************************************
  getQuizQuestion2(id, data){
    return this.executeFormData('GET', `admin/v1/quiz_questions/${id}.json`, data);
  },
  updateEntityEvaluationSet(id, data){
    return this.executeFormData('PATCH', `admin/v1/entity_evaluations/${id}.json`, data);
  },
  updateQuestionRow(id, data){
    return this.execute('PATCH', `admin/v1/quiz_questions/${id}.json`, data);
   },
   updateEvalationListDeleteEntity(id, data){
    return this.execute('PATCH', `admin/v1/entity_evaluations/${id}.json`, data);
   },
   updateQuestionRowEntitySet(id, data){
    return this.execute('PATCH', `admin/v1/quiz_questions/${id}.json`, data);
   },
   updateQuestionRowrequired(id, data){
    return this.executeFormData('PATCH', `admin/v1/quiz_questions/${id}.json`, data);
   },
   fetchLoDetails(learn_mod_id, id) {
    return this.execute('GET', `/admin/v1/learn_mods/${learn_mod_id}/learning_objects/${id}.json`);
  },
  fetchArchivedLos(learn_mod_id) {
    return this.execute('GET', `/admin/v1/learn_mods/${learn_mod_id}/learning_objects.json?has_archived=true`);
  },
  userEmailIteration(data, id) {
    return this.execute('POST', `/admin/v1/user_email_iterations/${id}/update_enrichment_items.json`, data);
  },
  genearteIterationResponse(id) {
    return this.execute('GET', `/admin/v1/user_email_iterations/${id}/gen_iteration_response.json`);
  },
  addNoteInIntent(id, data) {
    return this.execute('PATCH', `/admin/v1/asst_intents/${id}.json`, data);
  },
  addNoteInEntent(id, data) {
    return this.execute('POST', `/admin/v1/asst_entities/${id}/update_note.json`, data);
  },
  getCustomersList(page, name, colName, order) {
    return this.execute('GET', `/admin/v1/customers.json?page=${page}&search=${name}&sort_column=${colName}&sort_order=${order}`);
  },
  deleteCustomer(id) {
    return this.execute('DELETE', `/admin/v1/customers/${id}.json`);
  },
  addNoteInEntentValues(id, data) {
    return this.execute('POST', `/admin/v1/asst_entity_values/${id}/update_note.json`, data);
  },
  saveCustomer(data) {
    return this.execute('POST', `/admin/v1/customers.json`, data);
  },
  getCustomerTypes() {
    return this.execute('GET', `/admin/v1/customer_types.json`);
  },
  getContactRoles() {
    return this.execute('GET', `/admin/v1/contact_roles.json`);
  },
  getAllInstructors(page, instructor) {
    return this.execute('GET', `/admin/v1/users/users_list.json?page=${page}&search=${instructor}&user_type=instructor`);
  },
  getCustomerDetails(id) {
    return this.execute('GET', `/admin/v1/customers/${id}.json`);
  },
  editCustomer(id, data) {
    return this.execute('PATCH', `/admin/v1/customers/${id}.json`, data);
  },
  removeCustomerPhoto(id) {
    return this.execute('DELETE', `/admin/v1/customers/${id}/remove_photo.json`);
  },
  getCourses(page, name, colName, order) {
    return this.execute('GET', `/admin/v1/customer_courses.json?page=${page}&search=${name}&sort_column=${colName}&sort_order=${order}`);
  },
  deleteCourse(id) {
    return this.execute('DELETE', `/admin/v1/customer_courses/${id}.json`);
  },
  searchCustomers(page, customer) {
    return this.execute('GET', `/admin/v1/customers/auto_comp_data.json?page=${page}&search=${customer}`);
  },
  searchModules(page, moduleName) {
    return this.execute('GET', `/admin/v1/learn_mods.json?page=${page}&search=${moduleName}`);
  },
  createCourse(data) {
    return this.execute('POST', `/admin/v1/customer_courses.json`, data);
  },
  getProvisions(page, name, colName, order) {
    return this.execute('GET', `/admin/v1/sections.json?page=${page}&search=${name}&sort_column=${colName}&sort_order=${order}`);
  },
  deleteProvision(id) {
    return this.execute('DELETE', `/admin/v1/sections/${id}.json`);
  },
  searchCustomerCourse(id) {
    return this.execute('GET', `/admin/v1/customers/${id}/courses.json`);
  },
  createProvision(data) {
    return this.execute('POST', `/admin/v1/sections.json`, data);
  },
  generateCode(id, status, prefix) {
    return this.execute('POST', `/admin/v1/registration_codes/generate_code.json?section_id=${id}&total_code_required=${status}&prefix=${prefix}`);
  },
  getCourseDetails(id) {
    return this.execute('GET', `/admin/v1/customer_courses/${id}.json`);
  },
  editCourse(data, id) {
    return this.execute('PATCH', `/admin/v1/customer_courses/${id}.json`, data);
  },
  editProvision(id, data) {
    return this.execute('PATCH', `/admin/v1/sections/${id}.json`, data);
  },
  getSectionData(id) {
    return this.execute('GET', `/admin/v1/sections/${id}.json`)
  },
  generateRegCode(id, page) {
    return this.execute('GET', `/admin/v1/registration_codes.json?section_id=${id}&page=${page}`);
  },
  exportRegCodeCsv(id,page) {
    return this.execute('GET', `/admin/v1/registration_codes.csv?section_id=${id}&page=${page}`);
  },
  addNoteInEntitiesValues(id, data) {
    return this.execute('PATCH', `/admin/v1/asst_entities/${id}.json`, data);
  },
  searchLmsModules() {
    return this.execute('GET', `/admin/v1/learning_management_systems.json`);
  },
   getGradingElm(id){
    return this.executeFormData('GET', `/admin/v1/learn_mods/${id}/learning_objects/learn_obj_grading_weights.json`);
  },
  updateLearnObjGradingWeights(id, data){
    return this.execute('PATCH', `admin/v1/learn_mods/${id}/update_learn_obj_grading_weights.json`, data);
  },
  getPlagiarismLo(course_id, lo_id) {
    return this.execute('GET', `admin/v1/customer_courses/${course_id}/customer_course_los/${lo_id}`)
  },
  updatePlagiarismLo(course_id, lo_id, data) {
    return this.execute('PUT', `admin/v1/customer_courses/${course_id}/customer_course_los/${lo_id}`, data)
  },
  getAllILOs(course_id, learn_mod_id) {
    return this.execute('GET', `admin/v1/customer_courses/${course_id}/customer_course_los/get_all_elm_los?learn_mod_id=${learn_mod_id}`)
  },
  resetDialogic(id) {
    return this.execute('POST', `admin/v1/dialogic_evaluations/${id}/reset_dialogic.json`);
  },
  resetEmail(id) {
    return this.execute('POST', `admin/v1/user_email_evaluations/${id}/reset_email_lo.json`);
  },
  resetQuiz(id) {
    return this.execute('POST', `admin/v1/quiz_evaluations/${id}/reset_quiz.json`);
  },
  resetCode(id) {
    return this.execute('POST', `admin/v1/user_coding_evaluations/${id}/reset_interaction.json`);
  },
  resetEmailSubmission(id) {
    return this.execute('POST', `/admin/v1/user_learn_objs/reset_submission_email?id=${id}`);
  },
  resetSubmission(id) {
    return this.execute('POST', `/admin/v1/user_learn_objs/reset_submission?id=${id}`);
  },
  getEditSectionDetails(section_id) {
    return this.executeFormData('GET', `/admin/v1/user_sections/get_edit_section_details?section_id=${section_id}`);
  },
  updateEditSection(user_id, current_section_id, new_section_id) {
    return this.executeFormData('POST', `/admin/v1/user_sections/update_edit_section_details?user_id=${user_id}&current_section_id=${current_section_id}&new_section_id=${new_section_id}`);
  },
  reorderPrompts(id, type, prompts) {
    return this.executeFormData('POST', `admin/v1/reorder_prompts?llm_id=${id}&llm_type=${type}`, prompts);
  },
  addLearnerToCourse(user_email, section_id) {
    return this.executeFormData('POST', `/admin/v1/user_sections/add_learner_to_section?user_email=${user_email}&section_id=${section_id}`);
  },
  addLlmDialogicQuestionAnswer(data) {
    return this.execute('POST', `/admin/v1/llm_dialogic_question_answers`, data)
  },
  updateLlmDialogicQuestionAnswer(data) {
    return this.execute('PATCH', `/admin/v1/llm_dialogic_question_answers`, data)
  },
  deleteLlmDialogicQuestionAnswer(data) {
    return this.execute('DELETE', `/admin/v1/llm_dialogic_question_answers/${data}`)
  },
  getAllLlmDialogicQuestionAnswers(data) {
    return this.execute('GET', `/admin/v1/llm_dialogic_question_answers?llm_dialogic_question_id=${data}`)
  },
  addLlmDialogicQuestionAssessment(data) {
    return this.execute('POST', '/admin/v1/llm_dialogic_question_assessments', data)
  },
  updateLlmDialogicQuestionAssessment(data, id) {
    return this.execute('PATCH', `/admin/v1/llm_dialogic_question_assessments/${id}`, data)
  },
  deleteLlmDialogicQuestionAssessment(id) {
    return this.execute('DELETE', `/admin/v1/llm_dialogic_question_assessments/${id}`)
  },
  getAllLlmDialogicQuestionAssessments(data) {
    return this.execute('GET', `/admin/v1/llm_dialogic_question_assessments?llm_dialogic_question_id=${data}`)
  },
  resetLlmLo(data) {
    return this.execute('POST', `/admin/v1/user_learn_objs/reset_llm_lo`, data)
  },
  getLlmHistory(data) {
    return this.execute('GET', `admin/v1/llm_learner_messages?user_learn_obj_id=${data.user_learn_obj_id}`);
  },
  getLlmChatAssessment(data) {
    return this.execute('GET', `admin/v1/user_llm_chat_evaluations/${data}`);
  },
  getLlmDialogicAssessment(data) {
    return this.execute('GET', `admin/v1/user_llm_dialogic_evaluations/${data}`);
  },
  getLlmEmailAssessment(data) {
    return this.execute('GET', `admin/v1/user_llm_email_evaluations/${data}`);
  },
  getLlmDialogicQuestions(data) {
    return this.execute('GET', `admin/v1/llm_dialogic_questions`, data);
  },
  addLlmDialogicQuestion(data) {
    return this.execute('POST', `admin/v1/llm_dialogic_questions`, data);
  },
  saveLlmDialogicQuestion(data, id) {
    return this.execute('PATCH', `admin/v1/llm_dialogic_questions/${id}`, data);
  },
  deleteLlmDialogicQuestion(id) {
    return this.execute('DELETE', `admin/v1/llm_dialogic_questions/${id}`)
  },
  reorderLlmDialogicQuestions(id, data) {
    return this.execute('POST', `admin/v1/reorder_llm_dialogic_questions?llm_id=${id}`, data)
  },
  getAllLlmSkills() {
    return this.execute('GET', `admin/v1/llm_skills`)
  },
  createLlmSkill(data) {
    return this.execute('POST', `admin/v1/llm_skills`, data)
  },
  getLlmSkill(id) {
    return this.execute('GET', `admin/v1/llm_skills/${id}`)
  },
  updateLlmSkill(data, id) {
    return this.execute('PATCH', `admin/v1/llm_skills/${id}`, data)
  },
  deleteLlmSkill(data) {
    return this.execute('DELETE', `admin/v1/llm_skills/${data.llm_skill_id}`)
  },
  submitTestLlmSkillMessage(data) {
    return this.execute('POST', `admin/v1/submit_test_skill_message`, data)
  },
  getAllLlmSkillSets() {
    return this.execute('GET', `admin/v1/llm_skill_sets`)
  },
  getLlmSkillSet(id) {
    return this.execute('GET', `admin/v1/llm_skill_sets/${id}`)
  },
  createLlmSkillSet(data) {
    return this.execute('POST', `admin/v1/llm_skill_sets`, data)
  },
  updateLlmSkillSet(data, id) {
    return this.execute('PATCH', `admin/v1/llm_skill_sets/${id}`, data)
  },
  deleteLlmSkillSet(data) {
    return this.execute('DELETE', `admin/v1/llm_skill_sets/${data.llm_skill_set_id}`)
  },
  createLlmSkillSetInteraction(data) {
    return this.execute('POST', `admin/v1/llm_skill_set_interactions`, data)
  },
  getLlmSkillSetInteraction(id) {
    return this.execute('GET', `admin/v1/llm_skill_set_interactions/${id}`)
  },
  deleteSkillSetInteraction(id) {
    return this.execute('DELETE', `admin/v1/llm_skill_set_interactions/${id}`)
  },
  getLlmSkillSetInteractionItem(id) {
    return this.execute('GET', `admin/v1/llm_skill_set_interaction_item/${id}`)
  },
  updateLlmSkillSetInteractionItem(id, data) {
    return this.execute('PATCH', `admin/v1/llm_skill_set_interactions/${id}`, data)
  },
  getLlmSkillAssessment(id) {
    return this.execute('GET', `admin/v1/llm_skill_assessments/${id}`)
  },
  updateLlmSkillAssessment(id, data) {
    return this.execute('PATCH', `admin/v1/llm_skill_assessments/${id}`, data)
  },
  createLlmFeedbackRequestItem(data) {
    return this.execute('POST', `admin/v1/llm_feedback_request_items`, data)
  },
  deleteLlmFeedbackRequestItem(id) {
    return this.execute('DELETE', `admin/v1/llm_feedback_request_items/${id}`)
  },
  createLlmAssessmentKnowledgeItem(data) {
    return this.execute('POST', `admin/v1/llm_assessment_knowledge_items`, data)
  },
  deleteLlmAssessmentKnowledgeItem(id) {
    return this.execute('DELETE', `admin/v1/llm_assessment_knowledge_items/${id}`)
  },
  removeLlmCharacterImage(learn_mod_id, id) {
    return this.executeFormData('DELETE', `/admin/v1/learn_mods/${learn_mod_id}/learning_objects/${id}/remove_llm_character_image.json`);
  },
}