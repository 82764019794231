var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "userdetail",
        width: 430,
        height: "auto",
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content modal-edit-character" }, [
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "modal-edit-image" }, [
            _vm.photo_url
              ? _c("img", {
                  attrs: { src: _vm.photo_url.large_version, alt: "image" },
                })
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide("userdetail")
                  },
                },
              },
              [_c("em", { staticClass: "icon-close" })]
            ),
          ]),
          _c("div", { staticClass: "modal-edit-title" }, [
            _vm._v(_vm._s(_vm.first_name) + " " + _vm._s(_vm.last_name)),
          ]),
          _c("div", { staticClass: "modal-edit-text" }, [
            _vm._v(_vm._s(_vm.email)),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.show("editUser", { user_id: _vm.user_id })
                },
              },
            },
            [_vm._v("Edit User")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }