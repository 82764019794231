<template>
  <div id="DashboardContent">
    <modal name="cropperImages" height="auto" :width="800" :scrollable="true">
      <div class="modal-content modal-cropper-content">
        <div class="modal-body">
          <div class="modal-images-cropper">
            <div class="modal-cropper-large" v-if="uploadImage">
              <cropper :src="uploadImage" :stencil-props="{ aspectRatio: 16/9 }" ref="cropper"></cropper>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="$modal.hide('cropperImages')" type="button" class="btn default">Cancel</button>
          <button type="button" @click="crop()" class="btn primary">Save</button>
        </div>
      </div>
    </modal>
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>Dashboard Content</h2>
        </div>
      </header>
      <div class="data-content data-dashboard">
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Title</label>
                  <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                    <textarea
                      class="form-control"
                      type="text"
                      maxlength="50"
                      placeholder="Add a Title"
                      v-model="title"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Sub-Title</label>
                  <ValidationProvider name="Sub Title" rules="required" v-slot="{ errors }">
                    <textarea
                      class="form-control"
                      type="text"
                      maxlength="200"
                      placeholder="Add a Sub-Title"
                      v-model="subTitle"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                    <textarea
                      class="form-control textarea-control"
                      type="text"
                      maxlength="1500"
                      placeholder="Add a description"
                      v-model="desc"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <div class="data-upload">
                    <input
                      style="display: none;"
                      ref="fileInput"
                      type="file"
                      @change="onFileSelect"
                    />
                    <span class="add-more-btn" @click="$refs.fileInput.click()">+ Upload Image</span>
                  </div>
                  <label>Image alt text</label>
                  <ValidationProvider name="imageAltText" rules="required" v-slot="{ errors }">
                    <textarea
                      class="form-control textarea-control"
                      type="text"
                      maxlength="300"
                      placeholder="Add a detailed description of the image"
                      v-model="imageAltText"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Preview</label>
                  <div class="dashaboard-image-preview">
                    <div class="dashaboard-image-edit">
                      <img
                        class="sidebar-preview"
                        src="../assets/images/leftlearner-image.png"
                        alt="sidebar"
                      />
                      <div class="dashaboard-image-content">
                        <div class="preview-image">
                          <img :src="image" alt="image" />
                        </div>
                        <h2>{{title}}</h2>
                        <h3>{{subTitle}}</h3>
                        <p>{{desc}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right pt-4">
                  <a
                    class="btn primary"
                    href="javascript:void(0);"
                    @click="saveDashboardConfig()"
                  >Save and Publish</a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "DashboardContent",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  metaInfo: {
    title: "Dashboard Content",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      title: "",
      subTitle: "",
      desc: "",
      image: "",
      imageAltText: "",
      isActive: "",
      id: "",
      url: utilFunctionService.getImageUrl(),
      uploadImage: "",
      cropImage: ""
    };
  },
  methods: {
    show() {
      this.$modal.show();
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    saveDashboardConfig() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          if (this.cropImage) {
            fb.append("learner_dash[welcome_img]", this.image);
            fb.append("learner_dash[title]", this.subTitle);
            fb.append("learner_dash[description]", this.desc);
            fb.append("learner_dash[welcome_text]", this.title);
            fb.append("learner_dash[image_alt_text]", this.imageAltText);
          } else {
            fb.append("learner_dash[title]", this.subTitle);
            fb.append("learner_dash[description]", this.desc);
            fb.append("learner_dash[welcome_text]", this.title);
            fb.append("learner_dash[image_alt_text]", this.imageAltText);
          }
          if (this.id) {
            api
              .editDashboardConfig(this.id, fb)
              .then(() => {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.editDashboardConfig
                );
                this.cropImage = "";
                this.getConfig();
              })
              .catch();
          } else {
            api
              .saveDashboardConfig(fb)
              .then(() => {
                 utilFunctionService.showSuccess(
                  commonConfig.appMessages.createDashboardConfig
                );
                this.getConfig();
              })
              .catch();
          }
        }
      });
    },
    onFileSelect(event) {
      this.selectedFile = "";
      this.fileName = "";
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.uploadImage = e.target.result;
      };
      this.$modal.show("cropperImages");
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.cropImage = canvas.toDataURL();
      this.image = canvas.toDataURL();
      this.hide("cropperImages");
    },
    getConfig() {
      api
        .getDashboardConfig()
        .then(res => {
          this.image = res.data.data.attributes.welcome_img;
          this.title = res.data.data.attributes.welcome_text;
          this.desc = res.data.data.attributes.description;
          this.subTitle = res.data.data.attributes.title;
          this.imageAltText = res.data.data.attributes.image_alt_text
          this.id = res.data.data.id;
        })
        .catch();
    }
  },
  created() {
    this.getConfig();
  }
};
</script>
