var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "wistiaVideo",
        height: "auto",
        width: 1020,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", {
            staticClass: "modal-title",
            attrs: { id: "exampleModalLabel" },
          }),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("wistiaVideo")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c(
            "div",
            {
              staticClass: "wistia_responsive_padding data-video",
              staticStyle: { padding: "56.25% 0 0 0", position: "relative" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "wistia_responsive_wrapper data-video",
                  staticStyle: {
                    height: "100%",
                    left: "0",
                    position: "absolute",
                    top: "0",
                    width: "100%",
                  },
                },
                [
                  _c("iframe", {
                    staticClass: "wistia_embed",
                    attrs: {
                      src: _vm.preIframUrl + _vm.wistia_id + _vm.postIframUrl,
                      title: "Video.mp4",
                      name: "wistia_embed",
                      allowtransparency: "true",
                      scrolling: "no",
                      allowfullscreen: "",
                      mozallowfullscreen: "",
                      webkitallowfullscreen: "",
                      oallowfullscreen: "",
                      msallowfullscreen: "",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn default mt-4 pr-0",
              attrs: { type: "button", "data-dismiss": "modal" },
              on: {
                click: function ($event) {
                  return _vm.hide("wistiaVideo")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }