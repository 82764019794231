var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qa-sidebar" },
    [
      _c("div", { staticClass: "iteration-items" }, [
        _c("div", { staticClass: "iteration-detail-title" }, [
          _vm._v("Administrative Notes"),
        ]),
        _c("div", { staticClass: "detail-content my-3" }, [
          _vm._v(_vm._s(_vm.iterationDetails.note)),
        ]),
        _c("div", { staticClass: "iteration-detail-title" }, [_vm._v("ELM")]),
        _c("div", { staticClass: "detail-content my-3" }, [
          _vm._v(" " + _vm._s(_vm.iterationDetails.learn_mod_name) + " "),
        ]),
        _c("div", { staticClass: "iteration-detail-title" }, [
          _vm._v("Learning Object"),
        ]),
        _c("div", { staticClass: "detail-content my-3" }, [
          _vm._v(" " + _vm._s(_vm.iterationDetails.learning_object_name) + " "),
        ]),
        _c("div", { staticClass: "iteration-detail-title" }, [
          _vm._v("Learner"),
        ]),
        _c("div", { staticClass: "detail-content my-3" }, [
          _vm._v(_vm._s(_vm.iterationDetails.learner_name)),
        ]),
        _c("div", { staticClass: "iteration-detail-title" }, [
          _vm._v("Iterations enabled"),
        ]),
        _c("div", { staticClass: "detail-content my-3" }, [
          _vm._v(" " + _vm._s(_vm.iterationDetails.iterations_enabled) + " "),
        ]),
        !_vm.iterationDetails.qa_resolved
          ? _c("div", { staticClass: "iteration-detail-title" }, [
              _vm._v(" Iteration requiring QA "),
            ])
          : _vm._e(),
        _vm.iterationDetails.qa_resolved
          ? _c("div", { staticClass: "iteration-detail-title" }, [
              _vm._v(" Iteration resolved "),
            ])
          : _vm._e(),
        _c("div", { staticClass: "detail-content my-3" }, [
          _vm._v(
            " iteration - " + _vm._s(_vm.iterationDetails.iteration) + " "
          ),
        ]),
        _c("div", { staticClass: "iteration-detail-title margin-title" }, [
          _vm._v("LO QA conditions"),
        ]),
        _vm.charQaConditions.length > 0
          ? _c(
              "div",
              _vm._l(_vm.charQaConditions, function (qaCondition, i) {
                return _c("div", { key: i, staticClass: "row custom-row" }, [
                  qaCondition.responseFormula.length > 0
                    ? _c("div", { staticClass: "tab-content col-sm-12" }, [
                        _c("div", { staticClass: "detail-content mt-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(qaCondition.character) +
                              "'s QA Condition Formula(s) "
                          ),
                        ]),
                        _c(
                          "ul",
                          { staticClass: "formulas-data-list" },
                          _vm._l(
                            qaCondition.responseFormula,
                            function (res, i) {
                              return _c("li", { key: i }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "detail-content mt-2 formulas-left",
                                  },
                                  [
                                    _vm._v(
                                      " Formula " +
                                        _vm._s(i + 1) +
                                        " (ID: " +
                                        _vm._s(res.ques_id) +
                                        ") "
                                    ),
                                  ]
                                ),
                                res.present
                                  ? _c(
                                      "div",
                                      { staticClass: "formulas-list" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "formulas-list-title",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "detail-content" },
                                              [
                                                _vm._v(" Present: "),
                                                _c(
                                                  "span",
                                                  { staticClass: "keywords" },
                                                  [
                                                    _vm._v(
                                                      "Keyword: at-least " +
                                                        _vm._s(res.present_min)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        res.present
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "formulas-list-item",
                                              },
                                              _vm._l(
                                                res.present,
                                                function (formula, key, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "formula-item-flex email-formula-item",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "formula-name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(key) +
                                                              ": "
                                                          ),
                                                          _vm._l(
                                                            formula,
                                                            function (entity) {
                                                              return _c(
                                                                "span",
                                                                {
                                                                  key: entity.id,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      entity.value
                                                                    ) + " ,"
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                      Object.keys(res.present)
                                                        .length -
                                                        1 !==
                                                      index
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "formula-and",
                                                            },
                                                            [_vm._v("and")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                res.absent
                                  ? _c(
                                      "div",
                                      { staticClass: "formulas-list" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "formulas-list-title",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "detail-content" },
                                              [
                                                _vm._v(" Absent: "),
                                                _c(
                                                  "span",
                                                  { staticClass: "keywords" },
                                                  [
                                                    _vm._v(
                                                      "Keyword: at-least " +
                                                        _vm._s(res.absent_min)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "formulas-list-item" },
                                          _vm._l(
                                            res.absent,
                                            function (formula, key, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "formula-item-flex email-formula-item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "formula-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " + _vm._s(key) + ": "
                                                      ),
                                                      _vm._l(
                                                        formula,
                                                        function (entity) {
                                                          return _c(
                                                            "span",
                                                            { key: entity.id },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  entity.value
                                                                ) + " ,"
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  Object.keys(res.absent)
                                                    .length -
                                                    1 !==
                                                  index
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "formula-and",
                                                        },
                                                        [_vm._v("and")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            )
          : _vm._e(),
        _c("div", { staticClass: "lo-entities-title" }, [
          _vm._v("LO Entities"),
        ]),
        _c(
          "div",
          { staticClass: "row entity-main-row" },
          _vm._l(_vm.iterationDetails.asst_entities, function (details, i) {
            return _c("div", { key: i, staticClass: "row entity-row" }, [
              _vm.iterationDetails.asst_entities.length > 0
                ? _c("div", { staticClass: "tab-content col-sm-12" }, [
                    _c("ul", { staticClass: "formulas-data-list" }, [
                      _c("li", [
                        _c("div", { staticClass: "formulas-list" }, [
                          _c("div", { staticClass: "formulas-list-title" }, [
                            _c("div", { staticClass: "detail-content" }, [
                              _vm._v(
                                " " + _vm._s(details.attributes.name) + " "
                              ),
                              _c("li", { staticClass: "sticky-note" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn dropdown-toggle",
                                    attrs: {
                                      id: "menu1",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                    },
                                  },
                                  [
                                    details.attributes.note
                                      ? _c("img", {
                                          staticClass: "sticky-image",
                                          attrs: {
                                            alt: "images",
                                            src: require("../assets/images/sticky-yellow.png"),
                                          },
                                        })
                                      : _c("img", {
                                          staticClass: "sticky-image",
                                          attrs: {
                                            alt: "images",
                                            src: require("../assets/images/sticky-white.png"),
                                          },
                                        }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu data-popover-dropdown dropdown-menu-right",
                                    attrs: { "aria-labelledby": "menu1" },
                                  },
                                  [
                                    _c("div", { staticClass: "data-popover" }, [
                                      _vm._m(0, true),
                                      _c(
                                        "form",
                                        { staticClass: "data-popover-form" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      details.attributes.note,
                                                    expression:
                                                      "details.attributes.note",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control small-textarea-height",
                                                attrs: { rows: "4" },
                                                domProps: {
                                                  value:
                                                    details.attributes.note,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      details.attributes,
                                                      "note",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-group-button",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "btn btn-remove",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteNoteInEntities(
                                                        i,
                                                        details.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Remove Note")]
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "btn btn-save",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.saveNoteInEntities(
                                                        i,
                                                        details.id,
                                                        details.attributes.note
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Save")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "formulas-list-item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "formula-item-flex email-formula-item",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "name-formula" },
                                  _vm._l(
                                    details.attributes.asst_entity_values,
                                    function (detail, j) {
                                      return _c(
                                        "span",
                                        {
                                          key: j,
                                          staticClass: "sticky-wrapper",
                                        },
                                        [
                                          _c(
                                            "drag",
                                            {
                                              staticClass: "drag",
                                              attrs: {
                                                "transfer-data": {
                                                  ententName:
                                                    details.attributes.name,
                                                  entityId: detail.id,
                                                  ententValueName:
                                                    detail.attributes.value,
                                                  type: "entent",
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "nav-tab-image",
                                                attrs: {
                                                  alt: "images",
                                                  src: require("../assets/images/tag-icon.svg"),
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    detail.attributes.value
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "entities-plus-button",
                                                  staticStyle: {
                                                    "background-color": "black",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showModal(
                                                        detail
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "plus-image",
                                                    attrs: {
                                                      alt: "images",
                                                      src: require("../assets/images/entities-plus-icon.svg"),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "li",
                                            { staticClass: "sticky-note" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn dropdown-toggle",
                                                  attrs: {
                                                    id: "menu1",
                                                    "data-toggle": "dropdown",
                                                    "aria-haspopup": "true",
                                                    "aria-expanded": "false",
                                                  },
                                                },
                                                [
                                                  detail.attributes.note
                                                    ? _c("img", {
                                                        staticClass:
                                                          "sticky-image",
                                                        attrs: {
                                                          alt: "images",
                                                          src: require("../assets/images/sticky-yellow.png"),
                                                        },
                                                      })
                                                    : _c("img", {
                                                        staticClass:
                                                          "sticky-image",
                                                        attrs: {
                                                          alt: "images",
                                                          src: require("../assets/images/sticky-white.png"),
                                                        },
                                                      }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-menu data-popover-dropdown dropdown-menu-right",
                                                  attrs: {
                                                    "aria-labelledby": "menu1",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "data-popover",
                                                    },
                                                    [
                                                      _vm._m(1, true),
                                                      _c(
                                                        "form",
                                                        {
                                                          staticClass:
                                                            "data-popover-form",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group",
                                                            },
                                                            [
                                                              _c("textarea", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      detail
                                                                        .attributes
                                                                        .note,
                                                                    expression:
                                                                      "detail.attributes.note",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control small-textarea-height",
                                                                attrs: {
                                                                  rows: "4",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    detail
                                                                      .attributes
                                                                      .note,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        detail.attributes,
                                                                        "note",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group-button",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-remove",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeEntentNote(
                                                                          i,
                                                                          j,
                                                                          detail.id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Remove Note"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-save",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.saveNoteInValues(
                                                                          i,
                                                                          j,
                                                                          detail.id,
                                                                          detail
                                                                            .attributes
                                                                            .note
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("Save")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "lo-entities-title" }, [_vm._v("LO Intents")]),
        _c(
          "div",
          { staticClass: "entity-main-row" },
          _vm._l(
            _vm.iterationDetails.asst_intents,
            function (intentDetails, j) {
              return _c("div", { key: j, staticClass: "row entity-row" }, [
                _vm.iterationDetails.asst_intents.length > 0
                  ? _c("div", { staticClass: "tab-content col-sm-12" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "formulas-data-list",
                          attrs: { id: "lo-intents-wrapper" },
                        },
                        [
                          _c("li", [
                            _c("div", { staticClass: "formulas-list" }, [
                              _c(
                                "div",
                                { staticClass: "formulas-list-title" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "detail-content" },
                                    [
                                      _c(
                                        "drag",
                                        {
                                          staticClass: "drag",
                                          attrs: {
                                            "transfer-data": {
                                              intentName:
                                                intentDetails.attributes.name,
                                              intentId: intentDetails.id,
                                              type: "intent",
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "nav-tab-image",
                                            attrs: {
                                              alt: "images",
                                              src: require("../assets/images/tag-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                intentDetails.attributes.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c("li", { staticClass: "sticky-note" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn dropdown-toggle",
                                class: [
                                  intentDetails.attributes.description
                                    ? "active"
                                    : "",
                                ],
                                attrs: {
                                  id: "menu1",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                intentDetails.attributes.note
                                  ? _c("img", {
                                      staticClass: "sticky-image",
                                      attrs: {
                                        alt: "images",
                                        src: require("../assets/images/sticky-yellow.png"),
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "sticky-image",
                                      attrs: {
                                        alt: "images",
                                        src: require("../assets/images/sticky-white.png"),
                                      },
                                    }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu data-popover-dropdown dropdown-menu-right",
                                attrs: { "aria-labelledby": "menu1" },
                              },
                              [
                                _c("div", { staticClass: "data-popover" }, [
                                  _vm._m(2, true),
                                  _c(
                                    "form",
                                    { staticClass: "data-popover-form" },
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                intentDetails.attributes.note,
                                              expression:
                                                "intentDetails.attributes.note",
                                            },
                                          ],
                                          staticClass:
                                            "form-control small-textarea-height",
                                          attrs: { rows: "4" },
                                          domProps: {
                                            value:
                                              intentDetails.attributes.note,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                intentDetails.attributes,
                                                "note",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "form-group-button" },
                                        [
                                          intentDetails.attributes.note
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "btn btn-remove",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeIntentNote(
                                                        j,
                                                        intentDetails.id,
                                                        intentDetails.attributes
                                                          .note,
                                                        intentDetails.attributes
                                                          .name
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Remove Note")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "btn btn-save",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addIntentNote(
                                                    j,
                                                    intentDetails.id,
                                                    intentDetails.attributes
                                                      .note,
                                                    intentDetails.attributes
                                                      .name
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Save")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            }
          ),
          0
        ),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "enityValueModal",
            height: "auto",
            width: 500,
            scrollable: true,
          },
        },
        [
          _vm.selectedSynonymList && _vm.selectedSynonymList.attributes
            ? _c("div", { staticClass: "modal-content modal-default" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "exampleModalLabel" },
                    },
                    [
                      _c("h5", [
                        _vm._v(
                          "Entity value:" +
                            _vm._s(_vm.selectedSynonymList.attributes.value)
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "form",
                    {
                      staticClass: "modal-form modal-min-height",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.updateSynonymValues("add")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "form-group mt-2" }, [
                        _c("label", [_vm._v("Synonyms")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.entitySynonymName,
                              expression: "entitySynonymName",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.entitySynonymName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.entitySynonymName = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "chip-container entity-chip" },
                          _vm._l(
                            _vm.selectedSynonymList.attributes
                              .asst_entity_val_synonyms,
                            function (synonym) {
                              return _c(
                                "div",
                                { key: synonym.id, staticClass: "chip" },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(synonym.attributes.synonym)),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn default",
                            attrs: { type: "button", "data-dismiss": "modal" },
                            on: {
                              click: function ($event) {
                                return _vm.$modal.hide("enityValueModal")
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.updateSynonymValues("done")
                              },
                            },
                          },
                          [_vm._v(" Done ")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-popover-title" }, [
      _c("span", [_vm._v("Add Note")]),
      _c("a", { staticClass: "data-popover-close" }, [
        _c("em", { staticClass: "icon-close" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-popover-title" }, [
      _c("span", [_vm._v("Add Note")]),
      _c("a", { staticClass: "data-popover-close" }, [
        _c("em", { staticClass: "icon-close" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-popover-title" }, [
      _c("span", [_vm._v("Add Note")]),
      _c("a", { staticClass: "data-popover-close" }, [
        _c("em", { staticClass: "icon-close" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }