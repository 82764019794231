<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'">
    </component>
    <router-view />
  </div>
</template>
<script>
    import { utilFunctionService } from "@/utils/utils.service";
    import layout from '@/components/Layout.vue'
    export default {
        name: 'App',
        components: {
            layout
        },
        channels: {
          ElmImportNotificationChannel: {
            received(data) {
                console.log('ElmImportNotificationChannel Received Data' + data)
                if(data.invoked_user_id == utilFunctionService.currentUserId()){
                if (data.done){
                if (data.progress == 100){
                    utilFunctionService.showSuccess(data.message);
                    // this.$cable.unsubscribe('ElmImportNotificationChannel');
                    this.$store.dispatch('endElmImport');
                    // this.getElm(1);
                } else if (data.progress == 0){
                    utilFunctionService.showerr(data.message);
                }
                } else {
                this.$store.dispatch('updateElmImportProgress', parseInt(data.progress));
                }
            }
            }
          },
          ElmExportNotificationChannel: {
            received(data) {
                if (data.invoked_user_id == utilFunctionService.currentUserId()){
                    if (data.done){
                        utilFunctionService.showSuccess(data.message);
                        let res = JSON.stringify(data.data);
                        let fileName = data.filename;
                        const url = window.URL.createObjectURL(new Blob([res], {type: 'text/plain'}))
                        var link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                    }
                }
            }
          },
          CloneCompletionNotificationChannel: {
            received(data) {
                if(data.invoked_user_id == utilFunctionService.currentUserId()){
                    if (data.done){
                        if(data.progress == 100){
                            utilFunctionService.showSuccess(data.message);
                            // this.$cable.unsubscribe('CloneCompletionNotificationChannel');
                            this.$store.dispatch('endClone');
                            // this.getElm(1);
                        } else if (data.progress == 0){
                            utilFunctionService.showErr(data.message);
                            this.$store.dispatch('endClone');
                        }
                    } else {
                        this.$store.dispatch('updateCloneProgress', parseInt(data.progress));
                    }
                }
            },
          },
          WorldExportNotificationChannel: {
            received(data) {
                if (data.done){
                    let res = JSON.stringify(data.data);
                    let fileName = data.filename;
                    const url = window.URL.createObjectURL(new Blob([res], {type: 'text/plain'}))
                    var link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    // this.$cable.unsubscribe('WorldExportNotificationChannel');
                }
            },
          },
          WorldImportNotificationChannel: {
            received(data) {
                if (data.done){
                utilFunctionService.showSuccess(data.message);
                // this.$cable.unsubscribe('WorldImportNotificationChannel');
                this.$store.dispatch('endWorldImport');
                this.importedFile = null;
                // this.getWorlds(1);
                } else {
                this.$store.dispatch('updateWorldlmImportProgress', parseInt(data.progress));
                }
            },
         }
        },
        created(){
             this.$cable.subscribe({
                channel: 'ElmImportNotificationChannel',
                room : 'public'
            });
            this.$cable.subscribe({
                channel: 'CloneCompletionNotificationChannel',
                room: 'public'
            });
            this.$cable.subscribe({
                channel: 'ElmExportNotificationChannel',
                room: 'public'
            });
            this.$cable.subscribe({
                channel: 'WorldExportNotificationChannel',
                room: 'public'
            });
            this.$cable.subscribe({
                channel: 'WorldImportNotificationChannel',
                room: 'public'
            });
        }
    }
</script>

<style lang="scss">

@import "./styles/style.scss";
.vm--overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}
.vm--modal {
    background-color: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    overflow: visible !important;
}
.scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    max-height: 350px;
}
.ps {
    .ps__rail-y {
        margin: 5px;
        .ps__thumb-y {
            width: 4px;
            right: 0;
            opacity: 0.5;
        }
        &:hover {
            background-color: transparent !important;
            >.ps__thumb-y {
                width: 4px;
            }
        }
    }
}

.ql-toolbar.ql-snow {
    border: 0 !important;
    background-color: $white;
}
.ql-container.ql-snow {
    border: 0 !important;
}
</style>
