<template>
    <modal
      name="EditSectionModal"
      :width="700"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Edit Section</h5>
          </div>
          <button type="button" class="close" @click="hide('EditSectionModal')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body mt-3">
            <div class="edit-section-info">
                <p class="name">User name: <strong>{{user_info.first_name}} {{user_info.last_name}}</strong></p>
                <p class="current_section">Current section: <strong>{{user_info.section_name}}</strong></p>
                <select v-model="selected_section">
                    <option disabled value="">Please select section to switch to:</option>
                    <option v-for="section in all_sections" v-bind:value="section[1]" :key="section[1]">
                        {{section[0]}}
                    </option>
                </select>
            </div>
            <div class="edit-section-info__buttons">
                <button class="btn secondary" @click="changeSection">Change section</button>
            </div>
        </div>
      </div>
    </modal>
</template>

<script>
import api from "../../services/api";
// import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "EditSectionModal",
  components: {
  },
  data() {
    return {
        user_info: {},
        user_id: "",
        section_id: "",
        all_sections: [],
        selected_section: ""
    };
  },
  methods: {
    beforeOpen(event) {
        this.user_info = event.params.user_info;
        this.user_id = event.params.user_info.user_id;
        this.section_id = event.params.user_info.section_id;
        api.getEditSectionDetails(this.section_id).then(res => { this.all_sections = res.data.all_sections });
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    changeSection() {
        api.updateEditSection(this.user_id, this.section_id, this.selected_section)
        .then(res => { 
            if (res.data.status === "ok") {
                this.hide('EditSectionModal')
                utilFunctionService.showSuccess(
                    commonConfig.appMessages.sectionChangeSuccess
                );
                setTimeout(() => {
                    this.$parent.refreshEditSection();
                }, 3000)
            }
        })
        .catch(err => {
            console.log(err);
            utilFunctionService.showerr(
                commonConfig.appMessages.sectionChangeError
            );
        })
    }
  }
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.edit-section-info {
    margin-bottom: 32px;
    .name {
        text-transform: capitalize;
        font-size: 18px;
        margin-bottom: 8px;
    }
    .current_section {
        font-size: 16px;
        margin-bottom: 8px;
    }
}
.edit-section-info__buttons {
    .secondary {
        color: #000000;
    }
    .secondary:hover {
        color: #ffffff;
        background: #3dbc9e;
    }
}
</style>
