var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "DashboardContent" } },
    [
      _c(
        "modal",
        {
          attrs: {
            name: "cropperImages",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-cropper-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "modal-images-cropper" }, [
                _vm.uploadImage
                  ? _c(
                      "div",
                      { staticClass: "modal-cropper-large" },
                      [
                        _c("cropper", {
                          ref: "cropper",
                          attrs: {
                            src: _vm.uploadImage,
                            "stencil-props": { aspectRatio: 16 / 9 },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cropperImages")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.crop()
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "data-content data-dashboard" },
          [
            _c("ValidationObserver", { ref: "form" }, [
              _c("form", { staticClass: "w-100" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Title")]),
                        _c("ValidationProvider", {
                          attrs: { name: "Title", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.title,
                                        expression: "title",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      maxlength: "50",
                                      placeholder: "Add a Title",
                                    },
                                    domProps: { value: _vm.title },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.title = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Sub-Title")]),
                        _c("ValidationProvider", {
                          attrs: { name: "Sub Title", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.subTitle,
                                        expression: "subTitle",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      maxlength: "200",
                                      placeholder: "Add a Sub-Title",
                                    },
                                    domProps: { value: _vm.subTitle },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.subTitle = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Description")]),
                        _c("ValidationProvider", {
                          attrs: { name: "Description", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.desc,
                                        expression: "desc",
                                      },
                                    ],
                                    staticClass:
                                      "form-control textarea-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      maxlength: "1500",
                                      placeholder: "Add a description",
                                    },
                                    domProps: { value: _vm.desc },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.desc = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("div", { staticClass: "data-upload" }, [
                          _c("input", {
                            ref: "fileInput",
                            staticStyle: { display: "none" },
                            attrs: { type: "file" },
                            on: { change: _vm.onFileSelect },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "add-more-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.fileInput.click()
                                },
                              },
                            },
                            [_vm._v("+ Upload Image")]
                          ),
                        ]),
                        _c("label", [_vm._v("Image alt text")]),
                        _c("ValidationProvider", {
                          attrs: { name: "imageAltText", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.imageAltText,
                                        expression: "imageAltText",
                                      },
                                    ],
                                    staticClass:
                                      "form-control textarea-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      maxlength: "300",
                                      placeholder:
                                        "Add a detailed description of the image",
                                    },
                                    domProps: { value: _vm.imageAltText },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.imageAltText = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Preview")]),
                      _c("div", { staticClass: "dashaboard-image-preview" }, [
                        _c("div", { staticClass: "dashaboard-image-edit" }, [
                          _c("img", {
                            staticClass: "sidebar-preview",
                            attrs: {
                              src: require("../assets/images/leftlearner-image.png"),
                              alt: "sidebar",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "dashaboard-image-content" },
                            [
                              _c("div", { staticClass: "preview-image" }, [
                                _c("img", {
                                  attrs: { src: _vm.image, alt: "image" },
                                }),
                              ]),
                              _c("h2", [_vm._v(_vm._s(_vm.title))]),
                              _c("h3", [_vm._v(_vm._s(_vm.subTitle))]),
                              _c("p", [_vm._v(_vm._s(_vm.desc))]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "text-right pt-4" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn primary",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.saveDashboardConfig()
                            },
                          },
                        },
                        [_vm._v("Save and Publish")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "header-left" }, [
        _c("h2", [_vm._v("Dashboard Content")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }