<template>
  <div class="organisation-sidebar">
    <div class="organisation-items">
      <div class="organisation-head">
        <div class="organisation-title" v-if="searchOrganization.length">
          All Organizations({{ searchOrganization.length }})
        </div>
        <div class="organisation-action" v-if="searchOrganization.length">
          <a
            @click="
              $modal.show('newWorld', { id: worldDetails.id, editOrg: true })
            "
          >
            <em class="icon-pen"></em>
          </a>
        </div>
      </div>
      <div class="organisation-search">
        <form class="organisation-form">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Search Organization"
              v-model="searchOrg"
              v-on:input="filterOrg"
            />
            <em class="icon-search"></em>
          </div>
        </form>
      </div>
      <div
        class="organisation-card"
        id="accordion"
        v-if="searchOrganization.length"
      >
        <div
          v-for="(world, index) in searchOrganization"
          :key="world.organizationName"
        >
          <div class="card">
            <div class="card-header" id="headingOne">
              <button
                class="btn"
                data-toggle="collapse"
                :data-target="`#collapseOne${index}`"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div>
                  <h5>{{ world.organizationName }}</h5>
                  <p>{{ world.industryType }}</p>
                </div>
                <em class="icon-carrot-down"></em>
              </button>
            </div>
            <div
              :id="`collapseOne${index}`"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <VuePerfectScrollbar
                  class="scroll-area"
                  v-once
                  :settings="settings"
                >
                  <div
                    class="card-items"
                    v-for="org in world.characters"
                    :key="org.name"
                  >
                    <div class="card-pic" v-if="org.photoUrl">
                      <img :src="org.photoUrl" alt="image1" />
                    </div>
                    <div class="card-pic" v-if="!org.photoUrl"></div>
                    <div class="card-content">
                      <h4>{{ org.name }}</h4>
                      <p>{{ org.role }}</p>
                      <div class="user-gender">
                        {{ org.age }}
                        <div class="d-inline" v-if="org.gender != null">,</div>
                        {{ org.gender | uppercase(true) }}
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="organisation-items"
      v-if="mutableList.attributes && mutableList.attributes.customer"
    >
      <div class="organisation-head">
        <div class="organisation-title">Customer</div>
      </div>
      <div class="organisation-card">
        <div class="card-items">
          <div class="card-content">
            <h4>{{ mutableList.attributes.customer.attributes.name }}</h4>
            <p>{{ mutableList.attributes.customer.attributes.email }}</p>
            <div class="user-gender">46 yrs, Male</div>
          </div>
        </div>
      </div>
    </div>
    <WorldDetails />
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { utilFunctionService } from "@/utils/utils.service";
import WorldDetails from "@/components/modal/worldDetails.vue";
import api from "../services/api";

export default {
  name: "OrganisationSidebar",
  components: {
    WorldDetails,
    VuePerfectScrollbar,
  },
  props: ["worldDetails"],
  filters: {
    uppercase: function (value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (value === "file") {
        value = "Pdf";
      }
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    },
  },
  data() {
    return {
      mutableList: "",
      searchOrg: "",
      orgDetails: "",
      orgDetailRecord: "",
      editOrganization: false,
      searchOrganization: "",
      url: utilFunctionService.getImageUrl(),
      settings: {
        maxScrollbarLength: false,
      },
    };
  },
  watch: {
    worldDetails: function () {
      this.mutableList = this.worldDetails;
      this.orgDetails = this.mutableList.attributes.world_organizations.map(
        (x) => {
          const char = x.attributes.world_org_characters.map((y) => {
            return {
              name: y.attributes.characters.attributes.full_name,
              role: y.attributes.world_role.attributes.name,
              photoUrl: y.attributes.characters.attributes.photo_url.thumbnail,
              age: y.attributes.characters.attributes.age,
              gender: y.attributes.characters.attributes.gender,
            };
          });
          return {
            organizationName: x.attributes.organization.attributes.name,
            industryType: x.attributes.organization.attributes.industry_name,
            characters: char,
          };
        }
      );
      this.searchOrganization = this.orgDetails;
    },
  },
  methods: {
    filterOrg() {
      if (this.searchOrg.length !== 0) {
        this.searchOrganization = this.orgDetails.filter((org) => {
          return org.organizationName
            .toLowerCase()
            .includes(this.searchOrg.toLowerCase());
        });
      } else {
        return (this.searchOrganization = this.orgDetails);
      }
    },
    editOrg() {
      api
        .getWorldDetails(this.worldDetails.id)
        .then((res) => {
          this.mutableList = {};
          this.mutableList = res.data.data;
          this.watcher();
        })
        .catch();
    },
    watcher() {
      this.$parent.worldDetail();
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.organisation-sidebar {
  width: 220px;
  @include position(absolute, 0, 0, 0);
  min-height: 100%;
  padding: 20px 10px 0;
  background-color: $theme-color;
  &:after {
    content: "";
    display: block;
    @include position(fixed, 0, 0, 0);
    width: 220px;
    background-color: $theme-color;
  }
  @include media-breakpoint(xl) {
    width: 313px;
    padding: 30px 30px 10px;
    &:after {
      width: 313px;
    }
  }
  .scroll-area {
    max-height: 230px;
  }
  .organisation-form {
    margin: 16px 0 0;
    .form-group {
      position: relative;
      margin: 0;
    }
    .form-control {
      border-radius: 4px;
      padding: 10px;
      &::placeholder {
        font-size: 12px;
        color: #b7b7b8;
      }
    }
    em {
      @include position(absolute, 13px, 15px);
      opacity: 0.2;
    }
  }
  .organisation-items {
    border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
    margin: 30px 0 0;
    padding: 0 0 33px;
    position: relative;
    z-index: 50;
    &:last-child {
      border: 0;
    }
  }
  .organisation-head {
    @include flexProperty(space-between, center);
    .organisation-action {
      @include flexProperty(null, center);
      a {
        color: $white;
      }
      em {
        font-size: 11px;
        display: block;
      }
      .add-action {
        @include font-bold;
        font-size: 11px;
        padding: 5px 11px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.14);
      }
    }
  }
  .organisation-title {
    @include font-bold;
    font-size: 17px;
    line-height: 1.18;
    color: $white;
  }
  .organisation-card {
    margin: 30px 0 0;
    .card {
      background-color: transparent;
      border-radius: 0;
      border: 0;
      margin: 30px 0 0;
    }
    .card-header {
      padding: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;
    }
    .btn {
      @include flexProperty(space-between, flex-start);
      color: $white;
      width: 100%;
      padding: 0;
      text-align: left;
      &:hover {
        color: $white;
      }
      h5 {
        @include font-regular;
        font-size: 16px;
        margin: 0 0 3px;
      }
      em {
        font-size: 9px;
        margin: 5px 0 0;
        display: block;
        transform: rotate(-180deg);
      }
      p {
        @include font-regular;
        font-size: 13px;
        line-height: 1.23;
        margin: 0;
        color: rgba(255, 255, 255, 0.5);
      }
      &.collapsed {
        em {
          transform: inherit;
        }
      }
    }
    .card-body {
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      padding: 0;
      font-size: 13px;
      color: $white;
      float: none;
    }
    .card-items {
      @include flexProperty;
      margin: 20px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .card-pic {
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin: 0 10px 0 0;
      flex: none;
      border-radius: 100%;
      background: #f2f7f5;
      // background: #f2f7f5 url("image-placeholder.svg") no-repeat;
      img {
        @include imageSize;
        object-position: top;
      }
    }
    .card-content {
      flex: 1;
      margin: 5px 0 0;
      color: $white;
      h4 {
        font-size: 14px;
        line-height: 1.14;
        margin: 0 0 2px;
      }
      p {
        @include font-light;
        font-size: 12px;
      }
      .user-gender {
        @include font-italic;
        font-size: 11px;
        line-height: 1.45;
      }
    }
  }
}
</style>
