<template>
  <div id="login" class="login-container">
    <img src="../assets/images/ametros-learning-logo.png" alt="ametros logo" class="login-logo" />
    <div class="login-form">
      <h1>Login</h1>
      <div class="form-group">
        <label>Username</label>
        <input
          type="text"
          class="form-control"
          name="username"
          v-model="input.username"
        />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input
          type="password"
          name="password"
          class="form-control"
          v-model="input.password"
        />
      </div>
      <div class="text-right">
        <button type="button" class="btn primary" v-on:click="login()">Login</button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "Login-component",
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      url: process.env.VUE_APP_URL
    };
  },
  methods: {
    login() {
      const user = {
        email: this.input.username,
        password: this.input.password
      };
      api
        .loginUser(user)
        .then(res => {
          const result = JSON.stringify(res["data"]);
          console.log(result);
          utilFunctionService.setLocalStorageService("user", result);
          const roles = utilFunctionService.getUserRoles();
          if (roles.includes("instructor")) {
            this.$router.replace({ name: "learner-central" });
          } else {
            this.$router.replace({ name: "learning-modules" });
        }
        })
        .catch();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.login-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  .login-logo {
    width: 180px;
    margin: 0 0 30px;
  }
  .login-form {
    width: 500px;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
  }
  h1 {
    @include font-bold;
    text-transform: uppercase;
    font-size: 32px;
    margin: 0 0 30px;
    text-align: center;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
}
</style>
