var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "QaEmail" } }, [
    _c("div", [
      _c("div", [
        _vm.emailLists.length > 0
          ? _c(
              "div",
              { attrs: { id: "QaMessageContainer" } },
              [
                _vm._l(_vm.emailLists, function (email, i) {
                  return _c(
                    "div",
                    { key: email.id, staticClass: "learn-object" },
                    [
                      _c("div", { staticClass: "learn-object-forward" }, [
                        _c("div", { staticClass: "learn-object-tag" }, [
                          _vm._v(
                            " Subject: " +
                              _vm._s(_vm.filterEmailIndex[i].sub) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "learn-object-paragraph" }, [
                          _c("div", { staticClass: "learn-object-desc" }, [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(_vm.filterEmailIndex[i].msg),
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "learn-object-paragraph" }, [
                          _c(
                            "div",
                            { staticClass: "learn-object-desc my-4 details" },
                            [
                              _c("p", [
                                _vm._v(
                                  "Iteration: " +
                                    _vm._s(_vm.filterEmailIndex[i].iteration)
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " QA condition hit ID(s): " +
                                    _vm._s(
                                      _vm.emailIterationDetails
                                        .qa_condition_hits
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "Date Sent: " +
                                    _vm._s(_vm.emailIterationDetails.date_sent)
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._l(_vm.filterEmailIndex[i].arr, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "learn-object-editable",
                          },
                          [
                            _c("div", { staticClass: "learn-reply-head" }, [
                              _c("span", [
                                _vm._v(
                                  "Reply from " +
                                    _vm._s(
                                      item.attributes.character.attributes
                                        .char_full_name
                                    )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "learn-object-tag" }, [
                              _vm._v(
                                " Reply: " +
                                  _vm._s(email.attributes.email_subject) +
                                  " "
                              ),
                            ]),
                            item.attributes.qa_fix_required === true &&
                            item.attributes.qa_fixed === false
                              ? _c(
                                  "div",
                                  { staticClass: "learn-object-editor" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("vue-editor", {
                                          staticClass: "form-control",
                                          attrs: {
                                            editorToolbar: _vm.fullToolbar,
                                          },
                                          model: {
                                            value: item.attributes.response,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.attributes,
                                                "response",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.attributes.response",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "learn-object-paragraph" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "learn-object-desc" },
                                        [
                                          _c("p", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.replaceLearnerToken(
                                                  item.attributes.response
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                _vm.emailfeedbackResponse !== undefined
                  ? _c("div", { staticClass: "admin-feedback-content" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-wrap w-100 justify-content-between",
                            },
                            [
                              _c("h3", [_vm._v("Feedback")]),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("p", [
                                  _vm._v(
                                    " Number of iterations:" +
                                      _vm._s(
                                        _vm.emailfeedbackResponse.attributes
                                          .iteration
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._l(
                            _vm.emailfeedbackResponse.attributes
                              .learner_debrief_items,
                            function (values) {
                              return _c(
                                "div",
                                {
                                  key: values.id,
                                  staticClass: "feedback-items mastery",
                                },
                                [
                                  _c("div", { staticClass: "feedback-icon" }, [
                                    _c("img", {
                                      attrs: {
                                        src: values.attributes
                                          .email_assessment_item.attributes
                                          .assessment_icon_url,
                                        alt: "suggestion",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "feedback-content" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "feedback-head d-flex" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                values.attributes
                                                  .email_assessment_item
                                                  .attributes.assessment_label
                                              ) +
                                              " "
                                          ),
                                          values.attributes.email_skill &&
                                          values.attributes.email_skill
                                            .eval_explanation
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tippy",
                                                      rawName: "v-tippy",
                                                      value: {
                                                        trigger: "click",
                                                        arrow: true,
                                                      },
                                                      expression:
                                                        "{ trigger: 'click', arrow: true }",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "display-inline info-icon no-style-btn",
                                                  attrs: {
                                                    content:
                                                      values.attributes
                                                        .email_skill
                                                        .eval_explanation,
                                                  },
                                                },
                                                [_vm._v(" i ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("h4", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              values.attributes.global_skill
                                                .name
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.replaceLearnerToken(
                                              values.attributes.debrief_content
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }