var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Organisation" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchOrg,
                      expression: "searchOrg",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:400ms",
                      value: _vm.searchOrganization,
                      expression: "searchOrganization",
                      arg: "400ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "aria-describedby": "searchOrg",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchOrg },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchOrg = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("createOrganisation", {
                      organisation_id: "",
                    })
                  },
                },
              },
              [_vm._v("Add a new Organization")]
            ),
          ]),
          _vm.totalOrgs > 1 && !_vm.searchOrg
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalOrgs) +
                    " Organizations in the system."
                ),
              ])
            : _vm.totalOrgs <= 1 && !_vm.searchOrg
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalOrgs) +
                    " Organization in the system."
                ),
              ])
            : _vm.totalOrgs > 1 && _vm.searchOrg
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalOrgs) +
                    " Organizations in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalOrgs) +
                    " Organization in the search result."
                ),
              ]),
        ]),
        _vm.orgLists.length !== 0
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", { staticClass: "data-table data-column-5" }, [
                _c("div", { staticClass: "data-head" }, [
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Name "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "organizations.name" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("organizations.name", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "organizations.name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "organizations.name",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Created on "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Industry type "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "industries.name" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("industries.name", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "industries.name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("industries.name", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Characters "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "characters_count" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("characters_count", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "characters_count" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("characters_count", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
                ]),
                _c(
                  "div",
                  { staticClass: "data-items" },
                  _vm._l(_vm.orgLists, function (org) {
                    return _c("div", { key: org.id, staticClass: "data-row" }, [
                      _c("div", { staticClass: "data-col" }, [
                        _c("div", { staticClass: "data-image" }, [
                          _c(
                            "div",
                            {
                              staticClass: "data-pic organise-pic cursor",
                              on: {
                                click: function ($event) {
                                  return _vm.$modal.show("organisationDetail", {
                                    organisation_id: org.id,
                                  })
                                },
                              },
                            },
                            [
                              org.attributes.photo_url &&
                              org.attributes.photo_url.thumbnail
                                ? _c("img", {
                                    attrs: {
                                      src: org.attributes.photo_url.thumbnail,
                                      alt: "image2",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "data-text cursor",
                              on: {
                                click: function ($event) {
                                  return _vm.$modal.show("organisationDetail", {
                                    organisation_id: org.id,
                                  })
                                },
                              },
                            },
                            [_vm._v(_vm._s(org.attributes.name))]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(org.attributes.created_on)),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(org.attributes.industry_name)),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _c("div", { staticClass: "customer-dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn data-multiple-btn dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              org.attributes.organization_characters.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "data-multiple-pic" },
                                    [
                                      org.attributes.organization_characters[0]
                                        .attributes.characters.attributes
                                        .photo_url &&
                                      org.attributes.organization_characters[0]
                                        .attributes.characters.attributes
                                        .photo_url.thumbnail
                                        ? _c("img", {
                                            attrs: {
                                              src: org.attributes
                                                .organization_characters[0]
                                                .attributes.characters
                                                .attributes.photo_url.thumbnail,
                                              alt: "image2",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              org.attributes.organization_characters.length > 1
                                ? _c(
                                    "div",
                                    { staticClass: "data-multiple-pic" },
                                    [
                                      org.attributes.organization_characters[1]
                                        .attributes.characters.attributes
                                        .photo_url.thumbnail
                                        ? _c("img", {
                                            attrs: {
                                              src: org.attributes
                                                .organization_characters[1]
                                                .attributes.characters
                                                .attributes.photo_url.thumbnail,
                                              alt: "image2",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              org.attributes.organization_characters.length > 2
                                ? _c(
                                    "div",
                                    { staticClass: "data-multiple-pic" },
                                    [
                                      org.attributes.organization_characters[2]
                                        .attributes.characters.attributes
                                        .photo_url.thumbnail
                                        ? _c("img", {
                                            attrs: {
                                              src: org.attributes
                                                .organization_characters[2]
                                                .attributes.characters
                                                .attributes.photo_url.thumbnail,
                                              alt: "image2",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              org.attributes.organization_characters.length > 3
                                ? _c(
                                    "div",
                                    { staticClass: "data-multiple-pic" },
                                    [
                                      _vm._v(
                                        "+" +
                                          _vm._s(
                                            org.attributes
                                              .organization_characters.length -
                                              3
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          org.attributes.organization_characters.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right",
                                },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      "All Characters(" +
                                        _vm._s(
                                          org.attributes.organization_characters
                                            .length
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                  _c(
                                    "VuePerfectScrollbar",
                                    {
                                      staticClass: "scroll-area",
                                      attrs: { settings: _vm.settings },
                                    },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "dropdown-list" },
                                        _vm._l(
                                          org.attributes
                                            .organization_characters,
                                          function (organizationcharacters) {
                                            return _c(
                                              "li",
                                              {
                                                key: organizationcharacters.id,
                                                staticClass: "dropdown-items",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "dropdown-pic",
                                                  },
                                                  [
                                                    organizationcharacters
                                                      .attributes.characters
                                                      .attributes.photo_url &&
                                                    organizationcharacters
                                                      .attributes.characters
                                                      .attributes.photo_url
                                                      .thumbnail
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: organizationcharacters
                                                              .attributes
                                                              .characters
                                                              .attributes
                                                              .photo_url
                                                              .thumbnail,
                                                            alt: "image2",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "user-name",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            organizationcharacters
                                                              .attributes
                                                              .characters
                                                              .attributes
                                                              .full_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "user-role",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            organizationcharacters
                                                              .attributes
                                                              .world_role
                                                              .attributes.name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "user-age",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            organizationcharacters
                                                              .attributes
                                                              .characters
                                                              .attributes.age
                                                          ) + " "
                                                        ),
                                                        organizationcharacters
                                                          .attributes.characters
                                                          .attributes.gender !=
                                                        null
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-inline",
                                                              },
                                                              [_vm._v(",")]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "upperCaseChar"
                                                              )(
                                                                organizationcharacters
                                                                  .attributes
                                                                  .characters
                                                                  .attributes
                                                                  .gender,
                                                                true
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "data-col data-action" }, [
                        _c("div", { staticClass: "data-dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _c("em", {
                                staticClass: "icon-edit",
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.show(
                                      "organisationDetail",
                                      { organisation_id: org.id }
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "data-delete-dropdown" }, [
                          _vm._m(1, true),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu dropdown-menu-right",
                            },
                            [
                              _c("div", { staticClass: "data-popper-title" }, [
                                _vm._v(
                                  " Are you sure you want to delete this Organization? "
                                ),
                              ]),
                              _c("div", { staticClass: "data-popper-footer" }, [
                                _c("a", { staticClass: "btn default" }, [
                                  _vm._v("cancel"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteOrg(org.id)
                                      },
                                    },
                                  },
                                  [_vm._v("Ok")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (pages) {
                            return _c(
                              "li",
                              { key: pages, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    class: [pages === _vm.page ? "active" : ""],
                                    on: {
                                      click: function ($event) {
                                        return _vm.getOrganization(pages)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(pages))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.orgLists.length === 0 && !_vm.searchOrg && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no organization in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("createOrganisation", {
                          organisation_id: "",
                        })
                      },
                    },
                  },
                  [_vm._v("Add a new organization")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.orgLists.length === 0 && _vm.searchOrg
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c("createOrganisation"),
      _c("organisationDetail"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("All Organizations")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-delete" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }