<template>
  <modal
    name="organisationDetail"
    height="auto"
    :width="500"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close ml-auto" @click="hide('organisationDetail')">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-edit-organisation">
          <div class="organisation-edit-group">
            <div class="organisation-edit-pic">
              <img :src="image" v-if="image" alt="image1" />
            </div>
            <div class="organisation-edit-title">{{name}}</div>
            <div class="organisation-edit-text">Created on {{created_on}}</div>
            <span>{{industry_name}}</span>
          </div>
          <p>{{description}}</p>
          <div class="organisation-customer-title">Characters({{count}})</div>
          <div class="customer-dropdown">
            <ul class="dropdown-list mt-0 mb-4" v-if="charactersList.length">
              <li class="dropdown-items" v-for="character in charactersList" :key="character.id">
                <div class="dropdown-pic">
                  <img :src="character.photo_url" v-if="character.photo_url" alt="image1" />
                </div>
                <div class="dropdown-content">
                  <div class="user-name">{{character.name}}</div>
                  <div class="user-role">{{character.role}}</div>
                  <div class="user-age">{{character.age}} <div class="d-inline" v-if="character.gender != null">,</div>  {{character.gender}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="data-delete-dropdown">
          <button
            type="button"
            class="btn danger dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >Delete</button>
          <div class="dropdown-menu dropdown-menu-right">
            <div class="data-popper-title">Are you sure you want to delete this organization?</div>
            <div class="data-popper-footer">
              <a class="btn default">cancel</a>
              <a class="btn primary" @click="deleteOrganisation(organisation_id)">Ok</a>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn primary"
          @click="hide('organisationDetail'),$modal.show('createOrganisation',{organisation_id : organisation_id })
                "
        >Edit Organization</button>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "organisationDetail",
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      selectedFile: null,
      image: null,
      name: "",
      description: "",
      industry_name: "",
      charactersList: [],
      addedCharacters: [],
      roles: [],
      url: utilFunctionService.getImageUrl(),
      created_on: "",
      count: 0,
      organisation_id: ""
    };
  },
  methods: {
    beforeOpen(event) {
      this.name = "";
      this.description = "";
      this.industry_name = "";
      this.image = "";
      this.created_on = "";
      this.charactersList = [];
      this.count = 0;
      this.organisation_id = "";
      this.organisation_id = event.params.organisation_id;
      this.getOrganisationDetails(this.organisation_id);
    },
    hide(model) {
      this.$parent.hide(model);
    },
    getOrganisationDetails(organisation_id) {
      api
        .getOrganisationDetails(organisation_id)
        .then(res => {
          this.name = res.data.data.attributes.name;
          this.description = res.data.data.attributes.description;
          this.industry_name = res.data.data.attributes.industry_name;
          this.image = res.data.data.attributes.photo_url.thumbnail
            ? res.data.data.attributes.photo_url.thumbnail
            : "";
          this.created_on = res.data.data.attributes.created_on;
          let characters = [];
          if (res.data.data.attributes.organization_characters) {
            characters = res.data.data.attributes.organization_characters.map(
              y => {
                return {
                  age: y.attributes.characters.attributes.age,
                  role: y.attributes.world_role.attributes.name,
                  name: y.attributes.characters.attributes.full_name,
                  gender: y.attributes.characters.attributes.gender,
                  photo_url: y.attributes.characters.attributes.photo_url.thumbnail
                    ? y.attributes.characters.attributes.photo_url.thumbnail
                    : "",
                  id: y.attributes.characters.id
                };
              }
            );
          }
          this.charactersList = characters;
          this.count = characters.length ? characters.length : 0;
        })
        .catch();
    },
    deleteOrganisation(id) {
      utilFunctionService.showLoader();
      api.deleteOrg(id).then(() => {
        utilFunctionService.hideLoader();
        this.hide("organisationDetail");
        utilFunctionService.showSuccess(commonConfig.appMessages.deleteOrg);
      });
    }
  }
};
</script>
