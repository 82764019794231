<template>
  <div id="LearnerLO">
    <div class="container-3-column">
      <header class="header">
        <div class="header-left">
            <h2>LO {{learnObjData.attributes.card_order}}: {{learnObjData.attributes.name}}</h2>
        </div>
      </header>
    <learnerDialogic v-if="showDialogic == true" :userLearnObj="userLearnObjData" :adminLearnerLoDetails="LOAdminDetails" />
    <learnerQuiz v-if="showQuiz == true" :userLearnObj="userLearnObjData" :adminLearnerLoDetails="LOAdminDetails" />
    <learnerEmail v-if="showEmail == true" :userLearnObj="userLearnObjData" :adminLearnerLoDetails="LOAdminDetails" />
    <LearnerLlmDialogic v-if="showLlmDialogic == true" :userLearnObj="userLearnObjData" :adminLearnerLoDetails="LOAdminDetails" />
    <LearnerLlmEmail v-if="showLlmEmail == true" :userLearnObj="userLearnObjData" :adminLearnerLoDetails="LOAdminDetails" />
    </div>
    <learnerLOSidebar v-if="showSidebar == true" ref="sideBar" :adminLearnerLODetails="LOAdminDetails" />
  </div>
</template>
<script>
  import api from "../services/api";
  import learnerLOSidebar from "../components/LearnerLOSidebar.vue";
  import learnerDialogic from "../components/LearnerDialogic.vue";
  import learnerQuiz from "../components/LearnerQuiz.vue";
  import learnerEmail from "../components/LearnerEmail.vue";
  import LearnerLlmDialogic from "../components/LearnerLlmDialogic.vue";
  import LearnerLlmEmail from "../components/LearnerLlmEmail.vue";

  export default {
    name: "LearnerLO",
    components: {
      learnerDialogic,
      learnerQuiz,
      learnerEmail,
      learnerLOSidebar,
      LearnerLlmDialogic,
      LearnerLlmEmail
    },
    metaInfo: {
    title: "Learner Interactive Learning Object Detail",
      htmlAttrs: {
        lang: "en",
        amp: true
      }
    },
     data() {
      return {
        settings: {
          maxScrollbarLength: false
        },
        userLearnObjData: [],
        LOAdminDetails: {
          attributes: {
            last_name: ""
          }
        },
        learnObjData: {
          attributes: {
            card_order: "",
            name: ""
          }
        },
        showDialogic: false,
        showQuiz: false,
        showEmail: false,
        showLlmDialogic: false,
        showSidebar: true,
        id: this.$route.params.id,
        user_section_id: "",
        learnObjType: ""
      };
    },
    methods: {
      show() {
        this.$modal.show();
        this.isModalVisible = true;
      },
      hide(modal) {
        this.clearFormData();
        this.$modal.hide(modal);
        this.isModalVisible = false;
      },

      getUserLearnObj(id) {
        api
          .getUserLearningObject(id)
          .then(res => {
            this.userLearnObjData = res.data.data;
            this.learnObjData = res.data.data.attributes.learning_object;
            this.user_section_id = res.data.data.attributes.user_section_id;
            this.learnObjType = res.data.data.attributes.learning_object.attributes.card_type;
            this.getUserData(this.user_section_id);
            if (this.learnObjType == 'quiz' ) {
              this.showQuiz = true }
            else if (this.learnObjType == 'email' ) {
              this.showEmail = true }
            else if (this.learnObjType == 'dialogic' ) {
              this.showDialogic = true
            }
            else if (this.learnObjType == 'llm_dialogic' || this.learnObjType == 'llm_chat') {
              this.showLlmDialogic = true
            }
            else if (this.learnObjType == 'llm_email') {
              this.showLlmEmail = true
            }
          })
      },
      getUserData(id) {
        api
          .getUserSectionDetails(id)
          .then(res => {
            this.LOAdminDetails = res.data.data;
          })
      }

    },
    created() {
      this.getUserLearnObj(this.$route.params.id);
    }

};
</script>
