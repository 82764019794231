var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "organisationDetail",
        height: "auto",
        width: 500,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close ml-auto",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("organisationDetail")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "modal-edit-organisation" }, [
            _c("div", { staticClass: "organisation-edit-group" }, [
              _c("div", { staticClass: "organisation-edit-pic" }, [
                _vm.image
                  ? _c("img", { attrs: { src: _vm.image, alt: "image1" } })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "organisation-edit-title" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _c("div", { staticClass: "organisation-edit-text" }, [
                _vm._v("Created on " + _vm._s(_vm.created_on)),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.industry_name))]),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.description))]),
            _c("div", { staticClass: "organisation-customer-title" }, [
              _vm._v("Characters(" + _vm._s(_vm.count) + ")"),
            ]),
            _c("div", { staticClass: "customer-dropdown" }, [
              _vm.charactersList.length
                ? _c(
                    "ul",
                    { staticClass: "dropdown-list mt-0 mb-4" },
                    _vm._l(_vm.charactersList, function (character) {
                      return _c(
                        "li",
                        { key: character.id, staticClass: "dropdown-items" },
                        [
                          _c("div", { staticClass: "dropdown-pic" }, [
                            character.photo_url
                              ? _c("img", {
                                  attrs: {
                                    src: character.photo_url,
                                    alt: "image1",
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "dropdown-content" }, [
                            _c("div", { staticClass: "user-name" }, [
                              _vm._v(_vm._s(character.name)),
                            ]),
                            _c("div", { staticClass: "user-role" }, [
                              _vm._v(_vm._s(character.role)),
                            ]),
                            _c("div", { staticClass: "user-age" }, [
                              _vm._v(_vm._s(character.age) + " "),
                              character.gender != null
                                ? _c("div", { staticClass: "d-inline" }, [
                                    _vm._v(","),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(character.gender)),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c("div", { staticClass: "data-delete-dropdown" }, [
            _c(
              "button",
              {
                staticClass: "btn danger dropdown-toggle",
                attrs: {
                  type: "button",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                },
              },
              [_vm._v("Delete")]
            ),
            _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
              _c("div", { staticClass: "data-popper-title" }, [
                _vm._v("Are you sure you want to delete this organization?"),
              ]),
              _c("div", { staticClass: "data-popper-footer" }, [
                _c("a", { staticClass: "btn default" }, [_vm._v("cancel")]),
                _c(
                  "a",
                  {
                    staticClass: "btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.deleteOrganisation(_vm.organisation_id)
                      },
                    },
                  },
                  [_vm._v("Ok")]
                ),
              ]),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.hide("organisationDetail"),
                    _vm.$modal.show("createOrganisation", {
                      organisation_id: _vm.organisation_id,
                    })
                },
              },
            },
            [_vm._v("Edit Organization")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }