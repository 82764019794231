var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "dialogic" } },
        [
          _c(
            "div",
            {
              staticClass: "learning-form-header flex-wrap",
              class: [_vm.backSection ? "d-none" : ""],
            },
            [
              _c("h4", [_vm._v("Interaction(Dialogic)")]),
              _c("div", { staticClass: "learning-quiz-btn" }, [
                _vm.status == "published"
                  ? _c(
                      "a",
                      {
                        staticClass: "btn header-btn secondary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.restore()
                          },
                        },
                      },
                      [_vm._v("Save")]
                    )
                  : _vm._e(),
                _vm.status == "drafted"
                  ? _c(
                      "a",
                      {
                        staticClass: "btn header-btn primary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.restore()
                          },
                        },
                      },
                      [_vm._v("Publish LO")]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn ml-2 secondary",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("cloneConfirmationModal")
                      },
                    },
                  },
                  [_vm._v("Clone LO")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "learning-lo-tabs",
              class: [_vm.backSection ? "d-none" : ""],
            },
            [
              _c("div", { staticClass: "setup-container" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[0] },
                      on: {
                        click: function ($event) {
                          return _vm.handleDialogicContent(
                            $event,
                            "basic-information",
                            0
                          )
                        },
                      },
                    },
                    [_vm._m(0)]
                  ),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.isActiveTab[1] ||
                          _vm.dialogicContentId === "entity-setup",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDialogicContent(
                            $event,
                            "entity-setup",
                            1
                          )
                        },
                      },
                    },
                    [_vm._m(1)]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[2] },
                      on: {
                        click: function ($event) {
                          return _vm.handleDialogicContent(
                            $event,
                            "question-setup",
                            2
                          )
                        },
                      },
                    },
                    [_vm._m(2)]
                  ),
                  _vm.overallAssessment
                    ? _c(
                        "li",
                        {
                          class: { active: _vm.isActiveTab[3] },
                          on: {
                            click: function ($event) {
                              return _vm.handleDialogicContent(
                                $event,
                                "overall-assessment",
                                4
                              )
                            },
                          },
                        },
                        [_vm._m(3)]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "learning-quiz-content" },
            [
              _vm.dialogicContentId === "basic-information"
                ? _c(
                    "div",
                    { staticClass: "setup-content" },
                    [
                      _vm._m(4),
                      _c("ValidationObserver", { ref: "forms" }, [
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Interaction title")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Interaction Title",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.name,
                                                    expression: "name",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: { type: "text" },
                                                domProps: { value: _vm.name },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.name =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1240204542
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Administrative notes")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      rules: "required",
                                      name: "Administrative Notes",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.admin_notes,
                                                    expression: "admin_notes",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  id: "admin_notes",
                                                  rows: "8",
                                                },
                                                domProps: {
                                                  value: _vm.admin_notes,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.admin_notes =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2048479118
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "form-group pt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-checkboxes d-inline-flex",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "custom-checkbox right",
                                        attrs: { name: "quizAssessment" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.overallAssessment,
                                              expression: "overallAssessment",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            name: "quizAssessment",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.overallAssessment
                                            )
                                              ? _vm._i(
                                                  _vm.overallAssessment,
                                                  null
                                                ) > -1
                                              : _vm.overallAssessment,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.overallAssessment,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.overallAssessment =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.overallAssessment = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.overallAssessment = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "checkmark",
                                          attrs: { for: "quizAssessment" },
                                        }),
                                        _vm._v(
                                          " Use overall interaction assessment "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.overallAssessment
                                ? _c(
                                    "div",
                                    { staticClass: "form-group pt-1" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-checkboxes d-inline-flex",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "custom-checkbox right",
                                              attrs: {
                                                name: "displayOverallAssessment",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.displayOverallAssessment,
                                                    expression:
                                                      "displayOverallAssessment",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "displayOverallAssessment",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.displayOverallAssessment
                                                  )
                                                    ? _vm._i(
                                                        _vm.displayOverallAssessment,
                                                        null
                                                      ) > -1
                                                    : _vm.displayOverallAssessment,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.displayOverallAssessment,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.displayOverallAssessment =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.displayOverallAssessment =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.displayOverallAssessment =
                                                        $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass: "checkmark",
                                                attrs: {
                                                  for: "quizAssessment",
                                                },
                                              }),
                                              _vm._v(
                                                " Display overall assessment in learner feedback? "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.overallAssessment
                                ? _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("label", [
                                          _vm._v("Competency code"),
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.competencyCode,
                                              expression: "competencyCode",
                                            },
                                          ],
                                          staticClass:
                                            "form-control form-control-md ml-3",
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.competencyCode,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.competencyCode =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "form-group pt-1 mb-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          attrs: {
                                            name: "hideAssessmentLabel",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.hideAssessmentLabel,
                                                expression:
                                                  "hideAssessmentLabel",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "hideAssessmentLabel",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.hideAssessmentLabel
                                              )
                                                ? _vm._i(
                                                    _vm.hideAssessmentLabel,
                                                    null
                                                  ) > -1
                                                : _vm.hideAssessmentLabel,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.hideAssessmentLabel,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.hideAssessmentLabel =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.hideAssessmentLabel =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.hideAssessmentLabel = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: {
                                              for: "hideAssessmentLabel",
                                            },
                                          }),
                                          _vm._v(
                                            " Hide assessment labels in learner application? "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("br"),
                                  _c(
                                    "div",
                                    { staticClass: "form-group pt-1" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-checkboxes d-inline-flex",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "custom-checkbox right",
                                              attrs: { name: "missedSkills" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.enableMissedSkills,
                                                    expression:
                                                      "enableMissedSkills",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "missedSkills",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.enableMissedSkills
                                                  )
                                                    ? _vm._i(
                                                        _vm.enableMissedSkills,
                                                        null
                                                      ) > -1
                                                    : _vm.enableMissedSkills,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.enableMissedSkills,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.enableMissedSkills =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.enableMissedSkills =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.enableMissedSkills =
                                                        $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass: "checkmark",
                                                attrs: { for: "missedSkills" },
                                              }),
                                              _vm._v(
                                                " Remove Missed Skill on Questions "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          attrs: { name: "enableChatImView" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.enableChatImView,
                                                expression: "enableChatImView",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "hideAssessmentLabel",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.enableChatImView
                                              )
                                                ? _vm._i(
                                                    _vm.enableChatImView,
                                                    null
                                                  ) > -1
                                                : _vm.enableChatImView,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.enableChatImView,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.enableChatImView =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.enableChatImView =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.enableChatImView = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: {
                                              for: "hideAssessmentLabel",
                                            },
                                          }),
                                          _vm._v(
                                            " Enable chat view in learner application? "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group pt-1 mb-3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-checkboxes d-inline-flex",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "custom-checkbox right",
                                              attrs: { name: "learnerRetries" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.enableLearnerRetries,
                                                    expression:
                                                      "enableLearnerRetries",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "learnerRetries",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.enableLearnerRetries
                                                  )
                                                    ? _vm._i(
                                                        _vm.enableLearnerRetries,
                                                        null
                                                      ) > -1
                                                    : _vm.enableLearnerRetries,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.enableLearnerRetries,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.enableLearnerRetries =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.enableLearnerRetries =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.enableLearnerRetries =
                                                        $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass: "checkmark",
                                                attrs: {
                                                  for: "learnerRetries",
                                                },
                                              }),
                                              _vm._v(
                                                " Enable learner retries "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.enableLearnerRetries
                                    ? _c(
                                        "div",
                                        { staticClass: "form-group pt-1 mb-3" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { name: "maxRetries" } },
                                            [_vm._v(" Max number of retries ")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.maxNumberOfRetries,
                                                expression:
                                                  "maxNumberOfRetries",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              name: "maxRetries",
                                            },
                                            domProps: {
                                              value: _vm.maxNumberOfRetries,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.maxNumberOfRetries =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-sm-7" }, [
                              _c(
                                "div",
                                { staticClass: "form-group customer-dropdown" },
                                [
                                  _c("label", [
                                    _vm._v("Select dialogic character"),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.customer,
                                        expression: "customer",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && !_vm.addedCustomer.id
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "Search by name, role & organization",
                                    },
                                    domProps: { value: _vm.customer },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.customer = $event.target.value
                                        },
                                        _vm.getCharactersData,
                                      ],
                                    },
                                  }),
                                  !_vm.addedCustomer.id
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [_vm._v("Add customer is required")]
                                      )
                                    : _vm._e(),
                                  _vm.customer.length !== 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-row-scroll custom-user-dropdown",
                                        },
                                        _vm._l(
                                          _vm.searchCharacters,
                                          function (character) {
                                            return _c(
                                              "div",
                                              {
                                                key: character.id,
                                                staticClass: "user-row",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "img-container",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "user-info",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "head",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  character.char_full_name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  character.world_role
                                                                ) +
                                                                " at " +
                                                                _vm._s(
                                                                  character.org_name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                character.char_age
                                                              ) +
                                                              " , " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "uppCase"
                                                                )(
                                                                  character.char_gender,
                                                                  true
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                !character.status
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-action",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "add-action",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectCharacters(
                                                                  character,
                                                                  character.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("+")]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                character.status
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-action",
                                                      },
                                                      [
                                                        _c("em", {
                                                          staticClass:
                                                            "icon-check",
                                                        }),
                                                        _c("span", [
                                                          _vm._v("Added"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm.addedCustomer.id
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "customer-dropdown-results",
                                    },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "dropdown-list mt-0" },
                                        [
                                          _c(
                                            "li",
                                            { staticClass: "dropdown-items" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "user-row mb-0 flex-1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "img-container",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-info",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "head",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .addedCustomer
                                                                      .char_full_name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .addedCustomer
                                                                      .world_role
                                                                  ) +
                                                                  " at " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .addedCustomer
                                                                      .org_name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .addedCustomer
                                                                    .char_age
                                                                ) +
                                                                " , " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "uppCase"
                                                                  )(
                                                                    _vm
                                                                      .addedCustomer
                                                                      .char_gender,
                                                                    true
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-action",
                                                },
                                                [
                                                  !_vm.editWorldStatus
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "delete-action",
                                                          class: [
                                                            _vm.editWorldStatus
                                                              ? "disabled"
                                                              : "",
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeCustomer()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Remove")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.overallQuizAssessment
                                ? _c("div", { staticClass: "form-group" }, [
                                    _c("label", [
                                      _vm._v("Select assessment scheme"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "quiz-dropdown" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary dropdown-toggle",
                                            class: [
                                              _vm.isActive &&
                                              !_vm.assessmentListKey.id &&
                                              _vm.overallQuizAssessment
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "button",
                                              id: "entity",
                                              "data-toggle": "dropdown",
                                              "aria-haspopup": "true",
                                              "aria-expanded": "false",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.assessmentListKey.name
                                                ) +
                                                " "
                                            ),
                                            _c("em", {
                                              staticClass: "icon-carrot-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            attrs: {
                                              "aria-labelledby": "entity",
                                            },
                                          },
                                          _vm._l(
                                            _vm.assessmentLists,
                                            function (assessment) {
                                              return _c(
                                                "option",
                                                {
                                                  key: assessment.id,
                                                  staticClass: "dropdown-item",
                                                  domProps: {
                                                    value: `${assessment.id}*${assessment.attributes.name}`,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectedAssessment(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        assessment.attributes
                                                          .name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                    !_vm.assessmentListKey.id &&
                                    _vm.overallQuizAssessment
                                      ? _c(
                                          "span",
                                          {
                                            class: [
                                              _vm.isActive ? "invalid" : "",
                                            ],
                                            style: [
                                              _vm.isActive
                                                ? { display: "block" }
                                                : { display: "none" },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "Assessment Scheme field is required"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Description")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Description",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("vue-editor", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "Description",
                                                  editorToolbar: _vm.fullTool,
                                                  placeholder:
                                                    "Add a Description",
                                                },
                                                model: {
                                                  value: _vm.description,
                                                  callback: function ($$v) {
                                                    _vm.description = $$v
                                                  },
                                                  expression: "description",
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2757278820
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mb-4",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveDialogicContent(
                                            $event,
                                            "entity-setup",
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Next ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-container justify-content-end",
                                },
                                [
                                  _c("a", { staticClass: "delete-btn" }, [
                                    _c("div", { staticClass: "delete-box" }, [
                                      _c("span", {
                                        staticClass: "icon-delete",
                                      }),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text",
                                        on: {
                                          click: function ($event) {
                                            _vm.$route.query.id
                                              ? _vm.$parent.archiveLO(
                                                  "archived"
                                                )
                                              : ""
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Archive this Learning Object(LO)"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogicContentId === "entity-setup"
                ? _c(
                    "div",
                    {
                      staticClass: "setup-content",
                      attrs: { id: "entity-setup" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h4", { staticClass: "head-title" }, [
                            _vm._v("Entity Setup"),
                          ]),
                          _c("div", { staticClass: "learning-quiz-btn" }, [
                            _vm.dialogicContentId === "entity-setup"
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-auto header-btn secondary",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleImportCSV(
                                          $event,
                                          "dialog_skill"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Link to Assistant Dialog Skill")]
                                )
                              : _vm._e(),
                            _vm.dialogicContentId === "entity-setup"
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-auto header-btn secondary",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleImportCSV(
                                          $event,
                                          "sync_assistant"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Sync with Assistant")]
                                )
                              : _vm._e(),
                            _vm.dialogicContentId === "entity-setup"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn btn-auto header-btn secondary btn-type",
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "file" },
                                      on: { change: _vm.handleImportCSV },
                                    }),
                                    _vm._v(" Import CSV "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c("ValidationObserver", { ref: "form" }, [
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "big-label" }, [
                                  _vm._v("Key topic entities"),
                                ]),
                                _vm.asstEntitiesList
                                  ? _c(
                                      "ul",
                                      { staticClass: "form-entity-tabs" },
                                      _vm._l(
                                        _vm.asstEntitiesList,
                                        function (item) {
                                          return _c(
                                            "li",
                                            {
                                              key: item.id,
                                              class:
                                                item.id ===
                                                _vm.selectedAsstEntities.id
                                                  ? "active"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleAsstEntitiesList(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0);",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.attributes &&
                                                          item.attributes.name
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("span", [
                                                    _c("em", {
                                                      staticClass: "icon-close",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleAsstEntitiesList(
                                                            item,
                                                            true
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "form-group pt-3" }, [
                                _c("label", [_vm._v("Add key topic entity")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.entityName,
                                      expression: "entityName",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text", id: "name" },
                                  domProps: { value: _vm.entityName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.entityName = $event.target.value
                                    },
                                  },
                                }),
                                _c("div", { staticClass: "form-btn-group" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addEntity($event)
                                        },
                                      },
                                    },
                                    [_vm._v(" Add key topic entity ")]
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-7" }, [
                              _c(
                                "div",
                                { staticClass: "entity-tabs-content mt-0" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-8 form-group" },
                                      [
                                        _c("label", [_vm._v("Label")]),
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "Label",
                                            rules: "required|max:100",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .selectedAsstEntities
                                                              .attributes.label,
                                                          expression:
                                                            "selectedAsstEntities.attributes.label",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .selectedAsstEntities
                                                            .attributes.label,
                                                      },
                                                      on: {
                                                        blur: _vm.saveEntityLabel,
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm
                                                              .selectedAsstEntities
                                                              .attributes,
                                                            "label",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4291184233
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 form-group" },
                                      [
                                        _c("label", [_vm._v("Value")]),
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "Value",
                                            rules: "required|max:100",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.asstName,
                                                          expression:
                                                            "asstName",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value: _vm.asstName,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.asstName =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3250041726
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 form-group" },
                                      [
                                        _c("label", [_vm._v("Value Type")]),
                                        _c("div", [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.asstType,
                                                  expression: "asstType",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.asstType = $event.target
                                                    .multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                              },
                                            },
                                            [
                                              _c("option", [
                                                _vm._v("Synonyms"),
                                              ]),
                                              _c("option", [
                                                _vm._v("Patterns"),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm.asstType === "Synonyms"
                                      ? _c(
                                          "div",
                                          { staticClass: "col-12 form-group" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "Synonyms",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.asstSynonyms,
                                                              expression:
                                                                "asstSynonyms",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          class: [
                                                            _vm.isActive &&
                                                            errors[0]
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "enter synonyms here",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.asstSynonyms,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.asstSynonyms =
                                                                $event.target.value
                                                            },
                                                          },
                                                        }),
                                                        errors[0]
                                                          ? _c(
                                                              "span",
                                                              {
                                                                class: [
                                                                  _vm.isActive
                                                                    ? "invalid"
                                                                    : "",
                                                                ],
                                                                style: [
                                                                  _vm.isActive
                                                                    ? {
                                                                        display:
                                                                          "block",
                                                                      }
                                                                    : {
                                                                        display:
                                                                          "none",
                                                                      },
                                                                ],
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0].replace(
                                                                      "The ",
                                                                      ""
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3461105563
                                              ),
                                            }),
                                            _c("br"),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 form-group",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "add-more-btn",
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.addAssessmentValues,
                                                    },
                                                  },
                                                  [_vm._v("Save Value")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm.asstType === "Patterns"
                                      ? _c(
                                          "div",
                                          { staticClass: "col-12 form-group" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "Synonyms",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.asstSynonyms,
                                                              expression:
                                                                "asstSynonyms",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          class: [
                                                            _vm.isActive &&
                                                            errors[0]
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "enter pattern here",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.asstSynonyms,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.asstSynonyms =
                                                                $event.target.value
                                                            },
                                                          },
                                                        }),
                                                        errors[0]
                                                          ? _c(
                                                              "span",
                                                              {
                                                                class: [
                                                                  _vm.isActive
                                                                    ? "invalid"
                                                                    : "",
                                                                ],
                                                                style: [
                                                                  _vm.isActive
                                                                    ? {
                                                                        display:
                                                                          "block",
                                                                      }
                                                                    : {
                                                                        display:
                                                                          "none",
                                                                      },
                                                                ],
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0].replace(
                                                                      "The ",
                                                                      ""
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3610994321
                                              ),
                                            }),
                                            _c("br"),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 form-group",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "add-more-btn",
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.addAssessmentValuesPatterns,
                                                    },
                                                  },
                                                  [_vm._v("Save Value")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "quiz-table-data" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "quiz-table-head row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "quiz-tab-col col-4",
                                            },
                                            [_c("span", [_vm._v("Values")])]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "quiz-tab-col col-7",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Synonyms/Patterns"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.selectedAsstEntities
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "quiz-table-content",
                                            },
                                            _vm._l(
                                              _vm.selectedAsstEntities
                                                .attributes.asst_entity_values,
                                              function (asstValue) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: asstValue.id,
                                                    staticClass:
                                                      "quiz-table-list",
                                                  },
                                                  [
                                                    asstValue.attributes
                                                      .asst_entity_val_synonyms
                                                      .length
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "quiz-tabs-row row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col col-4",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      asstValue
                                                                        .attributes
                                                                        .value
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col col-7",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSynonymModal(
                                                                        asstValue
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "d-block",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$parent.getAssessmentValue(
                                                                          asstValue
                                                                            .attributes
                                                                            .asst_entity_val_synonyms
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col col-1 text-right",
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: "javascript:void(0);",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("em", {
                                                                      staticClass:
                                                                        "icon-close",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeAsstEntities(
                                                                              asstValue
                                                                            )
                                                                          },
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    asstValue.attributes
                                                      .asst_entity_val_patterns &&
                                                    asstValue.attributes
                                                      .asst_entity_val_patterns
                                                      .length
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "quiz-tabs-row row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col col-4",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      asstValue
                                                                        .attributes
                                                                        .value
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col col-7",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handlePatternModal(
                                                                        asstValue
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "d-block",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$parent.getAssessmentValuePattern(
                                                                          asstValue
                                                                            .attributes
                                                                            .asst_entity_val_patterns
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col col-1 text-right",
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: "javascript:void(0);",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("em", {
                                                                      staticClass:
                                                                        "icon-close",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeAsstEntities(
                                                                              asstValue
                                                                            )
                                                                          },
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mt-4 mb-3",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveDialogicContent(
                                            $event,
                                            "question-setup",
                                            2
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Next ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-container justify-content-end",
                                },
                                [
                                  _c("a", { staticClass: "delete-btn" }, [
                                    _c("div", { staticClass: "delete-box" }, [
                                      _c("span", {
                                        staticClass: "icon-delete",
                                      }),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text",
                                        on: {
                                          click: function ($event) {
                                            _vm.$route.query.id
                                              ? _vm.$parent.archiveLO(
                                                  "archived"
                                                )
                                              : ""
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Archive this Learning Object(LO)"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedSynonymList
                ? _c(
                    "modal",
                    {
                      attrs: {
                        name: "entityValueModal",
                        height: "auto",
                        width: 500,
                        scrollable: true,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-content modal-default" },
                        [
                          _c("div", { staticClass: "modal-header" }, [
                            _c(
                              "div",
                              {
                                staticClass: "modal-title",
                                attrs: { id: "exampleModalLabel" },
                              },
                              [
                                _c("h5", [
                                  _vm._v(
                                    "Entity value:" +
                                      _vm._s(
                                        _vm.selectedSynonymList.attributes.value
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "modal-body" }, [
                            _c(
                              "form",
                              {
                                staticClass: "modal-form modal-min-height",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.updateAssessmentValues("add")
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "form-group mt-2" }, [
                                  _c("label", [_vm._v("Synonyms")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.entitySynonymName,
                                        expression: "entitySynonymName",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.entitySynonymName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.entitySynonymName =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chip-container entity-chip",
                                    },
                                    _vm._l(
                                      _vm.selectedSynonymList.attributes
                                        .asst_entity_val_synonyms,
                                      function (synonym, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: synonym.id,
                                            staticClass: "chip",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  synonym.attributes.synonym
                                                )
                                              ),
                                            ]),
                                            _c("em", {
                                              staticClass: "icon-close",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateAssessmentValues(
                                                    "delete",
                                                    index,
                                                    synonym
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _c("div", { staticClass: "modal-footer" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn default",
                                      attrs: {
                                        type: "button",
                                        "data-dismiss": "modal",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$modal.hide(
                                            "entityValueModal"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateAssessmentValues(
                                            "done"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Done ")]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.selectedPatternList
                ? _c(
                    "modal",
                    {
                      attrs: {
                        name: "entityValueModalPattern",
                        height: "auto",
                        width: 500,
                        scrollable: true,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-content modal-default" },
                        [
                          _c("div", { staticClass: "modal-header" }, [
                            _c(
                              "div",
                              {
                                staticClass: "modal-title",
                                attrs: { id: "exampleModalLabel" },
                              },
                              [
                                _c("h5", [
                                  _vm._v(
                                    "Entity value:" +
                                      _vm._s(
                                        _vm.selectedPatternList.attributes.value
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "modal-body" }, [
                            _c(
                              "form",
                              {
                                staticClass: "modal-form modal-min-height",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.updateAssessmentValuesPattern(
                                      "add"
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "form-group mt-2" }, [
                                  _c("label", [_vm._v("Patterns")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.entityPatternName,
                                        expression: "entityPatternName",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.entityPatternName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.entityPatternName =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chip-container entity-chip",
                                    },
                                    _vm._l(
                                      _vm.selectedPatternList.attributes
                                        .asst_entity_val_patterns,
                                      function (pattern, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: pattern.id,
                                            staticClass: "chip",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  pattern.attributes.pattern
                                                )
                                              ),
                                            ]),
                                            _c("em", {
                                              staticClass: "icon-close",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateAssessmentValuesPattern(
                                                    "delete",
                                                    index,
                                                    pattern
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _c("div", { staticClass: "modal-footer" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn default",
                                      attrs: {
                                        type: "button",
                                        "data-dismiss": "modal",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$modal.hide(
                                            "entityValueModalPattern"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateAssessmentValuesPattern(
                                            "done"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Done ")]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "modal",
                {
                  attrs: {
                    name: "cloneConfirmationModal",
                    height: "auto",
                    width: 350,
                    scrollable: true,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-content modal-delete-content" },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-body modal-delete-body" },
                        [
                          _vm._v(
                            " Are you sure you want to clone this learning object? "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "modal-footer justify-content-center" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn medium default",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$modal.hide(
                                    "cloneConfirmationModal"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn medium primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.cloneLo(),
                                    _vm.$modal.hide("cloneConfirmationModal")
                                },
                              },
                            },
                            [_vm._v(" Ok ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm.dialogicContentId === "question-setup" &&
              _vm.backSection === false
                ? _c(
                    "div",
                    {
                      staticClass: "setup-content",
                      attrs: { id: "question-setup" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h4", { staticClass: "head-title" }, [
                            _vm._v("Question Setup"),
                          ]),
                          _c("div", { staticClass: "learning-quiz-btn" }, [
                            _c(
                              "a",
                              {
                                class: `btn header-btn secondary ${
                                  _vm.QuestionList.length > 0 ? "" : "disabled"
                                }`,
                                attrs: { href: "javascript:void(0);" },
                                on: { click: _vm.handleselectQuestionToTest },
                              },
                              [_vm._v("Test")]
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "ul",
                              { staticClass: "form-entity-tabs" },
                              [
                                _c(
                                  "li",
                                  {
                                    class: _vm.addIntro ? "active" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addQuestion(true, false)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0);" },
                                      },
                                      [_vm._v("Introduction")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "draggable",
                                  {
                                    attrs: {
                                      sort: true,
                                      move: _vm.reorderQuestions,
                                    },
                                    on: {
                                      start: function ($event) {
                                        _vm.isDragging = true
                                      },
                                      end: function ($event) {
                                        _vm.isDragging = true
                                      },
                                    },
                                    model: {
                                      value: _vm.QuestionList,
                                      callback: function ($$v) {
                                        _vm.QuestionList = $$v
                                      },
                                      expression: "QuestionList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.QuestionList,
                                    function (question, key) {
                                      return _c(
                                        "li",
                                        {
                                          key: key,
                                          class:
                                            _vm.queId === question.id
                                              ? "active"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addQuestion(
                                                false,
                                                false,
                                                question.id,
                                                key
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  question.attributes.order
                                                ) +
                                                  ": " +
                                                  _vm._s(
                                                    question.attributes.concept
                                                  ) +
                                                  " "
                                              ),
                                              _vm.queId === question.id
                                                ? _c("span", [
                                                    _c("em", {
                                                      staticClass: "icon-close",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$modal.show(
                                                            "deleteQstnConfirmationModal"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "li",
                                  {
                                    class: _vm.addCon ? "active" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addQuestion(false, true)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0);" },
                                      },
                                      [_vm._v("Conclusion")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "form-btn-group" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-full primary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addQuestion(false, false, null)
                                  },
                                },
                              },
                              [_vm._v("Add Question")]
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-sm-7" },
                          [
                            _c("ValidationObserver", { ref: "formdata" }, [
                              _c("form", { staticClass: "w-100" }, [
                                !_vm.addIntro && !_vm.addCon
                                  ? _c(
                                      "div",
                                      { attrs: { id: "elevator-pitch" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("label", [_vm._v("Concept")]),
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "Concept",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.concept,
                                                              expression:
                                                                "concept",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          class: [
                                                            _vm.isActive &&
                                                            errors[0]
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "Concept",
                                                          },
                                                          domProps: {
                                                            value: _vm.concept,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.concept =
                                                                $event.target.value
                                                            },
                                                          },
                                                        }),
                                                        errors && errors[0]
                                                          ? _c(
                                                              "span",
                                                              {
                                                                class: [
                                                                  _vm.isActive
                                                                    ? "invalid"
                                                                    : "",
                                                                ],
                                                                style: [
                                                                  _vm.isActive
                                                                    ? {
                                                                        display:
                                                                          "block",
                                                                      }
                                                                    : {
                                                                        display:
                                                                          "none",
                                                                      },
                                                                ],
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0].replace(
                                                                      "The ",
                                                                      ""
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1066157728
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "Concept Description (Evaluation explanation)"
                                              ),
                                            ]),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.conceptDescription,
                                                  expression:
                                                    "conceptDescription",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              class: [
                                                _vm.isActive && _vm.errors[0]
                                                  ? "invalid"
                                                  : "",
                                              ],
                                              attrs: {
                                                id: "conceptDescription",
                                                rows: "4",
                                              },
                                              domProps: {
                                                value: _vm.conceptDescription,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.conceptDescription =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "assessment-response",
                                              _vm.question_variations_attributes
                                                .length > 0
                                                ? "pt-2"
                                                : "",
                                            ],
                                          },
                                          [
                                            _vm.question_variations_attributes
                                              .length > 0
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass: "response-tab",
                                                  },
                                                  _vm._l(
                                                    _vm.question_variations_attributes,
                                                    function (variations, key) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: key,
                                                          class:
                                                            _vm.variationsSelect ===
                                                            key + 1
                                                              ? "active"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "javascript:void(0);",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addVariation(
                                                                      key + 1
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Question Variation " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .question_variations_attributes
                                                                      .length >
                                                                      1
                                                                      ? key + 1
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _vm.question_variations_attributes
                                              .length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "response-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-control p-0",
                                                          },
                                                          [
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                attrs: {
                                                                  name: "Variation",
                                                                  rules:
                                                                    "required|max:4500",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "vue-editor",
                                                                              {
                                                                                class:
                                                                                  [
                                                                                    "form-control",
                                                                                    _vm.isActive &&
                                                                                    errors[0]
                                                                                      ? "invalid"
                                                                                      : "",
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    rows: "6",
                                                                                    placeholder:
                                                                                      "variation",
                                                                                    editorToolbar:
                                                                                      _vm.fullTool,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .question_variations_attributes[
                                                                                        _vm.variationsSelect -
                                                                                          1
                                                                                      ]
                                                                                        .question,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .question_variations_attributes[
                                                                                            _vm.variationsSelect -
                                                                                              1
                                                                                          ],
                                                                                          "question",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "\n                                question_variations_attributes[\n                                  variationsSelect - 1\n                                ].question\n                              ",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            errors[0]
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? "invalid"
                                                                                          : "",
                                                                                      ],
                                                                                    style:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? {
                                                                                              display:
                                                                                                "block",
                                                                                            }
                                                                                          : {
                                                                                              display:
                                                                                                "none",
                                                                                            },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        errors[0].replace(
                                                                                          "The ",
                                                                                          ""
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    4253255965
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add-more-btn",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm
                                                      .question_variations_attributes
                                                      .length < 5
                                                      ? _vm.addVariation(null)
                                                      : ""
                                                  },
                                                },
                                              },
                                              [_vm._v("+ Add Variation")]
                                            ),
                                            _vm.question_variations_attributes
                                              .length <= 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Add Variation field is required"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "assessment-topics" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "topic-head" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-4" },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Key Topics:"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-8 topic-keywords",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Entity Values:"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.addAsstEntitiesList.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "topic-list-content",
                                                  },
                                                  _vm._l(
                                                    _vm.addAsstEntitiesList,
                                                    function (entity, key) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: key,
                                                          staticClass:
                                                            "topic-items",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-4",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.switchToAssessmentSetup(
                                                                              entity.id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          entity
                                                                            .attributes
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-8 topic-keywords",
                                                                },
                                                                _vm._l(
                                                                  entity
                                                                    .attributes
                                                                    .asst_entity_values,
                                                                  function (
                                                                    synonyms
                                                                  ) {
                                                                    return _c(
                                                                      "span",
                                                                      {
                                                                        key: synonyms.id,
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleSynonymModal(
                                                                                synonyms
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            synonyms
                                                                              .attributes
                                                                              .value
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add-more-btn",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$modal.show(
                                                      "keyTopicsModal"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("+ Add key topic")]
                                            ),
                                            _vm.addAsstEntitiesList.length <= 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Add key topic field is required"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { attrs: { id: "introduction" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.addIntro
                                                    ? "Introduction"
                                                    : "Conclusion"
                                                )
                                              ),
                                            ]),
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: _vm.addIntro
                                                  ? "Introduction"
                                                  : "Conclusion",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("textarea", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.questionText,
                                                              expression:
                                                                "questionText",
                                                            },
                                                          ],
                                                          class: [
                                                            "form-control",
                                                            _vm.isActive &&
                                                            errors &&
                                                            errors[0]
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          attrs: {
                                                            rows: "6",
                                                            placeholder:
                                                              _vm.addIntro
                                                                ? "Introduction"
                                                                : "Conclusion",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.questionText,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.questionText =
                                                                $event.target.value
                                                            },
                                                          },
                                                        }),
                                                        errors && errors[0]
                                                          ? _c(
                                                              "span",
                                                              {
                                                                class: [
                                                                  _vm.isActive
                                                                    ? "invalid"
                                                                    : "",
                                                                ],
                                                                style: [
                                                                  _vm.isActive
                                                                    ? {
                                                                        display:
                                                                          "block",
                                                                      }
                                                                    : {
                                                                        display:
                                                                          "none",
                                                                      },
                                                                ],
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0].replace(
                                                                      "The ",
                                                                      ""
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1209793566
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                _c(
                                  "div",
                                  { staticClass: "form-group pt-3 text-right" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn secondary",
                                        attrs: {
                                          id: "feedback-tab",
                                          "data-toggle": "tab",
                                          href: "#nav-feedback",
                                          role: "tab",
                                          "aria-controls": "nav-feedback",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleQuestionChange("")
                                          },
                                        },
                                      },
                                      [_vm._v("Save")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-sm-12 mt-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _vm.overallAssessment
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveDialogicContent(
                                            $event,
                                            "overall-assessment",
                                            4
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Next ")]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.restore()
                                        },
                                      },
                                    },
                                    [_vm._v(" Publish LO ")]
                                  ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _vm._m(5),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        _vm.$route.query.id
                                          ? _vm.$parent.archiveLO("archived")
                                          : ""
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "modal",
                {
                  attrs: {
                    name: "keyTopicsModal",
                    height: "auto",
                    width: 500,
                    scrollable: true,
                  },
                  on: { "before-open": _vm.beforeOpen },
                },
                [
                  _c("div", { staticClass: "modal-content modal-default" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _c(
                        "div",
                        {
                          staticClass: "modal-title",
                          attrs: { id: "exampleModalLabel" },
                        },
                        [_c("h5", [_vm._v("Key Topics")])]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [
                        _c("ValidationObserver", { ref: "form" }, [
                          _c(
                            "form",
                            { staticClass: "modal-form modal-min-height" },
                            [
                              _c(
                                "div",
                                { staticClass: "modal-keyword-checkbox" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "modal-keyword-list" },
                                    _vm._l(
                                      _vm.asstEntitiesList,
                                      function (entities, key) {
                                        return _c(
                                          "div",
                                          {
                                            key: key,
                                            staticClass: "form-checkboxes",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  display: "none",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (_vm.index =
                                                        _vm.addAsstEntitiesList.findIndex(
                                                          (y) => {
                                                            return (
                                                              parseInt(y.id) ===
                                                              parseInt(
                                                                entities.id
                                                              )
                                                            )
                                                          }
                                                        ))
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "custom-checkbox",
                                                class: [
                                                  _vm.editWorldStatus
                                                    ? "disabled"
                                                    : "",
                                                ],
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.editWorldStatus ==
                                                      true,
                                                    type: "checkbox",
                                                    name: "userOverall1",
                                                  },
                                                  domProps: {
                                                    value: "userOverall1",
                                                    checked:
                                                      _vm.index !== -1
                                                        ? "checked"
                                                        : "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectEntity(
                                                        entities.id
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "keyword-value",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          entities.attributes
                                                            .name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn default",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$modal.hide("keyTopicsModal")
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$modal.hide("keyTopicsModal")
                                      },
                                    },
                                  },
                                  [_vm._v(" Done ")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "modal",
                {
                  attrs: {
                    name: "deleteQstnConfirmationModal",
                    height: "auto",
                    width: 350,
                    scrollable: true,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-content modal-delete-content" },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-body modal-delete-body" },
                        [
                          _vm._v(
                            " It also removes the learner answers/evaluations (if there are any). Are you sure you want to delete this question? "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "modal-footer justify-content-center" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn medium default",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$modal.hide(
                                    "deleteQstnConfirmationModal"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn medium primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.deleteQuestion(),
                                    _vm.$modal.hide(
                                      "deleteQstnConfirmationModal"
                                    )
                                },
                              },
                            },
                            [_vm._v(" Ok ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm.dialogicContentId === "overall-assessment"
                ? _c(
                    "div",
                    { staticClass: "setup-content" },
                    [
                      _vm._m(6),
                      _vm.overallAssmntItem && _vm.overallAssmntItem.length > 0
                        ? _c("ValidationObserver", { ref: "formOverall" }, [
                            _c("form", { staticClass: "w-100" }, [
                              _c("div", { staticClass: "row custom-row" }, [
                                _c("div", { staticClass: "col-sm-5" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", [
                                      _vm._v("Overall Interaction Assessment"),
                                    ]),
                                    _vm.overallAssmntItem &&
                                    _vm.overallAssmntItem.length > 0
                                      ? _c(
                                          "ul",
                                          { staticClass: "form-entity-tabs" },
                                          _vm._l(
                                            _vm.overallAssmntItem,
                                            function (assmntItem) {
                                              return _c(
                                                "li",
                                                {
                                                  key: assmntItem.id,
                                                  class:
                                                    assmntItem.id ==
                                                    _vm
                                                      .selectedOverallAssmntItem
                                                      .id
                                                      ? "active"
                                                      : "",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleOverallAssmntItem(
                                                        assmntItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:void(0);",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          assmntItem.attributes
                                                            .assessment_label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-sm-7" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "question-content",
                                      attrs: { id: "number-range" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "question-row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", [
                                                _vm._v("Evaluation Text"),
                                              ]),
                                              _c("vue-editor", {
                                                attrs: {
                                                  id: "Descriptiontext",
                                                  placeholder:
                                                    "Add a Description",
                                                  editorToolbar: _vm.fullTool,
                                                },
                                                model: {
                                                  value:
                                                    _vm.overallAssmntItemFeedback,
                                                  callback: function ($$v) {
                                                    _vm.overallAssmntItemFeedback =
                                                      $$v
                                                  },
                                                  expression:
                                                    "overallAssmntItemFeedback",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group pt-2" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Evaluation range(" +
                                                    _vm._s(
                                                      _vm.overallAssessmentRange
                                                    ) +
                                                    "):"
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c("label", [
                                                        _vm._v("Minimum"),
                                                      ]),
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Minimum",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm.overallAssmntItemMinScore,
                                                                          expression:
                                                                            "overallAssmntItemMinScore",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    class: [
                                                                      _vm.isActive &&
                                                                      errors[0]
                                                                        ? "invalid"
                                                                        : "",
                                                                    ],
                                                                    attrs: {
                                                                      type: "number",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm.overallAssmntItemMinScore,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.overallAssmntItemMinScore =
                                                                            $event.target.value
                                                                        },
                                                                    },
                                                                  }),
                                                                  errors[0]
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            [
                                                                              _vm.isActive
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          style:
                                                                            [
                                                                              _vm.isActive
                                                                                ? {
                                                                                    display:
                                                                                      "block",
                                                                                  }
                                                                                : {
                                                                                    display:
                                                                                      "none",
                                                                                  },
                                                                            ],
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              errors[0].replace(
                                                                                "The ",
                                                                                ""
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1069256972
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c("label", [
                                                        _vm._v("Maximum"),
                                                      ]),
                                                      _c("ValidationProvider", {
                                                        attrs: {
                                                          name: "Maximum",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm.overallAssmntItemMaxScore,
                                                                          expression:
                                                                            "overallAssmntItemMaxScore",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    class: [
                                                                      _vm.isActive &&
                                                                      errors[0]
                                                                        ? "invalid"
                                                                        : "",
                                                                    ],
                                                                    attrs: {
                                                                      type: "number",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm.overallAssmntItemMaxScore,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.overallAssmntItemMaxScore =
                                                                            $event.target.value
                                                                        },
                                                                    },
                                                                  }),
                                                                  errors[0]
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            [
                                                                              _vm.isActive
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          style:
                                                                            [
                                                                              _vm.isActive
                                                                                ? {
                                                                                    display:
                                                                                      "block",
                                                                                  }
                                                                                : {
                                                                                    display:
                                                                                      "none",
                                                                                  },
                                                                            ],
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              errors[0].replace(
                                                                                "The ",
                                                                                ""
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          8271116
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-12 pt-5" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end mt-4 mb-3",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn primary",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleOverallAssmntItem(
                                                _vm.selectedOverallAssmntItem,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Save & Publish")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn-container justify-content-end",
                                    },
                                    [
                                      _c("a", { staticClass: "delete-btn" }, [
                                        _c(
                                          "div",
                                          { staticClass: "delete-box" },
                                          [
                                            _c("span", {
                                              staticClass: "icon-delete",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text",
                                            on: {
                                              click: function ($event) {
                                                _vm.$route.query.id
                                                  ? _vm.$parent.archiveLO(
                                                      "archived"
                                                    )
                                                  : ""
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Archive this Learning Object(LO)"
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "modal",
            {
              staticClass: "modal-video",
              attrs: {
                name: "dialogicTesting",
                height: "auto",
                width: 1000,
                scrollable: true,
              },
            },
            [
              _c("div", { staticClass: "modal-content modal-test-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "exampleModalLabel" },
                    },
                    [
                      _c("h5", [_vm._v("Testing")]),
                      _c("div", { staticClass: "modal-right-box" }, [
                        _c("div", { staticClass: "modal-head-checkbox" }, [
                          _c("label", { staticClass: "custom-checkbox mr-4" }, [
                            _vm._v(" Test entire Dialogic "),
                            _c("input", {
                              attrs: { type: "checkbox" },
                              on: { change: _vm.changeQuestionTest },
                            }),
                            _c("span", { staticClass: "checkmark circle" }),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button" },
                      on: { click: _vm.hideTestModal },
                    },
                    [_c("em", { staticClass: "icon-close" })]
                  ),
                ]),
                _vm.allQuestionTestId
                  ? _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "form",
                        { staticClass: "modal-form modal-min-height" },
                        [
                          _c("div", { staticClass: "form-group mt-2" }, [
                            _c("label", [_vm._v("Select Question")]),
                            _c("div", { staticClass: "test-dropdown" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-secondary dropdown-toggle",
                                  attrs: {
                                    type: "button",
                                    id: "entity",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectQuestionToTest &&
                                          _vm.selectQuestionToTest.name
                                      ) +
                                      " "
                                  ),
                                  _c("em", { staticClass: "icon-carrot-down" }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { "aria-labelledby": "entity" },
                                },
                                _vm._l(
                                  _vm.QuestionList,
                                  function (item, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: item.id,
                                        staticClass: "dropdown-item",
                                        domProps: { value: index },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleselectQuestionToTest(
                                              $event,
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.attributes.concept) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _vm.ansResponse
                            ? _c("div", { staticClass: "question-preview" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group d-flex" },
                                  [
                                    _c("div", { staticClass: "test-user-pic" }),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectQuestionToTest &&
                                              _vm.selectQuestionToTest
                                                .varationName
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.ansResponse &&
                                _vm.ansResponse.attributes &&
                                _vm.ansResponse.attributes.follow_up_question
                                  ? _c("div", { staticClass: "mb-5" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-2" },
                                        [_c("label", [_vm._v("Answer")])]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.ansResponse.attributes.answer
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.ansResponse &&
                          _vm.ansResponse.attributes &&
                          _vm.ansResponse.attributes.follow_up_question
                            ? _c("div", { staticClass: "question-edit" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group d-flex" },
                                  [
                                    _c("div", { staticClass: "test-user-pic" }),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.ansResponse
                                              ? _vm.selectQuestionToTest
                                                  .varationName
                                              : _vm.ansResponse.attributes
                                                  .follow_up_question
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.selectQuestionToTest.data
                                  ? _c("div", { staticClass: "form-group" }, [
                                      _c("label", [_vm._v("Answer")]),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.testQuizAnswer,
                                            expression: "testQuizAnswer",
                                          },
                                        ],
                                        staticClass:
                                          "form-control form-textarea",
                                        domProps: { value: _vm.testQuizAnswer },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.testQuizAnswer =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("div", { staticClass: "question-edit" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", [_vm._v("Answer")]),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.testQuizAnswer,
                                        expression: "testQuizAnswer",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    domProps: { value: _vm.testQuizAnswer },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.testQuizAnswer = $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.testQuizAnswer.length === 0,
                                },
                                on: { click: _vm.handleCheckQuizTest },
                              },
                              [_vm._v(" Test ")]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                !_vm.allQuestionTestId
                  ? _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticClass: "learn-object" }, [
                        _c("div", { staticClass: "learn-object-progress" }, [
                          _c("div", { staticClass: "progress" }, [
                            _c("div", {
                              staticClass: "progress-bar",
                              style: _vm.selectQuestionToTest.statusBar,
                              attrs: {
                                role: "progressbar",
                                "aria-valuenow": "50",
                                "aria-valuemin": "0",
                                "aria-valuemax": "100",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "learn-object-question" }, [
                            _vm._v(
                              " Question " +
                                _vm._s(_vm.selectQuestionToTest.id + 1) +
                                "/" +
                                _vm._s(_vm.QuestionList.length) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "learn-object-editor" }, [
                          _vm.selectQuestionToTest &&
                          _vm.selectQuestionToTest.data
                            ? _c(
                                "div",
                                { staticClass: "learn-object-info mt-0" },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectQuestionToTest.varationName
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "learn-voice-form" }, [
                            _c(
                              "div",
                              { staticClass: "learn-voice-checkboxes" },
                              [
                                _vm.ansResponse &&
                                _vm.ansResponse.attributes &&
                                _vm.ansResponse.attributes.follow_up_question
                                  ? _c("div", { staticClass: "mb-5" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-2" },
                                        [_c("label", [_vm._v("Answer")])]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.ansResponse.attributes.answer
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.ansResponse &&
                                _vm.ansResponse.attributes &&
                                _vm.ansResponse.attributes.follow_up_question
                                  ? _c(
                                      "div",
                                      { staticClass: "question-edit" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group d-flex" },
                                          [
                                            _c("div", {
                                              staticClass: "test-user-pic",
                                            }),
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !_vm.ansResponse
                                                      ? _vm.selectQuestionToTest
                                                          .varationName
                                                      : _vm.ansResponse
                                                          .attributes
                                                          .follow_up_question
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm.selectQuestionToTest.data
                                          ? _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c("label", [_vm._v("Answer")]),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.testQuizAnswer,
                                                      expression:
                                                        "testQuizAnswer",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control form-textarea",
                                                  domProps: {
                                                    value: _vm.testQuizAnswer,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.testQuizAnswer =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "question-edit" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("label", [_vm._v("Answer")]),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.testQuizAnswer,
                                                  expression: "testQuizAnswer",
                                                },
                                              ],
                                              staticClass:
                                                "form-control form-textarea",
                                              domProps: {
                                                value: _vm.testQuizAnswer,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.testQuizAnswer =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                _c("div", {}, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn secondary",
                                      attrs: {
                                        disabled:
                                          _vm.testQuizAnswer.length === 0,
                                      },
                                      on: { click: _vm.handleCheckQuizTest },
                                    },
                                    [_vm._v(" Test ")]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "learn-object-pagination" },
                              [
                                _vm.selectQuestionToTest.id + 1 !== 1
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "btn default",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleTestPage(false)
                                          },
                                        },
                                      },
                                      [_vm._v("Previous")]
                                    )
                                  : _vm._e(),
                                _vm.selectQuestionToTest.id + 1 <
                                _vm.totalQuizQues
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "btn primary ml-auto",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleTestPage(true)
                                          },
                                        },
                                      },
                                      [_vm._v("Next")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.ansResponse
                  ? _c("div", { staticClass: "modal-test-tabs" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "nav nav-tabs",
                          attrs: { id: "myTab", role: "tablist" },
                        },
                        [
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link active",
                                attrs: {
                                  id: "Test-tab",
                                  "data-toggle": "tab",
                                  href: "#Test",
                                  role: "tab",
                                  "aria-controls": "Test",
                                  "aria-selected": "true",
                                },
                              },
                              [_vm._v("Test input Return Data")]
                            ),
                          ]),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  id: "json-tab",
                                  "data-toggle": "tab",
                                  href: "#json",
                                  role: "tab",
                                  "aria-controls": "json",
                                  "aria-selected": "false",
                                },
                              },
                              [_vm._v("View JSON")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-content pt-2",
                          attrs: { id: "myTabContent" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade show active",
                              attrs: {
                                id: "Test",
                                role: "tabpanel",
                                "aria-labelledby": "Test-tab",
                              },
                            },
                            [
                              _c("div", { staticClass: "test-items" }, [
                                _c("label", [_vm._v("Character Response:")]),
                                _c(
                                  "div",
                                  { staticClass: "test-chips-container" },
                                  [
                                    _c("span", { staticClass: "test-chips" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ansResponse.attributes
                                              .character_response
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.ansResponse.attributes.key_topic_evaluations
                                  .length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.ansResponse.attributes
                                          .key_topic_evaluations,
                                        function (evaluations) {
                                          return _c(
                                            "div",
                                            {
                                              key: evaluations.id,
                                              staticClass: "test-items",
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Assessment and Entity Evaluation Set hit:"
                                                ),
                                              ]),
                                              evaluations.attributes
                                                .missed_assmnt_item_id
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "test-chips-container",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "test-chips",
                                                        },
                                                        [_vm._v(" Missed ")]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "test-chips-container",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-wrap",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "test-chips",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Dialogic entity : " +
                                                                  _vm._s(
                                                                    evaluations
                                                                      .attributes
                                                                      .key_topic &&
                                                                      evaluations
                                                                        .attributes
                                                                        .key_topic
                                                                        .attributes
                                                                        .asst_entities
                                                                      ? evaluations
                                                                          .attributes
                                                                          .key_topic
                                                                          .attributes
                                                                          .asst_entities
                                                                          .attributes
                                                                          .name
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          evaluations.attributes
                                                            .key_topic &&
                                                          evaluations.attributes
                                                            .key_topic
                                                            .attributes
                                                            .asst_entities &&
                                                          evaluations.attributes
                                                            .key_topic
                                                            .attributes
                                                            .asst_entities
                                                            .attributes
                                                            .asst_entity_values
                                                            .length > 0
                                                            ? _c(
                                                                "div",
                                                                _vm._l(
                                                                  evaluations
                                                                    .attributes
                                                                    .key_topic
                                                                    .attributes
                                                                    .asst_entities
                                                                    .attributes
                                                                    .asst_entity_values,
                                                                  function (
                                                                    asst_entity_values
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: asst_entity_values.id,
                                                                        staticClass:
                                                                          "d-flex flex-wrap",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "test-chips",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Value: " +
                                                                                _vm._s(
                                                                                  asst_entity_values
                                                                                    .attributes
                                                                                    .value
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          asst_entity_values
                                                                            .attributes
                                                                            .asst_entity_val_synonyms,
                                                                          function (
                                                                            synonyms
                                                                          ) {
                                                                            return _c(
                                                                              "p",
                                                                              {
                                                                                key: synonyms.id,
                                                                                staticClass:
                                                                                  "test-chips",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      synonyms
                                                                                        .attributes
                                                                                        .synonym
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tab-pane fade enrichment-concept-editor",
                              attrs: {
                                id: "json",
                                role: "tabpanel",
                                "aria-labelledby": "json-tab",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "enrichment-concept-code" },
                                [
                                  _c("json-viewer", {
                                    attrs: {
                                      value: _vm.ansResponse,
                                      "expand-depth": 5,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "enrichment-code-copy" },
                                [
                                  _c(
                                    "copy-to-clipboard",
                                    {
                                      attrs: {
                                        text: JSON.stringify(_vm.ansResponse),
                                      },
                                      on: { copy: _vm.handleCopy },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "copy-icon",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                        },
                                        [_vm._v("Copy")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.ansResponse1
                  ? _c("div", { staticClass: "modal-test-tabs" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "nav nav-tabs",
                          attrs: { id: "myTab", role: "tablist" },
                        },
                        [
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link active",
                                attrs: {
                                  id: "Test-tab",
                                  "data-toggle": "tab",
                                  href: "#Test1",
                                  role: "tab",
                                  "aria-controls": "Test",
                                  "aria-selected": "true",
                                },
                              },
                              [_vm._v("Test input Return Data")]
                            ),
                          ]),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  id: "json-tab",
                                  "data-toggle": "tab",
                                  href: "#json1",
                                  role: "tab",
                                  "aria-controls": "json",
                                  "aria-selected": "false",
                                },
                              },
                              [_vm._v("View JSON")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-content pt-2",
                          attrs: { id: "myTabContent" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade show active",
                              attrs: {
                                id: "Test1",
                                role: "tabpanel",
                                "aria-labelledby": "Test-tab",
                              },
                            },
                            [
                              _c("div", { staticClass: "test-items" }, [
                                _c("label", [_vm._v("Character Response:")]),
                                _c(
                                  "div",
                                  { staticClass: "test-chips-container" },
                                  [
                                    _c("span", { staticClass: "test-chips" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ansResponse1.attributes
                                              .character_response
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.ansResponse1.attributes
                                  .key_topic_evaluations.length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.ansResponse1.attributes
                                          .key_topic_evaluations,
                                        function (evaluations) {
                                          return _c(
                                            "div",
                                            {
                                              key: evaluations.id,
                                              staticClass: "test-items",
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Assessment and Entity Evaluation Set hit:"
                                                ),
                                              ]),
                                              evaluations.attributes
                                                .missed_assmnt_item_id
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "test-chips-container",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "test-chips",
                                                        },
                                                        [_vm._v(" Missed ")]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "test-chips-container",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-wrap",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "test-chips",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Dialogic entity : " +
                                                                  _vm._s(
                                                                    evaluations
                                                                      .attributes
                                                                      .key_topic &&
                                                                      evaluations
                                                                        .attributes
                                                                        .key_topic
                                                                        .attributes
                                                                        .asst_entities
                                                                      ? evaluations
                                                                          .attributes
                                                                          .key_topic
                                                                          .attributes
                                                                          .asst_entities
                                                                          .attributes
                                                                          .name
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          evaluations.attributes
                                                            .key_topic &&
                                                          evaluations.attributes
                                                            .key_topic
                                                            .attributes
                                                            .asst_entities &&
                                                          evaluations.attributes
                                                            .key_topic
                                                            .attributes
                                                            .asst_entities
                                                            .attributes
                                                            .asst_entity_values
                                                            .length > 0
                                                            ? _c(
                                                                "div",
                                                                _vm._l(
                                                                  evaluations
                                                                    .attributes
                                                                    .key_topic
                                                                    .attributes
                                                                    .asst_entities
                                                                    .attributes
                                                                    .asst_entity_values,
                                                                  function (
                                                                    asst_entity_values
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: asst_entity_values.id,
                                                                        staticClass:
                                                                          "d-flex flex-wrap",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "test-chips",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Value: " +
                                                                                _vm._s(
                                                                                  asst_entity_values
                                                                                    .attributes
                                                                                    .value
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          asst_entity_values
                                                                            .attributes
                                                                            .asst_entity_val_synonyms,
                                                                          function (
                                                                            synonyms
                                                                          ) {
                                                                            return _c(
                                                                              "p",
                                                                              {
                                                                                key: synonyms.id,
                                                                                staticClass:
                                                                                  "test-chips",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      synonyms
                                                                                        .attributes
                                                                                        .synonym
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tab-pane fade enrichment-concept-editor",
                              attrs: {
                                id: "json1",
                                role: "tabpanel",
                                "aria-labelledby": "json-tab",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "enrichment-concept-code" },
                                [
                                  _c("json-viewer", {
                                    attrs: {
                                      value: _vm.ansResponse1,
                                      "expand-depth": 5,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "enrichment-code-copy" },
                                [
                                  _c(
                                    "copy-to-clipboard",
                                    {
                                      attrs: {
                                        text: JSON.stringify(_vm.ansResponse1),
                                      },
                                      on: { copy: _vm.handleCopy },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "copy-icon",
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                        },
                                        [_vm._v("Copy")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            { class: ["back-section", _vm.backSection ? "" : "d-none"] },
            [
              _c("div", { staticClass: "learning-form-header flex-wrap" }, [
                _c("h4", [
                  _c(
                    "a",
                    {
                      staticClass: "back-btn-inner",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.backSectionData(false)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "icon-back" }),
                      _vm._v(" Back to Question Setup "),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "learning-quiz-btn" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn header-btn primary",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.restore()
                        },
                      },
                    },
                    [_vm._v("Publish LO")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "learning-quiz-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "setup-content",
                    attrs: { id: "question-setup" },
                  },
                  [
                    _vm._m(7),
                    _c("ValidationObserver", { ref: "formAssessment" }, [
                      _c("form", { staticClass: "w-100" }, [
                        _c("div", { staticClass: "row custom-row" }, [
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Select Concept")]),
                              _c("div", { staticClass: "quiz-dropdown" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-secondary dropdown-toggle",
                                    attrs: {
                                      type: "button",
                                      id: "entity",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.Selectedconcept &&
                                            _vm.Selectedconcept.attributes
                                            ? _vm.Selectedconcept.attributes
                                                .concept
                                            : ""
                                        ) +
                                        " "
                                    ),
                                    _c("em", {
                                      staticClass: "icon-carrot-down",
                                    }),
                                  ]
                                ),
                                !_vm.Selectedconcept &&
                                !_vm.Selectedconcept.attributes
                                  ? _c(
                                      "span",
                                      {
                                        class: [_vm.isActive ? "invalid" : ""],
                                        style: [
                                          _vm.isActive
                                            ? { display: "block" }
                                            : { display: "none" },
                                        ],
                                      },
                                      [_vm._v("Concept is required")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { "aria-labelledby": "entity" },
                                  },
                                  _vm._l(
                                    _vm.QuestionList,
                                    function (assItem, key) {
                                      return _c(
                                        "option",
                                        {
                                          key: assItem.id,
                                          staticClass: "dropdown-item",
                                          domProps: { value: assItem.id },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setKey(
                                                assItem.id,
                                                key,
                                                "concept"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                assItem.attributes.concept
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-7" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-sm-9 px-0" },
                              [
                                _c("label", [_vm._v("Key Topic")]),
                                _c("div", { staticClass: "quiz-dropdown" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary dropdown-toggle",
                                      attrs: {
                                        type: "button",
                                        id: "entity",
                                        "data-toggle": "dropdown",
                                        "aria-haspopup": "true",
                                        "aria-expanded": "false",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.SelectedKeyTopic &&
                                              _vm.SelectedKeyTopic.attributes
                                              ? _vm.SelectedKeyTopic.attributes
                                                  .asst_entities.attributes.name
                                              : ""
                                          ) +
                                          " "
                                      ),
                                      _c("em", {
                                        staticClass: "icon-carrot-down",
                                      }),
                                    ]
                                  ),
                                  !_vm.SelectedKeyTopic &&
                                  !_vm.SelectedKeyTopic.attributes
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [_vm._v("Key Topic is required")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: { "aria-labelledby": "entity" },
                                    },
                                    _vm._l(_vm.keyTopic, function (key, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: key.id,
                                          staticClass: "dropdown-item",
                                          domProps: { value: key.id },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setKey(
                                                key.id,
                                                index,
                                                "key"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                key.attributes.asst_entities
                                                  .attributes.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _vm.assessmentScheme &&
                              _vm.assessmentScheme.attributes
                                ? _c(
                                    "ul",
                                    { staticClass: "form-entity-tabs" },
                                    [
                                      _vm._l(
                                        _vm.assessmentScheme.attributes
                                          .assessment_labels,
                                        function (assessment) {
                                          return _c(
                                            "li",
                                            {
                                              key: assessment.id,
                                              class:
                                                _vm.assessment_labels ===
                                                assessment.id
                                                  ? "active"
                                                  : "",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0);",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setAssessmentScheme(
                                                        assessment.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        assessment.attributes
                                                          .name
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("span", [
                                                    _c("em", {
                                                      staticClass: "icon-close",
                                                    }),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      !_vm.enableMissedSkills
                                        ? _c(
                                            "li",
                                            {
                                              class: !_vm.assessment_labels
                                                ? "active"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0);",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setAssessmentScheme(
                                                        null
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(" Missed "),
                                                  _c("span", [
                                                    _c("em", {
                                                      staticClass: "icon-close",
                                                    }),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-7" }, [
                            _c(
                              "div",
                              {
                                staticClass: "nav nav-tabs",
                                attrs: { id: "nav-tab2", role: "tablist2" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    class: [
                                      "nav-item nav-link",
                                      parseInt(_vm.formValue) === parseInt(1)
                                        ? "active"
                                        : "",
                                    ],
                                    attrs: {
                                      id: "character-tab",
                                      "data-toggle": "tab",
                                      href: "#character-response",
                                      role: "tab",
                                      "aria-controls": "character-response",
                                      "aria-selected": "true",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeFormData(1)
                                      },
                                    },
                                  },
                                  [_vm._v("Character Response")]
                                ),
                                _vm.dialogic_assmnt_item
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "nav-item nav-link",
                                          parseInt(_vm.formValue) ===
                                          parseInt(2)
                                            ? "active"
                                            : "",
                                          !_vm.dialogic_assmnt_item
                                            ? "nav-link-disabled"
                                            : "",
                                        ],
                                        attrs: {
                                          id: "debrief-tab",
                                          "data-toggle": "tab",
                                          href: "#debrief-content",
                                          role: "tab",
                                          "aria-controls": "debrief-content",
                                          "aria-selected": "false",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeFormData(2)
                                          },
                                        },
                                      },
                                      [_vm._v("Debrief Content")]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        class: [
                                          "nav-item nav-link",
                                          parseInt(_vm.formValue) ===
                                          parseInt(2)
                                            ? "active"
                                            : "",
                                          !_vm.dialogic_assmnt_item
                                            ? "nav-link-disabled"
                                            : "",
                                        ],
                                      },
                                      [_vm._v("Debrief Content")]
                                    ),
                                _vm.dialogic_assmnt_item
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "nav-item nav-link",
                                          parseInt(_vm.formValue) ===
                                          parseInt(3)
                                            ? "active"
                                            : "",
                                          !_vm.dialogic_assmnt_item
                                            ? "nav-link-disabled"
                                            : "",
                                        ],
                                        attrs: {
                                          id: "adaptive-tab",
                                          "data-toggle": "tab",
                                          href: "#adaptive-content",
                                          role: "tab",
                                          "aria-controls": "adaptive-content",
                                          "aria-selected": "false",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeFormData(3)
                                          },
                                        },
                                      },
                                      [_vm._v("Adaptive Content")]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        class: [
                                          "nav-item nav-link",
                                          parseInt(_vm.formValue) ===
                                          parseInt(3)
                                            ? "active"
                                            : "",
                                          !_vm.dialogic_assmnt_item
                                            ? "nav-link-disabled"
                                            : "",
                                        ],
                                      },
                                      [_vm._v("Adaptive Content")]
                                    ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-content",
                                attrs: { id: "nav-tabContent2" },
                              },
                              [
                                parseInt(_vm.formValue) === parseInt(1)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade show active",
                                        attrs: {
                                          id: "character-response",
                                          role: "tabpanel",
                                          "aria-labelledby": "character-tab",
                                        },
                                      },
                                      [
                                        _vm.assessment_labels
                                          ? _c(
                                              "div",
                                              { attrs: { id: "mastery" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "assessment-response pt-2",
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "response-tab",
                                                      },
                                                      _vm._l(
                                                        _vm.setVariationList,
                                                        function (data, index) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: index,
                                                              class:
                                                                _vm.selIterationLevel ===
                                                                index
                                                                  ? "active"
                                                                  : "",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleSelectedIteration(
                                                                      index,
                                                                      "iteration",
                                                                      false
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "javascript:void(0);",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Iteration " +
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ] &&
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ].variation
                                                      ? _c(
                                                          "ul",
                                                          {
                                                            staticClass:
                                                              "response-tab",
                                                          },
                                                          _vm._l(
                                                            _vm
                                                              .setVariationList[
                                                              _vm
                                                                .selIterationLevel
                                                            ].variation,
                                                            function (
                                                              vari,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: index,
                                                                  class:
                                                                    _vm.selVariationLevel ===
                                                                    index
                                                                      ? "active"
                                                                      : "",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleSelectedIteration(
                                                                          index,
                                                                          "variation",
                                                                          false
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: "javascript:void(0);",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Variation " +
                                                                          _vm._s(
                                                                            index +
                                                                              1
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ] &&
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ].variation
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "response-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-control p-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ValidationProvider",
                                                                      {
                                                                        attrs: {
                                                                          name: "Variation",
                                                                          rules:
                                                                            "max:2000",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function ({
                                                                                  errors,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "vue-editor",
                                                                                      {
                                                                                        staticClass:
                                                                                          "form-control",
                                                                                        attrs:
                                                                                          {
                                                                                            id: "Variation",
                                                                                            placeholder:
                                                                                              "Variation",
                                                                                            editorToolbar:
                                                                                              _vm.fullTool,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .setVariationList[
                                                                                                _vm
                                                                                                  .selIterationLevel
                                                                                              ]
                                                                                                .variation[
                                                                                                _vm
                                                                                                  .selVariationLevel
                                                                                              ]
                                                                                                .response,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .setVariationList[
                                                                                                    _vm
                                                                                                      .selIterationLevel
                                                                                                  ]
                                                                                                    .variation[
                                                                                                    _vm
                                                                                                      .selVariationLevel
                                                                                                  ],
                                                                                                  "response",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "\n                                      setVariationList[selIterationLevel]\n                                        .variation[selVariationLevel].response\n                                    ",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    errors[0]
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            class:
                                                                                              [
                                                                                                _vm.isActive
                                                                                                  ? "invalid"
                                                                                                  : "",
                                                                                              ],
                                                                                            style:
                                                                                              [
                                                                                                _vm.isActive
                                                                                                  ? {
                                                                                                      display:
                                                                                                        "block",
                                                                                                    }
                                                                                                  : {
                                                                                                      display:
                                                                                                        "none",
                                                                                                    },
                                                                                              ],
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                errors[0].replace(
                                                                                                  "The ",
                                                                                                  ""
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1788539329
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "add-more-btn",
                                                        attrs: {
                                                          href: "javascript:void(0);",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm
                                                              .setVariationList[
                                                              _vm
                                                                .selIterationLevel
                                                            ].variation.length <
                                                            5
                                                              ? _vm.handleAddVariation(
                                                                  _vm.selIterationLevel,
                                                                  false
                                                                )
                                                              : ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "+ Add Variation"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group pt-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v("Minimum"),
                                                            ]),
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                attrs: {
                                                                  name: "Minimum",
                                                                  rules:
                                                                    "required|numeric",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model.number",
                                                                                      value:
                                                                                        _vm.dialogicAssmntItemMinScore,
                                                                                      expression:
                                                                                        "dialogicAssmntItemMinScore",
                                                                                      modifiers:
                                                                                        {
                                                                                          number: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                class:
                                                                                  [
                                                                                    _vm.isActive &&
                                                                                    errors[0]
                                                                                      ? "invalid"
                                                                                      : "",
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "age",
                                                                                    id: "Points",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm.dialogicAssmntItemMinScore,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.dialogicAssmntItemMinScore =
                                                                                        _vm._n(
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                    },
                                                                                  blur: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$forceUpdate()
                                                                                  },
                                                                                },
                                                                              }
                                                                            ),
                                                                            errors[0]
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? "invalid"
                                                                                          : "",
                                                                                      ],
                                                                                    style:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? {
                                                                                              display:
                                                                                                "block",
                                                                                            }
                                                                                          : {
                                                                                              display:
                                                                                                "none",
                                                                                            },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        errors[0].replace(
                                                                                          "The ",
                                                                                          ""
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2885449311
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v("Maximum"),
                                                            ]),
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                attrs: {
                                                                  name: "Maximum",
                                                                  rules:
                                                                    "required|numeric",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model.number",
                                                                                      value:
                                                                                        _vm.dialogicAssmntItemMaxScore,
                                                                                      expression:
                                                                                        "dialogicAssmntItemMaxScore",
                                                                                      modifiers:
                                                                                        {
                                                                                          number: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                class:
                                                                                  [
                                                                                    _vm.isActive &&
                                                                                    errors[0]
                                                                                      ? "invalid"
                                                                                      : "",
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "age",
                                                                                    id: "Points",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm.dialogicAssmntItemMaxScore,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.dialogicAssmntItemMaxScore =
                                                                                        _vm._n(
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                    },
                                                                                  blur: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$forceUpdate()
                                                                                  },
                                                                                },
                                                                              }
                                                                            ),
                                                                            errors[0]
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? "invalid"
                                                                                          : "",
                                                                                      ],
                                                                                    style:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? {
                                                                                              display:
                                                                                                "block",
                                                                                            }
                                                                                          : {
                                                                                              display:
                                                                                                "none",
                                                                                            },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        errors[0].replace(
                                                                                          "The ",
                                                                                          ""
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1869295327
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-1",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-checkboxes d-inline-flex",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "custom-checkbox right",
                                                            class: [
                                                              _vm.editWorldStatus
                                                                ? "disabled"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              name: "userOverall",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.useEnhanced,
                                                                  expression:
                                                                    "useEnhanced",
                                                                },
                                                              ],
                                                              attrs: {
                                                                disabled:
                                                                  _vm.editWorldStatus ==
                                                                  true,
                                                                type: "checkbox",
                                                                name: "useEnhancedResponses",
                                                              },
                                                              domProps: {
                                                                value: true,
                                                                checked:
                                                                  Array.isArray(
                                                                    _vm.useEnhanced
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm.useEnhanced,
                                                                        true
                                                                      ) > -1
                                                                    : _vm.useEnhanced,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm.useEnhanced,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v = true,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          (_vm.useEnhanced =
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            ))
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          (_vm.useEnhanced =
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              ))
                                                                      }
                                                                    } else {
                                                                      _vm.useEnhanced =
                                                                        $$c
                                                                    }
                                                                  },
                                                              },
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "checkmark",
                                                              attrs: {
                                                                for: "useEnhancedResponses",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " Use enhanced responses? "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm.useEnhanced == true
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c(
                                                          "ul",
                                                          _vm._l(
                                                            _vm.setEnhancedVariationList,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: item.id,
                                                                  class:
                                                                    item.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "enhanced-response-box",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mb-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "enhanced-label",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Entity value: "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.asst_entity_value
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "enhanced-response-container",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Present response:"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "assessment-response pt-2 enhanced-response",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "ul",
                                                                                {
                                                                                  staticClass:
                                                                                    "response-tab",
                                                                                },
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .setEnhancedVariationList[
                                                                                    index
                                                                                  ]
                                                                                    .iteration,
                                                                                  function (
                                                                                    data,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "li",
                                                                                      {
                                                                                        key: index,
                                                                                        class:
                                                                                          _vm.selEnhancedIterationLevel ===
                                                                                          index
                                                                                            ? "active"
                                                                                            : "",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleEnhancedSelectedIteration(
                                                                                                index,
                                                                                                "iteration",
                                                                                                item.id
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                href: "javascript:void(0);",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Iteration " +
                                                                                                _vm._s(
                                                                                                  index +
                                                                                                    1
                                                                                                )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                              _vm
                                                                                .setEnhancedVariationList[
                                                                                index
                                                                              ]
                                                                                .iteration[
                                                                                _vm
                                                                                  .selEnhancedIterationLevel
                                                                              ]
                                                                                .variation
                                                                                ? _c(
                                                                                    "ul",
                                                                                    {
                                                                                      staticClass:
                                                                                        "response-tab",
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm
                                                                                        .setEnhancedVariationList[
                                                                                        index
                                                                                      ]
                                                                                        .iteration[
                                                                                        _vm
                                                                                          .selEnhancedIterationLevel
                                                                                      ]
                                                                                        .variation,
                                                                                      function (
                                                                                        vari,
                                                                                        index
                                                                                      ) {
                                                                                        return _c(
                                                                                          "li",
                                                                                          {
                                                                                            key: index,
                                                                                            class:
                                                                                              _vm.selEnhancedVariationLevel ===
                                                                                              index
                                                                                                ? "active"
                                                                                                : "",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.handleEnhancedSelectedIteration(
                                                                                                    index,
                                                                                                    "variation",
                                                                                                    item.id
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    href: "javascript:void(0);",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Variation " +
                                                                                                    _vm._s(
                                                                                                      index +
                                                                                                        1
                                                                                                    )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    0
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .setEnhancedVariationList[
                                                                                index
                                                                              ]
                                                                                .iteration[
                                                                                _vm
                                                                                  .selEnhancedIterationLevel
                                                                              ] &&
                                                                              _vm
                                                                                .setEnhancedVariationList[
                                                                                index
                                                                              ]
                                                                                .iteration[
                                                                                _vm
                                                                                  .selEnhancedIterationLevel
                                                                              ]
                                                                                .variation[
                                                                                _vm
                                                                                  .selEnhancedVariationLevel
                                                                              ]
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "response-content",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "form-group",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "form-control p-0",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "textarea",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "model",
                                                                                                        rawName:
                                                                                                          "v-model",
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .setEnhancedVariationList[
                                                                                                            index
                                                                                                          ]
                                                                                                            .iteration[
                                                                                                            _vm
                                                                                                              .selEnhancedIterationLevel
                                                                                                          ]
                                                                                                            .variation[
                                                                                                            _vm
                                                                                                              .selEnhancedVariationLevel
                                                                                                          ]
                                                                                                            .present_response,
                                                                                                        expression:
                                                                                                          "\n                                              setEnhancedVariationList[index]\n                                                .iteration[\n                                                selEnhancedIterationLevel\n                                              ].variation[\n                                                selEnhancedVariationLevel\n                                              ].present_response\n                                            ",
                                                                                                      },
                                                                                                    ],
                                                                                                  staticClass:
                                                                                                    "form-control",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      rows: "6",
                                                                                                      placeholder:
                                                                                                        "variation",
                                                                                                    },
                                                                                                  domProps:
                                                                                                    {
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .setEnhancedVariationList[
                                                                                                          index
                                                                                                        ]
                                                                                                          .iteration[
                                                                                                          _vm
                                                                                                            .selEnhancedIterationLevel
                                                                                                        ]
                                                                                                          .variation[
                                                                                                          _vm
                                                                                                            .selEnhancedVariationLevel
                                                                                                        ]
                                                                                                          .present_response,
                                                                                                    },
                                                                                                  on: {
                                                                                                    input:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        if (
                                                                                                          $event
                                                                                                            .target
                                                                                                            .composing
                                                                                                        )
                                                                                                          return
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .setEnhancedVariationList[
                                                                                                            index
                                                                                                          ]
                                                                                                            .iteration[
                                                                                                            _vm
                                                                                                              .selEnhancedIterationLevel
                                                                                                          ]
                                                                                                            .variation[
                                                                                                            _vm
                                                                                                              .selEnhancedVariationLevel
                                                                                                          ],
                                                                                                          "present_response",
                                                                                                          $event
                                                                                                            .target
                                                                                                            .value
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "hr"
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Absent response:"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "assessment-response pt-2 enhanced-response",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "ul",
                                                                                {
                                                                                  staticClass:
                                                                                    "response-tab",
                                                                                },
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .setEnhancedVariationList[
                                                                                    index
                                                                                  ]
                                                                                    .iteration,
                                                                                  function (
                                                                                    data,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "li",
                                                                                      {
                                                                                        key: index,
                                                                                        class:
                                                                                          _vm.selEnhancedIterationLevel ===
                                                                                          index
                                                                                            ? "active"
                                                                                            : "",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleEnhancedSelectedIteration(
                                                                                                index,
                                                                                                "iteration",
                                                                                                item.id
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                href: "javascript:void(0);",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Iteration " +
                                                                                                _vm._s(
                                                                                                  index +
                                                                                                    1
                                                                                                )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                              _vm
                                                                                .setEnhancedVariationList[
                                                                                index
                                                                              ]
                                                                                .iteration[
                                                                                _vm
                                                                                  .selEnhancedIterationLevel
                                                                              ]
                                                                                .variation
                                                                                ? _c(
                                                                                    "ul",
                                                                                    {
                                                                                      staticClass:
                                                                                        "response-tab",
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm
                                                                                        .setEnhancedVariationList[
                                                                                        index
                                                                                      ]
                                                                                        .iteration[
                                                                                        _vm
                                                                                          .selEnhancedIterationLevel
                                                                                      ]
                                                                                        .variation,
                                                                                      function (
                                                                                        vari,
                                                                                        index
                                                                                      ) {
                                                                                        return _c(
                                                                                          "li",
                                                                                          {
                                                                                            key: index,
                                                                                            class:
                                                                                              _vm.selEnhancedVariationLevel ===
                                                                                              index
                                                                                                ? "active"
                                                                                                : "",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.handleEnhancedSelectedIteration(
                                                                                                    index,
                                                                                                    "variation",
                                                                                                    item.id
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    href: "javascript:void(0);",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Variation " +
                                                                                                    _vm._s(
                                                                                                      index +
                                                                                                        1
                                                                                                    )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    0
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .setEnhancedVariationList[
                                                                                index
                                                                              ]
                                                                                .iteration[
                                                                                _vm
                                                                                  .selEnhancedIterationLevel
                                                                              ] &&
                                                                              _vm
                                                                                .setEnhancedVariationList[
                                                                                index
                                                                              ]
                                                                                .iteration[
                                                                                _vm
                                                                                  .selEnhancedIterationLevel
                                                                              ]
                                                                                .variation[
                                                                                _vm
                                                                                  .selEnhancedVariationLevel
                                                                              ]
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "response-content",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "form-group",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "form-control p-0",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "textarea",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "model",
                                                                                                        rawName:
                                                                                                          "v-model",
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .setEnhancedVariationList[
                                                                                                            index
                                                                                                          ]
                                                                                                            .iteration[
                                                                                                            _vm
                                                                                                              .selEnhancedIterationLevel
                                                                                                          ]
                                                                                                            .variation[
                                                                                                            _vm
                                                                                                              .selEnhancedVariationLevel
                                                                                                          ]
                                                                                                            .absent_response,
                                                                                                        expression:
                                                                                                          "\n                                              setEnhancedVariationList[index]\n                                                .iteration[\n                                                selEnhancedIterationLevel\n                                              ].variation[\n                                                selEnhancedVariationLevel\n                                              ].absent_response\n                                            ",
                                                                                                      },
                                                                                                    ],
                                                                                                  staticClass:
                                                                                                    "form-control",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      rows: "6",
                                                                                                      placeholder:
                                                                                                        "variation",
                                                                                                    },
                                                                                                  domProps:
                                                                                                    {
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .setEnhancedVariationList[
                                                                                                          index
                                                                                                        ]
                                                                                                          .iteration[
                                                                                                          _vm
                                                                                                            .selEnhancedIterationLevel
                                                                                                        ]
                                                                                                          .variation[
                                                                                                          _vm
                                                                                                            .selEnhancedVariationLevel
                                                                                                        ]
                                                                                                          .absent_response,
                                                                                                    },
                                                                                                  on: {
                                                                                                    input:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        if (
                                                                                                          $event
                                                                                                            .target
                                                                                                            .composing
                                                                                                        )
                                                                                                          return
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .setEnhancedVariationList[
                                                                                                            index
                                                                                                          ]
                                                                                                            .iteration[
                                                                                                            _vm
                                                                                                              .selEnhancedIterationLevel
                                                                                                          ]
                                                                                                            .variation[
                                                                                                            _vm
                                                                                                              .selEnhancedVariationLevel
                                                                                                          ],
                                                                                                          "absent_response",
                                                                                                          $event
                                                                                                            .target
                                                                                                            .value
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "Required entity value(s)"
                                                      ),
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.searchEntityValue,
                                                          expression:
                                                            "searchEntityValue",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Search entity value",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.searchEntityValue,
                                                      },
                                                      on: {
                                                        input: [
                                                          function ($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.searchEntityValue =
                                                              $event.target.value
                                                          },
                                                          _vm.filterEntity,
                                                        ],
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "chip-container pt-2 pb-0 entity-chip",
                                                      },
                                                      _vm._l(
                                                        _vm.selectedEntityValue,
                                                        function (entity, i) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: entity.id,
                                                              staticClass:
                                                                "chip",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    entity
                                                                      .attributes
                                                                      .value
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("em", {
                                                                staticClass:
                                                                  "icon-close",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteSeletedentity(
                                                                        i
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm.searchEntityValue
                                                      .length > 0 &&
                                                    _vm.entityStatus
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "user-row-scroll custom-user-dropdown",
                                                          },
                                                          _vm._l(
                                                            _vm.searchEntityValues,
                                                            function (entitys) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: entitys.id,
                                                                  staticClass:
                                                                    "user-row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "img-container",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "user-info",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "head",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    entitys
                                                                                      .attributes
                                                                                      .value
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  entitys.status
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-action",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "em",
                                                                            {
                                                                              staticClass:
                                                                                "icon-check",
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Added"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  !entitys.status
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-action",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "add-action",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addEntityData(
                                                                                      entitys
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "+"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v("Points"),
                                                            ]),
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                attrs: {
                                                                  name: "Points",
                                                                  rules:
                                                                    "required|numeric",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model.number",
                                                                                      value:
                                                                                        _vm.points,
                                                                                      expression:
                                                                                        "points",
                                                                                      modifiers:
                                                                                        {
                                                                                          number: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                class:
                                                                                  [
                                                                                    _vm.isActive &&
                                                                                    errors[0]
                                                                                      ? "invalid"
                                                                                      : "",
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "age",
                                                                                    id: "Points",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm.points,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.points =
                                                                                        _vm._n(
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                    },
                                                                                  blur: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$forceUpdate()
                                                                                  },
                                                                                },
                                                                              }
                                                                            ),
                                                                            errors[0]
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? "invalid"
                                                                                          : "",
                                                                                      ],
                                                                                    style:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? {
                                                                                              display:
                                                                                                "block",
                                                                                            }
                                                                                          : {
                                                                                              display:
                                                                                                "none",
                                                                                            },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        errors[0].replace(
                                                                                          "The ",
                                                                                          ""
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    552940127
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                "Follow-up Points"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                attrs: {
                                                                  name: "Follow-up Points",
                                                                  rules:
                                                                    "numeric",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model.number",
                                                                                      value:
                                                                                        _vm.followUpPoint,
                                                                                      expression:
                                                                                        "followUpPoint",
                                                                                      modifiers:
                                                                                        {
                                                                                          number: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "form-control",
                                                                                class:
                                                                                  [
                                                                                    _vm.isActive &&
                                                                                    errors[0]
                                                                                      ? "invalid"
                                                                                      : "",
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "age",
                                                                                    id: "followUpPoint",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      _vm.followUpPoint,
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.followUpPoint =
                                                                                        _vm._n(
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                    },
                                                                                  blur: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$forceUpdate()
                                                                                  },
                                                                                },
                                                                              }
                                                                            ),
                                                                            errors[0]
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? "invalid"
                                                                                          : "",
                                                                                      ],
                                                                                    style:
                                                                                      [
                                                                                        _vm.isActive
                                                                                          ? {
                                                                                              display:
                                                                                                "block",
                                                                                            }
                                                                                          : {
                                                                                              display:
                                                                                                "none",
                                                                                            },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        errors[0].replace(
                                                                                          "The ",
                                                                                          ""
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    394537560
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mb-1",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-checkboxes d-inline-flex",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "custom-checkbox right",
                                                            class: [
                                                              _vm.editWorldStatus
                                                                ? "disabled"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              name: "userOverall",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.isFollowUp,
                                                                  expression:
                                                                    "isFollowUp",
                                                                },
                                                              ],
                                                              attrs: {
                                                                disabled:
                                                                  _vm.editWorldStatus ==
                                                                  true,
                                                                type: "checkbox",
                                                                name: "userOverall",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  "userOverall",
                                                                checked:
                                                                  Array.isArray(
                                                                    _vm.isFollowUp
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm.isFollowUp,
                                                                        "userOverall"
                                                                      ) > -1
                                                                    : _vm.isFollowUp,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm.isFollowUp,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          "userOverall",
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          (_vm.isFollowUp =
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            ))
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          (_vm.isFollowUp =
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              ))
                                                                      }
                                                                    } else {
                                                                      _vm.isFollowUp =
                                                                        $$c
                                                                    }
                                                                  },
                                                              },
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "checkmark",
                                                              attrs: {
                                                                for: "userOverall",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " Ask follow-up question? "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm.isFollowUp
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group pt-4",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Follow-Up Questions"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            class: [
                                                              "assessment-response",
                                                              _vm
                                                                .question_variations_attributes
                                                                .length > 0
                                                                ? "pt-2"
                                                                : "",
                                                            ],
                                                          },
                                                          [
                                                            _vm
                                                              .question_variations_attributes
                                                              .length > 0
                                                              ? _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "response-tab",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.question_variations_attributes,
                                                                    function (
                                                                      variations,
                                                                      key
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: key,
                                                                          class:
                                                                            _vm.variationsSelect ===
                                                                            key +
                                                                              1
                                                                              ? "active"
                                                                              : "",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  href: "javascript:void(0);",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addVariation(
                                                                                      key +
                                                                                        1
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Iteration " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .question_variations_attributes
                                                                                      .length >
                                                                                      1
                                                                                      ? key +
                                                                                          1
                                                                                      : ""
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _vm._e(),
                                                            _vm
                                                              .question_variations_attributes
                                                              .length > 0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "response-content",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "form-control p-0",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "ValidationProvider",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "Iteration",
                                                                                    rules:
                                                                                      "required|max:4500",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "default",
                                                                                        fn: function ({
                                                                                          errors,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "textarea",
                                                                                              {
                                                                                                directives:
                                                                                                  [
                                                                                                    {
                                                                                                      name: "model",
                                                                                                      rawName:
                                                                                                        "v-model",
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .question_variations_attributes[
                                                                                                          _vm.variationsSelect -
                                                                                                            1
                                                                                                        ]
                                                                                                          .question,
                                                                                                      expression:
                                                                                                        "\n                                        question_variations_attributes[\n                                          variationsSelect - 1\n                                        ].question\n                                      ",
                                                                                                    },
                                                                                                  ],
                                                                                                class:
                                                                                                  [
                                                                                                    "form-control",
                                                                                                    _vm.isActive &&
                                                                                                    errors[0]
                                                                                                      ? "invalid"
                                                                                                      : "",
                                                                                                  ],
                                                                                                attrs:
                                                                                                  {
                                                                                                    rows: "4",
                                                                                                    placeholder:
                                                                                                      "Iteration",
                                                                                                  },
                                                                                                domProps:
                                                                                                  {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .question_variations_attributes[
                                                                                                        _vm.variationsSelect -
                                                                                                          1
                                                                                                      ]
                                                                                                        .question,
                                                                                                  },
                                                                                                on: {
                                                                                                  input:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      if (
                                                                                                        $event
                                                                                                          .target
                                                                                                          .composing
                                                                                                      )
                                                                                                        return
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .question_variations_attributes[
                                                                                                          _vm.variationsSelect -
                                                                                                            1
                                                                                                        ],
                                                                                                        "question",
                                                                                                        $event
                                                                                                          .target
                                                                                                          .value
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                            errors[0]
                                                                                              ? _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    class:
                                                                                                      [
                                                                                                        _vm.isActive
                                                                                                          ? "invalid"
                                                                                                          : "",
                                                                                                      ],
                                                                                                    style:
                                                                                                      [
                                                                                                        _vm.isActive
                                                                                                          ? {
                                                                                                              display:
                                                                                                                "block",
                                                                                                            }
                                                                                                          : {
                                                                                                              display:
                                                                                                                "none",
                                                                                                            },
                                                                                                      ],
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        errors[0].replace(
                                                                                                          "The ",
                                                                                                          ""
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    false,
                                                                                    3816816758
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "add-more-btn",
                                                                attrs: {
                                                                  href: "javascript:void(0);",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm
                                                                        .question_variations_attributes
                                                                        .length <
                                                                      3
                                                                        ? _vm.addVariation(
                                                                            null
                                                                          )
                                                                        : ""
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "+ Add Iteration"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm
                                                              .question_variations_attributes
                                                              .length <= 0
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class: [
                                                                      _vm.isActive
                                                                        ? "invalid"
                                                                        : "",
                                                                    ],
                                                                    style: [
                                                                      _vm.isActive
                                                                        ? {
                                                                            display:
                                                                              "block",
                                                                          }
                                                                        : {
                                                                            display:
                                                                              "none",
                                                                          },
                                                                    ],
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Follow up question is required"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { attrs: { id: "missed" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "assessment-response",
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "response-tab",
                                                      },
                                                      _vm._l(
                                                        _vm.setVariationList,
                                                        function (data, index) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: index,
                                                              class:
                                                                _vm.selIterationLevel ===
                                                                index
                                                                  ? "active"
                                                                  : "",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleSelectedIteration(
                                                                      index,
                                                                      "iteration",
                                                                      false
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "javascript:void(0);",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Iteration " +
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ] &&
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ].variation
                                                      ? _c(
                                                          "ul",
                                                          {
                                                            staticClass:
                                                              "response-tab",
                                                          },
                                                          _vm._l(
                                                            _vm
                                                              .setVariationList[
                                                              _vm
                                                                .selIterationLevel
                                                            ].variation,
                                                            function (
                                                              vari,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: index,
                                                                  class:
                                                                    _vm.selVariationLevel ===
                                                                    index
                                                                      ? "active"
                                                                      : "",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleSelectedIteration(
                                                                          index,
                                                                          "variation",
                                                                          false
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: "javascript:void(0);",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Variation " +
                                                                          _vm._s(
                                                                            index +
                                                                              1
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ] &&
                                                    _vm.setVariationList[
                                                      _vm.selIterationLevel
                                                    ].variation
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "response-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-control p-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "ValidationProvider",
                                                                      {
                                                                        attrs: {
                                                                          name: "Variation",
                                                                          rules:
                                                                            "max:2000",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function ({
                                                                                  errors,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "vue-editor",
                                                                                      {
                                                                                        staticClass:
                                                                                          "form-control",
                                                                                        attrs:
                                                                                          {
                                                                                            id: "Variation",
                                                                                            placeholder:
                                                                                              "Variation",
                                                                                            editorToolbar:
                                                                                              _vm.fullTool,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .setVariationList[
                                                                                                _vm
                                                                                                  .selIterationLevel
                                                                                              ]
                                                                                                .variation[
                                                                                                _vm
                                                                                                  .selVariationLevel
                                                                                              ]
                                                                                                .response,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .setVariationList[
                                                                                                    _vm
                                                                                                      .selIterationLevel
                                                                                                  ]
                                                                                                    .variation[
                                                                                                    _vm
                                                                                                      .selVariationLevel
                                                                                                  ],
                                                                                                  "response",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "\n                                      setVariationList[selIterationLevel]\n                                        .variation[selVariationLevel].response\n                                    ",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    errors[0]
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            class:
                                                                                              [
                                                                                                _vm.isActive
                                                                                                  ? "invalid"
                                                                                                  : "",
                                                                                              ],
                                                                                            style:
                                                                                              [
                                                                                                _vm.isActive
                                                                                                  ? {
                                                                                                      display:
                                                                                                        "block",
                                                                                                    }
                                                                                                  : {
                                                                                                      display:
                                                                                                        "none",
                                                                                                    },
                                                                                              ],
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                errors[0].replace(
                                                                                                  "The ",
                                                                                                  ""
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1788539329
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "add-more-btn",
                                                        attrs: {
                                                          href: "javascript:void(0);",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm
                                                              .setVariationList[
                                                              _vm
                                                                .selIterationLevel
                                                            ].variation.length <
                                                            5
                                                              ? _vm.handleAddVariation(
                                                                  _vm.selIterationLevel,
                                                                  false
                                                                )
                                                              : ""
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "+ Add Variation"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group mt-4 pt-3",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "pb-4" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-checkboxes d-inline-flex",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "custom-checkbox right",
                                                                class: [
                                                                  _vm.editWorldStatus
                                                                    ? "disabled"
                                                                    : "",
                                                                ],
                                                                attrs: {
                                                                  name: "userOverall",
                                                                },
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.isFollowUp,
                                                                      expression:
                                                                        "isFollowUp",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.editWorldStatus ==
                                                                      true,
                                                                    type: "checkbox",
                                                                    name: "userOverall",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      "userOverall",
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.isFollowUp
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.isFollowUp,
                                                                            "userOverall"
                                                                          ) > -1
                                                                        : _vm.isFollowUp,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.isFollowUp,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              "userOverall",
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.isFollowUp =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.isFollowUp =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.isFollowUp =
                                                                            $$c
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark",
                                                                  attrs: {
                                                                    for: "userOverall",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " Ask follow-up question? "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm.isFollowUp
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Missed Follow-Up Questions"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            class: [
                                                              "assessment-response",
                                                              _vm
                                                                .question_variations_attributes
                                                                .length > 0
                                                                ? "pt-2"
                                                                : "",
                                                            ],
                                                          },
                                                          [
                                                            _vm
                                                              .question_variations_attributes
                                                              .length > 0
                                                              ? _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "response-tab",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.question_variations_attributes,
                                                                    function (
                                                                      variations,
                                                                      key
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: key,
                                                                          class:
                                                                            _vm.variationsSelect ===
                                                                            key +
                                                                              1
                                                                              ? "active"
                                                                              : "",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  href: "javascript:void(0);",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addVariation(
                                                                                      key +
                                                                                        1
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Iteration " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .question_variations_attributes
                                                                                      .length >
                                                                                      1
                                                                                      ? key +
                                                                                          1
                                                                                      : ""
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _vm._e(),
                                                            _vm
                                                              .question_variations_attributes
                                                              .length > 0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "response-content",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "form-control p-0",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "ValidationProvider",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "Iteration",
                                                                                    rules:
                                                                                      "required|max:4500",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "default",
                                                                                        fn: function ({
                                                                                          errors,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "textarea",
                                                                                              {
                                                                                                directives:
                                                                                                  [
                                                                                                    {
                                                                                                      name: "model",
                                                                                                      rawName:
                                                                                                        "v-model",
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .question_variations_attributes[
                                                                                                          _vm.variationsSelect -
                                                                                                            1
                                                                                                        ]
                                                                                                          .question,
                                                                                                      expression:
                                                                                                        "\n                                        question_variations_attributes[\n                                          variationsSelect - 1\n                                        ].question\n                                      ",
                                                                                                    },
                                                                                                  ],
                                                                                                class:
                                                                                                  [
                                                                                                    "form-control",
                                                                                                    _vm.isActive &&
                                                                                                    errors[0]
                                                                                                      ? "invalid"
                                                                                                      : "",
                                                                                                  ],
                                                                                                attrs:
                                                                                                  {
                                                                                                    rows: "4",
                                                                                                    placeholder:
                                                                                                      "Iteration",
                                                                                                  },
                                                                                                domProps:
                                                                                                  {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .question_variations_attributes[
                                                                                                        _vm.variationsSelect -
                                                                                                          1
                                                                                                      ]
                                                                                                        .question,
                                                                                                  },
                                                                                                on: {
                                                                                                  input:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      if (
                                                                                                        $event
                                                                                                          .target
                                                                                                          .composing
                                                                                                      )
                                                                                                        return
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .question_variations_attributes[
                                                                                                          _vm.variationsSelect -
                                                                                                            1
                                                                                                        ],
                                                                                                        "question",
                                                                                                        $event
                                                                                                          .target
                                                                                                          .value
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                            errors[0]
                                                                                              ? _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    class:
                                                                                                      [
                                                                                                        _vm.isActive
                                                                                                          ? "invalid"
                                                                                                          : "",
                                                                                                      ],
                                                                                                    style:
                                                                                                      [
                                                                                                        _vm.isActive
                                                                                                          ? {
                                                                                                              display:
                                                                                                                "block",
                                                                                                            }
                                                                                                          : {
                                                                                                              display:
                                                                                                                "none",
                                                                                                            },
                                                                                                      ],
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        errors[0].replace(
                                                                                                          "The ",
                                                                                                          ""
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    false,
                                                                                    3816816758
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "add-more-btn",
                                                                attrs: {
                                                                  href: "javascript:void(0);",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm
                                                                        .question_variations_attributes
                                                                        .length <
                                                                      3
                                                                        ? _vm.addVariation(
                                                                            null
                                                                          )
                                                                        : ""
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "+ Add Iteration"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm
                                                              .question_variations_attributes
                                                              .length <= 0
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class: [
                                                                      _vm.isActive
                                                                        ? "invalid"
                                                                        : "",
                                                                    ],
                                                                    style: [
                                                                      _vm.isActive
                                                                        ? {
                                                                            display:
                                                                              "block",
                                                                          }
                                                                        : {
                                                                            display:
                                                                              "none",
                                                                          },
                                                                    ],
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Follow up question field is required"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                      ]
                                    )
                                  : _vm._e(),
                                parseInt(_vm.formValue) === parseInt(2)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade show active",
                                        attrs: {
                                          id: "debrief-content",
                                          role: "tabpanel",
                                          "aria-labelledby": "debrief-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "assessment-response",
                                              _vm.variations_briefattributes
                                                .length > 0
                                                ? "pt-2"
                                                : "",
                                            ],
                                          },
                                          [
                                            _vm.variations_briefattributes
                                              .length > 0
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass: "response-tab",
                                                  },
                                                  _vm._l(
                                                    _vm.variations_briefattributes,
                                                    function (variations, key) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: key,
                                                          class:
                                                            _vm.variationsSelect ===
                                                            key + 1
                                                              ? "active"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "javascript:void(0);",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addDebriefVariation(
                                                                      key + 1,
                                                                      "debrief"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Variation " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .variations_briefattributes
                                                                      .length >
                                                                      1
                                                                      ? key + 1
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _vm.variations_briefattributes
                                              .length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "response-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-control p-0",
                                                          },
                                                          [
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                attrs: {
                                                                  name: "Variation",
                                                                  rules:
                                                                    "required|max:4500",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "vue-editor",
                                                                              {
                                                                                class:
                                                                                  [
                                                                                    "form-control",
                                                                                    _vm.isActive &&
                                                                                    errors[0]
                                                                                      ? "invalid"
                                                                                      : "",
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    editorToolbar:
                                                                                      _vm.fullTool,
                                                                                    placeholder:
                                                                                      "Variation",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .variations_briefattributes[
                                                                                        _vm.variationsSelect -
                                                                                          1
                                                                                      ]
                                                                                        .content,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .variations_briefattributes[
                                                                                            _vm.variationsSelect -
                                                                                              1
                                                                                          ],
                                                                                          "content",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "\n                                    variations_briefattributes[\n                                      variationsSelect - 1\n                                    ].content\n                                  ",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    548349715
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add-more-btn",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm
                                                      .variations_briefattributes
                                                      .length < 5
                                                      ? _vm.addDebriefVariation(
                                                          null,
                                                          "debrief"
                                                        )
                                                      : ""
                                                  },
                                                },
                                              },
                                              [_vm._v("+ Add Variation")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                parseInt(_vm.formValue) === parseInt(3)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade show active",
                                        attrs: {
                                          id: "adaptive-content",
                                          role: "tabpanel",
                                          "aria-labelledby": "adaptive-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "h5",
                                              { staticClass: "small-title" },
                                              [_vm._v("Add Adaptive Content")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "px-4 learning-quiz-btn",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn ml-2 px-4 secondary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$modal.show(
                                                          "uploadInterstitialModal",
                                                          {
                                                            create: true,
                                                            content: "adaptive",
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Upload")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.adaptiveLists.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "learning-data-list flex-column pt-3",
                                              },
                                              _vm._l(
                                                _vm.adaptiveLists,
                                                function (item, i) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: item.id,
                                                      staticClass:
                                                        "learning-data-item w-100 learning-interstitial-data-item",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "learning-data-left",
                                                        },
                                                        [
                                                          item.attributes
                                                            .content_details
                                                            .type ==
                                                          "text_learn_obj"
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "learning-data-richText",
                                                              })
                                                            : _vm._e(),
                                                          item.attributes
                                                            .content_details
                                                            .type ==
                                                          "file_learn_obj"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "learning-data-icon",
                                                                },
                                                                [
                                                                  _c("a", [
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: require("../../assets/images/pdf-big-icon.png"),
                                                                        alt: "pdf",
                                                                      },
                                                                    }),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.attributes
                                                            .content_details
                                                            .type ==
                                                          "slide_learn_obj"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "learning-data-icon",
                                                                },
                                                                [
                                                                  item
                                                                    .attributes
                                                                    .content_details
                                                                    .attributes
                                                                    .slider_images
                                                                    .length > 0
                                                                    ? _c("a", [
                                                                        item
                                                                          .attributes
                                                                          .content_details
                                                                          .attributes
                                                                          .slider_images
                                                                          .length >
                                                                        0
                                                                          ? _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: item
                                                                                      .attributes
                                                                                      .content_details
                                                                                      .attributes
                                                                                      .slider_images[0]
                                                                                      .attributes
                                                                                      .resource_url
                                                                                      .thumbnail,
                                                                                    alt: "slide",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ])
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.attributes
                                                            .content_details
                                                            .type ==
                                                          "video_learn_obj"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "learning-data-video",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "wistia_responsive_padding data-video",
                                                                      staticStyle:
                                                                        {
                                                                          padding:
                                                                            "56.25% 0 0 0",
                                                                          position:
                                                                            "relative",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "wistia_responsive_wrapper data-video",
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "100%",
                                                                              left: "0",
                                                                              position:
                                                                                "absolute",
                                                                              top: "0",
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "iframe",
                                                                            {
                                                                              staticClass:
                                                                                "wistia_embed",
                                                                              attrs:
                                                                                {
                                                                                  src:
                                                                                    _vm.preIframUrl +
                                                                                    item
                                                                                      .attributes
                                                                                      .content_details
                                                                                      .attributes
                                                                                      .global_video
                                                                                      .attributes
                                                                                      .wistia_code +
                                                                                    _vm.postIframUrl,
                                                                                  title:
                                                                                    "Video.mp4",
                                                                                  name: "wistia_embed",
                                                                                  allowtransparency:
                                                                                    "true",
                                                                                  scrolling:
                                                                                    "no",
                                                                                  allowfullscreen:
                                                                                    "",
                                                                                  mozallowfullscreen:
                                                                                    "",
                                                                                  webkitallowfullscreen:
                                                                                    "",
                                                                                  oallowfullscreen:
                                                                                    "",
                                                                                  msallowfullscreen:
                                                                                    "",
                                                                                  width:
                                                                                    "100%",
                                                                                  height:
                                                                                    "100%",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "learning-data-title",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "data-heads",
                                                            },
                                                            [
                                                              _c("h4", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      item
                                                                        .attributes
                                                                        .title
                                                                    ),
                                                                },
                                                              }),
                                                              item.attributes
                                                                .content_details
                                                                .type ==
                                                              "file_learn_obj"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.size(
                                                                          item
                                                                            .attributes
                                                                            .content_details
                                                                            .attributes
                                                                            .resource_size
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c("h4", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.attributes
                                                                  .content_details
                                                                  .attributes
                                                                  .title
                                                              ),
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "learning-data-paragraph",
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c("p", {
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        item
                                                                          .attributes
                                                                          .description
                                                                      ),
                                                                  },
                                                                }),
                                                              ]),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#3dbc9e",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$modal.show(
                                                                          "uploadInterstitialModal",
                                                                          {
                                                                            create: false,
                                                                            edit: item,
                                                                            content:
                                                                              "adaptive",
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("Edit")]
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeAdaptiveData(
                                                                          item.id,
                                                                          i
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Remove"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end mt-4 mb-3",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveDialogicContent(
                                          $event,
                                          "overall-assessment",
                                          3
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formValue === 3
                                            ? "Save"
                                            : "Save & Next"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "btn-container justify-content-end",
                              },
                              [
                                _c("a", { staticClass: "delete-btn" }, [
                                  _c("div", { staticClass: "delete-box" }, [
                                    _c("span", { staticClass: "icon-delete" }),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text",
                                      on: {
                                        click: function ($event) {
                                          _vm.$route.query.id
                                            ? _vm.$parent.archiveLO("archived")
                                            : ""
                                        },
                                      },
                                    },
                                    [_vm._v("Archive this Learning Object(LO)")]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("addInterstitialContent", {
        attrs: { "v-if": _vm.type == "dialogic" },
        on: { adaptiveContents: _vm.adaptiveContents },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Basic Information")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Entity Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Question Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Overall Assessment")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "delete-box" }, [
      _c("span", { staticClass: "icon-delete" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Overall Assessment")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center mt-5" },
      [
        _c("h4", { staticClass: "head-title" }, [
          _vm._v("Assessment & Responses"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }