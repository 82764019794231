<template>
  <div id="Characters">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Characters</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchCharacters"
                placeholder="Search"
                v-model="searchCharacters"
                v-debounce:400ms="search"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <a
            @click="$modal.show('createCharacter')"
            class="btn header-btn primary"
          >Add a new Character</a>
        </div>
        <p
          v-if="totalCharacters > 1 && !searchCharacters"
        >You have {{totalCharacters}} Characters in the system.</p>
        <p
          v-else-if="totalCharacters <= 1 && !searchCharacters"
        >You have {{totalCharacters}} Character in the system.</p>
        <p
          v-else-if="totalCharacters > 1 && searchCharacters"
        >You have {{totalCharacters}} Characters in the search result.</p>
        <p v-else>You have {{totalCharacters}} Character in the search result.</p>
      </header>
      <div class="data-content" v-if="charactersList.length !== 0">
        <div class="data-table data-column-7">
          <div class="data-head">
            <div class="data-col">
              Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'first_name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('first_name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'first_name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('first_name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Created on
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('created_at','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('created_at','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Age
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'age' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('age','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'age' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('age','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Gender
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'gender' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('gender','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'gender' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('gender','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Assigned to</div>
            <div class="data-col">Organisation</div>
            <div class="data-col">Actions</div>
          </div>
          <div class="data-items" v-for="character in charactersList" :key="character.id">
            <div class="data-row">
              <div class="data-col">
                <div class="data-image">
                  <div
                    class="data-pic circle cursor"
                    @click="$modal.show('characterdetail',{character_id : character.id })"
                  >
                    <img
                      :src="character.attributes.photo_url.thumbnail"
                      v-if="character.attributes.photo_url && character.attributes.photo_url.thumbnail"
                      alt
                    />
                  </div>
                  <div
                    class="data-text cursor"
                    @click="$modal.show('characterdetail',{character_id : character.id })"
                  >{{character.attributes.full_name}}</div>
                </div>
              </div>
              <div class="data-col">{{character.attributes.created_on}}</div>
              <div class="data-col">{{character.attributes.age}}</div>
              <div class="data-col">{{character.attributes.gender.name }}</div>
              <div class="data-col">
                <div class="customer-dropdown customer-organise-dropdown">
                  <span v-if="character.attributes.organizations.length > 0">
                    {{character.attributes.organizations[0].attributes.name}}
                    <span
                      v-if="character.attributes.organizations.length > 1"
                    >, + {{character.attributes.organizations.length -1}}</span>
                  </span>
                  <div class="dropdown-menu dropdown-menu-right">
                    <h3>Organization({{character.attributes.organizations.length}})</h3>
                    <VuePerfectScrollbar class="scroll-area" :settings="settings">
                      <ul class="dropdown-list">
                        <li
                          class="dropdown-items"
                          v-for="(organizationcharacters) of character.attributes.organizations"
                          :key="organizationcharacters.id"
                        >
                          <div class="dropdown-pic">
                            <img
                              :src="organizationcharacters.attributes.photo_url.thumbnail"
                              v-if="organizationcharacters.attributes.photo_url && organizationcharacters.attributes.photo_url.thumbnail"
                              alt
                            />
                          </div>
                          <div class="dropdown-content">
                            <div class="user-name">{{organizationcharacters.attributes.name}}</div>
                            <div
                              class="user-role"
                            >{{organizationcharacters.attributes.industry_name}}</div>
                          </div>
                        </li>
                      </ul>
                    </VuePerfectScrollbar>
                  </div>
                </div>
              </div>
              <div class="data-col">
                <a
                  href="javascript:void(0);"
                  @click="$modal.show('assignOrganisation',{character_id : character.id })"
                  class="data-button"
                >Add to Organization</a>
              </div>
              <div class="data-col data-action">
                <div class="data-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em
                      class="icon-edit"
                      @click="$modal.show('characterdetail',{character_id : character.id })"
                    ></em>
                  </button>
                </div>
                <div class="data-delete-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-delete"></em>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div class="data-popper-title">Are you sure you want to delete this character?</div>
                    <div class="data-popper-footer">
                      <a class="btn default">cancel</a>
                      <a class="btn primary" @click="deleteCharacter(character.id)">Ok</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="page in totalPages.slice(page-1, page+3)" :key="page">
                <a class="page-link active" @click="getCharacters(page)">{{page}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="charactersList.length === 0 && !searchStatus && display">
        <h3>There’s nothing here!</h3>
        <p>You have no Characters in the system yet.</p>
        <div>
          <a
            @click="$modal.show('createCharacter')"
            class="btn header-btn primary"
          >Add a new Character</a>
        </div>
      </div>
      <div class="data-no-result" v-if="charactersList.length === 0 && searchStatus">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <modal name="createCharacter" height="auto" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Create a new Character</h5>
          </div>
          <button type="button" class="close" @click="hide('createCharacter')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="form">
            <form class="modal-form organisation-form">
              <div class="organisation-image-group">
                <!-- <div class="organisation-image-pic">
                   <input
                    ref="fileInput"
                    type="file"
                    @change="onFileSelected"
                    accept="image/*"
                  />
                  <img :src="image" v-if="image" alt="image1" />
                </div>-->
                <div class="organisation-charcter-pic cursor" @click="$refs.fileInput.click()">
                  <img :src="image" v-if="image" alt="images" />
                </div>
                <div class="organisation-image-remove">
                  <input
                    style="display: none;"
                    ref="fileInput"
                    type="file"
                    @change="onFileSelected"
                    accept="image/*"
                  />
                  <a
                    href="#"
                    class="btn add"
                    @click="$refs.fileInput.click()"
                    v-if="!image"
                  >Add Photo</a>
                  <a href="#" class="btn delete" @click="uploadImageRemoved" v-else>Remove Photo</a>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="first_name">First Name</label>
                <ValidationProvider name="First Name" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="first_name"
                    class="form-control"
                    id="first_name"
                    aria-describedby="emailHelp"
                    v-model="first_name"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label class="form-label" for="last_name">Last Name</label>

                <ValidationProvider name="Last Name" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="last_name"
                    class="form-control"
                    id="last_name"
                    aria-describedby="emailHelp"
                    v-model="last_name"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
                </ValidationProvider>
              </div>
              <div class="row mb-4">
                <div class="form-group col">
                  <label class="form-label" for="exampleInputEmail1">Gender</label>
                  <div class="form-gender-dropdown">
                    <button
                      class="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {{gender | upperCharCase(true)}}
                      <em class="icon-carrot-down"></em>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                        class="dropdown-item"
                        href="#"
                        v-for="gen in gender_list"
                        :key="gen.id"
                        @click="setGender(gen.attributes.name, gen.id)"
                      >{{gen.attributes.name | upperCharCase(true)}}</a>
                    </div>
                  </div>
                </div>
                <div class="form-group col">
                  <label class="form-label" for="Age">Age</label>
                  <ValidationProvider name="Age" rules="required|numeric" v-slot="{ errors }">
                    <input
                      type="age"
                      class="form-control"
                      id="age"
                      aria-describedby="emailHelp"
                      v-model.number="age"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn default"
                  data-dismiss="modal"
                  @click="hide('createCharacter')"
                >Cancel</button>
                <button
                  type="button"
                  class="btn primary"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#addOrganisation"
                  v-on:click="saveCharacter"
                >Save</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </modal>
    <assignOrganisation />
    <characterdetail />
    <editCharacter />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import characterdetail from "../components/modal/CharacterDetail.vue";
import assignOrganisation from "../components/modal/assignOrganisation.vue";
import editCharacter from "../components/modal/EditCharacter.vue";
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "Characters-component",
  components: {
    assignOrganisation,
    ValidationProvider,
    ValidationObserver,
    characterdetail,
    editCharacter,
    VuePerfectScrollbar
  },
  filters: {
    upperCharCase(key, firstChar) {
      if (!key) {
        return "";
      }
      key = key.toString();
      if (firstChar) {
        return key.charAt(0).toUpperCase() + key.slice(1);
      } else {
        return key.toUpperCase();
      }
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      selectedFile: null,
      image: null,
      gender: "",
      gender_id : '',
      first_name: "",
      last_name: "",
      age: "",
      name: "",
      description: "",
      type: "private",
      charactersList: [],
      totalPages: [],
      page: 1,
      totalCharacters: "",
      searchCharacters: "",
      sortOrder: "desc",
      sortColumn: "id",
      submitted: false,
      searchStatus: false,
      url: utilFunctionService.getImageUrl(),
      isModalVisible: false,
      character_id: "",
      isActive: false,
      display: false,
      gender_list: []
    };
  },
  methods: {
    show() {
      this.$modal.show();
      this.isModalVisible = true;
    },
    hide(modal) {
      this.clearFormData();
      this.$modal.hide(modal);
      this.isModalVisible = false;
      this.getCharacters(this.page);
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.image = e.target.result;
      };
    },
    uploadImageRemoved: function() {
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
      this.image = null;
      this.selectedFile = null;
    },
    clearFormData() {
      this.selectedFile = null;
      this.image = null;
      this.gender = this.gender_list[0].attributes.name;
      this.gender_id = this.gender_list[0].id;
      this.first_name = "";
      this.last_name = "";
      this.age = "";
    },
    saveCharacter() {
      this.$refs.form
        .validate()
        .then(success => {
          this.isActive = true;
          if (!success) {
            return;
          } else {
            const fb = new FormData();
            if (this.selectedFile)
              fb.append("character[photo]", this.selectedFile);
            fb.append("character[gender_id]", this.gender_id);
            fb.append("character[first_name]", this.first_name);
            fb.append("character[last_name]", this.last_name);
            fb.append("character[age]", this.age);
            fb.append("character[real_world]", true);
            api
              .saveCharacter(fb)
              .then(() => {
                this.clearFormData();
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.characterCreate
                );
                this.hide("createCharacter");
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showErr(commonConfig.appMessages.error);
              });
          }
        })
        .catch();
    },
    getCharacters(page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getAllCharacters(
          page,
          this.searchCharacters,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.searchStatus = false;
          this.charactersList = res.data.data;
          this.totalCharacters = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    search(value) {
      api
        .getAllCharacters(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.searchStatus = true;
          this.charactersList = res.data.data;
          this.totalCharacters = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    nextPage() {
      this.page = this.page + 1;
      this.getWorlds(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getWorlds(this.page);
    },
    deleteCharacter(id) {
      utilFunctionService.showLoader();
      api
        .deleteCharacter(id)
        .then(() => {
          utilFunctionService.hideLoader();
          let page;
          if (this.charactersList.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getCharacters(page);
            } else {
              page = this.page - 1;
              this.getCharacters(page);
            }
          } else {
            const page = this.page;
            this.getCharacters(page);
          }
          utilFunctionService.showSuccess(
            commonConfig.appMessages.deletecharacter
          );
        })
        .catch(error => {
          utilFunctionService.showErr(
            error.response.data.errors
          );
          utilFunctionService.hideLoader();
           if (error.response.status == 401) {
            utilFunctionService.showErr(commonConfig.appMessages.sessionExpire);
            utilFunctionService.hideLoader();
            utilFunctionService.removeLocalStorageService("user");
            this.$router.push({ name: "login" });
          }
        });
    },
    setGender(gender, gender_id) {
      this.gender = gender;
      this.gender_id = gender_id;
    },
    genderList() {
      api
        .genderList()
        .then(res => {
          this.gender_list = res.data.data;
          this.gender = this.gender_list[0].attributes.name;
          this.gender_id = this.gender_list[0].id;
        })
        .catch();
    },
    toggleSort(colName, sortType) {
      let _colName = colName;
      if (colName === "first_name") {
        colName = "first_name,last_name";
      }
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getCharacters(this.page);
      this.sortColumn = _colName;
    }
  },
  created() {
    this.genderList();
    this.getCharacters(this.page);
    utilFunctionService.getImageUrl();
  }
};
</script>
