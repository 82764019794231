<template>
    <modal
      name="wistiaVideo"
      height="auto"
      :width="1020"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
            </div>
            <button type="button" class="close" @click="hide('wistiaVideo')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body">
              <div class="wistia_responsive_padding data-video" style="padding:56.25% 0 0 0;position:relative;" >
                  <div class="wistia_responsive_wrapper data-video" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                      <iframe :src='preIframUrl+wistia_id+postIframUrl'
                      title="Video.mp4"
                      class="wistia_embed"
                      name="wistia_embed"
                      allowtransparency="true"
                      scrolling="no"
                      allowfullscreen
                      mozallowfullscreen
                      webkitallowfullscreen
                      oallowfullscreen
                      msallowfullscreen
                      width="100%"
                      height="100%">
                      </iframe>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button
              type="button"
              class="btn default mt-4 pr-0"
              data-dismiss="modal"
              @click="hide('wistiaVideo')"
              >Cancel</button>
          </div>
      </div>
    </modal>
</template>
<script src="fast.wistia.com/assets/external/E-v1.js" async></script>
<script>
export default {
  name: "wistia-component",
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      wistia_id: "",
      preIframUrl : "https://fast.wistia.net/embed/iframe/",
      postIframUrl : "?videoFoam=true",
    };
  },
  methods: {
    beforeOpen(event) {
      this.wistia_id = event.params.wistiaId;
    },
    hide(modal) {
      this.wistia_id ='';
      this.$parent.hide(modal);
    }
  }
}
</script>

