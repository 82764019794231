var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "addLearnerToCourse",
            height: "auto",
            width: 850,
            scrollable: true,
          },
          on: { "before-open": _vm.beforeOpen },
        },
        [
          _c("div", { staticClass: "modal-content modal-default" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Add Learner to Course")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", { staticClass: "modal-form mt-0" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userEmail,
                      expression: "userEmail",
                    },
                  ],
                  staticClass: "form-control mb-3",
                  attrs: { type: "email", placeholder: "Learner email" },
                  domProps: { value: _vm.userEmail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.userEmail = $event.target.value
                    },
                  },
                }),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedSection,
                        expression: "selectedSection",
                      },
                    ],
                    staticClass: "form-select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedSection = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v("Please select section to register into:"),
                    ]),
                    _vm._l(
                      _vm.courseData.attributes.course_sections,
                      function (section) {
                        return _c(
                          "option",
                          { key: section.id, domProps: { value: section.id } },
                          [_vm._v(" " + _vm._s(section.name) + " ")]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "modal-footer mt-4" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn default",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.hide("addLearnerToCourse")
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.saveLearner()
                      },
                    },
                  },
                  [_vm._v(" Save Learner ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }