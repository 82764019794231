<template>
  <div>
    <div class="learning-form-header flex-wrap">
      <h4>Submission (Email)</h4>
      <div class="learning-quiz-btn">
        <a
          href="javascript:void(0);"
          @click="restore"
          class="btn header-btn secondary"
          v-if="status == 'published'"
          >Save</a
        >
        <a
          v-if="status == 'drafted'"
          href="javascript:void(0);"
          @click="restore"
          class="btn header-btn primary"
          >Publish LO</a
        >
        <a
          href="javascript:void(0);"
          @click="$modal.show('cloneConfirmationModal')"
          class="btn header-btn ml-2 secondary"
          >Clone LO</a
        >
      </div>
    </div>
    <div class="learning-lo-tabs">
      <div class="setup-container">
        <ul>
          <li
            @click="currentStep('basic-setup', 0)"
            :class="{ active: isActiveTab[0] }"
          >
            <a>
              <span class="check-box"></span>
              <span class="check-caption w-auto">Basic Information</span>
            </a>
          </li>
          <li
            @click="currentStep('configure-rubric', 1)"
            :class="{ active: isActiveTab[1] }"
          >
            <a>
              <span class="check-box"></span>
              <span class="check-caption w-auto"
                >Configure Rubric Feedback</span
              >
            </a>
          </li>
          <li
            @click="
              rubric_character_response.length > 0
                ? currentStep('character-response', 2)
                : ''
            "
            :class="{ active: isActiveTab[2] }"
          >
            <a>
              <span class="check-box"></span>
              <span class="check-caption w-auto"
                >Configure Character Response</span
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="learning-quiz-content pt-4">
      <div class="setup-content" v-if="step == 'basic-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Basic Information</h4>
        </div>
        <span>
          <ValidationObserver ref="subform">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label>Interaction title</label>
                    <ValidationProvider
                      name="Interaction Title"
                      rules="required|max:100"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :class="[isActive && errors[0] ? 'invalid' : '']"
                        v-model="name"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[
                          isActive ? { display: 'block' } : { display: 'none' },
                        ]"
                        v-if="errors[0]"
                        >{{ errors[0].replace("The ", "") }}</span
                      >
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Administrative notes</label>
                    <ValidationProvider name="Description">
                      <textarea
                        type="Description"
                        class="form-control"
                        id="Description"
                        rows="8"
                        v-model="adminNotes"
                      ></textarea>
                    </ValidationProvider>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="form-group mr-1">
                      <div class="form-checkboxes">
                        <label name="quizAssessment" class="custom-checkbox">
                          <input
                            type="checkbox"
                            name="quizAssessment"
                            v-model="resubmission"
                          />
                          <span for="quizAssessment" class="checkmark"></span
                          >Enable resubmission:
                        </label>
                      </div>
                    </div>
                    <div class="form-group" v-if="resubmission">
                      <span>
                        <input
                          type="number"
                          min="1"
                          max="2"
                          placeholder="times"
                          class="form-control form-control-small"
                          v-model="resubmission_limit"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="form-group mr-1">
                    <div class="form-checkboxes">
                      <label name="quizAssessment" class="custom-checkbox">
                        <input
                          type="checkbox"
                          name="quizAssessment"
                          v-model="pdf_submission_enabled"
                        />
                        <span for="quizAssessment" class="checkmark"></span
                        >Allow PDF Submission
                      </label>
                    </div>
                  </div>
                  <div class="form-group mr-1">
                    <div class="form-checkboxes">
                      <label name="InstructorEdit" class="custom-checkbox">
                        <input
                          type="checkbox"
                          name="instructorEdit"
                          v-model="editable_by_instructor"
                        />
                        <span for="instructorEdit" class="checkmark"></span
                        >Allow Instructor to Edit Rubric
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-7">
                  <CharacterEmail
                    :selectedMenuItem="selectedMenuItem"
                    :characters="characters"
                    :updatedData="updatedData"
                    ref="emailCharacterForm"
                    v-on:selectedCharacters="selectedCharacters"
                  />
                  <div class="form-group submission-group">
                    <label>Learner Description</label>
                    <span>
                      <vue-editor
                        v-model="description"
                        placeholder="Add a Description"
                        class="form-control"
                      ></vue-editor>
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 mt-2">
                  <div class="d-flex justify-content-end mb-4">
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="createSubmissionEmail('drafted')"
                      >Next</a
                    >
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a class="text" @click="archiveSubLo('archived')"
                        >Archive this Learning Object(LO)</a
                      >
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </span>
      </div>
      <div class="setup-content" v-if="step == 'configure-rubric'">
        <Rubric :updatedData="updatedData" ref="rubricForm" />
      </div>
      <div class="setup-content" v-if="step == 'character-response'">
        <!-- Configure Character start here -->
        <div class="setup-content">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Configure Character Response</h4>
          </div>
          <span>
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-12 nav nav-tabs">
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      character_affix.active_range === 1 ? 'active' : '',
                    ]"
                    @click="saveCharacterAffix(1, 1)"
                    >0-59</a
                  >
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      character_affix.active_range === 2 ? 'active' : '',
                    ]"
                    @click="saveCharacterAffix(2, 1)"
                    >60-79</a
                  >
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      character_affix.active_range === 3 ? 'active' : '',
                    ]"
                    @click="saveCharacterAffix(3, 1)"
                    >80-100</a
                  >
                </div>
                <div class="col-sm-12 tab-content">
                  <!-- 0-59 start here -->
                  <div class="tab-item">
                    <div class="assessment-response">
                      <ul class="response-tab">
                        <li
                          :class="[
                            character_affix.active_order === 1 ? 'active' : '',
                          ]"
                        >
                          <a
                            @click="
                              saveCharacterAffix(
                                character_affix.active_range,
                                1
                              )
                            "
                          >
                            Iteration 1
                          </a>
                        </li>
                        <li
                          v-if="resubmission_limit >= 1"
                          :class="[
                            character_affix.active_order === 2 ? 'active' : '',
                          ]"
                        >
                          <a
                            @click="
                              saveCharacterAffix(
                                character_affix.active_range,
                                2
                              )
                            "
                          >
                            Iteration 2
                          </a>
                        </li>
                        <li
                          v-if="resubmission_limit === 2"
                          :class="[
                            character_affix.active_order === 3 ? 'active' : '',
                          ]"
                        >
                          <a
                            @click="
                              saveCharacterAffix(
                                character_affix.active_range,
                                3
                              )
                            "
                          >
                            Iteration 3
                          </a>
                        </li>
                      </ul>
                      <div class="response-content">
                        <!-- iteration content repeat start here -->
                        <ValidationObserver ref="subCharacterAffix">
                          <div class="response-item">
                            <div class="row custom-row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Introduction</label>
                                  <span>
                                    <vue-editor
                                      v-model="
                                        character_response_affix[
                                          character_affix.intro
                                        ][character_affix.active_order - 1]
                                          .value
                                      "
                                      placeholder="Add a Description"
                                      class="form-control"
                                    ></vue-editor>
                                  </span>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Conclusion</label>
                                  <span>
                                    <vue-editor
                                      v-model="
                                        character_response_affix[
                                          character_affix.conc
                                        ][character_affix.active_order - 1]
                                          .value
                                      "
                                      placeholder="Add a Conclusion"
                                      class="form-control"
                                    ></vue-editor>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ValidationObserver>
                        <!-- iteration content repeat start here -->
                      </div>
                    </div>
                  </div>
                  <!-- 0-59 end here -->
                </div>
              </div>
              <div class="row custom-row">
                <div class="col-sm-12 nav nav-tabs justify-content-between">
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      rubric_character.active_range === 1 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(1, 1, '0-49')"
                    >0-49</a
                  >
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      rubric_character.active_range === 2 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(2, 1, '50-59')"
                    >50-59</a
                  >
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      rubric_character.active_range === 3 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(3, 1, '60-69')"
                    >60-69</a
                  >
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      rubric_character.active_range === 4 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(4, 1, '70-79')"
                    >70-79</a
                  >
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      rubric_character.active_range === 5 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(5, 1, '80-89')"
                    >80-89</a
                  >
                  <a
                    :class="[
                      'nav-item',
                      'nav-link',
                      'flex-none',
                      'px-5',
                      rubric_character.active_range === 6 ? 'active' : '',
                    ]"
                    @click="setActiveRubric(6, 1, '90-100')"
                    >90-100</a
                  >
                </div>
                <div class="col-sm-12 tab-content">
                  <!-- 0-59 start here -->
                  <div class="tab-item">
                    <div class="assessment-response">
                      <ul class="response-tab">
                        <li
                          :class="[
                            rubric_character.active_order === 0 ? 'active' : '',
                          ]"
                        >
                          <a
                            @click="
                              setActiveRubric(
                                rubric_character.active_range,
                                0,
                                rubric_character.rangeValue
                              )
                            "
                            >Variation 1</a
                          >
                        </li>
                        <li
                          :class="[
                            rubric_character.active_order === 1 ? 'active' : '',
                          ]"
                        >
                          <a
                            @click="
                              setActiveRubric(
                                rubric_character.active_range,
                                1,
                                rubric_character.rangeValue
                              )
                            "
                            >Variation 2</a
                          >
                        </li>
                        <li
                          :class="[
                            rubric_character.active_order === 2 ? 'active' : '',
                          ]"
                        >
                          <a
                            @click="
                              setActiveRubric(
                                rubric_character.active_range,
                                2,
                                rubric_character.rangeValue
                              )
                            "
                            >Variation 3</a
                          >
                        </li>
                      </ul>
                      <div class="response-content">
                        <!-- iteration content repeat start here -->
                        <div class="response-item">
                          <div class="row custom-row mt-0">
                            <div class="col-sm-12">
                              <!-- grading table start here -->
                              <div class="grading-table grading-column-2">
                                <div class="grading-head mb-1">
                                  <div class="grading-col">
                                    <span>criteria</span>
                                  </div>
                                </div>
                                <div class="grading-data">
                                  <!-- repeat row start here -->
                                  <div
                                    class="grading-row"
                                    v-for="(
                                      character_response, index
                                    ) in rubric_character_response"
                                    :key="character_response.id"
                                  >
                                    <div class="grading-col">
                                      <div class="grading-box">
                                        <div class="grading-criteria">
                                          <p
                                            class="form-control"
                                            placeholder="Enter Criteria"
                                            :value="
                                              character_response.attributes
                                                .rubric_criteria
                                            "
                                          >
                                            {{
                                              character_response.attributes
                                                .rubric_criteria
                                            }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="grading-col">
                                      <div class="grading-box">
                                        <div
                                          v-if="
                                            character_response.attributes[
                                              rubric_character.rangeTitle
                                            ][rubric_character.active_order]
                                              .value
                                          "
                                          class="grading-edit"
                                          @click="
                                            showModel(
                                              'addRubricCharacterResponseModal',
                                              index,
                                              rubric_character.rangeTitle,
                                              character_response.attributes[
                                                rubric_character.rangeTitle
                                              ][rubric_character.active_order]
                                                .value,
                                              character_response.id,
                                              rubric_character.rangeValue
                                            )
                                          "
                                        >
                                          <p
                                            class="form-control"
                                            placeholder="Enter Criteria"
                                            v-html="
                                              character_response.attributes[
                                                rubric_character.rangeTitle
                                              ][rubric_character.active_order]
                                                .value
                                            "
                                          ></p>
                                        </div>
                                        <div class="grading-add" v-else>
                                          <a
                                            @click="
                                              showModel(
                                                'addRubricCharacterResponseModal',
                                                index,
                                                rubric_character.rangeTitle,
                                                character_response.attributes[
                                                  rubric_character.rangeTitle
                                                ][rubric_character.active_order]
                                                  .value,
                                                character_response.id,
                                                rubric_character.rangeValue
                                              )
                                            "
                                            >Add Feedback</a
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- repeat row end here -->
                                </div>
                              </div>
                              <!-- grading table end here -->
                            </div>
                          </div>
                        </div>
                        <!-- iteration content repeat start here -->
                      </div>
                    </div>
                  </div>
                  <!-- 0-59 end here -->
                </div>
              </div>
              <div class="row custom-row">
                <div class="col-sm-12">
                  <!-- 0-59 start here -->
                  <div
                    class="
                      d-flex
                      mt-4
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="head-title">Interim Response</h4>
                  </div>
                  <div class="assessment-response">
                    <ul class="response-tab mt-3 mb-2">
                      <li :class="[activeInterim === 1 ? 'active' : '']">
                        <a @click="setAndSaveInterim(1)">Iteration 1</a>
                      </li>
                      <li :class="[activeInterim === 2 ? 'active' : '']">
                        <a @click="setAndSaveInterim(2)">Iteration 2</a>
                      </li>
                      <li :class="[activeInterim === 3 ? 'active' : '']">
                        <a @click="setAndSaveInterim(3)">Iteration 3</a>
                      </li>
                    </ul>
                    <div class="response-content">
                      <!-- iteration content repeat start here -->
                      <div class="response-item">
                        <div class="row custom-row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <span>
                                <vue-editor
                                  v-model="interim_resp[activeInterim]"
                                  placeholder="Add a Description"
                                  class="form-control"
                                ></vue-editor>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- iteration content repeat start here -->
                    </div>
                  </div>
                  <!-- 0-59 end here -->
                </div>
              </div>
              <div class="row custom-row">
                <div class="col-sm-12 pt-5">
                  <div class="d-flex justify-content-end mb-4">
                    <a
                      class="btn primary"
                      href="javascript:void(0);"
                      @click="reload"
                      >Save & Publish</a
                    >
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a class="text">Archive this Learning Object(LO)</a></a
                    >
                  </div>
                </div>
              </div>
            </form>
          </span>
        </div>
        <!-- Configure Character end here -->
      </div>
    </div>
    <!-- add feedback modal start -->
    <modal
      name="addRubricCharacterResponseModal"
      height="auto"
      :width="700"
      :scrollable="true"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Add Feedback</h5>
            <p class="addFeedbackTitle">Criteria (Score {{ colName }})</p>
          </div>
        </div>
        <div class="modal-body">
          <form class="modal-form modal-addFeedbackForm">
            <vue-editor
              id="test"
              :editorToolbar="fullTool"
              v-model="transcript"
            ></vue-editor>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="$modal.hide('addRubricCharacterResponseModal')"
            class="btn default"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn primary"
            @click="saveRubricCharacterData('addRubricCharacterResponseModal')"
          >
            Save
          </button>
        </div>
      </div>
    </modal>
    <!-- add feedback modal end -->
    <modal
      name="cloneConfirmationModal"
      height="auto"
      :width="350"
      :scrollable="true"
    >
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          Are you sure you want to clone this learning object?
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('cloneConfirmationModal')"
            class="btn medium default"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="cloneLo(), $modal.hide('cloneConfirmationModal')"
            class="btn medium primary"
          >
            Ok
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "@/services/api";
import CharacterEmail from "./characterEmail.vue";
import Rubric from "./Rubric.vue";
import { VueEditor } from "vue2-editor";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "SubmissionEmail",
  components: {
    ValidationProvider,
    ValidationObserver,
    Rubric,
    CharacterEmail,
    VueEditor,
  },
  props: ["elmMenu", "selectedMenuItem", "characters"],
  data() {
    return {
      name: "",
      description: "",
      adminNotes: "",
      isActive: false,
      lod: null,
      status: "drafted",
      resubmission_limit: 0,
      resubmission: "",
      pdf_submission_enabled: false,
      editable_by_instructor: false,
      isActiveTab: ["active"],
      step: "basic-setup",
      rubric: [
        {
          id: null,
          criteria: null,
          weight: null,
          range_1: null,
          range_2: null,
          range_3: null,
          range_4: null,
          range_5: null,
        },
      ],
      transcript: "",
      fullTool: [],
      index: 0,
      key: "rubric_1",
      submit: false,
      distributeWeight: false,
      totalWeight: 0,
      validate: false,
      colName: "Criteria",
      objectableId: "",
      selectedChar: [],
      selectedCcChar: [],
      invalidWeight: false,
      updatedData: "",
      submissionEmailBasicInfo: "",
      prevstep: "basic-setup",
      character_response_affix: {
        intro_range_1: [],
        intro_range_2: [],
        intro_range_3: [],
        conc_range_1: [],
        conc_range_2: [],
        conc_range_3: [],
      },
      submission_email_learn_obj: null,
      character_affix_id: null,
      character_affix: {
        range: 3,
        order: 3,
        active_range: 1,
        active_order: 1,
        intro: "intro_range_1",
        conc: "conc_range_1",
      },
      interim_resp: {
        1: "",
        2: "",
        3: "",
      },
      activeInterim: 1,
      rubric_character_response: [],
      active_rubric_character: 1,
      rubric_character: {
        range: 6,
        order: 3,
        active_range: 1,
        active_order: 0,
        rangeTitle: "range_1",
        rangeValue: "0-49",
      },
    };
  },
  methods: {
    cloneLo() {
      utilFunctionService.showLoader();
      api
        .cloneObj(this.$route.params.id, this.$route.query.id)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(commonConfig.appMessages.loCloned);
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch();
    },
    selectedCharacters(data) {
      this.selectedCcChar = data.ccCharacters;
      this.selectedChar = data.toCharacter;
    },
    restore() {
      const formVal = new FormData();
      formVal.append("learn_mod_id", this.$route.params.id);
      formVal.append("learning_object[status]", "published");
      formVal.append("id", this.$route.query.id);
      api
        .restoreLo(this.$route.params.id, this.$route.query.id, formVal)
        .then(() => {
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$parent.card_type = "";
          this.$router.push({
            id: this.$route.params.id,
            query: { id: this.$route.query.id },
          });
          window.location.reload();
        })
        .catch(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$router.push({
            id: this.$route.params.id,
            query: { id: this.$route.query.id },
          });
          window.location.reload();
        });
    },
    submissionUpdate() {
      if (!this.$route.query.id) {
        this.searchCharacters = this.characters;
        this.charactersList = this.characters;
        this.searchOptionalCharacters = this.characters;
        this.clearForm();
      } else {
        this.submissionEmailBasicInfo = this.selectedMenuItem;
        this.setFieldValue();
      }
    },
    setFieldValue() {
      if (this.submissionEmailBasicInfo) {
        let { name, status, admin_notes, description } =
          this.submissionEmailBasicInfo.attributes;
        this.name = name;
        this.status = status;
        this.adminNotes = admin_notes;
        this.description = description;
        this.distributeWeight =
          this.submissionEmailBasicInfo.attributes.card_detail.attributes.distribute_weight_eq;
        this.resubmission =
          this.submissionEmailBasicInfo.attributes.card_detail.attributes.resubmission;
        this.pdf_submission_enabled =
          this.submissionEmailBasicInfo.attributes.card_detail.attributes.pdf_submission_enabled;
        this.editable_by_instructor =
          this.submissionEmailBasicInfo.attributes.card_detail.attributes.editable_by_instructor;
        this.resubmission_limit =
          this.submissionEmailBasicInfo.attributes.card_detail.attributes.resubmission_limit;
        if (
          this.submissionEmailBasicInfo.attributes.card_detail.attributes
            .to_character &&
          this.submissionEmailBasicInfo.attributes.card_detail.attributes
            .to_character.length > 0
        ) {
          this.selectedChar =
            this.submissionEmailBasicInfo.attributes.card_detail.attributes.to_character;
        }
        if (
          this.submissionEmailBasicInfo.attributes.card_detail.attributes
            .cc_characters &&
          this.submissionEmailBasicInfo.attributes.card_detail.attributes
            .cc_characters.length > 0
        ) {
          this.selectedCcChar =
            this.submissionEmailBasicInfo.attributes.card_detail.attributes.cc_characters;
        }
        this.setCharacterAffix();
        this.setRubricCharacter();
        this.setAndSaveInterim(
          this.activeInterim,
          this.submissionEmailBasicInfo.attributes.card_detail.attributes
            .interim_resp
        );
      }
    },
    currentStep(step, type) {
      console.log(type);
      if (step === "basic-setup") {
        this.prevstep = step;
        this.step = "basic-setup";
        this.submissionEmailBasicInfo = this.updatedData;
        this.setFieldValue();
        this.isActiveTab = ["active"];
      } else {
        if (step === "character-response") {
          this.setFieldValue();
          this.step = "character-response";
          this.setCharacterAffix();
          // if (this.prevstep === "basic-setup" && type !== undefined) {
          //   this.createSubmissionEmail();
          // }
          this.prevstep = step;
          this.isActiveTab = ["active", "active", "active"];
          this.setRubricCharacter();
        } else {
          this.setFieldValue();
          if (this.prevstep === "basic-setup") {
            this.prevstep = step;
            this.createSubmissionEmail();
          } else {
            this.step = "configure-rubric";
            this.prevstep = step;
            this.isActiveTab = ["active", "active"];
          }
        }
      }
    },
    createSubmissionEmail() {
      utilFunctionService.showLoader();
      this.$refs.subform.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          utilFunctionService.hideLoader();
          return;
        } else {
          if (this.selectedChar.length === 0) {
            utilFunctionService.hideLoader();
            this.$refs.emailCharacterForm.validateErr();
          } else {
            if (!this.resubmission) {
              this.submitBasicEmail();
            } else {
              if (
                Number(this.resubmission_limit) ===
                  Number(commonConfig.setting.minResubmission) ||
                Number(this.resubmission_limit) ===
                  Number(commonConfig.setting.maxResubmission)
              ) {
                this.submitBasicEmail();
              } else {
                utilFunctionService.hideLoader();
                utilFunctionService.showerr(
                  commonConfig.appMessages.resubmissionLimit
                );
              }
            }
          }
        }
      });
    },
    submitBasicEmail() {
      const fb = new FormData();
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[description]", this.description);
      fb.append("learning_object[learning_object_type]", "submission");
      fb.append(
        "learning_object[status]",
        this.status === "published" ? "published" : "drafted"
      );
      fb.append("card[resubmission]", this.resubmission);
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("card_type", this.elmMenu.card_type);
      fb.append("learning_object[admin_notes]", this.adminNotes);
      fb.append("card[to_character_id]", this.selectedChar[0].id);
      fb.append("card[pdf_submission_enabled]", this.pdf_submission_enabled);
      fb.append("card[editable_by_instructor]", this.editable_by_instructor);
      this.selectedCcChar.map((x) => {
        fb.append("card[cc_character_ids][]", parseInt(x.id));
      });
      if (this.resubmission)
        fb.append("card[resubmission_limit]", this.resubmission_limit);
      if (this.$route.query.id || this.submissionEmailBasicInfo.id) {
        api
          .updateLo(
            this.$route.params.id ||
              this.submissionEmailBasicInfo.attributes.learn_mod_id,
            this.$route.query.id || this.submissionEmailBasicInfo.id,
            fb
          )
          .then((res) => {
            this.routeToRubric(res);
          })
          .catch(() => {
            utilFunctionService.hideLoader();
            this.clearForm();
          });
      } else {
        api
          .saveLo(this.$route.params.id, fb)
          .then((res) => {
            this.$route.query.id = res.data.data.id;
            this.routeToRubric(res);
          })
          .catch(() => {
            utilFunctionService.hideLoader();
            this.clearForm();
          });
      }
    },
    routeToRubric(res) {
      utilFunctionService.hideLoader();
      if (this.step !== "character-response") {
        this.step = "configure-rubric";
        this.isActiveTab.push("active");
      }
      this.updatedData = res.data.data;
      this.objectableId = res.data.data.id;
      this.$emit("updateLoList");
      setTimeout(() => {
        this.updateSubmissionBasicInfo();
      }, 1500);
    },
    updateSubmissionBasicInfo() {
      this.submissionEmailBasicInfo = this.updatedData;
      this.setFieldValue();
    },
    clearForm() {
      this.resubmission_limit = 0;
      this.resubmission = false;
      this.status = "drafted";
      this.name = "";
      this.adminNotes = "";
      this.description = "";
    },
    archiveSubLo() {
      this.$emit("archiveLO");
    },
    setCharacterAffix() {
      this.submission_email_learn_obj =
        this.submissionEmailBasicInfo &&
        this.submissionEmailBasicInfo.attributes
          ? this.submissionEmailBasicInfo.attributes.card_detail.id
          : null;
      if (this.submission_email_learn_obj) {
        api
          .getCharacterResponseAffixes(this.submission_email_learn_obj)
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              this.character_response_affix = res.data.data[0].attributes;
              if (
                this.character_response_affix["conc_range_1"].length - 1 <
                this.resubmission_limit
              ) {
                let j =
                  this.resubmission_limit -
                  (this.character_response_affix["conc_range_1"].length - 1);
                for (let i = j; i <= this.resubmission_limit; i++) {
                  this.character_response_affix.intro_range_1.push({
                    order: i,
                    value: "",
                  });
                  this.character_response_affix.intro_range_2.push({
                    order: i,
                    value: "",
                  });
                  this.character_response_affix.intro_range_3.push({
                    order: i,
                    value: "",
                  });
                  this.character_response_affix.conc_range_1.push({
                    order: i,
                    value: "",
                  });
                  this.character_response_affix.conc_range_2.push({
                    order: i,
                    value: "",
                  });
                  this.character_response_affix.conc_range_3.push({
                    order: i,
                    value: "",
                  });
                }
              }
              this.character_affix_id = res.data.data[0].id;
            } else {
              this.setDefaultCharacterAffix();
            }
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      }
    },
    setRubricCharacter() {
      this.submission_email_learn_obj =
        this.submissionEmailBasicInfo &&
        this.submissionEmailBasicInfo.attributes
          ? this.submissionEmailBasicInfo.attributes.card_detail.id
          : null;
      if (this.submission_email_learn_obj) {
        api
          .getRubricCharacterResponse(this.submission_email_learn_obj)
          .then((res) => {
            if (res.data.data) {
              this.rubric_character_response = res.data.data;
              this.rubric_character_response.forEach((ele) => {
                for (const [key, value] of Object.entries(ele.attributes)) {
                  if (value.length === 0) {
                    ele.attributes[key].push({ order: 1, value: "" });
                    ele.attributes[key].push({ order: 2, value: "" });
                    ele.attributes[key].push({ order: 3, value: "" });
                  }
                }
              });
            }
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      }
    },
    setDefaultCharacterAffix() {
      this.character_response_affix = {
        intro_range_1: [],
        intro_range_2: [],
        intro_range_3: [],
        conc_range_1: [],
        conc_range_2: [],
        conc_range_3: [],
      };

      for (let i = 0; i <= this.resubmission_limit; i++) {
        this.character_response_affix.intro_range_1.push({
          order: i,
          value: "",
        });
        this.character_response_affix.intro_range_2.push({
          order: i,
          value: "",
        });
        this.character_response_affix.intro_range_3.push({
          order: i,
          value: "",
        });
        this.character_response_affix.conc_range_1.push({
          order: i,
          value: "",
        });
        this.character_response_affix.conc_range_2.push({
          order: i,
          value: "",
        });
        this.character_response_affix.conc_range_3.push({
          order: i,
          value: "",
        });
      }
    },
    setActiveRange(range, order) {
      this.character_affix.active_range = range;
      this.character_affix.active_order = order;
      this.character_affix.intro = "intro_range_" + range;
      this.character_affix.conc = "conc_range_" + range;
    },
    setAndSaveInterim(activeInterim, data) {
      if (data) {
        this.activeInterim = activeInterim;
        this.interim_resp[1] = data[1] ? data[1] : "";
        this.interim_resp[2] = data[2] ? data[2] : "";
        this.interim_resp[3] = data[3] ? data[3] : "";
      } else {
        this.activeInterim = activeInterim;
        const formval = new FormData();
        formval.append("learning_object_id", this.submissionEmailBasicInfo.id);
        formval.append("interim_resp", JSON.stringify(this.interim_resp));
        formval.append("distribute_weight_eq", "");
        api
          .updateInterimResp(formval)
          .then(() => {
            this.submissionEmailBasicInfo.attributes.card_detail.attributes.interim_resp =
              this.interim_resp;
          })
          .catch(() => {});
      }
    },
    saveCharacterAffix(range, order) {
      utilFunctionService.showLoader();
      this.$refs.subCharacterAffix.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          utilFunctionService.hideLoader();
          return;
        } else {
          const fbData = new FormData();
          fbData.append(
            "character_response_affix[intro_range_1]",
            JSON.stringify(this.character_response_affix.intro_range_1)
          );
          fbData.append(
            "character_response_affix[intro_range_2]",
            JSON.stringify(this.character_response_affix.intro_range_2)
          );
          fbData.append(
            "character_response_affix[intro_range_3]",
            JSON.stringify(this.character_response_affix.intro_range_3)
          );
          fbData.append(
            "character_response_affix[conc_range_1]",
            JSON.stringify(this.character_response_affix.conc_range_1)
          );
          fbData.append(
            "character_response_affix[conc_range_2]",
            JSON.stringify(this.character_response_affix.conc_range_2)
          );
          fbData.append(
            "character_response_affix[conc_range_3]",
            JSON.stringify(this.character_response_affix.conc_range_3)
          );
          if (this.character_affix_id) {
            api
              .patchCharacterResponseAffixes(this.character_affix_id, fbData)
              .then((res) => {
                this.character_response_affix = res.data.data.attributes;
                utilFunctionService.hideLoader();
                this.setActiveRange(range, order);
              })
              .catch(() => {
                utilFunctionService.hideLoader();
              });
          } else {
            fbData.append(
              "character_response_affix[submission_email_learn_obj_id]",
              this.submission_email_learn_obj
            );
            api
              .postCharacterResponseAffixes(fbData)
              .then((res) => {
                this.character_affix_id = res.data.data.id;
                this.character_response_affix = res.data.data.attributes;
                utilFunctionService.hideLoader();
                this.setActiveRange(range, order);
              })
              .catch(() => {
                utilFunctionService.hideLoader();
              });
          }
        }
      });
    },
    setActiveRubric(range, order, rangeValue) {
      this.rubric_character.active_range = range;
      this.rubric_character.active_order = order;
      this.rubric_character.rangeTitle = "range_" + range;
      this.rubric_character.rangeValue = rangeValue;
    },
    showModel(modal, index, key, value, id, name) {
      this.index = index;
      this.key = key;
      this.transcript = value;
      this.id = id;
      this.colName = name;
      this.$modal.show(modal);
    },
    saveRubricCharacterData(modal) {
      // if (this.transcript != null && this.transcript.length > commonConfig.setting.default) {
      this.rubric_character_response[this.index].attributes[
        this.rubric_character.rangeTitle
      ][this.rubric_character.active_order].value = this.transcript;
      if (this.id != null) {
        const fb = new FormData();
        fb.append(
          `rubric_character_response[${this.key}]`,
          JSON.stringify(
            this.rubric_character_response[this.index].attributes[
              this.rubric_character.rangeTitle
            ]
          )
        );
        api.patchRubricCharacterResponse(this.id, fb).then(() => {});
        // }
      }
      this.$modal.hide(modal);
    },
    reload() {
      this.saveCharacterAffix(
        this.character_affix.range,
        this.character_affix.order
      );
      this.setAndSaveInterim(this.activeInterim);
      this.restore();
    },
  },
};
</script>
