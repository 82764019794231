<template>
    <div>
        <div class="container">
            <header class="header">
                <div class="header-left">
                    <h2>{{ this.$route.params.id ? `Updating Skill - ${llm_skill_name}` : 'Creating Skill' }}</h2>
                </div>
            </header>

            <div class="content-container">
                <div class="row">
                    <div class="col-6">
                        <form @submit.prevent="saveUpdateLlmSkill">
                            <div class="form-group">
                                <label for="skillName">Skill Name</label>
                                <input type="text" class="form-control" id="skillName" v-model="llm_skill_name">
                            </div>
                            <div class="form-group">
                                <label for="skillDescription">Skill Description</label>
                                <textarea class="form-control" id="skillDescription" rows="3"
                                    v-model="llm_skill_description"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="skillType">Auto Create skill set?</label>
                                <input type="checkbox" class="form-control auto-create-skill-set-check" id="skillType"
                                    v-model="llm_skill_auto_create_skill_set">
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-6">
                        <h3>Skill Testing</h3>
                        <div class="d-flex flex-column w-100 mb-5">
                            <div v-for="(messages, index) in messageList" :key="index" class="mb-5">
                                <!-- learner -->
                                <div class="bg-white text-dark font-weight-bold rounded border p-3 mb-3 learner-message">
                                    <p>{{ messages.learner }}</p>
                                </div>
                                <!-- character -->
                                <div
                                    class="bg-white text-dark font-weight-bold rounded border p-3 mb-3 float-right character-message">
                                    <p v-if="messages.character === true" class="text-success">This demonstrates the skill!
                                    </p>
                                    <p v-if="messages.character === false" class="text-danger">This does not demonstrate the
                                        skill!</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <input class="form-control mb-5" type="text" v-model="testMessage"
                                placeholder="Type test message here" />
                            <div class="chat-button-container">
                                <button class="btn btn-primary" @click.prevent="submitTestMessage">Test Skill</button>
                                <button class="btn btn-secondary" @click.prevent="resetInteraction">Reset
                                    Interaction</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
    name: "CreateLlmSkill",
    data() {
        return {
            llm_skill_name: "",
            llm_skill_description: "",
            llm_skill_auto_create_skill_set: false,
            messageList: [],
            testMessage: ""
        };
    },
    methods: {
        createLlmSkill() {
            utilFunctionService.showLoader();
            let payload = {
                name: this.llm_skill_name,
                description: this.llm_skill_description,
                auto_create_skill_set: this.llm_skill_auto_create_skill_set
            }
            api.createLlmSkill(payload).then((response) => {
                this.llm_skills = response.data;
                this.$router.push("/llm-skills");
                utilFunctionService.hideLoader();
            })
                .catch((error) => {
                    utilFunctionService.hideLoader();
                    console.log(error);
                });
        },
        updateLlmSkill() {
            utilFunctionService.showLoader();
            let payload = {
                name: this.llm_skill_name,
                description: this.llm_skill_description,
                auto_create_skill_set: this.llm_skill_auto_create_skill_set
            }
            api.updateLlmSkill(payload, this.$route.params.id).then((response) => {
                this.llm_skills = response.data;
                utilFunctionService.hideLoader();
            })
                .catch((error) => {
                    utilFunctionService.hideLoader();
                    console.log(error);
                });
        },
        saveUpdateLlmSkill() {
            if (this.$route.params.id) {
                this.updateLlmSkill();
            } else {
                this.createLlmSkill();
            }
        },
        submitTestMessage() {
            this.createTestAssessmentChannel();
        },
        getLlmSkillData() {
            api.getLlmSkill(this.$route.params.id).then((response) => {
                console.log(response);
                const LLM_SKILL_DATA = response.data.data;
                this.llm_skill_name = LLM_SKILL_DATA.attributes.name;
                this.llm_skill_description = LLM_SKILL_DATA.attributes.description;
                this.llm_skill_auto_create_skill_set = LLM_SKILL_DATA.attributes.auto_create_skill_set;
            });
        },
        resetInteraction() {
            console.log("Resetting interaction");
            this.messageList = [];
            this.testMessage = "";
            this.$toast.open({
                message: 'Interaction has been reset',
                type: 'success',
                duration: 2000,
                dismissible: true,
                position: 'bottom-right',
            })
        },
        createTestAssessmentChannel() {
            console.log("*** in createTestAssessmentChannel")
            this.$cable.subscribe({
                channel: "TestAssessmentChannel",
                room: this.$route.params.id,
            });
        },
    },
    channels: {
        TestAssessmentChannel: {
            connected() {
                console.log("*** TestAssessmentChannel created")
                let payload = {
                    learner_interaction_id: this.$route.params.id,
                    skill_name: this.llm_skill_name,
                    skill_description: this.llm_skill_description,
                    answer: this.testMessage,
                }
                this.messageList.push({ learner: this.testMessage, character: "" })

                api.submitTestLlmSkillMessage(payload)
                    .then((response) => {
                        this.$toast.open({
                            message: 'Test message has been submitted',
                            type: 'success',
                            duration: 2000,
                            dismissible: true,
                            position: 'bottom-right',
                        })
                        this.testMessage = "";
                        console.log("*** response", response)
                    })
            },
            received(data) {
                console.log("*** data received in createTestAssessmentChannel", data)
                let messageListLength = this.messageList.length - 1;
                this.messageList[messageListLength].character = data.data.result
                this.$cable.unsubscribe('TestAssessmentChannel');
            },
        },
    },
    created() {
        if (this.$route.params.id) {
            this.getLlmSkillData();
        }
    },
};
</script>
  
<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.learner-message {
    width: 13rem;
    border-color: slategray;
}

.character-message {
    width: 13rem;
    border-color: slategray;
}

.form-group label {
    font-weight: bold;
    font-size: 18px;
}

.form-control {
    border: 1px solid #abb0b5;
}

.auto-create-skill-set-check {
    width: 15px;
}

.chat-button-container {
    display: flex;
    flex-direction: rcow;
    justify-content: space-between;
}
</style>
  