<template>
  <div>
    <span>
      <ValidationObserver ref="subform">
        <form class="w-100">
          <div class="row custom-row mt-0">
            <div class="col-sm-12">
              <div class="form-group customer-dropdown">
                <label class="form-label" for="exampleInputEmail1"
                  >Select email to character</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="searchCharacter"
                  v-on:input="filterCharacter"
                  placeholder="Search by name, role & organization"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="error"
                  >Select email to character is required</span
                >
                <div class="chip-container pt-2 pb-0 entity-chip">
                  <div
                    class="chip"
                    v-for="(char, i) of selectedChar"
                    :key="char.id"
                  >
                    <span>{{ char.char_full_name }}</span>
                    <em class="icon-close" @click="deleteSeletedChar(i)"></em>
                  </div>
                </div>
                <div
                  class="user-row-scroll custom-user-dropdown"
                  v-if="searchCharacter.length > 0 && charStatus"
                >
                  <div
                    class="user-row"
                    v-for="character in searchCharacters"
                    :key="character.id"
                  >
                    <div class="img-container">
                      <div class="img-box">
                        <img
                          :src="character.char_photo_url"
                          v-if="character.char_photo_url"
                          alt="image"
                        />
                      </div>
                      <div class="user-info">
                        <div class="head">{{ character.char_full_name }}</div>
                        <div class="text">
                          {{ character.world_role }} at {{ character.org_name }}
                        </div>
                        <p>
                          {{ character.char_age }} , {{ character.char_gender }}
                        </p>
                      </div>
                    </div>
                    <div class="dropdown-action" v-if="character.status">
                      <em class="icon-check"></em>
                      <span>Added</span>
                    </div>
                    <div class="dropdown-action" v-if="!character.status">
                      <a class="add-action" @click="addEmailChar(character)"
                        >+</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group customer-dropdown">
                <label class="form-label" for="exampleInputEmail1"
                  >Select email cc character(s) (optional)</label
                >
                <input
                  type="text"
                  v-model="searchCcCharacter"
                  v-on:input="filterCcCharacter"
                  class="form-control"
                  placeholder="Search by name, role & organization"
                />
                <div class="chip-container pt-2 pb-0 entity-chip">
                  <div
                    class="chip"
                    v-for="(char, i) of selectedCcChar"
                    :key="char.id"
                  >
                    <span>{{ char.char_full_name }}</span>
                    <em class="icon-close" @click="deleteSeletedCcChar(i)"></em>
                  </div>
                </div>
                <div
                  class="user-row-scroll custom-user-dropdown"
                  v-if="searchCcCharacter.length > 0 && optionalCharStatus"
                >
                  <div
                    class="user-row"
                    v-for="character in searchOptionalCharacters"
                    :key="character.id"
                  >
                    <div class="img-container">
                      <div class="img-box">
                        <img
                          :src="character.char_photo_url"
                          v-if="character.char_photo_url"
                          alt="image"
                        />
                      </div>
                      <div class="user-info">
                        <div class="head">{{ character.char_full_name }}</div>
                        <div class="text">
                          {{ character.world_role }} at {{ character.org_name }}
                        </div>
                        <p>
                          {{ character.char_age }} , {{ character.char_gender }}
                        </p>
                      </div>
                    </div>
                    <div class="dropdown-action" v-if="character.status">
                      <em class="icon-check"></em>
                      <span>Added</span>
                    </div>
                    <div class="dropdown-action" v-if="!character.status">
                      <a class="add-action" @click="addEmailCcChar(character)"
                        >+</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </span>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
// import api from "@/services/api";

export default {
  name: "characterEmail",
  components: {
    ValidationObserver,
  },
  props: ["selectedMenuItem", "characters", "updatedData"],
  data() {
    return {
      error: false,
      charStatus: false,
      isActive: false,
      charactersData: [],
      selectedChar: [],
      selectedCcChar: [],
      searchCcCharacter: "",
      charactersList: [],
      searchCharacter: "",
      optionalCharStatus: false,
      mutateProps: "",
      searchCharacters: [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          char_photo_url: "",
          char_age: "",
          char_gender: "",
          status: false,
        },
      ],
      searchOptionalCharacters: [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          char_photo_url: "",
          char_age: "",
          char_gender: "",
        },
      ],
    };
  },
  async created() {
    this.submissionUpdate();
  },
  methods: {
    submissionUpdate() {
      if (this.updatedData && this.updatedData.attributes) {
        this.mutateProps = this.updatedData;
      } else {
        this.mutateProps = this.selectedMenuItem;
      }
      this.setFieldValue();
    },
    validateErr() {
      this.error = true;
      this.isActive = true;
    },
    setFieldValue() {
      if (this.mutateProps) {
        this.selectedChar = [];
        this.selectedCcChar = [];
        this.charactersData = [];
        this.charactersData = this.characters;
        this.searchCharacters = this.characters;
        this.charactersList = this.characters;
        this.searchOptionalCharacters = this.characters;
        //console.log(this.mutateProps, "*** this.mutateProps in setFieldValue");
        if (this.mutateProps.attributes.card_type == "submission_email") {
          if (
            this.mutateProps.attributes.card_detail.attributes.to_character &&
            this.mutateProps.attributes.card_detail.attributes.to_character
              .length > 0
          ) {
            this.getCharacterData(
              this.mutateProps.attributes.card_detail.attributes.to_character
            );
          }
        }

        if (this.mutateProps.attributes.card_type !== "submission_email") {
          if (
            this.mutateProps.attributes.card_detail.attributes.to_characters &&
            this.mutateProps.attributes.card_detail.attributes.to_characters
              .length > 0
          ) {
            this.getCharacterData(
              this.mutateProps.attributes.card_detail.attributes.to_characters
            );
          }
        }

        if (
          this.mutateProps.attributes.card_detail.attributes.cc_characters &&
          this.mutateProps.attributes.card_detail.attributes.cc_characters
            .length > 0
        ) {
          this.selectedCcChar =
            this.mutateProps.attributes.card_detail.attributes.cc_characters.map(
              (x) => ({
                id: x.id,
                status: true,
                world_role: x.attributes.world_role,
                org_name: x.attributes.org_name,
                char_photo_url: x.attributes.char_photo_url.thumbnail,
                char_full_name: x.attributes.char_full_name,
                char_gender: x.attributes.char_gender,
                char_age: x.attributes.char_age,
              })
            );
          this.selectedTabChar = this.selectedCcChar[0];
        }
      }
    },
    clearForm() {
      this.selectedChar = [];
      this.selectedCcChar = [];
      this.searchCharacters = "";
      this.searchCcCharacter = "";
      this.searchCharacters = [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          char_photo_url: "",
          char_age: "",
          char_gender: "",
          status: false,
        },
      ];
      this.searchOptionalCharacters = [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          char_photo_url: "",
          char_age: "",
          char_gender: "",
        },
      ];
    },
    addEmailChar(e) {
      this.error = false;
      this.isActive = false;
      this.selectedChar = [];
      this.selectedChar.push(e);
      this.searchCharacter = "";
      this.searchCharacters = [];
      let data = {
        toCharacter: this.selectedChar,
        ccCharacters: this.selectedCcChar,
      };
      this.$emit("selectedCharacters", data);
    },
    addEmailCcChar(e) {
      this.selectedCcChar.push(e);
      this.searchCcCharacter = "";
      this.searchOptionalCharacters = [];
      let data = {
        toCharacter: this.selectedChar,
        ccCharacters: this.selectedCcChar,
      };
      this.$emit("selectedCharacters", data);
    },
    getCharacterData(data) {
      this.selectedChar = data.map((x) => ({
        id: x.id,
        status: true,
        world_role: x.attributes.world_role,
        org_name: x.attributes.org_name,
        char_photo_url: x.attributes.char_photo_url.thumbnail,
        char_full_name: x.attributes.char_full_name,
        char_gender: x.attributes.char_gender,
        char_age: x.attributes.char_age,
      }));
      this.emailSelChar = this.selectedChar[0];
    },
    deleteSeletedChar(i) {
      this.selectedChar.splice(i, 1);
      let data = {
        toCharacter: this.selectedChar,
        ccCharacters: this.selectedCcChar,
      };
      this.$emit("selectedCharacters", data);
    },
    deleteSeletedCcChar(i) {
      this.selectedCcChar.splice(i, 1);
      let data = {
        toCharacter: this.selectedChar,
        ccCharacters: this.selectedCcChar,
      };
      this.$emit("selectedCharacters", data);
    },
    // getCharacters() {
    //   api
    //     .getWorldCharacters(this.worldId)
    //     .then((res) => {
    //       this.searchCharacters = [];
    //       this.characters = [];
    //       this.characters = res.data.data.map((x) => ({
    //         id: x.id,
    //         world_role: x.attributes.world_role,
    //         org_name: x.attributes.org_name,
    //         char_photo_url: x.attributes.char_photo_url.thumbnail,
    //         char_full_name: x.attributes.char_full_name,
    //         char_gender: x.attributes.char_gender,
    //         char_age: x.attributes.char_age,
    //       }));
    //       this.searchCharacters = this.characters;
    //       this.charactersList = this.characters;
    //       this.searchOptionalCharacters = this.characters;
    //     })
    //     .catch();
    //   //console.log(this, "*** getCharacters() this in characterEmail.vue");
    // },
    filterCharacter() {
      this.charStatus = true;
      if (this.searchCharacter.length !== 0) {
        if (this.selectedChar.length > 0) {
          this.characters.forEach((char) => {
            char.status = false;
          });

          this.selectedChar.forEach((element) => {
            const index = this.characters.findIndex(
              (elem) => elem.id === element.id
            );
            if (index !== -1) {
              this.charactersData[index].status = true;
            } else {
              this.charactersData[index].status = false;
            }
          });

          this.searchCharacters = this.characters.filter((char) => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase())
            );
          });
        } else {
          this.characters.forEach((char) => {
            char.status = false;
          });

          this.searchCharacters = this.characters.filter((char) => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase())
            );
          });
        }
      } else {
        return (this.searchCharacters = this.characters);
      }
    },
    filterCcCharacter() {
      this.optionalCharStatus = true;
      if (this.searchCcCharacter.length !== 0) {
        if (this.selectedCcChar.length > 0) {
          this.characters.forEach((char) => {
            char.status = false;
          });

          this.selectedCcChar.forEach((element) => {
            const index = this.characters.findIndex(
              (elem) => elem.id === element.id
            );
            if (index !== -1) {
              this.charactersData[index].status = true;
            } else {
              this.charactersData[index].status = false;
            }
          });

          this.searchOptionalCharacters = this.characters.filter((char) => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase())
            );
          });
        } else {
          this.characters.forEach((char) => {
            char.status = false;
          });
          this.searchOptionalCharacters = this.characters.filter((char) => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase())
            );
          });
        }
      } else {
        return (this.searchOptionalCharacters = this.characters);
      }
    },
  },
};
</script>
