<template>
  <modal
    name="editCharacterRole"
    :width="430"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title" id="exampleModalLabel">
          <h5>Edit Character Role</h5>
        </div>
        <button type="button" class="close" @click="hide('editCharacterRole')">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <ValidationObserver ref="form">
          <form class="modal-form scheme-form">
            <div class="form-group">
              <label class="form-label mb-2" for="name">Name</label>
              <ValidationProvider name="Name" rules="required|max:100" v-slot="{ errors }">
                <input
                  type="name"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  v-model="name"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn default"
                data-dismiss="modal"
                @click="hide('editCharacterRole')"
              >Cancel</button>
              <button
                type="button"
                class="btn primary"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#addOrganisation"
                v-on:click="updateCharacterRole"
              >Save</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "editCharacterRole",
  props: {
    method: { type: Function }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      name: "",
      isActive: false,
      page: 1,
      role_id: ""
    };
  },
  methods: {
    beforeOpen(event) {
      this.role_id = event.params.role_id;
      this.page = event.params.page;
      this.getRoleDetails(this.role_id);
    },
    hide(modal) {
      this.clearFormData();
      this.$parent.getCharacterRoles(this.page);
      this.$parent.hide(modal);
    },
    updateCharacterRole() {
      this.$refs.form
        .validate()
        .then(success => {
          this.isActive = true;
          if (!success) {
            return;
          } else {
            this.isActive = false;
            utilFunctionService.showLoader();
            const fb = new FormData();
            fb.append("world_role[name]", this.name);
            api
              .updateCharacterRole(this.role_id, fb)
              .then(() => {
                utilFunctionService.hideLoader();
                this.clearFormData();
                utilFunctionService.showSuccess(commonConfig.appMessages.editCharacterRole);
                this.$parent.getCharacterRoles(this.page);
                this.$parent.hide("editCharacterRole");
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                this.clearFormData();
                utilFunctionService.showSuccess(commonConfig.appMessages.error);
                this.$parent.hide("editCharacterRole");
              });
          }
        })
        .catch();
    },
    getRoleDetails(role_id) {
      api
        .getRoleDetails(role_id)
        .then(res => {
          this.name = res.data.data.attributes.name;
        })
        .catch();
    },
    clearFormData () {
      this.name = "";
    }
  }
};
</script>
