var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "editCharacter",
        width: 430,
        height: "auto",
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [_c("h5", [_vm._v("Edit Character")])]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("editCharacter")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("ValidationObserver", { ref: "form" }, [
              _c("form", { staticClass: "modal-form organisation-form" }, [
                _c("div", { staticClass: "organisation-image-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "organisation-charcter-pic cursor",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.fileInput.click()
                        },
                      },
                    },
                    [
                      _vm.image
                        ? _c("img", {
                            attrs: { src: _vm.image, alt: "images" },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "organisation-image-remove" }, [
                    _c("input", {
                      ref: "fileInput",
                      staticStyle: { display: "none" },
                      attrs: { type: "file", accept: "image/*" },
                      on: { change: _vm.onFileSelected },
                    }),
                    !_vm.image
                      ? _c(
                          "a",
                          {
                            staticClass: "btn add",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput.click()
                              },
                            },
                          },
                          [_vm._v("Add Photo")]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "btn delete",
                            attrs: { href: "#" },
                            on: { click: _vm.uploadImageRemoved },
                          },
                          [_vm._v("Remove Photo")]
                        ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "first_name" },
                      },
                      [_vm._v("First Name")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "First Name", rules: "required|max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.first_name,
                                    expression: "first_name",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "first_name",
                                  id: "first_name",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.first_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.first_name = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "last_name" },
                      },
                      [_vm._v("Last Name")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "Last Name", rules: "required|max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.last_name,
                                    expression: "last_name",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "last_name",
                                  id: "last_name",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.last_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.last_name = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "row mb-4" }, [
                  _c("div", { staticClass: "form-group col" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "exampleInputEmail1" },
                      },
                      [_vm._v("Gender")]
                    ),
                    _c("div", { staticClass: "form-gender-dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn dropdown-toggle",
                          attrs: {
                            type: "button",
                            id: "dropdownMenuButton",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("uppercase")(_vm.gender, true)) +
                              " "
                          ),
                          _c("em", { staticClass: "icon-carrot-down" }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "dropdownMenuButton" },
                        },
                        _vm._l(_vm.gender_list, function (gen) {
                          return _c(
                            "a",
                            {
                              key: gen.id,
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.setGender(
                                    gen.attributes.name,
                                    gen.id
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("uppercase")(gen.attributes.name, true)
                                )
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group col" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "Age" } },
                        [_vm._v("Age")]
                      ),
                      _c("ValidationProvider", {
                        attrs: { name: "Age", rules: "required|numeric" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.age,
                                      expression: "age",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: [
                                    _vm.isActive && errors[0] ? "invalid" : "",
                                  ],
                                  attrs: {
                                    type: "age",
                                    id: "age",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.age },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.age = _vm._n($event.target.value)
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                errors[0]
                                  ? _c(
                                      "span",
                                      {
                                        class: [_vm.isActive ? "invalid" : ""],
                                        style: [
                                          _vm.isActive
                                            ? { display: "block" }
                                            : { display: "none" },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(errors[0].replace("The ", ""))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.hide("editCharacter")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        "data-toggle": "modal",
                        "data-target": "#addOrganisation",
                      },
                      on: { click: _vm.updateCharacter },
                    },
                    [_vm._v("Save")]
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }