<template>
  <div id="Organisation">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>Courses</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchCust"
                placeholder="Search"
                v-model="searchCust"
                v-debounce:400ms="searchCourse"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <a
            @click="$modal.show('createCourse',{customer_id : '' , tab: 'address'})"
            class="btn header-btn primary"
          >Add New Course</a>
        </div>
        <p v-if="totalCustomers > 1 && !searchCust">You have {{totalCustomers}} Courses in the system.</p>
        <p v-else-if="totalCustomers <= 1 && !searchCust">You have {{totalCustomers}} Course in the system.</p>
        <p v-else-if="totalCustomers > 1 && searchCust">You have {{totalCustomers}} Courses in the search result.</p>
        <p v-else>You have {{totalCustomers}} Course in the search result.</p>
      </header>
      <div class="data-content" v-if="coursesLists.length !== 0">
        <div class="data-table data-column-courses">
          <div class="data-head courses-data-head">
            <div class="data-col">
              Course Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn ===  'customer_courses.name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customer_courses.name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customer_courses.name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customer_courses.name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Section
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'customer_courses.sections_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customer_courses.sections_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customer_courses.sections_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customer_courses.sections_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Instructors
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'customer_instructors_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customer_instructors_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customer_instructors_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customer_instructors_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Modules
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'customer_courses.modules_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customer_courses.modules_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customer_courses.modules_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customer_courses.modules_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Total Learners
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'customer_courses. total_learners_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customer_courses. total_learners_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customer_courses. total_learners_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customer_courses. total_learners_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Completed Learners
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'customer_courses. total_learners_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customer_courses. total_learners_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customer_courses. total_learners_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customer_courses. total_learners_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
           <div class="data-col">
              Customer
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'customers.name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customers.name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customers.name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customers.name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">ACTIONS</div>
          </div>
          <div class="data-items">
            <div class="data-row courses-data-row" v-for="course in coursesLists" :key="course.id">
              <div class="data-col">
                <div class="data-image">
                  <div
                    class="data-text"
                  ><a @click="$modal.show('createCourse',{customer_id : course.id })">{{course.attributes.name}} [{{course.id}}]</a></div>
                </div>
              </div>
              <div class="data-col text-center">{{course.attributes.sections_count}}</div>
              <div class="data-col text-center">{{course.attributes.instructors_count}}</div>
              <div class="data-col text-center">{{course.attributes.modules_count}}</div>
              <div class="data-col text-center">{{course.attributes.total_learners_count}}</div>
              <div class="data-col text-center">{{course.attributes.completed_learners}}</div>
              <div class="data-col">{{course.attributes.customer_name}}</div>

              <div class="data-col data-action">
                <div class="data-dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn dropdown-toggle">
                <em class="icon-menu"></em></button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul>
                      <li><a @click="$modal.show('createCourse',{customer_id : course.id })">Edit</a></li>
                      <li><a @click="$modal.show('addLearnerToCourse', {course_data: course })">Add Learner</a></li>
                      <li><a class="dropdown-error" @click="deleteCourse(course.id)">Delete</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getCourses(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="coursesLists.length === 0 && !searchCust && display">
        <h3>There’s nothing here!</h3>
        <p>You have no customer in the system yet.</p>
        <div>
          <a
            @click="$modal.show('createCustomer',{customer_id : '' })"
            class="btn header-btn primary"
          >Add a new customer</a>
        </div>
      </div>
      <div class="data-no-result" v-if="coursesLists.length === 0 && searchCust">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <createCourse />
    <createCustomer />
    <addLearnerToCourse />
  </div>
</template>

<script>
import { commonConfig } from "@/utils/commonConfig";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import createCustomer from "../components/modal/customer.vue";
import createCourse from "../components/modal/course.vue";
import addLearnerToCourse from "../components/modal/addLearnerToCourse.vue";

export default {
  name: "Courses-component",
  components: {
    createCourse,
    createCustomer,
    addLearnerToCourse,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      searchRole: "",
      roles: [],
      page: 1,
      coursesLists: [],
      totalCustomers: "",
      totalPages: [],
      searchCust: "",
      sortOrder: "",
      sortColumn: "",
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      display : false
    };
  },
  methods: {
    show() {
      this.$modal.show();
    },
    hide(modal) {
      this.$modal.hide(modal);
      this.getCourses(this.page);
    },
    searchCourse(value) {
      this.display = false;
      api
        .getCourses(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.coursesLists = res.data.data;
          this.totalCustomers = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    getCourses(page) {
      utilFunctionService.showLoader();
      this.page = page;
      api
        .getCourses(
          page,
          this.searchCust,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.coursesLists = res.data.data;
          this.totalCustomers = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    toggleSort(colName, sortType) {
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getCourses(this.page);
    },
    deleteCourse(id) {
      utilFunctionService.showLoader();
      api.deleteCourse(id).then(() => {
        utilFunctionService.hideLoader();
        let page;
          if (this.coursesLists.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getCourses(page);
            } else {
              page = this.page - 1;
              this.getCourses(page);
            }
          } else {
            const page = this.page;
            this.getCourses(page);
          }
        utilFunctionService.showSuccess(commonConfig.appMessages.deleteCourse);
      })
      .catch(error => {
          utilFunctionService.showErr(
            error.response.data.errors
          );
          utilFunctionService.hideLoader();
      });
    },
    nextPage() {
      this.page = this.page + 1;
      this.getCourses(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getCourses(this.page);
    }
  },
  created() {
    this.getCourses(this.page);
  }
};
</script>
