var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      [
        _c("ValidationObserver", { ref: "subform" }, [
          _c("form", { staticClass: "w-100" }, [
            _c("div", { staticClass: "row custom-row mt-0" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "form-group customer-dropdown" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "exampleInputEmail1" },
                    },
                    [_vm._v("Select email to character")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchCharacter,
                        expression: "searchCharacter",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Search by name, role & organization",
                    },
                    domProps: { value: _vm.searchCharacter },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.searchCharacter = $event.target.value
                        },
                        _vm.filterCharacter,
                      ],
                    },
                  }),
                  _vm.error
                    ? _c(
                        "span",
                        {
                          class: [_vm.isActive ? "invalid" : ""],
                          style: [
                            _vm.isActive
                              ? { display: "block" }
                              : { display: "none" },
                          ],
                        },
                        [_vm._v("Select email to character is required")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "chip-container pt-2 pb-0 entity-chip" },
                    _vm._l(_vm.selectedChar, function (char, i) {
                      return _c("div", { key: char.id, staticClass: "chip" }, [
                        _c("span", [_vm._v(_vm._s(char.char_full_name))]),
                        _c("em", {
                          staticClass: "icon-close",
                          on: {
                            click: function ($event) {
                              return _vm.deleteSeletedChar(i)
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                  _vm.searchCharacter.length > 0 && _vm.charStatus
                    ? _c(
                        "div",
                        { staticClass: "user-row-scroll custom-user-dropdown" },
                        _vm._l(_vm.searchCharacters, function (character) {
                          return _c(
                            "div",
                            { key: character.id, staticClass: "user-row" },
                            [
                              _c("div", { staticClass: "img-container" }, [
                                _c("div", { staticClass: "img-box" }, [
                                  character.char_photo_url
                                    ? _c("img", {
                                        attrs: {
                                          src: character.char_photo_url,
                                          alt: "image",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "user-info" }, [
                                  _c("div", { staticClass: "head" }, [
                                    _vm._v(_vm._s(character.char_full_name)),
                                  ]),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(character.world_role) +
                                        " at " +
                                        _vm._s(character.org_name) +
                                        " "
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(character.char_age) +
                                        " , " +
                                        _vm._s(character.char_gender) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                              character.status
                                ? _c(
                                    "div",
                                    { staticClass: "dropdown-action" },
                                    [
                                      _c("em", { staticClass: "icon-check" }),
                                      _c("span", [_vm._v("Added")]),
                                    ]
                                  )
                                : _vm._e(),
                              !character.status
                                ? _c(
                                    "div",
                                    { staticClass: "dropdown-action" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "add-action",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addEmailChar(character)
                                            },
                                          },
                                        },
                                        [_vm._v("+")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "form-group customer-dropdown" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "exampleInputEmail1" },
                    },
                    [_vm._v("Select email cc character(s) (optional)")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchCcCharacter,
                        expression: "searchCcCharacter",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Search by name, role & organization",
                    },
                    domProps: { value: _vm.searchCcCharacter },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.searchCcCharacter = $event.target.value
                        },
                        _vm.filterCcCharacter,
                      ],
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "chip-container pt-2 pb-0 entity-chip" },
                    _vm._l(_vm.selectedCcChar, function (char, i) {
                      return _c("div", { key: char.id, staticClass: "chip" }, [
                        _c("span", [_vm._v(_vm._s(char.char_full_name))]),
                        _c("em", {
                          staticClass: "icon-close",
                          on: {
                            click: function ($event) {
                              return _vm.deleteSeletedCcChar(i)
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                  _vm.searchCcCharacter.length > 0 && _vm.optionalCharStatus
                    ? _c(
                        "div",
                        { staticClass: "user-row-scroll custom-user-dropdown" },
                        _vm._l(
                          _vm.searchOptionalCharacters,
                          function (character) {
                            return _c(
                              "div",
                              { key: character.id, staticClass: "user-row" },
                              [
                                _c("div", { staticClass: "img-container" }, [
                                  _c("div", { staticClass: "img-box" }, [
                                    character.char_photo_url
                                      ? _c("img", {
                                          attrs: {
                                            src: character.char_photo_url,
                                            alt: "image",
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _c("div", { staticClass: "user-info" }, [
                                    _c("div", { staticClass: "head" }, [
                                      _vm._v(_vm._s(character.char_full_name)),
                                    ]),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(character.world_role) +
                                          " at " +
                                          _vm._s(character.org_name) +
                                          " "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(character.char_age) +
                                          " , " +
                                          _vm._s(character.char_gender) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                character.status
                                  ? _c(
                                      "div",
                                      { staticClass: "dropdown-action" },
                                      [
                                        _c("em", { staticClass: "icon-check" }),
                                        _c("span", [_vm._v("Added")]),
                                      ]
                                    )
                                  : _vm._e(),
                                !character.status
                                  ? _c(
                                      "div",
                                      { staticClass: "dropdown-action" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "add-action",
                                            on: {
                                              click: function ($event) {
                                                return _vm.addEmailCcChar(
                                                  character
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("+")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }