<template>
  <div>
    <div class="learning-form-header flex-wrap">
      <h4>Interaction (External API)</h4>
      <div class="learning-quiz-btn">
          <a
            href="javascript:void(0);"
            @click="restore('published')"
            class="btn header-btn secondary"
            v-if="status == 'published'"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            @click="restore('published')"
            class="btn header-btn primary"
          >Publish LO</a>
          <a
            href="javascript:void(0);"
            @click="$modal.show('cloneConfirmationModal')"
            class="btn header-btn ml-2 secondary"
          >Clone LO</a>
      </div>
    </div>
    <!-- Quiz tabs start here -->
    <div class="learning-quiz-tabs">
      <div class="setup-container">
        <ul>
          <li @click="currentStep('basic-setup', 0)" :class="{ active: isActiveTab[0] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Basic Information</span>
            </a>
          </li>
          
          
          
          <li @click="currentStep('assmnt-block-setup', 1)" :class="{ active: isActiveTab[1] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Assessment Blocks</span>
            </a>
          </li>
          <li @click="currentStep('email-testing', 2)" :class="{ active: isActiveTab[2] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Testing</span>
            </a>
          </li>
          
          <li
            v-if="overallInteraction"
            :class="{ active: isActiveTab[3] }"
            @click="currentStep('overall-assessment', 3)"
          >
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Overall Assessment</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="learning-quiz-content pt-4">
      <div class="setup-content" v-if="step == 'basic-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Basic Information</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Interaction title</label>
                  <ValidationProvider
                    name="Interaction Title"
                    rules="required|max:100"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="name"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Administrative notes</label>
                  <ValidationProvider
                    name="Administrative Notes"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <textarea
                      type="Description"
                      class="form-control"
                      id="notes"
                      rows="4"
                      v-model="adminNotes"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group mb-2">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="userOverall">
                      <input
                        type="checkbox"
                        name="userOverall"
                        v-model="overallInteraction"
                        v-bind:value="'userOverall'"
                      />
                      <span for="userOverall" class="checkmark"></span>
                      Use overall interaction assessment
                    </label>
                  </div>
                </div>

                
                <div class="form-group pt-1 mb-3">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="hideFeedbackandAdaptive">
                      <input
                        type="checkbox"
                        name="hideFeedbackandAdaptive"
                        v-model="hideFeedbackandAdaptive"
                      />
                      <span for="hideFeedbackandAdaptive" class="checkmark"></span>
                      Hide learner feedback and adaptive content
                    </label>
                  </div>
                </div>
                
                
                
              </div>
              <div class="col-sm-7">
                 
                <div class="form-group">
                  <label>Select assessment scheme</label>
                  <ValidationProvider name="Assessment Scheme">
                    <div class="quiz-dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="entity"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :class="[(isActive && !assessmentListKey.id) ? 'invalid' : '']"
                      >
                        {{assessmentListKey.name}}
                        <em class="icon-carrot-down"></em>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="entity">
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          value="0"
                        >None</option>
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          :value="`${assessment.id}*${assessment.attributes.name}`"
                          v-for="assessment of assessmentLists"
                          :key="assessment.id"
                        >{{assessment.attributes.name}}</option>
                      </div>
                    </div>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="!assessmentListKey.id"
                    >Assessment scheme field is required</span>
                  </ValidationProvider>
                </div>
                
                <div class="form-group">
                  <label>Learner Description</label>
                  <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                   <vue-editor
                                :class="[(isActive && errors[0]) ? 'form-control invalid' : 'form-control']"
                                id="Description"
                                :editorToolbar="fullTool"
                                 placeholder="Add a Description"
                               v-model="description"
                            ></vue-editor>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-12 pt-5">
                <div class="d-flex justify-content-end mb-4">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="saveExternalAPILO('drafted', 1)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Basic Information end here -->
      
      
      
      
      
      <modal name="cloneConfirmationModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Are you sure you want to clone this learning object?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('cloneConfirmationModal')"
                class="btn medium default"
              >Cancel</button>
              <button type="button" @click="cloneLo(),$modal.hide('cloneConfirmationModal')" class="btn medium primary">Ok</button>
            </div>
          </div>
      </modal>


      <modal name="newAssessFormulaModal" height="auto" :width="700" :scrollable="true">
        <div class="modal-content modal-default modal-api-formula">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel" >
              <h5>API Formula</h5>
            </div>
          </div>
          <div class="modal-body">
            <form class="modal-form modal-min-height">
              <div class="modal-formula-content">
                <!-- External API form content goes here -->

                <div class="modal-api-formula-item">
                  <label>AWS SDK Service:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaApiName"
                  />
                </div>
                
                <div class="modal-api-formula-item">
                  <!--<label>API Endpoint:</label>-->
                  <label>AWS Service Method:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaApiEndpoint"
                  />
                </div>

                <div class="modal-api-formula-item">
                  <!--<label>API Endpoint:</label>-->
                  <label>AWS Region:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaApiRegion"
                  />
                </div>

                <div class="modal-api-formula-item">
                  <label>Query Params:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaQueryParams"
                  />
                </div>

                <div class="modal-api-formula-item">
                  <label>Results section to inspect:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaResultSection"
                  />
                </div>

                <div class="modal-api-formula-item">
                  <label>Results Include:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaIncludes"
                  />
                </div>

                <div class="modal-api-formula-item">
                  <label>Result less than:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaLessThan"
                  />
                </div>

                <div class="modal-api-formula-item">
                  <label>Result greater than:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="assmntFormulaGreaterThan"
                  />
                </div>

                <div class="modal-api-formula-item">
                  <label class="custom-checkbox" name="assmntFormulaAbsent">
                    Absent condition
                    <input
                      type="checkbox"
                      name="assmntFormulaAbsent"
                      v-model="assmntFormulaAbsent"
                      :id="assmntFormulaAbsent"
                      :checked="this.assmntFormulaAbsent"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                

              </div>
              
              <div class="modal-footer mt-4">
                <button
                  type="button"
                  @click="$modal.hide('newAssessFormulaModal')"
                  data-dismiss="modal"
                  class="btn default"
                >Cancel</button>
                <button
                  type="button"
                  class="btn primary"
                  @click="handleAddResFormula"
                  v-if="!isFormulaEdited"
                >Done</button>
                <button type="button" class="btn primary" @click="handleEditResFormula" v-else>Done</button>
              </div>
            </form>
          </div>
        </div>
      </modal>
      
      
      <!-- Assessment Skill setup start  -->
      <div class="setup-content" v-if="step === 'assmnt-block-setup' && !assessmentFormula">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Assessment Blocks</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <ul class="form-entity-tabs">
                    <li
                      v-for="(item, ind) in externalApiAssmntBlocks"
                      :key="item.id"
                      @click="selectedBlocks(item, ind)"
                      :class="item.blockId == selectedBlock.blockId ? 'active' :''"
                    >
                      <a>{{item.blockName}}</a>
                    </li>
                  </ul>
                </div>
                <div class="form-group pt-4">
                  <label>Add new assessment block</label>
                  <input
                    class="form-control"
                    id="admin_notes"
                    v-model="newAssmntBlock"
                    :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                  />
                </div>
                <div class="form-group">
                  <div class="form-btn-group">
                    <a
                      href="javascript:void(0);"
                      class="btn w-100 primary"
                      @click="addNewAssessBlock"
                    >Add new assessment block</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="skill-title my-4">
                <h5>{{this.selectedBlock.blockName}}</h5>
                    <a
                      href="javascript:void(0);"
                      class="email-delete-btn"
                      v-if="this.selectedBlock.id"
                      @click="$modal.show('deleteAssmntBlockConfirmationModal')"
                      ><em class="icon-delete"></em>
                      Delete skill
                    </a>
                  </div>
                <div class="form-group" v-if="this.selectedBlock.id">
                  <label>Block Description (Evaluation explanation)</label>
                  <ValidationProvider name="Administrative Notes" v-slot="{ errors }">
                    <textarea
                      class="form-control"
                      id="admin_notes"
                      rows="8"
                      v-model="blockDesc"
                      :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive && errors[0] ? 'invalid' : '']"
                      :style="[isActive && errors[0] ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group justify-content-end d-flex" v-if="this.selectedBlock.id">
                  <button
                    href="javascript:void(0);"
                    @click="updateExternalApiAssmntBlocks"
                    class="add-more-btn"
                  >Save</button>
                </div>
                <div class="form-group justify-content-end d-flex" v-if="this.selectedBlock.id">
                  <a
                    href="javascript:void(0);"
                    @click="goToAssessment"
                    class="add-more-btn"
                  >Configure assessments</a>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="updateExternalApiAssmntBlocks(),next('email-testing', 7)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Assessment Skill setup end  -->
      <!-- Testing start -->
      <div class="setup-content" v-if="step == 'email-testing'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Testing</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Access Key ID</label>
                  <ValidationProvider name="Test Access Key" rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      v-model="test_access_key"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Secret Access Key</label>
                  <ValidationProvider name="Test Secret Access Key" rules="required" v-slot="{ errors }">
                    <input
                      type="test"
                      class="form-control"
                      v-model="test_secret_access_key"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <div class="form-testing-group">
                    <a
                      href="javascript:void(0);"
                      class="btn default"
                      @click="clearTesting"
                    >Clear</a>
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="submitTesting"
                    >Submit</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-7" v-if="testEvalJsonScheme.attributes">
                <div
                  class="modal-test-mail"
                  v-if="testEvalJsonScheme.attributes.responses.length > 0"
                >
                  <h4>Character Response</h4>
                  <p
                    v-for="(scheme,index) in testEvalJsonScheme.attributes.responses"
                    :key="index"
                    v-html="scheme.attributes.response"
                  ></p>
                </div>
                <div class="modal-test-tabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="Test-tab"
                        data-toggle="tab"
                        href="#Test"
                        role="tab"
                        aria-controls="Test"
                        aria-selected="true"
                      >Test input Return Data</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="json-tab"
                        data-toggle="tab"
                        href="#json"
                        role="tab"
                        aria-controls="json"
                        aria-selected="false"
                      >View JSON</a>
                    </li>
                  </ul>
                  <div class="tab-content pt-2" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="Test"
                      role="tabpanel"
                      aria-labelledby="Test-tab"
                    >
                      <div class="test-items">
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.asst_entity_value_list.length">
                          <label>Assistant Entities Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.asst_entity_value_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.asst_intent_list.length">
                          <label>Assistant Intents Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.asst_intent_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.keyword_list.length">
                          <label>NLU Keywords Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.keyword_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.concept_list.length">
                          <label>NLU Concepts Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.concept_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                         <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.nlu_entities_list.length">
                          <label>NLU Entities Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.nlu_entities_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3">

                          <div class="test-chips-container">
                            <label>NLU Emotion Scores:</label>
                            <span class="test-chips">
                              JOY: {{testEmailEnrichmentItems.attributes.joy_score}},
                              ANGER: {{testEmailEnrichmentItems.attributes.anger_score}},
                              DISGUST: {{testEmailEnrichmentItems.attributes.disgust_score}},
                              SADNESS: {{testEmailEnrichmentItems.attributes.sadness_score}},
                              FEAR: {{testEmailEnrichmentItems.attributes.fear_score}}
                            </span>
                          </div>
                        </div>
                         <div class="mt-3">
                          <label>NLU Sentiment Score:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              Sentiment: {{testEmailEnrichmentItems.attributes.sentiment}},
                              Score: {{testEmailEnrichmentItems.attributes.sentiment_score}}
                            </span>
                          </div>
                        </div>

                      </div>
                      <div v-if="testEvalJsonScheme && testEvalJsonScheme.attributes && testEvalJsonScheme.attributes.response_formula_hits">
                        <label
                            class="text-label"
                          >Email Response hit:</label>
                        <div
                          class="test-items"
                          v-for="(formula,index) in testEvalJsonScheme.attributes.response_formula_hits"
                          :key="index"
                        >
                          <div class="test-chips-container">
                            <span
                              class="test-chips"
                            >{{formula.attributes.email_response.name}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="json" role="tabpanel" aria-labelledby="json-tab">
                      <div class="enrichment-concept-view p-0">
                        <div class="enrichment-concept-editor">
                          <div class="enrichment-concept-code">
                            <json-viewer :value="testEvalJsonScheme" :expand-depth="5"></json-viewer>
                          </div>
                          <div class="enrichment-code-copy">
                            <copy-to-clipboard
                              :text="JSON.stringify(testEvalJsonScheme)"
                              @copy="handleCopy"
                            >
                              <a href="javascript:void(0);" class="copy-icon">Copy</a>
                            </copy-to-clipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary btn-large"
                    @click="next('qa-and-interstitial', 8)"
                  >Next</a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Testing end -->
      
      <div class="setup-content" id="overall-assessment" v-if="step === 'overall-assessment'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Overall Assessment</h4>
        </div>
        <ValidationObserver ref="form" v-if="overallAssmntItem">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Overall Interaction Assessment</label>
                  <ul class="form-entity-tabs">
                    <li
                      v-for="assmntItem of overallAssmntItem"
                      :key="assmntItem.id"
                      :class="assmntItem.id == selectedOverallAssmntItem.id ? 'active' : ''"
                      @click="handleOverallAssmntItem(assmntItem)"
                    >
                      <a href="javascript:void(0);">{{assmntItem.attributes.assessment_label}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="question-content" id="number-range">
                  <div class="question-row">
                    <div class="form-group">
                      <label>Evaluation Text</label>
                      <ValidationProvider name="Evaluation Text" v-slot="{ errors }">
                        <vue-editor
                          id="Descriptiontext"
                          placeholder="Add a Description"
                          :editorToolbar="fullTool"
                          v-model="overallAssmntItemFeedback"
                        ></vue-editor>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group pt-2">
                      <label>Evaluation range:</label>
                      <ValidationProvider name="Evaluation range" v-slot="{ errors }">
                        <div class="row mt-2">
                          <div class="col-6">
                            <label>Minimum</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="overallAssmntItemMinScore"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                          </div>
                          <div class="col-6">
                            <label>Maximum</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="overallAssmntItemMaxScore"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                          </div>
                        </div>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 pt-5">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a class="btn primary" @click="restore('published')">Save & Publish</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div v-if="assessmentFormula">
      <div class="learning-form-header flex-wrap">
        <h4>
          <a @click="backToAssessment" class="back-btn-inner">
            <span class="icon-back"></span>
            Back to Assessment Blocks
          </a>
        </h4>
      </div>

      <!-- Details for assessment item - top level -->
      <div class="learning-quiz-content pt-4">
        <div class="setup-content">
          <ValidationObserver>
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group my-3">
                    <label>Block: {{selectedBlock.blockName}}</label>
                  </div>
                  <div class="form-group">
                    <ul class="form-entity-tabs form-check-tabs form-check-tabs2">
                      <li
                        v-for="skill
                              of externalApiAssessmentBlockItems"
                        :key="skill.id"
                        :class="skill.id == selectedAssmntItem.id ? 'active' : ''"
                        @click="selectAssessmentItem(skill)"
                      >
                        <a href="javascript:void(0);">
                          <div>
                            {{skill.attributes.assessment_label}}
                            <span
                              class="caption"
                            >{{skill.attributes.assessment_formulas.length}} API Formulas</span>
                          </div>
                          <span>
                            <em class="icon-check"></em>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>


                <div class="col-sm-7">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      :class="`nav-item nav-link flex-none active`"
                      id="response-tab"
                      data-toggle="tab"
                      href="#nav-response"
                      role="tab"
                      aria-controls="nav-response"
                      :aria-selected="true? 'false': 'true'"
                    >Debrief Content</a>
                    <a
                      class="nav-item nav-link"
                      id="adaptive-tab"
                      data-toggle="tab"
                      href="#nav-adaptive"
                      role="tab"
                      aria-controls="nav-adaptive"
                      aria-selected="false"
                    >Adaptive configuration</a>
                  </div>
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      :class="`tab-pane fade show active`"
                      id="nav-response"
                      role="tabpanel"
                      aria-labelledby="response-tab"
                    >
                      <div class="assessment-response">
                        <div
                          :class="['assessment-response', variations_briefattributes.length > 0 ? 'pt-2':'']"
                        >
                          <ul class="response-tab" v-if="variations_briefattributes.length > 0">
                            <li
                              v-for="(variations, key) in variations_briefattributes"
                              :key="key"
                              :class="variationsSelect === key+1 ? 'active' : ''"
                            >
                              <a
                                href="javascript:void(0);"
                                @click="addDebriefVariation(key+1,'debrief')"
                              >
                                Variation
                                {{variations_briefattributes.length > 1 ? key+1 : ''}}
                              </a>
                            </li>
                          </ul>
                          <div
                            class="response-content"
                            v-if="variations_briefattributes.length > 0"
                          >
                            <div class="form-group">
                              <div class="form-control p-0">
                                <ValidationProvider
                                  name="Variation"
                                  rules="required|max:500"
                                  v-slot="{ errors }"
                                >
                                 <vue-editor
                                    :class="['form-control',(isActive && errors[0]) ? 'invalid' : '']"
                                    :editorToolbar="fullTool"
                                    placeholder="Variation"
                                    v-model="variations_briefattributes[variationsSelect -1].content"
                                  ></vue-editor>
                                  <span
                                    :class="[isActive ? 'invalid' : '']"
                                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                    v-if="errors[0]"
                                  >{{ errors[0].replace("The ", "" )}}</span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="add-more-btn"
                            @click="variations_briefattributes.length < 5 ? addDebriefVariation(null,'debrief') : ''"
                          >+ Add Variation</a>
                          <a
                            v-if="variations_briefattributes.length > 0"
                            href="javascript:void(0);"
                            class="add-more-btn variation-save"
                            @click="saveDebriefVariations()"
                          >Save variation(s) & points</a>
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="variations_briefattributes.length <= 0"
                          >Add Variation field is required</span>
                        </div>
                        <div class="form-group mt-4">
                          <label>Points</label>
                          <ValidationProvider
                            name="Points"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              class="form-control"
                              v-model="assessmentPoints"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>

                        <div class="form-group mt-4">
                          <label>Assessment Code (optional)</label>
                          
                            <input
                              type="text"
                              class="form-control"
                              v-model="assessmentCode"
                              
                            />
                            
                          
                        </div>

                      </div>
                      <!-- formulas data start-->
                      <div class="formulas-data">
                        <!-- formulas edit head start -->
                        <div class="formulas-edit">
                          <div class="formulas-title" v-if="selApiAssmntItemFormula.length > 1 || selApiAssmntItemFormula == 0">API Formulas</div>
                          <div class="formulas-title" v-if="selApiAssmntItemFormula.length == 1">API Formula</div>
                          <a
                            href="javascript:void(0);"
                            @click="openAssmntFormulaModal"
                            class="add-more-btn"
                          >+ Add API Formula</a>
                        </div>
                        <!-- formulas edit head end -->
                        
                        <!-- formulas list start -->
                        <ul class="formulas-data-list" v-if="selApiAssmntItemFormula == 0">
                          <div class="formulas-left">No formulas yet</div>
                        </ul>
                        <ul class="formulas-data-list" v-if="selApiAssmntItemFormula.length > 0">
                          <li v-for="(res, i) of selApiAssmntItemFormula" :key="i">
                            <!-- formulas preset start  -->
                            <div class="formulas-list" >
                              <div class="formulas-list-title">
                                <div class="formulas-left">
                                  AWS SDK: {{res.api_name}}
                                  
                                </div>
                                <a
                                  href="javascript:void(0);"
                                  class="formulas-edit-btn"
                                  @click="editResponseFormula(res,  i)"
                                >
                                  <em class="icon-edit"></em>
                                  Edit
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  class="formulas-edit-btn"
                                  @click="setDestroyId(res, 'externalApiAssmntFormula');$modal.show('deleteResponseModal')"
                                >Delete</a>
                              </div>
                              <div class="formulas-list-item" v-if="res.present">
                                <div
                                  class="formula-item-flex"
                                  v-for="(formula, key, index) in res.present"
                                  :key="index"
                                >
                                  <div class="formula-name" v-if="formula">
                                    {{key}}:
                                    <span
                                      v-for="(entity) in formula"
                                      :key="entity.id"
                                    >{{entity.value}} ,</span>
                                  </div>
                                  <span
                                    class="formula-and"
                                    v-if="Object.keys(res.present).length -1 !== index"
                                  >and</span>
                                </div>
                              </div>
                            </div>
                            <!-- formulas preset end  -->
                           
                          </li>
                        </ul>
                        <!-- formulas list end -->
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="nav-adaptive"
                      role="tabpanel"
                      aria-labelledby="adaptive-tab"
                    >
                      <div class="d-flex align-items-center">
                        <h5 class="small-title">Add Adaptive Content</h5>
                        <div class="px-4 learning-quiz-btn">
                          <a
                            @click="$modal.show('uploadInterstitialModal',{create: true, content: 'adaptive'})"
                            class="btn ml-2 px-4 secondary"
                          >Upload</a>
                        </div>
                      </div>

                      <div
                        class="learning-data-list flex-column pt-3"
                        v-if="adaptiveContentList.length > 0"
                      >
                        <div
                          class="learning-data-item w-100 learning-interstitial-data-item"
                          v-for="(item, i) in adaptiveContentList"
                          :key="item.id"
                        >
                          <div class="learning-data-left">
                            <div
                              class="learning-data-richText"
                              v-if="item.attributes.content_details.type == 'text_learn_obj'"
                            ></div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'file_learn_obj'"
                            >
                              <a>
                                <img src="../../assets/images/pdf-big-icon.png" alt="pdf" />
                              </a>
                            </div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'slide_learn_obj'"
                            >
                              <a>
                                <img
                                  :src="item.attributes.content_details.attributes.slider_images[0].attributes.resource_url.thumbnail"
                                  alt="slide"
                                />
                              </a>
                            </div>
                            <div
                              class="learning-data-video"
                              v-if="item.attributes.content_details.type == 'video_learn_obj'"
                            >
                              <div
                                class="wistia_responsive_padding data-video"
                                style="padding:56.25% 0 0 0;position:relative;"
                              >
                                <div
                                  class="wistia_responsive_wrapper data-video"
                                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                                >
                                  <iframe
                                    :src="preIframUrl+item.attributes.content_details.attributes.global_video.attributes.wistia_code+postIframUrl"
                                    title="Video.mp4"
                                    class="wistia_embed"
                                    name="wistia_embed"
                                    allowtransparency="true"
                                    scrolling="no"
                                    allowfullscreen
                                    mozallowfullscreen
                                    webkitallowfullscreen
                                    oallowfullscreen
                                    msallowfullscreen
                                    width="100%"
                                    height="100%"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="learning-data-title">
                            <div class="data-heads">
                              <h4 v-html="item.attributes.title"></h4>
                              <span
                                v-if="item.attributes.content_details.type == 'file_learn_obj'"
                              >{{size(item.attributes.content_details.attributes.resource_size)}}</span>
                            </div>
                            <h4 v-html="item.attributes.content_details.attributes.title"></h4>
                            <div class="learning-data-paragraph">
                              <div>
                                <p v-html="item.attributes.description"></p>
                              </div>
                              <a
                                style="color: #3dbc9e"
                                @click="$modal.show('uploadInterstitialModal',{create: false, edit: item, content: 'adaptive'})"
                              >Edit</a>
                              <a @click="removeAdaptiveData(item.id, i)">Remove</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <a class="btn primary" @click="next('email-testing', 7)">Done</a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    
    <modal name="deleteResponseModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">Are you sure you want to delete?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteResponseModal')"
            class="btn medium default"
          >Cancel</button>
          
           
          <button
            v-if="responseFormulaId.name === 'externalApiAssmntFormula'"
            type="button"
            @click="deleteAssessmentResponseFormula(),$modal.hide('deleteResponseModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
    <modal name="deleteEmailRespModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
        >Are you sure you want to delete this response?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteEmailRespModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteResponse(),$modal.hide('deleteEmailRespModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
    
    <modal name="deleteAssmntBlockConfirmationModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
        >Are you sure you want to delete this skill?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteAssmntBlockConfirmationModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteAssmntBlock(),$modal.hide('deleteAssmntBlockConfirmationModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import CopyToClipboard from "vue-copy-to-clipboard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import "vue-slider-component/theme/antd.css";
import { VueEditor } from "vue2-editor";

export default {
  name: "ExternalAPI",
  props: [
    "selectedMenuItem",
    "assessmentLists",
    "externalApiInteractiveData",
    "id",
    "type"
  ],
  components: {
    ValidationProvider,
    ValidationObserver,
    CopyToClipboard,
    VueEditor
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
  data() {
    return {
      step: "basic-setup",
      isActive: false,
      overallInteraction: false,
      hideAssessmentLabel: false,
      hideFeedbackandAdaptive: false,
      name: "",
      adminNotes: "",
      editEmailloId: "",
      description: "",
      url: utilFunctionService.getImageUrl(),
      assessmentListKey: { name: "None", id: null },
      
      activeTab: "",
      message: "",
      index: 0,
      objectableId: "",
      isActiveTab: ["active"],
      value: "",
      activeEnrichmentTab: "concept",
      emailEntityName: "",
      
      activeState: "",
      loId: this.$route.query.id,
      emailResFormulas: null,
      selEmailResFormulas: null,
      emailSelChar: null,
      responseName: "",
      selVariationList: null,
      variationName: "",
      storeTab: "",
      setSelFormulaField: "nlu_concepts",
      selectedEvalVal: null,
      selIterationLevel: 1,
      selectedVariation: null,
      presentMinKeyword: 0,
      absentMinKeyword: 0,
      selApiAssmntItemFormula: null,

      assmntFormulaApiName: '',
      assmntFormulaApiEndpoint: '',
      assmntFormulaApiRegion: '',
      assmntFormulaQueryParams: '',
      assmntFormulaResultSection: '',
      assmntFormulaIncludes: '',
      assmntFormulaLessThan: '',
      assmntFormulaGreaterThan: '',
      assmntFormulaAbsent: false,
      
      overallAssmntItem: [],
      selectedOverallAssmntItem: [],
      assessmentSchemeId: "",
      overallAssmntItemFeedback: "",
      overallAssmntItemMaxScore: "",
      overallAssmntItemMinScore: "",
      overallAssessmentRange: "percentage",
      fullTool: [],
      interstitialLists: [],
      qaConditions: [],
      imagePreview: "",
      contentDescription: "",
      selDesPresentFormula: [],
      selDesAbsentFormula: [],
      isFormulaEdited: false,
      editFormulaIndex: null,
      selectedTabChar: null,
      allEmailResFormulas: null,
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      
      testInput: "",
      test_access_key: '',
      test_secret_access_key: '',
      skillLists: [],
      //globalSkills: [],
      emailSkills: [],
      externalApiAssmntBlocks: [],
      blockDesc: "",
      newAssmntBlock: "",
      blockId: "",
      selectedBlock: {},
      assessmentFormula: false,
      externalApiAssessmentBlockItems: [],
      selectedAssmntItem: "",
      selectBlock: "",
      adaptiveContentList: [],
      testEvalJsonScheme: "",
      testEmailEnrichmentItems: "",
      variations_briefattributes: [],
      variationsSelect: 1,
      assessmentPoints: 1,
      assessmentCode: '',
      testEvaulationScheme: [],
      emailQaResFormulas: [],
      emailSelCharId: "",
      responseFormulaId: "",
      status: 'drafted',
      updatedData: ""
    };
  },
  methods: {
    log({ relatedContext, draggedContext }) {

      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      let menuListData = [...this.emailResFormulas];
      const fb = new FormData();
      let draggedCardOrder = draggedElement.attributes.response_order;
      let relatedCardOrder = relatedElement.attributes.response_order;
      console.log(draggedCardOrder)
      console.log(relatedCardOrder)
      menuListData.map((data, index) => {
        if (parseInt(data.id) === parseInt(relatedElement.id)) {
          fb.append("external_api_learn_obj[email_responses_attributes][][id]", data.id);
          fb.append(
            "external_api_learn_obj[email_responses_attributes][][response_order]",
            draggedCardOrder
          );
          data.attributes.response_order = draggedCardOrder;
        } else if (parseInt(data.id) === parseInt(draggedElement.id)) {
          fb.append("external_api_learn_obj[email_responses_attributes][][id]", data.id);
          fb.append(
            "external_api_learn_obj[email_responses_attributes][][response_order]",
            relatedCardOrder
          );
          data.attributes.response_order = relatedCardOrder;
        } else {
          fb.append("external_api_learn_obj[email_responses_attributes][][id]", data.id);
          fb.append(
            "external_api_learn_obj[email_responses_attributes][][response_order]",
            index + 1
          );
          data.attributes.response_order = index + 1;
        }
        return data;
      });
      api
        .reorderResponses(this.objectableId, fb)
        .then(() => {})
        .catch();
    },
    cloneLo() {
      utilFunctionService.showLoader();
      api
        .cloneObj(this.$route.params.id, this.$route.query.id)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(
            commonConfig.appMessages.loCloned
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showErr(commonConfig.appMessages.cloneLoError);
        });
    },
    saveResponseName(){
      if (this.selEmailResFormulas){
        let fb = { email_response: {name: this.selEmailResFormulas.attributes.name}}
        api
          .addEmailVariation(this.selEmailResFormulas.id, fb)
          .then(res => {
            this.selEmailResFormula.attributes.name = res.data.data.attributes.name;
          })
          .catch(() => {});
      }
    },
    restore() {
      if (this.step === 'overall-assessment') {
          this.updateOverallAssessment();
      }
      const fb = new FormData();
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("learning_object[status]", "published");
      fb.append("id", this.$route.query.id);
      api
        .restoreLo(this.$route.params.id, this.$route.query.id, fb)
        .then(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        });

    },
    addDebriefVariation(isSelect) {
      if (!isSelect) {
        this.variations_briefattributes.push({ content: "" });
      } else {
        this.variationsSelect = isSelect;
      }
    },
    clearTesting() {
      this.test_access_key = "";
      this.test_secret_access_key = "";
      this.testEvalJsonScheme = "";
    },
    goToAssessment() {
      this.assessmentFormula = true;
      this.updateExternalApiAssmntBlocks();
    },
    backToAssessment() {
      this.assessmentFormula = false;
    },
    submitTesting() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("external_api_learn_obj_id", this.objectableId);
          fb.append("access_key_id", this.test_access_key);
          fb.append("secret_access_key", this.test_secret_access_key);
          api
            .externalApiTesting(fb)
            .then(res => {
              utilFunctionService.hideLoader();
              this.testEvalJsonScheme = res.data.data;
              utilFunctionService.showSuccess(
              commonConfig.appMessages.externalApiTestSuccess
            );
            })
            .catch(() => {
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    },
    adaptiveContents(data) {
      if (this.type === "external_api") {
        const fb = new FormData();
        fb.append("content_type", data.tab);
        fb.append("adaptable_type", "ExternalApiAssmntItem");
        fb.append("adaptable_id", this.selectedAssmntItem.id);
        fb.append("adaptive_content[title]", data.name);
        fb.append("adaptive_content[description]", data.desc);
        if (data.tab === "text") fb.append("content[title]", data.text);
        if (data.tab === "file")
          fb.append("content[global_resource_id]", data.doc.id);
        if (data.tab === "video")
          fb.append("content[global_video_id]", data.video.id);
        if (data.tab === "slide")
          fb.append("content[has_caption]", data.imageCaption);
        data.slider.map((x, i) => {
          fb.append(
            "content[slider_images_attributes][" + i + "][caption]",
            x.title
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][global_resource_id]",
            parseInt(x.sliderId)
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][_destroy]",
            data.edit == false ? false : x.destroy == true ? true : false
          );
          if (x.id != undefined) {
            fb.append("content[slider_images_attributes][" + i + "][id]", x.id);
          }
        });
        if (data.edit) {
          api.editQuizAdaptiveContent(fb, data.id).then(() => {
            this.blockSetUp(false);
          });
        } else {
          api.saveQuizAdaptiveContent(fb, this.objectableId).then(() => {
            this.blockSetUp(false);
          });
        }
      }
    },
    
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },

    next(name, key) {
      this.currentStep(name, key);
      this.step = name;
      if (name == "overall-assessment") {
        const fb = new FormData();
        fb.append("qa_condition[ooto_response]", this.qaResponse);
        api.postQaCondition(this.qaConditionId, fb).then(() => {});
      }
    },
    handleCopy() {
      utilFunctionService.showSuccess(commonConfig.appMessages.jsonCopied);
    },
    
    
    currentStep(e, key) {
      this.step = e;
      this.assessmentFormula = false;
      this.activeState = e;
      this.activeEmailModule = key;
      this.loId = this.$route.query.id;
      if (e === "basic-setup") {
        if (this.storeTab > this.activeEmailModule) {
          this.step = e;
          this.setActive(this.storeTab);
        } else {
          this.step = e;
          this.setActive(this.activeEmailModule);
        }
      } else if (e === "overall-assessment") {
        this.blockDesc = "";
        this.setActive(key);
        this.getOverAllAssessments(true);
      } else if (e === "email-testing") {
        this.testInput = "";
        this.setActive(key);
      } else if (e === "assmnt-block-setup") {
        this.setActive(key);
        this.getAssmntBlocks();
      }
    },
    
    
    getAssmntBlocks() {
      console.log("*** hit getAssmntBlocks ");
      api.getExternalApiAssmntBlocks(this.objectableId).then(res => {
        this.externalApiAssmntBlocks = res.data.data.map(x => ({
          id: x.id,
          blockName: x.attributes.name,
          blockId: x.id,
          desc: x.attributes.eval_explanation
        }));
        console.log(this.externalApiAssmntBlocks, "*** this.externalApiAssmntBlocks");
        console.log(res.data.data, "*** res.data.data");
        
        if (this.externalApiAssmntBlocks.length > 0) {
          this.selectedBlock = this.externalApiAssmntBlocks[0];
          this.blockDesc = this.selectedBlock.desc;
          this.blockId = this.selectedBlock.blockId;
          this.index = 0;
          this.step = "assmnt-block-setup";
          this.storeTab = 6;
          if (this.storeTab > this.activeEmailModule) {
            this.setActive(this.storeTab);
          } else {
            this.storeTab = 6;
            this.setActive(this.activeEmailModule);
          }
        }
      });
    },
    addNewAssessBlock() {
      if (!this.newAssmntBlock) {
        utilFunctionService.showErr(commonConfig.appMessages.assestSkillErr);
      } else {
        const fb = new FormData();
        fb.append("external_api_assmnt_block[name]", this.newAssmntBlock);
        fb.append("external_api_assmnt_block[external_api_learn_obj_id]", this.objectableId)
        api.postNewExternalApiAssmntBlocks(fb).then(res => {
          this.newAssmntBlock = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.newAssmntBlockAddedd
          );
          this.externalApiAssmntBlocks.push({
            blockId: res.data.data.id,
            blockName: res.data.data.attributes.name,
            eval_explanation: ""
          });
        });
      }
    },
    selectedBlocks(item, ind) {
      console.log('hii', item , ind, item.blockId)
      this.updateExternalApiAssmntBlocks();
      setTimeout(() => {
        this.blockDesc = item.desc;
        this.blockId = item.blockId;
        this.selectedBlock = item;
        this.index = ind;
      }, 400);
    },

    updateExternalApiAssmntBlocks() {
      let fb = new FormData();
      fb.append("external_api_assmnt_block[external_api_learn_obj_id]", this.objectableId);
      fb.append("external_api_assmnt_block[eval_explanation]", this.blockDesc);
      if (this.selectedBlock && this.selectedBlock.id) {
        api.updateExternalApiAssmntBlocks(fb, this.selectedBlock.id).then(res => {
          const i = this.externalApiAssmntBlocks.findIndex(
            x => x.blockId == res.data.data.id
          );
          this.externalApiAssmntBlocks[i].desc = res.data.data.attributes.eval_explanation;
          if (this.assessmentFormula) {
            this.selectBlock = res.data.data;
            this.blockSetUp(true);
          }
        });
      } else {
        api.postAssmntBlockDesc(fb, this.objectableId).then(res => {
          const ind = this.externalApiAssmntBlocks.findIndex(
            x => x.blockId == res.data.data.id
          );
          this.externalApiAssmntBlocks[ind].desc =
            res.data.data.attributes.eval_explanation;
          this.externalApiAssmntBlocks[ind].id = res.data.data.id;
          if (this.assessmentFormula) {
            this.selectBlock = res.data.data;
            this.blockSetUp(true);
          }
        });
      }
    },
    
    saveDebriefVariations() {
      let fb = new FormData();
      if (this.variations_briefattributes.length > 0) {
        this.variations_briefattributes.forEach((ele, i) => {
          if (ele.content) {
            fb.append(
              "external_api_assmnt_item[debriefs_attributes][][variation]",
              i + 1
            );
            fb.append(
              "external_api_assmnt_item[debriefs_attributes][][content]",
              ele.content
            );
            fb.append(
              "external_api_assmnt_item[debriefs_attributes][][_destroy]",
              false
            );
            if (ele.id) {
              fb.append(
                "external_api_assmnt_item[debriefs_attributes][][id]",
                ele.id
              );
            }
          }
        });
      }
      fb.append(
        "external_api_assmnt_item[points]", this.assessmentPoints
      );
      fb.append(
        "external_api_assmnt_item[assessment_code]", this.assessmentCode
      );
      api.updateExternalApiAssessmentVariation(this.selectedBlock.id, this.selectedAssmntItem.id, fb).then(() => {
      });
    },
    selectAssessmentItem(data) {

        this.blockSetUp(false);
        this.externalApiItem(data);

    },
    externalApiItem(data) {
      console.log("*** hit externalApiItem");
      this.variations_briefattributes = [];
      this.selectedAssmntItem = data;
      this.assessmentPoints = data.attributes.points;
      this.assessmentCode = data.attributes.assessment_code;
      this.adaptiveContentList = data.attributes.adaptive_contents;
      this.setAssessmentFormula(data.attributes.assessment_formulas);
      if (data && data.attributes.debrief_contents.length > 0) {
        data.attributes.debrief_contents.forEach(ele => {
          this.variations_briefattributes.push({
            content: ele.attributes.content,
            id: ele.id
          });
        });
      }
    },
    compareObjects(a, b) {
      if (
        a.attributes.assessment_label_order <
        b.attributes.assessment_label_order
      ) {
        return -1;
      }
      if (
        a.attributes.assessment_label_order >
        b.attributes.assessment_label_order
      ) {
        return 1;
      }
      return 0;
    },

    blockSetUp(updated) {
      console.log("*** hit blockSetUp");
      console.log(updated, "*** updated in blockSetup");
      api.getExternalApiAssessItemsBlock(this.selectedBlock.id).then(res => {
        this.externalApiAssessmentBlockItems = res.data.data.sort(this.compareObjects);
        console.log(this.externalApiAssessmentBlockItems, "*** this.externalApiAssessmentBlockItems in blockSetup");
        this.step = "assmnt-block-setup";
        if (updated) {
          this.selectedAssmntItem = this.externalApiAssessmentBlockItems[0];
          this.externalApiItem(this.externalApiAssessmentBlockItems[0]);

          if (this.externalApiAssessmentBlockItems[0] != undefined) {
            this.adaptiveContentList = this.externalApiAssessmentBlockItems[0].attributes.adaptive_contents;
            this.setAssessmentFormula(
              this.externalApiAssessmentBlockItems[0].attributes.assessment_formulas
            );
          }
        } else {
          let filterData = this.externalApiAssessmentBlockItems.filter(
            x => x.id == this.selectedAssmntItem.id
          );
          this.adaptiveContentList = filterData[0].attributes.adaptive_contents;
          this.setAssessmentFormula(
            filterData[0].attributes.assessment_formulas
          );
        }
      });
    },
    removeAdaptiveData(id) {
      utilFunctionService.showLoader();
      api.deleteAdaptiveContent(id).then(() => {
        utilFunctionService.hideLoader();
        this.blockSetUp(false);
      });
    },
    
    getOverAllAssessments(isUpdated = true) {
      api
        .getOverallAssmntItem(this.$route.query.id)
        .then(res => {
          this.overallAssmntItem = res.data.data.sort(this.sortOrder);
          if (this.overallAssmntItem.length === 0) {
            this.assessment_scheme_id &&
              api
                .getAssessmentScheme(this.assessmentSchemeId)
                .then(res => {
                  this.generateOverAllAssessment(
                    res.data.data.attributes.assessment_labels
                  );
                  api
                    .getOverallAssmntItem(this.$route.query.id)
                    .then(res2 => {
                      this.overallAssmntItem = res2.data.data.sort(
                        this.sortOrder
                      );
                      this.selectedOverallAssmntItem = this.overallAssmntItem[0];
                      this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
                      this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
                      this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
                    })
                    .catch(() => {
                      utilFunctionService.hideLoader();
                    });
                })
                .catch(() => {
                  utilFunctionService.hideLoader();
                });
          } else {
            if (isUpdated) {
              this.selectedOverallAssmntItem = this.overallAssmntItem[0];
              this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
              this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
              this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
            }
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    sortOrder(a, b) {
      if (a > b) return 1;
      if (b > a) return -1;

      return 0;
    },
    handleOverallAssmntItem(data) {
      this.updateOverallAssessment();
      setTimeout(() => {
        this.selectedOverallAssmntItem = data;
        this.overallAssmntItemFeedback = this.selectedOverallAssmntItem.attributes.feedback;
        this.overallAssmntItemMaxScore = this.selectedOverallAssmntItem.attributes.max_score;
        this.overallAssmntItemMinScore = this.selectedOverallAssmntItem.attributes.min_score;
      }, 200);
    },
    updateOverallAssessment() {
      this.$refs.form.validate().then(success => {
        if (
          !this.overallAssmntItemFeedback &&
          !this.overallAssmntItemMaxScore &&
          !this.overallAssmntItemMaxScore
        ) {
          this.isActive = true;
          return;
        }
        if (!success) {
          this.isActive = true;
          return;
        } else {
          let fb = new FormData();
          fb.append(
            "overall_assmnt_item[feedback]",
            this.overallAssmntItemFeedback
          );
          fb.append(
            "overall_assmnt_item[max_score]",
            this.overallAssmntItemMaxScore
          );
          fb.append(
            "overall_assmnt_item[min_score]",
            this.overallAssmntItemMinScore
          );
          api
            .updateOverallAssesScheme(this.selectedOverallAssmntItem.id, fb)
            .then(() => {
              this.getOverAllAssessments(false);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.feedbackUpdateErr
              );
            });
        }
      });
    },
    generateOverAllAssessment(data) {
      data.forEach(ele => {
        let fb = new FormData();
        fb.append("overall_assmnt_item[assessment_label_id]", ele.id);
        fb.append("overall_assmnt_item[feedback]", "");
        fb.append("overall_assmnt_item[max_score]", 0);
        fb.append("overall_assmnt_item[min_score]", 0);
        api
          .createOverallAssesScheme(this.$route.query.id, fb)
          .then(() => {})
          .catch(() => {
            utilFunctionService.showErr();
          });
      });
    },
    setActive(key) {
      this.isActiveTab = [];
      for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
    },
    
    sortByOrder(a, b) {
      if (a.attributes.order_num < b.attributes.order_num) {
        return -1;
      }
      if (a.attributes.order_num > b.attributes.order_num) {
        return 1;
      }
      return 0;
    },
    basicSetupUpdate() {
      if (!this.$route.query.id) {
        console.log("*** hit basicSetupUpdate in ExternalAPI.vue");
        console.log(this, "*** this in basicSetupUpdate");
        this.clearForm();
      } else {
        this.setActive(0);
        this.step = "basic-setup";
        this.setFieldValue();
      }
    },

    
    setFieldValue() {
      if (this.externalApiInteractiveData.id) {
        console.log(this.externalApiInteractiveData, "*** this.externalApiInteractiveData ");
        this.isActiveTab = [];
        this.status = this.externalApiInteractiveData.attributes.status;
        this.editEmailloId = this.externalApiInteractiveData.id;
        this.objectableId = this.externalApiInteractiveData.attributes.objectable_id;
        this.name = this.externalApiInteractiveData.attributes.name;
        this.adminNotes = this.externalApiInteractiveData.attributes.admin_notes;
        this.description = this.externalApiInteractiveData.attributes.description;
        this.hideAssessmentLabel = this.externalApiInteractiveData.attributes.card_detail.attributes.hide_assessment_label;
        this.hideFeedbackandAdaptive = this.externalApiInteractiveData.attributes.card_detail.attributes.hide_feedback;
        console.log(this.externalApiInteractiveData.attributes.card_detail.attributes.hide_assessment_label, "*** this.externalApiInteractiveData.attributes.card_detail.attributes.hide_assessment_label in ExternalAPI.vue setFieldValue method");
        this.overallInteraction = this.externalApiInteractiveData.attributes.overall_assessment_required;
        console.log(this.externalApiInteractiveData.attributes.overall_assessment_required, "*** this.externalApiInteractiveData.attributes.overall_assessment_required in ExternalAPI.vue setFieldValue method");
        this.assessmentSchemeId = this.externalApiInteractiveData.attributes.assessment_scheme_id;
        this.getSelectedAssessment(
          this.externalApiInteractiveData.attributes.assessment_scheme_id
        );
        
        
        this.selApiAssmntItemFormula = null;
        this.selEmailResFormulas = null;
        this.selVariationList = null;
        this.allEmailResFormulas = [];
        this.emailResFormulas = [];
        setTimeout(() => {
          // if (this.asstEntitiesList.length > 0)
          this.getAssmntBlocks();
        }, 1000);
        
      }
    },
    selectedAssessment(event) {
      let value = event.target.value.split("*");
      this.assessmentListKey = { name: value[1], id: value[0] };
    },
    saveExternalAPILO(type, key) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        console.log(this.overallInteraction, "*** this.overallInteraction in ExternalAPI.vue line 4685 in saveExternalAPILO method");
        console.log(this.id, "*** this.id boo yah in saveExternalAPILO");
        console.log(this, "*** this ya");
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("learning_object[status]", type);
          fb.append("learning_object[description]", this.description);
          fb.append(
            "learning_object[overall_assessment_required]",
            this.overallInteraction
          );
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[admin_notes]", this.adminNotes);
          fb.append("card_type", "external_api");
          fb.append("card[hide_assessment_label]", this.hideAssessmentLabel);
          fb.append("learning_object[learning_object_type]", "interaction");
          fb.append(
            "learning_object[assessment_scheme_id]",
            this.assessmentListKey.id
          );
          fb.append("card[hide_feedback]", this.hideFeedbackandAdaptive);
          
          if (this.$route.query.id) {
            api
              .updateLo(this.id, this.editEmailloId, fb)
              .then(res => {
                this.loId = res.data.data.id;
                utilFunctionService.hideLoader();
                this.objectableId = res.data.data.attributes.objectable_id;
                this.activeEmailModule = key;
                this.qaConditions =
                  res.data.data.attributes.card_detail.attributes.qa_conditions;
                this.assessmentSchemeId =
                  res.data.data.attributes.assessment_scheme_id;
                
                this.trainingInputs();
                if (
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids &&
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids.length > 0
                ) {
                  this.getCharacterData(
                    res.data.data.attributes.card_detail.attributes
                      .to_characters
                  );
                }
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showErr("Internal Server Error");
              });
          } else {
            api
              .createLearningObject(this.id, fb)
              .then(res => {
                utilFunctionService.hideLoader();
                this.storeTab = 1;
                this.loId = res.data.data.id;
                this.qaConditions =
                  res.data.data.attributes.card_detail.attributes.qa_conditions;
                this.$route.query.id = this.loId;
                this.objectableId = res.data.data.attributes.objectable_id;
                this.assessmentSchemeId =
                  res.data.data.attributes.assessment_scheme_id;
                this.activeEmailModule = key;
                
                this.step = "nlu-training";
                this.trainingInputs();
                api.createOverAllAssessment(this.objectableId).then(() => {});
                if (
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids &&
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids.length > 0
                ) {
                  this.getCharacterData(
                    res.data.data.attributes.card_detail.attributes
                      .to_characters
                  );
                }
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                this.$emit("updateSubmissionList", false);
              });
          }
        }
      });
    },
    getSelectedAssessment(id) {
      if (this.assessmentLists) {
        this.assessmentLists.forEach(e => {
          if (id == e.id) {
            this.assessmentListKey = { name: e.attributes.name, id: e.id };
          }
        });
      }
    },
    
    sortVariation(a, b) {
      if (a.attributes.variation < b.attributes.variation) {
        return -1;
      }
      if (a.attributes.variation > b.attributes.variation) {
        return 1;
      }
      return 0;
    },
    setAssessmentFormula(data) {
      this.selApiAssmntItemFormula = [];
      console.log(data, "*** data in setAssessmentFormula");
      data.forEach((e, index) => {
        if (!this.selApiAssmntItemFormula[index])
          this.selApiAssmntItemFormula[index] = {};
        this.selApiAssmntItemFormula[index]["external_api_assmnt_item_formula_id"] = e.id;
        this.selApiAssmntItemFormula[index]["api_name"] =
          e.attributes.api_name;
        this.selApiAssmntItemFormula[index]["api_endpoint"] =
          e.attributes.api_endpoint;
        this.selApiAssmntItemFormula[index]["query_params"] =
          e.attributes.query_params;
        this.selApiAssmntItemFormula[index]["result_section"] =
          e.attributes.result_section;
          this.selApiAssmntItemFormula[index]["result_includes"] =
          e.attributes.result_includes;
          this.selApiAssmntItemFormula[index]["result_less_than"] =
          e.attributes.result_less_than;
          this.selApiAssmntItemFormula[index]["result_greater_than"] =
          e.attributes.result_greater_than;
          this.selApiAssmntItemFormula[index]["conditions_absent"] =
          e.attributes.conditions_absent;
        
      });
    },
    setFormFieldValues(data, index, name, key, status = false) {
      if (status) {
        if (!this.selApiAssmntItemFormula[index]["present"][name])
          this.selApiAssmntItemFormula[index]["present"][name] = [];
        this.selApiAssmntItemFormula[index]["present"][name][0] = {
          id: 0,
          name: name,
          value: data ? "enabled" : "disabled",
          isAdded: true,
          data
        };
      } else {
        data.forEach(ele => {
          if (!ele.attributes.present_cond) {
            if (!this.selApiAssmntItemFormula[index]["absent"][name])
              this.selApiAssmntItemFormula[index]["absent"][name] = [];
            this.selApiAssmntItemFormula[index]["absent"][name].push({
              id: ele.id,
              name: key,
              value: ele.attributes[key],
              isAdded: true,
              data
            });
          } else {
            if (!this.selApiAssmntItemFormula[index]["present"][name])
              this.selApiAssmntItemFormula[index]["present"][name] = [];
            this.selApiAssmntItemFormula[index]["present"][name].push({
              id: ele.id,
              name: key,
              value: ele.attributes[key],
              isAdded: true,
              data
            });
          }
        });
      }
    },

     
    addVariation() {},

    clearForm() {
      this.status = 'drafted';
      this.name = "";
      this.adminNotes = "";
      this.description = "";
      this.hideAssessmentLabel = false;
      this.hideFeedbackandAdaptive = false;
      this.overallInteraction = false;
      this.learnerExplanation =
        "This email interaction provided multiple opportunities to achieve the highest overall assessment. This number indicates how many iterations you went through on this interaction.";
      this.step = "basic-setup";
      this.isActive = false;
      this.assessmentListKey = { name: "None", id: null };
      
      this.activeTab = "";
      this.message = "";
      this.index = 0;
      this.objectableId = "";
      this.isActiveTab = ["active"];
      this.value = "";
      this.activeState = "";
      this.activeKey = "";
      this.blockDesc = "";
      console.log(this, "*** this in clearForm");
      this.setActive(0);
    },
    handleResFormula(field) {
      this.setSelFormulaField = field;
      
    },
    handleEvaluationDrop(data, status) {
      if (status === "present") {
        let selectedPresentFormula = JSON.parse(
            JSON.stringify(this.selectedPresentFormula)
          ),
          type = data.type.split("_"),
          index;
        if (!selectedPresentFormula[type[1]])
          selectedPresentFormula[type[1]] = [];
        index = this.getAllIndexes(selectedPresentFormula[type[1]], data.id);
        if (index.length === 0) {
          this.selectedPresentFormula = {};
          if (data.type === "nlu_Assistant-Entity") {
            selectedPresentFormula[type[1]].push({
              id: data.id,
              name: `${type[1]}`,
              value: data.attributes.value,
              asst_entity_id: data.asst_entity_id
            });
          } else {
            selectedPresentFormula[type[1]].push({
              id: data.id,
              name: type[1],
              value: data.attributes[type[1]] || data.attributes.name
            });
          }
        }
        this.selectedPresentFormula = selectedPresentFormula;
      }
      if (status === "absent") {
        let selectedAbsentFormula = JSON.parse(
            JSON.stringify(this.selectedAbsentFormula)
          ),
          type = data.type.split("_"),
          index;
        if (!selectedAbsentFormula[type[1]])
          selectedAbsentFormula[type[1]] = [];
        index = this.getAllIndexes(selectedAbsentFormula[type[1]], data.id);
        if (index.length === 0) {
          this.selectedAbsentFormula = {};
          if (data.type === "nlu_Assistant-Entity") {
            selectedAbsentFormula[type[1]].push({
              id: data.id,
              name: `${type[1]}`,
              value: data.attributes.value,
              asst_entity_id: data.asst_entity_id
            });
          } else {
            selectedAbsentFormula[type[1]].push({
              id: data.id,
              name: type[1],
              value: data.attributes[type[1]] || data.attributes.name
            });
          }
        }
        this.selectedAbsentFormula = selectedAbsentFormula;
      }
    },
    getAllIndexes(arr, val, status = true) {
      let index = [];
      arr.forEach((e, i) => {
        if (status && e.id === val) index.push(i);
        if (!status && e.asst_entity_id === val) index.push(i);
      });
      return index;
    },
    handleSelectedIteration(level) {
      this.selIterationLevel = level;
      this.getselVariationList(
        this.selEmailResFormulas.attributes.char_response_variations
      );
       if (this.selVariationList){
        this.selectedVariation = this.selVariationList[0];
        this.variationName = this.selectedVariation.attributes.response;
      } else{
        this.variationName = "";
      }
    },
    handleAddVariation() {
      if (
        !this.emailResFormulas ||
        (this.emailResFormulas && this.emailResFormulas.length === 0)
      )
        utilFunctionService.showErr(commonConfig.appMessages.addResFormula);
      let fb = {
        email_response: {
          name: "",
          character_id: null,
          char_response_variations_attributes: [
            {
              iteration: 1,
              variation: 1,
              response: null
            }
          ]
        }
      };
      fb.email_response.name = this.selEmailResFormulas.attributes.name;
      fb.email_response.char_response_variations_attributes[0].iteration = this.selIterationLevel;
      fb.email_response.char_response_variations_attributes[0].variation =
        this.selVariationList.length + 1;
      fb.email_response.char_response_variations_attributes[0].response = this.variationName;
      fb.email_response.character_id = this.emailSelChar.id;
      if (this.selVariationList.length - 1 < 4)
        this.variationName &&
          api
            .addEmailVariation(this.selEmailResFormulas.id, fb)
            .then(() => {
              this.variationName = "";
              this.getEmailResList(false);
            })
            .catch(() => {
              if (
                !this.emailResFormulas ||
                (this.emailResFormulas && this.emailResFormulas.length === 0)
              )
                utilFunctionService.showErr(
                  commonConfig.appMessages.addResFormula
                );
            });
      else
        utilFunctionService.showErr(commonConfig.appMessages.invalidVariation);
    },
    getselVariationList(data) {
      this.selVariationList = [];
      data.forEach(ele => {
        if (ele.attributes.iteration === this.selIterationLevel)
          this.selVariationList.push(ele);
      });
    },
    getSelectedVariation(index) {
      if (this.selectedVariation) {
        this.updateVariation(this.selectedVariation);
      }
      setTimeout(() => {
        this.selectedVariation = this.selVariationList[index];
        this.variationName = this.selectedVariation.attributes.response;
      }, 1000);
    },
    updateVariationOnNext(data) {
      let fb = {
        email_response: {
          name: this.selEmailResFormulas.attributes.name,
          character_id: this.emailSelChar.id,
          char_response_variations_attributes: [
            {
              iteration: data.iteration,
              variation: data.variation,
              response: this.variationName,
              id: data.id
            }
          ]
        }
      };
      // this.variationName &&
        api.addEmailVariation(this.selEmailResFormulas.id, fb).then(() => {
        });
    },
    updateVariation(data) {
      let fb = {
        email_response: {
          name: this.selEmailResFormulas.attributes.name,
          character_id: this.emailSelChar.id,
          char_response_variations_attributes: [
            {
              iteration: data.iteration,
              variation: data.variation,
              response: this.variationName,
              id: data.id
            }
          ]
        }
      };
      // this.variationName &&
        api.addEmailVariation(this.selEmailResFormulas.id, fb).then(() => {
          this.getEmailResList(false);
        });
    },
    handleLabelEvalChange(e, data) {
      let selectedPresentFormula = JSON.parse(
        JSON.stringify(this.selectedPresentFormula)
      );
      if (!selectedPresentFormula["Assistant-Entity"])
        selectedPresentFormula["Assistant-Entity"] = [];
      let evalVal = this.getAllIndexes(
        selectedPresentFormula["Assistant-Entity"],
        data.id,
        false
      );
      if (evalVal.length !== 0) {
        for (let i = evalVal.length - 1; i >= 0; i--)
          selectedPresentFormula["Assistant-Entity"].splice(evalVal[i], 1);
      }
      if (e.target.checked) {
        this.selectedPresentFormula = {};
        selectedPresentFormula["Assistant-Entity"].push({
          id: data.id,
          name: `Assistant-Entity`,
          value: data.attributes.name,
          asst_entity_id: data.id,
          asst_entity_values: data.attributes.asst_entity_values,
          isAll: true
        });
      }
      this.selectedPresentFormula = selectedPresentFormula;
      if (this.selectedPresentFormula["Assistant-Entity"].length === 0)
        delete this.selectedPresentFormula["Assistant-Entity"];
    },
    handleAddResFormula() {
      
      let externalApiAssmntFormula = {
        external_api_assmnt_item_formula: {
          external_api_assmnt_item_id: this.selectedAssmntItem.id,
          api_name: this.assmntFormulaApiName,
          api_endpoint: this.assmntFormulaApiEndpoint, 
          api_region: this.assmntFormulaApiRegion,
          query_params: this.assmntFormulaQueryParams, 
          result_section: this.assmntFormulaResultSection,
          result_includes: this.assmntFormulaIncludes, 
          result_less_than: this.assmntFormulaLessThan, 
          result_greater_than: this.assmntFormulaGreaterThan, 
          conditions_absent: this.assmntFormulaAbsent
        }
      };

      
      api
        .postExternalApiAssmntFormula(
          this.selectedBlock.id,
          this.selectedAssmntItem.id,
          externalApiAssmntFormula
        )
        .then(() => {
          this.$modal.hide("newAssessFormulaModal");
          this.blockSetUp(false);
        });
      
    },
    setDestroyId(res, formulaName) {
      this.responseFormulaId = { id: res.ques_id, name: formulaName };
    },
    deleteResponseFormula() {
      utilFunctionService.showLoader();
      api.destroyResFormula(this.responseFormulaId.id).then(() => {
        utilFunctionService.hideLoader();
        this.getEmailResList(false);
      });
    },
    
     deleteAssmntBlock() {
      utilFunctionService.showLoader();
      api.deleteExternalApiAssmntBlock(this.selectedBlock.id).then(() => {
        utilFunctionService.hideLoader();
        this.getAssmntBlocks();
      });
    },

    deleteAssessmentResponseFormula() {
      utilFunctionService.showLoader();
      api.destroyExternalApiAssmntFormula(this.selectedBlock.id, this.selectedAssmntItem.id, this.responseFormulaId.id).then(() => {
        utilFunctionService.hideLoader();
        this.blockSetUp(false);
      });
    },
    getFormData(objKey, fb, formula, formulaKey, key) {
      formula &&
        formula.forEach(e => {
          if (e.isAll) {
            if (!fb[formulaKey]) fb[formulaKey] = [];
            !e.isAdded &&
              e.asst_entity_values.forEach(val => {
                fb[formulaKey].push({
                  [key]: val.id,
                  present_cond: objKey
                });
              });
          } else if (e.name === "emotion" && !formula[0].data) {
            if (e.value === "enabled" && !this.disabledEmotion) {
              if (!fb[formulaKey]) fb[formulaKey] = [];
              Object.keys(this.selRangeEmotion).forEach((e, i) => {
                if (
                  this.selRangeEmotion[e] === "lt" ||
                  this.selRangeEmotion[e] === "gt"
                )
                  !e.isAdded &&
                    fb[formulaKey].push({
                      emotion: e,
                      comparator: this.selRangeEmotion[e],
                      score: this.emotionData[e],
                      present_cond: true
                    });
                else
                  !e.isAdded &&
                    fb[formulaKey].push({
                      emotion: e,
                      range_value: this.selRange[e],
                      score: this.emotionData[e],
                      present_cond: true
                    });
                if (e.isAdded) fb[formulaKey][i]["id"] = e.id;
              });
            }
          } else if (e.name === "sentiment" && !formula[0].data) {
            if (e.value === "enabled" && !this.disabledSentiment) {
              if (!fb[formulaKey]) fb[formulaKey] = [];
              if (
                this.sentimentData.sentiment === "lt" ||
                this.sentimentData.sentiment === "gt"
              )
                !e.isAdded &&
                  fb[formulaKey].push({
                    sentiment: this.sentimentData.value,
                    comparator: this.sentimentData.sentiment,
                    score: this.sentimentData.score,
                    present_cond: true
                  });
              else
                !e.isAdded &&
                  fb[formulaKey].push({
                    sentiment: this.sentimentData.value,
                    range_value: this.sentimentData.range,
                    score: this.sentimentData.score,
                    present_cond: true
                  });
            }
          } else {
            if (!fb[formulaKey]) fb[formulaKey] = [];
            !e.isAdded &&
              fb[formulaKey].push({
                [key]: e.id,
                present_cond: objKey
              });
          }
        });
    },
    openAssmntFormulaModal() {
      
        this.openFormulaModal();
      
    },
    openFormulaModal() {
      this.emptyAssmntFormulaModal();
      this.activeEnrichmentTab = "concept";
      this.$modal.show("newAssessFormulaModal");
    },

    editResponseFormula(data, index) {
      this.emptyAssmntFormulaModal();
      this.activeEnrichmentTab = "concept";
      this.isFormulaEdited = true;
      this.selDesPresentFormula = [];
      this.selDesAbsentFormula = [];
      this.selectedPresentFormula = data.present;
      this.selectedAbsentFormula = data.absent;
      this.absentMinKeyword = data.absent_min;
      this.presentMinKeyword = data.present_min;
      this.editFormulaIndex = index;
      
      
      this.$modal.show("newAssessFormulaModal");
    },

    emptyAssmntFormulaModal() {
      // Clear the API formula modal
      this.presentMinKeyword = 0;
      this.absentMinKeyword = 0;
      this.selectedPresentFormula = {};
      this.selectedAbsentFormula = {};
      this.activeEnrichmentTab = null;
      //this.disabledEmotion = false;
      //this.disabledSentiment = false;
      this.isFormulaEdited = false;
    },

    handleRangeChange(e) {
      let rangeKey = e.target.id.split("_");
      if (parseFloat(e.target.value) > 0 && parseFloat(e.target.value) < 1) {
        this.emotionData[rangeKey[0]] = parseFloat(e.target.value);
      }
    },
    handleButtonRangeChange(e, key, status) {
      e.preventDefault();
      let rangeKey = key.split("_");
      if (status) {
        this.selRange[rangeKey[0]] = null;
        if (rangeKey[1] === "below") this.selRangeEmotion[rangeKey[0]] = "lt";
        if (rangeKey[1] === "above") this.selRangeEmotion[rangeKey[0]] = "gt";
      } else {
        this.selRangeEmotion[rangeKey[0]] = null;
        this.selRange[rangeKey[0]] = e.target.value;
      }
      this.handleEmotionChange(null, "emotion");
      this.disabledEmotion = false;
    },
    handleEmotionChange(e, key) {
      let selectedPresentFormula = JSON.parse(
          JSON.stringify(this.selectedPresentFormula)
        ),
        checked = (e && e.target.checked) || false,
        data = null;
      if (
        this.selectedPresentFormula[key] &&
        this.selectedPresentFormula[key][0].data
      )
        data = this.selectedPresentFormula[key][0].data;
      if (!selectedPresentFormula[key]) selectedPresentFormula[key] = [];
      this.selectedPresentFormula = {};
      selectedPresentFormula[key][0] = {
        id: 0,
        name: key,
        value: checked ? "disabled" : "enabled",
        data
      };
      this.selectedPresentFormula = selectedPresentFormula;
      if (e && key === "emotion") this.disabledEmotion = e.target.checked;
      else if (!e && key === "emotion") this.disabledEmotion = false;
      if (e && key === "sentiment") this.disabledSentiment = e.target.checked;
      else if (!e && key === "sentiment") this.disabledEmotion = false;
    },
    
    handleEditResFormula() {
      let formulaKey =
        this.step === "qa-and-interstitial"
          ? this.qaFormulaKeys
          : this.step == "assmnt-block-setup"
          ? this.assessmentFormulaKeys
          : this.formulaKeys;
      this.$modal.hide("newAssessFormulaModal");
      let formulaPresentKeys = this.selApiAssmntItemFormula[this.editFormulaIndex][
        "present"
      ];
      let formulaAbsentKeys = this.selApiAssmntItemFormula[this.editFormulaIndex][
        "absent"
      ];
      let fbKey =
        this.step === "qa-and-interstitial"
          ? "qa_formula"
          : this.step == "assmnt-block-setup"
          ? "assessment_formula"
          : "response_formula";
      let fb = {
        [fbKey]: {
          present_cond_keyword_min: this.presentMinKeyword,
          absent_cond_keyword_min: this.absentMinKeyword
        }
      };
      Object.keys(formulaPresentKeys).length > 0 &&
        Object.keys(formulaPresentKeys).forEach(ele => {
          let sentiEmotionKey =
            ele === "emotion" ? this.disabledEmotion : this.disabledSentiment;
          this.getUpdateFormData(
            ele,
            true,
            this.selectedPresentFormula,
            fb[fbKey],
            formulaPresentKeys,
            formulaKey,
            sentiEmotionKey
          );
        });
      Object.keys(formulaAbsentKeys).length > 0 &&
        Object.keys(formulaAbsentKeys).forEach(ele => {
          this.getUpdateFormData(
            ele,
            false,
            this.selectedAbsentFormula,
            fb[fbKey],
            formulaAbsentKeys,
            formulaKey
          );
        });
      for (let key in formulaKey) {
        this.getFormData(
          true,
          fb[fbKey],
          this.selectedPresentFormula[key],
          formulaKey[key][1],
          formulaKey[key][0]
        );
        this.getFormData(
          false,
          fb[fbKey],
          this.selectedAbsentFormula[key],
          formulaKey[key][1],
          formulaKey[key][0]
        );
      }
      if (this.step === "qa-and-interstitial") {
        if (
          fb[fbKey].qa_formula_sentiments_attributes &&
          fb[fbKey].qa_formula_sentiments_attributes.length === 2
        )
          fb[fbKey].qa_formula_sentiments_attributes.pop();
        if (
          fb[fbKey].qa_formula_emotions_attributes &&
          fb[fbKey].qa_formula_emotions_attributes.length === 6
        )
          fb[fbKey].qa_formula_emotions_attributes.pop();
        this.addSentimentEmotion(
          fb.qa_formula,
          "emotion_enabled",
          this.disabledEmotion
        );
        this.addSentimentEmotion(
          fb.qa_formula,
          "sentiment_enabled",
          this.disabledSentiment
        );
        api
          .editResponseFormula(
            this.selApiAssmntItemFormula[this.editFormulaIndex].ques_id,
            fb
          )
          .then(() => {
            api.getQaCondition(this.qaConditionId).then(res => {
              this.setQaResFormula(res.data.data.attributes.qa_formulas);
            });
          });
      } else if (this.step === "assmnt-block-setup") {
        if (
          fb[fbKey].assessment_formula_sentiments_attributes &&
          fb[fbKey].assessment_formula_sentiments_attributes.length === 2
        )
          fb[fbKey].assessment_formula_sentiments_attributes.pop();
        if (
          fb[fbKey].assessment_formula_emotions_attributes &&
          fb[fbKey].assessment_formula_emotions_attributes.length === 6
        )
          fb[fbKey].assessment_formula_emotions_attributes.pop();
        this.addSentimentEmotion(
          fb.assessment_formula,
          "emotion_enabled",
          this.disabledEmotion
        );
        this.addSentimentEmotion(
          fb.assessment_formula,
          "sentiment_enabled",
          this.disabledSentiment
        );
        api
          .updateAssessmentFormula(
            this.selApiAssmntItemFormula[this.editFormulaIndex].ques_id,
            fb
          )
          .then(() => {
            this.blockSetUp(false);
          });
      } else {
        if (
          fb[fbKey].formula_sentiments_attributes &&
          fb[fbKey].formula_sentiments_attributes.length === 2
        )
          fb[fbKey].formula_sentiments_attributes.pop();
        if (
          fb[fbKey].formula_emotions_attributes &&
          fb[fbKey].formula_emotions_attributes.length === 6
        )
          fb[fbKey].formula_emotions_attributes.pop();
        this.addSentimentEmotion(
          fb.response_formula,
          "emotion_enabled",
          this.disabledEmotion
        );
        this.addSentimentEmotion(
          fb.response_formula,
          "sentiment_enabled",
          this.disabledSentiment
        );
        api
          .updateResFormulas(
            this.selApiAssmntItemFormula[this.editFormulaIndex].ques_id,
            fb
          )
          .then(() => {
            this.getEmailResList(false);
          });
      }
    },
    getUpdateFormData(key, objKey, data, fb, formula, obj, sentiEmotionKey) {
      if (!data[key]) {
        if (key !== "emotion" || key !== "sentiment") {
          formula[key] &&
            formula[key].forEach(e => {
              if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
              fb[obj[key][1]].push({
                _destroy: true,
                id: e.id,
                present_cond: objKey
              });
            });
        }
      } else {
        if (
          data[key][0].name === "emotion" ||
          data[key][0].name === "sentiment"
        ) {
          if (sentiEmotionKey) {
            if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
            formula[key][0].data &&
              formula[key][0].data.forEach(e => {
                fb[obj[key][1]].push({
                  _destroy: true,
                  id: e.id,
                  present_cond: objKey
                });
              });
          } else if (data[key][0].value === "enabled") {
            if (data[key][0].name === "emotion") {
              if (!this.disabledEmotion) {
                if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
                data[key][0].data &&
                  data[key][0].data.forEach(e => {
                    if (
                      this.selRangeEmotion[e.attributes.emotion] === "lt" ||
                      this.selRangeEmotion[e.attributes.emotion] === "gt"
                    )
                      fb[obj[key][1]].push({
                        emotion: e.attributes.emotion,
                        comparator: this.selRangeEmotion[e.attributes.emotion],
                        score: this.emotionData[e.attributes.emotion],
                        present_cond: true,
                        id: e.id
                      });
                    else
                      fb[obj[key][1]].push({
                        emotion: e.attributes.emotion,
                        range_value: this.selRange[e.attributes.emotion],
                        score: this.emotionData[e.attributes.emotion],
                        present_cond: true,
                        id: e.id
                      });
                  });
              }
            } else if (data[key][0].name === "sentiment") {
              if (!this.disabledSentiment) {
                if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
                if (data[key][0].data) {
                  if (
                    this.sentimentData.sentiment === "lt" ||
                    this.sentimentData.sentiment === "gt"
                  )
                    fb[obj[key][1]].push({
                      sentiment: this.sentimentData.value,
                      comparator: this.sentimentData.sentiment,
                      score: this.sentimentData.score,
                      present_cond: true,
                      id: data[key][0].data[0].id
                    });
                  else
                    fb[obj[key][1]].push({
                      sentiment: this.sentimentData.value,
                      range_value: this.sentimentData.range,
                      score: this.sentimentData.score,
                      present_cond: true,
                      id: data[key][0].data[0].id
                    });
                }
              }
            }
          }
        }
      }
    },
    reverseFormulaKey(obj) {
      const ret = {};
      Object.keys(obj).forEach(key => {
        ret[obj[key]] = key;
      });
      return ret;
    },
    handleSentimentChange() {
      let value;
      if (this.sentimentData.score >= -1 && this.sentimentData.score < 0)
        value = "negative";
      if (this.sentimentData.score > 0 && this.sentimentData.score <= 1)
        value = "positive";
      if (this.sentimentData.score === 0) value = "neutral";
      this.disabledSentiment = false;
      this.sentimentData.value = value;
    },
    handleBtnSentimentChange(e, key, status) {
      if (status) {
        this.sentimentData.range = null;
        if (key === "below") this.sentimentData.sentiment = "lt";
        if (key === "above") this.sentimentData.sentiment = "gt";
      } else {
        this.sentimentData.sentiment = null;
        this.sentimentData.range = e.target.value;
      }
      this.disabledSentiment = false;
      this.handleEmotionChange(null, "sentiment");
    }
    
  }
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";
</style>
