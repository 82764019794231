<template>
  <div id="ContentDocument">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Documents</h2>
          <div class="progress-bar-overlay" v-if="uploadPercentages > 0">
            <div class="progress-bar-container">
              <progress-bar :options="options" :value="getPercentage" v-if="uploadPercentages > 0" />
            </div>
          </div>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="searchDoc"
                class="form-control"
                aria-describedby="searchDoc"
                placeholder="Search"
                v-model="searchDoc"
                v-on:input="searchDocument"
              />
            </div>
          </form>
          <input
            style="display: none;"
            ref="fileInput"
            type="file"
            @change="onFileSelected"
            accept="application/pdf"
            multiple="multiple"
          />
          <a class="btn header-btn primary" @click="$refs.fileInput.click()">Upload</a>
        </div>
        <p v-if="totalDocs > 1 && !searchDoc">You have {{totalDocs}} Documents in the system.</p>
        <p v-else-if="totalDocs <= 1 && !searchDoc">You have {{totalDocs}} Document in the system.</p>
        <p
          v-else-if="totalDocs > 1 && searchDoc"
        >You have {{totalDocs}} Documents in the search result.</p>
        <p v-else>You have {{totalDocs}} Document in the search result.</p>
      </header>
      <div class="data-content" v-if="docLists.length !== 0">
        <div class="data-table data-column-document">
          <div class="data-head">
            <div class="data-col">
              Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'active_storage_blobs.filename' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('active_storage_blobs.filename','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'active_storage_blobs.filename' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('active_storage_blobs.filename','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Created on
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('created_at','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('created_at','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Size
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'active_storage_blobs.byte_size' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('active_storage_blobs.byte_size','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'active_storage_blobs.byte_size' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('active_storage_blobs.byte_size','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Tags</div>
            <div class="data-col">Actions</div>
          </div>
          <div class="data-items">
            <div class="data-row" v-for="doc in docLists" :key="doc.id">
              <div class="data-col">
                <div class="data-image">
                  <div class="data-pic default cursor">
                    <a @click="$modal.show('pdfModal',{pdfUrl : doc.attributes.resource_url })">
                      <img src="../assets/images/pdf-icon.png" alt="pdf" />
                    </a>
                  </div>
                  <div class="data-text cursor">
                    <a
                      @click="$modal.show('pdfModal',{pdfUrl : doc.attributes.resource_url })"
                    >{{doc.attributes.file_name}}</a>
                  </div>
                </div>
               </div>
              <div class="data-col">{{doc.attributes.created_on}}</div>
              <div class="data-col">{{size(doc.attributes.resource_size)}}</div>
              <div class="data-col">
                <span class="mr-2" v-if="doc.attributes.tag_list">{{doc.attributes.tag_list}}</span>
                <a
                  href="javascript:void(0);"
                  class="data-link dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >{{doc.attributes.tag_list?'Tag' : 'Add Tag'}}</a>
                <notesModal
                  v-bind:doc="{id : doc.id , type : 'document' , notes :doc.attributes.tag_list, page :page}"
                  :method="getDoc"
                />
              </div>
             <div class="data-col data-action">
               <div class="mr-3">
                  <copy-to-clipboard  :text="getshortCode(doc)" @copy="copiedMessage">
                    <a href="javascript:void(0);" class="copy-icon">Get shortcode</a>
                  </copy-to-clipboard>
               </div>
               <div class="data-delete-dropdown">
                    <button
                        type="button"
                        class="btn dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <em class="icon-delete"></em>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <div class="data-popper-title">Are you sure you want to delete this document?</div>
                        <div class="data-popper-footer">
                          <a class="btn default">cancel</a>
                          <a class="btn primary" @click="deleteDoc(doc.id)">Ok</a>
                        </div>
                    </div>
                </div>
               </div>
             </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getDoc(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="docLists.length === 0 && !searchDoc && display">
        <h3>There’s nothing here!</h3>
        <p>You have no Documents in the system yet.</p>
        <div>
          <a @click="$refs.fileInput.click()" class="btn header-btn primary">Add a new Document</a>
        </div>
      </div>
      <div class="data-no-result" v-if="docLists.length === 0 && searchDoc">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <pdfModal />
  </div>
</template>

<script>
import { commonConfig } from "@/utils/commonConfig";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import pdfModal from "../components/modal/Pdf1.vue";
import notesModal from "../components/modal/Note.vue";
import axios from "axios";
import ProgressBar from "vuejs-progress-bar";
import CopyToClipboard from "vue-copy-to-clipboard";

export default {
  name: "Content-document",
  components: {
    pdfModal,
    notesModal,
    ProgressBar,
    CopyToClipboard
  },
  metaInfo: {
    title: "All Organization",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      characters: [],
      roles: [],
      page: 1,
      docLists: [],
      totalDocs: "",
      totalPages: [],
      searchDoc: "",
      sortOrder: "desc",
      sortColumn: "id",
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      organizationcharacters: "",
      display: false,
      selectedFile: "",
      uploadPercentages: 0,
      options: {
        text: {
          color: "#FFF",
          shadowEnable: true,
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "rgba(0, 0, 0, 0.09)"
        },
        layout: {
          height: 15,
          width: 200,
          verticalTextAlign: 80,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "line"
        }
      }
    };
  },
  methods: {
    copiedMessage() {
      utilFunctionService.showSuccess(commonConfig.appMessages.shortcodeCopied);
    },
    getshortCode(doc) {
      let fileName = doc.attributes.file_name;
      let resourceId = doc.id;
      let shortCode = `[filePopup resource_id="${resourceId}" resource_file_name="${fileName}"/]`;
      return shortCode;
    },
    show() {
      this.$modal.show();
    },
    hide(modal) {
      this.$modal.hide(modal);
      this.getDoc(this.page);
    },
    searchDocument() {
      api
        .getAllDoc(
          commonConfig.setting.searchPage,
          this.searchDoc,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.docLists = res.data.data;
          this.totalDocs = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    getDoc(page) {
      utilFunctionService.showLoader();
      this.page = page;
      api
        .getAllDoc(page, this.searchDoc, this.sortColumn, this.sortOrder)
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.docLists = res.data.data;
          this.totalDocs = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    toggleSort(colName, sortType) {
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getDoc(this.page);
    },
    deleteDoc(id) {
      utilFunctionService.showLoader();
      api
        .deleteGlobalResource(id)
        .then(() => {
          utilFunctionService.hideLoader();
          let page;
          if (this.docLists.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getDoc(page);
            } else {
              page = this.page - 1;
              this.getDoc(page);
            }
          } else {
            const page = this.page;
            this.getDoc(page);
          }
          utilFunctionService.showSuccess(
            commonConfig.appMessages.deleteDocument
          );
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    nextPage() {
      this.page = this.page + 1;
      this.getDoc(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getDoc(this.page);
    },
    onFileSelected(event) {
      utilFunctionService.showLoader();
      event.target.files.forEach((element, idx) => {
        this.selectedFile = element;
        const fData = new FormData();
        let accessTokenVal = utilFunctionService.getAuthToken();
        if (this.selectedFile) {
          fData.append("global_resource[attachment]", this.selectedFile);
          fData.append("global_resource[resource_type]", "document");
          fData.append("global_resource[tag_list]", "");
          fData.append("global_resource[private]", false);
          axios
            .post(`${this.url}/api/admin/v1/global_resources.json`, fData, {
              headers: {
                Authorization: `${accessTokenVal}`,
                "content-type": "multipart/form-data"
              },
              onUploadProgress: function(progressEvent) {
                this.uploadPercentages =
                  parseInt(
                    Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  ) !== 100
                    ? parseInt(
                        Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        )
                      )
                    : 0;
              }.bind(this)
            })
            .then(() => {
              if (event.target.files.length - 1 === idx) {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.addDoc
                );
                this.getDoc(commonConfig.setting.searchPage);
              }
            })
            .catch(() => {
              if (event.target.files.length - 1 === idx) {
                utilFunctionService.showErr(commonConfig.appMessages.errDoc);
                this.getDoc(commonConfig.setting.searchPage);
              }
            });
        }
      });
      utilFunctionService.hideLoader();
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    }
  },
  created() {
    this.getDoc(this.page);
  },
  computed: {
    getPercentage() {
      return this.uploadPercentages;
    }
  }
};
</script>
<style scoped>
#progress-bar-line {
  display: inline-block;
  width: auto;
}
</style>
