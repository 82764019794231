<template>
  <div id="WatsonAdmin">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>LLM Skill Sets</h2>
        </div>
        <div class="header-right">
          <router-link to="/create-llm-skill-set" class="btn btn-primary">Create Skill Set</router-link>
        </div>
      </header>

      <div class="content-container">
        <div class="data-content" v-if="llm_skill_sets.length">
          <div class="data-table data-column-skill-sets">
            <div class="data-head">
              <div class="data-col">
                Skill Name
              </div>
              <div class="data-col">
                Primary Skill
              </div>
              <div class="data-col">
                Sub Skills
              </div>
              <div class="data-col">
                Actions
              </div>
            </div>
            <div class="data-items">
              <div class="data-row" v-for="llm_skill_set in llm_skill_sets" :key="llm_skill_set.id">
                <div class="data-col">{{ llm_skill_set.attributes.name }}</div>
                <div class="data-col">{{ llm_skill_set.attributes.primary_skill_name }}</div>
                <div class="data-col">
                  <div v-for="(sub_skills, index) in llm_skill_set.attributes.sub_skills_list" :key="index">
                    {{ sub_skills.name }}
                  </div>
                </div>
                <div class="data-col">
                  <ul class="btn-container">
                    <li>
                      <router-link
                        :to="{ name: 'edit-llm-skill-set', params: { id: llm_skill_set.id, llm_skill_set_data: llm_skill_set } }"><button class="btn btn-edit">Edit</button></router-link>
                    </li>
                    <li>
                      <button class="btn btn-danger" @click="deleteLlmSkillSet(llm_skill_set.id)">Delete</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- In order for this work, there needs to be tabulation on the backend -->
          <!-- <div class="data-footer">
                      <div class="data-results">Showing {{ page }} of {{ pages.length }}</div>
                      <div aria-label="Page navigation example">
                          <ul class="pagination">
                              <li class="page-item" v-if="page !== 1">
                                  <a class="page-link" aria-label="Previous" @click="prevPage">
                                      <em class="icon-prev"></em>
                                  </a>
                              </li>
                              <li class="page-item" v-for="pages in pages.slice(page - 1, page + 3)" :key="pages">
                                  <a class="page-link" :class="[pages === page ? 'active' : '']"
                                      @click="getElm(pages)">{{ pages }}</a>
                              </li>
                              <li class="page-item" v-if="page < pages.length">
                                  <a class="page-link" aria-label="Next" @click="nextPage">
                                      <em class="icon-next"></em>
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </div> -->
        </div>
        <div class="data-content" v-else>
          <h1>No Skills created</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import deleteLlmSkillSetModal from "@/components/modal/deleteLlmSkillSetModal.vue";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "LlmSkills",
  data() {
    return {
      llm_skill_sets: []
    };
  },
  methods: {
    getAllSkillSets() {
      api.getAllLlmSkillSets().then((response) => {
        this.llm_skill_sets = response.data.data;
      });
    },
    showDeleteLlmSkillSetsModal(id) {
      this.$modal.show(deleteLlmSkillSetModal, { llm_skill_id: parseInt(id) });
    },
    deleteLlmSkillSet(id) {
      console.log(id);
      utilFunctionService.showLoader();
      let payload = {
        llm_skill_set_id: id
      }
      api.deleteLlmSkillSet(payload).then(() => {
        this.getAllSkillSets();
        utilFunctionService.hideLoader();
      })
    }
  },
  created() {
    this.getAllSkillSets();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.btn-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.btn-edit {
    background-color: rgb(99, 99, 244);
    color: white;
    margin-right: 8px;
}

.btn-danger {
    background-color: rgb(251, 72, 72);
    color: white;
}
</style>
