var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { name: "assignOrganisation", height: "auto", scrollable: true },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content modal-content-large" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [
              _c("h5", [_vm._v("Assign Organization")]),
              _c("p", [
                _vm._v("Add Characters to the Organizations you have created."),
              ]),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("assignOrganisation")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("ValidationObserver", { ref: "form" }, [
              _c(
                "form",
                {
                  staticClass: "modal-form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "organisation-dropdown" },
                      [
                        _c("ValidationProvider", {
                          attrs: { name: "Organisation", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.organisation,
                                        expression: "organisation",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && _vm.isOrgnizationData
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: "Type here",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                    },
                                    domProps: { value: _vm.organisation },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.organisation = $event.target.value
                                        },
                                        _vm.searchOrganisation,
                                      ],
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive &&
                                            _vm.isOrgnizationData
                                              ? "invalid"
                                              : "",
                                          ],
                                          style: [
                                            _vm.isActive &&
                                            _vm.isOrgnizationData
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm.organisations.length !== 0
                      ? _c("div", { staticClass: "organisation-dropdown" }, [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              class: [
                                _vm.organisations.length !== 0 ? "show" : "",
                              ],
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "dropdown-list" },
                                _vm._l(
                                  _vm.organisations,
                                  function (organisation) {
                                    return _c(
                                      "li",
                                      {
                                        key: organisation.id,
                                        staticClass: "dropdown-items",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-pics",
                                            on: {
                                              click: function ($event) {
                                                return _vm.addOrganisation(
                                                  organisation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            organisation.photo_url.thumbnail
                                              ? _c("img", {
                                                  attrs: {
                                                    src: organisation.photo_url
                                                      .thumbnail,
                                                    alt: "image2",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-content",
                                            on: {
                                              click: function ($event) {
                                                return _vm.addOrganisation(
                                                  organisation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("h4", [
                                              _vm._v(_vm._s(organisation.name)),
                                            ]),
                                            _c("h5", [
                                              _vm._v(
                                                _vm._s(
                                                  organisation.industry_name
                                                )
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  organisation.characters_name
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-action" },
                                          [
                                            !organisation.status
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-action",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "add-action",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addOrganisation(
                                                              organisation
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("+")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            organisation.status
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-action",
                                                  },
                                                  [
                                                    _c("em", {
                                                      staticClass: "icon-check",
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Added"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.addedOrganisation.id
                      ? _c(
                          "div",
                          { staticClass: "organisation-dropdown-results" },
                          [
                            _c("ul", { staticClass: "dropdown-list" }, [
                              _c("li", { staticClass: "dropdown-items" }, [
                                _c("div", { staticClass: "dropdown-pics" }, [
                                  _vm.addedOrganisation.photo_url.thumbnail
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm.addedOrganisation.photo_url
                                            .thumbnail,
                                          alt: "image2",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "dropdown-content mt-2" },
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        _vm._s(_vm.addedOrganisation.name)
                                      ),
                                    ]),
                                    _c("h5", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.addedOrganisation.industry_name
                                        )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.addedOrganisation.characters_name
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", [
                                  _c("div", { staticClass: "dropdown-form" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group mb-1" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "searchIndustry",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.searchRole,
                                                          expression:
                                                            "searchRole",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Enter Role",
                                                      },
                                                      domProps: {
                                                        value: _vm.searchRole,
                                                      },
                                                      on: {
                                                        input: [
                                                          function ($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.searchRole =
                                                              $event.target.value
                                                          },
                                                          _vm.getRole,
                                                        ],
                                                      },
                                                    }),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Role is required"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            576494835
                                          ),
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            class: [
                                              _vm.roles.length !== 0
                                                ? "show"
                                                : "",
                                            ],
                                          },
                                          [
                                            _vm.roles.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-list mt-3",
                                                  },
                                                  _vm._l(
                                                    _vm.roles,
                                                    function (role) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: role.id,
                                                          staticClass:
                                                            "dropdown-items",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-content",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setRole(
                                                                      role.id,
                                                                      role
                                                                        .attributes
                                                                        .name
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("h4", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    role
                                                                      .attributes
                                                                      .name
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-action",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "add-action",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.setRole(
                                                                              role.id,
                                                                              role
                                                                                .attributes
                                                                                .name
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "+"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "dropdown-action" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "delete-action",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeOrganisation()
                                            },
                                          },
                                        },
                                        [_vm._v("Remove")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn default",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#createCharacter",
                          "data-dismiss": "modal",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.hide()
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      { staticClass: "btn primary", attrs: { type: "submit" } },
                      [_vm._v("Save")]
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }