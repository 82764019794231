<template>
  <div>
    <div class="setup-content">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="head-title" v-if="updatedData.attributes.card_type == 'submission'">Configure Grading Rubric</h4>
        <h4 class="head-title" v-if="updatedData.attributes.card_type == 'submission_email'">Configure Submission Rubric</h4>
        <div class="head-checkbox form-checkboxes">
          <!-- <label name="quizAssessment" class="custom-checkbox">
            <input
              type="checkbox"
              name="quizAssessment"
              v-model="distributeWeight"
              @change="distributeWeightEqually"
            />
            <span for="quizAssessment" class="checkmark"></span>Distribute weight equally
          </label> -->
           <button
            type="button"
            class="btn primary"
            @click="distributeWeightEqually"
          >Distribute Weight</button>
        </div>
      </div>
      <span>
        <form class="w-100">
          <div class="row custom-row">
            <div class="col-sm-12">
              <div class="grading-table">
                <div class="grading-head">
                  <div class="grading-col">
                    <span>criteria</span>
                  </div>
                  <div class="grading-col">
                    <span>0-49</span>
                  </div>
                  <div class="grading-col">
                    <span>50-59</span>
                  </div>
                  <div class="grading-col">
                    <span>60-69</span>
                  </div>
                  <div class="grading-col">
                    <span>70-79</span>
                  </div>
                  <div class="grading-col">
                    <span>80-89</span>
                  </div>
                  <div class="grading-col">
                    <span>90-100</span>
                  </div>
                  <div class="grading-col">
                    <span>Weight</span>
                  </div>
                  <div class="grading-col"></div>
                </div>
                <div class="grading-data">
                  <div class="grading-row" v-for="(data,index) in rubric" :key="data + index">
                    <div class="grading-col">
                      <div class="grading-box"
                        @click="show('addCriteriaWeightModal', index, 'criteria', data.criteria, data.id, 'Criteria', data.criteriaDesc)">
                        <div
                          class="grading-criteria"
                          :class="[data.criteria == null && validate ? 'grading-invalid' : '']"
                        >
                          <div
                            type="text"
                            class="form-control"
                            placeholder="Enter Criteria"
                            v-html="data.criteria"
                          />
                          <p class="invalid" v-if="data.criteria == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div
                        class="grading-box"
                        @click="show('addFeedbackModal', index, 'range_1', data.range_1, data.id, '0-49')"
                      >
                        <div class="grading-edit" v-if="data.range_1 != null">
                          <div
                            class="form-control"
                            v-html="data.range_1"
                            placeholder="Enter Criteria"
                          ></div>
                          <p></p>
                        </div>
                        <div
                          class="grading-add"
                          :class="[data.range_1 == null && validate ? 'grading-invalid' : '']"
                          v-if="data.range_1 == null"
                        >
                          <a href="javascript:void(0);">Add Feedback</a>
                          <p class="invalid" v-if="data.range_1 == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div
                        class="grading-box"
                        @click="show('addFeedbackModal', index, 'range_2', data.range_2, data.id, '50-59')"
                      >
                        <div class="grading-edit" v-if="data.range_2 != null">
                          <div class="form-control" v-html="data.range_2"></div>
                        </div>
                        <div
                          class="grading-add"
                          :class="[data.range_2 == null && validate ? 'grading-invalid' : '']"
                          v-if="data.range_2 == null"
                        >
                          <a href="javascript:void(0);">Add Feedback</a>
                          <p class="invalid" v-if="data.range_2 == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div
                        class="grading-box"
                        @click="show('addFeedbackModal', index, 'range_3', data.range_3, data.id, '60-69')"
                      >
                        <div class="grading-edit" v-if="data.range_3 != null">
                          <div class="form-control" v-html="data.range_3"></div>
                        </div>
                        <div
                          class="grading-add"
                          :class="[data.range_3 == null && validate ? 'grading-invalid' : '']"
                          v-if="data.range_3 == null"
                        >
                          <a href="javascript:void(0);">Add Feedback</a>
                          <p class="invalid" v-if="data.range_3 == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div
                        class="grading-box"
                        @click="show('addFeedbackModal', index, 'range_4', data.range_4, data.id, '70-79')"
                      >
                        <div class="grading-edit" v-if="data.range_4 != null">
                          <div class="form-control" v-html="data.range_4"></div>
                        </div>
                        <div
                          class="grading-add"
                          :class="[data.range_4 == null && validate ? 'grading-invalid' : '']"
                          v-if="data.range_4 == null"
                        >
                          <a href="javascript:void(0);">Add Feedback</a>
                          <p class="invalid" v-if="data.range_5 == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div
                        class="grading-box"
                        @click="show('addFeedbackModal', index, 'range_5', data.range_5, data.id, '80-89')"
                      >
                        <div class="grading-edit" v-if="data.range_5 != null">
                          <div class="form-control" v-html="data.range_5"></div>
                        </div>
                        <div
                          class="grading-add"
                          :class="[data.range_5 == null && validate ? 'grading-invalid' : '']"
                          v-if="data.range_5 == null"
                        >
                          <a href="javascript:void(0);">Add Feedback</a>
                          <p class="invalid" v-if="data.range_5 == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div
                        class="grading-box"
                        @click="show('addFeedbackModal', index, 'range_6', data.range_6, data.id, '90-100')"
                      >
                        <div class="grading-edit" v-if="data.range_6 != null">
                          <div class="form-control" v-html="data.range_6"></div>
                        </div>
                        <div
                          class="grading-add"
                          :class="[data.range_6 == null && validate ? 'grading-invalid' : '']"
                          v-if="data.range_6 == null"
                        >
                          <a href="javascript:void(0);">Add Feedback</a>
                          <p class="invalid" v-if="data.range_6 == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div class="grading-box" :class="[data.disabled ? 'grading-disabled' : '']">
                        <div
                          class="grading-criteria"
                          :class="[data.weight == null && validate ? 'grading-invalid' : '']"
                        >
                          <input
                            class="form-control"
                            v-model="data.weight"
                            placeholder="100%"
                            @click="show('addCriteriaWeightModal', index, 'weight', data.weight, data.id, 'Weight')"
                          />
                          <p class="invalid" v-if="data.weight == null && validate">Mandatory</p>
                        </div>
                      </div>
                    </div>
                    <div class="grading-col">
                      <div class="grading-delete">
                        <a href="javascript:void(0);">
                          <em class="icon-delete" @click="$modal.show('deleteRubricModal'),deleteRubric(index, data.id)"></em>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="add-grading-row pt-2">
                    <a
                      href="javascript:void(0);"
                      @click="addCriteria"
                      class="btn primary"
                    >Add Criteria</a>
                    <div class="add-grading-weight">
                      <p>Total Weight: {{gettotalWeight()}}%</p>
                      <p
                        class="invalid"
                        v-if="totalWeight > 100"
                      >Total weight can't be more than 100%</p>
                      <p class="invalid" v-if="invalidWeight">Total weight should be 100%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 pt-5">
              <div class="d-flex justify-content-end mb-4">
                <a
                  class="btn primary"
                  href="javascript:void(0);"
                  @click="restore('drafted')"
                  v-if="updatedData.attributes.card_type == 'submission_email'"
                >Next</a>
                <a
                  class="btn primary"
                  href="javascript:void(0);"
                  @click="restore('drafted')"
                  v-else
                >Save & Publish</a>
              </div>
              <div class="btn-container justify-content-end">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a class="text" @click="$parent.archiveSubLo()">Archive this Learning Object(LO)</a>
                </a>
              </div>
            </div>
          </div>
        </form>
      </span>
    </div>
    <modal name="addCriteriaWeightModal" height="auto" :width="450" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Add {{colName}}</h5>
          </div>
        </div>
        <div class="modal-body">
          <form class="modal-form modal-addFeedbackForm">
            <label>{{colName}}</label>
            <input
              type="text"
              class="form-control"
              placeholder="Add Criteria"
              v-model="transcript"
              v-if="colName == 'Criteria'"
            />
            <div v-if="colName == 'Criteria'" class="mt-2">
                <label >Description</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Description"
                  v-model="criteriaDescription"
                />
            </div>
            <input
              type="number"
              class="form-control"
              placeholder="Add Weight"
              v-model="transcript"
              v-if="colName == 'Weight'"
              min="0"
              max="100"
            />
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="$modal.hide('addCriteriaWeightModal')"
            class="btn default"
          >Cancel</button>
          <button
            type="button"
            class="btn primary"
            @click="saveCellData('addCriteriaWeightModal')"
          >Save</button>
        </div>
      </div>
    </modal>
    <modal name="addFeedbackModal" height="auto" :width="700" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Add Feedback</h5>
            <p class="addFeedbackTitle">Criteria (Score {{colName}})</p>
          </div>
        </div>
        <div class="modal-body">
          <form class="modal-form modal-addFeedbackForm">
            <vue-editor id="test" :editorToolbar="fullTool" v-model="transcript"></vue-editor>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" @click="$modal.hide('addFeedbackModal')" class="btn default">Cancel</button>
          <button type="button" class="btn primary" @click="saveCellData('addFeedbackModal')">Save</button>
        </div>
      </div>
    </modal>
    <modal name="deleteRubricModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">Are you sure you want to delete?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteRubricModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteRubricRow(),$modal.hide('deleteRubricModal')"
            class="btn medium primary"
          >ok</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import api from "@/services/api";
import { VueEditor } from "vue2-editor";

export default {
  name: "Rubric-component",
  components: {
    VueEditor
  },
  props: ["updatedData"],
  data() {
    return {
      rubric: [
        {
          id: null,
          criteria: null,
          weight: null,
          range_1: null,
          range_2: null,
          range_3: null,
          range_4: null,
          range_5: null
        }
      ],
      isActiveTab: ["active"],
      step: "basic-setup",
      distributeWeight: false,
      totalWeight: 0,
      validate: false,
      colName: "",
      invalidWeight: false,
      transcript: "",
      criteriaDescription: "",
      fullTool: [],
      index: 0,
      key: "rubric_1",
      submit: false,
      rowIndex: "",
      id: ""
    };
  },
  async created() {
    this.getRubric();
  },
  methods: {
    gettotalWeight() {
      return this.totalWeight.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },
    restore() {
      this.submit = true;
      this.rubric.forEach(row => {
        this.validate = Object.keys(row).some(key => {
          return row[key] == null;
        });
      });
      if (
        !this.validate &&
        this.totalWeight < commonConfig.setting.weightMinRange
      ) {
        this.invalidWeight = true;
      }
      if (
        this.validate &&
        this.totalWeight >= commonConfig.setting.weightMinRange &&
        this.totalWeight <= commonConfig.setting.weightMaxRange
      ) {
        const fd = new FormData();
        fd.append("learn_mod_id", this.$route.params.id || this.data.attributes.learn_mod_id);
        fd.append("learning_object[status]", "published");
        fd.append("id", this.$route.query.id);
        if (this.updatedData.attributes.card_type !== "submission_email") {
          api
            .restoreLo(this.$route.params.id || this.data.attributes.learn_mod_id, this.$route.query.id || this.updatedData.id, fd)
            .then(() => {
              this.reload()
            })
            .catch(() => {
              this.reload()
            });
        }
        else
          this.$parent.currentStep('character-response')
        }
    },
    reload() {
      this.$parent.card_type = "";
      utilFunctionService.showSuccess(
        commonConfig.appMessages.LoPublichedText
      );
      this.$router.push(this.$route.params.id);
      window.location.reload();
    },
    distributeWeightEqually() {
      this.distributeWeight = true;
      if (this.distributeWeight) {
        this.distributeWeightInRows();
      }
      const data = new FormData();
      data.append("learning_object_id", this.$route.query.id || this.updatedData.id);
      data.append("distribute_weight_eq", this.distributeWeight);
      api.distributeWeight(data).then(() => {});
    },
    saveCellData(modal) {
      if (this.transcript != null && this.transcript.length > commonConfig.setting.default) {
        this.rubric[this.index][this.key] = this.transcript;
        this.rubric[this.index]['criteriaDesc'] = this.criteriaDescription;
        if (this.id != null) {
          const fb = new FormData();
          fb.append(`rubric[${this.key}]`, this.transcript);
          if (this.criteriaDescription != null)
            fb.append(`rubric[description]`, this.criteriaDescription)
          api.editRubric(this.id, fb).then(() => {});
        } else {
          const fb = new FormData();
          fb.append(`rubric[${this.key}]`, this.transcript);
          if (this.criteriaDescription != null)
            fb.append(`rubric[description]`, this.criteriaDescription);
          fb.append("rubric[gradable_id]", this.updatedData.attributes.objectable_id);
          fb.append("rubric[rubric_type]", this.updatedData.attributes.card_type);
          api.createRubric(fb).then(x => {
            this.rubric[this.index]["id"] = x.data.data.id;
          });
        }
        this.totalWeight = 0;
        this.rubric.map(x => {
          if (x.weight != null)
            this.totalWeight = Number(this.totalWeight) + Number(x.weight);
        });
        if (
          this.totalWeight >= commonConfig.setting.weightMinRange &&
          this.totalWeight <= commonConfig.setting.weightMaxRange
        ) {
          this.invalidWeight = false;
        }
        this.hide(modal);
      }
    },
    deleteRubric(i, id) {
      this.rowIndex = i;
      this.id = id;
    },
    deleteRubricRow() {
      if (this.id == null) {
        this.rubric.splice(this.rowIndex, 1);
      } else {
        api.removeRubric(this.id).then(() => {
          this.rubric.splice(this.rowIndex, 1);
          utilFunctionService.showSuccess(
            commonConfig.appMessages.deleteRubric
          );
          if (this.distributeWeight) {
            this.distributeWeightInRows();
          }
        });
      }
    },
    show(modal, index, key, value, id, name, desc=null) {
      this.index = index;
      this.key = key;
      this.transcript = value;
      this.id = id;
      this.colName = name;
      this.criteriaDescription = desc;
      this.$modal.show(modal);
    },
    hide(modal) {
      this.transcript = "";
      this.criteriaDescription = "";
      this.$modal.hide(modal);
    },
    addCriteria() {
      this.submit = true;
      this.rubric.forEach(row => {
        this.validate = Object.keys(row).some(k => {
          return k !=='criteriaDesc' && row[k] == null;
        });
      });
      if (!this.validate) {
        this.rubric.push({
          id: null,
          criteria: null,
          criteriaDesc: null,
          weight: null,
          range_1: null,
          range_2: null,
          range_3: null,
          range_4: null,
          range_5: null,
          disabled: false
        });
        this.show(
          "addCriteriaWeightModal",
          this.rubric.length - 1,
          "criteria",
          "",
          null,
          "Criteria",
          ""
        );
        if (this.distributeWeight) {
          this.distributeWeightInRows()
        }
      }
    },
    distributeWeightInRows() {
      this.totalWeight = 0;
      const number = commonConfig.setting.weightMaxRange /this.rubric.length;
      let upTwoDecimalpoints = number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      this.rubric.map((x, i) => {
        this.rubric[i].weight = upTwoDecimalpoints;
      });
      this.rubric.map(x => {
        if (x.weight != null) {
          this.totalWeight = Number(this.totalWeight) + Number(x.weight);
        }
      });
      if (
          this.totalWeight >= commonConfig.setting.weightMinRange &&
          this.totalWeight <= commonConfig.setting.weightMaxRange
        ) {
          this.invalidWeight = false;
        }
    },
    getRubric() {
      if(this.updatedData.id) {
        api
        .getRubricData(this.updatedData.id)
        .then(res => {
          this.distributeWeight = false;
          if (res.data.rubrics.length) {
            this.rubric = res.data.rubrics.map(x => ({
              id: x.id,
              criteria: x.attributes.criteria,
              criteriaDesc: x.attributes.description,
              weight: x.attributes.weight,
              range_1: x.attributes.range_1,
              range_2: x.attributes.range_2,
              range_3: x.attributes.range_3,
              range_4: x.attributes.range_4,
              range_5: x.attributes.range_5,
              range_6: x.attributes.range_6,
              disabled: false
            }));
            this.totalWeight = 0;
            this.rubric.map(x => {
              if (x.weight != null) {
                  this.totalWeight = Number(this.totalWeight) + Number(x.weight);
              }
            });
          }
        })
        .catch();
      }
    }
  }
};
</script>
