<template>
  <div id="QaEmail">
    <div class="">
      <p
        class="learn-info"
        v-if="card_data.description"
        v-html="card_data.description"
      ></p>
      <div class="learn-object">
        <div class="learn-object-head">
          <div class="learn-object-col">
            <div class="learn-object-titles">
              <h5>{{ character.attributes.char_full_name }}</h5>
              <p>
                {{ character.attributes.world_role }}({{
                  character.attributes.org_name
                }})
              </p>
            </div>
          </div>
        </div>
        <div class="learn-object-preview" v-if="submitquestions.length">
          <div
            class="learn-object-info"
            v-for="(que, k) in submitquestions"
            :key="k"
          >
            <h4
              v-if="k === 0"
              v-html="
                replaceLearnerToken(
                  card_data.card_detail.attributes.introduction
                )
              "
            ></h4>
            <h4
              v-if="que.attributes.attempt === 1"
              v-html="replaceLearnerToken(que.attributes.question)"
            ></h4>
            <div class="dialogic-answer">
              <p v-html="que.attributes.answer"></p>
            </div>
            <h4
              v-if="que.attributes.character_response"
              v-html="replaceLearnerToken(que.attributes.character_response)"
            ></h4>
            <h4
              v-if="que.attributes.follow_up_question"
              v-html="replaceLearnerToken(que.attributes.follow_up_question)"
            ></h4>
          </div>
        </div>
        <div class="learn-object-info">
          <h4 v-if="isTheEndOfConversation()">
            {{ card_data.card_detail.attributes.conclusion }}
          </h4>
        </div>
      </div>

      <div class="admin-feedback-content modal-score-content">
        <div class="" :settings="settings">
          <div class="">
            <div class="modal-title modal-flex-title">
              <h3>Feedback</h3>
            </div>
            <div class="modal-score-strip mb-4" v-if="overall_assmnt_item && overall_assmnt_item.display_feedback">
              <span class="modal-score-icon">
                <img
                  v-if="overall_assmnt_item.assessment_icon_url"
                  :src="overall_assmnt_item.assessment_icon_url"
                  :alt="overall_assmnt_item.assessment_label"
                />
                {{ overall_assmnt_item.assessment_label }}
              </span>
              <p v-html="overall_assmnt_item.feedback"></p>
            </div>
            <!-- carousel container repeat -->
            <div v-if="dialogic_debrief_evaluation">
              <div
                class="dialogic-container"
                v-for="(dialogicdata, key) in dialogic_debrief_evaluation"
                :key="key"
              >
                <div class="dialogic-heading">
                  {{
                    dialogic_debrief_evaluation[key][0].attributes &&
                    dialogic_debrief_evaluation[key][0].attributes
                      .question_concept
                      ? dialogic_debrief_evaluation[key][0].attributes
                          .question_concept
                      : ""
                  }}
                </div>
                <div
                  class="dialogic-carousel"
                  :responsive="{
                    0: { items: 1 },
                    768: { items: 2 },
                    1024: { items: 3 },
                    1367: { items: 3 },
                  }"
                  :id="dialogic_debrief_evaluation[key]"
                >
                  <!-- feedback items start -->
                  <div
                    v-for="dialogic in dialogic_debrief_evaluation[key]"
                    :key="dialogic.id"
                  >
                    <!-- feedback items end -->
                    <!-- feedback items start -->
                    <div class="feedback-items missed-items" v-if="dialogic.attributes.key_topic_missed">
                      <div class="feedback-content">
                        <div class="feedback-heads">
                          <div class="feedback-icon"></div>
                          <div class="feedback-infos">
                            <div class="feedback-head">Missed</div>
                            <h4>{{ dialogic.attributes.topic_label }}</h4>
                          </div>
                        </div>
                        <p v-html="dialogic.attributes.debrief_received"></p>
                      </div>
                    </div>
                    <div class="feedback-items mastery-items" v-else>
                      <div class="feedback-content">
                        <div class="feedback-heads">
                          <div class="feedback-icon">
                            <img :src="dialogic.attributes.assessment_icon_url"
                              :alt="dialogic.attributes.assessment_label" />
                          </div>
                          <div class="feedback-infos">
                            <div class="feedback-head">
                              {{ dialogic.attributes.assessment_label }}
                            </div>
                            <h4>{{ dialogic.attributes.topic_label }}</h4>
                          </div>
                        </div>
                        <p v-html="dialogic.attributes.debrief_received"></p>
                      </div>
                    </div>

                    <!-- feedback items end -->
                  </div>
                  <!-- feedback items end -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";

export default {
  name: "LearnerDialogic",
  props: ["userLearnObj", "adminLearnerLoDetails"],
  data() {
    return {
      settings: {
        maxScrollbarLength: 30,
      },
      loId: "",
      card_type: "",
      card_data: this.userLearnObj.attributes.learning_object.attributes,
      url: utilFunctionService.getImageUrl(),
      character:
        this.userLearnObj.attributes.learning_object.attributes.card_detail
          .attributes.character,
      evaluation_id: this.userLearnObj.attributes.current_evaluation_id,
      fullToolbar: [],
      transcript: "",
      questions: [],
      submitquestions: [],
      disableNextBtn: true,
      ans: "",
      can_retry: false,
      overall_assmnt_item: {},
      dialogic_debrief_evaluation: [],
      questions_data: [],
      questions_datas: [],
      videoCode: "",
      type: "",
      sliderImages: [],
      title: "",
      desc: "",
    };
  },
  methods: {
    beforeOpen(event) {
      this.type = event.params.type;
      this.title = event.params.title.title;
      this.desc = event.params.title.description;
      if (event.params.type === "video") {
        this.videoCode = event.params.data;
      } else {
        this.sliderImages = event.params.data;
      }
    },
    show(modal) {
      this.$modal.show(modal);
    },
    next(type) {
      this.$modal.hide("dialogicLearningData");
      this.$parent.next(type);
    },
    alertShow() {
      this.$modal.show("alertShow");
    },
    Answer() {
      if (this.ans.length === 0) {
        this.disableNextBtn = true;
      } else {
        this.disableNextBtn = false;
      }
    },
    isTheEndOfConversation() {
      return (
        this.questions_datas.length === 0 &&
        this.submitquestions[this.submitquestions.length - 1] &&
        !this.submitquestions[this.submitquestions.length - 1].attributes
          .follow_up_question
      );
    },
    getDialogicAnswers(dialogicEvaluationId) {
      this.submitquestions = [];
      api.getDialogicAnswers(dialogicEvaluationId).then((res) => {
        let ansData = res.data.data;
        if (ansData && ansData.length > 0) {
          ansData.forEach((element, i) => {
            let index = this.questions.findIndex((elem) => {
              if (
                parseInt(elem.attributes.dialogic_question_id) ===
                parseInt(element.attributes.dialogic_question_id)
              ) {
                return elem.id;
              }
            });
            let indexs = this.questions_data.findIndex((elema) => {
              if (
                parseInt(elema.attributes.dialogic_question_id) ===
                parseInt(element.attributes.dialogic_question_id)
              ) {
                return elema.id;
              }
            });
            if (indexs !== -1) {
              this.questions_data[indexs].status = true;
            }
            if (index !== -1) {
              element.attributes.question =
                this.questions[index].attributes.question;
              if (
                element.attributes.attempt > 4 ||
                (element.attributes.follow_up_question &&
                  element.attributes.follow_up_question.length < 4)
              )
                element.attributes.follow_up_question = "";
              this.submitquestions.push(element);
            }
            if (i === ansData.length - 1) {
              this.questionsDataVal();
            }
          });
        } else {
          this.questionsDataVal();
        }
      });
    },
    questionsDataVal() {
      this.questions_datas = [];
      if (this.questions_data.length > 0) {
        this.questions_data.forEach((que) => {
          if (!que.status) {
            this.questions_datas.push(que);
          }
        });
      }
    },

    dialogicEvaluations() {
      api.dialogicEvaluations(this.evaluation_id).then((res) => {
        let complete =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.attributes &&
          res.data.data.attributes.complete
            ? res.data.data.attributes.complete
            : false;
        if (complete) {
          this.overall_assmnt_item =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.overall_assmnt_item
              ? res.data.data.attributes.overall_assmnt_item.attributes
              : "";
          console.log(this.overall_assmnt_item, "this.overall_assmnt_item");
          this.dialogic_debrief_evaluation =
            res && res.data && res.data.data && res.data.data.attributes
              ? res.data.data.attributes.dialogic_debrief_evaluation
              : "";
          console.log(
            this.dialogic_debrief_evaluation,
            "this.dialogic_debrief_evaluation"
          );
        } else this;
      });
    },
    dialogicQuestions() {
      api
        .getdialogicQuestions(
          this.userLearnObj.attributes.learning_object.attributes.card_detail
            .id,
          this.userLearnObj.id
        )
        .then((res) => {
          this.questions = [...res.data.variations.data];
          this.questions_data = [...this.questions];
          this.getDialogicAnswers(this.evaluation_id);
        });
    },
    replaceLearnerToken(text) {
      return text.replace(
        "[learner-name]",
        this.adminLearnerLoDetails.attributes.first_name
      );
    },
  },
  created() {
    this.dialogicQuestions();
    this.dialogicEvaluations();
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.dialogic-answer {
  margin-bottom: 2.4em;
  margin-top: -1em;

  p {
    //background: #f5f5f5;
    padding: 1em;
    margin-top: 0px;
  }
}
.learn-content {
  .learn-object-info {
    margin: 0px;
    h4 {
      margin-bottom: 1em;
    }
  }
}

.learn-object {
  margin: 35px -20px 0;
  padding: 30px 20px 40px;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06);
  background: white;

  @include media-breakpoint(md) {
    margin: 50px 0 0;
    padding: 40px 47px 60px;
  }

  .learn-object-caption {
    font-size: 12px;
    margin: 0 15px 0 0;
  }
}

#QaMessageContainer {
  margin-bottom: 2em;
}

.learn-object-tag {
  @include font-bold;
  font-size: 17px;
  line-height: 1.47;
  margin: 0 0 12px;
}

.details {
  // background-color: $white;
  padding: 25px 25px 25px;
  border-top: 1px dashed #ccc;
  // box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
  text-align: right;
}
</style>