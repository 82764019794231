var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "uploadInterstitialModal",
            height: "auto",
            width: 850,
            scrollable: true,
          },
          on: { "before-open": _vm.beforeOpen },
        },
        [
          _c("div", { staticClass: "modal-content modal-default" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [
                  _vm.content != "adaptive"
                    ? _c("h5", [_vm._v("Upload Interstitial Content")])
                    : _vm._e(),
                  _vm.content == "adaptive"
                    ? _c("h5", [_vm._v("Upload Adaptive Content")])
                    : _vm._e(),
                ]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("form", { staticClass: "modal-form mt-0" }, [
                _c("div", { staticClass: "modal-images-tab" }, [
                  _c(
                    "a",
                    {
                      class: _vm.tab == "video" ? "active" : "",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.tabChange("video")
                        },
                      },
                    },
                    [_vm._v("Video")]
                  ),
                  _c(
                    "a",
                    {
                      class: _vm.tab == "slide" ? "active" : "",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.tabChange("slide")
                        },
                      },
                    },
                    [_vm._v("Slide")]
                  ),
                  _c(
                    "a",
                    {
                      class: _vm.tab == "file" ? "active" : "",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.tabChange("file")
                        },
                      },
                    },
                    [_vm._v("PDF")]
                  ),
                  _c(
                    "a",
                    {
                      class: _vm.tab == "text" ? "active" : "",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.tabChange("text")
                        },
                      },
                    },
                    [_vm._v("Rich Text")]
                  ),
                ]),
                _c("div", { staticClass: "modal-interstitial-content" }, [
                  _vm.tab == "video"
                    ? _c(
                        "div",
                        {
                          staticClass: "modal-interstitial-pane",
                          attrs: { id: "Video" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Title")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Card Title",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.name,
                                                    expression: "name",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  id: "name",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                },
                                                domProps: { value: _vm.name },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.name =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2820331040
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Description")]),
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { name: "Description" } },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.description,
                                            expression: "description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "5",
                                        },
                                        domProps: { value: _vm.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.description =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "btn-container" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn mb-2 secondary medium",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.show("AddVideo")
                                      },
                                    },
                                  },
                                  [_vm._v("Add a new video")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn mb-2 secondary medium",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.show("uploadVideo")
                                      },
                                    },
                                  },
                                  [_vm._v("Select from Library")]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group video-group" },
                                [
                                  _c("div", { staticClass: "video-section" }, [
                                    !_vm.video
                                      ? _c(
                                          "div",
                                          { staticClass: "video-empty" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "images",
                                                src: require("../../assets/images/video-icon.png"),
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "video-player" },
                                          [
                                            _vm.video && _vm.video.attributes
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learning-data-list",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "close-pdf",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeVideo()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("em", {
                                                          staticClass:
                                                            "icon-close",
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "wistia_responsive_wrapper data-video",
                                                        staticStyle: {
                                                          height: "100%",
                                                          left: "0",
                                                          position: "absolute",
                                                          top: "0",
                                                          width: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c("iframe", {
                                                          staticClass:
                                                            "wistia_embed",
                                                          attrs: {
                                                            src:
                                                              _vm.preIframUrl +
                                                              _vm.video
                                                                .attributes
                                                                .wistia_code +
                                                              _vm.postIframUrl,
                                                            title: "Video.mp4",
                                                            name: "wistia_embed",
                                                            allowtransparency:
                                                              "true",
                                                            scrolling: "no",
                                                            allowfullscreen: "",
                                                            mozallowfullscreen:
                                                              "",
                                                            webkitallowfullscreen:
                                                              "",
                                                            oallowfullscreen:
                                                              "",
                                                            msallowfullscreen:
                                                              "",
                                                            width: "100%",
                                                            height: "100%",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.tab == "slide"
                    ? _c(
                        "div",
                        {
                          staticClass: "modal-interstitial-pane",
                          attrs: { id: "slide" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Title")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Card Title",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.name,
                                                    expression: "name",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  id: "name",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                },
                                                domProps: { value: _vm.name },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.name =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2820331040
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Description")]),
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { name: "Description" } },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.description,
                                            expression: "description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "5",
                                        },
                                        domProps: { value: _vm.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.description =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "btn-container" }, [
                                _c("input", {
                                  ref: "fileInput",
                                  staticStyle: { display: "none" },
                                  attrs: { type: "file", accept: "image/jpeg" },
                                  on: { change: _vm.onFileSelect },
                                }),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn mb-2 secondary medium",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.fileInput.click()
                                      },
                                    },
                                  },
                                  [_vm._v("Upload new images")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn mb-2 secondary medium",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.show("sliderImages")
                                      },
                                    },
                                  },
                                  [_vm._v("Select from Library")]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              !_vm.imagePreview
                                ? _c("div", { staticClass: "slide-empty" }, [
                                    _c("img", {
                                      attrs: {
                                        alt: "images",
                                        src: require("../../assets/images/svg/image-placeholder.svg"),
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-12 px-0" }, [
                                _vm.imagePreview
                                  ? _c("div", {}, [
                                      _c("img", {
                                        attrs: {
                                          alt: "images",
                                          src: _vm.imagePreview,
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "form-group mt-3 mb-3" },
                                  [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c("label", [_vm._v("Image Caption")]),
                                      _c(
                                        "label",
                                        { staticClass: "switch ml-auto" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.imageCaption,
                                                expression: "imageCaption",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.imageCaption
                                              )
                                                ? _vm._i(
                                                    _vm.imageCaption,
                                                    null
                                                  ) > -1
                                                : _vm.imageCaption,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.imageCaption,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.imageCaption =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.imageCaption = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.imageCaption = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "slider round",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.imageTitle,
                                          expression: "imageTitle",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Add Caption",
                                      },
                                      domProps: { value: _vm.imageTitle },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.imageTitle = $event.target.value
                                          },
                                          _vm.getTitle,
                                        ],
                                      },
                                    }),
                                  ]
                                ),
                                _vm.slider.length
                                  ? _c(
                                      "div",
                                      { staticClass: "plot-thumbs" },
                                      [
                                        _c(
                                          "VueSlickCarousel",
                                          _vm._b(
                                            {},
                                            "VueSlickCarousel",
                                            _vm.settings,
                                            false
                                          ),
                                          _vm._l(
                                            _vm.slider,
                                            function (slide, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: slide.id,
                                                  staticClass: "owl-item",
                                                },
                                                [
                                                  !slide.destroy
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "item",
                                                          class: [
                                                            i == _vm.index
                                                              ? "active"
                                                              : "",
                                                          ],
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "plot-btn",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeSliderImage(
                                                                      slide.id,
                                                                      slide.sliderId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("em", {
                                                                staticClass:
                                                                  "icon-close",
                                                              }),
                                                            ]
                                                          ),
                                                          _c("img", {
                                                            attrs: {
                                                              alt: "images",
                                                              src: slide.image,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.slideImage(
                                                                  i,
                                                                  slide.title,
                                                                  slide.image
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "plot-slider",
                                  staticStyle: { display: "none" },
                                },
                                [
                                  _c("div", { staticClass: "item" }, [
                                    _vm.imagePreview != false
                                      ? _c("img", {
                                          attrs: {
                                            alt: "images",
                                            src: _vm.imagePreview,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("div", {
                                      staticClass: "plot-slider-title",
                                    }),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.tab == "file"
                    ? _c(
                        "div",
                        {
                          staticClass: "modal-interstitial-pane",
                          attrs: { id: "pdf" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Title")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Card Title",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.name,
                                                    expression: "name",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  id: "name",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                },
                                                domProps: { value: _vm.name },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.name =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2820331040
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Description")]),
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { name: "Description" } },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.description,
                                            expression: "description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "5",
                                        },
                                        domProps: { value: _vm.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.description =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "btn-container" }, [
                                _c("input", {
                                  ref: "fileInput",
                                  staticStyle: { display: "none" },
                                  attrs: { type: "file", accept: ".pdf, .doc" },
                                  on: { change: _vm.onDocSelect },
                                }),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn mb-2 secondary medium",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.fileInput.click()
                                      },
                                    },
                                  },
                                  [_vm._v("Upload new Pdf")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn mb-2 secondary medium",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.show("uploadDocument")
                                      },
                                    },
                                  },
                                  [_vm._v("Select from Library")]
                                ),
                              ]),
                            ]),
                            _vm.doc.attributes
                              ? _c("div", { staticClass: "col-6" }, [
                                  _c("div", { staticClass: "form-pdf-group" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-pdf-item" },
                                      [
                                        _c(
                                          "a",
                                          { attrs: { "data-v-248432b2": "" } },
                                          [
                                            _c("img", {
                                              attrs: {
                                                "data-v-248432b2": "",
                                                src: require("@/assets/images/pdf-big-icon.png"),
                                                alt: "pdf",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "close-pdf",
                                            attrs: {
                                              "data-v-248432b2": "",
                                              href: "javascript:void(0);",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removePdf()
                                              },
                                            },
                                          },
                                          [
                                            _c("em", {
                                              staticClass: "icon-close",
                                              attrs: { "data-v-248432b2": "" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.tab == "text"
                    ? _c(
                        "div",
                        {
                          staticClass: "modal-interstitial-pane",
                          attrs: { id: "rich-text" },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Title")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Card Title",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.name,
                                                    expression: "name",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  id: "name",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                },
                                                domProps: { value: _vm.name },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.name =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2820331040
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Description")]),
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { name: "Description" } },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.description,
                                            expression: "description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "9",
                                        },
                                        domProps: { value: _vm.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.description =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group group-editor" },
                                [
                                  _c("label", [_vm._v("Add Description")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Description",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("vue-editor", {
                                                attrs: {
                                                  id: "Descriptiontext",
                                                  placeholder:
                                                    "Add a Description",
                                                  editorToolbar: _vm.fullTool,
                                                },
                                                model: {
                                                  value: _vm.contentDescription,
                                                  callback: function ($$v) {
                                                    _vm.contentDescription = $$v
                                                  },
                                                  expression:
                                                    "contentDescription",
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2438103456
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "modal-footer mt-4" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn default",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.hide("uploadInterstitialModal")
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm.content === "adaptive"
                  ? _c(
                      "button",
                      {
                        staticClass: "btn primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.saveContent("uploadInterstitialModal")
                          },
                        },
                      },
                      [_vm._v("Save Adaptive Content ")]
                    )
                  : _vm._e(),
                _vm.content != "adaptive"
                  ? _c(
                      "button",
                      {
                        staticClass: "btn primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.saveContent("uploadInterstitialModal")
                          },
                        },
                      },
                      [_vm._v("Done ")]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "uploadDocument",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-pdf-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Upload a Pdf")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("form", { staticClass: "header-form" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchDoc,
                        expression: "searchDoc",
                      },
                      {
                        name: "debounce",
                        rawName: "v-debounce:700ms",
                        value: _vm.searchDocument,
                        expression: "searchDocument",
                        arg: "700ms",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      id: "exampleInputEmail1",
                      "aria-describedby": "emailHelp",
                      placeholder: "Search",
                    },
                    domProps: { value: _vm.searchDoc },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchDoc = $event.target.value
                      },
                    },
                  }),
                  _c("em", { staticClass: "icon-search" }),
                ]),
              ]),
              _vm.docLists.length > 0
                ? _c(
                    "div",
                    { staticClass: "modal-images-content" },
                    [
                      _c(
                        "v-infinite-scroll",
                        {
                          staticClass: "modal-content-scroll",
                          attrs: { offset: 20 },
                          on: { bottom: _vm.nextPage },
                        },
                        [
                          _c("div", { staticClass: "modal-images-library" }, [
                            _vm.docLists.length
                              ? _c(
                                  "div",
                                  { staticClass: "modal-images-row" },
                                  _vm._l(_vm.docLists, function (doc) {
                                    return _c(
                                      "div",
                                      {
                                        key: doc.id,
                                        staticClass: "modal-images-col",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "modal-images-checkbox",
                                            on: {
                                              click: function ($event) {
                                                _vm.$modal.hide(
                                                  "uploadDocument"
                                                )
                                                _vm.saveDoc(doc)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "custom-checkbox",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        doc.attributes.status,
                                                      expression:
                                                        "doc.attributes.status",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    name: "image",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      doc.attributes.status
                                                    )
                                                      ? _vm._i(
                                                          doc.attributes.status,
                                                          null
                                                        ) > -1
                                                      : doc.attributes.status,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          doc.attributes.status,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              doc.attributes,
                                                              "status",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              doc.attributes,
                                                              "status",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          doc.attributes,
                                                          "status",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "checkmark circle",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modal-images-box",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "modal-pdf-icon",
                                                      attrs: {
                                                        alt: "pdf",
                                                        src: require("@/assets/images/pdf-big-icon.png"),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modal-images-info",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(
                                                          doc.attributes
                                                            .file_name
                                                        )
                                                      ),
                                                    ]),
                                                    doc.attributes.tag_list
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              doc.attributes
                                                                .tag_list
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("div", {
                                                  staticClass: "checked",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.docLists.length === 0
                ? _c("div", { staticClass: "data-no-result" }, [
                    _c("h3", [_vm._v("No results found")]),
                    _c("p", [_vm._v("Try using a different keyword")]),
                  ])
                : _vm._e(),
            ]),
            _vm.docLists.length > 0
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("uploadDocument")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.saveDoc()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "sliderImages",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Upload Images")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("div", { staticClass: "modal-images-tab" }, [
                _c("a", { staticClass: "active", attrs: { href: "" } }, [
                  _vm._v("Library"),
                ]),
              ]),
              _c("form", { staticClass: "header-form" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchImg,
                        expression: "searchImg",
                      },
                      {
                        name: "debounce",
                        rawName: "v-debounce:700ms",
                        value: _vm.searchImage,
                        expression: "searchImage",
                        arg: "700ms",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      id: "exampleInputEmail1",
                      "aria-describedby": "emailHelp",
                      placeholder: "Search",
                    },
                    domProps: { value: _vm.searchImg },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchImg = $event.target.value
                      },
                    },
                  }),
                  _c("em", { staticClass: "icon-search" }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "modal-images-content" },
                [
                  _c(
                    "v-infinite-scroll",
                    {
                      staticClass: "modal-content-scroll",
                      attrs: { offset: 20 },
                      on: { bottom: _vm.nextImagesPage },
                    },
                    [
                      _c("div", { staticClass: "modal-images-library" }, [
                        _c(
                          "div",
                          { staticClass: "modal-images-row" },
                          _vm._l(_vm.imageLists, function (images) {
                            return _c(
                              "div",
                              {
                                key: images.id,
                                staticClass: "modal-images-col",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "modal-images-checkbox" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "custom-checkbox" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: images.attributes.status,
                                              expression:
                                                "images.attributes.status",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              images.attributes.status
                                            )
                                              ? _vm._i(
                                                  images.attributes.status,
                                                  null
                                                ) > -1
                                              : images.attributes.status,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  images.attributes.status,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      images.attributes,
                                                      "status",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      images.attributes,
                                                      "status",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  images.attributes,
                                                  "status",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "checkmark circle",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "modal-images-box" },
                                          [
                                            images.attributes.resource_url
                                              .large_version
                                              ? _c("img", {
                                                  attrs: {
                                                    src: images.attributes
                                                      .resource_url
                                                      .large_version,
                                                    alt: "image",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _vm.imageLists.length === 0
                    ? _c("div", { staticClass: "data-no-result" }, [
                        _c("h3", [_vm._v("No results found")]),
                        _c("p", [_vm._v("Try using a different keyword")]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.imageLists.length > 0
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.hide("sliderImages")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.$modal.hide("sliderImages")
                          _vm.saveImages()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "cropperImages",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-cropper-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "modal-images-cropper" }, [
                _vm.image
                  ? _c(
                      "div",
                      { staticClass: "modal-cropper-large" },
                      [
                        _c("cropper", {
                          ref: "cropper",
                          attrs: {
                            src: _vm.image,
                            "stencil-props": { aspectRatio: 16 / 9 },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cropperImages")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.crop()
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ]),
          ]),
        ]
      ),
      _c("AddVideo"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }