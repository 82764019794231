var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c("header", { staticClass: "header" }, [
        _c("div", { staticClass: "header-left" }, [
          _c("h2", [
            _vm._v(
              _vm._s(
                this.$route.params.id
                  ? `Updating Skill - ${_vm.llm_skill_name}`
                  : "Creating Skill"
              )
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveUpdateLlmSkill.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "skillName" } }, [
                    _vm._v("Skill Name"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.llm_skill_name,
                        expression: "llm_skill_name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", id: "skillName" },
                    domProps: { value: _vm.llm_skill_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.llm_skill_name = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "skillDescription" } }, [
                    _vm._v("Skill Description"),
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.llm_skill_description,
                        expression: "llm_skill_description",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "skillDescription", rows: "3" },
                    domProps: { value: _vm.llm_skill_description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.llm_skill_description = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "skillType" } }, [
                    _vm._v("Auto Create skill set?"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.llm_skill_auto_create_skill_set,
                        expression: "llm_skill_auto_create_skill_set",
                      },
                    ],
                    staticClass: "form-control auto-create-skill-set-check",
                    attrs: { type: "checkbox", id: "skillType" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.llm_skill_auto_create_skill_set
                      )
                        ? _vm._i(_vm.llm_skill_auto_create_skill_set, null) > -1
                        : _vm.llm_skill_auto_create_skill_set,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.llm_skill_auto_create_skill_set,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.llm_skill_auto_create_skill_set = $$a.concat(
                                [$$v]
                              ))
                          } else {
                            $$i > -1 &&
                              (_vm.llm_skill_auto_create_skill_set = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.llm_skill_auto_create_skill_set = $$c
                        }
                      },
                    },
                  }),
                ]),
                _vm._m(0),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-6" }, [
            _c("h3", [_vm._v("Skill Testing")]),
            _c(
              "div",
              { staticClass: "d-flex flex-column w-100 mb-5" },
              _vm._l(_vm.messageList, function (messages, index) {
                return _c("div", { key: index, staticClass: "mb-5" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-white text-dark font-weight-bold rounded border p-3 mb-3 learner-message",
                    },
                    [_c("p", [_vm._v(_vm._s(messages.learner))])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-white text-dark font-weight-bold rounded border p-3 mb-3 float-right character-message",
                    },
                    [
                      messages.character === true
                        ? _c("p", { staticClass: "text-success" }, [
                            _vm._v("This demonstrates the skill! "),
                          ])
                        : _vm._e(),
                      messages.character === false
                        ? _c("p", { staticClass: "text-danger" }, [
                            _vm._v("This does not demonstrate the skill!"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.testMessage,
                    expression: "testMessage",
                  },
                ],
                staticClass: "form-control mb-5",
                attrs: { type: "text", placeholder: "Type test message here" },
                domProps: { value: _vm.testMessage },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.testMessage = $event.target.value
                  },
                },
              }),
              _c("div", { staticClass: "chat-button-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitTestMessage.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Test Skill")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.resetInteraction.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Reset Interaction")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Save")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }