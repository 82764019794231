var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "learning-form-header flex-wrap" }, [
        _c("h4", [_vm._v("Interaction(Email)")]),
        _c("div", { staticClass: "learning-quiz-btn" }, [
          _vm.status == "published"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn secondary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.restore("published")
                    },
                  },
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _vm.status == "drafted"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn primary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.restore("published")
                    },
                  },
                },
                [_vm._v("Publish LO")]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "btn header-btn ml-2 secondary",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.$modal.show("cloneConfirmationModal")
                },
              },
            },
            [_vm._v("Clone LO")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "learning-lo-tabs" }, [
        _c("div", { staticClass: "setup-container" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[0] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("basic-setup", 0)
                  },
                },
              },
              [_vm._m(0)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[1] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("intent-setup", 1)
                  },
                },
              },
              [_vm._m(1)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[2] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("entity-setup", 2)
                  },
                },
              },
              [_vm._m(2)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[3] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("response-formulas", 3)
                  },
                },
              },
              [_vm._m(3)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[4] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("skill-setup", 4)
                  },
                },
              },
              [_vm._m(4)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[5] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("email-testing", 5)
                  },
                },
              },
              [_vm._m(5)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[6] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("qa-and-interstitial", 6)
                  },
                },
              },
              [_vm._m(6)]
            ),
            _vm.overallInteraction
              ? _c(
                  "li",
                  {
                    class: { active: _vm.isActiveTab[7] },
                    on: {
                      click: function ($event) {
                        return _vm.currentStep("overall-assessment", 7)
                      },
                    },
                  },
                  [_vm._m(7)]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "learning-quiz-content pt-4" },
        [
          _vm.step == "basic-setup"
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _vm._m(8),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Interaction title")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Interaction Title",
                                  rules: "required|max:100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: { type: "text" },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  268414483
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Administrative notes")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Administrative Notes",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.adminNotes,
                                                expression: "adminNotes",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "Description",
                                              id: "notes",
                                              rows: "4",
                                            },
                                            domProps: { value: _vm.adminNotes },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.adminNotes =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  599525079
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [
                              _vm._v("Learner iteration explanation"),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.learnerExplanation,
                                  expression: "learnerExplanation",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "Description",
                                id: "explanation",
                                rows: "4",
                              },
                              domProps: { value: _vm.learnerExplanation },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.learnerExplanation = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "form-group mb-2" }, [
                            _c(
                              "div",
                              { staticClass: "form-checkboxes d-inline-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-checkbox right",
                                    attrs: { name: "userOverall" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.overallInteraction,
                                          expression: "overallInteraction",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "userOverall",
                                      },
                                      domProps: {
                                        value: "userOverall",
                                        checked: Array.isArray(
                                          _vm.overallInteraction
                                        )
                                          ? _vm._i(
                                              _vm.overallInteraction,
                                              "userOverall"
                                            ) > -1
                                          : _vm.overallInteraction,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.overallInteraction,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "userOverall",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.overallInteraction =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.overallInteraction = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.overallInteraction = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "checkmark",
                                      attrs: { for: "userOverall" },
                                    }),
                                    _vm._v(
                                      " Use overall interaction assessment "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm.overallInteraction
                            ? _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("label", [_vm._v("Competency code")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.competencyCode,
                                          expression: "competencyCode",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-md ml-3",
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.competencyCode },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.competencyCode =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.overallInteraction
                            ? _c(
                                "div",
                                { staticClass: "form-group pt-1 mb-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          attrs: { name: "quizAssessment" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.enableLearnerIteration,
                                                expression:
                                                  "enableLearnerIteration",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "quizAssessment",
                                            },
                                            domProps: {
                                              value: "userOverall",
                                              checked: Array.isArray(
                                                _vm.enableLearnerIteration
                                              )
                                                ? _vm._i(
                                                    _vm.enableLearnerIteration,
                                                    "userOverall"
                                                  ) > -1
                                                : _vm.enableLearnerIteration,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.enableLearnerIteration,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "userOverall",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.enableLearnerIteration =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.enableLearnerIteration =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.enableLearnerIteration =
                                                    $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "quizAssessment" },
                                          }),
                                          _vm._v(" Enable learner iteration? "),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.enableLearnerIteration && _vm.overallInteraction
                            ? _c("div", { staticClass: "form-group mb-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("label", [_vm._v("Iteration levels?")]),
                                    _c("div", { staticClass: "w-80 ml-3" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.iterationLevel,
                                            expression: "iterationLevel",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          onkeydown:
                                            "javascript: return event.keyCode == 69 ? false : true",
                                          min: "1",
                                          max: "5",
                                        },
                                        domProps: { value: _vm.iterationLevel },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.displayReduceIterationModal()
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.iterationLevel =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.enableLearnerIteration
                            ? _c(
                                "div",
                                { staticClass: "form-group pt-1 mb-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          attrs: { name: "newItrLogic" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.newIterationLogic,
                                                expression: "newIterationLogic",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "newItrLogic",
                                            },
                                            domProps: {
                                              value: "userOverall",
                                              checked: Array.isArray(
                                                _vm.newIterationLogic
                                              )
                                                ? _vm._i(
                                                    _vm.newIterationLogic,
                                                    "userOverall"
                                                  ) > -1
                                                : _vm.newIterationLogic,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.newIterationLogic,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "userOverall",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.newIterationLogic =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.newIterationLogic =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.newIterationLogic = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "newItrLogic" },
                                          }),
                                          _vm._v(" Use new iteration logic? "),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.overallInteraction
                            ? _c(
                                "div",
                                { staticClass: "form-group pt-1 mb-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          attrs: {
                                            name: "displayOverallAssessment",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.displayOverallAssessment,
                                                expression:
                                                  "displayOverallAssessment",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "displayOverallAssessment",
                                            },
                                            domProps: {
                                              value: "userOverall",
                                              checked: Array.isArray(
                                                _vm.displayOverallAssessment
                                              )
                                                ? _vm._i(
                                                    _vm.displayOverallAssessment,
                                                    "userOverall"
                                                  ) > -1
                                                : _vm.displayOverallAssessment,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.displayOverallAssessment,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "userOverall",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.displayOverallAssessment =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.displayOverallAssessment =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.displayOverallAssessment =
                                                    $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "quizAssessment" },
                                          }),
                                          _vm._v(
                                            " Display overall assessment in learner feedback? "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Learner Description")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Description",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("vue-editor", {
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "form-control invalid"
                                                : "form-control",
                                            ],
                                            attrs: {
                                              id: "Description",
                                              editorToolbar: _vm.fullTool,
                                              placeholder: "Add a Description",
                                            },
                                            model: {
                                              value: _vm.description,
                                              callback: function ($$v) {
                                                _vm.description = $$v
                                              },
                                              expression: "description",
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1400623348
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c(
                            "span",
                            [
                              _c("ValidationObserver", { ref: "subform" }, [
                                _c("form", { staticClass: "w-100" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row custom-row mt-0" },
                                    [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group customer-dropdown",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-label",
                                                attrs: {
                                                  for: "exampleInputEmail1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Select email to character"
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.searchCharacter,
                                                  expression: "searchCharacter",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Search by name, role & organization",
                                              },
                                              domProps: {
                                                value: _vm.searchCharacter,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.searchCharacter =
                                                      $event.target.value
                                                  },
                                                  _vm.filterCharacter,
                                                ],
                                              },
                                            }),
                                            _vm.error
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Select email to character is required"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "chip-container pt-2 pb-0 entity-chip",
                                              },
                                              _vm._l(
                                                _vm.selectedChar,
                                                function (char, i) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: char.id,
                                                      staticClass: "chip",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            char.char_full_name
                                                          )
                                                        ),
                                                      ]),
                                                      _c("em", {
                                                        staticClass:
                                                          "icon-close",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteSeletedChar(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm.searchCharacter.length > 0 &&
                                            _vm.charStatus
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "user-row-scroll custom-user-dropdown",
                                                  },
                                                  _vm._l(
                                                    _vm.searchCharacters,
                                                    function (character) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: character.id,
                                                          staticClass:
                                                            "user-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "img-container",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-box",
                                                                },
                                                                [
                                                                  character.char_photo_url
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: character.char_photo_url,
                                                                              alt: "image",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "user-info",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "head",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character.char_full_name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character.world_role
                                                                        ) +
                                                                          " at " +
                                                                          _vm._s(
                                                                            character.org_name
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        character.char_age
                                                                      ) +
                                                                        " , " +
                                                                        _vm._s(
                                                                          character.char_gender
                                                                        )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          character.status
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-action",
                                                                },
                                                                [
                                                                  _c("em", {
                                                                    staticClass:
                                                                      "icon-check",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Added"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !character.status
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-action",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "add-action",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addEmailChar(
                                                                              character
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "+"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group customer-dropdown",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-label",
                                                attrs: {
                                                  for: "exampleInputEmail1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Select email cc character(s) (optional)"
                                                ),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.searchCcCharacter,
                                                  expression:
                                                    "searchCcCharacter",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Search by name, role & organization",
                                              },
                                              domProps: {
                                                value: _vm.searchCcCharacter,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.searchCcCharacter =
                                                      $event.target.value
                                                  },
                                                  _vm.filterCcCharacter,
                                                ],
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "chip-container pt-2 pb-0 entity-chip",
                                              },
                                              _vm._l(
                                                _vm.selectedCcChar,
                                                function (char, i) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: char.id,
                                                      staticClass: "chip",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            char.char_full_name
                                                          )
                                                        ),
                                                      ]),
                                                      _c("em", {
                                                        staticClass:
                                                          "icon-close",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteSeletedCcChar(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm.searchCcCharacter.length > 0 &&
                                            _vm.optionalCharStatus
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "user-row-scroll custom-user-dropdown",
                                                  },
                                                  _vm._l(
                                                    _vm.searchOptionalCharacters,
                                                    function (character) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: character.id,
                                                          staticClass:
                                                            "user-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "img-container",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "img-box",
                                                                },
                                                                [
                                                                  character.char_photo_url
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: character.char_photo_url,
                                                                              alt: "image",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "user-info",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "head",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character.char_full_name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character.world_role
                                                                        ) +
                                                                          " at " +
                                                                          _vm._s(
                                                                            character.org_name
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        character.char_age
                                                                      ) +
                                                                        " , " +
                                                                        _vm._s(
                                                                          character.char_gender
                                                                        )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          character.status
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-action",
                                                                },
                                                                [
                                                                  _c("em", {
                                                                    staticClass:
                                                                      "icon-check",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Added"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !character.status
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-action",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "add-action",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addEmailCcChar(
                                                                              character
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "+"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [
                                _vm._v(
                                  "Select assessment scheme for the skills"
                                ),
                              ]),
                              _c(
                                "ValidationProvider",
                                { attrs: { name: "Assessment Scheme" } },
                                [
                                  _c("div", { staticClass: "quiz-dropdown" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary dropdown-toggle",
                                        class: [
                                          _vm.isActive &&
                                          !_vm.assessmentListKey.id
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "button",
                                          id: "entity",
                                          "data-toggle": "dropdown",
                                          "aria-haspopup": "true",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.assessmentListKey.name) +
                                            " "
                                        ),
                                        _c("em", {
                                          staticClass: "icon-carrot-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: { "aria-labelledby": "entity" },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { value: "0" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectedAssessment(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("None")]
                                        ),
                                        _vm._l(
                                          _vm.assessmentLists,
                                          function (assessment) {
                                            return _c(
                                              "option",
                                              {
                                                key: assessment.id,
                                                staticClass: "dropdown-item",
                                                domProps: {
                                                  value: `${assessment.id}*${assessment.attributes.name}`,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectedAssessment(
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    assessment.attributes.name
                                                  )
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  !_vm.assessmentListKey.id
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "Assessment scheme field is required"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group pt-1 mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-checkboxes d-inline-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-checkbox right",
                                    attrs: { name: "hideAssessmentLabel" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.hideAssessmentLabel,
                                          expression: "hideAssessmentLabel",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "hideAssessmentLabel",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.hideAssessmentLabel
                                        )
                                          ? _vm._i(
                                              _vm.hideAssessmentLabel,
                                              null
                                            ) > -1
                                          : _vm.hideAssessmentLabel,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.hideAssessmentLabel,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.hideAssessmentLabel =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.hideAssessmentLabel = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.hideAssessmentLabel = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "checkmark",
                                      attrs: { for: "hideAssessmentLabel" },
                                    }),
                                    _vm._v(
                                      " Hide assessment labels in learner application? "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "form-group pt-1 mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-checkboxes d-inline-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-checkbox right",
                                    attrs: { name: "enableLearnerRetries" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.enableLearnerRetries,
                                          expression: "enableLearnerRetries",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "enableLearnerRetries",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.enableLearnerRetries
                                        )
                                          ? _vm._i(
                                              _vm.enableLearnerRetries,
                                              null
                                            ) > -1
                                          : _vm.enableLearnerRetries,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.enableLearnerRetries,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.enableLearnerRetries =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.enableLearnerRetries = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.enableLearnerRetries = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "checkmark",
                                      attrs: { for: "quizAssessment" },
                                    }),
                                    _vm._v(" Enable learner retries "),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm.enableLearnerRetries
                            ? _c(
                                "div",
                                { staticClass: "form-group pt-1 mb-3" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { name: "maxNumberOfRetries" } },
                                    [_vm._v(" Max number of retries ")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.maxNumberOfRetries,
                                        expression: "maxNumberOfRetries",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      name: "maxNumberOfRetries",
                                    },
                                    domProps: { value: _vm.maxNumberOfRetries },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.maxNumberOfRetries =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "form-group pt-1 mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-checkboxes d-inline-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-checkbox right",
                                    attrs: { name: "displayOverallAssessment" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.introEmailStatus,
                                          expression: "introEmailStatus",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "addIntroEmail",
                                      },
                                      domProps: {
                                        value: "userOverall",
                                        checked: Array.isArray(
                                          _vm.introEmailStatus
                                        )
                                          ? _vm._i(
                                              _vm.introEmailStatus,
                                              "userOverall"
                                            ) > -1
                                          : _vm.introEmailStatus,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.introEmailStatus,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "userOverall",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.introEmailStatus =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.introEmailStatus = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.introEmailStatus = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "checkmark",
                                      attrs: { for: "quizAssessment" },
                                    }),
                                    _vm._v(" Add Intro Email "),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm.introEmailStatus
                            ? _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Subject")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.introEmailSubject,
                                      expression: "introEmailSubject",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Add Subject",
                                  },
                                  domProps: { value: _vm.introEmailSubject },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.introEmailSubject =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm.introEmailStatus
                            ? _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Add Email Body")]),
                                  _c("vue-editor", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "Email Body",
                                      editorToolbar: _vm.fullTool,
                                      placeholder: "Add Email Body",
                                    },
                                    model: {
                                      value: _vm.introEmailBody,
                                      callback: function ($$v) {
                                        _vm.introEmailBody = $$v
                                      },
                                      expression: "introEmailBody",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.introEmailStatus
                            ? _c("div", { staticClass: "btn-container" }, [
                                _c("input", {
                                  ref: "fileInput",
                                  staticStyle: { display: "none" },
                                  attrs: { type: "file", accept: ".pdf" },
                                  on: { change: _vm.onDocSelect },
                                }),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn ml-2 mb-2 secondary medium pdf-button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.fileInput.click()
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "pin-image",
                                      attrs: {
                                        src: require("../../assets/images/pin-icon.png"),
                                        alt: "pin",
                                      },
                                    }),
                                    _vm._v(" Attach a File"),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.introEmailStatus
                            ? _c("div", { staticClass: "col-sm-7" }, [
                                _vm.docs.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "form-pdf-group" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-pdf-item" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$modal.show(
                                                      "pdfModal",
                                                      {
                                                        pdfUrl:
                                                          _vm.docs[0].attributes
                                                            .resource_url,
                                                      }
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("../../assets/images/pdf-big-icon.png"),
                                                    alt: "pdf",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "close-pdf",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeDoc()
                                                  },
                                                },
                                              },
                                              [
                                                _c("em", {
                                                  staticClass: "icon-close",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "col-12 pt-5" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-end mb-4" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveEmaillo("drafted", 1)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$parent.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.step === "intent-setup"
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("h4", { staticClass: "head-title" }, [
                        _vm._v("Intents Setup"),
                      ]),
                      _c("div", { staticClass: "learning-quiz-btn" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-auto header-btn secondary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.linkIntent($event, "dialog_skill")
                              },
                            },
                          },
                          [_vm._v("Link to Assistant Dialog Skill")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-auto header-btn secondary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.linkIntent($event, "sync_assistant")
                              },
                            },
                          },
                          [_vm._v("Sync with Assistant")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "btn btn-auto header-btn secondary btn-type",
                          },
                          [
                            _c("input", {
                              attrs: { type: "file" },
                              on: { change: _vm.linkIntent },
                            }),
                            _vm._v(" Import CSV "),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "big-label" }, [
                              _vm._v("Intents in this interaction"),
                            ]),
                            _vm.intentLists
                              ? _c(
                                  "ul",
                                  { staticClass: "form-entity-tabs" },
                                  _vm._l(_vm.intentLists, function (item) {
                                    return _c(
                                      "li",
                                      {
                                        key: item.id,
                                        class:
                                          item.id === _vm.selectedAsstIntent.id
                                            ? "active"
                                            : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectedIntent(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.attributes &&
                                                    item.attributes.name
                                                ) +
                                                " "
                                            ),
                                            _c("span", [
                                              _c("em", {
                                                staticClass: "icon-close",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteIntent(
                                                      item.id
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-group pt-4" }, [
                            _c("label", [_vm._v("Add Intent")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.emailIntentName,
                                  expression: "emailIntentName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", id: "name" },
                              domProps: { value: _vm.emailIntentName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.emailIntentName = $event.target.value
                                },
                              },
                            }),
                            _c("div", { staticClass: "form-btn-group" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addEmailIntent()
                                    },
                                  },
                                },
                                [_vm._v("Add Intent")]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c(
                            "div",
                            { staticClass: "entity-tabs-content mt-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Description")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Description",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.intentDesc,
                                                    expression: "intentDesc",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  id: "Description",
                                                },
                                                domProps: {
                                                  value: _vm.intentDesc,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.intentDesc =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3400044150
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Example user input")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "User input",
                                      rules: "required|max:300",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.intentInput,
                                                    expression: "intentInput",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  id: "Value",
                                                },
                                                domProps: {
                                                  value: _vm.intentInput,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.intentInput =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3456674979
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "add-more-btn w-90",
                                    attrs: { href: "javascript:void(0);" },
                                    on: { click: _vm.addIntentValues },
                                  },
                                  [_vm._v("+ Add")]
                                ),
                              ]),
                              _vm.selectedAsstIntent &&
                              _vm.selectedAsstIntent.attributes
                                ? _c(
                                    "div",
                                    { staticClass: "quiz-table-data" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "quiz-table-head row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "quiz-tab-col col-10",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedAsstIntent
                                                      .attributes.description
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "quiz-tab-col col-2",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "quiz-table-content" },
                                        _vm._l(
                                          _vm.selectedAsstIntent.attributes
                                            .asst_intent_examples,
                                          function (values) {
                                            return _c(
                                              "div",
                                              {
                                                key: values.id,
                                                staticClass: "quiz-table-list",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "quiz-tabs-row row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "quiz-tab-col col-10",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                values
                                                                  .attributes
                                                                  .example
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "quiz-tab-col col-2 text-right",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                          },
                                                          [
                                                            _c("em", {
                                                              staticClass:
                                                                "icon-close",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteIntentValue(
                                                                      values
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "col-sm-12 mt-5" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.next("entity-setup", 4)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$parent.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.step === "entity-setup"
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("h4", { staticClass: "head-title" }, [
                        _vm._v("Entities Setup"),
                      ]),
                      _c("div", { staticClass: "learning-quiz-btn" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-auto header-btn secondary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.linkEntitiesData(
                                  $event,
                                  "dialog_skill"
                                )
                              },
                            },
                          },
                          [_vm._v("Link to Assistant Dialog Skill")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-auto header-btn secondary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.linkEntitiesData(
                                  $event,
                                  "sync_assistant"
                                )
                              },
                            },
                          },
                          [_vm._v("Sync with Assistant")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "btn btn-auto header-btn secondary btn-type",
                          },
                          [
                            _c("input", {
                              attrs: { type: "file" },
                              on: { change: _vm.linkEntitiesData },
                            }),
                            _vm._v(" Import CSV "),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-auto header-btn secondary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.linkEntitiesData($event, "exportCsv")
                              },
                            },
                          },
                          [_vm._v("Export CSV")]
                        ),
                      ]),
                    ]
                  ),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "big-label" }, [
                              _vm._v("Assistant entities in this interaction"),
                            ]),
                            _vm.asstEntitiesList
                              ? _c(
                                  "ul",
                                  { staticClass: "form-entity-tabs" },
                                  _vm._l(_vm.asstEntitiesList, function (item) {
                                    return _c(
                                      "li",
                                      {
                                        key: item.id,
                                        class:
                                          item.id ===
                                          _vm.selectedAsstEntities.id
                                            ? "active"
                                            : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectedEntent(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.attributes &&
                                                    item.attributes.name
                                                ) +
                                                " "
                                            ),
                                            _c("span", [
                                              _c("em", {
                                                staticClass: "icon-close",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteEntent(
                                                      item.id
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-group pt-4" }, [
                            _c("label", [_vm._v("Add Assistant Entity")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.emailEntityName,
                                  expression: "emailEntityName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", id: "name" },
                              domProps: { value: _vm.emailEntityName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.emailEntityName = $event.target.value
                                },
                              },
                            }),
                            _c("div", { staticClass: "form-btn-group" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addEmailEntity($event)
                                    },
                                  },
                                },
                                [_vm._v("Create Assistant Entity")]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c(
                            "div",
                            { staticClass: "entity-tabs-content mt-0" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-6 form-group" },
                                  [
                                    _c("label", [_vm._v("Value")]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Value",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.asstName,
                                                      expression: "asstName",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: _vm.asstName,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.asstName =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1104651667
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-6 form-group" }, [
                                  _c("label", [_vm._v("Value Type")]),
                                  _c("div", [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.asstType,
                                            expression: "asstType",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.asstType = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      [
                                        _c("option", [_vm._v("Synonyms")]),
                                        _c("option", [_vm._v("Patterns")]),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm.asstType === "Synonyms"
                                  ? _c(
                                      "div",
                                      { staticClass: "col-12 form-group" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "Synonyms",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.asstSynonyms,
                                                          expression:
                                                            "asstSynonyms",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "enter synonyms here",
                                                      },
                                                      domProps: {
                                                        value: _vm.asstSynonyms,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.asstSynonyms =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1123681078
                                          ),
                                        }),
                                        _c("br"),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 form-group" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add-more-btn",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click:
                                                    _vm.addAssessmentValues,
                                                },
                                              },
                                              [_vm._v("Save Value")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm.asstType === "Patterns"
                                  ? _c(
                                      "div",
                                      { staticClass: "col-12 form-group" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "Synonyms",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.asstSynonyms,
                                                          expression:
                                                            "asstSynonyms",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      class: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "enter pattern here",
                                                      },
                                                      domProps: {
                                                        value: _vm.asstSynonyms,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.asstSynonyms =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            880364732
                                          ),
                                        }),
                                        _c("br"),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 form-group" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add-more-btn",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click:
                                                    _vm.addAssessmentValuesPatterns,
                                                },
                                              },
                                              [_vm._v("Save Value")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "quiz-table-data" }, [
                                _c(
                                  "div",
                                  { staticClass: "quiz-table-head row" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "quiz-tab-col col-4" },
                                      [_c("span", [_vm._v("Values")])]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "quiz-tab-col col-7" },
                                      [
                                        _c("span", [
                                          _vm._v("Synonyms/Patterns"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.selectedAsstEntities
                                  ? _c(
                                      "div",
                                      { staticClass: "quiz-table-content" },
                                      _vm._l(
                                        _vm.selectedAsstEntities.attributes
                                          .asst_entity_values,
                                        function (asstValue) {
                                          return _c(
                                            "div",
                                            {
                                              key: asstValue.id,
                                              staticClass: "quiz-table-list",
                                            },
                                            [
                                              asstValue.attributes
                                                .asst_entity_val_synonyms &&
                                              asstValue.attributes
                                                .asst_entity_val_synonyms.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "quiz-tabs-row row",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "quiz-tab-col col-4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              asstValue
                                                                .attributes
                                                                .value
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "quiz-tab-col col-7",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleSynonymModal(
                                                                asstValue
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "d-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$parent.getAssessmentValue(
                                                                    asstValue
                                                                      .attributes
                                                                      .asst_entity_val_synonyms
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "quiz-tab-col col-1 text-right",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "javascript:void(0);",
                                                              },
                                                            },
                                                            [
                                                              _c("em", {
                                                                staticClass:
                                                                  "icon-close",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeAsstEntities(
                                                                        asstValue
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              asstValue.attributes
                                                .asst_entity_val_patterns &&
                                              asstValue.attributes
                                                .asst_entity_val_patterns.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "quiz-tabs-row row",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "quiz-tab-col col-4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              asstValue
                                                                .attributes
                                                                .value
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "quiz-tab-col col-7",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handlePatternModal(
                                                                asstValue
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "d-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$parent.getAssessmentValuePattern(
                                                                    asstValue
                                                                      .attributes
                                                                      .asst_entity_val_patterns
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "quiz-tab-col col-1 text-right",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "javascript:void(0);",
                                                              },
                                                            },
                                                            [
                                                              _c("em", {
                                                                staticClass:
                                                                  "icon-close",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeAsstEntities(
                                                                        asstValue
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "col-sm-12 mt-5" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.next("response-formulas", 5)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$parent.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedSynonymList
            ? _c(
                "modal",
                {
                  attrs: {
                    name: "enityValueModal",
                    height: "auto",
                    width: 500,
                    scrollable: true,
                  },
                },
                [
                  _c("div", { staticClass: "modal-content modal-default" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _c(
                        "div",
                        {
                          staticClass: "modal-title",
                          attrs: { id: "exampleModalLabel" },
                        },
                        [
                          _c("h5", [
                            _vm._v(
                              "Entity value:" +
                                _vm._s(_vm.selectedSynonymList.attributes.value)
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "form",
                        {
                          staticClass: "modal-form modal-min-height",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.updateSynonymValues("add")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-group mt-2" }, [
                            _c("label", [_vm._v("Synonyms")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.entitySynonymName,
                                  expression: "entitySynonymName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.entitySynonymName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.entitySynonymName = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "chip-container entity-chip" },
                              _vm._l(
                                _vm.selectedSynonymList.attributes
                                  .asst_entity_val_synonyms,
                                function (synonym, index) {
                                  return _c(
                                    "div",
                                    { key: synonym.id, staticClass: "chip" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(synonym.attributes.synonym)
                                        ),
                                      ]),
                                      _c("em", {
                                        staticClass: "icon-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateSynonymValues(
                                              "delete",
                                              index,
                                              synonym
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: {
                                  type: "button",
                                  "data-dismiss": "modal",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide("enityValueModal")
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateSynonymValues("done")
                                  },
                                },
                              },
                              [_vm._v("Done")]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.selectedPatternList
            ? _c(
                "modal",
                {
                  attrs: {
                    name: "enityValueModalPattern",
                    height: "auto",
                    width: 500,
                    scrollable: true,
                  },
                },
                [
                  _c("div", { staticClass: "modal-content modal-default" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _c(
                        "div",
                        {
                          staticClass: "modal-title",
                          attrs: { id: "exampleModalLabel" },
                        },
                        [
                          _c("h5", [
                            _vm._v(
                              "Entity value:" +
                                _vm._s(_vm.selectedPatternList.attributes.value)
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "form",
                        {
                          staticClass: "modal-form modal-min-height",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.updateAssessmentValuesPattern("add")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "form-group mt-2" }, [
                            _c("label", [_vm._v("Patterns")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.entityPatternName,
                                  expression: "entityPatternName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.entityPatternName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.entityPatternName = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "chip-container entity-chip" },
                              _vm._l(
                                _vm.selectedPatternList.attributes
                                  .asst_entity_val_patterns,
                                function (pattern, index) {
                                  return _c(
                                    "div",
                                    { key: pattern.id, staticClass: "chip" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(pattern.attributes.pattern)
                                        ),
                                      ]),
                                      _c("em", {
                                        staticClass: "icon-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateAssessmentValuesPattern(
                                              "delete",
                                              index,
                                              pattern
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: {
                                  type: "button",
                                  "data-dismiss": "modal",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide(
                                      "enityValueModalPattern"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateAssessmentValuesPattern(
                                      "done"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Done")]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "modal",
            {
              attrs: {
                name: "cloneConfirmationModal",
                height: "auto",
                width: 350,
                scrollable: true,
              },
            },
            [
              _c("div", { staticClass: "modal-content modal-delete-content" }, [
                _c("div", { staticClass: "modal-body modal-delete-body" }, [
                  _vm._v(
                    "Are you sure you want to clone this learning object?"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "modal-footer justify-content-center" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn medium default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$modal.hide("cloneConfirmationModal")
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn medium primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.cloneLo(),
                              _vm.$modal.hide("cloneConfirmationModal")
                          },
                        },
                      },
                      [_vm._v("Ok")]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm.step === "response-formulas"
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _vm._m(9),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _vm.selectedChar
                        ? _c(
                            "div",
                            {
                              staticClass: "nav nav-tabs",
                              attrs: { id: "nav-tab", role: "tablist" },
                            },
                            _vm._l(_vm.selectedChar, function (char) {
                              return _c(
                                "a",
                                {
                                  key: char.id,
                                  class: `nav-item nav-link flex-none px-5 ${
                                    _vm.emailSelChar.id === char.id
                                      ? "active"
                                      : ""
                                  }`,
                                  attrs: {
                                    id: "response-tab",
                                    "data-toggle": "tab",
                                    href: "#nav-response",
                                    role: "tab",
                                    "aria-controls": "nav-response",
                                    "aria-selected": "true",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCharTabChange(char)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(char.char_full_name))]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "tab-content",
                          attrs: { id: "nav-tabContent" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade show active",
                              attrs: {
                                id: "nav-response",
                                role: "tabpanel",
                                "aria-labelledby": "response-tab",
                              },
                            },
                            [
                              _c("div", { staticClass: "row custom-row" }, [
                                _c("div", { staticClass: "col-sm-5" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    (_vm.emailResFormulas &&
                                      _vm.selEmailResFormulas) ||
                                    _vm.addIntro ||
                                    _vm.addConclusion
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "form-entity-tabs form-check-tabs2",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                class: _vm.addIntro
                                                  ? "active"
                                                  : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.markIntroActive()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                  },
                                                  [_vm._v("Introduction")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "draggable",
                                              {
                                                attrs: {
                                                  sort: true,
                                                  move: _vm.log,
                                                },
                                                on: {
                                                  start: function ($event) {
                                                    _vm.isDragging = true
                                                  },
                                                  end: function ($event) {
                                                    _vm.isDragging = true
                                                  },
                                                },
                                                model: {
                                                  value: _vm.emailResFormulas,
                                                  callback: function ($$v) {
                                                    _vm.emailResFormulas = $$v
                                                  },
                                                  expression:
                                                    "emailResFormulas",
                                                },
                                              },
                                              _vm._l(
                                                _vm.emailResFormulas,
                                                function (res) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: res.id,
                                                      class:
                                                        _vm.isActiveResponse(
                                                          res
                                                        )
                                                          ? "active"
                                                          : "",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelEmailResFormulas(
                                                            res
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  res.attributes
                                                                    .name
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    res
                                                                      .attributes
                                                                      .response_formulas
                                                                      .length
                                                                  ) +
                                                                    " Response Formulas"
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _vm.isActiveResponse(
                                                            res
                                                          )
                                                            ? _c("span", [
                                                                _c("em", {
                                                                  staticClass:
                                                                    "icon-close",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$modal.show(
                                                                          "deleteEmailRespModal"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c(
                                              "li",
                                              {
                                                class: _vm.addConclusion
                                                  ? "active"
                                                  : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.markConclusionActive()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                  },
                                                  [_vm._v("Conclusion")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", [_vm._v("Add Response Name")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.responseName,
                                          expression: "responseName",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", id: "name" },
                                      domProps: { value: _vm.responseName },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.responseName = $event.target.value
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "form-btn-group" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn w-100 primary",
                                            on: { click: _vm.addEmailResName },
                                          },
                                          [_vm._v("Add Response")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-sm-7" }, [
                                  _vm.addIntro
                                    ? _c(
                                        "div",
                                        { attrs: { id: "intro-form" } },
                                        [
                                          _c("EmailRespIntroConclusion", {
                                            ref: "EmailRespIntroConclusion",
                                            attrs: {
                                              addIntro: true,
                                              addConclusion: false,
                                              objectableId: _vm.objectableId,
                                              emailSelChar: _vm.emailSelChar,
                                              iterationLevel:
                                                _vm.iterationLevel,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.addIntro && !_vm.addConclusion
                                    ? _c(
                                        "div",
                                        {
                                          attrs: {
                                            id: "character-response-form",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("label", [
                                                _vm._v("Response Name"),
                                              ]),
                                              _vm.selEmailResFormulas
                                                ? _c(
                                                    "ValidationProvider",
                                                    {
                                                      attrs: {
                                                        name: "Value",
                                                        rules:
                                                          "required|max:100",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .selEmailResFormulas
                                                                .attributes
                                                                .name,
                                                            expression:
                                                              "selEmailResFormulas.attributes.name",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .selEmailResFormulas
                                                              .attributes.name,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.saveResponseName()
                                                          },
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm
                                                                .selEmailResFormulas
                                                                .attributes,
                                                              "name",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "assessment-response",
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "response-tab" },
                                                _vm._l(
                                                  _vm.iterationLevel,
                                                  function (index) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: index,
                                                        class:
                                                          _vm.selIterationLevel ===
                                                          index
                                                            ? "active"
                                                            : "",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleSelectedIteration(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Iteration " +
                                                                _vm._s(index)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm.selVariationList
                                                ? _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "response-tab",
                                                    },
                                                    _vm._l(
                                                      _vm.selVariationList,
                                                      function (vari, index) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key: vari.id,
                                                            class:
                                                              _vm.selectedVariation &&
                                                              _vm
                                                                .selectedVariation
                                                                .id === vari.id
                                                                ? "active"
                                                                : "",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getSelectedVariation(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: "javascript:void(0);",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "variation " +
                                                                    _vm._s(
                                                                      vari
                                                                        .attributes
                                                                        .variation
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "response-content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c("vue-editor", {
                                                        attrs: {
                                                          id: "Descriptiontext",
                                                          placeholder:
                                                            "Add a Variation",
                                                          editorToolbar:
                                                            _vm.fullTool,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.variationName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.variationName =
                                                              $$v
                                                          },
                                                          expression:
                                                            "variationName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "formulas-data" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "formulas-edit",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "formulas-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Response Formulas"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "add-more-btn",
                                                      attrs: {
                                                        href: "javascript:void(0);",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openResFormulaModal,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "+ Add Response Formula"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.selPresentResFormula
                                                ? _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "formulas-data-list",
                                                    },
                                                    _vm._l(
                                                      _vm.selPresentResFormula,
                                                      function (res, i) {
                                                        return _c(
                                                          "li",
                                                          { key: i },
                                                          [
                                                            res.present
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "formulas-list",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "formulas-list-title",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "formulas-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Present: "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "keywords",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Keyword: at-least " +
                                                                                    _vm._s(
                                                                                      res.present_min
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "formulas-edit-btn",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:void(0);",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editResponseFormula(
                                                                                    res,
                                                                                    i
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "em",
                                                                              {
                                                                                staticClass:
                                                                                  "icon-edit",
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " Edit "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "formulas-edit-btn",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:void(0);",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.setDestroyId(
                                                                                    res,
                                                                                    "responseFormula"
                                                                                  )
                                                                                  _vm.$modal.show(
                                                                                    "deleteResponseModal"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Delete"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    res.present
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "formulas-list-item",
                                                                          },
                                                                          _vm._l(
                                                                            res.present,
                                                                            function (
                                                                              formula,
                                                                              key,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: index,
                                                                                  staticClass:
                                                                                    "formula-item-flex",
                                                                                },
                                                                                [
                                                                                  formula
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "formula-name",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                key
                                                                                              ) +
                                                                                              ": "
                                                                                          ),
                                                                                          _vm._l(
                                                                                            formula,
                                                                                            function (
                                                                                              entity
                                                                                            ) {
                                                                                              return _c(
                                                                                                "span",
                                                                                                {
                                                                                                  key: entity.id,
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      entity.value
                                                                                                    ) +
                                                                                                      " ,"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        2
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  Object.keys(
                                                                                    res.present
                                                                                  )
                                                                                    .length -
                                                                                    1 !==
                                                                                  index
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "formula-and",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "and"
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            res.absent
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "formulas-list",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "formulas-list-title",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "formulas-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Absent: "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "keywords",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Keyword: at-least " +
                                                                                    _vm._s(
                                                                                      res.absent_min
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "formulas-list-item",
                                                                      },
                                                                      _vm._l(
                                                                        res.absent,
                                                                        function (
                                                                          formula,
                                                                          key,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: index,
                                                                              staticClass:
                                                                                "formula-item-flex",
                                                                            },
                                                                            [
                                                                              formula
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "formula-name",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            key
                                                                                          ) +
                                                                                          ": "
                                                                                      ),
                                                                                      _vm._l(
                                                                                        formula,
                                                                                        function (
                                                                                          entity
                                                                                        ) {
                                                                                          return _c(
                                                                                            "span",
                                                                                            {
                                                                                              key: entity.id,
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  entity.value
                                                                                                ) +
                                                                                                  " ,"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                : _vm._e(),
                                                                              Object.keys(
                                                                                res.absent
                                                                              )
                                                                                .length -
                                                                                1 !==
                                                                              index
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "formula-and",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "and"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.addConclusion
                                    ? _c(
                                        "div",
                                        { attrs: { id: "conclusion-form" } },
                                        [
                                          _c("EmailRespIntroConclusion", {
                                            ref: "EmailRespIntroConclusion",
                                            attrs: {
                                              addIntro: false,
                                              addConclusion: true,
                                              objectableId: _vm.objectableId,
                                              emailSelChar: _vm.emailSelChar,
                                              iterationLevel:
                                                _vm.iterationLevel,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-sm-12 mt-5" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-end mt-4 mb-3",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                on: {
                                  click: function ($event) {
                                    _vm.updateVariationOnNext(
                                      _vm.selectedVariation
                                    ),
                                      _vm.next("skill-setup", 6)
                                  },
                                },
                              },
                              [_vm._v("Next")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "btn-container justify-content-end" },
                          [
                            _c("a", { staticClass: "delete-btn" }, [
                              _c("div", { staticClass: "delete-box" }, [
                                _c("span", { staticClass: "icon-delete" }),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$parent.archiveLO("archived")
                                    },
                                  },
                                },
                                [_vm._v("Archive this Learning Object(LO)")]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "modal",
            {
              attrs: {
                name: "responseFormulaModal",
                height: "auto",
                width: "700",
                scrollable: true,
              },
            },
            [
              _c("div", { staticClass: "modal-content modal-default" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _vm.selEmailResFormulas
                    ? _c(
                        "div",
                        {
                          staticClass: "modal-title",
                          attrs: { id: "exampleModalLabel" },
                        },
                        [
                          _vm.step == "response-formulas"
                            ? _c("h5", [
                                _vm._v(
                                  _vm._s(
                                    _vm.selEmailResFormulas.attributes.name
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("form", { staticClass: "modal-form modal-min-height" }, [
                    _c("div", { staticClass: "modal-formula-content" }, [
                      _c("div", { staticClass: "formulas-tabs-list" }, [
                        _c("div", { staticClass: "formulas-tabs" }, [
                          _c(
                            "a",
                            {
                              class:
                                _vm.setSelFormulaField === "nlu_intent"
                                  ? "active"
                                  : "",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleResFormula("nlu_intent")
                                },
                              },
                            },
                            [_vm._v("Intent")]
                          ),
                          _c(
                            "a",
                            {
                              class:
                                _vm.setSelFormulaField === "assist-entity"
                                  ? "active"
                                  : "",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleResFormula("assist-entity")
                                },
                              },
                            },
                            [_vm._v("Assistant Entity")]
                          ),
                        ]),
                        _c("div", { staticClass: "formulas-tabs-content" }, [
                          _vm.selectedEvalVal
                            ? _c(
                                "div",
                                {
                                  staticClass: "formula-tab-pane active",
                                  attrs: { id: "concept" },
                                },
                                [
                                  _vm.setSelFormulaField === "nlu_intent"
                                    ? _c(
                                        "div",
                                        { staticClass: "formula-chips-list" },
                                        _vm._l(
                                          _vm.selectedEvalVal,
                                          function (nlu) {
                                            return _c(
                                              "span",
                                              {
                                                key: nlu.id,
                                                staticClass: "formula-chips",
                                              },
                                              [
                                                _c(
                                                  "drag",
                                                  {
                                                    staticClass: "drag",
                                                    attrs: {
                                                      "transfer-data": nlu,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        nlu.attributes.name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm.setSelFormulaField === "assist-entity"
                            ? _c(
                                "div",
                                {
                                  staticClass: "formula-tab-pane active",
                                  attrs: { id: "assistant-entity" },
                                },
                                _vm._l(
                                  _vm.selectedEvalVal,
                                  function (evalItem) {
                                    return _c(
                                      "div",
                                      {
                                        key: evalItem.id,
                                        staticClass: "formulas-assistant-item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "formula-chips-head" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "formula-chips-left",
                                              },
                                              [
                                                _vm._v(" Assistant Entity: "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      evalItem.attributes.name
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-checkboxes d-inline-flex",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "custom-checkbox",
                                                    attrs: {
                                                      name: "userOverall",
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleLabelEvalChange(
                                                            $event,
                                                            evalItem
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("span", {
                                                      staticClass:
                                                        "checkmark circle",
                                                      attrs: {
                                                        for: "userOverall",
                                                      },
                                                    }),
                                                    _vm._v(" Select All "),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "formula-chips-list" },
                                          [
                                            _c("label", [_vm._v("Values:")]),
                                            _vm._l(
                                              evalItem.attributes
                                                .asst_entity_values,
                                              function (valItem) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: valItem.id,
                                                    staticClass:
                                                      "formula-chips",
                                                  },
                                                  [
                                                    _c(
                                                      "drag",
                                                      {
                                                        staticClass: "drag",
                                                        attrs: {
                                                          "transfer-data": {
                                                            id: valItem.id,
                                                            asst_entity_id:
                                                              evalItem.id,
                                                            attributes:
                                                              valItem.attributes,
                                                            type: `nlu_Assistant-Entity`,
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            valItem.attributes
                                                              .value
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c("ul", { staticClass: "formulas-data-list" }, [
                        _c("li", [
                          _c(
                            "div",
                            { staticClass: "formulas-list" },
                            [
                              _c(
                                "drop",
                                {
                                  staticClass: "drop",
                                  on: {
                                    drop: function ($event) {
                                      return _vm.handleEvaluationDrop(
                                        $event,
                                        "present"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "formulas-list-title" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "formulas-left" },
                                        [
                                          _vm._v(" Present: "),
                                          _c(
                                            "span",
                                            { staticClass: "keywords" },
                                            [
                                              _vm._v(
                                                "Keyword: at-least " +
                                                  _vm._s(_vm.presentMinKeyword)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formulas-input" },
                                        [
                                          _c("h5", [
                                            _vm._v("Keyword minimum:"),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.presentMinKeyword,
                                                    expression:
                                                      "presentMinKeyword",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.presentMinKeyword,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.presentMinKeyword =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "formulas-list-item" },
                                    _vm._l(
                                      _vm.selectedPresentFormula,
                                      function (formula, key, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "formula-item-flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "formula-name" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(key) + ": "
                                                ),
                                                _vm._l(
                                                  formula,
                                                  function (entity) {
                                                    return _c(
                                                      "span",
                                                      { key: entity.id },
                                                      [
                                                        _vm._v(
                                                          _vm._s(entity.value) +
                                                            " ,"
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "formula-cross",
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFormulaEntity(
                                                          "present",
                                                          key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("em", {
                                                      staticClass: "icon-close",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                            Object.keys(
                                              _vm.selectedPresentFormula
                                            ).length -
                                              1 !==
                                            index
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "formula-and",
                                                  },
                                                  [_vm._v("and")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  Object.keys(_vm.selectedPresentFormula)
                                    .length === 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "formulas-list-placeholder",
                                        },
                                        [_vm._v("Drag and Drop here")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "div",
                            { staticClass: "formulas-list" },
                            [
                              _c(
                                "drop",
                                {
                                  staticClass: "drop",
                                  on: {
                                    drop: function ($event) {
                                      return _vm.handleEvaluationDrop(
                                        $event,
                                        "absent"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "formulas-list-title" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "formulas-left" },
                                        [
                                          _vm._v(" Absent: "),
                                          _c(
                                            "span",
                                            { staticClass: "keywords" },
                                            [
                                              _vm._v(
                                                "Keyword: at-least " +
                                                  _vm._s(_vm.absentMinKeyword)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formulas-input" },
                                        [
                                          _c("h5", [
                                            _vm._v("Keyword minimum:"),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.absentMinKeyword,
                                                    expression:
                                                      "absentMinKeyword",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.absentMinKeyword,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.absentMinKeyword =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "formulas-list-item" },
                                    _vm._l(
                                      _vm.selectedAbsentFormula,
                                      function (formula, key, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "formula-item-flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "formula-name" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(key) + ": "
                                                ),
                                                _vm._l(
                                                  formula,
                                                  function (entity) {
                                                    return _c(
                                                      "span",
                                                      { key: entity.id },
                                                      [
                                                        _vm._v(
                                                          _vm._s(entity.value) +
                                                            ","
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "formula-cross",
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFormulaEntity(
                                                          "absent",
                                                          key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("em", {
                                                      staticClass: "icon-close",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                            Object.keys(
                                              _vm.selectedAbsentFormula
                                            ).length -
                                              1 !==
                                            index
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "formula-and",
                                                  },
                                                  [_vm._v("and")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  Object.keys(_vm.selectedAbsentFormula)
                                    .length === 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "formulas-list-placeholder",
                                        },
                                        [_vm._v("Drag and Drop here")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "modal-footer mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn default",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: {
                            click: function ($event) {
                              return _vm.$modal.hide("responseFormulaModal")
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      !_vm.isFormulaEdited
                        ? _c(
                            "button",
                            {
                              staticClass: "btn primary",
                              attrs: { type: "button" },
                              on: { click: _vm.handleAddResFormula },
                            },
                            [_vm._v("Done")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn primary",
                              attrs: { type: "button" },
                              on: { click: _vm.handleEditResFormula },
                            },
                            [_vm._v("Done")]
                          ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm.step === "skill-setup" && !_vm.assessmentFormula
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _vm._m(10),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Select Skills")]),
                            _vm._v("Assessment (Skill setup) "),
                            _c(
                              "ul",
                              { staticClass: "form-entity-tabs" },
                              [
                                _c("li", [
                                  _c("div", { staticClass: "quiz-dropdown" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary dropdown-toggle",
                                        attrs: {
                                          type: "button",
                                          id: "entity",
                                          "data-toggle": "dropdown",
                                          "aria-haspopup": "true",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _c("div", [_vm._v("Add New Skills")]),
                                        _c("em", {
                                          staticClass: "icon-carrot-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: { "aria-labelledby": "entity" },
                                      },
                                      _vm._l(
                                        _vm.globalSkills,
                                        function (skill) {
                                          return _c(
                                            "option",
                                            {
                                              key: skill.id,
                                              staticClass: "dropdown-item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addSkills(skill)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    skill.attributes.name
                                                  ) +
                                                  " "
                                              ),
                                              _c("div", {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addSkills(skill)
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]),
                                _vm._l(_vm.emailSkills, function (item, ind) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.id,
                                      class:
                                        item.skillId ==
                                        _vm.selectedSkill.skillId
                                          ? "active"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectedSkills(item, ind)
                                        },
                                      },
                                    },
                                    [_c("a", [_vm._v(_vm._s(item.skillName))])]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c("div", { staticClass: "form-group pt-4" }, [
                            _c("label", [_vm._v("Add new skill")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newSkill,
                                  expression: "newSkill",
                                },
                              ],
                              staticClass: "form-control",
                              class: [
                                _vm.isActive && _vm.errors[0] ? "invalid" : "",
                              ],
                              attrs: { id: "admin_notes" },
                              domProps: { value: _vm.newSkill },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.newSkill = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "form-btn-group" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn w-100 primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.addNewSkill },
                                },
                                [_vm._v("Add new skill")]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c("div", { staticClass: "skill-title my-4" }, [
                            _c("h5", [
                              _vm._v(_vm._s(this.selectedSkill.skillName)),
                            ]),
                            this.selectedSkill.id
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "email-delete-btn",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$modal.show(
                                          "deleteSkillConfirmationModal"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("em", { staticClass: "icon-delete" }),
                                    _vm._v(" Delete skill "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [
                                _vm._v(
                                  "Skill Description (Evaluation explanation)"
                                ),
                              ]),
                              _c("ValidationProvider", {
                                attrs: { name: "Administrative Notes" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.skillDesc,
                                                expression: "skillDesc",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              id: "admin_notes",
                                              rows: "8",
                                            },
                                            domProps: { value: _vm.skillDesc },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.skillDesc =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive && errors[0]
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  333500931
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-group justify-content-end d-flex",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "add-more-btn",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.updateSkills },
                                },
                                [_vm._v("Save")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-group justify-content-end d-flex",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "add-more-btn",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.goToAssessment },
                                },
                                [_vm._v("Configure assessments")]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      _vm.updateSkills(),
                                        _vm.next("email-testing", 7)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$parent.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.step == "email-testing"
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _vm._m(11),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Submit test input")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Test Input",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.testInput,
                                                expression: "testInput",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "Description",
                                              id: "notes",
                                              rows: "4",
                                            },
                                            domProps: { value: _vm.testInput },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.testInput =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  71506839
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "form-testing-group" }, [
                              _vm.testInput.length > 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn default",
                                      attrs: { href: "javascript:void(0);" },
                                      on: { click: _vm.clearTesting },
                                    },
                                    [_vm._v("Clear")]
                                  )
                                : _vm._e(),
                              _vm.testInput.length > 50
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn primary",
                                      attrs: { href: "javascript:void(0);" },
                                      on: { click: _vm.submitTesting },
                                    },
                                    [_vm._v("Submit")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                        _vm.testEvalJsonScheme.attributes
                          ? _c("div", { staticClass: "col-sm-7" }, [
                              _vm.testEvalJsonScheme.attributes.responses
                                .length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "modal-test-mail" },
                                    [
                                      _c("h4", [_vm._v("Character Response")]),
                                      _vm._l(
                                        _vm.testEvalJsonScheme.attributes
                                          .responses,
                                        function (scheme, index) {
                                          return _c("p", {
                                            key: index,
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scheme.attributes.response
                                              ),
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "modal-test-tabs" }, [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "nav nav-tabs",
                                    attrs: { id: "myTab", role: "tablist" },
                                  },
                                  [
                                    _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link active",
                                          attrs: {
                                            id: "Test-tab",
                                            "data-toggle": "tab",
                                            href: "#Test",
                                            role: "tab",
                                            "aria-controls": "Test",
                                            "aria-selected": "true",
                                          },
                                        },
                                        [_vm._v("Test input Return Data")]
                                      ),
                                    ]),
                                    _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            id: "json-tab",
                                            "data-toggle": "tab",
                                            href: "#json",
                                            role: "tab",
                                            "aria-controls": "json",
                                            "aria-selected": "false",
                                          },
                                        },
                                        [_vm._v("View JSON")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-content pt-2",
                                    attrs: { id: "myTabContent" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade show active",
                                        attrs: {
                                          id: "Test",
                                          role: "tabpanel",
                                          "aria-labelledby": "Test-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "test-items" },
                                          [
                                            _vm.testEmailEnrichmentItems
                                              .attributes.asst_entity_value_list
                                              .length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "Assistant Entities Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.asst_entity_value_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.asst_intent_list
                                              .length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "Assistant Intents Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.asst_intent_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.keyword_list.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "NLU Keywords Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.keyword_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.concept_list.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "NLU Concepts Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.concept_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.nlu_entities_list
                                              .length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "NLU Entities Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.nlu_entities_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("div", { staticClass: "mt-3" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "NLU Emotion Scores:"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " JOY: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .joy_score
                                                          ) +
                                                          ", ANGER: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .anger_score
                                                          ) +
                                                          ", DISGUST: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .disgust_score
                                                          ) +
                                                          ", SADNESS: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .sadness_score
                                                          ) +
                                                          ", FEAR: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .fear_score
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("div", { staticClass: "mt-3" }, [
                                              _c("label", [
                                                _vm._v("NLU Sentiment Score:"),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Sentiment: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .sentiment
                                                          ) +
                                                          ", Score: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .sentiment_score
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm.testEvalJsonScheme &&
                                        _vm.testEvalJsonScheme.attributes &&
                                        _vm.testEvalJsonScheme.attributes
                                          .response_formula_hits
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "text-label" },
                                                  [
                                                    _vm._v(
                                                      "Email Response hit:"
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.testEvalJsonScheme
                                                    .attributes
                                                    .response_formula_hits,
                                                  function (formula, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "test-items",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "test-chips-container",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "test-chips",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    formula
                                                                      .attributes
                                                                      .email_response
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "json",
                                          role: "tabpanel",
                                          "aria-labelledby": "json-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "enrichment-concept-view p-0",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "enrichment-concept-editor",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "enrichment-concept-code",
                                                  },
                                                  [
                                                    _c("json-viewer", {
                                                      attrs: {
                                                        value:
                                                          _vm.testEvalJsonScheme,
                                                        "expand-depth": 5,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "enrichment-code-copy",
                                                  },
                                                  [
                                                    _c(
                                                      "copy-to-clipboard",
                                                      {
                                                        attrs: {
                                                          text: JSON.stringify(
                                                            _vm.testEvalJsonScheme
                                                          ),
                                                        },
                                                        on: {
                                                          copy: _vm.handleCopy,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "copy-icon",
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                          },
                                                          [_vm._v("Copy")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary btn-large",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.next("qa-and-interstitial", 8)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.step === "qa-and-interstitial"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "setup-content" },
                  [
                    _vm._m(12),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "nav nav-tabs mb-0",
                            attrs: { id: "nav-tab5", role: "tablist5" },
                          },
                          _vm._l(_vm.qaConditions, function (char, index) {
                            return _c(
                              "a",
                              {
                                key: char.id,
                                class: `nav-item nav-link flex-none px-5 ${
                                  _vm.emailSelChar.id ===
                                  char.attributes.character.id
                                    ? "active"
                                    : ""
                                }`,
                                attrs: {
                                  id: `#collapseOne${index}`,
                                  "data-toggle": "tab",
                                  "data-target": `#collapseOne${index}`,
                                  role: "tab",
                                  "aria-controls": `${index}`,
                                  "aria-selected": "true",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getQaId(
                                      char.id,
                                      char.attributes.character.id
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    char.attributes.character.attributes
                                      .char_full_name
                                  )
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "learning-quiz-btn" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn px-5 header-btn secondary",
                              attrs: { href: "javascript:void(0);" },
                              on: { click: _vm.openResFormulaModal },
                            },
                            [_vm._v("Add QA Condition")]
                          ),
                        ]),
                      ]
                    ),
                    _c("ValidationObserver", [
                      _c("form", { staticClass: "w-100" }, [
                        _c("div", { staticClass: "row custom-row" }, [
                          _vm.selPresentResFormula.length > 0
                            ? _c(
                                "div",
                                { staticClass: "tab-content col-sm-12" },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "formulas-data-list" },
                                    _vm._l(
                                      _vm.selPresentResFormula,
                                      function (res, i) {
                                        return _c("li", { key: i }, [
                                          res.present
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "formulas-list",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "formulas-list-title",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "qa-formulas-left",
                                                        },
                                                        [
                                                          _vm._v(" Present: "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "keywords",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Keyword: at-least " +
                                                                  _vm._s(
                                                                    res.present_min
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "formulas-edit-btn",
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editResponseFormula(
                                                                res,
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("em", {
                                                            staticClass:
                                                              "icon-edit",
                                                          }),
                                                          _vm._v(" Edit "),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "formulas-delete-btn",
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.setDestroyId(
                                                                res,
                                                                "qaFormula"
                                                              )
                                                              _vm.$modal.show(
                                                                "deleteResponseModal"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("em", {
                                                            staticClass:
                                                              "icon-delete",
                                                          }),
                                                          _vm._v(" Delete "),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  res.present
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "formulas-list-item",
                                                        },
                                                        _vm._l(
                                                          res.present,
                                                          function (
                                                            formula,
                                                            key,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "formula-item-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "formula-name",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          key
                                                                        ) +
                                                                        ": "
                                                                    ),
                                                                    _vm._l(
                                                                      formula,
                                                                      function (
                                                                        entity
                                                                      ) {
                                                                        return _c(
                                                                          "span",
                                                                          {
                                                                            key: entity.id,
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                entity.value
                                                                              ) +
                                                                                " ,"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                                Object.keys(
                                                                  res.present
                                                                ).length -
                                                                  1 !==
                                                                index
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "formula-and",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "and"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          res.absent
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "formulas-list",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "formulas-list-title",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "formulas-left",
                                                        },
                                                        [
                                                          _vm._v(" Absent: "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "keywords",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Keyword: at-least " +
                                                                  _vm._s(
                                                                    res.absent_min
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "formulas-list-item",
                                                    },
                                                    _vm._l(
                                                      res.absent,
                                                      function (
                                                        formula,
                                                        key,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "formula-item-flex",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "formula-name",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      key
                                                                    ) +
                                                                    ": "
                                                                ),
                                                                _vm._l(
                                                                  formula,
                                                                  function (
                                                                    entity
                                                                  ) {
                                                                    return _c(
                                                                      "span",
                                                                      {
                                                                        key: entity.id,
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            entity.value
                                                                          ) +
                                                                            " ,"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                            Object.keys(
                                                              res.absent
                                                            ).length -
                                                              1 !==
                                                            index
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "formula-and",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "and"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", [
                                        _vm._v("Out of office response"),
                                      ]),
                                      _c(
                                        "ValidationProvider",
                                        {
                                          attrs: { name: "Assessment Scheme" },
                                        },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.qaResponse,
                                                expression: "qaResponse",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              rows: "6",
                                              placeholder:
                                                "Search by name, role & organization",
                                            },
                                            domProps: { value: _vm.qaResponse },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.qaResponse =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end mt-4 mb-3",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary btn-large",
                                    on: {
                                      click: function ($event) {
                                        return _vm.next("overall-assessment", 9)
                                      },
                                    },
                                  },
                                  [_vm._v("Next")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "setup-content-border" }),
                _c("div", { staticClass: "setup-content" }, [
                  _c("div", { staticClass: "form-section m-0 p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "learning-form-header d-flex align-items-start",
                      },
                      [
                        _vm._m(13),
                        _c("input", {
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "file",
                            multiple: "multiple",
                            accept: ".pdf, .doc",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-auto pt-4 text-right learning-quiz-btn",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn ml-2 px-4 secondary",
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.show(
                                      "uploadInterstitialModal",
                                      { create: true }
                                    )
                                  },
                                },
                              },
                              [_vm._v("Upload Interestitial Content")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "learning-data-list flex-column pt-3" },
                      _vm._l(_vm.interstitialLists, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass:
                              "learning-data-item learning-interstitial-data-item",
                          },
                          [
                            _c("div", { staticClass: "learning-data-left" }, [
                              item.attributes.content_details.type ==
                              "text_learn_obj"
                                ? _c("div", {
                                    staticClass: "learning-data-richText",
                                  })
                                : _vm._e(),
                              item.attributes.content_details.type ==
                              "file_learn_obj"
                                ? _c(
                                    "div",
                                    { staticClass: "learning-data-icon" },
                                    [_vm._m(14, true)]
                                  )
                                : _vm._e(),
                              item.attributes.content_details.type ==
                              "slide_learn_obj"
                                ? _c(
                                    "div",
                                    { staticClass: "learning-data-icon" },
                                    [
                                      item.attributes.content_details.attributes
                                        .slider_images.length > 0
                                        ? _c("a", [
                                            _c("img", {
                                              attrs: {
                                                src: item.attributes
                                                  .content_details.attributes
                                                  .slider_images[0].attributes
                                                  .resource_url.thumbnail,
                                                alt: "slide",
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              item.attributes.content_details.type ==
                              "video_learn_obj"
                                ? _c(
                                    "div",
                                    { staticClass: "learning-data-video" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "wistia_responsive_padding data-video",
                                          staticStyle: {
                                            padding: "56.25% 0 0 0",
                                            position: "relative",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "wistia_responsive_wrapper data-video",
                                              staticStyle: {
                                                height: "100%",
                                                left: "0",
                                                position: "absolute",
                                                top: "0",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("iframe", {
                                                staticClass: "wistia_embed",
                                                attrs: {
                                                  src:
                                                    _vm.preIframUrl +
                                                    item.attributes
                                                      .content_details
                                                      .attributes.global_video
                                                      .attributes.wistia_code +
                                                    _vm.postIframUrl,
                                                  title: "Video.mp4",
                                                  name: "wistia_embed",
                                                  allowtransparency: "true",
                                                  scrolling: "no",
                                                  allowfullscreen: "",
                                                  mozallowfullscreen: "",
                                                  webkitallowfullscreen: "",
                                                  oallowfullscreen: "",
                                                  msallowfullscreen: "",
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "learning-data-title" }, [
                              _c("div", { staticClass: "data-heads" }, [
                                _c("h4", {
                                  domProps: {
                                    innerHTML: _vm._s(item.attributes.title),
                                  },
                                }),
                                item.attributes.content_details.type ==
                                "file_learn_obj"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.size(
                                            item.attributes.content_details
                                              .attributes.resource_size
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("h4", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    item.attributes.content_details.attributes
                                      .title
                                  ),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "learning-data-paragraph" },
                                [
                                  _c("div", [
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item.attributes.description
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "learning-edit-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$modal.show(
                                            "uploadInterstitialModal",
                                            { create: false, edit: item }
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeInterstitialData(
                                            item.id,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Remove")]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.step === "overall-assessment"
            ? _c(
                "div",
                {
                  staticClass: "setup-content",
                  attrs: { id: "overall-assessment" },
                },
                [
                  _vm._m(15),
                  _vm.overallAssmntItem
                    ? _c("ValidationObserver", { ref: "form" }, [
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [
                                  _vm._v("Overall Interaction Assessment"),
                                ]),
                                _c(
                                  "ul",
                                  { staticClass: "form-entity-tabs" },
                                  _vm._l(
                                    _vm.overallAssmntItem,
                                    function (assmntItem) {
                                      return _c(
                                        "li",
                                        {
                                          key: assmntItem.id,
                                          class:
                                            assmntItem.id ==
                                            _vm.selectedOverallAssmntItem.id
                                              ? "active"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleOverallAssmntItem(
                                                assmntItem
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  assmntItem.attributes
                                                    .assessment_label
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-7" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "question-content",
                                  attrs: { id: "number-range" },
                                },
                                [
                                  _c("div", { staticClass: "question-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("label", [
                                          _vm._v("Evaluation Text"),
                                        ]),
                                        _c("ValidationProvider", {
                                          attrs: { name: "Evaluation Text" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("vue-editor", {
                                                      attrs: {
                                                        id: "Descriptiontext",
                                                        placeholder:
                                                          "Add a Description",
                                                        editorToolbar:
                                                          _vm.fullTool,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.overallAssmntItemFeedback,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.overallAssmntItemFeedback =
                                                            $$v
                                                        },
                                                        expression:
                                                          "overallAssmntItemFeedback",
                                                      },
                                                    }),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3765878218
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group pt-2" },
                                      [
                                        _c("label", [
                                          _vm._v("Evaluation range:"),
                                        ]),
                                        _c("ValidationProvider", {
                                          attrs: { name: "Evaluation range" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v("Minimum"),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.overallAssmntItemMinScore,
                                                                  expression:
                                                                    "overallAssmntItemMinScore",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class: [
                                                                _vm.isActive &&
                                                                errors[0]
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.overallAssmntItemMinScore,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.overallAssmntItemMinScore =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v("Maximum"),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.overallAssmntItemMaxScore,
                                                                  expression:
                                                                    "overallAssmntItemMaxScore",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class: [
                                                                _vm.isActive &&
                                                                errors[0]
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.overallAssmntItemMaxScore,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.overallAssmntItemMaxScore =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1464490268
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-12 pt-5" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mt-4 mb-3",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.restore("published")
                                        },
                                      },
                                    },
                                    [_vm._v("Save & Publish")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-container justify-content-end",
                                },
                                [
                                  _c("a", { staticClass: "delete-btn" }, [
                                    _c("div", { staticClass: "delete-box" }, [
                                      _c("span", {
                                        staticClass: "icon-delete",
                                      }),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$parent.archiveLO(
                                              "archived"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Archive this Learning Object(LO)"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.assessmentFormula
        ? _c("div", [
            _c("div", { staticClass: "learning-form-header flex-wrap" }, [
              _c("h4", [
                _c(
                  "a",
                  {
                    staticClass: "back-btn-inner",
                    on: { click: _vm.backToAssessment },
                  },
                  [
                    _c("span", { staticClass: "icon-back" }),
                    _vm._v(" Back to Assessment (Skill setup) "),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "learning-quiz-content pt-4" }, [
              _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _c("ValidationObserver", [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group my-3" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.selectedSkill.skillName)),
                            ]),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "form-entity-tabs form-check-tabs form-check-tabs2",
                              },
                              _vm._l(
                                _vm.emailAssessmentSkill,
                                function (skill) {
                                  return _c(
                                    "li",
                                    {
                                      key: skill.id,
                                      class:
                                        skill.id == _vm.selectedAssmntItem.id
                                          ? "active"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectAssessmentItem(skill)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  skill.attributes
                                                    .assessment_label_name
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    skill.attributes
                                                      .assessment_formulas
                                                      .length
                                                  ) + " Response Formulas"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("span", [
                                            _c("em", {
                                              staticClass: "icon-check",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c(
                            "div",
                            {
                              staticClass: "nav nav-tabs",
                              attrs: { id: "nav-tab", role: "tablist" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: `nav-item nav-link flex-none active`,
                                  attrs: {
                                    id: "response-tab",
                                    "data-toggle": "tab",
                                    href: "#nav-response",
                                    role: "tab",
                                    "aria-controls": "nav-response",
                                    "aria-selected": true ? "false" : "true",
                                  },
                                },
                                [_vm._v("Debrief Content")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "nav-item nav-link",
                                  attrs: {
                                    id: "adaptive-tab",
                                    "data-toggle": "tab",
                                    href: "#nav-adaptive",
                                    role: "tab",
                                    "aria-controls": "nav-adaptive",
                                    "aria-selected": "false",
                                  },
                                },
                                [_vm._v("Adaptive configuration")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-content",
                              attrs: { id: "nav-tabContent" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: `tab-pane fade show active`,
                                  attrs: {
                                    id: "nav-response",
                                    role: "tabpanel",
                                    "aria-labelledby": "response-tab",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "assessment-response" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: [
                                            "assessment-response",
                                            _vm.variations_briefattributes
                                              .length > 0
                                              ? "pt-2"
                                              : "",
                                          ],
                                        },
                                        [
                                          _vm.variations_briefattributes
                                            .length > 0
                                            ? _c(
                                                "ul",
                                                { staticClass: "response-tab" },
                                                _vm._l(
                                                  _vm.variations_briefattributes,
                                                  function (variations, key) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: key,
                                                        class:
                                                          _vm.variationsSelect ===
                                                          key + 1
                                                            ? "active"
                                                            : "",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addDebriefVariation(
                                                                  key + 1,
                                                                  "debrief"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Variation " +
                                                                _vm._s(
                                                                  _vm
                                                                    .variations_briefattributes
                                                                    .length > 1
                                                                    ? key + 1
                                                                    : ""
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.variations_briefattributes
                                            .length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "response-content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-control p-0",
                                                        },
                                                        [
                                                          _c(
                                                            "ValidationProvider",
                                                            {
                                                              attrs: {
                                                                name: "Variation",
                                                                rules:
                                                                  "max:500",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "default",
                                                                      fn: function ({
                                                                        errors,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "vue-editor",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  "form-control",
                                                                                  _vm.isActive &&
                                                                                  errors[0]
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  editorToolbar:
                                                                                    _vm.fullTool,
                                                                                  placeholder:
                                                                                    "Variations",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .variations_briefattributes[
                                                                                      _vm.variationsSelect -
                                                                                        1
                                                                                    ]
                                                                                      .content,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .variations_briefattributes[
                                                                                          _vm.variationsSelect -
                                                                                            1
                                                                                        ],
                                                                                        "content",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "variations_briefattributes[variationsSelect -1].content",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  1465117895
                                                                ),
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "add-more-btn",
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.variations_briefattributes
                                                    .length < 5
                                                    ? _vm.addDebriefVariation(
                                                        null,
                                                        "debrief"
                                                      )
                                                    : ""
                                                },
                                              },
                                            },
                                            [_vm._v("+ Add Variation")]
                                          ),
                                          _vm.variations_briefattributes
                                            .length > 0
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "add-more-btn variation-save",
                                                  attrs: {
                                                    href: "javascript:void(0);",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.saveDebriefVariations()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Save variation(s) & points"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-group mt-4" },
                                        [
                                          _c("label", [_vm._v("Points")]),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Points",
                                              rules: "required|max:100",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.assessmentPoints,
                                                            expression:
                                                              "assessmentPoints",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: [
                                                          _vm.isActive &&
                                                          errors[0]
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            _vm.assessmentPoints,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.assessmentPoints =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      errors[0]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: [
                                                                _vm.isActive
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              style: [
                                                                _vm.isActive
                                                                  ? {
                                                                      display:
                                                                        "block",
                                                                    }
                                                                  : {
                                                                      display:
                                                                        "none",
                                                                    },
                                                              ],
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0].replace(
                                                                    "The ",
                                                                    ""
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2822605907
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "formulas-data" }, [
                                    _c(
                                      "div",
                                      { staticClass: "formulas-edit" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "formulas-title" },
                                          [_vm._v("Assessment Formula")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "add-more-btn",
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                            on: {
                                              click: _vm.openResFormulaModal,
                                            },
                                          },
                                          [_vm._v("+ Add Assessment Formula")]
                                        ),
                                      ]
                                    ),
                                    _vm.selPresentResFormula
                                      ? _c(
                                          "ul",
                                          { staticClass: "formulas-data-list" },
                                          _vm._l(
                                            _vm.selPresentResFormula,
                                            function (res, i) {
                                              return _c("li", { key: i }, [
                                                res.present
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "formulas-list",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "formulas-list-title",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "formulas-left",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Present: "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "keywords",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Keyword: at-least " +
                                                                        _vm._s(
                                                                          res.present_min
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "formulas-edit-btn",
                                                                attrs: {
                                                                  href: "javascript:void(0);",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editResponseFormula(
                                                                        res,
                                                                        i
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("em", {
                                                                  staticClass:
                                                                    "icon-edit",
                                                                }),
                                                                _vm._v(
                                                                  " Edit "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "formulas-edit-btn",
                                                                attrs: {
                                                                  href: "javascript:void(0);",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.setDestroyId(
                                                                        res,
                                                                        "assessmentResponseFormula"
                                                                      )
                                                                      _vm.$modal.show(
                                                                        "deleteResponseModal"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Delete")]
                                                            ),
                                                          ]
                                                        ),
                                                        res.present
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "formulas-list-item",
                                                              },
                                                              _vm._l(
                                                                res.present,
                                                                function (
                                                                  formula,
                                                                  key,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: index,
                                                                      staticClass:
                                                                        "formula-item-flex",
                                                                    },
                                                                    [
                                                                      formula
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "formula-name",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    key
                                                                                  ) +
                                                                                  ": "
                                                                              ),
                                                                              _vm._l(
                                                                                formula,
                                                                                function (
                                                                                  entity
                                                                                ) {
                                                                                  return _c(
                                                                                    "span",
                                                                                    {
                                                                                      key: entity.id,
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          entity.value
                                                                                        ) +
                                                                                          " ,"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                            ],
                                                                            2
                                                                          )
                                                                        : _vm._e(),
                                                                      Object.keys(
                                                                        res.present
                                                                      ).length -
                                                                        1 !==
                                                                      index
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "formula-and",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "and"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                res.absent
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "formulas-list",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "formulas-list-title",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "formulas-left",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Absent: "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "keywords",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Keyword: at-least " +
                                                                        _vm._s(
                                                                          res.absent_min
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "formulas-list-item",
                                                          },
                                                          _vm._l(
                                                            res.absent,
                                                            function (
                                                              formula,
                                                              key,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "formula-item-flex",
                                                                },
                                                                [
                                                                  formula
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "formula-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                key
                                                                              ) +
                                                                              ": "
                                                                          ),
                                                                          _vm._l(
                                                                            formula,
                                                                            function (
                                                                              entity
                                                                            ) {
                                                                              return _c(
                                                                                "span",
                                                                                {
                                                                                  key: entity.id,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      entity.value
                                                                                    ) +
                                                                                      " ,"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      )
                                                                    : _vm._e(),
                                                                  Object.keys(
                                                                    res.absent
                                                                  ).length -
                                                                    1 !==
                                                                  index
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "formula-and",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "and"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tab-pane fade",
                                  attrs: {
                                    id: "nav-adaptive",
                                    role: "tabpanel",
                                    "aria-labelledby": "adaptive-tab",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c("h5", { staticClass: "small-title" }, [
                                        _vm._v("Add Adaptive Content"),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "px-4 learning-quiz-btn",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn ml-2 px-4 secondary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$modal.show(
                                                    "uploadInterstitialModal",
                                                    {
                                                      create: true,
                                                      content: "adaptive",
                                                    }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Upload")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.adaptiveContentList.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "learning-data-list flex-column pt-3",
                                        },
                                        _vm._l(
                                          _vm.adaptiveContentList,
                                          function (item, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.id,
                                                staticClass:
                                                  "learning-data-item w-100 learning-interstitial-data-item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learning-data-left",
                                                  },
                                                  [
                                                    item.attributes
                                                      .content_details.type ==
                                                    "text_learn_obj"
                                                      ? _c("div", {
                                                          staticClass:
                                                            "learning-data-richText",
                                                        })
                                                      : _vm._e(),
                                                    item.attributes
                                                      .content_details.type ==
                                                    "file_learn_obj"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "learning-data-icon",
                                                          },
                                                          [
                                                            _c("a", [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: require("../../assets/images/pdf-big-icon.png"),
                                                                  alt: "pdf",
                                                                },
                                                              }),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.attributes
                                                      .content_details.type ==
                                                    "slide_learn_obj"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "learning-data-icon",
                                                          },
                                                          [
                                                            _c("a", [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: item
                                                                    .attributes
                                                                    .content_details
                                                                    .attributes
                                                                    .slider_images[0]
                                                                    .attributes
                                                                    .resource_url
                                                                    .thumbnail,
                                                                  alt: "slide",
                                                                },
                                                              }),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.attributes
                                                      .content_details.type ==
                                                    "video_learn_obj"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "learning-data-video",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wistia_responsive_padding data-video",
                                                                staticStyle: {
                                                                  padding:
                                                                    "56.25% 0 0 0",
                                                                  position:
                                                                    "relative",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wistia_responsive_wrapper data-video",
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "100%",
                                                                        left: "0",
                                                                        position:
                                                                          "absolute",
                                                                        top: "0",
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "iframe",
                                                                      {
                                                                        staticClass:
                                                                          "wistia_embed",
                                                                        attrs: {
                                                                          src:
                                                                            _vm.preIframUrl +
                                                                            item
                                                                              .attributes
                                                                              .content_details
                                                                              .attributes
                                                                              .global_video
                                                                              .attributes
                                                                              .wistia_code +
                                                                            _vm.postIframUrl,
                                                                          title:
                                                                            "Video.mp4",
                                                                          name: "wistia_embed",
                                                                          allowtransparency:
                                                                            "true",
                                                                          scrolling:
                                                                            "no",
                                                                          allowfullscreen:
                                                                            "",
                                                                          mozallowfullscreen:
                                                                            "",
                                                                          webkitallowfullscreen:
                                                                            "",
                                                                          oallowfullscreen:
                                                                            "",
                                                                          msallowfullscreen:
                                                                            "",
                                                                          width:
                                                                            "100%",
                                                                          height:
                                                                            "100%",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learning-data-title",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-heads",
                                                      },
                                                      [
                                                        _c("h4", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.attributes
                                                                .title
                                                            ),
                                                          },
                                                        }),
                                                        item.attributes
                                                          .content_details
                                                          .type ==
                                                        "file_learn_obj"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.size(
                                                                    item
                                                                      .attributes
                                                                      .content_details
                                                                      .attributes
                                                                      .resource_size
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("h4", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.attributes
                                                            .content_details
                                                            .attributes.title
                                                        ),
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "learning-data-paragraph",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("p", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.attributes
                                                                  .description
                                                              ),
                                                            },
                                                          }),
                                                        ]),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticStyle: {
                                                              color: "#3dbc9e",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$modal.show(
                                                                  "uploadInterstitialModal",
                                                                  {
                                                                    create: false,
                                                                    edit: item,
                                                                    content:
                                                                      "adaptive",
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Edit")]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeAdaptiveData(
                                                                  item.id,
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Remove")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.next("email-testing", 7)
                                    },
                                  },
                                },
                                [_vm._v("Done")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.type == "email"
        ? _c("addInterstitialContent", {
            on: {
              interstitialContents: _vm.interstitialContents,
              adaptiveContents: _vm.adaptiveContents,
            },
          })
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteResponseModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteResponseModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm.responseFormulaId.name === "responseFormula"
                ? _c(
                    "button",
                    {
                      staticClass: "btn medium primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.deleteResponseFormula(),
                            _vm.$modal.hide("deleteResponseModal")
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  )
                : _vm._e(),
              _vm.responseFormulaId.name === "qaFormula"
                ? _c(
                    "button",
                    {
                      staticClass: "btn medium primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.deleteQaFormula(),
                            _vm.$modal.hide("deleteResponseModal")
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  )
                : _vm._e(),
              _vm.responseFormulaId.name === "assessmentResponseFormula"
                ? _c(
                    "button",
                    {
                      staticClass: "btn medium primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.deleteAssessmentResponseFormula(),
                            _vm.$modal.hide("deleteResponseModal")
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteEmailRespModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete this response?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteEmailRespModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.deleteResponse(),
                        _vm.$modal.hide("deleteEmailRespModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "ReduceIteration",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v(
                "Reducing the iteration level removes the respective iteration responses added, if any. Are you sure you want to reduce the iteration level? "
              ),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.changeBackIteration(),
                        _vm.$modal.hide("ReduceIteration")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("ReduceIteration")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteSkillConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete this skill?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteSkillConfirmationModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.deleteSkill(),
                        _vm.$modal.hide("deleteSkillConfirmationModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Basic Information"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Intents Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Entities Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Response & Formulas"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Assessment(Skill Setup)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Testing")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("QA & Interstitial"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Overall Assessment"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Response & Formulas")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", { staticClass: "head-title" }, [
          _vm._v("Assessment (Skill setup)"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Testing")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "head-title" }, [_vm._v("QA Conditions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-6 px-0 pt-4 pb-1" }, [
      _c("h4", [_vm._v("Upload Interstitial Content:")]),
      _c("p", { staticClass: "mt-1" }, [
        _vm._v(
          "Add Interstitial Content for the learner about the learning module."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("img", {
        attrs: {
          src: require("../../assets/images/pdf-big-icon.png"),
          alt: "pdf",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Overall Assessment")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }