var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Users" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchLearners,
                      expression: "searchLearners",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:400ms",
                      value: _vm.search,
                      expression: "search",
                      arg: "400ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "aria-describedby": "searchLearners",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchLearners },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchLearners = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
          ]),
          _vm.totalLearners > 1 && !_vm.searchLearners
            ? _c("p", [
                _vm._v(
                  " You have " +
                    _vm._s(_vm.totalLearners) +
                    " Learners in the system. "
                ),
              ])
            : _vm.totalLearners <= 1 && !_vm.searchLearners
            ? _c("p", [
                _vm._v(
                  " You have " +
                    _vm._s(_vm.totalLearners) +
                    " Learner in the system. "
                ),
              ])
            : _vm.totalLearners > 1 && _vm.searchLearners
            ? _c("p", [
                _vm._v(
                  " You have " +
                    _vm._s(_vm.totalLearners) +
                    " Learners in the search result. "
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalLearners) +
                    " Learner in the search result."
                ),
              ]),
        ]),
        _vm.learnersList.length !== 0 && !_vm.isLoadingStudents
          ? _c("div", { staticClass: "data-content" }, [
              _c(
                "div",
                { staticClass: "data-table data-column-7" },
                [
                  _c("div", { staticClass: "data-head" }, [
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Last Name "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "last_name" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("last_name", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "last_name" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("last_name", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" First Name "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "first_name" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("first_name", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "first_name" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("first_name", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" LOs Completed "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "completed_count" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("completed_count", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "completed_count" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("completed_count", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Started "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "time_started" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("time_started", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "time_started" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("time_started", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Completed "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "time_completed" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("time_completed", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "time_completed" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("time_completed", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [_vm._v("Module")]),
                    _c("div", { staticClass: "data-col" }, [_vm._v("Section")]),
                    _c("div", { staticClass: "data-col" }, [_vm._v("Course")]),
                    _c("div", { staticClass: "data-col" }),
                  ]),
                  _vm._l(_vm.learnersList, function (learner) {
                    return _c(
                      "div",
                      { key: learner.id, staticClass: "data-items" },
                      [
                        _c("div", { staticClass: "data-row" }, [
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(learner.attributes.last_name)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(learner.attributes.first_name)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(
                              " " +
                                _vm._s(learner.attributes.completed_count) +
                                " of " +
                                _vm._s(learner.attributes.total_count) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(learner.attributes.time_started)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(
                              " " +
                                _vm._s(learner.attributes.time_completed) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(learner.attributes.module_name)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(learner.attributes.section_name)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(learner.attributes.course_name)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-col" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: `/learner-lo-list/${learner.id}`,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    { staticClass: "btn secondary btn-small" },
                                    [_vm._v("ELM Details")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "data-col" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn secondary btn-small",
                                on: {
                                  click: function ($event) {
                                    return _vm.editSection(learner.attributes)
                                  },
                                },
                              },
                              [_vm._v(" Edit Section ")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    " Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (page) {
                            return _c(
                              "li",
                              { key: page, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link active",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getLearners(page)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(page))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.learnersList.length === 0 && !_vm.searchStatus && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no User in the system yet.")]),
            ])
          : _vm._e(),
        _vm.learnersList.length === 0 && _vm.searchStatus
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c("EditSectionModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("Learner Central")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }