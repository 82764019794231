<template>
  <div id="CreateELM">
    <div class="create-elm-container">
      <router-link to="/learning-modules" class="elm-back-btn">
        <span class="icon-back"></span>
      </router-link>
      <div class="elm-header">
        <div class="header-left">
          <h2>Create ELM</h2>
          <p>Add a few basic details about this Learning Module</p>
        </div>
        <div class="d-flex align-items-center">
          <router-link to="/learning-modules" class="btn-link mr-5"
            >Cancel</router-link
          >
          <button class="btn header-btn primary big" @click="createElm">
            Save & Add Learning Objects
          </button>
        </div>
      </div>
      <div class="elm-form-container">
        <div class="image-container">
          <div class="image-container-content" v-if="!image">
            <input
              class="custom-file-input"
              ref="fileInput"
              type="file"
              @change="onFileSelected"
              accept="image/png, image/jpeg, image/gif"
            />
            <div class="default-text">
              <span class="icon-plus"></span>
              <p>
                Add a cover image for the
                <br />Learning module
              </p>
            </div>
          </div>
          <div v-if="image">
            <div class="image-container-content">
              <img :src="image" alt="image" />
            </div>
            <div class="image-delete">
              <span class="icon-delete"></span>
              <a class="btn delete" @click="uploadImageRemoved">Remove Photo</a>
            </div>
          </div>
        </div>
        <div class="right-side-container">
          <div class="form-section">
            <ValidationObserver ref="form1">
              <form class="w-100">
                <div class="form-section-header">
                  <h4>Basic Details</h4>
                  <div class="form-section-right">
                    <a
                      class="btn dropdown-toggle"
                      id="menu1"
                      :class="[note ? 'active' : '']"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="../assets/images/icon-yellownote.svg"
                        alt="yellow"
                        class="imgGray"
                      />
                    </a>
                    <div
                      class="
                        dropdown-menu
                        data-popover-dropdown
                        dropdown-menu-right
                      "
                      aria-labelledby="menu1"
                    >
                      <div class="data-popover">
                        <div class="data-popover-title">
                          <span>Note for admin</span>
                          <a class="data-popover-close">
                            <em class="icon-close"></em>
                          </a>
                        </div>
                        <form class="data-popover-form">
                          <div class="form-group">
                            <textarea
                              class="form-control small-textarea-height"
                              rows="4"
                              v-model="note"
                            ></textarea>
                          </div>
                          <div class="form-group-button">
                            <a
                              class="btn btn-remove"
                              v-if="note"
                              @click="removeNote()"
                              >Remove Note</a
                            >
                            <a class="btn btn-save">Save</a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row custom-row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>ELM Title</label>
                      <ValidationProvider
                        name="ELM title"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          maxlength="100"
                          placeholder="Add a Title"
                          v-model="name"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Assign World</label>
                      <div class="elm-dropdown">
                        <ValidationProvider
                          name="Assign world"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            v-model="searchWorld"
                            placeholder="Type here"
                            class="form-control"
                            v-debounce:700ms="searchWorlds"
                            :class="[isActive && errors[0] ? 'invalid' : '']"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[
                              isActive
                                ? { display: 'block' }
                                : { display: 'none' },
                            ]"
                            v-if="errors[0]"
                            >{{ errors[0].replace("The ", "") }}</span
                          >
                        </ValidationProvider>
                        <div v-if="worldLists.length">
                          <div
                            class="dropdown-menu"
                            :class="[worldLists.length !== 0 ? 'show' : '']"
                          >
                            <ul class="dropdown-list">
                              <li v-for="world in worldLists" :key="world.id">
                                <div class="elm-assign-item">
                                  <div
                                    class="elm-assign-text"
                                    @click="
                                      assignWorld(
                                        world.id,
                                        world.attributes.name
                                      )
                                    "
                                  >
                                    <div>
                                      <h3 v-if="world.attributes.is_private">
                                        Private
                                      </h3>
                                      <h3 v-if="!world.attributes.is_private">
                                        Public
                                      </h3>
                                    </div>
                                    <h4>
                                      {{ world.attributes.name }}
                                      <div
                                        class="elms"
                                        v-if="
                                          world.attributes.world_organizations
                                        "
                                      >
                                        {{
                                          world.attributes.world_organizations
                                            .length
                                        }}
                                      </div>
                                    </h4>
                                  </div>
                                  <div class="dropdown-action">
                                    <a
                                      class="add-action"
                                      @click="
                                        assignWorld(
                                          world.id,
                                          world.attributes.name
                                        )
                                      "
                                      >+</a
                                    >
                                  </div>
                                  <p v-if="world.attributes.description">
                                    {{
                                      getDesc(world.attributes.description)
                                    }}
                                    ...
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Add Learner Organization</label>
                      <ValidationProvider
                        name="Learner organization"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="searchOrg"
                          v-on:input="getOrg"
                          class="form-control"
                          placeholder="Type here"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                      <div v-if="searchOrganization.length">
                        <div
                          class="dropdown-menu"
                          :class="[
                            searchOrganization.length !== 0 ? 'show' : '',
                          ]"
                        >
                          <ul class="dropdown-list">
                            <li
                              class="dropdown-items"
                              v-for="org in searchOrganization"
                              :key="org.id"
                            >
                              <div class="dropdown-content">
                                <h4 @click="assignOrg(org)">{{ org.name }}</h4>
                              </div>
                              <div class="dropdown-action">
                                <a class="add-action" @click="assignOrg(org)"
                                  >+</a
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Assign a Role</label>
                      <div class="elm-dropdown">
                        <ValidationProvider
                          name="Learner role"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            v-model="searchRole"
                            v-on:input="getRole"
                            placeholder="Add Learner Role"
                            :class="[isActive && errors[0] ? 'invalid' : '']"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[
                              isActive
                                ? { display: 'block' }
                                : { display: 'none' },
                            ]"
                            v-if="errors[0]"
                            >{{ errors[0].replace("The ", "") }}</span
                          >
                        </ValidationProvider>
                        <div
                          class="dropdown-menu"
                          :class="[roles.length !== 0 ? 'show' : '']"
                          v-if="roles.length"
                        >
                          <ul class="dropdown-list">
                            <li
                              class="dropdown-items"
                              v-for="role of roles"
                              :key="role.id"
                            >
                              <div class="dropdown-content">
                                <h4
                                  @click="
                                    setRole(role.id, role.attributes.name)
                                  "
                                >
                                  {{ role.attributes.name }}
                                </h4>
                              </div>
                              <div class="dropdown-action">
                                <div class="dropdown-action">
                                  <a
                                    class="add-action"
                                    @click="
                                      setRole(role.id, role.attributes.name)
                                    "
                                    >+</a
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Lead Designer</label>
                      <ValidationProvider
                        name="Lead designer"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="searchDesigner"
                          v-on:input="getDesigner"
                          class="form-control"
                          placeholder="Type here"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                      <div
                        class="dropdown-menu"
                        :class="[designerLists.length !== 0 ? 'show' : '']"
                        v-if="designerLists.length"
                      >
                        <ul class="dropdown-list">
                          <li
                            class="dropdown-items"
                            v-for="designer of designerLists"
                            :key="designer.id"
                          >
                            <div class="dropdown-content">
                              <h4
                                @click="
                                  setDesigner(
                                    designer.id,
                                    designer.attributes.first_name
                                  )
                                "
                              >
                                {{ designer.attributes.first_name }}
                              </h4>
                            </div>
                            <div class="dropdown-action">
                              <div class="dropdown-action">
                                <a
                                  class="add-action"
                                  @click="
                                    setDesigner(
                                      designer.id,
                                      designer.attributes.first_name
                                    )
                                  "
                                  >+</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Assign Subject Matter Expert(SME)</label>
                      <ValidationProvider
                        name="Assign subject matter expert"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="searchSme"
                          v-on:input="getSme"
                          class="form-control"
                          placeholder="Type here"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                      <div
                        class="dropdown-menu"
                        :class="[smeLists.length !== 0 ? 'show' : '']"
                        v-if="smeLists.length"
                      >
                        <ul class="dropdown-list mt-3" v-if="smeLists.length">
                          <li
                            class="dropdown-items"
                            v-for="sme of smeLists"
                            :key="sme.id"
                          >
                            <div class="dropdown-content">
                              <h4
                                @click="
                                  setSme(sme.id, sme.attributes.first_name)
                                "
                              >
                                {{ sme.attributes.first_name }}
                              </h4>
                            </div>
                            <div class="dropdown-action">
                              <div class="dropdown-action">
                                <a
                                  class="add-action"
                                  @click="
                                    setSme(sme.id, sme.attributes.first_name)
                                  "
                                  >+</a
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Expected Hours to Complete</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="timeToComplete"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                        placeholder="Add Expected Hours to Complete"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>Description</label>
                      <textarea
                        type="text"
                        class="form-control textarea-height"
                        placeholder="Add a Description"
                        v-model="description"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>Final debrief overview</label>
                      <vue-editor
                        :editorToolbar="fullToolbar"
                        v-model="final_debrief_overview"
                      ></vue-editor>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Learning Objectives</label>
                      <textarea
                        type="text"
                        v-model="learningObjectives"
                        class="form-control small-textarea-height"
                        placeholder="Add Comma Separated Learning Objectives"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group multi-select">
                      <label>Skills</label>
                      <div class="chip-container">
                        <div
                          class="chip"
                          v-for="(chip, i) of chips"
                          :key="chip.label"
                        >
                          <span>{{ chip.attributes.name }}</span>
                          <em class="icon-close" @click="deleteChip(i)"></em>
                        </div>
                        <input
                          v-model="skill"
                          v-on:input="searchSkill"
                          :placeholder="skillsPlaceholder"
                        />
                        <div
                          class="dropdown-list mt-3"
                          v-if="updatedData.length"
                        >
                          <div
                            class="dropdown-items"
                            v-for="skill of updatedData"
                            :key="skill.id"
                          >
                            <div class="dropdown-content">
                              <h4>{{ skill.attributes.name }}</h4>
                            </div>
                            <div class="dropdown-action">
                              <div class="dropdown-action" v-if="!skill.status">
                                <a @click="addChip(skill)" class="add-action"
                                  >+</a
                                >
                              </div>
                              <div class="dropdown-action" v-if="skill.status">
                                <em class="icon-check"></em>
                                <span>Added</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label>Assessment Code</label>
                      <textarea
                        type="text"
                        maxlength="100"
                        class="form-control"
                        placeholder="Add assessment code"
                        v-model="assessment_code"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-sm-12" v-if="image">
                    <div class="form-group">
                      <label>Cover image caption</label>
                      <textarea
                        type="text"
                        class="form-control"
                        placeholder="Descriptive text of the cover image"
                        v-model="coverImageCaption"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="progress-bar-overlay" v-if="uploadPercent > 0">
            <div class="progress-bar-container">
              <progress-bar
                :options="options"
                :value="getPercentage"
                v-if="uploadPercent > 0"
              />
            </div>
          </div>
          <div class="form-section">
            <div class="learning-form-header d-flex align-items-start">
              <div class="col-lg-4 px-0">
                <h4>Introductory Documents</h4>
              </div>
              <input
                style="display: none"
                ref="fileInput1"
                type="file"
                @change="onFileSelect"
                multiple="multiple"
                accept=".pdf, .doc"
              />
              <div class="ml-auto text-right">
                <a
                  class="btn ml-2 mb-2 secondary medium"
                  @click="$refs.fileInput1.click()"
                  >Upload a Document</a
                >
                <a @click="selectDoc()" class="btn ml-2 mb-2 secondary medium"
                  >Select from Library</a
                >
              </div>
            </div>
            <p>Add documents for the learner about the learning module.</p>
            <div class="learning-data-list" v-if="docs.length">
              <div
                class="learning-data-item"
                v-for="(doc, i) in docs"
                :key="doc.id"
              >
                <div
                  class="learning-data-icon"
                  v-if="doc.attributes.file_name.includes('pdf')"
                >
                  <a
                    @click="
                      $modal.show('pdfModal', {
                        pdfUrl: doc.attributes.resource_url,
                      })
                    "
                  >
                    <img src="../assets/images/pdf-big-icon.png" alt="pdf" />
                  </a>
                </div>
                <div
                  class="learning-data-icon"
                  v-if="doc.attributes.file_name.includes('doc')"
                >
                  <img src="../assets/images/ddc-big-icon.png" alt="doc" />
                </div>
                <div class="learning-data-title">
                  <h4>{{ doc.attributes.file_name }}</h4>
                  <p>{{ size(doc.attributes.resource_size) }}</p>
                  <a @click="removeDoc(i, doc.id)">Remove</a>
                </div>
              </div>
            </div>
          </div>
          <modal
            name="uploadDocument"
            height="auto"
            :width="800"
            :scrollable="true"
            :clickToClose="false"
          >
            <div class="modal-content modal-pdf-content">
              <div class="modal-header">
                <div class="modal-title" id="exampleModalLabel">
                  <h5>Upload a Document</h5>
                </div>
              </div>
              <div class="modal-body modal-image-body">
                <form class="header-form" v-if="uploadGalleryStatus">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Search"
                      v-model="searchDoc"
                      v-debounce:700ms="searchDocument"
                    />
                    <em class="icon-search"></em>
                  </div>
                </form>
                <div class="modal-images-content">
                  <div class="modal-images-upload" v-if="!uploadGalleryStatus">
                    <div class="modal-images-placeholder">
                      <p>
                        Drag and drop your file here or
                        <span>
                          <input
                            type="text"
                            class="upload"
                            @click="$refs.fileInput.click()"
                          />Browse
                        </span>
                        for a file.
                      </p>
                      video
                    </div>
                  </div>
                  <v-infinite-scroll
                    @bottom="nextPage"
                    :offset="20"
                    class="modal-content-scroll"
                    v-if="uploadGalleryStatus"
                  >
                    <div class="modal-images-library">
                      <div class="modal-images-row" v-if="docLists.length">
                        <div
                          class="modal-images-col"
                          v-for="doc in docLists"
                          :key="doc.id"
                        >
                          <div class="modal-images-checkbox">
                            <label class="custom-checkbox">
                              <input
                                type="checkbox"
                                name="image"
                                v-model="doc.attributes.status"
                              />
                              <span class="checkmark circle"></span>
                              <div class="modal-images-box">
                                <img
                                  alt="pdf"
                                  src="../assets/images/pdf-big-icon.png"
                                  class="modal-pdf-icon"
                                />
                              </div>
                              <div class="modal-images-info">
                                <h4>{{ doc.attributes.file_name }}</h4>
                                <p v-if="doc.attributes.tag_list">
                                  {{ doc.attributes.tag_list }}
                                </p>
                              </div>
                              <div class="checked"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-infinite-scroll>
                  <div class="data-no-result" v-if="docLists.length === 0">
                    <h3>No results found</h3>
                    <p>Try using a different keyword</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="cancelDocGallery()"
                  type="button"
                  class="btn default"
                >
                  Cancel
                </button>
                <button
                  v-if="docLists.length > 0"
                  type="button"
                  class="btn primary"
                  @click="saveDoc()"
                >
                  Save
                </button>
              </div>
            </div>
          </modal>
          <div class="form-section">
            <div class="learning-form-header d-flex align-items-start">
              <div class="col-lg-4 px-0">
                <h4>Introductory Video</h4>
              </div>
              <div class="ml-auto text-right">
                <a
                  class="btn ml-2 mb-2 secondary medium"
                  @click="addVideo('uploadVideo')"
                  >Add a new video</a
                >
                <a
                  @click="addVideoFromGallery('uploadVideo')"
                  class="btn ml-2 mb-2 secondary medium"
                  >Select from Library</a
                >
              </div>
            </div>
            <p>Add an introductory video for the learning module.</p>
            <div class="learning-data-list" v-if="video.attributes">
              <div class="learning-data-item w-100">
                <div class="learning-data-video">
                  <div
                    class="wistia_responsive_padding data-video"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper data-video"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                      <iframe
                        :src="
                          preIframUrl +
                          video.attributes.wistia_code +
                          postIframUrl
                        "
                        title="Video.mp4"
                        class="wistia_embed"
                        name="wistia_embed"
                        allowtransparency="true"
                        scrolling="no"
                        allowfullscreen
                        mozallowfullscreen
                        webkitallowfullscreen
                        oallowfullscreen
                        msallowfullscreen
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div class="learning-data-title">
                  <h4>{{ video.attributes.title }}</h4>
                  <a @click="removeVideo()">Remove</a>
                </div>
              </div>
            </div>
          </div>

          <div class="form-section">
            <div class="learning-form-header d-flex align-items-start">
              <div class="col-lg-4 px-0">
                <h4>Brand Images</h4>
              </div>
              <input
                style="display: none"
                ref="fileInput2"
                type="file"
                @change="onBrandImageSelect"
                multiple="multiple"
                accept="image/*"
              />
              <div class="ml-auto text-right">
                <a
                  class="btn ml-2 mb-2 secondary medium"
                  @click="$refs.fileInput2.click()"
                  >Upload Image(s)</a
                >
              </div>
            </div>
            <p>Add brand images for the learner application.</p>
            <div
              class="learning-data-list"
              v-if="brandImages.length && brandImagePreviews.length === 0"
            >
              <div
                class="learning-data-item brand-image-item"
                v-for="(bImage, index) in brandImages"
                :key="bImage.id"
              >
                <img :src="bImage.large_version" alt="bimage" />
                <button 
                  @click="removeBrandImage(index)" 
                  class="btn delete-img"
                >
                  Delete Brand Image
                </button>
                <div class="learning-data-title" v-if="bImage"></div>
              </div>
            </div>
            <div
              class="learning-data-list"
              v-if="brandImagePreviews.length > 0"
            >
              <div
                class="learning-data-item"
                v-for="bImage in brandImagePreviews"
                :key="bImage.id"
              >
                <img :src="bImage" alt="bimage" />
                <button class="btn delete-img">Delete Brand Image</button>
              </div>
            </div>
          </div>

          <!-- ADMIN GRADING AT AN ELM LEVEL -->
          <div class="form-section">
            <div class="learning-form-header d-flex align-items-start">
              <div class="col-lg-4 px-0">
                <h4>ELM Grading</h4>
              </div>
              <!-- <input
                style="display: none;"
                ref="fileInput2"
                type="file"
                @change="onBrandImageSelect"
                multiple="multiple"
                accept="image/*"
              /> -->
              <div class="ml-auto text-right">
                <label class="switch-grading ml-auto">
                  <input type="checkbox" v-model="elmGradingActivate" />
                  <span class="slider-grading round"></span>
                </label>
                <!-- <a
                  class="btn ml-2 mb-2 secondary medium"
                  @click="showGradingSetupModal"
                >Grading Setup</a> -->
              </div>
            </div>
            <p>Configure ELM grading.</p>

            <!-- ELM Grading Setting-->

            <div class="row custom-row" v-if="elmGradingActivate">
              <div class="col-sm-7">
                <!-- <div class="row custom-row"> -->
                <div class="col-sm-5">
                  <label
                    class="custom-checkbox left checkbox-grading"
                    name="quizAssessment"
                  >
                    <input
                      :disabled="!elmGradingActivate"
                      type="checkbox"
                      name="quizAssessment"
                      v-model="assessmentCheck"
                      v-bind:value="'quizAssessment'"
                    />
                    <span for="quizAssessment" class="checkmark"></span>
                    Assessment
                  </label>
                </div>
                <div class="col-sm-5">
                  <label
                    class="custom-checkbox left checkbox-grading"
                    name="quizAssessment"
                  >
                    <input
                      :disabled="!elmGradingActivate"
                      type="checkbox"
                      name="quizAssessment"
                      v-model="completionCheck"
                      v-bind:value="'quizAssessment'"
                    />
                    <span for="quizAssessment" class="checkmark"></span>
                    Completion
                  </label>
                </div>
                <!-- </div> -->
              </div>
              <div class="col-sm-5">
                <label
                  class="custom-checkbox left checkbox-grading"
                  name="quizAssessment"
                >
                  <input
                    :disabled="!elmGradingActivate"
                    type="checkbox"
                    name="quizAssessment"
                    v-model="enableInstructorEdit"
                    v-bind:value="'quizAssessment'"
                  />
                  <span for="quizAssessment" class="checkmark"></span>
                  Enable Instructor Edit
                </label>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div class="setup-content" v-if="elmGradingActivate">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="head-title">Learner Overall Grade Display Type:</h4>
              </div>

              <form class="w-100">
                <div class="row custom-row">
                  <div class="col-sm-5">
                    <label class="custom-checkbox" name="public">
                      Labels
                      <input
                        :disabled="!elmGradingActivate"
                        type="radio"
                        name="public"
                        v-model="gradeDisplayType"
                        v-bind:value="'labels'"
                      />
                      <span for="public" class="checkmark circle"></span>
                    </label>
                    <label class="custom-checkbox" name="public">
                      Percentage
                      <input
                        :disabled="!elmGradingActivate"
                        type="radio"
                        name="public"
                        v-model="gradeDisplayType"
                        v-bind:value="'percentage'"
                      />
                      <span for="public" class="checkmark circle"></span>
                    </label>
                    <label class="custom-checkbox" name="public">
                      Both
                      <input
                        :disabled="!elmGradingActivate"
                        type="radio"
                        name="public"
                        v-model="gradeDisplayType"
                        v-bind:value="'both'"
                      />
                      <span for="public" class="checkmark circle"></span>
                    </label>
                    <label class="custom-checkbox" name="public">
                      Hide
                      <input
                        :disabled="!elmGradingActivate"
                        type="radio"
                        name="public"
                        v-model="gradeDisplayType"
                        v-bind:value="'hide'"
                      />
                      <span for="public" class="checkmark circle"></span>
                    </label>
                  </div>

                  <div class="col-sm-5">
                    <span
                      v-for="(ro, inx) in multiRowsAssessmentLabels"
                      :key="inx"
                    >
                      <div v-if="!ro.deleted">
                        <div class="ai-row ai-rows-header">
                          <!--<span class="pr-4">Number of rows: {{RowNumbersSelected}}  </span>-->

                          <span class="pr-4">
                            <input
                              v-model.number="ro.min"
                              :disabled="!elmGradingActivate"
                              class="custom-input-style-grade-number"
                              type="number"
                            />
                          </span>

                          <span> &#x2192; </span>

                          <span class="pr-4">
                            <input
                              v-model.number="ro.max"
                              :disabled="!elmGradingActivate"
                              class="custom-input-style-grade-number"
                              type="number"
                            />
                          </span>

                          <span>
                            <input
                              v-model="ro.label"
                              placeholder="Assessment Label"
                              :disabled="!elmGradingActivate"
                              class="custom-input-style-grade"
                              type="text"
                            />
                          </span>
                          <!-- 
                                <span > 
                                  &#128465;
                                </span> -->
                          <span
                            ><a
                              v-if="elmGradingActivate"
                              href="javascript:void(0);"
                              @click="deleteRow(inx)"
                              >&#128465;</a
                            >
                          </span>
                        </div>
                      </div>
                    </span>
                    <div class="form-group pb-4">
                      <a v-if="elmGradingActivate" @click="addNewRange()"
                        >+ Add Grade Range</a
                      >
                    </div>
                  </div>
                </div>
              </form>
              <!-- <div class="pt-3 text-right" v-if="assessmentCheck || completionCheck">
                <a
                  href="javascript:void(0);"
                  class="btn secondary"
                  @click="saveGradingSetup"
                >Save</a>
              </div> -->
            </div>
            <!-- <div class="pt-3 text-right" v-if="assessmentCheck || completionCheck">
                <a
                  href="javascript:void(0);"
                  class="btn secondary"
                  @click="saveGradingSetup"
                >Save</a>
              </div> -->

            <!-- Elm Grading Setting end-->
          </div>

          <!-- ADMIN GRADING AT AN ELM LEVEL END -->

          <!-- ADMIN Integrity Start
           -->
          <div class="form-section">
            <div class="learning-form-header d-flex align-items-start">
              <div class="col-lg-4 px-0">
                <h4>Learner Academic Integrity Tab</h4>
              </div>

              <div class="ml-auto text-right">
                <label class="switch-grading ml-auto">
                  <input type="checkbox" v-model="integrityActivate" />
                  <span class="slider-grading round"></span>
                </label>
              </div>
            </div>
            <div class="row" v-if="integrityActivate">
              <div class="col-sm-6">
                <div class="integrity-video" v-if="!videoIntegrity.attributes">
                  <a
                    class="btn ml-2 mb-2 secondary medium"
                    @click="addVideoIntegrity('uploadVideoIntegrity')"
                    >Add a new video</a
                  >
                </div>

                <div
                  class="learning-data-list"
                  v-if="videoIntegrity.attributes && integrityActivate"
                >
                  <div class="learning-data-item w-100">
                    <div class="learning-data-video">
                      <div
                        class="wistia_responsive_padding data-video"
                        style="padding: 56.25% 0 0 0; position: relative"
                      >
                        <div
                          class="wistia_responsive_wrapper data-video"
                          style="
                            height: 100%;
                            left: 0;
                            position: absolute;
                            top: 0;
                            width: 100%;
                          "
                        >
                          <iframe
                            :src="
                              preIframUrl +
                              videoIntegrity.attributes.wistia_code +
                              postIframUrl
                            "
                            title="Video.mp4"
                            class="wistia_embed"
                            name="wistia_embed"
                            allowtransparency="true"
                            scrolling="no"
                            allowfullscreen
                            mozallowfullscreen
                            webkitallowfullscreen
                            oallowfullscreen
                            msallowfullscreen
                            width="100%"
                            height="100%"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="learning-data-title">
                    <h4>{{ videoIntegrity.attributes.title }}</h4>
                    <a @click="removeVideoIntegrity()">Remove</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 integrity-video">
                <div class="form-group">
                  <label>Learner Text</label>
                  <textarea
                    type="text"
                    v-model="learnerIntegrityText"
                    class="form-control small-textarea-height"
                    placeholder=""
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <!-- ADMIN Integrity END -->

          <div class="elm-header elm-footer">
            <router-link to="/learning-modules" class="btn-link mr-5"
              >Cancel</router-link
            >
            <button class="btn header-btn primary big" @click="createElm">
              Save & Add Learning Objects
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal name="uploadVideo" height="auto" :width="800" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Upload Videos</h5>
          </div>
        </div>
        <div class="modal-body modal-image-body">
          <div class="modal-images-tab">
            <a
              @click="videoStatus()"
              :class="[!uploadVideoGallery ? 'active' : '']"
              >New Video</a
            >
            <a
              @click="videoStatus()"
              :class="[uploadVideoGallery ? 'active' : '']"
              >Library</a
            >
          </div>
          <form class="header-form" v-if="uploadVideoGallery">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchVideo"
                v-on:input="searchVideos"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <div class="modal-images-content">
            <div class="modal-images-upload" v-if="!uploadVideoGallery">
              <ValidationObserver ref="form">
                <form
                  class="modal-form organisation-form"
                  @submit.prevent="submitForm"
                >
                  <div class="row">
                    <div class="form-group col">
                      <label class="form-label">Card Title</label>
                      <ValidationProvider
                        name="Card Title"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="title"
                          class="form-control"
                          id="title"
                          aria-describedby="emailHelp"
                          v-model="title"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                    </div>
                    <div class="form-group col">
                      <label class="form-label">Wistia Code</label>
                      <ValidationProvider
                        name="Wistia Code"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="wistia_code"
                          class="form-control"
                          id="wistia_code"
                          aria-describedby="emailHelp"
                          v-model="wistia_code"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          v-if="wistia_code"
                          :class="['success', 'cursor']"
                          @click="getWistiaInfo()"
                          >Fetch Video Meta Info</span
                        >
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Transcript Description</label>
                    <vue-editor
                      :editorToolbar="fullToolbar"
                      v-model="transcript"
                    ></vue-editor>
                  </div>
                  <div class="form-group">
                    <label class="form-label"> Video Duration</label>
                    <ValidationProvider
                      name="Video Duration"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        type="duration"
                        class="form-control"
                        aria-describedby="emailHelp"
                        v-model="duration"
                        :class="[isActive && errors[0] ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[
                          isActive ? { display: 'block' } : { display: 'none' },
                        ]"
                        v-if="errors[0]"
                        >{{ errors[0].replace("The ", "") }}</span
                      >
                    </ValidationProvider>
                  </div>
                  <div
                    class="form-group organisation-image-group flex-wrap mt-0"
                  >
                    <label class="form-label w-100">Wistia Thumbnail</label>
                    <div class="organisation-image-pic cursor">
                      <img
                        :src="wistaThumbnail"
                        v-if="wistaThumbnail"
                        alt="images"
                      />
                    </div>
                    <div class="organisation-image-remove">
                      <input
                        style="display: none"
                        ref="fileInput"
                        type="file"
                        @change="onFile"
                        accept="image/*"
                      />
                      <a
                        class="btn add"
                        @click="$refs.fileInput.click()"
                        v-if="!wistaThumbnail"
                        >Add Photo</a
                      >
                      <a class="btn delete" @click="removeWistaThumbnail" v-else
                        >Remove Photo</a
                      >
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn default"
                      data-toggle="modal"
                      data-target="#AddVideo"
                      data-dismiss="modal"
                      @click="hide('uploadVideo')"
                    >
                      Cancel
                    </button>
                    <button type="submit" class="btn primary">Save</button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div class="modal-images-library" v-if="uploadVideoGallery">
              <v-infinite-scroll
                @bottom="nextPageVideos"
                :offset="20"
                class="modal-content-scroll"
              >
                <div class="modal-videos-row">
                  <div
                    class="modal-videos-col"
                    v-for="video in videoLists"
                    :key="video.id"
                  >
                    <div
                      class="modal-images-checkbox"
                      @click="
                        $modal.hide('uploadVideo');
                        selectedVideo(video);
                      "
                    >
                      <label class="custom-checkbox">
                        <input
                          type="checkbox"
                          name="video"
                          v-model="video.attributes.status"
                        />
                        <span class="checkmark circle"></span>
                        <div
                          class="wistia_responsive_padding data-video"
                          style="padding: 56.25% 0 0 0; position: relative"
                        >
                          <div
                            class="wistia_responsive_wrapper data-video"
                            style="
                              height: 100%;
                              left: 0;
                              position: absolute;
                              top: 0;
                              width: 100%;
                            "
                          >
                            <iframe
                              :src="
                                preIframUrl +
                                video.attributes.wistia_code +
                                postIframUrl
                              "
                              title="Video.mp4"
                              class="wistia_embed"
                              name="wistia_embed"
                              allowtransparency="true"
                              scrolling="no"
                              allowfullscreen
                              mozallowfullscreen
                              webkitallowfullscreen
                              oallowfullscreen
                              msallowfullscreen
                              width="100%"
                              height="100%"
                            ></iframe>
                          </div>
                        </div>
                        <div class="modal-images-info">
                          <h4>{{ video.attributes.title }}</h4>
                          <p
                            v-for="tag in video.attributes.tag_list"
                            :key="tag"
                          >
                            {{ tag }}
                          </p>
                        </div>
                        <div class="checked"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </v-infinite-scroll>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="uploadVideoGallery">
          <button
            @click="hide('uploadVideo')"
            type="button"
            class="btn default"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn primary"
            @click="$modal.hide('uploadVideo')"
          >
            Save
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="uploadVideoIntegrity"
      height="auto"
      :width="800"
      :scrollable="true"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Upload Videos</h5>
          </div>
        </div>
        <div class="modal-body modal-image-body">
          <div class="modal-images-tab">
            <a
              @click="videoStatusIntegrity()"
              :class="[!uploadVideoGalleryIntegrity ? 'active' : '']"
              >New Video</a
            >
            <a
              @click="videoStatusIntegrity()"
              :class="[uploadVideoGalleryIntegrity ? 'active' : '']"
              >Library</a
            >
          </div>
          <form class="header-form" v-if="uploadVideoGalleryIntegrity">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchVideo"
                v-on:input="searchVideos"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <div class="modal-images-content">
            <div
              class="modal-images-upload"
              v-if="!uploadVideoGalleryIntegrity"
            >
              <ValidationObserver ref="form">
                <form
                  class="modal-form organisation-form"
                  @submit.prevent="submitForm"
                >
                  <div class="row">
                    <div class="form-group col">
                      <label class="form-label">Card Title</label>
                      <ValidationProvider
                        name="Card Title"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="title"
                          class="form-control"
                          id="title"
                          aria-describedby="emailHelp"
                          v-model="title"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                    </div>
                    <div class="form-group col">
                      <label class="form-label">Wistia Code</label>
                      <ValidationProvider
                        name="Wistia Code"
                        rules="required|max:100"
                        v-slot="{ errors }"
                      >
                        <input
                          type="wistia_code"
                          class="form-control"
                          id="wistia_code"
                          aria-describedby="emailHelp"
                          v-model="wistia_code"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                        />
                        <span
                          v-if="wistia_code"
                          :class="['success', 'cursor']"
                          @click="getWistiaInfo()"
                          >Fetch Video Meta Info</span
                        >
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Transcript Description</label>
                    <vue-editor
                      :editorToolbar="fullToolbar"
                      v-model="transcript"
                    ></vue-editor>
                  </div>
                  <div class="form-group">
                    <label class="form-label"> Video Duration</label>
                    <ValidationProvider
                      name="Video Duration"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        type="duration"
                        class="form-control"
                        aria-describedby="emailHelp"
                        v-model="duration"
                        :class="[isActive && errors[0] ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[
                          isActive ? { display: 'block' } : { display: 'none' },
                        ]"
                        v-if="errors[0]"
                        >{{ errors[0].replace("The ", "") }}</span
                      >
                    </ValidationProvider>
                  </div>
                  <div
                    class="form-group organisation-image-group flex-wrap mt-0"
                  >
                    <label class="form-label w-100">Wistia Thumbnail</label>
                    <div class="organisation-image-pic cursor">
                      <img
                        :src="wistaThumbnail"
                        v-if="wistaThumbnail"
                        alt="images"
                      />
                    </div>
                    <div class="organisation-image-remove">
                      <input
                        style="display: none"
                        ref="fileInput"
                        type="file"
                        @change="onFile"
                        accept="image/*"
                      />
                      <a
                        class="btn add"
                        @click="$refs.fileInput.click()"
                        v-if="!wistaThumbnail"
                        >Add Photo</a
                      >
                      <a class="btn delete" @click="removeWistaThumbnail" v-else
                        >Remove Photo</a
                      >
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn default"
                      data-toggle="modal"
                      data-target="#AddVideo"
                      data-dismiss="modal"
                      @click="hide('uploadVideoIntegrity')"
                    >
                      Cancel
                    </button>
                    <button type="submit" class="btn primary">Save</button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div
              class="modal-images-library"
              v-if="uploadVideoGalleryIntegrity"
            >
              <v-infinite-scroll
                @bottom="nextPageVideos"
                :offset="20"
                class="modal-content-scroll"
              >
                <div class="modal-videos-row">
                  <div
                    class="modal-videos-col"
                    v-for="video in videoLists"
                    :key="video.id"
                  >
                    <div
                      class="modal-images-checkbox"
                      @click="
                        $modal.hide('uploadVideoIntegrity');
                        selectedVideoIntegrity(video);
                      "
                    >
                      <label class="custom-checkbox">
                        <input
                          type="checkbox"
                          name="video"
                          v-model="video.attributes.status"
                        />
                        <!-- <input type="checkbox" name="video"  /> -->
                        <span class="checkmark circle"></span>
                        <div
                          class="wistia_responsive_padding data-video"
                          style="padding: 56.25% 0 0 0; position: relative"
                        >
                          <div
                            class="wistia_responsive_wrapper data-video"
                            style="
                              height: 100%;
                              left: 0;
                              position: absolute;
                              top: 0;
                              width: 100%;
                            "
                          >
                            <iframe
                              :src="
                                preIframUrl +
                                video.attributes.wistia_code +
                                postIframUrl
                              "
                              title="Video.mp4"
                              class="wistia_embed"
                              name="wistia_embed"
                              allowtransparency="true"
                              scrolling="no"
                              allowfullscreen
                              mozallowfullscreen
                              webkitallowfullscreen
                              oallowfullscreen
                              msallowfullscreen
                              width="100%"
                              height="100%"
                            ></iframe>
                          </div>
                        </div>
                        <div class="modal-images-info">
                          <h4>{{ video.attributes.title }}</h4>
                          <p
                            v-for="tag in video.attributes.tag_list"
                            :key="tag"
                          >
                            {{ tag }}
                          </p>
                        </div>
                        <div class="checked"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </v-infinite-scroll>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="uploadVideoGalleryIntegrity">
          <button
            @click="hide('uploadVideoIntegrity')"
            type="button"
            class="btn default"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn primary"
            @click="$modal.hide('uploadVideoIntegrity')"
          >
            Save
          </button>
        </div>
      </div>
    </modal>

    <!-- grading modal start -->
    <modal
      name="GradingSetupModal"
      height="auto"
      :width="1400"
      :scrollable="true"
    >
      <div class="hide-modal" @click="$modal.hide('GradingSetupModal')"></div>
      <div class="modal-content modal-default">
        <div class="row d-inline-flex">
          <div class="col-3">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Enable ELM Grading</h5>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="switch-grading ml-auto">
              <input type="checkbox" v-model="elmGradingActivate" />
              <span class="slider-grading round"></span>
            </label>
          </div>
        </div>

        <div class="row custom-row">
          <div class="col-sm-7">
            <!-- <div class="row custom-row"> -->
            <div class="col-sm-5">
              <label
                class="custom-checkbox left checkbox-grading"
                name="quizAssessment"
              >
                <input
                  :disabled="!elmGradingActivate"
                  type="checkbox"
                  name="quizAssessment"
                  v-model="assessmentCheck"
                  v-bind:value="'quizAssessment'"
                />
                <span for="quizAssessment" class="checkmark"></span>
                Assessment
              </label>
            </div>
            <div class="col-sm-5">
              <label
                class="custom-checkbox left checkbox-grading"
                name="quizAssessment"
              >
                <input
                  :disabled="!elmGradingActivate"
                  type="checkbox"
                  name="quizAssessment"
                  v-model="completionCheck"
                  v-bind:value="'quizAssessment'"
                />
                <span for="quizAssessment" class="checkmark"></span>
                Completion
              </label>
            </div>
            <!-- </div> -->
          </div>
          <div class="col-sm-5">
            <label
              class="custom-checkbox left checkbox-grading"
              name="quizAssessment"
            >
              <input
                :disabled="!elmGradingActivate"
                type="checkbox"
                name="quizAssessment"
                v-model="enableInstructorEdit"
                v-bind:value="'quizAssessment'"
              />
              <span for="quizAssessment" class="checkmark"></span>
              Enable Instructor Edit
            </label>
          </div>
        </div>

        <br />
        <br />
        <br />

        <div class="setup-content">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Learner Overall Grade Display Type:</h4>
          </div>

          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <label class="custom-checkbox" name="public">
                  Labels
                  <input
                    :disabled="!elmGradingActivate"
                    type="radio"
                    name="public"
                    v-model="gradeDisplayType"
                    v-bind:value="'labels'"
                  />
                  <span for="public" class="checkmark circle"></span>
                </label>
                <label class="custom-checkbox" name="public">
                  Percentage
                  <input
                    :disabled="!elmGradingActivate"
                    type="radio"
                    name="public"
                    v-model="gradeDisplayType"
                    v-bind:value="'percentage'"
                  />
                  <span for="public" class="checkmark circle"></span>
                </label>
                <label class="custom-checkbox" name="public">
                  Both
                  <input
                    :disabled="!elmGradingActivate"
                    type="radio"
                    name="public"
                    v-model="gradeDisplayType"
                    v-bind:value="'both'"
                  />
                  <span for="public" class="checkmark circle"></span>
                </label>
                <label class="custom-checkbox" name="public">
                  Hide
                  <input
                    :disabled="!elmGradingActivate"
                    type="radio"
                    name="public"
                    v-model="gradeDisplayType"
                    v-bind:value="'hide'"
                  />
                  <span for="public" class="checkmark circle"></span>
                </label>
              </div>

              <div class="col-sm-5">
                <span v-for="(ro, inx) in multiRowsAssessmentLabels" :key="inx">
                  <div v-if="!ro.deleted">
                    <div class="ai-row ai-rows-header">
                      <!--<span class="pr-4">Number of rows: {{RowNumbersSelected}}  </span>-->

                      <span class="pr-4">
                        <input
                          v-model.number="ro.min"
                          :disabled="!elmGradingActivate"
                          class="custom-input-style-grade-number"
                          type="number"
                        />
                      </span>

                      <span> &#x2192; </span>

                      <span class="pr-4">
                        <input
                          v-model.number="ro.max"
                          :disabled="!elmGradingActivate"
                          class="custom-input-style-grade-number"
                          type="number"
                        />
                      </span>

                      <span>
                        <input
                          v-model="ro.label"
                          placeholder="Assessment Label"
                          :disabled="!elmGradingActivate"
                          class="custom-input-style-grade"
                          type="text"
                        />
                      </span>
                      <!-- 
                                <span > 
                                  &#128465;
                                </span> -->
                      <span
                        ><a
                          v-if="elmGradingActivate"
                          href="javascript:void(0);"
                          @click="deleteRow(inx)"
                          >&#128465;</a
                        >
                      </span>
                    </div>
                  </div>
                </span>
                <div class="form-group pb-4">
                  <a v-if="elmGradingActivate" @click="addNewRange()"
                    >+ Add Grade Range</a
                  >
                </div>
              </div>
            </div>
          </form>
          <div
            class="pt-3 text-right"
            v-if="assessmentCheck || completionCheck"
          >
            <a
              href="javascript:void(0);"
              class="btn secondary"
              @click="saveGradingSetup"
              >Save</a
            >
          </div>
        </div>

        <!-- <div class="modal-body question-modal-body">
              <div class="row">
                <div class="col-6" @click="addQuestionType('multiple_choice')">
                  <a class="add-question-link">
                    <em class="icon-multiple-icon"></em>
                    <span>Multiple Choice</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('numeric')">
                  <a class="add-question-link">
                    <em class="icon-number-icon"></em>
                    <span>Specific Number</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('long_answer')">
                  <a class="add-question-link">
                    <em class="icon-question-icon"></em>
                    <span>AI Text Questions</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('range')">
                  <a class="add-question-link add-question-small">
                    <em class="icon-range-icon"></em>
                    <span>Number in Range</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('ai_text_rows')">
                  <a class="add-question-link" @click="showSelectQuestionModalMultiRow">
                    <em class="icon-question-icon"></em>
                    <span>AI Text Rows</span>
                  </a>
                </div>
              </div>
            </div>   -->
      </div>
    </modal>
    <!-- grading modal start end-->

    <pdfModal />
  </div>
</template>

<script>
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import pdfModal from "../components/modal/PdfComponent.vue";
import ProgressBar from "vuejs-progress-bar";
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "CreateELM",
  components: {
    ProgressBar,
    pdfModal,
    VueEditor,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      learnerIntegrityText: "",
      integrityActivate: false,
      videoIntegrityId: 0,
      gradingType: "",
      gradeDisplayType: "labels",
      enableInstructorEdit: false,
      completionCheck: false,
      assessmentCheck: true,
      elmGradingActivate: false,
      multiRowsAssessmentLabels: [],
      wistaThumbnail: "",
      selectedWistaThumbnail: null,
      wistia_thumbnail_url: "",
      duration: "",
      skillsPlaceholder: "Search Skills",
      id: this.$route.params.id,
      elmDetails: "",
      note: "",
      name: "",
      searchRole: "",
      searchWorld: "",
      searchOrg: "",
      worldId: "",
      expectedHours: "",
      orgLists: [],
      roles: [],
      orgDetails: {},
      isActive: false,
      timeToComplete: null,
      description: "",
      final_debrief_overview: "",
      assessment_code: "",
      selectedFile: null,
      skills: [],
      designerLists: [],
      smeLists: [],
      smeId: "",
      designerId: "",
      searchDesigner: "",
      searchSme: "",
      image: null,
      orgs: [],
      searchOrganization: [],
      learningObjectives: "",
      settings: {
        maxScrollbarLength: false,
      },
      skill: "",
      chips: [],
      worldLists: [],
      currentInput: "",
      updatedData: [],
      listArray: [],
      skillLists: [],
      page: 1,
      docLists: [],
      totalDocs: "",
      docs: [],
      brandImages: [],
      brandImagePreviews: [],
      selectedImageFile: null,
      uploadBrandImageStatus: false,
      totalPages: [],
      searchDoc: "",
      sortOrder: "desc",
      sortColumn: "id",
      searchVideo: "",
      videoLists: [],
      uploadGalleryStatus: true,
      uploadVideoGallery: true,
      uploadVideoGalleryIntegrity: false,
      uploadDocs: [],
      totalVideos: "",
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      video: {},
      videoIntegrity: {},
      url: utilFunctionService.getImageUrl(),
      wistia_code: "",
      title: "",
      fullToolbar: [],
      transcript: "",
      uploadPercent: 0,
      uploadImageStatus: true,
      options: {
        text: {
          color: "#FFF",
          shadowEnable: true,
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "rgba(0, 0, 0, 0.09)",
        },
        layout: {
          height: 15,
          width: 200,
          verticalTextAlign: 80,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "line",
        },
      },
      coverImageCaption: ""
    };
  },
  methods: {
    saveGradingSetup() {
      for (let i = 0; i < this.multiRowsAssessmentLabels.length; i++) {
        if (
          this.multiRowsAssessmentLabels[i].added &&
          !this.multiRowsAssessmentLabels[i].deleted
        ) {
          const fb = new FormData();
          fb.append("learn_mod[enable_grading]", this.elmGradingActivate);
          fb.append(
            "learn_mod[overall_grade_display_type]",
            this.gradeDisplayType
          );
          if (this.assessmentCheck && this.completionCheck) {
            fb.append("learn_mod[grading_type]", "combination");
          } else if (this.completionCheck) {
            fb.append("learn_mod[grading_type]", "completion");
          } else {
            fb.append("learn_mod[grading_type]", "assessment");
          }
          fb.append(
            "learn_mod[overall_grade_assessments_attributes][0][min_percentage]",
            this.multiRowsAssessmentLabels[i].min
          );
          fb.append(
            "learn_mod[overall_grade_assessments_attributes][0][max_percentage]",
            this.multiRowsAssessmentLabels[i].max
          );
          fb.append(
            "learn_mod[overall_grade_assessments_attributes][0][assessment_label]",
            this.multiRowsAssessmentLabels[i].label
          );
          api
            .editElm(fb, this.id)
            .then(() => {})
            .catch(() => {});
        }
        if (
          !this.multiRowsAssessmentLabels[i].added &&
          this.multiRowsAssessmentLabels[i].deleted
        ) {
          const fb = new FormData();
          fb.append("learn_mod[enable_grading]", this.elmGradingActivate);
          fb.append(
            "learn_mod[overall_grade_display_type]",
            this.gradeDisplayType
          );
          if (this.assessmentCheck && this.completionCheck) {
            fb.append("learn_mod[grading_type]", "combination");
          } else if (this.completionCheck) {
            fb.append("learn_mod[grading_type]", "completion");
          } else {
            fb.append("learn_mod[grading_type]", "assessment");
          }
          fb.append(
            "learn_mod[overall_grade_assessments_attributes][0][id]",
            this.multiRowsAssessmentLabels[i].id
          );
          fb.append(
            "learn_mod[overall_grade_assessments_attributes][0][_destroy]",
            true
          );
          api
            .editElm(fb, this.id)
            .then(() => {})
            .catch(() => {});
        }
      }
      const fb = new FormData();
      fb.append("learn_mod[enable_grading]", this.elmGradingActivate);
      fb.append("learn_mod[overall_grade_display_type]", this.gradeDisplayType);
      if (this.assessmentCheck && this.completionCheck) {
        fb.append("learn_mod[grading_type]", "combination");
      } else if (this.completionCheck) {
        fb.append("learn_mod[grading_type]", "completion");
      } else {
        fb.append("learn_mod[grading_type]", "assessment");
      }
      api
        .editElm(fb, this.id)
        .then(() => {})
        .catch(() => {});

      // api.editElm(fb, this.id).then(() => {
      //   utilFunctionService.showSuccess(commonConfig.appMessages.editElm);
      //   })
      //   .catch(() => {});
      // utilFunctionService.showSuccess(commonConfig.appMessages.editElm);
      // this.$modal.hide("GradingSetupModal");
    },
    deleteRow(inx) {
      let numb = 0;
      console.log(this.multiRowsAssessmentLabels);
      for (let i = 0; i < this.multiRowsAssessmentLabels.length; i++) {
        if (!this.multiRowsAssessmentLabels[i].deleted) {
          numb = numb + 1;
        }
      }
      if (numb > 2) {
        this.multiRowsAssessmentLabels[inx].deleted = true;
      }
    },
    addNewRange() {
      let numb = 0;
      console.log(this.multiRowsAssessmentLabels);
      for (let i = 0; i < this.multiRowsAssessmentLabels.length; i++) {
        if (!this.multiRowsAssessmentLabels[i].deleted) {
          numb = numb + 1;
        }
      }

      if (numb < 6) {
        const rowx = {
          min: 0.0,
          max: 0.0,
          label: "",
          added: true,
          deleted: false,
        };
        this.multiRowsAssessmentLabels.push(rowx);
      }
    },
    showGradingSetupModal() {
      // this.multiRowsAssessmentLabels= [];
      // for (let i =0; i<2; i++)
      // {
      //   const rowx ={
      //     min: 0.0,
      //     max: 0.0,
      //     label: "Assessment Label"}
      //   this.multiRowsAssessmentLabels.push(rowx)
      // }
      this.getElmDetails();
      this.$modal.show("GradingSetupModal");
    },
    getWistiaInfo() {
      api
        .videoDetail(this.wistia_code)
        .then((res) => {
          res = res.data;
          this.duration = res.duration;
          this.file_name = res.file_name;
          this.wistaThumbnail = res.thumbnail;
        })
        .catch(() => {
          this.duration = "";
          this.file_name = "";
          this.wistaThumbnail = "";
        });
    },
    submitForm() {
      this.$refs.form.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("global_video[title]", this.title);
          fb.append("global_video[wistia_code]", this.wistia_code);
          fb.append("global_video[transcript]", this.transcript);
          fb.append("global_video[duration]", this.duration);
          fb.append("global_video[video_type]", "content");
          fb.append("global_video[wistia_thumbnail_url]", this.wistaThumbnail);
          if (this.selectedWistaThumbnail) {
            fb.append("global_video[thumbnail]", this.selectedWistaThumbnail);
            fb.append("global_video[file_name]", this.file_name);
          }
          api
            .saveGlobalVideo(fb)
            .then((res) => {
              utilFunctionService.hideLoader();
              utilFunctionService.showSuccess(
                commonConfig.appMessages.addVideo
              );
              this.clearForm();
              this.video = res.data.data;
              this.hide("uploadVideo");
            })
            .catch(() => {
              utilFunctionService.hideLoader();
              this.clearForm();
              this.hide("uploadVideo");
            });
        }
      });
    },
    clearForm() {
      this.duration = "";
      this.wistia_code = "";
      this.title = "";
      this.transcript = "";
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    status() {
      this.uploadGalleryStatus = !this.uploadGalleryStatus;
    },
    videoStatus() {
      this.uploadVideoGallery = !this.uploadVideoGallery;
    },
    videoStatusIntegrity() {
      this.uploadVideoGalleryIntegrity = !this.uploadVideoGalleryIntegrity;
    },
    selectDoc() {
      if (this.docs.length) {
        this.docs.forEach((element) => {
          const index = this.docLists.findIndex(
            (elem) => elem.id === element.id
          );
          if (index !== -1) {
            this.docLists[index].attributes.status = true;
          }
        });
      }
      this.$modal.show("uploadDocument");
    },
    addVideo(modal) {
      this.uploadVideoGallery = false;
      this.$modal.show(modal);
    },
    addVideoIntegrity(modal) {
      this.uploadVideoGalleryIntegrity = false;
      this.$modal.show(modal);
    },
    addVideoFromGallery(modal) {
      this.uploadVideoGallery = true;
      if (this.video.id) {
        this.videoLists.map((x, i) => {
          this.videoLists[i].attributes.status = false;
        });
        const index = this.videoLists.findIndex(
          (elem) => elem.id === this.video.id
        );
        if (index !== -1) {
          this.videoLists[index].attributes.status = true;
          this.$modal.show(modal);
        }
      } else {
        this.$modal.show(modal);
      }
    },
    removeVideo() {
      this.videoLists.map((x, i) => {
        this.videoLists[i].attributes.status = false;
      });
      this.video = {};
    },
    removeVideoIntegrity() {
      this.videoLists.map((x, i) => {
        this.videoLists[i].attributes.status = false;
      });
      this.videoIntegrity = {};
    },
    removeDoc(i, id) {
      if (this.docs.length) {
        const index = this.docLists.findIndex((elem) => elem.id == id);
        const i = this.uploadDocs.findIndex((elem) => elem.id == id);
        const docIndex = this.docs.findIndex((elem) => elem.id == id);
        this.docs.splice(docIndex, 1);
        this.uploadDocs.splice(i, 1);
        if (index !== -1) {
          this.docLists[index].attributes.status = false;
        }
      }
    },
    nextPage() {
      ++this.page;
      if (this.page <= this.totalDocs) {
        this.getDoc(this.page);
      } else {
        return;
      }
    },
    nextPageVideos() {
      ++this.page;
      if (this.page <= this.totalVideos) {
        this.getVideo(this.page);
      } else {
        return;
      }
    },
    searchDocument() {
      api
        .getAllDoc(
          commonConfig.setting.searchPage,
          this.searchDoc,
          this.sortColumn,
          this.sortOrder
        )
        .then((res) => {
          this.docLists = res.data.data;
          this.totalDocs = res.data.filtered_count;
        })
        .catch();
    },
    onFileSelected(event) {
      this.uploadImageStatus = true;
      this.selectedFile = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = (e) => {
        this.image = e.target.result;
      };
    },
    uploadImageRemoved() {
      if (this.id) {
        this.removeElmPhoto();
      } else {
        this.image = null;
        this.selectedFile = null;
      }
    },
    onBrandImageSelect(event) {
      this.uploadBrandImageStatus = true;
      this.brandImages = [];
      this.brandImagePreviews = [];
      for (var i = 0; i < event.target.files.length; i++) {
        console.log(event.target.files[i], "*** event.target.files[i]");
        this.selectedImageFile = event.target.files[i];
        this.brandImages.push(this.selectedImageFile);

        let _reader = null;
        _reader = new FileReader();
        _reader.readAsDataURL(this.selectedImageFile);
        _reader.onload = (e) => {
          this.brandImagePreviews.push(e.target.result);
        };
      }
      console.log(
        this.brandImages,
        "*** this.brandImages after onBrandImageSelect execute ***"
      );
    },
    onFile(event) {
      this.selectedWistaThumbnail = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedWistaThumbnail);
      _reader.onload = (e) => {
        this.wistaThumbnail = e.target.result;
      };
    },
    removeWistaThumbnail() {
      this.wistaThumbnail = null;
      this.selectedWistaThumbnail = null;
    },
    onFileSelect(event) {
      utilFunctionService.showLoader();
      this.$modal.hide("uploadDocument");
      for (var i = 0; i < event.target.files.length; i++) {
        this.selectedFile = event.target.files[i];
        const formD = new FormData();
        if (this.selectedFile) {
          formD.append("global_resource[attachment]", this.selectedFile);
          formD.append("global_resource[resource_type]", "document");
          formD.append("global_resource[tag_list]", "");
          formD.append("global_resource[private]", false);
          let _accessToken = utilFunctionService.getAuthToken();
          axios
            .post(`${this.url}/api/admin/v1/global_resources.json`, formD, {
              headers: {
                Authorization: `${_accessToken}`,
                "content-type": "multipart/form-data",
              },
              onUploadProgress: function (progressEvent) {
                this.uploadPercent =
                  parseInt(
                    Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  ) !== 100
                    ? parseInt(
                        Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        )
                      )
                    : 0;
              }.bind(this),
            })
            .then((res) => {
              if (this.docs.length < 5) {
                this.uploadDocs = [];
                this.uploadDocs.push(res.data.data);
                this.docs.push(res.data.data);
                if (event.target.files.length === i) {
                  utilFunctionService.showSuccess(
                    commonConfig.appMessages.addDoc
                  );
                }
              } else {
                utilFunctionService.showErr(
                  commonConfig.appMessages.docUploadLimit
                );
              }
            })
            .catch(() => {
              if (event.target.files.length - 1 === i) {
                utilFunctionService.showErr(commonConfig.appMessages.errDoc);
                this.getDoc(this.page);
              }
            });
        }
      }
      utilFunctionService.hideLoader();
    },
    getDoc(page) {
      this.page = page;
      api
        .getAllDoc(page, this.searchDoc, this.sortColumn, this.sortOrder)
        .then((res) => {
          this.display = true;
          this.docLists = [...this.docLists, ...res.data.data];
          this.totalDocs = res.data.filtered_count;
        })
        .catch();
    },
    saveDoc() {
      const selectedDoc = this.docLists.filter(
        (x) => x.attributes.status === true
      );
      this.docs = [];
      const len = 5 - this.uploadDocs.length;
      if (selectedDoc.length <= len) {
        this.docs = [...this.docs, ...selectedDoc];
        this.uploadDocs.forEach((docData) => {
          this.docs.push(docData);
        });
        this.$modal.hide("uploadDocument");
      } else {
        utilFunctionService.showErr(commonConfig.appMessages.docUploadLimit);
        this.docs = [...this.docs, ...selectedDoc];
        this.uploadDocs.forEach((doc) => {
          this.docs.push(doc);
        });
      }
    },
    cancelDocGallery() {
      this.$modal.hide("uploadDocument");
    },
    selectedVideo(e) {
      this.video = e;
    },
    selectedVideoIntegrity(e) {
      this.videoIntegrity = e;
    },
    searchVideos() {
      api
        .getAllVideo(
          commonConfig.setting.searchPage,
          this.searchVideo,
          this.sortColumn,
          this.sortOrder
        )
        .then((res) => {
          this.videoLists = res.data.data;
          this.totalVideos = res.data.filtered_count;
        })
        .catch();
    },
    getVideo(page) {
      this.page = page;
      api
        .getAllVideo(page, this.searchVideo, this.sortColumn, this.sortOrder)
        .then((res) => {
          this.display = true;
          this.videoLists = [...this.videoLists, ...res.data.data];
          this.totalVideos = res.data.filtered_count;
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    getElmVideo(video) {
      this.video = video;
    },
    getDesc(desc) {
      return desc.substr(0, 85);
    },
    createElm() {
      this.$refs.form1.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          if (this.selectedFile)
            fb.append("learn_mod[photo]", this.selectedFile);
          fb.append("learn_mod[name]", this.name);
          fb.append("learn_mod[description]", this.description);
          fb.append(
            "learn_mod[final_debrief_overview]",
            this.final_debrief_overview
          );
          fb.append("learn_mod[assessment_code]", this.assessment_code);
          
          fb.append("learn_mod[abstract]", true);
          fb.append("learn_mod[time_to_complete]", this.timeToComplete);
          fb.append("learn_mod[world_id]", this.worldId);
          fb.append("learn_mod[lead_designer_id]", this.designerId);
          fb.append("learn_mod[sme_id]", this.smeId);
          fb.append("learn_mod[notes]", this.note);
          fb.append("learn_mod[learning_objectives]", this.learningObjectives);
          fb.append("learn_mod[intro_video_id]", this.video.id);
          fb.append("learn_mod[image_caption]", this.coverImageCaption);
          // Ingerity Add Video
          fb.append(
            "learn_mod[academic_integrity_video_id]",
            this.videoIntegrity.id
          );
          fb.append(
            "learn_mod[enable_academic_integrity]",
            this.integrityActivate
          );
          fb.append(
            "learn_mod[academic_integrity_text]",
            this.learnerIntegrityText
          );

          // Ingerity Add Video
          this.chips.map((x) => {
            fb.append("learn_mod[global_skill_ids][]", parseInt(x.id));
          });
          this.docs.map((x) => {
            fb.append("learn_mod[global_resource_ids][]", parseInt(x.id));
          });
          this.brandImages.map((x) => {
            fb.append("learn_mod[brand_images][]", x);
          });
          fb.append(
            "learn_mod[learn_mod_organizations_attributes][0][is_learner_organization]",
            true
          );
          fb.append(
            "learn_mod[learn_mod_organizations_attributes][0][world_organization_id]",
            this.orgDetails.id
          );
          fb.append(
            "learn_mod[learn_mod_organizations_attributes][0][world_role_id]",
            this.roleId
          );
          if (this.id && this.orgId)
            fb.append(
              "learn_mod[learn_mod_organizations_attributes][0][id]",
              this.orgId
            );
          if (this.id) {
            api
              .editElm(fb, this.id)
              .then(() => {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.editElm
                );
                // setTimeout(() => {
                //   this.$router.replace({ name: "learning-modules" });
                // }, 1500);
              })
              .catch(() => {});
          } else {
            api
              .saveElm(fb)
              .then(() => {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.createElm
                );
                setTimeout(() => {
                  this.$router.replace({ name: "learning-modules" });
                }, 1500);
              })
              .catch((error) => {
                if (error.response.status == 422) {
                  if (
                    error.response.data.errors &&
                    error.response.data.errors.name
                  ) {
                    utilFunctionService.showErr(
                      commonConfig.appMessages.nameTaken
                    );
                  }
                }
              });
          }
        }
      });
      this.saveGradingSetup();
    },
    searchWorlds() {
      if (this.searchWorld.length === 0) {
        this.worldLists = [];
        this.orgs = [];
        this.orgDetails = "";
        this.searchOrg = "";
        this.searchOrganization = [];
      } else {
        api
          .getElmWorlds(this.searchWorld)
          .then((res) => {
            this.worldLists = [];
            this.searchOrg = "";
            this.orgs = [];
            this.orgDetails = "";
            this.searchOrganization = [];
            this.worldLists = res.data.data;
          })
          .catch();
      }
    },
    assignWorld(id, name) {
      this.searchWorld = name;
      this.worldId = id;
      this.worldLists = [];
      this.orgs = [];
      this.searchOrganization = [];
      this.orgDetails = "";
      api
        .getAssociateOrg(this.worldId)
        .then((res) => {
          const result = res.data.data;
          this.orgs = result.map((x) => ({
            id: x.id,
            name: x.attributes.org_name,
          }));
        })
        .catch();
    },
    updateOrg() {
      this.worldLists = [];
      this.orgs = [];
      this.searchOrganization = [];
      this.orgDetails = "";
      api
        .getAssociateOrg(this.worldId)
        .then((res) => {
          const result = res.data.data;
          this.orgs = result.map((x) => ({
            id: x.id,
            name: x.attributes.org_name,
          }));
        })
        .catch();
    },
    getOrg() {
      if (this.searchOrg.length !== 0) {
        this.searchOrganization = this.orgs.filter((org) => {
          return org.name.toLowerCase().includes(this.searchOrg.toLowerCase());
        });
      } else {
        return (this.searchOrganization = this.orgs);
      }
    },
    getRole() {
      if (this.searchRole.length === 0) {
        this.roles = [];
      } else {
        api
          .getRoles(this.searchRole)
          .then((res) => {
            this.roles = res.data.data;
          })
          .catch();
      }
    },
    setRole(id, name) {
      this.searchRole = name;
      this.roleId = id;
      this.roles = [];
    },
    getDesigner() {
      if (this.searchDesigner.length === 0) {
        this.designerLists = [];
      } else {
        api
          .getDesigners(this.searchDesigner, "lead_designer")
          .then((res) => {
            this.designerLists = res.data.data;
          })
          .catch();
      }
    },
    setDesigner(id, name) {
      this.designerId = id;
      this.searchDesigner = name;
      this.designerLists = [];
    },
    getSme() {
      if (this.searchSme.length === 0) {
        this.smeLists = [];
      } else {
        api
          .getDesigners(this.searchSme, "sme")
          .then((res) => {
            this.smeLists = res.data.data;
          })
          .catch();
      }
    },
    setSme(id, name) {
      this.smeId = id;
      this.searchSme = name;
      this.smeLists = [];
    },
    assignOrg(org) {
      this.orgDetails = org;
      this.searchOrg = org.name;
      this.searchOrganization = [];
    },
    removeNote() {
      this.note = "";
    },
    saveChip() {
      const { chips, skill, set } = this;
      ((set && chips.indexOf(skill) === -1) || !set) && chips.push(skill);
      this.skill = "";
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
      if (this.chips.length == 0) {
        this.skillsPlaceholder = "Search Skills";
      }
    },
    backspaceDelete({ which }) {
      which === 8 &&
        this.skill === "" &&
        this.chips.splice(this.chips.length - 1);
    },
    addChip(e) {
      this.chips.push(e);
      this.skill = "";
      this.updatedData = [];
      this.skillsPlaceholder = "";
    },
    searchSkill() {
      if (this.skill.length === 0) {
        this.updatedData = [];
      } else {
        if (this.chips.length > 0) {
          this.skillLists.forEach((skill) => {
            skill.status = false;
          });

          this.chips.forEach((element) => {
            const index = this.skillLists.findIndex(
              (elem) => elem.attributes.name === element.attributes.name
            );
            if (index !== -1) {
              this.skillLists[index].status = true;
            } else {
              this.skillLists[index].status = false;
            }
          });
          this.updatedData = this.skillLists.filter((item) => {
            return (
              item.attributes.name
                .toLowerCase()
                .indexOf(this.skill.toLowerCase()) > -1
            );
          });
        } else {
          this.skillLists.forEach((skill) => {
            skill.status = false;
          });
          this.updatedData = this.skillLists.filter((item) => {
            return (
              item.attributes.name
                .toLowerCase()
                .indexOf(this.skill.toLowerCase()) > -1
            );
          });
        }
      }
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    },
    removeBrandImage(id) {
      utilFunctionService.showLoader();
      api.deleteBrandImage(this.id).then(() => {
        this.brandImages.splice(id, 1);
        this.selectedFile = null;
        utilFunctionService.hideLoader();
        utilFunctionService.showSuccess(
          commonConfig.appMessages.createElm
        );
      })
    },
    removeElmPhoto() {
      if (this.id) {
        api.removeElmPhoto(this.id).then(() => {
          this.image = null;
          this.selectedFile = null;
        });
      }
    },
    getVideoDetails(id) {
      api
        .getGlobalVideo(id)
        .then((res) => {
          console.log(res.data.data, "-- *** getVideoDetails response");
          this.videoIntegrity = res.data.data;
          // const video_data = res.data.data.attributes;
          // this.title = video_data.title;
          // this.wistia_code = video_data.wistia_code;
          // this.transcript = video_data.transcript;
          // this.wistia_thumbnail_url = video_data.wistia_thumbnail_url;
          // this.duration = video_data.duration;
        })
        .catch();
    },
    getElmDetails() {
      utilFunctionService.showLoader();
      api.getElmDetail(this.id).then((res) => {
        console.log(res);

        utilFunctionService.hideLoader();
        this.elmDetails = res.data.data;
        // Setup Grading Variables
        this.elmGradingActivate = this.elmDetails.attributes.enable_grading;
        this.enableInstructorEdit =
          this.elmDetails.attributes.enable_instructor_grading_edit;
        this.gradingType = this.elmDetails.attributes.grading_type;
        this.gradeDisplayType =
          this.elmDetails.attributes.overall_grade_display_type;
        this.learnerIntegrityText =
          this.elmDetails.attributes.academic_integrity_text;
        this.integrityActivate =
          this.elmDetails.attributes.enable_academic_integrity;
        this.videoIntegrityId =
          this.elmDetails.attributes.academic_integrity_video_id;
        this.getVideoDetails(this.videoIntegrityId);

        if (this.elmDetails.attributes.grading_type === "combination") {
          this.assessmentCheck = true;
          this.completionCheck = true;
        } else if (this.elmDetails.attributes.grading_type === "assessment") {
          this.assessmentCheck = true;
          this.completionCheck = false;
        } else {
          {
            this.assessmentCheck = false;
            this.completionCheck = true;
          }
        }
        this.multiRowsAssessmentLabels = [];
        for (
          let i = 0;
          i < this.elmDetails.attributes.overall_grade_assessments.length;
          i++
        ) {
          const rowx = {
            id: this.elmDetails.attributes.overall_grade_assessments[i].id,
            min: this.elmDetails.attributes.overall_grade_assessments[i]
              .attributes.min_percentage,
            max: this.elmDetails.attributes.overall_grade_assessments[i]
              .attributes.max_percentage,
            label:
              this.elmDetails.attributes.overall_grade_assessments[i].attributes
                .assessment_label,
            added: false,
            deleted: false,
          };
          this.multiRowsAssessmentLabels.push(rowx);
        }

        if (this.elmDetails.attributes.photo_url != false)
          this.image = this.elmDetails.attributes.photo_url.large_version;
          this.coverImageCaption = this.elmDetails.attributes.image_caption;
        if (this.elmDetails.attributes.brand_images != false) {
          this.brandImages = this.elmDetails.attributes.brand_images;
          console.log(
            this.elmDetails.attributes.brand_images,
            "*** this.elmDetails.attributes.brand_images ***"
          );
          if (this.brandImages == undefined) this.brandImages = [];
        }
        this.name = this.elmDetails.attributes.name;
        this.description = this.elmDetails.attributes.description;
        this.final_debrief_overview = this.elmDetails.attributes
          .final_debrief_overview
          ? this.elmDetails.attributes.final_debrief_overview
          : "";
        this.assessment_code = this.elmDetails.attributes.assessment_code
          ? this.elmDetails.attributes.assessment_code
          : "";
        this.note =
          this.elmDetails.attributes.notes != "null"
            ? this.elmDetails.attributes.notes
            : "";
        this.searchWorld = this.elmDetails.attributes.world_name;
        this.worldId = this.elmDetails.attributes.world_id;
        this.timeToComplete = this.elmDetails.attributes.time_to_complete;
        this.searchDesigner =
          this.elmDetails.attributes.lead_designer != null
            ? this.elmDetails.attributes.lead_designer.attributes.first_name
            : "";
        this.designerId =
          this.elmDetails.attributes.lead_designer != null
            ? this.elmDetails.attributes.lead_designer.id
            : "";
        this.searchSme =
          this.elmDetails.attributes.sme != null
            ? this.elmDetails.attributes.sme.attributes.first_name
            : "";
        this.smeId =
          this.elmDetails.attributes.sme != null
            ? this.elmDetails.attributes.sme.id
            : "";
        this.updateOrg();
        const orgId = {
          id: this.elmDetails.attributes.learner_organization[
            this.elmDetails.attributes.learner_organization.length - 1
          ].attributes.world_organization_id,
        };
        this.orgDetails = orgId;
        this.orgId =
          this.elmDetails.attributes.learner_organization[
            this.elmDetails.attributes.learner_organization.length - 1
          ].id;
        this.searchOrg =
          this.elmDetails.attributes.learner_organization[
            this.elmDetails.attributes.learner_organization.length - 1
          ].attributes.organization_name;
        this.roleId =
          this.elmDetails.attributes.learner_organization[
            this.elmDetails.attributes.learner_organization.length - 1
          ].attributes.world_role_id;
        this.searchRole =
          this.elmDetails.attributes.learner_organization[
            this.elmDetails.attributes.learner_organization.length - 1
          ].attributes.world_role_name;
        this.learningObjectives =
          this.elmDetails && this.elmDetails.attributes.learning_objectives
            ? this.elmDetails.attributes.learning_objectives
            : "";
        this.chips =
          this.elmDetails && this.elmDetails.attributes.skills
            ? this.elmDetails.attributes.skills
            : [];
        if (this.chips.length > 0) this.skillsPlaceholder = "";
        this.docs = this.elmDetails.attributes.intro_documents;
        if (this.elmDetails.attributes.intro_video != null)
          this.video = this.elmDetails.attributes.intro_video;
      });
    },
  },
  created() {
    api
      .getSkills()
      .then((res) => {
        this.skillLists = res.data.data;
      })
      .catch();
    this.getDoc(this.page);
    this.getVideo(this.page);
    if (this.id) {
      this.getElmDetails();
    }
  },
  computed: {
    getPercentage() {
      return this.uploadPercent;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
#progress-bar-line {
  display: inline-block;
  width: auto;
}
.create-elm-container {
  max-width: 98%;
  margin: 0 auto;
  padding: 40px 0;
  @include media-breakpoint(xl) {
    max-width: 95%;
  }
}
.elm-back-btn {
  font-size: 20px;
  &:hover {
    color: $theme-color;
  }
}
.elm-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 50px;
  border-bottom: 1px solid #d5e6e0;
  h2 {
    font-size: 35px;
  }
  .header-btn {
    min-width: 262px;
  }
  .elm-archived {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
    }
    ul {
      li {
        list-style: disc inside;
        font-size: 12px;
        margin: 0 0 0 20px;
        padding: 0;
        span {
          color: $input-error-color;
          text-decoration: underline;
          font-size: 16px;
        }
      }
    }
  }
  .btn-link {
    color: $theme-error-color;
    font-size: 13px;
    &:hover {
      color: $theme-error-color;
    }
  }
}
.elm-footer {
  border: 0;
  padding: 20px 0 0;
  justify-content: flex-end;
}
.elm-form-container {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  .btn-active {
    img {
      filter: grayscale(100%);
    }
  }
}
.form-section {
  background: $white;
  padding: 15px;
  flex: 1;
  margin-left: 20px;
  margin-bottom: 40px;
  .ql-toolbar.ql-snow {
    padding: 0;
  }
  .ql-container.ql-snow {
    border: 0 !important;
    background-color: #f2f8f6;
  }
  .form-section-header {
    @include flexProperty(space-between);
  }
  @include media-breakpoint(xxl) {
    margin-left: 70px;
    padding: 50px;
  }
  h4 {
    font-size: 22px;
    color: $theme-color;
    @include font-bold;
  }
  .head-title {
    font-size: 20px;
    margin: 0 0 10px;
  }
  .form-group {
    label {
      font-size: 13px;
      color: $theme-color;
      @include font-medium;
    }
    .elm-dropdown {
      .form-control {
        text-align: left;
      }
      .dropdown-menu {
        padding: 0;
        max-height: 340px;
        overflow: auto;
      }
      ul {
        margin: 0;
        padding: 7px 20px;
      }
      li {
        border-bottom: #eaeaea 1px solid;
        padding: 20px 0;
        margin: 0;
        &:last-child {
          border: 0;
        }
      }
    }
    .elm-assign-item {
      @include flexProperty(space-between, flex-end, null, wrap);
      .elm-assign-text {
        width: calc(100% - 28px);
      }
      h3 {
        @include font-regular;
        font-size: 14px;
        padding: 0;
        margin: 0;
        line-height: 1.14;
        color: $theme-color;
      }
      h4 {
        @include font-medium;
        font-size: 17px;
        margin: 2px 0 0;
        color: $theme-color;
      }
      p {
        width: 100%;
        font-size: 13px;
        line-height: 1.15;
        color: $list-color;
        margin: 5px 0 0;
      }
    }
    .elms {
      @include elmBadge;
      font-size: 14px;
      margin: 0 0 0 5px;
      display: inline-block;
    }
    .form-control {
      border-radius: 3px;
      background-color: $theme-light-background;
      border: 0;
      &::placeholder {
        font-size: 14px;
      }
      &.textarea-height {
        height: 151px;
        padding: 15px;
        resize: none;
      }
      &.small-textarea-height {
        height: 110px;
        padding: 14px;
        resize: none;
      }
    }
  }
  .learning-data-list {
    display: flex;
    flex-wrap: wrap;
  }
  .learning-data-title {
    flex: 1;
  }
  .learning-data-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 33.33%;
    margin: 35px 16px 0 16px;
  }

  .brand-image-item {
    height: 200px;
  }
  .brand-image-item img {
    height: 60%;
  }
  .delete-img {
    background: #3dbc9e;
    width: 100%;
    color: white;
  }
  .learning-data-title h4 {
    @include font-medium;
    font-size: 16px;
    color: $theme-color;
    word-break: break-word;
  }
  .cropper-loader {
    display: flex;
    align-items: center;
    min-width: 120px;
    .loader-delete {
      color: $theme-color;
      text-decoration: none;
      opacity: 0.3;
      font-size: 10px;
      margin: 0 0 0 10px;
    }
  }
  .learning-data-title p {
    font-size: 14px;
    font-style: normal;
    color: $theme-color;
    margin: 0 0 10px;
  }
  .learning-data-title a {
    font-size: 12px;
    color: $input-error-color;
    text-decoration: underline;
    display: block;
  }
  .learning-data-icon {
    margin: 0 15px 0 0;
    flex: none;
  }

  .learning-data-video {
    background-color: $theme-light-background;
    width: 208px;
    flex: none;
    height: 118px;
    border: solid 1px #979797;
    margin: 0 15px 0 0;
    position: relative;
    .data-video {
      height: auto;
    }
    img {
      @include imageSize;
    }
    a {
      @include position(absolute, 0, 0, 0, 0);
      margin: auto;
    }
  }
}
.custom-row {
  margin: 36px -15px 0;
  @include media-breakpoint(xxl) {
    margin: 36px -25px 0 -25px;
  }
  .col-sm-6,
  .col-sm-12,
  .col-sm-4,
  .col-sm-8,
  .col-sm-5,
  .col-sm-7,
  .col-sm-3 {
    padding: 0 15px;
    @include media-breakpoint(xxl) {
      padding: 0 25px;
    }
  }
}
.setup-content {
  .custom-row {
    margin-top: 15px;
  }
}
.custom-file-input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  left: -100px;
}
.image-container {
  width: 387px;
  flex: none;
  .image-container-content {
    width: 100%;
    height: 485px;
    border: dashed 2px $primary-button-color;
    background-color: rgba(64, 154, 132, 0.07);
    position: relative;
    cursor: pointer;
    overflow: hidden;
    @include flexProperty(center, center);
    img {
      @include imageSize;
    }
  }
  .image-delete {
    margin: 10px 0 0;
    @include flexProperty(flex-end, center);
    text-align: right;
    color: $input-error-color;
    a {
      margin: 0 0 0 5px;
    }
  }
  .default-text {
    text-align: center;
    position: absolute;
    .icon-plus {
      font-size: 21px;
      &::before {
        color: $primary-button-color;
      }
    }
    p {
      font-size: 17px;
      text-align: center;
      color: $primary-button-color;
      @include font-italic;
    }
  }
  .default-image-upload {
    @include imageSize;
    position: relative;
    z-index: 10;
  }
  .upload-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .icon-camera {
    position: absolute;
    bottom: 20px;
    font-size: 24px;
    right: 20px;
    z-index: 2;
    &::before {
      color: rgba(#fff, 0.7);
    }
  }
}

.chip-container {
  border-radius: 3px;
  background-color: $theme-light-background;
  border: 0;
  height: 110px;
  padding: 15px 10px;
  input {
    border: 0;
    background-color: transparent;
    width: 80px;
    outline: 0;
    padding: 0;
    margin: 0 0 0 5px;
    height: 24px;
  }

  &.entity-chip {
    background-color: transparent;
    border: 0;
    height: auto;
    padding: 15px 0;
  }
  .chip {
    display: inline-block;
    border-radius: 12px;
    border: solid 1px #d5e6e0;
    background-color: $white;
    padding: 0 10px;
    height: 24px;
    margin: 0 0 5px 5px;
    span {
      display: inline-block;
      font-size: 11px;
      line-height: 24px;
      white-space: nowrap;
    }
    em {
      font-size: 9px;
      display: inline-block;
      cursor: pointer;
      opacity: 0.25;
      margin: 0 0 0 8px;
    }
  }
  .chip-results,
  .dropdown-list {
    border-radius: 2px;
    box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
    padding: 5px;
    .chip-button {
      font-size: 15px;
      line-height: 3.07;
      color: $theme-color;
      padding: 0 15px;
      text-transform: capitalize;
      button {
        display: none;
      }
    }
    .dropdown-items {
      padding: 20px 15px;
      align-items: center;
      margin: 0;
    }
  }
}

// grading
.switch-grading {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch-grading input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-grading {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: table-cell;
}

.slider-grading:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 8px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-grading {
  background-color: #3dbc9e;
}

input:focus + .slider-grading {
  box-shadow: 0 0 1px #3dbc9e;
}

input:checked + .slider-grading:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider-grading.round {
  border-radius: 34px;
}

.slider-grading.round:before {
  border-radius: 50%;
}
.checkbox-grading {
  font-size: 16px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}
.column-1 {
  width: 10%;
  padding: 1em;
  float: left;
}

.column-2 {
  width: 80%;
  padding: 1em;
  float: right;
}
.column-3 {
  width: 10%;
  padding: 1em;
  float: left;
}

.ai-row {
  display: flex;
  flex-wrap: wrap;
}

// .ai-row:after {
//     content: "";
//     display: table;
//     clear: both;
//   }

.ai-rows-header {
  margin-bottom: 2em;
  font-size: 12px;
  width: 100%;
  // display:block;
}

.ai-rows-header input {
  width: 20px;
}

.float-right {
  float: right;
}

.ai-text-row {
  // border-bottom: 1px solid #ccc;
  margin-bottom: 2em;
  width: 100%;
}

.ai-text-rows-container {
  padding-top: 2em;
}

.ai-text-rows-code {
  margin-bottom: 3em;
}
.custom-input-style-grade {
  border: 1px solid #ccc;
  background-color: #f2f8f6;
  border-radius: 3px;
  display: inline;
  width: 150px !important;
  height: 30px;
  padding-left: 1em;
}
.custom-input-style-grade-number {
  border: 1px solid #ccc;
  background-color: #f2f8f6;
  border-radius: 3px;
  display: inline;
  width: 45px !important;
  height: 30px;
  padding-left: 1em;
  padding-right: 1em;
}

.integrity-video {
  padding-top: 3em;
  padding-left: 0;
  padding-right: 1em;
}
.image-caption {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
</style>
