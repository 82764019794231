var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "World" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchWorld,
                      expression: "searchWorld",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:700ms",
                      value: _vm.search,
                      expression: "search",
                      arg: "700ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "aria-describedby": "emailHelp",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchWorld },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchWorld = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("newWorld")
                  },
                },
              },
              [_vm._v("Add a new World")]
            ),
            !this.$store.state.importWorldstatus
              ? _c(
                  "div",
                  { staticClass: "ml-2 btn header-btn primary btn-type" },
                  [
                    _c("input", {
                      attrs: { type: "file" },
                      on: { change: _vm.importWorld },
                    }),
                    _vm._v(" Import World "),
                  ]
                )
              : _vm._e(),
            _vm.showNotification()
              ? _c("div", { staticClass: "data-popper-dropdown" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu dropdown-menu-right" },
                    [
                      _c("div", [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              " World Import progress: " +
                                _vm._s(
                                  this.importedFile && this.importedFile.name
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "progress-bar-container" },
                            [
                              _c("progress-bar", {
                                attrs: {
                                  options: _vm.options,
                                  value: this.$store.state.worldImportProgress,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.totalWorlds > 1 && !_vm.searchWorld
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalWorlds) +
                    " worlds in the system."
                ),
              ])
            : _vm.totalWorlds <= 1 && !_vm.searchWorld
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalWorlds) +
                    " world in the system."
                ),
              ])
            : _vm.totalWorlds > 1 && _vm.searchWorld
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalWorlds) +
                    " worlds in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalWorlds) +
                    " world in the search result."
                ),
              ]),
        ]),
        _vm.worldsList.length !== 0
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", { staticClass: "data-table data-column-7" }, [
                _c("div", { staticClass: "data-head" }, [
                  _c(
                    "div",
                    {
                      staticClass: "data-col",
                      on: {
                        click: function ($event) {
                          return _vm.toggleSort("name", _vm.sorting)
                        },
                      },
                    },
                    [
                      _vm._v(" Name "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "name" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "name" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "data-col",
                      on: {
                        click: function ($event) {
                          return _vm.toggleSort("created_at", _vm.sorting)
                        },
                      },
                    },
                    [
                      _vm._v(" Created on "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "created_at" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "created_at" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "data-col",
                      on: {
                        click: function ($event) {
                          return _vm.toggleSort("is_private", _vm.sorting)
                        },
                      },
                    },
                    [
                      _vm._v(" type "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "is_private" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "is_private" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v("Unique code"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "data-col",
                      on: {
                        click: function ($event) {
                          return _vm.toggleSort("learn_mods_count", _vm.sorting)
                        },
                      },
                    },
                    [
                      _vm._v(" ELMs "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "learn_mods_count" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "learn_mods_count" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Customer")]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
                ]),
                _c(
                  "div",
                  { staticClass: "data-items" },
                  _vm._l(_vm.worldsList, function (world) {
                    return _c(
                      "div",
                      { key: world.id, staticClass: "data-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "data-col" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: `/world-detail/${world.id}` } },
                              [_vm._v(_vm._s(world.attributes.name))]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "data-col" }, [
                          _vm._v(_vm._s(world.attributes.created_at)),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          !world.attributes.is_private
                            ? _c(
                                "div",
                                { staticClass: "data-popper-dropdown" },
                                [_vm._v("Public")]
                              )
                            : _vm._e(),
                          world.attributes.is_private
                            ? _c(
                                "div",
                                { staticClass: "data-popper-dropdown" },
                                [_vm._v("Private")]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          _vm._v(_vm._s(world.attributes.world_code)),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          _c("div", { staticClass: "data-popper-dropdown" }, [
                            _c("span", { staticClass: "elms" }, [
                              _vm._v(_vm._s(world.attributes.learn_mods_count)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          _c("div", { staticClass: "customer-dropdown" }, [
                            world.attributes.customer
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn dropdown-toggle",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        world.attributes.customer.attributes
                                          .name
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !world.attributes.customer
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn dropdown-toggle",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "dropdown",
                                    },
                                  },
                                  [_vm._v("-")]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "data-col data-action" }, [
                          _c("div", { staticClass: "data-dropdown" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn dropdown-toggle",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c("em", {
                                  staticClass: "icon-edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editWorld(world.id)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "data-delete-dropdown" }, [
                            _vm._m(2, true),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "data-popper-title" },
                                  [
                                    _vm._v(
                                      "Are you sure you want to delete this world?"
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "data-popper-footer" },
                                  [
                                    _c("a", { staticClass: "btn default" }, [
                                      _vm._v("cancel"),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteWorld(world.id)
                                          },
                                        },
                                      },
                                      [_vm._v("Ok")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm.totalPages.length > 1
                ? _c("div", { staticClass: "data-footer" }, [
                    _c("div", { staticClass: "data-results" }, [
                      _vm._v(
                        "Showing " +
                          _vm._s(_vm.page) +
                          " of " +
                          _vm._s(_vm.totalPages.length)
                      ),
                    ]),
                    _c(
                      "div",
                      { attrs: { "aria-label": "Page navigation example" } },
                      [
                        _c(
                          "ul",
                          { staticClass: "pagination" },
                          [
                            _vm.page !== 1
                              ? _c("li", { staticClass: "page-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "page-link",
                                      attrs: { "aria-label": "Previous" },
                                      on: { click: _vm.prevPage },
                                    },
                                    [_c("em", { staticClass: "icon-prev" })]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._l(
                              _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                              function (pages) {
                                return _c(
                                  "li",
                                  { key: pages, staticClass: "page-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "page-link",
                                        class: [
                                          pages === _vm.page ? "active" : "",
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.getWorlds(pages)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(pages))]
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm.page < _vm.totalPages.length
                              ? _c("li", { staticClass: "page-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "page-link",
                                      attrs: { "aria-label": "Next" },
                                      on: { click: _vm.nextPage },
                                    },
                                    [_c("em", { staticClass: "icon-next" })]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.worldsList.length === 0 && !_vm.searchStatus && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no Worlds in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn primary",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("newWorld")
                      },
                    },
                  },
                  [_vm._v("Add a new World")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.worldsList.length === 0 && _vm.searchStatus
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "modal",
        { attrs: { name: "newWorld", height: "auto", scrollable: true } },
        [
          _c("div", { staticClass: "modal-content" }, [
            !_vm.addOrganisation
              ? _c("div", { attrs: { id: "AddNewWorld" } }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c(
                      "div",
                      {
                        staticClass: "modal-title",
                        attrs: { id: "exampleModalLabel" },
                      },
                      [
                        !_vm.editWorldStatus
                          ? _c("h5", [_vm._v("Create a new world")])
                          : _vm._e(),
                        _vm.editWorldStatus
                          ? _c("h5", [_vm._v("Edit world")])
                          : _vm._e(),
                        !_vm.editWorldStatus
                          ? _c("p", [
                              _vm._v("Create a new world and add customers."),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.hide("newWorld")
                          },
                        },
                      },
                      [_c("em", { staticClass: "icon-close" })]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _c("ValidationObserver", { ref: "form" }, [
                        _c("form", { staticClass: "modal-form" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("Name"),
                              ]),
                              _c("ValidationProvider", {
                                attrs: { name: "Name", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "text",
                                              id: "name",
                                              "aria-describedby": "emailHelp",
                                              maxlength: "30",
                                            },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4051322429
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "form-label" }, [
                              _vm._v("Description"),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.description,
                                  expression: "description",
                                },
                              ],
                              staticClass: "form-control form-textarea-control",
                              attrs: { maxlength: "500" },
                              domProps: { value: _vm.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.description = $event.target.value
                                },
                              },
                            }),
                            _c("div", { staticClass: "form-characters" }, [
                              _vm._v("500 characters"),
                            ]),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            !_vm.editWorldStatus
                              ? _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Type"),
                                ])
                              : _vm._e(),
                            _vm.editWorldStatus
                              ? _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Type"),
                                ])
                              : _vm._e(),
                            _vm.editWorldStatus
                              ? _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "text-transform": "capitalize",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.type))]
                                )
                              : _vm._e(),
                            !_vm.editWorldStatus
                              ? _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox",
                                      class: [
                                        _vm.editWorldStatus ? "disabled" : "",
                                      ],
                                      attrs: { name: "public" },
                                    },
                                    [
                                      _vm._v(" Public "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.type,
                                            expression: "type",
                                          },
                                        ],
                                        attrs: {
                                          disabled: _vm.editWorldStatus == true,
                                          type: "radio",
                                          name: "public",
                                        },
                                        domProps: {
                                          value: "public",
                                          checked: _vm._q(_vm.type, "public"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.type = "public"
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        attrs: { for: "public" },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox",
                                      class: [
                                        _vm.editWorldStatus ? "disabled" : "",
                                      ],
                                      attrs: { name: "private" },
                                    },
                                    [
                                      _vm._v(" Private "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.type,
                                            expression: "type",
                                          },
                                        ],
                                        attrs: {
                                          disabled: _vm.editWorldStatus == true,
                                          type: "radio",
                                          name: "private",
                                        },
                                        domProps: {
                                          value: "private",
                                          checked: _vm._q(_vm.type, "private"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.type = "private"
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        attrs: { for: "private" },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.type === "private"
                            ? _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Customer"),
                                ]),
                                !_vm.editWorldStatus
                                  ? _c(
                                      "div",
                                      { staticClass: "customer-dropdown mb-3" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.customer,
                                              expression: "customer",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.noCustomerAdded
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            disabled:
                                              _vm.editWorldStatus == true,
                                            type: "text",
                                            placeholder: "Type here",
                                          },
                                          domProps: { value: _vm.customer },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.customer =
                                                  $event.target.value
                                              },
                                              _vm.searchCustomer,
                                            ],
                                          },
                                        }),
                                        _vm.noCustomerAdded
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "invalid",
                                                staticStyle: {
                                                  display: "block",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Add customer is required"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.customers.length !== 0
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "dropdown-menu",
                                                  class: [
                                                    _vm.customers.length !== 0
                                                      ? "show"
                                                      : "",
                                                  ],
                                                },
                                                [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "dropdown-list",
                                                    },
                                                    _vm._l(
                                                      _vm.customers,
                                                      function (customer) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key: customer.id,
                                                            staticClass:
                                                              "dropdown-items",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown-content",
                                                              },
                                                              [
                                                                _c("h4", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      customer.name
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      customer.email
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            !customer.status
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-action",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "add-action",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.addCustomer(
                                                                                customer
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "+"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            customer.status
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "dropdown-action",
                                                                  },
                                                                  [
                                                                    _c("em", {
                                                                      staticClass:
                                                                        "icon-check",
                                                                    }),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Added"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.addedCustomer.id
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "customer-dropdown-results",
                                      },
                                      [
                                        _c(
                                          "ul",
                                          { staticClass: "dropdown-list mt-0" },
                                          [
                                            _c(
                                              "li",
                                              { staticClass: "dropdown-items" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-content",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.addedCustomer.name
                                                        )
                                                      ),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.addedCustomer
                                                            .email
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-action",
                                                  },
                                                  [
                                                    !_vm.editWorldStatus
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "delete-action",
                                                            class: [
                                                              _vm.editWorldStatus
                                                                ? "disabled"
                                                                : "",
                                                            ],
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeCustomer()
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Remove")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.hide("newWorld")
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            !_vm.editWorldStatus
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#addOrganisation",
                                      "data-dismiss": "modal",
                                    },
                                    on: { click: _vm.createWorld },
                                  },
                                  [
                                    _vm._v(" Save & add organization "),
                                    _c("em", {
                                      staticClass: "icon-carrot-right",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.editWorldStatus
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#addOrganisation",
                                      "data-dismiss": "modal",
                                    },
                                    on: { click: _vm.createWorld },
                                  },
                                  [
                                    _vm._v(" Save & edit organization "),
                                    _c("em", {
                                      staticClass: "icon-carrot-right",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.addOrganisation
              ? _c("div", { attrs: { id: "AddOrganisation" } }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c(
                      "div",
                      {
                        staticClass: "modal-title",
                        attrs: { id: "exampleModalLabel" },
                      },
                      [
                        !_vm.editWorldStatus
                          ? _c("h5", [_vm._v("Add Organization to the World")])
                          : _vm._e(),
                        _vm.editWorldStatus
                          ? _c("h5", [_vm._v("Edit Organization to the World")])
                          : _vm._e(),
                        _vm.worldDetails.id
                          ? _c("p", [
                              _vm._v(
                                _vm._s(_vm.worldDetails.attributes.name) +
                                  ", " +
                                  _vm._s(_vm.worldDetails.attributes.world_code)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.hide("newWorld")
                          },
                        },
                      },
                      [_c("em", { staticClass: "icon-close" })]
                    ),
                  ]),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("form", { staticClass: "modal-form" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "organisation-dropdown" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchOrg,
                                expression: "searchOrg",
                              },
                            ],
                            staticClass: "form-control btn dropdown-toggle",
                            class: [_vm.noOrgAdded ? "invalid" : ""],
                            attrs: { type: "text", placeholder: "Type here" },
                            domProps: { value: _vm.searchOrg },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.searchOrg = $event.target.value
                                },
                                _vm.searchOrganisation,
                              ],
                            },
                          }),
                          _vm.noOrgAdded
                            ? _c(
                                "span",
                                {
                                  staticClass: "invalid",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _vm._v(
                                    "Atleast one organization is required."
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.noCharacterAdded
                            ? _c(
                                "span",
                                {
                                  staticClass: "invalid",
                                  staticStyle: { display: "block" },
                                },
                                [
                                  _vm._v(
                                    "Minimum one character selection is required per organization."
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.worldOrg.length && _vm.showOrgList
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    class: [
                                      _vm.worldOrg.length !== 0 ? "show" : "",
                                    ],
                                  },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "dropdown-list" },
                                      _vm._l(_vm.worldOrg, function (org) {
                                        return _c(
                                          "li",
                                          {
                                            key: org.id,
                                            staticClass: "dropdown-items",
                                          },
                                          [
                                            org.attributes.photo_url
                                              .thumbnail != false
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-pics",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: org.attributes
                                                          .photo_url.thumbnail,
                                                        alt: "images",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            org.attributes.photo_url
                                              .thumbnail == false
                                              ? _c("div", {
                                                  staticClass: "dropdown-pics",
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(org.attributes.name)
                                                  ),
                                                ]),
                                                _c("h5", [
                                                  _vm._v(
                                                    _vm._s(
                                                      org.attributes
                                                        .industry_name
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            !org.status
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-action",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "add-action",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addOrg(
                                                              org
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("+")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            org.status
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-action",
                                                  },
                                                  [
                                                    _c("em", {
                                                      staticClass: "icon-check",
                                                    }),
                                                    _c("span", [
                                                      _vm._v("Added"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.addedOrgList.length
                          ? _c(
                              "div",
                              { staticClass: "organisation-dropdown-results" },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "dropdown-list" },
                                  _vm._l(
                                    _vm.addedOrgList,
                                    function (addOrg, i) {
                                      return _c(
                                        "li",
                                        {
                                          key: addOrg.id,
                                          staticClass: "dropdown-items",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dropdown-content d-flex",
                                            },
                                            [
                                              _c("div", [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(
                                                      addOrg.attributes.name
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-info",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        addOrg.attributes
                                                          .industry_name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "custom-checkbox organisation-select",
                                              attrs: { name: "public3" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: addOrg.status,
                                                    expression: "addOrg.status",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "public3",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    addOrg.status
                                                  )
                                                    ? _vm._i(
                                                        addOrg.status,
                                                        null
                                                      ) > -1
                                                    : addOrg.status,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a = addOrg.status,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              addOrg,
                                                              "status",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              addOrg,
                                                              "status",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          addOrg,
                                                          "status",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.multipleSelectOrg(
                                                        i,
                                                        addOrg.status
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                              _c("span", {
                                                staticClass: "checkmark circle",
                                                attrs: { for: "public3" },
                                              }),
                                              _c("div", [_vm._v("Select All")]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "dropdown-action" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "delete-action",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeOrg(
                                                        i,
                                                        addOrg.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Remove")]
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "w-100" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-user-list",
                                              },
                                              _vm._l(
                                                addOrg.attributes
                                                  .organization_characters,
                                                function (character, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: character.id,
                                                      staticClass:
                                                        "dropdown-user-item",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-pic",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "custom-checkbox",
                                                              attrs: {
                                                                name: "public3",
                                                              },
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      character
                                                                        .attributes
                                                                        .characters
                                                                        .attributes
                                                                        .status,
                                                                    expression:
                                                                      "character.attributes.characters.attributes.status",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "checkbox",
                                                                  name: "public3",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      character
                                                                        .attributes
                                                                        .characters
                                                                        .attributes
                                                                        .status
                                                                    )
                                                                      ? _vm._i(
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes
                                                                            .status,
                                                                          null
                                                                        ) > -1
                                                                      : character
                                                                          .attributes
                                                                          .characters
                                                                          .attributes
                                                                          .status,
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes
                                                                            .status,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              character
                                                                                .attributes
                                                                                .characters
                                                                                .attributes,
                                                                              "status",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              character
                                                                                .attributes
                                                                                .characters
                                                                                .attributes,
                                                                              "status",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes,
                                                                          "status",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.characterSelect(
                                                                        i,
                                                                        character
                                                                          .attributes
                                                                          .characters
                                                                          .attributes
                                                                          .status
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "checkmark circle",
                                                                attrs: {
                                                                  for: "public3",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          character.attributes
                                                            .characters
                                                            .attributes
                                                            .photo_url.thumbnail
                                                            ? _c("img", {
                                                                attrs: {
                                                                  alt: "users",
                                                                  src: character
                                                                    .attributes
                                                                    .characters
                                                                    .attributes
                                                                    .photo_url
                                                                    .thumbnail,
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-month",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                character
                                                                  .attributes
                                                                  .characters
                                                                  .attributes
                                                                  .full_name
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "custom-tooltip",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  character
                                                                    .attributes
                                                                    .characters
                                                                    .attributes
                                                                    .full_name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-role dropdown-toggle p-0",
                                                          attrs: {
                                                            "data-toggle":
                                                              "dropdown",
                                                            "aria-haspopup":
                                                              "true",
                                                            "aria-expanded":
                                                              "false",
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                character
                                                                  .attributes
                                                                  .world_role
                                                                  .attributes
                                                                  .name
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "custom-tooltip",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  character
                                                                    .attributes
                                                                    .world_role
                                                                    .attributes
                                                                    .name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "user-gender",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              character
                                                                .attributes
                                                                .characters
                                                                .attributes.age
                                                            ) +
                                                              " , " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "uppercase"
                                                                )(
                                                                  character
                                                                    .attributes
                                                                    .characters
                                                                    .attributes
                                                                    .gender,
                                                                  true
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-menu data-popper-organise",
                                                          attrs: {
                                                            "aria-labelledby":
                                                              "menu1",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "data-popover",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "data-popover-title",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-items-center",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "user-pic",
                                                                        },
                                                                        [
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes
                                                                            .photo_url
                                                                            .thumbnail !=
                                                                          false
                                                                            ? _c(
                                                                                "img",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      alt: "users",
                                                                                      src: character
                                                                                        .attributes
                                                                                        .characters
                                                                                        .attributes
                                                                                        .photo_url
                                                                                        .thumbnail,
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes
                                                                                    .full_name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "user-poper-gender",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes
                                                                                    .age
                                                                                ) +
                                                                                  " , " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "uppercase"
                                                                                    )(
                                                                                      character
                                                                                        .attributes
                                                                                        .characters
                                                                                        .attributes
                                                                                        .gender,
                                                                                      true
                                                                                    )
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "data-popover-close",
                                                                    },
                                                                    [
                                                                      _c("em", {
                                                                        staticClass:
                                                                          "icon-close",
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "form",
                                                                {
                                                                  staticClass:
                                                                    "data-popover-form",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.searchRole,
                                                                                expression:
                                                                                  "searchRole",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              placeholder:
                                                                                "Enter Role",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.searchRole,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              [
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  )
                                                                                    return
                                                                                  _vm.searchRole =
                                                                                    $event.target.value
                                                                                },
                                                                                _vm.getRole,
                                                                              ],
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm.roles
                                                                        .length
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "dropdown-list mt-3",
                                                                            },
                                                                            _vm._l(
                                                                              _vm.roles,
                                                                              function (
                                                                                role
                                                                              ) {
                                                                                return _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: role.id,
                                                                                    staticClass:
                                                                                      "dropdown-items",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "dropdown-content",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "h4",
                                                                                          {
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.setRoles(
                                                                                                    i,
                                                                                                    index,
                                                                                                    role.id,
                                                                                                    role
                                                                                                      .attributes
                                                                                                      .name
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                role
                                                                                                  .attributes
                                                                                                  .name
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "dropdown-action",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "dropdown-action",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "add-action",
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.setRoles(
                                                                                                        i,
                                                                                                        index,
                                                                                                        role.id,
                                                                                                        role
                                                                                                          .attributes
                                                                                                          .name
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "+"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn default",
                            attrs: { type: "button" },
                            on: { click: _vm.cancel },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn primary",
                            attrs: { type: "button" },
                            on: { click: _vm.worldCreate },
                          },
                          [_vm._v("Save")]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "worldImportConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("This world already exists! Do you want to overwrite it?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("worldImportConfirmationModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.importWorld(),
                        _vm.$modal.hide("worldImportConfirmationModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("All Worlds")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [
        _c("span", { staticClass: "clone-notification" }, [
          _c("em", { staticClass: "icon-bell" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-delete" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }