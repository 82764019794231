<template>
  <div id="QaEmailList">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>QA Email LOs</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group col">
              <div class="form-gender-dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{filterApplied}}
                  <em class="icon-carrot-down"></em>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    href="#"
                    v-for="filter in filter_list"
                    :key="filter"
                    @click="applyFilter(filter, false)"
                  >{{filter}}</a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <p v-if="totalQaEmails > 1 && !searchQaEmail">You have {{totalQaEmails}} QA emails</p>
        <p v-else-if="totalQaEmails <= 1 && !searchQaEmail">You have {{totalQaEmails}} QA email.</p>
        <p
          v-else-if="totalQaEmails > 1 && searchQaEmail"
        >You have {{totalQaEmails}} QA emails in the search result.</p>
        <p v-else>You have {{totalQaEmails}} QA emails in the search result.</p>
      </header>
      <div class="data-content" v-if="qaEmails.length">
        <div class="data-table data-column-8">
          <div class="data-head">
            <div class="data-col">
              Learner
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'qaEmail.attributes.learner_name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('qaEmail.attributes.learner_name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'qaEmail.attributes.learner_name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('qaEmail.attributes.learner_name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Learning Object
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'qaEmail.attributes.learning_object_name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('qaEmail.attributes.learning_object_name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'qaEmail.attributes.learning_object_name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('qaEmail.attributes.learning_object_name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Date Sent</div>
            <div class="data-col">Resolved</div>
            <div class="data-col">Date Resolved</div>
            <div class="data-col">Actions</div>
          </div>
          <div class="data-items">
            <div class="data-row" v-for="qaEmail in qaEmails" :key="qaEmail.id">
              <div class="data-col">{{qaEmail.attributes.learner_name}}</div>
              <div class="data-col">{{qaEmail.attributes.learning_object_name}}</div>
              <div class="data-col">{{qaEmail.attributes.date_sent}}</div>
              <div class="data-col iteration-resolved">
                <div class="success-msg icon" v-if="qaEmail.attributes.qa_resolved === true">
                  <em class="icon-check"></em>
                </div>
                <div class="error-msg icon" v-if="qaEmail.attributes.qa_resolved === false">
                  <em class="icon-close"></em>
                </div>
              </div>
              <div class="data-col">{{qaEmail.attributes.qa_resolved_date}}</div>
              <div class="data-col data-action">
                <div class="data-dropdown">
                  <router-link :to="`/qa-email/${qaEmail.id}`">
                    <button
                      type="button"
                      class="btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <em class="icon-edit"></em>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="applyFilter(filterApplied, true, pages);"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="qaEmails.length === 0 && !searchStatus && display">
        <h3>There’s nothing here!</h3>
        <p>You have no QA emails with the selected filter.</p>
        <div></div>
      </div>
      <div class="data-no-result" v-if="qaEmails.length === 0 && searchStatus">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "QaEmailList",
  components: {},
  data() {
    return {
      page: 1,
      qaEmails: [],
      totalQaEmails: "",
      searchQaEmail: "",
      sortOrder: "desc",
      sortColumn: "id",
      totalPages: [],
      searchStatus: false,
      display: false,
      filter_list: ["QA Required", "Resolved", "Show all"],
      filterApplied: "QA Required"
    };
  },
  methods: {
    toggleSort(colName, sortType) {
      let _colName = colName;
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.applyFilter(this.filterApplied, true);
      this.sortColumn = _colName;
    },
    nextPage() {
      this.page = this.page + 1;
      this.applyFilter(this.filterApplied, true);
    },
    prevPage() {
      this.page = this.page - 1;
      this.applyFilter(this.filterApplied, true);
    },
    // search(value) {
    //   this.searchQaEmail = value;
    //   this.searchStatus = true;
    //   let filter = this.filterApplied;
    //   this.filterApplied = "";
    //   this.applyFilter(filter);
    // },
    applyFilter(filter, skipCheck, pages = this.page) {
      if (skipCheck || filter != this.filterApplied) {
        switch (filter) {
          case "Resolved":
            this.getResolvedQAEmails(pages);
            break;
          case "QA Required":
            this.getQAEmailsToResolve(pages);
            break;
          case "Show all":
            this.getAllQAEmails(pages);
            break;
        }
      }
    },
    goToQaEmail(id) {
      this.$router.push({ path: "/qa-email", query: { id: id } });
    },
    getQAEmailsToResolve(page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getQAEmailsToResolve(
          page,
          this.searchQaEmail,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.filterApplied = "QA Required";
          this.display = true;
          utilFunctionService.hideLoader();
          this.searchStatus = false;
          this.qaEmails = res.data.data;
          this.qaEmails.forEach(ele => {
            if (ele.attributes.notes == "null" || ele.attributes.notes == null)
              ele.attributes.notes = "";
          });
          this.totalQaEmails = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {});
    },
    getResolvedQAEmails(page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getResolvedQAEmails(
          page,
          this.searchQaEmail,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.filterApplied = "Resolved";
          this.display = true;
          utilFunctionService.hideLoader();
          this.searchStatus = false;
          this.qaEmails = res.data.data;
          this.qaEmails.forEach(ele => {
            if (ele.attributes.notes == "null" || ele.attributes.notes == null)
              ele.attributes.notes = "";
          });
          this.totalQaEmails = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {});
    },
    getAllQAEmails(page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getAllQAEmails(
          page,
          this.searchQaEmail,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.filterApplied = "Show all";
          this.display = true;
          utilFunctionService.hideLoader();
          this.searchStatus = false;
          this.qaEmails = res.data.data;
          this.qaEmails.forEach(ele => {
            if (ele.attributes.notes == "null" || ele.attributes.notes == null)
              ele.attributes.notes = "";
          });
          this.totalQaEmails = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {});
    }
  },
  created() {
    this.getQAEmailsToResolve(this.page);
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.iteration-resolved {
  margin: 40px 0 0;
  font-size: 13px;
  color: $black;
  em {
    @include flexProperty(center, center);
    width: 34px;
    height: 34px;
    color: $white;
    margin: 0 10px 0 0;
    flex: none;
    border-radius: 100%;
  }
  .icon {
    margin: 0 0 35px;
  }
  .error-msg {
    em {
      font-size: 16px;
      background-color: #e02020;
    }
  }
  .success-msg {
    em {
      font-size: 20px;
      background-color: #5c9943;
    }
  }
}
</style>
