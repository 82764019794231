var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "learning-form-header flex-wrap" }, [
        _c("h4", [_vm._v("Interaction (External API)")]),
        _c("div", { staticClass: "learning-quiz-btn" }, [
          _vm.status == "published"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn secondary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.restore("published")
                    },
                  },
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _vm.status == "drafted"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn primary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.restore("published")
                    },
                  },
                },
                [_vm._v("Publish LO")]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "btn header-btn ml-2 secondary",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.$modal.show("cloneConfirmationModal")
                },
              },
            },
            [_vm._v("Clone LO")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "learning-quiz-tabs" }, [
        _c("div", { staticClass: "setup-container" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[0] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("basic-setup", 0)
                  },
                },
              },
              [_vm._m(0)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[1] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("assmnt-block-setup", 1)
                  },
                },
              },
              [_vm._m(1)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[2] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("email-testing", 2)
                  },
                },
              },
              [_vm._m(2)]
            ),
            _vm.overallInteraction
              ? _c(
                  "li",
                  {
                    class: { active: _vm.isActiveTab[3] },
                    on: {
                      click: function ($event) {
                        return _vm.currentStep("overall-assessment", 3)
                      },
                    },
                  },
                  [_vm._m(3)]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "learning-quiz-content pt-4" },
        [
          _vm.step == "basic-setup"
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _vm._m(4),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Interaction title")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Interaction Title",
                                  rules: "required|max:100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: { type: "text" },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  268414483
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Administrative notes")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Administrative Notes",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.adminNotes,
                                                expression: "adminNotes",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "Description",
                                              id: "notes",
                                              rows: "4",
                                            },
                                            domProps: { value: _vm.adminNotes },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.adminNotes =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  599525079
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group mb-2" }, [
                            _c(
                              "div",
                              { staticClass: "form-checkboxes d-inline-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-checkbox right",
                                    attrs: { name: "userOverall" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.overallInteraction,
                                          expression: "overallInteraction",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "userOverall",
                                      },
                                      domProps: {
                                        value: "userOverall",
                                        checked: Array.isArray(
                                          _vm.overallInteraction
                                        )
                                          ? _vm._i(
                                              _vm.overallInteraction,
                                              "userOverall"
                                            ) > -1
                                          : _vm.overallInteraction,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.overallInteraction,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "userOverall",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.overallInteraction =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.overallInteraction = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.overallInteraction = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "checkmark",
                                      attrs: { for: "userOverall" },
                                    }),
                                    _vm._v(
                                      " Use overall interaction assessment "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "form-group pt-1 mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-checkboxes d-inline-flex" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-checkbox right",
                                    attrs: { name: "hideFeedbackandAdaptive" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.hideFeedbackandAdaptive,
                                          expression: "hideFeedbackandAdaptive",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "hideFeedbackandAdaptive",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.hideFeedbackandAdaptive
                                        )
                                          ? _vm._i(
                                              _vm.hideFeedbackandAdaptive,
                                              null
                                            ) > -1
                                          : _vm.hideFeedbackandAdaptive,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.hideFeedbackandAdaptive,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.hideFeedbackandAdaptive =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.hideFeedbackandAdaptive =
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.hideFeedbackandAdaptive = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "checkmark",
                                      attrs: { for: "hideFeedbackandAdaptive" },
                                    }),
                                    _vm._v(
                                      " Hide learner feedback and adaptive content "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Select assessment scheme")]),
                              _c(
                                "ValidationProvider",
                                { attrs: { name: "Assessment Scheme" } },
                                [
                                  _c("div", { staticClass: "quiz-dropdown" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary dropdown-toggle",
                                        class: [
                                          _vm.isActive &&
                                          !_vm.assessmentListKey.id
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "button",
                                          id: "entity",
                                          "data-toggle": "dropdown",
                                          "aria-haspopup": "true",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.assessmentListKey.name) +
                                            " "
                                        ),
                                        _c("em", {
                                          staticClass: "icon-carrot-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: { "aria-labelledby": "entity" },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { value: "0" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectedAssessment(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("None")]
                                        ),
                                        _vm._l(
                                          _vm.assessmentLists,
                                          function (assessment) {
                                            return _c(
                                              "option",
                                              {
                                                key: assessment.id,
                                                staticClass: "dropdown-item",
                                                domProps: {
                                                  value: `${assessment.id}*${assessment.attributes.name}`,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectedAssessment(
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    assessment.attributes.name
                                                  )
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  !_vm.assessmentListKey.id
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "Assessment scheme field is required"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Learner Description")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Description",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("vue-editor", {
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "form-control invalid"
                                                : "form-control",
                                            ],
                                            attrs: {
                                              id: "Description",
                                              editorToolbar: _vm.fullTool,
                                              placeholder: "Add a Description",
                                            },
                                            model: {
                                              value: _vm.description,
                                              callback: function ($$v) {
                                                _vm.description = $$v
                                              },
                                              expression: "description",
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1400623348
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-12 pt-5" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-end mb-4" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveExternalAPILO("drafted", 1)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$parent.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "modal",
            {
              attrs: {
                name: "cloneConfirmationModal",
                height: "auto",
                width: 350,
                scrollable: true,
              },
            },
            [
              _c("div", { staticClass: "modal-content modal-delete-content" }, [
                _c("div", { staticClass: "modal-body modal-delete-body" }, [
                  _vm._v(
                    "Are you sure you want to clone this learning object?"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "modal-footer justify-content-center" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn medium default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$modal.hide("cloneConfirmationModal")
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn medium primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.cloneLo(),
                              _vm.$modal.hide("cloneConfirmationModal")
                          },
                        },
                      },
                      [_vm._v("Ok")]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "modal",
            {
              attrs: {
                name: "newAssessFormulaModal",
                height: "auto",
                width: 700,
                scrollable: true,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-content modal-default modal-api-formula",
                },
                [
                  _c("div", { staticClass: "modal-header" }, [
                    _c(
                      "div",
                      {
                        staticClass: "modal-title",
                        attrs: { id: "exampleModalLabel" },
                      },
                      [_c("h5", [_vm._v("API Formula")])]
                    ),
                  ]),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("form", { staticClass: "modal-form modal-min-height" }, [
                      _c("div", { staticClass: "modal-formula-content" }, [
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("AWS SDK Service:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaApiName,
                                expression: "assmntFormulaApiName",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaApiName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaApiName = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("AWS Service Method:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaApiEndpoint,
                                expression: "assmntFormulaApiEndpoint",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaApiEndpoint },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaApiEndpoint =
                                  $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("AWS Region:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaApiRegion,
                                expression: "assmntFormulaApiRegion",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaApiRegion },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaApiRegion = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("Query Params:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaQueryParams,
                                expression: "assmntFormulaQueryParams",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaQueryParams },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaQueryParams =
                                  $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("Results section to inspect:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaResultSection,
                                expression: "assmntFormulaResultSection",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaResultSection },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaResultSection =
                                  $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("Results Include:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaIncludes,
                                expression: "assmntFormulaIncludes",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaIncludes },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaIncludes = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("Result less than:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaLessThan,
                                expression: "assmntFormulaLessThan",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaLessThan },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaLessThan = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c("label", [_vm._v("Result greater than:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assmntFormulaGreaterThan,
                                expression: "assmntFormulaGreaterThan",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.assmntFormulaGreaterThan },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.assmntFormulaGreaterThan =
                                  $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "modal-api-formula-item" }, [
                          _c(
                            "label",
                            {
                              staticClass: "custom-checkbox",
                              attrs: { name: "assmntFormulaAbsent" },
                            },
                            [
                              _vm._v(" Absent condition "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.assmntFormulaAbsent,
                                    expression: "assmntFormulaAbsent",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  name: "assmntFormulaAbsent",
                                  id: _vm.assmntFormulaAbsent,
                                },
                                domProps: {
                                  checked: this.assmntFormulaAbsent,
                                  checked: Array.isArray(
                                    _vm.assmntFormulaAbsent
                                  )
                                    ? _vm._i(_vm.assmntFormulaAbsent, null) > -1
                                    : _vm.assmntFormulaAbsent,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.assmntFormulaAbsent,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.assmntFormulaAbsent = $$a.concat(
                                            [$$v]
                                          ))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.assmntFormulaAbsent = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.assmntFormulaAbsent = $$c
                                    }
                                  },
                                },
                              }),
                              _c("span", { staticClass: "checkmark" }),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer mt-4" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn default",
                            attrs: { type: "button", "data-dismiss": "modal" },
                            on: {
                              click: function ($event) {
                                return _vm.$modal.hide("newAssessFormulaModal")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                        !_vm.isFormulaEdited
                          ? _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: { click: _vm.handleAddResFormula },
                              },
                              [_vm._v("Done")]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: { click: _vm.handleEditResFormula },
                              },
                              [_vm._v("Done")]
                            ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _vm.step === "assmnt-block-setup" && !_vm.assessmentFormula
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _vm._m(5),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "ul",
                              { staticClass: "form-entity-tabs" },
                              _vm._l(
                                _vm.externalApiAssmntBlocks,
                                function (item, ind) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.id,
                                      class:
                                        item.blockId ==
                                        _vm.selectedBlock.blockId
                                          ? "active"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectedBlocks(item, ind)
                                        },
                                      },
                                    },
                                    [_c("a", [_vm._v(_vm._s(item.blockName))])]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("div", { staticClass: "form-group pt-4" }, [
                            _c("label", [_vm._v("Add new assessment block")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newAssmntBlock,
                                  expression: "newAssmntBlock",
                                },
                              ],
                              staticClass: "form-control",
                              class: [
                                _vm.isActive && _vm.errors[0] ? "invalid" : "",
                              ],
                              attrs: { id: "admin_notes" },
                              domProps: { value: _vm.newAssmntBlock },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.newAssmntBlock = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "form-btn-group" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn w-100 primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.addNewAssessBlock },
                                },
                                [_vm._v("Add new assessment block")]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c("div", { staticClass: "skill-title my-4" }, [
                            _c("h5", [
                              _vm._v(_vm._s(this.selectedBlock.blockName)),
                            ]),
                            this.selectedBlock.id
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "email-delete-btn",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$modal.show(
                                          "deleteAssmntBlockConfirmationModal"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("em", { staticClass: "icon-delete" }),
                                    _vm._v(" Delete skill "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          this.selectedBlock.id
                            ? _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [
                                    _vm._v(
                                      "Block Description (Evaluation explanation)"
                                    ),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: { name: "Administrative Notes" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.blockDesc,
                                                    expression: "blockDesc",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  id: "admin_notes",
                                                  rows: "8",
                                                },
                                                domProps: {
                                                  value: _vm.blockDesc,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.blockDesc =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive &&
                                                        errors[0]
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      593778947
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.selectedBlock.id
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group justify-content-end d-flex",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "add-more-btn",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click:
                                          _vm.updateExternalApiAssmntBlocks,
                                      },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          this.selectedBlock.id
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group justify-content-end d-flex",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "add-more-btn",
                                      attrs: { href: "javascript:void(0);" },
                                      on: { click: _vm.goToAssessment },
                                    },
                                    [_vm._v("Configure assessments")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      _vm.updateExternalApiAssmntBlocks(),
                                        _vm.next("email-testing", 7)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$parent.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.step == "email-testing"
            ? _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _vm._m(6),
                  _c("ValidationObserver", { ref: "form" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Access Key ID")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Test Access Key",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.test_access_key,
                                                expression: "test_access_key",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: _vm.test_access_key,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.test_access_key =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1186898643
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Secret Access Key")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Test Secret Access Key",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.test_secret_access_key,
                                                expression:
                                                  "test_secret_access_key",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: { type: "test" },
                                            domProps: {
                                              value: _vm.test_secret_access_key,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.test_secret_access_key =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2492699576
                                ),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "form-testing-group" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn default",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.clearTesting },
                                },
                                [_vm._v("Clear")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.submitTesting },
                                },
                                [_vm._v("Submit")]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm.testEvalJsonScheme.attributes
                          ? _c("div", { staticClass: "col-sm-7" }, [
                              _vm.testEvalJsonScheme.attributes.responses
                                .length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "modal-test-mail" },
                                    [
                                      _c("h4", [_vm._v("Character Response")]),
                                      _vm._l(
                                        _vm.testEvalJsonScheme.attributes
                                          .responses,
                                        function (scheme, index) {
                                          return _c("p", {
                                            key: index,
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scheme.attributes.response
                                              ),
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "modal-test-tabs" }, [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "nav nav-tabs",
                                    attrs: { id: "myTab", role: "tablist" },
                                  },
                                  [
                                    _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link active",
                                          attrs: {
                                            id: "Test-tab",
                                            "data-toggle": "tab",
                                            href: "#Test",
                                            role: "tab",
                                            "aria-controls": "Test",
                                            "aria-selected": "true",
                                          },
                                        },
                                        [_vm._v("Test input Return Data")]
                                      ),
                                    ]),
                                    _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link",
                                          attrs: {
                                            id: "json-tab",
                                            "data-toggle": "tab",
                                            href: "#json",
                                            role: "tab",
                                            "aria-controls": "json",
                                            "aria-selected": "false",
                                          },
                                        },
                                        [_vm._v("View JSON")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-content pt-2",
                                    attrs: { id: "myTabContent" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade show active",
                                        attrs: {
                                          id: "Test",
                                          role: "tabpanel",
                                          "aria-labelledby": "Test-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "test-items" },
                                          [
                                            _vm.testEmailEnrichmentItems
                                              .attributes.asst_entity_value_list
                                              .length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "Assistant Entities Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.asst_entity_value_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.asst_intent_list
                                              .length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "Assistant Intents Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.asst_intent_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.keyword_list.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "NLU Keywords Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.keyword_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.concept_list.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "NLU Concepts Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.concept_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.testEmailEnrichmentItems
                                              .attributes.nlu_entities_list
                                              .length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "NLU Entities Hit:"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "test-chips-container",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "test-chips",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.testEmailEnrichmentItems.attributes.nlu_entities_list.join(
                                                                    ", "
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("div", { staticClass: "mt-3" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "NLU Emotion Scores:"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " JOY: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .joy_score
                                                          ) +
                                                          ", ANGER: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .anger_score
                                                          ) +
                                                          ", DISGUST: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .disgust_score
                                                          ) +
                                                          ", SADNESS: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .sadness_score
                                                          ) +
                                                          ", FEAR: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .fear_score
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("div", { staticClass: "mt-3" }, [
                                              _c("label", [
                                                _vm._v("NLU Sentiment Score:"),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Sentiment: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .sentiment
                                                          ) +
                                                          ", Score: " +
                                                          _vm._s(
                                                            _vm
                                                              .testEmailEnrichmentItems
                                                              .attributes
                                                              .sentiment_score
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm.testEvalJsonScheme &&
                                        _vm.testEvalJsonScheme.attributes &&
                                        _vm.testEvalJsonScheme.attributes
                                          .response_formula_hits
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "text-label" },
                                                  [
                                                    _vm._v(
                                                      "Email Response hit:"
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.testEvalJsonScheme
                                                    .attributes
                                                    .response_formula_hits,
                                                  function (formula, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "test-items",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "test-chips-container",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "test-chips",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    formula
                                                                      .attributes
                                                                      .email_response
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "json",
                                          role: "tabpanel",
                                          "aria-labelledby": "json-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "enrichment-concept-view p-0",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "enrichment-concept-editor",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "enrichment-concept-code",
                                                  },
                                                  [
                                                    _c("json-viewer", {
                                                      attrs: {
                                                        value:
                                                          _vm.testEvalJsonScheme,
                                                        "expand-depth": 5,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "enrichment-code-copy",
                                                  },
                                                  [
                                                    _c(
                                                      "copy-to-clipboard",
                                                      {
                                                        attrs: {
                                                          text: JSON.stringify(
                                                            _vm.testEvalJsonScheme
                                                          ),
                                                        },
                                                        on: {
                                                          copy: _vm.handleCopy,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "copy-icon",
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                          },
                                                          [_vm._v("Copy")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary btn-large",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.next("qa-and-interstitial", 8)
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.step === "overall-assessment"
            ? _c(
                "div",
                {
                  staticClass: "setup-content",
                  attrs: { id: "overall-assessment" },
                },
                [
                  _vm._m(7),
                  _vm.overallAssmntItem
                    ? _c("ValidationObserver", { ref: "form" }, [
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [
                                  _vm._v("Overall Interaction Assessment"),
                                ]),
                                _c(
                                  "ul",
                                  { staticClass: "form-entity-tabs" },
                                  _vm._l(
                                    _vm.overallAssmntItem,
                                    function (assmntItem) {
                                      return _c(
                                        "li",
                                        {
                                          key: assmntItem.id,
                                          class:
                                            assmntItem.id ==
                                            _vm.selectedOverallAssmntItem.id
                                              ? "active"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleOverallAssmntItem(
                                                assmntItem
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  assmntItem.attributes
                                                    .assessment_label
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-7" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "question-content",
                                  attrs: { id: "number-range" },
                                },
                                [
                                  _c("div", { staticClass: "question-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("label", [
                                          _vm._v("Evaluation Text"),
                                        ]),
                                        _c("ValidationProvider", {
                                          attrs: { name: "Evaluation Text" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("vue-editor", {
                                                      attrs: {
                                                        id: "Descriptiontext",
                                                        placeholder:
                                                          "Add a Description",
                                                        editorToolbar:
                                                          _vm.fullTool,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.overallAssmntItemFeedback,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.overallAssmntItemFeedback =
                                                            $$v
                                                        },
                                                        expression:
                                                          "overallAssmntItemFeedback",
                                                      },
                                                    }),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3765878218
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group pt-2" },
                                      [
                                        _c("label", [
                                          _vm._v("Evaluation range:"),
                                        ]),
                                        _c("ValidationProvider", {
                                          attrs: { name: "Evaluation range" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v("Minimum"),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.overallAssmntItemMinScore,
                                                                  expression:
                                                                    "overallAssmntItemMinScore",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class: [
                                                                _vm.isActive &&
                                                                errors[0]
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.overallAssmntItemMinScore,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.overallAssmntItemMinScore =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v("Maximum"),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.overallAssmntItemMaxScore,
                                                                  expression:
                                                                    "overallAssmntItemMaxScore",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              class: [
                                                                _vm.isActive &&
                                                                errors[0]
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.overallAssmntItemMaxScore,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.overallAssmntItemMaxScore =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    errors[0]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              _vm.isActive
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            style: [
                                                              _vm.isActive
                                                                ? {
                                                                    display:
                                                                      "block",
                                                                  }
                                                                : {
                                                                    display:
                                                                      "none",
                                                                  },
                                                            ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                errors[0].replace(
                                                                  "The ",
                                                                  ""
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1464490268
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-12 pt-5" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mt-4 mb-3",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.restore("published")
                                        },
                                      },
                                    },
                                    [_vm._v("Save & Publish")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-container justify-content-end",
                                },
                                [
                                  _c("a", { staticClass: "delete-btn" }, [
                                    _c("div", { staticClass: "delete-box" }, [
                                      _c("span", {
                                        staticClass: "icon-delete",
                                      }),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$parent.archiveLO(
                                              "archived"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Archive this Learning Object(LO)"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.assessmentFormula
        ? _c("div", [
            _c("div", { staticClass: "learning-form-header flex-wrap" }, [
              _c("h4", [
                _c(
                  "a",
                  {
                    staticClass: "back-btn-inner",
                    on: { click: _vm.backToAssessment },
                  },
                  [
                    _c("span", { staticClass: "icon-back" }),
                    _vm._v(" Back to Assessment Blocks "),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "learning-quiz-content pt-4" }, [
              _c(
                "div",
                { staticClass: "setup-content" },
                [
                  _c("ValidationObserver", [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c("div", { staticClass: "form-group my-3" }, [
                            _c("label", [
                              _vm._v(
                                "Block: " + _vm._s(_vm.selectedBlock.blockName)
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "form-entity-tabs form-check-tabs form-check-tabs2",
                              },
                              _vm._l(
                                _vm.externalApiAssessmentBlockItems,
                                function (skill) {
                                  return _c(
                                    "li",
                                    {
                                      key: skill.id,
                                      class:
                                        skill.id == _vm.selectedAssmntItem.id
                                          ? "active"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectAssessmentItem(skill)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "javascript:void(0);",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  skill.attributes
                                                    .assessment_label
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    skill.attributes
                                                      .assessment_formulas
                                                      .length
                                                  ) + " API Formulas"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("span", [
                                            _c("em", {
                                              staticClass: "icon-check",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c(
                            "div",
                            {
                              staticClass: "nav nav-tabs",
                              attrs: { id: "nav-tab", role: "tablist" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: `nav-item nav-link flex-none active`,
                                  attrs: {
                                    id: "response-tab",
                                    "data-toggle": "tab",
                                    href: "#nav-response",
                                    role: "tab",
                                    "aria-controls": "nav-response",
                                    "aria-selected": true ? "false" : "true",
                                  },
                                },
                                [_vm._v("Debrief Content")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "nav-item nav-link",
                                  attrs: {
                                    id: "adaptive-tab",
                                    "data-toggle": "tab",
                                    href: "#nav-adaptive",
                                    role: "tab",
                                    "aria-controls": "nav-adaptive",
                                    "aria-selected": "false",
                                  },
                                },
                                [_vm._v("Adaptive configuration")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-content",
                              attrs: { id: "nav-tabContent" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: `tab-pane fade show active`,
                                  attrs: {
                                    id: "nav-response",
                                    role: "tabpanel",
                                    "aria-labelledby": "response-tab",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "assessment-response" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: [
                                            "assessment-response",
                                            _vm.variations_briefattributes
                                              .length > 0
                                              ? "pt-2"
                                              : "",
                                          ],
                                        },
                                        [
                                          _vm.variations_briefattributes
                                            .length > 0
                                            ? _c(
                                                "ul",
                                                { staticClass: "response-tab" },
                                                _vm._l(
                                                  _vm.variations_briefattributes,
                                                  function (variations, key) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: key,
                                                        class:
                                                          _vm.variationsSelect ===
                                                          key + 1
                                                            ? "active"
                                                            : "",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addDebriefVariation(
                                                                  key + 1,
                                                                  "debrief"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Variation " +
                                                                _vm._s(
                                                                  _vm
                                                                    .variations_briefattributes
                                                                    .length > 1
                                                                    ? key + 1
                                                                    : ""
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.variations_briefattributes
                                            .length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "response-content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-control p-0",
                                                        },
                                                        [
                                                          _c(
                                                            "ValidationProvider",
                                                            {
                                                              attrs: {
                                                                name: "Variation",
                                                                rules:
                                                                  "required|max:500",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "default",
                                                                      fn: function ({
                                                                        errors,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "vue-editor",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  "form-control",
                                                                                  _vm.isActive &&
                                                                                  errors[0]
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  editorToolbar:
                                                                                    _vm.fullTool,
                                                                                  placeholder:
                                                                                    "Variation",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .variations_briefattributes[
                                                                                      _vm.variationsSelect -
                                                                                        1
                                                                                    ]
                                                                                      .content,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .variations_briefattributes[
                                                                                          _vm.variationsSelect -
                                                                                            1
                                                                                        ],
                                                                                        "content",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "variations_briefattributes[variationsSelect -1].content",
                                                                                },
                                                                            }
                                                                          ),
                                                                          errors[0]
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    [
                                                                                      _vm.isActive
                                                                                        ? "invalid"
                                                                                        : "",
                                                                                    ],
                                                                                  style:
                                                                                    [
                                                                                      _vm.isActive
                                                                                        ? {
                                                                                            display:
                                                                                              "block",
                                                                                          }
                                                                                        : {
                                                                                            display:
                                                                                              "none",
                                                                                          },
                                                                                    ],
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      errors[0].replace(
                                                                                        "The ",
                                                                                        ""
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  4067123491
                                                                ),
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "add-more-btn",
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.variations_briefattributes
                                                    .length < 5
                                                    ? _vm.addDebriefVariation(
                                                        null,
                                                        "debrief"
                                                      )
                                                    : ""
                                                },
                                              },
                                            },
                                            [_vm._v("+ Add Variation")]
                                          ),
                                          _vm.variations_briefattributes
                                            .length > 0
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "add-more-btn variation-save",
                                                  attrs: {
                                                    href: "javascript:void(0);",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.saveDebriefVariations()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Save variation(s) & points"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.variations_briefattributes
                                            .length <= 0
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "Add Variation field is required"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-group mt-4" },
                                        [
                                          _c("label", [_vm._v("Points")]),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Points",
                                              rules: "required|max:100",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.assessmentPoints,
                                                            expression:
                                                              "assessmentPoints",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: [
                                                          _vm.isActive &&
                                                          errors[0]
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            _vm.assessmentPoints,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.assessmentPoints =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      errors[0]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: [
                                                                _vm.isActive
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              style: [
                                                                _vm.isActive
                                                                  ? {
                                                                      display:
                                                                        "block",
                                                                    }
                                                                  : {
                                                                      display:
                                                                        "none",
                                                                    },
                                                              ],
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0].replace(
                                                                    "The ",
                                                                    ""
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2822605907
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-group mt-4" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              "Assessment Code (optional)"
                                            ),
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.assessmentCode,
                                                expression: "assessmentCode",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: _vm.assessmentCode,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.assessmentCode =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "formulas-data" }, [
                                    _c(
                                      "div",
                                      { staticClass: "formulas-edit" },
                                      [
                                        _vm.selApiAssmntItemFormula.length >
                                          1 || _vm.selApiAssmntItemFormula == 0
                                          ? _c(
                                              "div",
                                              { staticClass: "formulas-title" },
                                              [_vm._v("API Formulas")]
                                            )
                                          : _vm._e(),
                                        _vm.selApiAssmntItemFormula.length == 1
                                          ? _c(
                                              "div",
                                              { staticClass: "formulas-title" },
                                              [_vm._v("API Formula")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "add-more-btn",
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                            on: {
                                              click: _vm.openAssmntFormulaModal,
                                            },
                                          },
                                          [_vm._v("+ Add API Formula")]
                                        ),
                                      ]
                                    ),
                                    _vm.selApiAssmntItemFormula == 0
                                      ? _c(
                                          "ul",
                                          { staticClass: "formulas-data-list" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "formulas-left" },
                                              [_vm._v("No formulas yet")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selApiAssmntItemFormula.length > 0
                                      ? _c(
                                          "ul",
                                          { staticClass: "formulas-data-list" },
                                          _vm._l(
                                            _vm.selApiAssmntItemFormula,
                                            function (res, i) {
                                              return _c("li", { key: i }, [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "formulas-list",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "formulas-list-title",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "formulas-left",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " AWS SDK: " +
                                                                _vm._s(
                                                                  res.api_name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "formulas-edit-btn",
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editResponseFormula(
                                                                  res,
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("em", {
                                                              staticClass:
                                                                "icon-edit",
                                                            }),
                                                            _vm._v(" Edit "),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "formulas-edit-btn",
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.setDestroyId(
                                                                  res,
                                                                  "externalApiAssmntFormula"
                                                                )
                                                                _vm.$modal.show(
                                                                  "deleteResponseModal"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Delete")]
                                                        ),
                                                      ]
                                                    ),
                                                    res.present
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "formulas-list-item",
                                                          },
                                                          _vm._l(
                                                            res.present,
                                                            function (
                                                              formula,
                                                              key,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "formula-item-flex",
                                                                },
                                                                [
                                                                  formula
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "formula-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                key
                                                                              ) +
                                                                              ": "
                                                                          ),
                                                                          _vm._l(
                                                                            formula,
                                                                            function (
                                                                              entity
                                                                            ) {
                                                                              return _c(
                                                                                "span",
                                                                                {
                                                                                  key: entity.id,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      entity.value
                                                                                    ) +
                                                                                      " ,"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      )
                                                                    : _vm._e(),
                                                                  Object.keys(
                                                                    res.present
                                                                  ).length -
                                                                    1 !==
                                                                  index
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "formula-and",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "and"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tab-pane fade",
                                  attrs: {
                                    id: "nav-adaptive",
                                    role: "tabpanel",
                                    "aria-labelledby": "adaptive-tab",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c("h5", { staticClass: "small-title" }, [
                                        _vm._v("Add Adaptive Content"),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "px-4 learning-quiz-btn",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn ml-2 px-4 secondary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$modal.show(
                                                    "uploadInterstitialModal",
                                                    {
                                                      create: true,
                                                      content: "adaptive",
                                                    }
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Upload")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.adaptiveContentList.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "learning-data-list flex-column pt-3",
                                        },
                                        _vm._l(
                                          _vm.adaptiveContentList,
                                          function (item, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.id,
                                                staticClass:
                                                  "learning-data-item w-100 learning-interstitial-data-item",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learning-data-left",
                                                  },
                                                  [
                                                    item.attributes
                                                      .content_details.type ==
                                                    "text_learn_obj"
                                                      ? _c("div", {
                                                          staticClass:
                                                            "learning-data-richText",
                                                        })
                                                      : _vm._e(),
                                                    item.attributes
                                                      .content_details.type ==
                                                    "file_learn_obj"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "learning-data-icon",
                                                          },
                                                          [
                                                            _c("a", [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: require("../../assets/images/pdf-big-icon.png"),
                                                                  alt: "pdf",
                                                                },
                                                              }),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.attributes
                                                      .content_details.type ==
                                                    "slide_learn_obj"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "learning-data-icon",
                                                          },
                                                          [
                                                            _c("a", [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: item
                                                                    .attributes
                                                                    .content_details
                                                                    .attributes
                                                                    .slider_images[0]
                                                                    .attributes
                                                                    .resource_url
                                                                    .thumbnail,
                                                                  alt: "slide",
                                                                },
                                                              }),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.attributes
                                                      .content_details.type ==
                                                    "video_learn_obj"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "learning-data-video",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wistia_responsive_padding data-video",
                                                                staticStyle: {
                                                                  padding:
                                                                    "56.25% 0 0 0",
                                                                  position:
                                                                    "relative",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wistia_responsive_wrapper data-video",
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "100%",
                                                                        left: "0",
                                                                        position:
                                                                          "absolute",
                                                                        top: "0",
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "iframe",
                                                                      {
                                                                        staticClass:
                                                                          "wistia_embed",
                                                                        attrs: {
                                                                          src:
                                                                            _vm.preIframUrl +
                                                                            item
                                                                              .attributes
                                                                              .content_details
                                                                              .attributes
                                                                              .global_video
                                                                              .attributes
                                                                              .wistia_code +
                                                                            _vm.postIframUrl,
                                                                          title:
                                                                            "Video.mp4",
                                                                          name: "wistia_embed",
                                                                          allowtransparency:
                                                                            "true",
                                                                          scrolling:
                                                                            "no",
                                                                          allowfullscreen:
                                                                            "",
                                                                          mozallowfullscreen:
                                                                            "",
                                                                          webkitallowfullscreen:
                                                                            "",
                                                                          oallowfullscreen:
                                                                            "",
                                                                          msallowfullscreen:
                                                                            "",
                                                                          width:
                                                                            "100%",
                                                                          height:
                                                                            "100%",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learning-data-title",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-heads",
                                                      },
                                                      [
                                                        _c("h4", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.attributes
                                                                .title
                                                            ),
                                                          },
                                                        }),
                                                        item.attributes
                                                          .content_details
                                                          .type ==
                                                        "file_learn_obj"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.size(
                                                                    item
                                                                      .attributes
                                                                      .content_details
                                                                      .attributes
                                                                      .resource_size
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("h4", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.attributes
                                                            .content_details
                                                            .attributes.title
                                                        ),
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "learning-data-paragraph",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("p", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.attributes
                                                                  .description
                                                              ),
                                                            },
                                                          }),
                                                        ]),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticStyle: {
                                                              color: "#3dbc9e",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$modal.show(
                                                                  "uploadInterstitialModal",
                                                                  {
                                                                    create: false,
                                                                    edit: item,
                                                                    content:
                                                                      "adaptive",
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Edit")]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeAdaptiveData(
                                                                  item.id,
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Remove")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end mt-4 mb-3",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.next("email-testing", 7)
                                    },
                                  },
                                },
                                [_vm._v("Done")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteResponseModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteResponseModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm.responseFormulaId.name === "externalApiAssmntFormula"
                ? _c(
                    "button",
                    {
                      staticClass: "btn medium primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.deleteAssessmentResponseFormula(),
                            _vm.$modal.hide("deleteResponseModal")
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteEmailRespModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete this response?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteEmailRespModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.deleteResponse(),
                        _vm.$modal.hide("deleteEmailRespModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteAssmntBlockConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete this skill?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide(
                        "deleteAssmntBlockConfirmationModal"
                      )
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.deleteAssmntBlock(),
                        _vm.$modal.hide("deleteAssmntBlockConfirmationModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Basic Information"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Assessment Blocks"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Testing")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Overall Assessment"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Assessment Blocks")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Testing")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Overall Assessment")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }