var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _vm.user_roles.includes("admin")
            ? _c("router-link", { attrs: { to: "/learning-modules" } }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/ametros-learning-logo.png"),
                    alt: "ametros logo",
                  },
                }),
              ])
            : _vm._e(),
          _vm.user_roles.includes("instructor")
            ? _c("img", {
                attrs: {
                  src: require("../assets/images/ametros-learning-logo.png"),
                  alt: "ametros logo",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "navbar" }, [
        _c("ul", [
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                {
                  class: [_vm.currentPage("/learning-modules") ? "active" : ""],
                },
                [
                  _c("router-link", { attrs: { to: "/learning-modules" } }, [
                    _vm._v("Learning Modules"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                {
                  class: [
                    _vm.currentPage("/worlds") ||
                    _vm.currentPage("/world-detail")
                      ? "active"
                      : "",
                  ],
                },
                [
                  _c("router-link", { attrs: { to: "/worlds" } }, [
                    _vm._v("Worlds"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/organisations") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/organisations" } }, [
                    _vm._v("Organizations"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c("li", { staticClass: "show" }, [
                _c(
                  "a",
                  {
                    ref: "char",
                    staticClass: "dropdown-toggle",
                    attrs: {
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": [_vm.characterMgmtToggle ? true : false],
                    },
                  },
                  [
                    _vm._v(" Character Management "),
                    _c("em", { staticClass: "icon-carrot-down" }),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: [
                      _vm.characterMgmtToggle ? "show" : "",
                      "dropdown-menu",
                    ],
                  },
                  [
                    _c("ul", [
                      _c(
                        "li",
                        {
                          class: [
                            _vm.currentPage("/character-roles") ? "active" : "",
                          ],
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/character-roles" } },
                            [_vm._v("Character Roles")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        {
                          class: [
                            _vm.currentPage("/characters") ? "active" : "",
                          ],
                        },
                        [
                          _c("router-link", { attrs: { to: "/characters" } }, [
                            _vm._v("Characters"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/assessment") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/assessment" } }, [
                    _vm._v("Assessment Schemes"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c("li", { staticClass: "show" }, [
                _c(
                  "a",
                  {
                    ref: "cont",
                    staticClass: "dropdown-toggle",
                    attrs: {
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": [
                        _vm.contentMgmtToggle === "show" ? true : false,
                      ],
                    },
                  },
                  [
                    _vm._v(" Content Management "),
                    _c("em", { staticClass: "icon-carrot-down" }),
                  ]
                ),
                _c("div", { class: [_vm.contentMgmtToggle, "dropdown-menu"] }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        class: [
                          _vm.currentPage("/content-document") ? "active" : "",
                        ],
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/content-document" } },
                          [_vm._v("Documents")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        class: [
                          _vm.currentPage("/content-images") ? "active" : "",
                        ],
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/content-images" } },
                          [_vm._v("Images")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        class: [
                          _vm.currentPage("/content-videos") ? "active" : "",
                        ],
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/content-videos" } },
                          [_vm._v("Videos")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                {
                  class: [
                    _vm.currentPage("/dashboard-content") ? "active" : "",
                  ],
                },
                [
                  _c("router-link", { attrs: { to: "/dashboard-content" } }, [
                    _vm._v("Dashboard Content"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/users") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/users" } }, [
                    _vm._v("Users"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "li",
            { class: [_vm.currentPage("/user_sections") ? "active" : ""] },
            [
              _c("router-link", { attrs: { to: "/learner_central" } }, [
                _vm._v("Learner Central"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { class: [_vm.currentPage("/customers") ? "active" : ""] },
            [
              _c("router-link", { attrs: { to: "/customers" } }, [
                _vm._v("Customers"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { class: [_vm.currentPage("/courses") ? "active" : ""] },
            [
              _c("router-link", { attrs: { to: "/courses" } }, [
                _vm._v("Courses"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            { class: [_vm.currentPage("/provision") ? "active" : ""] },
            [
              _c("router-link", { attrs: { to: "/provision" } }, [
                _vm._v("Provisioning"),
              ]),
            ],
            1
          ),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/qa-emails") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/qa-emails" } }, [
                    _vm._v("Quality Assurance"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/watson-admin") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/watson-admin" } }, [
                    _vm._v("Watson Admin"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/llm-prompts") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/llm-prompts" } }, [
                    _vm._v("LLM Prompts"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/llm-skills") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/llm-skills" } }, [
                    _vm._v("LLM Skills"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.user_roles.includes("admin")
            ? _c(
                "li",
                { class: [_vm.currentPage("/llm-skill-sets") ? "active" : ""] },
                [
                  _c("router-link", { attrs: { to: "/llm-skill-sets" } }, [
                    _vm._v("LLM Skill Sets"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("userOptions"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }