<template>
  <div>
    <modal name="createLO" height="auto" :width="520" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Select Learning Object type</h5>
          </div>
          <button type="button" class="close ml-auto" @click="$modal.hide('createLO')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body modal-createLo-body">
          <form class="modal-form mt-0" @submit.prevent="onSubmit">
            <div class="modal-createLo-items">
              <h4>Plot Point</h4>
              <ul class="modal-archived-lists">
                <li>
                  <label class="custom-checkbox">
                    Rich Text
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'plot_point-text'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox" name="private">
                    Video
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'plot_point-video'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox">
                    Slide
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'plot_point-slide'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox" name="private">
                    Email
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'plot_point-email'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>
                </li>
              </ul>
              <span
                :class="[(!type && display) ? 'invalid' : '']"
                :style="[(!type && display) ? {'display': 'block'} : {'display': 'none'}]"
              >Learning Object type is required</span>
            </div>
            <div class="modal-createLo-items">
              <h4>Content</h4>
              <ul class="modal-archived-lists">
                <li>
                  <label class="custom-checkbox">
                    Rich Text
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'content-text'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox" name="private">
                    Video
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'content-video'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox">
                    Slide
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'content-slide'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox" name="private">
                    PDF
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'content-file'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox" name="private">
                    CSV
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'content-file'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-createLo-items">
              <h4>Interaction</h4>
              <ul class="modal-archived-lists">
                <li>
                  <label class="custom-checkbox">
                    Quiz
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-quiz'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox" name="private">
                    Chat
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-chat'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox">
                    Email
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-email'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>

                  <label class="custom-checkbox" name="private">
                    Dialogic
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-dialogic'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>

                  <label class="custom-checkbox" name="private">
                    External API
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-external_api'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>

                  <label class="custom-checkbox" name="private">
                    Coding
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-coding'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-createLo-items">
              <h4>LLM Interaction</h4>
              <ul class="modal-archived-lists llm-interaction">
                <li>
                  <label class="custom-checkbox">
                    Chat
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-llm_chat'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label class="custom-checkbox">
                    Dialogic
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-llm_dialogic'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label class="custom-checkbox">
                    Email
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-llm_email'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="modal-createLo-items">
              <h4>Submission</h4>
              <ul class="modal-archived-lists">
                <li>
                  <label class="custom-checkbox">
                    Grading
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'interaction-submission'"
                    />
                    <span for="public" class="checkmark"></span>
                  </label>
                  <label class="custom-checkbox" name="private">
                    Email
                    <input
                      type="radio"
                      name="card_type"
                      v-model="type"
                      v-bind:value="'submission-submission_email'"
                    />
                    <span for="private" class="checkmark"></span>
                  </label>

                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn primary">Done</button>
            </div>
          </form>
        </div>
      </div>
    </modal>
    <div class="lazy-loader" style="display: none">
      <div class="lazy-loader-content">
        <div class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="lazy-loader-title">Uploading</div>
      </div>
    </div>
    <modal
      name="archivedObjects"
      class="modal-archived"
      height="auto"
      :width="445"
      :scrollable="true"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close ml-auto" @click="$modal.hide('archivedObjects')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body">
          <h4>Archived Objects</h4>
          <p>{{ archivedRecord.archived && archivedRecord.archived.length}} Learning Object are in Archived.</p>
          <ul
            class="modal-archived-lists"
            v-if="archivedRecord.archived && archivedRecord.archived.length > 0"
          >
            <li v-for="(archive) in archivedRecord.archived" :key="archive.id">
              <div class="modal-archived-data">
                <p>{{archive.attributes.learning_object_type | heading | uppercase(true)}}({{archive.attributes.card_type | uppercase(true)}})</p>
                <div class="modal-archived-text">{{archive.attributes.name}}</div>
                <p>Deleted on {{archive.attributes.archived_on}}</p>
              </div>
              <a class="modal-archived-btn" @click="restore(archive.id)">
                <img alt="images" src="../../assets/images/restore-icon.png" />
                Restore
              </a>
            </li>
          </ul>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "createLO",
  components: {},
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      type: "",
      display: false,
      learn_mod_id: this.$route.params.id.name,
      first_name: "",
      isActive: "",
      last_name: "",
      searchVideo: "",
      videoLists: [],
      totalVideos: "",
      uploadGalleryStatus: true,
      uploadVideoGallery: true,
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      video: {},
      url: utilFunctionService.getImageUrl()
    };
  },
  props: ["archivedRecord"],
  filters: {
    uppercase: function(value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (value === "file") {
        value = "Pdf";
      }
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    },
    heading: function(value) {
      if (value === "plot_point") value = "Plot Point";
      if (value === "content") value = "Content";
      if (value === "interaction") value = "Interaction";
      if (value === "submission") value = "Submission";
      return value;
    }
  },
  methods: {
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    onSubmit() {
      if (this.type) {
        this.display = false;
        this.$emit("listenerChild", this.type);
        this.type = "";
        this.hide("createLO");
      } else {
        this.display = true;
      }
    },
    restore(id) {
      const fb = new FormData();
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("learning_object[status]", "drafted");
      fb.append("id", id);
      api
        .restoreLo(this.$route.params.id, id, fb)
        .then(() => {
          this.$parent.getElmDetails();
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoRestoredText
          );
          this.$parent.hide("archivedObjects");
          // this.$router.push({id:this.$route.params.id,query: { id: this.$route.query.id } });
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch(() => {
          this.$parent.getElmDetails();
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoRestoredText
          );
          this.$parent.hide("archivedObjects");
          this.$router.push(this.$route.params.id);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.llm-interaction {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
}
</style>