<template>
    <div class="learner-container">
        <div class="header">
            <h2 class="learn-info" v-html="userLearnObj.attributes.learning_object.attributes.description"></h2>
        </div>
        <div class="messages-container">
            <div class="messages-area" v-for="(message, index) in messages" :key="index">
                <transition name="up">
                    <div v-if="message.question" class="bubble robot-bubble">
                        <div class="character-container">
                            {{ characterName }}
                        </div>
                        <div class="character-response">
                            {{ message.question }}
                        </div>
                    </div>
                </transition>
                <transition name="up">
                    <div v-if="message.answer" class="bubble learner-bubble">
                        {{ message.answer }}
                    </div>
                </transition>
                <transition name="up">
                    <div v-if="message.response" class="bubble robot-bubble">
                        <div class="character-container">
                            <h4 class="character-name">{{ characterName }}</h4>
                        </div>
                        <div v-for="(characterResponse, index) in message.response" :key="index">
                            <div class="character-response">
                                {{ characterResponse.response }}
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="modal-body m-auto">
            <div class="modal-title">
                <div class="final-feedback-title">
                    <h1>Conversation Assessment</h1>
                </div>
            </div>
            <div class="container">
                <div v-for="(feedback, name, index) in feedbackData" class="row" :key="feedback[index]">
                    <h2>{{ name }}</h2>
                    <p class="feedback-result">{{ feedback.result }}</p>
                    <p>{{ feedback.feedback }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "../services/api";

export default {
    name: "learner-llm-email",
    props: ["userLearnObj", "adminLearnerLoDetails",],
    data() {
        return {
            messages: [
                {
                    question: "",
                    answer: "",
                    response: "",
                },
            ],
            userAnswer: "",
            loading: false,
            assessmentLoading: false,
            feedback: "",
            hasStarted: false,
            finishedLoading: false,
            numberOfAttempts: 0,
            attemptsLimit: 3,
            llmComplete: false,
            showAssessment: false,
            showFeedback: false,
            dialogicQuestions: [],
            characterName: "",
            llmChatImageEnabled: false,
            llmChatImageURL: "",
            voiceEnabled: false,
            text: '',
            feedbackData: "",
            isMoatCustom: false,
            qaItems: [],
            enableAvatar: false,
            showChatLog: true,
            responseToAdd: "",
            conclusionAdded: false,
            final_feedback_loading: false,
            evaluationId: null,
            isDialogic: false,
            moat_lo_id: null
        };
    },
    methods: {
        getLlmConversation() {
            let payload = {
                lo_id: this.userLearnObj.attributes.learning_object_id,
                user_learn_obj_id: this.userLearnObj.id
            };
            api.getLlmHistory(payload).then((res) => {
                let chatHistoryData = res.data.data;
                chatHistoryData.forEach((chatEl) => {
                    this.hasStarted = true;
                    let response = "";
                    if (chatEl.attributes.response.length > 0) {
                        response = chatEl.attributes.response;
                    }
                    this.messages.push({
                        answer: chatEl.attributes.body,
                        response: response,
                    });
                });
            });
        },
        getLlmAssessment() {
            this.assessmentLoading = true;
            api.getLlmEmailAssessment(this.userLearnObj.attributes.current_evaluation_id).then((res) => {
                console.log(res);
                this.feedbackData = res.data.data.attributes.assessment_results;
                this.finishedLoading = true;
            });
        },
    },
    mounted() {
        document.addEventListener("keyup", (event) => {
            if (event.keyCode === 13 && this.userAnswer !== "" && !this.loading) {
                this.submitAnswer();
            }
        });
        this.llmComplete = this.userLearnObj.attributes.complete;
        let cardDetail =
            this.userLearnObj.attributes.learning_object.attributes.card_detail;
        this.isDialogic = cardDetail.attributes.dialogic_switch;
        this.dialogicQuestions = cardDetail.attributes.dialogic_questions || [];
        if (cardDetail.attributes.dialogic_switch && this.isMoatCustom) {
            this.dialogicQuestions = cardDetail.attributes.dialogic_questions || [];
            this.messages[this.messages.length - 1].question = this.dialogicQuestions[0];
        } else {
            try {
                this.qaItems = JSON.parse(cardDetail.attributes.questions);
                if (this.qaItems.length > 0) {
                    this.messages[this.messages.length - 1].question = this.qaItems[0].question;
                } else {
                    this.messages[this.messages.length - 1].question = this.dialogicQuestions[0];
                }
            } catch (e) {
                this.qaItems = cardDetail.attributes.questions;
                this.messages[this.messages.length - 1].question = this.dialogicQuestions[0] || "";
            }
        }
        this.characterName = cardDetail.attributes.character_name;
        this.getLlmConversation();
        this.getLlmAssessment();
        this.evaluationId = this.userLearnObj.attributes.current_evaluation_id;
    },
};
</script>
  
<style lang="scss" scoped>
.learner-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.messages-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 45px;
}

.bubble {
    border-radius: 15px;
    padding: 16px;
    margin-bottom: 16px;
    max-width: 50%;
}

.learner-bubble {
    background: #3dbc9e;
    color: #faf9f6;
    font-weight: bold;
    justify-self: flex-end;
    align-self: flex-end;
    text-align: right;
    width: fit-content;
}

.robot-bubble {
    color: #faf9f6;
    font-weight: bold;
    justify-self: flex-start;
    align-self: flex-start;
    width: fit-content;
    position: relative;
    min-width: 300px;
    padding: 0;

    .character-response {
        margin-bottom: 12px;
        background: #3d9bbc;
        padding: 16px;
        color: #faf9f6;
        font-weight: bold;
        justify-self: flex-start;
        align-self: flex-start;
        width: fit-content;
        position: relative;
        min-width: 300px;
        border-radius: 15px
    }
}

.chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 32px;
}

.button {
    padding: 8px;
    border-radius: 5px;
    background: white;
    border: 2px solid green;
    margin-top: 16px;
}

.button:hover {
    background: green;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: 100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.up-enter-active,
.fade-leave-active {
    transition: all 0.5s;
}

.up-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
    transform: translateY(30px);
}

.feedback {
    width: 50%;
    margin: 25px auto;
    text-align: left;
    line-height: 1.6;
}

.spacer {
    display: block;
    width: 50%;
    margin: 0 auto;
    height: 1px;
    background: black;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 128px;
}

.messages-buttons-container {
    display: flex;
    width: 100%;
    padding: 10px;
    position: relative;
    min-height: 75px;
    max-height: 450px;
}

.messages-container {
    display: flex;
    width: 100%;
    padding: 10px;
    position: relative;
    min-height: 75px;
    flex-direction: column;
}

.input-area {
    color: black;
    width: 400px;
    min-height: 75px;
    padding: 16px;
    max-height: 450px;
}

.submit-button {
    border: none;
    font-size: 14px;
    padding: 6px 10px;
    border: 1px solid green;
    margin-top: 0;
}

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin ball {
    @include center;
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgb(240, 240, 240);
    z-index: 2;
    margin-top: 4px;
    animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}

/*--------------------
  Bounce
  --------------------*/
@keyframes bounce {
    0% {
        transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    4.7% {
        transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    9.41% {
        transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    14.11% {
        transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    18.72% {
        transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    24.32% {
        transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    29.93% {
        transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    35.54% {
        transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    41.04% {
        transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    52.15% {
        transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    63.26% {
        transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    85.49% {
        transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    100% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
}

.loading {
    padding: 16px;

    &::before {
        @include ball;
        border: none;
        animation-delay: 0.15s;
    }

    & span {
        display: block;
        font-size: 0;
        width: 20px;
        height: 10px;
        position: relative;

        &::before {
            @include ball;
            margin-left: -7px;
        }

        &::after {
            @include ball;
            margin-left: 7px;
            animation-delay: 0.3s;
        }
    }
}

@keyframes ball {
    from {
        transform: translateY(0) scaleY(0.8);
    }

    to {
        transform: translateY(-10px);
    }
}

.avatar {
    border-radius: 30px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 2px solid rgba(255, 255, 255, 0.24);
    margin-right: 16px;

    img {
        width: 100%;
        height: auto;
    }
}

.character-container {
    position: absolute;
    z-index: 1;
    top: -50px;
    left: 0px;
    background: #3d9bbc;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.character-loading {
    position: absolute;
    z-index: 1;
    top: -50px;
    left: 0px;
    background: #3d9bbc;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.character-name {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 0;
}

.scroll-chat {
    overflow: scroll;
    max-height: 450px;
}
</style>