var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "WorldDetail" } },
    [
      _c("div", { staticClass: "container-3-column" }, [
        _c("header", { staticClass: "header pr-0 flex-nowrap" }, [
          _vm.worldDetails.attributes
            ? _c("div", { staticClass: "header-left" }, [
                _c("div", { staticClass: "header-subtitle" }, [
                  _vm._v(
                    "Unique code " +
                      _vm._s(_vm.worldDetails.attributes.world_code)
                  ),
                ]),
                _c("h2", [_vm._v(_vm._s(_vm.worldDetails.attributes.name))]),
                _c("h4", [
                  _vm._v(
                    "Created on " +
                      _vm._s(_vm.worldDetails.attributes.created_on)
                  ),
                ]),
                _c("div", { staticClass: "header-content" }, [
                  _c(
                    "div",
                    { staticClass: "hello" },
                    [
                      _c("read-more", {
                        attrs: {
                          "more-str": "Read more",
                          text: _vm.description,
                          link: "#",
                          "less-str": "Read less",
                          "max-chars": 270,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "header-edit-dropdown" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "dropdown-menu mt-1 dropdown-menu-right" },
              [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          "data-toggle": "modal",
                          "data-target": "#createWorld",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$modal.show("newWorld", {
                              id: _vm.id,
                              editOrg: false,
                            })
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          "data-toggle": "modal",
                          "data-target": "#createWorld",
                        },
                        on: { click: _vm.exportWorld },
                      },
                      [_vm._v("Export")]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-error",
                        on: {
                          click: function ($event) {
                            return _vm.$modal.show("deleteWorldModal")
                          },
                        },
                      },
                      [_vm._v("Delete")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "data-no-result data-no-elm" }, [
          !_vm.elms.length
            ? _c("div", { staticClass: "manage-module" }, [
                _c(
                  "div",
                  { staticClass: "manage-head" },
                  [
                    _vm._m(1),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn manage-btn mt-0",
                        attrs: { to: "/create-elm" },
                      },
                      [_vm._v("+ Add a new ELM")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.elms.length
            ? _c("div", { staticClass: "manage-content" }, [
                _c(
                  "div",
                  { staticClass: "manage-module" },
                  [
                    _c(
                      "div",
                      { staticClass: "manage-head" },
                      [
                        _c("div", { staticClass: "manage-subtitle" }, [
                          _vm._v("All ELMs(" + _vm._s(_vm.totalElmCount) + ")"),
                        ]),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn manage-btn mt-0",
                            attrs: { to: "/create-elm" },
                          },
                          [_vm._v("+ Add a new ELM")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-infinite-scroll",
                      {
                        staticClass: "manage-scroller",
                        on: { bottom: _vm.nextPage },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "manage-row" },
                          _vm._l(_vm.elms, function (elm) {
                            return _c(
                              "div",
                              { key: elm.id, staticClass: "manage-card" },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: `/edit-elm/${elm.id}` } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "manage-card-image" },
                                      [
                                        elm.attributes.photo_url.large_version
                                          ? _c("img", {
                                              attrs: {
                                                src: elm.attributes.photo_url
                                                  .large_version,
                                                alt: "image",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "manage-card-content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "manage-card-title" },
                                      [_vm._v(_vm._s(elm.attributes.name))]
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          elm.attributes.learning_objects_count
                                        ) + " Learning Objects"
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteWorldModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v(" Are you sure you want to delete "),
              _vm.worldDetails.attributes
                ? _c("span", [
                    _vm._v(_vm._s(_vm.worldDetails.attributes.name) + " ?"),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteWorldModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.deleteWorld(), _vm.$modal.hide("deleteWorldModal")
                    },
                  },
                },
                [_vm._v("ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c("WorldDetails", { on: { worldDetail: _vm.worldDetail } }),
      _c("OrganisationSidebar", { attrs: { worldDetails: _vm.worldDetails } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "true",
        },
      },
      [_c("em", { staticClass: "icon-menu" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", [_vm._v("All ELMs")]),
      _c("p", { staticClass: "no-manage-text" }, [
        _vm._v("You have no ELM in the system yet."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }