var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ELMBuild" } },
    [
      _c(
        "div",
        { staticClass: "create-elm-container" },
        [
          _c(
            "router-link",
            { staticClass: "elm-back-btn", attrs: { to: "/learning-modules" } },
            [_c("span", { staticClass: "icon-back" })]
          ),
          _c("div", { staticClass: "elm-header-main" }, [
            _c("div", { staticClass: "header-left" }, [
              _c("h2", [_vm._v(_vm._s(_vm.elmData.elm_name))]),
              _c("div", { staticClass: "elm-archived" }, [
                _vm.elmData &&
                _vm.elmData.active &&
                _vm.elmData.active.length > 1
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.elmData.active.length) +
                          " Learning Objects in this ELM "
                      ),
                    ])
                  : _vm.elmData &&
                    _vm.elmData.active &&
                    _vm.elmData.active.length == 1
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.elmData.active.length) +
                          " Learning Object in this ELM "
                      ),
                    ])
                  : _vm.elmData &&
                    _vm.elmData.active &&
                    _vm.elmData.active.length == 0
                  ? _c("p", [_vm._v(" 0 Learning Objects in this ELM ")])
                  : _vm._e(),
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$modal.show("archivedObjects")
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.elmData.archived &&
                                _vm.elmData.archived.length
                            ) + " Archived"
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _vm.elmData.elm_status == "drafted"
                ? _c(
                    "a",
                    {
                      staticClass: "btn header-btn primary big",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.publishElm()
                        },
                      },
                    },
                    [_vm._v("Publish ELM")]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm.enableGrading
            ? _c("div", { staticClass: "elm-header-grading" }, [
                _c("div", { staticClass: "align-items-right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn secondary big",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.showGradeElm()
                        },
                      },
                    },
                    [_vm._v("Configure ELM Grading")]
                  ),
                ]),
              ])
            : _vm._e(),
          _c("LoMenu", {
            attrs: {
              elmMenu: {
                card_type: _vm.card_type,
                learning_object_type: _vm.learning_object_type,
                video: _vm.video,
                newLo: _vm.newLo,
              },
            },
          }),
        ],
        1
      ),
      _c("CreateLO", {
        attrs: { archivedRecord: { archived: _vm.elmData.archived } },
        on: {
          listenerChild: _vm.listenerChild,
          selectedVideo: function ($event) {
            return _vm.selectedVideo($event)
          },
        },
      }),
      _c(
        "modal",
        {
          attrs: {
            name: "GradingSetupModal",
            height: "auto",
            width: 1400,
            scrollable: true,
          },
        },
        [
          _c("div", {
            staticClass: "hide-modal",
            on: {
              click: function ($event) {
                return _vm.$modal.hide("GradingSetupModal")
              },
            },
          }),
          _c("div", { staticClass: "modal-content modal-default" }, [
            _c(
              "div",
              {
                staticClass: "row d-inline-flex",
                staticStyle: { float: "right" },
              },
              [
                _c("div", { staticClass: "col-3" }),
                _c("div", { staticClass: "col-2" }),
                _c("div", { staticClass: "col-2" }),
                _c("div", { staticClass: "col-1" }),
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c(
                      "div",
                      {
                        staticClass: "modal-title",
                        attrs: { id: "exampleModalLabel" },
                      },
                      [_c("h5", [_vm._v("ELM Highest Grade")])]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "pr-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.allOverallWeight,
                          expression: "allOverallWeight",
                        },
                      ],
                      staticClass: "custom-input-style-grade-number2",
                      attrs: { type: "text" },
                      domProps: { value: _vm.allOverallWeight },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.allOverallWeight = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "row d-inline-flex",
                staticStyle: { float: "right" },
              },
              [
                _c("div", { staticClass: "col-3" }),
                _c("div", { staticClass: "col-2" }),
                _c("div", { staticClass: "col-2" }),
                _c("div", { staticClass: "col-1" }),
                _vm.overallWeightExceede
                  ? _c("div", { staticClass: "col" }, [
                      _c("span", { staticClass: "pr-4" }, [
                        _c(
                          "div",
                          { staticClass: "invalid text-right w-100 mr-2" },
                          [_vm._v(" The ELM grade should not excced 100 ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "modal-body question-modal-body-grading" },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.learnObjGradingWeights, function (ro, inx) {
                    return _c("div", { key: inx, staticClass: "col-4" }, [
                      _c("a", { staticClass: "add-question-link-grading" }, [
                        _c("span", [_vm._v(_vm._s(ro.learning_object_name))]),
                        _c(
                          "div",
                          {
                            staticClass: "row d-inline-flex",
                            staticStyle: { float: "right" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "custom-span-style-grade-number2",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal-title",
                                    attrs: { id: "exampleModalLabel" },
                                  },
                                  [_c("span", [_vm._v("ILO Overall Weight")])]
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "pr-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: ro.ilo_weight,
                                    expression: "ro.ilo_weight",
                                  },
                                ],
                                staticClass: "custom-input-style-grade-number2",
                                attrs: { type: "text" },
                                domProps: { value: ro.ilo_weight },
                                on: {
                                  change: _vm.changeIloWeight,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      ro,
                                      "ilo_weight",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                        _vm.gradingType === "combination"
                          ? _c(
                              "div",
                              {
                                staticClass: "row d-inline-flex",
                                staticStyle: { float: "right" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-span-style-grade-number2",
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [_vm._v("Assessment")]),
                                    ]),
                                  ]
                                ),
                                _c("span", { staticClass: "pr-4" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ro.assessment_weight,
                                        expression: "ro.assessment_weight",
                                      },
                                    ],
                                    staticClass:
                                      "custom-input-style-grade-number2",
                                    attrs: { type: "text" },
                                    domProps: { value: ro.assessment_weight },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeIloAssessment(inx)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          ro,
                                          "assessment_weight",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.gradingType === "combination"
                          ? _c(
                              "div",
                              {
                                staticClass: "row d-inline-flex",
                                staticStyle: { float: "right" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-span-style-grade-number2",
                                  },
                                  [
                                    _c("div", [
                                      _c("span", [_vm._v("Completion")]),
                                    ]),
                                  ]
                                ),
                                _c("span", { staticClass: "pr-4" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: ro.completion_weight,
                                        expression: "ro.completion_weight",
                                      },
                                    ],
                                    staticClass:
                                      "custom-input-style-grade-number2",
                                    attrs: { type: "text" },
                                    domProps: { value: ro.completion_weight },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeIloCompletion(inx)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          ro,
                                          "completion_weight",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
            _c("div", { staticClass: "form-group pt-3 text-right" }, [
              _c(
                "a",
                {
                  staticClass: "btn secondary",
                  attrs: { href: "javascript:void(0);" },
                  on: { click: _vm.saveElmGrade },
                },
                [_vm._v("Save")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "saveTotalWeightIncorrect",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v(" The ELM grade should not excced 100 "),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("saveTotalWeightIncorrect")
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }