var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "createUser",
        height: "auto",
        width: 500,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [
              _vm.user_id
                ? _c("h5", [_vm._v("Edit User")])
                : _c("h5", [_vm._v("Create a new User")]),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("createUser")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("ValidationObserver", { ref: "form" }, [
              _c("form", { staticClass: "modal-form organisation-form" }, [
                _c("div", { staticClass: "organisation-image-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "organisation-image-pic cursor",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.fileInput.click()
                        },
                      },
                    },
                    [
                      _vm.image
                        ? _c("img", {
                            attrs: { src: _vm.image, alt: "images" },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "organisation-image-remove" }, [
                    _c("input", {
                      ref: "fileInput",
                      staticStyle: { display: "none" },
                      attrs: { type: "file", accept: "image/*" },
                      on: { change: _vm.onFileSelectImage },
                    }),
                    !_vm.image
                      ? _c(
                          "a",
                          {
                            staticClass: "btn add",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput.click()
                              },
                            },
                          },
                          [_vm._v("Add Photo")]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "btn delete",
                            on: { click: _vm.imageRemoved },
                          },
                          [_vm._v("Remove Photo")]
                        ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "first_name" },
                      },
                      [_vm._v("First Name")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "First Name", rules: "required|max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.first_name,
                                    expression: "first_name",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "first_name",
                                  id: "first_name",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.first_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.first_name = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "last_name" },
                      },
                      [_vm._v("Last Name")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "Last Name", rules: "required|max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.last_name,
                                    expression: "last_name",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "last_name",
                                  id: "last_name",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.last_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.last_name = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "last_name" },
                      },
                      [_vm._v("Email")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "Email", rules: "required|max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "email",
                                  id: "email",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "last_name" },
                      },
                      [_vm._v("Password")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "Password", rules: "max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password,
                                    expression: "password",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "password",
                                  id: "password",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.password = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "exampleInputEmail1" },
                    },
                    [_vm._v("Add a Role")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchRoles,
                        expression: "searchRoles",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.searchRoles },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.searchRoles = $event.target.value
                        },
                        _vm.getRoles,
                      ],
                    },
                  }),
                  _vm.rolesList.length
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            class: [_vm.rolesList.length !== 0 ? "show" : ""],
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "dropdown-list" },
                              _vm._l(_vm.rolesList, function (roles) {
                                return _c(
                                  "li",
                                  {
                                    key: roles.id,
                                    staticClass: "dropdown-items",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c("h4", [
                                          _vm._v(_vm._s(roles.attributes.name)),
                                        ]),
                                      ]
                                    ),
                                    !roles.status
                                      ? _c(
                                          "div",
                                          { staticClass: "dropdown-action" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add-action",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addRoles(roles)
                                                  },
                                                },
                                              },
                                              [_vm._v("+")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    roles.status
                                      ? _c(
                                          "div",
                                          { staticClass: "dropdown-action" },
                                          [
                                            _c("em", {
                                              staticClass: "icon-check",
                                            }),
                                            _c("span", [_vm._v("Added")]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm.addedRoles.length
                  ? _c("div", { staticClass: "customer-dropdown" }, [
                      _c(
                        "ul",
                        { staticClass: "dropdown-list mt-0 mb-4" },
                        _vm._l(_vm.addedRoles, function (roles, index) {
                          return _c(
                            "li",
                            { key: roles.id, staticClass: "dropdown-items" },
                            [
                              _c("div", { staticClass: "dropdown-content" }, [
                                _c("div", { staticClass: "user-name" }, [
                                  _vm._v(_vm._s(roles.attributes.name)),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeRole(index)
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn default",
              attrs: { type: "button", "data-dismiss": "modal" },
              on: {
                click: function ($event) {
                  return _vm.hide("createUser")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "button",
            {
              staticClass: "btn primary",
              attrs: { type: "button", "data-dismiss": "modal" },
              on: { click: _vm.saveUser },
            },
            [_vm._v("Save")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }