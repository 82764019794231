var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "learning-form-header flex-wrap" }, [
        _c("h4", [_vm._v("Submission (Email)")]),
        _c("div", { staticClass: "learning-quiz-btn" }, [
          _vm.status == "published"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn secondary",
                  attrs: { href: "javascript:void(0);" },
                  on: { click: _vm.restore },
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _vm.status == "drafted"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn primary",
                  attrs: { href: "javascript:void(0);" },
                  on: { click: _vm.restore },
                },
                [_vm._v("Publish LO")]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "btn header-btn ml-2 secondary",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.$modal.show("cloneConfirmationModal")
                },
              },
            },
            [_vm._v("Clone LO")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "learning-lo-tabs" }, [
        _c("div", { staticClass: "setup-container" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[0] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("basic-setup", 0)
                  },
                },
              },
              [_vm._m(0)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[1] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("configure-rubric", 1)
                  },
                },
              },
              [_vm._m(1)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[2] },
                on: {
                  click: function ($event) {
                    _vm.rubric_character_response.length > 0
                      ? _vm.currentStep("character-response", 2)
                      : ""
                  },
                },
              },
              [_vm._m(2)]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "learning-quiz-content pt-4" }, [
        _vm.step == "basic-setup"
          ? _c("div", { staticClass: "setup-content" }, [
              _vm._m(3),
              _c(
                "span",
                [
                  _c("ValidationObserver", { ref: "subform" }, [
                    _c("form", { staticClass: "w-100" }, [
                      _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Interaction title")]),
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Interaction Title",
                                  rules: "required|max:100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: { type: "text" },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1240204542
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Administrative notes")]),
                              _c(
                                "ValidationProvider",
                                { attrs: { name: "Description" } },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.adminNotes,
                                        expression: "adminNotes",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "Description",
                                      id: "Description",
                                      rows: "8",
                                    },
                                    domProps: { value: _vm.adminNotes },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.adminNotes = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("div", { staticClass: "form-group mr-1" }, [
                                _c("div", { staticClass: "form-checkboxes" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox",
                                      attrs: { name: "quizAssessment" },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.resubmission,
                                            expression: "resubmission",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "quizAssessment",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.resubmission
                                          )
                                            ? _vm._i(_vm.resubmission, null) >
                                              -1
                                            : _vm.resubmission,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.resubmission,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.resubmission =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.resubmission = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.resubmission = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        attrs: { for: "quizAssessment" },
                                      }),
                                      _vm._v("Enable resubmission: "),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.resubmission
                                ? _c("div", { staticClass: "form-group" }, [
                                    _c("span", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.resubmission_limit,
                                            expression: "resubmission_limit",
                                          },
                                        ],
                                        staticClass:
                                          "form-control form-control-small",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: "2",
                                          placeholder: "times",
                                        },
                                        domProps: {
                                          value: _vm.resubmission_limit,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.resubmission_limit =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "form-group mr-1" }, [
                            _c("div", { staticClass: "form-checkboxes" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox",
                                  attrs: { name: "quizAssessment" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.pdf_submission_enabled,
                                        expression: "pdf_submission_enabled",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "quizAssessment",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.pdf_submission_enabled
                                      )
                                        ? _vm._i(
                                            _vm.pdf_submission_enabled,
                                            null
                                          ) > -1
                                        : _vm.pdf_submission_enabled,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.pdf_submission_enabled,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.pdf_submission_enabled =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.pdf_submission_enabled = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.pdf_submission_enabled = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    attrs: { for: "quizAssessment" },
                                  }),
                                  _vm._v("Allow PDF Submission "),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "form-group mr-1" }, [
                            _c("div", { staticClass: "form-checkboxes" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox",
                                  attrs: { name: "InstructorEdit" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.editable_by_instructor,
                                        expression: "editable_by_instructor",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "instructorEdit",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.editable_by_instructor
                                      )
                                        ? _vm._i(
                                            _vm.editable_by_instructor,
                                            null
                                          ) > -1
                                        : _vm.editable_by_instructor,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.editable_by_instructor,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.editable_by_instructor =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.editable_by_instructor = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.editable_by_instructor = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    attrs: { for: "instructorEdit" },
                                  }),
                                  _vm._v("Allow Instructor to Edit Rubric "),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-sm-7" },
                          [
                            _c("CharacterEmail", {
                              ref: "emailCharacterForm",
                              attrs: {
                                selectedMenuItem: _vm.selectedMenuItem,
                                characters: _vm.characters,
                                updatedData: _vm.updatedData,
                              },
                              on: {
                                selectedCharacters: _vm.selectedCharacters,
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "form-group submission-group" },
                              [
                                _c("label", [_vm._v("Learner Description")]),
                                _c(
                                  "span",
                                  [
                                    _c("vue-editor", {
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: "Add a Description",
                                      },
                                      model: {
                                        value: _vm.description,
                                        callback: function ($$v) {
                                          _vm.description = $$v
                                        },
                                        expression: "description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-sm-12 mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-end mb-4" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn primary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createSubmissionEmail(
                                        "drafted"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Next")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.archiveSubLo("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.step == "configure-rubric"
          ? _c(
              "div",
              { staticClass: "setup-content" },
              [
                _c("Rubric", {
                  ref: "rubricForm",
                  attrs: { updatedData: _vm.updatedData },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.step == "character-response"
          ? _c("div", { staticClass: "setup-content" }, [
              _c("div", { staticClass: "setup-content" }, [
                _vm._m(4),
                _c("span", [
                  _c("form", { staticClass: "w-100" }, [
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-12 nav nav-tabs" }, [
                        _c(
                          "a",
                          {
                            class: [
                              "nav-item",
                              "nav-link",
                              "flex-none",
                              "px-5",
                              _vm.character_affix.active_range === 1
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.saveCharacterAffix(1, 1)
                              },
                            },
                          },
                          [_vm._v("0-59")]
                        ),
                        _c(
                          "a",
                          {
                            class: [
                              "nav-item",
                              "nav-link",
                              "flex-none",
                              "px-5",
                              _vm.character_affix.active_range === 2
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.saveCharacterAffix(2, 1)
                              },
                            },
                          },
                          [_vm._v("60-79")]
                        ),
                        _c(
                          "a",
                          {
                            class: [
                              "nav-item",
                              "nav-link",
                              "flex-none",
                              "px-5",
                              _vm.character_affix.active_range === 3
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.saveCharacterAffix(3, 1)
                              },
                            },
                          },
                          [_vm._v("80-100")]
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-12 tab-content" }, [
                        _c("div", { staticClass: "tab-item" }, [
                          _c("div", { staticClass: "assessment-response" }, [
                            _c("ul", { staticClass: "response-tab" }, [
                              _c(
                                "li",
                                {
                                  class: [
                                    _vm.character_affix.active_order === 1
                                      ? "active"
                                      : "",
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveCharacterAffix(
                                            _vm.character_affix.active_range,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Iteration 1 ")]
                                  ),
                                ]
                              ),
                              _vm.resubmission_limit >= 1
                                ? _c(
                                    "li",
                                    {
                                      class: [
                                        _vm.character_affix.active_order === 2
                                          ? "active"
                                          : "",
                                      ],
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveCharacterAffix(
                                                _vm.character_affix
                                                  .active_range,
                                                2
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Iteration 2 ")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.resubmission_limit === 2
                                ? _c(
                                    "li",
                                    {
                                      class: [
                                        _vm.character_affix.active_order === 3
                                          ? "active"
                                          : "",
                                      ],
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveCharacterAffix(
                                                _vm.character_affix
                                                  .active_range,
                                                3
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Iteration 3 ")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "response-content" },
                              [
                                _c(
                                  "ValidationObserver",
                                  { ref: "subCharacterAffix" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "response-item" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "row custom-row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Introduction"),
                                                    ]),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c("vue-editor", {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            placeholder:
                                                              "Add a Description",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .character_response_affix[
                                                                _vm
                                                                  .character_affix
                                                                  .intro
                                                              ][
                                                                _vm
                                                                  .character_affix
                                                                  .active_order -
                                                                  1
                                                              ].value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .character_response_affix[
                                                                  _vm
                                                                    .character_affix
                                                                    .intro
                                                                ][
                                                                  _vm
                                                                    .character_affix
                                                                    .active_order -
                                                                    1
                                                                ],
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                                      character_response_affix[\n                                        character_affix.intro\n                                      ][character_affix.active_order - 1]\n                                        .value\n                                    ",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Conclusion"),
                                                    ]),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c("vue-editor", {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            placeholder:
                                                              "Add a Conclusion",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .character_response_affix[
                                                                _vm
                                                                  .character_affix
                                                                  .conc
                                                              ][
                                                                _vm
                                                                  .character_affix
                                                                  .active_order -
                                                                  1
                                                              ].value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .character_response_affix[
                                                                  _vm
                                                                    .character_affix
                                                                    .conc
                                                                ][
                                                                  _vm
                                                                    .character_affix
                                                                    .active_order -
                                                                    1
                                                                ],
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "\n                                      character_response_affix[\n                                        character_affix.conc\n                                      ][character_affix.active_order - 1]\n                                        .value\n                                    ",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row custom-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-sm-12 nav nav-tabs justify-content-between",
                        },
                        [
                          _c(
                            "a",
                            {
                              class: [
                                "nav-item",
                                "nav-link",
                                "flex-none",
                                "px-5",
                                _vm.rubric_character.active_range === 1
                                  ? "active"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveRubric(1, 1, "0-49")
                                },
                              },
                            },
                            [_vm._v("0-49")]
                          ),
                          _c(
                            "a",
                            {
                              class: [
                                "nav-item",
                                "nav-link",
                                "flex-none",
                                "px-5",
                                _vm.rubric_character.active_range === 2
                                  ? "active"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveRubric(2, 1, "50-59")
                                },
                              },
                            },
                            [_vm._v("50-59")]
                          ),
                          _c(
                            "a",
                            {
                              class: [
                                "nav-item",
                                "nav-link",
                                "flex-none",
                                "px-5",
                                _vm.rubric_character.active_range === 3
                                  ? "active"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveRubric(3, 1, "60-69")
                                },
                              },
                            },
                            [_vm._v("60-69")]
                          ),
                          _c(
                            "a",
                            {
                              class: [
                                "nav-item",
                                "nav-link",
                                "flex-none",
                                "px-5",
                                _vm.rubric_character.active_range === 4
                                  ? "active"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveRubric(4, 1, "70-79")
                                },
                              },
                            },
                            [_vm._v("70-79")]
                          ),
                          _c(
                            "a",
                            {
                              class: [
                                "nav-item",
                                "nav-link",
                                "flex-none",
                                "px-5",
                                _vm.rubric_character.active_range === 5
                                  ? "active"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveRubric(5, 1, "80-89")
                                },
                              },
                            },
                            [_vm._v("80-89")]
                          ),
                          _c(
                            "a",
                            {
                              class: [
                                "nav-item",
                                "nav-link",
                                "flex-none",
                                "px-5",
                                _vm.rubric_character.active_range === 6
                                  ? "active"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveRubric(6, 1, "90-100")
                                },
                              },
                            },
                            [_vm._v("90-100")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-sm-12 tab-content" }, [
                        _c("div", { staticClass: "tab-item" }, [
                          _c("div", { staticClass: "assessment-response" }, [
                            _c("ul", { staticClass: "response-tab" }, [
                              _c(
                                "li",
                                {
                                  class: [
                                    _vm.rubric_character.active_order === 0
                                      ? "active"
                                      : "",
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.setActiveRubric(
                                            _vm.rubric_character.active_range,
                                            0,
                                            _vm.rubric_character.rangeValue
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Variation 1")]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  class: [
                                    _vm.rubric_character.active_order === 1
                                      ? "active"
                                      : "",
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.setActiveRubric(
                                            _vm.rubric_character.active_range,
                                            1,
                                            _vm.rubric_character.rangeValue
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Variation 2")]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  class: [
                                    _vm.rubric_character.active_order === 2
                                      ? "active"
                                      : "",
                                  ],
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.setActiveRubric(
                                            _vm.rubric_character.active_range,
                                            2,
                                            _vm.rubric_character.rangeValue
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Variation 3")]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "response-content" }, [
                              _c("div", { staticClass: "response-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "row custom-row mt-0" },
                                  [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grading-table grading-column-2",
                                        },
                                        [
                                          _vm._m(5),
                                          _c(
                                            "div",
                                            { staticClass: "grading-data" },
                                            _vm._l(
                                              _vm.rubric_character_response,
                                              function (
                                                character_response,
                                                index
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: character_response.id,
                                                    staticClass: "grading-row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grading-col",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grading-box",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "grading-criteria",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      placeholder:
                                                                        "Enter Criteria",
                                                                      value:
                                                                        character_response
                                                                          .attributes
                                                                          .rubric_criteria,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          character_response
                                                                            .attributes
                                                                            .rubric_criteria
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grading-col",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grading-box",
                                                          },
                                                          [
                                                            character_response
                                                              .attributes[
                                                              _vm
                                                                .rubric_character
                                                                .rangeTitle
                                                            ][
                                                              _vm
                                                                .rubric_character
                                                                .active_order
                                                            ].value
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "grading-edit",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.showModel(
                                                                            "addRubricCharacterResponseModal",
                                                                            index,
                                                                            _vm
                                                                              .rubric_character
                                                                              .rangeTitle,
                                                                            character_response
                                                                              .attributes[
                                                                              _vm
                                                                                .rubric_character
                                                                                .rangeTitle
                                                                            ][
                                                                              _vm
                                                                                .rubric_character
                                                                                .active_order
                                                                            ]
                                                                              .value,
                                                                            character_response.id,
                                                                            _vm
                                                                              .rubric_character
                                                                              .rangeValue
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("p", {
                                                                      staticClass:
                                                                        "form-control",
                                                                      attrs: {
                                                                        placeholder:
                                                                          "Enter Criteria",
                                                                      },
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              character_response
                                                                                .attributes[
                                                                                _vm
                                                                                  .rubric_character
                                                                                  .rangeTitle
                                                                              ][
                                                                                _vm
                                                                                  .rubric_character
                                                                                  .active_order
                                                                              ]
                                                                                .value
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "grading-add",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showModel(
                                                                                "addRubricCharacterResponseModal",
                                                                                index,
                                                                                _vm
                                                                                  .rubric_character
                                                                                  .rangeTitle,
                                                                                character_response
                                                                                  .attributes[
                                                                                  _vm
                                                                                    .rubric_character
                                                                                    .rangeTitle
                                                                                ][
                                                                                  _vm
                                                                                    .rubric_character
                                                                                    .active_order
                                                                                ]
                                                                                  .value,
                                                                                character_response.id,
                                                                                _vm
                                                                                  .rubric_character
                                                                                  .rangeValue
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Add Feedback"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _vm._m(6),
                        _c("div", { staticClass: "assessment-response" }, [
                          _c("ul", { staticClass: "response-tab mt-3 mb-2" }, [
                            _c(
                              "li",
                              {
                                class: [
                                  _vm.activeInterim === 1 ? "active" : "",
                                ],
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.setAndSaveInterim(1)
                                      },
                                    },
                                  },
                                  [_vm._v("Iteration 1")]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                class: [
                                  _vm.activeInterim === 2 ? "active" : "",
                                ],
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.setAndSaveInterim(2)
                                      },
                                    },
                                  },
                                  [_vm._v("Iteration 2")]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                class: [
                                  _vm.activeInterim === 3 ? "active" : "",
                                ],
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.setAndSaveInterim(3)
                                      },
                                    },
                                  },
                                  [_vm._v("Iteration 3")]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "response-content" }, [
                            _c("div", { staticClass: "response-item" }, [
                              _c("div", { staticClass: "row custom-row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "span",
                                      [
                                        _c("vue-editor", {
                                          staticClass: "form-control",
                                          attrs: {
                                            placeholder: "Add a Description",
                                          },
                                          model: {
                                            value:
                                              _vm.interim_resp[
                                                _vm.activeInterim
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.interim_resp,
                                                _vm.activeInterim,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "interim_resp[activeInterim]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-12 pt-5" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end mb-4" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn primary",
                                attrs: { href: "javascript:void(0);" },
                                on: { click: _vm.reload },
                              },
                              [_vm._v("Save & Publish")]
                            ),
                          ]
                        ),
                        _vm._m(7),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "addRubricCharacterResponseModal",
            height: "auto",
            width: 700,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [
                  _c("h5", [_vm._v("Add Feedback")]),
                  _c("p", { staticClass: "addFeedbackTitle" }, [
                    _vm._v("Criteria (Score " + _vm._s(_vm.colName) + ")"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "form",
                { staticClass: "modal-form modal-addFeedbackForm" },
                [
                  _c("vue-editor", {
                    attrs: { id: "test", editorToolbar: _vm.fullTool },
                    model: {
                      value: _vm.transcript,
                      callback: function ($$v) {
                        _vm.transcript = $$v
                      },
                      expression: "transcript",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("addRubricCharacterResponseModal")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.saveRubricCharacterData(
                        "addRubricCharacterResponseModal"
                      )
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "cloneConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v(" Are you sure you want to clone this learning object? "),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.cloneLo(), _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v(" Ok ")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption w-auto" }, [
        _vm._v("Basic Information"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption w-auto" }, [
        _vm._v("Configure Rubric Feedback"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption w-auto" }, [
        _vm._v("Configure Character Response"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", { staticClass: "head-title" }, [
          _vm._v("Configure Character Response"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "grading-head mb-1" }, [
      _c("div", { staticClass: "grading-col" }, [
        _c("span", [_vm._v("criteria")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex mt-4 justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Interim Response")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btn-container justify-content-end" }, [
      _c("a", { staticClass: "delete-btn" }, [
        _c("div", { staticClass: "delete-box" }, [
          _c("span", { staticClass: "icon-delete" }),
        ]),
        _c("a", { staticClass: "text" }, [
          _vm._v("Archive this Learning Object(LO)"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }