<template>
  <div class="lo-sidebar">
    <div class="iteration-items">
      <div class="iteration-detail-title">{{adminLearnerLODetails.attributes.last_name}}, {{adminLearnerLODetails.attributes.first_name}}</div>
      <div class="detail-content my-3">
        <router-link :to="`/learner-lo-list/${adminLearnerLODetails.id}`">
          <button
            type="button"
            class="btn secondary btn-small"
          >back to LO list
          </button>
        </router-link>
        
        </div>
      
      
    </div>
  </div>
</template>

<script>
//import api from "../services/api";
export default {
  name: "LearnerLOSidebar",
  props: ["adminLearnerLODetails"],
  data() {
    return {
      
    };
  },
  methods: {
    
    
    
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.lo-sidebar {
  width: 220px;
  @include position(fixed, 0, 0, 0);
  min-height: 100%;
  padding: 20px 10px 0;
  background-color: $theme-color;
  &:after {
    content: "";
    display: block;
    @include position(fixed, 0, 0, 0);
    width: 220px;
    background-color: $theme-color;
  }
  @include media-breakpoint(xl) {
    width: 313px;
    padding: 30px 30px 10px;
    &:after {
      width: 313px;
    }
  }

  .iteration-detail-title {
    @include font-bold;
    font-size: 18px;
    line-height: 1.18;
    color: $white;
    margin-top: 25px;
  }

  .detail-content {
    font-size: 14px;
    line-height: 1.18;
    color: $white;
  }

  .iteration-items {
    border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
    margin: 30px 0 0;
    padding: 0 0 33px;
    position: relative;
    z-index: 50;
    &:last-child {
      border: 0;
    }
  }
}
</style>
