<template>
  <div>
    <div class="learning-form-header flex-wrap">
      <h4>Submission (Grading)</h4>
      <div class="learning-quiz-btn">
         <a
            href="javascript:void(0);"
            @click="publishLo('published')"
            class="btn header-btn secondary"
            v-if="status == 'published'"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            @click="publishLo('published')"
            class="btn header-btn primary"
          >Publish LO</a>
           <a
            href="javascript:void(0);"
            @click="$modal.show('cloneConfirmationModal')"
            class="btn header-btn ml-2 secondary"
          >Clone LO</a>
      </div>
    </div>
    <div class="learning-lo-tabs">
      <div class="setup-container">
        <ul>
          <li @click="currentForm('basic-setup', 0)" :class="{ active: isActiveTab[0] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption w-auto">Basic Information</span>
            </a>
          </li>
          <li @click="currentForm('configure-rubric', 1)" :class="{ active: isActiveTab[1] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption w-auto">Configure Rubric Feedback</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="learning-quiz-content">
      <div class="setup-content" v-if="step == 'basic-setup'">
          <ValidationObserver ref="subform">
            <form class="w-100"  @submit.prevent="submitLo('drafted')">
              <div class="row custom-row pt-4">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label>Interaction Title</label>
                    <ValidationProvider
                      name="Interaction Title" rules="required|max:100" v-slot="{ errors }">
                    <input type="text" class="form-control"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                      v-model="name"/>
                    <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Administrative Notes</label>
                    <ValidationProvider name="Description">
                      <textarea
                        type="Description"
                        class="form-control"
                        id="Description"
                        rows="8"
                        v-model="adminNotes"
                      ></textarea>
                    </ValidationProvider>
                  </div>
                </div>
                 <div class="col-sm-7">
                  <div class="form-group submission-group">
                    <label>Learner Description</label>
                    <span>
                       <ValidationProvider name="Description">
                       <vue-editor
                          v-model="description"
                          placeholder="Add a Description"
                          class="form-control"
                        ></vue-editor>
                     </ValidationProvider>
                    </span>
                  </div>
                  <div class="row pb-5">
                    <div class="form-group col-5">
                      <label>Submission type:</label>
                      <div class="form-checkboxes mb-4">
                        <label name="quizAssessment" class="custom-checkbox">
                        <input
                              type="radio"
                              name="public"
                              v-on:click="handleCheckboxChange"
                              :value="'has_text'"
                              :checked="textSubmission"
                            />
                        <span for="quizAssessment" class="checkmark"></span>Text
                        </label>
                      </div>
                      <div class="form-checkboxes mb-4">
                        <label name="quizAssessment" class="custom-checkbox">
                        <input
                              type="radio"
                              name="private"
                              v-on:click="handleCheckboxChange"
                              :value="'has_file_upload'"
                              :checked="fileUpload"
                            />
                        <span for="quizAssessment" class="checkmark"></span>File upload
                        </label>
                      </div>
                    </div>
                    <div class="form-group col-7">
                      <label class="form-label">Allow instructor to edit rubric:</label>
                      <div class="form-checkboxes mb-4">
                        <label name="yes" class="custom-checkbox"> Yes
                        <input type="radio" name="yes" value="true" v-model="editPermission">
                        <span for="yes" class="checkmark circle"></span>
                        </label>
                      </div>
                      <div class="form-checkboxes mb-4">
                        <label name="no" class="custom-checkbox"> No
                        <input type="radio" name="no" value="false" v-model="editPermission">
                        <span for="no" class="checkmark circle"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-7">
                      <label name="writingAssessment" class="custom-checkbox">
                        <input 
                          type="checkbox" 
                          name="enableWritingAssessment" 
                          value="false" 
                          v-model="enableWritingAssessment">
                        <span for="writingAssessment" class="checkmark"></span>Enable Writing Assessment [Coherence]
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end mb-4">
                    <a class="btn primary" href="javascript:void(0);" @click="submitLo('drafted')">Save</a>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveSubLo"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
      </div>
      <div v-if="step == 'configure-rubric'">
        <Rubric :updatedData="updatedData" ref="rubricForm"/>
      </div>
    </div>
    <modal name="cloneConfirmationModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Are you sure you want to clone this learning object?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('cloneConfirmationModal')"
                class="btn medium default"
              >Cancel</button>
              <button type="button" @click="cloneLo(),$modal.hide('cloneConfirmationModal')" class="btn medium primary">Ok</button>
            </div>
          </div>
    </modal>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "../services/api";
import Rubric from "./../components/modal/Rubric";
import { VueEditor } from "vue2-editor";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "SubmissionCard",
  components: {
      ValidationProvider,
      ValidationObserver,
      Rubric,
      VueEditor
  },
  props: ["elmMenu", "selectedMenuItem"],
  data() {
    return {
      name: "",
      step: "basic-setup",
      description: "",
      adminNotes: "",
      textSubmission: null,
      fileUpload: true,
      isActive: false,
      isActiveTab: ["active"],
      loId: null,
      status: 'drafted',
      editPermission: false,
      enableWritingAssessment: false,
      objectableId: "",
      updatedData: "",
      data: ""
    };
  },
  methods: {
      cloneLo() {
        utilFunctionService.showLoader();
        api
          .cloneObj(this.$route.params.id, this.$route.query.id)
          .then(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.loCloned
            );
            this.$router.push(this.$route.params.id);
            window.location.reload();
          })
          .catch();
      },
      publishLo() {
        if (this.step === 'basic-setup') {
           const fd = new FormData();
          fd.append("learn_mod_id", this.$route.params.id || this.data.attributes.learn_mod_id);
          fd.append("learning_object[status]", "published");
          fd.append("id", this.$route.query.id);
          api
            .restoreLo(this.$route.params.id || this.data.attributes.learn_mod_id, this.$route.query.id || this.updatedData.id, fd)
            .then(() => {
              this.reload()
            })
            .catch(() => {
              this.reload()
            });
        } else {
          this.$refs.rubricForm.restore();
        }
      },
      reload() {
        this.$parent.card_type = "";
        utilFunctionService.showSuccess(
          commonConfig.appMessages.LoPublichedText
        );
        // this.$router.push(this.$route.params.id);
        window.location.reload();
      },
      submissionUpdate() {
          this.fileUpload = false;
          this.textSubmission = false;
          if (!this.$route.query.id) {
             this.clearForm()
          } else {
            this.data = this.selectedMenuItem
            this.setFormValues();
          }
      },
      setFormValues() {
        if (this.data) {
            let { name, status,admin_notes, description ,card_detail, objectable_id} = this.data.attributes
            this.name = name;
            this.status = status;
            this.adminNotes = admin_notes;
            this.description = description;
            this.fileUpload = card_detail.attributes.has_file_upload;
            this.textSubmission = card_detail.attributes.has_text;
            this.editPermission = card_detail.attributes.editable_by_instructor;
            this.enableWritingAssessment = card_detail.attributes.writing_assessment;
            this.objectableId = objectable_id;
        }
    },
    submitLo() {
      utilFunctionService.showLoader();
      this.$refs.subform.validate().then((success) => {
        this.isActive = true;
        if(!this.fileUpload && !this.textSubmission){
            utilFunctionService.hideLoader();
            return;
        }
        if (!success) {
          utilFunctionService.hideLoader();
          return;
        } else {
          const fb = new FormData();
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[description]", this.description);
          fb.append(
            "learning_object[learning_object_type]",
            "submission"
          );
          fb.append("learning_object[status]", this.status === 'published' ? 'published' : 'drafted');
          fb.append("learn_mod_id", this.$route.params.id);
          fb.append("card_type", this.elmMenu.card_type);
          fb.append("card[editable_by_instructor]", this.editPermission)
          fb.append("card[writing_assessment]", this.enableWritingAssessment)
          fb.append("card[has_text]", this.textSubmission);
          fb.append("card[has_file_upload]", this.fileUpload);
          fb.append("learning_object[admin_notes]", this.adminNotes);
          if (this.$route.query.id || this.data.id) {
            api
              .updateLo(this.$route.params.id || this.data.attributes.learn_mod_id, this.$route.query.id || this.data.id, fb)
              .then((res) => {
                this.navigateToRubric(res);
              })
              .catch(() => {
                utilFunctionService.hideLoader();
              });
          } else {
            api
              .saveLo(this.$route.params.id, fb)
              .then((res) => {
                this.navigateToRubric(res)
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                this.clearForm();
              });
          }
        }
      });
    },
    navigateToRubric(res) {
      utilFunctionService.hideLoader();
      this.step = "configure-rubric";
      this.isActiveTab.push("active");
      this.updatedData = res.data.data;
      this.$emit('updateLoList');
      setTimeout(() => {
          this.updateBasicInfo();
       }, 1500);
    },
    updateBasicInfo() {
       this.data = this.updatedData;
       this.setFormValues()
    },
    currentForm(step) {
      if (step === "basic-setup") {
        this.step = "basic-setup";
        this.data = this.updatedData;
        this.setFormValues();
      } else {
        this.submitLo('drafted');
      }
    },
    handleCheckboxChange(e) {
        if (e.target.value === "has_text") {
           this.textSubmission = ( this.textSubmission)? false: true;
         } else {
           this.fileUpload = (this.fileUpload)? false: true;
        }
    },
    clearForm() {
        this.data = ""
        this.status = 'drafted';
        this.name = '';
        this.adminNotes = '';
        this.description = '';
        this.fileUpload = true;
        this.textSubmission = false;
        this.editPermission = false;
        this.enableWritingAssessment = false;
    },
    archiveSubLo(){
        this.$emit("archiveLO");
    }
  }
};
</script>

<style lang="scss" scoped>
.assessment-checkbox {
  display: flex;
  flex-direction: row;
  label {
    margin: 0 0 0 16px;
  }
}
</style>
