<template>
  <div id="World">
    <modal name="newWorld" height="auto" :scrollable="true" @before-open="beforeOpen">
      <div class="modal-content" v-if="!editOrg && !editOrgs && addedOrgList.length">
        <div>
          <div id="AddNewWorld" v-if="!addOrganisation">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5 v-if="!editWorldStatus">Create a new world</h5>
                <h5 v-if="editWorldStatus">Edit world</h5>
                <p v-if="!editWorldStatus">Create a new world and add customers.</p>
              </div>
              <button type="button" class="close" @click="hide('newWorld')">
                <em class="icon-close"></em>
              </button>
            </div>
            <div class="modal-body">
              <ValidationObserver ref="form">
                <form class="modal-form">
                  <div class="form-group">
                    <label class="form-label">Name</label>
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        aria-describedby="emailHelp"
                        v-model="name"
                        maxlength="30"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "") }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Description</label>
                    <textarea class="form-control" v-model="description" maxlength="500"></textarea>
                    <div class="form-characters">500 characters</div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Type</label>
                    <div class="form-checkboxes">
                      <label
                        class="custom-checkbox"
                        name="public"
                        :class="[editWorldStatus ? 'disabled' : '']"
                      >
                        Public
                        <input
                          :disabled="editWorldStatus == true"
                          type="radio"
                          name="public"
                          v-model="type"
                          v-bind:value="'public'"
                        />
                        <span for="public" class="checkmark"></span>
                      </label>
                      <label
                        class="custom-checkbox"
                        name="private"
                        :class="[editWorldStatus ? 'disabled' : '']"
                      >
                        Private
                        <input
                          :disabled="editWorldStatus == true"
                          type="radio"
                          name="private"
                          v-model="type"
                          v-bind:value="'private'"
                        />
                        <span for="private" class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group" v-if="type === 'private'">
                    <label class="form-label">Customer</label>
                    <div class="customer-dropdown" v-if="!editWorldStatus">
                      <input
                        :disabled="editWorldStatus == true"
                        v-model="customer"
                        v-on:input="searchCustomer"
                        type="text"
                        placeholder="Type here"
                        class="form-control"
                        :class="[noCustomerAdded ? 'invalid' : '']"
                      />
                      <span
                        class="invalid"
                        style="display:block"
                        v-if="noCustomerAdded"
                      >Add customer is required</span>

                      <div v-if="customers.length != 0">
                        <div class="dropdown-menu" :class="[customers.length !== 0 ? 'show' : '']">
                          <ul class="dropdown-list">
                            <li
                              class="dropdown-items"
                              v-for="(customer) of customers"
                              :key="customer.id"
                            >
                              <div class="dropdown-content">
                                <h4>{{customer.name}}</h4>
                                <p>{{customer.email}}</p>
                              </div>
                              <div class="dropdown-action" v-if="!customer.status">
                                <a class="add-action" @click="addCustomer(customer)">+</a>
                              </div>
                              <div class="dropdown-action" v-if="customer.status">
                                <em class="icon-check"></em>
                                <span>Added</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="customer-dropdown-results" v-if="addedCustomer.id">
                      <ul class="dropdown-list">
                        <li class="dropdown-items">
                          <div class="dropdown-content">
                            <h4>{{addedCustomer.name}}</h4>
                            <p>{{addedCustomer.email}}</p>
                          </div>
                          <div class="dropdown-action">
                            <a
                              v-if="!editWorldStatus"
                              @click="removeCustomer()"
                              class="delete-action"
                              :class="[editWorldStatus ? 'disabled' : '']"
                            >Remove</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn default" @click="hide('newWorld')">Cancel</button>
                    <button
                      v-if="!editWorldStatus"
                      type="button"
                      data-toggle="modal"
                      data-target="#addOrganisation"
                      data-dismiss="modal"
                      class="btn primary"
                      v-on:click="validateWorld"
                    >
                      Save & add organization
                      <em class="icon-carrot-right"></em>
                    </button>
                    <button
                      v-if="editWorldStatus"
                      type="button"
                      data-toggle="modal"
                      data-target="#addOrganisation"
                      data-dismiss="modal"
                      class="btn primary"
                      v-on:click="validateWorld"
                    >
                      Save & edit organization
                      <em class="icon-carrot-right"></em>
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
           <div id="AddOrganisation" v-if="addOrganisation">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5 v-if="!editWorldStatus">Add Organization to the World</h5>
                <h5 v-if="editWorldStatus">Edit Organization to the World</h5>
                <p
                  v-if="worldDetails.id"
                >{{worldDetails.attributes.name}}, {{worldDetails.attributes.world_code}}</p>
              </div>
              <button type="button" class="close" @click="hide('newWorld')">
                <em class="icon-close"></em>
              </button>
            </div>
            <div class="modal-body">
              <form class="modal-form">
                <div class="form-group">
                  <div class="organisation-dropdown">
                    <input
                      type="text"
                      v-model="searchOrg"
                      v-on:input="searchOrganisation"
                      placeholder="Type here"
                      class="form-control btn dropdown-toggle"
                      :class="[noOrgAdded ? 'invalid' : '']"
                    />
                    <span
                      class="invalid"
                      style="display:block"
                      v-if="noOrgAdded"
                    >Atleast one organization is required.</span>
                    <span
                      v-if="noCharacterAdded"
                      class="invalid"
                      style="display:block"
                    >Minimum one character selection is required per organization.</span>

                    <div v-if="worldOrg.length">
                      <div class="dropdown-menu" :class="[worldOrg.length !== 0 ? 'show' : '']">
                        <ul class="dropdown-list">
                          <li class="dropdown-items" v-for="(org) of worldOrg" :key="org.id">
                            <div class="dropdown-pics" v-if="org.attributes.photo_url.thumbnail !=  false">
                              <img :src="org.attributes.photo_url.thumbnail" alt="images" />
                            </div>
                            <div class="dropdown-pics" v-if="org.attributes.photo_url.thumbnail ==  false"></div>
                            <div class="dropdown-content">
                              <h4>{{org.attributes.name}}</h4>
                              <h5>{{org.attributes.industry_name}}</h5>
                            </div>
                            <div class="dropdown-action" v-if="!org.status">
                              <a @click="addOrg(org)" class="add-action">+</a>
                            </div>
                            <div class="dropdown-action" v-if="org.status">
                              <em class="icon-check"></em>
                              <span>Added</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="organisation-dropdown-results"
                    v-if="addedOrgList.length && !worldOrg.length"
                  >
                    <ul class="dropdown-list">
                      <li
                        class="dropdown-items"
                        v-for="(addOrg,i) of addedOrgList"
                        :key="addOrg.id"
                      >
                        <div class="dropdown-content">
                          <h4>{{addOrg.attributes.name}}</h4>
                          <div class="dropdown-info">{{addOrg.attributes.industry_name}}</div>
                        </div>
                        <div class="dropdown-action">
                          <a @click="removeOrg(i, addOrg.id)" class="delete-action">Remove</a>
                        </div>
                        <div class="w-100">
                          <div class="dropdown-user-list">
                            <div
                              class="dropdown-user-item"
                              v-for="(character,index) of addOrg.attributes.organization_characters"
                              :key="character.id"
                            >
                              <label class="custom-checkbox" name="public3">
                                <input
                                  type="checkbox"
                                  name="public3"
                                  v-model="character.attributes.characters.attributes.status"
                                />
                                <span for="public3" class="checkmark circle"></span>
                              </label>
                              <div class="user-pic">
                                <img
                                  :src="character.attributes.characters.attributes.photo_url.thumbnail"
                                  v-if="character.attributes.characters.attributes.photo_url.thumbnail"
                                  alt="image"
                                />
                              </div>
                              <div class="user-month">
                                {{character.attributes.characters.attributes.full_name}}
                                <span
                                  class="custom-tooltip"
                                >{{character.attributes.characters.attributes.full_name}}</span>
                              </div>
                              <div
                                class="user-role dropdown-toggle p-0"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <div>{{character.attributes.world_role.attributes.name}}</div>
                                <span
                                  class="custom-tooltip"
                                >{{character.attributes.world_role.attributes.name}}</span>
                              </div>
                              <div
                                class="user-gender"
                              >{{character.attributes.characters.attributes.age}} , {{character.attributes.characters.attributes.gender | uppercase(true)}}</div>
                              <div
                                class="dropdown-menu data-popper-organise"
                                aria-labelledby="menu1"
                              >
                                <div class="data-popover">
                                  <div class="data-popover-title">
                                    <div class="d-flex align-items-center">
                                      <div class="user-pic">
                                        <img
                                          :src="character.attributes.characters.attributes.photo_url.thumbnail"
                                          v-if="character.attributes.characters.attributes.photo_url.thumbnail"
                                          alt="image"
                                        />
                                      </div>
                                      <span>{{character.attributes.characters.attributes.full_name}}</span>
                                      <div
                                        class="user-poper-gender"
                                      >{{character.attributes.characters.attributes.age}} , {{character.attributes.characters.attributes.gender}}</div>
                                    </div>
                                    <a class="data-popover-close">
                                      <em class="icon-close"></em>
                                    </a>
                                  </div>
                                  <form class="data-popover-form">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        v-model="searchRole"
                                        v-on:input="getRole"
                                        class="form-control"
                                        placeholder="Enter Role"
                                      />
                                      <div class="dropdown-list mt-3" v-if="roles.length">
                                        <div
                                          class="dropdown-items"
                                          v-for="(role) of roles"
                                          :key="role.id"
                                        >
                                          <div class="dropdown-content">
                                            <h4
                                              @click="setRole(i,index,role.id, role.attributes.name)"
                                            >{{role.attributes.name}}</h4>
                                          </div>
                                          <div class="dropdown-action">
                                            <div class="dropdown-action">
                                              <a
                                                class="add-action"
                                                @click="setRole(i,index,role.id, role.attributes.name)"
                                              >+</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group-button">
                                      <button class="btn btn-save">Save</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn default" @click="cancel">Cancel</button>
                  <button type="button" class="btn primary" @click="editWorldDetails">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-content" v-if="editOrg && editOrganization && addedOrgList.length">
        <div>
          <div id="AddOrganisation" v-if="editOrg && editOrganization && addedOrgList.length">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5 v-if="editWorldStatus">Edit Organization to the World</h5>
                <p
                  v-if="worldDetails.id"
                >{{worldDetails.attributes.name}}, {{worldDetails.attributes.world_code}}</p>
              </div>
              <button type="button" class="close" @click="hide('newWorld')">
                <em class="icon-close"></em>
              </button>
            </div>
            <div class="modal-body" v-if="editOrg && editOrganization && addedOrgList.length">
              <form class="modal-form">
                <div class="form-group">
                  <div class="organisation-dropdown">
                    <input
                      type="text"
                      v-model="searchOrg"
                      v-on:input="searchOrganisation"
                      placeholder="Type here"
                      class="form-control btn dropdown-toggle"
                      :class="[noOrgAdded ? 'invalid' : '']"
                    />
                    <span
                      class="invalid"
                      style="display:block"
                      v-if="noOrgAdded"
                    >Atleast one organization is required.</span>
                    <span
                      v-if="noCharacterAdded"
                      class="invalid"
                      style="display:block"
                    >Minimum one character selection is required per organization.</span>

                    <div v-if="worldOrg.length">
                      <div class="dropdown-menu" :class="[worldOrg.length !== 0 ? 'show' : '']">
                        <ul class="dropdown-list">
                          <li class="dropdown-items" v-for="(org) of worldOrg" :key="org.id">
                            <div class="dropdown-pics">
                              <img :src="org.attributes.photo_url.thumbnail" alt="images" />
                            </div>
                            <div class="dropdown-content">
                              <h4>{{org.attributes.name}}</h4>
                              <h5>{{org.attributes.industry_name}}</h5>
                            </div>
                            <div class="dropdown-action" v-if="!org.status">
                              <a @click="addOrg(org)" class="add-action">+</a>
                            </div>
                            <div class="dropdown-action" v-if="org.status">
                              <em class="icon-check"></em>
                              <span>Added</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="organisation-dropdown-results"
                    v-if="addedOrgList.length && !worldOrg.length"
                  >
                    <ul class="dropdown-list">
                      <li
                        class="dropdown-items"
                        v-for="(addOrg,i) of addedOrgList"
                        :key="addOrg.id"
                      >
                        <div class="dropdown-content">
                          <h4>{{addOrg.attributes.name}}</h4>
                          <div class="dropdown-info">{{addOrg.attributes.industry_name}}</div>
                        </div>
                        <div class="dropdown-action">
                          <a @click="removeOrg(i, addOrg.id)" class="delete-action">Remove</a>
                        </div>
                        <div class="w-100">
                          <div class="dropdown-user-list">
                            <div
                              class="dropdown-user-item"
                              v-for="(character,index) of addOrg.attributes.organization_characters"
                              :key="character.id"
                            >
                              <label class="custom-checkbox" name="public3">
                                <input
                                  type="checkbox"
                                  name="public3"
                                  v-model="character.attributes.characters.attributes.status"
                                />
                                <span for="public3" class="checkmark circle"></span>
                              </label>

                              <div class="user-pic">
                                <img
                                  :src="character.attributes.characters.attributes.photo_url.thumbnail"
                                  v-if="character.attributes.characters.attributes.photo_url.thumbnail"
                                  alt="image"
                                />
                              </div>
                              <span>{{character.attributes.characters.attributes.full_name}}</span>
                              <p
                                class="user-role dropdown-toggle p-0"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >{{character.attributes.world_role.attributes.name}}</p>
                              <div
                                class="user-gender"
                              >{{character.attributes.characters.attributes.age}} , {{character.attributes.characters.attributes.gender}}</div>
                              <div
                                class="dropdown-menu data-popper-organise"
                                aria-labelledby="menu1"
                              >
                                <div class="data-popover">
                                  <div class="data-popover-title">
                                    <div class="d-flex align-items-center">
                                      <div class="user-pic"></div>
                                      <span>{{character.attributes.full_name}}</span>
                                    </div>
                                    <a class="data-popover-close">
                                      <em class="icon-close"></em>
                                    </a>
                                  </div>
                                  <form class="data-popover-form">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        v-model="searchRole"
                                        v-on:input="getRole"
                                        class="form-control"
                                        placeholder="Enter Role"
                                      />
                                      <div class="dropdown-list mt-3" v-if="roles.length">
                                        <div
                                          class="dropdown-items"
                                          v-for="(role) of roles"
                                          :key="role.id"
                                        >
                                          <div class="dropdown-content">
                                            <h4
                                              @click="setRole(i,index,role.id, role.attributes.name)"
                                            >{{role.attributes.name}}</h4>
                                          </div>
                                          <div class="dropdown-action">
                                            <div class="dropdown-action">
                                              <a
                                                class="add-action"
                                                @click="setRole(i,index,role.id, role.attributes.name)"
                                              >+</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn default" @click="hide('newWorld')">Cancel</button>
                  <button type="button" class="btn primary" @click="editWorldDetails">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "WorldDetails-component",
  filters: {
    uppercase : function(value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  metaInfo: {
    title: "World Detail",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      noCustomerAdded: false,
      name: "",
      searchRole: "",
      editWorldStatus: false,
      searchOrg: "",
      description: "",
      type: "private",
      customer: "",
      worldsList: [],
      addedCustomer: {},
      customers: [],
      totalPages: [],
      worldOrg: [],
      addedOrgList: [],
      noOrgAdded: false,
      page: 1,
      roles: [],
      totalWorlds: "",
      searchWorld: "",
      sortOrder: "desc",
      sortColumn: "id",
      searchStatus: false,
      addOrganisation: false,
      noCharacterAdded: false,
      editOrganization: false,
      editOrg: false,
      editOrgs: false,
      worldDetails: {},
      world_organizations_attributes: [],
      world_org_characters_attributes: [],
      isActive: false,
      settings: {
        maxScrollbarLength: false
      },
      url: utilFunctionService.getImageUrl()
    };
  },
  methods: {
    beforeOpen(event) {
      this.editOrg = false;
      this.editOrgs = false;
      this.editOrganization = false;
      this.editWorld(event.params.id, event.params.editOrg);
    },
    show(e) {
      this.$modal.show(e);
    },
    hide(e) {
      this.clearForm();
      this.$modal.hide(e);
    },
    getRole() {
      if (this.searchRole.length === 0) {
        this.roles = [];
      } else {
        api
          .getRoles(this.searchRole)
          .then(res => {
            this.roles = res.data.data;
          })
          .catch();
      }
    },
    setRole(i, characterId, id, name) {
      this.addedOrgList[i].attributes.organization_characters[
        characterId
      ].attributes.world_role_id = id;
      this.addedOrgList[i].attributes.organization_characters[
        characterId
      ].attributes.world_role.attributes.name = name;
    },
    editWorldDetails() {
      let org = this.addedOrgList;
      if (org.length === 0) {
        this.noOrgAdded = true;
      } else {
        this.noOrgAdded = false;

        this.world_organizations_attributes = this.addedOrgList.map(x => {
          let status = x.attributes.organization_characters
            .filter(x => x.attributes.characters.attributes.status == true)
            .map(y => {
              return {
                id: null,
                world_role_id: y.attributes.world_role_id,
                character_id: parseInt(y.attributes.characters.id)
              };
            });
          return status;
        });
        if (this.world_organizations_attributes[0].length > 0) {
          if (this.worldDetails.id) {
            this.world_organizations_attributes = this.addedOrgList.map(x => {
              let z = x.attributes.organization_characters.map(y => {
                if (
                  y.attributes.characters.attributes.status ||
                  y.attributes.characters.attributes.alreadyAdded
                ) {
                  return {
                    id: y.attributes.characters.attributes.alreadyAdded
                      ? parseInt(y.id)
                      : null,
                    world_role_id: parseInt(y.attributes.world_role_id),
                    character_id: parseInt(y.attributes.character_id),
                    _destroy: !y.attributes.characters.attributes.status
                  };
                }
              });
              return {
                id: x && x.alreadyAdded ? parseInt(x.id) : null,
                _destroy: false,
                organization_id: parseInt(x.attributes.organization_id)
                  ? parseInt(x.attributes.organization_id)
                  : parseInt(x.id),
                world_org_characters_attributes: z
              };
            });

            let worldType;
            if (this.type === "private") {
              worldType = true;
            } else {
              worldType = false;
            }

            api
              .editWorld(
                this.worldDetails.id,
                this.name,
                this.description,
                worldType,
                this.addedCustomer.id,
                this.world_organizations_attributes
              )
              .then(() => {
                this.$parent.watcher();
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.editWorld
                );
                this.hide("newWorld");
              })
              .catch();
          } else {
            return false;
          }
        } else {
          this.noCharacterAdded = true;
        }
      }
    },
    validateWorld() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          if (this.type == "private" && this.addedCustomer.id == undefined) {
            this.noCustomerAdded = true;
          } else {
            this.addOrganisation = !this.addOrganisation;
          }
        }
      });
    },
    cancel() {
      this.addOrganisation = !this.addOrganisation;
    },
    addOrg(e) {
      this.worldOrg = [];
      this.searchOrg = "";
      this.addedOrgList.push(e);
      if (this.addedOrgList.length === 0) {
        this.noOrgAdded = true;
      } else {
        this.noOrgAdded = false;
      }
    },
    removeOrg(i, worldOrgId) {
      this.addedOrgList.splice(i, 1);
      if (this.worldDetails.id) {
        let worldId = this.worldDetails.id;
        api.deleteWorldOrg(worldId, worldOrgId).then();
      }
    },
    searchOrganisation() {
      if (this.searchOrg.length === 0) {
        this.worldOrg = [];
      } else {
        api
          .getWorldOrganisation(this.searchOrg)
          .then(res => {
            this.worldOrg = res.data.data;
            if (this.addedOrgList.length > 0) {
              this.addedOrgList.forEach(element => {
                const index = this.worldOrg.findIndex(
                  elem => elem.attributes.name === element.attributes.name
                );
                if (index !== -1) {
                  this.worldOrg[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    addCustomer(e) {
      this.customer = "";
      this.customers = [];
      this.addedCustomer = e;
      this.noCustomerAdded = false;
    },
    removeCustomer() {
      if (this.worldDetails.id) {
        return;
      } else {
        this.addedCustomer = {};
        this.customers = [];
      }
    },
    searchCustomer() {
      if (this.customer.length === 0) {
        this.customers = [];
      } else {
        api
          .getCustomers(this.customer)
          .then(res => {
            this.customers = [];
            const customersList = res.data.data.map(item => {
              const customerObj = {};
              customerObj["name"] = item.attributes.name;
              customerObj["email"] = item.attributes.email;
              customerObj["id"] = item.id;
              customerObj["status"] = false;
              return customerObj;
            });
            if (this.addedCustomer && this.addedCustomer.id) {
              const index = customersList.findIndex(
                elem => elem.id === this.addedCustomer.id
              );
              if (index !== -1) {
                customersList[index].status = true;
              }
              this.customers = customersList;
            } else {
              this.customers = customersList;
            }
          })
          .catch();
      }
    },
    editWorld(id, status) {
      api
        .getWorldDetails(id)
        .then(res => {
          this.worldDetails = res.data.data;
          this.editWorldStatus = true;
          this.name = this.worldDetails.attributes.name;
          this.show("newWorld");
          this.description = this.worldDetails.attributes.description;
          this.editOrganization = status;
          this.editOrg = status;
          if (this.worldDetails.attributes.is_private) {
            this.type = "private";
          } else {
            this.type = "public";
          }
          if (
            this.worldDetails.attributes &&
            this.worldDetails.attributes.customer
          ) {
            this.addedCustomer.name = this.worldDetails.attributes.customer.attributes.name;
            this.addedCustomer.id = this.worldDetails.attributes.customer.id;
            this.addedCustomer.email = this.worldDetails.attributes.customer.attributes.email;
            this.addedCustomer.status = true;
          }

          this.addedOrgList = this.worldDetails.attributes.world_organizations.map(
            x => {
              x.attributes.name = x.attributes.organization.attributes.name;
              x.attributes.description =
                x.attributes.organization.attributes.description;
              x.attributes.industry_name =
                x.attributes.organization.attributes.industry_name;
              x.alreadyAdded = true;
              x.attributes.photo_url =
                x.attributes.organization.attributes.photo_url;
              x.attributes.organization_character = x.attributes.organization_character.map(
                y => {
                  y.attributes.characters.attributes.status = false;
                  return y;
                }
              );
              x.attributes.world_org_characters = x.attributes.world_org_characters.map(
                z => {
                  z.attributes.world_role_id = z.attributes.world_role.id;
                  z.attributes.characters.attributes.alreadyAdded = true;
                  z.attributes.characters.attributes.status = true;
                  delete z.attributes.character;
                  return z;
                }
              );
              let a = [
                ...x.attributes.organization_character,
                ...x.attributes.world_org_characters
              ];
              x.attributes = { ...x.attributes, organization_characters: a };
              delete x.attributes.world_org_characters;
              return x;
            }
          );
          if (this.addedOrgList.length) {
            this.editOrganization = status;
            this.editOrg = status;
          }
        })
        .catch();
    },
    clearForm() {
      this.name = "";
      this.description = "";
      this.addedCustomer = {};
      this.addOrganisation = false;
      this.addedOrgList = [];
      this.noCustomerAdded = false;
      this.noOrgAdded = false;
      this.noCharacterAdded = false;
      this.editWorldStatus = false;
      this.worldDetails = {};
    }
  }
};
</script>

<style lang="scss">
.customer-scroll-area {
  max-height: 180px;
}
.activePage {
  background-color: #007bff;
  border-color: #007bff;
}
</style>
