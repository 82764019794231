var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ContentDocument" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _c("div", { staticClass: "header-left" }, [
            _c("h2", [_vm._v("All Documents")]),
            _vm.uploadPercentages > 0
              ? _c("div", { staticClass: "progress-bar-overlay" }, [
                  _c(
                    "div",
                    { staticClass: "progress-bar-container" },
                    [
                      _vm.uploadPercentages > 0
                        ? _c("progress-bar", {
                            attrs: {
                              options: _vm.options,
                              value: _vm.getPercentage,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchDoc,
                      expression: "searchDoc",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "searchDoc",
                    "aria-describedby": "searchDoc",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchDoc },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.searchDoc = $event.target.value
                      },
                      _vm.searchDocument,
                    ],
                  },
                }),
              ]),
            ]),
            _c("input", {
              ref: "fileInput",
              staticStyle: { display: "none" },
              attrs: {
                type: "file",
                accept: "application/pdf",
                multiple: "multiple",
              },
              on: { change: _vm.onFileSelected },
            }),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$refs.fileInput.click()
                  },
                },
              },
              [_vm._v("Upload")]
            ),
          ]),
          _vm.totalDocs > 1 && !_vm.searchDoc
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalDocs) +
                    " Documents in the system."
                ),
              ])
            : _vm.totalDocs <= 1 && !_vm.searchDoc
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalDocs) +
                    " Document in the system."
                ),
              ])
            : _vm.totalDocs > 1 && _vm.searchDoc
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalDocs) +
                    " Documents in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalDocs) +
                    " Document in the search result."
                ),
              ]),
        ]),
        _vm.docLists.length !== 0
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", { staticClass: "data-table data-column-document" }, [
                _c("div", { staticClass: "data-head" }, [
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Name "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn ===
                              "active_storage_blobs.filename" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "active_storage_blobs.filename",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn ===
                              "active_storage_blobs.filename" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "active_storage_blobs.filename",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Created on "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Size "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn ===
                              "active_storage_blobs.byte_size" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "active_storage_blobs.byte_size",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn ===
                              "active_storage_blobs.byte_size" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "active_storage_blobs.byte_size",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Tags")]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
                ]),
                _c(
                  "div",
                  { staticClass: "data-items" },
                  _vm._l(_vm.docLists, function (doc) {
                    return _c("div", { key: doc.id, staticClass: "data-row" }, [
                      _c("div", { staticClass: "data-col" }, [
                        _c("div", { staticClass: "data-image" }, [
                          _c(
                            "div",
                            { staticClass: "data-pic default cursor" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show("pdfModal", {
                                        pdfUrl: doc.attributes.resource_url,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../assets/images/pdf-icon.png"),
                                      alt: "pdf",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "data-text cursor" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.show("pdfModal", {
                                      pdfUrl: doc.attributes.resource_url,
                                    })
                                  },
                                },
                              },
                              [_vm._v(_vm._s(doc.attributes.file_name))]
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(doc.attributes.created_on)),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(_vm.size(doc.attributes.resource_size))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "data-col" },
                        [
                          doc.attributes.tag_list
                            ? _c("span", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(doc.attributes.tag_list)),
                              ])
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "data-link dropdown-toggle",
                              attrs: {
                                href: "javascript:void(0);",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  doc.attributes.tag_list ? "Tag" : "Add Tag"
                                )
                              ),
                            ]
                          ),
                          _c("notesModal", {
                            attrs: {
                              doc: {
                                id: doc.id,
                                type: "document",
                                notes: doc.attributes.tag_list,
                                page: _vm.page,
                              },
                              method: _vm.getDoc,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "data-col data-action" }, [
                        _c(
                          "div",
                          { staticClass: "mr-3" },
                          [
                            _c(
                              "copy-to-clipboard",
                              {
                                attrs: { text: _vm.getshortCode(doc) },
                                on: { copy: _vm.copiedMessage },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "copy-icon",
                                    attrs: { href: "javascript:void(0);" },
                                  },
                                  [_vm._v("Get shortcode")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "data-delete-dropdown" }, [
                          _vm._m(0, true),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu dropdown-menu-right",
                            },
                            [
                              _c("div", { staticClass: "data-popper-title" }, [
                                _vm._v(
                                  "Are you sure you want to delete this document?"
                                ),
                              ]),
                              _c("div", { staticClass: "data-popper-footer" }, [
                                _c("a", { staticClass: "btn default" }, [
                                  _vm._v("cancel"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteDoc(doc.id)
                                      },
                                    },
                                  },
                                  [_vm._v("Ok")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (pages) {
                            return _c(
                              "li",
                              { key: pages, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    class: [pages === _vm.page ? "active" : ""],
                                    on: {
                                      click: function ($event) {
                                        return _vm.getDoc(pages)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(pages))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.docLists.length === 0 && !_vm.searchDoc && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no Documents in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.fileInput.click()
                      },
                    },
                  },
                  [_vm._v("Add a new Document")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.docLists.length === 0 && _vm.searchDoc
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c("pdfModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-delete" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }