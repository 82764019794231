<template>
  <div id="LearnerLOList">
    <div class="create-elm-container">
     <router-link to="/learning-modules" class="elm-back-btn">
        <span class="icon-back"></span>
      </router-link>
      <header class="elm-header">
        <div class="header-left">
            <h2 style="float:left;">{{assistantName}} - Interaction LOs</h2>
            <a
              href="javascript:void(0);"
              style="float:right;"
              class="btn btn-auto header-btn secondary ml-5"
              @click="openAssistantInstance()"
            >Link to Assistant Instance</a>
        </div>
      </header>

      <div class="data-content" >
        <div class="data-table data-column-5">
          <div class="data-head">
            
            <div class="data-col">
                Learning Object
            </div>

            <div class="data-col">
              Interaction Type
            </div>
            
            <div class="data-col">
              Link to Watson Dialog Skill
            </div>

            <div class="data-col">
              Last Backed Up at
            </div>

             <div class="data-col">
             
            </div>
            
          </div>
          <div class="data-items" v-for="learnObject in learnObjectsList" :key="learnObject.id">
            <div class="data-row">
              <div class="data-col">
                   <router-link :to="`/edit-elm/${elmId}?id=${learnObject.id}`">
                        {{learnObject.attributes.card_order}}: {{learnObject.attributes.name}}
                   </router-link>
              </div>
              <div class="data-col">{{learnObject.attributes.card_type | capitalize}}</div>
              <div class="data-col">
                  <a
                    href="javascript:void(0);"
                    @click="openDialogSkill(learnObject.attributes.dialog_skill_url)"
                    >{{learnObject.attributes.dialog_skill_name}}</a>
                  </div>
              <div class="data-col">{{learnObject.attributes.dialog_skill_backed_up_at}}</div>
              <div class="data-col">
                <button
                    @click="downloadBackUp(learnObject.attributes.dialog_skill_backup_url, learnObject.attributes.name)"
                    class="btn btn-auto secondary"
                     >Download Backup
                </button>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
      
    </div>
  </div>
  
</template>

<script>
  import api from "../services/api";
  import Axios from "axios";

  export default {
    name: "AssistantDetails",
    components: {},
    metaInfo: {
      title: "Assistant Details",
      htmlAttrs: {
        lang: "en",
        amp: true
      }
    },
     data() {
      return {
        settings: {
          maxScrollbarLength: false
        },
        elmId: '',
        learnObjectsList: [],
        assistantName: "",
        assistantUrl: ""
      };
    },
    methods: {
      openAssistantInstance() {
        window.open(this.assistantUrl, "_blank");
      },
      openDialogSkill(url){
        window.open(url, "_blank");
      },
       downloadBackUp(url, skillName) {
        if (url) {
            let fileName = `${skillName}_dialog_skill.json`
            Axios.get(url, { responseType: "blob" })
            .then(response => {
                const blob = new Blob([response.data], { type: "application/json" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', fileName);
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(console.error);
        }
       }
    },
    filters: {
    capitalize: function (value) {
        if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    created() {
     this.elmId = this.$route.params.id;
      api.getAssistantDetails(this.elmId)
      .then(res => {
           console.log(res.data.data.attributes.learning_obj_dialog_skills)
            this.assistantName = res.data.data.attributes.name;
            this.assistantUrl = res.data.data.attributes.assistant_url;
            this.learnObjectsList = res.data.data.attributes.learning_obj_dialog_skills;
          })
      .catch();
    }
  };
</script>