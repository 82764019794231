<template>
  <draggable 
    class="dragArea" 
    tag="ul" 
    :list="nested_skills"
    :group="{ name: 'skills' }" 
    @add="addParentId"
    @remove="removeChildID"
  >
    <li v-for="el in nested_skills" :key="el.name">
      <p>{{ el.skill_name }}</p>
      <p>{{ el.llm_skill_name }}</p>
      <nested-draggable :nested_skills="el.children" />
    </li>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';
import { EventBus } from "@/utils/eventBus";

export default {
  name: "nested-draggable",
  props: ['nested_skills'],
  components: {
    draggable
  },
  methods: {
    addParentId(event) {
      if (this.nested_skills) {
        EventBus.$emit('updateParentId', this.nested_skills[event.newIndex]);
      } else {
        EventBus.$emit('updateParentId', event.newIndex);
      }
      console.log("emitted");
    },
    removeChildID(event) {
      EventBus.$emit('removeChildID', event.newIndex);
      console.log("emitted");
    }
  }
};
</script>
<style scoped >
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
  padding: 8px;
}

.dragArea .dragArea {
  min-height: 50px;
  outline: 1px dashed;
  margin-left: 15px;
  padding: 8px;
}
</style>