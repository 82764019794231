var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "createCustomer",
        height: "auto",
        width: 500,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "edit-customer-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "edit-customer-page" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [
                  _vm.customer_id
                    ? _c("h5", [_vm._v("Edit Customer")])
                    : _c("h5", [_vm._v("Add New Customer")]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide("createCustomer")
                    },
                  },
                },
                [_c("em", { staticClass: "icon-close" })]
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "modal-form" }, [
                    _c("div", { staticClass: "edit-customer-image" }, [
                      _c(
                        "div",
                        {
                          staticClass: "organisation-image-pic cursor",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.fileInput.click()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "image-placeholder",
                            attrs: {
                              alt: "images",
                              src: require("../../assets/images/user-placeholder.svg"),
                            },
                          }),
                          _c("div", { staticClass: "uploaded-img" }, [
                            _vm.image
                              ? _c("img", {
                                  staticClass: "image-uploaded",
                                  attrs: { src: _vm.image, alt: "images" },
                                })
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "organisation-image-remove" }, [
                        _c("input", {
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "image/*" },
                          on: { change: _vm.onFileSelect },
                        }),
                        !_vm.image
                          ? _c(
                              "a",
                              {
                                staticClass: "btn add",
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.fileInput.click()
                                  },
                                },
                              },
                              [_vm._v("Add Photo")]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "btn delete",
                                on: { click: _vm.imageRemoved },
                              },
                              [_vm._v("Remove Photo")]
                            ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Name"),
                        ]),
                        _c("ValidationProvider", {
                          attrs: { name: "Name", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.customerName,
                                        expression: "customerName",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      id: "customerName",
                                      "aria-describedby": "emailHelp",
                                    },
                                    domProps: { value: _vm.customerName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.customerName = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { staticClass: "form-label" }, [
                              _vm._v("Select Faculty"),
                            ]),
                            _c(
                              "ValidationProvider",
                              { attrs: { name: "Faculty" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "quiz-dropdown customer-quiz-dropdown",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary dropdown-toggle",
                                        attrs: {
                                          type: "button",
                                          id: "entity",
                                          "data-toggle": "dropdown",
                                          "aria-haspopup": "true",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _vm._v(" " + _vm._s(_vm.faculty) + " "),
                                        _c("em", {
                                          staticClass: "icon-carrot-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: { "aria-labelledby": "entity" },
                                      },
                                      _vm._l(_vm.facultyLists, function (fac) {
                                        return _c(
                                          "option",
                                          {
                                            key: fac.name,
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectedFaculty(
                                                  fac.key
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(fac.name))]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                !_vm.faculty
                                  ? _c(
                                      "span",
                                      {
                                        class: [_vm.isActive ? "invalid" : ""],
                                        style: [
                                          _vm.isActive
                                            ? { display: "block" }
                                            : { display: "none" },
                                        ],
                                      },
                                      [_vm._v("Faculty field is required")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("Name of Faculty (or school or department)"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.facultyName,
                                expression: "facultyName",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "name",
                              "aria-describedby": "emailHelp",
                            },
                            domProps: { value: _vm.facultyName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.facultyName = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("Shortcode"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.shortCode,
                                expression: "shortCode",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "shortCode",
                              "aria-describedby": "emailHelp",
                              disabled: true,
                            },
                            domProps: { value: _vm.shortCode },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.shortCode = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { staticClass: "form-label" }, [
                              _vm._v("Customer Type"),
                            ]),
                            _c(
                              "ValidationProvider",
                              { attrs: { name: "Customer type" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "quiz-dropdown customer-quiz-dropdown",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary dropdown-toggle",
                                        attrs: {
                                          type: "button",
                                          id: "entity",
                                          "data-toggle": "dropdown",
                                          "aria-haspopup": "true",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.customerType.name) +
                                            " "
                                        ),
                                        _c("em", {
                                          staticClass: "icon-carrot-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: { "aria-labelledby": "entity" },
                                      },
                                      _vm._l(
                                        _vm.customerTypes,
                                        function (type) {
                                          return _c(
                                            "option",
                                            {
                                              key: type.id,
                                              staticClass: "dropdown-item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectedCustomerType(
                                                    type
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(type.name))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                !_vm.customerType.name
                                  ? _c(
                                      "span",
                                      {
                                        class: [_vm.isActive ? "invalid" : ""],
                                        style: [
                                          _vm.isActive
                                            ? { display: "block" }
                                            : { display: "none" },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "Customer type field is required"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "customer-tab-section" }, [
                      _c("ul", { staticClass: "tab-section" }, [
                        _c(
                          "li",
                          {
                            class: _vm.tab === "address" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("address")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "address"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Address")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: _vm.tab === "online" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("online")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "online"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Online")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: _vm.tab === "contact" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("contact")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "contact"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Contacts")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class:
                              _vm.tab === "instructor" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("instructor")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "instructor"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Instructor(s)")]
                            ),
                          ]
                        ),
                      ]),
                      _vm.tab === "address"
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "address" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Address 1"),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Address",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.address1,
                                                    expression: "address1",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  id: "address1",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                  maxlength: "150",
                                                },
                                                domProps: {
                                                  value: _vm.address1,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.address1 =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3815285706
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Address 2"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.address2,
                                      expression: "address2",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "address2",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.address2 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.address2 = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Country"),
                                  ]),
                                  _c("country-select", {
                                    attrs: {
                                      country: _vm.country,
                                      topCountry: "US",
                                    },
                                    model: {
                                      value: _vm.country,
                                      callback: function ($$v) {
                                        _vm.country = $$v
                                      },
                                      expression: "country",
                                    },
                                  }),
                                  _c("div", { staticClass: "dropdown-arrow" }, [
                                    _c("img", {
                                      staticClass: "dropdown-img",
                                      attrs: {
                                        alt: "images",
                                        src: require("../../assets/images/svg/carrot-down.svg"),
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Province"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.province,
                                          expression: "province",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "province",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.province },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.province = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Postal Code"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.postalCode,
                                          expression: "postalCode",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "postalCode",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.postalCode },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.postalCode = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Main Phone"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.addressPhone,
                                      expression: "addressPhone",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    id: "addressPhone",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.addressPhone },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.addressPhone = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Admin Notes"),
                                ]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.note,
                                      expression: "note",
                                    },
                                  ],
                                  staticClass:
                                    "form-control admin-note-textarea",
                                  attrs: { maxlength: "500" },
                                  domProps: { value: _vm.note },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.note = $event.target.value
                                    },
                                  },
                                }),
                                _c("div", { staticClass: "form-characters" }, [
                                  _vm._v("500 characters"),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm.tab === "online"
                      ? _c("div", { staticClass: "customer-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "online" },
                            },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Website"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.website,
                                      expression: "website",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.website },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.website = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Linkedin"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.linkedIn,
                                      expression: "linkedIn",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.linkedIn },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.linkedIn = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Twitter"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.twitter,
                                      expression: "twitter",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.twitter },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.twitter = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Facebook"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.facebook,
                                      expression: "facebook",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.facebook },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.facebook = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Instagram"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.instagram,
                                      expression: "instagram",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "name",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.instagram },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.instagram = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Youtube"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.youtube,
                                      expression: "youtube",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    "aria-describedby": "emailHelp",
                                  },
                                  domProps: { value: _vm.youtube },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.youtube = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.tab === "contact"
                      ? _c("div", { staticClass: "customer-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "contact" },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "form-label" },
                                        [_vm._v("First Name")]
                                      ),
                                      _c(
                                        "ValidationProvider",
                                        {
                                          attrs: {
                                            name: "First Name",
                                            rules: "required",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.contactFirstName,
                                                expression: "contactFirstName",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.firstNameRequired
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "text",
                                              id: "contactFirstName",
                                              "aria-describedby": "emailHelp",
                                            },
                                            domProps: {
                                              value: _vm.contactFirstName,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.contactFirstName =
                                                    $event.target.value
                                                },
                                                function ($event) {
                                                  return _vm.getFirstName()
                                                },
                                              ],
                                            },
                                          }),
                                          _vm.firstNameRequired
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.firstNameRequired
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.firstNameRequired
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "First name is required"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "form-label" },
                                        [_vm._v("Last Name")]
                                      ),
                                      _c(
                                        "ValidationProvider",
                                        {
                                          attrs: {
                                            name: "Last Name",
                                            rules: "required",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.contactLastName,
                                                expression: "contactLastName",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.lastNameRequired
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "text",
                                              id: "contactLastName",
                                              "aria-describedby": "emailHelp",
                                            },
                                            domProps: {
                                              value: _vm.contactLastName,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.contactLastName =
                                                    $event.target.value
                                                },
                                                function ($event) {
                                                  return _vm.getLastName()
                                                },
                                              ],
                                            },
                                          }),
                                          _vm.lastNameRequired
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.lastNameRequired
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.lastNameRequired
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "Last name is required"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Email"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.contactEmail,
                                          expression: "contactEmail",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        "aria-describedby": "emailHelp",
                                      },
                                      domProps: { value: _vm.contactEmail },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.contactEmail = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Phone"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.contactPhone,
                                          expression: "contactPhone",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        id: "name",
                                        "aria-describedby": "phoneHelp",
                                      },
                                      domProps: { value: _vm.contactPhone },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.contactPhone = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v("Role"),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "quiz-dropdown customer-quiz-dropdown",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-secondary dropdown-toggle",
                                        attrs: {
                                          type: "button",
                                          id: "entity",
                                          "data-toggle": "dropdown",
                                          "aria-haspopup": "true",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedRole.attributes.name
                                            ) +
                                            " "
                                        ),
                                        _c("em", {
                                          staticClass: "icon-carrot-down",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: { "aria-labelledby": "entity" },
                                      },
                                      _vm._l(_vm.roles, function (role) {
                                        return _c(
                                          "option",
                                          {
                                            key: role.id,
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectContactRole(
                                                  role
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(role.attributes.name))]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "contact-list-section" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "add-content-btn" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn secondary",
                                          attrs: { type: "button" },
                                          on: { click: _vm.addContact },
                                        },
                                        [_vm._v(" Add Contact ")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "list-container" },
                                    [
                                      _vm.contactList.length > 0
                                        ? _c(
                                            "h2",
                                            { staticClass: "title-text" },
                                            [
                                              _vm._v(
                                                "Contact List (" +
                                                  _vm._s(
                                                    _vm.contactList.length
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.contactList,
                                        function (contact, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: contact.id,
                                              staticClass: "list-box",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "left-section" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "contact-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          contact.firstName
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            contact.lastName
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "degination",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          contact.contactRole
                                                            .name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "email" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(contact.email) +
                                                          " "
                                                      ),
                                                      contact.phone
                                                        ? _c("span", [
                                                            _vm._v("|"),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            contact.phone
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "right-section",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "remove-link",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeContact(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Remove")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.tab === "instructor"
                      ? _c("div", { staticClass: "customer-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "instructor" },
                            },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "exampleInputEmail1" },
                                  },
                                  [_vm._v("Instructor")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.searchCharacters,
                                      expression: "searchCharacters",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Search Instructor...",
                                  },
                                  domProps: { value: _vm.searchCharacters },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.searchCharacters =
                                          $event.target.value
                                      },
                                      _vm.getCharacters,
                                    ],
                                  },
                                }),
                                _vm.charactersList.length
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          class: [
                                            _vm.charactersList.length !== 0
                                              ? "show"
                                              : "",
                                          ],
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "dropdown-list" },
                                            _vm._l(
                                              _vm.charactersList,
                                              function (characters) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: characters.id,
                                                    staticClass:
                                                      "dropdown-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-content",
                                                      },
                                                      [
                                                        _c("h4", [
                                                          _vm._v(
                                                            _vm._s(
                                                              characters
                                                                .attributes
                                                                .first_name
                                                            ) +
                                                              " " +
                                                              _vm._s(
                                                                characters
                                                                  .attributes
                                                                  .last_name
                                                              )
                                                          ),
                                                        ]),
                                                        _c("h4", [
                                                          _vm._v(
                                                            _vm._s(
                                                              characters
                                                                .attributes
                                                                .email
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    !characters.status
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-action",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "add-action",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.addCharacters(
                                                                        characters
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("+")]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    characters.status
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-action",
                                                          },
                                                          [
                                                            _c("em", {
                                                              staticClass:
                                                                "icon-check",
                                                            }),
                                                            _c("span", [
                                                              _vm._v("Added"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "contact-list-section" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "list-container" },
                                    [
                                      _vm.addedCharacters.length > 0
                                        ? _c(
                                            "h2",
                                            { staticClass: "title-text" },
                                            [
                                              _vm._v(
                                                "Instructors (" +
                                                  _vm._s(
                                                    _vm.addedCharacters.length
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.addedCharacters,
                                        function (instr, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: instr.id,
                                              staticClass: "list-box",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "left-section" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "contact-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(instr.firsName) +
                                                          " " +
                                                          _vm._s(instr.lastName)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "email" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(instr.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "right-section",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "remove-link",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeCharcter(
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Remove")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: { click: _vm.hide },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: { click: _vm.createCustomers },
                    },
                    [_vm._v("Save")]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }