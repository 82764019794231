var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "WatsonAdmin" } }, [
    _c("div", { staticClass: "container" }, [
      _c("header", { staticClass: "header" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "header-right" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary",
                attrs: { to: "/create-llm-skill" },
              },
              [_vm._v("Create Skill")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "content-container" }, [
        _vm.llm_skills.length
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", { staticClass: "data-table data-column-skills" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "data-items" },
                  _vm._l(_vm.llm_skills, function (llm_skill) {
                    return _c(
                      "div",
                      { key: llm_skill.id, staticClass: "data-row" },
                      [
                        _c("div", { staticClass: "data-col" }, [
                          _vm._v(_vm._s(llm_skill.attributes.name)),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          _vm._v(_vm._s(llm_skill.attributes.description)),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          _c("ul", { staticClass: "btn-container" }, [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "edit-llm-skill",
                                        params: {
                                          id: llm_skill.id,
                                          llm_skill_data: llm_skill,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      { staticClass: "btn btn-edit" },
                                      [_vm._v("Edit")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("li", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteLlmSkill(llm_skill.id)
                                    },
                                  },
                                },
                                [_vm._v("Delete")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _c("div", { staticClass: "data-content" }, [
              _c("h1", [_vm._v("No Skills created")]),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("LLM Skills")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-head" }, [
      _c("div", { staticClass: "data-col col-title" }, [
        _vm._v(" Skill Name "),
      ]),
      _c("div", { staticClass: "data-col col-title" }, [
        _vm._v(" Description "),
      ]),
      _c("div", { staticClass: "data-col col-title" }, [_vm._v(" Actions ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }