<template>
    <modal name="deleteLlmSkillSetModal" :width="400" :height="400" :adaptive="true">
        <div class="modal-content modal-default">
            <h3>
                Are you sure you want to delete this LLM Skill Set?
            </h3>
            <button @click="deleteLlmSkillSet">Yes</button>
            <button @click="closeModal">No</button>
        </div>
    </modal>
</template>
<script>
import api from "../../services/api";
import { utilFunctionService } from "../../utils/utils.service";
export default {
    name: 'DeleteLlmSkillSetModal', 
    props: {
        llm_skill_set_id: {
            type: Number,
            required: true
        }
    },
    methods: {
        closeModal() {
            this.$modal.hide('deleteLlmSkillSetModal')
        },
        deleteLlmSkillSet() {
            utilFunctionService.showLoader();
            let payload = {
                id: this.llm_skill_id
            }
            api.deleteLlmSkillSet(payload, this).then((response) => {
                this.llm_skills = response.data;
                this.$router.push("/llm-skills");
                utilFunctionService.hideLoader();
            })
        }
    }
}
</script>
<style lang="scss">
</style>