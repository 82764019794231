var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Assessment" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.show("addScheme")
                  },
                },
              },
              [_vm._v("Add Scheme")]
            ),
          ]),
          _c("p", [
            _vm._v(
              "You have " +
                _vm._s(_vm.schemeLists.length) +
                " Assessment Schemes in the system."
            ),
          ]),
        ]),
        _c("div", { staticClass: "data-content" }, [
          _c(
            "div",
            { staticClass: "data-table data-column-3" },
            [
              _vm._m(1),
              _vm._l(_vm.schemeLists, function (scheme, index) {
                return _c(
                  "div",
                  { key: scheme.id, staticClass: "data-items" },
                  [
                    _c("div", { staticClass: "data-row" }, [
                      _c("div", { staticClass: "data-col" }, [
                        _c("div", [_vm._v(_vm._s(scheme.attributes.name))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "data-col" },
                        _vm._l(
                          scheme.attributes.assessment_labels,
                          function (schemeLabel) {
                            return _c(
                              "div",
                              {
                                key: schemeLabel.id,
                                staticClass: "data-assement-icons",
                                attrs: { "track-by": "id" },
                              },
                              [
                                schemeLabel.attributes.icon_url
                                  ? _c("div", { staticClass: "data-icons" }, [
                                      _c("img", {
                                        attrs: {
                                          src: schemeLabel.attributes.icon_url,
                                          alt: "strong",
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(schemeLabel.attributes.name) +
                                    " "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _c("div", { staticClass: "data-col data-action" }, [
                        _c("div", { staticClass: "data-dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editScheme(scheme.id, index)
                                },
                              },
                            },
                            [_c("em", { staticClass: "icon-edit" })]
                          ),
                        ]),
                        _c("div", { staticClass: "data-delete-dropdown" }, [
                          _vm._m(2, true),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu dropdown-menu-right",
                            },
                            [
                              _c("div", { staticClass: "data-popper-title" }, [
                                _vm._v(
                                  "Are you sure you want to delete this Assessment Scheme?"
                                ),
                              ]),
                              _c("div", { staticClass: "data-popper-footer" }, [
                                _c("a", { staticClass: "btn default" }, [
                                  _vm._v("cancel"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteAssessment(scheme.id)
                                      },
                                    },
                                  },
                                  [_vm._v("Ok")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c("div", { staticClass: "data-footer" }, [
            _c("div", { attrs: { "aria-label": "Page navigation example" } }, [
              _c(
                "ul",
                { staticClass: "pagination" },
                [
                  _vm.page !== 1
                    ? _c("li", { staticClass: "page-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "page-link",
                            attrs: { "aria-label": "Previous" },
                            on: { click: _vm.prevPage },
                          },
                          [_c("em", { staticClass: "icon-prev" })]
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                    function (pages) {
                      return _c(
                        "li",
                        { key: pages, staticClass: "page-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "page-link",
                              class: [pages === _vm.page ? "active" : ""],
                              on: {
                                click: function ($event) {
                                  return _vm.getAssessment(pages)
                                },
                              },
                            },
                            [_vm._v(_vm._s(pages))]
                          ),
                        ]
                      )
                    }
                  ),
                  _vm.page < _vm.totalPages.length
                    ? _c("li", { staticClass: "page-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "page-link",
                            attrs: { "aria-label": "Next" },
                            on: { click: _vm.nextPage },
                          },
                          [_c("em", { staticClass: "icon-next" })]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm.schemeLists.length === 0 && !_vm.searchSchemes && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no Assessment in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.show("addScheme")
                      },
                    },
                  },
                  [_vm._v("Add a Assessment")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.schemeLists.length === 0 && _vm.searchSchemes
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "addScheme",
            width: 500,
            height: "auto",
            scrollable: true,
          },
        },
        [
          _c("div", {
            staticClass: "hide-modal",
            on: {
              click: function ($event) {
                return _vm.cancelModal(false)
              },
            },
          }),
          _c("div", { staticClass: "modal-content scheme-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [
                  _c("h5", [
                    _vm._v(
                      _vm._s(_vm.isEditScheme ? "Edit" : "Create new") +
                        " assessment scheme"
                    ),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-body mt-3" },
              [
                _c("ValidationObserver", { ref: "form" }, [
                  _c(
                    "form",
                    { staticClass: "modal-form scheme-form" },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("Scheme Name"),
                          ]),
                          _c("ValidationProvider", {
                            attrs: { name: "Name", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.schemeName,
                                          expression: "schemeName",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: [
                                        _vm.isActive && errors[0]
                                          ? "invalid"
                                          : "",
                                      ],
                                      attrs: {
                                        type: "text",
                                        id: "name",
                                        maxlength: "30",
                                      },
                                      domProps: { value: _vm.schemeName },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.schemeName = $event.target.value
                                        },
                                      },
                                    }),
                                    errors[0]
                                      ? _c(
                                          "span",
                                          {
                                            class: [
                                              _vm.isActive ? "invalid" : "",
                                            ],
                                            style: [
                                              _vm.isActive
                                                ? { display: "block" }
                                                : { display: "none" },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                errors[0].replace("The ", "")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group-list" },
                        [
                          _c("div", { staticClass: "form-group-heading" }, [
                            _vm._v("Evaluation Label"),
                          ]),
                          _c("label", { staticClass: "form-label mb-3" }, [
                            _vm._v(
                              "(Highest assessment level label should be first; lowest should be last)"
                            ),
                          ]),
                          _c(
                            "draggable",
                            {
                              on: { change: _vm.onUnpublishedChange },
                              model: {
                                value: _vm.inputs,
                                callback: function ($$v) {
                                  _vm.inputs = $$v
                                },
                                expression: "inputs",
                              },
                            },
                            _vm._l(_vm.inputs, function (input, index) {
                              return _c(
                                "div",
                                { key: input.id, staticClass: "inputArea" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group row",
                                      attrs: { for: input.id },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: input.order,
                                            expression: "input.order",
                                          },
                                        ],
                                        staticClass:
                                          "form-control form-control-too-small mr-4",
                                        attrs: {
                                          id: "order",
                                          type: "number",
                                          disabled: "true",
                                        },
                                        domProps: { value: input.order },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              input,
                                              "order",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "form-group-scheme" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "scheme-placeholder",
                                            },
                                            [
                                              _c("ValidationProvider", {
                                                attrs: { rules: "required" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  input.name,
                                                                expression:
                                                                  "input.name",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            class: [
                                                              (_vm.isActive &&
                                                                errors[0]) ||
                                                              _vm.isError
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              id: "Label Name",
                                                              type: "text",
                                                              placeholder:
                                                                "Label Name",
                                                            },
                                                            domProps: {
                                                              value: input.name,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  input,
                                                                  "name",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          errors[0] ||
                                                          _vm.isError
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  class: [
                                                                    _vm.isActive
                                                                      ? "invalid"
                                                                      : "",
                                                                  ],
                                                                  style: [
                                                                    _vm.isActive
                                                                      ? {
                                                                          display:
                                                                            "block",
                                                                        }
                                                                      : {
                                                                          display:
                                                                            "none",
                                                                        },
                                                                  ],
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.isError
                                                                        ? "At least one label should be there for a scheme."
                                                                        : errors[0].replace(
                                                                            "The ",
                                                                            ""
                                                                          )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          !input.url
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "scheme-upload",
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "upload",
                                                    attrs: {
                                                      type: "file",
                                                      id: index,
                                                      accept:
                                                        "image/x-png,image/gif,image/jpeg",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onFileChange(
                                                          $event,
                                                          input.labelId
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v("+ Select Icon"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          input.url
                                            ? _c(
                                                "div",
                                                { staticClass: "scheme-icon" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        _vm.isEditScheme &&
                                                        !input.icon
                                                          ? input.url
                                                          : input.url,
                                                      alt: "mastery",
                                                    },
                                                  }),
                                                  _c("input", {
                                                    staticClass: "upload",
                                                    attrs: {
                                                      type: _vm.isEditScheme
                                                        ? "text"
                                                        : "file",
                                                      id: index,
                                                      accept:
                                                        "image/x-png,image/gif,image/jpeg",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onFileChange(
                                                          $event,
                                                          input.labelId
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          input.name
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "scheme-delete",
                                                },
                                                [
                                                  _c("em", {
                                                    staticClass: "icon-close",
                                                    attrs: { id: input.id },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteLabel(
                                                          $event,
                                                          input.labelId,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            !_vm.isEditScheme
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "add-scheme-btn",
                                    attrs: { href: "#" },
                                    on: { click: _vm.addLabel },
                                  },
                                  [_vm._v("+ Add More Labels")]
                                )
                              : _vm._e(),
                          ]),
                          _vm.isLabelDragged
                            ? _c("label", [
                                _vm._v(
                                  "Note: you have to click save to see the updated order."
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "scheme-footer modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn default",
                            attrs: { type: "button", "data-dismiss": "modal" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelModal(false)
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn primary",
                            attrs: {
                              type: "button",
                              "data-dismiss": "modal",
                              "data-toggle": "modal",
                            },
                            on: { click: _vm.createScheme },
                          },
                          [_vm._v(_vm._s(_vm.isEditScheme ? "Save" : "Create"))]
                        ),
                      ]),
                      _c(
                        "modal",
                        {
                          attrs: {
                            name: "deleteWorldModal",
                            height: "auto",
                            width: 350,
                            scrollable: true,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "modal-content modal-delete-content",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "modal-body modal-delete-body" },
                                [
                                  _vm._v(
                                    "Are you sure you want to discard this?"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "modal-footer justify-content-center",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn medium default",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$modal.hide(
                                            "deleteWorldModal"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn medium primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelModal(true)
                                        },
                                      },
                                    },
                                    [_vm._v("Ok")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("Assessment Schemes")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-head" }, [
      _c("div", { staticClass: "data-col" }, [_vm._v("Name")]),
      _c("div", { staticClass: "data-col" }, [_vm._v("Labels")]),
      _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-delete" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }