<template>
  <modal
    name="createCustomer"
    height="auto"
    :width="500"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="edit-customer-dialog">
      <div class="modal-content">
        <div class="edit-customer-page">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
              <h5 v-if="customer_id">Edit Customer</h5>
              <h5 v-else>Add New Customer</h5>
            </div>
            <button type="button" class="close" @click="hide('createCustomer')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="form">
              <form class="modal-form">
                <div class="edit-customer-image">
                    <div class="organisation-image-pic cursor"  @click="$refs.fileInput.click()">
                        <img alt="images" class="image-placeholder" src="../../assets/images/user-placeholder.svg" />
                        <div class="uploaded-img">
                          <img :src="image" v-if="image" class="image-uploaded" alt="images"/>
                        </div>
                    </div>
                    <div class="organisation-image-remove">
                        <input style="display: none;" ref="fileInput" type="file" @change="onFileSelect" accept="image/*"/>
                        <a class="btn add" @click="$refs.fileInput.click()" v-if="!image">Add Photo</a>
                        <a class="btn delete" @click="imageRemoved" v-else>Remove Photo</a>
                    </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Name</label>
                  <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="customerName"
                      aria-describedby="emailHelp"
                      v-model="customerName"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                     <label class="form-label">Select Faculty</label>
                       <ValidationProvider name="Faculty">
                        <div class="quiz-dropdown customer-quiz-dropdown">
                          <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="entity"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                          >
                          {{faculty}}
                              <em class="icon-carrot-down"></em>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="entity">
                              <option
                              class="dropdown-item"
                              @click="selectedFaculty(fac.key)"
                              v-for="fac of facultyLists"
                              :key="fac.name"
                              >{{fac.name}}</option>
                          </div>
                        </div>
                      <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="!faculty"
                        >Faculty field is required</span>
                      </ValidationProvider>
                  </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label">Name of Faculty (or school or department)</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          aria-describedby="emailHelp"
                          v-model="facultyName"
                        />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label">Shortcode</label>
                        <input
                          type="text"
                          class="form-control"
                          id="shortCode"
                          aria-describedby="emailHelp"
                          v-model="shortCode"
                          :disabled="true"
                        />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label">Customer Type</label>
                          <ValidationProvider name="Customer type">
                            <div class="quiz-dropdown customer-quiz-dropdown">
                              <button
                                  class="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="entity"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                              >
                                {{customerType.name}}
                                  <em class="icon-carrot-down"></em>
                              </button>
                              <div class="dropdown-menu" aria-labelledby="entity">
                                  <option
                                  class="dropdown-item"
                                  @click="selectedCustomerType(type)"
                                  v-for="type of customerTypes"
                                  :key="type.id"
                                  >{{type.name}}</option>
                              </div>
                              </div>
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="!customerType.name"
                            >Customer type field is required</span>
                          </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="customer-tab-section">
                  <ul class="tab-section">
                    <li :class="tab === 'address' ? 'tab active' : 'tab'" @click="setTab('address')">
                      <a :class="tab === 'address' ? 'link link-active' : 'link'">Address</a>
                    </li>
                    <li :class="tab === 'online' ? 'tab active' : 'tab'" @click="setTab('online')">
                      <a :class="tab === 'online' ? 'link link-active' : 'link'">Online</a>
                    </li>
                    <li :class="tab === 'contact' ? 'tab active' : 'tab'" @click="setTab('contact')">
                      <a :class="tab === 'contact' ? 'link link-active' : 'link'">Contacts</a>
                    </li>
                    <li :class="tab === 'instructor' ? 'tab active' : 'tab'" @click="setTab('instructor')">
                      <a :class="tab === 'instructor' ? 'link link-active' : 'link'">Instructor(s)</a>
                    </li>
                  </ul>
                  <div class="tab-detail-show" id="address" v-if="tab === 'address'">
                    <div class="form-group">
                      <label class="form-label">Address 1</label>
                       <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                        <input
                          type="text"
                          class="form-control"
                          id="address1"
                          aria-describedby="emailHelp"
                          v-model="address1"
                          maxlength="150"
                          :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        />
                         <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label class="form-label">Address 2</label>
                        <input
                          type="text"
                          class="form-control"
                          id="address2"
                          aria-describedby="emailHelp"
                          v-model="address2"
                        />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Country</label>
                       <country-select v-model="country" :country="country" topCountry="US" />
                      <div class="dropdown-arrow">
                        <img alt="images" class="dropdown-img" src="../../assets/images/svg/carrot-down.svg" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Province</label>
                            <input
                              type="text"
                              class="form-control"
                              id="province"
                              aria-describedby="emailHelp"
                              v-model="province"
                            />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Postal Code</label>
                            <input
                              type="text"
                              class="form-control"
                              id="postalCode"
                              aria-describedby="emailHelp"
                              v-model="postalCode"
                            />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label">Main Phone</label>
                        <input
                          type="number"
                          class="form-control"
                          id="addressPhone"
                          aria-describedby="emailHelp"
                          v-model="addressPhone"
                        />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Admin Notes</label>
                      <textarea class="form-control admin-note-textarea" v-model="note" maxlength="500"></textarea>
                      <div class="form-characters">500 characters</div>
                    </div>
                  </div>
                </div>
                 <div class="customer-tab-section" v-if="tab === 'online'">
                  <div class="tab-detail-show" id="online">
                    <div class="form-group">
                      <label class="form-label">Website</label>
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="emailHelp"
                          v-model="website"
                        />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Linkedin</label>
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="emailHelp"
                          v-model="linkedIn"
                        />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Twitter</label>
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="emailHelp"
                          v-model="twitter"
                        />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Facebook</label>
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="emailHelp"
                          v-model="facebook"
                        />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Instagram</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          aria-describedby="emailHelp"
                          v-model="instagram"
                        />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Youtube</label>
                        <input
                          type="text"
                          class="form-control"
                          aria-describedby="emailHelp"
                          v-model="youtube"
                        />
                    </div>
                  </div>
                </div>
                <div class="customer-tab-section" v-if="tab === 'contact'">
                  <div class="tab-detail-show" id="contact">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">First Name</label>
                          <ValidationProvider name="First Name" rules="required">
                            <input
                              type="text"
                              class="form-control"
                              id="contactFirstName"
                              aria-describedby="emailHelp"
                              v-model="contactFirstName"
                              @input="getFirstName()"
                              :class="[firstNameRequired ? 'invalid' : '']"
                            />
                            <span
                              :class="[firstNameRequired ? 'invalid' : '']"
                              :style="[firstNameRequired ? {'display': 'block'} : {'display': 'none'}]"
                               v-if="firstNameRequired"
                            >First name is required</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Last Name</label>
                           <ValidationProvider name="Last Name" rules="required">
                              <input
                                type="text"
                                class="form-control"
                                id="contactLastName"
                                aria-describedby="emailHelp"
                                v-model="contactLastName"
                                @input="getLastName()"
                                :class="[lastNameRequired ? 'invalid' : '']"
                              />
                            <span
                              :class="[lastNameRequired ? 'invalid' : '']"
                              :style="[lastNameRequired ? {'display': 'block'} : {'display': 'none'}]"
                               v-if="lastNameRequired"
                            >Last name is required</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Email</label>
                            <input
                              type="text"
                              class="form-control"
                              aria-describedby="emailHelp"
                              v-model="contactEmail"
                            />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Phone</label>
                            <input
                              type="number"
                              class="form-control"
                              id="name"
                              aria-describedby="phoneHelp"
                              v-model="contactPhone"
                            />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label">Role</label>
                        <div class="quiz-dropdown customer-quiz-dropdown">
                          <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="entity"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                          >
                          {{selectedRole.attributes.name}}
                              <em class="icon-carrot-down"></em>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="entity">
                              <option
                              class="dropdown-item"
                              @click="selectContactRole(role)"
                              v-for="role of roles"
                              :key="role.id"
                              >{{role.attributes.name}}</option>
                          </div>
                        </div>
                    </div>
                    <div class="contact-list-section">
                      <div class="add-content-btn">
                        <button type="button" class="btn secondary" @click="addContact">
                          Add Contact
                        </button>
                      </div>
                      <div class="list-container">
                        <h2 class="title-text" v-if="contactList.length > 0">Contact List ({{contactList.length}})</h2>
                        <div class="list-box" v-for="(contact, index) of contactList" :key="contact.id">
                          <div class="left-section">
                            <h3 class="contact-text">{{contact.firstName}} {{contact.lastName}}</h3>
                            <p class="degination">{{contact.contactRole.name}}</p>
                            <p class="email">{{contact.email}} <span v-if="contact.phone">|</span> {{contact.phone}} </p>
                          </div>
                          <div class="right-section">
                          <a class="remove-link" @click="removeContact(index)">Remove</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="customer-tab-section" v-if="tab === 'instructor'">
                  <div class="tab-detail-show" id="instructor">
                     <div class="form-group">
                      <label class="form-label" for="exampleInputEmail1">Instructor</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search Instructor..."
                        v-model="searchCharacters"
                        v-on:input="getCharacters"
                      />
                      <div v-if="charactersList.length">
                        <div class="dropdown-menu" :class="[charactersList.length !== 0 ? 'show' : '']">
                          <ul class="dropdown-list">
                            <li
                              class="dropdown-items"
                              v-for="characters in charactersList"
                              :key="characters.id"
                            >
                              <div class="dropdown-content">
                                <h4>{{characters.attributes.first_name}} {{characters.attributes.last_name}}</h4>
                                 <h4>{{characters.attributes.email}}</h4>
                              </div>
                              <div class="dropdown-action" v-if="!characters.status">
                                <a @click="addCharacters(characters)" class="add-action">+</a>
                              </div>
                              <div class="dropdown-action" v-if="characters.status">
                                <em class="icon-check"></em>
                                <span>Added</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="contact-list-section">
                      <div class="list-container">
                        <h2 class="title-text" v-if="addedCharacters.length > 0">Instructors ({{addedCharacters.length}})</h2>
                        <div class="list-box" v-for="(instr,i) of addedCharacters" :key="instr.id">
                          <div class="left-section">
                            <h3 class="contact-text">{{instr.firsName}} {{instr.lastName}}</h3>
                            <!-- <p class="degination">Sr. Product Manager</p> -->
                            <p class="email">{{instr.email}}</p>
                          </div>
                          <div class="right-section">
                          <a class="remove-link" @click="removeCharcter(i)">Remove</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
            <div class="modal-footer">
              <button type="button" class="btn default" @click="hide">Cancel</button>
              <button type="button" class="btn primary" @click="createCustomers">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "createCustomer",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      selectedFile: null,
      image: null,
      name: "",
      description: "",
      searchCharacters: "",
      charactersList: [],
      addedCharacters: [],
      removeCharacters: [],
      searchRole: "",
      characters: [],
      roles: [],
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      customer_id: "",
      showRole: "",
      addressPhone: "",
      postalCode: "",
      province: "",
      address1: "",
      address2: "",
      country: "",
      shortCode: "",
      customerType: "",
      customerName: "",
      faculty: "",
      facultyName: "",
      facultyLists: [{
          'name' : 'None', 'key' : 'none'},{
          'name' : 'Faculty', 'key' : 'faculty'},{
          'name' : 'School', 'key' : 'school'},{
          'name' : 'Department', 'key' : 'department'
      }],
      customerTypes: [],
      note: '',
      tab: 'address',
      website: "",
      linkedIn: "",
      twitter: "",
      facebook: "",
      youtube: "",
      instagram: "",
      email: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      selectedRole: {'attributes': {
        'name': ''
      }},
      contactList: [],
      removeContacts : [],
      firstNameRequired: false,
      lastNameRequired: false
    };
  },
  methods: {
    getFirstName() {
      if (this.contactFirstName.length > 0) {
        this.firstNameRequired = false
      }
    },
    getLastName() {
      if (this.contactLastName.length > 0) {
        this.lastNameRequired = false
      }
    },
    removeContact(i) {
      if (this.contactList[i].id) {
        this.removeContacts.push(this.contactList[i])
      }
      this.contactList.splice(i,1);
    },
    addContact() {
      if (this.contactFirstName == "" || this.contactFirstName == null) {
        this.firstNameRequired = true;
      } else if (this.contactLastName == "" || this.contactLastName == null) {
        this.lastNameRequired = true;
      } else {
      this.contactList.push({
        'firstName': this.contactFirstName, 'lastName': this.contactLastName, 'email': this.contactEmail, 'phone': this.contactPhone,'contactRole': {'name' : this.selectedRole.attributes.name,
        'id' :this.selectedRole.id} });
        this.firstNameRequired = false;
        this.contactFirstName = "";
        this.contactLastName = "";
        this.contactEmail = "";
        this.contactPhone = "";
      }
    },
    setTab(currentTab) {
      this.tab = currentTab;
    },
    selectedFaculty(e) {
      this.faculty = e;
    },
    selectedCustomerType(customer) {
      this.customerType = customer
    },
    selectContactRole(contactRole) {
      this.selectedRole = contactRole
    },
    getCustomerType() {
        api
          .getCustomerTypes()
          .then(res => {
            this.customerTypes = res.data;
          })
          .catch();
    },
    getCharacters() {
      const page = 1;
      if (this.searchCharacters.length === 0) {
        this.charactersList = [];
      } else {
        api
          .getAllInstructors(page, this.searchCharacters)
          .then(res => {
            this.charactersList = res.data.data;
            if (this.addedCharacters.length > 0) {
              this.addedCharacters.forEach(element => {
                const index = this.charactersList.findIndex(
                  (elem) => {
                   return parseInt(elem.id) === parseInt(element.id)
                  }
                );
                if (index !== -1) {
                  this.charactersList[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    imageRemoved: function() {
      if (this.customer_id)
        api.removeCustomerPhoto(this.customer_id);
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
      this.selectedFile = null;
      this.image = null;
    },
    createCustomers() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          if (this.selectedFile)
          fb.append("customer[photo]", this.selectedFile);
          fb.append("customer[name]", this.customerName);
          fb.append(
            "customer[customer_category]", this.faculty
          );
          fb.append("customer[customer_category_value]", this.facultyName)
          fb.append("customer[customer_type_id]", this.customerType.id)
          fb.append('customer[address_attributes][address_first]', this.address1)
          fb.append('customer[address_attributes][address_second]', this.address2)
          fb.append('customer[address_attributes][country]', this.country)
          fb.append('customer[address_attributes][province]', this.province)
          fb.append('customer[address_attributes][main_phone]', this.addressPhone)
          fb.append('customer[address_attributes][postal_code]', this.postalCode)
          fb.append('customer[address_attributes][note]', this.note)
          fb.append('customer[facebook_link]', this.facebook)
          fb.append('customer[instagram_link]', this.instagram)
          fb.append('customer[twitter_link]', this.twitter)
          fb.append('customer[website_link]', this.website)
          fb.append('customer[youtube_link]', this.youtube)
          fb.append('customer[linkedin_link]', this.linkedIn)
          if (this.contactList.length > 0) {
            this.contactList.forEach((list) => {
              if (!list.id) {
                console.log(list.firstName, list.lastName, list.email, list.phone, "*** list values in this.contactList.forEach line 671");
                fb.append('customer[contacts_attributes][][first_name]', list.firstName)
                fb.append('customer[contacts_attributes][][last_name]', list.lastName)
                fb.append('customer[contacts_attributes][][email]', list.email)
                fb.append('customer[contacts_attributes][][phone]', list.phone)
                if (list.contactRole.id != undefined)
                  fb.append('customer[contacts_attributes][][contact_role_id]', list.contactRole.id)
              }
            })
          }
          if (this.removeContacts.length > 0) {
            this.removeContacts.forEach((list) => {
              if (list.id) {

                fb.append('customer[contacts_attributes][][id]', list.id)
                fb.append('customer[contacts_attributes][][first_name]', list.firstName)
                fb.append('customer[contacts_attributes][][last_name]', list.lastName)
                fb.append('customer[contacts_attributes][][email]', list.email)
                fb.append('customer[contacts_attributes][][phone]', list.phone)
                fb.append('customer[contacts_attributes][][contact_role_id]', list.contactRole.id)
                fb.append('customer[contacts_attributes][][_destroy]', true)
              }
            })
          }
          if (this.addedCharacters.length > 0) {
            this.addedCharacters.forEach((char) => {
              if (!char.id) {
               fb.append('customer[customer_instructors_attributes][][user_id]', char.user_id)
              }
            })
          }
          if (this.removeInstructors.length > 0) {
            this.removeInstructors.forEach((inst) => {
              if (inst.id)
              fb.append('customer[customer_instructors_attributes][][id]', inst.id)
              fb.append('customer[customer_instructors_attributes][][_destroy]', true)
            })
          }
          if (this.customer_id) {
            fb.append("id", this.customer_id);
            api.editCustomer(this.customer_id, fb).then(() => {
              utilFunctionService.showSuccess(commonConfig.appMessages.customerEdit);
              this.hide("createCustomer");
            });
          } else {
            api.saveCustomer(fb).then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.customerCreate
              );
              this.hide("createCustomer");
            });
          }
        }
      });
    },
    clearForm() {
      this.selectedFile = null;
      this.image = null;
      this.name = "";
      this.description = "";
      this.characters = [];
      this.addressPhone = "",
      this.postalCode = "",
      this.province = "",
      this.address1 = "",
      this.address2 = "",
      this.country = "",
      this.shortCode = "",
      this.customerName = "",
      this.facultyName = "",
      this.note = "",
      this.website = "",
      this.facebook = "",
      this.youtube = "",
      this.linkedIn = "",
      this.instagram = "",
      this.twitter = "",
      this.tab = 'address',
      this.email = "",
      this.contactList = [];
      this.removeContacts = [];
      this.removeInstructors = [];
      this.addedCharacters = [];
    },
    hide() {
      this.clearForm();
      this.$parent.hide("createCustomer");
    },
    addCharacters(e) {
      this.charactersList = [];
      this.addedCharacters.push({'firstName': e.attributes.first_name, 'lastName': e.attributes.last_name, 'email': e.attributes.email, 'user_id': e.id});
      this.searchCharacters = "";
    },
    removeCharcter(e) {
      if (this.addedCharacters[e].id) {
        this.removeInstructors.push(this.addedCharacters[e]);
      }
      this.addedCharacters.splice(e, 1);
    },
    getRole() {
      if (this.roles.length === 0) {
        api
          .getContactRoles()
          .then(res => {
            this.roles = res.data.data;
          })
          .catch();
      }
    },
    beforeOpen(event) {
      this.clearForm();
      this.getCustomerType();
      this.getRole();
      this.customer_id = event.params.customer_id;
      this.tab = event.params.tab;
      if (this.customer_id)
      this.getCustomerDetails();
    },
    getCustomerDetails() {
      api
        .getCustomerDetails(this.customer_id)
        .then(res => {
          this.customerName = res.data.data.attributes.name;
          this.shortCode = res.data.data.attributes.uniq_code;
          this.image = res.data.data.attributes.photo_url;
          if (res.data.data.attributes.customer_category_value != null) {
            this.facultyName = res.data.data.attributes.customer_category_value;
          }
          this.faculty = res.data.data.attributes.customer_category;
          if (res.data.data?.attributes?.customer_type != null) {
          this.customerType = res.data.data?.attributes?.customer_type;
          }
          if (res.data.data.attributes?.address != null) {
              this.address1 = res.data.data.attributes?.address.attributes?.address_first;
              this.address2 = res.data.data.attributes?.address.attributes?.address_second;
              this.city = res.data.data.attributes?.address.attributes?.city;
              this.country = res.data.data.attributes?.address.attributes?.country;
              this.addressPhone = res.data.data.attributes?.address.attributes?.main_phone;
              this.postal_code = res.data.data.attributes?.address.attributes?.postal_code;
              this.province = res.data.data.attributes?.address.attributes?.province;
              this.note = res.data.data.attributes?.address.attributes?.note;
          }

          this.website = res.data.data.attributes?.website_link;
          this.youtube = res.data.data.attributes?.youtube_link;
          this.twitter = res.data.data.attributes?.twitter_link;
          this.instagram = res.data.data.attributes?.instagram_link;
          this.facebook = res.data.data.attributes?.facebook_link;
          this.linkedIn = res.data.data.attributes?.linkedin_link;
          const contacts = res.data.data.attributes?.contacts;
          const instructors = res.data.data.attributes?.customer_instructors;
          if (contacts.length > 0) {
            contacts.map((contact) => {
                this.contactList.push({'firstName': contact.attributes.first_name ,'lastName': contact.attributes.last_name, 'email': contact.attributes.email , 'phone': contact.attributes.phone, 'contactRole' : {'name' : contact.attributes.contact_role_name, 'id': contact.attributes.contact_role_id }, id: contact.id})
            })
          }

          if (instructors.length > 0) {
            instructors.map((instructor) => {
                this.addedCharacters.push({'firstName': instructor.attributes.user.attributes.first_name ,'lastName': instructor.attributes.user.attributes.last_name, 'email': instructor.attributes.user.attributes.email , 'id': instructor.id, 'user_id' : instructor.attributes.user_id})
            })
          }
        })
        .catch();
    },
    onFileSelect(event) {
      this.selectedFile = event.target.files[0];
      let reader = null;
      reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = e => {
        this.image = e.target.result;
      };
    }
  }
};
</script>
