var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "learning-form-header flex-wrap" }, [
        _c("h4", [_vm._v("Interaction (Coding)")]),
        _c("div", { staticClass: "learning-quiz-btn" }, [
          _vm.status == "published"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn secondary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.restore("published")
                    },
                  },
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _vm.status == "drafted"
            ? _c(
                "a",
                {
                  staticClass: "btn header-btn primary",
                  attrs: { href: "javascript:void(0);" },
                  on: {
                    click: function ($event) {
                      return _vm.restore("published")
                    },
                  },
                },
                [_vm._v("Publish LO")]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "btn header-btn ml-2 secondary",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  return _vm.$modal.show("cloneConfirmationModal")
                },
              },
            },
            [_vm._v("Clone LO")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "learning-lo-tabs" }, [
        _c("div", { staticClass: "setup-container" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[0] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("basic-setup", 0)
                  },
                },
              },
              [_vm._m(0)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[1] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("code-setup", 1)
                  },
                },
              },
              [_vm._m(1)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[2] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("tests-setup", 2)
                  },
                },
              },
              [_vm._m(2)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[3] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("concepts-setup", 3)
                  },
                },
              },
              [_vm._m(3)]
            ),
            _c(
              "li",
              {
                class: { active: _vm.isActiveTab[4] },
                on: {
                  click: function ($event) {
                    return _vm.currentStep("admin-testing", 4)
                  },
                },
              },
              [_vm._m(4)]
            ),
            _vm.overallInteraction
              ? _c(
                  "li",
                  {
                    class: { active: _vm.isActiveTab[5] },
                    on: {
                      click: function ($event) {
                        return _vm.currentStep("overall-assessment", 5)
                      },
                    },
                  },
                  [_vm._m(5)]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "learning-quiz-content pt-4" }, [
        _vm.step == "basic-setup"
          ? _c(
              "div",
              { staticClass: "setup-content" },
              [
                _vm._m(6),
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "w-100" }, [
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Interaction title")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Interaction Title",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.name,
                                              expression: "name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.name = $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                268414483
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Administrative notes")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Administrative Notes",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.adminNotes,
                                              expression: "adminNotes",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "Description",
                                            id: "notes",
                                            rows: "4",
                                          },
                                          domProps: { value: _vm.adminNotes },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.adminNotes =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                599525079
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group mb-2" }, [
                          _c(
                            "div",
                            { staticClass: "form-checkboxes d-inline-flex" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox right",
                                  attrs: { name: "userOverall" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.overallInteraction,
                                        expression: "overallInteraction",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "userOverall",
                                    },
                                    domProps: {
                                      value: "userOverall",
                                      checked: Array.isArray(
                                        _vm.overallInteraction
                                      )
                                        ? _vm._i(
                                            _vm.overallInteraction,
                                            "userOverall"
                                          ) > -1
                                        : _vm.overallInteraction,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.overallInteraction,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "userOverall",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.overallInteraction =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.overallInteraction = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.overallInteraction = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    attrs: { for: "userOverall" },
                                  }),
                                  _vm._v(
                                    " Use overall interaction assessment "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm.overallInteraction
                          ? _c("div", { staticClass: "form-group pt-1 mb-2" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "form-checkboxes d-inline-flex",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox right",
                                      attrs: { name: "quizAssessment" },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.enableLearnerIterations,
                                            expression:
                                              "enableLearnerIterations",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "quizAssessment",
                                        },
                                        domProps: {
                                          value: "enableLearnerIterations",
                                          checked: Array.isArray(
                                            _vm.enableLearnerIterations
                                          )
                                            ? _vm._i(
                                                _vm.enableLearnerIterations,
                                                "enableLearnerIterations"
                                              ) > -1
                                            : _vm.enableLearnerIterations,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.enableLearnerIterations,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v =
                                                  "enableLearnerIterations",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.enableLearnerIterations =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.enableLearnerIterations =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.enableLearnerIterations = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        attrs: { for: "quizAssessment" },
                                      }),
                                      _vm._v(" Enable learner iteration "),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.enableLearnerIterations && _vm.overallInteraction
                          ? _c("div", { staticClass: "form-group mb-2" }, [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("label", [
                                    _vm._v("Max number of iterations"),
                                  ]),
                                  _c("div", { staticClass: "w-80 ml-3" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.maxIterations,
                                          expression: "maxIterations",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        onkeydown:
                                          "javascript: return event.keyCode == 69 ? false : true",
                                        min: "1",
                                        max: "5",
                                      },
                                      domProps: { value: _vm.maxIterations },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.displayReduceIterationModal()
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.maxIterations =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.enableLearnerIterations && _vm.overallInteraction
                          ? _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v("Learner iteration explanation"),
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.learnerExplanation,
                                    expression: "learnerExplanation",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "Description",
                                  id: "explanation",
                                  rows: "4",
                                },
                                domProps: { value: _vm.learnerExplanation },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.learnerExplanation = $event.target.value
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-sm-7" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Select assessment scheme")]),
                            _c(
                              "ValidationProvider",
                              { attrs: { name: "Assessment Scheme" } },
                              [
                                _c("div", { staticClass: "quiz-dropdown" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary dropdown-toggle",
                                      class: [
                                        _vm.isActive &&
                                        !_vm.assessmentListKey.id
                                          ? "invalid"
                                          : "",
                                      ],
                                      attrs: {
                                        type: "button",
                                        id: "entity",
                                        "data-toggle": "dropdown",
                                        "aria-haspopup": "true",
                                        "aria-expanded": "false",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.assessmentListKey.name) +
                                          " "
                                      ),
                                      _c("em", {
                                        staticClass: "icon-carrot-down",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: { "aria-labelledby": "entity" },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { value: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectedAssessment(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("None")]
                                      ),
                                      _vm._l(
                                        _vm.assessmentLists,
                                        function (assessment) {
                                          return _c(
                                            "option",
                                            {
                                              key: assessment.id,
                                              staticClass: "dropdown-item",
                                              domProps: {
                                                value: `${assessment.id}*${assessment.attributes.name}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectedAssessment(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  assessment.attributes.name
                                                )
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                !_vm.assessmentListKey.id
                                  ? _c(
                                      "span",
                                      {
                                        class: [_vm.isActive ? "invalid" : ""],
                                        style: [
                                          _vm.isActive
                                            ? { display: "block" }
                                            : { display: "none" },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "Assessment scheme field is required"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Learner Description")]),
                            _c("ValidationProvider", {
                              attrs: { name: "Description", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vue-editor", {
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "form-control invalid"
                                              : "form-control",
                                          ],
                                          attrs: {
                                            id: "Description",
                                            editorToolbar: _vm.fullTool,
                                            placeholder: "Add a Description",
                                          },
                                          model: {
                                            value: _vm.description,
                                            callback: function ($$v) {
                                              _vm.description = $$v
                                            },
                                            expression: "description",
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1400623348
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group pt-1 mb-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-checkboxes d-inline-flex" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox right",
                                  attrs: {
                                    name: "learnerModifiesProvidedCode",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.learnerModifiesProvidedCode,
                                        expression:
                                          "learnerModifiesProvidedCode",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "learnerModifiesProvidedCode",
                                    },
                                    domProps: {
                                      value: "learnerModifiesProvidedCode",
                                      checked: Array.isArray(
                                        _vm.learnerModifiesProvidedCode
                                      )
                                        ? _vm._i(
                                            _vm.learnerModifiesProvidedCode,
                                            "learnerModifiesProvidedCode"
                                          ) > -1
                                        : _vm.learnerModifiesProvidedCode,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.learnerModifiesProvidedCode,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v =
                                              "learnerModifiesProvidedCode",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.learnerModifiesProvidedCode =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.learnerModifiesProvidedCode =
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.learnerModifiesProvidedCode = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    attrs: { for: "quizAssessment" },
                                  }),
                                  _vm._v(" Learner modifies provided code "),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm.overallInteraction
                          ? _c("div", { staticClass: "form-group pt-1 mb-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "form-checkboxes d-inline-flex",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-checkbox right",
                                      attrs: {
                                        name: "displayOverallAssessment",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.displayOverallAssessment,
                                            expression:
                                              "displayOverallAssessment",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "displayOverallAssessment",
                                        },
                                        domProps: {
                                          value: "userOverall",
                                          checked: Array.isArray(
                                            _vm.displayOverallAssessment
                                          )
                                            ? _vm._i(
                                                _vm.displayOverallAssessment,
                                                "userOverall"
                                              ) > -1
                                            : _vm.displayOverallAssessment,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.displayOverallAssessment,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "userOverall",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.displayOverallAssessment =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.displayOverallAssessment =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.displayOverallAssessment = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        attrs: { for: "quizAssessment" },
                                      }),
                                      _vm._v(
                                        " Display overall assessment in learner feedback "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-12 pt-5" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end mb-4" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn primary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveCodingLO("drafted", 1)
                                  },
                                },
                              },
                              [_vm._v("Next")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "btn-container justify-content-end" },
                          [
                            _c("a", { staticClass: "delete-btn" }, [
                              _c("div", { staticClass: "delete-box" }, [
                                _c("span", { staticClass: "icon-delete" }),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$parent.archiveLO("archived")
                                    },
                                  },
                                },
                                [_vm._v("Archive this Learning Object(LO)")]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.step === "code-setup"
          ? _c("div", { staticClass: "setup-content" }, [
              _vm._m(7),
              _c("form", { staticClass: "w-100" }, [
                _c("div", { staticClass: "row custom-row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("ul", { staticClass: "form-entity-tabs" }, [
                        _c(
                          "li",
                          {
                            class:
                              _vm.optimalCodeSelected === true ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.selectedOptimalCode(true)
                              },
                            },
                          },
                          [_c("a", [_vm._v("Optimal Code")])]
                        ),
                        _vm.learnerModifiesProvidedCode === true
                          ? _c(
                              "li",
                              {
                                class:
                                  _vm.optimalCodeSelected === false
                                    ? "active"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectedOptimalCode(false)
                                  },
                                },
                              },
                              [_c("a", [_vm._v("Provided Code")])]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _vm.optimalCodeSelected === true
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("prism-editor", {
                                staticClass: "my-editor height-200",
                                attrs: {
                                  highlight: _vm.highlighter,
                                  "line-numbers": "",
                                  tabSize: 4,
                                },
                                model: {
                                  value: _vm.optimalCode,
                                  callback: function ($$v) {
                                    _vm.optimalCode = $$v
                                  },
                                  expression: "optimalCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-group justify-content-end d-flex",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "add-more-btn",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.updateOptimalCode },
                                },
                                [_vm._v("Save Optimal Code")]
                              ),
                            ]
                          ),
                        ])
                      : _c("div", [
                          _vm.learnerModifiesProvidedCode === true
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("prism-editor", {
                                      staticClass: "my-editor height-200",
                                      attrs: {
                                        highlight: _vm.highlighter,
                                        "line-numbers": "",
                                        tabSize: 4,
                                      },
                                      model: {
                                        value: _vm.providedCode,
                                        callback: function ($$v) {
                                          _vm.providedCode = $$v
                                        },
                                        expression: "providedCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group justify-content-end d-flex",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "add-more-btn",
                                        attrs: { href: "javascript:void(0);" },
                                        on: { click: _vm.updateProvidedCode },
                                      },
                                      [_vm._v("Save Provided Code")]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                  ]),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end mt-4 mb-3" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn primary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.next("tests-setup", 2)
                              },
                            },
                          },
                          [_vm._v("Next")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "btn-container justify-content-end" },
                      [
                        _c("a", { staticClass: "delete-btn" }, [
                          _vm._m(8),
                          _c(
                            "a",
                            {
                              staticClass: "text",
                              on: {
                                click: function ($event) {
                                  return _vm.$parent.archiveLO("archived")
                                },
                              },
                            },
                            [_vm._v("Archive this Learning Object(LO)")]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.step === "tests-setup"
          ? _c(
              "div",
              { staticClass: "setup-content" },
              [
                _vm._m(9),
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "w-100" }, [
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "ul",
                            { staticClass: "form-entity-tabs" },
                            _vm._l(_vm.codingTestBlocks, function (item, ind) {
                              return _c(
                                "li",
                                {
                                  key: item.id,
                                  class:
                                    item.codingTestId ==
                                    _vm.selectedCodingTest.codingTestId
                                      ? "active"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectedCodingTests(item, ind)
                                    },
                                  },
                                },
                                [_c("a", [_vm._v(_vm._s(item.codingTestName))])]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "form-group pt-4" }, [
                          _c("label", [_vm._v("Test name")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newCodingTest,
                                expression: "newCodingTest",
                              },
                            ],
                            staticClass: "form-control",
                            class: [
                              _vm.isActive && _vm.errors[0] ? "invalid" : "",
                            ],
                            attrs: { id: "admin_notes" },
                            domProps: { value: _vm.newCodingTest },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.newCodingTest = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "form-btn-group" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn w-100 primary",
                                attrs: { href: "javascript:void(0);" },
                                on: { click: _vm.addNewCodingTest },
                              },
                              [_vm._v("Add test")]
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-7" }, [
                        _c("div", { staticClass: "skill-title my-4" }, [
                          _c("h5", [
                            _vm._v(
                              _vm._s(this.selectedCodingTest.codingTestName)
                            ),
                          ]),
                          this.selectedCodingTest.id
                            ? _c(
                                "a",
                                {
                                  staticClass: "email-delete-btn",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show(
                                        "deleteCodingTestBlockConfirmationModal"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("em", { staticClass: "icon-delete" }),
                                  _vm._v(" Delete test "),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        this.selectedCodingTest.id
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [_vm._v("Learner explanation")]),
                                _c("ValidationProvider", {
                                  attrs: { name: "Learner Explanation" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.codeTestExplanation,
                                                  expression:
                                                    "codeTestExplanation",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              class: [
                                                _vm.isActive && errors[0]
                                                  ? "invalid"
                                                  : "",
                                              ],
                                              attrs: {
                                                id: "admin_notes",
                                                rows: "8",
                                              },
                                              domProps: {
                                                value: _vm.codeTestExplanation,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.codeTestExplanation =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            errors[0]
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive && errors[0]
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive && errors[0]
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        errors[0].replace(
                                                          "The ",
                                                          ""
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    277972163
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        this.selectedCodingTest.id
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("prism-editor", {
                                  staticClass: "my-editor height-200",
                                  attrs: {
                                    highlight: _vm.highlighter,
                                    "line-numbers": "",
                                    tabSize: 4,
                                  },
                                  model: {
                                    value: _vm.codeTestCode,
                                    callback: function ($$v) {
                                      _vm.codeTestCode = $$v
                                    },
                                    expression: "codeTestCode",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        this.selectedCodingTest.id
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "form-group justify-content-end d-flex",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "add-more-btn",
                                    attrs: { href: "javascript:void(0);" },
                                    on: { click: _vm.updateCodingTests },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-end mt-4 mb-3",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn primary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.next("concepts-setup", 3)
                                  },
                                },
                              },
                              [_vm._v("Next")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "btn-container justify-content-end" },
                          [
                            _c("a", { staticClass: "delete-btn" }, [
                              _c("div", { staticClass: "delete-box" }, [
                                _c("span", { staticClass: "icon-delete" }),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$parent.archiveLO("archived")
                                    },
                                  },
                                },
                                [_vm._v("Archive this Learning Object(LO)")]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.step === "concepts-setup"
          ? _c(
              "div",
              { staticClass: "setup-content" },
              [
                _vm._m(10),
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "w-100" }, [
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "ul",
                            { staticClass: "form-entity-tabs" },
                            _vm._l(_vm.conceptBlocks, function (item, ind) {
                              return _c(
                                "li",
                                {
                                  key: item.id,
                                  class:
                                    item.codingConceptId ==
                                    _vm.selectedConcept.codingConceptId
                                      ? "active"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectedConceptBlocks(
                                        item,
                                        ind
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("a", [
                                    _vm._v(_vm._s(item.codingConceptName)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "form-group pt-4" }, [
                          _c("label", [_vm._v("Concept name")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newConcept,
                                expression: "newConcept",
                              },
                            ],
                            staticClass: "form-control",
                            class: [
                              _vm.isActive && _vm.errors[0] ? "invalid" : "",
                            ],
                            attrs: { id: "admin_notes" },
                            domProps: { value: _vm.newConcept },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.newConcept = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "form-btn-group" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn w-100 primary",
                                attrs: { href: "javascript:void(0);" },
                                on: { click: _vm.addNewConcept },
                              },
                              [_vm._v("Add concept")]
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-7" }, [
                        _c("div", { staticClass: "skill-title my-4" }, [
                          _c("h5", [
                            _vm._v(
                              _vm._s(this.selectedConcept.codingConceptName)
                            ),
                          ]),
                          this.selectedConcept.id
                            ? _c(
                                "a",
                                {
                                  staticClass: "email-delete-btn",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show(
                                        "deleteCodingTestBlockConfirmationModal"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("em", { staticClass: "icon-delete" }),
                                  _vm._v(" Delete concept "),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        this.selectedConcept.id
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [_vm._v("Learner explanation")]),
                                _c("ValidationProvider", {
                                  attrs: { name: "Learner Explanation" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.conceptExplanation,
                                                  expression:
                                                    "conceptExplanation",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              class: [
                                                _vm.isActive && errors[0]
                                                  ? "invalid"
                                                  : "",
                                              ],
                                              attrs: {
                                                id: "admin_notes",
                                                rows: "8",
                                              },
                                              domProps: {
                                                value: _vm.conceptExplanation,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.conceptExplanation =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            errors[0]
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive && errors[0]
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive && errors[0]
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        errors[0].replace(
                                                          "The ",
                                                          ""
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1906347075
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        this.selectedConcept.id
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "form-group justify-content-end d-flex",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "add-more-btn-other",
                                    attrs: { href: "javascript:void(0);" },
                                    on: { click: _vm.openConceptTestsModal },
                                  },
                                  [_vm._v("Add / Edit Tests")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _vm._v("Tests associated with this concept:"),
                          ]),
                          _vm.codingConceptTests.length > 0
                            ? _c(
                                "div",
                                { staticClass: "chip-container entity-chip" },
                                _vm._l(_vm.codingConceptTests, function (test) {
                                  return _c(
                                    "div",
                                    { key: test.id, staticClass: "chip" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            test.attributes.test.attributes.name
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Assessments")]),
                            _vm._l(
                              _vm.codingConceptAssmntItems,
                              function (assmnt) {
                                return _c("span", { key: assmnt.id }, [
                                  _c(
                                    "div",
                                    { staticClass: "row concept-assess-item" },
                                    [
                                      _c("div", { staticClass: "form-col-3" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "concept-assess-label",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                assmnt.attributes
                                                  .assessment_label
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "form-col-9" }, [
                                        _c("span", [_vm._v("Min: ")]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                assmnt.attributes
                                                  .value_count_min,
                                              expression:
                                                "assmnt.attributes.value_count_min",
                                            },
                                          ],
                                          staticClass: "concept-form-item",
                                          attrs: { type: "text" },
                                          domProps: {
                                            value:
                                              assmnt.attributes.value_count_min,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                assmnt.attributes,
                                                "value_count_min",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c("span", [_vm._v("Max: ")]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                assmnt.attributes
                                                  .value_count_max,
                                              expression:
                                                "assmnt.attributes.value_count_max",
                                            },
                                          ],
                                          staticClass: "concept-form-item",
                                          attrs: { type: "text" },
                                          domProps: {
                                            value:
                                              assmnt.attributes.value_count_max,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                assmnt.attributes,
                                                "value_count_max",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c("span", [_vm._v("Points: ")]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: assmnt.attributes.points,
                                              expression:
                                                "assmnt.attributes.points",
                                            },
                                          ],
                                          staticClass: "concept-form-item",
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: assmnt.attributes.points,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                assmnt.attributes,
                                                "points",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                        this.selectedConcept.id
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "form-group justify-content-end d-flex",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "add-more-btn",
                                    attrs: { href: "javascript:void(0);" },
                                    on: { click: _vm.updateCodingConcepts },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-end mt-4 mb-3",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn primary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.next("admin-testing", 4)
                                  },
                                },
                              },
                              [_vm._v("Next")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "btn-container justify-content-end" },
                          [
                            _c("a", { staticClass: "delete-btn" }, [
                              _c("div", { staticClass: "delete-box" }, [
                                _c("span", { staticClass: "icon-delete" }),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$parent.archiveLO("archived")
                                    },
                                  },
                                },
                                [_vm._v("Archive this Learning Object(LO)")]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.step == "admin-testing"
          ? _c(
              "div",
              { staticClass: "setup-content" },
              [
                _vm._m(11),
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "w-100" }, [
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("prism-editor", {
                              staticClass: "my-editor height-200",
                              attrs: {
                                highlight: _vm.highlighter,
                                "line-numbers": "",
                                tabSize: 4,
                              },
                              model: {
                                value: _vm.adminTestCode,
                                callback: function ($$v) {
                                  _vm.adminTestCode = $$v
                                },
                                expression: "adminTestCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "form-testing-group" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn default",
                                attrs: { href: "javascript:void(0);" },
                                on: { click: _vm.clearTesting },
                              },
                              [_vm._v("Clear")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "btn primary",
                                attrs: { href: "javascript:void(0);" },
                                on: { click: _vm.submitTesting },
                              },
                              [_vm._v("Submit")]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm.testEvalJsonScheme.attributes
                        ? _c("div", { staticClass: "col-sm-4" }, [
                            _vm.testEvalJsonScheme.attributes.responses.length >
                            0
                              ? _c(
                                  "div",
                                  { staticClass: "modal-test-mail" },
                                  [
                                    _c("h4", [_vm._v("Character Response")]),
                                    _vm._l(
                                      _vm.testEvalJsonScheme.attributes
                                        .responses,
                                      function (scheme, index) {
                                        return _c("p", {
                                          key: index,
                                          domProps: {
                                            innerHTML: _vm._s(
                                              scheme.attributes.response
                                            ),
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "modal-test-tabs" }, [
                              _c(
                                "ul",
                                {
                                  staticClass: "nav nav-tabs",
                                  attrs: { id: "myTab", role: "tablist" },
                                },
                                [
                                  _c("li", { staticClass: "nav-item" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link active",
                                        attrs: {
                                          id: "Test-tab",
                                          "data-toggle": "tab",
                                          href: "#Test",
                                          role: "tab",
                                          "aria-controls": "Test",
                                          "aria-selected": "true",
                                        },
                                      },
                                      [_vm._v("Test input Return Data")]
                                    ),
                                  ]),
                                  _c("li", { staticClass: "nav-item" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "json-tab",
                                          "data-toggle": "tab",
                                          href: "#json",
                                          role: "tab",
                                          "aria-controls": "json",
                                          "aria-selected": "false",
                                        },
                                      },
                                      [_vm._v("View JSON")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tab-content pt-2",
                                  attrs: { id: "myTabContent" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane fade show active",
                                      attrs: {
                                        id: "Test",
                                        role: "tabpanel",
                                        "aria-labelledby": "Test-tab",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "test-items" }, [
                                        _vm.testEmailEnrichmentItems.attributes
                                          .asst_entity_value_list.length
                                          ? _c("div", { staticClass: "mt-3" }, [
                                              _c("label", [
                                                _vm._v(
                                                  "Assistant Entities Hit:"
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.testEmailEnrichmentItems.attributes.asst_entity_value_list.join(
                                                              ", "
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.testEmailEnrichmentItems.attributes
                                          .asst_intent_list.length
                                          ? _c("div", { staticClass: "mt-3" }, [
                                              _c("label", [
                                                _vm._v(
                                                  "Assistant Intents Hit:"
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.testEmailEnrichmentItems.attributes.asst_intent_list.join(
                                                              ", "
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.testEmailEnrichmentItems.attributes
                                          .keyword_list.length
                                          ? _c("div", { staticClass: "mt-3" }, [
                                              _c("label", [
                                                _vm._v("NLU Keywords Hit:"),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.testEmailEnrichmentItems.attributes.keyword_list.join(
                                                              ", "
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.testEmailEnrichmentItems.attributes
                                          .concept_list.length
                                          ? _c("div", { staticClass: "mt-3" }, [
                                              _c("label", [
                                                _vm._v("NLU Concepts Hit:"),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.testEmailEnrichmentItems.attributes.concept_list.join(
                                                              ", "
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.testEmailEnrichmentItems.attributes
                                          .nlu_entities_list.length
                                          ? _c("div", { staticClass: "mt-3" }, [
                                              _c("label", [
                                                _vm._v("NLU Entities Hit:"),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "test-chips-container",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "test-chips",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.testEmailEnrichmentItems.attributes.nlu_entities_list.join(
                                                              ", "
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c("div", { staticClass: "mt-3" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "test-chips-container",
                                            },
                                            [
                                              _c("label", [
                                                _vm._v("NLU Emotion Scores:"),
                                              ]),
                                              _c(
                                                "span",
                                                { staticClass: "test-chips" },
                                                [
                                                  _vm._v(
                                                    " JOY: " +
                                                      _vm._s(
                                                        _vm
                                                          .testEmailEnrichmentItems
                                                          .attributes.joy_score
                                                      ) +
                                                      ", ANGER: " +
                                                      _vm._s(
                                                        _vm
                                                          .testEmailEnrichmentItems
                                                          .attributes
                                                          .anger_score
                                                      ) +
                                                      ", DISGUST: " +
                                                      _vm._s(
                                                        _vm
                                                          .testEmailEnrichmentItems
                                                          .attributes
                                                          .disgust_score
                                                      ) +
                                                      ", SADNESS: " +
                                                      _vm._s(
                                                        _vm
                                                          .testEmailEnrichmentItems
                                                          .attributes
                                                          .sadness_score
                                                      ) +
                                                      ", FEAR: " +
                                                      _vm._s(
                                                        _vm
                                                          .testEmailEnrichmentItems
                                                          .attributes.fear_score
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "mt-3" }, [
                                          _c("label", [
                                            _vm._v("NLU Sentiment Score:"),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "test-chips-container",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "test-chips" },
                                                [
                                                  _vm._v(
                                                    " Sentiment: " +
                                                      _vm._s(
                                                        _vm
                                                          .testEmailEnrichmentItems
                                                          .attributes.sentiment
                                                      ) +
                                                      ", Score: " +
                                                      _vm._s(
                                                        _vm
                                                          .testEmailEnrichmentItems
                                                          .attributes
                                                          .sentiment_score
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm.testEvalJsonScheme &&
                                      _vm.testEvalJsonScheme.attributes &&
                                      _vm.testEvalJsonScheme.attributes
                                        .response_formula_hits
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "text-label" },
                                                [_vm._v("Email Response hit:")]
                                              ),
                                              _vm._l(
                                                _vm.testEvalJsonScheme
                                                  .attributes
                                                  .response_formula_hits,
                                                function (formula, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass: "test-items",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "test-chips-container",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "test-chips",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  formula
                                                                    .attributes
                                                                    .email_response
                                                                    .name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane fade",
                                      attrs: {
                                        id: "json",
                                        role: "tabpanel",
                                        "aria-labelledby": "json-tab",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "enrichment-concept-view p-0",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "enrichment-concept-editor",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "enrichment-concept-code",
                                                },
                                                [
                                                  _c("json-viewer", {
                                                    attrs: {
                                                      value:
                                                        _vm.testEvalJsonScheme,
                                                      "expand-depth": 5,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "enrichment-code-copy",
                                                },
                                                [
                                                  _c(
                                                    "copy-to-clipboard",
                                                    {
                                                      attrs: {
                                                        text: JSON.stringify(
                                                          _vm.testEvalJsonScheme
                                                        ),
                                                      },
                                                      on: {
                                                        copy: _vm.handleCopy,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "copy-icon",
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                        },
                                                        [_vm._v("Copy")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-end mt-4 mb-3",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn primary btn-large",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.next("qa-and-interstitial", 8)
                                  },
                                },
                              },
                              [_vm._v("Next")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.step === "overall-assessment"
          ? _c(
              "div",
              {
                staticClass: "setup-content",
                attrs: { id: "overall-assessment" },
              },
              [
                _vm._m(12),
                _vm.overallAssmntItem
                  ? _c("ValidationObserver", { ref: "form" }, [
                      _c("form", { staticClass: "w-100" }, [
                        _c("div", { staticClass: "row custom-row" }, [
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v("Overall Interaction Assessment"),
                              ]),
                              _c(
                                "ul",
                                { staticClass: "form-entity-tabs" },
                                _vm._l(
                                  _vm.overallAssmntItem,
                                  function (assmntItem) {
                                    return _c(
                                      "li",
                                      {
                                        key: assmntItem.id,
                                        class:
                                          assmntItem.id ==
                                          _vm.selectedOverallAssmntItem.id
                                            ? "active"
                                            : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleOverallAssmntItem(
                                              assmntItem
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                assmntItem.attributes
                                                  .assessment_label
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-7" }, [
                            _c(
                              "div",
                              {
                                staticClass: "question-content",
                                attrs: { id: "number-range" },
                              },
                              [
                                _c("div", { staticClass: "question-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", [_vm._v("Evaluation Text")]),
                                      _c("ValidationProvider", {
                                        attrs: { name: "Evaluation Text" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("vue-editor", {
                                                    attrs: {
                                                      id: "Descriptiontext",
                                                      placeholder:
                                                        "Add a Description",
                                                      editorToolbar:
                                                        _vm.fullTool,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.overallAssmntItemFeedback,
                                                      callback: function ($$v) {
                                                        _vm.overallAssmntItemFeedback =
                                                          $$v
                                                      },
                                                      expression:
                                                        "overallAssmntItemFeedback",
                                                    },
                                                  }),
                                                  errors[0]
                                                    ? _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            _vm.isActive
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          style: [
                                                            _vm.isActive
                                                              ? {
                                                                  display:
                                                                    "block",
                                                                }
                                                              : {
                                                                  display:
                                                                    "none",
                                                                },
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              errors[0].replace(
                                                                "The ",
                                                                ""
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3765878218
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group pt-2" },
                                    [
                                      _c("label", [
                                        _vm._v("Evaluation range:"),
                                      ]),
                                      _c("ValidationProvider", {
                                        attrs: { name: "Evaluation range" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row mt-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-6",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v("Minimum"),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.overallAssmntItemMinScore,
                                                                expression:
                                                                  "overallAssmntItemMinScore",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            class: [
                                                              _vm.isActive &&
                                                              errors[0]
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              type: "number",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.overallAssmntItemMinScore,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.overallAssmntItemMinScore =
                                                                  $event.target.value
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-6",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v("Maximum"),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.overallAssmntItemMaxScore,
                                                                expression:
                                                                  "overallAssmntItemMaxScore",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            class: [
                                                              _vm.isActive &&
                                                              errors[0]
                                                                ? "invalid"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              type: "number",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.overallAssmntItemMaxScore,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.overallAssmntItemMaxScore =
                                                                  $event.target.value
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  errors[0]
                                                    ? _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            _vm.isActive
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          style: [
                                                            _vm.isActive
                                                              ? {
                                                                  display:
                                                                    "block",
                                                                }
                                                              : {
                                                                  display:
                                                                    "none",
                                                                },
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              errors[0].replace(
                                                                "The ",
                                                                ""
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1464490268
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-12 pt-5" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end mt-4 mb-3",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.restore("published")
                                      },
                                    },
                                  },
                                  [_vm._v("Save & Publish")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "btn-container justify-content-end",
                              },
                              [
                                _c("a", { staticClass: "delete-btn" }, [
                                  _c("div", { staticClass: "delete-box" }, [
                                    _c("span", { staticClass: "icon-delete" }),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$parent.archiveLO(
                                            "archived"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Archive this Learning Object(LO)")]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteResponseModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteResponseModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm.responseFormulaId.name === "externalApiAssmntFormula"
                ? _c(
                    "button",
                    {
                      staticClass: "btn medium primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.deleteAssessmentResponseFormula(),
                            _vm.$modal.hide("deleteResponseModal")
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteCodingTestBlockConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete this test?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide(
                        "deleteCodingTestBlockConfirmationModal"
                      )
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.deleteCodingTest(),
                        _vm.$modal.hide(
                          "deleteCodingTestBlockConfirmationModal"
                        )
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "ReduceIteration",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v(
                "Reducing the iteration level removes the respective iteration responses added, if any. Are you sure you want to reduce the iteration level? "
              ),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.changeBackIteration(),
                        _vm.$modal.hide("ReduceIteration")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("ReduceIteration")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "cloneConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to clone this learning object?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.cloneLo(), _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "conceptTestsModal",
            height: "auto",
            width: 700,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-default" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Add tests to concept")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", { staticClass: "modal-form modal-min-height" }, [
                _c("div", { staticClass: "modal-formula-content" }, [
                  _c("div", { staticClass: "formulas-list-title" }, [
                    _c("div", { staticClass: "formulas-left" }, [
                      _vm._v(" All available tests: "),
                    ]),
                  ]),
                  _c("div", { staticClass: "formulas-tabs-list" }, [
                    _c("div", { staticClass: "formulas-tabs-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "formula-tab-pane active",
                          attrs: { id: "concept" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "formula-chips-list" },
                            _vm._l(_vm.allCodingTests, function (ctest) {
                              return _c(
                                "span",
                                { key: ctest.id, staticClass: "formula-chips" },
                                [
                                  _c(
                                    "drag",
                                    {
                                      staticClass: "drag",
                                      attrs: { "transfer-data": ctest },
                                    },
                                    [_vm._v(_vm._s(ctest.testName))]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "formulas-data-list" }, [
                    _c(
                      "div",
                      { staticClass: "formulas-list" },
                      [
                        _c(
                          "drop",
                          {
                            staticClass: "drop",
                            on: {
                              drop: function ($event) {
                                return _vm.handleEvaluationDrop($event)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "formulas-list-title" }, [
                              _c("div", { staticClass: "formulas-left" }, [
                                _vm._v(" Tests on this concept: "),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "formulas-list-item" },
                              _vm._l(
                                _vm.codingConceptTests,
                                function (ctest, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "formula-item-flex",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "formula-name" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                ctest.attributes.test.attributes
                                                  .name
                                              ) +
                                              ": "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "formula-cross",
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeConceptTest(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("em", {
                                                staticClass: "icon-close",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      Object.keys(_vm.codingConceptTests)
                                        .length -
                                        1 !==
                                      index
                                        ? _c(
                                            "span",
                                            { staticClass: "formula-and" },
                                            [_vm._v("and")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            Object.keys(_vm.codingConceptTests).length === 0
                              ? _c(
                                  "div",
                                  { staticClass: "formulas-list-placeholder" },
                                  [_vm._v("Drag and Drop here")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "modal-footer mt-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          _vm.cancelAddEditConceptTests(),
                            _vm.$modal.hide("conceptTestsModal")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.handleAddEditConceptTests(),
                            _vm.$modal.hide("conceptTestsModal")
                        },
                      },
                    },
                    [_vm._v("Done")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Basic Information"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Code")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Tests")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Concepts")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [_vm._v("Testing")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", [
      _c("span", { staticClass: "check-box" }),
      _c("span", { staticClass: "check-caption" }, [
        _vm._v("Overall Assessment"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Code")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "delete-box" }, [
      _c("span", { staticClass: "icon-delete" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Tests")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Concepts")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Testing")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Overall Assessment")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }