<template>
    <div id="WatsonAdmin">
        <div class="container">
            <header class="header">
                <div class="header-left">
                    <h2>LLM Skills</h2>
                </div>
                <div class="header-right">
                    <router-link to="/create-llm-skill" class="btn btn-primary">Create Skill</router-link>
                </div>
            </header>

            <div class="content-container">
                <div class="data-content" v-if="llm_skills.length">
                    <div class="data-table data-column-skills">
                        <div class="data-head">
                            <div class="data-col col-title">
                                Skill Name
                            </div>
                            <div class="data-col col-title">
                                Description
                            </div>
                            <div class="data-col col-title">
                                Actions
                            </div>
                        </div>
                        <div class="data-items">
                            <div class="data-row" v-for="llm_skill in llm_skills" :key="llm_skill.id">
                                <div class="data-col">{{ llm_skill.attributes.name }}</div>
                                <div class="data-col">{{ llm_skill.attributes.description }}</div>
                                <div class="data-col">
                                    <ul class="btn-container">
                                        <li>
                                            <router-link
                                                :to="{ name: 'edit-llm-skill', params: { id: llm_skill.id, llm_skill_data: llm_skill } }"><button
                                                    class="btn btn-edit">Edit</button></router-link>
                                        </li>
                                        <li>
                                            <button class="btn btn-danger"
                                                @click="deleteLlmSkill(llm_skill.id)">Delete</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- In order for this work, there needs to be tabulation on the backend -->
                    <!-- <div class="data-footer">
                        <div class="data-results">Showing {{ page }} of {{ pages.length }}</div>
                        <div aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item" v-if="page !== 1">
                                    <a class="page-link" aria-label="Previous" @click="prevPage">
                                        <em class="icon-prev"></em>
                                    </a>
                                </li>
                                <li class="page-item" v-for="pages in pages.slice(page - 1, page + 3)" :key="pages">
                                    <a class="page-link" :class="[pages === page ? 'active' : '']"
                                        @click="getElm(pages)">{{ pages }}</a>
                                </li>
                                <li class="page-item" v-if="page < pages.length">
                                    <a class="page-link" aria-label="Next" @click="nextPage">
                                        <em class="icon-next"></em>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                <div class="data-content" v-else>
                    <h1>No Skills created</h1>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "../services/api";
import deleteLlmSkillModal from "@/components/modal/deleteLlmSkillModal.vue";
import { utilFunctionService } from "@/utils/utils.service";

export default {
    name: "LlmSkills",
    data() {
        return {
            llm_skills: []
        };
    },
    methods: {
        getAllSkills() {
            api.getAllLlmSkills().then((response) => {
                this.llm_skills = response.data.data;
                utilFunctionService.hideLoader();
            });
        },
        showDeleteLlmSkillModal(id) {
            this.$modal.show(deleteLlmSkillModal, { llm_skill_id: parseInt(id) });
        },
        deleteLlmSkill(id) {
            utilFunctionService.showLoader();
            let payload = {
                llm_skill_id: id
            }
            api.deleteLlmSkill(payload, this).then((response) => {
                // this.llm_skills = response.data;
                console.log(response);
                this.getAllSkills();
            })
        }
    },
    created() {
        utilFunctionService.showLoader();
        this.getAllSkills();
    },
};
</script>
  
<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.col-title {
    font-weight: bold;
    font-size: 26px;
}

.btn-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.btn-edit {
    background-color: rgb(99, 99, 244);
    color: white;
    margin-right: 8px;
}

.btn-danger {
    background-color: rgb(251, 72, 72);
    color: white;
}</style>
  