var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "elm-form-container learning-module-container" },
    [
      _c(
        "div",
        { staticClass: "step-container" },
        [
          _c("vue-scroll", { ref: "vs", attrs: { ops: _vm.ops } }, [
            _vm.menus.active && _vm.menus.active.length > 0
              ? _c(
                  "ul",
                  { staticClass: "step-list" },
                  [
                    _c(
                      "draggable",
                      {
                        on: {
                          start: function ($event) {
                            _vm.isDragging = true
                          },
                          end: _vm.onEnd,
                        },
                        model: {
                          value: _vm.menus.active,
                          callback: function ($$v) {
                            _vm.$set(_vm.menus, "active", $$v)
                          },
                          expression: "menus.active",
                        },
                      },
                      _vm._l(_vm.menus.active, function (menu, key) {
                        return _c(
                          "li",
                          {
                            key: menu.id,
                            class:
                              _vm.$route.query.id === menu.id ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.getActive(menu.id, key)
                              },
                            },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: `/edit-elm/${_vm.id}?id=${menu.id}`,
                                },
                              },
                              [
                                _c("div", { staticClass: "number-box" }, [
                                  _vm._v(_vm._s(key + 1)),
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _c("div", { staticClass: "small-text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("uppercase")(
                                            _vm._f("heading")(
                                              menu.attributes
                                                .learning_object_type
                                            ),
                                            true
                                          )
                                        ) +
                                        " (" +
                                        _vm._s(
                                          _vm._f("uppercase")(
                                            menu.attributes.card_type ==
                                              "submission"
                                              ? "Grading"
                                              : menu.attributes.card_type,
                                            true
                                          )
                                        ) +
                                        ") "
                                    ),
                                    menu.attributes.status === "drafted"
                                      ? _c("span", { staticClass: "tag" }, [
                                          _vm._v("Draft"),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("div", { staticClass: "heading" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("uppercase")(
                                          menu.attributes.name,
                                          true
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("span", {
                                  staticClass: "icon-carrot-right",
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "button",
            {
              staticClass: "btn header-btn primary w-100",
              on: {
                click: function ($event) {
                  return _vm.$modal.show("createLO")
                },
              },
            },
            [_vm._v("Add New Learning Object")]
          ),
        ],
        1
      ),
      _vm.elmMenu.card_type === "video"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("ValidationObserver", { ref: "form" }, [
                _c(
                  "form",
                  {
                    staticClass: "w-100",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitLo.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "learning-form-header" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm._f("heading")(_vm.$parent.learning_object_type)
                          ) +
                            " (" +
                            _vm._s(
                              _vm._f("uppercase")(_vm.elmMenu.card_type, true)
                            ) +
                            ")"
                        ),
                      ]),
                      _c("div", { staticClass: "d-flex" }, [
                        _vm.status == "published"
                          ? _c(
                              "a",
                              {
                                staticClass: "btn header-btn secondary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitLo("published")
                                  },
                                },
                              },
                              [_vm._v("Save")]
                            )
                          : _vm._e(),
                        _vm.status == "drafted"
                          ? _c(
                              "a",
                              {
                                staticClass: "btn header-btn ml-2 primary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitLo("published")
                                  },
                                },
                              },
                              [_vm._v("Publish LO")]
                            )
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            staticClass: "btn header-btn ml-2 secondary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.cloneLo()
                              },
                            },
                          },
                          [_vm._v("Clone LO")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Card Title")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Card Title",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.name,
                                              expression: "name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "name",
                                            "aria-describedby": "emailHelp",
                                          },
                                          domProps: { value: _vm.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.name = $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2820331040
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Administrative Notes")]),
                            _c(
                              "ValidationProvider",
                              { attrs: { name: "Description" } },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.adminNotes,
                                      expression: "adminNotes",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "Description",
                                    id: "Description",
                                    rows: "8",
                                  },
                                  domProps: { value: _vm.adminNotes },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.adminNotes = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "btn-container" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn ml-2 mb-2 secondary medium",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.addVideo("AddVideo")
                                },
                              },
                            },
                            [_vm._v("Add a new video")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "btn ml-2 mb-2 secondary medium",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.addVideoFromGallery("uploadVideo")
                                },
                              },
                            },
                            [_vm._v("Select from Library")]
                          ),
                        ]),
                        !_vm.video
                          ? _c(
                              "div",
                              {
                                class: [
                                  _vm.isActive
                                    ? "invalid text-right w-100 mr-2"
                                    : "text-right w-100 mr-2",
                                ],
                                style: [
                                  _vm.isActive
                                    ? { display: "block" }
                                    : { display: "none" },
                                ],
                              },
                              [_vm._v("Add Video field is required")]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-sm-7" }, [
                        _vm.video && _vm.video.attributes
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c("label", [_vm._v("Video Preview")]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "video-preview",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Preview(
                                          _vm.elmMenu.card_type
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [_vm._v("See Preview")]),
                                    _c("em", {
                                      staticClass: "icon-carrot-right",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "form-group video-group" }, [
                          _c("div", { staticClass: "video-section" }, [
                            !_vm.video
                              ? _c("div", { staticClass: "video-empty" }, [
                                  _c("img", {
                                    attrs: {
                                      alt: "images",
                                      src: require("../assets/images/video-icon.png"),
                                    },
                                  }),
                                ])
                              : _c("div", { staticClass: "video-player" }, [
                                  _vm.video && _vm.video.attributes
                                    ? _c(
                                        "div",
                                        { staticClass: "learning-data-list" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "wistia_responsive_wrapper data-video",
                                              staticStyle: {
                                                height: "100%",
                                                left: "0",
                                                position: "absolute",
                                                top: "0",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("iframe", {
                                                staticClass: "wistia_embed",
                                                attrs: {
                                                  src:
                                                    _vm.preIframUrl +
                                                    _vm.video.attributes
                                                      .wistia_code +
                                                    _vm.postIframUrl,
                                                  title: "Video.mp4",
                                                  name: "wistia_embed",
                                                  allowtransparency: "true",
                                                  scrolling: "no",
                                                  allowfullscreen: "",
                                                  mozallowfullscreen: "",
                                                  webkitallowfullscreen: "",
                                                  oallowfullscreen: "",
                                                  msallowfullscreen: "",
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Video Title")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Video Title",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.title,
                                              expression: "title",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "title",
                                            id: "title",
                                            "aria-describedby": "emailHelp",
                                            placeholder: "Add a Video Title",
                                          },
                                          domProps: { value: _vm.title },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.title = $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3085195262
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Learner Description")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Learner Description",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.description,
                                              expression: "description",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "Description",
                                            id: "Description",
                                            "aria-describedby": "emailHelp",
                                            rows: "4",
                                            placeholder:
                                              "Add a Learner Description",
                                          },
                                          domProps: { value: _vm.description },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.description =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3645290093
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm.$route.query.id
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "btn-container justify-content-end",
                              },
                              [
                                _c("a", { staticClass: "delete-btn" }, [
                                  _c("div", { staticClass: "delete-box" }, [
                                    _c("span", { staticClass: "icon-delete" }),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text",
                                      on: {
                                        click: function ($event) {
                                          return _vm.archiveLO("archived")
                                        },
                                      },
                                    },
                                    [_vm._v("Archive this Learning Object(LO)")]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: {
            name: "cropperImages",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-cropper-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "modal-images-cropper" }, [
                _vm.image
                  ? _c(
                      "div",
                      { staticClass: "modal-cropper-large" },
                      [
                        _c("cropper", {
                          ref: "cropper",
                          attrs: {
                            src: _vm.image,
                            "stencil-props": { aspectRatio: 16 / 9 },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cropperImages")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.crop()
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ]),
          ]),
        ]
      ),
      _vm.elmMenu.card_type === "slide"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("div", { staticClass: "learning-form-header" }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm._f("heading")(_vm.$parent.learning_object_type)
                    ) +
                      " (" +
                      _vm._s(_vm._f("uppercase")(_vm.elmMenu.card_type, true)) +
                      ")"
                  ),
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _vm.status == "published"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn header-btn secondary",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.saveSlide("published")
                            },
                          },
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                  _vm.status == "drafted"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn header-btn ml-2 primary",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.saveSlide("published")
                            },
                          },
                        },
                        [_vm._v("Publish LO")]
                      )
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "btn header-btn ml-2 secondary",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.cloneLo()
                        },
                      },
                    },
                    [_vm._v("Clone LO")]
                  ),
                ]),
              ]),
              _c("ValidationObserver", { ref: "form" }, [
                _c("form", { staticClass: "w-100" }, [
                  _c("div", { staticClass: "row custom-row" }, [
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Card Title")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Card title",
                              rules: "required|max:100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "title",
                                          id: "title",
                                          placeholder: "Add Card Title",
                                        },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.name = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3133420277
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Administrative Notes")]),
                          _c(
                            "ValidationProvider",
                            { attrs: { name: "Description" } },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminNotes,
                                    expression: "adminNotes",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "Description",
                                  id: "Description",
                                  rows: "8",
                                },
                                domProps: { value: _vm.adminNotes },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.adminNotes = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Learner Description")]),
                          _c("ValidationProvider", {
                            attrs: { name: "Description", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.description,
                                            expression: "description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "4",
                                          placeholder: "Add a Description",
                                        },
                                        domProps: { value: _vm.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.description =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              874232385
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "btn-container" }, [
                        _c("input", {
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "image/jpeg" },
                          on: { change: _vm.onFileSelect },
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "btn header-btn secondary small-btn",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput.click()
                              },
                            },
                          },
                          [_vm._v("Upload new Images")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn header-btn secondary small-btn",
                            on: {
                              click: function ($event) {
                                return _vm.openSliderGallery("sliderImages")
                              },
                            },
                          },
                          [_vm._v("Select from Library")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c("label", [_vm._v("Image Preview")]),
                            _c(
                              "a",
                              {
                                staticClass: "video-preview",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.Preview(_vm.elmMenu.card_type)
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v("See Preview")]),
                                _c("em", { staticClass: "icon-carrot-right" }),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "plot-slider" }, [
                          _c("div", { staticClass: "item" }, [
                            _vm.imagePreview != false
                              ? _c("img", {
                                  attrs: {
                                    alt: "images",
                                    src: _vm.imagePreview,
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "plot-slider-title" }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group mt-3 mb-3" }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("label", [_vm._v("Image Caption")]),
                            _c("label", { staticClass: "switch ml-auto" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageCaption,
                                    expression: "imageCaption",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.imageCaption)
                                    ? _vm._i(_vm.imageCaption, null) > -1
                                    : _vm.imageCaption,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.imageCaption,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.imageCaption = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.imageCaption = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.imageCaption = $$c
                                    }
                                  },
                                },
                              }),
                              _c("span", { staticClass: "slider round" }),
                            ]),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.imageTitle,
                                expression: "imageTitle",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "Add Caption" },
                            domProps: { value: _vm.imageTitle },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.imageTitle = $event.target.value
                                },
                                _vm.getTitle,
                              ],
                            },
                          }),
                        ]),
                        _vm.slider.length
                          ? _c(
                              "div",
                              { staticClass: "plot-thumbs" },
                              [
                                _c(
                                  "VueSlickCarousel",
                                  _vm._b(
                                    { on: { reInit: _vm.onInitCarousel } },
                                    "VueSlickCarousel",
                                    _vm.settings,
                                    false
                                  ),
                                  _vm._l(_vm.slider, function (slide, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: slide.id,
                                        staticClass: "owl-item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "item",
                                            class: [
                                              i == _vm.index ? "active" : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "plot-btn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeSliderImage(
                                                      slide.id,
                                                      slide.sliderId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("em", {
                                                  staticClass: "icon-close",
                                                }),
                                              ]
                                            ),
                                            _c("img", {
                                              attrs: {
                                                alt: "images",
                                                src: slide.image,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.slideImage(
                                                    i,
                                                    slide.title,
                                                    slide.image
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm.$route.query.id
                        ? _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveSlide("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "text"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("ValidationObserver", { ref: "form" }, [
                _c(
                  "form",
                  {
                    staticClass: "w-100",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitLo.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "learning-form-header" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm._f("heading")(_vm.$parent.learning_object_type)
                          ) +
                            " (" +
                            _vm._s(
                              _vm._f("uppercase")(_vm.elmMenu.card_type, true)
                            ) +
                            ")"
                        ),
                      ]),
                      _c("div", { staticClass: "d-flex" }, [
                        _vm.status == "published"
                          ? _c(
                              "a",
                              {
                                staticClass: "btn header-btn secondary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveContentText("published")
                                  },
                                },
                              },
                              [_vm._v("Save")]
                            )
                          : _vm._e(),
                        _vm.status == "drafted"
                          ? _c(
                              "a",
                              {
                                staticClass: "btn header-btn ml-2 primary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveContentText("published")
                                  },
                                },
                              },
                              [_vm._v("Publish LO")]
                            )
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            staticClass: "btn header-btn ml-2 secondary",
                            attrs: { href: "javascript:void(0);" },
                            on: {
                              click: function ($event) {
                                return _vm.cloneLo()
                              },
                            },
                          },
                          [_vm._v("Clone LO")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "learning-preview" }, [
                      _c(
                        "a",
                        {
                          staticClass: "video-preview",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.Preview(_vm.elmMenu.card_type)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v("See Preview")]),
                          _c("em", { staticClass: "icon-carrot-right" }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Card Title")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Card Title",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.name,
                                              expression: "name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "title",
                                            "aria-describedby": "emailHelp",
                                            placeholder: "Add Card Title",
                                          },
                                          domProps: { value: _vm.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.name = $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                48191143
                              ),
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Administrative Notes")]),
                            _c(
                              "ValidationProvider",
                              { attrs: { name: "Description" } },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.adminNotes,
                                      expression: "adminNotes",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "Description",
                                    id: "Description",
                                    rows: "8",
                                  },
                                  domProps: { value: _vm.adminNotes },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.adminNotes = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group group-editor" },
                          [
                            _c("label", [_vm._v("Learner Description")]),
                            _c("ValidationProvider", {
                              attrs: { name: "Description", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vue-editor", {
                                          attrs: {
                                            id: "Descriptiontext",
                                            placeholder: "Add a Description",
                                            editorToolbar: _vm.fullTool,
                                          },
                                          model: {
                                            value: _vm.contentDescription,
                                            callback: function ($$v) {
                                              _vm.contentDescription = $$v
                                            },
                                            expression: "contentDescription",
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2438103456
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group group-editor" },
                          [
                            _c("label", [_vm._v("Text Body")]),
                            _c("ValidationProvider", {
                              attrs: { name: "Text Body", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vue-editor", {
                                          attrs: {
                                            id: "TextBody",
                                            placeholder: "Add Text Body",
                                            editorToolbar: _vm.fullTool,
                                          },
                                          model: {
                                            value: _vm.textBody,
                                            callback: function ($$v) {
                                              _vm.textBody = $$v
                                            },
                                            expression: "textBody",
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1618853822
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm.$route.query.id
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "btn-container justify-content-end",
                              },
                              [
                                _c("a", { staticClass: "delete-btn" }, [
                                  _c("div", { staticClass: "delete-box" }, [
                                    _c("span", { staticClass: "icon-delete" }),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text",
                                      on: {
                                        click: function ($event) {
                                          return _vm.archiveLO("archived")
                                        },
                                      },
                                    },
                                    [_vm._v("Archive this Learning Object(LO)")]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "email" &&
      _vm.elmMenu.learning_object_type === "plot_point"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("div", { staticClass: "learning-form-header" }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm._f("heading")(_vm.$parent.learning_object_type)
                    ) +
                      " (" +
                      _vm._s(_vm._f("uppercase")(_vm.elmMenu.card_type, true)) +
                      ")"
                  ),
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _vm.status == "published"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn header-btn secondary",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.saveLoEmail("published")
                            },
                          },
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                  _vm.status == "drafted"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn header-btn ml-2 primary",
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _vm.saveLoEmail("published")
                            },
                          },
                        },
                        [_vm._v("Publish LO")]
                      )
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "btn header-btn ml-2 secondary",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.cloneLo()
                        },
                      },
                    },
                    [_vm._v("Clone LO")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "learning-preview" }, [
                _c(
                  "a",
                  {
                    staticClass: "video-preview",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.Preview(_vm.elmMenu.card_type)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("See Preview")]),
                    _c("em", { staticClass: "icon-carrot-right" }),
                  ]
                ),
              ]),
              _c("ValidationObserver", { ref: "form" }, [
                _c("form", { staticClass: "w-100" }, [
                  _c("div", { staticClass: "row custom-row" }, [
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Card Title")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Card Title",
                              rules: "required|max:100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "name",
                                          "aria-describedby": "emailHelp",
                                          placeholder: "Add Card Title",
                                        },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.name = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2858671904
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Learner Description")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Learner Description",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.description,
                                            expression: "description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "4",
                                          placeholder: "Add Description",
                                        },
                                        domProps: { value: _vm.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.description =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              147674496
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group search-input" }, [
                        _c("label", [_vm._v("Select Character(s)")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchCharacter,
                              expression: "searchCharacter",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: "Search by name, role or organization",
                          },
                          domProps: { value: _vm.searchCharacter },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.searchCharacter = $event.target.value
                              },
                              _vm.filterCharacter,
                            ],
                          },
                        }),
                        _c("span", { staticClass: "icon-search" }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "user-row-scroll" },
                        _vm._l(_vm.searchCharacters, function (character) {
                          return _c(
                            "div",
                            { key: character.id, staticClass: "user-row" },
                            [
                              _c("div", { staticClass: "img-container" }, [
                                _c("div", { staticClass: "user-info" }, [
                                  _c("div", { staticClass: "head" }, [
                                    _vm._v(_vm._s(character.char_full_name)),
                                  ]),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(
                                      _vm._s(character.world_role) +
                                        " at " +
                                        _vm._s(character.org_name)
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(character.char_age) +
                                        " , " +
                                        _vm._s(
                                          _vm._f("uppercase")(
                                            character.char_gender,
                                            true
                                          )
                                        )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "form-checkboxes" }, [
                                _c(
                                  "label",
                                  { staticClass: "custom-checkbox mr-3" },
                                  [
                                    _vm._v(" From "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: character.to,
                                          expression: "character.to",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: character.id,
                                      },
                                      domProps: {
                                        checked: Array.isArray(character.to)
                                          ? _vm._i(character.to, null) > -1
                                          : character.to,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCharacter(
                                            character,
                                            character.id,
                                            "to"
                                          )
                                        },
                                        change: function ($event) {
                                          var $$a = character.to,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  character,
                                                  "to",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  character,
                                                  "to",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(character, "to", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "checkmark" }),
                                  ]
                                ),
                                _c(
                                  "label",
                                  { staticClass: "custom-checkbox" },
                                  [
                                    _vm._v(" cc "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: character.cc,
                                          expression: "character.cc",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: character.id,
                                      },
                                      domProps: {
                                        checked: Array.isArray(character.cc)
                                          ? _vm._i(character.cc, null) > -1
                                          : character.cc,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCharacter(
                                            character,
                                            character.id,
                                            "cc"
                                          )
                                        },
                                        change: function ($event) {
                                          var $$a = character.cc,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  character,
                                                  "cc",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  character,
                                                  "cc",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(character, "cc", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "checkmark" }),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Subject")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Subject",
                              rules: "required|max:100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.title,
                                            expression: "title",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "title",
                                          "aria-describedby": "emailHelp",
                                          placeholder: "Enter Subject",
                                        },
                                        domProps: { value: _vm.title },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.title = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3739192006
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Add Email Body")]),
                          _c("div", { staticClass: "email-body mt-2" }, [
                            _c("ul", { staticClass: "variations-tab" }, [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    class:
                                      _vm.selectedVariation === 1
                                        ? "active"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setActiveVariation(1)
                                      },
                                    },
                                  },
                                  [_vm._v("Variation 1")]
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    class:
                                      _vm.selectedVariation === 2
                                        ? "active"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setActiveVariation(2)
                                      },
                                    },
                                  },
                                  [_vm._v("Variation 2")]
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    class:
                                      _vm.selectedVariation === 3
                                        ? "active"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setActiveVariation(3)
                                      },
                                    },
                                  },
                                  [_vm._v("Variation 3")]
                                ),
                              ]),
                            ]),
                          ]),
                          _vm.selectedVariation === 1
                            ? _c("ValidationProvider", {
                                attrs: {
                                  name: "Email Body",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _vm.selectedVariation === 1
                                            ? _c(
                                                "div",
                                                [
                                                  _c("vue-editor", {
                                                    attrs: {
                                                      id: "test",
                                                      placeholder:
                                                        "Add Email Body",
                                                      editorToolbar:
                                                        _vm.fullTool,
                                                    },
                                                    model: {
                                                      value: _vm.emailBody1,
                                                      callback: function ($$v) {
                                                        _vm.emailBody1 = $$v
                                                      },
                                                      expression: "emailBody1",
                                                    },
                                                  }),
                                                  errors[0]
                                                    ? _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            _vm.isActive
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          style: [
                                                            _vm.isActive
                                                              ? {
                                                                  display:
                                                                    "block",
                                                                }
                                                              : {
                                                                  display:
                                                                    "none",
                                                                },
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              errors[0].replace(
                                                                "The ",
                                                                ""
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  104511566
                                ),
                              })
                            : _vm._e(),
                          _vm.selectedVariation === 2
                            ? _c(
                                "div",
                                [
                                  _c("vue-editor", {
                                    attrs: {
                                      placeholder: "Add Email Body",
                                      editorToolbar: _vm.fullTool,
                                    },
                                    model: {
                                      value: _vm.emailBody2,
                                      callback: function ($$v) {
                                        _vm.emailBody2 = $$v
                                      },
                                      expression: "emailBody2",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedVariation === 3
                            ? _c(
                                "div",
                                [
                                  _c("vue-editor", {
                                    attrs: {
                                      placeholder: "Add Email Body",
                                      editorToolbar: _vm.fullTool,
                                    },
                                    model: {
                                      value: _vm.emailBody3,
                                      callback: function ($$v) {
                                        _vm.emailBody3 = $$v
                                      },
                                      expression: "emailBody3",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Administrative Notes")]),
                          _c(
                            "ValidationProvider",
                            { attrs: { name: "Description" } },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminNotes,
                                    expression: "adminNotes",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "Description",
                                  id: "Description",
                                  rows: "8",
                                },
                                domProps: { value: _vm.adminNotes },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.adminNotes = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "btn-container" }, [
                        _c("input", {
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", multiple: "", accept: ".pdf" },
                          on: { change: _vm.onFileSelected },
                        }),
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn ml-2 mb-2 secondary medium pdf-button",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput.click()
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "pin-image",
                              attrs: {
                                src: require("../assets/images/pin-icon.png"),
                                alt: "pin",
                              },
                            }),
                            _vm._v(" Attach a File"),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-7" }, [
                        _vm.emailPdfs.length > 0
                          ? _c(
                              "div",
                              { staticClass: "form-pdf-group" },
                              _vm._l(_vm.emailPdfs, function (emailPdf, i) {
                                return _c(
                                  "div",
                                  {
                                    key: emailPdf.id,
                                    staticClass: "form-pdf-item",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show("pdfModal", {
                                              pdfUrl:
                                                emailPdf.attributes
                                                  .resource_url,
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../assets/images/pdf-big-icon.png"),
                                            alt: "pdf",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "close-pdf",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removePdfs(i)
                                          },
                                        },
                                      },
                                      [_c("em", { staticClass: "icon-close" })]
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm.$route.query.id
                        ? _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "file"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("div", { staticClass: "learning-form-header" }, [
                _c("h4", [
                  _vm._v(
                    _vm._s(
                      _vm._f("heading")(_vm.$parent.learning_object_type)
                    ) +
                      " (" +
                      _vm._s(_vm._f("uppercase")(_vm.elmMenu.card_type, true)) +
                      ")"
                  ),
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _vm.status == "published"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn header-btn secondary",
                          on: {
                            click: function ($event) {
                              return _vm.submitLo("published")
                            },
                          },
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                  _vm.status === "drafted"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn header-btn ml-2 primary",
                          on: {
                            click: function ($event) {
                              return _vm.submitLo("published")
                            },
                          },
                        },
                        [_vm._v("Publish LO")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn header-btn ml-2 secondary",
                      on: {
                        click: function ($event) {
                          return _vm.cloneLo()
                        },
                      },
                    },
                    [_vm._v("Clone LO")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "learning-preview" }, [
                _c(
                  "a",
                  {
                    staticClass: "video-preview",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.Preview(_vm.elmMenu.card_type)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("See Preview")]),
                    _c("em", { staticClass: "icon-carrot-right" }),
                  ]
                ),
              ]),
              _c("ValidationObserver", { ref: "form" }, [
                _c("form", { staticClass: "w-100" }, [
                  _c("div", { staticClass: "row custom-row" }, [
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Card Title")]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Card Title",
                              rules: "required|max:100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "name",
                                          "aria-describedby": "emailHelp",
                                          placeholder: "Add Card Title",
                                        },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.name = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2858671904
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Administrative Notes")]),
                          _c(
                            "ValidationProvider",
                            { attrs: { name: "Description" } },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminNotes,
                                    expression: "adminNotes",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "Description",
                                  id: "Description",
                                  rows: "8",
                                },
                                domProps: { value: _vm.adminNotes },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.adminNotes = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Learner Description")]),
                          _c("ValidationProvider", {
                            attrs: { name: "Description", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.description,
                                            expression: "description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "4",
                                          placeholder: "Add a Description",
                                        },
                                        domProps: { value: _vm.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.description =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              874232385
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "btn-container" }, [
                        _c("input", {
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "file",
                            multiple: "multiple",
                            accept: ".pdf, .doc, .csv",
                          },
                          on: { change: _vm.onDocSelect },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn ml-2 mb-2 secondary medium",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput.click()
                              },
                            },
                          },
                          [_vm._v("Upload a File")]
                        ),
                        _c("h1", { staticStyle: { display: "none" } }, [
                          _vm._v(
                            _vm._s(_vm.docs) + " " + _vm._s(_vm.removestatus)
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn ml-2 mb-2 secondary medium",
                            on: {
                              click: function ($event) {
                                return _vm.selectDoc()
                              },
                            },
                          },
                          [_vm._v("Select from Library")]
                        ),
                        _vm.docs.length < 1
                          ? _c(
                              "div",
                              {
                                class: [
                                  _vm.isActive
                                    ? "invalid text-right w-100 mr-2"
                                    : "text-right w-100 mr-2",
                                ],
                                style: [
                                  _vm.isActive
                                    ? { display: "block" }
                                    : { display: "none" },
                                ],
                              },
                              [_vm._v("Add Pdf field is required")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _vm.docs.length > 0
                        ? _c("div", { staticClass: "form-pdf-group" }, [
                            _vm.docs[0].attributes.is_csv
                              ? _c(
                                  "div",
                                  {
                                    key: _vm.docs[0].id,
                                    staticClass: "form-pdf-item",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show(
                                              "csvPreview",
                                              {
                                                pdfUrl:
                                                  _vm.docs[0].attributes
                                                    .resource_url,
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../assets/images/csv_placeholder.png"),
                                            alt: "csv",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "close-pdf",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeDoc()
                                          },
                                        },
                                      },
                                      [_c("em", { staticClass: "icon-close" })]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.docs[0].attributes.is_pdf
                              ? _c(
                                  "div",
                                  {
                                    key: _vm.docs[0].id,
                                    staticClass: "form-pdf-item",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show("pdfModal", {
                                              pdfUrl:
                                                _vm.docs[0].attributes
                                                  .resource_url,
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../assets/images/pdf-big-icon.png"),
                                            alt: "pdf",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "close-pdf",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeDoc()
                                          },
                                        },
                                      },
                                      [_c("em", { staticClass: "icon-close" })]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.$route.query.id
                        ? _c(
                            "div",
                            {
                              staticClass: "btn-container justify-content-end",
                            },
                            [
                              _c("a", { staticClass: "delete-btn" }, [
                                _c("div", { staticClass: "delete-box" }, [
                                  _c("span", { staticClass: "icon-delete" }),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function ($event) {
                                        return _vm.archiveLO("archived")
                                      },
                                    },
                                  },
                                  [_vm._v("Archive this Learning Object(LO)")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("csv-preview"),
      _c("pdfModal"),
      _c("AddVideo"),
      _c(
        "modal",
        {
          attrs: {
            name: "sliderImages",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Upload Images")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("div", { staticClass: "modal-images-tab" }, [
                _c("a", { staticClass: "active", attrs: { href: "" } }, [
                  _vm._v("Library"),
                ]),
              ]),
              _c("form", { staticClass: "header-form" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchImg,
                        expression: "searchImg",
                      },
                      {
                        name: "debounce",
                        rawName: "v-debounce:700ms",
                        value: _vm.searchImage,
                        expression: "searchImage",
                        arg: "700ms",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      id: "exampleInputEmail1",
                      "aria-describedby": "emailHelp",
                      placeholder: "Search",
                    },
                    domProps: { value: _vm.searchImg },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchImg = $event.target.value
                      },
                    },
                  }),
                  _c("em", { staticClass: "icon-search" }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "modal-images-content" },
                [
                  _c(
                    "v-infinite-scroll",
                    {
                      staticClass: "modal-content-scroll",
                      attrs: { offset: 20 },
                      on: { bottom: _vm.nextImagesPage },
                    },
                    [
                      _c("div", { staticClass: "modal-images-library" }, [
                        _c(
                          "div",
                          { staticClass: "modal-images-row" },
                          _vm._l(_vm.imageLists, function (images) {
                            return _c(
                              "div",
                              {
                                key: images.id,
                                staticClass: "modal-images-col",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "modal-images-checkbox" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "custom-checkbox" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: images.attributes.status,
                                              expression:
                                                "images.attributes.status",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              images.attributes.status
                                            )
                                              ? _vm._i(
                                                  images.attributes.status,
                                                  null
                                                ) > -1
                                              : images.attributes.status,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  images.attributes.status,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      images.attributes,
                                                      "status",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      images.attributes,
                                                      "status",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  images.attributes,
                                                  "status",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "checkmark circle",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "modal-images-box" },
                                          [
                                            images.attributes.resource_url
                                              .large_version
                                              ? _c("img", {
                                                  attrs: {
                                                    src: images.attributes
                                                      .resource_url
                                                      .large_version,
                                                    alt: "image",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _vm.imageLists.length === 0
                    ? _c("div", { staticClass: "data-no-result" }, [
                        _c("h3", [_vm._v("No results found")]),
                        _c("p", [_vm._v("Try using a different keyword")]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.imageLists.length > 0
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelSliderGallery("sliderImages")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.$modal.hide("sliderImages")
                          _vm.saveImages()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "uploadDocument",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-pdf-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Upload a Pdf")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("form", { staticClass: "header-form" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchDoc,
                        expression: "searchDoc",
                      },
                      {
                        name: "debounce",
                        rawName: "v-debounce:700ms",
                        value: _vm.searchDocument,
                        expression: "searchDocument",
                        arg: "700ms",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      id: "exampleInputEmail1",
                      "aria-describedby": "emailHelp",
                      placeholder: "Search",
                    },
                    domProps: { value: _vm.searchDoc },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchDoc = $event.target.value
                      },
                    },
                  }),
                  _c("em", { staticClass: "icon-search" }),
                ]),
              ]),
              _vm.docLists.length > 0
                ? _c(
                    "div",
                    { staticClass: "modal-images-content" },
                    [
                      _c(
                        "v-infinite-scroll",
                        {
                          staticClass: "modal-content-scroll",
                          attrs: { offset: 20 },
                          on: { bottom: _vm.nextPage },
                        },
                        [
                          _c("div", { staticClass: "modal-images-library" }, [
                            _vm.docLists.length
                              ? _c(
                                  "div",
                                  { staticClass: "modal-images-row" },
                                  _vm._l(_vm.docLists, function (doc) {
                                    return _c(
                                      "div",
                                      {
                                        key: doc.id,
                                        staticClass: "modal-images-col",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "modal-images-checkbox",
                                            on: {
                                              click: function ($event) {
                                                _vm.$modal.hide(
                                                  "uploadDocument"
                                                )
                                                _vm.saveDoc(doc)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "custom-checkbox",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        doc.attributes.status,
                                                      expression:
                                                        "doc.attributes.status",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    name: "image",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      doc.attributes.status
                                                    )
                                                      ? _vm._i(
                                                          doc.attributes.status,
                                                          null
                                                        ) > -1
                                                      : doc.attributes.status,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          doc.attributes.status,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              doc.attributes,
                                                              "status",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              doc.attributes,
                                                              "status",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          doc.attributes,
                                                          "status",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "checkmark circle",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modal-images-box",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "modal-pdf-icon",
                                                      attrs: {
                                                        alt: "pdf",
                                                        src: require("../assets/images/pdf-big-icon.png"),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modal-images-info",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(
                                                          doc.attributes
                                                            .file_name
                                                        )
                                                      ),
                                                    ]),
                                                    doc.attributes.tag_list
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              doc.attributes
                                                                .tag_list
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("div", {
                                                  staticClass: "checked",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.docLists.length === 0
                ? _c("div", { staticClass: "data-no-result" }, [
                    _c("h3", [_vm._v("No results found")]),
                    _c("p", [_vm._v("Try using a different keyword")]),
                  ])
                : _vm._e(),
            ]),
            _vm.docLists.length > 0
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("uploadDocument")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.saveDoc()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          staticClass: "modal-video",
          attrs: {
            name: "videoPreview",
            height: "auto",
            width: 1000,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content video-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close ml-auto",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("videoPreview")
                    },
                  },
                },
                [_c("em", { staticClass: "icon-close" })]
              ),
            ]),
            _vm.elmMenu.card_type === "video"
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("heading")(_vm.$parent.learning_object_type)
                      ) +
                        " (" +
                        _vm._s(
                          _vm._f("uppercase")(_vm.elmMenu.card_type, true)
                        ) +
                        ") Preview"
                    ),
                  ]),
                  _c("h3", [_vm._v(_vm._s(_vm.title))]),
                  _c("p", [_vm._v(_vm._s(_vm.description))]),
                  _vm.video && _vm.video.attributes
                    ? _c(
                        "div",
                        { staticClass: "video-container introduction" },
                        [
                          _c("div", { staticClass: "introduction-video" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "wistia_responsive_wrapper data-video",
                                staticStyle: {
                                  height: "100%",
                                  left: "0",
                                  position: "absolute",
                                  top: "0",
                                  width: "100%",
                                },
                              },
                              [
                                _c("iframe", {
                                  staticClass: "wistia_embed",
                                  attrs: {
                                    src:
                                      _vm.preIframUrl +
                                      _vm.video.attributes.wistia_code +
                                      _vm.postIframUrl,
                                    title: "Video.mp4",
                                    name: "wistia_embed",
                                    allowtransparency: "true",
                                    scrolling: "no",
                                    allowfullscreen: "",
                                    mozallowfullscreen: "",
                                    webkitallowfullscreen: "",
                                    oallowfullscreen: "",
                                    msallowfullscreen: "",
                                    width: "100%",
                                    height: "100%",
                                  },
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "introduction-titles" }, [
                              _c("div", { staticClass: "introduction-intro" }, [
                                _c(
                                  "div",
                                  { staticClass: "introduction-time" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.video.attributes.duration)
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "introduction-title" },
                                  [_vm._v(_vm._s(_vm.name))]
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "intro-transcript" },
                            [
                              _c("h5", [_vm._v("Transcript:")]),
                              _c("read-more", {
                                attrs: {
                                  "more-str": "Read more",
                                  text: _vm.video.attributes.transcript,
                                  link: "#",
                                  "less-str": "Read less",
                                  "max-chars": 400,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.elmMenu.card_type === "slide"
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("heading")(_vm.$parent.learning_object_type)
                      ) +
                        " (" +
                        _vm._s(
                          _vm._f("uppercase")(_vm.elmMenu.card_type, true)
                        ) +
                        ") Preview"
                    ),
                  ]),
                  _c("h1", { staticClass: "learn-title" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                  _c("p", {
                    staticClass: "learn-info",
                    domProps: { innerHTML: _vm._s(_vm.description) },
                  }),
                  _c(
                    "div",
                    { staticClass: "introduction" },
                    [
                      _vm.slider.length
                        ? _c(
                            "carousel",
                            {
                              attrs: {
                                margin: 0,
                                nav: true,
                                navText: [],
                                autoplay: false,
                                autoHeight: false,
                                items: 1,
                                dots: false,
                                loop: true,
                              },
                            },
                            _vm._l(_vm.slider, function (img, i) {
                              return _c("div", { key: img.id }, [
                                _vm.imageCaption
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "introduction-video-text",
                                      },
                                      [_vm._v(_vm._s(img.title))]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "introduction-video" },
                                  [
                                    _c("img", {
                                      staticClass: "intro-slide-video",
                                      attrs: { src: img.image, alt: "mask" },
                                    }),
                                    _c("div", {
                                      staticClass: "introduction-intro-top",
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "introduction-intro-bottom",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "introduction-intro-count",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(i + 1) +
                                                "/" +
                                                _vm._s(_vm.slider.length)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "introduction-progress dashboard-progress-bar",
                                  },
                                  [
                                    _c("div", { staticClass: "progress" }, [
                                      _c("div", {
                                        staticClass: "progress-bar",
                                        style: {
                                          width: [
                                            ((i + 1) * 100) / _vm.image.length +
                                              "%",
                                          ],
                                        },
                                        attrs: {
                                          role: "progressbar",
                                          "aria-valuenow": "0",
                                          "aria-valuemin": "0",
                                          "aria-valuemax": "100",
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.elmMenu.card_type === "text"
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("heading")(_vm.$parent.learning_object_type)
                      ) +
                        " (" +
                        _vm._s(
                          _vm._f("uppercase")(_vm.elmMenu.card_type, true)
                        ) +
                        ") Preview"
                    ),
                  ]),
                  _c("h1", [_vm._v(_vm._s(_vm.name))]),
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.contentDescription) },
                  }),
                  _c("p", { domProps: { innerHTML: _vm._s(_vm.textBody) } }),
                ])
              : _vm._e(),
            _vm.elmMenu.card_type === "email"
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("heading")(_vm.$parent.learning_object_type)
                      ) +
                        " (" +
                        _vm._s(
                          _vm._f("uppercase")(_vm.elmMenu.card_type, true)
                        ) +
                        ") Preview"
                    ),
                  ]),
                  _c("h1", { staticClass: "learn-title" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                  _c("p", { staticClass: "learn-info" }, [
                    _vm._v(_vm._s(_vm.description)),
                  ]),
                  _c("div", { staticClass: "learn-object" }, [
                    _c("div", { staticClass: "learn-object-forward" }, [
                      _c(
                        "div",
                        { staticClass: "learn-object-head" },
                        [
                          _vm._l(_vm.to_characters, function (character) {
                            return _c(
                              "div",
                              {
                                key: character.id + "test1",
                                staticClass: "learn-object-col",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "learn-object-label" },
                                  [_vm._v("To")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "learn-object-titles" },
                                  [
                                    _c("h5", [
                                      _vm._v(_vm._s(character.char_full_name)),
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(character.world_role)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm._l(_vm.cc_characters, function (character) {
                            return _c(
                              "div",
                              {
                                key: character.id + "test",
                                staticClass: "learn-object-col",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "learn-object-label" },
                                  [_vm._v("CC")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "learn-object-titles" },
                                  [
                                    _c("h5", [
                                      _vm._v(_vm._s(character.char_full_name)),
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(character.world_role)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "learn-object-mail" }, [
                        _c("div", { staticClass: "learn-object-tag" }, [
                          _vm._v("Subject: " + _vm._s(_vm.title)),
                        ]),
                        _c("div", { staticClass: "learn-object-paragraph" }, [
                          _c("div", { staticClass: "learn-object-desc" }, [
                            _c("p", {
                              domProps: { innerHTML: _vm._s(_vm.transcript) },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.elmMenu.card_type === "file"
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("heading")(_vm.$parent.learning_object_type)
                      ) +
                        " (" +
                        _vm._s(
                          _vm._f("uppercase")(_vm.elmMenu.card_type, true)
                        ) +
                        ") Preview"
                    ),
                  ]),
                  _c("h1", { staticClass: "learn-title" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                  _c("p", {
                    staticClass: "learn-info",
                    domProps: { innerHTML: _vm._s(_vm.description) },
                  }),
                  _vm.docs.length > 0
                    ? _c("div", { staticClass: "learn-docs-btn" }, [
                        _c("a", { staticClass: "docs-btn" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.docs[0].attributes.file_name
                                  ? _vm.docs[0].attributes.file_name
                                  : _vm.$parent.selectedMenu.attributes
                                      .card_detail.attributes.resource_file_name
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm.elmMenu.card_type === "quiz"
        ? _c("div", { staticClass: "form-section" }, [
            _c("div", { staticClass: "learning-form-header flex-wrap" }, [
              _c("h4", [_vm._v("Interaction(Quiz)")]),
              _c("div", { staticClass: "learning-quiz-btn" }, [
                _vm.status == "published"
                  ? _c(
                      "a",
                      {
                        staticClass: "btn header-btn secondary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.submitLo("published")
                          },
                        },
                      },
                      [_vm._v("Save")]
                    )
                  : _vm._e(),
                _vm.status == "drafted"
                  ? _c(
                      "a",
                      {
                        staticClass: "btn header-btn primary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.submitLo("published")
                          },
                        },
                      },
                      [_vm._v("Publish LO")]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn ml-2 secondary",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("cloneConfirmationModal")
                      },
                    },
                  },
                  [_vm._v("Clone LO")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "learning-lo-tabs" }, [
              _c("div", { staticClass: "setup-container" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[0] },
                      on: {
                        click: function ($event) {
                          return _vm.handleQuizContent(
                            $event,
                            "basic-information",
                            0
                          )
                        },
                      },
                    },
                    [_vm._m(0)]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[1] },
                      on: {
                        click: function ($event) {
                          return _vm.handleQuizContent(
                            $event,
                            "entity-setup",
                            1
                          )
                        },
                      },
                    },
                    [_vm._m(1)]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[2] },
                      on: {
                        click: function ($event) {
                          return _vm.handleQuizContent(
                            $event,
                            "question-setup",
                            2
                          )
                        },
                      },
                    },
                    [_vm._m(2)]
                  ),
                  _vm.overallQuizAssessment
                    ? _c(
                        "li",
                        {
                          class: { active: _vm.isActiveTab[3] },
                          on: {
                            click: function ($event) {
                              return _vm.handleQuizContent(
                                $event,
                                "overall-assessment",
                                3
                              )
                            },
                          },
                        },
                        [_vm._m(3)]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "learning-quiz-content" },
              [
                _vm.quizContentId === "basic-information"
                  ? _c(
                      "div",
                      {
                        staticClass: "setup-content",
                        attrs: { id: "basic-information" },
                      },
                      [
                        _vm._m(4),
                        _c("ValidationObserver", { ref: "form" }, [
                          _c("form", { staticClass: "w-100" }, [
                            _c("div", { staticClass: "row custom-row" }, [
                              _c("div", { staticClass: "col-sm-5" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", [_vm._v("Interaction Title")]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Interaction Title",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.name,
                                                      expression: "name",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: { type: "text" },
                                                  domProps: { value: _vm.name },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.name =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        268414483
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", [
                                      _vm._v("Administrative Notes"),
                                    ]),
                                    _c(
                                      "ValidationProvider",
                                      { attrs: { name: "Description" } },
                                      [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.adminNotes,
                                              expression: "adminNotes",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "Description",
                                            id: "Description",
                                            rows: "8",
                                          },
                                          domProps: { value: _vm.adminNotes },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.adminNotes =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Display points as:"),
                                    ]),
                                    _c("ValidationProvider", [
                                      _c(
                                        "div",
                                        { staticClass: "form-checkboxes" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "quiz-checboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                  attrs: { name: "public" },
                                                },
                                                [
                                                  _vm._v(" Numeric Score "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.scoreViewType,
                                                        expression:
                                                          "scoreViewType",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "public",
                                                    },
                                                    domProps: {
                                                      value: "numeric",
                                                      checked: _vm._q(
                                                        _vm.scoreViewType,
                                                        "numeric"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.scoreViewType =
                                                          "numeric"
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "checkmark circle",
                                                    attrs: { for: "public" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "quiz-checboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                  attrs: { name: "private" },
                                                },
                                                [
                                                  _vm._v(" Percentage "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.scoreViewType,
                                                        expression:
                                                          "scoreViewType",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "private",
                                                    },
                                                    domProps: {
                                                      value: "percentage",
                                                      checked: _vm._q(
                                                        _vm.scoreViewType,
                                                        "percentage"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.scoreViewType =
                                                          "percentage"
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "checkmark circle",
                                                    attrs: { for: "private" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "quiz-checboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                  attrs: { name: "tally" },
                                                },
                                                [
                                                  _vm._v(
                                                    " Tally correct answers "
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.scoreViewType,
                                                        expression:
                                                          "scoreViewType",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "radio",
                                                      name: "tally",
                                                    },
                                                    domProps: {
                                                      value:
                                                        "tally_correct_ans",
                                                      checked: _vm._q(
                                                        _vm.scoreViewType,
                                                        "tally_correct_ans"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.scoreViewType =
                                                          "tally_correct_ans"
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "checkmark circle",
                                                    attrs: { for: "tally" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      !_vm.scoreViewType
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                "Display points field is required"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-sm-7" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          class: [
                                            _vm.editWorldStatus
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: { name: "userOverall" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.overallQuizAssessment,
                                                expression:
                                                  "overallQuizAssessment",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.editWorldStatus == true,
                                              type: "checkbox",
                                              name: "userOverall",
                                            },
                                            domProps: {
                                              value: "userOverall",
                                              checked: Array.isArray(
                                                _vm.overallQuizAssessment
                                              )
                                                ? _vm._i(
                                                    _vm.overallQuizAssessment,
                                                    "userOverall"
                                                  ) > -1
                                                : _vm.overallQuizAssessment,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.overallQuizAssessment,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = "userOverall",
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.overallQuizAssessment =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.overallQuizAssessment =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.overallQuizAssessment =
                                                      $$c
                                                  }
                                                },
                                                _vm.handleOverAllAssChange,
                                              ],
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "userOverall" },
                                          }),
                                          _vm._v(
                                            " Use overall interaction assessment? "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm.overallQuizAssessment
                                  ? _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("label", [
                                          _vm._v("Select assessment scheme"),
                                        ]),
                                        _c(
                                          "ValidationProvider",
                                          {
                                            attrs: {
                                              name: "Assessment Scheme",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "quiz-dropdown" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary dropdown-toggle",
                                                    class: [
                                                      _vm.isActive &&
                                                      !_vm.assessmentListKey
                                                        .id &&
                                                      _vm.overallQuizAssessment
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    attrs: {
                                                      type: "button",
                                                      id: "entity",
                                                      "data-toggle": "dropdown",
                                                      "aria-haspopup": "true",
                                                      "aria-expanded": "false",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.assessmentListKey
                                                            .name
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("em", {
                                                      staticClass:
                                                        "icon-carrot-down",
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-menu",
                                                    attrs: {
                                                      "aria-labelledby":
                                                        "entity",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        staticClass:
                                                          "dropdown-item",
                                                        domProps: {
                                                          value: `None*${null}`,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectedAssessment(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("None")]
                                                    ),
                                                    _vm._l(
                                                      _vm.assessmentLists,
                                                      function (assessment) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: assessment.id,
                                                            staticClass:
                                                              "dropdown-item",
                                                            domProps: {
                                                              value: `${assessment.id}*${assessment.attributes.name}`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectedAssessment(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                assessment
                                                                  .attributes
                                                                  .name
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                            !_vm.assessmentListKey.id &&
                                            _vm.overallQuizAssessment
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Assessment Scheme field is required"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.overallQuizAssessment
                                  ? _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c("label", [
                                            _vm._v("Competency code"),
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.competencyCode,
                                                expression: "competencyCode",
                                              },
                                            ],
                                            staticClass:
                                              "form-control form-control-md ml-3",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: _vm.competencyCode,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.competencyCode =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "form-group pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          class: [
                                            _vm.editWorldStatus
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: { name: "quizAssessment" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.overallAssessment,
                                                expression: "overallAssessment",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.editWorldStatus == true,
                                              type: "checkbox",
                                              name: "quizAssessment",
                                            },
                                            domProps: {
                                              value: "quizAssessment",
                                              checked: Array.isArray(
                                                _vm.overallAssessment
                                              )
                                                ? _vm._i(
                                                    _vm.overallAssessment,
                                                    "quizAssessment"
                                                  ) > -1
                                                : _vm.overallAssessment,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.overallAssessment,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "quizAssessment",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.overallAssessment =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.overallAssessment =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.overallAssessment = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "quizAssessment" },
                                          }),
                                          _vm._v(
                                            " Apply quiz assessment to overall module score "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-group pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          class: [
                                            _vm.editWorldStatus
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: { name: "quizQuestionOrder" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.quizQuestionOrder,
                                                expression: "quizQuestionOrder",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.editWorldStatus == true,
                                              type: "checkbox",
                                              name: "quizQuestionOrder",
                                            },
                                            domProps: {
                                              value: "quizQuestionOrder",
                                              checked: Array.isArray(
                                                _vm.quizQuestionOrder
                                              )
                                                ? _vm._i(
                                                    _vm.quizQuestionOrder,
                                                    "quizQuestionOrder"
                                                  ) > -1
                                                : _vm.quizQuestionOrder,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.quizQuestionOrder,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "quizQuestionOrder",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.quizQuestionOrder =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.quizQuestionOrder =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.quizQuestionOrder = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "quizQuestionOrder" },
                                          }),
                                          _vm._v(
                                            " Display questions to learner in sequential order "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-group pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          class: [
                                            _vm.editWorldStatus
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: { name: "quizHideFeedbak" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.quizHideFeedbak,
                                                expression: "quizHideFeedbak",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.editWorldStatus == true,
                                              type: "checkbox",
                                              name: "quizHideFeedbak",
                                            },
                                            domProps: {
                                              value: "quizHideFeedbak",
                                              checked: Array.isArray(
                                                _vm.quizHideFeedbak
                                              )
                                                ? _vm._i(
                                                    _vm.quizHideFeedbak,
                                                    "quizHideFeedbak"
                                                  ) > -1
                                                : _vm.quizHideFeedbak,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.quizHideFeedbak,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "quizHideFeedbak",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.quizHideFeedbak =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.quizHideFeedbak = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.quizHideFeedbak = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "quizHideFeedbak" },
                                          }),
                                          _vm._v(
                                            " Hide learner feedback and adaptive content "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-group pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          class: [
                                            _vm.editWorldStatus
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: {
                                            name: "showCorrectAdaptiveFeedback",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.showCorrectAdaptiveFeedback,
                                                expression:
                                                  "showCorrectAdaptiveFeedback",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.editWorldStatus == true,
                                              type: "checkbox",
                                              name: "showCorrectAdaptiveFeedback",
                                            },
                                            domProps: {
                                              value:
                                                "showCorrectAdaptiveFeedback",
                                              checked: Array.isArray(
                                                _vm.showCorrectAdaptiveFeedback
                                              )
                                                ? _vm._i(
                                                    _vm.showCorrectAdaptiveFeedback,
                                                    "showCorrectAdaptiveFeedback"
                                                  ) > -1
                                                : _vm.showCorrectAdaptiveFeedback,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.showCorrectAdaptiveFeedback,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v =
                                                      "showCorrectAdaptiveFeedback",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.showCorrectAdaptiveFeedback =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.showCorrectAdaptiveFeedback =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.showCorrectAdaptiveFeedback =
                                                    $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: {
                                              for: "showCorrectAdaptiveFeedback",
                                            },
                                          }),
                                          _vm._v(
                                            " Show adaptive feedback on correct answers "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-group pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-checkboxes d-inline-flex",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox right",
                                          attrs: { name: "quizHideFeedbak" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.hideAssessmentLabels,
                                                expression:
                                                  "hideAssessmentLabels",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "hideAssessmentLabel",
                                            },
                                            domProps: {
                                              value: "hideAssessmentLabels",
                                              checked: Array.isArray(
                                                _vm.hideAssessmentLabels
                                              )
                                                ? _vm._i(
                                                    _vm.hideAssessmentLabels,
                                                    "hideAssessmentLabels"
                                                  ) > -1
                                                : _vm.hideAssessmentLabels,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.hideAssessmentLabels,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v =
                                                      "hideAssessmentLabels",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.hideAssessmentLabels =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.hideAssessmentLabels =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.hideAssessmentLabels = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: {
                                              for: "hideAssessmentLabel",
                                            },
                                          }),
                                          _vm._v(" Hide assessment labels "),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "form-group group-editor" },
                                  [
                                    _c("label", [
                                      _vm._v("Learner Description"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Description",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("vue-editor", {
                                                  attrs: {
                                                    id: "Descriptiontext",
                                                    placeholder:
                                                      "Add a Description",
                                                    editorToolbar: _vm.fullTool,
                                                  },
                                                  model: {
                                                    value: _vm.description,
                                                    callback: function ($$v) {
                                                      _vm.description = $$v
                                                    },
                                                    expression: "description",
                                                  },
                                                }),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        320316617
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-end mb-4",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleQuizContent(
                                              $event,
                                              "entity-setup",
                                              1
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Next")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-container justify-content-end",
                                  },
                                  [
                                    _c("a", { staticClass: "delete-btn" }, [
                                      _c("div", { staticClass: "delete-box" }, [
                                        _c("span", {
                                          staticClass: "icon-delete",
                                        }),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text",
                                          on: {
                                            click: function ($event) {
                                              return _vm.archiveLO("archived")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Archive this Learning Object(LO)"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "deleteWorldModal",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [
                            _vm._v(
                              "Question fields are incomplete. Do you want to discard this question?"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide("deleteWorldModal")
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.discardQuestion(),
                                      _vm.$modal.hide("deleteWorldModal")
                                  },
                                },
                              },
                              [_vm._v("ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "deleteWorldModal2",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [_vm._v("your question is a multi row question?")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide("deleteWorldModal2")
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide("deleteWorldModal2")
                                  },
                                },
                              },
                              [_vm._v("ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "deleteEvalModal",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [
                            _vm._v(
                              "Are you sure you want to discard this evaluation?"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide("deleteEvalModal")
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.discardEvalModal()
                                  },
                                },
                              },
                              [_vm._v("ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "deleteEvalModalMultiRow",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [
                            _vm._v(
                              "Are you sure you want to discard this evaluation?"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide(
                                      "deleteEvalModalMultiRow"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.discardEvalModalMultiRow()
                                  },
                                },
                              },
                              [_vm._v("ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "rowNumberLowModal",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [_vm._v("The number of rows cannot be lower than 2")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.discardLowNumberModal()
                                  },
                                },
                              },
                              [_vm._v("ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "sameEntitySetExist",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [_vm._v("The Same Entity Evaluation Set Exists")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.discardSameEntitySetExist()
                                  },
                                },
                              },
                              [_vm._v("ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "removeRowModal",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [_vm._v("Are you sure you want to discard this row?")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide("removeRowModal")
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeRow()
                                  },
                                },
                              },
                              [_vm._v("ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "cloneConfirmationModal",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [
                            _vm._v(
                              "Are you sure you want to clone this learning object?"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide(
                                      "cloneConfirmationModal"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.cloneLo(),
                                      _vm.$modal.hide("cloneConfirmationModal")
                                  },
                                },
                              },
                              [_vm._v("Ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.quizContentId === "entity-setup"
                  ? _c(
                      "div",
                      {
                        staticClass: "setup-content",
                        attrs: { id: "entity-setup" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("h4", { staticClass: "head-title" }, [
                              _vm._v("Entity Setup"),
                            ]),
                            _c("div", { staticClass: "learning-quiz-btn" }, [
                              _vm.quizContentId === "entity-setup"
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-auto header-btn secondary",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleImportCSV(
                                            $event,
                                            "dialog_skill"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Link to Assistant Dialog Skill")]
                                  )
                                : _vm._e(),
                              _vm.quizContentId === "entity-setup"
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-auto header-btn secondary",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleImportCSV(
                                            $event,
                                            "sync_assistant"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Sync with Assistant")]
                                  )
                                : _vm._e(),
                              _vm.quizContentId === "entity-setup"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn btn-auto header-btn secondary btn-type",
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "file" },
                                        on: { change: _vm.handleImportCSV },
                                      }),
                                      _vm._v(" Import CSV "),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _c("ValidationObserver", { ref: "form" }, [
                          _c("form", { staticClass: "w-100" }, [
                            _c("div", { staticClass: "row custom-row" }, [
                              _c("div", { staticClass: "col-sm-5" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { staticClass: "big-label" }, [
                                    _vm._v("Quiz entities in this question"),
                                  ]),
                                  _vm.asstEntitiesList
                                    ? _c(
                                        "ul",
                                        { staticClass: "form-entity-tabs" },
                                        _vm._l(
                                          _vm.asstEntitiesList,
                                          function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.id,
                                                class:
                                                  item.id ===
                                                  _vm.selectedAsstEntities.id
                                                    ? "active"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAsstEntitiesList(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.attributes &&
                                                            item.attributes.name
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("span", [
                                                      _c("em", {
                                                        staticClass:
                                                          "icon-close",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleAsstEntitiesList(
                                                              item,
                                                              true
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "form-group pt-3" }, [
                                  _c("label", [_vm._v("Add Quiz Entity")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.quizEntityName,
                                        expression: "quizEntityName",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", id: "name" },
                                    domProps: { value: _vm.quizEntityName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.quizEntityName = $event.target.value
                                      },
                                    },
                                  }),
                                  _c("div", { staticClass: "form-btn-group" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addQuizEntity($event)
                                          },
                                        },
                                      },
                                      [_vm._v("Add Quiz Entity")]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-sm-7" }, [
                                _c(
                                  "div",
                                  { staticClass: "entity-tabs-content mt-0" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-6 form-group" },
                                        [
                                          _c("label", [_vm._v("Value")]),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Value",
                                              rules:
                                                "required|max:100|entityValueNameRule",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.asstName,
                                                            expression:
                                                              "asstName",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: [
                                                          _vm.isActive &&
                                                          errors[0]
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value: _vm.asstName,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.asstName =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      errors[0]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: [
                                                                _vm.isActive
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              style: [
                                                                _vm.isActive
                                                                  ? {
                                                                      display:
                                                                        "block",
                                                                    }
                                                                  : {
                                                                      display:
                                                                        "none",
                                                                    },
                                                              ],
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0].replace(
                                                                    "The ",
                                                                    ""
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1104651667
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-6 form-group" },
                                        [
                                          _c("label", [_vm._v("Synonyms")]),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Synonyms",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.asstSynonyms,
                                                            expression:
                                                              "asstSynonyms",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: [
                                                          _vm.isActive &&
                                                          errors[0]
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            _vm.asstSynonyms,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.asstSynonyms =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      errors[0]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: [
                                                                _vm.isActive
                                                                  ? "invalid"
                                                                  : "",
                                                              ],
                                                              style: [
                                                                _vm.isActive
                                                                  ? {
                                                                      display:
                                                                        "block",
                                                                    }
                                                                  : {
                                                                      display:
                                                                        "none",
                                                                    },
                                                              ],
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0].replace(
                                                                    "The ",
                                                                    ""
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2490382803
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-12 form-group" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "add-more-btn",
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                              on: {
                                                click: _vm.addAssessmentValues,
                                              },
                                            },
                                            [_vm._v("Save Value")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "quiz-table-data" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "quiz-table-head row",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "quiz-tab-col col-4",
                                              },
                                              [_c("span", [_vm._v("Values")])]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "quiz-tab-col col-7",
                                              },
                                              [_c("span", [_vm._v("Synonyms")])]
                                            ),
                                          ]
                                        ),
                                        _vm.selectedAsstEntities
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "quiz-table-content",
                                              },
                                              _vm._l(
                                                _vm.selectedAsstEntities
                                                  .attributes
                                                  .asst_entity_values,
                                                function (asstValue) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: asstValue.id,
                                                      staticClass:
                                                        "quiz-table-list",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "quiz-tabs-row row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "quiz-tab-col col-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  asstValue
                                                                    .attributes
                                                                    .value
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "quiz-tab-col col-7",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleSynonymModal(
                                                                      asstValue
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "d-block",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getAssessmentValue(
                                                                        asstValue
                                                                          .attributes
                                                                          .asst_entity_val_synonyms
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "quiz-tab-col col-1 text-right",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "javascript:void(0);",
                                                                  },
                                                                },
                                                                [
                                                                  _c("em", {
                                                                    staticClass:
                                                                      "icon-close",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeAsstEntities(
                                                                            asstValue
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-end mt-4 mb-3",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleQuizContent(
                                              $event,
                                              "question-setup",
                                              2
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Next")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-container justify-content-end",
                                  },
                                  [
                                    _c("a", { staticClass: "delete-btn" }, [
                                      _c("div", { staticClass: "delete-box" }, [
                                        _c("span", {
                                          staticClass: "icon-delete",
                                        }),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text",
                                          on: {
                                            click: function ($event) {
                                              return _vm.archiveLO("archived")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Archive this Learning Object(LO)"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedSynonymList
                  ? _c(
                      "modal",
                      {
                        attrs: {
                          name: "enityValueModal",
                          height: "auto",
                          width: 500,
                          scrollable: true,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-content modal-default" },
                          [
                            _c("div", { staticClass: "modal-header" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-title",
                                  attrs: { id: "exampleModalLabel" },
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      "Entity value:" +
                                        _vm._s(
                                          _vm.selectedSynonymList.attributes
                                            .value
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "modal-body" }, [
                              _c(
                                "form",
                                {
                                  staticClass: "modal-form modal-min-height",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.updateAssessmentValues("add")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mt-2" },
                                    [
                                      _c("label", [_vm._v("Synonyms")]),
                                      _c(
                                        "ValidationProvider",
                                        {
                                          attrs: {
                                            name: "Assign subject matter expert",
                                            rules: "required",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.entitySynonymName,
                                                expression: "entitySynonymName",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: _vm.entitySynonymName,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.entitySynonymName =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "chip-container entity-chip",
                                        },
                                        _vm._l(
                                          _vm.selectedSynonymList.attributes
                                            .asst_entity_val_synonyms,
                                          function (synonym, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: synonym.id,
                                                staticClass: "chip",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      synonym.attributes.synonym
                                                    )
                                                  ),
                                                ]),
                                                _c("em", {
                                                  staticClass: "icon-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateAssessmentValues(
                                                        "delete",
                                                        index,
                                                        synonym
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn default",
                                        attrs: {
                                          type: "button",
                                          "data-dismiss": "modal",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.hide(
                                              "enityValueModal"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateAssessmentValues(
                                              "done"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Done")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.quizContentId === "question-setup"
                  ? _c(
                      "div",
                      {
                        staticClass: "setup-content",
                        attrs: { id: "question-setup" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("h4", { staticClass: "head-title" }, [
                              _vm._v("Question Setup"),
                            ]),
                            _c("div", { staticClass: "learning-quiz-btn" }, [
                              _vm.quizContentId === "question-setup"
                                ? _c(
                                    "a",
                                    {
                                      class: `btn header-btn secondary ${
                                        _vm.allQuizQuestionTestList.length > 0
                                          ? ""
                                          : "disabled"
                                      }`,
                                      on: {
                                        click: _vm.handleselectQuestionToTest,
                                      },
                                    },
                                    [_vm._v("Test")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _c("ValidationObserver", { ref: "form" }, [
                          _c("form", { staticClass: "w-100" }, [
                            _c("div", { staticClass: "row custom-row" }, [
                              _c("div", { staticClass: "col-sm-5" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "ul",
                                    { staticClass: "form-entity-tabs" },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          attrs: {
                                            sort: true,
                                            move: _vm.reorderQuizQuestions,
                                          },
                                          on: {
                                            start: function ($event) {
                                              _vm.isDragging = true
                                            },
                                            end: function ($event) {
                                              _vm.isDragging = true
                                            },
                                          },
                                          model: {
                                            value: _vm.quizQuestionList,
                                            callback: function ($$v) {
                                              _vm.quizQuestionList = $$v
                                            },
                                            expression: "quizQuestionList",
                                          },
                                        },
                                        _vm._l(
                                          _vm.quizQuestionList,
                                          function (questionList) {
                                            return _c(
                                              "li",
                                              {
                                                key: questionList.id,
                                                class:
                                                  !_vm.isQuestionCreated &&
                                                  questionList.id ===
                                                    _vm.selectedQuizQuestion.id
                                                    ? "active"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleQuizQuestionSelect(
                                                      questionList
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          questionList
                                                            .attributes.order
                                                        ) +
                                                        ": " +
                                                        _vm._s(
                                                          _vm.removeQuestionTags(
                                                            questionList
                                                              .attributes
                                                              .question
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("span", [
                                                      _c("em", {
                                                        staticClass:
                                                          "icon-close",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$modal.show(
                                                              "deleteQstnConfirmationModal"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "form-btn-group" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-full primary",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: _vm.showSelectQuestionModal,
                                      },
                                    },
                                    [_vm._v("Add Question")]
                                  ),
                                ]),
                              ]),
                              this.selectedQuizQuestion ||
                              this.isQuestionCreated
                                ? _c("div", { staticClass: "col-sm-7" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "nav nav-tabs",
                                        attrs: {
                                          id: "nav-tab",
                                          role: "tablist",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            class: `nav-item nav-link ${
                                              _vm.ariaSelected ? "" : "active"
                                            }`,
                                            attrs: {
                                              id: "question-tab",
                                              "data-toggle": "tab",
                                              href: "#nav-question",
                                              role: "tab",
                                              "aria-controls": "nav-question",
                                              "aria-selected": _vm.ariaSelected
                                                ? "false"
                                                : "true",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleFeedbackChange(
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("1. Question Setup")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            class: `nav-item nav-link ${
                                              _vm.ariaSelected ? "active" : ""
                                            }`,
                                            attrs: {
                                              id: "feedback-tab",
                                              "data-toggle": "tab",
                                              href: "#nav-feedback",
                                              role: "tab",
                                              "aria-controls": "nav-feedback",
                                              "aria-selected": _vm.ariaSelected
                                                ? "true"
                                                : "false",
                                            },
                                          },
                                          [_vm._v("2. Feedback")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-item nav-link",
                                            attrs: {
                                              id: "adaptive-tab",
                                              "data-toggle": "tab",
                                              href: "#nav-adaptive",
                                              role: "tab",
                                              "aria-controls": "nav-adaptive",
                                              "aria-selected": "false",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleFeedbackChange(
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("3. Adaptive configuration")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-content",
                                        attrs: { id: "nav-tabContent" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: `tab-pane fade ${
                                              _vm.ariaSelected
                                                ? ""
                                                : "show active"
                                            }`,
                                            attrs: {
                                              id: "nav-question",
                                              role: "tabpanel",
                                              "aria-labelledby": "question-tab",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "question-content",
                                                attrs: {
                                                  id: "multiple-choice",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "question-row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Question"),
                                                        ]),
                                                        _c("vue-editor", {
                                                          attrs: {
                                                            id: "Descriptiontext",
                                                            placeholder:
                                                              "Add a Description",
                                                            editorToolbar:
                                                              _vm.fullTool,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.questionValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.questionValue =
                                                                $$v
                                                            },
                                                            expression:
                                                              "questionValue",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm.questionType ===
                                                "multiple_choice"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "quiz-table-data pt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "quiz-table-head row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col col-8",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Possible answers"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-tab-col quiz-center col-3",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Correct Answer"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "quiz-table-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "quiz-table-list",
                                                              },
                                                              _vm._l(
                                                                _vm.multipleChoiceAnswer,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: item.id,
                                                                      staticClass:
                                                                        "quiz-tabs-row row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "quiz-tab-col quiz-text col-8",
                                                                        },
                                                                        [
                                                                          !item.isActive
                                                                            ? _c(
                                                                                "a",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.handleMcqQuesSelect(
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.option
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          item.isActive
                                                                            ? _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          item.option,
                                                                                        expression:
                                                                                          "item.option",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "form-control",
                                                                                  attrs:
                                                                                    {
                                                                                      id: item.id,
                                                                                      type: "text",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        item.option,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "option",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "quiz-tab-col quiz-center col-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "custom-checkbox",
                                                                              attrs:
                                                                                {
                                                                                  name: "public",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm.selectedMultipleChoiceAnswer,
                                                                                        expression:
                                                                                          "selectedMultipleChoiceAnswer",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      type: "radio",
                                                                                      name: "public",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        item.id,
                                                                                      checked:
                                                                                        _vm._q(
                                                                                          _vm.selectedMultipleChoiceAnswer,
                                                                                          item.id
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      [
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.selectedMultipleChoiceAnswer =
                                                                                            item.id
                                                                                        },
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.changeMultipleChoiceAnswer(
                                                                                            index
                                                                                          )
                                                                                        },
                                                                                      ],
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "checkmark circle",
                                                                                  attrs:
                                                                                    {
                                                                                      for: "public",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "quiz-tab-col col-1 text-right",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  href: "javascript:void(0);",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleMcqAnswersAdded(
                                                                                      index,
                                                                                      true
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "em",
                                                                                {
                                                                                  staticClass:
                                                                                    "icon-close",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group pt-3",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "add-more-btn",
                                                                attrs: {
                                                                  href: "javascript:void(0);",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.handleMcqAnswersAdded,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "+ Add Answer"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.questionType === "numeric"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Answer",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Answer"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.numericQuestionAnswer,
                                                                                expression:
                                                                                  "numericQuestionAnswer",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          class:
                                                                            [
                                                                              _vm.isActive &&
                                                                              errors[0]
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.numericQuestionAnswer,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.numericQuestionAnswer =
                                                                                  $event.target.value
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      errors[0]
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              style:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? {
                                                                                        display:
                                                                                          "block",
                                                                                      }
                                                                                    : {
                                                                                        display:
                                                                                          "none",
                                                                                      },
                                                                                ],
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0].replace(
                                                                                    "The ",
                                                                                    ""
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              1533115844
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.questionType ===
                                                "long_answer"
                                                  ? _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group mb-3",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Correct answer entity evaluation set(s):"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "aiText-data",
                                                            },
                                                            _vm._l(
                                                              _vm.selectedEvaulationScheme,
                                                              function (
                                                                scheme,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                    staticClass:
                                                                      "aiText-data-list",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "aiText-left",
                                                                      },
                                                                      _vm._l(
                                                                        scheme,
                                                                        function (
                                                                          item,
                                                                          i2
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: i2,
                                                                              staticClass:
                                                                                "aiText-col",
                                                                            },
                                                                            [
                                                                              item.isAll
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-caption",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Entity:" +
                                                                                          _vm._s(
                                                                                            item.entity
                                                                                          )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              !item.isAll
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-caption",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Entity:" +
                                                                                          _vm._s(
                                                                                            item.entity
                                                                                          ) +
                                                                                          ", Value:" +
                                                                                          _vm._s(
                                                                                            item.value
                                                                                          )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              i2 +
                                                                                1 !==
                                                                              scheme.length
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "aiText-col",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "and"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "aiText-delete",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "mx-2",
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:void(0);",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "em",
                                                                              {
                                                                                staticClass:
                                                                                  "icon-edit",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editEvaluationSet(
                                                                                        scheme,
                                                                                        index
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                href: "javascript:void(0);",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "em",
                                                                              {
                                                                                staticClass:
                                                                                  "icon-close",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.removeEvaluationSet(
                                                                                        index,
                                                                                        false
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group pb-4",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "add-more-btn",
                                                              attrs: {
                                                                href: "javascript:void(0);",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.showSchemeModal,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "+ Add Evaluation Set"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Correct custom assessment code: "
                                                              ),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.correctCustomAssessmentCode,
                                                                  expression:
                                                                    "correctCustomAssessmentCode",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.correctCustomAssessmentCode,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.correctCustomAssessmentCode =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]),
                                                          _c("br"),
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Incorrect custom assessment code: "
                                                              ),
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.incorrectCustomAssessmentCode,
                                                                  expression:
                                                                    "incorrectCustomAssessmentCode",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.incorrectCustomAssessmentCode,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.incorrectCustomAssessmentCode =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _c("br"),
                                                    ])
                                                  : _vm._e(),
                                                _vm.questionType ===
                                                "ai_text_rows"
                                                  ? _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ai-text-rows-container",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ai-row ai-rows-header border-bottom-solid",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pr-4",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Correct rows to pass: "
                                                                  ),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model.number",
                                                                          value:
                                                                            _vm.correctRowsPass,
                                                                          expression:
                                                                            "correctRowsPass",
                                                                          modifiers:
                                                                            {
                                                                              number: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "custom-input-style",
                                                                    attrs: {
                                                                      type: "number",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm.correctRowsPass,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.checkCorrectRowsPass,
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.correctRowsPass =
                                                                            _vm._n(
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                        },
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$forceUpdate()
                                                                      },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    " Evaluate rows in sequence "
                                                                  ),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm.checkRowsSequenceMultiRow,
                                                                          expression:
                                                                            "checkRowsSequenceMultiRow",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                      id: "checkboxMultiRow",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          _vm.checkRowsSequenceMultiRow
                                                                        )
                                                                          ? _vm._i(
                                                                              _vm.checkRowsSequenceMultiRow,
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : _vm.checkRowsSequenceMultiRow,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              _vm.checkRowsSequenceMultiRow,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                (_vm.checkRowsSequenceMultiRow =
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  ))
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                (_vm.checkRowsSequenceMultiRow =
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    ))
                                                                            }
                                                                          } else {
                                                                            _vm.checkRowsSequenceMultiRow =
                                                                              $$c
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "checkmark",
                                                                  }),
                                                                ]),
                                                              ]),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Correct row required?"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.multiRowsEntitiesEvaluation,
                                                            function (ro, inx) {
                                                              return _c(
                                                                "span",
                                                                { key: inx },
                                                                [
                                                                  !ro.isRemovedRow
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "ai-row ai-text-row",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "column-1",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      ro.id
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "column-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-group mb-3",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "label",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Correct answer entity evaluation set(s):"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "evaluation-set-row",
                                                                                    },
                                                                                    _vm._l(
                                                                                      ro.entityEvaluationRow,
                                                                                      function (
                                                                                        scheme,
                                                                                        index
                                                                                      ) {
                                                                                        return _c(
                                                                                          "div",
                                                                                          {
                                                                                            key: index,
                                                                                          },
                                                                                          [
                                                                                            !scheme.isRemovedEntitySet
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "aiText-data-list",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "aiText-left",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._l(
                                                                                                          scheme.evalValue,
                                                                                                          function (
                                                                                                            item,
                                                                                                            i3
                                                                                                          ) {
                                                                                                            return _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                key: i3,
                                                                                                                staticClass:
                                                                                                                  "aiText-col",
                                                                                                              },
                                                                                                              [
                                                                                                                item.isAll
                                                                                                                  ? _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "text-caption",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "Entity:" +
                                                                                                                            _vm._s(
                                                                                                                              item.entity
                                                                                                                            )
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                                !item.isAll
                                                                                                                  ? _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "text-caption",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "Entity:" +
                                                                                                                            _vm._s(
                                                                                                                              item.entity
                                                                                                                            ) +
                                                                                                                            ", Value:" +
                                                                                                                            _vm._s(
                                                                                                                              item.value
                                                                                                                            )
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                                i3 +
                                                                                                                  1 !==
                                                                                                                scheme
                                                                                                                  .evalValue
                                                                                                                  .length
                                                                                                                  ? _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "aiText-col",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "and"
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                              ]
                                                                                                            )
                                                                                                          }
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "aiText-delete",
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "a",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "mx-2",
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    href: "javascript:void(0);",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "em",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "icon-edit",
                                                                                                                    on: {
                                                                                                                      click:
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.editEvaluationSetMultiRow(
                                                                                                                            scheme,
                                                                                                                            ro.id
                                                                                                                          )
                                                                                                                        },
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "a",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    href: "javascript:void(0);",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "em",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "icon-close",
                                                                                                                    on: {
                                                                                                                      click:
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.removeEvaluationSetMultiRow(
                                                                                                                            ro.id,
                                                                                                                            index
                                                                                                                          )
                                                                                                                        },
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      2
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    0
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {},
                                                                                [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "add-more-btn",
                                                                                      attrs:
                                                                                        {
                                                                                          href: "javascript:void(0);",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.showSchemeModalMultiRow(
                                                                                              ro.id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "+ Add Evaluation Set"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {},
                                                                                    [
                                                                                      _c(
                                                                                        "a",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn btn-delete",
                                                                                          attrs:
                                                                                            {
                                                                                              href: "javascript:void(0);",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.deleteRow(
                                                                                                  ro.id
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Remove Row"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "column-3",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                {
                                                                                  staticClass:
                                                                                    "custom-checkbox right",
                                                                                  attrs:
                                                                                    {
                                                                                      name: "rowRequired",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "input",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              ro.rowRequired,
                                                                                            expression:
                                                                                              "ro.rowRequired",
                                                                                          },
                                                                                        ],
                                                                                      attrs:
                                                                                        {
                                                                                          type: "checkbox",
                                                                                          name: "rowRequired",
                                                                                          value:
                                                                                            "ro.rowRequired",
                                                                                        },
                                                                                      domProps:
                                                                                        {
                                                                                          checked:
                                                                                            Array.isArray(
                                                                                              ro.rowRequired
                                                                                            )
                                                                                              ? _vm._i(
                                                                                                  ro.rowRequired,
                                                                                                  "ro.rowRequired"
                                                                                                ) >
                                                                                                -1
                                                                                              : ro.rowRequired,
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            var $$a =
                                                                                                ro.rowRequired,
                                                                                              $$el =
                                                                                                $event.target,
                                                                                              $$c =
                                                                                                $$el.checked
                                                                                                  ? true
                                                                                                  : false
                                                                                            if (
                                                                                              Array.isArray(
                                                                                                $$a
                                                                                              )
                                                                                            ) {
                                                                                              var $$v =
                                                                                                  "ro.rowRequired",
                                                                                                $$i =
                                                                                                  _vm._i(
                                                                                                    $$a,
                                                                                                    $$v
                                                                                                  )
                                                                                              if (
                                                                                                $$el.checked
                                                                                              ) {
                                                                                                $$i <
                                                                                                  0 &&
                                                                                                  _vm.$set(
                                                                                                    ro,
                                                                                                    "rowRequired",
                                                                                                    $$a.concat(
                                                                                                      [
                                                                                                        $$v,
                                                                                                      ]
                                                                                                    )
                                                                                                  )
                                                                                              } else {
                                                                                                $$i >
                                                                                                  -1 &&
                                                                                                  _vm.$set(
                                                                                                    ro,
                                                                                                    "rowRequired",
                                                                                                    $$a
                                                                                                      .slice(
                                                                                                        0,
                                                                                                        $$i
                                                                                                      )
                                                                                                      .concat(
                                                                                                        $$a.slice(
                                                                                                          $$i +
                                                                                                            1
                                                                                                        )
                                                                                                      )
                                                                                                  )
                                                                                              }
                                                                                            } else {
                                                                                              _vm.$set(
                                                                                                ro,
                                                                                                "rowRequired",
                                                                                                $$c
                                                                                              )
                                                                                            }
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "checkmark",
                                                                                      attrs:
                                                                                        {
                                                                                          for: "rowRequired",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group pb-4 border-bottom-solid",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "add-more-btn",
                                                                  attrs: {
                                                                    href: "javascript:void(0);",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addNewRow()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "+ Add Row"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ai-text-rows-code",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Correct custom assessment code: "
                                                                    ),
                                                                  ]),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm.correctCustomAssessmentCode,
                                                                          expression:
                                                                            "correctCustomAssessmentCode",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      type: "text",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm.correctCustomAssessmentCode,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.correctCustomAssessmentCode =
                                                                            $event.target.value
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ai-text-rows-code",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Incorrect custom assessment code: "
                                                                    ),
                                                                  ]),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm.incorrectCustomAssessmentCode,
                                                                          expression:
                                                                            "incorrectCustomAssessmentCode",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      type: "text",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm.incorrectCustomAssessmentCode,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.incorrectCustomAssessmentCode =
                                                                            $event.target.value
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm.questionType === "range"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-content",
                                                        attrs: {
                                                          id: "number-range",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "question-row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Answer"
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "ValidationProvider",
                                                                  {
                                                                    attrs: {
                                                                      name: "Answer",
                                                                      rules:
                                                                        "required|max:100",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function ({
                                                                              errors,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "row mt-3",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "col-6",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "From"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm.questionLowRange,
                                                                                                  expression:
                                                                                                    "questionLowRange",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control",
                                                                                            class:
                                                                                              [
                                                                                                _vm.isActive &&
                                                                                                errors[0]
                                                                                                  ? "invalid"
                                                                                                  : "",
                                                                                              ],
                                                                                            attrs:
                                                                                              {
                                                                                                type: "number",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm.questionLowRange,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  if (
                                                                                                    $event
                                                                                                      .target
                                                                                                      .composing
                                                                                                  )
                                                                                                    return
                                                                                                  _vm.questionLowRange =
                                                                                                    $event.target.value
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "col-6",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "To"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm.questionHighRange,
                                                                                                  expression:
                                                                                                    "questionHighRange",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control",
                                                                                            class:
                                                                                              [
                                                                                                _vm.isActive &&
                                                                                                errors[0]
                                                                                                  ? "invalid"
                                                                                                  : "",
                                                                                              ],
                                                                                            attrs:
                                                                                              {
                                                                                                type: "number",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm.questionHighRange,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  if (
                                                                                                    $event
                                                                                                      .target
                                                                                                      .composing
                                                                                                  )
                                                                                                    return
                                                                                                  _vm.questionHighRange =
                                                                                                    $event.target.value
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                errors[0]
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        class:
                                                                                          [
                                                                                            _vm.isActive
                                                                                              ? "invalid"
                                                                                              : "",
                                                                                          ],
                                                                                        style:
                                                                                          [
                                                                                            _vm.isActive
                                                                                              ? {
                                                                                                  display:
                                                                                                    "block",
                                                                                                }
                                                                                              : {
                                                                                                  display:
                                                                                                    "none",
                                                                                                },
                                                                                          ],
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            errors[0].replace(
                                                                                              "The ",
                                                                                              ""
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        1063569422
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group pt-3 text-right",
                                                  },
                                                  [
                                                    _vm.isQuestionCreated
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "btn secondary",
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.createQuestion,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Create Question"
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "btn secondary",
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.saveQuestion,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Save Question"
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            class: `tab-pane fade ${
                                              _vm.ariaSelected
                                                ? "show active"
                                                : ""
                                            }`,
                                            attrs: {
                                              id: "nav-feedback",
                                              role: "tabpanel",
                                              "aria-labelledby": "feedback-tab",
                                            },
                                          },
                                          [
                                            !_vm.isQuestionCreated
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "feedback-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Points"),
                                                        ]),
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Points",
                                                              rules:
                                                                "required|max:100",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.questionPoints,
                                                                                expression:
                                                                                  "questionPoints",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          class:
                                                                            [
                                                                              _vm.isActive &&
                                                                              errors[0]
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.questionPoints,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.questionPoints =
                                                                                  $event.target.value
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      errors[0]
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              style:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? {
                                                                                        display:
                                                                                          "block",
                                                                                      }
                                                                                    : {
                                                                                        display:
                                                                                          "none",
                                                                                      },
                                                                                ],
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0].replace(
                                                                                    "The ",
                                                                                    ""
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              2283657555
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Correct Feedback Title"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Correct Feedback Title",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.correctFeedbackTitle,
                                                                                expression:
                                                                                  "correctFeedbackTitle",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          class:
                                                                            [
                                                                              _vm.isActive &&
                                                                              errors[0]
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "Add Correct Feedback Title",
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.correctFeedbackTitle,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.correctFeedbackTitle =
                                                                                  $event.target.value
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      errors[0]
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              style:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? {
                                                                                        display:
                                                                                          "block",
                                                                                      }
                                                                                    : {
                                                                                        display:
                                                                                          "none",
                                                                                      },
                                                                                ],
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0].replace(
                                                                                    "The ",
                                                                                    ""
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              904254544
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Correct Feedback"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Correct Feedback",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "vue-editor",
                                                                        {
                                                                          class:
                                                                            [
                                                                              _vm.isActive &&
                                                                              errors[0]
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              id: "Descriptiontext",
                                                                              placeholder:
                                                                                "Add a Description",
                                                                              editorToolbar:
                                                                                _vm.fullTool,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm.questionCorrectFeedback,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.questionCorrectFeedback =
                                                                                    $$v
                                                                                },
                                                                              expression:
                                                                                "questionCorrectFeedback",
                                                                            },
                                                                        }
                                                                      ),
                                                                      errors[0]
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              style:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? {
                                                                                        display:
                                                                                          "block",
                                                                                      }
                                                                                    : {
                                                                                        display:
                                                                                          "none",
                                                                                      },
                                                                                ],
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0].replace(
                                                                                    "The ",
                                                                                    ""
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              3557557302
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Incorrect Feedback Title"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Incorrect Feedback Title",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.inCorrectFeedbackTitle,
                                                                                expression:
                                                                                  "inCorrectFeedbackTitle",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          class:
                                                                            [
                                                                              _vm.isActive &&
                                                                              errors[0]
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "Add Incorrect Feedback Title",
                                                                              type: "text",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.inCorrectFeedbackTitle,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.inCorrectFeedbackTitle =
                                                                                  $event.target.value
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      errors[0]
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              style:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? {
                                                                                        display:
                                                                                          "block",
                                                                                      }
                                                                                    : {
                                                                                        display:
                                                                                          "none",
                                                                                      },
                                                                                ],
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0].replace(
                                                                                    "The ",
                                                                                    ""
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              1668813015
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Incorrect Feedback"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Incorrect Feedback",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "vue-editor",
                                                                        {
                                                                          class:
                                                                            [
                                                                              _vm.isActive &&
                                                                              errors[0]
                                                                                ? "invalid"
                                                                                : "",
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              id: "Descriptiontext",
                                                                              placeholder:
                                                                                "Add a Description",
                                                                              editorToolbar:
                                                                                _vm.fullTool,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm.questionIncorrectFeedback,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.questionIncorrectFeedback =
                                                                                    $$v
                                                                                },
                                                                              expression:
                                                                                "questionIncorrectFeedback",
                                                                            },
                                                                        }
                                                                      ),
                                                                      errors[0]
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              class:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? "invalid"
                                                                                    : "",
                                                                                ],
                                                                              style:
                                                                                [
                                                                                  _vm.isActive
                                                                                    ? {
                                                                                        display:
                                                                                          "block",
                                                                                      }
                                                                                    : {
                                                                                        display:
                                                                                          "none",
                                                                                      },
                                                                                ],
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0].replace(
                                                                                    "The ",
                                                                                    ""
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              2569081201
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    !_vm.isQuestionCreated
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pt-3 text-right",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "btn secondary",
                                                                attrs: {
                                                                  href: "javascript:void(0);",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.saveFeedback()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Save Feedback"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "note" },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Note: You can add feedback after you create the question. Please complete step 1."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tab-pane fade",
                                            attrs: {
                                              id: "nav-adaptive",
                                              role: "tabpanel",
                                              "aria-labelledby": "adaptive-tab",
                                            },
                                          },
                                          [
                                            !_vm.isQuestionCreated
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "small-title",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Add Adaptive Content"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "px-4 learning-quiz-btn",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "btn ml-2 px-4 secondary",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$modal.show(
                                                                  "uploadInterstitialModal",
                                                                  {
                                                                    create: true,
                                                                    content:
                                                                      "adaptive",
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Upload")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "note" },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Note: You can add adaptive content after you create the question. Please complete step 1."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                            _vm.interstitialLists.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learning-data-list flex-column pt-3",
                                                  },
                                                  _vm._l(
                                                    _vm.interstitialLists,
                                                    function (item, i) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "learning-data-item w-100 learning-interstitial-data-item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "learning-data-left",
                                                            },
                                                            [
                                                              item.attributes
                                                                .content_details
                                                                .type ==
                                                              "text_learn_obj"
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "learning-data-richText",
                                                                  })
                                                                : _vm._e(),
                                                              item.attributes
                                                                .content_details
                                                                .type ==
                                                              "file_learn_obj"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "learning-data-icon",
                                                                    },
                                                                    [
                                                                      _c("a", [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require("../assets/images/pdf-big-icon.png"),
                                                                                alt: "pdf",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.attributes
                                                                .content_details
                                                                .type ==
                                                              "slide_learn_obj"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "learning-data-icon",
                                                                    },
                                                                    [
                                                                      _c("a", [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: item
                                                                                  .attributes
                                                                                  .content_details
                                                                                  .attributes
                                                                                  .slider_images[0]
                                                                                  .attributes
                                                                                  .resource_url
                                                                                  .thumbnail,
                                                                                alt: "slide",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.attributes
                                                                .content_details
                                                                .type ==
                                                              "video_learn_obj"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "learning-data-video",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "wistia_responsive_padding data-video",
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "56.25% 0 0 0",
                                                                              position:
                                                                                "relative",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "wistia_responsive_wrapper data-video",
                                                                              staticStyle:
                                                                                {
                                                                                  height:
                                                                                    "100%",
                                                                                  left: "0",
                                                                                  position:
                                                                                    "absolute",
                                                                                  top: "0",
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "iframe",
                                                                                {
                                                                                  staticClass:
                                                                                    "wistia_embed",
                                                                                  attrs:
                                                                                    {
                                                                                      src:
                                                                                        _vm.preIframUrl +
                                                                                        item
                                                                                          .attributes
                                                                                          .content_details
                                                                                          .attributes
                                                                                          .global_video
                                                                                          .attributes
                                                                                          .wistia_code +
                                                                                        _vm.postIframUrl,
                                                                                      title:
                                                                                        "Video.mp4",
                                                                                      name: "wistia_embed",
                                                                                      allowtransparency:
                                                                                        "true",
                                                                                      scrolling:
                                                                                        "no",
                                                                                      allowfullscreen:
                                                                                        "",
                                                                                      mozallowfullscreen:
                                                                                        "",
                                                                                      webkitallowfullscreen:
                                                                                        "",
                                                                                      oallowfullscreen:
                                                                                        "",
                                                                                      msallowfullscreen:
                                                                                        "",
                                                                                      width:
                                                                                        "100%",
                                                                                      height:
                                                                                        "100%",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "learning-data-title",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "data-heads",
                                                                },
                                                                [
                                                                  _c("h4", {
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          item
                                                                            .attributes
                                                                            .title
                                                                        ),
                                                                    },
                                                                  }),
                                                                  item
                                                                    .attributes
                                                                    .content_details
                                                                    .type ==
                                                                  "file_learn_obj"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.size(
                                                                                item
                                                                                  .attributes
                                                                                  .content_details
                                                                                  .attributes
                                                                                  .resource_size
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c("h4", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      item
                                                                        .attributes
                                                                        .content_details
                                                                        .attributes
                                                                        .title
                                                                    ),
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "learning-data-paragraph",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c("p", {
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              item
                                                                                .attributes
                                                                                .description
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]),
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#3dbc9e",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$modal.show(
                                                                              "uploadInterstitialModal",
                                                                              {
                                                                                create: false,
                                                                                content:
                                                                                  "adaptive",
                                                                                edit: item,
                                                                              }
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Edit"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeAdaptiveData(
                                                                              item.id,
                                                                              i
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Remove"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-12" }, [
                                _vm.overallQuizAssessment
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end mt-4 mb-3",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleQuizContent(
                                                  $event,
                                                  "overall-assessment",
                                                  3
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Next")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.overallQuizAssessment
                                  ? _c("div", {
                                      staticClass:
                                        "d-flex justify-content-end mt-4 mb-3",
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-container justify-content-end",
                                  },
                                  [
                                    _c("a", { staticClass: "delete-btn" }, [
                                      _c("div", { staticClass: "delete-box" }, [
                                        _c("span", {
                                          staticClass: "icon-delete",
                                        }),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text",
                                          on: {
                                            click: function ($event) {
                                              return _vm.archiveLO("archived")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Archive this Learning Object(LO)"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "addQuestionType",
                      height: "auto",
                      width: 500,
                      scrollable: true,
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "hide-modal",
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("addQuestionType")
                        },
                      },
                    }),
                    _c("div", { staticClass: "modal-content modal-default" }, [
                      _c("div", { staticClass: "modal-header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "modal-title",
                            attrs: { id: "exampleModalLabel" },
                          },
                          [_c("h5", [_vm._v("Select Question Type")])]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "modal-body question-modal-body" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-6",
                                on: {
                                  click: function ($event) {
                                    return _vm.addQuestionType(
                                      "multiple_choice"
                                    )
                                  },
                                },
                              },
                              [
                                _c("a", { staticClass: "add-question-link" }, [
                                  _c("em", {
                                    staticClass: "icon-multiple-icon",
                                  }),
                                  _c("span", [_vm._v("Multiple Choice")]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-6",
                                on: {
                                  click: function ($event) {
                                    return _vm.addQuestionType("numeric")
                                  },
                                },
                              },
                              [
                                _c("a", { staticClass: "add-question-link" }, [
                                  _c("em", { staticClass: "icon-number-icon" }),
                                  _c("span", [_vm._v("Specific Number")]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-6",
                                on: {
                                  click: function ($event) {
                                    return _vm.addQuestionType("long_answer")
                                  },
                                },
                              },
                              [
                                _c("a", { staticClass: "add-question-link" }, [
                                  _c("em", {
                                    staticClass: "icon-question-icon",
                                  }),
                                  _c("span", [_vm._v("AI Text Questions")]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-6",
                                on: {
                                  click: function ($event) {
                                    return _vm.addQuestionType("range")
                                  },
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "add-question-link add-question-small",
                                  },
                                  [
                                    _c("em", {
                                      staticClass: "icon-range-icon",
                                    }),
                                    _c("span", [_vm._v("Number in Range")]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-6",
                                on: {
                                  click: function ($event) {
                                    return _vm.addQuestionType("ai_text_rows")
                                  },
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "add-question-link",
                                    on: {
                                      click:
                                        _vm.showSelectQuestionModalMultiRow,
                                    },
                                  },
                                  [
                                    _c("em", {
                                      staticClass: "icon-question-icon",
                                    }),
                                    _c("span", [_vm._v("AI Text Rows")]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "addQuestionrows",
                      height: "auto",
                      width: 500,
                      scrollable: true,
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "hide-modal",
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("addQuestionrows")
                        },
                      },
                    }),
                    _c("div", { staticClass: "modal-content modal-default" }, [
                      _c("div", { staticClass: "modal-header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "modal-title",
                            attrs: { id: "exampleModalLabel" },
                          },
                          [
                            _c("span", [
                              _vm._v("Number of Rows: "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.newRowNumbersSelected,
                                      expression: "newRowNumbersSelected",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.newRowNumbersSelected = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                _vm._l(_vm.RowNumbers, function (n) {
                                  return _c(
                                    "option",
                                    { key: n, domProps: { value: n } },
                                    [_vm._v(" " + _vm._s(n) + " ")]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]
                        ),
                        _c("br"),
                        _c("br"),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn primary",
                              on: {
                                click: function ($event) {
                                  _vm.$modal.hide("addQuestionrows")
                                  _vm.addQuestionType("ai_text_rows")
                                  _vm.multirowsQuestion()
                                },
                              },
                            },
                            [_vm._v("done")]
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "modal-body question-modal-body" },
                        [_c("div", { staticClass: "row" })]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "entityEvaluation",
                      height: "auto",
                      width: 700,
                      scrollable: true,
                    },
                  },
                  [
                    _vm.asstEntitiesQuizList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "modal-content modal-evaluation-content",
                          },
                          [
                            _c("div", { staticClass: "modal-header" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-title",
                                  attrs: { id: "exampleModalLabel" },
                                },
                                [_c("h5", [_vm._v("Entity Evaluation Set")])]
                              ),
                            ]),
                            _c("div", { staticClass: "modal-body" }, [
                              _c(
                                "form",
                                { staticClass: "modal-form modal-min-height" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row mt-3" },
                                    [
                                      _c(
                                        "VuePerfectScrollbar",
                                        {
                                          staticClass:
                                            "modal-evaluation-scroller",
                                          attrs: { settings: _vm.settings },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            _vm._l(
                                              _vm.asstEntitiesQuizList,
                                              function (evalItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: evalItem.id,
                                                    staticClass:
                                                      "evaluation-entity-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "evaluation-head",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            " Quiz Entity: "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                evalItem
                                                                  .attributes
                                                                  .name
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "evaluation-checkboxes",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "custom-checkbox",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Select All "
                                                                ),
                                                                _c("input", {
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleLabelEvalChange(
                                                                          $event,
                                                                          evalItem
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark circle",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "evaluation-values",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Values:"),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "evaluation-row",
                                                          },
                                                          _vm._l(
                                                            evalItem.attributes
                                                              .asst_entity_values,
                                                            function (valItem) {
                                                              return _c(
                                                                "span",
                                                                {
                                                                  key: valItem.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "drag",
                                                                    {
                                                                      staticClass:
                                                                        "drag",
                                                                      attrs: {
                                                                        "transfer-data":
                                                                          {
                                                                            entity:
                                                                              evalItem
                                                                                .attributes
                                                                                .name,
                                                                            value:
                                                                              valItem
                                                                                .attributes
                                                                                .value,
                                                                            isAll: false,
                                                                            asst_entity_value_id:
                                                                              valItem.id,
                                                                            entityId:
                                                                              evalItem.id,
                                                                            valItem,
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          valItem
                                                                            .attributes
                                                                            .value
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "VuePerfectScrollbar",
                                        {
                                          staticClass:
                                            "modal-evaluation-scroller",
                                          attrs: { settings: _vm.settings },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c(
                                                "drop",
                                                {
                                                  staticClass: "drop",
                                                  on: {
                                                    drop: _vm.handleEvaluationDrop,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "evaluation-dragbox",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .selectedEvalValue
                                                                .length == 0
                                                                ? "Drag and Drop values here"
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "evaluation-chips-container",
                                                        },
                                                        _vm._l(
                                                          _vm.selectedEvalValue,
                                                          function (
                                                            scheme,
                                                            key
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: key,
                                                                staticClass:
                                                                  "evaluation-chips",
                                                              },
                                                              [
                                                                scheme.isAll
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "Quiz entity:" +
                                                                          _vm._s(
                                                                            scheme.entity
                                                                          )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                !scheme.isAll
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "Quiz entity:" +
                                                                          _vm._s(
                                                                            scheme.entity
                                                                          ) +
                                                                          ", Value:" +
                                                                          _vm._s(
                                                                            scheme.value
                                                                          )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: "javascript:void(0);",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("em", {
                                                                      staticClass:
                                                                        "icon-close",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeEvaluationSet(
                                                                              key,
                                                                              true,
                                                                              scheme.evalId,
                                                                              scheme.entityEvaluationId
                                                                            )
                                                                          },
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn default",
                                        attrs: {
                                          type: "button",
                                          "data-dismiss": "modal",
                                        },
                                        on: { click: _vm.hideEvalMOdal },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        attrs: { type: "button" },
                                        on: { click: _vm.saveEvaulationScheme },
                                      },
                                      [_vm._v("Save")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "editEntityEvaluationMultiRow",
                      height: "auto",
                      width: 700,
                      scrollable: true,
                    },
                  },
                  [
                    _vm.asstEntitiesQuizList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "modal-content modal-evaluation-content",
                          },
                          [
                            _c("div", { staticClass: "modal-header" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-title",
                                  attrs: { id: "exampleModalLabel" },
                                },
                                [_c("h5", [_vm._v("Entity Evaluation Set")])]
                              ),
                            ]),
                            _c("div", { staticClass: "modal-body" }, [
                              _c(
                                "form",
                                { staticClass: "modal-form modal-min-height" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row mt-3" },
                                    [
                                      _c(
                                        "VuePerfectScrollbar",
                                        {
                                          staticClass:
                                            "modal-evaluation-scroller",
                                          attrs: { settings: _vm.settings },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            _vm._l(
                                              _vm.asstEntitiesQuizList,
                                              function (evalItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: evalItem.id,
                                                    staticClass:
                                                      "evaluation-entity-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "evaluation-head",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            " Quiz Entity: "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                evalItem
                                                                  .attributes
                                                                  .name
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "evaluation-checkboxes",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "custom-checkbox",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Select All "
                                                                ),
                                                                _c("input", {
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleLabelEvalChangeMultiRow(
                                                                          $event,
                                                                          evalItem
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark circle",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "evaluation-values",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Values:"),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "evaluation-row",
                                                          },
                                                          _vm._l(
                                                            evalItem.attributes
                                                              .asst_entity_values,
                                                            function (valItem) {
                                                              return _c(
                                                                "span",
                                                                {
                                                                  key: valItem.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "drag",
                                                                    {
                                                                      staticClass:
                                                                        "drag",
                                                                      attrs: {
                                                                        "transfer-data":
                                                                          {
                                                                            entity:
                                                                              evalItem
                                                                                .attributes
                                                                                .name,
                                                                            value:
                                                                              valItem
                                                                                .attributes
                                                                                .value,
                                                                            isAll: false,
                                                                            asst_entity_value_id:
                                                                              valItem.id,
                                                                            entityId:
                                                                              evalItem.id,
                                                                            valItem,
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          valItem
                                                                            .attributes
                                                                            .value
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "VuePerfectScrollbar",
                                        {
                                          staticClass:
                                            "modal-evaluation-scroller",
                                          attrs: { settings: _vm.settings },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c(
                                                "drop",
                                                {
                                                  staticClass: "drop",
                                                  on: {
                                                    drop: _vm.handleEvaluationDropMultiRow,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "evaluation-dragbox",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .selectedEvalValueTemp
                                                                .length == 0
                                                                ? "Drag and Drop values here"
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "evaluation-chips-container",
                                                        },
                                                        _vm._l(
                                                          _vm
                                                            .selectedEvalValueTemp
                                                            .evalValue,
                                                          function (
                                                            scheme,
                                                            key
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: key,
                                                                staticClass:
                                                                  "evaluation-chips",
                                                              },
                                                              [
                                                                scheme.isAll
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "Quiz entity:" +
                                                                          _vm._s(
                                                                            scheme.entity
                                                                          )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                !scheme.isAll
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "Quiz entity:" +
                                                                          _vm._s(
                                                                            scheme.entity
                                                                          ) +
                                                                          ", Value:" +
                                                                          _vm._s(
                                                                            scheme.value
                                                                          )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: "javascript:void(0);",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("em", {
                                                                      staticClass:
                                                                        "icon-close",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeEvaluationMultiRow(
                                                                              key,
                                                                              true,
                                                                              scheme.evalId,
                                                                              scheme.entityEvaluationId,
                                                                              scheme
                                                                            )
                                                                          },
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn default",
                                        attrs: {
                                          type: "button",
                                          "data-dismiss": "modal",
                                        },
                                        on: {
                                          click: _vm.hideEvalMOdalEditMultiRow,
                                        },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        attrs: { type: "button" },
                                        on: {
                                          click:
                                            _vm.saveEvaulationEntitySchemeMultiRow,
                                        },
                                      },
                                      [_vm._v("Save")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "entityEvaluationMultiRow",
                      height: "auto",
                      width: 700,
                      scrollable: true,
                    },
                  },
                  [
                    _vm.asstEntitiesQuizList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "modal-content modal-evaluation-content",
                          },
                          [
                            _c("div", { staticClass: "modal-header" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-title",
                                  attrs: { id: "exampleModalLabel" },
                                },
                                [_c("h5", [_vm._v("Entity Evaluation Set ")])]
                              ),
                            ]),
                            _c("div", { staticClass: "modal-body" }, [
                              _c(
                                "form",
                                { staticClass: "modal-form modal-min-height" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row mt-3" },
                                    [
                                      _c(
                                        "VuePerfectScrollbar",
                                        {
                                          staticClass:
                                            "modal-evaluation-scroller",
                                          attrs: { settings: _vm.settings },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            _vm._l(
                                              _vm.asstEntitiesQuizList,
                                              function (evalItem) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: evalItem.id,
                                                    staticClass:
                                                      "evaluation-entity-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "evaluation-head",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            " Quiz Entity: "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                evalItem
                                                                  .attributes
                                                                  .name
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "evaluation-checkboxes",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "custom-checkbox",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Select All "
                                                                ),
                                                                _c("input", {
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleLabelEvalChange(
                                                                          $event,
                                                                          evalItem
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark circle",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "evaluation-values",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Values:"),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "evaluation-row",
                                                          },
                                                          _vm._l(
                                                            evalItem.attributes
                                                              .asst_entity_values,
                                                            function (valItem) {
                                                              return _c(
                                                                "span",
                                                                {
                                                                  key: valItem.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "drag",
                                                                    {
                                                                      staticClass:
                                                                        "drag",
                                                                      attrs: {
                                                                        "transfer-data":
                                                                          {
                                                                            entity:
                                                                              evalItem
                                                                                .attributes
                                                                                .name,
                                                                            value:
                                                                              valItem
                                                                                .attributes
                                                                                .value,
                                                                            isAll: false,
                                                                            asst_entity_value_id:
                                                                              valItem.id,
                                                                            entityId:
                                                                              evalItem.id,
                                                                            valItem,
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          valItem
                                                                            .attributes
                                                                            .value
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "VuePerfectScrollbar",
                                        {
                                          staticClass:
                                            "modal-evaluation-scroller",
                                          attrs: { settings: _vm.settings },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c(
                                                "drop",
                                                {
                                                  staticClass: "drop",
                                                  on: {
                                                    drop: _vm.handleEvaluationDropMultiRow,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "evaluation-dragbox",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .selectedEvalValueTemp
                                                                .length == 0
                                                                ? "Drag and Drop values here"
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "evaluation-chips-container",
                                                        },
                                                        _vm._l(
                                                          _vm
                                                            .selectedEvalValueTemp
                                                            .evalValue,
                                                          function (
                                                            scheme,
                                                            key
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: key,
                                                                staticClass:
                                                                  "evaluation-chips",
                                                              },
                                                              [
                                                                scheme.isAll
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "Quiz entity:" +
                                                                          _vm._s(
                                                                            scheme.entity
                                                                          )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                !scheme.isAll
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "Quiz entity:" +
                                                                          _vm._s(
                                                                            scheme.entity
                                                                          ) +
                                                                          ", Value:" +
                                                                          _vm._s(
                                                                            scheme.value
                                                                          )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: "javascript:void(0);",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("em", {
                                                                      staticClass:
                                                                        "icon-close",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeEvaluationMultiRow(
                                                                              key,
                                                                              true,
                                                                              scheme.evalId,
                                                                              scheme.entityEvaluationId
                                                                            )
                                                                          },
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn default",
                                        attrs: {
                                          type: "button",
                                          "data-dismiss": "modal",
                                        },
                                        on: { click: _vm.hideEvalMOdal2 },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        attrs: { type: "button" },
                                        on: {
                                          click:
                                            _vm.saveEvaulationEntitySchemeMultiRow,
                                        },
                                      },
                                      [_vm._v("Save")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "deleteQstnConfirmationModal",
                      height: "auto",
                      width: 350,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-delete-content" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-body modal-delete-body" },
                          [
                            _vm._v(
                              "Are you sure you want to delete this question?"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer justify-content-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn medium default",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.hide(
                                      "deleteQstnConfirmationModal"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn medium primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.handleQuizQuestionSelect(
                                      _vm.selectedQuizQuestion,
                                      true
                                    ),
                                      _vm.$modal.hide(
                                        "deleteQstnConfirmationModal"
                                      )
                                  },
                                },
                              },
                              [_vm._v("Ok")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.quizContentId === "overall-assessment"
                  ? _c(
                      "div",
                      {
                        staticClass: "setup-content",
                        attrs: { id: "overall-assessment" },
                      },
                      [
                        _vm._m(5),
                        _vm.overallAssmntItem
                          ? _c("ValidationObserver", { ref: "form" }, [
                              _c("form", { staticClass: "w-100" }, [
                                _c("div", { staticClass: "row custom-row" }, [
                                  _c("div", { staticClass: "col-sm-5" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", [
                                        _vm._v(
                                          "Overall Interaction Assessment"
                                        ),
                                      ]),
                                      _c(
                                        "ul",
                                        { staticClass: "form-entity-tabs" },
                                        _vm._l(
                                          _vm.overallAssmntItem,
                                          function (assmntItem) {
                                            return _c(
                                              "li",
                                              {
                                                key: assmntItem.id,
                                                class:
                                                  assmntItem.id ===
                                                  _vm.selectedOverallAssmntItem
                                                    .id
                                                    ? "active"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleOverallAssmntItem(
                                                      assmntItem
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        assmntItem.attributes
                                                          .assessment_label
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-sm-7" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "question-content",
                                        attrs: { id: "number-range" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "question-row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c("label", [
                                                  _vm._v("Evaluation Text"),
                                                ]),
                                                _c("ValidationProvider", {
                                                  attrs: {
                                                    name: "Evaluation Text",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c("vue-editor", {
                                                              attrs: {
                                                                id: "Descriptiontext",
                                                                placeholder:
                                                                  "Add a Description",
                                                                editorToolbar:
                                                                  _vm.fullTool,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.overallAssmntItemFeedback,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.overallAssmntItemFeedback =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "overallAssmntItemFeedback",
                                                              },
                                                            }),
                                                            errors[0]
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class: [
                                                                      _vm.isActive
                                                                        ? "invalid"
                                                                        : "",
                                                                    ],
                                                                    style: [
                                                                      _vm.isActive
                                                                        ? {
                                                                            display:
                                                                              "block",
                                                                          }
                                                                        : {
                                                                            display:
                                                                              "none",
                                                                          },
                                                                    ],
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0].replace(
                                                                          "The ",
                                                                          ""
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3765878218
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-group pt-2",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    "Evaluation range(" +
                                                      _vm._s(
                                                        _vm.overallAssessmentRange
                                                      ) +
                                                      "):"
                                                  ),
                                                ]),
                                                _c("ValidationProvider", {
                                                  attrs: {
                                                    name: "Evaluation range",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mt-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "Minimum"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm.overallAssmntItemMinScore,
                                                                              expression:
                                                                                "overallAssmntItemMinScore",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        class: [
                                                                          _vm.isActive &&
                                                                          errors[0]
                                                                            ? "invalid"
                                                                            : "",
                                                                        ],
                                                                        attrs: {
                                                                          type: "number",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              _vm.overallAssmntItemMinScore,
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.overallAssmntItemMinScore =
                                                                                $event.target.value
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "Maximum"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm.overallAssmntItemMaxScore,
                                                                              expression:
                                                                                "overallAssmntItemMaxScore",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        class: [
                                                                          _vm.isActive &&
                                                                          errors[0]
                                                                            ? "invalid"
                                                                            : "",
                                                                        ],
                                                                        attrs: {
                                                                          type: "number",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              _vm.overallAssmntItemMaxScore,
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.overallAssmntItemMaxScore =
                                                                                $event.target.value
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            errors[0]
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class: [
                                                                      _vm.isActive
                                                                        ? "invalid"
                                                                        : "",
                                                                    ],
                                                                    style: [
                                                                      _vm.isActive
                                                                        ? {
                                                                            display:
                                                                              "block",
                                                                          }
                                                                        : {
                                                                            display:
                                                                              "none",
                                                                          },
                                                                    ],
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0].replace(
                                                                          "The ",
                                                                          ""
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1464490268
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-12 pt-5" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end mt-4 mb-3",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitLo("published")
                                              },
                                            },
                                          },
                                          [_vm._v("Save & Publish")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "btn-container justify-content-end",
                                      },
                                      [
                                        _c("a", { staticClass: "delete-btn" }, [
                                          _c(
                                            "div",
                                            { staticClass: "delete-box" },
                                            [
                                              _c("span", {
                                                staticClass: "icon-delete",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.archiveLO(
                                                    "archived"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Archive this Learning Object(LO)"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "modal",
                  {
                    staticClass: "modal-video",
                    attrs: {
                      name: "testingQuiz",
                      height: "auto",
                      width: 1000,
                      scrollable: true,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-test-content" },
                      [
                        _c("div", { staticClass: "modal-header" }, [
                          _c(
                            "div",
                            {
                              staticClass: "modal-title",
                              attrs: { id: "exampleModalLabel" },
                            },
                            [
                              _c("h5", [_vm._v("Testing")]),
                              _c("div", { staticClass: "modal-right-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "modal-head-checkbox" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "custom-checkbox mr-4" },
                                      [
                                        _vm._v(" Test individual questions "),
                                        _c("input", {
                                          attrs: { type: "checkbox" },
                                          on: {
                                            change: _vm.changeQuestionTest,
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "checkmark circle",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn secondary",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetTest(true)
                                      },
                                    },
                                  },
                                  [_vm._v("Reset test")]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button" },
                              on: { click: _vm.hideTestModal },
                            },
                            [_c("em", { staticClass: "icon-close" })]
                          ),
                        ]),
                        _vm.allQuestionTestId
                          ? _c("div", { staticClass: "modal-body" }, [
                              _c(
                                "form",
                                { staticClass: "modal-form modal-min-height" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mt-2" },
                                    [
                                      _c("label", [_vm._v("Select Question")]),
                                      _c(
                                        "div",
                                        { staticClass: "test-dropdown" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary dropdown-toggle",
                                              attrs: {
                                                type: "button",
                                                id: "entity",
                                                "data-toggle": "dropdown",
                                                "aria-haspopup": "true",
                                                "aria-expanded": "false",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectQuestionToTest &&
                                                      _vm.selectQuestionToTest
                                                        .name
                                                  ) +
                                                  " "
                                              ),
                                              _c("em", {
                                                staticClass: "icon-carrot-down",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "dropdown-menu",
                                              attrs: {
                                                "aria-labelledby": "entity",
                                              },
                                            },
                                            _vm._l(
                                              _vm.quizQuestionList,
                                              function (item, index) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: item.id,
                                                    staticClass:
                                                      "dropdown-item",
                                                    domProps: { value: index },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleselectQuestionToTest(
                                                          $event,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attributes.question
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.selectQuestionToTest.name)
                                      ),
                                    ]),
                                  ]),
                                  _vm.selectQuestionToTest.data
                                    ? _c("div", { staticClass: "form-group" }, [
                                        _c("label", [_vm._v("Answer")]),
                                        _vm.selectQuestionToTest
                                          .question_type === "numeric" ||
                                        _vm.selectQuestionToTest
                                          .question_type === "range"
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.testQuizAnswer,
                                                  expression: "testQuizAnswer",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: { type: "number" },
                                              domProps: {
                                                value: _vm.testQuizAnswer,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.testQuizAnswer =
                                                    $event.target.value
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.selectQuestionToTest
                                          .question_type === "long_answer"
                                          ? _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.testQuizAnswer,
                                                  expression: "testQuizAnswer",
                                                },
                                              ],
                                              staticClass:
                                                "form-control form-textarea",
                                              domProps: {
                                                value: _vm.testQuizAnswer,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.testQuizAnswer =
                                                    $event.target.value
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.selectQuestionToTest
                                          .question_type === "ai_text_rows"
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                _vm._l(
                                                  _vm.testMultiRowResponses,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        _c("textarea", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.responseMultiRow,
                                                              expression:
                                                                "item.responseMultiRow",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control form-textarea",
                                                          domProps: {
                                                            value:
                                                              item.responseMultiRow,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                item,
                                                                "responseMultiRow",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("br"),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.selectQuestionToTest
                                          .question_type === "multiple_choice"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "learn-voice-form",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learn-voice-checkboxes",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-checkboxes",
                                                      },
                                                      _vm._l(
                                                        _vm.selectQuestionToTest
                                                          .data.attributes
                                                          .mcq_options,
                                                        function (item) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: item.id,
                                                              staticClass:
                                                                "form-checkboxes-items",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "custom-checkbox",
                                                                  class: [
                                                                    _vm.editWorldStatus
                                                                      ? "disabled"
                                                                      : "",
                                                                  ],
                                                                  attrs: {
                                                                    name: "public",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item
                                                                          .attributes
                                                                          .option
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c("input", {
                                                                    attrs: {
                                                                      type: "radio",
                                                                      name: "public",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        item
                                                                          .attributes
                                                                          .is_correct,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeQuestionTest(
                                                                            $event,
                                                                            true
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "checkmark circle",
                                                                    attrs: {
                                                                      for: "public",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn primary",
                                        attrs: { type: "button" },
                                        on: { click: _vm.handleCheckQuizTest },
                                      },
                                      [_vm._v("Test")]
                                    ),
                                  ]),
                                  _vm.isTestQuizAnswer === "yes"
                                    ? _c(
                                        "div",
                                        { staticClass: "learn-object-results" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "learn-success-msg result-msg",
                                            },
                                            [
                                              _c("em", {
                                                staticClass: "icon-check",
                                              }),
                                              _vm._v(
                                                " Yes, that is the correct answer. "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isTestQuizAnswer === "no"
                                    ? _c(
                                        "div",
                                        { staticClass: "learn-object-results" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "learn-error-msg result-msg",
                                            },
                                            [
                                              _c("em", {
                                                staticClass: "icon-close",
                                              }),
                                              _vm._v(" Oh! That’s Incorrect. "),
                                            ]
                                          ),
                                          _vm.correctAnswer
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-12 px-0",
                                                },
                                                [
                                                  _c(
                                                    "ValidationProvider",
                                                    {
                                                      attrs: {
                                                        name: "Card Title",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.correctAnswer,
                                                            expression:
                                                              "correctAnswer",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control test-big-input-control",
                                                        attrs: {
                                                          type: "text",
                                                          id: "name",
                                                          disabled: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.correctAnswer,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.correctAnswer =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  ["long_answer", "ai_text_rows"].includes(
                                    _vm.selectQuestionToTest.question_type
                                  ) && _vm.aiWatsonResponse.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "modal-test-tabs" },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass: "nav nav-tabs",
                                              attrs: {
                                                id: "myTab",
                                                role: "tablist",
                                              },
                                            },
                                            [
                                              _c(
                                                "li",
                                                { staticClass: "nav-item" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "nav-link active",
                                                      attrs: {
                                                        id: "Test-tab",
                                                        "data-toggle": "tab",
                                                        href: "#Test",
                                                        role: "tab",
                                                        "aria-controls": "Test",
                                                        "aria-selected": "true",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Test input Return Data"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "nav-item" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "nav-link",
                                                      attrs: {
                                                        id: "json-tab",
                                                        "data-toggle": "tab",
                                                        href: "#json",
                                                        role: "tab",
                                                        "aria-controls": "json",
                                                        "aria-selected":
                                                          "false",
                                                      },
                                                    },
                                                    [_vm._v("View JSON")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "tab-content pt-2",
                                              attrs: { id: "myTabContent" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tab-pane fade show active",
                                                  attrs: {
                                                    id: "Test",
                                                    role: "tabpanel",
                                                    "aria-labelledby":
                                                      "Test-tab",
                                                  },
                                                },
                                                [
                                                  _vm.aiWatsonResponse.length >
                                                  0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tab-pane fade show active",
                                                          attrs: {
                                                            id: "Test",
                                                            role: "tabpanel",
                                                            "aria-labelledby":
                                                              "Test-tab",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.aiWatsonResponse,
                                                          function (
                                                            quiz,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "test-items",
                                                              },
                                                              [
                                                                _vm
                                                                  .selectQuestionToTest
                                                                  .question_type ===
                                                                "long_answer"
                                                                  ? _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "Watson Response:"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "Entity hit for Text Row:" +
                                                                            _vm._s(
                                                                              quiz
                                                                                .attributes
                                                                                .text_row_number
                                                                            ) +
                                                                            " [ID: " +
                                                                            _vm._s(
                                                                              quiz
                                                                                .attributes
                                                                                .text_row_id
                                                                            ) +
                                                                            "]"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "test-chips-container",
                                                                  },
                                                                  _vm._l(
                                                                    quiz
                                                                      .attributes
                                                                      .watson_response,
                                                                    function (
                                                                      item,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        {
                                                                          key: i,
                                                                          staticClass:
                                                                            "test-chips",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Quiz entity:" +
                                                                              _vm._s(
                                                                                item.entity
                                                                              ) +
                                                                              ", Value:" +
                                                                              _vm._s(
                                                                                item.value
                                                                              )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "tab-pane fade",
                                                  attrs: {
                                                    id: "json",
                                                    role: "tabpanel",
                                                    "aria-labelledby":
                                                      "json-tab",
                                                  },
                                                },
                                                [
                                                  _c("json-viewer", {
                                                    attrs: {
                                                      value:
                                                        _vm.aiWatsonResponse,
                                                      "expand-depth": 5,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.allQuestionTestId
                          ? _c("div", { staticClass: "modal-body" }, [
                              _c("div", { staticClass: "learn-object" }, [
                                _c(
                                  "div",
                                  { staticClass: "learn-object-progress" },
                                  [
                                    _c("div", { staticClass: "progress" }, [
                                      _c("div", {
                                        staticClass: "progress-bar",
                                        style:
                                          _vm.selectQuestionToTest.statusBar,
                                        attrs: {
                                          role: "progressbar",
                                          "aria-valuenow": "50",
                                          "aria-valuemin": "0",
                                          "aria-valuemax": "100",
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "learn-object-question" },
                                      [
                                        _vm._v(
                                          "Question " +
                                            _vm._s(
                                              _vm.selectQuestionToTest.id + 1
                                            ) +
                                            "/" +
                                            _vm._s(_vm.totalQuizQues)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "learn-object-editor" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "learn-object-info" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectQuestionToTest.name
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "learn-voice-form" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "learn-voice-checkboxes",
                                          },
                                          [
                                            _vm.selectQuestionToTest
                                              .question_type === "numeric" ||
                                            _vm.selectQuestionToTest
                                              .question_type === "range"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learn-voice-placeholder",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-6 pl-0",
                                                      },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Card Title",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.testQuizAnswer,
                                                                  expression:
                                                                    "testQuizAnswer",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control test-input-control",
                                                              attrs: {
                                                                type: "text",
                                                                id: "name",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.testQuizAnswer,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.testQuizAnswer =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", {}, [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn secondary",
                                                          on: {
                                                            click:
                                                              _vm.handleCheckQuizTest,
                                                          },
                                                        },
                                                        [_vm._v("Test")]
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.selectQuestionToTest
                                              .question_type === "long_answer"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learn-voice-placeholder",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-6 pl-0",
                                                      },
                                                      [
                                                        _vm.selectQuestionToTest
                                                          .question_type ===
                                                        "long_answer"
                                                          ? _c("textarea", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.testQuizAnswer,
                                                                  expression:
                                                                    "testQuizAnswer",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control form-textarea",
                                                              domProps: {
                                                                value:
                                                                  _vm.testQuizAnswer,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.testQuizAnswer =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("div", {}, [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn secondary",
                                                          on: {
                                                            click:
                                                              _vm.handleCheckQuizTest,
                                                          },
                                                        },
                                                        [_vm._v("Test")]
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.selectQuestionToTest
                                              .question_type === "ai_text_rows"
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.testMultiRowResponses,
                                                      function (item, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "form-group col-8 pl-0",
                                                          },
                                                          [
                                                            _c("textarea", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    item.responseMultiRow,
                                                                  expression:
                                                                    "item.responseMultiRow",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control form-textarea",
                                                              domProps: {
                                                                value:
                                                                  item.responseMultiRow,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      item,
                                                                      "responseMultiRow",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c("br"),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c("div", {}, [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn secondary",
                                                        on: {
                                                          click:
                                                            _vm.handleCheckQuizTest,
                                                        },
                                                      },
                                                      [_vm._v("Test")]
                                                    ),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                            _vm.selectQuestionToTest &&
                                            _vm.selectQuestionToTest
                                              .question_type ===
                                              "multiple_choice"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-checkboxes",
                                                  },
                                                  _vm._l(
                                                    _vm.selectQuestionToTest
                                                      .data.attributes
                                                      .mcq_options,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "form-checkboxes-items",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "custom-checkbox",
                                                              class: [
                                                                _vm.editWorldStatus
                                                                  ? "disabled"
                                                                  : "",
                                                              ],
                                                              attrs: {
                                                                name: "public",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item
                                                                      .attributes
                                                                      .option
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("input", {
                                                                attrs: {
                                                                  type: "radio",
                                                                  name: "public",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    item
                                                                      .attributes
                                                                      .is_correct,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleChangeQuestionTest(
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "checkmark circle",
                                                                attrs: {
                                                                  for: "public",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "learn-object-pagination",
                                          },
                                          [
                                            _vm.selectQuestionToTest.id + 1 !==
                                            1
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass: "btn default",
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleTestPage(
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Previous")]
                                                )
                                              : _vm._e(),
                                            _vm.selectQuestionToTest.id + 1 <
                                            _vm.totalQuizQues
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn primary ml-auto",
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleTestPage(
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Next")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.isTestQuizAnswer === "yes"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "learn-object-results",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learn-success-msg result-msg",
                                                  },
                                                  [
                                                    _c("em", {
                                                      staticClass: "icon-check",
                                                    }),
                                                    _vm._v(
                                                      " Yes, that is the correct answer. "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isTestQuizAnswer === "no"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "learn-object-results",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "learn-error-msg result-msg",
                                                  },
                                                  [
                                                    _c("em", {
                                                      staticClass: "icon-close",
                                                    }),
                                                    _vm._v(
                                                      " Oh! That’s Incorrect. "
                                                    ),
                                                  ]
                                                ),
                                                _vm.correctAnswer
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-12 px-0",
                                                      },
                                                      [
                                                        _c(
                                                          "ValidationProvider",
                                                          {
                                                            attrs: {
                                                              name: "Card Title",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.correctAnswer,
                                                                  expression:
                                                                    "correctAnswer",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control test-big-input-control",
                                                              attrs: {
                                                                type: "text",
                                                                id: "name",
                                                                disabled: "",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.correctAnswer,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.correctAnswer =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                        [
                                          "long_answer",
                                          "ai_text_rows",
                                        ].includes(
                                          _vm.selectQuestionToTest.question_type
                                        ) && _vm.aiWatsonResponse.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "modal-test-tabs",
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass: "nav nav-tabs",
                                                    attrs: {
                                                      id: "myTab",
                                                      role: "tablist",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "nav-link active",
                                                            attrs: {
                                                              id: "Test-tab",
                                                              "data-toggle":
                                                                "tab",
                                                              href: "#Test",
                                                              role: "tab",
                                                              "aria-controls":
                                                                "Test",
                                                              "aria-selected":
                                                                "true",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Test input Return Data"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              id: "json-tab",
                                                              "data-toggle":
                                                                "tab",
                                                              href: "#json",
                                                              role: "tab",
                                                              "aria-controls":
                                                                "json",
                                                              "aria-selected":
                                                                "false",
                                                            },
                                                          },
                                                          [_vm._v("View JSON")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tab-content pt-2",
                                                    attrs: {
                                                      id: "myTabContent",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "tab-pane fade show active",
                                                        attrs: {
                                                          id: "Test",
                                                          role: "tabpanel",
                                                          "aria-labelledby":
                                                            "Test-tab",
                                                        },
                                                      },
                                                      [
                                                        _vm.aiWatsonResponse
                                                          .length > 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "tab-pane fade show active",
                                                                attrs: {
                                                                  id: "Test",
                                                                  role: "tabpanel",
                                                                  "aria-labelledby":
                                                                    "Test-tab",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.aiWatsonResponse,
                                                                function (
                                                                  quiz,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: index,
                                                                      staticClass:
                                                                        "test-items",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Watson Response:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "test-chips-container",
                                                                        },
                                                                        _vm._l(
                                                                          quiz
                                                                            .attributes
                                                                            .watson_response,
                                                                          function (
                                                                            item,
                                                                            i
                                                                          ) {
                                                                            return _c(
                                                                              "span",
                                                                              {
                                                                                key: i,
                                                                                staticClass:
                                                                                  "test-chips",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Quiz entity:" +
                                                                                    _vm._s(
                                                                                      item.entity
                                                                                    ) +
                                                                                    ", Value:" +
                                                                                    _vm._s(
                                                                                      item.value
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "tab-pane fade",
                                                        attrs: {
                                                          id: "json",
                                                          role: "tabpanel",
                                                          "aria-labelledby":
                                                            "json-tab",
                                                        },
                                                      },
                                                      [
                                                        _c("json-viewer", {
                                                          attrs: {
                                                            value:
                                                              _vm.aiWatsonResponse,
                                                            "expand-depth": 5,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.elmMenu.card_type === "chat"
        ? _c("div", { staticClass: "form-section" }, [
            _c("div", { staticClass: "learning-form-header flex-wrap" }, [
              _c("h4", [_vm._v("Interaction(Chat)")]),
              _c("div", { staticClass: "learning-quiz-btn" }, [
                _vm.status == "published"
                  ? _c(
                      "a",
                      {
                        staticClass: "btn header-btn secondary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.submitLo("published")
                          },
                        },
                      },
                      [_vm._v("Save")]
                    )
                  : _vm._e(),
                _vm.status == "drafted"
                  ? _c(
                      "a",
                      {
                        staticClass: "btn header-btn primary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.submitLo("published")
                          },
                        },
                      },
                      [_vm._v("Publish LO")]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "learning-lo-tabs" }, [
              _c("div", { staticClass: "setup-container" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[0] },
                      on: {
                        click: function ($event) {
                          return _vm.chatMenu("basic-information", 0)
                        },
                      },
                    },
                    [_vm._m(6)]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[1] },
                      on: {
                        click: function ($event) {
                          return _vm.chatMenu("watson-setup", 1)
                        },
                      },
                    },
                    [_vm._m(7)]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.isActiveTab[2] },
                      on: {
                        click: function ($event) {
                          return _vm.chatMenu("assessment-setup", 2)
                        },
                      },
                    },
                    [_vm._m(8)]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "learning-quiz-content" }, [
              _vm.chatContentId == "basic-information"
                ? _c(
                    "div",
                    { staticClass: "setup-content" },
                    [
                      _vm._m(9),
                      _c("ValidationObserver", { ref: "form" }, [
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Interaction Title")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Interaction Title",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.name,
                                                    expression: "name",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: { type: "text" },
                                                domProps: { value: _vm.name },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.name =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      268414483
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Administrative Notes")]),
                                  _c(
                                    "ValidationProvider",
                                    { attrs: { name: "Description" } },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.adminNotes,
                                            expression: "adminNotes",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "Description",
                                          id: "Description",
                                          rows: "8",
                                        },
                                        domProps: { value: _vm.adminNotes },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.adminNotes = $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-sm-7" }, [
                              _c(
                                "div",
                                { staticClass: "form-group customer-dropdown" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "exampleInputEmail1" },
                                    },
                                    [_vm._v("Select chat character")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchCharacter,
                                        expression: "searchCharacter",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "Search by name, role & organization",
                                    },
                                    domProps: { value: _vm.searchCharacter },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.searchCharacter =
                                            $event.target.value
                                        },
                                        _vm.filterCharacter,
                                      ],
                                    },
                                  }),
                                  _vm.searchCharacters.length &&
                                  _vm.searchCharacter.length > 0 &&
                                  _vm.charStatus
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-row-scroll custom-user-dropdown",
                                        },
                                        _vm._l(
                                          _vm.searchCharacters,
                                          function (character) {
                                            return _c(
                                              "div",
                                              {
                                                key: character.id,
                                                staticClass: "user-row",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "img-container",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setCharacter(
                                                          character.char_full_name,
                                                          character.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "user-info",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "head",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                character.char_full_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                character.world_role
                                                              ) +
                                                                " at " +
                                                                _vm._s(
                                                                  character.org_name
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              character.char_age
                                                            ) +
                                                              " , " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "uppercase"
                                                                )(
                                                                  character.char_gender,
                                                                  true
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group customer-dropdown" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "exampleInputEmail1" },
                                    },
                                    [
                                      _vm._v(
                                        "Select mentor character(optional)"
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchOptionalCharacter,
                                        expression: "searchOptionalCharacter",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "Search by name, role & organization",
                                    },
                                    domProps: {
                                      value: _vm.searchOptionalCharacter,
                                    },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.searchOptionalCharacter =
                                            $event.target.value
                                        },
                                        _vm.filterOptionalCharacter,
                                      ],
                                    },
                                  }),
                                  _vm.searchOptionalCharacters.length &&
                                  _vm.searchOptionalCharacter.length > 0 &&
                                  _vm.optionalCharStatus
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-row-scroll custom-user-dropdown",
                                        },
                                        _vm._l(
                                          _vm.searchOptionalCharacters,
                                          function (character) {
                                            return _c(
                                              "div",
                                              {
                                                key: character.id,
                                                staticClass: "user-row",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "img-container",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setOptionalCharacter(
                                                          character.char_full_name,
                                                          character.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "user-info",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "head",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                character.char_full_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                character.world_role
                                                              ) +
                                                                " at " +
                                                                _vm._s(
                                                                  character.org_name
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              character.char_age
                                                            ) +
                                                              " , " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "uppercase"
                                                                )(
                                                                  character.char_gender,
                                                                  true
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [
                                  _vm._v("Select assessment scheme"),
                                ]),
                                _c("div", { staticClass: "quiz-dropdown" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary dropdown-toggle",
                                      class: [
                                        _vm.isActive &&
                                        !_vm.assessmentListKey.id &&
                                        _vm.overallQuizAssessment
                                          ? "invalid"
                                          : "",
                                      ],
                                      attrs: {
                                        type: "button",
                                        id: "entity",
                                        "data-toggle": "dropdown",
                                        "aria-haspopup": "true",
                                        "aria-expanded": "false",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.assessmentListKey.name) +
                                          " "
                                      ),
                                      _c("em", {
                                        staticClass: "icon-carrot-down",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: { "aria-labelledby": "entity" },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          staticClass: "dropdown-item",
                                          domProps: { value: `None*${null}` },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectedAssessment(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("None")]
                                      ),
                                      _vm._l(
                                        _vm.assessmentLists,
                                        function (assessment) {
                                          return _c(
                                            "option",
                                            {
                                              key: assessment.id,
                                              staticClass: "dropdown-item",
                                              domProps: {
                                                value: `${assessment.id}*${assessment.attributes.name}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectedAssessment(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  assessment.attributes.name
                                                )
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mb-4",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn primary",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveChatlo(
                                            "drafted",
                                            "watson-setup",
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Next")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-container justify-content-end",
                                },
                                [
                                  _c("a", { staticClass: "delete-btn" }, [
                                    _c("div", { staticClass: "delete-box" }, [
                                      _c("span", {
                                        staticClass: "icon-delete",
                                      }),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.archiveLO("archived")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Archive this Learning Object(LO)"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.chatContentId == "watson-setup"
                ? _c(
                    "div",
                    { staticClass: "setup-content" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h4", { staticClass: "head-title" }, [
                            _vm._v("Watson Setup"),
                          ]),
                          _c("div", { staticClass: "learning-quiz-btn" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-auto header-btn secondary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleImportCSV(
                                      $event,
                                      "dialog_skill"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Link to Assistant Dialog Skill")]
                            ),
                          ]),
                        ]
                      ),
                      _c("ValidationObserver", { ref: "form" }, [
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c("div", { staticClass: "form-group mb-2" }, [
                                _c("label", [
                                  _vm._v("Copy Paste Assistant info:"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Assistant ID")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Assistant ID",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.assistantKey,
                                                    expression: "assistantKey",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.assistantKey,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.assistantKey =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3513329491
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [_vm._v("Assistant API Key")]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Assistant API Key",
                                      rules: "required|max:100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.apiKey,
                                                    expression: "apiKey",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: { type: "text" },
                                                domProps: { value: _vm.apiKey },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.apiKey =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3497054419
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", [
                                    _vm._v("Assistant Service URL"),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: { name: "Assistant Service URL" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.serviceUrl,
                                                    expression: "serviceUrl",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.serviceUrl,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.serviceUrl =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      362503187
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group form-group-success",
                                },
                                [
                                  _c("label", [
                                    _c("em", { staticClass: "icon-check" }),
                                    _vm._v(" Watson connected successfully "),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mt-4 mb-3",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn primary",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveWatsonConfig(
                                            "assessment-setup",
                                            2
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Next")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-container justify-content-end",
                                },
                                [
                                  _c("a", { staticClass: "delete-btn" }, [
                                    _c("div", { staticClass: "delete-box" }, [
                                      _c("span", {
                                        staticClass: "icon-delete",
                                      }),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.archiveLO("archived")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Archive this Learning Object(LO)"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.chatContentId == "assessment-setup"
                ? _c(
                    "div",
                    { staticClass: "setup-content" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h4", { staticClass: "head-title" }, [
                            _vm._v("Assessment Setup"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "learning-quiz-btn align-items-center",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "learning-notification" },
                                [
                                  _vm._v(
                                    "Skills last imported: " +
                                      _vm._s(_vm.last_skills_import_date)
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-auto header-btn secondary",
                                  attrs: { href: "javascript:void(0);" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.importSkillFromAssistant(true)
                                    },
                                  },
                                },
                                [_vm._v("Import skills from Assistant")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("ValidationObserver", { ref: "form" }, [
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Skills")]),
                                _vm.chatSkillList && _vm.selectedChatSkill
                                  ? _c(
                                      "ul",
                                      { staticClass: "form-entity-tabs" },
                                      _vm._l(
                                        _vm.chatSkillList,
                                        function (skill) {
                                          return _c(
                                            "li",
                                            {
                                              key: skill.id,
                                              class:
                                                _vm.selectedChatSkill.id ===
                                                skill.id
                                                  ? "active"
                                                  : "",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:void(0);",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleSelectSkill(
                                                        skill,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      skill.attributes &&
                                                        skill.attributes.name
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm chat-sm" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group d-flex flex-column w-100",
                                },
                                [
                                  _c("label", [_vm._v("Evaluations")]),
                                  _vm.assessmentSkillLabelList.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "chat-tabs" },
                                        _vm._l(
                                          _vm.assessmentSkillLabelList,
                                          function (assessment, index) {
                                            return _c(
                                              "a",
                                              {
                                                key: index,
                                                staticClass: "active",
                                                attrs: {
                                                  href: "javascript:void(0);",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleSelectSkill(
                                                      assessment,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      assessment.attributes &&
                                                        assessment.attributes
                                                          .name
                                                    ) +
                                                    " "
                                                ),
                                                assessment.id ===
                                                _vm.selAssessmentSkillLabel.id
                                                  ? _c("em", {
                                                      staticClass:
                                                        "icon-carrot-right",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-sm" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "nav nav-tabs",
                                  attrs: { id: "nav-tab", role: "tablist" },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-item nav-link active",
                                      attrs: {
                                        id: "question-tab",
                                        "data-toggle": "tab",
                                        href: "#nav-question",
                                        role: "tab",
                                        "aria-controls": "nav-question",
                                        "aria-selected": "true",
                                      },
                                    },
                                    [_vm._v("Question Setup")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-item nav-link",
                                      attrs: {
                                        id: "adaptive-tab",
                                        "data-toggle": "tab",
                                        href: "#nav-adaptive",
                                        role: "tab",
                                        "aria-controls": "nav-adaptive",
                                        "aria-selected": "false",
                                      },
                                      on: { click: _vm.updatedSkillItems },
                                    },
                                    [_vm._v("Adaptive configuration")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tab-content",
                                  attrs: { id: "nav-tabContent" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane fade show active",
                                      attrs: {
                                        id: "nav-question",
                                        role: "tabpanel",
                                        "aria-labelledby": "question-tab",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "question-content",
                                          attrs: { id: "multiple-choice" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "question-row" },
                                            [
                                              _vm.selAssessmentSkillLabel
                                                .attributes &&
                                              _vm.selAssessmentSkillLabel
                                                .attributes.name !== "Missed"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "ValidationProvider",
                                                        {
                                                          attrs: {
                                                            name: "Interaction Title",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row mt-2",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-6",
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      "Value Range(Low)"
                                                                    ),
                                                                  ]),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .selectedSkillItem
                                                                              .value_count_min,
                                                                          expression:
                                                                            "selectedSkillItem.value_count_min",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      type: "number",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm
                                                                          .selectedSkillItem
                                                                          .value_count_min,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            _vm.selectedSkillItem,
                                                                            "value_count_min",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-6",
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      "Value Range(High)"
                                                                    ),
                                                                  ]),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .selectedSkillItem
                                                                              .value_count_max,
                                                                          expression:
                                                                            "selectedSkillItem.value_count_max",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      type: "number",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm
                                                                          .selectedSkillItem
                                                                          .value_count_max,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            _vm.selectedSkillItem,
                                                                            "value_count_max",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c("label", [
                                                    _vm._v("Score"),
                                                  ]),
                                                  _c(
                                                    "ValidationProvider",
                                                    {
                                                      attrs: {
                                                        name: "Interaction Title",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .selectedSkillItem
                                                                .points,
                                                            expression:
                                                              "selectedSkillItem.points",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "number",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .selectedSkillItem
                                                              .points,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.selectedSkillItem,
                                                              "points",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c("label", [
                                                    _vm._v("Debrief Content"),
                                                  ]),
                                                  _c("ValidationProvider", {
                                                    attrs: {
                                                      name: "Debrief Content",
                                                      rules: "max:500",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                          }) {
                                                            return [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .selectedSkillItem
                                                                        .debrief_contents,
                                                                    expression:
                                                                      "selectedSkillItem.debrief_contents",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control",
                                                                class: [
                                                                  _vm.isActive &&
                                                                  errors[0]
                                                                    ? "invalid"
                                                                    : "",
                                                                ],
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .selectedSkillItem
                                                                      .debrief_contents,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        _vm.selectedSkillItem,
                                                                        "debrief_contents",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              errors[0]
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      class: [
                                                                        _vm.isActive
                                                                          ? "invalid"
                                                                          : "",
                                                                      ],
                                                                      style: [
                                                                        _vm.isActive
                                                                          ? {
                                                                              display:
                                                                                "block",
                                                                            }
                                                                          : {
                                                                              display:
                                                                                "none",
                                                                            },
                                                                      ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          errors[0].replace(
                                                                            "The ",
                                                                            ""
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1159273191
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane fade",
                                      attrs: {
                                        id: "nav-adaptive",
                                        role: "tabpanel",
                                        "aria-labelledby": "adaptive-tab",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c(
                                            "h5",
                                            { staticClass: "small-title" },
                                            [_vm._v("Add Adaptive Content")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-4 learning-quiz-btn",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn ml-2 px-4 secondary",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$modal.show(
                                                        "uploadInterstitialModal",
                                                        {
                                                          create: true,
                                                          content: "adaptive",
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Upload")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.selAssessmentSkillLabel
                                        .adaptive_contents
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "learning-data-list flex-column pt-3",
                                            },
                                            _vm._l(
                                              _vm.selAssessmentSkillLabel
                                                .adaptive_contents,
                                              function (item, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass:
                                                      "learning-data-item w-100 learning-interstitial-data-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "learning-data-left",
                                                      },
                                                      [
                                                        item.attributes
                                                          .content_details
                                                          .type ==
                                                        "text_learn_obj"
                                                          ? _c("div", {
                                                              staticClass:
                                                                "learning-data-richText",
                                                            })
                                                          : _vm._e(),
                                                        item.attributes
                                                          .content_details
                                                          .type ==
                                                        "file_learn_obj"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "learning-data-icon",
                                                              },
                                                              [
                                                                _c("a", [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: require("../assets/images/pdf-big-icon.png"),
                                                                      alt: "pdf",
                                                                    },
                                                                  }),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        item.attributes
                                                          .content_details
                                                          .type ==
                                                        "slide_learn_obj"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "learning-data-icon",
                                                              },
                                                              [
                                                                _c("a", [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: item
                                                                        .attributes
                                                                        .content_details
                                                                        .attributes
                                                                        .slider_images[0]
                                                                        .attributes
                                                                        .resource_url
                                                                        .thumbnail,
                                                                      alt: "slide",
                                                                    },
                                                                  }),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        item.attributes
                                                          .content_details
                                                          .type ==
                                                        "video_learn_obj"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "learning-data-video",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "wistia_responsive_padding data-video",
                                                                    staticStyle:
                                                                      {
                                                                        padding:
                                                                          "56.25% 0 0 0",
                                                                        position:
                                                                          "relative",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "wistia_responsive_wrapper data-video",
                                                                        staticStyle:
                                                                          {
                                                                            height:
                                                                              "100%",
                                                                            left: "0",
                                                                            position:
                                                                              "absolute",
                                                                            top: "0",
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "iframe",
                                                                          {
                                                                            staticClass:
                                                                              "wistia_embed",
                                                                            attrs:
                                                                              {
                                                                                src:
                                                                                  _vm.preIframUrl +
                                                                                  item
                                                                                    .attributes
                                                                                    .content_details
                                                                                    .attributes
                                                                                    .global_video
                                                                                    .attributes
                                                                                    .wistia_code +
                                                                                  _vm.postIframUrl,
                                                                                title:
                                                                                  "Video.mp4",
                                                                                name: "wistia_embed",
                                                                                allowtransparency:
                                                                                  "true",
                                                                                scrolling:
                                                                                  "no",
                                                                                allowfullscreen:
                                                                                  "",
                                                                                mozallowfullscreen:
                                                                                  "",
                                                                                webkitallowfullscreen:
                                                                                  "",
                                                                                oallowfullscreen:
                                                                                  "",
                                                                                msallowfullscreen:
                                                                                  "",
                                                                                width:
                                                                                  "100%",
                                                                                height:
                                                                                  "100%",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "learning-data-title",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "data-heads",
                                                          },
                                                          [
                                                            _c("h4", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    item
                                                                      .attributes
                                                                      .title
                                                                  ),
                                                              },
                                                            }),
                                                            item.attributes
                                                              .content_details
                                                              .type ==
                                                            "file_learn_obj"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.size(
                                                                        item
                                                                          .attributes
                                                                          .content_details
                                                                          .attributes
                                                                          .resource_size
                                                                      )
                                                                    )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c("h4", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.attributes
                                                                .content_details
                                                                .attributes
                                                                .title
                                                            ),
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "learning-data-paragraph",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c("p", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      item
                                                                        .attributes
                                                                        .description
                                                                    ),
                                                                },
                                                              }),
                                                            ]),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#3dbc9e",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$modal.show(
                                                                        "uploadInterstitialModal",
                                                                        {
                                                                          create: false,
                                                                          content:
                                                                            "adaptive",
                                                                          edit: item,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Edit")]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeAdaptiveData(
                                                                        item.id,
                                                                        i
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("Remove")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mt-4 mb-3",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn primary",
                                      attrs: { href: "javascript:void(0);" },
                                      on: { click: _vm.createTestChat },
                                    },
                                    [_vm._v("Test")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-container justify-content-end",
                                },
                                [
                                  _c("a", { staticClass: "delete-btn" }, [
                                    _c("div", { staticClass: "delete-box" }, [
                                      _c("span", {
                                        staticClass: "icon-delete",
                                      }),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.archiveLO("archived")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Archive this Learning Object(LO)"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.elmMenu.card_type === "email" &&
      _vm.elmMenu.learning_object_type === "interaction"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("interactiveEmailLo", {
                ref: "interactionEmailLo",
                attrs: {
                  selectedMenuItem: _vm.selectedMenuItem,
                  assessmentLists: _vm.assessmentLists,
                  id: _vm.id,
                  emailInteractiveData: _vm.emailInteractiveData,
                  type: _vm.elmMenu.card_type,
                  charList: _vm.characters,
                },
                on: {
                  updateSubmissionList: _vm.updateSubmissionList,
                  archiveLO: _vm.archiveLO,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "dialogic"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("interactiveDialogiclLo", {
                ref: "form",
                attrs: {
                  elmMenu: _vm.elmMenu,
                  selectedMenuItem: _vm.selectedMenuItem,
                  worldId: _vm.worldId,
                },
                on: {
                  updateDialogicSubmit: _vm.updateDialogicSubmit,
                  archiveLO: _vm.archiveLO,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "submission"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("SubmissionCard", {
                ref: "gradingSubmission",
                attrs: {
                  elmMenu: _vm.elmMenu,
                  selectedMenuItem: _vm.selectedMenuItem,
                },
                on: {
                  updateSubmissionList: _vm.updateSubmissionList,
                  archiveLO: _vm.archiveLO,
                  updateLoList: _vm.updateLoList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "submission_email"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("SubmissionEmail", {
                ref: "form",
                attrs: {
                  elmMenu: _vm.elmMenu,
                  selectedMenuItem: _vm.selectedMenuItem,
                  characters: _vm.characters,
                },
                on: {
                  archiveLO: _vm.archiveLO,
                  updateLoList: _vm.updateLoList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "external_api"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("ExternalAPI", {
                ref: "form",
                attrs: {
                  elmMenu: _vm.elmMenu,
                  id: _vm.id,
                  externalApiInteractiveData: _vm.externalApiInteractiveData,
                  assessmentLists: _vm.assessmentLists,
                  selectedMenuItem: _vm.selectedMenuItem,
                },
                on: {
                  archiveLO: _vm.archiveLO,
                  updateLoList: _vm.updateLoList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.elmMenu.card_type === "coding"
        ? _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("CodingLO", {
                ref: "interactionCodingLO",
                attrs: {
                  elmMenu: _vm.elmMenu,
                  id: _vm.id,
                  assessmentLists: _vm.assessmentLists,
                  selectedMenuItem: _vm.selectedMenuItem,
                },
                on: {
                  archiveLO: _vm.archiveLO,
                  updateLoList: _vm.updateLoList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.llmCard
        ? _c(
            "div",
            { key: _vm.llmKey, staticClass: "form-section" },
            [
              _c("llm-view", {
                attrs: {
                  llmCardType: _vm.llmCardType,
                  llmData: _vm.$store.state.llmData,
                  newLo: _vm.$store.state.newLo,
                },
                on: { archiveLO: _vm.archiveLO },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "modal",
        {
          staticClass: "modal-video",
          attrs: {
            name: "chatModal",
            height: "auto",
            width: 1000,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-test-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [
                  _c("h5", [_vm._v("Chat")]),
                  _c("div", { staticClass: "modal-right-box" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn secondary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.resetChatTest()
                          },
                        },
                      },
                      [_vm._v("Reset test")]
                    ),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("chatModal")
                    },
                  },
                },
                [_c("em", { staticClass: "icon-close" })]
              ),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", { staticClass: "modal-form modal-min-height" }, [
                _c("div", { staticClass: "learn-object learn-chat" }, [
                  _c(
                    "div",
                    { staticClass: "learn-chat-container" },
                    _vm._l(_vm.testChatQuesData, function (ques, index) {
                      return _c("div", { key: index }, [
                        ques.assistant_response
                          ? _c("div", { staticClass: "learn-chat-user" }, [
                              _c("div", { staticClass: "learn-chat-box" }, [
                                _c("div", { staticClass: "learn-chat-image" }),
                                _c("div", { staticClass: "learn-chat-msg" }, [
                                  _vm._v(_vm._s(ques.test_message)),
                                ]),
                              ]),
                              _c("div", { staticClass: "learn-chat-time" }, [
                                _vm._v(_vm._s(ques.test_time) + " min"),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "learn-chat-me" }, [
                          _c("div", { staticClass: "learn-chat-box" }, [
                            _c("div", { staticClass: "learn-chat-msg" }, [
                              _vm._v(_vm._s(ques.assistant_response)),
                            ]),
                            _c("div", { staticClass: "learn-chat-image" }),
                          ]),
                          index === _vm.testChatQuesData.length - 1
                            ? _c("div", { staticClass: "learn-chat-time" }, [
                                _vm._v("Just now"),
                              ])
                            : _c("div", { staticClass: "learn-chat-time" }, [
                                _vm._v(_vm._s(ques.assistant_time) + " min"),
                              ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                  _c("form", { staticClass: "learn-chat-footer" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.testChatQuestion,
                            expression: "testChatQuestion",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { placeholder: "Type your Message here" },
                        domProps: { value: _vm.testChatQuestion },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.sendChatQuestionTest.apply(
                              null,
                              arguments
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.testChatQuestion = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "form-chat-btn" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn primary",
                          on: { click: _vm.sendChatQuestionTest },
                        },
                        [_vm._v("Send")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.elmMenu.card_type == "quiz" || _vm.elmMenu.card_type == "chat"
        ? _c("addInterstitialContent", {
            on: { adaptiveContents: _vm.adaptiveContents },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Basic Information")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Entity Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Question Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Overall Assessment")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Overall Assessment")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Basic Information")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Watson Chat Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0);" } }, [
      _c("span", { staticClass: "check-box" }),
      _c("span", [_vm._v("Assessment Setup")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }