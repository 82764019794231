<template>
  <div class="sidebar">
    <div class="logo">
      <router-link to="/learning-modules" v-if="user_roles.includes('admin')">
        <img src="../assets/images/ametros-learning-logo.png" alt="ametros logo">
      </router-link>
        <img src="../assets/images/ametros-learning-logo.png" alt="ametros logo" v-if="user_roles.includes('instructor')">
    </div>
    <div class="navbar">
      <ul>
        <li v-bind:class="[currentPage('/learning-modules') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/learning-modules">Learning Modules</router-link>
        </li>
        <li
          v-bind:class="[currentPage('/worlds') || currentPage('/world-detail')  ? 'active' : '']" v-if="user_roles.includes('admin')"
        >
          <router-link to="/worlds">Worlds</router-link>
        </li>
        <li v-bind:class="[currentPage('/organisations') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/organisations">Organizations</router-link>
        </li>
        <li class="show" v-if="user_roles.includes('admin')">
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            ref="char"
            :aria-expanded="[ characterMgmtToggle ? true : false]"
          >
            Character Management
            <em class="icon-carrot-down"></em>
          </a>
          <div :class="[ characterMgmtToggle ? 'show' : '', 'dropdown-menu']">
            <ul>
              <li v-bind:class="[currentPage('/character-roles') ? 'active' : '']">
                <router-link to="/character-roles">Character Roles</router-link>
              </li>
              <li v-bind:class="[currentPage('/characters') ? 'active' : '']">
                <router-link to="/characters">Characters</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li v-bind:class="[currentPage('/assessment') ? 'active' : '']" v-if="user_roles.includes('admin')" >
          <router-link to="/assessment">Assessment Schemes</router-link>
        </li>
        <li class="show" v-if="user_roles.includes('admin')">
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            ref="cont"
            v-bind:aria-expanded="[ contentMgmtToggle === 'show' ? true : false]"
          >
            Content Management
            <em class="icon-carrot-down"></em>
          </a>
          <div v-bind:class="[ contentMgmtToggle, 'dropdown-menu']">
            <ul>
              <li v-bind:class="[currentPage('/content-document') ? 'active' : '']">
                <router-link to="/content-document">Documents</router-link>
              </li>
              <li v-bind:class="[currentPage('/content-images') ? 'active' : '']">
                <router-link to="/content-images">Images</router-link>
              </li>
              <li v-bind:class="[currentPage('/content-videos') ? 'active' : '']">
                <router-link to="/content-videos">Videos</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li v-bind:class="[currentPage('/dashboard-content') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/dashboard-content">Dashboard Content</router-link>
        </li>
        <li v-bind:class="[currentPage('/users') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/users">Users</router-link>
        </li>
        <li v-bind:class="[currentPage('/user_sections') ? 'active' : '']">
          <router-link to="/learner_central">Learner Central</router-link>
        </li>
        <li v-bind:class="[currentPage('/customers') ? 'active' : '']">
          <router-link to="/customers">Customers</router-link>
        </li>
        <li v-bind:class="[currentPage('/courses') ? 'active' : '']">
          <router-link to="/courses">Courses</router-link>
        </li>
        <li v-bind:class="[currentPage('/provision') ? 'active' : '']">
          <router-link to="/provision">Provisioning</router-link>
        </li>
        <li v-bind:class="[currentPage('/qa-emails') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/qa-emails">Quality Assurance</router-link>
        </li>
        <li v-bind:class="[currentPage('/watson-admin') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/watson-admin">Watson Admin</router-link>
        </li>
        <li v-bind:class="[currentPage('/llm-prompts') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/llm-prompts">LLM Prompts</router-link>
        </li>
        <li v-bind:class="[currentPage('/llm-skills') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/llm-skills">LLM Skills</router-link>
        </li>
        <li v-bind:class="[currentPage('/llm-skill-sets') ? 'active' : '']" v-if="user_roles.includes('admin')">
          <router-link to="/llm-skill-sets">LLM Skill Sets</router-link>
        </li>
      </ul>
    </div>
    <userOptions />
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import userOptions from '@/components/userOptions.vue'
export default {
  name: "Side-bar",
  components: {
    userOptions
  },
  data() {
    return {
      characterMgmtToggle: true,
      contentMgmtToggle: 'hide',
      user_roles: utilFunctionService.getUserRoles()
    };
  },
  methods: {
    currentPage(submenu) {
      if (this.$route.path === submenu) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch:{
    $route (to){
      if (
        to.name === "character-roles" ||
        to.name === "characters"
      ) {
        this.$nextTick(function () {
          this.characterMgmtToggle = true;
          this.contentMgmtToggle = "hide";
        })
      } 
      else if (
        to.name === "content-document" ||
        to.name === "content-images" ||
        to.name === "content-videos"
      ) {
        this.$nextTick(function () {
          this.characterMgmtToggle = false;
          this.contentMgmtToggle = "show";
        })
      } else {
        this.characterMgmtToggle = false;
        this.$nextTick(function () {
          this.contentMgmtToggle = "hide";
        })
      }
    }
  },
  mounted() {
    if (
      this.$route.name === "character-roles" ||
      this.$route.name === "characters"
    ) {
      this.characterMgmtToggle = true;
      this.contentMgmtToggle = "hide";
    } else if (
      this.$route.name === "content-document" ||
      this.$route.name === "content-images" ||
      this.$route.name === "content-videos"
    ) {
      this.contentMgmtToggle = "show";
      this.characterMgmtToggle = false;
    } else {
      this.characterMgmtToggle = false;
      this.contentMgmtToggle = "hide";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.sidebar::-webkit-scrollbar {
    display: none;
  }
.sidebar {
  width: 200px;
  padding: 50px 15px 0;
  @include position(fixed, 0, 0, 0, 0);
  background-color: $white;
  max-height: 100%;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  .logo {
    width: 140px;
    @include media-breakpoint(xl) {
      width: 165px;
      margin: 7px 0 0;
    }
  }
  .navbar {
    margin: 0;
    padding: 20px 0 0;
    @include font-medium;
    font-size: 14px;
    @include media-breakpoint(xl) {
      font-size: 15px;
    }
    ul {
      margin: 0;
    }
    li {
      margin: 45px 0 0;
    }
    a {
      color: $theme-secondary-text;
      &:hover {
        color: $theme-color;
      }
    }
    .active {
      a {
        color: $theme-color;
      }
    }
    .dropdown-toggle {
      @include flexProperty(null, center);
      em {
        font-size: 9px;
        margin: 2px 0 0 5px;
      }
      &[aria-expanded="true"] {
        color: $theme-color;
        em {
          transform: rotate(180deg);
        }
      }
    }
    .dropdown-menu {
      width: 100%;
      border: 0;
      position: static;
      @include font-regular;
      font-size: 13px;
      margin: 25px 0 0;
      padding: 0;
      box-shadow: none;
      li {
        margin: 0 0 5px;
        a {
          color: $theme-color;
          display: block;
          border-radius: 4px;
          padding: 15px;
        }
      }
      .active {
        a {
          background-color: #ecfaf5;
          color: #32be9d;
        }
      }
    }
  }
  @include media-breakpoint(xl) {
    width: 265px;
    min-width: 265px;
    padding: 60px 20px 20px 40px;
  }
}
</style>
