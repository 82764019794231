/* eslint-disable vue/valid-v-if */
<template>
  <div class="elm-form-container learning-module-container">
    <div class="step-container">
      <vue-scroll :ops="ops" ref="vs">
        <ul class="step-list" v-if="menus.active && menus.active.length > 0">
          <draggable v-model="menus.active" @start="isDragging=true" @end="onEnd">
            <li
              v-for="(menu, key) in menus.active"
              :key="menu.id"
              :class="$route.query.id === menu.id ? 'active' :''"
              @click="getActive(menu.id, key)"
            >
              <router-link :to="`/edit-elm/${id}?id=${menu.id}`">
                <div class="number-box">{{key+1}}</div>
                <div class="ml-2">
                  <div class="small-text">
                    {{menu.attributes.learning_object_type | heading | uppercase(true)}}
                    ({{menu.attributes.card_type == 'submission' ? 'Grading' : menu.attributes.card_type | uppercase(true)}})
                    <span
                      class="tag"
                      v-if="menu.attributes.status === 'drafted'"
                    >Draft</span>
                  </div>
                  <div class="heading">{{menu.attributes.name| uppercase(true)}}</div>
                </div>
                <span class="icon-carrot-right"></span>
              </router-link>
            </li>
          </draggable>
        </ul>
      </vue-scroll>
      <button
        @click="$modal.show('createLO')"
        class="btn header-btn primary w-100"
      >Add New Learning Object</button>
    </div>

    <div class="form-section" v-if="elmMenu.card_type === 'video'">
      <ValidationObserver ref="form">
        <form class="w-100" @submit.prevent="submitLo">
          <div class="learning-form-header">
            <h4>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}})</h4>
            <div class="d-flex">
              <a
                href="javascript:void(0);"
                @click="submitLo('published')"
                v-if="status == 'published'"
                class="btn header-btn secondary"
              >Save</a>
              <a
                v-if="status == 'drafted'"
                href="javascript:void(0);"
                @click="submitLo('published')"
                class="btn header-btn ml-2 primary"
              >Publish LO</a>
                <a
                href="javascript:void(0);"
                @click="cloneLo()"
                class="btn header-btn ml-2 secondary"
              >Clone LO</a>
            </div>
          </div>
          <div class="row custom-row">
            <div class="col-sm-5">
              <div class="form-group">
                <label>Card Title</label>
                <ValidationProvider name="Card Title" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    aria-describedby="emailHelp"
                    v-model="name"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Administrative Notes</label>
                <ValidationProvider name="Description">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    rows="8"
                    v-model="adminNotes"
                  ></textarea>
                </ValidationProvider>
              </div>
              <div class="btn-container">
                <a
                  href="javascript:void(0);"
                  class="btn ml-2 mb-2 secondary medium"
                  @click="addVideo('AddVideo')"
                >Add a new video</a>
                <a
                  href="javascript:void(0);"
                  @click="addVideoFromGallery('uploadVideo')"
                  class="btn ml-2 mb-2 secondary medium"
                >Select from Library</a>
              </div>
              <div
                v-if="!video"
                :class="[isActive ? 'invalid text-right w-100 mr-2' : 'text-right w-100 mr-2']"
                :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
              >Add Video field is required</div>
            </div>
            <div class="col-sm-7">
              <div class="d-flex justify-content-between" v-if="video && video.attributes">
                <label>Video Preview</label>
                <a
                  href="javascript:void(0);"
                  @click="Preview(elmMenu.card_type)"
                  class="video-preview"
                >
                  <span>See Preview</span>
                  <em class="icon-carrot-right"></em>
                </a>
              </div>
              <div class="form-group video-group">
                <div class="video-section">
                  <!-- video empty state  -->
                  <div class="video-empty" v-if="!video">
                    <img alt="images" src="../assets/images/video-icon.png" />
                  </div>
                  <!-- video player state  -->
                  <div class="video-player" v-else>
                    <div class="learning-data-list" v-if="video && video.attributes">
                      <div
                        class="wistia_responsive_wrapper data-video"
                        style="height:100%;left:0;position:absolute;top:0;width:100%;"
                      >
                        <iframe
                          :src="preIframUrl+video.attributes.wistia_code+postIframUrl"
                          title="Video.mp4"
                          class="wistia_embed"
                          name="wistia_embed"
                          allowtransparency="true"
                          scrolling="no"
                          allowfullscreen
                          mozallowfullscreen
                          webkitallowfullscreen
                          oallowfullscreen
                          msallowfullscreen
                          width="100%"
                          height="100%"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Video Title</label>
                <ValidationProvider name="Video Title" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="title"
                    class="form-control"
                    id="title"
                    aria-describedby="emailHelp"
                    v-model="title"
                    placeholder="Add a Video Title"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Learner Description</label>
                <ValidationProvider name="Learner Description" rules="required" v-slot="{ errors }">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    aria-describedby="emailHelp"
                    v-model="description"
                    rows="4"
                    placeholder="Add a Learner Description"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  ></textarea>
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="btn-container justify-content-end" v-if="$route.query.id">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a @click="archiveLO('archived')" class="text">Archive this Learning Object(LO)</a>
                </a>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <modal name="cropperImages" height="auto" :width="800" :scrollable="true">
      <div class="modal-content modal-cropper-content">
        <div class="modal-body">
          <div class="modal-images-cropper">
            <div class="modal-cropper-large" v-if="image">
              <cropper :src="image" :stencil-props="{ aspectRatio: 16/9 }" ref="cropper"></cropper>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="$modal.hide('cropperImages')" type="button" class="btn default">Cancel</button>
          <button type="button" @click="crop()" class="btn primary">Save</button>
        </div>
      </div>
    </modal>
    <div class="form-section" v-if="elmMenu.card_type === 'slide'">
      <div class="learning-form-header">
        <h4>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}})</h4>
        <div class="d-flex">
          <a
            href="javascript:void(0);"
            @click="saveSlide('published')"
            v-if="status == 'published'"
            class="btn header-btn secondary"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            @click="saveSlide('published')"
            class="btn header-btn ml-2 primary"
          >Publish LO</a>
           <a
              href="javascript:void(0);"
              @click="cloneLo()"
              class="btn header-btn ml-2 secondary"
            >Clone LO</a>
        </div>
      </div>
      <ValidationObserver ref="form">
        <form class="w-100">
          <div class="row custom-row">
            <div class="col-sm-5">
              <div class="form-group">
                <label>Card Title</label>
                <ValidationProvider name="Card title" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="title"
                    class="form-control"
                    id="title"
                    v-model="name"
                    placeholder="Add Card Title"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Administrative Notes</label>
                <ValidationProvider name="Description">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    rows="8"
                    v-model="adminNotes"
                  ></textarea>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Learner Description</label>
                <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    v-model="description"
                    rows="4"
                    placeholder="Add a Description"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  ></textarea>
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="btn-container">
                <input
                  style="display: none;"
                  ref="fileInput"
                  type="file"
                  @change="onFileSelect"
                  accept="image/jpeg"
                />
                <a
                  @click="$refs.fileInput.click()"
                  class="btn header-btn secondary small-btn"
                >Upload new Images</a>
                <a
                  class="btn header-btn secondary small-btn"
                  @click="openSliderGallery('sliderImages')"
                >Select from Library</a>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>Image Preview</label>
                  <a
                    href="javascript:void(0);"
                    @click="Preview(elmMenu.card_type)"
                    class="video-preview"
                  >
                    <span>See Preview</span>
                    <em class="icon-carrot-right"></em>
                  </a>
                </div>
                <div class="plot-slider">
                  <div class="item">
                    <img alt="images" :src="imagePreview" v-if="imagePreview != false" />
                    <div class="plot-slider-title"></div>
                  </div>
                </div>
                <div class="form-group mt-3 mb-3">
                  <div class="d-flex">
                    <label>Image Caption</label>
                    <label class="switch ml-auto">
                      <input type="checkbox" v-model="imageCaption" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="imageTitle"
                    v-on:input="getTitle"
                    placeholder="Add Caption"
                  />
                </div>
                <div v-if="slider.length" class="plot-thumbs">
                  <VueSlickCarousel v-bind="settings" @reInit="onInitCarousel">
                    <div class="owl-item" v-for="(slide,i) in slider" :key="slide.id">
                      <div class="item" :class="[i == index ? 'active' : '']">
                        <a class="plot-btn" @click="removeSliderImage(slide.id, slide.sliderId)">
                          <em class="icon-close"></em>
                        </a>
                        <img
                          alt="images"
                          :src="slide.image"
                          @click="slideImage(i, slide.title, slide.image)"
                        />
                      </div>
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
              <div class="btn-container justify-content-end" v-if="$route.query.id">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a @click="saveSlide('archived')" class="text">Archive this Learning Object(LO)</a>
                </a>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <div class="form-section" v-if="elmMenu.card_type === 'text'">
      <ValidationObserver ref="form">
        <form class="w-100" @submit.prevent="submitLo">
          <div class="learning-form-header">
            <h4>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}})</h4>
            <div class="d-flex">
              <a
                href="javascript:void(0);"
                v-if="status == 'published'"
                class="btn header-btn secondary"
                @click="saveContentText('published')"
              >Save</a>
              <a
                v-if="status == 'drafted'"
                href="javascript:void(0);"
                class="btn header-btn ml-2 primary"
                @click="saveContentText('published')"
              >Publish LO</a>
               <a
                href="javascript:void(0);"
                @click="cloneLo()"
                class="btn header-btn ml-2 secondary"
              >Clone LO</a>
            </div>
          </div>
          <div class="learning-preview">
            <a href="javascript:void(0);" @click="Preview(elmMenu.card_type)" class="video-preview">
              <span>See Preview</span>
              <em class="icon-carrot-right"></em>
            </a>
          </div>
          <div class="row custom-row">
            <div class="col-sm-5">
              <div class="form-group">
                <label>Card Title</label>
                <ValidationProvider name="Card Title" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    aria-describedby="emailHelp"
                    v-model="name"
                    placeholder="Add Card Title"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>Administrative Notes</label>
                <ValidationProvider name="Description">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    rows="8"
                    v-model="adminNotes"
                  ></textarea>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group group-editor">
                <label>Learner Description</label>
                <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                  <vue-editor
                    id="Descriptiontext"
                    placeholder="Add a Description"
                    :editorToolbar="fullTool"
                    v-model="contentDescription"
                  ></vue-editor>
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
                <div class="form-group group-editor">
                <label>Text Body</label>
                <ValidationProvider name="Text Body" rules="required" v-slot="{ errors }">
                  <vue-editor
                    id="TextBody"
                    placeholder="Add Text Body"
                    :editorToolbar="fullTool"
                    v-model="textBody"
                  ></vue-editor>
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="btn-container justify-content-end" v-if="$route.query.id">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a @click="archiveLO('archived')" class="text">Archive this Learning Object(LO)</a>
                </a>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div
      class="form-section"
      v-if="elmMenu.card_type === 'email' && elmMenu.learning_object_type === 'plot_point'"
    >
      <div class="learning-form-header">
        <h4>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}})</h4>
        <div class="d-flex">
          <a
            href="javascript:void(0);"
            v-if="status == 'published'"
            class="btn header-btn secondary"
            @click="saveLoEmail('published')"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            class="btn header-btn ml-2 primary"
            @click="saveLoEmail('published')"
          >Publish LO</a>
           <a
              href="javascript:void(0);"
              @click="cloneLo()"
              class="btn header-btn ml-2 secondary"
            >Clone LO</a>
        </div>
      </div>
      <div class="learning-preview">
        <a href="javascript:void(0);" @click="Preview(elmMenu.card_type)" class="video-preview">
          <span>See Preview</span>
          <em class="icon-carrot-right"></em>
        </a>
      </div>

      <ValidationObserver ref="form">
        <form class="w-100">
          <div class="row custom-row">
            <div class="col-sm-5">
              <div class="form-group">
                <label>Card Title</label>
                <ValidationProvider name="Card Title" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    aria-describedby="emailHelp"
                    v-model="name"
                    placeholder="Add Card Title"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Learner Description</label>
                <ValidationProvider name="Learner Description" rules="required" v-slot="{ errors }">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    v-model="description"
                    rows="4"
                    placeholder="Add Description"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  ></textarea>
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group search-input">
                <label>Select Character(s)</label>
                <input
                  type="text"
                  v-model="searchCharacter"
                  v-on:input="filterCharacter"
                  class="form-control"
                  placeholder="Search by name, role or organization"
                />
                <span class="icon-search"></span>
              </div>
              <div class="user-row-scroll">
                <div class="user-row" v-for="(character) in searchCharacters" :key="character.id">
                  <div class="img-container">
                    <!-- <div class="img-box">
                      <img
                        :src="character.char_photo_url"
                        v-if="character.char_photo_url"
                        alt="image"
                      />
                    </div> -->
                    <div class="user-info">
                      <div class="head">{{character.char_full_name}}</div>
                      <div class="text">{{character.world_role}} at {{character.org_name}}</div>
                      <p>{{character.char_age}} , {{character.char_gender | uppercase(true)}}</p>
                    </div>
                  </div>
                  <div class="form-checkboxes">
                    <label class="custom-checkbox mr-3">
                      From
                      <input
                        type="checkbox"
                        :id="character.id"
                        v-model="character.to"
                        @click="selectCharacter(character, character.id, 'to')"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="custom-checkbox">
                      cc
                      <input
                        type="checkbox"
                        :id="character.id"
                        v-model="character.cc"
                        @click="selectCharacter(character, character.id, 'cc')"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="form-group">
                <label>Subject</label>
                <ValidationProvider name="Subject" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    aria-describedby="emailHelp"
                    v-model="title"
                    placeholder="Enter Subject"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Add Email Body</label>
                 <div class="email-body mt-2">
                   <ul class="variations-tab">
                    <li >
                      <a :class="(selectedVariation === 1) ? 'active': ''" @click="setActiveVariation(1);">Variation 1</a>
                    </li>
                    <li>
                      <a :class="(selectedVariation === 2) ? 'active': ''" @click="setActiveVariation(2);">Variation 2</a>
                    </li>
                    <li>
                      <a :class="(selectedVariation === 3) ? 'active': ''" @click="setActiveVariation(3);">Variation 3</a>
                    </li>
                  </ul>
                 </div>
                <ValidationProvider name="Email Body" rules="required" v-slot="{ errors }" v-if="selectedVariation === 1">
                 <div v-if="selectedVariation === 1">
                    <vue-editor
                    id="test"
                    placeholder="Add Email Body"
                    :editorToolbar="fullTool"
                    v-model="emailBody1"
                  ></vue-editor>
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                 </div>
                </ValidationProvider>
                <div v-if="selectedVariation === 2">
                  <vue-editor
                    placeholder="Add Email Body"
                    :editorToolbar="fullTool"
                    v-model="emailBody2"></vue-editor>
                </div>
                <div v-if="selectedVariation === 3">
                  <vue-editor
                    placeholder="Add Email Body"
                    :editorToolbar="fullTool"
                    v-model="emailBody3"
                  ></vue-editor>
                </div>
              </div>
              <div class="form-group">
                <label>Administrative Notes</label>
                <ValidationProvider name="Description">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    rows="8"
                    v-model="adminNotes"
                  ></textarea>
                </ValidationProvider>
              </div>
              <div class="btn-container">
                <input
                  style="display: none;"
                  ref="fileInput"
                  type="file"
                  multiple
                  @change="onFileSelected"
                  accept=".pdf"
                />
                <a
                  class="btn ml-2 mb-2 secondary medium pdf-button"
                  @click="$refs.fileInput.click()"
                >
                <img src="../assets/images/pin-icon.png" alt="pin" class="pin-image" />
                Attach a File</a>
              </div>
              <div class="col-sm-7">
                <div class="form-pdf-group" v-if="emailPdfs.length > 0">
                  <div class="form-pdf-item" v-for="(emailPdf,i) of emailPdfs" :key="emailPdf.id">
                    <a @click="$modal.show('pdfModal',{pdfUrl : emailPdf.attributes.resource_url })">
                      <img src="../assets/images/pdf-big-icon.png" alt="pdf" />
                    </a>
                    <a href="javascript:void(0);" @click="removePdfs(i)" class="close-pdf">
                      <em class="icon-close"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="btn-container justify-content-end" v-if="$route.query.id">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a @click="archiveLO('archived')" class="text">Archive this Learning Object(LO)</a>
                </a>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <div class="form-section" v-if="elmMenu.card_type === 'file'">
      <div class="learning-form-header">
        <h4>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}})</h4>
        <div class="d-flex">
          <button
            class="btn header-btn secondary"
            v-if="status == 'published'"
            @click="submitLo('published')"
          >Save</button>
          <button
            v-if="status === 'drafted'"
            class="btn header-btn ml-2 primary"
            @click="submitLo('published')"
          >Publish LO</button>
           <button
              @click="cloneLo()"
              class="btn header-btn ml-2 secondary"
            >Clone LO</button>
        </div>
      </div>
      <div class="learning-preview">
        <a href="javascript:void(0);" @click="Preview(elmMenu.card_type)" class="video-preview">
          <span>See Preview</span>
          <em class="icon-carrot-right"></em>
        </a>
      </div>
      <ValidationObserver ref="form">
        <form class="w-100">
          <div class="row custom-row">
            <div class="col-sm-5">
              <div class="form-group">
                <label>Card Title</label>
                <ValidationProvider name="Card Title" rules="required|max:100" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    aria-describedby="emailHelp"
                    v-model="name"
                    placeholder="Add Card Title"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Administrative Notes</label>
                <ValidationProvider name="Description">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    rows="8"
                    v-model="adminNotes"
                  ></textarea>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Learner Description</label>
                <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                  <textarea
                    type="Description"
                    class="form-control"
                    id="Description"
                    v-model="description"
                    rows="4"
                    placeholder="Add a Description"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  ></textarea>
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
                </ValidationProvider>
              </div>
              <div class="btn-container">
                <input
                  style="display: none;"
                  ref="fileInput"
                  type="file"
                  @change="onDocSelect"
                  multiple="multiple"
                  accept=".pdf, .doc, .csv"
                />
                <button
                  class="btn ml-2 mb-2 secondary medium"
                  @click="$refs.fileInput.click()"
                >Upload a File</button>
                <h1 style="display:none">{{docs}} {{removestatus}}</h1>
                <button @click="selectDoc()" class="btn ml-2 mb-2 secondary medium">Select from Library</button>
                <div
                  v-if="docs.length < 1"
                  :class="[isActive ? 'invalid text-right w-100 mr-2' : 'text-right w-100 mr-2']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                >Add Pdf field is required</div>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="form-pdf-group" v-if="docs.length > 0">
                <div class="form-pdf-item" :key="docs[0].id" v-if="docs[0].attributes.is_csv">
                  <a @click="$modal.show('csvPreview',{pdfUrl: docs[0].attributes.resource_url })">
                    <img src="../assets/images/csv_placeholder.png" alt="csv" />
                  </a>
                  <a href="javascript:void(0);" @click="removeDoc()" class="close-pdf">
                    <em class="icon-close"></em>
                  </a>
                </div>
                <div class="form-pdf-item" :key="docs[0].id" v-if="docs[0].attributes.is_pdf">
                  <a @click="$modal.show('pdfModal',{pdfUrl : docs[0].attributes.resource_url })">
                    <img src="../assets/images/pdf-big-icon.png" alt="pdf" />
                  </a>
                  <a href="javascript:void(0);" @click="removeDoc()" class="close-pdf">
                    <em class="icon-close"></em>
                  </a>
                </div>
              </div>
              <div class="btn-container justify-content-end" v-if="$route.query.id">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a @click="archiveLO('archived')" class="text">Archive this Learning Object(LO)</a>
                </a>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <csv-preview />
    <pdfModal />
    <AddVideo />
    <modal name="sliderImages" height="auto" :width="800" :scrollable="true">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Upload Images</h5>
          </div>
        </div>
        <div class="modal-body modal-image-body">
          <div class="modal-images-tab">
            <a href class="active">Library</a>
          </div>
          <form class="header-form">
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchImg"
                v-debounce:700ms="searchImage"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <div class="modal-images-content">
            <v-infinite-scroll @bottom="nextImagesPage" :offset="20" class="modal-content-scroll">
              <div class="modal-images-library">
                <div class="modal-images-row">
                  <div class="modal-images-col" v-for="images in imageLists" :key="images.id">
                    <div class="modal-images-checkbox">
                      <label class="custom-checkbox">
                        <input type="checkbox" v-model="images.attributes.status" />
                        <span class="checkmark circle"></span>
                        <div class="modal-images-box">
                          <img
                            :src="images.attributes.resource_url.large_version"
                            v-if="images.attributes.resource_url.large_version"
                            alt="image"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </v-infinite-scroll>
            <div class="data-no-result" v-if="imageLists.length === 0">
              <h3>No results found</h3>
              <p>Try using a different keyword</p>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="imageLists.length > 0">
          <button
            @click="cancelSliderGallery('sliderImages')"
            type="button"
            class="btn default"
          >Cancel</button>
          <button
            type="button"
            @click="$modal.hide('sliderImages');saveImages()"
            class="btn primary"
          >Save</button>
        </div>
      </div>
    </modal>
    <modal name="uploadDocument" height="auto" :width="800" :scrollable="true">
      <div class="modal-content modal-pdf-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Upload a Pdf</h5>
          </div>
        </div>
        <div class="modal-body modal-image-body">
          <form class="header-form">
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchDoc"
                v-debounce:700ms="searchDocument"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <div class="modal-images-content" v-if="docLists.length > 0">
            <v-infinite-scroll @bottom="nextPage" :offset="20" class="modal-content-scroll">
              <div class="modal-images-library">
                <div class="modal-images-row" v-if="docLists.length">
                  <div class="modal-images-col" v-for="doc in docLists" :key="doc.id">
                    <div
                      class="modal-images-checkbox"
                      @click="$modal.hide('uploadDocument');saveDoc(doc)"
                    >
                      <label class="custom-checkbox">
                        <input type="checkbox" name="image" v-model="doc.attributes.status" />
                        <span class="checkmark circle"></span>
                        <div class="modal-images-box">
                          <img
                            alt="pdf"
                            src="../assets/images/pdf-big-icon.png"
                            class="modal-pdf-icon"
                          />
                        </div>
                        <div class="modal-images-info">
                          <h4>{{doc.attributes.file_name}}</h4>
                          <p v-if="doc.attributes.tag_list">{{doc.attributes.tag_list}}</p>
                        </div>
                        <div class="checked"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </v-infinite-scroll>
          </div>
          <div class="data-no-result" v-if="docLists.length === 0">
            <h3>No results found</h3>
            <p>Try using a different keyword</p>
          </div>
        </div>
        <div class="modal-footer" v-if="docLists.length > 0">
          <button type="button" @click="$modal.hide('uploadDocument');" class="btn default">Cancel</button>
          <button type="button" class="btn primary" @click="saveDoc()">Save</button>
        </div>
      </div>
    </modal>
    <modal name="videoPreview" class="modal-video" height="auto" :width="1000" :scrollable="true">
      <div class="modal-content video-content">
        <div class="modal-header">
          <button type="button" class="close ml-auto" @click="$modal.hide('videoPreview')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body" v-if="elmMenu.card_type === 'video'">
          <h2>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}}) Preview</h2>
          <h3>{{title}}</h3>
          <p>{{description}}</p>
          <div class="video-container introduction" v-if="video && video.attributes">
            <div class="introduction-video">
              <div
                class="wistia_responsive_wrapper data-video"
                style="height:100%;left:0;position:absolute;top:0;width:100%;"
              >
                <iframe
                  :src="preIframUrl+video.attributes.wistia_code+postIframUrl"
                  title="Video.mp4"
                  class="wistia_embed"
                  name="wistia_embed"
                  allowtransparency="true"
                  scrolling="no"
                  allowfullscreen
                  mozallowfullscreen
                  webkitallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
              <div class="introduction-titles">
                <div class="introduction-intro">
                  <div class="introduction-time">{{video.attributes.duration}}</div>
                  <div class="introduction-title">{{name}}</div>
                </div>
              </div>
            </div>
            <div class="intro-transcript">
              <h5>Transcript:</h5>
              <read-more
                more-str="Read more"
                :text="video.attributes.transcript"
                link="#"
                less-str="Read less"
                :max-chars="400"
              ></read-more>
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="elmMenu.card_type === 'slide'">
          <h2>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}}) Preview</h2>
          <h1 class="learn-title">{{name}}</h1>
          <p class="learn-info" v-html="description"></p>
          <div class="introduction">
            <carousel
              v-if="slider.length"
              :margin="0"
              :nav="true"
              :navText="[]"
              :autoplay="false"
              :autoHeight="false"
              :items="1"
              :dots="false"
              :loop="true"
            >
              <div v-for="(img , i) in slider" :key="img.id">
                <div v-if="imageCaption" class="introduction-video-text">{{img.title}}</div>
                <div class="introduction-video">
                  <img :src="img.image" alt="mask" class="intro-slide-video" />
                  <div class="introduction-intro-top"></div>
                  <div class="introduction-intro-bottom">
                    <div class="introduction-intro-count">{{i+1}}/{{slider.length}}</div>
                  </div>
                </div>
                <div class="introduction-progress dashboard-progress-bar">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{ width: [(i+1)*100/image.length+'%'] }"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
        <div class="modal-body" v-if="elmMenu.card_type === 'text'">
          <h2>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}}) Preview</h2>
          <h1>{{name}}</h1>
          <p v-html="contentDescription"></p>
          <p v-html="textBody"></p>
        </div>
        <div class="modal-body" v-if="elmMenu.card_type === 'email'">
          <h2>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}}) Preview</h2>
          <h1 class="learn-title">{{name}}</h1>
          <p class="learn-info">{{description}}</p>
          <!-- learn object details start -->
          <div class="learn-object">
            <div class="learn-object-forward">
              <div class="learn-object-head">
                <!-- learn object col repeat start -->
                <div
                  class="learn-object-col"
                  v-for="character in to_characters"
                  :key="character.id+'test1'"
                >
                  <div class="learn-object-label">To</div>
                  <!-- <div class="learn-object-user">
                    <img
                      :src="character.char_photo_url"
                      v-if="character.char_photo_url"
                      alt="image"
                    />
                  </div> -->
                  <div class="learn-object-titles">
                    <h5>{{character.char_full_name}}</h5>
                    <p>{{character.world_role}}</p>
                  </div>
                </div>
                <!-- learn object col repeat end -->
                <!-- learn object col repeat start -->
                <div
                  class="learn-object-col"
                  v-for="character in cc_characters"
                  :key="character.id+'test'"
                >
                  <div class="learn-object-label">CC</div>
                  <!-- <div class="learn-object-user">
                    <img
                      :src="url+character.char_photo_url"
                      v-if="character.char_photo_url"
                      alt="image"
                    />
                  </div> -->
                  <div class="learn-object-titles">
                    <h5>{{character.char_full_name}}</h5>
                    <p>{{character.world_role}}</p>
                  </div>
                </div>
                <!-- learn object col repeat end -->
              </div>
              <div class="learn-object-mail">
                <div class="learn-object-tag">Subject: {{title}}</div>
                <div class="learn-object-paragraph">
                  <div class="learn-object-desc">
                    <p v-html="transcript"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="elmMenu.card_type === 'file'">
          <h2>{{$parent.learning_object_type| heading}} ({{elmMenu.card_type| uppercase(true)}}) Preview</h2>
          <h1 class="learn-title">{{name}}</h1>
          <p class="learn-info" v-html="description"></p>
          <div class="learn-docs-btn" v-if="docs.length > 0">
            <a class="docs-btn">
              {{docs[0].attributes.file_name ? docs[0].attributes.file_name :
              $parent.selectedMenu.attributes.card_detail.attributes.resource_file_name}}
            </a>
          </div>
        </div>
      </div>
    </modal>
    <div class="form-section" v-if="elmMenu.card_type === 'quiz'">
      <div class="learning-form-header flex-wrap">
        <h4>Interaction(Quiz)</h4>
        <div class="learning-quiz-btn">
          <a
            href="javascript:void(0);"
            @click="submitLo('published')"
            class="btn header-btn secondary"
            v-if="status == 'published'"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            class="btn header-btn primary"
            @click="submitLo('published')"
          >Publish LO</a>
           <a
              href="javascript:void(0);"
              @click="$modal.show('cloneConfirmationModal')"
              class="btn header-btn ml-2 secondary"
            >Clone LO</a>
        </div>
      </div>
      <!-- Quiz tabs start here -->
      <div class="learning-lo-tabs">
        <div class="setup-container">
          <ul>
            <li
              :class="{ active: isActiveTab[0] }"
              @click="handleQuizContent($event, 'basic-information', 0)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Basic Information</span>
              </a>
            </li>
            <li
              :class="{ active: isActiveTab[1] }"
              @click="handleQuizContent($event, 'entity-setup', 1)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Entity Setup</span>
              </a>
            </li>
            <li
              :class="{ active: isActiveTab[2] }"
              @click="handleQuizContent($event, 'question-setup', 2)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Question Setup</span>
              </a>
            </li>
            <li
              v-if="overallQuizAssessment"
              :class="{ active: isActiveTab[3] }"
              @click="handleQuizContent($event, 'overall-assessment', 3)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Overall Assessment</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Quiz tabs content start here -->
      <div class="learning-quiz-content">
        <!-- Basic Information start here -->
        <div
          class="setup-content"
          id="basic-information"
          v-if="quizContentId === 'basic-information'"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Basic Information</h4>
          </div>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label>Interaction Title</label>
                    <ValidationProvider
                      name="Interaction Title"
                      rules="required|max:100"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Administrative Notes</label>
                    <ValidationProvider name="Description">
                      <textarea
                        type="Description"
                        class="form-control"
                        id="Description"
                        rows="8"
                        v-model="adminNotes"
                      ></textarea>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Display points as:</label>
                    <ValidationProvider>
                      <div class="form-checkboxes">
                        <div class="quiz-checboxes">
                          <label class="custom-checkbox" name="public">
                            Numeric Score
                            <input
                              type="radio"
                              name="public"
                              v-model="scoreViewType"
                              v-bind:value="'numeric'"
                            />
                            <span for="public" class="checkmark circle"></span>
                          </label>
                        </div>
                        <div class="quiz-checboxes">
                          <label class="custom-checkbox" name="private">
                            Percentage
                            <input
                              type="radio"
                              name="private"
                              v-model="scoreViewType"
                              v-bind:value="'percentage'"
                            />
                            <span for="private" class="checkmark circle"></span>
                          </label>
                        </div>
                        <div class="quiz-checboxes">
                          <label class="custom-checkbox" name="tally">
                            Tally correct answers
                            <input
                              type="radio"
                              name="tally"
                              v-model="scoreViewType"
                              v-bind:value="'tally_correct_ans'"
                            />
                            <span for="tally" class="checkmark circle"></span>
                          </label>
                        </div>
                      </div>
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="!scoreViewType"
                      >Display points field is required</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="form-group">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="userOverall"
                        :class="[editWorldStatus ? 'disabled' : '']"
                      >
                        <input
                          :disabled="editWorldStatus == true"
                          type="checkbox"
                          name="userOverall"
                          v-model="overallQuizAssessment"
                          @change="handleOverAllAssChange"
                          v-bind:value="'userOverall'"
                        />
                        <span for="userOverall" class="checkmark"></span>
                        Use overall interaction assessment?
                      </label>
                    </div>
                  </div>
                  <div class="form-group" v-if="overallQuizAssessment">
                    <label>Select assessment scheme</label>
                    <ValidationProvider name="Assessment Scheme">
                      <div class="quiz-dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="entity"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          :class="[(isActive && !assessmentListKey.id && overallQuizAssessment) ? 'invalid' : '']"
                        >
                          {{assessmentListKey.name}}
                          <em class="icon-carrot-down"></em>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="entity">
                          <option
                            class="dropdown-item"
                            @click="selectedAssessment($event)"
                            :value="`None*${null}`"
                          >None</option>
                          <option
                            class="dropdown-item"
                            @click="selectedAssessment($event)"
                            :value="`${assessment.id}*${assessment.attributes.name}`"
                            v-for="assessment of assessmentLists"
                            :key="assessment.id"
                          >{{assessment.attributes.name}}</option>
                        </div>
                      </div>
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="!assessmentListKey.id && overallQuizAssessment"
                      >Assessment Scheme field is required</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group" v-if="overallQuizAssessment">
                     <div class="d-flex align-items-center">
                        <label>Competency code</label>
                        <input
                          type="text"
                          class="form-control form-control-md ml-3"
                          v-model="competencyCode"
                        />
                      </div>
                   </div>
                  <div class="form-group pt-1">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="quizAssessment"
                        :class="[editWorldStatus ? 'disabled' : '']"
                      >
                        <input
                          :disabled="editWorldStatus == true"
                          type="checkbox"
                          name="quizAssessment"
                          v-model="overallAssessment"
                          v-bind:value="'quizAssessment'"
                        />
                        <span for="quizAssessment" class="checkmark"></span>
                        Apply quiz assessment to overall module score
                      </label>
                    </div>
                  </div>
                   <div class="form-group pt-1">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="quizQuestionOrder"
                        :class="[editWorldStatus ? 'disabled' : '']"
                      >
                        <input
                          :disabled="editWorldStatus == true"
                          type="checkbox"
                          name="quizQuestionOrder"
                          v-model="quizQuestionOrder"
                          v-bind:value="'quizQuestionOrder'"
                        />
                        <span for="quizQuestionOrder" class="checkmark"></span>
                        Display questions to learner in sequential order
                      </label>
                    </div>
                  </div>
                  <!-- hide learner feedback -->
                  <div class="form-group pt-1">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="quizHideFeedbak"
                        :class="[editWorldStatus ? 'disabled' : '']"
                      >
                        <input
                          :disabled="editWorldStatus == true"
                          type="checkbox"
                          name="quizHideFeedbak"
                          v-model="quizHideFeedbak"
                          v-bind:value="'quizHideFeedbak'"
                        />
                        <span for="quizHideFeedbak" class="checkmark"></span>
                        Hide learner feedback and adaptive content
                      </label>
                    </div>
                  </div>
                  <div class="form-group pt-1">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="showCorrectAdaptiveFeedback"
                        :class="[editWorldStatus ? 'disabled' : '']"
                      >
                        <input
                          :disabled="editWorldStatus == true"
                          type="checkbox"
                          name="showCorrectAdaptiveFeedback"
                          v-model="showCorrectAdaptiveFeedback"
                          :value="'showCorrectAdaptiveFeedback'"
                        />
                        <span for="showCorrectAdaptiveFeedback" class="checkmark"></span>
                        Show adaptive feedback on correct answers
                      </label>
                    </div>
                  </div>
                   <div class="form-group pt-1">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="quizHideFeedbak"
                      >
                        <input
                          type="checkbox"
                          name="hideAssessmentLabel"
                          v-model="hideAssessmentLabels"
                          v-bind:value="'hideAssessmentLabels'"
                        />
                        <span for="hideAssessmentLabel" class="checkmark"></span>
                        Hide assessment labels
                      </label>
                    </div>
                  </div>
                  <div class="form-group group-editor">
                    <label>Learner Description</label>
                    <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                      <vue-editor
                        id="Descriptiontext"
                        placeholder="Add a Description"
                        :editorToolbar="fullTool"
                        v-model="description"
                      ></vue-editor>
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end mb-4">
                    <button
                      class="btn primary"
                      @click="handleQuizContent($event, 'entity-setup', 1)"
                    >Next</button>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveLO('archived')"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <modal name="deleteWorldModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Question fields are incomplete. Do you want to discard this question?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('deleteWorldModal')"
                class="btn medium default"
              >Cancel</button>
              <button
                type="button"
                @click="discardQuestion(),$modal.hide('deleteWorldModal')"
                class="btn medium primary"
              >ok</button>
            </div>
          </div>
        </modal>
        <modal name="deleteWorldModal2" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >your question is a multi row question?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('deleteWorldModal2')"
                class="btn medium default"
              >Cancel</button>
              <button
                type="button"
                @click="$modal.hide('deleteWorldModal2')"
                class="btn medium primary"
              >ok</button>
            </div>
          </div>
        </modal>
        <modal name="deleteEvalModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Are you sure you want to discard this evaluation?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('deleteEvalModal')"
                class="btn medium default"
              >Cancel</button>
              <button type="button" @click="discardEvalModal()" class="btn medium primary">ok</button>
            </div>
          </div>
        </modal>
        <modal name="deleteEvalModalMultiRow" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Are you sure you want to discard this evaluation?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('deleteEvalModalMultiRow')"
                class="btn medium default"
              >Cancel</button>
              <button type="button" @click="discardEvalModalMultiRow()" class="btn medium primary">ok</button>
            </div>
          </div>
        </modal>


        <modal name="rowNumberLowModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >The number of rows cannot be lower than 2</div>
            <div class="modal-footer justify-content-center">
              <button type="button" @click="discardLowNumberModal()" class="btn medium primary">ok</button>
            </div>
          </div>
        </modal>

        <modal name="sameEntitySetExist" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >The Same Entity Evaluation Set Exists</div>
            <div class="modal-footer justify-content-center">
              <button type="button" @click="discardSameEntitySetExist()" class="btn medium primary">ok</button>
            </div>
          </div>
        </modal>


        <modal name="removeRowModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Are you sure you want to discard this row?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('removeRowModal')"
                class="btn medium default"
              >Cancel</button>
              <button type="button" @click="removeRow()" class="btn medium primary">ok</button>
            </div>
          </div>
        </modal>



        <modal name="cloneConfirmationModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Are you sure you want to clone this learning object?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('cloneConfirmationModal')"
                class="btn medium default"
              >Cancel</button>
              <button type="button" @click="cloneLo(),$modal.hide('cloneConfirmationModal')" class="btn medium primary">Ok</button>
            </div>
          </div>
        </modal>
        <!-- Basic Information end here -->
        <!-- Entity Setup start here -->
        <div class="setup-content" id="entity-setup" v-if="quizContentId === 'entity-setup'">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Entity Setup</h4>
            <div class="learning-quiz-btn">
              <a
                v-if="quizContentId === 'entity-setup'"
                href="javascript:void(0);"
                class="btn btn-auto header-btn secondary"
                @click="handleImportCSV($event,'dialog_skill')"
              >Link to Assistant Dialog Skill</a>
              <a
                v-if="quizContentId === 'entity-setup'"
                href="javascript:void(0);"
                class="btn btn-auto header-btn secondary"
                @click="handleImportCSV($event,'sync_assistant')"
              >Sync with Assistant</a>
              <div
                class="btn btn-auto header-btn secondary btn-type"
                v-if="quizContentId === 'entity-setup'"
              >
                <input type="file" class @change="handleImportCSV" />
                Import CSV
              </div>
            </div>
          </div>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label class="big-label">Quiz entities in this question</label>
                    <ul class="form-entity-tabs" v-if="asstEntitiesList">
                      <li
                        v-for="item in asstEntitiesList"
                        :key="item.id"
                        :class="item.id === selectedAsstEntities.id ? 'active' :''"
                        @click="handleAsstEntitiesList(item)"
                      >
                        <a href="javascript:void(0);">
                          {{item.attributes && item.attributes.name}}
                          <span>
                            <em @click="handleAsstEntitiesList(item, true)" class="icon-close"></em>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="form-group pt-3">
                    <label>Add Quiz Entity</label>
                    <input type="text" class="form-control" id="name" v-model="quizEntityName" />
                    <div class="form-btn-group">
                      <button class="btn primary" @click="addQuizEntity($event)">Add Quiz Entity</button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="entity-tabs-content mt-0">
                    <div class="row">
                      <div class="col-6 form-group">
                        <label>Value</label>
                        <ValidationProvider
                          name="Value"
                          rules="required|max:100|entityValueNameRule"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            v-model="asstName"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="errors[0]"
                          >{{ errors[0].replace("The ", "" )}}</span>
                        </ValidationProvider>
                      </div>
                      <div class="col-6 form-group">
                        <label>Synonyms</label>
                        <ValidationProvider name="Synonyms" rules="required" v-slot="{ errors }">
                          <input
                            type="text"
                            class="form-control"
                            :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            v-model="asstSynonyms"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="errors[0]"
                          >{{ errors[0].replace("The ", "" )}}</span>
                        </ValidationProvider>
                      </div>
                      <div class="col-12 form-group">
                        <a
                          href="javascript:void(0);"
                          class="add-more-btn"
                          @click="addAssessmentValues"
                        >Save Value</a>
                      </div>
                    </div>
                    <div class="quiz-table-data">
                      <div class="quiz-table-head row">
                        <div class="quiz-tab-col col-4">
                          <span>Values</span>
                        </div>
                        <div class="quiz-tab-col col-7">
                          <span>Synonyms</span>
                        </div>
                      </div>
                      <!-- quiz table content start -->
                      <div class="quiz-table-content" v-if="selectedAsstEntities">
                        <!-- quiz table list repeat -->
                        <div
                          class="quiz-table-list"
                          v-for="asstValue of selectedAsstEntities.attributes.asst_entity_values"
                          :key="asstValue.id"
                        >
                          <div class="quiz-tabs-row row">
                            <div class="quiz-tab-col col-4">{{asstValue.attributes.value}}</div>
                            <div class="quiz-tab-col col-7" @click="handleSynonymModal(asstValue)">
                              <a
                                class="d-block"
                              >{{getAssessmentValue(asstValue.attributes.asst_entity_val_synonyms)}}</a>
                            </div>
                            <div class="quiz-tab-col col-1 text-right">
                              <a href="javascript:void(0);">
                                <em @click="removeAsstEntities(asstValue)" class="icon-close"></em>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- quiz table content end -->
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <button
                      class="btn primary"
                      @click="handleQuizContent($event, 'question-setup', 2)"
                    >Next</button>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveLO('archived')"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <modal
          name="enityValueModal"
          height="auto"
          :width="500"
          :scrollable="true"
          v-if="selectedSynonymList"
        >
          <div class="modal-content modal-default">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Entity value:{{selectedSynonymList.attributes.value}}</h5>
              </div>
            </div>
            <div class="modal-body">
              <form
                class="modal-form modal-min-height"
                v-on:submit.prevent="updateAssessmentValues('add')"
              >
                <div class="form-group mt-2">
                  <label>Synonyms</label>
                  <ValidationProvider name="Assign subject matter expert" rules="required">
                    <input type="text" class="form-control" v-model="entitySynonymName" />
                  </ValidationProvider>
                  <div class="chip-container entity-chip">
                    <div
                      class="chip"
                      v-for="(synonym, index) in selectedSynonymList.attributes.asst_entity_val_synonyms"
                      :key="synonym.id"
                    >
                      <span>{{synonym.attributes.synonym}}</span>
                      <em
                        class="icon-close"
                        @click="updateAssessmentValues('delete',index, synonym )"
                      ></em>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    @click="$modal.hide('enityValueModal')"
                    data-dismiss="modal"
                    class="btn default"
                  >Cancel</button>
                  <button
                    type="button"
                    class="btn primary"
                    @click="updateAssessmentValues('done')"
                  >Done</button>
                </div>
              </form>
            </div>
          </div>
        </modal>
        <!-- Entity Setup end here -->
        <!-- Question Setup start here -->
        <div class="setup-content" id="question-setup" v-if="quizContentId === 'question-setup'">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Question Setup</h4>
            <div class="learning-quiz-btn">
              <a
                v-if="quizContentId === 'question-setup'"
                :class="`btn header-btn secondary ${allQuizQuestionTestList.length >0 ?'':'disabled'}`"
                @click="handleselectQuestionToTest"
              >Test</a>
            </div>
          </div>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <ul class="form-entity-tabs">
                      <draggable
                       v-model="quizQuestionList"
                        :sort="true"
                        :move="reorderQuizQuestions"
                        @start="isDragging=true"
                        @end="isDragging=true"
                      >
                        <li
                          v-for="(questionList) of quizQuestionList"
                          :key="questionList.id"
                          :class="!isQuestionCreated && questionList.id === selectedQuizQuestion.id ? 'active' :''"
                          @click="handleQuizQuestionSelect(questionList)"
                        >
                          <a href="javascript:void(0);">
                            {{questionList.attributes.order}}:
                            {{removeQuestionTags(questionList.attributes.question)}}

                            <span>
                              <em
                                @click="$modal.show('deleteQstnConfirmationModal')"
                                class="icon-close"
                              ></em>
                            </span>
                          </a>
                        </li>
                      </draggable>
                    </ul>
                  </div>
                  <div class="form-btn-group">
                    <a
                      href="javascript:void(0);"
                      @click="showSelectQuestionModal"
                      class="btn btn-full primary"
                    >Add Question</a>
                  </div>
                </div>
                <div class="col-sm-7" v-if="this.selectedQuizQuestion || this.isQuestionCreated">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      @click="handleFeedbackChange(false)"
                      :class="`nav-item nav-link ${ariaSelected ?'': 'active'}`"
                      id="question-tab"
                      data-toggle="tab"
                      href="#nav-question"
                      role="tab"
                      aria-controls="nav-question"
                      :aria-selected="ariaSelected? 'false': 'true'"
                    >1. Question Setup</a>
                    <a
                      :class="`nav-item nav-link ${ariaSelected ?'active': ''}`"
                      id="feedback-tab"
                      data-toggle="tab"
                      href="#nav-feedback"
                      role="tab"
                      aria-controls="nav-feedback"
                      :aria-selected="ariaSelected? 'true': 'false'"
                    >2. Feedback</a>
                    <a
                      class="nav-item nav-link"
                      id="adaptive-tab"
                      data-toggle="tab"
                      href="#nav-adaptive"
                      role="tab"
                      aria-controls="nav-adaptive"
                      aria-selected="false"
                      @click="handleFeedbackChange(false)"
                    >3. Adaptive configuration</a>
                  </div>
                  <div class="tab-content" id="nav-tabContent">
                    <!-- Question Tab -->
                    <div
                      :class="`tab-pane fade ${ariaSelected ?'': 'show active'}`"
                      id="nav-question"
                      role="tabpanel"
                      aria-labelledby="question-tab"
                    >
                      <!-- multiple choice start-->
                      <div class="question-content" id="multiple-choice">
                        <div class="question-row">
                          <div class="form-group">
                            <label>Question</label>
                            <vue-editor
                              id="Descriptiontext"
                              placeholder="Add a Description"
                              :editorToolbar="fullTool"
                              v-model="questionValue"
                            ></vue-editor>
                          </div>
                        </div>
                        <!-- quiz table data start -->
                        <div class="quiz-table-data pt-2" v-if="questionType === 'multiple_choice'">
                          <div class="quiz-table-head row">
                            <div class="quiz-tab-col col-8">
                              <span>Possible answers</span>
                            </div>
                            <div class="quiz-tab-col quiz-center col-3">
                              <span>Correct Answer</span>
                            </div>
                          </div>
                          <!-- quiz table content start -->
                          <div class="quiz-table-content">
                            <!-- quiz table list repeat -->
                            <div class="quiz-table-list">
                              <div
                                class="quiz-tabs-row row"
                                v-for="(item, index) in multipleChoiceAnswer"
                                :key="item.id"
                              >
                                <div class="quiz-tab-col quiz-text col-8">
                                  <a
                                    v-if="!item.isActive"
                                    @click="handleMcqQuesSelect(index)"
                                  >{{item.option}}</a>
                                  <input
                                    v-if="item.isActive"
                                    :id="item.id"
                                    type="text"
                                    class="form-control"
                                    v-model="item.option"
                                  />
                                </div>
                                <div class="quiz-tab-col quiz-center col-3">
                                  <label class="custom-checkbox" name="public">
                                    <input
                                      type="radio"
                                      name="public"
                                      v-on:change="changeMultipleChoiceAnswer(index)"
                                      v-model="selectedMultipleChoiceAnswer"
                                      :value="item.id"
                                    />
                                    <span for="public" class="checkmark circle"></span>
                                  </label>
                                </div>
                                <div class="quiz-tab-col col-1 text-right">
                                  <a
                                    href="javascript:void(0);"
                                    @click="handleMcqAnswersAdded(index, true)"
                                  >
                                    <em class="icon-close"></em>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- quiz table content end -->
                          <div class="form-group pt-3">
                            <a
                              href="javascript:void(0);"
                              class="add-more-btn"
                              v-on:click="handleMcqAnswersAdded"
                            >+ Add Answer</a>
                          </div>
                        </div>
                        <!-- quiz table data end -->
                        <!-- specific number start -->
                        <div class="form-group" v-if="questionType === 'numeric'">
                          <ValidationProvider name="Answer" v-slot="{ errors }">
                            <label>Answer</label>
                            <input
                              type="number"
                              v-model="numericQuestionAnswer"
                              class="form-control"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>
                        <!-- Question Type: Long Answer -->
                        <div v-if="questionType === 'long_answer'">
                          <div class="form-group mb-3">
                            <label>Correct answer entity evaluation set(s):</label>
                            <div class="aiText-data">
                              <!-- aiText list repeat start -->
                              <div
                                class="aiText-data-list"
                                v-for="(scheme,index) in selectedEvaulationScheme"
                                :key="index"
                              >
                                <div class="aiText-left">
                                  <div class="aiText-col" v-for="(item,i2) in scheme" :key="i2">
                                    <div
                                      class="text-caption"
                                      v-if="item.isAll"
                                    >Entity:{{item.entity}}</div>
                                    <div
                                      class="text-caption"
                                      v-if="!item.isAll"
                                    >Entity:{{item.entity}}, Value:{{item.value}}</div>
                                    <div class="aiText-col" v-if="i2+1 !== scheme.length">and</div>
                                  </div>
                                </div>
                                <div class="aiText-delete">
                                  <a href="javascript:void(0);" class="mx-2">
                                    <em class="icon-edit" @click="editEvaluationSet(scheme, index)"></em>
                                  </a>
                                  <a href="javascript:void(0);">
                                    <em
                                      class="icon-close"
                                      @click="removeEvaluationSet(index, false)"
                                    ></em>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--   aiText list repeat end -->
                          </div>
                          <div class="form-group pb-4">
                            <a
                              href="javascript:void(0);"
                              @click="showSchemeModal"
                              class="add-more-btn"
                            >+ Add Evaluation Set</a>
                          </div>
                          <br>
                          <div class="form-group">

                            <div>
                                    <span>Correct custom assessment code: </span>
                                      <input
                                      type="text"
                                      class="form-control"
                                      v-model="correctCustomAssessmentCode">
                            </div>
                            <br>
                            <div>
                                    <span>Incorrect custom assessment code: </span>
                                      <input
                                      type="text"
                                      class="form-control"
                                      v-model="incorrectCustomAssessmentCode">
                            </div>
                          </div>
                          <br>
                          <br>
                        </div>
                        <!-- Question Type: Long Answer end -->

                        <!-- Question Type: Multi Row -->
                        <div v-if="questionType === 'ai_text_rows'" >

                        <div class="ai-text-rows-container ">
                          <div class="ai-row ai-rows-header border-bottom-solid">

                              <!--<span class="pr-4">Number of rows: {{RowNumbersSelected}}  </span>-->

                              <span class="pr-4 ">Correct rows to pass:
                                <input v-model.number="correctRowsPass"
                                      class="custom-input-style"
                                      type="number"
                                      v-on:change="checkCorrectRowsPass" />
                              </span>
                            <span>

                              <label  >
                                Evaluate rows in sequence
                                <input
                                  type="checkbox"
                                  id="checkboxMultiRow"
                                  v-model="checkRowsSequenceMultiRow"/>
                                <span class="checkmark"></span>

                              </label>
                            </span>

                            <span class="float-right">Correct row required?</span>


                          </div>



                            <span v-for="(ro,inx) in multiRowsEntitiesEvaluation"  :key="inx">
                              <div class="ai-row ai-text-row" v-if="!ro.isRemovedRow">
                                <div class="column-1"><label >{{ro.id}}</label></div>
                                  <div class="column-2">

                                      <div class="form-group mb-3">

                                        <label> Correct answer entity evaluation set(s):</label>

                                        <div class="evaluation-set-row">
                                        <!-- aiText list repeat start -->
                                          <div
                                            v-for="(scheme,index) in ro.entityEvaluationRow"
                                            :key="index">
                                            <div  class="aiText-data-list" v-if="!scheme.isRemovedEntitySet">
                                                <div class="aiText-left">
                                                    <div class="aiText-col" v-for="(item,i3) in scheme.evalValue" :key="i3">
                                                        <div
                                                          class="text-caption"
                                                          v-if="item.isAll"
                                                        >Entity:{{item.entity}}</div>
                                                        <div
                                                          class="text-caption"
                                                          v-if="!item.isAll"
                                                        >Entity:{{item.entity}}, Value:{{item.value}}</div>
                                                        <div class="aiText-col" v-if="i3+1 !== scheme.evalValue.length">and</div>
                                                    </div>

                                                    <div class="aiText-delete">
                                                      <a href="javascript:void(0);" class="mx-2">
                                                        <em class="icon-edit" @click="editEvaluationSetMultiRow(scheme, ro.id)"></em>
                                                      </a>
                                                      <a href="javascript:void(0);">
                                                        <em
                                                          class="icon-close"
                                                          @click="removeEvaluationSetMultiRow(ro.id, index)"
                                                        ></em>
                                                      </a>
                                                    </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>

                                <!--   aiText list repeat end -->
                                </div>

                                <div class="">
                                  <a
                                    href="javascript:void(0);"
                                    @click="showSchemeModalMultiRow(ro.id)"
                                    class="add-more-btn"
                                  >+ Add Evaluation Set</a>
                                  <span class=""><a
                                      href="javascript:void(0);"
                                      @click="deleteRow(ro.id)"
                                      class="btn btn-delete"
                                    >Remove Row</a>
                                  </span>
                                </div>
                              </div>

                              <div class="column-3 ">
                                <label class="custom-checkbox right" name="rowRequired">
                                <input
                                  type="checkbox"
                                  name="rowRequired"
                                  value="ro.rowRequired"
                                  v-model="ro.rowRequired"/>
                                <span for="rowRequired" class="checkmark"></span>
                                </label>
                              </div>
                              </div>

                            </span>
                            <div class="form-group pb-4 border-bottom-solid">
                                  <a
                                    href="javascript:void(0);"
                                    @click="addNewRow()"
                                    class="add-more-btn"
                                  >+ Add Row</a>
                            </div>

                            <div class="form-group">

                              <div class="ai-text-rows-code">
                                    <span>Correct custom assessment code: </span>
                                      <input
                                      type="text"
                                      class="form-control"
                                      v-model="correctCustomAssessmentCode">
                              </div>
                              <div class="ai-text-rows-code">
                                    <span>Incorrect custom assessment code: </span>
                                      <input
                                      type="text"
                                      class="form-control"
                                      v-model="incorrectCustomAssessmentCode">
                              </div>
                            </div>


                          </div>
                        </div>
                        <!-- Question Type: Multi Row end -->

                        <!-- number range start-->
                        <div
                          class="question-content"
                          id="number-range"
                          v-if="questionType === 'range'"
                        >
                          <div class="question-row">
                            <div class="form-group">
                              <label>Answer</label>
                              <ValidationProvider
                                name="Answer"
                                rules="required|max:100"
                                v-slot="{ errors }"
                              >
                                <div class="row mt-3">
                                  <div class="col-6">
                                    <label>From</label>
                                    <input
                                      type="number"
                                      class="form-control"
                                      v-model="questionLowRange"
                                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                                    />
                                  </div>
                                  <div class="col-6">
                                    <label>To</label>
                                    <input
                                      type="number"
                                      class="form-control"
                                      v-model="questionHighRange"
                                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                                    />
                                  </div>
                                </div>
                                <span
                                  :class="[isActive ? 'invalid' : '']"
                                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                  v-if="errors[0]"
                                >{{ errors[0].replace("The ", "" )}}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="form-group pt-3 text-right">
                          <a
                            href="javascript:void(0);"
                            class="btn secondary"
                            v-if="isQuestionCreated"
                            @click="createQuestion"
                          >Create Question</a>
                          <a
                            href="javascript:void(0);"
                            class="btn secondary"
                            v-else
                            @click="saveQuestion"
                          >Save Question</a>
                        </div>
                      </div>
                      <!--AI text end-->
                    </div>
                    <!-- Feedback Tab -->
                    <div
                      :class="`tab-pane fade ${ariaSelected ?'show active': ''}`"
                      id="nav-feedback"
                      role="tabpanel"
                      aria-labelledby="feedback-tab"
                    >
                      <!-- feedback content start -->
                      <div class="feedback-content" v-if="!isQuestionCreated">
                        <div class="form-group">
                          <label>Points</label>
                          <ValidationProvider
                            name="Points"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              class="form-control"
                              v-model="questionPoints"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group">
                          <label>Correct Feedback Title</label>
                          <ValidationProvider name="Correct Feedback Title" v-slot="{ errors }">
                            <input
                              placeholder="Add Correct Feedback Title"
                              type="text"
                              class="form-control"
                              v-model="correctFeedbackTitle"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group">
                          <label>Correct Feedback</label>
                          <ValidationProvider name="Correct Feedback" v-slot="{ errors }">
                            <vue-editor
                              id="Descriptiontext"
                              placeholder="Add a Description"
                              :editorToolbar="fullTool"
                              v-model="questionCorrectFeedback"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            ></vue-editor>
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group">
                          <label>Incorrect Feedback Title</label>
                          <ValidationProvider name="Incorrect Feedback Title" v-slot="{ errors }">
                            <input
                              placeholder="Add Incorrect Feedback Title"
                              type="text"
                              class="form-control"
                              v-model="inCorrectFeedbackTitle"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group">
                          <label>Incorrect Feedback</label>
                          <ValidationProvider name="Incorrect Feedback" v-slot="{ errors }">
                            <vue-editor
                              id="Descriptiontext"
                              placeholder="Add a Description"
                              :editorToolbar="fullTool"
                              v-model="questionIncorrectFeedback"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            ></vue-editor>
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>
                        <div class="pt-3 text-right" v-if="!isQuestionCreated">
                          <a
                            href="javascript:void(0);"
                            class="btn secondary"
                            @click="saveFeedback()"
                          >Save Feedback</a>
                        </div>
                      </div>
                      <div class="note" v-else>
                        <p>Note: You can add feedback after you create the question. Please complete step 1.</p>
                      </div>
                      <!-- feedback content end -->
                    </div>
                    <!-- Adaptive Tab -->
                    <div
                      class="tab-pane fade"
                      id="nav-adaptive"
                      role="tabpanel"
                      aria-labelledby="adaptive-tab"
                    >
                      <div class="d-flex align-items-center" v-if="!isQuestionCreated">
                        <h5 class="small-title">Add Adaptive Content</h5>
                        <div class="px-4 learning-quiz-btn">
                          <a
                            @click="$modal.show('uploadInterstitialModal',{create: true, content: 'adaptive'})"
                            class="btn ml-2 px-4 secondary"
                          >Upload</a>
                        </div>
                      </div>
                      <div class="note" v-else>
                        <p>Note: You can add adaptive content after you create the question. Please complete step 1.</p>
                      </div>

                      <div
                        class="learning-data-list flex-column pt-3"
                        v-if="interstitialLists.length > 0"
                      >
                        <div
                          class="learning-data-item w-100 learning-interstitial-data-item"
                          v-for="(item, i) in interstitialLists"
                          :key="item.id"
                        >
                          <div class="learning-data-left">
                            <div
                              class="learning-data-richText"
                              v-if="item.attributes.content_details.type == 'text_learn_obj'"
                            ></div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'file_learn_obj'"
                            >
                              <a>
                                <img src="../assets/images/pdf-big-icon.png" alt="pdf" />
                              </a>
                            </div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'slide_learn_obj'"
                            >
                              <a>
                                <img
                                  :src="item.attributes.content_details.attributes.slider_images[0].attributes.resource_url.thumbnail"
                                  alt="slide"
                                />
                              </a>
                            </div>
                            <div
                              class="learning-data-video"
                              v-if="item.attributes.content_details.type == 'video_learn_obj'"
                            >
                              <div
                                class="wistia_responsive_padding data-video"
                                style="padding:56.25% 0 0 0;position:relative;"
                              >
                                <div
                                  class="wistia_responsive_wrapper data-video"
                                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                                >
                                  <iframe
                                    :src="preIframUrl+item.attributes.content_details.attributes.global_video.attributes.wistia_code+postIframUrl"
                                    title="Video.mp4"
                                    class="wistia_embed"
                                    name="wistia_embed"
                                    allowtransparency="true"
                                    scrolling="no"
                                    allowfullscreen
                                    mozallowfullscreen
                                    webkitallowfullscreen
                                    oallowfullscreen
                                    msallowfullscreen
                                    width="100%"
                                    height="100%"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="learning-data-title">
                            <div class="data-heads">
                              <h4 v-html="item.attributes.title"></h4>
                              <span
                                v-if="item.attributes.content_details.type == 'file_learn_obj'"
                              >{{size(item.attributes.content_details.attributes.resource_size)}}</span>
                            </div>
                            <h4 v-html="item.attributes.content_details.attributes.title"></h4>
                            <div class="learning-data-paragraph">
                              <div>
                                <p v-html="item.attributes.description"></p>
                              </div>
                              <a
                                style="color: #3dbc9e"
                                @click="$modal.show('uploadInterstitialModal',{create: false, content: 'adaptive',edit: item})"
                              >Edit</a>
                              <a @click="removeAdaptiveData(item.id, i)">Remove</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="d-flex justify-content-end mt-4 mb-3" v-if="overallQuizAssessment">
                    <button
                      class="btn primary"
                      @click="handleQuizContent($event, 'overall-assessment', 3)"
                    >Next</button>
                  </div>
                  <div class="d-flex justify-content-end mt-4 mb-3" v-if="!overallQuizAssessment"></div>
                  <!-- <div class="d-flex justify-content-end mt-4 mb-3" v-if="!overallQuizAssessment">
                    <button class="btn primary" @click="submitLo('published')">Save & Publish</button>
                  </div>-->
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveLO('archived')"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <modal name="addQuestionType" height="auto" :width="500" :scrollable="true">
          <div class="hide-modal" @click="$modal.hide('addQuestionType')"></div>
          <div class="modal-content modal-default">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Select Question Type</h5>
              </div>
            </div>
            <div class="modal-body question-modal-body">
              <div class="row">
                <div class="col-6" @click="addQuestionType('multiple_choice')">
                  <a class="add-question-link">
                    <em class="icon-multiple-icon"></em>
                    <span>Multiple Choice</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('numeric')">
                  <a class="add-question-link">
                    <em class="icon-number-icon"></em>
                    <span>Specific Number</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('long_answer')">
                  <a class="add-question-link">
                    <em class="icon-question-icon"></em>
                    <span>AI Text Questions</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('range')">
                  <a class="add-question-link add-question-small">
                    <em class="icon-range-icon"></em>
                    <span>Number in Range</span>
                  </a>
                </div>
                <div class="col-6" @click="addQuestionType('ai_text_rows')">
                  <a class="add-question-link" @click="showSelectQuestionModalMultiRow">
                    <em class="icon-question-icon"></em>
                    <span>AI Text Rows</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </modal>
        <!-- Selecting number of rows for multi row question -->
        <modal name="addQuestionrows" height="auto" :width="500" :scrollable="true">
          <div class="hide-modal" @click="$modal.hide('addQuestionrows')"></div>
          <div class="modal-content modal-default">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <span>Number of Rows:
                <select v-model="newRowNumbersSelected">
                  <option v-for="n in RowNumbers" :value="n" v-bind:key="n" >
                    {{ n }}
                  </option>
                </select>
                </span>
              </div>
              <br /><br />
              <div><button class="btn primary" @click="$modal.hide('addQuestionrows'); addQuestionType('ai_text_rows');multirowsQuestion()" >done</button></div>
            </div>
            <div class="modal-body question-modal-body">
              <div class="row">

              </div>
            </div>
          </div>
        </modal>
        <!-- Selecting number of rows for multi row question end-->
        <modal name="entityEvaluation" height="auto" :width="700" :scrollable="true">
          <div class="modal-content modal-evaluation-content" v-if="asstEntitiesQuizList">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Entity Evaluation Set</h5>
              </div>
            </div>
            <div class="modal-body">
              <form class="modal-form modal-min-height">
                <div class="row mt-3">
                  <VuePerfectScrollbar class="modal-evaluation-scroller" :settings="settings">
                    <div class="col-12">
                      <div
                        class="evaluation-entity-items"
                        v-for="evalItem of asstEntitiesQuizList"
                        :key="evalItem.id"
                      >
                        <div class="evaluation-head">
                          <label>
                            Quiz Entity:
                            <span>{{evalItem.attributes.name}}</span>
                          </label>
                          <div class="evaluation-checkboxes">
                            <label class="custom-checkbox">
                              Select All
                              <input
                                type="checkbox"
                                @change="handleLabelEvalChange($event,evalItem)"
                              />
                              <span class="checkmark circle"></span>
                            </label>
                          </div>
                        </div>
                        <div class="evaluation-values">
                          <label>Values:</label>
                          <div class="evaluation-row">
                            <span
                              v-for="valItem of evalItem.attributes.asst_entity_values"
                              :key="valItem.id"
                            >
                              <drag
                                class="drag"
                                :transfer-data="{
                                entity: evalItem.attributes.name,
                                value: valItem.attributes.value ,
                                isAll: false,
                                asst_entity_value_id: valItem.id,
                                entityId:evalItem.id,
                                valItem }"
                              >{{valItem.attributes.value}}</drag>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </VuePerfectScrollbar>
                  <VuePerfectScrollbar class="modal-evaluation-scroller" :settings="settings">
                    <div class="col-12">
                      <drop class="drop" @drop="handleEvaluationDrop">
                        <div class="evaluation-dragbox">
                          <span
                            class="caption"
                          >{{selectedEvalValue.length == 0 ? 'Drag and Drop values here':''}}</span>
                          <div class="evaluation-chips-container">
                            <div
                              class="evaluation-chips"
                              v-for="(scheme, key) in selectedEvalValue"
                              :key="key"
                            >
                              <span v-if="scheme.isAll">Quiz entity:{{scheme.entity}}</span>
                              <span
                                v-if="!scheme.isAll"
                              >Quiz entity:{{scheme.entity}}, Value:{{scheme.value}}</span>
                              <a href="javascript:void(0);">
                                <em
                                  class="icon-close"
                                  @click="removeEvaluationSet(key, true , scheme.evalId, scheme.entityEvaluationId )"
                                ></em>
                              </a>
                            </div>
                          </div>
                        </div>
                      </drop>
                    </div>
                  </VuePerfectScrollbar>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    @click="hideEvalMOdal"
                    data-dismiss="modal"
                    class="btn default"
                  >Cancel</button>
                  <button type="button" class="btn primary" v-on:click="saveEvaulationScheme">Save</button>
                </div>
              </form>
            </div>
          </div>
        </modal>

        <!-- Edit entity for multi row question -->
        <modal name="editEntityEvaluationMultiRow" height="auto" :width="700" :scrollable="true">
          <div class="modal-content modal-evaluation-content" v-if="asstEntitiesQuizList">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Entity Evaluation Set</h5>
              </div>
            </div>
            <div class="modal-body">
              <form class="modal-form modal-min-height">
                <div class="row mt-3">
                  <VuePerfectScrollbar class="modal-evaluation-scroller" :settings="settings">
                    <div class="col-12">
                      <div
                        class="evaluation-entity-items"
                        v-for="evalItem of asstEntitiesQuizList"
                        :key="evalItem.id"
                      >
                        <div class="evaluation-head">
                          <label>
                            Quiz Entity:
                            <span>{{evalItem.attributes.name}}</span>
                          </label>
                          <div class="evaluation-checkboxes">
                            <label class="custom-checkbox">
                              Select All
                              <input
                                type="checkbox"
                                @change="handleLabelEvalChangeMultiRow($event,evalItem)"
                              />
                              <span class="checkmark circle"></span>
                            </label>
                          </div>
                        </div>
                        <div class="evaluation-values">
                          <label>Values:</label>
                          <div class="evaluation-row">
                            <span
                              v-for="valItem of evalItem.attributes.asst_entity_values"
                              :key="valItem.id"
                            >
                              <drag
                                class="drag"
                                :transfer-data="{
                                entity: evalItem.attributes.name,
                                value: valItem.attributes.value ,
                                isAll: false,
                                asst_entity_value_id: valItem.id,
                                entityId:evalItem.id,
                                valItem }"
                              >{{valItem.attributes.value}}</drag>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </VuePerfectScrollbar>
                  <VuePerfectScrollbar class="modal-evaluation-scroller" :settings="settings">
                    <div class="col-12">
                      <drop class="drop" @drop="handleEvaluationDropMultiRow">
                        <div class="evaluation-dragbox">
                          <span
                            class="caption"
                          >{{selectedEvalValueTemp.length == 0 ? 'Drag and Drop values here':''}}</span>
                          <div class="evaluation-chips-container">
                            <div
                              class="evaluation-chips"
                              v-for="(scheme, key) in selectedEvalValueTemp.evalValue"
                              :key="key"
                            >
                              <span v-if="scheme.isAll">Quiz entity:{{scheme.entity}}</span>
                              <span
                                v-if="!scheme.isAll"
                              >Quiz entity:{{scheme.entity}}, Value:{{scheme.value}}</span>
                              <a href="javascript:void(0);">
                                <em
                                  class="icon-close"
                                  @click="removeEvaluationMultiRow(key, true , scheme.evalId, scheme.entityEvaluationId, scheme )"
                                ></em>
                              </a>
                            </div>
                          </div>
                        </div>
                      </drop>
                    </div>
                  </VuePerfectScrollbar>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    @click="hideEvalMOdalEditMultiRow"
                    data-dismiss="modal"
                    class="btn default"
                  >Cancel</button>
                  <button type="button" class="btn primary" v-on:click="saveEvaulationEntitySchemeMultiRow">Save</button>
                </div>
              </form>
            </div>
          </div>
        </modal>
        <!-- Edit entity for multi row question end -->
        <!-- select entity for multi row question -->
        <modal name="entityEvaluationMultiRow" height="auto" :width="700" :scrollable="true">
          <div class="modal-content modal-evaluation-content" v-if="asstEntitiesQuizList">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Entity Evaluation Set </h5>
              </div>
            </div>
            <div class="modal-body">
              <form class="modal-form modal-min-height">
                <div class="row mt-3">
                  <VuePerfectScrollbar class="modal-evaluation-scroller" :settings="settings">
                    <div class="col-12">
                      <div
                        class="evaluation-entity-items"
                        v-for="evalItem of asstEntitiesQuizList"
                        :key="evalItem.id"
                      >
                        <div class="evaluation-head">
                          <label>
                            Quiz Entity:
                            <span>{{evalItem.attributes.name}}</span>
                          </label>
                          <div class="evaluation-checkboxes">
                            <label class="custom-checkbox">
                              Select All
                              <input
                                type="checkbox"
                                @change="handleLabelEvalChange($event,evalItem)"
                              />
                              <span class="checkmark circle"></span>
                            </label>
                          </div>
                        </div>
                        <div class="evaluation-values">
                          <label>Values:</label>
                          <div class="evaluation-row">
                            <span
                              v-for="valItem of evalItem.attributes.asst_entity_values"
                              :key="valItem.id"
                            >
                              <drag
                                class="drag"
                                :transfer-data="{
                                entity: evalItem.attributes.name,
                                value: valItem.attributes.value ,
                                isAll: false,
                                asst_entity_value_id: valItem.id,
                                entityId:evalItem.id,
                                valItem }"
                              >{{valItem.attributes.value}}</drag>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </VuePerfectScrollbar>
                  <VuePerfectScrollbar class="modal-evaluation-scroller" :settings="settings">
                    <div class="col-12">
                      <drop class="drop" @drop="handleEvaluationDropMultiRow">
                        <div class="evaluation-dragbox">
                          <span
                            class="caption"
                          >{{selectedEvalValueTemp.length == 0 ? 'Drag and Drop values here':''}}</span>
                          <div class="evaluation-chips-container">
                            <div
                              class="evaluation-chips"
                              v-for="(scheme, key) in selectedEvalValueTemp.evalValue"
                              :key="key"
                            >
                              <span v-if="scheme.isAll">Quiz entity:{{scheme.entity}}</span>
                              <span
                                v-if="!scheme.isAll"
                              >Quiz entity:{{scheme.entity}}, Value:{{scheme.value}}</span>
                              <a href="javascript:void(0);">
                                <em
                                  class="icon-close"
                                  @click="removeEvaluationMultiRow(key, true , scheme.evalId, scheme.entityEvaluationId )"
                                ></em>
                              </a>
                            </div>
                          </div>
                        </div>
                      </drop>
                    </div>
                  </VuePerfectScrollbar>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    @click="hideEvalMOdal2"
                    data-dismiss="modal"
                    class="btn default"
                  >Cancel</button>
                  <button type="button" class="btn primary" v-on:click="saveEvaulationEntitySchemeMultiRow">Save</button>
                </div>
              </form>
            </div>
          </div>
        </modal>


        <modal name="deleteQstnConfirmationModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div class="modal-body modal-delete-body">Are you sure you want to delete this question?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('deleteQstnConfirmationModal')"
                class="btn medium default"
              >Cancel</button>
              <button
                type="button"
                @click="handleQuizQuestionSelect(selectedQuizQuestion, true),$modal.hide('deleteQstnConfirmationModal')"
                class="btn medium primary"
              >Ok</button>
            </div>
          </div>
        </modal>
        <!-- Question Setup end here -->
        <!-- Overall Assessment end here -->
        <div
          class="setup-content"
          id="overall-assessment"
          v-if="quizContentId === 'overall-assessment'"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Overall Assessment</h4>
          </div>
          <ValidationObserver ref="form" v-if="overallAssmntItem">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label>Overall Interaction Assessment</label>
                    <ul class="form-entity-tabs">
                      <li
                        v-for="assmntItem of overallAssmntItem"
                        :key="assmntItem.id"
                        :class="assmntItem.id === selectedOverallAssmntItem.id ? 'active' :''"
                        @click="handleOverallAssmntItem(assmntItem)"
                      >
                        <a href="javascript:void(0);">{{assmntItem.attributes.assessment_label}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-7">
                  <!-- number range start-->
                  <div class="question-content" id="number-range">
                    <div class="question-row">
                      <div class="form-group">
                        <label>Evaluation Text</label>
                        <ValidationProvider name="Evaluation Text" v-slot="{ errors }">
                          <vue-editor
                            id="Descriptiontext"
                            placeholder="Add a Description"
                            :editorToolbar="fullTool"
                            v-model="overallAssmntItemFeedback"
                          ></vue-editor>
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="errors[0]"
                          >{{ errors[0].replace("The ", "" )}}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group pt-2">
                        <label>Evaluation range({{overallAssessmentRange}}):</label>
                        <ValidationProvider name="Evaluation range" v-slot="{ errors }">
                          <div class="row mt-2">
                            <div class="col-6">
                              <label>Minimum</label>
                              <input
                                type="number"
                                class="form-control"
                                v-model="overallAssmntItemMinScore"
                                :class="[(isActive && errors[0]) ? 'invalid' : '']"
                              />
                            </div>
                            <div class="col-6">
                              <label>Maximum</label>
                              <input
                                type="number"
                                class="form-control"
                                v-model="overallAssmntItemMaxScore"
                                :class="[(isActive && errors[0]) ? 'invalid' : '']"
                              />
                            </div>
                          </div>
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="errors[0]"
                          >{{ errors[0].replace("The ", "" )}}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <!-- number range end-->
                </div>
                <div class="col-12 pt-5">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <button class="btn primary" @click="submitLo('published')">Save & Publish</button>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveLO('archived')"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <modal
          class="modal-video"
          name="testingQuiz"
          height="auto"
          :width="1000"
          :scrollable="true"
        >
          <div class="modal-content modal-test-content">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Testing</h5>
                <div class="modal-right-box">
                  <div class="modal-head-checkbox">
                    <label class="custom-checkbox mr-4">
                      Test individual questions
                      <input type="checkbox" v-on:change="changeQuestionTest" />
                      <span class="checkmark circle"></span>
                    </label>
                  </div>
                  <a
                    href="javascript:void(0);"
                    class="btn secondary"
                    v-on:click="resetTest(true)"
                  >Reset test</a>
                </div>
              </div>
              <button type="button" class="close" @click="hideTestModal">
                <em class="icon-close"></em>
              </button>
            </div>
            <div class="modal-body" v-if="allQuestionTestId">
              <form class="modal-form modal-min-height">
                <div class="form-group mt-2">
                  <label>Select Question</label>
                  <div class="test-dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="entity"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {{selectQuestionToTest && selectQuestionToTest.name}}
                      <em
                        class="icon-carrot-down"
                      ></em>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="entity">
                      <option
                        class="dropdown-item"
                        v-for="(item, index) of quizQuestionList"
                        @click="handleselectQuestionToTest($event, true)"
                        :value="index"
                        :key="item.id"
                      >{{item.attributes.question}}</option>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <p>{{selectQuestionToTest.name}}</p>
                </div>
                <div class="form-group" v-if="selectQuestionToTest.data">
                  <label>Answer</label>
                  <input
                    v-if="selectQuestionToTest.question_type ==='numeric' ||
                          selectQuestionToTest.question_type ==='range'"
                    type="number"
                    class="form-control"
                    v-model="testQuizAnswer"
                  />
                  <textarea
                    v-if="selectQuestionToTest.question_type === 'long_answer'"
                    class="form-control form-textarea"
                    v-model="testQuizAnswer"
                  ></textarea>

                  <div

                    v-if="selectQuestionToTest.question_type === 'ai_text_rows'"
                    >
                    <div >
                      <div v-for="(item, index) of testMultiRowResponses" :key="index">
                        <textarea

                          class="form-control form-textarea"
                           v-model="item.responseMultiRow"
                        ></textarea>
                        <br/>
                      </div>
                    </div>



                  </div>
                  <div
                    class="learn-voice-form"
                    v-if="selectQuestionToTest.question_type ==='multiple_choice'"
                  >
                    <div class="learn-voice-checkboxes">
                      <div class="form-checkboxes">
                        <!-- repeat checkbox items here -->
                        <div
                          class="form-checkboxes-items"
                          v-for="item in selectQuestionToTest.data.attributes.mcq_options"
                          :key="item.id"
                        >
                          <label
                            class="custom-checkbox"
                            name="public"
                            :class="[editWorldStatus ? 'disabled' : '']"
                          >
                            {{item.attributes.option}}
                            <input
                              type="radio"
                              name="public"
                              @change="changeQuestionTest($event, true)"
                              v-bind:value="item.attributes.is_correct"
                            />
                            <span for="public" class="checkmark circle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn primary" v-on:click="handleCheckQuizTest">Test</button>
                </div>
                <div class="learn-object-results" v-if="isTestQuizAnswer === 'yes'">
                  <div class="learn-success-msg result-msg">
                    <em class="icon-check"></em>
                    Yes, that is the correct answer.
                  </div>
                </div>
                <div class="learn-object-results" v-if="isTestQuizAnswer === 'no'">
                  <div class="learn-error-msg result-msg">
                    <em class="icon-close"></em>
                    Oh! That’s Incorrect.
                  </div>
                  <div class="form-group col-12 px-0" v-if="correctAnswer">
                    <ValidationProvider name="Card Title">
                      <input
                        type="text"
                        class="form-control test-big-input-control"
                        v-model="correctAnswer"
                        id="name"
                        disabled
                      />
                    </ValidationProvider>
                  </div>
                </div>

                <div
                  class="modal-test-tabs"
                  v-if="(['long_answer', 'ai_text_rows'].includes(selectQuestionToTest.question_type)) && aiWatsonResponse.length > 0"
                >
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="Test-tab"
                        data-toggle="tab"
                        href="#Test"
                        role="tab"
                        aria-controls="Test"
                        aria-selected="true"
                      >Test input Return Data</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="json-tab"
                        data-toggle="tab"
                        href="#json"
                        role="tab"
                        aria-controls="json"
                        aria-selected="false"
                      >View JSON</a>
                    </li>
                  </ul>
                  <div class="tab-content pt-2" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="Test"
                      role="tabpanel"
                      aria-labelledby="Test-tab"
                    >
                      <div
                        class="tab-pane fade show active"
                        id="Test"
                        role="tabpanel"
                        aria-labelledby="Test-tab"
                        v-if="aiWatsonResponse.length > 0"
                      >
                        <div
                          class="test-items"
                          v-for="(quiz,index) in aiWatsonResponse"
                          :key="index"
                        >
                          <label v-if="selectQuestionToTest.question_type === 'long_answer'">Watson Response:</label>
                          <label v-else>Entity hit for Text Row:{{quiz.attributes.text_row_number }} [ID: {{quiz.attributes.text_row_id}}]</label>
                          <div class="test-chips-container">
                            <span
                              class="test-chips"
                              v-for="(item,i) in quiz.attributes.watson_response"
                              :key="i"
                            >Quiz entity:{{item.entity}}, Value:{{item.value}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="json" role="tabpanel" aria-labelledby="json-tab">
                      <json-viewer :value="aiWatsonResponse" :expand-depth="5"></json-viewer>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-body" v-if="!allQuestionTestId">
              <div class="learn-object">
                <div class="learn-object-progress">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      v-bind:style="selectQuestionToTest.statusBar"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div
                    class="learn-object-question"
                  >Question {{selectQuestionToTest.id + 1}}/{{totalQuizQues}}</div>
                </div>
                <div class="learn-object-editor">
                  <!-- learn object repeat start -->
                  <div class="learn-object-info">
                    <h4>{{selectQuestionToTest.name}}</h4>
                  </div>
                  <div class="learn-voice-form">
                    <div class="learn-voice-checkboxes">
                      <div
                        class="learn-voice-placeholder"
                        v-if="selectQuestionToTest.question_type ==='numeric' ||
                          selectQuestionToTest.question_type ==='range'"
                      >
                        <div class="form-group col-6 pl-0">
                          <ValidationProvider name="Card Title">
                            <input
                              type="text"
                              class="form-control test-input-control"
                              id="name"
                              v-model="testQuizAnswer"
                            />
                          </ValidationProvider>
                        </div>
                        <div class>
                          <button class="btn secondary" v-on:click="handleCheckQuizTest">Test</button>
                        </div>
                      </div>
                      <div
                        class="learn-voice-placeholder"
                        v-if="selectQuestionToTest.question_type === 'long_answer'"
                      >
                        <div class="form-group col-6 pl-0">
                          <textarea
                            v-if="selectQuestionToTest.question_type === 'long_answer'"
                            class="form-control form-textarea"
                            v-model="testQuizAnswer"
                          ></textarea>
                        </div>
                        <div class>
                          <button class="btn secondary" v-on:click="handleCheckQuizTest">Test</button>
                        </div>
                      </div>


                      <div

                    v-if="selectQuestionToTest.question_type === 'ai_text_rows'"
                    >
                    <div >
                      <div class="form-group col-8 pl-0" v-for="(item, index) of testMultiRowResponses" :key="index">
                        <textarea

                          class="form-control form-textarea"
                           v-model="item.responseMultiRow"
                        ></textarea>
                        <br/>
                      </div>
                    </div>
                    <div class>
                          <button class="btn secondary" v-on:click="handleCheckQuizTest">Test</button>
                      </div>


                  </div>


                      <div
                        class="form-checkboxes"
                        v-if="selectQuestionToTest && selectQuestionToTest.question_type ==='multiple_choice'"
                      >
                        <!-- repeat checkbox items here -->
                        <div
                          class="form-checkboxes-items"
                          v-for="item in selectQuestionToTest.data.attributes.mcq_options"
                          :key="item.id"
                        >
                          <label
                            class="custom-checkbox"
                            name="public"
                            :class="[editWorldStatus ? 'disabled' : '']"
                          >
                            {{item.attributes.option}}
                            <input
                              type="radio"
                              name="public"
                              @change="handleChangeQuestionTest($event)"
                              v-bind:value="item.attributes.is_correct"
                            />
                            <span for="public" class="checkmark circle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="learn-object-pagination">
                      <a
                        href="javascript:void(0);"
                        class="btn default"
                        @click="handleTestPage(false)"
                        v-if="selectQuestionToTest.id + 1 !== 1"
                      >Previous</a>
                      <a
                        href="javascript:void(0);"
                        class="btn primary ml-auto"
                        v-if="selectQuestionToTest.id + 1 < totalQuizQues"
                        @click="handleTestPage(true)"
                      >Next</a>
                    </div>
                    <div class="learn-object-results" v-if="isTestQuizAnswer === 'yes'">
                      <div class="learn-success-msg result-msg">
                        <em class="icon-check"></em>
                        Yes, that is the correct answer.
                      </div>
                    </div>
                    <div class="learn-object-results" v-if="isTestQuizAnswer === 'no'">
                      <div class="learn-error-msg result-msg">
                        <em class="icon-close"></em>
                        Oh! That’s Incorrect.
                      </div>
                      <div class="form-group col-12 px-0" v-if="correctAnswer">
                        <ValidationProvider name="Card Title">
                          <input
                            type="text"
                            class="form-control test-big-input-control"
                            v-model="correctAnswer"
                            id="name"
                            disabled
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <div
                      class="modal-test-tabs"
                      v-if="(['long_answer', 'ai_text_rows'].includes(selectQuestionToTest.question_type)) && aiWatsonResponse.length > 0"
                    >
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="Test-tab"
                            data-toggle="tab"
                            href="#Test"
                            role="tab"
                            aria-controls="Test"
                            aria-selected="true"
                          >Test input Return Data</a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="json-tab"
                            data-toggle="tab"
                            href="#json"
                            role="tab"
                            aria-controls="json"
                            aria-selected="false"
                          >View JSON</a>
                        </li>
                      </ul>
                      <div class="tab-content pt-2" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="Test"
                          role="tabpanel"
                          aria-labelledby="Test-tab"
                        >
                          <div
                            class="tab-pane fade show active"
                            id="Test"
                            role="tabpanel"
                            aria-labelledby="Test-tab"
                            v-if="aiWatsonResponse.length > 0"
                          >
                            <div
                              class="test-items"
                              v-for="(quiz,index) in aiWatsonResponse"
                              :key="index"
                            >
                              <label>Watson Response:</label>
                              <div class="test-chips-container">
                                <span
                                  class="test-chips"
                                  v-for="(item,i) in quiz.attributes.watson_response"
                                  :key="i"
                                >Quiz entity:{{item.entity}}, Value:{{item.value}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="json"
                          role="tabpanel"
                          aria-labelledby="json-tab"
                        >
                          <json-viewer :value="aiWatsonResponse" :expand-depth="5"></json-viewer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>
        <!-- Question Assessment end here -->
      </div>
      <!-- Quiz tabs content end here -->
    </div>
    <div class="form-section" v-if="elmMenu.card_type === 'chat'">
      <div class="learning-form-header flex-wrap">
        <h4>Interaction(Chat)</h4>
        <div class="learning-quiz-btn">
          <a
            href="javascript:void(0);"
            @click="submitLo('published')"
            class="btn header-btn secondary"
            v-if="status == 'published'"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            class="btn header-btn primary"
            @click="submitLo('published')"
          >Publish LO</a>
        </div>
      </div>
      <!-- Quiz tabs start here -->
      <div class="learning-lo-tabs">
        <div class="setup-container">
          <ul>
            <li :class="{ active: isActiveTab[0] }" @click="chatMenu('basic-information', 0)">
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Basic Information</span>
              </a>
            </li>
            <li :class="{ active: isActiveTab[1] }" @click="chatMenu('watson-setup', 1)">
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Watson Chat Setup</span>
              </a>
            </li>
            <li :class="{ active: isActiveTab[2] }" @click="chatMenu('assessment-setup', 2)">
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Assessment Setup</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="learning-quiz-content">
        <div class="setup-content" v-if="chatContentId == 'basic-information'">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Basic Information</h4>
          </div>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label>Interaction Title</label>
                    <ValidationProvider
                      name="Interaction Title"
                      rules="required|max:100"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Administrative Notes</label>
                    <ValidationProvider name="Description">
                      <textarea
                        type="Description"
                        class="form-control"
                        id="Description"
                        rows="8"
                        v-model="adminNotes"
                      ></textarea>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="form-group customer-dropdown">
                    <label class="form-label" for="exampleInputEmail1">Select chat character</label>
                    <input
                      type="text"
                      v-model="searchCharacter"
                      v-on:input="filterCharacter"
                      class="form-control"
                      placeholder="Search by name, role & organization"
                    />

                    <div
                      class="user-row-scroll custom-user-dropdown"
                      v-if="searchCharacters.length && searchCharacter.length > 0 && charStatus"
                    >
                      <div
                        class="user-row"
                        v-for="(character) in searchCharacters"
                        :key="character.id"
                      >
                        <div
                          class="img-container"
                          @click="setCharacter(character.char_full_name, character.id)"
                        >
                          <!-- <div class="img-box">
                            <img
                              :src="url+character.char_photo_url"
                              v-if="character.char_photo_url"
                              alt="image"
                            />
                          </div> -->
                          <div class="user-info">
                            <div class="head">{{character.char_full_name}}</div>
                            <div class="text">{{character.world_role}} at {{character.org_name}}</div>
                            <p>{{character.char_age}} , {{character.char_gender | uppercase(true)}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group customer-dropdown">
                    <label
                      class="form-label"
                      for="exampleInputEmail1"
                    >Select mentor character(optional)</label>
                    <input
                      type="text"
                      v-model="searchOptionalCharacter"
                      v-on:input="filterOptionalCharacter"
                      class="form-control"
                      placeholder="Search by name, role & organization"
                    />
                    <div
                      class="user-row-scroll custom-user-dropdown"
                      v-if="searchOptionalCharacters.length && searchOptionalCharacter.length > 0 && optionalCharStatus"
                    >
                      <div
                        class="user-row"
                        v-for="(character) in searchOptionalCharacters"
                        :key="character.id"
                      >
                        <div
                          class="img-container"
                          @click="setOptionalCharacter(character.char_full_name, character.id)"
                        >
                          <!-- <div class="img-box">
                            <img
                              :src="url+character.char_photo_url"
                              v-if="character.char_photo_url"
                              alt="image"
                            />
                          </div> -->
                          <div class="user-info">
                            <div class="head">{{character.char_full_name}}</div>
                            <div class="text">{{character.world_role}} at {{character.org_name}}</div>
                            <p>{{character.char_age}} , {{character.char_gender | uppercase(true)}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Select assessment scheme</label>
                    <div class="quiz-dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="entity"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :class="[(isActive && !assessmentListKey.id && overallQuizAssessment) ? 'invalid' : '']"
                      >
                        {{assessmentListKey.name}}
                        <em class="icon-carrot-down"></em>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="entity">
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          :value="`None*${null}`"
                        >None</option>
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          :value="`${assessment.id}*${assessment.attributes.name}`"
                          v-for="assessment of assessmentLists"
                          :key="assessment.id"
                        >{{assessment.attributes.name}}</option>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end mb-4">
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="saveChatlo('drafted', 'watson-setup' , 1)"
                    >Next</a>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveLO('archived')"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <!-- Basic Information end here -->
        <!-- Entity Setup start here -->
        <div class="setup-content" v-if="chatContentId == 'watson-setup'">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Watson Setup</h4>
            <div class="learning-quiz-btn">
              <a
                href="javascript:void(0);"
                class="btn btn-auto header-btn secondary"
                @click="handleImportCSV($event,'dialog_skill')"
              >Link to Assistant Dialog Skill</a>
            </div>
          </div>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group mb-2">
                    <label>Copy Paste Assistant info:</label>
                  </div>
                  <div class="form-group">
                    <label>Assistant ID</label>

                    <ValidationProvider
                      name="Assistant ID"
                      rules="required|max:100"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="assistantKey"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Assistant API Key</label>
                    <ValidationProvider
                      name="Assistant API Key"
                      rules="required|max:100"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="apiKey"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Assistant Service URL</label>
                    <ValidationProvider name="Assistant Service URL" v-slot="{ errors }">
                      <input
                        type="text"
                        class="form-control"
                        v-model="serviceUrl"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group form-group-success">
                    <label>
                      <em class="icon-check"></em>
                      Watson connected successfully
                    </label>
                  </div>
                </div>

                <div class="col-12">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="saveWatsonConfig('assessment-setup' , 2)"
                    >Next</a>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveLO('archived')"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <!-- Entity Setup end here -->
        <!-- Question Setup start here -->
        <div class="setup-content" v-if="chatContentId == 'assessment-setup'">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Assessment Setup</h4>
            <div class="learning-quiz-btn align-items-center">
              <span class="learning-notification">Skills last imported: {{last_skills_import_date}}</span>
              <a
                href="javascript:void(0);"
                class="btn btn-auto header-btn secondary"
                @click="importSkillFromAssistant(true)"
              >Import skills from Assistant</a>
            </div>
          </div>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Skills</label>
                    <ul class="form-entity-tabs" v-if="chatSkillList && selectedChatSkill">
                      <li
                        v-for="skill of chatSkillList"
                        :class="selectedChatSkill.id === skill.id ? 'active':''"
                        :key="skill.id"
                      >
                        <a
                          href="javascript:void(0);"
                          @click="handleSelectSkill(skill, true)"
                        >{{skill.attributes && skill.attributes.name}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm chat-sm">
                  <div class="form-group d-flex flex-column w-100">
                    <label>Evaluations</label>
                    <div class="chat-tabs" v-if="assessmentSkillLabelList.length > 0">
                      <a
                        class="active"
                        href="javascript:void(0);"
                        v-for="(assessment, index) of assessmentSkillLabelList"
                        :key="index"
                        @click="handleSelectSkill(assessment, false)"
                      >
                        {{assessment.attributes && assessment.attributes.name}}
                        <em
                          class="icon-carrot-right"
                          v-if="assessment.id === selAssessmentSkillLabel.id"
                        ></em>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      class="nav-item nav-link active"
                      id="question-tab"
                      data-toggle="tab"
                      href="#nav-question"
                      role="tab"
                      aria-controls="nav-question"
                      aria-selected="true"
                    >Question Setup</a>
                    <a
                      class="nav-item nav-link"
                      id="adaptive-tab"
                      data-toggle="tab"
                      href="#nav-adaptive"
                      role="tab"
                      @click="updatedSkillItems"
                      aria-controls="nav-adaptive"
                      aria-selected="false"
                    >Adaptive configuration</a>
                  </div>
                  <div class="tab-content" id="nav-tabContent">
                    <!-- Question Tab -->
                    <div
                      class="tab-pane fade show active"
                      id="nav-question"
                      role="tabpanel"
                      aria-labelledby="question-tab"
                    >
                      <!-- multiple choice start-->
                      <div class="question-content" id="multiple-choice">
                        <div class="question-row">
                          <div
                            class="form-group"
                            v-if="selAssessmentSkillLabel.attributes &&
                              selAssessmentSkillLabel.attributes.name !== 'Missed'"
                          >
                            <ValidationProvider name="Interaction Title">
                              <div class="row mt-2">
                                <div class="col-6">
                                  <label>Value Range(Low)</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="selectedSkillItem.value_count_min"
                                  />
                                </div>
                                <div class="col-6">
                                  <label>Value Range(High)</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="selectedSkillItem.value_count_max"
                                  />
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="form-group">
                            <label>Score</label>
                            <ValidationProvider name="Interaction Title">
                              <input
                                type="number"
                                class="form-control"
                                v-model="selectedSkillItem.points"
                              />
                            </ValidationProvider>
                          </div>
                          <div class="form-group">
                            <label>Debrief Content</label>
                            <ValidationProvider
                              name="Debrief Content"
                              rules="max:500"
                              v-slot="{ errors }"
                            >
                              <input
                                type="text"
                                class="form-control"
                                :class="[(isActive && errors[0]) ? 'invalid' : '']"
                                v-model="selectedSkillItem.debrief_contents"
                              />
                              <span
                                :class="[isActive ? 'invalid' : '']"
                                :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                v-if="errors[0]"
                              >{{ errors[0].replace("The ", "" )}}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <!-- multiple choice end -->
                    </div>
                    <!-- Adaptive Tab -->
                    <div
                      class="tab-pane fade"
                      id="nav-adaptive"
                      role="tabpanel"
                      aria-labelledby="adaptive-tab"
                    >
                      <div class="d-flex align-items-center">
                        <h5 class="small-title">Add Adaptive Content</h5>
                        <div class="px-4 learning-quiz-btn">
                          <a
                            @click="$modal.show('uploadInterstitialModal',{create: true, content: 'adaptive'})"
                            class="btn ml-2 px-4 secondary"
                          >Upload</a>
                        </div>
                      </div>
                      <div
                        class="learning-data-list flex-column pt-3"
                        v-if="selAssessmentSkillLabel.adaptive_contents"
                      >
                        <div
                          class="learning-data-item w-100 learning-interstitial-data-item"
                          v-for="(item, i) in selAssessmentSkillLabel.adaptive_contents"
                          :key="item.id"
                        >
                          <div class="learning-data-left">
                            <div
                              class="learning-data-richText"
                              v-if="item.attributes.content_details.type == 'text_learn_obj'"
                            ></div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'file_learn_obj'"
                            >
                              <a>
                                <img src="../assets/images/pdf-big-icon.png" alt="pdf" />
                              </a>
                            </div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'slide_learn_obj'"
                            >
                              <a>
                                <img
                                  :src="item.attributes.content_details.attributes.slider_images[0].attributes.resource_url.thumbnail"
                                  alt="slide"
                                />
                              </a>
                            </div>
                            <div
                              class="learning-data-video"
                              v-if="item.attributes.content_details.type == 'video_learn_obj'"
                            >
                              <div
                                class="wistia_responsive_padding data-video"
                                style="padding:56.25% 0 0 0;position:relative;"
                              >
                                <div
                                  class="wistia_responsive_wrapper data-video"
                                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                                >
                                  <iframe
                                    :src="preIframUrl+item.attributes.content_details.attributes.global_video.attributes.wistia_code+postIframUrl"
                                    title="Video.mp4"
                                    class="wistia_embed"
                                    name="wistia_embed"
                                    allowtransparency="true"
                                    scrolling="no"
                                    allowfullscreen
                                    mozallowfullscreen
                                    webkitallowfullscreen
                                    oallowfullscreen
                                    msallowfullscreen
                                    width="100%"
                                    height="100%"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="learning-data-title">
                            <div class="data-heads">
                              <h4 v-html="item.attributes.title"></h4>
                              <span
                                v-if="item.attributes.content_details.type == 'file_learn_obj'"
                              >{{size(item.attributes.content_details.attributes.resource_size)}}</span>
                            </div>
                            <h4 v-html="item.attributes.content_details.attributes.title"></h4>
                            <div class="learning-data-paragraph">
                              <div>
                                <p v-html="item.attributes.description"></p>
                              </div>
                              <a
                                style="color: #3dbc9e"
                                @click="$modal.show('uploadInterstitialModal',{create: false, content: 'adaptive',edit: item})"
                              >Edit</a>
                              <a @click="removeAdaptiveData(item.id, i)">Remove</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <a href="javascript:void(0);" @click="createTestChat" class="btn primary">Test</a>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="archiveLO('archived')"
                        class="text"
                      >Archive this Learning Object(LO)</a>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <!-- Quiz tabs content end here -->
    </div>
    <div
      class="form-section"
      v-if="elmMenu.card_type === 'email' && elmMenu.learning_object_type === 'interaction'"
    >
      <interactiveEmailLo
        :selectedMenuItem="selectedMenuItem"
        :assessmentLists="assessmentLists"
        :id="id"
        :emailInteractiveData="emailInteractiveData"
        v-on:updateSubmissionList="updateSubmissionList"
        v-on:archiveLO="archiveLO"
        :type="elmMenu.card_type"
        :charList="characters"
        ref="interactionEmailLo"
      />
    </div>
    <div class="form-section" v-if="elmMenu.card_type === 'dialogic'">
      <interactiveDialogiclLo
        v-on:updateDialogicSubmit="updateDialogicSubmit"
        v-on:archiveLO="archiveLO"
        :elmMenu="elmMenu"
        :selectedMenuItem="selectedMenuItem"
        :worldId="worldId"
        ref="form"
      />
    </div>
    <div class="form-section" v-if="elmMenu.card_type === 'submission'">
      <SubmissionCard
        v-on:updateSubmissionList="updateSubmissionList"
        v-on:archiveLO="archiveLO"
        :elmMenu="elmMenu"
        :selectedMenuItem="selectedMenuItem"
        v-on:updateLoList="updateLoList"
        ref="gradingSubmission"
      />
    </div>
    <div class="form-section" v-if="elmMenu.card_type === 'submission_email'">
      <SubmissionEmail
        v-on:archiveLO="archiveLO"
        :elmMenu="elmMenu"
        :selectedMenuItem="selectedMenuItem"
        v-on:updateLoList="updateLoList"
        :characters="characters"
        ref="form"
      />
    </div>
    <div class="form-section" v-if="elmMenu.card_type === 'external_api'">
      <ExternalAPI
        v-on:archiveLO="archiveLO"
        :elmMenu="elmMenu"
        :id="id"
        :externalApiInteractiveData="externalApiInteractiveData"
        :assessmentLists="assessmentLists"
        :selectedMenuItem="selectedMenuItem"
        v-on:updateLoList="updateLoList"
        ref="form"
      />
    </div>

    <div class="form-section" v-if="elmMenu.card_type === 'coding'">
      <CodingLO
        v-on:archiveLO="archiveLO"
        :elmMenu="elmMenu"
        :id="id"
        :assessmentLists="assessmentLists"
        :selectedMenuItem="selectedMenuItem"
        v-on:updateLoList="updateLoList"
        ref="interactionCodingLO"
      />
    </div>
    <div class="form-section" v-if="llmCard" :key="llmKey">
      <llm-view
        :llmCardType="llmCardType"
        :llmData="$store.state.llmData"
        :newLo="$store.state.newLo"
        v-on:archiveLO="archiveLO"
      />
    </div>
    <!-- add feedback modal end -->
    <modal class="modal-video" name="chatModal" height="auto" :width="1000" :scrollable="true">
      <div class="modal-content modal-test-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Chat</h5> 
            <div class="modal-right-box">
              <a
                href="javascript:void(0);"
                class="btn secondary"
                v-on:click="resetChatTest()"
              >Reset test</a>
            </div>
          </div>
          <button type="button" class="close" @click="$modal.hide('chatModal')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body">
          <form class="modal-form modal-min-height">
            <div class="learn-object learn-chat">
              <div class="learn-chat-container">
                <!-- learn chat me  -->
                <div v-for="(ques, index) of testChatQuesData" :key="index">
                  <div class="learn-chat-user" v-if="ques.assistant_response">
                    <div class="learn-chat-box">
                      <div class="learn-chat-image"></div>
                      <div class="learn-chat-msg">{{ques.test_message}}</div>
                    </div>
                    <div class="learn-chat-time">{{ques.test_time}} min</div>
                  </div>
                  <div class="learn-chat-me">
                    <div class="learn-chat-box">
                      <div class="learn-chat-msg">{{ques.assistant_response}}</div>
                      <div class="learn-chat-image"></div>
                    </div>
                    <div class="learn-chat-time" v-if="index === testChatQuesData.length-1">Just now</div>
                    <div class="learn-chat-time" v-else>{{ques.assistant_time}} min</div>
                  </div>
                  <!-- learn chat user  -->
                </div>
              </div>
              <form class="learn-chat-footer">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    placeholder="Type your Message here"
                    v-on:keyup.enter="sendChatQuestionTest"
                    v-model="testChatQuestion"
                  ></textarea>
                </div>
                <div class="form-chat-btn">
                  <a class="btn primary" @click="sendChatQuestionTest">Send</a>
                </div>
              </form>
            </div>
          </form>
        </div>
      </div>
    </modal>
    <addInterstitialContent
      v-on:adaptiveContents="adaptiveContents"
      v-if="elmMenu.card_type == 'quiz' || elmMenu.card_type == 'chat'"
    />
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";
import axios from "axios";
import AddVideo from "../components/modal/AddVideo.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import pdfModal from "./modal/PdfComponent.vue";
import carousel from "vue-owl-carousel";
import interactiveEmailLo from "../components/modal/interactiveEmailLo.vue";
import interactiveDialogiclLo from "../components/modal/interactiveDialogicLo.vue";
import { Drag, Drop } from "vue-drag-drop";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SubmissionCard from "./SubmissionCard.vue";
import SubmissionEmail from "../components/modal/SubmissionEmail.vue";
import ExternalAPI from "./modal/ExternalAPI.vue";
import CodingLO from "./modal/CodingLO.vue";
import llmView from "../components/gpt3-interaction/LlmView.vue";
import moment from "moment-timezone";
import addInterstitialContent from "@/components/modal/addInterstitialContent.vue";
import { extend } from "vee-validate"; //import extend function from vee-validate
import csvPreview from "@/components/modal/csvPreview.vue";

extend("entityValueNameRule", {
  message:
    "{_field_} must contain only alphanmeric characters, spaces, hyphen or underscore ",
  validate: value => {
    var regexp = /^[a-zA-Z0-9-_\s]+$/;
    return regexp.test(value);
  }
});

export default {
  name: "LoMenu",
  components: {
    VueEditor,
    ValidationProvider,
    ValidationObserver,
    draggable,
    VueSlickCarousel,
    pdfModal,
    AddVideo,
    interactiveEmailLo,
    interactiveDialogiclLo,
    addInterstitialContent,
    carousel,
    Drag,
    Drop,
    VuePerfectScrollbar,
    SubmissionCard,
    SubmissionEmail,
    ExternalAPI,
    CodingLO,
    llmView,
    csvPreview
  },
  filters: {
    uppercase: function(value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    },
    heading: function(value) {
      if (value === "plot_point") value = "Plot Point";
      if (value === "content") value = "Content";
      if (value === "interaction") value = "Interaction";
      if (value === "llm_chat") value = "LLM Chat";
      if (value === "llm_email") value = "LLM Email";
      if (value === "llm_dialogic") value = "LLM Dialogic";
      return value;
    }
  },
  data() {
    return {
      RowNumbers: [2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      RowNumbersSelected: 1,
      newRowNumbersSelected:2,
      oldRowNumbersSelected: 1,
      multiRowsEntitiesEvaluation: [],
      multiRowsEntitiesSetTemp: [],
      selectedEvalValueTemp: [],
      rowSelected: 1,
      rowNumberTemp: 1,
      evalNumberTemp: 1,
      checkRowsSequenceMultiRow: false,
      correctRowsPass:1,
      correctCustomAssessmentCode:"",
      incorrectCustomAssessmentCode:"",
      textRowInfoId: 1,
      deleteEvaluationSchemeMultiRow: [],
      deleteEntityMultiRowTemp: [],
      testMultiRowResponses:[],
      isDragging: false,
      menus: [],
      selectedFile: "",
      image: "",
      fileName: "",
      card_type: "",
      learning_object_type: "",
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      name: "",
      description: "",
      contentDescription: "",
      transcript: "",
      title: "",
      isActive: false,
      imageCaption: false,
      loid: this.$route.query.id,
      video: "",
      display: false,
      type: "",
      imageTitle: "",
      competencyCode: "",
      slider: [],
      sliderData: [],
      fullTool: [],
      characters: [],
      selectedChar: [],
      settings: {
        dots: false,
        infinite: false,
        dotsClass: "slick-dots custom-dot-class",
        speed: 200,
        slidesToShow: 7,
        slidesToScroll: 1,
        maxScrollbarLength: 30
      },
      searchCharacters: [
        {
          to: false,
          cc: false,
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          // char_photo_url: "",
          char_age: "",
          char_gender: ""
        }
      ],
      searchCharacter: "",
      id: this.$route.params.id,
      index: 0,
      url: utilFunctionService.getImageUrl(),
      imagePreview: false,
      uploadGalleryStatus: true,
      searchDoc: "",
      docs: [],
      uploadDocs: [],
      docLists: [],
      removestatus: 0,
      ops: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true
        },
        scrollPanel: {
          initialScrollY: "0%",
          initialScrollX: false,
          scrollingX: true,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right"
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "5px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "0px",
          keepShow: false
        },
        bar: {
          showDelay: 500,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: "5px",
          disable: false,
          entitySynonymName: "",
          mcqQuesIndex: null
        }
      },
      page: 1,
      imageLists: [],
      totalImages: "",
      totalPages: "",
      searchImg: "",
      sortOrder: "desc",
      sortColumn: "id",
      editWorldStatus: false,
      quizContentId: "basic-information",
      chatContentId: "basic-information",
      isActiveTab: ["active"],
      assessmentLists: null,
      assessmentListKey: { name: "None", id: null },
      overallAssessment: false,
      overallQuizAssessment: false,
      quizQuestionOrder: false,
      quizHideFeedbak: false,
      showCorrectAdaptiveFeedback: false,
      scoreViewType: null,
      asstEntitiesList: null,
      cardAsstEntityId: "",
      quizEntityName: "",
      quizQuestionList: [],
      cc_characters: [],
      to_characters: [],
      selectedQuizQuestion: null,
      selectedAsstEntities: null,
      asstName: "",
      asstSynonyms: "",
      selectedSynonymList: null,
      entitySynonymName: "",
      questionType: "",
      questionValue: "",
      numericQuestionAnswer: "",
      questionPoints: 1,
      questionIncorrectFeedback: "",
      questionCorrectFeedback: "",
      questionHighRange: "",
      questionLowRange: "",
      attributes: null,
      overallAssmntItem: [],
      selectedOverallAssmntItem: [],
      searchOptionalCharacter: "",
      searchOptionalCharacters: [
        {
          to: false,
          cc: false,
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          // char_photo_url: "",
          char_age: "",
          char_gender: ""
        }
      ],
      charactersList: [],
      chatCharacterId: "",
      chatMentorId: "",
      charStatus: false,
      optionalCharStatus: false,
      adminNotes: "",
      multipleChoiceAnswer: [
        {
          id: 0,
          option: "",
          is_correct: false,
          isActive: true
        }
      ],
      counter: 0,
      selectedMultipleChoiceAnswer: "",
      deletedSynonymsList: [],
      clickCount: 0,
      allQuestionTestId: false,
      selectQuestionToTest: [],
      testQuizAnswer: "",
      isTestQuizAnswer: null,
      correctAnswer: null,
      quizTestCounter: 0,
      totalQuizQues: null,
      assistantKey: "",
      apiKey: "",
      serviceUrl: "",
      ariaSelected: false,
      previousQuesData: [],
      isQuestionCreated: false,
      cardAsstSchemeId: "",
      overallAssmntItemFeedback: "",
      overallAssmntItemMaxScore: "",
      overallAssmntItemMinScore: "",
      asstEntitiesQuizList: null,
      deleteStatus: false,
      overallAssessmentRange: "",
      assessment_scheme_id: null,
      selectedEvalValue: [],
      selectedEvaulationScheme: [],
      allQuizQuestionTestList: [],
      worldId: "",
      emailInteractiveData: "",
      externalApiInteractiveData: "",
      selectedMenuItem: null,
      chatSkillList: null,
      skillFromAssistantData: null,
      assessmentSkillLabelList: [],
      selAssessmentSkillLabel: {},
      selectedChatSkill: null,
      selectedSkillItem: {
        value_count_max: "",
        value_count_min: "",
        points: "",
        debriefContent: "",
        debrief_contents: ""
      },
      isAIEdited: false,
      selEvaluationId: null,
      evaluationIdList: [],
      deletedEvaulationScheme: {},
      discardSelIndex: null,
      assistant_dialog_skill_id: "",
      assistant_id: "",
      last_skills_import_date: "",
      testChatData: null,
      testChatQuestion: "",
      testChatQuesData: [],
      currentDate: null,
      quizEvalId: null,
      testEvaulationScheme: [],
      testEvalJsonScheme: null,
      interstitialLists: [],
      aiWatsonResponse: [],
      selectedVariation: 1,
      emailBody1: "",
      emailBody2: "",
      emailBody3: "",
      textBody: "",
      emailPdfs: [],
      correctFeedbackTitle : "",
      inCorrectFeedbackTitle : "",
      hideAssessmentLabels : false,
      elmMenuData: "",
      llmCardType: "",
      llmCard: false,
      llmData: {},
      llmKey: 0
    };
  },
  props: ["elmMenu", "newLo"],
  watch: {
    elmMenu: function () {
      const cardType = this.elmMenu.card_type;
      console.log(cardType);
      console.log("newLO", this.$store.state.newLo)
      if (this.newLo) {
        console.log("newLo");
      }
      if (cardType.includes('llm')) {
        this.llmCard = true;
        this.llmCardType = cardType;
        // this.llmData = "";
        this.llmKey++;
      } else {
        this.llmCard = false;
        // this.llmData = "";
        this.llmCardType = "";
        this.llmKey++;
      }
    },
  },
  async created() {
    this.menus = await this.$parent.elmData;
    this.id = this.$route.params.id;
    this.elmMenuData = this.elmMenu;
    this.getAssessment(this.page);
    // this.getCharacters();
    //this.getImages(this.page);
    //this.getDoc(1);
    this.elmMenuData.card_type === "chat" && this.getAssessmentData(false);
    //utilFunctionService.showLoader();
    setTimeout(() => {
      utilFunctionService.hideLoader();
       this.getImages(this.page);
       this.getDoc(1);
       this.getCharacters();
      if (this.menus && this.menus.active && this.menus.active.length > 0) {
        this.getSelectedGrid(this.menus);
      }
    }, 2000);
  },
  async updated() {
    this.menus = await this.$parent.elmData;
    this.worldId = this.menus.world_id;
    if (
      this.elmMenu.card_type === "submission_email" &&
      this.elmMenu.learning_object_type === "submission"
    ) {
      this.$refs.form.submissionUpdate();
    }
    if (this.elmMenu.card_type === "submission") {
      this.$refs.gradingSubmission.submissionUpdate();
    }
    if (this.elmMenu.card_type === "external_api") {
      this.$refs.form.basicSetupUpdate();
    }
    if (this.elmMenu.card_type === "coding") {
      if (this.$refs.interactionCodingLO)
        this.$refs.interactionCodingLO.basicSetupUpdate();

    }
    if (
      this.elmMenu.card_type === "email" &&
      this.elmMenu.learning_object_type === "interaction"
    ) {
      if (this.$refs.interactionEmailLo)
        this.$refs.interactionEmailLo.basicSetupUpdate();
    }
    if (this.elmMenu.card_type === "dialogic") this.$refs.form.dialogicUpdate();
    this.removestatus = 0;
    if (!this.$route.query.id && this.$parent.count === 1) {
      this.$parent.count = 3;
      this.getCharacters(this.menus.world_id);
      this.clearForm();
      this.removestatus = 1;
      this.selectedMenuItem = null;
    } else {
      if (!this.$route.query.id && this.$parent.count === 0) {
        this.getSelectedGrid(this.menus);
      }
    }
  },
  methods: {
     onFileSelected(event) {
      utilFunctionService.showLoader();
      var totalSize = 0;
      for (var i = 0; i < event.target.files.length; i++) {
        totalSize = totalSize + event.target.files[i].size;
      }
      for (var z = 0; z < this.emailPdfs.length; z++) {
        totalSize = totalSize + this.emailPdfs[z].attributes.resource_size;
      }
      if ((totalSize / (1024*1024)).toFixed(1) > 15) {
          alert("File(s) size should not be greater than 15 MB");
      }  else {
       for (var j = 0; j < event.target.files.length; j++) {
        this.selectedFile = event.target.files[j];
        let accessToken = utilFunctionService.getAuthToken();
        const fb = new FormData();
        if (this.selectedFile) {
          fb.append("global_resource[attachment]", this.selectedFile);
          fb.append("global_resource[resource_type]", "document");
          fb.append("global_resource[tag_list]", "");
          fb.append("global_resource[private]", false);
          axios
            .post(`${this.url}/api/admin/v1/global_resources.json`, fb, {
              headers: {
                Authorization: `${accessToken}`,
                "content-type": "multipart/form-data"
              },
              onUploadProgress: function(progressEvent) {
                this.uploadPercentage =
                  parseInt(
                    Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  ) !== 100
                    ? parseInt(
                        Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        )
                      )
                    : 0;
              }.bind(this)
            })
            .then((res) => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.addPdf
              );

              this.emailPdfs.push(res.data.data);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.errImageUpload
              );
            });
        }
       }
      }
      utilFunctionService.hideLoader();
    },
    setActiveVariation(key){
     this.selectedVariation = key;
     if(key == 1)
        this.transcript = this.emailBody1;
     if(key == 2)
        this.transcript = this.emailBody2;
     if(key == 3)
        this.transcript = this.emailBody3;
    },
    updateMultiRowsEntitiesSetTemp(){
      this.multiRowsEntitiesSetTemp= []
      for (let i=0; i < this.multiRowsEntitiesEvaluation.length; i++)
      {
        if (!this.multiRowsEntitiesEvaluation[i].isRemovedRow){
          for (let j=0; j < this.multiRowsEntitiesEvaluation[i].entityEvaluationRow.length; j++)
          {
            if (!this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].isRemovedEntitySet){
              this.multiRowsEntitiesSetTemp.push(this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue)
            }
          }
        }
      }
      console.log(this.multiRowsEntitiesSetTemp)
    },

    multirowsQuestion(){
      this.multiRowsEntitiesEvaluation =[],
      this.RowNumbersSelected = this.newRowNumbersSelected,
      this.newRowNumbersSelected = 2,
      this.rowSelected= 1,
      this.rowNumberTemp= 1,
      this.evalNumberTemp= 1,
      this.checkRowsSequenceMultiRow= false,
      this.correctCustomAssessmentCode="",
      this.incorrectCustomAssessmentCode="",
      this.deleteEvaluationSchemeMultiRow= []
      for (let counterx = 1; counterx< this.RowNumbersSelected+1; counterx++){
        const rowx ={
          id: counterx,
          entityEvaluationRow:[],
          rowRequired: false,
        }
        this.multiRowsEntitiesEvaluation.push(rowx)
      }
      this.correctRowsPass = this.RowNumbersSelected
    },
    checkCorrectRowsPass(){
      if(this.correctRowsPass > this.RowNumbersSelected){
        this.correctRowsPass = this.RowNumbersSelected
      }
      if(this.correctRowsPass < 1){
        this.correctRowsPass = 1
      }
    },
    refreshQuestionList() {
      api
        .getQuizQuestionList(this.cardAsstEntityId)
        .then(res => {
          this.quizQuestionList = res.data.data;
          this.allQuizQuestionTestList = res.data.data;
          this.totalQuizQues =
            this.allQuizQuestionTestList.length > 5
              ? 5
              : this.allQuizQuestionTestList.length;
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
      !this.asstEntitiesQuizList &&
        api
          .getAsstEntities(this.$route.query.id)
          .then(res => {
            this.quizQuestionList = res.data.data;
            this.quizQuestionList.sort(
              this.sortedOrder
            )
            this.allQuizQuestionTestList = res.data.data;
             this.totalQuizQues =
              this.allQuizQuestionTestList.length > 5
                ? 5
                : this.allQuizQuestionTestList.length;
            this.asstEntitiesQuizList = res.data.data;
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
    },
    saveQuestion() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (
          (this.questionType === "range" && !this.questionLowRange) ||
          (this.questionType === "range" && !this.questionHighRange)
        ) {
          this.showDeleteModal();
          return;
        }
        if (
          this.multiple_choice &&
          this.questionType === "numeric" &&
          this.multiple_choice.length < 1
        ) {
          this.showDeleteModal();
          return;
        }
        if (!success) {
          this.showDeleteModal();
          return;
        } else {
          utilFunctionService.showLoader();
          let fb = this.getQuizFormDataUpdate();
          this.selectedQuizQuestion &&
            api
              .updateQuizQuestion(this.selectedQuizQuestion.id, fb)
              .then(res => {
                if (this.questionType === "long_answer") {
                  this.createEvaluation(res.data.data, true);
                  setTimeout(() => {
                    utilFunctionService.hideLoader();
                    utilFunctionService.showSuccess(
                      commonConfig.appMessages.questionSaved
                    );
                    this.refreshQuestionList();
                  }, 2000);

                }
                 else {
                  utilFunctionService.hideLoader();
                  utilFunctionService.showSuccess(
                    commonConfig.appMessages.questionSaved
                  );
                  this.refreshQuestionList();
                }
                if(this.questionType === "ai_text_rows")
                  this.getQuizQuestion2(res.data.data.id)
              });

        }
      });
    },
    saveFeedback() {
      let fb = new FormData();
      fb.append("quiz_question[points]", this.questionPoints);
      fb.append(
        "quiz_question[quiz_feedback_attributes][right_ans_feedback]",
        this.questionCorrectFeedback
      );
      fb.append(
        "quiz_question[quiz_feedback_attributes][wrong_ans_feedback]",
        this.questionIncorrectFeedback
      );
       fb.append(
        "quiz_question[quiz_feedback_attributes][right_ans_label]",
        this.correctFeedbackTitle
      );
       fb.append(
        "quiz_question[quiz_feedback_attributes][wrong_ans_label]",
        this.inCorrectFeedbackTitle
      );
      utilFunctionService.showLoader();
      this.selectedQuizQuestion &&
        api.updateQuizQuestion(this.selectedQuizQuestion.id, fb).then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(
            commonConfig.appMessages.feedbackSaved
          );
          this.refreshQuestionList();
        });
    },
    getSkillId() {
      if (this.selAssessmentSkillLabel.attributes.name === "Missed")
        return this.selAssessmentSkillLabel.id;
      else return this.selAssessmentSkillLabel.skillId;
    },
    adaptiveContents(data) {
      if (
        this.elmMenu.card_type == "quiz" ||
        this.elmMenu.card_type == "chat"
      ) {
        const fb = new FormData();
        fb.append("content_type", data.tab);
        fb.append(
          "adaptable_type",
          this.elmMenu.card_type == "quiz"
            ? "QuizQuestion"
            : this.selAssessmentSkillLabel.attributes.name === "Missed"
            ? "ChatSkillAssmntMissed"
            : "ChatSkillAssmntItem"
        );

        fb.append(
          "adaptable_id",
          this.elmMenu.card_type == "quiz"
            ? this.selectedQuizQuestion.id
            : this.getSkillId()
        );
        fb.append("adaptive_content[title]", data.name);
        fb.append("adaptive_content[description]", data.desc);
        if (data.tab == "text") fb.append("content[title]", data.text);
        if (data.tab == "file")
          fb.append("content[global_resource_id]", data.doc.id);
        if (data.tab == "video")
          fb.append("content[global_video_id]", data.video.id);
        if (data.tab == "slide")
          fb.append("content[has_caption]", data.imageCaption);
        data.slider.map((x, i) => {
          fb.append(
            "content[slider_images_attributes][" + i + "][caption]",
            x.title
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][global_resource_id]",
            parseInt(x.sliderId)
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][_destroy]",
            data.edit == false ? false : x.destroy == true ? true : false
          );
          if (x.id != undefined) {
            fb.append("content[slider_images_attributes][" + i + "][id]", x.id);
          }
        });
        if (data.edit) {
          api.editQuizAdaptiveContent(fb, data.id).then(() => {
            if (this.elmMenu.card_type == "quiz") {
              this.getEntityList(2, "question-setup", false);
            } else {
              if (this.selAssessmentSkillLabel.attributes.name === "Missed")
                this.getMissedChatSkill(true, false);
              else this.getSkillContent(this.selAssessmentSkillLabel.skillId);
            }
          });
        } else {
          api.saveQuizAdaptiveContent(fb, this.objectableId).then(() => {
            if (this.elmMenu.card_type == "quiz") {
              this.getEntityList(2, "question-setup", false);
            } else {
              if (this.selAssessmentSkillLabel.attributes.name === "Missed")
                this.getMissedChatSkill(true, false);
              else this.getSkillContent(this.selAssessmentSkillLabel.skillId);
            }
          });
        }
      }
    },
    removeAdaptiveData(id) {
      utilFunctionService.showLoader();
      api.deleteAdaptiveContent(id).then(() => {
        utilFunctionService.hideLoader();
        if (this.elmMenu.card_type == "quiz") {
          this.getEntityList(2, "question-setup", false);
        } else {
          if (this.selAssessmentSkillLabel.attributes.name === "Missed")
            this.getMissedChatSkill(true, false);
          else this.getSkillContent(this.selAssessmentSkillLabel.skillId);
        }
      });
    },
    updateSubmit() {},
    onInitCarousel() {},
    openSliderGallery(modal) {
      if (this.$route.query.id) {
        this.$modal.show(modal);
      } else {
        if (this.slider.length) {
          this.imageLists.forEach(element => {
            const index = this.docLists.findIndex(
              elem => elem.id === element.id
            );
            if (index !== -1) {
              this.imageLists[index].attributes.status = true;
            }
          });
        }
        this.$modal.show(modal);
      }
    },
    cancelSliderGallery(modal) {
      this.$modal.hide(modal);
    },
    saveImages() {
      const selectedImages = this.imageLists.filter(
        x => x.attributes.status === true
      );

      if (this.$route.query.id) {
        selectedImages.map(x => {
          const a = this.slider.findIndex(y => y.sliderId === x.id);
          if (a === -1) {
            this.slider.push({
              image: x.attributes.resource_url.large_version,
              title: "",
              sliderId: x.id
            });
          }
        });
      } else {
        this.slider = selectedImages.map(element => {
          return {
            image: element.attributes.resource_url.large_version,
            title: "",
            sliderId: element.id
          };
        });
      }

      if (this.slider.length) {
        this.imagePreview = this.slider[this.index].image;
        this.imageTitle = this.slider[this.index].title;
      }
    },
    nextImagesPage() {
      ++this.page;
      if (this.page <= this.totalDocs) {
        this.getImages(this.page);
      } else {
        return;
      }
    },
    getImages(page) {
      this.page = page;
      api
        .getAllImages(page, this.searchImg, this.sortColumn, this.sortOrder)
        .then(res => {
          this.display = true;
          this.searchStatus = false;
          if (page === 1) this.imageLists = res.data.data;
          else this.imageLists = [...this.imageLists, ...res.data.data];
          this.totalImages = res.data.filtered_count;
          this.totalPages = res.data.total_pages;
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    searchImage() {
      api
        .getAllImages(
          commonConfig.setting.searchPage,
          this.searchImg,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.imageLists = res.data.data;
          this.totalImages = res.data.filtered_count;
          this.totalPages = res.data.total_pages;
          this.searchStatus = true;
        })
        .catch();
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    },
    selectDoc() {
      if (this.docs.length) {
        this.docs.forEach(element => {
          const index = this.docLists.findIndex((elem, key) => {
            this.docLists[key].attributes.status = false;
            return parseInt(elem.id) === parseInt(element.id);
          });
          if (index !== -1) {
            this.docLists[index].attributes.status = true;
          }
        });
      }
      this.$modal.show("uploadDocument");
    },
    nextPage() {
      ++this.page;
      if (this.page <= this.totalDocs) {
        this.getDoc(this.page);
      } else {
        return;
      }
    },
    async onDocSelect(event) {
      this.$modal.hide("uploadDocument");
      utilFunctionService.showLoader();
      for (var i = 0; i < event.target.files.length; i++) {
        this.selectedFile = event.target.files[i];
        const fb = new FormData();
        if (this.selectedFile) {
          let tokenAccess = utilFunctionService.getAuthToken();
          fb.append("global_resource[attachment]", this.selectedFile);
          fb.append("global_resource[private]", false);
          fb.append("global_resource[resource_type]", "document");
          fb.append("global_resource[tag_list]", "");
          axios
            .post(`${this.url}/api/admin/v1/global_resources.json`, fb, {
              headers: {
                Authorization: `${tokenAccess}`,
                "content-type": "multipart/form-data"
              }
            })
            .then(
              async function(res) {
                this.removestatus = 0;
                this.docs = [];
                if (res.data.data) {
                  await this.docs.push(res.data.data);
                }
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.addDoc
                );
                utilFunctionService.hideLoader();
              }.bind(this)
            )
            .catch(() => {
              utilFunctionService.hideLoader();
              if (event.target.files.length - 1 === i) {
                utilFunctionService.showErr(commonConfig.appMessages.errDoc);
                this.getDoc(this.page);
              }
            });
        }
      }
    },
    getDoc(page) {
      this.page = page;
      if (this.page === 1) {
        this.docLists = [];
      }
      api
        .getAllDoc(page, this.searchDoc, this.sortColumn, this.sortOrder)
        .then(res => {
          this.display = true;
          this.docLists = [...this.docLists, ...res.data.data];
          this.totalDocs = res.data.filtered_count;
        })
        .catch();
    },
    saveDoc(docs) {
      this.docs = [];
      this.docs = [docs];
    },
    removeDoc() {
      this.removestatus = 1;
      this.docs.splice(0, 1);
    },
    removePdfs(index) {
      this.emailPdfs.splice(index, 1);
    },
    searchDocument() {
      api
        .getAllDoc(
          commonConfig.setting.searchPage,
          this.searchDoc,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.display = true;
          this.docLists = res.data.data;
          this.totalDocs = res.data.filtered_count;
        })
        .catch();
    },
    onFileSelect(event) {
      this.selectedFile = "";
      this.fileName = "";
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.image = e.target.result;
      };
      this.$modal.show("cropperImages");
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.image = canvas.toDataURL();
      this.$modal.hide("cropperImages");
      let accessToken = utilFunctionService.getAuthToken();
      const fb = new FormData();
      fb.append("global_resource[attachment]", this.image);
      fb.append("global_resource[resource_type]", "image");
      fb.append("global_resource[tag_list]", "");
      fb.append("global_resource[private]", false);
      fb.append("global_resource[attachment_name]", this.fileName);
      axios
        .post(`${this.url}/api/admin/v1/global_resources.json`, fb, {
          headers: {
            Authorization: `${accessToken}`,
            "content-type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage =
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ) !== 100
                ? parseInt(
                    Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  )
                : 0;
          }.bind(this)
        })
        .then(res => {
          utilFunctionService.showSuccess(commonConfig.appMessages.addImage);
          this.slider.push({
            image: res.data.data.attributes.resource_url.large_version,
            title: "",
            sliderId: res.data.data.id
          });
          if (this.slider.length) {
            this.imagePreview = this.slider[this.index].image;
            this.imageTitle = this.slider[this.index].title;
          }
        })
        .catch(() => {
          utilFunctionService.showErr(commonConfig.appMessages.errImageUpload);
        });
    },
    slideImage(i, title, imageUrl) {
      this.imageTitle = title;
      this.imagePreview = imageUrl;
      this.index = i;
    },
    getTitle() {
      this.slider[this.index].title = this.imageTitle;
    },
    removeSliderImage(id, sliderId) {
      if (this.slider.length) {
        const index = this.imageLists.findIndex(
          elem => parseInt(elem.id) === parseInt(sliderId)
        );
        const sliderIndex = this.slider.findIndex(
          elem => parseInt(elem.sliderId) === parseInt(sliderId)
        );
        this.slider.splice(sliderIndex, 1);
        if (index !== -1) {
          this.imageLists[index].attributes.status = false;
        }
      }

      if (id != undefined) {
        api
          .deleteSliderImage(this.$route.params.id, this.$route.query.id, id)
          .then(() => {
            utilFunctionService.showSuccess(
              commonConfig.appMessages.removeSliderImage
            );
          })
          .catch();
      }

      if (this.slider.length == 0) {
        this.imageTitle = "";
        this.imagePreview = "";
      }
    },
    saveSlide(type) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          fb.append("learning_object[status]", type);
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[description]", this.description);
          fb.append("card[title]", this.title);
          fb.append("card[has_caption]", this.imageCaption);
          fb.append("card_type", this.elmMenu.card_type);
          fb.append(
            "learning_object[learning_object_type]",
            this.elmMenu.learning_object_type
          );
          fb.append("learning_object[admin_notes]", this.adminNotes);
          this.slider.map((x, i) => {
            fb.append(
              "card[slider_images_attributes][" + i + "][caption]",
              x.title
            );
            fb.append(
              "card[slider_images_attributes][" + i + "][global_resource_id]",
              parseInt(x.sliderId)
            );
            if (x.id != undefined) {
              fb.append("card[slider_images_attributes][" + i + "][id]", x.id);
            }
          });
          if (this.$route.query.id) {
            utilFunctionService.showLoader();
            api
              .updateLo(this.$route.params.id, this.$route.query.id, fb)
              .then(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showSuccess(
                  type !== "drafted"
                    ? commonConfig.appMessages.LoPublichedText
                    : commonConfig.appMessages.LoDraftText
                );
                this.$parent.card_type = "";
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                // this.$router.push(this.$route.params.id);
                if (type == 'archived') {
                    this.$router.push(this.$route.params.id);
                     window.location.reload()
                } else {
                   window.location.reload()
                }
              })
              .catch();
          } else {
            utilFunctionService.showLoader();
            api
              .createLearningObject(this.id, fb)
              .then((res) => {
                utilFunctionService.hideLoader();
                this.clearForm();
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$parent.card_type = "";
                this.$router.push({id:this.$route.params.id,query: { id: res.data.data.id } });
                window.location.reload();
              })
              .catch();
          }
        }
      });
    },
    updateLoList() {
      this.$parent.getElmDetails();
      this.menus = this.$parent.elmData;
    },
    addVideo(modal) {
      this.$modal.show(modal);
    },
    addVideoFromGallery(modal) {
      this.$modal.show(modal);
    },
    saveLoEmail(type) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const to = this.characters
            .filter(x => x.to == true)
            .map(x => x.id);
          const cc = this.searchCharacters
            .filter(x => x.cc == true)
            .map(x => x.id);

          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("learning_object[status]", type);
          fb.append("learning_object[name]", this.name);
          fb.append("card[title]", this.title);
          fb.append("card_type", this.elmMenu.card_type);
          fb.append("learning_object[description]", this.description);
          fb.append(
            "learning_object[learning_object_type]",
            this.elmMenu.learning_object_type
          );
          fb.append("learning_object[admin_notes]", this.adminNotes);
          fb.append("card[email_body]", this.emailBody1);
          fb.append("card[email_body2]", this.emailBody2);
          fb.append("card[email_body3]", this.emailBody3);
          fb.append("card[enable_reattempt", false);
          this.emailPdfs.map((pdf) => {
            fb.append("card[global_resource_ids][]", parseInt(pdf.id));
          });
          to.map(x => {
            fb.append("card[to_character_ids][]", parseInt(x));
          });

          cc.map(x => {
            fb.append("card[cc_character_ids][]", parseInt(x));
          });

          if (this.$route.query.id) {
            api
              .updateLo(this.$route.params.id, this.$route.query.id, fb)
              .then(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showSuccess(
                  type === "drafted"
                    ? commonConfig.appMessages.LoDraftText
                    : commonConfig.appMessages.LoPublichedText
                );
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$parent.card_type = "";
                this.$parent.count = 0;
                // this.$router.push(this.$route.params.id);
                window.location.reload();
              })
              .catch();
          } else {
            api
              .createLearningObject(this.id, fb)
              .then((res) => {
                utilFunctionService.hideLoader();
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$parent.card_type = "";
                this.$parent.count = 0;
                // this.$router.push(this.$route.params.id);
                this.$router.push({id:this.$route.params.id,query: { id: res.data.data.id } });
                window.location.reload();
              })
              .catch();
          }
        }
      });
    },
    archiveLO() {
      const fb = new FormData();
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("learning_object[status]", "archived");
      fb.append("id", this.$route.query.id);
      api
        .restoreLo(this.$route.params.id, this.$route.query.id, fb)
        .then(() => {
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoArchiveText
          );
          this.$parent.getElmDetails();
          this.menus = this.$parent.elmData;
          this.$parent.card_type = "";
          this.$parent.count = 0;
          this.$router.push(this.$route.params.id);
        })
        .catch();
    },
    submitLo(status, value = "", key = "") {
      utilFunctionService.showLoader();
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        let submit = true;
        if (this.elmMenu.card_type === "video") {
          if (!this.video) {
            submit = false;
          }
        } else if (this.elmMenu.card_type === "file") {
          if (!this.docs || !this.docs[0]) {
            submit = false;
          }
        } else if (this.elmMenu.card_type === "quiz") {
          if (!this.assessmentListKey.id && this.overallQuizAssessment) {
            submit = false;
          }
          if (!this.scoreViewType) {
            submit = false;
          }
        }
        if (!success || submit !== true) {
          utilFunctionService.hideLoader();
          return;
        } else {
          if (this.$route.query.id && this.elmMenu.card_type === "quiz")
            this.emptyTabs(value, key, this.$route.query.id);
          const fb = new FormData();
          if (this.video) fb.append("card[global_video_id]", this.video.id);
          if (this.docs && this.docs[0])
            fb.append("card[global_resource_id]", this.docs[0].id);
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[description]", this.description);
          fb.append("learning_object[admin_notes]", this.adminNotes);
          if (this.elmMenu.card_type !== "quiz") {
            fb.append("card[title]", this.title);
            fb.append("card[description]", this.description);
          }
          fb.append(
            "learning_object[learning_object_type]",
            this.elmMenu.learning_object_type
          );
          fb.append("learn_mod_id", this.$route.params.id);
          fb.append("learning_object[status]", status);
          fb.append("card_type", this.elmMenu.card_type);
          if (this.elmMenu.card_type === "quiz") {
            fb.append("card[title]", this.name);
            fb.append(
              "card[overall_module_assessment_inclusion]",
              this.overallAssessment
            );
            this.overallQuizAssessment &&
              fb.append(
                "learning_object[assessment_scheme_id]",
                this.assessmentListKey.id
              );
               this.overallQuizAssessment &&
              fb.append(
                "learning_object[competency_code]",
                this.competencyCode
              );
            fb.append("card[score_view_type]", this.scoreViewType);
            fb.append(
              "learning_object[overall_assessment_required]",
              this.overallQuizAssessment
            );
             fb.append(
              "card[display_questions_in_order]",
               this.quizQuestionOrder
            );
            fb.append(
              "card[hide_feedback]",
               this.quizHideFeedbak
            );
            fb.append(
              "card[hide_assessment_labels]",
               this.hideAssessmentLabels
            );
            fb.append(
              "card[show_correct_adaptive_feedback]",
               this.showCorrectAdaptiveFeedback
            );
          } else if (this.elmMenu.card_type === "chat") {
            fb.append("learning_object[admin_notes]", this.adminNotes);
            fb.append("card_type", this.elmMenu.card_type);
            fb.append(
              "learning_object[learning_object_type]",
              this.elmMenu.learning_object_type
            );
            fb.append(
              "learning_object[assessment_scheme_id]",
              this.assessmentListKey.id
            );
            fb.append("card[mentor_character_id]", this.chatMentorId);
            fb.append("card[chat_character_id]", this.chatCharacterId);
          }
          if (this.$route.query.id) {
            api
              .updateLo(this.$route.params.id, this.$route.query.id, fb)
              .then(res => {
                utilFunctionService.hideLoader();
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                // this.$router.push(this.$route.params.id);
                if (this.elmMenu.card_type !== "quiz") {
                  utilFunctionService.showSuccess(
                    status === "drafted"
                      ? commonConfig.appMessages.LoDraftText
                      : commonConfig.appMessages.LoPublichedText
                  );
                  this.$parent.card_type = "";
                  this.$parent.count = 0;
                  if (status == "published") {
                    window.location.reload();
                  }
                } else {
                  this.emptyTabs(value, key, res.data.data.id);
                  this.$router.push({ query: { id: res.data.data.id } });
                  this.cardAsstEntityId =
                    res.data.data.attributes.card_detail.id;
                  this.overallAssessmentRange =
                    res.data.data.attributes.card_detail.attributes.score_view_type;
                  this.assessment_scheme_id =
                    res.data.data.attributes.assessment_scheme_id;
                  this.getSelectedAssessment(
                    res.data.data.attributes.assessment_scheme_id
                  );
                  if (status == "published") {
                    window.location.reload();
                  }
                }
                // window.location.reload();
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
              });
          } else {
            api
              .saveLo(this.$route.params.id, fb)
              .then(res => {
                utilFunctionService.hideLoader();
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$router.push(this.$route.params.id);
                if (this.elmMenu.card_type !== "quiz") {
                  utilFunctionService.showSuccess(
                    status === "drafted"
                      ? commonConfig.appMessages.LoDraftText
                      : commonConfig.appMessages.LoPublichedText
                  );
                  this.$parent.card_type = "";
                  this.$parent.count = 0;
                } else {
                  this.getSelectedAssessment(
                    res.data.data.attributes.assessment_scheme_id
                  );
                  this.emptyTabs(value, key, res.data.data.id);
                  this.cardAsstEntityId =
                    res.data.data.attributes.card_detail.id;
                  this.assessment_scheme_id =
                    res.data.data.attributes.assessment_scheme_id;
                  this.overallAssessmentRange =
                    res.data.data.attributes.card_detail.attributes.score_view_type;
                  this.$router.push({ query: { id: res.data.data.id } });
                  if (status == "published") {
                    window.location.reload();
                  }
                }
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$router.reload();
              });
          }
        }
      });
    },
    cloneLo() {
      utilFunctionService.showLoader();
      api
        .cloneObj(this.$route.params.id, this.$route.query.id)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(
            commonConfig.appMessages.loCloned
          );
          this.$parent.getElmDetails();
          this.menus = this.$parent.elmData;
          this.$parent.card_type = "";
          this.$parent.count = 0;
          this.$router.push(this.$route.params.id);
        })
        .catch();
    },
    reorderQuizQuestions({ relatedContext, draggedContext }){
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      const fb = new FormData();
      let draggedCardOrder = draggedElement.attributes.order;
      let relatedCardOrder = relatedElement.attributes.order;
       this.quizQuestionList.map((data, index) => {
        if (parseInt(data.id) === parseInt(relatedElement.id)) {
          fb.append("quiz_learn_obj[quiz_questions_attributes][][id]", data.id);
          fb.append(
            "quiz_learn_obj[quiz_questions_attributes][][order]",
            draggedCardOrder
          );
          data.attributes.order = draggedCardOrder;
        } else if (parseInt(data.id) === parseInt(draggedElement.id)) {
          fb.append("quiz_learn_obj[quiz_questions_attributes][][id]", data.id);
          fb.append(
            "quiz_learn_obj[quiz_questions_attributes][][order]",
            relatedCardOrder
          );
          data.attributes.order = relatedCardOrder;
        } else {
          fb.append("quiz_learn_obj[quiz_questions_attributes][][id]", data.id);
          fb.append(
            "quiz_learn_obj[quiz_questions_attributes][][order]",
            index + 1
          );
          data.attributes.order = index + 1;
        }
        return data;
      });
      api
        .reorderQuizQuestions(this.cardAsstEntityId, fb)
        .then(() => {})
        .catch();
    },
      sortedOrder(a, b) {
      if (a.attributes.order < b.attributes.order) {
        return -1;
      }
      if (a.attributes.order > b.attributes.order) {
        return 1;
      }
      return 0;
    },
    onEnd() {
      let menuListData = [...this.menus.active];
      const fd = new FormData();
      menuListData.map((data, index) => {
        fd.append("learn_mod[learning_objects_attributes][][id]", data.id);
        fd.append(
          "learn_mod[learning_objects_attributes][][card_order]",
          index + 1
        );
      });
      api
        .reorderCards(this.$route.params.id, fd)
        .then(() => {})
        .catch();
    },
    getLoDetails(loId) {
      this.loid = loId;
      this.$parent.getLoDetails(loId);
    },
    getActive(loid, key) {
                    console.log(loid,key, "loid key");
      api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then(res => {
              console.log(res.data.data, "res");
      this.menus.active[key] = res.data.data;
      console.log(loid, "*** hit getActive, loid ***");
      console.log(key, "*** hit getActive, key ***");
      this.chatCharacterId = "";
      this.chatMentorId = "";
      this.searchCharacter = "";
      this.searchOptionalCharacter = "";
      if (this.elmMenu.newLo) {
        this.selectedMenuItem = this.menus.active[key];
        this.$parent.setMenu(this.menus.active[key]);
        this.elmMenuData.card_type = this.$parent.card_type
          ? this.$parent.card_type
          : "";
        this.learning_object_type = this.$parent.learning_object_type
          ? this.$parent.learning_object_type
          : "";
        this.emailInteractiveData = this.menus.active[key];
        this.llmCardType = this.menus.active[key].attributes.learning_object_type;
        console.log(this.llmCardType, "this.llmCardType");
        if (this.llmCardType) {
          console.log("setting llmData")
          console.log(this.emailInteractiveData);
          this.$store.dispatch("updateLlmData", this.emailInteractiveData);
          // if (this.menus.active[key]) {
          //   this.llmData = this.emailInteractiveData;
          // }
          this.llmKey++;
          this.llmCard = true;
        } else {
          this.llmCard = false;
        }
      }
      if (loid === this.$route.query.id) {
        this.selectedMenuItem = this.menus.active[key];
        this.$parent.setMenu(this.menus.active[key]);
        // let prc = key * 6.8;
        // this.$refs["vs"].scrollTo({ y: prc + "%" }, 500);
        this.elmMenuData.card_type = this.$parent.card_type
          ? this.$parent.card_type
          : "";
        this.learning_object_type = this.$parent.learning_object_type
          ? this.$parent.learning_object_type
          : "";
        this.emailInteractiveData = this.menus.active[key];
        this.llmCardType = this.menus.active[key].attributes.learning_object_type;
        console.log(this.llmCardType, "this.llmCardType");
        if (this.llmCardType) {
          console.log("setting llmData")
          this.$store.dispatch("updateLlmData", this.emailInteractiveData);
          // this.llmData = this.emailInteractiveData;
          this.llmKey++;
          this.llmCard = true;
        } else {
          this.llmCard = false;
        }
        this.externalApiInteractiveData = this.menus.active[key];
        this.name = this.menus.active[key].attributes.name;
        this.description = this.menus.active[key].attributes.description;
        this.contentDescription = this.menus.active[key].attributes.description;
        this.textBody = this.menus.active[key]?.attributes?.card_detail?.attributes?.description;
        this.worldId = this.menus.world_id;
        console.log(this.worldId, "*** this.worldId in LoMenu.vue getActive");
        this.selectedVariation = 1;
        // Wut? This line below - the transcript thing - that can't be correct, can it?
        this.transcript = this.menus.active[key]?.attributes?.card_detail?.attributes?.email_body;
        this.emailBody1 = this.menus.active[key]?.attributes?.card_detail?.attributes?.email_body;
        this.emailBody2 = this.menus.active[key]?.attributes?.card_detail?.attributes?.email_body2;
        this.emailBody3 = this.menus.active[key]?.attributes?.card_detail?.attributes?.email_body3;
        this.title = this.menus.active[key]?.attributes?.card_detail?.attributes?.title;
        if (this.menus.active[key]?.attributes?.card_detail?.attributes && this.menus.active[key]?.attributes?.card_detail?.attributes?.global_resources) {
          this.menus.active[key]?.attributes?.card_detail?.attributes?.global_resources.map(pdf => this.emailPdfs.push(pdf));
        }
        if (
          this.menus.active[key].attributes.card_detail.attributes
            .chat_character_id != null ||
          this.menus.active[key].attributes.card_detail.attributes
            .mentor_character_id != null
        ) {
          this.chatCharacterId = this.menus.active[
            key
          ].attributes.card_detail.attributes.chat_character_id;
          const chatCharacter = this.searchCharacters.filter(
            x => x.id == this.chatCharacterId
          );
          if (chatCharacter && chatCharacter.length) {
            this.searchCharacter = chatCharacter[0].char_full_name;
          }
          this.chatMentorId = this.menus.active[
            key
          ].attributes.card_detail.attributes.mentor_character_id;
          const mentorId = this.searchCharacters.filter(
            x => x.id == this.chatMentorId
          );
          if (mentorId && mentorId.length) {
            this.searchOptionalCharacter = mentorId[0].char_full_name;
          }
        }

        this.adminNotes = this.menus.active[key].attributes.admin_notes;
        this.charStatus = false;
        this.optionalCharStatus = false;
        this.learning_object_type = this.$parent.learning_object_type
          ? this.$parent.learning_object_type
          : "";
        this.elmMenuData.video = "";
        this.elmMenuData.video = this.$parent.video
          ? this.$parent.video
          : this.menus.active[key].attributes.card_detail.attributes
              .global_video;
        this.video = this.$parent.video
          ? this.$parent.video
          : this.elmMenuData.video;
        this.status = this.menus.active[key].attributes.status;
        this.imageCaption = this.menus.active[
          key
        ].attributes.card_detail.attributes.has_caption;
        this.overallAssessmentRange = this.menus.active[
          key
        ].attributes.card_detail.attributes.score_view_type;

        this.overallAssessment = this.menus.active[
          key
        ].attributes.card_detail.attributes.overall_module_assessment_inclusion;
        this.overallQuizAssessment = this.menus.active[
          key
        ].attributes.overall_assessment_required;
        this.hideAssessmentLabels = this.menus.active[
          key
        ].attributes.card_detail.attributes.hide_assessment_labels;
        this.showCorrectAdaptiveFeedback = this.menus.active[
          key
        ].attributes.card_detail.attributes.show_correct_adaptive_feedback;
        this.scoreViewType = this.menus.active[
          key
        ].attributes.card_detail.attributes.score_view_type;
         this.competencyCode = this.menus.active[
          key
        ].attributes.competency_code || "";
        this.cardAsstEntityId = this.menus.active[
          key
        ].attributes.card_detail.id;
        this.getSelectedAssessment(
          this.menus.active[key].attributes.assessment_scheme_id
        );
        this.assessment_scheme_id = this.menus.active[
          key
        ].attributes.assessment_scheme_id;
         this.quizQuestionOrder = this.menus.active[
          key
        ].attributes.card_detail.attributes.display_questions_in_order;
        this.cardAsstSchemeId = this.menus.active[
          key
        ].attributes.card_detail.id;
        this.quizContentId = "basic-information";
        this.chatContentId = "basic-information";
        this.isActiveTab = ["active"];
        if (this.menus.active[key].attributes.card_type === "quiz")
          this.getActiveBarStatus(this.menus.active[key]);
        this.handleQuestionValueChange({}, true);
        if (
          this.menus.active[key].attributes.card_detail.attributes
            .asst_assistant_shell
        ) {
          this.assistantKey = this.menus.active[
            key
          ].attributes.card_detail.attributes.asst_assistant_shell.assistant_id;
          this.apiKey = this.menus.active[
            key
          ].attributes.card_detail.attributes.asst_assistant_shell.api_key;
          this.serviceUrl = this.menus.active[
            key
          ].attributes.card_detail.attributes.asst_assistant_shell.url;
          this.assistant_dialog_skill_id = this.menus.active[
            key
          ].attributes.card_detail.attributes.asst_assistant_shell.assistant_dialog_skill_id;
          if (
            this.menus.active[key].attributes.card_detail.attributes
              .asst_assistant_shell.id
          )
            this.assistant_id = this.menus.active[
              key
            ].attributes.card_detail.attributes.asst_assistant_shell.id;

          if (
            this.menus.active[key].attributes.card_detail.attributes
              .last_skills_import_date
          ) {
            this.last_skills_import_date = moment(
              this.menus.active[key].attributes.card_detail.attributes
                .last_skills_import_date
            ).format("MMMM Do YYYY");
          } else this.last_skills_import_date = "";
        }
        if (this.menus.active[key].attributes.card_type === "chat")
          this.setChatStatusBar();
        if (this.$parent.card_type === "file") {
          this.getDoc(1);
          let docDetails = {
            id: this.menus.active[key].attributes.card_detail.attributes
              .global_resource_id,
            attributes: {
              resource_url: this.menus.active[key].attributes.card_detail
                .attributes.resource_url,
              file_name: this.menus.active[key].attributes.card_detail
                .attributes.file_name,
              is_csv: this.menus.active[key].attributes.card_detail
                .attributes.is_csv,
              is_pdf: this.menus.active[key].attributes.card_detail
                .attributes.is_pdf,
            }
          };
          this.docs = [];
          if (this.removestatus === 0) {
            this.docs.push(docDetails);
          }
        }

        if (this.menus.active[key].attributes.status) {
          if (this.menus.worldId) this.getCharacters(this.menus.worldId);
        }
        if (this.menus.active[key].attributes.card_type === "slide") {
          this.imageSlider(this.menus, key);
        }
        this.elmMenu.card_type === "chat" && this.getAssessmentData();
        this.cc_characters = [];
        this.to_characters = [];
        if (this.menus.active[key].attributes.card_type == "email") {
          // Clear cc and from options for every character before setting them ...
          this.searchCharacters.forEach(char => {
            char.cc = false;
            char.to = false;
          });
          if (
            (this.menus.active[key].attributes.card_detail &&
              this.menus.active[key].attributes.card_detail.attributes
                .to_characters) ||
            (this.menus.active[key].attributes.card_detail &&
              this.menus.active[key].attributes.card_detail.attributes
                .cc_characters)
          ) {
            this.menus.active[
              key
            ].attributes.card_detail.attributes.cc_characters.forEach(
              element => {
                const index = this.searchCharacters.findIndex(elem => {
                  return parseInt(elem.id) == parseInt(element.id);
                });
                if (index !== -1) {
                  this.cc_characters.push(this.searchCharacters[index]);
                  this.searchCharacters[index].cc = true;
                }
              }
            );
            this.menus.active[
              key
            ].attributes.card_detail.attributes.to_characters.forEach(
              element => {
                const index = this.searchCharacters.findIndex(elem => {
                  return parseInt(elem.id) == parseInt(element.id);
                });
                if (index !== -1) {
                  this.to_characters.push(this.searchCharacters[index]);
                  this.searchCharacters[index].to = true;
                }
              }
            );
          }
        }
        return true;
      }
     })
    },
    getSelectedGrid(menus) {
      menus = menus.active;
      console.log(menus);
      if (menus) {
        if (!this.$route.query.id) {
          this.$route.query.id = menus[0].id;
          console.log(this.$route.query.id, "this.$route.query.id")
          let x = 0;
          this.getActive(this.$route.query.id, x);
        } else {
          let index = menus.findIndex((data, key) => {
            if (this.$route.query.id === data.id) {
              return key;
            }
          });
          console.log(index, "index");
          let activeKey = index === -1 ? 0 : index;
          this.getActive(this.$route.query.id, activeKey);
        }
      }
    },
    clearForm() {
      this.name = "";
      this.description = "";
      this.title = "";
      this.transcript = "";
      this.contentDescription = "";
      this.textBody = "",
      this.isActive = false;
      this.slider = [];
      this.imageTitle = "";
      this.video = "";
      this.imageCaption = false;
      this.imagePreview = "";
      this.slider = [];
      this.docs = [];
      this.searchDoc = "";
      this.docLists.map((elem, key) => {
        this.docLists[key].attributes.status = false;
      });
      this.imageLists.map((elem, key) => {
        this.imageLists[key].attributes.status = false;
      });
      this.overallAssessment = false;
      this.overallQuizAssessment = false;
      this.scoreViewType = null;
      this.quizQuestionOrder = false;
      this.assessmentListKey = { name: "None", id: null };
      this.quizEntityName = "";
      this.quizContentId = "basic-information";
      this.isActiveTab = ["active"];
      this.adminNotes = "";
      this.chatCharacterId = "";
      this.chatMentorId = "";
      this.searchCharacter = "";
      this.searchOptionalCharacter = "";
      this.selectedSkillItem = {
        value_count_max: "",
        value_count_min: "",
        points: "",
        debriefContent: "",
        debrief_contents: ""
      };
      this.last_skills_import_date = "";
      this.assessmentSkillLabelList = [];
      this.status = "drafted";
      this.inCorrectFeedbackTitle  = '';
      this.correctFeedbackTitle  = '';
    },
    imageSlider(menus, key) {
      if (
        menus.active[key].attributes.card_detail.attributes &&
        menus.active[key].attributes.card_detail.attributes.slider_images &&
        menus.active[key].attributes.card_detail.attributes.slider_images
          .length > 0
      ) {
        let sliderdata = menus.active[
          key
        ].attributes.card_detail.attributes.slider_images.map(res => {
          return {
            image: res.attributes.resource_url.large_version,
            title: res.attributes.caption,
            id: res.id,
            sliderId: res.attributes.global_resource_id
          };
        });
        this.slider = sliderdata;
        if (this.slider.length) {
          this.imageTitle = this.slider[this.index].title;
          this.imagePreview = this.slider[this.index].image;
        }
      } else {
        this.slider = [];
      }
    },
    saveContentText(type) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("learning_object[status]", type);
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[description]", this.contentDescription);
          fb.append("card[description]", this.textBody);
          fb.append("card_type", this.elmMenu.card_type);
          fb.append(
            "learning_object[learning_object_type]",
            this.elmMenu.learning_object_type
          );
          fb.append("learning_object[admin_notes]", this.adminNotes);
          if (this.$route.query.id) {
            api
              .updateLo(this.$route.params.id, this.$route.query.id, fb)
              .then(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showSuccess(
                  type === "drafted"
                    ? commonConfig.appMessages.LoDraftText
                    : commonConfig.appMessages.LoPublichedText
                );
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$parent.card_type = "";
                // this.$router.push(this.$route.params.id);
                window.location.reload();
              })
              .catch();
          } else {
            api
              .createLearningObject(this.id, fb)
              .then((res) => {
                utilFunctionService.hideLoader();
                utilFunctionService.showSuccess(
                  type === "drafted"
                    ? commonConfig.appMessages.LoDraftText
                    : commonConfig.appMessages.LoPublichedText
                );
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$parent.card_type = "";
                // this.$router.push(this.$route.params.id);
                this.$router.push({id:this.$route.params.id,query: { id: res.data.data.id } });
                window.location.reload();
              })
              .catch();
          }
        }
      });
    },
    saveChatlo(type, value, id) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("learning_object[status]", type);
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[admin_notes]", this.adminNotes);
          fb.append("card_type", this.elmMenu.card_type);
          fb.append(
            "learning_object[learning_object_type]",
            this.elmMenu.learning_object_type
          );
          fb.append(
            "learning_object[assessment_scheme_id]",
            this.assessmentListKey.id
          );
          fb.append("card[mentor_character_id]", this.chatMentorId);
          fb.append("card[chat_character_id]", this.chatCharacterId);
          if (this.$route.query.id) {
            api
              .updateLo(this.$route.params.id, this.$route.query.id, fb)
              .then(res => {
                utilFunctionService.hideLoader();
                utilFunctionService.showSuccess(
                  type === "drafted"
                    ? commonConfig.appMessages.LoDraftText
                    : commonConfig.appMessages.LoPublichedText
                );
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$router.push(this.$route.params.id);
                if (this.elmMenu.card_type !== "chat") {
                  this.$parent.card_type = "";
                  this.$parent.count = 0;
                } else {
                  this.chatMenu(value, id);
                  this.$router.push({ query: { id: res.data.data.id } });
                }
              })
              .catch();
          } else {
            api
              .createLearningObject(this.id, fb)
              .then(res => {
                utilFunctionService.hideLoader();
                utilFunctionService.showSuccess(
                  type === "drafted"
                    ? commonConfig.appMessages.LoDraftText
                    : commonConfig.appMessages.LoPublichedText
                );
                this.$parent.getElmDetails();
                this.menus = this.$parent.elmData;
                this.$router.push(this.$route.params.id);
                if (this.elmMenu.card_type !== "chat") {
                  this.$parent.card_type = "";
                  this.$parent.count = 0;
                } else {
                  this.chatMenu(value, id);
                  this.$router.push({ query: { id: res.data.data.id } });
                }
              })
              .catch();
          }
        }
      });
    },
    getCharacters() {
      if (this.menus.world_id == undefined) {
          api.getElmDetails(this.id).then(res => {
            this.menus.world_id = res.data.world_id;
            this.elmCharacters();
           });
         } else {
           this.elmCharacters();
         }
    },
    elmCharacters() {
        api.getWorldCharacters(this.menus.world_id).then(res => {
        this.searchCharacters = [];
        this.characters = [];
        this.characters = res.data.data.map(x => ({
          to: false,
          cc: false,
          id: x.id,
          world_role: x.attributes.world_role,
          org_name: x.attributes.org_name,
          // char_photo_url: x.attributes.char_photo_url.thumbnail,
          char_full_name: x.attributes.char_full_name,
          char_gender: x.attributes.char_gender,
          char_age: x.attributes.char_age
        }));
        this.searchCharacters = this.characters;
        this.charactersList = this.characters;
      })
      .catch();
    },
    filterCharacter() {
      this.charStatus = true;
      if (this.searchCharacter.length !== 0) {
        this.searchCharacters = this.characters.filter(char => {
          return (
            char.char_full_name
              .toLowerCase()
              .includes(this.searchCharacter.toLowerCase()) ||
            char.world_role
              .toLowerCase()
              .includes(this.searchCharacter.toLowerCase()) ||
            char.org_name
              .toLowerCase()
              .includes(this.searchCharacter.toLowerCase())
          );
        });
      } else {
        return (this.searchCharacters = this.characters);
      }
    },
    filterOptionalCharacter() {
      this.optionalCharStatus = true;
      if (this.searchOptionalCharacter.length !== 0) {
        this.searchOptionalCharacters = this.charactersList.filter(char => {
          return (
            char.char_full_name
              .toLowerCase()
              .includes(this.searchOptionalCharacter.toLowerCase()) ||
            char.world_role
              .toLowerCase()
              .includes(this.searchOptionalCharacter.toLowerCase()) ||
            char.org_name
              .toLowerCase()
              .includes(this.searchOptionalCharacter.toLowerCase())
          );
        });
      } else {
        return (this.searchOptionalCharacters = this.charactersList);
      }
    },
    selectCharacter(character, id, type) {
      const i = this.searchCharacters.findIndex(elem => elem.id == id);
      if (type == "to") {
        this.searchCharacters.map(x => (x.to = false));
        this.searchCharacters[i].to = true;
      } else {
        this.searchCharacters[i].cc = !this.searchCharacters[i].cc;
      }
    },
    getElmVideo(video) {
      this.video = video;
    },
    selectedVideo(e) {
      this.video = e;
    },
    removeVideo() {
      this.videoLists.map((x, i) => {
        this.videoLists[i].attributes.status = false;
      });
    },
    handleQuizContent(e, value, key) {
      e.preventDefault();
      if (key === 1) this.submitLo("drafted", value, key);
      else if (key === 3) {
        this.overallAssmntItem = [];
        this.emptyTabs(value, key, this.$route.query.id);
      } else this.emptyTabs(value, key);
      this.ariaSelected = false;
    },
    emptyTabs(value, key, elmId) {
      this.getEntityList(key, elmId);
      if (
        this.asstEntitiesList &&
        this.asstEntitiesList.length === 0 &&
        key === 2
      ) {
        this.quizContentId = "entity-setup";
        if (this.isActiveTab.length - 1 < key) {
          this.isActiveTab = [];
          for (let i = 0; i <= key - 1; i++) this.isActiveTab.push("active");
        }
        utilFunctionService.showErr(commonConfig.appMessages.updateTabError);
      } else if (
        !this.isQuestionCreated &&
        this.quizQuestionList &&
        this.quizQuestionList.length === 0 &&
        key === 3
      ) {
        this.quizContentId = "question-setup";
        if (this.isActiveTab.length - 1 < key) {
          this.isActiveTab = [];
          for (let i = 0; i <= key - 1; i++) this.isActiveTab.push("active");
        }
        utilFunctionService.showErr(commonConfig.appMessages.updateTabError);
      } else {
        this.quizContentId = value;
        if (this.isActiveTab.length - 1 < key) {
          this.isActiveTab = [];
          for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
        }
        this.isQuestionCreated = false;
        this.clickCount = 0;
      }
    },
    getEntityList(
      key,
      elmId,
      status = true,
      entityId = null,
      isUpdated = true
    ) {
      elmId &&
        key === 1 &&
        api
          .getAsstEntities(elmId)
          .then(res => {
            this.asstEntitiesList = res.data.data;
            if (status) this.selectedAsstEntities = this.asstEntitiesList[0];
            if (entityId) {
              this.asstEntitiesList.forEach(ele => {
                if (ele.id === entityId) this.selectedAsstEntities = ele;
              });
            }
            if (this.asstEntitiesList[0].attributes.asst_entity_values)
              this.selectedSynonymList = this.asstEntitiesList[0].attributes.asst_entity_values[0];
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      if (key === 2) {
        api
          .getQuizQuestionList(this.cardAsstEntityId)
          .then(res => {
            this.quizQuestionList = res.data.data;
            this.quizQuestionList.sort(
                          this.sortedOrder
                        )
            this.allQuizQuestionTestList = res.data.data;
            this.totalQuizQues =
              this.allQuizQuestionTestList.length > 5
                ? 5
                : this.allQuizQuestionTestList.length;
            status && this.handleQuestionValueChange(this.quizQuestionList[0]);

            if (this.selectedQuizQuestion) {
              let data = this.quizQuestionList.filter(
                x => x.id == this.selectedQuizQuestion.id
              );
              this.interstitialLists = data[0].attributes.adaptive_contents;
            }
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
        !this.asstEntitiesQuizList &&
          api
            .getAsstEntities(this.$route.query.id)
            .then(res => {
              this.asstEntitiesQuizList = res.data.data;
            })
            .catch(() => {
              utilFunctionService.hideLoader();
            });
      }
      if (key === 3) {
        api
          .getOverallAssmntItem(this.$route.query.id)
          .then(res3 => {
            this.overallAssmntItem = res3.data.data.sort(this.compareObjects);
            if (this.overallAssmntItem.length === 0) {
              this.assessment_scheme_id &&
                api
                  .getAssessmentScheme(this.assessment_scheme_id)
                  .then(res => {
                    this.createOverAllAssessment(
                      res.data.data.attributes.assessment_labels
                    );
                    api
                      .getOverallAssmntItem(this.$route.query.id)
                      .then(res2 => {
                        this.overallAssmntItem = res2.data.data.sort(
                          this.compareObjects
                        );
                        this.selectedOverallAssmntItem = this.overallAssmntItem[0];
                        this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
                        this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
                        this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
                      })
                      .catch(() => {
                        utilFunctionService.hideLoader();
                      });
                  })
                  .catch(() => {
                    utilFunctionService.hideLoader();
                  });
            } else {
              if (isUpdated) {
                this.selectedOverallAssmntItem = this.overallAssmntItem[0];
                this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
                this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
                this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
              }
            }
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      }
    },
    getAssessment(page) {
      api
        .getAllAssessment(page, "", "", "")
        .then(res => {
          this.assessmentLists = res.data.data;
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    selectedAssessment(event) {
      let value = event.target.value.split("*");
      this.assessmentListKey = { name: value[1], id: value[0] };
    },
    saveQuizLo() {
      const fb = new FormData();
      api
        .saveLo(this.$route.params.id, fb)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(
            status === "drafted"
              ? commonConfig.appMessages.LoDraftText
              : commonConfig.appMessages.LoPublichedText
          );
          this.$parent.getElmDetails();
          this.menus = this.$parent.elmData;
          this.$parent.card_type = "";
          this.$parent.count = 0;
          this.$router.push(this.$route.params.id);
        })
        .catch(() => {
          utilFunctionService.hideLoader();
          this.$parent.getElmDetails();
          this.menus = this.$parent.elmData;
          this.$router.reload();
          this.clearForm();
        });
    },
    addQuizEntity(e) {
      e.preventDefault();
      if (!this.quizEntityName) {
        utilFunctionService.showErr(commonConfig.appMessages.assestEntityErr);
      } else {
        const fb = new FormData();
        fb.append("asst_entity[name]", this.quizEntityName);
        api
          .addQuizEntity(this.$route.query.id, fb)
          .then(res => {
            this.selectedAsstEntities = res.data.data;
            this.quizEntityName = "";
            this.getEntityList(1, this.$route.query.id, false);
          })
          .catch(() => {
            utilFunctionService.showErr(
              commonConfig.appMessages.entityNameValueError
            );
          });
      }
    },
    handleQuizQuestionSelect(data, status = false) {
      if (status) {
        api
          .removeQuizQuestion(data.id)
          .then(() => {
            this.getEntityList(2);
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      } else {
        this.isQuestionCreated = false;
        this.clickCount = 0;
        this.handleQuestionValueChange(data, false);
        if (data.attributes.question_type === "long_answer") {
          this.getQuizQuestionEntityEvalList(data.id);
        }
        if (data.attributes.question_type === "ai_text_rows") {
          this.getQuizQuestionEntityEvalList(data.id);
        }
      }
    },
    handleQuestionValueChange(data, isClear = false) {
      if (!isClear) {
        this.selectedQuizQuestion = data;
        let {
          numeric_answer,
          points,
          quiz_feedback,
          question,
          question_type,
          range_answer,
          adaptive_contents,
          mcq_options
        } = data.attributes;
        this.questionValue = question;
        this.questionType = question_type;
        this.interstitialLists = adaptive_contents;
        if (question_type === "numeric")
          this.numericQuestionAnswer = numeric_answer.attributes.answer;
        if (question_type === "range") {
          this.questionHighRange =
            range_answer && range_answer.attributes.high_range;
          this.questionLowRange =
            range_answer && range_answer.attributes.low_range;
        }
        if (question_type === "multiple_choice") {
          this.multipleChoiceAnswer = mcq_options.map((e, i) => {
            if (e.attributes.is_correct) this.selectedMultipleChoiceAnswer = i;
            return {
              id: i,
              option: e.attributes.option,
              is_correct: e.attributes.is_correct,
              isActive: false,
              optionId: e.id
            };
          });
        }
        if (question_type === "long_answer")
          this.getQuizQuestionEntityEvalList(data.id);
        if (question_type === "ai_text_rows")
          this.getQuizQuestion2(data.id);
        this.questionPoints = points;
        this.questionIncorrectFeedback =
          quiz_feedback && quiz_feedback.attributes.wrong_ans_feedback;
        this.questionCorrectFeedback =
          quiz_feedback && quiz_feedback.attributes.right_ans_feedback;
        this.correctFeedbackTitle =
          quiz_feedback && quiz_feedback.attributes.right_ans_label;
        this.inCorrectFeedbackTitle =
          quiz_feedback && quiz_feedback.attributes.wrong_ans_label;
      } else {
        this.numericQuestionAnswer = "";
        this.questionPoints = 1;
        this.questionIncorrectFeedback = "";
        this.questionCorrectFeedback = "";
        this.questionValue = "";
        this.questionHighRange = "";
        this.questionLowRange = "";
        this.multipleChoiceAnswer = [
          {
            id: 0,
            option: "",
            is_correct: false,
            isActive: true
          }
        ];
        this.counter = 0;
        this.selectedMultipleChoiceAnswer = "";
        this.correctCustomAssessmentCode = "";
        this.incorrectCustomAssessmentCode = "";
        this.rowNumberTemp= 1;
        this.evalNumberTemp= 1;
        this.checkRowsSequenceMultiRow= false;
        this.correctFeedbackTitle = "";
        this.inCorrectFeedbackTitle = "";
      }
    },
    handleAsstEntitiesList(data, status = false) {
      if (status) {
        api
          .removeAssesstEntity(data.id)
          .then(() => {
            this.getEntityList(1, this.$route.query.id);
          })
          .catch(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showErr(commonConfig.appMessages.entityInUseErr);
          });
      } else {
        this.selectedAsstEntities = data;
        this.asstSynonyms = "";
        this.asstName = "";
        this.isActive = false;
      }
    },
    addAssessmentValues(e) {
      e.preventDefault();
      utilFunctionService.showLoader();
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.isActive = true;
          return;
        } else {
          let data = {
            asst_entity_val_synonyms_attributes: [],
            value: ""
          };
          data.value = this.asstName;
          let value = this.asstSynonyms.split(",");
          value.forEach((ele, i) => {
            if (ele)
              data.asst_entity_val_synonyms_attributes[i] = { synonym: ele };
          });
          api
            .addAssessmentValues(this.selectedAsstEntities.id, data)
            .then(() => {
              this.asstName = "";
              this.asstSynonyms = "";
              this.isActive = false;
              utilFunctionService.showSuccess(
                commonConfig.appMessages.assestEntityAdd
              );
              utilFunctionService.hideLoader();
              this.getEntityList(
                1,
                this.$route.query.id,
                false,
                this.selectedAsstEntities.id
              );
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.sameSynonymList
              );
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    handleImportCSV(e, status) {
      utilFunctionService.showLoader();
      if (status === "sync_assistant")
        api
          .syncAssistantEntities(this.$route.query.id)
          .then(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.syncAssistantSuccess
            );
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      else if (status === "dialog_skill")
        api
          .linkToDialogSkill(this.$route.query.id)
          .then(res => {
            utilFunctionService.hideLoader();
            window.open(res.data.url, "_blank");
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      else {
        const fb = new FormData();
        fb.append("file", e.target.files[0]);
        api
          .importCsv(this.$route.query.id, fb)
          .then(() => {
            utilFunctionService.showSuccess(commonConfig.appMessages.uploadCsv);
            utilFunctionService.hideLoader();
            this.getEntityList(1, this.$route.query.id);
          })
          .catch(() => {
            utilFunctionService.showErr(commonConfig.appMessages.invalidCsv);
            utilFunctionService.hideLoader();
          });
      }
    },
    getAssessmentValue(data) {
      if (data.length > 0) {
        let addedList = "";
        for (let i = 0; i < data.length; i++) {
          if (i < data.length - 1) {
            addedList += data[i].attributes.synonym + ", ";
          } else {
            addedList += data[i].attributes.synonym;
          }
        }
        return addedList;
      } else return "";
    },
    getAssessmentValuePattern(data) {
      if (data.length > 0) {
        let addedList = "";
        for (let i = 0; i < data.length; i++) {
          if (i < data.length - 1) {
            addedList += data[i].attributes.pattern + ", ";
          } else {
            addedList += data[i].attributes.pattern;
          }
        }
        return addedList;
      } else return "";
    },
    handleSynonymModal(data) {
      this.selectedSynonymList = JSON.parse(JSON.stringify(data));
      this.entitySynonymName = "";
      this.deletedSynonymsList = [];
      this.$modal.show("enityValueModal");
    },
    updateAssessmentValues(status, index, synData) {
      if (status === "delete") {
        this.selectedSynonymList.attributes.asst_entity_val_synonyms.splice(
          index,
          1
        );
        this.deletedSynonymsList.push(synData.id);
        this.deleteStatus = true;
      }

      if (status === "add") {
        const index = this.selectedSynonymList.attributes.asst_entity_val_synonyms.findIndex(
          elem => elem.attributes.synonym.toUpperCase() === this.entitySynonymName.toUpperCase()
        );
        if (index === -1) {
          this.selectedSynonymList.attributes.asst_entity_val_synonyms.push({
            attributes: {
              synonym: this.entitySynonymName,
              asst_entity_value_id: null
            }
          });
          this.entitySynonymName = "";
        } else {
          utilFunctionService.showErr(
            commonConfig.appMessages.sameSynonymNameError
          );
        }
      }

      if (status === "done") {
        let data = {
          asst_entity_value: {
            asst_entity_val_synonyms_attributes: [],
            value: this.selectedSynonymList.attributes.value
          }
        };
        this.selectedSynonymList.attributes.asst_entity_val_synonyms.forEach(
          ele => {
            if (!ele.attributes.asst_entity_value_id)
              data.asst_entity_value.asst_entity_val_synonyms_attributes.push({
                synonym: ele.attributes.synonym
              });
          }
        );
        if (this.deletedSynonymsList.length > 0) {
          this.deletedSynonymsList.forEach(e => {
            data.asst_entity_value.asst_entity_val_synonyms_attributes.push({
              id: e,
              _destroy: true
            });
          });
        }
        this.deleteStatus = false;
        api
          .updateAssessmentValues(this.selectedSynonymList.id, data)
          .then(() => {
            this.getEntityList(
              1,
              this.$route.query.id,
              false,
              this.selectedAsstEntities.id
            );
            this.deletedSynonymsList = [];
            utilFunctionService.showSuccess(
              commonConfig.appMessages.assestEntityUpdated
            );
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data && error.response.data.error) {
                utilFunctionService.showErr(error.response.data.error);
              }
            }
            utilFunctionService.hideLoader();
          });
        this.$modal.hide("enityValueModal");
      }
    },
    addQuestionType(status) {
      this.questionType = status;
      this.isQuestionCreated = true;
      this.$modal.hide("addQuestionType");
      this.selectedEvaulationScheme = [];
      this.interstitialLists = [];
      this.correctCustomAssessmentCode="",
      this.incorrectCustomAssessmentCode="",
      this.handleQuestionValueChange({}, true);
    },
    addQuestionrows(status) {
      this.questionType = status;
      this.isQuestionCreated = true;
      this.$modal.hide("addQuestionrows");
      this.selectedEvaulationScheme = [];
      this.interstitialLists = [];
      this.RowNumbersSelected= 1,
      this.multiRowsEntitiesEvaluation= [],
      this.rowSelected= 1,
      this.rowNumberTemp= 1,
      this.evalNumberTemp= 1,
      this.checkRowsSequenceMultiRow= false,
      this.correctRowsPass=1,
      this.correctCustomAssessmentCode="",
      this.incorrectCustomAssessmentCode="",
      this.deleteEvaluationSchemeMultiRow=[],
      this.handleQuestionValueChange({}, true);
    },
    createQuestion() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (this.questionType === "numeric" && !this.numericQuestionAnswer) {
          this.showDeleteModal();
          return;
        }
        if (
          (this.questionType === "range" && !this.questionLowRange) ||
          (this.questionType === "range" && !this.questionHighRange)
        ) {
          this.showDeleteModal();
          return;
        }
        if (
          this.multiple_choice &&
          this.questionType === "numeric" &&
          this.multiple_choice.length < 1
        ) {
          this.showDeleteModal();
          return;
        }
        if (!success) {
          this.showDeleteModal();
          return;
        } else {
          utilFunctionService.showLoader();
          let fb = this.getQuizFormData();
          api
            .createQuestion(this.cardAsstEntityId, fb)
            .then(res => {
              if (this.questionType === "long_answer")
                this.createEvaluation(res.data.data, true);
              // if (this.questionType === "ai_text_rows")
              //   this.createMultiRowEvaluation(res.data.data, true);
              utilFunctionService.showSuccess(
                commonConfig.appMessages.questionCreated
              );
              // this.getEntityList(2);
              this.refreshQuestionList();
              // if (status) {
              //   this.emptyTabs(value, key, this.$route.query.id);
              // }
              this.isQuestionCreated = false;
              this.selectedQuizQuestion = res.data.data;
              this.clickCount = 0;
              utilFunctionService.hideLoader();
              if(this.questionType === "ai_text_rows")
                this.getQuizQuestion2(res.data.data.id)
              console.log(res)
            })
            .catch(() => {
              this.showDeleteModal();
              utilFunctionService.hideLoader();
            });
        }
      });
    },

    handleOverallAssmntItem(data) {
      this.updateOverallAssessment();
      setTimeout(() => {
        this.selectedOverallAssmntItem = data;
        this.overallAssmntItemFeedback = this.selectedOverallAssmntItem.attributes.feedback;
        this.overallAssmntItemMaxScore = this.selectedOverallAssmntItem.attributes.max_score;
        this.overallAssmntItemMinScore = this.selectedOverallAssmntItem.attributes.min_score;
      }, 100);
    },
    Preview() {
      this.cc_characters = [];
      this.to_characters = [];
      this.to_characters = this.searchCharacters
        .filter(x => x.to == true)
        .map(x => x);
      this.cc_characters = this.searchCharacters
        .filter(x => x.cc == true)
        .map(x => x);
      this.$modal.show("videoPreview");
    },
    setCharacter(name, id) {
      this.searchCharacter = name;
      this.chatCharacterId = id;
      this.searchCharacters = [];
    },
    setOptionalCharacter(name, id) {
      this.searchOptionalCharacter = name;
      this.chatMentorId = id;
      this.searchOptionalCharacters = [];
    },
    chatMenu(value, key) {
      if (value) this.chatContentId = value;
      if (this.isActiveTab.length - 1 < key) {
        this.isActiveTab = [];
        for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
      }
    },
    saveWatsonConfig(value, id) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          let fb = new FormData();
          fb.append("asst_assistant_shell[name]", "");
          fb.append(
            "asst_assistant_shell[assistant_dialog_skill_id]",
            this.assistant_dialog_skill_id
          );
          fb.append("asst_assistant_shell[assistant_id]", this.assistantKey);
          fb.append("asst_assistant_shell[url]", this.serviceUrl);
          fb.append("asst_assistant_shell[api_key]", this.apiKey);
          fb.append("asst_assistant_shell[credentials_name]", "");

          api
            .watsonSetUp(fb, this.assistant_id)
            .then(() => {
              this.chatMenu(value, id);
              this.getAssessmentData();
            })
            .catch(() => {});
        }
      });
    },
    handleMcqQuesSelect(index) {
      let list = JSON.parse(JSON.stringify(this.multipleChoiceAnswer));
      this.multipleChoiceAnswer = [];
      this.multipleChoiceAnswer = list.map((e, i) => {
        if (i === index) {
          e["isActive"] = true;
          this.selectedMultipleChoiceAnswer = index;
        } else e["isActive"] = false;
        return e;
      });
    },
    handleMcqAnswersAdded(index, isRemove = false) {
      if (isRemove) this.multipleChoiceAnswer.splice(index, 1);
      else {
        this.counter += 1;
        this.multipleChoiceAnswer.push({
          id: this.counter,
          option: "",
          is_correct: false,
          isActive: true
        });
      }
    },
    changeMultipleChoiceAnswer(index) {
      this.multipleChoiceAnswer.forEach(ele => {
        ele.is_correct = false;
      });
      this.multipleChoiceAnswer[index].is_correct = true;
      this.selectedMultipleChoiceAnswer = index;
    },
    removeAsstEntities(data) {
      utilFunctionService.showLoader();
      api
        .removeAssesSchemeValues(data.id)
        .then(() => {
          this.getEntityList(
            1,
            this.$route.query.id,
            false,
            this.selectedAsstEntities.id
          );
          utilFunctionService.showSuccess(
            commonConfig.appMessages.assestEntityUpdated
          );
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showErr(commonConfig.appMessages.entityInUseErr);
        });
    },
    showSelectQuestionModal() {
      ++this.clickCount;
      if (!this.isQuestionCreated) {
        this.$modal.show("addQuestionType");
      } else {
        // if (this.isQuestionCreated && this.clickCount === 2)
        //   this.createQuestion("question-setup", 2);
        // else this.updatePreviousQuizData();
      }
    },
    showSelectQuestionModalMultiRow() {
      ++this.clickCount;
      if (!this.isQuestionCreated) {
        this.$modal.show("addQuestionrows");
      } else {
        // if (this.isQuestionCreated && this.clickCount === 2)
        //   this.createQuestion("question-setup", 2);
        // else this.updatePreviousQuizData();
      }
    },
    discardQuestion() {
      this.isQuestionCreated = false;
      this.clickCount = 0;
      this.isActive = false;
    },
    changeQuestionTest(e, status) {
      this.testQuizAnswer = "";
      this.isTestQuizAnswer = null;
      this.testEvaulationScheme = [];
      this.testEvalJsonScheme = null;
      this.quizTestCounter = 0;
      if (status)
        this.isTestMultiQuizAnswer = e.target.value === "true" ? true : false;
      else {
        this.allQuestionTestId = e.target.checked;
        !e.target.checked && this.handleselectQuestionToTest({}, true);
      }
    },
    handleselectQuestionToTest(e, status = false) {
      this.aiWatsonResponse = [];
      this.totalQuizQues =
        this.allQuizQuestionTestList.length > 5
          ? 5
          : this.allQuizQuestionTestList.length;
      if (this.allQuestionTestId) {
        if (!status) {
          this.selectQuestionToTest = {
            name: this.allQuizQuestionTestList[0].attributes.question,
            data: this.allQuizQuestionTestList[0],
            question_type: this.allQuizQuestionTestList[0].attributes
              .question_type,
            quizId: this.allQuizQuestionTestList[0].id
          };
          this.$modal.show("testingQuiz");
        } else
          this.selectQuestionToTest = {
            name: this.allQuizQuestionTestList[e.target.value].attributes
              .question,
            data: this.allQuizQuestionTestList[e.target.value],
            question_type: this.allQuizQuestionTestList[e.target.value]
              .attributes.question_type,
            quizId: this.allQuizQuestionTestList[e.target.value].id
          };
        this.testQuizAnswer = "";
        this.isTestQuizAnswer = null;
      } else {
        this.selectQuestionToTest = {
          id: this.quizTestCounter,
          name: this.allQuizQuestionTestList[0].attributes.question,
          data: this.allQuizQuestionTestList[0],
          question_type: this.allQuizQuestionTestList[0].attributes
            .question_type,
          statusBar: {
            width: `${this.getStatusBarWidth(this.quizTestCounter + 1)}%`
          },
          quizId: this.allQuizQuestionTestList[0].id
        };
        this.quizTestCounter = 0;
        this.$modal.show("testingQuiz");
      }
      this.testQuizAnswer = "";
      this.isTestQuizAnswer = null;
      this.testEvaulationScheme = [];
      this.testEvalJsonScheme = null;
      this.testMultiRowResponses = []
      if(this.selectQuestionToTest.question_type === "ai_text_rows"){
        for (let i = 0; i < this.selectQuestionToTest.data.attributes.text_rows.length; i++){
          const MultiRowResponses = {
            responseMultiRow:""
          };
          this.testMultiRowResponses.push(MultiRowResponses)
        }
      }

    },
    handleCheckQuizTest(e) {
      e.preventDefault();
      let { question_type, data } = this.selectQuestionToTest,
        { numeric_answer, range_answer, mcq_options } = data.attributes;
      if (question_type === "numeric") {
        if (parseInt(this.testQuizAnswer) === numeric_answer.attributes.answer)
          this.isTestQuizAnswer = "yes";
        else {
          this.isTestQuizAnswer = "no";
          this.correctAnswer = `Correct answer is ${numeric_answer.attributes.answer}`;
        }
      }
      if (question_type === "range") {
        if (
          parseInt(this.testQuizAnswer) >= range_answer.attributes.low_range &&
          parseInt(this.testQuizAnswer) <= range_answer.attributes.high_range
        )
          this.isTestQuizAnswer = "yes";
        else {
          this.isTestQuizAnswer = "no";
          this.correctAnswer = `Correct answer is between ${range_answer.attributes.low_range} to ${range_answer.attributes.high_range}`;
        }
      }
      if (question_type === "multiple_choice") {
        if (this.isTestMultiQuizAnswer) this.isTestQuizAnswer = "yes";
        else {
          this.isTestQuizAnswer = "no";
          mcq_options.forEach(ele => {
            if (ele.attributes.is_correct)
              this.correctAnswer = `Correct answer is ${ele.attributes.option}`;
          });
        }
      }
      if (question_type === "long_answer") {
        this.getAiQuizEvaluation();
      }
      if (question_type === "ai_text_rows") {
        this.getAiMultiRowQuizEvaluation();
      }
    },
    handleTestPage(status) {
      this.aiWatsonResponse = [];
      let isUpdated = false;
      if (status) {
        if (
          this.selectQuestionToTest.id >= 0 &&
          this.selectQuestionToTest.id < 4
        ) {
          ++this.quizTestCounter;
          isUpdated = true;
        }
      } else {
        if (
          this.selectQuestionToTest.id > 0 &&
          this.selectQuestionToTest.id <= 4
        ) {
          this.quizTestCounter -= 1;
          isUpdated = true;
        }
      }
      if (isUpdated) {
        this.selectQuestionToTest = {
          id: this.quizTestCounter,
          name: this.allQuizQuestionTestList[this.quizTestCounter].attributes
            .question,
          data: this.allQuizQuestionTestList[this.quizTestCounter],
          question_type: this.allQuizQuestionTestList[this.quizTestCounter]
            .attributes.question_type,
          statusBar: {
            width: `${this.getStatusBarWidth(this.quizTestCounter + 1)}%`
          },
          quizId: this.allQuizQuestionTestList[this.quizTestCounter].id
        };
        this.testQuizAnswer = "";
        this.isTestQuizAnswer = null;
        this.testEvaulationScheme = [];
        this.testEvalJsonScheme = null;
      }
    },
    resetTest(isEntireTest) {
      this.quizTestCounter = 0;
      this.isTestQuizAnswer = null;
      if (isEntireTest) this.handleselectQuestionToTest({});
      else this.handleselectQuestionToTest({}, true);
    },
    hideTestModal() {
      this.allQuestionTestId = false;
      this.$modal.hide("testingQuiz");
    },
    handleChangeQuestionTest(e) {
      if (e.target.value === "true") this.isTestQuizAnswer = "yes";
      else {
        this.isTestQuizAnswer = "no";
        this.selectQuestionToTest.data.attributes.mcq_options.forEach(ele => {
          if (ele.attributes.is_correct)
            this.correctAnswer = `Correct answer is ${ele.attributes.option}`;
        });
      }
    },
    handleFeedbackChange(status) {
      this.ariaSelected = status;
    },
    getQuizFormDataUpdate(){
      let fb = new FormData();
      fb.append(
          "quiz_question[question]",
          this.questionValue //.replace(/(<([^>]+)>)/gi, "")
        );
      fb.append("quiz_question[question_type]", this.questionType);
      if (this.questionType !== "ai_text_rows") {

        fb.append("quiz_question[points]", this.questionPoints);
        if (this.questionType === "numeric")
          fb.append(
            "quiz_question[numeric_answer_attributes][answer]",
            this.numericQuestionAnswer
          );
        if (this.questionType === "range") {
          fb.append(
            "quiz_question[range_answer_attributes][high_range]",
            this.questionHighRange
          );
          fb.append(
            "quiz_question[range_answer_attributes][low_range]",
            this.questionLowRange
          );
        }
        if (this.questionType === "multiple_choice" && !status) {
          this.multipleChoiceAnswer.forEach((e, i) => {
            if (e.optionId)
              fb.append(
                `quiz_question[mcq_options_attributes][${i}][id]`,
                e.optionId
              );
            fb.append(`quiz_question[mcq_options_attributes][${i}][order]`, i);
            fb.append(
              `quiz_question[mcq_options_attributes][${i}][option]`,
              e.option
            );
            fb.append(
              `quiz_question[mcq_options_attributes][${i}][is_correct]`,
              e.is_correct
            );
          });
        }
      }
      else {
        fb.append(
          "quiz_question[text_row_info_attributes][id]",
          this.textRowInfoId
        );
        fb.append(
          "quiz_question[text_row_info_attributes][row_count]",
          this.RowNumbersSelected
        );

        fb.append(
          "quiz_question[text_row_info_attributes][row_count_to_pass]",
          this.correctRowsPass
        );
        fb.append(
          "quiz_question[text_row_info_attributes][sequential_evaluation]",
          this.checkRowsSequenceMultiRow
        );

        for(let i = 0; i < this.multiRowsEntitiesEvaluation.length; i++)
        {
          if (!(this.multiRowsEntitiesEvaluation[i].isRemovedRow && this.multiRowsEntitiesEvaluation[i].isAddedRow))
          {
            if (this.multiRowsEntitiesEvaluation[i].isRemovedRow){
              let fb3 = {
                quiz_question: {
                  text_rows_attributes: []
                }
              }
              fb3.quiz_question.text_rows_attributes.push({
                id: this.multiRowsEntitiesEvaluation[i].rowOrder,
                _destroy: true
              });
              api
                .updateQuestionRow(this.selectedQuizQuestion.id, fb3)
                .then()
                .catch()
            }
            else{
              if(this.multiRowsEntitiesEvaluation[i].isAddedRow)
              {
                let idindextemp = 0
                for (let i2 =0; i2<i;i2++){
                  if(this.multiRowsEntitiesEvaluation[i2].id !== -1){
                    console.log(this.multiRowsEntitiesEvaluation[i2].id)
                    idindextemp = i2
                  }
                }
                fb.append(
                  `quiz_question[text_rows_attributes][${i}][order]`,
                  this.multiRowsEntitiesEvaluation[idindextemp].rowOrderNumber+1
                );

                fb.append(
                  `quiz_question[text_rows_attributes][${i}][correct_row_required]`,
                  this.multiRowsEntitiesEvaluation[i].rowRequired
                );
                for (let j = 0; j<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow.length; j++)
                  {
                    for (let k = 0; k<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue.length; k++)
                    {
                      fb.append(
                      `quiz_question[text_rows_attributes][${i}][entity_evaluations_attributes][${j}][entity_evaluation_items_attributes][${k}][asst_entity_value_id]`,
                      this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue[k].asst_entity_value_id,
                      );
                    }
                  }

              }
              else{

                for (let j = 0; j<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow.length; j++)
                {

                  if (!(this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].isRemovedEntitySet && this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].isAddedEntitySet))
                  {
                    if(this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].isRemovedEntitySet)
                      {

                        api
                          .deleteEvalationList(this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].entitySetId)
                          .then()
                          .catch()
                      }
                    else
                    {
                      if (this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].isAddedEntitySet)
                      {
                        let inx = 0
                        let fb2 = new FormData();
                        fb2.append(
                          `quiz_question[text_rows_attributes][${inx}][id]`,
                          this.multiRowsEntitiesEvaluation[i].rowOrder);
                        for (let k = 0; k<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue.length; k++)
                        {
                          fb2.append(
                          `quiz_question[text_rows_attributes][${inx}][entity_evaluations_attributes][${inx}][entity_evaluation_items_attributes][${k}][asst_entity_value_id]`,
                          this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue[k].asst_entity_value_id,);
                        }
                        api
                          .updateQuestionRowEntitySet(this.selectedQuizQuestion.id, fb2)
                          .then()
                          .catch()


                      }
                      else{
                        for (let k = 0; k<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue.length; k++)
                        {
                          if (!(this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue[k].isRemovedEntity && this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue[k].isAddedEntity))
                        {
                              if (this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue[k].isAddedEntity){

                                let inx = 0
                                let fb2 = new FormData();
                                fb2.append(
                                `entity_evaluation[entity_evaluation_items_attributes][${inx}][asst_entity_value_id]`,
                                this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue[k].asst_entity_value_id);
                                api
                                  .updateEntityEvaluationSet(this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].entitySetId, fb2)
                                  .then()
                                  .catch()
                              }

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        for(let i = 0; i < this.multiRowsEntitiesEvaluation.length; i++){

          if (!(this.multiRowsEntitiesEvaluation[i].isRemovedRow)){
            if(!(this.multiRowsEntitiesEvaluation[i].isAddedRow)){
              let inx = 0
              let fb2 = new FormData();
              fb2.append(
                `quiz_question[text_rows_attributes][${inx}][id]`,
                this.multiRowsEntitiesEvaluation[i].rowOrder);

              fb2.append(
                `quiz_question[text_rows_attributes][${inx}][correct_row_required]`,
                this.multiRowsEntitiesEvaluation[i].rowRequired);

              api
                .updateQuestionRowrequired(this.selectedQuizQuestion.id, fb2)
                .then()
                .catch()
            }
          }

        }

        for (let inx = 0; inx<this.deleteEvaluationSchemeMultiRow.length; inx++)
        {
          let fb3 = {
            entity_evaluation: {
              entity_evaluation_items_attributes: []
            }
          }
          fb3.entity_evaluation.entity_evaluation_items_attributes.push({
            id: this.deleteEvaluationSchemeMultiRow[inx].evaluationId,
            _destroy: true
          });

          api
            .updateEvalationListDeleteEntity(this.deleteEvaluationSchemeMultiRow[inx].evaluationSetId, fb3)
            .then()
            .catch()
        }
      }
      return fb;
    },
    getQuizFormData(status) {
      let fb = new FormData();
      fb.append(
        "quiz_question[question]",
        this.questionValue //.replace(/(<([^>]+)>)/gi, "")
      );
      fb.append("quiz_question[question_type]", this.questionType);
      fb.append("quiz_question[points]", this.questionPoints);
      if (this.questionType === "numeric")
        fb.append(
          "quiz_question[numeric_answer_attributes][answer]",
          this.numericQuestionAnswer
        );
      if (this.questionType === "range") {
        fb.append(
          "quiz_question[range_answer_attributes][high_range]",
          this.questionHighRange
        );
        fb.append(
          "quiz_question[range_answer_attributes][low_range]",
          this.questionLowRange
        );
      }
      if (this.questionType === "multiple_choice" && !status) {
        this.multipleChoiceAnswer.forEach((e, i) => {
          if (e.optionId)
            fb.append(
              `quiz_question[mcq_options_attributes][${i}][id]`,
              e.optionId
            );
          fb.append(`quiz_question[mcq_options_attributes][${i}][order]`, i);
          fb.append(
            `quiz_question[mcq_options_attributes][${i}][option]`,
            e.option
          );
          fb.append(
            `quiz_question[mcq_options_attributes][${i}][is_correct]`,
            e.is_correct
          );
        });
      }
      if (this.questionType === "ai_text_rows") {
        fb.append("quiz_question[correct_assessment_code]", this.correctCustomAssessmentCode);
        fb.append("quiz_question[incorrect_assessment_code]", this.incorrectCustomAssessmentCode);
        fb.append(
          "quiz_question[text_row_info_attributes][row_count]",
          this.RowNumbersSelected
        );
        fb.append(
          "quiz_question[text_row_info_attributes][row_count_to_pass]",
          this.correctRowsPass
        );
        fb.append(
          "quiz_question[text_row_info_attributes][sequential_evaluation]",
          this.checkRowsSequenceMultiRow
        );

        for (let i =0; i<this.multiRowsEntitiesEvaluation.length; i++){
          if (!this.multiRowsEntitiesEvaluation[i].isRemovedRow){


            fb.append(
              `quiz_question[text_rows_attributes][${i}][order]`,
              i+1
            );
            fb.append(
              `quiz_question[text_rows_attributes][${i}][correct_row_required]`,
              this.multiRowsEntitiesEvaluation[i].rowRequired
            );
            for (let j = 0; j<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow.length; j++)
            {
              for (let k = 0; k<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue.length; k++)
              {
                fb.append(
                `quiz_question[text_rows_attributes][${i}][entity_evaluations_attributes][${j}][entity_evaluation_items_attributes][${k}][asst_entity_value_id]`,
                this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].evalValue[k].asst_entity_value_id,
                );
              }
            }
          }
        }
      }
      return fb;
    },

    updatePreviousQuizData(value, key) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (
          (this.questionType === "range" && !this.questionLowRange) ||
          (this.questionType === "range" && !this.questionHighRange)
        ) {
          this.showDeleteModal();
          return;
        }
        if (
          this.multiple_choice &&
          this.questionType === "numeric" &&
          this.multiple_choice.length < 1
        ) {
          this.showDeleteModal();
          return;
        }
        if (!success) {
          this.showDeleteModal();
          return;
        } else {
          let fb = this.getQuizFormData();
          this.selectedQuizQuestion &&
            api
              .updateQuizQuestion(this.selectedQuizQuestion.id, fb)
              .then(res => {
                this.getEntityList(2, "question-setup", false);
                let feedbackFb = new FormData();
                if (this.questionType === "long_answer")
                  this.createEvaluation(res.data.data, true);
                feedbackFb.append(
                  "quiz_feedback[quiz_feedback_attributes][right_ans_feedback]",
                  this.questionCorrectFeedback
                );
                feedbackFb.append(
                  "quiz_feedback[quiz_feedback_attributes][wrong_ans_feedback]",
                  this.questionIncorrectFeedback
                );
                  feedbackFb.append(
                    "quiz_question[quiz_feedback_attributes][right_ans_label]",
                    this.correctFeedbackTitle
                  );
                  feedbackFb.append(
                    "quiz_question[quiz_feedback_attributes][wrong_ans_label]",
                    this.inCorrectFeedbackTitle
                  );
                api
                  .updateQuizFeedabck(
                    res.data.data.attributes.quiz_feedback.id,
                    feedbackFb
                  )
                  .then(() => {
                    this.getEntityList(2, "question-setup", false);
                  })
                  .catch(() => {
                    this.getEntityList(2, "question-setup", false);
                    this.emptyTabs(value, key, this.$route.query.id);
                    utilFunctionService.showErr(
                      commonConfig.appMessages.feedbackUpdateErr
                    );
                    utilFunctionService.hideLoader();
                  });
              })
              .catch(() => {});
          value && this.emptyTabs(value, key, this.$route.query.id);
        }
      });
    },
    updateOverallAssessment() {
      this.$refs.form.validate().then(success => {
        if (
          !this.overallAssmntItemFeedback &&
          !this.overallAssmntItemMaxScore &&
          !this.overallAssmntItemMaxScore
        ) {
          this.isActive = true;
          return;
        }
        if (!success) {
          this.isActive = true;
          return;
        } else {
          let fb = new FormData();
          fb.append(
            "overall_assmnt_item[feedback]",
            this.overallAssmntItemFeedback
          );
          fb.append(
            "overall_assmnt_item[max_score]",
            this.overallAssmntItemMaxScore
          );
          fb.append(
            "overall_assmnt_item[min_score]",
            this.overallAssmntItemMinScore
          );
          api
            .updateOverallAssesScheme(this.selectedOverallAssmntItem.id, fb)
            .then(() => {
              this.getEntityList(3, this.$route.query.id, true, null, false);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.feedbackUpdateErr
              );
            });
        }
      });
    },
    getStatusBarWidth(quizTestCounter) {
      let calVal;
      if (this.totalQuizQues === 1) calVal = 100;
      if (this.totalQuizQues === 2) calVal = quizTestCounter * 50;
      if (this.totalQuizQues === 3) calVal = (quizTestCounter * 100) / 3;
      if (this.totalQuizQues === 4) calVal = quizTestCounter * 25;
      if (this.totalQuizQues === 5) calVal = (quizTestCounter + 1) * 20;
      return calVal;
    },
    createOverAllAssessment(data) {
      data.forEach(ele => {
        let fb = new FormData();
        fb.append("overall_assmnt_item[assessment_label_id]", ele.id);
        fb.append("overall_assmnt_item[feedback]", "");
        fb.append("overall_assmnt_item[max_score]", 0);
        fb.append("overall_assmnt_item[min_score]", 0);
        api
          .createOverallAssesScheme(this.$route.query.id, fb)
          .then(() => {})
          .catch(() => {
            utilFunctionService.showErr();
          });
      });
    },
    showDeleteModal() {
      this.quizContentId === "question-setup" &&
        this.$modal.show("deleteWorldModal");
    },
    showDeleteModal2() {

      this.$modal.show("deleteWorldModal2");
    },

    getSelectedAssessment(id) {
      if (this.assessmentLists) {
        this.assessmentLists.forEach(e => {
          if (id == e.id) {
            this.assessmentListKey = { name: e.attributes.name, id: e.id };
          }
        });
      }
    },
    handleEvaluationDrop(data) {
      let arr = this.getAllIndexes(
        this.selectedEvalValue,
        data.asst_entity_value_id,
        true
      );
      if (this.isAIEdited) data["entityEvaluationId"] = this.selEvaluationId;
      if (arr.length === 0) this.selectedEvalValue.push(data);
    },
    handleEvaluationDropMultiRow(data) {
      //
      let b = true
      for (let i =0; i < this.selectedEvalValueTemp.evalValue.length; i++)
      {
        if (this.selectedEvalValueTemp.evalValue[i].asst_entity_value_id === data.asst_entity_value_id)
        {
          b = false

        }
      }
      if(b)
      {
        data.isAddedEntity = true
        data.isRemovedEntity = false
        this.selectedEvalValueTemp.evalValue.push(data);
      }
      // let b = true
      // for (let i =0; i < this.selectedEvalValue.evalValue.length; i++)
      // {
      //   if (this.selectedEvalValue.evalValue[i].asst_entity_value_id === data.asst_entity_value_id)
      //   {
      //     b = false

      //   }
      // }
      // // if (this.isAIEdited) data["entityEvaluationId"] = this.selEvaluationId;
      // // if (arr.length === 0) this.selectedEvalValue.push(entitySet);
      // if(b)
      // {
      //   data.isAddedEntity = true
      //   data.isRemovedEntity = false
      //   this.selectedEvalValue.evalValue.push(data);
      // }
    },
    handleLabelEvalChange(e, data) {
      let evalVal = this.getAllIndexes(this.selectedEvalValue, data.id, false);
      if (evalVal.length !== 0) {
        for (let i = evalVal.length - 1; i >= 0; i--)
          this.selectedEvalValue.splice(evalVal[i], 1);
      }
      if (e.target.checked)
        this.selectedEvalValue.push({
          entity: data.attributes.name,
          entityId: data.id,
          value: data.attributes.asst_entity_values.map(ele => ele.id),
          isAll: true
        });
    },
    handleLabelEvalChangeMultiRow(e, data) {
      let evalVal = this.getAllIndexes(this.selectedEvalValueTemp, data.id, false);
      if (evalVal.length !== 0) {
        for (let i = evalVal.length - 1; i >= 0; i--)
          this.selectedEvalValueTemp.splice(evalVal[i], 1);
      }
      if (e.target.checked)
        this.selectedEvalValueTemp.push({
          entity: data.attributes.name,
          entityId: data.id,
          value: data.attributes.asst_entity_values.map(ele => ele.id),
          isAll: true
        });
    },
    getAllIndexes(arr, val, status) {
      let indexes = [];
      arr.forEach((e, i) => {
        if (status && e.asst_entity_value_id === val) indexes.push(i);
        if (!status && e.entityId === val) indexes.push(i);
      });
      return indexes;
    },
    showSchemeModal() {
      this.selectedEvalValue = [];
      this.$modal.show("entityEvaluation");
    },
    showSchemeModalMultiRow(id) {
      this.rowSelected = id;
      this.rowNumberTemp = id
      this.selectedEvalValue = [];
      this.isAIEdited = false
      this.selectedEvalValueTemp={
        entitySetId:null,
        evalValue: [],
        isAddedEntitySet:false,
        isRemovedEntitySet: false
      }
      this.deleteEntityMultiRow= [],
      this.updateMultiRowsEntitiesSetTemp(),
      this.$modal.show("entityEvaluationMultiRow");
    },
    deleteRow(id) {
      this.rowNumberTemp = id
      if (this.RowNumbersSelected < 3){
        this.$modal.show("rowNumberLowModal");
      }
      else{
        this.$modal.show("removeRowModal");
      }
    },
    removeRow(){
      this.RowNumbersSelected = this.RowNumbersSelected - 1
      // this.multiRowsEntitiesEvaluation.splice(this.rowNumberTemp-1,1)
      for (let i = 0; i<this.multiRowsEntitiesEvaluation.length; i++){
        if (this.multiRowsEntitiesEvaluation[i].id == this.rowNumberTemp){
          this.multiRowsEntitiesEvaluation[i].isRemovedRow = true;
          this.multiRowsEntitiesEvaluation[i].id= -1;
        }
      }
      let inx = 0
      let inx2 = 1
      while( inx < this.multiRowsEntitiesEvaluation.length){
        if(!this.multiRowsEntitiesEvaluation[inx].isRemovedRow){
          this.multiRowsEntitiesEvaluation[inx].id = inx2
          inx = inx + 1
          inx2 = inx2 +1
        }
        else{
          inx = inx + 1
        }
      }

      this.$modal.hide("removeRowModal");
      this.checkCorrectRowsPass();
      this.updateMultiRowsEntitiesSetTemp()


    },
    discardLowNumberModal(){
      this.$modal.hide("rowNumberLowModal");

    },

    addNewRow(){
      if (this.RowNumbersSelected < 15){
        this.RowNumbersSelected = this.RowNumbersSelected+1;
        const rowx ={
          id: this.RowNumbersSelected,
          entityEvaluationRow:[],
          rowRequired: false,
          isAddedRow: true,
          isRemovedRow: false}
        this.multiRowsEntitiesEvaluation.push(rowx)
      }

    },
    saveEvaulationScheme() {
      if (!this.isAIEdited)
        this.selectedEvaulationScheme.push(this.selectedEvalValue);
      this.$modal.hide("entityEvaluation");
      this.selectedEvalValue = [];
    },
    saveEvaulationEntitySchemeMultiRow() {
      let index = 0
      let indexset = 0
      let b = true
      if (this.isAIEdited)
      {

        for(let i =0; i<this.multiRowsEntitiesSetTemp.length;i++){
          if (this.multiRowsEntitiesSetTemp[i].length === this.selectedEvalValueTemp.evalValue.length)
          {
            let entitySetTemp = []
            for (let j = 0; j<this.multiRowsEntitiesSetTemp[i].length;j++){
              entitySetTemp.push(this.multiRowsEntitiesSetTemp[i][j].asst_entity_value_id)
            }
            let a = false
            for(let k =0 ; k<this.selectedEvalValueTemp.evalValue.length; k++){
              if(! entitySetTemp.includes(this.selectedEvalValueTemp.evalValue[k].asst_entity_value_id)){
                a = true
              }
            }
            if (!a){
              b = false
            }
          }

        }
        for (let i = 0; i<this.multiRowsEntitiesEvaluation.length; i++)
        {
          if (this.multiRowsEntitiesEvaluation[i].id === this.rowNumberTemp){
            index = i
            for(let j = 0; j<this.multiRowsEntitiesEvaluation[i].entityEvaluationRow.length; j++)
            {
              if (this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[j].entitySetId === this.evalNumberTemp){
                indexset = j
              }
            }
          }
        }
        if (b){

          console.log(this.deleteEntityMultiRowTemp)
          for(let i =0; i<this.deleteEntityMultiRowTemp.length; i++){
            const removeEvaluationEntity={
            evaluationSetId: this.evalNumberTemp,
            evaluationId: this.deleteEntityMultiRowTemp[i].evalId
            }
            console.log(this.multiRowsEntitiesEvaluation[index].entityEvaluationRow[indexset])
            this.deleteEvaluationSchemeMultiRow.push(removeEvaluationEntity)
            for (let j = 0; j<this.multiRowsEntitiesEvaluation[index].entityEvaluationRow[indexset].evalValue.length;j++){
              if (this.deleteEntityMultiRowTemp[i].asst_entity_value_id === this.multiRowsEntitiesEvaluation[index].entityEvaluationRow[indexset].evalValue[j].asst_entity_value_id)
              {
                this.multiRowsEntitiesEvaluation[index].entityEvaluationRow[indexset].evalValue.splice(j, 1);
              }
            }
          }

          for(let k =0 ; k<this.selectedEvalValueTemp.evalValue.length; k++){
            if (this.selectedEvalValueTemp.evalValue[k].entityEvaluationId !== null)
            {
              this.multiRowsEntitiesEvaluation[index].entityEvaluationRow[indexset].evalValue.push(this.selectedEvalValueTemp.evalValue[k])
            }
          }

        }
        else{
          this.$modal.show("sameEntitySetExist");
        }



      }
      else{
        for(let i =0; i<this.multiRowsEntitiesSetTemp.length;i++){
          if (this.multiRowsEntitiesSetTemp[i].length === this.selectedEvalValueTemp.evalValue.length)
          {
            let entitySetTemp = []
            for (let j = 0; j<this.multiRowsEntitiesSetTemp[i].length;j++){
              entitySetTemp.push(this.multiRowsEntitiesSetTemp[i][j].asst_entity_value_id)
            }
            let a = false
            for(let k =0 ; k<this.selectedEvalValueTemp.evalValue.length; k++){
              if(! entitySetTemp.includes(this.selectedEvalValueTemp.evalValue[k].asst_entity_value_id)){
                a = true
              }
            }
            if (!a){
              b = false
            }
          }

        }
        for (let i = 0; i<this.multiRowsEntitiesEvaluation.length; i++)
        {
          if (this.multiRowsEntitiesEvaluation[i].id === this.rowNumberTemp){
            index = i
          }
        }
        if (b){
          if (this.multiRowsEntitiesEvaluation[index].entityEvaluationRow.length < 4)
          {
            if(this.multiRowsEntitiesEvaluation[index].isAddedRow){
              const entitySet={
                isAddedEntitySet: false,
                isRemovedEntitySet: false,
                evalValue: this.selectedEvalValueTemp.evalValue
              }
              this.multiRowsEntitiesEvaluation[index].entityEvaluationRow.push(entitySet);
            }
          else{
            const entitySet={
              isAddedEntitySet: true,
              isRemovedEntitySet: false,
              evalValue: this.selectedEvalValueTemp.evalValue
            }
            this.multiRowsEntitiesEvaluation[index].entityEvaluationRow.push(entitySet);

          }
        }

        }
        else{
          this.$modal.show("sameEntitySetExist");
        }

      }
      // if (!this.isAIEdited)
      //   this.selectedEvaulationScheme.push(this.selectedEvalValue);
      this.$modal.hide("editEntityEvaluationMultiRow");
      this.$modal.hide("entityEvaluationMultiRow");

      this.selectedEvalValueTemp={
        entitySetId:null,
        evalValue: [],
        isAddedEntitySet:false,
        isRemovedEntitySet: false
      }
      this.deleteEntityMultiRowTemp= [];
      this.updateMultiRowsEntitiesSetTemp()

    },
    discardSameEntitySetExist(){
      this.$modal.hide("sameEntitySetExist");
    },
    saveEvaulationSchemeMultiRow() {
      let rowSelectedIndexTemp = 1
      for (let i = 0; i < this.multiRowsEntitiesEvaluation.length; i++){
        if (this.multiRowsEntitiesEvaluation[i].id === this.rowSelected){
          rowSelectedIndexTemp = i
        }
      }
        if (this.multiRowsEntitiesEvaluation[rowSelectedIndexTemp].entityEvaluationRow.length < 4)
        {
          if(this.multiRowsEntitiesEvaluation[rowSelectedIndexTemp].isAddedRow){
            const entitySet={
              isAddedEntitySet: false,
              isRemovedEntitySet: false,
              evalValue: this.selectedEvalValue
            }
            this.multiRowsEntitiesEvaluation[rowSelectedIndexTemp].entityEvaluationRow.push(entitySet);
          }
          else{
            const entitySet={
              isAddedEntitySet: true,
              isRemovedEntitySet: false,
              evalValue: this.selectedEvalValue
            }
            this.multiRowsEntitiesEvaluation[rowSelectedIndexTemp].entityEvaluationRow.push(entitySet);

          }
        }
      this.$modal.hide("entityEvaluationMultiRow");
      this.selectedEvalValue = [];
      this.updateMultiRowsEntitiesSetTemp()
    },
    createMultiRowEvaluation(data, isUpdated){
      let fb = {
          quiz_question: {
            text_row_info_attributes: {
              row_count: this.RowNumbersSelected
            },
          }
      }
      if (isUpdated)
      {
        api
          .updateQuizQuestion(data.id, fb)
          .then()
          .catch()

      }

    },
    createEvaluation(data, isUpdated) {
      this.selectedEvaulationScheme.forEach(ele => {
        let fb = {
            entity_evaluation: {
              entity_evaluation_items_attributes: []
            }
          },
          evalid,
          isDel = false;
        for (let i = 0; i < ele.length; i++) {
          evalid = ele[i]["entityEvaluationId"];
          if (!ele[i].isAdded) {
            if (ele[i].isAll) {
              ele[i].value.forEach(e => {
                fb.entity_evaluation.entity_evaluation_items_attributes.push({
                  asst_entity_value_id: e
                });
              });
            } else
              fb.entity_evaluation.entity_evaluation_items_attributes.push({
                asst_entity_value_id: ele[i].asst_entity_value_id
              });
          }
        }
        if (
          this.deletedEvaulationScheme[evalid] &&
          this.deletedEvaulationScheme[evalid].length > 0
        ) {
          isDel = true;
          this.deletedEvaulationScheme[evalid].forEach(e => {
            fb.entity_evaluation.entity_evaluation_items_attributes.push({
              id: e,
              _destroy: true
            });
          });
        }
        if ((isUpdated && evalid) || (isUpdated && isDel)) {
          api.updateEvaluationScheme(evalid, fb).then(() => {
            this.isAIEdited = false;
            this.selectedEvalValue = [];
            this.selEvaluationId = [];
            this.getQuizQuestionEntityEvalList(this.selectedQuizQuestion.id);
          });
        } else
          api.createEvaluationScheme(data.id, fb).then(() => {
            this.getQuizQuestionEntityEvalList(this.selectedQuizQuestion.id);
          });
      });
      this.deletedEvaulationScheme = {};
      api
        .getElmDetails(this.id)
        .then(() => {})
        .catch();
      this.isAIEdited = false;
    },
    getQuizQuestionEntityEvalList(questionId, status = false) {
      api.getQuizQuestionEntityEvalList(questionId).then(res => {
        this.selectedEvaulationScheme = [];
        this.evaluationIdList = [];
        if (status) this.testEvalJsonScheme = res.data.data;
        res.data.data.forEach(e => {
          this.evaluationIdList.push(e.id);
          let evaluationCondition = e.attributes.condition
           .replace(/[{()}]/g, "")
            .split("&&");
          let evalValue = [];
          evaluationCondition.forEach(ele => {
            let value = ele.split(":");
            let entity = this.getEntityValue(
              e.attributes.entity_evaluation_items,
              value[1].trim()
            );
               evalValue.push({
                asst_entity_value_id: entity.attributes.asst_entity_value.id,
                entity: value[0],
                isAll: null,
                entityEvaluationId: e.id,
                isAdded: true,
                value: entity.attributes.asst_entity_value.attributes.value,
                evalId: entity.id
              });
          });
          if (status) this.testEvaulationScheme.push(evalValue);
          else this.selectedEvaulationScheme.push(evalValue);
        });

      });
    },
    getQuizQuestion2(questionId){

      this.multiRowsEntitiesEvaluation = [];
      api
            .getQuizQuestion2(questionId)
            .then(res => {
              this.deleteEvaluationSchemeMultiRow=[],
              this.correctCustomAssessmentCode = res.data.data.attributes.correct_assessment_code
              this.incorrectCustomAssessmentCode = res.data.data.attributes.incorrect_assessment_code
              // this.RowNumbers = res.data.data.attributes.text_row_info.attributes.row_count
              this.correctRowsPass = res.data.data.attributes.text_row_info.attributes.row_count_to_pass
              this.checkRowsSequenceMultiRow = res.data.data.attributes.text_row_info.attributes.sequential_evaluation
              this.RowNumbersSelected = res.data.data.attributes.text_row_info.attributes.row_count
              this.oldRowNumbersSelected = res.data.data.attributes.text_row_info.attributes.row_count
              this.textRowInfoId= res.data.data.attributes.text_row_info.id

              for (let i =0; i<res.data.data.attributes.text_rows.length; i++){
                let entityeval = []

                if(typeof res.data.data.attributes.text_rows[i].attributes.entity_evaluations !== 'undefined'){
                  res.data.data.attributes.text_rows[i].attributes.entity_evaluations.forEach(e => {
                    let evaluationCondition = e.attributes.condition
                    .replace(/[{()}]/g, "")
                      .split("&&");
                    let evalValue = [];
                    evaluationCondition.forEach(ele => {
                      let value = ele.split(":");
                      let entity = this.getEntityValue(
                      e.attributes.entity_evaluation_items,
                      value[1].trim()
                    );
                    evalValue.push({
                      asst_entity_value_id: entity.attributes.asst_entity_value.id,
                      entity: value[0],
                      isAll: null,
                      entityEvaluationId: e.id,
                      isAdded: true,
                      isAddedEntity: false,
                      isRemovedEntity: false,
                      value: entity.attributes.asst_entity_value.attributes.value,
                      evalId: entity.id
                    });
                    });
                    const entitySet ={
                      isAddedEntitySet: false,
                      isRemovedEntitySet: false,
                      entitySetId: e.id,
                      evalValue
                    }
                    entityeval.push(entitySet)

                  })
                }
                const rowx ={
                  rowOrder: res.data.data.attributes.text_rows[i].id,
                  rowOrderNumber: res.data.data.attributes.text_rows[i].attributes.order,
                  id: i+1,
                  entityEvaluationRow:entityeval,
                  rowRequired: res.data.data.attributes.text_rows[i].attributes.correct_row_required,
                  isAddedRow: false,
                  isRemovedRow: false}

                this.multiRowsEntitiesEvaluation.push(rowx)
              }
              let multiRowsEntitiesEvaluationSorted = [];
              let rowsSorting = [];
              for (let i =0; i <this.multiRowsEntitiesEvaluation.length; i++){
                rowsSorting.push(parseInt(this.multiRowsEntitiesEvaluation[i].rowOrderNumber))
              }
              rowsSorting.sort((a, b) => a - b);
              for (let i = 0; i<rowsSorting.length; i++){
                for (let j =0; j <this.multiRowsEntitiesEvaluation.length; j++){
                  if (parseInt(this.multiRowsEntitiesEvaluation[j].rowOrderNumber) === rowsSorting[i])
                  {
                    multiRowsEntitiesEvaluationSorted.push(this.multiRowsEntitiesEvaluation[j])
                  }
                }
              }
              this.multiRowsEntitiesEvaluation =[];
              for (let i = 0; i<multiRowsEntitiesEvaluationSorted.length; i++){
                const rowx ={
                  rowOrder: multiRowsEntitiesEvaluationSorted[i].rowOrder,
                  rowOrderNumber: multiRowsEntitiesEvaluationSorted[i].rowOrderNumber,
                  id: i+1,
                  entityEvaluationRow:multiRowsEntitiesEvaluationSorted[i].entityEvaluationRow,
                  rowRequired: multiRowsEntitiesEvaluationSorted[i].rowRequired,
                  isAddedRow: false,
                  isRemovedRow: false}

                this.multiRowsEntitiesEvaluation.push(rowx)
              }

            })
            .catch();

    },


    getEntityValue(arr, searchKey) {
      let val = [];
      arr.forEach(e => {
        if (e.attributes.asst_entity_value.attributes.value.trim() === searchKey.trim())
          val = e;
      });
      return val;
    },
    removeEvaluationSet(index, status, id, evalId) {
      if (status) {
        if (this.isAIEdited) {
          if (!this.deletedEvaulationScheme[evalId])
            this.deletedEvaulationScheme[evalId] = [];
          this.deletedEvaulationScheme[evalId].push(id);
        }
        this.selectedEvalValue.splice(index, 1);
      } else {
        this.discardSelIndex = index;
        this.$modal.show("deleteEvalModal");
      }
    },
    removeEvaluationMultiRow(index, status, id, evalId, data) {
      if (status) {
        if (this.isAIEdited) {
          // const removeEvaluationEntity={
          //   evaluationSetId: evalId,
          //   evaluationId: id
          // }
          // this.deleteEvaluationSchemeMultiRow.push(removeEvaluationEntity)
          if ( data.entityEvaluationId === null){
            this.deleteEntityMultiRowTemp.push(data)
          }
        }

        this.selectedEvalValueTemp.evalValue.splice(index, 1);
      } else {
        this.discardSelIndex = index;
        this.$modal.show("deleteEvalModal");
      }
    },
    removeEvaluationSetMultiRow(index, id) {
      this.rowNumberTemp = index
      this.evalNumberTemp = id
      this.$modal.show("deleteEvalModalMultiRow");
    },
    discardEvalModalMultiRow(){
      // this.multiRowsEntitiesEvaluation[this.rowNumberTemp-1].entityEvaluationRow.evalValue.splice(this.evalNumberTemp, 1);
      for (let i = 0; i<this.multiRowsEntitiesEvaluation.length; i++)
      {
        if (this.multiRowsEntitiesEvaluation[i].id === this.rowNumberTemp){
          this.multiRowsEntitiesEvaluation[i].entityEvaluationRow[this.evalNumberTemp].isRemovedEntitySet= true
        }
      }

      this.$modal.hide("deleteEvalModalMultiRow");
      this.updateMultiRowsEntitiesSetTemp()

    },


    discardEvalModal() {
      if (this.evaluationIdList[this.discardSelIndex]) {
        utilFunctionService.showLoader();
        api
          .deleteEvaluationScheme(this.evaluationIdList[this.discardSelIndex])
          .then(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.removeEvaluation
            );
          });
      }
      this.selectedEvaulationScheme.splice(this.discardSelIndex, 1);
      this.evaluationIdList.splice(this.discardSelIndex, 1);
      this.$modal.hide("deleteEvalModal");
    },
    filterQuestion(data) {
      let arr = [];
      data.forEach(ele => {
        if (ele.attributes.question_type !== "long_answer") arr.push(ele);
      });
      return arr;
    },
    getActiveBarStatus(data) {
      if (data.attributes.status === "published") this.setActiveTabs(3);
      else {
        api.getAsstEntities(data.id).then(res => {
          this.asstEntitiesList = res.data.data;
          this.selectedAsstEntities = this.asstEntitiesList[0];
          if (this.asstEntitiesList.length > 0) this.setActiveTabs(1);
          api.getQuizQuestionList(data.attributes.card_detail.id).then(res => {
            this.quizQuestionList = res.data.data;
            this.quizQuestionList.sort(
              this.sortedOrder
            )
            this.selectedQuizQuestion = this.quizQuestionList[0];
            if (this.selectedQuizQuestion)
              this.interstitialLists = this.selectedQuizQuestion.attributes.adaptive_contents;
            if (this.quizQuestionList.length > 0) this.setActiveTabs(2);
          });
        });
      }
    },
    setActiveTabs(key) {
      this.isActiveTab = [];
      for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
    },
    removeQuestionTags(ques) {
      return ques.replace(/&nbsp;/gi, " ");
    },
    updateSubmissionList(status) {
      if (status) {
        this.$parent.getElmDetails();
        this.menus = this.$parent.elmData;
        this.$router.push(this.$route.params.id);
        utilFunctionService.showSuccess(
          status === "drafted"
            ? commonConfig.appMessages.LoDraftText
            : commonConfig.appMessages.LoPublichedText
        );
        this.$parent.card_type = "";
        this.$parent.count = 0;
      } else {
        this.$parent.getElmDetails();
        this.menus = this.$parent.elmData;
      }
    },
    updateDialogicSubmit(Statuskey, id) {
      if (status) {
        this.$parent.getElmDetails();
        this.menus = this.$parent.elmData;
        this.$route.query.id = id;
        this.$router.push(this.$route.params.id);
        utilFunctionService.showSuccess(
          status === "drafted"
            ? commonConfig.appMessages.LoDraftText
            : commonConfig.appMessages.LoPublichedText
        );
        this.$parent.card_type = "";
        this.$parent.count = 0;
      } else {
        this.$parent.getElmDetails();
        this.$route.query.id = id;
        this.menus = this.$parent.elmData;
      }
    },
    importSkillFromAssistant() {
      api.importSkillFromAssistant(this.cardAsstEntityId).then(res => {
        this.skillFromAssistantData = res.data.data;
        if (this.skillFromAssistantData.attributes.last_skills_import_date) {
          this.last_skills_import_date = moment(
            this.skillFromAssistantData.last_skills_import_date
          ).format("MMMM Do YYYY");
        } else this.last_skills_import_date = "";
        this.getAssessmentData();
      });
    },
    getAssessmentData(isSkill = true) {
      this.assessment_scheme_id &&
        api.getAssessmentSkill(this.assessment_scheme_id).then(res => {
          this.selectedChatSkill &&
            api.getSkillAsstItemList(this.selectedChatSkill.id).then(res1 => {
              this.assessmentSkillLabelList = [];
              res1.data.data.forEach(e => {
                res.data.data.attributes.assessment_labels.forEach(ele => {
                  if (ele.attributes.name === e.attributes.label_name)
                    ele["skillId"] = e.id;
                });
              });
              this.getMissedChatSkill(false, true);
              this.assessmentSkillLabelList =
                res.data.data.attributes.assessment_labels;
              this.selAssessmentSkillLabel =
                res.data.data.attributes.assessment_labels[0];
              this.getSkillContent(this.selAssessmentSkillLabel.skillId);
            });
        });
      api.getChatSkillList(this.$route.query.id).then(res => {
        this.chatSkillList = res.data.data;
        if (isSkill) this.selectedChatSkill = res.data.data[0];
        if (this.chatSkillList.length > 0) this.chatMenu(null, 2);
      });
    },
    getMissedChatSkill(status, isAdd) {
      utilFunctionService.showLoader();
      this.selectedChatSkill &&
        api.getMissedAssessmentSkill(this.selectedChatSkill.id).then(res => {
          utilFunctionService.hideLoader();
          let {
            points,
            adaptive_contents,
            debrief_contents,
            chat_skill_id
          } = res.data.data[0].attributes;
          if (status) {
            let index = this.assessmentSkillLabelList.findIndex(
              ele => ele.attributes.name === "Missed"
            );
            this.assessmentSkillLabelList[index] = {
              id: res.data.data[0].id,
              skillId: chat_skill_id,
              adaptive_contents: adaptive_contents,
              attributes: {
                name: "Missed",
                debrief_contents: debrief_contents[debrief_contents.length - 1],
                points: points
              }
            };
            this.selAssessmentSkillLabel = this.assessmentSkillLabelList[index];
            this.selectedSkillItem = {
              value_count_max: "",
              value_count_min: "",
              points: points,
              adaptive_contents: adaptive_contents,
              debrief_contents:
                debrief_contents.length > 0
                  ? debrief_contents[debrief_contents.length - 1].attributes
                      .content
                  : "",
              debrief_id:
                debrief_contents.length > 0
                  ? debrief_contents[debrief_contents.length - 1].id
                  : ""
            };
          }
          isAdd &&
            this.assessmentSkillLabelList.push({
              id: res.data.data[0].id,
              skillId: chat_skill_id,
              adaptive_contents: adaptive_contents,
              attributes: {
                name: "Missed",
                debrief_contents: debrief_contents[debrief_contents.length - 1],
                points: points
              }
            });
        });
    },
    handleSelectSkill(data, status) {
      this.updatedSkillItems();
      if (status) {
        setTimeout(() => {
          this.selectedChatSkill = data;
          this.getAssessmentData(false);
        }, 1000);
      } else {
        setTimeout(() => {
          if (data.attributes.name === "Missed")
            this.getMissedChatSkill(true, false);
          else {
            this.selAssessmentSkillLabel = data;
            this.getSkillContent(this.selAssessmentSkillLabel.skillId);
          }
        }, 1000);
      }
    },
    getSkillContent(id) {
      id &&
        api.getSkillItemContent(id).then(res => {
          let {
            value_count_max,
            value_count_min,
            points,
            adaptive_contents,
            debrief_contents,
            chat_skill_id
          } = res.data.data.attributes;
          this.selectedSkillItem = {
            value_count_max,
            value_count_min,
            points,
            adaptive_contents,
            debrief_contents:
              debrief_contents.length > 0
                ? debrief_contents[debrief_contents.length - 1].attributes
                    .content
                : "",
            debrief_id:
              debrief_contents.length > 0
                ? debrief_contents[debrief_contents.length - 1].id
                : null
          };
          this.chat_skill_id = chat_skill_id;
          this.selAssessmentSkillLabel["adaptive_contents"] = adaptive_contents;
        });
    },
    updatedSkillItems() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.isActive = true;
          return;
        } else {
          let fb = {};
          if (this.selAssessmentSkillLabel.attributes.name !== "Missed") {
            fb = {
              chat_skill_assmnt_item: {
                assessment_label_id: this.selAssessmentSkillLabel.id,
                value_count_max: this.selectedSkillItem.value_count_max,
                value_count_min: this.selectedSkillItem.value_count_min,
                points: this.selectedSkillItem.points,
                debriefs_attributes: [
                  {
                    content: this.selectedSkillItem.debrief_contents
                  }
                ]
              }
            };
            if (this.selectedSkillItem.debrief_id)
              fb.chat_skill_assmnt_item.debriefs_attributes[0].id = this.selectedSkillItem.debrief_id;
            if (this.selAssessmentSkillLabel.skillId)
              api.updatedSkillItems(this.selAssessmentSkillLabel.skillId, fb);
          } else {
            fb = {
              chat_skill_assmnt_missed: {
                points: this.selectedSkillItem.points,
                debriefs_attributes: [
                  {
                    content: this.selectedSkillItem.debrief_contents,
                    id: this.selectedSkillItem.debrief_id
                  }
                ]
              }
            };
            if (this.selectedChatSkill.id)
              api.updatedMissedSkillItems(this.selectedChatSkill.id, fb);
          }
        }
      });
    },
    handleOverAllAssChange(e) {
      this.overallQuizAssessment = e.target.checked;
    },
    editEvaluationSet(data, index) {
      this.isAIEdited = true;
      this.selectedEvalValue = data;
      this.selEvaluationId = this.evaluationIdList[index];
      this.$modal.show("entityEvaluation");
    },
    editEvaluationSetMultiRow(data, rowID) {
      this.isAIEdited = true;
      this.evalNumberTemp = data.entitySetId
      this.rowNumberTemp = rowID
      // this.selectedEvalValue = data;
      let evalValueTemp = []
      for (let i = 0 ; i<data.evalValue.length;i++)
      {
        const evalTemp={
          asst_entity_value_id: data.evalValue[i].asst_entity_value_id,
          entity: data.evalValue[i].entity,
          entityEvaluationId: null,
          evalId: data.evalValue[i].evalId,
          isAdded:data.evalValue[i].isAdded,
          isAddedEntity: data.evalValue[i].isAddedEntity,
          isAll: data.evalValue[i].isAll,
          isRemovedEntity: data.evalValue[i].isRemovedEntity,
          value: data.evalValue[i].value
        }
        evalValueTemp.push(evalTemp)
      }
      this.selectedEvalValueTemp={
        entitySetId:null,
        evalValue: evalValueTemp,
        isAddedEntitySet:false,
        isRemovedEntitySet: false
      }
      this.isAIEdited = true;
      // this.selectedEvalValue = data;
      // this.selEvaluationId = this.evaluationIdList[index];
      this.$modal.show("editEntityEvaluationMultiRow");

    },
    hideEvalMOdal() {
      this.$modal.hide("entityEvaluation");
      this.isAIEdited = false;
    },
    hideEvalMOdal2() {
      this.$modal.hide("entityEvaluationMultiRow");
      this.isAIEdited = false;
    },
    hideEvalMOdalEditMultiRow() {
      this.$modal.hide("editEntityEvaluationMultiRow");
      this.isAIEdited = false;
    },
    setChatStatusBar() {
      if (this.assistantKey) this.chatMenu(null, 1);
    },
    compareObjects(a, b) {
      if (a.attributes.order < b.attributes.order) {
        return -1;
      }
      if (a.attributes.order > b.attributes.order) {
        return 1;
      }
      return 0;
    },
    createTestChat() {
      this.$modal.show("chatModal");
      this.testChatQuestion = "";
      this.testChatQuesData = [];
      this.currentDate = null;
      let fb = new FormData();
      fb.append("test_chat[chat_learn_obj_id]", this.cardAsstSchemeId);
      api
        .postTestChat(this.$route.query.id, fb)
        .then(res => {
          this.testChatData = res.data.data;
        })
        .catch(() => {
          utilFunctionService.showErr(commonConfig.appMessages.expiredTestChat);
        });
    },
    sendChatQuestionTest() {
      this.$modal.show("chatModal");
      let fb = new FormData();
      fb.append("test_chat_message[test_chat_id]", this.testChatData.id);
      fb.append("test_chat_message[message]", this.testChatQuestion);
      api
        .postTestQuestion(this.$route.query.id, fb)
        .then(res => {
          this.testChatQuestion = "";
          if (!this.currentDate) this.currentDate = new Date();
          let then = moment(
            res.data.data.attributes.test_chat_watson_response.updated_at
          );
          let now = moment(this.currentDate);
          this.testChatQuesData.push({
            test_message: res.data.data.attributes.message,
            assistant_response:
              res.data.data.attributes.test_chat_watson_response.message,
            test_time: moment.duration(now.diff(then, "minutes")).minutes() + 1,
            assistant_time:
              moment.duration(now.diff(then, "minutes")).minutes() + 1
          });
        })
        .catch(() => {
          utilFunctionService.showErr(commonConfig.appMessages.invalidQuestion);
        });
    },
    resetChatTest() {
      this.testChatQuestion = "";
      this.testChatQuesData = [];
    },
    getAiQuizEvaluation() {
      api.getQuizEvaluationId(this.$route.query.id).then(res => {
        let fb = new FormData();
        fb.append("quiz_evaluation_id", res.data.quiz_evaluation_id);
        fb.append(
          "quiz_evaluation[quiz_responses_attributes][][quiz_question_id]",
          this.selectQuestionToTest.quizId
        );
        fb.append(
          "quiz_evaluation[quiz_responses_attributes][][response]",
          this.testQuizAnswer
        );
        api.testQuizQuestion(fb).then(res => {
          if (res.data.data.attributes.quiz_responses[0].attributes.is_correct)
            this.isTestQuizAnswer = "yes";
          else this.isTestQuizAnswer = "no";
          this.aiWatsonResponse = res.data.data.attributes.quiz_responses;
        });
      });
    },
    getAiMultiRowQuizEvaluation(){
      api.getQuizEvaluationId(this.$route.query.id).then(res => {

        let fb = new FormData();
        fb.append("quiz_evaluation_id", res.data.quiz_evaluation_id);
        fb.append(
          "quiz_evaluation[quiz_responses_attributes][][quiz_question_id]",
          this.selectQuestionToTest.quizId
        );

        for (let i = 0; i<this.testMultiRowResponses.length; i++)
        {
          fb.append(
          `quiz_evaluation[quiz_responses_attributes][][text_row_responses_attributes][${i}][response]`,
          this.testMultiRowResponses[i].responseMultiRow
        );
        fb.append(
          `quiz_evaluation[quiz_responses_attributes][][text_row_responses_attributes][${i}][text_row_id]`,
          this.selectQuestionToTest.data.attributes.text_rows[i].id
        );
        }
        api.testQuizQuestion(fb).then(res => {

          if (res.data.data.attributes.quiz_responses[0].attributes.is_correct)
            this.isTestQuizAnswer = "yes";
          else this.isTestQuizAnswer = "no";
          this.aiWatsonResponse = res.data.data.attributes.quiz_responses[0].attributes.text_row_responses;
        });
      })

    }
  }
};
</script>
<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
  .note {
    background-color: $input-background-active;
    padding: 15px;
  }
  .email-body {
      .variations-tab {
        display: flex;
        li {
          @include font-medium;
          font-size: 13px;
          margin: 0 35px 15px 0;
        }
        a {
          &:hover {
            color: $primary-button-color;
          }
          &.active {
             color: $primary-button-color;
          }
        }
      }
  }
</style>
<style>


  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
.column-1 {
    width: 10%;
    padding: 1em;
    float: left;
}

.column-2 {
    width: 80%;
    padding: 1em;
    float: right;
}
.column-3 {
    width: 10%;
    padding: 1em;
    float: left;
}

.ai-row {
  display: flex;
  flex-wrap: wrap;
}

.ai-row:after {
    content: "";
    display: table;
    clear: both;
  }

.ai-rows-header {
  font-size: 12px;
  width: 100%;
  display:block;
}

.ai-rows-header input {
  width: 20px;
}

.float-right {
  float: right;
}

.ai-text-row {
  border-bottom: 1px solid #ccc;
  margin-bottom: 2em;
  width: 100%;
}

.ai-text-rows-container {
  padding-top: 2em;
}

.ai-text-rows-code {
  margin-bottom: 3em;
}

.evaluation-set-row {
  border-bottom: 1px dotted #ccc;
}

.border-bottom-solid {
  border-bottom: 1px solid #ccc;
  margin-bottom: 1em;
}

.custom-input-style {
  border:0;
  background-color: #f2f8f6;
  border-radius: 3px;
  display: inline;
  width: 40px !important;
  padding-left: 1em;
}
</style>
