var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "LearningModules" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "header-right" },
            [
              _c("form", { staticClass: "header-form" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchElm,
                        expression: "searchElm",
                      },
                      {
                        name: "debounce",
                        rawName: "v-debounce:700ms",
                        value: _vm.search,
                        expression: "search",
                        arg: "700ms",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      "aria-describedby": "emailHelp",
                      placeholder: "Search",
                    },
                    domProps: { value: _vm.searchElm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchElm = $event.target.value
                      },
                    },
                  }),
                  _c("em", { staticClass: "icon-search" }),
                ]),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn header-btn primary",
                  attrs: { to: "/create-elm" },
                },
                [_vm._v("Add a new ELM")]
              ),
              !this.$store.state.importElmStatus
                ? _c(
                    "div",
                    { staticClass: "ml-2 btn header-btn primary btn-type" },
                    [
                      _c("input", {
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.importElm($event)
                          },
                        },
                      }),
                      _vm._v(" Import ELM "),
                    ]
                  )
                : _vm._e(),
              _vm.showNotification()
                ? _c("div", { staticClass: "data-popper-dropdown" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      { staticClass: "dropdown-menu dropdown-menu-right" },
                      [
                        this.$store.state.cloningStatus === true
                          ? _c("div", [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    " Cloning progress of " +
                                      _vm._s(
                                        this.$store.state.cloningElm.attributes
                                          .name
                                      ) +
                                      ": "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "progress-bar-container" },
                                  [
                                    _c("progress-bar", {
                                      attrs: {
                                        options: _vm.options,
                                        value: this.$store.state.cloneProgress,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        this.$store.state.importElmStatus === true
                          ? _c("div", [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    " Import progress of " +
                                      _vm._s(this.$store.state.importingElm) +
                                      ": "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "progress-bar-container" },
                                  [
                                    _c("progress-bar", {
                                      attrs: {
                                        options: _vm.options,
                                        value:
                                          this.$store.state.elmImportProgress,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.totalElms > 1 && !_vm.searchElm
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalElms) +
                    " Learning Modules in the system."
                ),
              ])
            : _vm.totalElms <= 1 && !_vm.searchElm
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalElms) +
                    " Learning Module in the system."
                ),
              ])
            : _vm.totalElms > 1 && _vm.searchElm
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalElms) +
                    " Learning Modules in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalElms) +
                    " Learning Module in the search result."
                ),
              ]),
        ]),
        _vm.elms.length
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", { staticClass: "data-table data-column-9" }, [
                _c("div", { staticClass: "data-head" }, [
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Name "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "learn_mods.name" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("learn_mods.name", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "learn_mods.name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("learn_mods.name", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Created on "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v("Unique code"),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" LO "),
                    _c("span", { staticClass: "text-lowercase" }, [
                      _vm._v("s"),
                    ]),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "learning_objects_count" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "learning_objects_count",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "learning_objects_count" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "learning_objects_count",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Expected hours "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "time_to_complete" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("time_to_complete", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "time_to_complete" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("time_to_complete", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("ELM ID")]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Grading")]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
                ]),
                _c(
                  "div",
                  { staticClass: "data-items" },
                  _vm._l(_vm.elms, function (elm) {
                    return _c("div", { key: elm.id, staticClass: "data-row" }, [
                      _c("div", { staticClass: "data-col" }, [
                        _c("div", { staticClass: "data-image" }, [
                          _c(
                            "div",
                            { staticClass: "data-pic square" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: `/edit-elm/${elm.id}` } },
                                [
                                  elm.attributes.photo_url.thumbnail
                                    ? _c("img", {
                                        attrs: {
                                          src: elm.attributes.photo_url
                                            .thumbnail,
                                          alt: "image",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "data-text data-image-text" },
                            [
                              _c("div", { staticClass: "data-draft-head" }, [
                                _c("div", { staticClass: "data-draft-title" }, [
                                  _vm.isCloningElm(elm.id)
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(elm.attributes.name) +
                                            " "
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: `/edit-elm/${elm.id}`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(elm.attributes.name)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                ]),
                                elm.attributes.status === "drafted"
                                  ? _c("span", { staticClass: "data-tag" }, [
                                      _vm._v("Draft"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(elm.attributes.world_name)),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(elm.attributes.created_on)),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(elm.attributes.unique_code)),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _c("span", { staticClass: "elms" }, [
                          _vm._v(_vm._s(elm.attributes.learning_objects_count)),
                        ]),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(
                          _vm._s(elm.attributes.time_to_complete) + " hrs"
                        ),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(" " + _vm._s(elm.id) + " "),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(elm.attributes.enable_grading)),
                      ]),
                      _c("div", { staticClass: "data-col data-action" }, [
                        _c("div", { staticClass: "data-dropdown" }, [
                          _vm._m(2, true),
                          _vm.isCloningElm(elm.id)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "data-popper-title" },
                                    [
                                      _vm._v(
                                        " This ELM is currently being cloned and can be edited once cloning is complete. "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right",
                                },
                                [
                                  _c("ul", [
                                    _c(
                                      "li",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: `/assistant-details/${elm.id}`,
                                            },
                                          },
                                          [_vm._v("Watson Details")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "li",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: `/create-elm/${elm.id}`,
                                            },
                                          },
                                          [_vm._v("Edit Description")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "li",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: `/edit-elm/${elm.id}`,
                                            },
                                          },
                                          [_vm._v("Edit LOs")]
                                        ),
                                      ],
                                      1
                                    ),
                                    !_vm.$store.state.cloningStatus
                                      ? _c("li", [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.sendInfo(elm)
                                                  _vm.$modal.show(
                                                    "cloneConfirmationModal"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Clone ELM")]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.exportElm(elm)
                                            },
                                          },
                                        },
                                        [_vm._v("Export ELM")]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-error",
                                          on: {
                                            click: function ($event) {
                                              _vm.sendInfo(elm)
                                              _vm.$modal.show("deleteElmModal")
                                            },
                                          },
                                        },
                                        [_vm._v("Delete")]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.pages.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.pages.slice(_vm.page - 1, _vm.page + 3),
                          function (pages) {
                            return _c(
                              "li",
                              { key: pages, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    class: [pages === _vm.page ? "active" : ""],
                                    on: {
                                      click: function ($event) {
                                        return _vm.getElm(pages)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(pages))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.pages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.elms.length === 0 && !_vm.searchStatus && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no modules in the system yet.")]),
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn primary",
                      attrs: { to: "/create-elm" },
                    },
                    [_vm._v("Add a new ELM")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.elms.length === 0 && _vm.searchStatus
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteElmModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v(" Are you sure you want to delete this ELM? "),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("deleteElmModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.deleteElm(_vm.selectedElm.id),
                        _vm.$modal.hide("deleteElmModal")
                    },
                  },
                },
                [_vm._v("ok")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "cloneConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _vm._v(" Are you sure you want to clone this ELM? "),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.cloneElm(_vm.selectedElm.id),
                        _vm.$modal.hide("cloneConfirmationModal")
                    },
                  },
                },
                [_vm._v("Yes")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "elmImportConfirmationModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("This ELM already exists! Do you want to overwrite it?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("elmImportConfirmationModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.importElm(),
                        _vm.$modal.hide("elmImportConfirmationModal")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("All ELMs")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [
        _c("span", { staticClass: "clone-notification" }, [
          _c("em", { staticClass: "icon-bell" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-menu" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }