var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "QaEmail" } },
    [
      _c(
        "div",
        { staticClass: "container-3-column" },
        [
          _vm._m(0),
          _c("div", [
            _vm.emailLists.length > 0
              ? _c(
                  "div",
                  { attrs: { id: "QaMessageContainer" } },
                  _vm._l(_vm.emailLists, function (email, i) {
                    return _c(
                      "div",
                      { key: email.id, staticClass: "learn-object" },
                      [
                        _c("div", { staticClass: "learn-object-forward" }, [
                          _c("div", { staticClass: "learn-object-tag" }, [
                            _vm._v(
                              "Subject: " + _vm._s(_vm.filterEmailIndex[i].sub)
                            ),
                          ]),
                          _c("div", { staticClass: "learn-object-paragraph" }, [
                            _c("div", { staticClass: "learn-object-desc" }, [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.filterEmailIndex[i].msg
                                  ),
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "count-container" }, [
                            _c(
                              "div",
                              { staticClass: "learn-object-paragraph" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "learn-object-desc" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "Iteration: " +
                                          _vm._s(
                                            _vm.filterEmailIndex[i].iteration
                                          )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "QA condition hit ID(s): " +
                                          _vm._s(
                                            _vm.emailIterationDetails
                                              .qa_condition_hits
                                          )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "Date Sent: " +
                                          _vm._s(
                                            _vm.emailIterationDetails.date_sent
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "drop-container" },
                              [
                                _c(
                                  "drop",
                                  {
                                    staticClass: "drop",
                                    on: { drop: _vm.handleEntentDrop },
                                  },
                                  [
                                    _c("label", { staticClass: "title-text" }, [
                                      _vm._v("Edit Entities:"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "evaluation-dragbox" },
                                      [
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedEntentValues.length ==
                                                0
                                                ? "Drag and Drop entities values here"
                                                : ""
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "evaluation-chips-container",
                                          },
                                          _vm._l(
                                            _vm.selectedEntentValues,
                                            function (entent, key) {
                                              return _c(
                                                "div",
                                                {
                                                  key: key,
                                                  staticClass:
                                                    "evaluation-chips",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        entent.ententName
                                                      ) +
                                                        " : " +
                                                        _vm._s(
                                                          entent.ententValueName
                                                        )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:void(0);",
                                                      },
                                                    },
                                                    [
                                                      _c("em", {
                                                        staticClass:
                                                          "icon-close",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeEntentValues(
                                                              key
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "drop",
                                  {
                                    staticClass: "drop",
                                    on: { drop: _vm.handleIntentDrop },
                                  },
                                  [
                                    _c("label", { staticClass: "title-text" }, [
                                      _vm._v("Edit Intents:"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "evaluation-dragbox" },
                                      [
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedIntentValues.length ==
                                                0
                                                ? "Drag and Drop intent here"
                                                : ""
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "evaluation-chips-container",
                                          },
                                          _vm._l(
                                            _vm.selectedIntentValues,
                                            function (intent, key) {
                                              return _c(
                                                "div",
                                                {
                                                  key: key,
                                                  staticClass:
                                                    "evaluation-chips",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(intent.intentName)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:void(0);",
                                                      },
                                                    },
                                                    [
                                                      _c("em", {
                                                        staticClass:
                                                          "icon-close",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeIntentValues(
                                                              key
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.filterEmailIndex[i].arr[0].attributes
                                  .qa_fixed !== true
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "generat-response-button",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn primary mr-2",
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.genearteIterationResponse()
                                              },
                                            },
                                          },
                                          [_vm._v("Generate Response")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._l(_vm.filterEmailIndex[i].arr, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "learn-object-editable",
                            },
                            [
                              _c("div", { staticClass: "learn-reply-head" }, [
                                _c("span", [
                                  _vm._v(
                                    "Reply from " +
                                      _vm._s(
                                        item.attributes.character.attributes
                                          .char_full_name
                                      )
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "learn-object-tag" }, [
                                _vm._v(
                                  "Reply: " +
                                    _vm._s(email.attributes.email_subject)
                                ),
                              ]),
                              item.attributes.qa_fixed === false
                                ? _c(
                                    "div",
                                    { staticClass: "learn-object-editor" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("vue-editor", {
                                            staticClass: "form-control",
                                            attrs: {
                                              editorToolbar: _vm.fullToolbar,
                                            },
                                            model: {
                                              value: item.attributes.response,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item.attributes,
                                                  "response",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.attributes.response",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "learn-object-send" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn primary mr-2",
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveResponse(
                                                    item.id,
                                                    item.attributes.response
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Save")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn primary",
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.setActiveResponse(item)
                                                  _vm.$modal.show(
                                                    "submitResponseConfirmation"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Mark Resolved")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "learn-object-paragraph" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "learn-object-desc" },
                                          [
                                            _c("p", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.attributes.response
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    item.attributes.qa_fixed === true
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "learn-object-paragraph",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "learn-object-desc my-4 details",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn primary my-3",
                                                    attrs: {
                                                      href: "javascript:void(0);",
                                                    },
                                                  },
                                                  [_vm._v("Resolved")]
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    "Resolved Date: " +
                                                      _vm._s(
                                                        item.attributes
                                                          .qa_resolved_date
                                                      )
                                                  ),
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    "Resolved By: " +
                                                      _vm._s(
                                                        item.attributes.qa_user
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _c("EmailQaSidebar", {
            ref: "sideBar",
            attrs: { iterationDetails: _vm.emailIterationDetails },
          }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "submitResponseConfirmation",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v(
                " You won't be able to edit further and the response will be visible to learner once you mark resolved. Are you sure? "
              ),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("submitResponseConfirmation")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.updateResponseAndMarkResolved(),
                        _vm.$modal.hide("submitResponseConfirmation")
                    },
                  },
                },
                [_vm._v("Ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "header pr-0 flex-nowrap" }, [
      _c("div", { staticClass: "header-left" }, [
        _c("h2", [_vm._v("QA Email")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }