<template>
  <modal
    name="editCharacter"
    :width="430"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title" id="exampleModalLabel">
          <h5>Edit Character</h5>
        </div>
        <button type="button" class="close" @click="hide('editCharacter')">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <ValidationObserver ref="form">
          <form class="modal-form organisation-form">
            <div class="organisation-image-group">
              <!-- <div class="organisation-image-pic">
                 <input
                  ref="fileInput"
                  type="file"
                  @change="onFileSelected"
                  accept="image/*"
                />
                <img :src="image" v-if="image" alt="image1" />
              </div> -->
              <div class="organisation-charcter-pic cursor"  @click="$refs.fileInput.click()">
                <img :src="image" v-if="image" alt="images"/>
              </div>
              <div class="organisation-image-remove">
                <input
                  style="display: none;"
                  ref="fileInput"
                  type="file"
                  @change="onFileSelected"
                  accept="image/*"
                />
                <a href="#" class="btn add" @click="$refs.fileInput.click()" v-if="!image">Add Photo</a>
                <a href="#" class="btn delete" @click="uploadImageRemoved" v-else>Remove Photo</a>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="first_name">First Name</label>
              <ValidationProvider name="First Name" rules="required|max:100" v-slot="{ errors }">
                <input
                  type="first_name"
                  class="form-control"
                  id="first_name"
                  aria-describedby="emailHelp"
                  v-model="first_name"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label" for="last_name">Last Name</label>
              <ValidationProvider name="Last Name" rules="required|max:100" v-slot="{ errors }">
                <input
                  type="last_name"
                  class="form-control"
                  id="last_name"
                  aria-describedby="emailHelp"
                  v-model="last_name"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
            <div class="row mb-4">
              <div class="form-group col">
                <label class="form-label" for="exampleInputEmail1">Gender</label>
                <div class="form-gender-dropdown">
                  <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{gender | uppercase(true)}}
                    <em class="icon-carrot-down"></em>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" v-for="gen in gender_list" :key="gen.id" @click="setGender(gen.attributes.name, gen.id)">{{gen.attributes.name | uppercase(true)}}</a>
                  </div>
                </div>
              </div>
              <div class="form-group col">
                <label class="form-label" for="Age">Age</label>
                <ValidationProvider name="Age" rules="required|numeric" v-slot="{ errors }">
                  <input
                    type="age"
                    class="form-control"
                    id="age"
                    aria-describedby="emailHelp"
                    v-model.number="age"
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn default"
                data-dismiss="modal"
                @click="hide('editCharacter')"
              >Cancel</button>
              <button
                type="button"
                class="btn primary"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#addOrganisation"
                v-on:click="updateCharacter"
              >Save</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "editCharacter",
  props: {
    method: { type: Function }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      selectedFile: null,
      image: null,
      gender: "male",
      gender_id : '',
      first_name: "",
      last_name: "",
      age: "",
      name: "",
      description: "",
      url: utilFunctionService.getImageUrl(),
      character_id: "",
      isActive: false,
      page: 1,
      gender_list : []
    };
  },
  filters: {
    uppercase: function(value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  methods: {
    beforeOpen(event) {
      console.log(event, "EditCharacter beforeOpen event");
      this.genderList();
      this.character_id = event.params.character_id;
      this.getCharactersDetails(this.character_id);
    },
    setGender(gender,gender_id) {
      this.gender = gender;
      this.gender_id = gender_id;
    },
    hide(modal) {
      this.clearFormData();
      this.$parent.getCharacters(this.page);
      this.$parent.hide(modal);
    },
    uploadImageRemoved() {
      api.removeCharactersPhoto(this.character_id);
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
      this.image = null;
      this.selectedFile = null;
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.image = e.target.result;
      };
    },
    updateCharacter() {
      this.$refs.form
        .validate()
        .then(success => {
          this.isActive = true;
          if (!success) {
            return;
          } else {
            this.isActive = false;
            utilFunctionService.showLoader();
            const fb = new FormData();
            if (this.selectedFile)
              fb.append("character[photo]", this.selectedFile);
            fb.append("character[gender_id]", this.gender_id);
            fb.append("character[first_name]", this.first_name);
            fb.append("character[last_name]", this.last_name);
            fb.append("character[age]", this.age);
            fb.append("character[real_world]", true);
            api
              .updateCharacter(this.character_id, fb)
              .then(() => {
                utilFunctionService.hideLoader();
                this.clearFormData();
                utilFunctionService.showSuccess(commonConfig.appMessages.editCharacter);
                this.$parent.getCharacters(this.page);
                this.$parent.hide("editCharacter");
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                this.clearFormData();
                utilFunctionService.showSuccess(commonConfig.appMessages.error);
                this.$parent.hide("editCharacter");
              });
          }
        })
        .catch();
    },
    getCharactersDetails(character_id) {
      api
        .getCharactersDetails(character_id)
        .then(res => {
          this.first_name = res.data.data.attributes.first_name;
          this.last_name = res.data.data.attributes.last_name;
          this.gender = res.data.data.attributes.gender;
          this.gender_id = res.data.data.attributes.gender_id;
          this.image = res.data.data.attributes.photo_url.large_version
            ? res.data.data.attributes.photo_url.large_version
            : "";
          this.created_on = res.data.data.attributes.created_on;
          this.age = parseInt(res.data.data.attributes.age.replace("yrs", ""));
        })
        .catch();
    },
    genderList() {
      api
        .genderList()
        .then(res => {
          this.gender_list = res.data.data;
        })
        .catch();
    },
    clearFormData () {
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
      this.image = null;
      this.selectedFile = null;
      this.image = null;
      this.gender = "male";
      this.first_name = "";
      this.last_name = "";
      this.age = "";
    }
  }
};
</script>
