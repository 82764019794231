var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-dropdown" }, [
    _c("div", {}, [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.logOut()
                },
              },
            },
            [_vm._v("Log out")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }