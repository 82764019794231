var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "CreateELM" } },
    [
      _c(
        "div",
        { staticClass: "create-elm-container" },
        [
          _c(
            "router-link",
            { staticClass: "elm-back-btn", attrs: { to: "/learning-modules" } },
            [_c("span", { staticClass: "icon-back" })]
          ),
          _c("div", { staticClass: "elm-header" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn-link mr-5",
                    attrs: { to: "/learning-modules" },
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn header-btn primary big",
                    on: { click: _vm.createElm },
                  },
                  [_vm._v(" Save & Add Learning Objects ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "elm-form-container" }, [
            _c("div", { staticClass: "image-container" }, [
              !_vm.image
                ? _c("div", { staticClass: "image-container-content" }, [
                    _c("input", {
                      ref: "fileInput",
                      staticClass: "custom-file-input",
                      attrs: {
                        type: "file",
                        accept: "image/png, image/jpeg, image/gif",
                      },
                      on: { change: _vm.onFileSelected },
                    }),
                    _vm._m(1),
                  ])
                : _vm._e(),
              _vm.image
                ? _c("div", [
                    _c("div", { staticClass: "image-container-content" }, [
                      _c("img", { attrs: { src: _vm.image, alt: "image" } }),
                    ]),
                    _c("div", { staticClass: "image-delete" }, [
                      _c("span", { staticClass: "icon-delete" }),
                      _c(
                        "a",
                        {
                          staticClass: "btn delete",
                          on: { click: _vm.uploadImageRemoved },
                        },
                        [_vm._v("Remove Photo")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "right-side-container" },
              [
                _c(
                  "div",
                  { staticClass: "form-section" },
                  [
                    _c("ValidationObserver", { ref: "form1" }, [
                      _c("form", { staticClass: "w-100" }, [
                        _c("div", { staticClass: "form-section-header" }, [
                          _c("h4", [_vm._v("Basic Details")]),
                          _c("div", { staticClass: "form-section-right" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn dropdown-toggle",
                                class: [_vm.note ? "active" : ""],
                                attrs: {
                                  id: "menu1",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "imgGray",
                                  attrs: {
                                    src: require("../assets/images/icon-yellownote.svg"),
                                    alt: "yellow",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu data-popover-dropdown dropdown-menu-right",
                                attrs: { "aria-labelledby": "menu1" },
                              },
                              [
                                _c("div", { staticClass: "data-popover" }, [
                                  _c(
                                    "div",
                                    { staticClass: "data-popover-title" },
                                    [
                                      _c("span", [_vm._v("Note for admin")]),
                                      _c(
                                        "a",
                                        { staticClass: "data-popover-close" },
                                        [
                                          _c("em", {
                                            staticClass: "icon-close",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "form",
                                    { staticClass: "data-popover-form" },
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.note,
                                              expression: "note",
                                            },
                                          ],
                                          staticClass:
                                            "form-control small-textarea-height",
                                          attrs: { rows: "4" },
                                          domProps: { value: _vm.note },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.note = $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "form-group-button" },
                                        [
                                          _vm.note
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "btn btn-remove",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeNote()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Remove Note")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a",
                                            { staticClass: "btn btn-save" },
                                            [_vm._v("Save")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "row custom-row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [_vm._v("ELM Title")]),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "ELM title",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "text",
                                              id: "name",
                                              maxlength: "100",
                                              placeholder: "Add a Title",
                                            },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Assign World")]),
                              _c(
                                "div",
                                { staticClass: "elm-dropdown" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Assign world",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.searchWorld,
                                                  expression: "searchWorld",
                                                },
                                                {
                                                  name: "debounce",
                                                  rawName: "v-debounce:700ms",
                                                  value: _vm.searchWorlds,
                                                  expression: "searchWorlds",
                                                  arg: "700ms",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              class: [
                                                _vm.isActive && errors[0]
                                                  ? "invalid"
                                                  : "",
                                              ],
                                              attrs: {
                                                type: "text",
                                                placeholder: "Type here",
                                              },
                                              domProps: {
                                                value: _vm.searchWorld,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.searchWorld =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            errors[0]
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        errors[0].replace(
                                                          "The ",
                                                          ""
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm.worldLists.length
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            class: [
                                              _vm.worldLists.length !== 0
                                                ? "show"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "dropdown-list" },
                                              _vm._l(
                                                _vm.worldLists,
                                                function (world) {
                                                  return _c(
                                                    "li",
                                                    { key: world.id },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "elm-assign-item",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "elm-assign-text",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.assignWorld(
                                                                      world.id,
                                                                      world
                                                                        .attributes
                                                                        .name
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                world.attributes
                                                                  .is_private
                                                                  ? _c("h3", [
                                                                      _vm._v(
                                                                        " Private "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                !world
                                                                  .attributes
                                                                  .is_private
                                                                  ? _c("h3", [
                                                                      _vm._v(
                                                                        " Public "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]),
                                                              _c("h4", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      world
                                                                        .attributes
                                                                        .name
                                                                    ) +
                                                                    " "
                                                                ),
                                                                world.attributes
                                                                  .world_organizations
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "elms",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              world
                                                                                .attributes
                                                                                .world_organizations
                                                                                .length
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "add-action",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.assignWorld(
                                                                          world.id,
                                                                          world
                                                                            .attributes
                                                                            .name
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("+")]
                                                              ),
                                                            ]
                                                          ),
                                                          world.attributes
                                                            .description
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getDesc(
                                                                        world
                                                                          .attributes
                                                                          .description
                                                                      )
                                                                    ) +
                                                                    " ... "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [
                                  _vm._v("Add Learner Organization"),
                                ]),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Learner organization",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.searchOrg,
                                                expression: "searchOrg",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: "Type here",
                                            },
                                            domProps: { value: _vm.searchOrg },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.searchOrg =
                                                    $event.target.value
                                                },
                                                _vm.getOrg,
                                              ],
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm.searchOrganization.length
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          class: [
                                            _vm.searchOrganization.length !== 0
                                              ? "show"
                                              : "",
                                          ],
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "dropdown-list" },
                                            _vm._l(
                                              _vm.searchOrganization,
                                              function (org) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: org.id,
                                                    staticClass:
                                                      "dropdown-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-content",
                                                      },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.assignOrg(
                                                                  org
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(org.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-action",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "add-action",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.assignOrg(
                                                                  org
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("+")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Assign a Role")]),
                              _c(
                                "div",
                                { staticClass: "elm-dropdown" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Learner role",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.searchRole,
                                                  expression: "searchRole",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              class: [
                                                _vm.isActive && errors[0]
                                                  ? "invalid"
                                                  : "",
                                              ],
                                              attrs: {
                                                type: "text",
                                                placeholder: "Add Learner Role",
                                              },
                                              domProps: {
                                                value: _vm.searchRole,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.searchRole =
                                                      $event.target.value
                                                  },
                                                  _vm.getRole,
                                                ],
                                              },
                                            }),
                                            errors[0]
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      _vm.isActive
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    style: [
                                                      _vm.isActive
                                                        ? { display: "block" }
                                                        : { display: "none" },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        errors[0].replace(
                                                          "The ",
                                                          ""
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm.roles.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          class: [
                                            _vm.roles.length !== 0
                                              ? "show"
                                              : "",
                                          ],
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "dropdown-list" },
                                            _vm._l(_vm.roles, function (role) {
                                              return _c(
                                                "li",
                                                {
                                                  key: role.id,
                                                  staticClass: "dropdown-items",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-content",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setRole(
                                                                role.id,
                                                                role.attributes
                                                                  .name
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                role.attributes
                                                                  .name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-action",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-action",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "add-action",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setRole(
                                                                      role.id,
                                                                      role
                                                                        .attributes
                                                                        .name
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("+")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [_vm._v("Lead Designer")]),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Lead designer",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.searchDesigner,
                                                expression: "searchDesigner",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: "Type here",
                                            },
                                            domProps: {
                                              value: _vm.searchDesigner,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.searchDesigner =
                                                    $event.target.value
                                                },
                                                _vm.getDesigner,
                                              ],
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm.designerLists.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        class: [
                                          _vm.designerLists.length !== 0
                                            ? "show"
                                            : "",
                                        ],
                                      },
                                      [
                                        _c(
                                          "ul",
                                          { staticClass: "dropdown-list" },
                                          _vm._l(
                                            _vm.designerLists,
                                            function (designer) {
                                              return _c(
                                                "li",
                                                {
                                                  key: designer.id,
                                                  staticClass: "dropdown-items",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-content",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setDesigner(
                                                                designer.id,
                                                                designer
                                                                  .attributes
                                                                  .first_name
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                designer
                                                                  .attributes
                                                                  .first_name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-action",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-action",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "add-action",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setDesigner(
                                                                      designer.id,
                                                                      designer
                                                                        .attributes
                                                                        .first_name
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("+")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [
                                  _vm._v("Assign Subject Matter Expert(SME)"),
                                ]),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Assign subject matter expert",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.searchSme,
                                                expression: "searchSme",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: [
                                              _vm.isActive && errors[0]
                                                ? "invalid"
                                                : "",
                                            ],
                                            attrs: {
                                              type: "text",
                                              placeholder: "Type here",
                                            },
                                            domProps: { value: _vm.searchSme },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.searchSme =
                                                    $event.target.value
                                                },
                                                _vm.getSme,
                                              ],
                                            },
                                          }),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  class: [
                                                    _vm.isActive
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  style: [
                                                    _vm.isActive
                                                      ? { display: "block" }
                                                      : { display: "none" },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0].replace(
                                                        "The ",
                                                        ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm.smeLists.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        class: [
                                          _vm.smeLists.length !== 0
                                            ? "show"
                                            : "",
                                        ],
                                      },
                                      [
                                        _vm.smeLists.length
                                          ? _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "dropdown-list mt-3",
                                              },
                                              _vm._l(
                                                _vm.smeLists,
                                                function (sme) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: sme.id,
                                                      staticClass:
                                                        "dropdown-items",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-content",
                                                        },
                                                        [
                                                          _c(
                                                            "h4",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setSme(
                                                                      sme.id,
                                                                      sme
                                                                        .attributes
                                                                        .first_name
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    sme
                                                                      .attributes
                                                                      .first_name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-action",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "add-action",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setSme(
                                                                          sme.id,
                                                                          sme
                                                                            .attributes
                                                                            .first_name
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("+")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v("Expected Hours to Complete"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.timeToComplete,
                                    expression: "timeToComplete",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  onkeydown:
                                    "javascript: return event.keyCode == 69 ? false : true",
                                  placeholder: "Add Expected Hours to Complete",
                                },
                                domProps: { value: _vm.timeToComplete },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.timeToComplete = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Description")]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.description,
                                    expression: "description",
                                  },
                                ],
                                staticClass: "form-control textarea-height",
                                attrs: {
                                  type: "text",
                                  placeholder: "Add a Description",
                                },
                                domProps: { value: _vm.description },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.description = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [_vm._v("Final debrief overview")]),
                                _c("vue-editor", {
                                  attrs: { editorToolbar: _vm.fullToolbar },
                                  model: {
                                    value: _vm.final_debrief_overview,
                                    callback: function ($$v) {
                                      _vm.final_debrief_overview = $$v
                                    },
                                    expression: "final_debrief_overview",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Learning Objectives")]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.learningObjectives,
                                    expression: "learningObjectives",
                                  },
                                ],
                                staticClass:
                                  "form-control small-textarea-height",
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    "Add Comma Separated Learning Objectives",
                                },
                                domProps: { value: _vm.learningObjectives },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.learningObjectives = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group multi-select" },
                              [
                                _c("label", [_vm._v("Skills")]),
                                _c(
                                  "div",
                                  { staticClass: "chip-container" },
                                  [
                                    _vm._l(_vm.chips, function (chip, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: chip.label,
                                          staticClass: "chip",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(chip.attributes.name)
                                            ),
                                          ]),
                                          _c("em", {
                                            staticClass: "icon-close",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteChip(i)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.skill,
                                          expression: "skill",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: _vm.skillsPlaceholder,
                                      },
                                      domProps: { value: _vm.skill },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.skill = $event.target.value
                                          },
                                          _vm.searchSkill,
                                        ],
                                      },
                                    }),
                                    _vm.updatedData.length
                                      ? _c(
                                          "div",
                                          { staticClass: "dropdown-list mt-3" },
                                          _vm._l(
                                            _vm.updatedData,
                                            function (skill) {
                                              return _c(
                                                "div",
                                                {
                                                  key: skill.id,
                                                  staticClass: "dropdown-items",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-content",
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(
                                                          _vm._s(
                                                            skill.attributes
                                                              .name
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-action",
                                                    },
                                                    [
                                                      !skill.status
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "add-action",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addChip(
                                                                          skill
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("+")]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      skill.status
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c("em", {
                                                                staticClass:
                                                                  "icon-check",
                                                              }),
                                                              _c("span", [
                                                                _vm._v("Added"),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Assessment Code")]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.assessment_code,
                                    expression: "assessment_code",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  maxlength: "100",
                                  placeholder: "Add assessment code",
                                },
                                domProps: { value: _vm.assessment_code },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.assessment_code = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm.image
                            ? _c("div", { staticClass: "col-sm-12" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", [_vm._v("Cover image caption")]),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coverImageCaption,
                                        expression: "coverImageCaption",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "Descriptive text of the cover image",
                                    },
                                    domProps: { value: _vm.coverImageCaption },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.coverImageCaption =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm.uploadPercent > 0
                  ? _c("div", { staticClass: "progress-bar-overlay" }, [
                      _c(
                        "div",
                        { staticClass: "progress-bar-container" },
                        [
                          _vm.uploadPercent > 0
                            ? _c("progress-bar", {
                                attrs: {
                                  options: _vm.options,
                                  value: _vm.getPercentage,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-section" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "learning-form-header d-flex align-items-start",
                    },
                    [
                      _vm._m(2),
                      _c("input", {
                        ref: "fileInput1",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          multiple: "multiple",
                          accept: ".pdf, .doc",
                        },
                        on: { change: _vm.onFileSelect },
                      }),
                      _c("div", { staticClass: "ml-auto text-right" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn ml-2 mb-2 secondary medium",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput1.click()
                              },
                            },
                          },
                          [_vm._v("Upload a Document")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn ml-2 mb-2 secondary medium",
                            on: {
                              click: function ($event) {
                                return _vm.selectDoc()
                              },
                            },
                          },
                          [_vm._v("Select from Library")]
                        ),
                      ]),
                    ]
                  ),
                  _c("p", [
                    _vm._v(
                      "Add documents for the learner about the learning module."
                    ),
                  ]),
                  _vm.docs.length
                    ? _c(
                        "div",
                        { staticClass: "learning-data-list" },
                        _vm._l(_vm.docs, function (doc, i) {
                          return _c(
                            "div",
                            { key: doc.id, staticClass: "learning-data-item" },
                            [
                              doc.attributes.file_name.includes("pdf")
                                ? _c(
                                    "div",
                                    { staticClass: "learning-data-icon" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.$modal.show(
                                                "pdfModal",
                                                {
                                                  pdfUrl:
                                                    doc.attributes.resource_url,
                                                }
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("../assets/images/pdf-big-icon.png"),
                                              alt: "pdf",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              doc.attributes.file_name.includes("doc")
                                ? _c(
                                    "div",
                                    { staticClass: "learning-data-icon" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../assets/images/ddc-big-icon.png"),
                                          alt: "doc",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "learning-data-title" },
                                [
                                  _c("h4", [
                                    _vm._v(_vm._s(doc.attributes.file_name)),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.size(doc.attributes.resource_size)
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeDoc(i, doc.id)
                                        },
                                      },
                                    },
                                    [_vm._v("Remove")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c(
                  "modal",
                  {
                    attrs: {
                      name: "uploadDocument",
                      height: "auto",
                      width: 800,
                      scrollable: true,
                      clickToClose: false,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content modal-pdf-content" },
                      [
                        _c("div", { staticClass: "modal-header" }, [
                          _c(
                            "div",
                            {
                              staticClass: "modal-title",
                              attrs: { id: "exampleModalLabel" },
                            },
                            [_c("h5", [_vm._v("Upload a Document")])]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-body modal-image-body" },
                          [
                            _vm.uploadGalleryStatus
                              ? _c("form", { staticClass: "header-form" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.searchDoc,
                                          expression: "searchDoc",
                                        },
                                        {
                                          name: "debounce",
                                          rawName: "v-debounce:700ms",
                                          value: _vm.searchDocument,
                                          expression: "searchDocument",
                                          arg: "700ms",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "exampleInputEmail1",
                                        "aria-describedby": "emailHelp",
                                        placeholder: "Search",
                                      },
                                      domProps: { value: _vm.searchDoc },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.searchDoc = $event.target.value
                                        },
                                      },
                                    }),
                                    _c("em", { staticClass: "icon-search" }),
                                  ]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "modal-images-content" },
                              [
                                !_vm.uploadGalleryStatus
                                  ? _c(
                                      "div",
                                      { staticClass: "modal-images-upload" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "modal-images-placeholder",
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " Drag and drop your file here or "
                                              ),
                                              _c("span", [
                                                _c("input", {
                                                  staticClass: "upload",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.fileInput.click()
                                                    },
                                                  },
                                                }),
                                                _vm._v("Browse "),
                                              ]),
                                              _vm._v(" for a file. "),
                                            ]),
                                            _vm._v(" video "),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.uploadGalleryStatus
                                  ? _c(
                                      "v-infinite-scroll",
                                      {
                                        staticClass: "modal-content-scroll",
                                        attrs: { offset: 20 },
                                        on: { bottom: _vm.nextPage },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "modal-images-library",
                                          },
                                          [
                                            _vm.docLists.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modal-images-row",
                                                  },
                                                  _vm._l(
                                                    _vm.docLists,
                                                    function (doc) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: doc.id,
                                                          staticClass:
                                                            "modal-images-col",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "modal-images-checkbox",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "custom-checkbox",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            doc
                                                                              .attributes
                                                                              .status,
                                                                          expression:
                                                                            "doc.attributes.status",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                      name: "image",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          doc
                                                                            .attributes
                                                                            .status
                                                                        )
                                                                          ? _vm._i(
                                                                              doc
                                                                                .attributes
                                                                                .status,
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : doc
                                                                              .attributes
                                                                              .status,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              doc
                                                                                .attributes
                                                                                .status,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  doc.attributes,
                                                                                  "status",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  doc.attributes,
                                                                                  "status",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              doc.attributes,
                                                                              "status",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "checkmark circle",
                                                                  }),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "modal-images-box",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "modal-pdf-icon",
                                                                          attrs:
                                                                            {
                                                                              alt: "pdf",
                                                                              src: require("../assets/images/pdf-big-icon.png"),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "modal-images-info",
                                                                    },
                                                                    [
                                                                      _c("h4", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            doc
                                                                              .attributes
                                                                              .file_name
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      doc
                                                                        .attributes
                                                                        .tag_list
                                                                        ? _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    doc
                                                                                      .attributes
                                                                                      .tag_list
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "checked",
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.docLists.length === 0
                                  ? _c(
                                      "div",
                                      { staticClass: "data-no-result" },
                                      [
                                        _c("h3", [_vm._v("No results found")]),
                                        _c("p", [
                                          _vm._v(
                                            "Try using a different keyword"
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn default",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelDocGallery()
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _vm.docLists.length > 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveDoc()
                                    },
                                  },
                                },
                                [_vm._v(" Save ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "form-section" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "learning-form-header d-flex align-items-start",
                    },
                    [
                      _vm._m(3),
                      _c("div", { staticClass: "ml-auto text-right" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn ml-2 mb-2 secondary medium",
                            on: {
                              click: function ($event) {
                                return _vm.addVideo("uploadVideo")
                              },
                            },
                          },
                          [_vm._v("Add a new video")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn ml-2 mb-2 secondary medium",
                            on: {
                              click: function ($event) {
                                return _vm.addVideoFromGallery("uploadVideo")
                              },
                            },
                          },
                          [_vm._v("Select from Library")]
                        ),
                      ]),
                    ]
                  ),
                  _c("p", [
                    _vm._v(
                      "Add an introductory video for the learning module."
                    ),
                  ]),
                  _vm.video.attributes
                    ? _c("div", { staticClass: "learning-data-list" }, [
                        _c("div", { staticClass: "learning-data-item w-100" }, [
                          _c("div", { staticClass: "learning-data-video" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "wistia_responsive_padding data-video",
                                staticStyle: {
                                  padding: "56.25% 0 0 0",
                                  position: "relative",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "wistia_responsive_wrapper data-video",
                                    staticStyle: {
                                      height: "100%",
                                      left: "0",
                                      position: "absolute",
                                      top: "0",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c("iframe", {
                                      staticClass: "wistia_embed",
                                      attrs: {
                                        src:
                                          _vm.preIframUrl +
                                          _vm.video.attributes.wistia_code +
                                          _vm.postIframUrl,
                                        title: "Video.mp4",
                                        name: "wistia_embed",
                                        allowtransparency: "true",
                                        scrolling: "no",
                                        allowfullscreen: "",
                                        mozallowfullscreen: "",
                                        webkitallowfullscreen: "",
                                        oallowfullscreen: "",
                                        msallowfullscreen: "",
                                        width: "100%",
                                        height: "100%",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "learning-data-title" }, [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.video.attributes.title)),
                            ]),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeVideo()
                                  },
                                },
                              },
                              [_vm._v("Remove")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "form-section" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "learning-form-header d-flex align-items-start",
                    },
                    [
                      _vm._m(4),
                      _c("input", {
                        ref: "fileInput2",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          multiple: "multiple",
                          accept: "image/*",
                        },
                        on: { change: _vm.onBrandImageSelect },
                      }),
                      _c("div", { staticClass: "ml-auto text-right" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn ml-2 mb-2 secondary medium",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput2.click()
                              },
                            },
                          },
                          [_vm._v("Upload Image(s)")]
                        ),
                      ]),
                    ]
                  ),
                  _c("p", [
                    _vm._v("Add brand images for the learner application."),
                  ]),
                  _vm.brandImages.length && _vm.brandImagePreviews.length === 0
                    ? _c(
                        "div",
                        { staticClass: "learning-data-list" },
                        _vm._l(_vm.brandImages, function (bImage, index) {
                          return _c(
                            "div",
                            {
                              key: bImage.id,
                              staticClass:
                                "learning-data-item brand-image-item",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: bImage.large_version,
                                  alt: "bimage",
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn delete-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeBrandImage(index)
                                    },
                                  },
                                },
                                [_vm._v(" Delete Brand Image ")]
                              ),
                              bImage
                                ? _c("div", {
                                    staticClass: "learning-data-title",
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.brandImagePreviews.length > 0
                    ? _c(
                        "div",
                        { staticClass: "learning-data-list" },
                        _vm._l(_vm.brandImagePreviews, function (bImage) {
                          return _c(
                            "div",
                            {
                              key: bImage.id,
                              staticClass: "learning-data-item",
                            },
                            [
                              _c("img", {
                                attrs: { src: bImage, alt: "bimage" },
                              }),
                              _c("button", { staticClass: "btn delete-img" }, [
                                _vm._v("Delete Brand Image"),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "form-section" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "learning-form-header d-flex align-items-start",
                    },
                    [
                      _vm._m(5),
                      _c("div", { staticClass: "ml-auto text-right" }, [
                        _c("label", { staticClass: "switch-grading ml-auto" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.elmGradingActivate,
                                expression: "elmGradingActivate",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.elmGradingActivate)
                                ? _vm._i(_vm.elmGradingActivate, null) > -1
                                : _vm.elmGradingActivate,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.elmGradingActivate,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.elmGradingActivate = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.elmGradingActivate = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.elmGradingActivate = $$c
                                }
                              },
                            },
                          }),
                          _c("span", { staticClass: "slider-grading round" }),
                        ]),
                      ]),
                    ]
                  ),
                  _c("p", [_vm._v("Configure ELM grading.")]),
                  _vm.elmGradingActivate
                    ? _c("div", { staticClass: "row custom-row" }, [
                        _c("div", { staticClass: "col-sm-7" }, [
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "custom-checkbox left checkbox-grading",
                                attrs: { name: "quizAssessment" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.assessmentCheck,
                                      expression: "assessmentCheck",
                                    },
                                  ],
                                  attrs: {
                                    disabled: !_vm.elmGradingActivate,
                                    type: "checkbox",
                                    name: "quizAssessment",
                                  },
                                  domProps: {
                                    value: "quizAssessment",
                                    checked: Array.isArray(_vm.assessmentCheck)
                                      ? _vm._i(
                                          _vm.assessmentCheck,
                                          "quizAssessment"
                                        ) > -1
                                      : _vm.assessmentCheck,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.assessmentCheck,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "quizAssessment",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.assessmentCheck = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.assessmentCheck = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.assessmentCheck = $$c
                                      }
                                    },
                                  },
                                }),
                                _c("span", {
                                  staticClass: "checkmark",
                                  attrs: { for: "quizAssessment" },
                                }),
                                _vm._v(" Assessment "),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "custom-checkbox left checkbox-grading",
                                attrs: { name: "quizAssessment" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.completionCheck,
                                      expression: "completionCheck",
                                    },
                                  ],
                                  attrs: {
                                    disabled: !_vm.elmGradingActivate,
                                    type: "checkbox",
                                    name: "quizAssessment",
                                  },
                                  domProps: {
                                    value: "quizAssessment",
                                    checked: Array.isArray(_vm.completionCheck)
                                      ? _vm._i(
                                          _vm.completionCheck,
                                          "quizAssessment"
                                        ) > -1
                                      : _vm.completionCheck,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.completionCheck,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "quizAssessment",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.completionCheck = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.completionCheck = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.completionCheck = $$c
                                      }
                                    },
                                  },
                                }),
                                _c("span", {
                                  staticClass: "checkmark",
                                  attrs: { for: "quizAssessment" },
                                }),
                                _vm._v(" Completion "),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-sm-5" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "custom-checkbox left checkbox-grading",
                              attrs: { name: "quizAssessment" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.enableInstructorEdit,
                                    expression: "enableInstructorEdit",
                                  },
                                ],
                                attrs: {
                                  disabled: !_vm.elmGradingActivate,
                                  type: "checkbox",
                                  name: "quizAssessment",
                                },
                                domProps: {
                                  value: "quizAssessment",
                                  checked: Array.isArray(
                                    _vm.enableInstructorEdit
                                  )
                                    ? _vm._i(
                                        _vm.enableInstructorEdit,
                                        "quizAssessment"
                                      ) > -1
                                    : _vm.enableInstructorEdit,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.enableInstructorEdit,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "quizAssessment",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.enableInstructorEdit =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.enableInstructorEdit = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.enableInstructorEdit = $$c
                                    }
                                  },
                                },
                              }),
                              _c("span", {
                                staticClass: "checkmark",
                                attrs: { for: "quizAssessment" },
                              }),
                              _vm._v(" Enable Instructor Edit "),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _vm.elmGradingActivate
                    ? _c("div", { staticClass: "setup-content" }, [
                        _vm._m(6),
                        _c("form", { staticClass: "w-100" }, [
                          _c("div", { staticClass: "row custom-row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox",
                                  attrs: { name: "public" },
                                },
                                [
                                  _vm._v(" Labels "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gradeDisplayType,
                                        expression: "gradeDisplayType",
                                      },
                                    ],
                                    attrs: {
                                      disabled: !_vm.elmGradingActivate,
                                      type: "radio",
                                      name: "public",
                                    },
                                    domProps: {
                                      value: "labels",
                                      checked: _vm._q(
                                        _vm.gradeDisplayType,
                                        "labels"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.gradeDisplayType = "labels"
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark circle",
                                    attrs: { for: "public" },
                                  }),
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox",
                                  attrs: { name: "public" },
                                },
                                [
                                  _vm._v(" Percentage "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gradeDisplayType,
                                        expression: "gradeDisplayType",
                                      },
                                    ],
                                    attrs: {
                                      disabled: !_vm.elmGradingActivate,
                                      type: "radio",
                                      name: "public",
                                    },
                                    domProps: {
                                      value: "percentage",
                                      checked: _vm._q(
                                        _vm.gradeDisplayType,
                                        "percentage"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.gradeDisplayType = "percentage"
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark circle",
                                    attrs: { for: "public" },
                                  }),
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox",
                                  attrs: { name: "public" },
                                },
                                [
                                  _vm._v(" Both "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gradeDisplayType,
                                        expression: "gradeDisplayType",
                                      },
                                    ],
                                    attrs: {
                                      disabled: !_vm.elmGradingActivate,
                                      type: "radio",
                                      name: "public",
                                    },
                                    domProps: {
                                      value: "both",
                                      checked: _vm._q(
                                        _vm.gradeDisplayType,
                                        "both"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.gradeDisplayType = "both"
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark circle",
                                    attrs: { for: "public" },
                                  }),
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-checkbox",
                                  attrs: { name: "public" },
                                },
                                [
                                  _vm._v(" Hide "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gradeDisplayType,
                                        expression: "gradeDisplayType",
                                      },
                                    ],
                                    attrs: {
                                      disabled: !_vm.elmGradingActivate,
                                      type: "radio",
                                      name: "public",
                                    },
                                    domProps: {
                                      value: "hide",
                                      checked: _vm._q(
                                        _vm.gradeDisplayType,
                                        "hide"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.gradeDisplayType = "hide"
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "checkmark circle",
                                    attrs: { for: "public" },
                                  }),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-sm-5" },
                              [
                                _vm._l(
                                  _vm.multiRowsAssessmentLabels,
                                  function (ro, inx) {
                                    return _c("span", { key: inx }, [
                                      !ro.deleted
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ai-row ai-rows-header",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pr-4" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value: ro.min,
                                                          expression: "ro.min",
                                                          modifiers: {
                                                            number: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-input-style-grade-number",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.elmGradingActivate,
                                                        type: "number",
                                                      },
                                                      domProps: {
                                                        value: ro.min,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            ro,
                                                            "min",
                                                            _vm._n(
                                                              $event.target
                                                                .value
                                                            )
                                                          )
                                                        },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.$forceUpdate()
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("span", [_vm._v(" → ")]),
                                                _c(
                                                  "span",
                                                  { staticClass: "pr-4" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value: ro.max,
                                                          expression: "ro.max",
                                                          modifiers: {
                                                            number: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-input-style-grade-number",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.elmGradingActivate,
                                                        type: "number",
                                                      },
                                                      domProps: {
                                                        value: ro.max,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            ro,
                                                            "max",
                                                            _vm._n(
                                                              $event.target
                                                                .value
                                                            )
                                                          )
                                                        },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.$forceUpdate()
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: ro.label,
                                                        expression: "ro.label",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "custom-input-style-grade",
                                                    attrs: {
                                                      placeholder:
                                                        "Assessment Label",
                                                      disabled:
                                                        !_vm.elmGradingActivate,
                                                      type: "text",
                                                    },
                                                    domProps: {
                                                      value: ro.label,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          ro,
                                                          "label",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]),
                                                _c("span", [
                                                  _vm.elmGradingActivate
                                                    ? _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "javascript:void(0);",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteRow(
                                                                inx
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("🗑")]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                _c("div", { staticClass: "form-group pb-4" }, [
                                  _vm.elmGradingActivate
                                    ? _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.addNewRange()
                                            },
                                          },
                                        },
                                        [_vm._v("+ Add Grade Range")]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "form-section" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "learning-form-header d-flex align-items-start",
                    },
                    [
                      _vm._m(7),
                      _c("div", { staticClass: "ml-auto text-right" }, [
                        _c("label", { staticClass: "switch-grading ml-auto" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.integrityActivate,
                                expression: "integrityActivate",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.integrityActivate)
                                ? _vm._i(_vm.integrityActivate, null) > -1
                                : _vm.integrityActivate,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.integrityActivate,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.integrityActivate = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.integrityActivate = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.integrityActivate = $$c
                                }
                              },
                            },
                          }),
                          _c("span", { staticClass: "slider-grading round" }),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.integrityActivate
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          !_vm.videoIntegrity.attributes
                            ? _c("div", { staticClass: "integrity-video" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn ml-2 mb-2 secondary medium",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addVideoIntegrity(
                                          "uploadVideoIntegrity"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Add a new video")]
                                ),
                              ])
                            : _vm._e(),
                          _vm.videoIntegrity.attributes && _vm.integrityActivate
                            ? _c("div", { staticClass: "learning-data-list" }, [
                                _c(
                                  "div",
                                  { staticClass: "learning-data-item w-100" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "learning-data-video" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "wistia_responsive_padding data-video",
                                            staticStyle: {
                                              padding: "56.25% 0 0 0",
                                              position: "relative",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "wistia_responsive_wrapper data-video",
                                                staticStyle: {
                                                  height: "100%",
                                                  left: "0",
                                                  position: "absolute",
                                                  top: "0",
                                                  width: "100%",
                                                },
                                              },
                                              [
                                                _c("iframe", {
                                                  staticClass: "wistia_embed",
                                                  attrs: {
                                                    src:
                                                      _vm.preIframUrl +
                                                      _vm.videoIntegrity
                                                        .attributes
                                                        .wistia_code +
                                                      _vm.postIframUrl,
                                                    title: "Video.mp4",
                                                    name: "wistia_embed",
                                                    allowtransparency: "true",
                                                    scrolling: "no",
                                                    allowfullscreen: "",
                                                    mozallowfullscreen: "",
                                                    webkitallowfullscreen: "",
                                                    oallowfullscreen: "",
                                                    msallowfullscreen: "",
                                                    width: "100%",
                                                    height: "100%",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "learning-data-title" },
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.videoIntegrity.attributes.title
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeVideoIntegrity()
                                          },
                                        },
                                      },
                                      [_vm._v("Remove")]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "col-sm-6 integrity-video" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("Learner Text")]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.learnerIntegrityText,
                                  expression: "learnerIntegrityText",
                                },
                              ],
                              staticClass: "form-control small-textarea-height",
                              attrs: { type: "text", placeholder: "" },
                              domProps: { value: _vm.learnerIntegrityText },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.learnerIntegrityText = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "elm-header elm-footer" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn-link mr-5",
                        attrs: { to: "/learning-modules" },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn header-btn primary big",
                        on: { click: _vm.createElm },
                      },
                      [_vm._v(" Save & Add Learning Objects ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "uploadVideo",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Upload Videos")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("div", { staticClass: "modal-images-tab" }, [
                _c(
                  "a",
                  {
                    class: [!_vm.uploadVideoGallery ? "active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.videoStatus()
                      },
                    },
                  },
                  [_vm._v("New Video")]
                ),
                _c(
                  "a",
                  {
                    class: [_vm.uploadVideoGallery ? "active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.videoStatus()
                      },
                    },
                  },
                  [_vm._v("Library")]
                ),
              ]),
              _vm.uploadVideoGallery
                ? _c("form", { staticClass: "header-form" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVideo,
                            expression: "searchVideo",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          "aria-describedby": "emailHelp",
                          placeholder: "Search",
                        },
                        domProps: { value: _vm.searchVideo },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.searchVideo = $event.target.value
                            },
                            _vm.searchVideos,
                          ],
                        },
                      }),
                      _c("em", { staticClass: "icon-search" }),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "modal-images-content" }, [
                !_vm.uploadVideoGallery
                  ? _c(
                      "div",
                      { staticClass: "modal-images-upload" },
                      [
                        _c("ValidationObserver", { ref: "form" }, [
                          _c(
                            "form",
                            {
                              staticClass: "modal-form organisation-form",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitForm.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Card Title"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Card Title",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.title,
                                                      expression: "title",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "title",
                                                    id: "title",
                                                    "aria-describedby":
                                                      "emailHelp",
                                                  },
                                                  domProps: {
                                                    value: _vm.title,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.title =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1089005335
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Wistia Code"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Wistia Code",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.wistia_code,
                                                      expression: "wistia_code",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "wistia_code",
                                                    id: "wistia_code",
                                                    "aria-describedby":
                                                      "emailHelp",
                                                  },
                                                  domProps: {
                                                    value: _vm.wistia_code,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.wistia_code =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _vm.wistia_code
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          "success",
                                                          "cursor",
                                                        ],
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getWistiaInfo()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Fetch Video Meta Info"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        27906109
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Transcript Description"),
                                  ]),
                                  _c("vue-editor", {
                                    attrs: { editorToolbar: _vm.fullToolbar },
                                    model: {
                                      value: _vm.transcript,
                                      callback: function ($$v) {
                                        _vm.transcript = $$v
                                      },
                                      expression: "transcript",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v(" Video Duration"),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Video Duration",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.duration,
                                                    expression: "duration",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "duration",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                },
                                                domProps: {
                                                  value: _vm.duration,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.duration =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1282131282
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group organisation-image-group flex-wrap mt-0",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-label w-100" },
                                    [_vm._v("Wistia Thumbnail")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "organisation-image-pic cursor",
                                    },
                                    [
                                      _vm.wistaThumbnail
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.wistaThumbnail,
                                              alt: "images",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "organisation-image-remove",
                                    },
                                    [
                                      _c("input", {
                                        ref: "fileInput",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept: "image/*",
                                        },
                                        on: { change: _vm.onFile },
                                      }),
                                      !_vm.wistaThumbnail
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "btn add",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.fileInput.click()
                                                },
                                              },
                                            },
                                            [_vm._v("Add Photo")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "btn delete",
                                              on: {
                                                click: _vm.removeWistaThumbnail,
                                              },
                                            },
                                            [_vm._v("Remove Photo")]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn default",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#AddVideo",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hide("uploadVideo")
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.uploadVideoGallery
                  ? _c(
                      "div",
                      { staticClass: "modal-images-library" },
                      [
                        _c(
                          "v-infinite-scroll",
                          {
                            staticClass: "modal-content-scroll",
                            attrs: { offset: 20 },
                            on: { bottom: _vm.nextPageVideos },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "modal-videos-row" },
                              _vm._l(_vm.videoLists, function (video) {
                                return _c(
                                  "div",
                                  {
                                    key: video.id,
                                    staticClass: "modal-videos-col",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "modal-images-checkbox",
                                        on: {
                                          click: function ($event) {
                                            _vm.$modal.hide("uploadVideo")
                                            _vm.selectedVideo(video)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "custom-checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    video.attributes.status,
                                                  expression:
                                                    "video.attributes.status",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "video",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  video.attributes.status
                                                )
                                                  ? _vm._i(
                                                      video.attributes.status,
                                                      null
                                                    ) > -1
                                                  : video.attributes.status,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      video.attributes.status,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          video.attributes,
                                                          "status",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          video.attributes,
                                                          "status",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      video.attributes,
                                                      "status",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span", {
                                              staticClass: "checkmark circle",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "wistia_responsive_padding data-video",
                                                staticStyle: {
                                                  padding: "56.25% 0 0 0",
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "wistia_responsive_wrapper data-video",
                                                    staticStyle: {
                                                      height: "100%",
                                                      left: "0",
                                                      position: "absolute",
                                                      top: "0",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c("iframe", {
                                                      staticClass:
                                                        "wistia_embed",
                                                      attrs: {
                                                        src:
                                                          _vm.preIframUrl +
                                                          video.attributes
                                                            .wistia_code +
                                                          _vm.postIframUrl,
                                                        title: "Video.mp4",
                                                        name: "wistia_embed",
                                                        allowtransparency:
                                                          "true",
                                                        scrolling: "no",
                                                        allowfullscreen: "",
                                                        mozallowfullscreen: "",
                                                        webkitallowfullscreen:
                                                          "",
                                                        oallowfullscreen: "",
                                                        msallowfullscreen: "",
                                                        width: "100%",
                                                        height: "100%",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "modal-images-info",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(
                                                      video.attributes.title
                                                    )
                                                  ),
                                                ]),
                                                _vm._l(
                                                  video.attributes.tag_list,
                                                  function (tag) {
                                                    return _c(
                                                      "p",
                                                      { key: tag },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(tag) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c("div", {
                                              staticClass: "checked",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm.uploadVideoGallery
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.hide("uploadVideo")
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("uploadVideo")
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "uploadVideoIntegrity",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Upload Videos")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("div", { staticClass: "modal-images-tab" }, [
                _c(
                  "a",
                  {
                    class: [!_vm.uploadVideoGalleryIntegrity ? "active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.videoStatusIntegrity()
                      },
                    },
                  },
                  [_vm._v("New Video")]
                ),
                _c(
                  "a",
                  {
                    class: [_vm.uploadVideoGalleryIntegrity ? "active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.videoStatusIntegrity()
                      },
                    },
                  },
                  [_vm._v("Library")]
                ),
              ]),
              _vm.uploadVideoGalleryIntegrity
                ? _c("form", { staticClass: "header-form" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVideo,
                            expression: "searchVideo",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          "aria-describedby": "emailHelp",
                          placeholder: "Search",
                        },
                        domProps: { value: _vm.searchVideo },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.searchVideo = $event.target.value
                            },
                            _vm.searchVideos,
                          ],
                        },
                      }),
                      _c("em", { staticClass: "icon-search" }),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "modal-images-content" }, [
                !_vm.uploadVideoGalleryIntegrity
                  ? _c(
                      "div",
                      { staticClass: "modal-images-upload" },
                      [
                        _c("ValidationObserver", { ref: "form" }, [
                          _c(
                            "form",
                            {
                              staticClass: "modal-form organisation-form",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitForm.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Card Title"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Card Title",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.title,
                                                      expression: "title",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "title",
                                                    id: "title",
                                                    "aria-describedby":
                                                      "emailHelp",
                                                  },
                                                  domProps: {
                                                    value: _vm.title,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.title =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1089005335
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Wistia Code"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Wistia Code",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.wistia_code,
                                                      expression: "wistia_code",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "wistia_code",
                                                    id: "wistia_code",
                                                    "aria-describedby":
                                                      "emailHelp",
                                                  },
                                                  domProps: {
                                                    value: _vm.wistia_code,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.wistia_code =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _vm.wistia_code
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          "success",
                                                          "cursor",
                                                        ],
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getWistiaInfo()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Fetch Video Meta Info"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        27906109
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Transcript Description"),
                                  ]),
                                  _c("vue-editor", {
                                    attrs: { editorToolbar: _vm.fullToolbar },
                                    model: {
                                      value: _vm.transcript,
                                      callback: function ($$v) {
                                        _vm.transcript = $$v
                                      },
                                      expression: "transcript",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v(" Video Duration"),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Video Duration",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.duration,
                                                    expression: "duration",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "duration",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                },
                                                domProps: {
                                                  value: _vm.duration,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.duration =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1282131282
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group organisation-image-group flex-wrap mt-0",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-label w-100" },
                                    [_vm._v("Wistia Thumbnail")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "organisation-image-pic cursor",
                                    },
                                    [
                                      _vm.wistaThumbnail
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.wistaThumbnail,
                                              alt: "images",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "organisation-image-remove",
                                    },
                                    [
                                      _c("input", {
                                        ref: "fileInput",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept: "image/*",
                                        },
                                        on: { change: _vm.onFile },
                                      }),
                                      !_vm.wistaThumbnail
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "btn add",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.fileInput.click()
                                                },
                                              },
                                            },
                                            [_vm._v("Add Photo")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "btn delete",
                                              on: {
                                                click: _vm.removeWistaThumbnail,
                                              },
                                            },
                                            [_vm._v("Remove Photo")]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn default",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#AddVideo",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hide("uploadVideoIntegrity")
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.uploadVideoGalleryIntegrity
                  ? _c(
                      "div",
                      { staticClass: "modal-images-library" },
                      [
                        _c(
                          "v-infinite-scroll",
                          {
                            staticClass: "modal-content-scroll",
                            attrs: { offset: 20 },
                            on: { bottom: _vm.nextPageVideos },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "modal-videos-row" },
                              _vm._l(_vm.videoLists, function (video) {
                                return _c(
                                  "div",
                                  {
                                    key: video.id,
                                    staticClass: "modal-videos-col",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "modal-images-checkbox",
                                        on: {
                                          click: function ($event) {
                                            _vm.$modal.hide(
                                              "uploadVideoIntegrity"
                                            )
                                            _vm.selectedVideoIntegrity(video)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "custom-checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    video.attributes.status,
                                                  expression:
                                                    "video.attributes.status",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "video",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  video.attributes.status
                                                )
                                                  ? _vm._i(
                                                      video.attributes.status,
                                                      null
                                                    ) > -1
                                                  : video.attributes.status,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      video.attributes.status,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          video.attributes,
                                                          "status",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          video.attributes,
                                                          "status",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      video.attributes,
                                                      "status",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span", {
                                              staticClass: "checkmark circle",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "wistia_responsive_padding data-video",
                                                staticStyle: {
                                                  padding: "56.25% 0 0 0",
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "wistia_responsive_wrapper data-video",
                                                    staticStyle: {
                                                      height: "100%",
                                                      left: "0",
                                                      position: "absolute",
                                                      top: "0",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c("iframe", {
                                                      staticClass:
                                                        "wistia_embed",
                                                      attrs: {
                                                        src:
                                                          _vm.preIframUrl +
                                                          video.attributes
                                                            .wistia_code +
                                                          _vm.postIframUrl,
                                                        title: "Video.mp4",
                                                        name: "wistia_embed",
                                                        allowtransparency:
                                                          "true",
                                                        scrolling: "no",
                                                        allowfullscreen: "",
                                                        mozallowfullscreen: "",
                                                        webkitallowfullscreen:
                                                          "",
                                                        oallowfullscreen: "",
                                                        msallowfullscreen: "",
                                                        width: "100%",
                                                        height: "100%",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "modal-images-info",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(
                                                      video.attributes.title
                                                    )
                                                  ),
                                                ]),
                                                _vm._l(
                                                  video.attributes.tag_list,
                                                  function (tag) {
                                                    return _c(
                                                      "p",
                                                      { key: tag },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(tag) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c("div", {
                                              staticClass: "checked",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm.uploadVideoGalleryIntegrity
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.hide("uploadVideoIntegrity")
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("uploadVideoIntegrity")
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "GradingSetupModal",
            height: "auto",
            width: 1400,
            scrollable: true,
          },
        },
        [
          _c("div", {
            staticClass: "hide-modal",
            on: {
              click: function ($event) {
                return _vm.$modal.hide("GradingSetupModal")
              },
            },
          }),
          _c("div", { staticClass: "modal-content modal-default" }, [
            _c("div", { staticClass: "row d-inline-flex" }, [
              _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "exampleModalLabel" },
                    },
                    [_c("h5", [_vm._v("Enable ELM Grading")])]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col" }, [
                _c("label", { staticClass: "switch-grading ml-auto" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.elmGradingActivate,
                        expression: "elmGradingActivate",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.elmGradingActivate)
                        ? _vm._i(_vm.elmGradingActivate, null) > -1
                        : _vm.elmGradingActivate,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.elmGradingActivate,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.elmGradingActivate = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.elmGradingActivate = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.elmGradingActivate = $$c
                        }
                      },
                    },
                  }),
                  _c("span", { staticClass: "slider-grading round" }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "row custom-row" }, [
              _c("div", { staticClass: "col-sm-7" }, [
                _c("div", { staticClass: "col-sm-5" }, [
                  _c(
                    "label",
                    {
                      staticClass: "custom-checkbox left checkbox-grading",
                      attrs: { name: "quizAssessment" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.assessmentCheck,
                            expression: "assessmentCheck",
                          },
                        ],
                        attrs: {
                          disabled: !_vm.elmGradingActivate,
                          type: "checkbox",
                          name: "quizAssessment",
                        },
                        domProps: {
                          value: "quizAssessment",
                          checked: Array.isArray(_vm.assessmentCheck)
                            ? _vm._i(_vm.assessmentCheck, "quizAssessment") > -1
                            : _vm.assessmentCheck,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.assessmentCheck,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "quizAssessment",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.assessmentCheck = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.assessmentCheck = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.assessmentCheck = $$c
                            }
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "checkmark",
                        attrs: { for: "quizAssessment" },
                      }),
                      _vm._v(" Assessment "),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "col-sm-5" }, [
                  _c(
                    "label",
                    {
                      staticClass: "custom-checkbox left checkbox-grading",
                      attrs: { name: "quizAssessment" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.completionCheck,
                            expression: "completionCheck",
                          },
                        ],
                        attrs: {
                          disabled: !_vm.elmGradingActivate,
                          type: "checkbox",
                          name: "quizAssessment",
                        },
                        domProps: {
                          value: "quizAssessment",
                          checked: Array.isArray(_vm.completionCheck)
                            ? _vm._i(_vm.completionCheck, "quizAssessment") > -1
                            : _vm.completionCheck,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.completionCheck,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "quizAssessment",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.completionCheck = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.completionCheck = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.completionCheck = $$c
                            }
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "checkmark",
                        attrs: { for: "quizAssessment" },
                      }),
                      _vm._v(" Completion "),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "label",
                  {
                    staticClass: "custom-checkbox left checkbox-grading",
                    attrs: { name: "quizAssessment" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.enableInstructorEdit,
                          expression: "enableInstructorEdit",
                        },
                      ],
                      attrs: {
                        disabled: !_vm.elmGradingActivate,
                        type: "checkbox",
                        name: "quizAssessment",
                      },
                      domProps: {
                        value: "quizAssessment",
                        checked: Array.isArray(_vm.enableInstructorEdit)
                          ? _vm._i(_vm.enableInstructorEdit, "quizAssessment") >
                            -1
                          : _vm.enableInstructorEdit,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.enableInstructorEdit,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "quizAssessment",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.enableInstructorEdit = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.enableInstructorEdit = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.enableInstructorEdit = $$c
                          }
                        },
                      },
                    }),
                    _c("span", {
                      staticClass: "checkmark",
                      attrs: { for: "quizAssessment" },
                    }),
                    _vm._v(" Enable Instructor Edit "),
                  ]
                ),
              ]),
            ]),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("div", { staticClass: "setup-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("h4", { staticClass: "head-title" }, [
                    _vm._v("Learner Overall Grade Display Type:"),
                  ]),
                ]
              ),
              _c("form", { staticClass: "w-100" }, [
                _c("div", { staticClass: "row custom-row" }, [
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "label",
                      {
                        staticClass: "custom-checkbox",
                        attrs: { name: "public" },
                      },
                      [
                        _vm._v(" Labels "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.gradeDisplayType,
                              expression: "gradeDisplayType",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.elmGradingActivate,
                            type: "radio",
                            name: "public",
                          },
                          domProps: {
                            value: "labels",
                            checked: _vm._q(_vm.gradeDisplayType, "labels"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.gradeDisplayType = "labels"
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "checkmark circle",
                          attrs: { for: "public" },
                        }),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "custom-checkbox",
                        attrs: { name: "public" },
                      },
                      [
                        _vm._v(" Percentage "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.gradeDisplayType,
                              expression: "gradeDisplayType",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.elmGradingActivate,
                            type: "radio",
                            name: "public",
                          },
                          domProps: {
                            value: "percentage",
                            checked: _vm._q(_vm.gradeDisplayType, "percentage"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.gradeDisplayType = "percentage"
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "checkmark circle",
                          attrs: { for: "public" },
                        }),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "custom-checkbox",
                        attrs: { name: "public" },
                      },
                      [
                        _vm._v(" Both "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.gradeDisplayType,
                              expression: "gradeDisplayType",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.elmGradingActivate,
                            type: "radio",
                            name: "public",
                          },
                          domProps: {
                            value: "both",
                            checked: _vm._q(_vm.gradeDisplayType, "both"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.gradeDisplayType = "both"
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "checkmark circle",
                          attrs: { for: "public" },
                        }),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "custom-checkbox",
                        attrs: { name: "public" },
                      },
                      [
                        _vm._v(" Hide "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.gradeDisplayType,
                              expression: "gradeDisplayType",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.elmGradingActivate,
                            type: "radio",
                            name: "public",
                          },
                          domProps: {
                            value: "hide",
                            checked: _vm._q(_vm.gradeDisplayType, "hide"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.gradeDisplayType = "hide"
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "checkmark circle",
                          attrs: { for: "public" },
                        }),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _vm._l(_vm.multiRowsAssessmentLabels, function (ro, inx) {
                        return _c("span", { key: inx }, [
                          !ro.deleted
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "ai-row ai-rows-header" },
                                  [
                                    _c("span", { staticClass: "pr-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value: ro.min,
                                            expression: "ro.min",
                                            modifiers: { number: true },
                                          },
                                        ],
                                        staticClass:
                                          "custom-input-style-grade-number",
                                        attrs: {
                                          disabled: !_vm.elmGradingActivate,
                                          type: "number",
                                        },
                                        domProps: { value: ro.min },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              ro,
                                              "min",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("span", [_vm._v(" → ")]),
                                    _c("span", { staticClass: "pr-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value: ro.max,
                                            expression: "ro.max",
                                            modifiers: { number: true },
                                          },
                                        ],
                                        staticClass:
                                          "custom-input-style-grade-number",
                                        attrs: {
                                          disabled: !_vm.elmGradingActivate,
                                          type: "number",
                                        },
                                        domProps: { value: ro.max },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              ro,
                                              "max",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("span", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: ro.label,
                                            expression: "ro.label",
                                          },
                                        ],
                                        staticClass: "custom-input-style-grade",
                                        attrs: {
                                          placeholder: "Assessment Label",
                                          disabled: !_vm.elmGradingActivate,
                                          type: "text",
                                        },
                                        domProps: { value: ro.label },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              ro,
                                              "label",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("span", [
                                      _vm.elmGradingActivate
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(inx)
                                                },
                                              },
                                            },
                                            [_vm._v("🗑")]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      _c("div", { staticClass: "form-group pb-4" }, [
                        _vm.elmGradingActivate
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addNewRange()
                                  },
                                },
                              },
                              [_vm._v("+ Add Grade Range")]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm.assessmentCheck || _vm.completionCheck
                ? _c("div", { staticClass: "pt-3 text-right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn secondary",
                        attrs: { href: "javascript:void(0);" },
                        on: { click: _vm.saveGradingSetup },
                      },
                      [_vm._v("Save")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c("pdfModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("Create ELM")]),
      _c("p", [_vm._v("Add a few basic details about this Learning Module")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "default-text" }, [
      _c("span", { staticClass: "icon-plus" }),
      _c("p", [
        _vm._v(" Add a cover image for the "),
        _c("br"),
        _vm._v("Learning module "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-4 px-0" }, [
      _c("h4", [_vm._v("Introductory Documents")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-4 px-0" }, [
      _c("h4", [_vm._v("Introductory Video")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-4 px-0" }, [
      _c("h4", [_vm._v("Brand Images")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-4 px-0" }, [
      _c("h4", [_vm._v("ELM Grading")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", { staticClass: "head-title" }, [
          _vm._v("Learner Overall Grade Display Type:"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-4 px-0" }, [
      _c("h4", [_vm._v("Learner Academic Integrity Tab")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }