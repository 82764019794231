<template>
  <modal name="assignOrganisation" height="auto" :scrollable="true" @before-open="beforeOpen">
    <div class="modal-content modal-content-large">
      <div class="modal-header">
        <div class="modal-title" id="exampleModalLabel">
          <h5>Assign Organization</h5>
          <p>Add Characters to the Organizations you have created.</p>
        </div>
        <button type="button" class="close" @click="hide('assignOrganisation')">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <ValidationObserver ref="form">
          <form class="modal-form" @submit.prevent="onSubmit">
            <div class="form-group">
              <div class="organisation-dropdown">
                <ValidationProvider name="Organisation" rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    placeholder="Type here"
                    class="form-control"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    v-on:input="searchOrganisation"
                    v-model="organisation"
                    :class="[(isActive && isOrgnizationData) ? 'invalid' : '']"
                  />
                  <span
                    :class="[(isActive && isOrgnizationData) ? 'invalid' : '']"
                    :style="[(isActive && isOrgnizationData) ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span>
                </ValidationProvider>
              </div>
              <div class="organisation-dropdown" v-if="organisations.length !== 0">
                <div class="dropdown-menu" :class="[organisations.length !== 0 ? 'show' : '']">
                  <ul class="dropdown-list">
                    <li
                      class="dropdown-items"
                      v-for="(organisation) of organisations"
                      :key="organisation.id"
                    >
                      <div class="dropdown-pics" @click="addOrganisation(organisation)">
                        <img
                          :src="organisation.photo_url.thumbnail"
                          alt="image2"
                          v-if="organisation.photo_url.thumbnail"
                        />
                      </div>
                      <div class="dropdown-content" @click="addOrganisation(organisation)">
                        <h4>{{organisation.name}}</h4>
                        <h5>{{organisation.industry_name}}</h5>
                        <p>{{organisation.characters_name}}</p>
                      </div>
                      <div class="dropdown-action">
                        <div class="dropdown-action" v-if="!organisation.status">
                          <a class="add-action" @click="addOrganisation(organisation)">+</a>
                        </div>
                        <div class="dropdown-action" v-if="organisation.status">
                          <em class="icon-check"></em>
                          <span>Added</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="organisation-dropdown-results" v-if="addedOrganisation.id">
                <ul class="dropdown-list">
                  <li class="dropdown-items">
                    <div class="dropdown-pics">
                      <img
                        :src="addedOrganisation.photo_url.thumbnail"
                        alt="image2"
                        v-if="addedOrganisation.photo_url.thumbnail"
                      />
                    </div>
                    <div class="dropdown-content mt-2">
                      <h4>{{addedOrganisation.name}}</h4>
                      <h5>{{addedOrganisation.industry_name}}</h5>
                      <p>{{addedOrganisation.characters_name}}</p>
                    </div>
                    <div>
                      <div class="dropdown-form">
                        <div class="form-group mb-1">
                          <ValidationProvider name="searchIndustry" rules="required" v-slot="{ errors }">
                            <input
                              type="text"
                              v-model="searchRole"
                              v-on:input="getRole"
                              class="form-control"
                              placeholder="Enter Role"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >Role is required</span>
                          </ValidationProvider>
                          <div class="dropdown-menu" :class="[roles.length !== 0 ? 'show' : '']">
                          <div class="dropdown-list mt-3" v-if="roles.length">
                            <div class="dropdown-items" v-for="(role) of roles" :key="role.id">
                              <div class="dropdown-content" @click="setRole(role.id, role.attributes.name)">
                                <h4>{{role.attributes.name}}</h4>
                              </div>
                              <div class="dropdown-action">
                                <div class="dropdown-action">
                                  <a
                                    class="add-action"
                                    @click="setRole(role.id, role.attributes.name)"
                                  >+</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div class="dropdown-action">
                        <a @click="removeOrganisation()" class="delete-action">Remove</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn default"
                data-toggle="modal"
                data-target="#createCharacter"
                data-dismiss="modal"
                @click="hide()"
              >Cancel</button>
              <button type="submit" class="btn primary">Save</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { commonConfig } from "@/utils/commonConfig"
export default {
  name: "AssignOrganisation",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    method: { type: Function }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      addedOrganisation: {},
      organisation: "",
      isActive: false,
      isOrgnizationData: true,
      worldsList: [],
      organisations: [],
      organisationsList: [],
      character_id: "",
      role: "",
      roles: [],
      rolesList: [],
      searchRole: "",
      url: utilFunctionService.getImageUrl(),
    };
  },
  methods: {
    beforeOpen(event) {
      this.character_id = event.params.character_id;
    },
    hide()
    {
      this.clearForm();
      this.$parent.hide("assignOrganisation");
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success && ( !this.searchRole || this.isOrgnizationData)) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append(
            "organization_character[organization_id]",
            parseInt(this.addedOrganisation.id)
          );
          fb.append(
            "organization_character[world_role_id]",
            parseInt(this.addedOrganisation.world_role_id)
          );
          fb.append("id", parseInt(this.character_id));
          api
            .assign_organization_role(this.character_id, fb)
            .then(() => {
              utilFunctionService.hideLoader();
              utilFunctionService.showSuccess(commonConfig.appMessages.successAssingOrgnization);
              this.clearForm();
              this.hide("assignOrganisation");
            })
            .catch(() => {
              utilFunctionService.hideLoader();
              this.clearForm()
              utilFunctionService.showErr(commonConfig.appMessages.failAssingOrgnization);
              this.hide("assignOrganisation");
            });
        }
      });
    },
    searchOrganisation() {
      if (this.organisation.length === 0) {
        this.organisations = [];
      } else {
        api
          .getOrganizations(this.organisation,this.character_id)
          .then(res => {
            this.organisations = [];
            const organisationsList = res.data.data.map(item => {
              const organisationObj = {};
              organisationObj["name"] = item.attributes.name;
              organisationObj["photo_url"] = item.attributes.photo_url;
              organisationObj["characters_name"] = '';
              organisationObj["id"] = item.id;
              organisationObj["industry_name"] = item.attributes.industry_name;
              organisationObj["status"] = false;
              organisationObj["world_role_id"] = "";
              let name = ''
              if(item.attributes.organization_characters.length > 0 ) {
                let organization_characters = item.attributes.organization_characters;
                name = organization_characters[0].attributes.characters.attributes.full_name
                if(item.attributes.organization_characters.length > 1) name = name +','+ organization_characters[1].attributes.characters.attributes.full_name
                if(item.attributes.organization_characters.length > 2) name = name +' + '+ (item.attributes.organization_characters.length - 2) + ' characters '
              }
              organisationObj["characters_name"] = name;
              return organisationObj;
            });
            if (this.addedOrganisation && this.addedOrganisation.id) {
              const index = organisationsList.findIndex(
                elem => elem.id === this.addedOrganisation.id
              );
              if (index !== -1) {
                organisationsList[index].status = true;
              }
              this.organisations = organisationsList;
            } else {
              this.organisations = organisationsList;
            }
          })
          .catch();
      }
    },
    searchWorldRoles() {
      if (this.roles.length === 0) {
        this.roles = [];
      } else {
        api
          .getRoles(this.role)
          .then(res => {
            this.roles = [];
            const rolesList = res.data.data.map(item => {
              const roleObj = {};
              roleObj["name"] = item.attributes.name;
              roleObj["id"] = item.id;
              return roleObj;
            });
            this.roles = rolesList;
          })
          .catch();
      }
    },
    addWorldRoles(e) {
      this.organisation.world_role_id = e;
    },
    addOrganisation(e) {
      this.organisation = "";
      this.organisations = [];
      this.addedOrganisation = e;
      this.isActive = false;
      this.isOrgnizationData = false;
    },
    removeOrganisation() {
      this.addedOrganisation = {};
      this.organisations = [];
      this.isActive = true;
      this.isOrgnizationData = true;
    },
    getRole() {
      if (this.searchRole.length === 0) {
        this.roles = [];
      } else {
        api
          .getRoles(this.searchRole)
          .then(res => {
            this.roles = res.data.data;
          })
          .catch();
      }
    },
    setRole(id, name) {
      this.addedOrganisation.world_role_id = id;
      this.addedOrganisation.world_role_name = name;
      this.searchRole = name;
      this.roles = [];
    },
    clearForm() {
      this.addedOrganisation = {};
      this.organisation = "";
      this.isActive = false;
      this.isOrgnizationData= false;
      this.worldsList= [];
      this.organisations = [];
      this.organisationsList= [];
      this.character_id= '';
      this.role = '';
      this.roles= [];
      this.rolesList = [];
      this.searchRole  = '';
    }
  }
};
</script>