var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "EditSectionModal",
        width: 700,
        height: "auto",
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [_c("h5", [_vm._v("Edit Section")])]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("EditSectionModal")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c("div", { staticClass: "modal-body mt-3" }, [
          _c("div", { staticClass: "edit-section-info" }, [
            _c("p", { staticClass: "name" }, [
              _vm._v("User name: "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.user_info.first_name) +
                    " " +
                    _vm._s(_vm.user_info.last_name)
                ),
              ]),
            ]),
            _c("p", { staticClass: "current_section" }, [
              _vm._v("Current section: "),
              _c("strong", [_vm._v(_vm._s(_vm.user_info.section_name))]),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected_section,
                    expression: "selected_section",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selected_section = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { disabled: "", value: "" } }, [
                  _vm._v("Please select section to switch to:"),
                ]),
                _vm._l(_vm.all_sections, function (section) {
                  return _c(
                    "option",
                    { key: section[1], domProps: { value: section[1] } },
                    [_vm._v(" " + _vm._s(section[0]) + " ")]
                  )
                }),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "edit-section-info__buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn secondary",
                on: { click: _vm.changeSection },
              },
              [_vm._v("Change section")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }