<template>
 <div id="iteration-variations-form">
    <div class="assessment-response">
      <ul class="response-tab">
        <li
          v-for="index in iterationLevel"
          :key="index"
          :class="selIterationLevel === index? 'active': ''"
          @click="hanldeIterationChange(index)"
        >
          <a href="javascript:void(0);">Iteration {{index}}</a>
        </li>
      </ul>
      <ul class="response-tab" v-if="selectedItrVariationRecords">
        <li
          v-for="(vari, index) of selectedItrVariationRecords"
          :key="vari.id"
          :class="selectedVariation && (selectedVariation.id === vari.id) ? 'active': ''"
          @click="handleVariationChange(index)"
        >
          <a href="javascript:void(0);">variation {{vari.attributes.variation}}</a>
        </li>
      </ul>
      <div class="response-content">
        <div class="form-group">
          <vue-editor
            id="Descriptiontext"
            placeholder="Add a Variation"
            :editorToolbar="fullTool"
            v-model="variationText"
          ></vue-editor>
        </div>
      </div>
    </div>
 </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import api from "../../services/api";

export default {
  name: "EmailRespIntroConclusion",
  props: [
    "addIntro",
    "addConclusion",
    "objectableId",
    "emailSelChar",
    "iterationLevel"
  ],
   components: {
    VueEditor,
  },
  data(){
    return {
      selectedVariation: null,
      variationText: "",
      selIterationLevel: 1,
      selCharVariationRecords: [],
      selectedItrVariationRecords: [],
      fullTool: [],
    }
  },

   methods: {
    getPlaceholderText(){
      return this.addIntro ? "Add an Introduction Variation" : "Add a Conclusion Variation";
    },
    // Introduction Methods ...
    chooseIteration(itr){
      this.selIterationLevel = itr;
    },
    getVariationText(){
      if(this.addIntro) {
        return this.selectedVariation?.attributes?.introduction;
      }
      else if(this.addConclusion){
        return this.selectedVariation.attributes.conclusion;
      }
    },
    defaultVariationToFirst(){
      console.log("** IN defaultVariationToFirst")
      if (this.selectedItrVariationRecords){
        this.selectedVariation = this.selectedItrVariationRecords[0];
        this.variationText = this.getVariationText();
      }
      else{
        this.variationText = "";
        this.selectedVariation = null;
      }
    },
    // On iteration click ...
    getVariationRecordsForSelectedItr(level){
      this.chooseIteration(level)
      let data = this.selCharVariationRecords.sort(
            this.sortVariation
        )
      this.setselectedItrVariationRecords(data);
    },
    hanldeIterationChange(level){
      this.getVariationRecordsForSelectedItr(level);
      this.defaultVariationToFirst();
    },
    //  API Call
    getCharIntroOrConclusionRecords(){
      console.log("** IN [getCharIntroOrConclusionRecords]");
      console.log(this.addIntro);
      console.log(this.addConclusion);
      if(this.addIntro)
        api
          .getEmailCharacterIntroVariations(
            this.objectableId,
            this.emailSelChar.id,
            ).then(res => {
              this.processResponseData(res);
        });
      else if(this.addConclusion){
         api
          .getEmailCharacterConclVariations(
            this.objectableId,
            this.emailSelChar.id,
            ).then(res => {
              this.processResponseData(res);
        });
      }
    },
    processResponseData(res){
      console.log("res.data.data");
      console.log(res.data.data);
      if(res.data.data.length > 0){
        this.selCharVariationRecords = [];
        this.selCharVariationRecords = res.data.data.sort(
          this.sortVariation
        );
        this.getVariationRecordsForSelectedItr(this.selIterationLevel);
      }
    },
    setselectedItrVariationRecords(data) {
      this.selectedItrVariationRecords = [];
      data.forEach(ele => {
        if (ele.attributes.iteration === this.selIterationLevel)
          this.selectedItrVariationRecords.push(ele);
      });
    },
    handleVariationChange(index) {
      console.log("IN handleVariationChange method..");
      console.log("index" + index);
      console.log(this.selectedVariation);
      console.log(this.selectedItrVariationRecords);
      if(this.selectedVariation){
        this.updateVariation(this.selectedVariation);
      }
      setTimeout(() => {
        this.selectedVariation = this.selectedItrVariationRecords[index];
        this.variationText = this.getVariationText();

      }, 1000);
    },
    updateVariation(data){
      console.log("In updateVariation method ...");
      console.log(data);
      if(this.addIntro) {
        let fb = {
          character_intro_variations: { introduction: this.variationText}
        };
        this.variationText &&
        api.addIntroVariation(data.id, fb).then(() => {
          this.getCharIntroOrConclusionRecords();
        });
      }
      else if(this.addConclusion){
        let fb = { character_conclusion_variations: { conclusion: this.variationText }};
        this.variationText &&
        api.addConclusionVariation(data.id, fb).then(() => {
          this.getCharIntroOrConclusionRecords();
        });
      }
    },
    sortVariation(a, b) {
      if (a.attributes.variation < b.attributes.variation) {
        return -1;
      }
      if (a.attributes.variation > b.attributes.variation) {
        return 1;
      }
      return 0;
    },
   },
   mounted(){
    this.$watch('addIntro', function() {
      this.getCharIntroOrConclusionRecords();
        setTimeout(() => {
          this.defaultVariationToFirst();
        }, 1000);
    }, {immediate:true})
     this.$watch('emailSelChar', function() {
      this.getCharIntroOrConclusionRecords();
        setTimeout(() => {
          this.defaultVariationToFirst();
        }, 1000);
    }, {immediate:true})
  }

}
</script>
<style lang="scss">
  @import "../../styles/variables.scss";
  @import "../../styles/mixin.scss";
</style>
