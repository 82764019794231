var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "QaEmailList" } }, [
    _c("div", { staticClass: "container" }, [
      _c("header", { staticClass: "header" }, [
        _vm._m(0),
        _c("div", { staticClass: "header-right" }, [
          _c("form", { staticClass: "header-form" }, [
            _c("div", { staticClass: "form-group col" }, [
              _c("div", { staticClass: "form-gender-dropdown" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn dropdown-toggle",
                    attrs: {
                      type: "button",
                      id: "dropdownMenuButton",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.filterApplied) + " "),
                    _c("em", { staticClass: "icon-carrot-down" }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu",
                    attrs: { "aria-labelledby": "dropdownMenuButton" },
                  },
                  _vm._l(_vm.filter_list, function (filter) {
                    return _c(
                      "a",
                      {
                        key: filter,
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.applyFilter(filter, false)
                          },
                        },
                      },
                      [_vm._v(_vm._s(filter))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
        _vm.totalQaEmails > 1 && !_vm.searchQaEmail
          ? _c("p", [
              _vm._v("You have " + _vm._s(_vm.totalQaEmails) + " QA emails"),
            ])
          : _vm.totalQaEmails <= 1 && !_vm.searchQaEmail
          ? _c("p", [
              _vm._v("You have " + _vm._s(_vm.totalQaEmails) + " QA email."),
            ])
          : _vm.totalQaEmails > 1 && _vm.searchQaEmail
          ? _c("p", [
              _vm._v(
                "You have " +
                  _vm._s(_vm.totalQaEmails) +
                  " QA emails in the search result."
              ),
            ])
          : _c("p", [
              _vm._v(
                "You have " +
                  _vm._s(_vm.totalQaEmails) +
                  " QA emails in the search result."
              ),
            ]),
      ]),
      _vm.qaEmails.length
        ? _c("div", { staticClass: "data-content" }, [
            _c("div", { staticClass: "data-table data-column-8" }, [
              _c("div", { staticClass: "data-head" }, [
                _c("div", { staticClass: "data-col" }, [
                  _vm._v(" Learner "),
                  _c("span", { staticClass: "data-sort" }, [
                    _c(
                      "a",
                      {
                        staticClass: "asc",
                        class: [
                          _vm.sortColumn ===
                            "qaEmail.attributes.learner_name" &&
                          _vm.sortOrder === "asc"
                            ? "active"
                            : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.toggleSort(
                              "qaEmail.attributes.learner_name",
                              "asc"
                            )
                          },
                        },
                      },
                      [_c("em", { staticClass: "icon-carrot-up" })]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "desc",
                        class: [
                          _vm.sortColumn ===
                            "qaEmail.attributes.learner_name" &&
                          _vm.sortOrder === "desc"
                            ? "active"
                            : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.toggleSort(
                              "qaEmail.attributes.learner_name",
                              "desc"
                            )
                          },
                        },
                      },
                      [_c("em", { staticClass: "icon-carrot-down" })]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "data-col" }, [
                  _vm._v(" Learning Object "),
                  _c("span", { staticClass: "data-sort" }, [
                    _c(
                      "a",
                      {
                        staticClass: "asc",
                        class: [
                          _vm.sortColumn ===
                            "qaEmail.attributes.learning_object_name" &&
                          _vm.sortOrder === "asc"
                            ? "active"
                            : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.toggleSort(
                              "qaEmail.attributes.learning_object_name",
                              "asc"
                            )
                          },
                        },
                      },
                      [_c("em", { staticClass: "icon-carrot-up" })]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "desc",
                        class: [
                          _vm.sortColumn ===
                            "qaEmail.attributes.learning_object_name" &&
                          _vm.sortOrder === "desc"
                            ? "active"
                            : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.toggleSort(
                              "qaEmail.attributes.learning_object_name",
                              "desc"
                            )
                          },
                        },
                      },
                      [_c("em", { staticClass: "icon-carrot-down" })]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "data-col" }, [_vm._v("Date Sent")]),
                _c("div", { staticClass: "data-col" }, [_vm._v("Resolved")]),
                _c("div", { staticClass: "data-col" }, [
                  _vm._v("Date Resolved"),
                ]),
                _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
              ]),
              _c(
                "div",
                { staticClass: "data-items" },
                _vm._l(_vm.qaEmails, function (qaEmail) {
                  return _c(
                    "div",
                    { key: qaEmail.id, staticClass: "data-row" },
                    [
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(qaEmail.attributes.learner_name)),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(qaEmail.attributes.learning_object_name)),
                      ]),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(qaEmail.attributes.date_sent)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "data-col iteration-resolved" },
                        [
                          qaEmail.attributes.qa_resolved === true
                            ? _c("div", { staticClass: "success-msg icon" }, [
                                _c("em", { staticClass: "icon-check" }),
                              ])
                            : _vm._e(),
                          qaEmail.attributes.qa_resolved === false
                            ? _c("div", { staticClass: "error-msg icon" }, [
                                _c("em", { staticClass: "icon-close" }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "data-col" }, [
                        _vm._v(_vm._s(qaEmail.attributes.qa_resolved_date)),
                      ]),
                      _c("div", { staticClass: "data-col data-action" }, [
                        _c(
                          "div",
                          { staticClass: "data-dropdown" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: `/qa-email/${qaEmail.id}` } },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn dropdown-toggle",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                    },
                                  },
                                  [_c("em", { staticClass: "icon-edit" })]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "data-footer" }, [
              _c("div", { staticClass: "data-results" }, [
                _vm._v(
                  "Showing " +
                    _vm._s(_vm.page) +
                    " of " +
                    _vm._s(_vm.totalPages.length)
                ),
              ]),
              _c(
                "div",
                { attrs: { "aria-label": "Page navigation example" } },
                [
                  _c(
                    "ul",
                    { staticClass: "pagination" },
                    [
                      _vm.page !== 1
                        ? _c("li", { staticClass: "page-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { "aria-label": "Previous" },
                                on: { click: _vm.prevPage },
                              },
                              [_c("em", { staticClass: "icon-prev" })]
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(
                        _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                        function (pages) {
                          return _c(
                            "li",
                            { key: pages, staticClass: "page-item" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  class: [pages === _vm.page ? "active" : ""],
                                  on: {
                                    click: function ($event) {
                                      return _vm.applyFilter(
                                        _vm.filterApplied,
                                        true,
                                        pages
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(pages))]
                              ),
                            ]
                          )
                        }
                      ),
                      _vm.page < _vm.totalPages.length
                        ? _c("li", { staticClass: "page-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { "aria-label": "Next" },
                                on: { click: _vm.nextPage },
                              },
                              [_c("em", { staticClass: "icon-next" })]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.qaEmails.length === 0 && !_vm.searchStatus && _vm.display
        ? _c("div", { staticClass: "data-no-result" }, [
            _c("h3", [_vm._v("There’s nothing here!")]),
            _c("p", [
              _vm._v("You have no QA emails with the selected filter."),
            ]),
            _c("div"),
          ])
        : _vm._e(),
      _vm.qaEmails.length === 0 && _vm.searchStatus
        ? _c("div", { staticClass: "data-no-result" }, [
            _c("h3", [_vm._v("No results found")]),
            _c("p", [_vm._v("Try using a different keyword")]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("QA Email LOs")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }