var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Users" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchUsers,
                      expression: "searchUsers",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:400ms",
                      value: _vm.search,
                      expression: "search",
                      arg: "400ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "aria-describedby": "searchUsers",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchUsers },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchUsers = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("createUser", {
                      user_id: "",
                      page: 1,
                    })
                  },
                },
              },
              [_vm._v("Add a new User")]
            ),
          ]),
          _vm.totalUsers > 1 && !_vm.searchUsers
            ? _c("p", [
                _vm._v(
                  " You have " +
                    _vm._s(_vm.totalUsers) +
                    " Users in the system. "
                ),
              ])
            : _vm.totalUsers <= 1 && !_vm.searchUsers
            ? _c("p", [
                _vm._v(
                  " You have " +
                    _vm._s(_vm.totalUsers) +
                    " User in the system. "
                ),
              ])
            : _vm.totalUsers > 1 && _vm.searchUsers
            ? _c("p", [
                _vm._v(
                  " You have " +
                    _vm._s(_vm.totalUsers) +
                    " Users in the search result. "
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalUsers) +
                    " User in the search result."
                ),
              ]),
        ]),
        _vm.usersList.length !== 0
          ? _c("div", { staticClass: "data-content" }, [
              _c(
                "div",
                { staticClass: "data-table data-column-6" },
                [
                  _c("div", { staticClass: "data-head" }, [
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" First Name "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "first_name" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("first_name", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "first_name" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("first_name", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Last Name "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "last_name" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("last_name", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "last_name" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("last_name", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Email "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "email" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("email", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "email" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("email", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Role(s) "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "roles" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("roles", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "roles" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("roles", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v(" Created "),
                      _c("span", { staticClass: "data-sort" }, [
                        _c(
                          "a",
                          {
                            staticClass: "asc",
                            class: [
                              _vm.sortColumn === "created_at" &&
                              _vm.sortOrder === "asc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("created_at", "asc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-up" })]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "desc",
                            class: [
                              _vm.sortColumn === "created_at" &&
                              _vm.sortOrder === "desc"
                                ? "active"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.toggleSort("created_at", "desc")
                              },
                            },
                          },
                          [_c("em", { staticClass: "icon-carrot-down" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
                    _c("div", { staticClass: "data-col" }, [
                      _vm._v("LTI Login"),
                    ]),
                  ]),
                  _vm._l(_vm.usersList, function (user) {
                    return _c(
                      "div",
                      { key: user.id, staticClass: "data-items" },
                      [
                        _c("div", { staticClass: "data-row" }, [
                          _c("div", { staticClass: "data-col" }, [
                            _c("div", { staticClass: "data-image" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "data-pic circle cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show("userDetail", {
                                        user_id: user.id,
                                      })
                                    },
                                  },
                                },
                                [
                                  user.attributes.photo_url
                                    ? _c("img", {
                                        attrs: {
                                          src: user.attributes.photo_url
                                            .thumbnail,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "data-text cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show("userDetail", {
                                        user_id: user.id,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(user.attributes.first_name) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(user.attributes.last_name)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(user.attributes.email)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(user.attributes.roles)),
                          ]),
                          _c("div", { staticClass: "data-col" }, [
                            _vm._v(_vm._s(user.attributes.created_at)),
                          ]),
                          _c("div", { staticClass: "data-col data-action" }, [
                            _c("div", { staticClass: "data-dropdown" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn dropdown-toggle",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                  },
                                },
                                [
                                  _c("em", {
                                    staticClass: "icon-edit",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$modal.show("createUser", {
                                          user_id: user.id,
                                          page: _vm.page,
                                        })
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          user.attributes.lti_link
                            ? _c(
                                "div",
                                { staticClass: "data-col data-action" },
                                [
                                  _c("div", { staticClass: "data-dropdown" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn dropdown-toggle",
                                        attrs: {
                                          href: user.attributes.lti_link,
                                          type: "button",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("em", { staticClass: "icon-user" }),
                                        _vm._v(" LTI log in "),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "data-col data-action" },
                                [_c("p", [_vm._v("No LTI log in available")])]
                              ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    " Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (page) {
                            return _c(
                              "li",
                              { key: page, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link active",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getUsers(page)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(page))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.usersList.length === 0 && !_vm.searchStatus && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no User in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("createUser", {
                          user_id: "",
                          page: 1,
                        })
                      },
                    },
                  },
                  [_vm._v("Add a new User")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.usersList.length === 0 && _vm.searchStatus
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c("userDetail"),
      _c("createUser"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("All Users")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }