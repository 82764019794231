<template>
  <div>
    <!-- dialogic code start here -->
    <div id="dialogic">
      <div
        class="learning-form-header flex-wrap"
        :class="[backSection ? 'd-none' : '']"
      >
        <h4>Interaction(Dialogic)</h4>
        <div class="learning-quiz-btn">
          <a
            href="javascript:void(0);"
            @click="restore()"
            class="btn header-btn secondary"
            v-if="status == 'published'"
            >Save</a
          >
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            @click="restore()"
            class="btn header-btn primary"
            >Publish LO</a
          >
          <a
            href="javascript:void(0);"
            @click="$modal.show('cloneConfirmationModal')"
            class="btn header-btn ml-2 secondary"
            >Clone LO</a
          >
        </div>
      </div>
      <!-- Dialogic navigation tabs start here -->
      <div class="learning-lo-tabs" :class="[backSection ? 'd-none' : '']">
        <div class="setup-container">
          <ul>
            <li
              :class="{ active: isActiveTab[0] }"
              @click="handleDialogicContent($event, 'basic-information', 0)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Basic Information</span>
              </a>
            </li>
            <li
              :class="{
                active: isActiveTab[1] || dialogicContentId === 'entity-setup',
              }"
              @click="handleDialogicContent($event, 'entity-setup', 1)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Entity Setup</span>
              </a>
            </li>
            <li
              :class="{ active: isActiveTab[2] }"
              @click="handleDialogicContent($event, 'question-setup', 2)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Question Setup</span>
              </a>
            </li>
            <li
              :class="{ active: isActiveTab[3] }"
              v-if="overallAssessment"
              @click="handleDialogicContent($event, 'overall-assessment', 4)"
            >
              <a href="javascript:void(0);">
                <span class="check-box"></span>
                <span>Overall Assessment</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Dialogic tabs end here -->

      <div class="learning-quiz-content">
        <!-- Basic Information start here -->
        <div
          class="setup-content"
          v-if="dialogicContentId === 'basic-information'"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Basic Information</h4>
          </div>
          <ValidationObserver ref="forms">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label>Interaction title</label>
                    <ValidationProvider
                      name="Interaction Title"
                      rules="required|max:100"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        :class="[isActive && errors[0] ? 'invalid' : '']"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[
                          isActive ? { display: 'block' } : { display: 'none' },
                        ]"
                        v-if="errors[0]"
                        >{{ errors[0].replace("The ", "") }}</span
                      >
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Administrative notes</label>
                    <ValidationProvider
                      rules="required"
                      name="Administrative Notes"
                      v-slot="{ errors }"
                    >
                      <textarea
                        class="form-control"
                        id="admin_notes"
                        rows="8"
                        v-model="admin_notes"
                        :class="[isActive && errors[0] ? 'invalid' : '']"
                      ></textarea>
                      <span
                        :class="[isActive && errors[0] ? 'invalid' : '']"
                        :style="[
                          isActive && errors[0]
                            ? { display: 'block' }
                            : { display: 'none' },
                        ]"
                        v-if="errors[0]"
                        >{{ errors[0].replace("The ", "") }}</span
                      >
                    </ValidationProvider>
                  </div>
                  <div class="form-group pt-1">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="quizAssessment"
                      >
                        <input
                          type="checkbox"
                          name="quizAssessment"
                          v-model="overallAssessment"
                        />
                        <span for="quizAssessment" class="checkmark"></span>
                        Use overall interaction assessment
                      </label>
                    </div>
                  </div>

                  <div class="form-group pt-1" v-if="overallAssessment">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="displayOverallAssessment"
                      >
                        <input
                          type="checkbox"
                          name="displayOverallAssessment"
                          v-model="displayOverallAssessment"
                        />
                        <span for="quizAssessment" class="checkmark"></span>
                        Display overall assessment in learner feedback?
                      </label>
                    </div>
                  </div>

                  <div class="form-group" v-if="overallAssessment">
                    <div class="d-flex align-items-center">
                      <label>Competency code</label>
                      <input
                        type="text"
                        class="form-control form-control-md ml-3"
                        v-model="competencyCode"
                      />
                    </div>
                  </div>

                  <div class="form-group pt-1 mb-3">
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="hideAssessmentLabel"
                      >
                        <input
                          type="checkbox"
                          name="hideAssessmentLabel"
                          v-model="hideAssessmentLabel"
                        />
                        <span
                          for="hideAssessmentLabel"
                          class="checkmark"
                        ></span>
                        Hide assessment labels in learner application?
                      </label>
                    </div>
                    <br />
                    <div class="form-group pt-1">
                      <div class="form-checkboxes d-inline-flex">
                        <label
                          class="custom-checkbox right"
                          name="missedSkills"
                        >
                          <input
                            type="checkbox"
                            name="missedSkills"
                            v-model="enableMissedSkills"
                          />
                          <span for="missedSkills" class="checkmark"></span>
                          Remove Missed Skill on Questions
                        </label>
                      </div>
                    </div>
                    <div class="form-checkboxes d-inline-flex">
                      <label
                        class="custom-checkbox right"
                        name="enableChatImView"
                      >
                        <input
                          type="checkbox"
                          name="hideAssessmentLabel"
                          v-model="enableChatImView"
                        />
                        <span
                          for="hideAssessmentLabel"
                          class="checkmark"
                        ></span>
                        Enable chat view in learner application?
                      </label>
                    </div>
                    <div class="form-group pt-1 mb-3">
                      <div class="form-checkboxes d-inline-flex">
                        <label
                          class="custom-checkbox right"
                          name="learnerRetries"
                        >
                          <input
                            type="checkbox"
                            name="learnerRetries"
                            v-model="enableLearnerRetries"
                          />
                          <span for="learnerRetries" class="checkmark"></span>
                          Enable learner retries
                        </label>
                      </div>
                    </div>
                    <div
                      class="form-group pt-1 mb-3"
                      v-if="enableLearnerRetries"
                    >
                      <label name="maxRetries">
                        Max number of retries
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        name="maxRetries"
                        v-model="maxNumberOfRetries"
                      />
                    </div>
                    <!--<div
                      class="form-group pt-1 mb-3"
                      v-if="enableLearnerRetries"
                    >
                      <div class="form-checkboxes d-inline-flex">
                        <label
                          class="custom-checkbox right"
                          name="enableInstructorOverride"
                        >
                          <input
                            type="checkbox"
                            name="enableInstructorOverride"
                            v-model="enableInstructorOverride"
                            v-bind:value="'enableInstructorOverride'"
                          />
                          <span for="quizAssessment" class="checkmark"></span>
                          Enable instructor overrides on retries
                        </label>
                      </div>
                    </div>-->
                  </div>
                </div>

                <div class="col-sm-7">
                  <div class="form-group customer-dropdown">
                    <label>Select dialogic character</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="customer"
                      v-on:input="getCharactersData"
                      placeholder="Search by name, role & organization"
                      :class="[isActive && !addedCustomer.id ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="!addedCustomer.id"
                      >Add customer is required</span
                    >
                    <div
                      class="user-row-scroll custom-user-dropdown"
                      v-if="customer.length !== 0"
                    >
                      <div
                        class="user-row"
                        v-for="character in searchCharacters"
                        :key="character.id"
                      >
                        <div class="img-container">
                          <!-- <div class="img-box">
                            <img
                              :src="character.char_photo_url"
                              v-if="character.char_photo_url"
                              alt="image"
                            />
                          </div> -->
                          <div class="user-info">
                            <div class="head">
                              {{ character.char_full_name }}
                            </div>
                            <div class="text">
                              {{ character.world_role }} at
                              {{ character.org_name }}
                            </div>
                            <p>
                              {{ character.char_age }} ,
                              {{ character.char_gender | uppCase(true) }}
                            </p>
                          </div>
                        </div>
                        <div class="dropdown-action" v-if="!character.status">
                          <a
                            @click="selectCharacters(character, character.id)"
                            class="add-action"
                            >+</a
                          >
                        </div>
                        <div class="dropdown-action" v-if="character.status">
                          <em class="icon-check"></em>
                          <span>Added</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="customer-dropdown-results"
                    v-if="addedCustomer.id"
                  >
                    <ul class="dropdown-list mt-0">
                      <li class="dropdown-items">
                        <div class="user-row mb-0 flex-1">
                          <div class="img-container">
                            <!-- <div class="img-box">
                              <img
                                :src="addedCustomer.char_photo_url"
                                v-if="addedCustomer.char_photo_url"
                                alt="image"
                              />
                            </div> -->
                            <div class="user-info">
                              <div class="head">
                                {{ addedCustomer.char_full_name }}
                              </div>
                              <div class="text">
                                {{ addedCustomer.world_role }} at
                                {{ addedCustomer.org_name }}
                              </div>
                              <p>
                                {{ addedCustomer.char_age }} ,
                                {{ addedCustomer.char_gender | uppCase(true) }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="dropdown-action">
                          <a
                            v-if="!editWorldStatus"
                            @click="removeCustomer()"
                            class="delete-action"
                            :class="[editWorldStatus ? 'disabled' : '']"
                            >Remove</a
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="form-group" v-if="overallQuizAssessment">
                    <label>Select assessment scheme</label>
                    <div class="quiz-dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="entity"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :class="[
                          isActive &&
                          !assessmentListKey.id &&
                          overallQuizAssessment
                            ? 'invalid'
                            : '',
                        ]"
                      >
                        {{ assessmentListKey.name }}
                        <em class="icon-carrot-down"></em>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="entity">
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          :value="`${assessment.id}*${assessment.attributes.name}`"
                          v-for="assessment of assessmentLists"
                          :key="assessment.id"
                        >
                          {{ assessment.attributes.name }}
                        </option>
                      </div>
                    </div>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="!assessmentListKey.id && overallQuizAssessment"
                      >Assessment Scheme field is required</span
                    >
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <ValidationProvider
                      name="Description"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vue-editor
                        class="form-control"
                        id="Description"
                        :editorToolbar="fullTool"
                        placeholder="Add a Description"
                        v-model="description"
                      ></vue-editor>
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[
                          isActive ? { display: 'block' } : { display: 'none' },
                        ]"
                        v-if="errors[0]"
                        >{{ errors[0].replace("The ", "") }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end mb-4">
                    <button
                      class="btn primary"
                      @click="saveDialogicContent($event, 'entity-setup', 1)"
                    >
                      Next
                    </button>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="
                          $route.query.id ? $parent.archiveLO('archived') : ''
                        "
                        class="text"
                        >Archive this Learning Object(LO)</a
                      >
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <!-- Basic Information end here -->
        <!-- Entity Setup start here -->
        <div
          class="setup-content"
          id="entity-setup"
          v-if="dialogicContentId === 'entity-setup'"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Entity Setup</h4>
            <div class="learning-quiz-btn">
              <a
                v-if="dialogicContentId === 'entity-setup'"
                href="javascript:void(0);"
                class="btn btn-auto header-btn secondary"
                @click="handleImportCSV($event, 'dialog_skill')"
                >Link to Assistant Dialog Skill</a
              >
              <a
                v-if="dialogicContentId === 'entity-setup'"
                href="javascript:void(0);"
                class="btn btn-auto header-btn secondary"
                @click="handleImportCSV($event, 'sync_assistant')"
                >Sync with Assistant</a
              >
              <div
                class="btn btn-auto header-btn secondary btn-type"
                v-if="dialogicContentId === 'entity-setup'"
              >
                <input type="file" class @change="handleImportCSV" />
                Import CSV
              </div>
            </div>
          </div>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label class="big-label">Key topic entities</label>
                    <ul class="form-entity-tabs" v-if="asstEntitiesList">
                      <li
                        v-for="item in asstEntitiesList"
                        :key="item.id"
                        :class="
                          item.id === selectedAsstEntities.id ? 'active' : ''
                        "
                        @click="handleAsstEntitiesList(item)"
                      >
                        <a href="javascript:void(0);">
                          {{ item.attributes && item.attributes.name }}
                          <span>
                            <em
                              @click="handleAsstEntitiesList(item, true)"
                              class="icon-close"
                            ></em>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="form-group pt-3">
                    <label>Add key topic entity</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="entityName"
                    />
                    <div class="form-btn-group">
                      <button class="btn primary" @click="addEntity($event)">
                        Add key topic entity
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="entity-tabs-content mt-0">
                    <div class="row">
                      <div class="col-8 form-group">
                        <label>Label</label>
                        <ValidationProvider
                          name="Label"
                          rules="required|max:100"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            :class="[isActive && errors[0] ? 'invalid' : '']"
                            v-model="selectedAsstEntities.attributes.label"
                            @blur="saveEntityLabel"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[
                              isActive
                                ? { display: 'block' }
                                : { display: 'none' },
                            ]"
                            v-if="errors[0]"
                            >{{ errors[0].replace("The ", "") }}</span
                          >
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 form-group">
                        <label>Value</label>
                        <ValidationProvider
                          name="Value"
                          rules="required|max:100"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            :class="[isActive && errors[0] ? 'invalid' : '']"
                            v-model="asstName"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[
                              isActive
                                ? { display: 'block' }
                                : { display: 'none' },
                            ]"
                            v-if="errors[0]"
                            >{{ errors[0].replace("The ", "") }}</span
                          >
                        </ValidationProvider>
                      </div>
                      <div class="col-6 form-group">
                        <label>Value Type</label>
                        <!-- <label>Synonyms</label>
                        <ValidationProvider
                          name="Synonyms"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            v-model="asstSynonyms"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="errors[0]"
                          >{{ errors[0].replace("The ", "" )}}</span>
                        </ValidationProvider> -->
                        <div>
                          <select v-model="asstType" class="form-control">
                            <option>Synonyms</option>
                            <option>Patterns</option>
                          </select>
                        </div>
                      </div>

                      <div
                        v-if="asstType === 'Synonyms'"
                        class="col-12 form-group"
                      >
                        <ValidationProvider
                          name="Synonyms"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="enter synonyms here"
                            :class="[isActive && errors[0] ? 'invalid' : '']"
                            v-model="asstSynonyms"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[
                              isActive
                                ? { display: 'block' }
                                : { display: 'none' },
                            ]"
                            v-if="errors[0]"
                            >{{ errors[0].replace("The ", "") }}</span
                          >
                        </ValidationProvider>
                        <br />
                        <div class="col-12 form-group">
                          <a
                            href="javascript:void(0);"
                            class="add-more-btn"
                            @click="addAssessmentValues"
                            >Save Value</a
                          >
                        </div>
                      </div>

                      <div
                        v-else-if="asstType === 'Patterns'"
                        class="col-12 form-group"
                      >
                        <ValidationProvider
                          name="Synonyms"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="enter pattern here"
                            :class="[isActive && errors[0] ? 'invalid' : '']"
                            v-model="asstSynonyms"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[
                              isActive
                                ? { display: 'block' }
                                : { display: 'none' },
                            ]"
                            v-if="errors[0]"
                            >{{ errors[0].replace("The ", "") }}</span
                          >
                        </ValidationProvider>
                        <br />
                        <div class="col-12 form-group">
                          <a
                            href="javascript:void(0);"
                            class="add-more-btn"
                            @click="addAssessmentValuesPatterns"
                            >Save Value</a
                          >
                        </div>
                      </div>

                      <!-- <div class="col-12 form-group">
                        <a
                          href="javascript:void(0);"
                          class="add-more-btn"
                          @click="addAssessmentValues"
                        >Save Value</a>
                      </div> -->
                    </div>
                    <div class="quiz-table-data">
                      <div class="quiz-table-head row">
                        <div class="quiz-tab-col col-4">
                          <span>Values</span>
                        </div>
                        <div class="quiz-tab-col col-7">
                          <span>Synonyms/Patterns</span>
                        </div>
                      </div>
                      <!-- quiz table content start -->
                      <div
                        class="quiz-table-content"
                        v-if="selectedAsstEntities"
                      >
                        <!-- quiz table list repeat -->
                        <div
                          class="quiz-table-list"
                          v-for="asstValue of selectedAsstEntities.attributes
                            .asst_entity_values"
                          :key="asstValue.id"
                        >
                          <div
                            class="quiz-tabs-row row"
                            v-if="
                              asstValue.attributes.asst_entity_val_synonyms
                                .length
                            "
                          >
                            <div class="quiz-tab-col col-4">
                              {{ asstValue.attributes.value }}
                            </div>
                            <div
                              class="quiz-tab-col col-7"
                              @click="handleSynonymModal(asstValue)"
                            >
                              <a class="d-block">{{
                                $parent.getAssessmentValue(
                                  asstValue.attributes.asst_entity_val_synonyms
                                )
                              }}</a>
                            </div>

                            <div class="quiz-tab-col col-1 text-right">
                              <a href="javascript:void(0);">
                                <em
                                  @click="removeAsstEntities(asstValue)"
                                  class="icon-close"
                                ></em>
                              </a>
                            </div>
                          </div>
                          <!-- patterns -->
                          <div
                            class="quiz-tabs-row row"
                            v-if="
                              asstValue.attributes.asst_entity_val_patterns && asstValue.attributes.asst_entity_val_patterns
                                .length
                            "
                          >
                            <div class="quiz-tab-col col-4">
                              {{ asstValue.attributes.value }}
                            </div>
                            <div
                              class="quiz-tab-col col-7"
                              @click="handlePatternModal(asstValue)"
                            >
                              <a class="d-block">{{
                                $parent.getAssessmentValuePattern(
                                  asstValue.attributes.asst_entity_val_patterns
                                )
                              }}</a>
                            </div>

                            <div class="quiz-tab-col col-1 text-right">
                              <a href="javascript:void(0);">
                                <em
                                  @click="removeAsstEntities(asstValue)"
                                  class="icon-close"
                                ></em>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- quiz table content end -->
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <button
                      class="btn primary"
                      @click="saveDialogicContent($event, 'question-setup', 2)"
                    >
                      Next
                    </button>
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="
                          $route.query.id ? $parent.archiveLO('archived') : ''
                        "
                        class="text"
                        >Archive this Learning Object(LO)</a
                      >
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <modal
          name="entityValueModal"
          height="auto"
          :width="500"
          :scrollable="true"
          v-if="selectedSynonymList"
        >
          <div class="modal-content modal-default">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Entity value:{{ selectedSynonymList.attributes.value }}</h5>
              </div>
            </div>
            <div class="modal-body">
              <form
                class="modal-form modal-min-height"
                v-on:submit.prevent="updateAssessmentValues('add')"
              >
                <div class="form-group mt-2">
                  <label>Synonyms</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="entitySynonymName"
                  />
                  <div class="chip-container entity-chip">
                    <div
                      class="chip"
                      v-for="(synonym, index) in selectedSynonymList.attributes
                        .asst_entity_val_synonyms"
                      :key="synonym.id"
                    >
                      <span>{{ synonym.attributes.synonym }}</span>
                      <em
                        class="icon-close"
                        @click="
                          updateAssessmentValues('delete', index, synonym)
                        "
                      ></em>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    @click="$modal.hide('entityValueModal')"
                    data-dismiss="modal"
                    class="btn default"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn primary"
                    @click="updateAssessmentValues('done')"
                  >
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </modal>

        <modal
          name="entityValueModalPattern"
          height="auto"
          :width="500"
          :scrollable="true"
          v-if="selectedPatternList"
        >
          <div class="modal-content modal-default">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Entity value:{{ selectedPatternList.attributes.value }}</h5>
              </div>
            </div>
            <div class="modal-body">
              <form
                class="modal-form modal-min-height"
                v-on:submit.prevent="updateAssessmentValuesPattern('add')"
              >
                <div class="form-group mt-2">
                  <label>Patterns</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="entityPatternName"
                  />
                  <div class="chip-container entity-chip">
                    <div
                      class="chip"
                      v-for="(pattern, index) in selectedPatternList.attributes
                        .asst_entity_val_patterns"
                      :key="pattern.id"
                    >
                      <span>{{ pattern.attributes.pattern }}</span>
                      <em
                        class="icon-close"
                        @click="
                          updateAssessmentValuesPattern(
                            'delete',
                            index,
                            pattern
                          )
                        "
                      ></em>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    @click="$modal.hide('entityValueModalPattern')"
                    data-dismiss="modal"
                    class="btn default"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn primary"
                    @click="updateAssessmentValuesPattern('done')"
                  >
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </modal>
        <modal
          name="cloneConfirmationModal"
          height="auto"
          :width="350"
          :scrollable="true"
        >
          <div class="modal-content modal-delete-content">
            <div class="modal-body modal-delete-body">
              Are you sure you want to clone this learning object?
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('cloneConfirmationModal')"
                class="btn medium default"
              >
                Cancel
              </button>
              <button
                type="button"
                @click="cloneLo(), $modal.hide('cloneConfirmationModal')"
                class="btn medium primary"
              >
                Ok
              </button>
            </div>
          </div>
        </modal>
        <!-- Entity Setup end here -->
        <!-- Question Setup start here -->
        <div
          class="setup-content"
          id="question-setup"
          v-if="dialogicContentId === 'question-setup' && backSection === false"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Question Setup</h4>
            <div class="learning-quiz-btn">
              <a
                href="javascript:void(0);"
                :class="`btn header-btn secondary ${
                  QuestionList.length > 0 ? '' : 'disabled'
                }`"
                @click="handleselectQuestionToTest"
                >Test</a
              >
            </div>
          </div>
          <div class="row custom-row">
            <div class="col-sm-5">
              <div class="form-group">
                <ul class="form-entity-tabs">
                  <li
                    :class="addIntro ? 'active' : ''"
                    @click="addQuestion(true, false)"
                  >
                    <a href="javascript:void(0);">Introduction</a>
                  </li>
                  <draggable
                    v-model="QuestionList"
                    :sort="true"
                    :move="reorderQuestions"
                    @start="isDragging = true"
                    @end="isDragging = true"
                  >
                    <li
                      v-for="(question, key) in QuestionList"
                      :key="key"
                      :class="queId === question.id ? 'active' : ''"
                      @click="addQuestion(false, false, question.id, key)"
                    >
                      <a href="javascript:void(0);"
                        >{{ question.attributes.order }}:
                        {{ question.attributes.concept }}
                        <span v-if="queId === question.id">
                          <em
                            @click="$modal.show('deleteQstnConfirmationModal')"
                            class="icon-close"
                          ></em>
                        </span>
                      </a>
                    </li>
                  </draggable>
                  <li
                    :class="addCon ? 'active' : ''"
                    @click="addQuestion(false, true)"
                  >
                    <a href="javascript:void(0);">Conclusion</a>
                  </li>
                </ul>
              </div>
              <div class="form-btn-group">
                <a
                  href="javascript:void(0);"
                  @click="addQuestion(false, false, null)"
                  class="btn btn-full primary"
                  >Add Question</a
                >
              </div>
            </div>
            <div class="col-sm-7">
              <ValidationObserver ref="formdata">
                <form class="w-100">
                  <!-- elevator pitch start  -->
                  <div id="elevator-pitch" v-if="!addIntro && !addCon">
                    <div class="form-group">
                      <label>Concept</label>
                      <ValidationProvider
                        name="Concept"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Concept"
                          :class="[isActive && errors[0] ? 'invalid' : '']"
                          v-model="concept"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors && errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label
                        >Concept Description (Evaluation explanation)</label
                      >
                      <textarea
                        class="form-control"
                        id="conceptDescription"
                        rows="4"
                        v-model="conceptDescription"
                        :class="[isActive && errors[0] ? 'invalid' : '']"
                      ></textarea>
                    </div>
                    <div
                      :class="[
                        'assessment-response',
                        question_variations_attributes.length > 0 ? 'pt-2' : '',
                      ]"
                    >
                      <ul
                        class="response-tab"
                        v-if="question_variations_attributes.length > 0"
                      >
                        <li
                          v-for="(
                            variations, key
                          ) in question_variations_attributes"
                          :key="key"
                          :class="variationsSelect === key + 1 ? 'active' : ''"
                        >
                          <a
                            href="javascript:void(0);"
                            @click="addVariation(key + 1)"
                          >
                            Question Variation
                            {{
                              question_variations_attributes.length > 1
                                ? key + 1
                                : ""
                            }}
                          </a>
                        </li>
                      </ul>
                      <div
                        class="response-content"
                        v-if="question_variations_attributes.length > 0"
                      >
                        <div class="form-group">
                          <div class="form-control p-0">
                            <ValidationProvider
                              name="Variation"
                              rules="required|max:4500"
                              v-slot="{ errors }"
                            >
                              <vue-editor
                                :class="[
                                  'form-control',
                                  isActive && errors[0] ? 'invalid' : '',
                                ]"
                                rows="6"
                                placeholder="variation"
                                :editorToolbar="fullTool"
                                v-model="
                                  question_variations_attributes[
                                    variationsSelect - 1
                                  ].question
                                "
                              ></vue-editor>
                              <span
                                :class="[isActive ? 'invalid' : '']"
                                :style="[
                                  isActive
                                    ? { display: 'block' }
                                    : { display: 'none' },
                                ]"
                                v-if="errors[0]"
                                >{{ errors[0].replace("The ", "") }}</span
                              >
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <a
                        href="javascript:void(0);"
                        class="add-more-btn"
                        @click="
                          question_variations_attributes.length < 5
                            ? addVariation(null)
                            : ''
                        "
                        >+ Add Variation</a
                      >
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[
                          isActive ? { display: 'block' } : { display: 'none' },
                        ]"
                        v-if="question_variations_attributes.length <= 0"
                        >Add Variation field is required</span
                      >
                    </div>
                    <div class="assessment-topics">
                      <div class="topic-head">
                        <div class="row">
                          <div class="col-4">
                            <span>Key Topics:</span>
                          </div>
                          <div class="col-8 topic-keywords">
                            <span>Entity Values:</span>
                          </div>
                        </div>
                      </div>
                      <!-- topic list content start here  -->
                      <div
                        class="topic-list-content"
                        v-if="addAsstEntitiesList.length > 0"
                      >
                        <!-- repeat items code start here -->
                        <div
                          class="topic-items"
                          v-for="(entity, key) in addAsstEntitiesList"
                          :key="key"
                        >
                          <div class="row">
                            <div class="col-4">
                              <span
                                @click="switchToAssessmentSetup(entity.id)"
                                >{{ entity.attributes.name }}</span
                              >
                            </div>
                            <div class="col-8 topic-keywords">
                              <span
                                v-for="synonyms in entity.attributes
                                  .asst_entity_values"
                                :key="synonyms.id"
                                @click="handleSynonymModal(synonyms)"
                                >{{ synonyms.attributes.value }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- repeat items code start here -->
                      </div>
                      <a
                        href="javascript:void(0);"
                        class="add-more-btn"
                        @click="$modal.show('keyTopicsModal')"
                        >+ Add key topic</a
                      >
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[
                          isActive ? { display: 'block' } : { display: 'none' },
                        ]"
                        v-if="addAsstEntitiesList.length <= 0"
                        >Add key topic field is required</span
                      >
                      <!-- topic list content end here  -->
                    </div>
                  </div>
                  <!-- elevator pitch end  -->
                  <!-- Introduction start  -->
                  <div id="introduction" v-else>
                    <div class="form-group">
                      <label>{{
                        addIntro ? "Introduction" : "Conclusion"
                      }}</label>
                      <ValidationProvider
                        :name="addIntro ? 'Introduction' : 'Conclusion'"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <textarea
                          :class="[
                            'form-control',
                            isActive && errors && errors[0] ? 'invalid' : '',
                          ]"
                          rows="6"
                          :placeholder="
                            addIntro ? 'Introduction' : 'Conclusion'
                          "
                          v-model="questionText"
                        ></textarea>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="errors && errors[0]"
                          >{{ errors[0].replace("The ", "") }}</span
                        >
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group pt-3 text-right">
                    <a
                      id="feedback-tab"
                      data-toggle="tab"
                      href="#nav-feedback"
                      role="tab"
                      aria-controls="nav-feedback"
                      @click="handleQuestionChange('')"
                      class="btn secondary"
                      >Save</a
                    >
                  </div>
                  <!-- Introduction end  -->
                </form>
              </ValidationObserver>
            </div>
            <div class="col-sm-12 mt-4">
              <div class="d-flex justify-content-end mt-4 mb-3">
                <button
                  class="btn primary"
                  v-if="overallAssessment"
                  @click="saveDialogicContent($event, 'overall-assessment', 4)"
                >
                  Next
                </button>
                <button class="btn primary" v-else @click="restore()">
                  Publish LO
                </button>
              </div>
              <div class="btn-container justify-content-end">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a
                    @click="
                      $route.query.id ? $parent.archiveLO('archived') : ''
                    "
                    class="text"
                    >Archive this Learning Object(LO)</a
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
        <modal
          name="keyTopicsModal"
          height="auto"
          :width="500"
          :scrollable="true"
          @before-open="beforeOpen"
        >
          <div class="modal-content modal-default">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Key Topics</h5>
              </div>
            </div>
            <div class="modal-body">
              <ValidationObserver ref="form">
                <form class="modal-form modal-min-height">
                  <div class="modal-keyword-checkbox">
                    <div class="modal-keyword-list">
                      <div
                        class="form-checkboxes"
                        v-for="(entities, key) in asstEntitiesList"
                        :key="key"
                      >
                        <p style="display: none">
                          {{
                            (index = addAsstEntitiesList.findIndex((y) => {
                              return parseInt(y.id) === parseInt(entities.id);
                            }))
                          }}
                        </p>
                        <label
                          class="custom-checkbox"
                          :class="[editWorldStatus ? 'disabled' : '']"
                        >
                          <input
                            :disabled="editWorldStatus == true"
                            type="checkbox"
                            name="userOverall1"
                            v-bind:value="'userOverall1'"
                            @click="selectEntity(entities.id)"
                            :checked="index !== -1 ? 'checked' : ''"
                          />
                          <span class="checkmark"></span>
                          <div class="keyword-value">
                            {{ entities.attributes.name }}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      @click="$modal.hide('keyTopicsModal')"
                      data-dismiss="modal"
                      class="btn default"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn primary"
                      @click="$modal.hide('keyTopicsModal')"
                    >
                      Done
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </modal>
        <modal
          name="deleteQstnConfirmationModal"
          height="auto"
          :width="350"
          :scrollable="true"
        >
          <div class="modal-content modal-delete-content">
            <div class="modal-body modal-delete-body">
              It also removes the learner answers/evaluations (if there are
              any). Are you sure you want to delete this question?
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('deleteQstnConfirmationModal')"
                class="btn medium default"
              >
                Cancel
              </button>
              <button
                type="button"
                @click="
                  deleteQuestion(), $modal.hide('deleteQstnConfirmationModal')
                "
                class="btn medium primary"
              >
                Ok
              </button>
            </div>
          </div>
        </modal>
        <!-- Question Setup end here -->
        <!-- Overall Assessment end here -->
        <div
          class="setup-content"
          v-if="dialogicContentId === 'overall-assessment'"
        >
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="head-title">Overall Assessment</h4>
          </div>
          <ValidationObserver
            ref="formOverall"
            v-if="overallAssmntItem && overallAssmntItem.length > 0"
          >
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group">
                    <label>Overall Interaction Assessment</label>
                    <ul
                      class="form-entity-tabs"
                      v-if="overallAssmntItem && overallAssmntItem.length > 0"
                    >
                      <li
                        v-for="assmntItem of overallAssmntItem"
                        :key="assmntItem.id"
                        :class="
                          assmntItem.id == selectedOverallAssmntItem.id
                            ? 'active'
                            : ''
                        "
                        @click="handleOverallAssmntItem(assmntItem)"
                      >
                        <a href="javascript:void(0);">{{
                          assmntItem.attributes.assessment_label
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="question-content" id="number-range">
                    <div class="question-row">
                      <div class="form-group">
                        <label>Evaluation Text</label>
                        <vue-editor
                          id="Descriptiontext"
                          placeholder="Add a Description"
                          :editorToolbar="fullTool"
                          v-model="overallAssmntItemFeedback"
                        ></vue-editor>
                      </div>
                      <div class="form-group pt-2">
                        <label
                          >Evaluation range({{
                            overallAssessmentRange
                          }}):</label
                        >
                        <div class="row mt-2">
                          <div class="col-6">
                            <label>Minimum</label>
                            <ValidationProvider
                              name="Minimum"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                type="number"
                                class="form-control"
                                v-model="overallAssmntItemMinScore"
                                :class="[
                                  isActive && errors[0] ? 'invalid' : '',
                                ]"
                              />
                              <span
                                :class="[isActive ? 'invalid' : '']"
                                :style="[
                                  isActive
                                    ? { display: 'block' }
                                    : { display: 'none' },
                                ]"
                                v-if="errors[0]"
                                >{{ errors[0].replace("The ", "") }}</span
                              >
                            </ValidationProvider>
                          </div>
                          <div class="col-6">
                            <label>Maximum</label>
                            <ValidationProvider
                              name="Maximum"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                type="number"
                                class="form-control"
                                v-model="overallAssmntItemMaxScore"
                                :class="[
                                  isActive && errors[0] ? 'invalid' : '',
                                ]"
                              />
                              <span
                                :class="[isActive ? 'invalid' : '']"
                                :style="[
                                  isActive
                                    ? { display: 'block' }
                                    : { display: 'none' },
                                ]"
                                v-if="errors[0]"
                                >{{ errors[0].replace("The ", "") }}</span
                              >
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-5">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="
                        handleOverallAssmntItem(selectedOverallAssmntItem, true)
                      "
                      >Save & Publish</a
                    >
                  </div>
                  <div class="btn-container justify-content-end">
                    <a class="delete-btn">
                      <div class="delete-box">
                        <span class="icon-delete"></span>
                      </div>
                      <a
                        @click="
                          $route.query.id ? $parent.archiveLO('archived') : ''
                        "
                        class="text"
                        >Archive this Learning Object(LO)</a
                      >
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <!-- Question Assessment end here -->
      </div>
      <modal
        class="modal-video"
        name="dialogicTesting"
        height="auto"
        :width="1000"
        :scrollable="true"
      >
        <div class="modal-content modal-test-content">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
              <h5>Testing</h5>
              <div class="modal-right-box">
                <div class="modal-head-checkbox">
                  <label class="custom-checkbox mr-4">
                    Test entire Dialogic
                    <input type="checkbox" v-on:change="changeQuestionTest" />
                    <span class="checkmark circle"></span>
                  </label>
                </div>
                <!-- <a
                    href="javascript:void(0);"
                    class="btn secondary"
                    v-on:click="resetTest(true)"
                  >Reset test</a> -->
              </div>
            </div>
            <button type="button" class="close" @click="hideTestModal">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body" v-if="allQuestionTestId">
            <form class="modal-form modal-min-height">
              <div class="form-group mt-2">
                <label>Select Question</label>
                <div class="test-dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="entity"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ selectQuestionToTest && selectQuestionToTest.name }}
                    <em class="icon-carrot-down"></em>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="entity">
                    <option
                      class="dropdown-item"
                      v-for="(item, index) of QuestionList"
                      @click="handleselectQuestionToTest($event, true)"
                      :value="index"
                      :key="item.id"
                    >
                      {{ item.attributes.concept }}
                    </option>
                  </div>
                </div>
              </div>
              <div class="question-preview" v-if="ansResponse">
                <div class="form-group d-flex">
                  <div class="test-user-pic">
                    <!-- insert image src here -->
                  </div>
                  <p>
                    {{
                      selectQuestionToTest && selectQuestionToTest.varationName
                    }}
                  </p>
                </div>
                <div
                  class="mb-5"
                  v-if="
                    ansResponse &&
                    ansResponse.attributes &&
                    ansResponse.attributes.follow_up_question
                  "
                >
                  <div class="form-group mb-2">
                    <label>Answer</label>
                  </div>
                  <p>{{ ansResponse.attributes.answer }}</p>
                </div>
              </div>

              <div
                class="question-edit"
                v-if="
                  ansResponse &&
                  ansResponse.attributes &&
                  ansResponse.attributes.follow_up_question
                "
              >
                <div class="form-group d-flex">
                  <div class="test-user-pic">
                    <!-- insert image src here -->
                  </div>
                  <p>
                    {{
                      !ansResponse
                        ? selectQuestionToTest.varationName
                        : ansResponse.attributes.follow_up_question
                    }}
                  </p>
                </div>
                <div class="form-group" v-if="selectQuestionToTest.data">
                  <label>Answer</label>
                  <textarea
                    class="form-control form-textarea"
                    v-model="testQuizAnswer"
                  ></textarea>
                </div>
              </div>
              <div class="question-edit" v-else>
                <div class="form-group">
                  <label>Answer</label>
                  <textarea
                    class="form-control"
                    v-model="testQuizAnswer"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn primary"
                  :disabled="testQuizAnswer.length === 0"
                  v-on:click="handleCheckQuizTest"
                >
                  Test
                </button>
              </div>
            </form>
          </div>
          <div class="modal-body" v-if="!allQuestionTestId">
            <div class="learn-object">
              <div class="learn-object-progress">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    v-bind:style="selectQuestionToTest.statusBar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="learn-object-question">
                  Question {{ selectQuestionToTest.id + 1 }}/{{
                    QuestionList.length
                  }}
                </div>
              </div>
              <div class="learn-object-editor">
                <!-- learn object repeat start -->
                <div
                  class="learn-object-info mt-0"
                  v-if="selectQuestionToTest && selectQuestionToTest.data"
                >
                  <h4>{{ selectQuestionToTest.varationName }}</h4>
                  <!-- <h4>{{selectQuestionToTest.varationName}}</h4> -->
                </div>
                <div class="learn-voice-form">
                  <div class="learn-voice-checkboxes">
                    <div
                      class="mb-5"
                      v-if="
                        ansResponse &&
                        ansResponse.attributes &&
                        ansResponse.attributes.follow_up_question
                      "
                    >
                      <div class="form-group mb-2">
                        <label>Answer</label>
                      </div>
                      <p>{{ ansResponse.attributes.answer }}</p>
                    </div>
                    <div
                      class="question-edit"
                      v-if="
                        ansResponse &&
                        ansResponse.attributes &&
                        ansResponse.attributes.follow_up_question
                      "
                    >
                      <div class="form-group d-flex">
                        <div class="test-user-pic">
                          <!-- insert image src here -->
                        </div>
                        <p>
                          {{
                            !ansResponse
                              ? selectQuestionToTest.varationName
                              : ansResponse.attributes.follow_up_question
                          }}
                        </p>
                      </div>
                      <div class="form-group" v-if="selectQuestionToTest.data">
                        <label>Answer</label>
                        <textarea
                          class="form-control form-textarea"
                          v-model="testQuizAnswer"
                        ></textarea>
                      </div>
                    </div>
                    <div class="question-edit" v-else>
                      <div class="form-group">
                        <label>Answer</label>
                        <textarea
                          class="form-control form-textarea"
                          v-model="testQuizAnswer"
                        ></textarea>
                      </div>
                    </div>
                    <div class>
                      <button
                        class="btn secondary"
                        :disabled="testQuizAnswer.length === 0"
                        v-on:click="handleCheckQuizTest"
                      >
                        Test
                      </button>
                    </div>
                  </div>
                  <div class="learn-object-pagination">
                    <a
                      href="javascript:void(0);"
                      class="btn default"
                      @click="handleTestPage(false)"
                      v-if="selectQuestionToTest.id + 1 !== 1"
                      >Previous</a
                    >
                    <a
                      href="javascript:void(0);"
                      class="btn primary ml-auto"
                      v-if="selectQuestionToTest.id + 1 < totalQuizQues"
                      @click="handleTestPage(true)"
                      >Next</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-test-tabs" v-if="ansResponse">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="Test-tab"
                  data-toggle="tab"
                  href="#Test"
                  role="tab"
                  aria-controls="Test"
                  aria-selected="true"
                  >Test input Return Data</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="json-tab"
                  data-toggle="tab"
                  href="#json"
                  role="tab"
                  aria-controls="json"
                  aria-selected="false"
                  >View JSON</a
                >
              </li>
            </ul>
            <div class="tab-content pt-2" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="Test"
                role="tabpanel"
                aria-labelledby="Test-tab"
              >
                <!-- test items start repeat -->
                <div class="test-items">
                  <label>Character Response:</label>
                  <div class="test-chips-container">
                    <span class="test-chips">
                      {{ ansResponse.attributes.character_response }}
                    </span>
                  </div>
                  <div
                    v-if="
                      ansResponse.attributes.key_topic_evaluations.length > 0
                    "
                  >
                    <div
                      class="test-items"
                      v-for="evaluations in ansResponse.attributes
                        .key_topic_evaluations"
                      :key="evaluations.id"
                    >
                      <label>Assessment and Entity Evaluation Set hit:</label>
                      <div
                        class="test-chips-container"
                        v-if="evaluations.attributes.missed_assmnt_item_id"
                      >
                        <span class="test-chips"> Missed </span>
                      </div>
                      <div class="test-chips-container" v-else>
                        <span class="d-flex flex-wrap">
                          <div class="test-chips">
                            Dialogic entity :
                            {{
                              evaluations.attributes.key_topic &&
                              evaluations.attributes.key_topic.attributes
                                .asst_entities
                                ? evaluations.attributes.key_topic.attributes
                                    .asst_entities.attributes.name
                                : ""
                            }}
                          </div>
                          <div
                            v-if="
                              evaluations.attributes.key_topic &&
                              evaluations.attributes.key_topic.attributes
                                .asst_entities &&
                              evaluations.attributes.key_topic.attributes
                                .asst_entities.attributes.asst_entity_values
                                .length > 0
                            "
                          >
                            <div
                              class="d-flex flex-wrap"
                              v-for="asst_entity_values in evaluations
                                .attributes.key_topic.attributes.asst_entities
                                .attributes.asst_entity_values"
                              :key="asst_entity_values.id"
                            >
                              <p class="test-chips">
                                Value: {{ asst_entity_values.attributes.value }}
                              </p>
                              <p
                                class="test-chips"
                                v-for="synonyms in asst_entity_values.attributes
                                  .asst_entity_val_synonyms"
                                :key="synonyms.id"
                              >
                                {{ synonyms.attributes.synonym }}
                              </p>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- test items end repeat -->
              </div>
              <div
                class="tab-pane fade enrichment-concept-editor"
                id="json"
                role="tabpanel"
                aria-labelledby="json-tab"
              >
                <div class="enrichment-concept-code">
                  <json-viewer
                    :value="ansResponse"
                    :expand-depth="5"
                  ></json-viewer>
                </div>
                <div class="enrichment-code-copy">
                  <copy-to-clipboard
                    :text="JSON.stringify(ansResponse)"
                    @copy="handleCopy"
                  >
                    <a href="javascript:void(0);" class="copy-icon">Copy</a>
                  </copy-to-clipboard>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-test-tabs" v-if="ansResponse1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="Test-tab"
                  data-toggle="tab"
                  href="#Test1"
                  role="tab"
                  aria-controls="Test"
                  aria-selected="true"
                  >Test input Return Data</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="json-tab"
                  data-toggle="tab"
                  href="#json1"
                  role="tab"
                  aria-controls="json"
                  aria-selected="false"
                  >View JSON</a
                >
              </li>
            </ul>
            <div class="tab-content pt-2" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="Test1"
                role="tabpanel"
                aria-labelledby="Test-tab"
              >
                <!-- test items start repeat -->
                <div class="test-items">
                  <label>Character Response:</label>
                  <div class="test-chips-container">
                    <span class="test-chips">
                      {{ ansResponse1.attributes.character_response }}
                    </span>
                  </div>
                  <div
                    v-if="
                      ansResponse1.attributes.key_topic_evaluations.length > 0
                    "
                  >
                    <div
                      class="test-items"
                      v-for="evaluations in ansResponse1.attributes
                        .key_topic_evaluations"
                      :key="evaluations.id"
                    >
                      <label>Assessment and Entity Evaluation Set hit:</label>
                      <div
                        class="test-chips-container"
                        v-if="evaluations.attributes.missed_assmnt_item_id"
                      >
                        <span class="test-chips"> Missed </span>
                      </div>
                      <div class="test-chips-container" v-else>
                        <span class="d-flex flex-wrap">
                          <div class="test-chips">
                            Dialogic entity :
                            {{
                              evaluations.attributes.key_topic &&
                              evaluations.attributes.key_topic.attributes
                                .asst_entities
                                ? evaluations.attributes.key_topic.attributes
                                    .asst_entities.attributes.name
                                : ""
                            }}
                          </div>
                          <div
                            v-if="
                              evaluations.attributes.key_topic &&
                              evaluations.attributes.key_topic.attributes
                                .asst_entities &&
                              evaluations.attributes.key_topic.attributes
                                .asst_entities.attributes.asst_entity_values
                                .length > 0
                            "
                          >
                            <div
                              class="d-flex flex-wrap"
                              v-for="asst_entity_values in evaluations
                                .attributes.key_topic.attributes.asst_entities
                                .attributes.asst_entity_values"
                              :key="asst_entity_values.id"
                            >
                              <p class="test-chips">
                                Value: {{ asst_entity_values.attributes.value }}
                              </p>
                              <p
                                class="test-chips"
                                v-for="synonyms in asst_entity_values.attributes
                                  .asst_entity_val_synonyms"
                                :key="synonyms.id"
                              >
                                {{ synonyms.attributes.synonym }}
                              </p>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- test items end repeat -->
              </div>
              <div
                class="tab-pane fade enrichment-concept-editor"
                id="json1"
                role="tabpanel"
                aria-labelledby="json-tab"
              >
                <div class="enrichment-concept-code">
                  <json-viewer
                    :value="ansResponse1"
                    :expand-depth="5"
                  ></json-viewer>
                </div>
                <div class="enrichment-code-copy">
                  <copy-to-clipboard
                    :text="JSON.stringify(ansResponse1)"
                    @copy="handleCopy"
                  >
                    <a href="javascript:void(0);" class="copy-icon">Copy</a>
                  </copy-to-clipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <!-- question setup back section start here -->
      <div :class="['back-section', backSection ? '' : 'd-none']">
        <div class="learning-form-header flex-wrap">
          <h4>
            <a
              href="javascript:void(0);"
              class="back-btn-inner"
              @click="backSectionData(false)"
            >
              <span class="icon-back"></span>
              Back to Question Setup
            </a>
          </h4>
          <div class="learning-quiz-btn">
            <a
              href="javascript:void(0);"
              class="btn header-btn primary"
              @click="restore()"
              >Publish LO</a
            >
          </div>
        </div>

        <div class="learning-quiz-content">
          <div class="setup-content" id="question-setup">
            <div class="d-flex justify-content-between align-items-center mt-5">
              <h4 class="head-title">Assessment & Responses</h4>
            </div>
            <ValidationObserver ref="formAssessment">
              <form class="w-100">
                <div class="row custom-row">
                  <div class="col-sm-5">
                    <div class="form-group">
                      <label>Select Concept</label>
                      <div class="quiz-dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="entity"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {{
                            Selectedconcept && Selectedconcept.attributes
                              ? Selectedconcept.attributes.concept
                              : ""
                          }}
                          <em class="icon-carrot-down"></em>
                        </button>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="!Selectedconcept && !Selectedconcept.attributes"
                          >Concept is required</span
                        >
                        <div class="dropdown-menu" aria-labelledby="entity">
                          <option
                            class="dropdown-item"
                            v-for="(assItem, key) in QuestionList"
                            :key="assItem.id"
                            :value="assItem.id"
                            @click="setKey(assItem.id, key, 'concept')"
                          >
                            {{ assItem.attributes.concept }}
                          </option>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <div class="form-group col-sm-9 px-0">
                      <label>Key Topic</label>
                      <div class="quiz-dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="entity"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {{
                            SelectedKeyTopic && SelectedKeyTopic.attributes
                              ? SelectedKeyTopic.attributes.asst_entities
                                  .attributes.name
                              : ""
                          }}
                          <em class="icon-carrot-down"></em>
                        </button>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[
                            isActive
                              ? { display: 'block' }
                              : { display: 'none' },
                          ]"
                          v-if="
                            !SelectedKeyTopic && !SelectedKeyTopic.attributes
                          "
                          >Key Topic is required</span
                        >
                        <div class="dropdown-menu" aria-labelledby="entity">
                          <option
                            v-for="(key, index) in keyTopic"
                            :key="key.id"
                            class="dropdown-item"
                            :value="key.id"
                            @click="setKey(key.id, index, 'key')"
                          >
                            {{ key.attributes.asst_entities.attributes.name }}
                          </option>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="form-group">
                      <ul
                        class="form-entity-tabs"
                        v-if="assessmentScheme && assessmentScheme.attributes"
                      >
                        <li
                          v-for="assessment in assessmentScheme.attributes
                            .assessment_labels"
                          :key="assessment.id"
                          :class="
                            assessment_labels === assessment.id ? 'active' : ''
                          "
                        >
                          <a
                            href="javascript:void(0);"
                            @click="setAssessmentScheme(assessment.id)"
                          >
                            {{ assessment.attributes.name }}
                            <span>
                              <em class="icon-close"></em>
                            </span>
                          </a>
                        </li>
                        <li
                          :class="!assessment_labels ? 'active' : ''"
                          v-if="!enableMissedSkills"
                        >
                          <a
                            href="javascript:void(0);"
                            @click="setAssessmentScheme(null)"
                          >
                            Missed
                            <span>
                              <em class="icon-close"></em>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <!-- Response - Debrief - Adaptive navigation tabs -->
                    <div class="nav nav-tabs" id="nav-tab2" role="tablist2">
                      <a
                        :class="[
                          'nav-item nav-link',
                          parseInt(formValue) === parseInt(1) ? 'active' : '',
                        ]"
                        id="character-tab"
                        data-toggle="tab"
                        href="#character-response"
                        role="tab"
                        @click="changeFormData(1)"
                        aria-controls="character-response"
                        aria-selected="true"
                        >Character Response</a
                      >

                      <a
                        v-if="dialogic_assmnt_item"
                        :class="[
                          'nav-item nav-link',
                          parseInt(formValue) === parseInt(2) ? 'active' : '',
                          !dialogic_assmnt_item ? 'nav-link-disabled' : '',
                        ]"
                        id="debrief-tab"
                        @click="changeFormData(2)"
                        data-toggle="tab"
                        href="#debrief-content"
                        role="tab"
                        aria-controls="debrief-content"
                        aria-selected="false"
                        >Debrief Content</a
                      >

                      <a
                        v-else
                        :class="[
                          'nav-item nav-link',
                          parseInt(formValue) === parseInt(2) ? 'active' : '',
                          !dialogic_assmnt_item ? 'nav-link-disabled' : '',
                        ]"
                        >Debrief Content</a
                      >

                      <a
                        id="adaptive-tab"
                        v-if="dialogic_assmnt_item"
                        @click="changeFormData(3)"
                        data-toggle="tab"
                        href="#adaptive-content"
                        role="tab"
                        aria-controls="adaptive-content"
                        aria-selected="false"
                        :class="[
                          'nav-item nav-link',
                          parseInt(formValue) === parseInt(3) ? 'active' : '',
                          !dialogic_assmnt_item ? 'nav-link-disabled' : '',
                        ]"
                        >Adaptive Content</a
                      >

                      <a
                        v-else
                        :class="[
                          'nav-item nav-link',
                          parseInt(formValue) === parseInt(3) ? 'active' : '',
                          !dialogic_assmnt_item ? 'nav-link-disabled' : '',
                        ]"
                        >Adaptive Content</a
                      >
                    </div>

                    <div class="tab-content" id="nav-tabContent2">
                      <!-- Question Tab -->
                      <div
                        class="tab-pane fade show active"
                        id="character-response"
                        role="tabpanel"
                        aria-labelledby="character-tab"
                        v-if="parseInt(formValue) === parseInt(1)"
                      >
                        <!-- Main character response iterations & variations  -->
                        <div id="mastery" v-if="assessment_labels">
                          <div class="assessment-response pt-2">
                            <!-- Iteration tabs -->
                            <ul class="response-tab">
                              <li
                                v-for="(data, index) in setVariationList"
                                :key="index"
                                :class="
                                  selIterationLevel === index ? 'active' : ''
                                "
                                @click="
                                  handleSelectedIteration(
                                    index,
                                    'iteration',
                                    false
                                  )
                                "
                              >
                                <a href="javascript:void(0);"
                                  >Iteration {{ index + 1 }}</a
                                >
                              </li>
                            </ul>

                            <!-- Variation tabs -->
                            <ul
                              class="response-tab"
                              v-if="
                                setVariationList[selIterationLevel] &&
                                setVariationList[selIterationLevel].variation
                              "
                            >
                              <li
                                v-for="(vari, index) of setVariationList[
                                  selIterationLevel
                                ].variation"
                                :key="index"
                                :class="
                                  selVariationLevel === index ? 'active' : ''
                                "
                                @click="
                                  handleSelectedIteration(
                                    index,
                                    'variation',
                                    false
                                  )
                                "
                              >
                                <a href="javascript:void(0);"
                                  >Variation {{ index + 1 }}</a
                                >
                              </li>
                            </ul>

                            <!-- Character response content text field -->
                            <div
                              class="response-content"
                              v-if="
                                setVariationList[selIterationLevel] &&
                                setVariationList[selIterationLevel].variation
                              "
                            >
                              <div class="form-group">
                                <div class="form-control p-0">
                                  <ValidationProvider
                                    name="Variation"
                                    rules="max:2000"
                                    v-slot="{ errors }"
                                  >
                                    <vue-editor
                                      class="form-control"
                                      id="Variation"
                                      placeholder="Variation"
                                      :editorToolbar="fullTool"
                                      v-model="
                                        setVariationList[selIterationLevel]
                                          .variation[selVariationLevel].response
                                      "
                                    ></vue-editor>
                                    <span
                                      :class="[isActive ? 'invalid' : '']"
                                      :style="[
                                        isActive
                                          ? { display: 'block' }
                                          : { display: 'none' },
                                      ]"
                                      v-if="errors[0]"
                                      >{{ errors[0].replace("The ", "") }}</span
                                    >
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>

                            <!-- Main character response add variation button -->
                            <a
                              href="javascript:void(0);"
                              class="add-more-btn"
                              @click="
                                setVariationList[selIterationLevel].variation
                                  .length < 5
                                  ? handleAddVariation(selIterationLevel, false)
                                  : ''
                              "
                              >+ Add Variation</a
                            >
                          </div>

                          <!-- Minimum / Maximum entity values section -->
                          <div class="form-group pt-4">
                            <div class="row mt-2">
                              <div class="col-6">
                                <label>Minimum</label>
                                <ValidationProvider
                                  name="Minimum"
                                  rules="required|numeric"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="age"
                                    class="form-control"
                                    id="Points"
                                    v-model.number="dialogicAssmntItemMinScore"
                                    :class="[
                                      isActive && errors[0] ? 'invalid' : '',
                                    ]"
                                  />
                                  <span
                                    :class="[isActive ? 'invalid' : '']"
                                    :style="[
                                      isActive
                                        ? { display: 'block' }
                                        : { display: 'none' },
                                    ]"
                                    v-if="errors[0]"
                                    >{{ errors[0].replace("The ", "") }}</span
                                  >
                                </ValidationProvider>
                              </div>
                              <div class="col-6">
                                <label>Maximum</label>
                                <ValidationProvider
                                  name="Maximum"
                                  rules="required|numeric"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="age"
                                    class="form-control"
                                    id="Points"
                                    v-model.number="dialogicAssmntItemMaxScore"
                                    :class="[
                                      isActive && errors[0] ? 'invalid' : '',
                                    ]"
                                  />
                                  <span
                                    :class="[isActive ? 'invalid' : '']"
                                    :style="[
                                      isActive
                                        ? { display: 'block' }
                                        : { display: 'none' },
                                    ]"
                                    v-if="errors[0]"
                                    >{{ errors[0].replace("The ", "") }}</span
                                  >
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>

                          <!-- Use enhanced responses checkbox -->
                          <div class="form-group mb-1">
                            <div class="form-checkboxes d-inline-flex">
                              <label
                                class="custom-checkbox right"
                                name="userOverall"
                                :class="[editWorldStatus ? 'disabled' : '']"
                              >
                                <input
                                  :disabled="editWorldStatus == true"
                                  type="checkbox"
                                  name="useEnhancedResponses"
                                  v-model="useEnhanced"
                                  v-bind:value="true"
                                />
                                <span
                                  for="useEnhancedResponses"
                                  class="checkmark"
                                ></span>
                                Use enhanced responses?
                              </label>
                            </div>
                          </div>

                          <!-- Enhanced responses section -->
                          <div class="form-group" v-if="useEnhanced == true">
                            <ul>
                              <li
                                v-for="(
                                  item, index
                                ) in setEnhancedVariationList"
                                :key="item.id"
                                :class="item.id"
                              >
                                <div class="enhanced-response-box">
                                  <div class="mb-2">
                                    <div class="enhanced-label">
                                      Entity value:
                                      <span>{{ item.asst_entity_value }}</span>
                                    </div>
                                  </div>
                                  <div class="enhanced-response-container">
                                    <div>Present response:</div>
                                    <div
                                      class="
                                        assessment-response
                                        pt-2
                                        enhanced-response
                                      "
                                    >
                                      <!-- Present iteration tabs -->
                                      <ul class="response-tab">
                                        <li
                                          v-for="(
                                            data, index
                                          ) in setEnhancedVariationList[index]
                                            .iteration"
                                          :key="index"
                                          :class="
                                            selEnhancedIterationLevel === index
                                              ? 'active'
                                              : ''
                                          "
                                          @click="
                                            handleEnhancedSelectedIteration(
                                              index,
                                              'iteration',
                                              item.id
                                            )
                                          "
                                        >
                                          <a href="javascript:void(0);"
                                            >Iteration {{ index + 1 }}</a
                                          >
                                        </li>
                                      </ul>

                                      <!-- Present variation tabs -->
                                      <ul
                                        class="response-tab"
                                        v-if="
                                          setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ].variation
                                        "
                                      >
                                        <li
                                          v-for="(
                                            vari, index
                                          ) of setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ].variation"
                                          :key="index"
                                          :class="
                                            selEnhancedVariationLevel === index
                                              ? 'active'
                                              : ''
                                          "
                                          @click="
                                            handleEnhancedSelectedIteration(
                                              index,
                                              'variation',
                                              item.id
                                            )
                                          "
                                        >
                                          <a href="javascript:void(0);"
                                            >Variation {{ index + 1 }}</a
                                          >
                                        </li>
                                      </ul>
                                      <div
                                        class="response-content"
                                        v-if="
                                          setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ] &&
                                          setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ].variation[selEnhancedVariationLevel]
                                        "
                                      >
                                        <div class="form-group">
                                          <div class="form-control p-0">
                                            <!-- <ValidationProvider
                                                name="Variation"
                                                rules="required|max:500"
                                                v-slot="{ errors }"
                                              > -->
                                            <!-- :class="['form-control',(isActive && errors[0]) ? 'invalid' : '']" -->
                                            <textarea
                                              class="form-control"
                                              rows="6"
                                              placeholder="variation"
                                              v-model="
                                                setEnhancedVariationList[index]
                                                  .iteration[
                                                  selEnhancedIterationLevel
                                                ].variation[
                                                  selEnhancedVariationLevel
                                                ].present_response
                                              "
                                            ></textarea>
                                            <!-- <span
                                                  :class="[isActive ? 'invalid' : '']"
                                                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                                  v-if="errors[0]"
                                                >{{ errors[0].replace("The ", "" )}}</span>
                                              </ValidationProvider> -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div>Absent response:</div>
                                    <div
                                      class="
                                        assessment-response
                                        pt-2
                                        enhanced-response
                                      "
                                    >
                                      <!-- Absent iteration tabs -->
                                      <ul class="response-tab">
                                        <li
                                          v-for="(
                                            data, index
                                          ) in setEnhancedVariationList[index]
                                            .iteration"
                                          :key="index"
                                          :class="
                                            selEnhancedIterationLevel === index
                                              ? 'active'
                                              : ''
                                          "
                                          @click="
                                            handleEnhancedSelectedIteration(
                                              index,
                                              'iteration',
                                              item.id
                                            )
                                          "
                                        >
                                          <a href="javascript:void(0);"
                                            >Iteration {{ index + 1 }}</a
                                          >
                                        </li>
                                      </ul>

                                      <!-- Absent variation tabs -->
                                      <ul
                                        class="response-tab"
                                        v-if="
                                          setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ].variation
                                        "
                                      >
                                        <li
                                          v-for="(
                                            vari, index
                                          ) of setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ].variation"
                                          :key="index"
                                          :class="
                                            selEnhancedVariationLevel === index
                                              ? 'active'
                                              : ''
                                          "
                                          @click="
                                            handleEnhancedSelectedIteration(
                                              index,
                                              'variation',
                                              item.id
                                            )
                                          "
                                        >
                                          <a href="javascript:void(0);"
                                            >Variation {{ index + 1 }}</a
                                          >
                                        </li>
                                      </ul>
                                      <div
                                        class="response-content"
                                        v-if="
                                          setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ] &&
                                          setEnhancedVariationList[index]
                                            .iteration[
                                            selEnhancedIterationLevel
                                          ].variation[selEnhancedVariationLevel]
                                        "
                                      >
                                        <div class="form-group">
                                          <div class="form-control p-0">
                                            <!-- <ValidationProvider
                                                name="Variation"
                                                rules="required|max:500"
                                                v-slot="{ errors }"
                                              > -->
                                            <!-- :class="['form-control',(isActive && errors[0]) ? 'invalid' : '']" -->
                                            <textarea
                                              class="form-control"
                                              rows="6"
                                              placeholder="variation"
                                              v-model="
                                                setEnhancedVariationList[index]
                                                  .iteration[
                                                  selEnhancedIterationLevel
                                                ].variation[
                                                  selEnhancedVariationLevel
                                                ].absent_response
                                              "
                                            ></textarea>
                                            <!-- <span
                                                  :class="[isActive ? 'invalid' : '']"
                                                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                                  v-if="errors[0]"
                                                >{{ errors[0].replace("The ", "" )}}</span>
                                              </ValidationProvider> -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div class="form-group">
                            <label>Required entity value(s)</label>
                            <input
                              type="text"
                              v-model="searchEntityValue"
                              v-on:input="filterEntity"
                              class="form-control"
                              placeholder="Search entity value"
                            />
                            <!-- <div class="chip-container entity-chip pb-0">
                              <div class="chip"><span>Global skill</span><em class="icon-close"></em></div>
                            </div>-->
                            <div class="chip-container pt-2 pb-0 entity-chip">
                              <div
                                class="chip"
                                v-for="(entity, i) of selectedEntityValue"
                                :key="entity.id"
                              >
                                <span>{{ entity.attributes.value }}</span>
                                <em
                                  class="icon-close"
                                  @click="deleteSeletedentity(i)"
                                ></em>
                              </div>
                            </div>
                            <div
                              class="user-row-scroll custom-user-dropdown"
                              v-if="
                                searchEntityValue.length > 0 && entityStatus
                              "
                            >
                              <div
                                class="user-row"
                                v-for="entitys in searchEntityValues"
                                :key="entitys.id"
                              >
                                <div class="img-container">
                                  <div class="user-info">
                                    <div class="head">
                                      {{ entitys.attributes.value }}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="dropdown-action"
                                  v-if="entitys.status"
                                >
                                  <em class="icon-check"></em>
                                  <span>Added</span>
                                </div>
                                <div
                                  class="dropdown-action"
                                  v-if="!entitys.status"
                                >
                                  <a
                                    class="add-action"
                                    @click="addEntityData(entitys)"
                                    >+</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="row mt-2">
                              <div class="col-6">
                                <label>Points</label>
                                <ValidationProvider
                                  name="Points"
                                  rules="required|numeric"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="age"
                                    class="form-control"
                                    id="Points"
                                    v-model.number="points"
                                    :class="[
                                      isActive && errors[0] ? 'invalid' : '',
                                    ]"
                                  />
                                  <span
                                    :class="[isActive ? 'invalid' : '']"
                                    :style="[
                                      isActive
                                        ? { display: 'block' }
                                        : { display: 'none' },
                                    ]"
                                    v-if="errors[0]"
                                    >{{ errors[0].replace("The ", "") }}</span
                                  >
                                </ValidationProvider>
                              </div>
                              <div class="col-6">
                                <label>Follow-up Points</label>
                                <ValidationProvider
                                  name="Follow-up Points"
                                  rules="numeric"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    type="age"
                                    class="form-control"
                                    id="followUpPoint"
                                    v-model.number="followUpPoint"
                                    :class="[
                                      isActive && errors[0] ? 'invalid' : '',
                                    ]"
                                  />
                                  <span
                                    :class="[isActive ? 'invalid' : '']"
                                    :style="[
                                      isActive
                                        ? { display: 'block' }
                                        : { display: 'none' },
                                    ]"
                                    v-if="errors[0]"
                                    >{{ errors[0].replace("The ", "") }}</span
                                  >
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <div class="form-group mb-1">
                            <div class="form-checkboxes d-inline-flex">
                              <label
                                class="custom-checkbox right"
                                name="userOverall"
                                :class="[editWorldStatus ? 'disabled' : '']"
                              >
                                <input
                                  :disabled="editWorldStatus == true"
                                  type="checkbox"
                                  name="userOverall"
                                  v-model="isFollowUp"
                                  v-bind:value="'userOverall'"
                                />
                                <span
                                  for="userOverall"
                                  class="checkmark"
                                ></span>
                                Ask follow-up question?
                              </label>
                            </div>
                          </div>
                          <!-- <div class="form-group pt-3" v-if="isFollowUp">
                            <div class="form-control">
                              <ValidationProvider
                                name="Description"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <vue-editor
                                  id="Descriptiontext"
                                  placeholder="Follow-up question"
                                  :editorToolbar="fullTool"
                                  v-model="followUpQuestion"
                                ></vue-editor>
                                <span
                                  :class="[isActive ? 'invalid' : '']"
                                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                  v-if="errors[0]"
                                >{{ errors[0].replace("The ", "" )}}</span>
                              </ValidationProvider>
                            </div>
                          </div>-->
                          <div class="form-group pt-4" v-if="isFollowUp">
                            <label>Follow-Up Questions</label>
                            <div
                              :class="[
                                'assessment-response',
                                question_variations_attributes.length > 0
                                  ? 'pt-2'
                                  : '',
                              ]"
                            >
                              <ul
                                class="response-tab"
                                v-if="question_variations_attributes.length > 0"
                              >
                                <li
                                  v-for="(
                                    variations, key
                                  ) in question_variations_attributes"
                                  :key="key"
                                  :class="
                                    variationsSelect === key + 1 ? 'active' : ''
                                  "
                                >
                                  <a
                                    href="javascript:void(0);"
                                    @click="addVariation(key + 1)"
                                  >
                                    Iteration
                                    {{
                                      question_variations_attributes.length > 1
                                        ? key + 1
                                        : ""
                                    }}
                                  </a>
                                </li>
                              </ul>
                              <div
                                class="response-content"
                                v-if="question_variations_attributes.length > 0"
                              >
                                <div class="form-group">
                                  <div class="form-control p-0">
                                    <ValidationProvider
                                      name="Iteration"
                                      rules="required|max:4500"
                                      v-slot="{ errors }"
                                    >
                                      <textarea
                                        :class="[
                                          'form-control',
                                          isActive && errors[0]
                                            ? 'invalid'
                                            : '',
                                        ]"
                                        rows="4"
                                        placeholder="Iteration"
                                        v-model="
                                          question_variations_attributes[
                                            variationsSelect - 1
                                          ].question
                                        "
                                      ></textarea>
                                      <span
                                        :class="[isActive ? 'invalid' : '']"
                                        :style="[
                                          isActive
                                            ? { display: 'block' }
                                            : { display: 'none' },
                                        ]"
                                        v-if="errors[0]"
                                        >{{
                                          errors[0].replace("The ", "")
                                        }}</span
                                      >
                                    </ValidationProvider>
                                  </div>
                                </div>
                              </div>
                              <a
                                href="javascript:void(0);"
                                class="add-more-btn"
                                @click="
                                  question_variations_attributes.length < 3
                                    ? addVariation(null)
                                    : ''
                                "
                                >+ Add Iteration</a
                              >
                              <span
                                :class="[isActive ? 'invalid' : '']"
                                :style="[
                                  isActive
                                    ? { display: 'block' }
                                    : { display: 'none' },
                                ]"
                                v-if="
                                  question_variations_attributes.length <= 0
                                "
                                >Follow up question is required</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- mastery content end -->
                        <!-- missed content start -->
                        <div id="missed" v-else>
                          <div class="assessment-response">
                            <ul class="response-tab">
                              <li
                                v-for="(data, index) in setVariationList"
                                :key="index"
                                :class="
                                  selIterationLevel === index ? 'active' : ''
                                "
                                @click="
                                  handleSelectedIteration(
                                    index,
                                    'iteration',
                                    false
                                  )
                                "
                              >
                                <a href="javascript:void(0);"
                                  >Iteration {{ index + 1 }}</a
                                >
                              </li>
                            </ul>
                            <ul
                              class="response-tab"
                              v-if="
                                setVariationList[selIterationLevel] &&
                                setVariationList[selIterationLevel].variation
                              "
                            >
                              <li
                                v-for="(vari, index) of setVariationList[
                                  selIterationLevel
                                ].variation"
                                :key="index"
                                :class="
                                  selVariationLevel === index ? 'active' : ''
                                "
                                @click="
                                  handleSelectedIteration(
                                    index,
                                    'variation',
                                    false
                                  )
                                "
                              >
                                <a href="javascript:void(0);"
                                  >Variation {{ index + 1 }}</a
                                >
                              </li>
                            </ul>
                            <div
                              class="response-content"
                              v-if="
                                setVariationList[selIterationLevel] &&
                                setVariationList[selIterationLevel].variation
                              "
                            >
                              <div class="form-group">
                                <div class="form-control p-0">
                                  <ValidationProvider
                                    name="Variation"
                                    rules="max:2000"
                                    v-slot="{ errors }"
                                  >
                                    <vue-editor
                                      class="form-control"
                                      id="Variation"
                                      placeholder="Variation"
                                      :editorToolbar="fullTool"
                                      v-model="
                                        setVariationList[selIterationLevel]
                                          .variation[selVariationLevel].response
                                      "
                                    ></vue-editor>
                                    <span
                                      :class="[isActive ? 'invalid' : '']"
                                      :style="[
                                        isActive
                                          ? { display: 'block' }
                                          : { display: 'none' },
                                      ]"
                                      v-if="errors[0]"
                                      >{{ errors[0].replace("The ", "") }}</span
                                    >
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <a
                              href="javascript:void(0);"
                              class="add-more-btn"
                              @click="
                                setVariationList[selIterationLevel].variation
                                  .length < 5
                                  ? handleAddVariation(selIterationLevel, false)
                                  : ''
                              "
                              >+ Add Variation</a
                            >
                          </div>
                          <div class="form-group mt-4 pt-3">
                            <div class="pb-4">
                              <div class="form-checkboxes d-inline-flex">
                                <label
                                  class="custom-checkbox right"
                                  name="userOverall"
                                  :class="[editWorldStatus ? 'disabled' : '']"
                                >
                                  <input
                                    :disabled="editWorldStatus == true"
                                    type="checkbox"
                                    name="userOverall"
                                    v-model="isFollowUp"
                                    v-bind:value="'userOverall'"
                                  />
                                  <span
                                    for="userOverall"
                                    class="checkmark"
                                  ></span>
                                  Ask follow-up question?
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group" v-if="isFollowUp">
                            <label>Missed Follow-Up Questions</label>
                            <div
                              :class="[
                                'assessment-response',
                                question_variations_attributes.length > 0
                                  ? 'pt-2'
                                  : '',
                              ]"
                            >
                              <ul
                                class="response-tab"
                                v-if="question_variations_attributes.length > 0"
                              >
                                <li
                                  v-for="(
                                    variations, key
                                  ) in question_variations_attributes"
                                  :key="key"
                                  :class="
                                    variationsSelect === key + 1 ? 'active' : ''
                                  "
                                >
                                  <a
                                    href="javascript:void(0);"
                                    @click="addVariation(key + 1)"
                                  >
                                    Iteration
                                    {{
                                      question_variations_attributes.length > 1
                                        ? key + 1
                                        : ""
                                    }}
                                  </a>
                                </li>
                              </ul>
                              <div
                                class="response-content"
                                v-if="question_variations_attributes.length > 0"
                              >
                                <div class="form-group">
                                  <div class="form-control p-0">
                                    <ValidationProvider
                                      name="Iteration"
                                      rules="required|max:4500"
                                      v-slot="{ errors }"
                                    >
                                      <textarea
                                        :class="[
                                          'form-control',
                                          isActive && errors[0]
                                            ? 'invalid'
                                            : '',
                                        ]"
                                        rows="4"
                                        placeholder="Iteration"
                                        v-model="
                                          question_variations_attributes[
                                            variationsSelect - 1
                                          ].question
                                        "
                                      ></textarea>
                                      <span
                                        :class="[isActive ? 'invalid' : '']"
                                        :style="[
                                          isActive
                                            ? { display: 'block' }
                                            : { display: 'none' },
                                        ]"
                                        v-if="errors[0]"
                                        >{{
                                          errors[0].replace("The ", "")
                                        }}</span
                                      >
                                    </ValidationProvider>
                                  </div>
                                </div>
                              </div>
                              <a
                                href="javascript:void(0);"
                                class="add-more-btn"
                                @click="
                                  question_variations_attributes.length < 3
                                    ? addVariation(null)
                                    : ''
                                "
                                >+ Add Iteration</a
                              >
                              <span
                                :class="[isActive ? 'invalid' : '']"
                                :style="[
                                  isActive
                                    ? { display: 'block' }
                                    : { display: 'none' },
                                ]"
                                v-if="
                                  question_variations_attributes.length <= 0
                                "
                                >Follow up question field is required</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- missed content end -->
                      </div>
                      <!-- Feedback Tab -->
                      <div
                        class="tab-pane fade show active"
                        id="debrief-content"
                        role="tabpanel"
                        aria-labelledby="debrief-tab"
                        v-if="parseInt(formValue) === parseInt(2)"
                      >
                        <div
                          :class="[
                            'assessment-response',
                            variations_briefattributes.length > 0 ? 'pt-2' : '',
                          ]"
                        >
                          <ul
                            class="response-tab"
                            v-if="variations_briefattributes.length > 0"
                          >
                            <li
                              v-for="(
                                variations, key
                              ) in variations_briefattributes"
                              :key="key"
                              :class="
                                variationsSelect === key + 1 ? 'active' : ''
                              "
                            >
                              <a
                                href="javascript:void(0);"
                                @click="addDebriefVariation(key + 1, 'debrief')"
                              >
                                Variation
                                {{
                                  variations_briefattributes.length > 1
                                    ? key + 1
                                    : ""
                                }}
                              </a>
                            </li>
                          </ul>
                          <div
                            class="response-content"
                            v-if="variations_briefattributes.length > 0"
                          >
                            <div class="form-group">
                              <div class="form-control p-0">
                                <ValidationProvider
                                  name="Variation"
                                  rules="required|max:4500"
                                  v-slot="{ errors }"
                                >
                                  <vue-editor
                                    :class="[
                                      'form-control',
                                      isActive && errors[0] ? 'invalid' : '',
                                    ]"
                                    :editorToolbar="fullTool"
                                    placeholder="Variation"
                                    v-model="
                                      variations_briefattributes[
                                        variationsSelect - 1
                                      ].content
                                    "
                                  ></vue-editor>

                                  <!-- <span
                                    :class="[isActive ? 'invalid' : '']"
                                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                    v-if="errors[0]"
                                  >{{ errors[0].replace("The ", "" )}}</span> -->
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="add-more-btn"
                            @click="
                              variations_briefattributes.length < 5
                                ? addDebriefVariation(null, 'debrief')
                                : ''
                            "
                            >+ Add Variation</a
                          >
                          <!-- <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="variations_briefattributes.length <= 0"
                          >Add Variation field is required</span> -->
                        </div>
                      </div>
                      <!-- Adaptive Tab -->
                      <div
                        class="tab-pane fade show active"
                        id="adaptive-content"
                        role="tabpanel"
                        aria-labelledby="adaptive-tab"
                        v-if="parseInt(formValue) === parseInt(3)"
                      >
                        <div class="d-flex align-items-center">
                          <h5 class="small-title">Add Adaptive Content</h5>
                          <div class="px-4 learning-quiz-btn">
                            <a
                              @click="
                                $modal.show('uploadInterstitialModal', {
                                  create: true,
                                  content: 'adaptive',
                                })
                              "
                              class="btn ml-2 px-4 secondary"
                              >Upload</a
                            >
                          </div>
                        </div>

                        <div
                          class="learning-data-list flex-column pt-3"
                          v-if="adaptiveLists.length > 0"
                        >
                          <div
                            class="
                              learning-data-item
                              w-100
                              learning-interstitial-data-item
                            "
                            v-for="(item, i) in adaptiveLists"
                            :key="item.id"
                          >
                            <div class="learning-data-left">
                              <div
                                class="learning-data-richText"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'text_learn_obj'
                                "
                              ></div>
                              <div
                                class="learning-data-icon"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'file_learn_obj'
                                "
                              >
                                <a>
                                  <img
                                    src="../../assets/images/pdf-big-icon.png"
                                    alt="pdf"
                                  />
                                </a>
                              </div>
                              <div
                                class="learning-data-icon"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'slide_learn_obj'
                                "
                              >
                                <a
                                  v-if="
                                    item.attributes.content_details.attributes
                                      .slider_images.length > 0
                                  "
                                >
                                  <img
                                    v-if="
                                      item.attributes.content_details.attributes
                                        .slider_images.length > 0
                                    "
                                    :src="
                                      item.attributes.content_details.attributes
                                        .slider_images[0].attributes
                                        .resource_url.thumbnail
                                    "
                                    alt="slide"
                                  />
                                </a>
                              </div>
                              <div
                                class="learning-data-video"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'video_learn_obj'
                                "
                              >
                                <div
                                  class="wistia_responsive_padding data-video"
                                  style="
                                    padding: 56.25% 0 0 0;
                                    position: relative;
                                  "
                                >
                                  <div
                                    class="wistia_responsive_wrapper data-video"
                                    style="
                                      height: 100%;
                                      left: 0;
                                      position: absolute;
                                      top: 0;
                                      width: 100%;
                                    "
                                  >
                                    <iframe
                                      :src="
                                        preIframUrl +
                                        item.attributes.content_details
                                          .attributes.global_video.attributes
                                          .wistia_code +
                                        postIframUrl
                                      "
                                      title="Video.mp4"
                                      class="wistia_embed"
                                      name="wistia_embed"
                                      allowtransparency="true"
                                      scrolling="no"
                                      allowfullscreen
                                      mozallowfullscreen
                                      webkitallowfullscreen
                                      oallowfullscreen
                                      msallowfullscreen
                                      width="100%"
                                      height="100%"
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="learning-data-title">
                              <div class="data-heads">
                                <h4 v-html="item.attributes.title"></h4>
                                <span
                                  v-if="
                                    item.attributes.content_details.type ==
                                    'file_learn_obj'
                                  "
                                  >{{
                                    size(
                                      item.attributes.content_details.attributes
                                        .resource_size
                                    )
                                  }}</span
                                >
                              </div>
                              <h4
                                v-html="
                                  item.attributes.content_details.attributes
                                    .title
                                "
                              ></h4>
                              <div class="learning-data-paragraph">
                                <div>
                                  <p v-html="item.attributes.description"></p>
                                </div>
                                <a
                                  style="color: #3dbc9e"
                                  @click="
                                    $modal.show('uploadInterstitialModal', {
                                      create: false,
                                      edit: item,
                                      content: 'adaptive',
                                    })
                                  "
                                  >Edit</a
                                >
                                <a @click="removeAdaptiveData(item.id, i)"
                                  >Remove</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="d-flex justify-content-end mt-4 mb-3">
                      <button
                        class="btn primary"
                        @click="
                          saveDialogicContent($event, 'overall-assessment', 3)
                        "
                      >
                        {{ formValue === 3 ? "Save" : "Save & Next" }}
                      </button>
                    </div>
                    <div class="btn-container justify-content-end">
                      <a class="delete-btn">
                        <div class="delete-box">
                          <span class="icon-delete"></span>
                        </div>
                        <a
                          @click="
                            $route.query.id ? $parent.archiveLO('archived') : ''
                          "
                          class="text"
                          >Archive this Learning Object(LO)</a
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <!-- Question Setup end here -->
        </div>
      </div>
      <!-- question setup back section end here -->
    </div>
    <!-- dialogic code end here -->
    <addInterstitialContent
      v-on:adaptiveContents="adaptiveContents"
      :v-if="type == 'dialogic'"
    />
  </div>
</template>

<script>
import CopyToClipboard from "vue-copy-to-clipboard";
import { VueEditor } from "vue2-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import draggable from "vuedraggable";
import addInterstitialContent from "@/components/modal/addInterstitialContent.vue";

export default {
  name: "Interactive-dialogic-lo",
  components: {
    VueEditor,
    ValidationProvider,
    ValidationObserver,
    draggable,
    addInterstitialContent,
    CopyToClipboard,
  },
  props: ["elmMenu", "selectedMenuItem", "worldId"],
  data() {
    return {
      name: "",
      step: "basic-setup",
      description: "",
      admin_notes: "",
      isActive: "",
      isLabelErrActive: "",
      overallQuizAssessment: [],
      editWorldStatus: "",
      fullTool: [],
      contentDescription: "",
      overallAssessment: "",
      displayOverallAssessment: false,
      competencyCode: "",
      assessmentListKey: "",
      assessmentLists: [],
      overallInteraction: "",
      searchCharacters: [],
      characters: [],
      charactersList: [],
      customer: "",
      addedCustomer: {},
      noCustomerAdded: false,
      url: utilFunctionService.getImageUrl(),
      character_id: "",
      isActiveTab: ["active"],
      selectedSynonymList: null,
      selectedPatternList: null,
      dialogicContentId: "basic-information",
      asstEntitiesList: null,
      entityName: "",
      selectedAsstEntities: null,
      asstName: "",
      asstType: "Synonyms",
      asstPatterns: "",
      asstSynonyms: "",
      entitySynonymName: "",
      entityPatternName: "",
      addIntro: true,
      addCon: false,
      questionText: "",
      objectable_id: "",
      introduction: "",
      conclusion: "",
      question_variations_attributes: [],
      variationsSelect: 1,
      concept: "",
      conceptDescription: "",
      key_topics_attributes: [],
      addAsstEntitiesList: [],
      QuestionList: [],
      order: "",
      queId: "",
      delete_question_variations: [],
      delete_key_topics: [],
      backSection: false,
      selectedkey: "",
      assessment_scheme_id: "",
      assessmentScheme: {},
      overallAssmntItem: [],
      keyTopic: [],
      Selectedconcept: {},
      SelectedKeyTopic: {},
      variations_attributes: [],
      overallAssmntItemMinScore: "",
      overallAssmntItemMaxScore: "",
      dialogicAssmntItemMinScore: "",
      dialogicAssmntItemMaxScore: "",
      searchEntityValue: "",
      searchEntityValues: [],
      selectedEntityValue: [],
      entityStatus: false,
      EntityList: [],
      KeyTopicEntityList: [],
      points: "",
      isFollowUp: false,
      followUpPoint: "",
      followUpQuestion: "",
      dialogic_assmnt_item: "",
      assessment_labels: "",
      destroy_variations_attributes: [],
      destroy_selectedEntityValue: [],
      variations_briefattributes: [],
      formValue: 1,
      overallAssmntItemFeedback: "",
      overallAssessmentRange: "percentage",
      selectedOverallAssmntItem: [],
      setVariationList: [],
      setEnhancedVariationList: [],
      setFollowupVariationList: [],
      iterationCount: 4,
      variationCount: 4,
      selIterationLevel: 0,
      selVariationLevel: 0,
      selEnhancedIterationLevel: 0,
      selEnhancedVariationLevel: 0,
      selFollowupIterationLevel: 0,
      selFollowupVariationLevel: 0,
      selectQuestion: {},
      adaptiveLists: [],
      hideAssessmentLabel: false,
      enableChatImView: "",
      type: "dialogic",
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      allQuestionTestId: false,
      testAnswer: "",
      isTestAnswer: null,
      testEvaulationScheme: [],
      testEvalJsonScheme: null,
      TestCounter: 0,
      isTestMultiAnswer: true,
      selectQuestionToTest: [],
      totalQuizQues: null,
      isTestQuizAnswer: null,
      basicSetupUpdate: "",
      testQuizAnswer: "",
      correctAnswer: null,
      ansResponse: null,
      ansResponse1: null,
      status: "drafted",
      useEnhanced: "",
      enableMissedSkills: false,
      enableLearnerRetries: false,
      maxNumberOfRetries: 0,
      enableInstructorOverride: false,
    };
  },
  async created() {
    this.backSection = false;
    if (!this.$route.query.id) this.clearForm();
    else this.setFieldValue();
    this.getAssessment();
    this.getCharactersData();
  },
  filters: {
    uppCase: function (value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    },
  },
  methods: {
    saveEntityLabel() {
      if (this.selectedAsstEntities) {
        const fb = new FormData();
        fb.append(
          "asst_entity[label]",
          this.selectedAsstEntities.attributes.label
        );
        api
          .updateEntityLabel(this.selectedAsstEntities.id, fb)
          .then((res) => {
            this.selectedAsstEntities.attributes.label =
              res.data.data.attributes.label;
          })
          .catch(() => {});
      }
    },
    handleCopy() {
      utilFunctionService.showSuccess(commonConfig.appMessages.jsonCopied);
    },
    cloneLo() {
      utilFunctionService.showLoader();
      api
        .cloneObj(this.$route.params.id, this.$route.query.id)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(commonConfig.appMessages.loCloned);
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch();
    },
    hideTestModal() {
      this.allQuestionTestId = false;
      this.ansResponse = null;
      this.ansResponse1 = null;
      this.TestCounter = 0;
      this.$modal.hide("dialogicTesting");
    },
    changeQuestionTest(e, status) {
      this.testAnswer = "";
      this.isTestAnswer = null;
      this.testQuizAnswer = "";
      this.testEvaulationScheme = [];
      this.testEvalJsonScheme = null;
      this.TestCounter = 0;
      this.ansResponse = null;
      this.ansResponse1 = null;
      if (status)
        this.isTestMultiAnswer = e.target.value === "true" ? true : false;
      else {
        this.allQuestionTestId = e.target.checked;
        !e.target.checked && this.handleselectQuestionToTest({}, true);
      }
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    },
    switchToAssessmentSetup(id) {
      this.backSectionData(true, id);
    },
    handleselectQuestionToTest(e, status = false) {
      this.testQuizAnswer = "";
      this.totalQues =
        this.QuestionList.length > 5 ? 5 : this.QuestionList.length;
      if (this.allQuestionTestId) {
        if (!status) {
          this.selectQuestionToTest = {
            name: this.QuestionList[0].attributes.concept,
            varationName:
              this.QuestionList[0].attributes.variations[0].attributes.question,
            varationId: this.QuestionList[0].attributes.variations[0].id,
            data: this.QuestionList[0].data,
            question_type: this.QuestionList[0].attributes.question_type,
            quizId: this.QuestionList[0].id,
          };
          this.$modal.show("dialogicTesting");
        } else
          this.selectQuestionToTest = {
            name: this.QuestionList[e.target.value].attributes.concept,
            varationName:
              this.QuestionList[e.target.value].attributes.variations[0]
                .attributes.question,
            varationId:
              this.QuestionList[e.target.value].attributes.variations[0].id,
            data: this.QuestionList[e.target.value],
            question_type:
              this.QuestionList[e.target.value].attributes.question_type,
            quizId: this.QuestionList[e.target.value].id,
          };
        this.ansResponse = null;
        this.ansResponse1 = null;
        this.testAnswer = "";
        this.isTestAnswer = null;
      } else {
        this.selectQuestionToTest = {
          id: this.TestCounter,
          name: this.QuestionList[0].attributes.concept,
          varationName:
            this.QuestionList[0].attributes.variations[0].attributes.question,
          varationId: this.QuestionList[0].attributes.variations[0].id,
          data: this.QuestionList[0],
          question_type: this.QuestionList[0].attributes.question_type,
          statusBar: {
            width: `${this.getStatusBarWidth(this.TestCounter + 1)}%`,
          },
          quizId: this.QuestionList[0].id,
        };
        this.ansResponse = null;
        this.ansResponse1 = null;
        this.TestCounter = 0;
        this.$modal.show("dialogicTesting");
      }
      this.testAnswer = "";
      this.isTestAnswer = null;
      this.testEvaulationScheme = [];
      this.testEvalJsonScheme = null;
    },
    handleCheckQuizTest(e) {
      e.preventDefault();
      utilFunctionService.showLoader();
      if (this.allQuestionTestId) {
        api.getEvaluationId(this.objectable_id).then((res) => {
          let fb = new FormData();
          fb.append("quiz_evaluation_id", res.data.data.id);
          fb.append(
            "dialogic_test_answer[dialogic_question_id]",
            this.selectQuestionToTest.quizId
          );
          fb.append(
            "dialogic_test_answer[question_variation_id]",
            this.selectQuestionToTest.varationId
          );
          fb.append("dialogic_test_answer[answer", this.testQuizAnswer);
          api.dialogicTestAnswers(res.data.data.id, fb).then((resData) => {
            if (this.ansResponse) {
              this.ansResponse1 = resData.data.data;
            } else {
              this.ansResponse = resData.data.data;
              if (
                this.ansResponse &&
                this.ansResponse.attributes &&
                this.ansResponse.attributes.follow_up_question
              )
                this.testQuizAnswer = "";
            }
            utilFunctionService.hideLoader();
          });
        });
      } else {
        let fb = new FormData();
        fb.append("dialogic_learn_obj_id", this.objectable_id);
        fb.append(
          "dialogic_test_answer[dialogic_question_id]",
          this.selectQuestionToTest.quizId
        );
        fb.append(
          "dialogic_test_answer[question_variation_id]",
          this.selectQuestionToTest.varationId
        );
        fb.append("dialogic_test_answer[answer]", this.testQuizAnswer);
        api.specificQuestionTest(this.objectable_id, fb).then((resData) => {
          if (this.ansResponse) {
            this.ansResponse1 = resData.data;
          } else {
            this.ansResponse = resData.data;
            if (
              this.ansResponse &&
              this.ansResponse.attributes &&
              this.ansResponse.attributes.follow_up_question
            )
              this.testQuizAnswer = "";
          }
          utilFunctionService.hideLoader();
        });
      }
    },
    getStatusBarWidth(quizTestCounter) {
      let calVal;
      if (this.totalQues === 1) calVal = 100;
      if (this.totalQues === 2) calVal = quizTestCounter * 50;
      if (this.totalQues === 3) calVal = (quizTestCounter * 100) / 3;
      if (this.totalQues === 4) calVal = quizTestCounter * 25;
      if (this.totalQues === 5) calVal = (quizTestCounter + 1) * 20;
      return calVal;
    },
    handleTestPage(status) {
      this.ansResponse = null;
      this.ansResponse1 = null;
      let isUpdated = false;
      if (status) {
        if (
          this.selectQuestionToTest.id >= 0 &&
          this.selectQuestionToTest.id < 4
        ) {
          ++this.TestCounter;
          isUpdated = true;
        }
      } else {
        if (
          this.selectQuestionToTest.id > 0 &&
          this.selectQuestionToTest.id <= 4
        ) {
          this.TestCounter -= 1;
          isUpdated = true;
        }
      }
      if (isUpdated) {
        this.selectQuestionToTest = {
          id: this.TestCounter,
          name: this.QuestionList[this.TestCounter].attributes.concept,
          varationName:
            this.QuestionList[this.TestCounter].attributes.variations[0]
              .attributes.question,
          varationId:
            this.QuestionList[this.TestCounter].attributes.variations[0].id,
          data: this.QuestionList[this.TestCounter],
          question_type:
            this.QuestionList[this.TestCounter].attributes.question_type,
          statusBar: {
            width: `${this.getStatusBarWidth(this.TestCounter + 1)}%`,
          },
          quizId: this.QuestionList[this.TestCounter].id,
        };
        this.testQuizAnswer = "";
        this.isTestQuizAnswer = null;
        this.testEvaulationScheme = [];
        this.testEvalJsonScheme = null;
      }
    },
    deleteQuestion() {
      utilFunctionService.showLoader();
      api.deleteDialogicQuestion(this.queId).then(() => {
        utilFunctionService.hideLoader();
        utilFunctionService.showSuccess(
          commonConfig.appMessages.deleteQuestion
        );
        this.entitysetUp("");
      });
    },
    removeAdaptiveData(id) {
      utilFunctionService.showLoader();
      api.deleteAdaptiveContent(id).then(() => {
        utilFunctionService.hideLoader();
        this.setAssessmentScheme(this.assessment_labels, this.formValue);
      });
    },
    restore() {
      const fb = new FormData();
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("learning_object[status]", "published");
      fb.append("id", this.$route.query.id);
      api
        .restoreLo(this.$route.params.id, this.$route.query.id, fb)
        .then(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.handleDialogicContent("", "basic-information", 0);
          this.$router.push({
            id: this.$route.params.id,
            query: { id: this.$route.query.id },
          });
          window.location.reload();
        })
        .catch(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.handleDialogicContent("", "basic-information", 0);
          this.$router.push({
            id: this.$route.params.id,
            query: { id: this.$route.query.id },
          });
          window.location.reload();
        });
    },
    selectQuizQuestion(data) {
      this.ansResponse = null;
      this.ansResponse1 = null;
      this.selectQuestion = data;
    },
    changeFormData(value) {
      this.formValue = value;
      //this.setAssessmentScheme(this.assessment_labels)
    },
    adaptiveContents(data) {
      if (this.elmMenu.card_type === "dialogic") {
        const fb = new FormData();
        fb.append("content_type", data.tab);
        fb.append(
          "adaptable_type",
          !this.assessment_labels ? "MissedAssmntItem" : "DialogicAssmntItem"
        );
        fb.append("adaptable_id", this.dialogic_assmnt_item);
        fb.append("adaptive_content[title]", data.name);
        fb.append("adaptive_content[description]", data.desc);
        if (data.tab === "text") fb.append("content[title]", data.text);
        if (data.tab === "file")
          fb.append("content[global_resource_id]", data.doc.id);
        if (data.tab === "video")
          fb.append("content[global_video_id]", data.video.id);
        if (data.tab === "slide")
          fb.append("content[has_caption]", data.imageCaption);
        data.slider.map((x, i) => {
          fb.append(
            "content[slider_images_attributes][" + i + "][caption]",
            x.title
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][global_resource_id]",
            parseInt(x.sliderId)
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][_destroy]",
            data.edit == false ? false : x.destroy == true ? true : false
          );
          if (x.id != undefined) {
            fb.append("content[slider_images_attributes][" + i + "][id]", x.id);
          }
        });
        if (data.edit) {
          api.editQuizAdaptiveContent(fb, data.id).then(() => {
            this.variationsSelect = 1;
            this.selIterationLevel = 0;
            this.selVariationLevel = 0;
            this.setAssessmentScheme(this.assessment_labels, this.formValue);
          });
        } else {
          api.saveQuizAdaptiveContent(fb, this.objectableId).then(() => {
            this.variationsSelect = 1;
            this.selIterationLevel = 0;
            this.selVariationLevel = 0;
            this.setAssessmentScheme(this.assessment_labels, this.formValue);
          });
        }
      }
    },
    handleAddVariation(index, isFollow) {
      if (!isFollow) {
        this.setVariationList[index].variation.push({ response: "" });
      } else {
        this.setFollowupVariationList[index].variation.push({ response: "" });
      }
    },
    handleOverallAssmntItem(data, isPublish) {
      this.updateOverallAssessment(isPublish);
      setTimeout(() => {
        this.selectedOverallAssmntItem = data;
        this.overallAssmntItemFeedback =
          this.selectedOverallAssmntItem.attributes.feedback;
        this.overallAssmntItemMaxScore =
          this.selectedOverallAssmntItem.attributes.max_score;
        this.overallAssmntItemMinScore =
          this.selectedOverallAssmntItem.attributes.min_score;
      }, 200);
    },
    handleSelectedIteration(level, type, isFollowUp) {
      if (!isFollowUp) {
        if (type === "iteration") {
          this.selIterationLevel = level;
          this.selVariationLevel = 0;
        } else {
          this.selVariationLevel = level;
        }
      } else {
        if (type === "iteration") {
          this.selFollowupIterationLevel = level;
          this.selFollowupVariationLevel = 0;
        } else {
          this.selFollowupVariationLevel = level;
        }
      }
    },
    handleEnhancedSelectedIteration(level, type) {
      if (type === "iteration") {
        this.selEnhancedIterationLevel = level;
        this.selEnhancedVariationLevel = 0;
      } else {
        this.selEnhancedVariationLevel = level;
      }
    },
    getselVariationList(data) {
      this.setVariationList = [];
      data.forEach((ele) => {
        if (
          ele.attributes.iteration === this.selIterationLevel &&
          ele.attributes.response
        )
          this.setVariationList.push(ele);
      });
      this.selectedVariation = this.setVariationList[0];
    },
    updateOverallAssessment(isPublish) {
      this.$refs.formOverall.validate().then((success) => {
        if (
          !this.overallAssmntItemFeedback &&
          !this.overallAssmntItemMaxScore &&
          !this.overallAssmntItemMaxScore
        ) {
          this.isActive = true;
          return;
        }
        if (!success) {
          this.isActive = true;
          return;
        } else {
          let fb = new FormData();
          fb.append(
            "overall_assmnt_item[feedback]",
            this.overallAssmntItemFeedback
          );
          fb.append(
            "overall_assmnt_item[max_score]",
            this.overallAssmntItemMaxScore
          );
          fb.append(
            "overall_assmnt_item[min_score]",
            this.overallAssmntItemMinScore
          );
          api
            .updateOverallAssesScheme(this.selectedOverallAssmntItem.id, fb)
            .then(() => {
              if (isPublish) {
                this.restore();
              }
              this.getOverAllAssessments(false);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.feedbackUpdateErr
              );
            });
        }
      });
    },
    submitOverallAssmntItem() {
      utilFunctionService.showLoader();
      this.$refs.formOverall.validate().then((success) => {
        if (!success) {
          utilFunctionService.hideLoader();
          return;
        } else {
          utilFunctionService.hideLoader();
          this.updateOverallAssessment();
          return;
        }
      });
    },
    getOverAllAssessments(isUpdated = true) {
      api
        .getOverallAssmntItem(this.$route.query.id)
        .then((res) => {
          this.overallAssmntItem = res.data.data.sort(this.sortOrder);
          if (this.overallAssmntItem.length === 0) {
            api.getAssessmentScheme(this.assessment_scheme_id).then((res) => {
              this.generateOverAllAssessment(
                res.data.data.attributes.assessment_labels
              );
              api.getOverallAssmntItem(this.$route.query.id).then((res2) => {
                this.overallAssmntItem = res2.data.data.sort(this.sortOrder);
                this.selectedOverallAssmntItem = this.overallAssmntItem[0];
                this.overallAssmntItemFeedback =
                  this.overallAssmntItem[0].attributes.feedback;
                this.overallAssmntItemMaxScore =
                  this.overallAssmntItem[0].attributes.max_score;
                this.overallAssmntItemMinScore =
                  this.overallAssmntItem[0].attributes.min_score;
              });
            });
          } else {
            if (isUpdated) {
              this.selectedOverallAssmntItem = this.overallAssmntItem[0];
              this.overallAssmntItemFeedback =
                this.overallAssmntItem[0].attributes.feedback;
              this.overallAssmntItemMaxScore =
                this.overallAssmntItem[0].attributes.max_score;
              this.overallAssmntItemMinScore =
                this.overallAssmntItem[0].attributes.min_score;
            }
          }
        })
        .catch(() => {});
    },
    generateOverAllAssessment(data) {
      data.forEach((ele) => {
        let fb = new FormData();
        fb.append("overall_assmnt_item[assessment_label_id]", ele.id);
        fb.append("overall_assmnt_item[feedback]", "");
        fb.append("overall_assmnt_item[max_score]", 0);
        fb.append("overall_assmnt_item[min_score]", 0);
        api
          .createOverallAssesScheme(this.$route.query.id, fb)
          .then(() => {})
          .catch(() => {
            utilFunctionService.showErr();
          });
      });
    },
    setAssessmentScheme(id, formValue) {
      //console.log(id, formValue, "*** setAssessmentScheme function");
      //console.log(this.searchEntityValues, "*** this.searchEntityValues in setAssessmentScheme");
      //console.log(this.asstEntitiesList, "*** this.asstEntitiesList in setAssessmentScheme");
      this.assessment_labels = id ? id : "";
      this.variationsSelect = !formValue ? 1 : this.variationsSelect;
      this.selIterationLevel = !formValue ? 0 : this.variationsSelect;
      this.selVariationLevel = !formValue ? 0 : this.variationsSelect;
      this.selEnhancedIterationLevel = !formValue ? 0 : this.variationsSelect;
      this.selEnhancedVariationLevel = !formValue ? 0 : this.variationsSelect;

      if (this.assessment_labels) {
        if (this.SelectedKeyTopic.id)
          this.getDialogicAssessment(
            this.SelectedKeyTopic.id,
            this.assessment_labels,
            formValue
          );
      } else {
        if (this.SelectedKeyTopic.id)
          this.getDialogicMissedAssessment(this.SelectedKeyTopic.id, formValue);
      }
      //this.getKeyTopicEntityValues();
    },
    log({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      console.log(relatedElement, draggedElement);
    },
    reorderQuestions({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      const fb = new FormData();
      let draggedCardOrder = draggedElement.attributes.order;
      let relatedCardOrder = relatedElement.attributes.order;
      this.QuestionList.map((data, index) => {
        if (parseInt(data.id) === parseInt(relatedElement.id)) {
          fb.append(
            "dialogic_learn_obj[dialogic_questions_attributes][][id]",
            data.id
          );
          fb.append(
            "dialogic_learn_obj[dialogic_questions_attributes][][order]",
            draggedCardOrder
          );
          data.attributes.order = draggedCardOrder;
        } else if (parseInt(data.id) === parseInt(draggedElement.id)) {
          fb.append(
            "dialogic_learn_obj[dialogic_questions_attributes][][id]",
            data.id
          );
          fb.append(
            "dialogic_learn_obj[dialogic_questions_attributes][][order]",
            relatedCardOrder
          );
          data.attributes.order = relatedCardOrder;
        } else {
          fb.append(
            "dialogic_learn_obj[dialogic_questions_attributes][][id]",
            data.id
          );
          fb.append(
            "dialogic_learn_obj[dialogic_questions_attributes][][order]",
            index + 1
          );
          data.attributes.order = index + 1;
        }
        return data;
      });
      api
        .reorderDialogicQuestions(this.objectable_id, fb)
        .then(() => {})
        .catch();
    },
    filterEntity() {
      this.entityStatus = true;
      if (this.SelectedKeyTopic && this.SelectedKeyTopic.attributes) {
        this.EntityList = [
          ...this.SelectedKeyTopic.attributes.asst_entities.attributes
            .asst_entity_values,
        ];
        console.log(this.EntityList, "*** this.EntityList");
        if (this.searchEntityValue.length !== 0) {
          if (this.selectedEntityValue.length > 0) {
            if (this.EntityList.length > 0)
              this.EntityList.forEach((char) => {
                char.status = false;
              });
            this.selectedEntityValue.forEach((element) => {
              const index = this.EntityList.findIndex(
                (elem) => elem.id === element.id
              );
              if (index !== -1) {
                this.EntityList[index].status = true;
              } else {
                this.EntityList[index].status = false;
              }
            });
            this.searchEntityValues = [...this.EntityList];
          } else {
            if (this.EntityList.length > 0)
              this.EntityList.forEach((char) => {
                char.status = false;
              });
            this.searchEntityValues = [...this.EntityList];
          }
        } else {
          this.searchEntityValues = [...this.EntityList];
        }
      }
    },
    getKeyTopicEntityValues() {
      if (this.SelectedKeyTopic && this.SelectedKeyTopic.attributes) {
        this.KeyTopicEntityList = [
          ...this.SelectedKeyTopic.attributes.asst_entities.attributes
            .asst_entity_values,
        ];
        console.log(this.KeyTopicEntityList, "*** this.KeyTopicEntityList");
      }
    },
    addEntityData(e) {
      this.selectedEntityValue.push(e);
      this.searchEntityValue = "";
      this.searchEntityValues = [];
    },
    deleteSeletedentity(i) {
      this.selectedEntityValue.splice(i, 1);
    },
    setKey(id, index, type) {
      this.clearAssessment(true);
      if (type === "concept") {
        this.Selectedconcept = this.QuestionList[index];
        this.keyTopic = this.QuestionList[index]
          ? this.QuestionList[index].attributes.key_topics
          : {};
        this.SelectedKeyTopic = {};
      } else {
        this.SelectedKeyTopic = this.keyTopic[index];
        if (this.assessment_labels) {
          this.getDialogicAssessment(
            this.SelectedKeyTopic.id,
            this.assessment_labels
          );
        } else {
          this.getDialogicMissedAssessment(this.SelectedKeyTopic.id);
        }
      }
    },
    getDialogicAssessment(id, lableid, formValue) {
      this.clearAssessment(true);
      utilFunctionService.showLoader();
      api
        .getDialogicAssessment(id)
        .then((res) => {
          utilFunctionService.hideLoader();
          if (res.data.data.length > 0) {
            res.data.data.forEach((elm) => {
              if (
                parseInt(lableid) ===
                parseInt(elm.attributes.assessment_label_id)
              ) {
                this.clearAssessment(false, elm, formValue);
              }
            });
          } else {
            utilFunctionService.hideLoader();
            this.clearAssessment(true, formValue);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDialogicMissedAssessment(id, formValue) {
      this.clearmissedAssessment(true);
      utilFunctionService.showLoader();
      api
        .getMissedAssessment(id)
        .then((res) => {
          utilFunctionService.hideLoader();
          if (res.data.data) {
            this.clearmissedAssessment(false, res.data.data, formValue);
          } else {
            this.clearmissedAssessment(true, formValue);
          }
        })
        .catch((err) => {
          utilFunctionService.hideLoader();
          console.log(err);
        });
    },
    clearmissedAssessment(isClear, data, formValue) {
      this.formValue = formValue ? formValue : 1;
      this.dialogic_assmnt_item = isClear ? "" : data.id;
      this.isFollowUp = isClear
        ? false
        : data.attributes.has_follow_up_question;
      this.adaptiveLists = isClear ? "" : data.attributes.adaptive_contents;
      this.variations_briefattributes = [];
      this.setVariationList = [];
      this.question_variations_attributes = [];
      this.setFollowupVariationList = [];
      if (isClear) {
        for (let i = 0; i < this.iterationCount; i++) {
          let variation = [];
          variation.push({ response: "" });
          this.setVariationList.push({ variation });
          let variations = [];
          variations.push({ response: "" });
          this.setFollowupVariationList.push({ variation: variations });
        }
      } else {
        for (let i = 0; i < this.iterationCount; i++) {
          let variation = [];
          variation.push({ response: "" });
          this.setVariationList.push({ variation });
        }
      }
      if (!isClear && data && data.attributes.character_responses.length > 0) {
        data.attributes.character_responses.forEach((ele) => {
          if (this.setVariationList[ele.attributes.iteration - 1]) {
            this.setVariationList[ele.attributes.iteration - 1].variation = [];
          }
        });
        data.attributes.character_responses.forEach((ele) => {
          if (this.setVariationList[ele.attributes.iteration - 1]) {
            this.setVariationList[ele.attributes.iteration - 1].variation.push({
              response: ele.attributes.response,
              id: ele.id,
            });
          }
        });
      }
      if (!isClear && data && data.attributes.follow_up_questions.length > 0) {
        data.attributes.follow_up_questions.forEach((ele) => {
          this.question_variations_attributes.push({
            question: ele.attributes.question,
            id: ele.id,
          });
        });
      }
      this.variations_briefattributes = [];
      if (!isClear && data && data.attributes.debrief_contents.length > 0) {
        data.attributes.debrief_contents.forEach((ele) => {
          this.variations_briefattributes.push({
            content: ele.attributes.content,
            id: ele.id,
          });
        });
      }
    },
    clearAssessment(isClear, data, formValue) {
      //console.log(data, this.points,'edit data===>');
      console.log(
        isClear,
        data,
        formValue,
        "*** isClear, data, formValue in clearAssessment"
      );
      this.formValue = formValue ? formValue : 1;
      this.dialogic_assmnt_item = isClear ? "" : data.id;
      this.dialogicAssmntItemMinScore = isClear
        ? ""
        : data.attributes.value_count_min;
      this.adaptiveLists = isClear ? "" : data.attributes.adaptive_contents;
      this.dialogicAssmntItemMaxScore = isClear
        ? ""
        : data.attributes.value_count_max;
      this.points = isClear ? "" : data.attributes.points;
      this.followUpPoint = isClear ? "" : data.attributes.follow_up_points;
      //console.log(this.overallAssmntItemMinScore, this.overallAssmntItemMaxScore, this.points,'edit===>')
      this.isFollowUp = isClear
        ? false
        : data.attributes.have_follow_up_question;
      this.useEnhanced = isClear
        ? false
        : data.attributes.use_enhanced_responses;
      this.question_variations_attributes = [];
      this.destroy_variations_attributes = [];
      this.variations_briefattributes = [];
      this.setVariationList = [];
      this.setEnhancedVariationList = [];
      this.setFollowupVariationList = [];

      for (let i = 0; i < this.iterationCount; i++) {
        let variation = [];
        variation.push({ response: "" });
        this.setVariationList.push({ variation });
      }

      // Sets up the setVariationList array variable containing the basic main character response(s)
      if (!isClear && data && data.attributes.responses.length > 0) {
        data.attributes.responses.forEach((ele) => {
          if (this.setVariationList[ele.attributes.iteration - 1]) {
            this.setVariationList[ele.attributes.iteration - 1].variation = [];
          }
        });
        data.attributes.responses.forEach((ele) => {
          //console.log(this.setVariationList[ele.attributes.iteration - 1], "*** OTHER THING");
          if (this.setVariationList[ele.attributes.iteration - 1]) {
            this.setVariationList[ele.attributes.iteration - 1].variation.push({
              response: ele.attributes.response,
              id: ele.id,
            });
          }
        });
      }

      if (isClear) {
        console.log("*** hitting isClear on line 3281");
        // Handles the case where there is no enhanced response data yet
        var kb = 0;
        this.KeyTopicEntityList.forEach((kt) => {
          var iteration = [];

          for (let i = 0; i < this.iterationCount; i++) {
            var variation = [];
            variation.push({
              present_response: "",
              absent_response: "",
              id: "",
              asst_entity_value_id: "",
              variation: 1,
              iteration: 1,
            });
            iteration.push({ variation });
          }
          this.setEnhancedVariationList.push({
            asst_entity_value_id: kt.id,
            asst_entity_value: kt.attributes.value,
            asst_entity_id: kt.attributes.asst_entity_id,
            order: kb,
            iteration,
          });

          kb = kb + 1;
        });
      }

      // Sets up the setEnhancedVariationList array variable containing the entity values and iteration, variation, and enhanced response data
      if (!isClear && data) {
        // Handles the case where enhanced response data exists
        if (data.attributes.enhanced_responses.length > 0) {
          var k = 0;
          this.KeyTopicEntityList.forEach((kt) => {
            console.log(kt, "*** kt in loop");
            var iteration = [];

            for (let i = 0; i < this.iterationCount; i++) {
              var variation = [];
              //variation.push({present_response: "", absent_response: ""});
              //console.log(k, "*** enhancedVar k");

              data.attributes.enhanced_responses.forEach((ele) => {
                if (ele.attributes.asst_entity_value_id == kt.id) {
                  if (ele.attributes.iteration == i + 1) {
                    variation.push({
                      present_response: ele.attributes.present_response,
                      absent_response: ele.attributes.absent_response,
                      id: ele.id,
                      asst_entity_value_id: ele.attributes.asst_entity_value_id,
                      variation: ele.attributes.variation,
                      iteration: ele.attributes.iteration,
                    });
                  }
                }
              });
              if (variation.length < 1) {
                variation.push({
                  present_response: "",
                  absent_response: "",
                  id: "",
                  asst_entity_value_id: "",
                  variation: 1,
                  iteration: 1,
                });
              }
              iteration.push({ variation });
            }
            //this.setEnhancedVariationList.iteration[k].push({asst_entity_value: kt.attributes.value, asst_entity_id: kt.id, order: k, variation});
            this.setEnhancedVariationList.push({
              asst_entity_value_id: kt.id,
              asst_entity_value: kt.attributes.value,
              asst_entity_id: kt.attributes.asst_entity_id,
              order: k,
              iteration,
            });

            k = k + 1;
          });
        }

        if (data.attributes.enhanced_responses.length < 1) {
          // Handles the case where there is no enhanced response data yet
          var ka = 0;
          this.KeyTopicEntityList.forEach((kt) => {
            var iteration = [];

            for (let i = 0; i < this.iterationCount; i++) {
              var variation = [];
              variation.push({
                present_response: "",
                absent_response: "",
                id: "",
                asst_entity_value_id: "",
                variation: 1,
                iteration: 1,
              });
              iteration.push({ variation });
            }
            this.setEnhancedVariationList.push({
              asst_entity_value_id: kt.id,
              asst_entity_value: kt.attributes.value,
              asst_entity_id: kt.attributes.asst_entity_id,
              order: ka,
              iteration,
            });

            ka = ka + 1;
          });
        }
        //console.log("line 3237 hits here ***");
        //console.log(data, "*** data below line 3237 here ***");
        //console.log(data.attributes.enhanced_responses.length, "*** data.attributes.enhanced_responses.length below line 3237 here ***");

        console.log(
          this.setEnhancedVariationList,
          "*** this.setEnhancedVariationList"
        );
      }

      this.selectedEntityValue = isClear
        ? []
        : data.attributes.required_key_topic_values;
      if (
        !isClear &&
        this.SelectedKeyTopic &&
        this.SelectedKeyTopic.attributes
      ) {
        this.EntityList = [
          ...this.SelectedKeyTopic.attributes.asst_entities.attributes
            .asst_entity_values,
        ];
        if (this.selectedEntityValue.length > 0) {
          let savedData = [];
          this.selectedEntityValue.forEach((element) => {
            const index = this.EntityList.findIndex((elem) => {
              console.log(
                elem.id,
                element.attributes.key_topic_value_id,
                elem.id === element.attributes.key_topic_value_id,
                "--elem.id === element.attributes.key_topic_value_id"
              );
              return (
                parseInt(elem.id) ===
                parseInt(element.attributes.key_topic_value_id)
              );
            });
            if (index !== -1) {
              this.EntityList[index].status = true;
              const indexdata = savedData.findIndex((elem) => {
                return (
                  parseInt(elem.id) === parseInt(this.EntityList[index].id)
                );
              });
              if (indexdata === -1) savedData.push(this.EntityList[index]);
            }
          });
          this.selectedEntityValue = savedData;
        }
      }
      if (!isClear && data && data.attributes.debrief_contents.length > 0) {
        data.attributes.debrief_contents.forEach((ele) => {
          this.variations_briefattributes.push({
            content: ele.attributes.content,
            id: ele.id,
          });
        });
      }
      if (!isClear && data && data.attributes.follow_up_question.length > 0) {
        data.attributes.follow_up_question.forEach((ele) => {
          this.question_variations_attributes.push({
            question: ele.attributes.question,
            id: ele.id,
          });
        });
      }
      this.destroy_selectedEntityValue = isClear
        ? []
        : data.attributes.required_key_topic_values;
    },
    backSectionData(status, id) {
      console.log(status, id, "*** status and id in backSectionData");
      this.backSection = status;
      if (status === false) {
        //this.addIntro = true;
      } else {
        //this.addQuestion(true,false);
        if (this.keyTopic)
          this.keyTopic.forEach((ele) => {
            if (ele.attributes.asst_entities.id === id) {
              this.SelectedKeyTopic = ele;
            }
          });
        utilFunctionService.hideLoader();
        this.setAssessmentScheme(null);
        this.getKeyTopicEntityValues();
      }
    },
    beforeOpen() {
      if (this.asstEntitiesList && this.asstEntitiesList.length > 0) {
        this.asstEntitiesList.forEach((x) => {
          let index = this.addAsstEntitiesList.findIndex((y) => {
            return parseInt(y.id) === parseInt(x.id);
          });
          if (index !== -1) {
            this.asstEntitiesList[index].status = true;
          }
        });
      }
      if (this.selectedSynonymList && this.selectedSynonymList.id) {
        api
          .getAssesSchemeValues(this.selectedSynonymList.id)
          .then((res) => {
            this.selectedSynonymList = res.data.data
              ? res.data.data.length
              : this.selectedSynonymList;
          })
          .catch();
      }
    },
    dialogicUpdate() {
      if (!this.$route.query.id) this.clearForm();
      else {
        this.getAssessment();
        this.getCharactersData();
        this.isActiveTab = [];
        this.isActiveTab.push("active");
        this.setFieldValue();
        this.entitysetUp("");
        this.backSection = false;
      }
    },
    setFieldValue() {
      if (
        this.selectedMenuItem &&
        this.$route.query.id === this.selectedMenuItem.id
      ) {
        this.status = this.selectedMenuItem.attributes.status;
        this.name = this.selectedMenuItem.attributes.name;
        this.getOverAllAssessments(true);
        this.description = this.selectedMenuItem.attributes.description;
        this.admin_notes = this.selectedMenuItem.attributes.admin_notes;
        this.isActive = false;
        this.overallAssessment =
          this.selectedMenuItem.attributes.overall_assessment_required;
        this.competencyCode =
          this.selectedMenuItem.attributes.competency_code || "";
        this.assessmentListKey = {
          name: "",
          id: this.selectedMenuItem.attributes.assessment_scheme_id,
        };
        this.assessment_scheme_id =
          this.selectedMenuItem.attributes.assessment_scheme_id;
        this.enableLearnerRetries = 
          this.selectedMenuItem.attributes.card_detail.attributes.enable_reattempt;
        this.maxNumberOfRetries = 
          this.selectedMenuItem.attributes.card_detail.attributes.max_reattempts;
        this.character_id =
          this.selectedMenuItem.attributes.card_detail.attributes.character_id;
        this.enableMissedSkills =
          this.selectedMenuItem.attributes.card_detail.attributes.disable_missed;
        this.hideAssessmentLabel =
          this.selectedMenuItem.attributes.card_detail.attributes.hide_assessment_label;
        this.enableChatImView =
          this.selectedMenuItem.attributes.card_detail.attributes.enable_chat_im_view;
        this.displayOverallAssessment =
          this.selectedMenuItem.attributes.card_detail.attributes.display_overall_assessment;
        this.beforeOpen();
        this.getAssessment(1);
        this.objectable_id = this.selectedMenuItem.attributes.objectable_id;
        if (this.selectedOverallAssmntItem) {
          this.activeLength = 3;
        }
      } else {
        this.clearForm();
      }
    },
    addQuestion(intro, com, id, key) {
      console.log("*** hitting addQuestion method line 3521");
      this.questionText = "";
      this.addIntro = intro;
      this.addCon = com;
      if (this.addCon) {
        this.questionText = this.conclusion;
        this.queId = "";
      } else if (this.addIntro) {
        this.questionText = this.introduction;
        this.queId = "";
      } else if (!this.addIntro && !this.addCon) {
        if (!id) {
          this.queId = "";
          this.concept = "";
          this.order = "";
          this.question_variations_attributes = [];
          this.addAsstEntitiesList = [];
        } else {
          if (this.QuestionList[key].id === id) {
            this.queId = id;
            this.Selectedconcept = this.QuestionList[key];
            this.keyTopic = this.QuestionList[key]
              ? this.QuestionList[key].attributes.key_topics
              : {};
            this.selectedkey = key;
            this.concept = this.QuestionList[key].attributes.concept;
            this.conceptDescription =
              this.QuestionList[key].attributes.concept_info;
            this.order = this.QuestionList[key].attributes.order;
            this.question_variations_attributes = [];
            if (this.QuestionList[key].attributes.variations.length > 0) {
              this.QuestionList[key].attributes.variations.forEach((ele) => {
                this.question_variations_attributes.push({
                  question: ele.attributes.question,
                });
              });
            }
            this.delete_question_variations =
              this.QuestionList[key].attributes.variations;
            this.delete_key_topics =
              this.QuestionList[key].attributes.key_topics;
            this.addAsstEntitiesList = [];
            if (this.QuestionList[key].attributes.key_topics.length > 0) {
              this.QuestionList[key].attributes.key_topics.forEach((ele) => {
                this.addAsstEntitiesList.push(ele.attributes.asst_entities);
              });
            }
          }
        }
      }
    },
    handleImportCSV(e, status) {
      utilFunctionService.showLoader();
      if (status === "dialog_skill")
        api
          .linkToDialogSkill(this.$route.query.id)
          .then((res) => {
            utilFunctionService.hideLoader();
            window.open(res.data.url, "_blank");
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      else if (status === "sync_assistant")
        api
          .syncAssistantEntities(this.$route.query.id)
          .then(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.syncAssistantSuccess
            );
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      else {
        const fb = new FormData();
        fb.append("file", e.target.files[0]);
        api
          .importCsv(this.$route.query.id, fb)
          .then(() => {
            utilFunctionService.showSuccess(commonConfig.appMessages.uploadCsv);
            utilFunctionService.hideLoader();
            this.getEntityList(1, this.$route.query.id);
          })
          .catch(() => {
            utilFunctionService.showErr(commonConfig.appMessages.invalidCsv);
            utilFunctionService.hideLoader();
          });
      }
    },
    removeAsstEntities(data) {
      api
        .removeAssesSchemeValues(data.id)
        .then(() => {
          this.getEntityList(
            1,
            this.$route.query.id,
            false,
            this.selectedAsstEntities.id
          );
          utilFunctionService.showSuccess(
            commonConfig.appMessages.assestEntityUpdated
          );
        })
        .catch(() => {});
    },
    addAssessmentValues(e) {
      e.preventDefault();
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.isActive = true;
          return;
        } else {
          utilFunctionService.showLoader();
          let data = {
            asst_entity_val_synonyms_attributes: [],
            value: "",
            is_pattern: false,
          };
          data.value = this.asstName;
          let value = this.asstSynonyms.split(",");
          value.forEach((ele, i) => {
            if (ele)
              data.asst_entity_val_synonyms_attributes[i] = { synonym: ele };
          });
          console.log(data);
          api
            .addAssessmentValues(this.selectedAsstEntities.id, data)
            .then(() => {
              this.asstName = "";
              this.asstSynonyms = "";
              this.isActive = false;
              utilFunctionService.showSuccess(
                commonConfig.appMessages.assestEntityAdd
              );
              utilFunctionService.hideLoader();
              this.getEntityList(
                1,
                this.$route.query.id,
                false,
                this.selectedAsstEntities.id
              );
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.sameSynonymList
              );
              this.isActive = false;
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    addAssessmentValuesPatterns(e) {
      e.preventDefault();
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.isActive = true;
          return;
        } else {
          utilFunctionService.showLoader();
          let data = {
            asst_entity_val_patterns_attributes: [],
            value: "",
            is_pattern: true,
          };
          data.value = this.asstName;
          // let value = this.asstSynonyms.split(",");
          let value = this.asstSynonyms;
          data.asst_entity_val_patterns_attributes[0] = { pattern: value };
          // value.forEach((ele, i) => {
          //   if (ele)
          //     data.asst_entity_val_patterns_attributes[i] = { pattern: ele };
          // });

          api
            .addAssessmentValues(this.selectedAsstEntities.id, data)
            .then(() => {
              this.asstName = "";
              this.asstSynonyms = "";
              this.isActive = false;
              utilFunctionService.showSuccess(
                commonConfig.appMessages.assestEntityAdd
              );
              utilFunctionService.hideLoader();
              this.getEntityList(
                1,
                this.$route.query.id,
                false,
                this.selectedAsstEntities.id
              );
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.sameSynonymList
              );
              this.isActive = false;
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    handleQuestionChange(id) {
      this.$refs.formdata.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          const fb = new FormData();
          if (this.addIntro) {
            utilFunctionService.showLoader();
            fb.append("dialogic_learn_obj[introduction]", this.questionText);
            api
              .addIntroductionDialogic(this.objectable_id, fb)
              .then((res) => {
                this.introduction = res.data.data.attributes.introduction;
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.createIntroduction
                );
                utilFunctionService.hideLoader();
              })
              .catch(() => {});
          } else if (this.addCon) {
            utilFunctionService.showLoader();
            fb.append("dialogic_learn_obj[conclusion]", this.questionText);
            api
              .addConclusionDialogic(this.objectable_id, fb)
              .then((res) => {
                this.conclusion = res.data.data.attributes.conclusion;
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.createConclusion
                );
                utilFunctionService.hideLoader();
              })
              .catch(() => {});
          } else {
            if (
              this.question_variations_attributes.length <= 0 ||
              this.addAsstEntitiesList.length <= 0
            ) {
              this.isActive = true;
              return;
            } else {
              utilFunctionService.showLoader();
              let fb = new FormData();
              fb.append(
                "dialogic_question[concept]",
                this.concept ? this.concept : ""
              );
              fb.append(
                "dialogic_question[concept_info]",
                this.conceptDescription ? this.conceptDescription : ""
              );
              if (this.queId) {
                fb.append("dialogic_question[order]", this.order);
                if (
                  this.delete_question_variations.length > 0 &&
                  this.question_variations_attributes.length > 0
                ) {
                  this.question_variations_attributes.forEach((ele, key) => {
                    if (this.delete_question_variations.length > key) {
                      fb.append(
                        "dialogic_question[question_variations_attributes][][id]",
                        this.delete_question_variations[key].id
                      );
                    }
                    fb.append(
                      "dialogic_question[question_variations_attributes][][question]",
                      ele.question
                    );
                  });
                } else {
                  if (this.question_variations_attributes.length > 0) {
                    this.question_variations_attributes.forEach((ele) => {
                      fb.append(
                        "dialogic_question[question_variations_attributes][][question]",
                        ele.question
                      );
                    });
                  }
                }
                if (this.delete_key_topics.length > 0) {
                  this.delete_key_topics.forEach((eles) => {
                    let index = this.addAsstEntitiesList.findIndex((ele) => {
                      return eles.attributes.asst_entities.id === ele.id;
                    });
                    if (index === -1) {
                      fb.append(
                        "dialogic_question[key_topics_attributes][][id]",
                        eles.id
                      );
                      fb.append(
                        "dialogic_question[key_topics_attributes][][_destroy]",
                        true
                      );
                    } else {
                      this.addAsstEntitiesList.splice(index, 1);
                    }
                  });
                }
                if (this.addAsstEntitiesList.length > 0) {
                  this.addAsstEntitiesList.forEach((ele) => {
                    fb.append(
                      "dialogic_question[key_topics_attributes][][asst_entity_id]",
                      ele.id
                    );
                  });
                }
                api
                  .updateQuestions(this.queId, fb)
                  .then((res) => {
                    utilFunctionService.showSuccess(
                      commonConfig.appMessages.updatedQuestion
                    );
                    utilFunctionService.hideLoader();
                    this.queId = res.data.data.id;
                    this.concept = res.data.data.attributes.concept;
                    this.conceptDescription =
                      res.data.data.attributes.concept_info;
                    this.order = res.data.data.attributes.order;
                    this.question_variations_attributes = [];
                    this.delete_question_variations =
                      res.data.data.attributes.variations;
                    this.delete_key_topics =
                      res.data.data.attributes.key_topics;
                    this.addAsstEntitiesList = [];
                    this.entitysetUp("");
                    if (id) {
                      //this.backSectionData(true,id)
                    } else {
                      if (res.data.data.attributes.variations.length > 0) {
                        res.data.data.attributes.variations.forEach((ele) => {
                          this.question_variations_attributes.push({
                            question: ele.attributes.question,
                          });
                        });
                      }
                      if (res.data.data.attributes.key_topics.length > 0) {
                        res.data.data.attributes.key_topics.forEach((ele) => {
                          this.addAsstEntitiesList.push(
                            ele.attributes.asst_entities
                          );
                        });
                      }
                    }
                  })
                  .catch(() => {});
              } else {
                if (this.addAsstEntitiesList.length > 0) {
                  this.addAsstEntitiesList.forEach((ele) => {
                    fb.append(
                      "dialogic_question[key_topics_attributes][][asst_entity_id]",
                      ele.id
                    );
                  });
                }
                if (this.question_variations_attributes.length > 0) {
                  this.question_variations_attributes.forEach((ele) => {
                    fb.append(
                      "dialogic_question[question_variations_attributes][][question]",
                      ele.question
                    );
                  });
                }
                fb.append("dialogic_learn_obj_id", this.objectable_id);
                fb.append(
                  "dialogic_question[order]",
                  this.QuestionList.length + 2
                );
                api
                  .addQuestions(this.objectable_id, fb)
                  .then((res) => {
                    this.queId = res.data.data.id;
                    utilFunctionService.showSuccess(
                      commonConfig.appMessages.createQuestion
                    );
                    utilFunctionService.hideLoader();
                    this.entitysetUp(this.queId);
                  })
                  .catch((err) => {
                    utilFunctionService.hideLoader();
                    utilFunctionService.showErr(err);
                    console.log(err.order);
                  });
              }
            }
          }
        }
      });
    },
    submitDialogic(event, value = "", key = "") {
      // this.$refs.forms.validate().then((success) => {
      //   if (!success) {
      //     this.isActive = true;
      //     return;
      //   } else {
      utilFunctionService.showLoader();
      let fb = new FormData();
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("card_type", "dialogic");
      fb.append("learning_object[status]", "drafted");
      fb.append("learning_object[learning_object_type]", "interaction");
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[description]", this.description);
      fb.append("learning_object[admin_notes]", this.admin_notes);
      fb.append("learning_object[competency_code]", this.competencyCode);
      fb.append(
        "learning_object[overall_assessment_required]",
        this.overallAssessment ? this.overallAssessment : false
      );
      fb.append(
        "learning_object[assessment_scheme_id]",
        this.assessmentListKey.id
      );
      this.assessment_scheme_id = this.assessmentListKey.id;
      fb.append("card[title]", this.name);
      fb.append("card[disable_missed]", this.enableMissedSkills);
      fb.append("card[enable_reattempt]", this.enableLearnerRetries);
      fb.append("card[max_reattempts]", this.maxNumberOfRetries);
      fb.append("card[character_id]", this.addedCustomer.id);
      fb.append("card[hide_assessment_label]", this.hideAssessmentLabel);
      fb.append("card[enable_chat_im_view]", this.enableChatImView);
      fb.append(
        "card[display_overall_assessment]",
        this.displayOverallAssessment
      );
      if (this.$route.query.id) {
        if (!this.addedCustomer.id) {
          utilFunctionService.hideLoader();
          this.emptyTabs(value, key, this.$route.query.id);
        } else {
          api
            .updateLo(this.$route.params.id, this.$route.query.id, fb)
            .then(() => {
              utilFunctionService.hideLoader();
              utilFunctionService.showSuccess(
                commonConfig.appMessages.LoDraftText
              );
              this.dialogicContentId = value;
              this.emptyTabs(value, key, this.$route.query.id);
            })
            .catch(() => {
              utilFunctionService.hideLoader();
              this.clearForm();
            });
        }
      } else {
        api
          .saveLo(this.$route.params.id, fb)
          .then((res) => {
            utilFunctionService.showSuccess(
              commonConfig.appMessages.LoDraftText
            );
            utilFunctionService.hideLoader();
            this.$route.query.id = res.data.data.id;
            this.objectable_id = res.data.data.attributes.objectable_id;
            this.dialogicContentId = value;
            this.emptyTabs(value, key, this.$route.query.id);
            this.handleDialogicContent("", "basic-information", 0);
            this.$router.push({
              id: this.$route.params.id,
              query: { id: res.data.data.id },
            });
            window.location.reload();
          })
          .catch(() => {
            utilFunctionService.hideLoader();
            this.clearForm();
            this.$emit("updateDialogicSubmit", false, this.$route.query.id);
          });
      }
      // }
      // });
    },
    addEntity(e) {
      e.preventDefault();
      if (!this.entityName) {
        utilFunctionService.showErr(commonConfig.appMessages.assestEntityErr);
      } else {
        const fb = new FormData();
        fb.append("asst_entity[name]", this.entityName);
        api
          .addQuizEntity(this.$route.query.id, fb)
          .then((res) => {
            this.selectedAsstEntities = res.data.data;
            this.entityName = "";
            this.getEntityList(1, this.$route.query.id, false);
          })
          .catch(() => {
            utilFunctionService.showErr(
              commonConfig.appMessages.entityNameValueError
            );
          });
      }
    },
    clearForm(status) {
      this.status = "drafted";
      this.name = "";
      this.description = "";
      this.admin_notes = "";
      this.isActive = false;
      this.overallAssessment = "";
      this.displayOverallAssessment = "";
      this.competencyCode = "";
      this.assessmentListKey = {};
      this.selectCharacter = [];
      this.addedCustomer = {};
      this.character_id = "";
      this.hideAssessmentLabel = "";
      if (this.searchCharacters)
        this.searchCharacters.map((x) => (x.status = false));
      if (!status) {
        this.isActiveTab = [];
        this.isActiveTab.push("active");
        this.dialogicContentId = "basic-information";
      }
    },
    getAssessment(page) {
      api
        .getAllAssessment(page, "", "", "")
        .then((res) => {
          this.assessmentLists = res.data.data;
          this.assessmentLists.map((x) => {
            if (
              this.assessmentListKey.id &&
              parseInt(this.assessmentListKey.id) === parseInt(x.id)
            ) {
              this.assessmentListKey = { name: x.attributes.name, id: x.id };
            }
            return x;
          });
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    selectedAssessment(event) {
      let value = event.target.value.split("*");
      this.assessmentListKey = {};
      if (value[1] && value[0]) {
        this.assessmentListKey = { name: value[1], id: value[0] };
      }
    },
    getCharactersData() {
      console.log(this.worldId, "*** this.worldId");
      api;
      //.getElmDetails(this.$route.params.id)
      //.then((res) => {
      api
        .getWorldCharacters(this.worldId)
        .then((res) => {
          console.log(
            res.data.data,
            "*** getWorldCharacters res.data.data ***"
          );
          this.searchCharacters = [];
          this.characters = [];
          this.characters = res.data.data.map((x) => ({
            id: x.id,
            world_role: x.attributes.world_role,
            org_name: x.attributes.org_name,
            //char_photo_url: x.attributes.char_photo_url.thumbnail,
            char_full_name: x.attributes.char_full_name,
            char_gender: x.attributes.char_gender,
            char_age: x.attributes.char_age,
            status: false,
          }));
          this.searchCharacters = this.characters;
          this.charactersList = this.characters;
          if (this.character_id) {
            this.selectCharacters("", this.character_id);
          }
        })
        .catch();
      //})
    },
    handleAsstEntitiesList(data, status = false) {
      if (status) {
        api
          .removeAssesstEntity(data.id)
          .then(() => {
            this.getEntityList(1, this.$route.query.id);
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      } else {
        this.selectedAsstEntities = data;
        this.asstSynonyms = "";
        this.asstName = "";
        this.isActive = false;
      }
    },
    selectCharacters(character, id) {
      this.addedCustomer = {};
      this.character_id = "";
      if (this.searchCharacters.length > 0) {
        const i = this.searchCharacters.findIndex((elem) => {
          return parseInt(elem.id) === parseInt(id);
        });
        this.searchCharacters.map((x) => (x.status = false));
        if (i !== -1) this.searchCharacters[i].status = true;
        this.customer = "";
        this.addedCustomer = i !== -1 ? this.searchCharacters[i] : {};
        this.noCustomerAdded = false;
      }
    },
    removeCustomer() {
      this.addedCustomer = {};
      this.character_id = "";
      this.searchCharacters.map((x) => (x.status = false));
    },
    handleDialogicContent(e, value, key) {
      console.log(key, this.dialogicContentId, "*** handleDialogicContent");
      if (e) {
        e.preventDefault();
      }
      if (this.dialogicContentId === "basic-information" && key !== 0) {
        console.log(this.dialogicContentId);
        //this.submitDialogic("drafted", value, key);
        this.emptyTabs(value, key);
        this.ariaSelected = false;
      } else if (key === 3) {
        this.overallAssmntItem = [];
        this.getOverAllAssessments(true);
        this.createsDialogicAssessment(value, key);
      } else if (key === 4) {
        this.emptyTabs(value, key);
        this.getOverAllAssessments(true);
      } else if (key === 2) {
        this.$refs.form.validate().then(() => {
          //if (!this.selectedAsstEntities.attributes.label) {
          // this.isLabelErrActive = true;
          //return;
          //}
          //else{
          this.emptyTabs(value, key);
          this.ariaSelected = false;
          //}
        });
      } else this.emptyTabs(value, key);
      this.ariaSelected = false;
    },
    saveDialogicContent(e, value, key) {
      console.log(key, this.dialogicContentId, "*** saveDialogicContent");
      if (e) {
        e.preventDefault();
      }
      if (this.dialogicContentId === "basic-information" && key !== 0) {
        console.log(this.dialogicContentId);
        this.submitDialogic("drafted", value, key);
      } else if (key === 3) {
        this.overallAssmntItem = [];
        this.getOverAllAssessments(true);
        this.createsDialogicAssessment(value, key);
      } else if (key === 4) {
        this.emptyTabs(value, key);
        this.getOverAllAssessments(true);
      } else if (key === 2) {
        this.$refs.form.validate().then(() => {
          if (!this.selectedAsstEntities.attributes.label) {
            this.isLabelErrActive = true;
            return;
          } else {
            this.emptyTabs(value, key);
            this.ariaSelected = false;
          }
        });
      } else this.emptyTabs(value, key);
      this.ariaSelected = false;
    },
    handleSynonymModal(data) {
      this.selectedSynonymList = {};
      this.selectedSynonymList = JSON.parse(JSON.stringify(data));
      this.selectedAsstEntities = {};
      this.selectedAsstEntities = this.selectedSynonymList;
      this.entitySynonymName = "";
      this.deletedSynonymsList = [];
      this.$modal.show("entityValueModal");
    },
    handlePatternModal(data) {
      console.log(data);
      this.selectedPatternList = {};
      this.selectedPatternList = JSON.parse(JSON.stringify(data));
      this.selectedAsstEntities = {};
      this.selectedAsstEntities = this.selectedPatternList;
      this.entityPatternName = "";
      this.deletedSynonymsList = [];
      this.$modal.show("entityValueModalPattern");
    },
    emptyTabs(value, key, elmId) {
      this.isActiveTab = [];
      this.getEntityList(key, elmId);
      if (
        this.asstEntitiesList &&
        this.asstEntitiesList.length === 0 &&
        key === 2
      ) {
        this.dialogicContentId = "entity-setup";
        for (let i = 0; i <= key - 1; i++) this.isActiveTab.push("active");
        utilFunctionService.showErr(commonConfig.appMessages.updateTabError);
      } else if (
        !this.isQuestionCreated &&
        this.QuestionList &&
        this.QuestionList.length === 0 &&
        key === 3
      ) {
        this.dialogicContentId = "question-setup";
        for (let i = 0; i <= key - 1; i++) this.isActiveTab.push("active");
        utilFunctionService.showErr(commonConfig.appMessages.updateTabError);
      } else {
        this.dialogicContentId = value;
        for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
        this.isQuestionCreated = false;
        this.clickCount = 0;
      }
    },
    createsDialogicAssessment() {
      this.$refs.formAssessment.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          utilFunctionService.showLoader();
          if (this.assessment_labels) {
            const fb = new FormData();
            fb.append(
              "dialogic_assmnt_item[assessment_label_id]",
              this.assessment_labels
            );
            fb.append(
              "dialogic_assmnt_item[value_count_min]",
              this.dialogicAssmntItemMinScore
            );
            fb.append(
              "dialogic_assmnt_item[value_count_max]",
              this.dialogicAssmntItemMaxScore
            );
            fb.append("dialogic_assmnt_item[points]", this.points);
            fb.append(
              "dialogic_assmnt_item[follow_up_points]",
              this.followUpPoint
            );
            fb.append(
              "dialogic_assmnt_item[have_follow_up_question]",
              this.isFollowUp
            );
            fb.append(
              "dialogic_assmnt_item[use_enhanced_responses]",
              this.useEnhanced
            );

            // Passing main character response data in
            if (this.setVariationList.length > 0) {
              this.setVariationList.forEach((el, j) => {
                el.variation.forEach((ele, i) => {
                  if (i < 5 && j < 4) {
                    //if ( ele.response) {

                    fb.append(
                      "dialogic_assmnt_item[dialogic_responses_attributes][][variation]",
                      i + 1
                    );
                    fb.append(
                      "dialogic_assmnt_item[dialogic_responses_attributes][][response]",
                      ele.response
                    );
                    fb.append(
                      "dialogic_assmnt_item[dialogic_responses_attributes][][iteration]",
                      j + 1
                    );
                    if (ele.id) {
                      fb.append(
                        "dialogic_assmnt_item[dialogic_responses_attributes][][id]",
                        ele.id
                      );
                    }
                    //}
                  }
                });
              });
            }

            // Passing enhanced character response data in if present
            if (this.setEnhancedVariationList.length > 0) {
              this.setEnhancedVariationList.forEach((ev) => {
                ev.iteration.forEach((iter, i) => {
                  iter.variation.forEach((vari, v) => {
                    if (v < 5 && i < 4) {
                      //if ( vari.present_response || vari.absent_response) {

                      fb.append(
                        "dialogic_assmnt_item[dialogic_enhanced_responses_attributes][][variation]",
                        v + 1
                      );
                      fb.append(
                        "dialogic_assmnt_item[dialogic_enhanced_responses_attributes][][present_response]",
                        vari.present_response
                      );
                      fb.append(
                        "dialogic_assmnt_item[dialogic_enhanced_responses_attributes][][absent_response]",
                        vari.absent_response
                      );
                      fb.append(
                        "dialogic_assmnt_item[dialogic_enhanced_responses_attributes][][iteration]",
                        i + 1
                      );
                      if (vari.id) {
                        fb.append(
                          "dialogic_assmnt_item[dialogic_enhanced_responses_attributes][][id]",
                          vari.id
                        );
                      }
                      if (vari.asst_entity_value_id) {
                        fb.append(
                          "dialogic_assmnt_item[dialogic_enhanced_responses_attributes][][asst_entity_value_id]",
                          vari.asst_entity_value_id
                        );
                      } else {
                        fb.append(
                          "dialogic_assmnt_item[dialogic_enhanced_responses_attributes][][asst_entity_value_id]",
                          ev.asst_entity_value_id
                        );
                      }
                      //}
                    }
                  });
                });
              });
            }

            // Follow up questions
            if (this.question_variations_attributes.length > 0) {
              this.question_variations_attributes.forEach((ele, k) => {
                fb.append(
                  "dialogic_assmnt_item[follow_up_questions_attributes][][question]",
                  ele.question
                );
                fb.append(
                  "dialogic_assmnt_item[follow_up_questions_attributes][][iteration]",
                  k + 1
                );
                if (ele.id) {
                  fb.append(
                    "dialogic_assmnt_item[follow_up_questions_attributes][][id]",
                    ele.id
                  );
                }
              });
            }

            if (this.variations_attributes.length > 0) {
              this.variations_attributes.forEach((ele, key) => {
                // if (ele.response) {
                if (ele.id)
                  fb.append(
                    "dialogic_assmnt_item[dialogic_responses_attributes][][id]",
                    ele.id
                  );
                fb.append(
                  "dialogic_assmnt_item[dialogic_responses_attributes][][response]",
                  ele.response
                );
                fb.append(
                  "dialogic_assmnt_item[dialogic_responses_attributes][][variation]",
                  key + 1
                );
                // }
              });
            }

            if (this.dialogic_assmnt_item) {
              if (this.variations_briefattributes.length > 0) {
                this.variations_briefattributes.forEach((ele) => {
                  // if (ele.content) {
                  if (ele.id)
                    fb.append(
                      "dialogic_assmnt_item[debriefs_attributes][][id]",
                      ele.id
                    );
                  fb.append(
                    "dialogic_assmnt_item[debriefs_attributes][][content]",
                    ele.content
                  );
                  // }
                });
              }
              if (this.selectedEntityValue.length > 0) {
                this.selectedEntityValue.forEach((ele) => {
                  if (ele.id) {
                    this.destroy_selectedEntityValue.splice(0, 1);
                  }
                  fb.append(
                    "dialogic_assmnt_item[required_key_topic_values_attributes][][key_topic_value_id]",
                    ele.id
                  );
                });
              }
              if (this.destroy_selectedEntityValue.length > 0) {
                this.destroy_selectedEntityValue.forEach((ele) => {
                  fb.append(
                    "dialogic_assmnt_item[required_key_topic_values_attributes][][id]",
                    ele.id
                  );
                  fb.append(
                    "dialogic_assmnt_item[required_key_topic_values_attributes][][_destroy]",
                    true
                  );
                });
              }
              api
                .updateDialogicAssessment(this.dialogic_assmnt_item, fb)
                .then(() => {
                  if (parseInt(this.formValue) === parseInt(1)) {
                    this.formValue = 2;
                  } else {
                    this.formValue = 3;
                  }
                  utilFunctionService.hideLoader();
                })
                .catch(() => {
                  utilFunctionService.hideLoader();
                  utilFunctionService.showErr(commonConfig.appMessages.error);
                });
            } else {
              if (this.selectedEntityValue.length > 0) {
                this.selectedEntityValue.forEach((ele) => {
                  fb.append(
                    "dialogic_assmnt_item[required_key_topic_values_attributes][][key_topic_value_id]",
                    ele.id
                  );
                });
              }
              fb.append("key_topic_id", this.SelectedKeyTopic.id);
              api
                .createsDialogicAssessment(this.SelectedKeyTopic.id, fb)
                .then((res) => {
                  utilFunctionService.hideLoader();
                  if (parseInt(this.formValue) === parseInt(1)) {
                    this.formValue = 2;
                  } else {
                    this.formValue = 3;
                  }
                  this.clearAssessment(false, res.data.data, this.formValue);
                })
                .catch(() => {
                  utilFunctionService.hideLoader();
                  utilFunctionService.showErr(commonConfig.appMessages.error);
                });
            }
          }
          // This handles missed assessment
          else {
            const fb = new FormData();
            fb.append("key_topic_id", this.SelectedKeyTopic.id);
            fb.append(
              "missed_assmnt_item[has_follow_up_question]",
              this.isFollowUp
            );
            if (this.setVariationList.length > 0) {
              this.setVariationList.forEach((el, j) => {
                el.variation.forEach((ele, i) => {
                  //if (ele.response && i < 5 && j < 4) {
                  fb.append(
                    "missed_assmnt_item[missed_responses_attributes][][variation]",
                    i + 1
                  );
                  fb.append(
                    "missed_assmnt_item[missed_responses_attributes][][response]",
                    ele.response
                  );
                  fb.append(
                    "missed_assmnt_item[missed_responses_attributes][][iteration]",
                    j + 1
                  );
                  if (ele.id) {
                    fb.append(
                      "missed_assmnt_item[missed_responses_attributes][][id]",
                      ele.id
                    );
                  }
                  //}
                });
              });
            }
            if (this.question_variations_attributes.length > 0) {
              this.question_variations_attributes.forEach((ele, k) => {
                fb.append(
                  "missed_assmnt_item[follow_up_questions_attributes][][question]",
                  ele.question
                );
                if (k < 3)
                  fb.append(
                    "missed_assmnt_item[follow_up_questions_attributes][][iteration]",
                    k + 1
                  );
                if (ele.id) {
                  fb.append(
                    "missed_assmnt_item[follow_up_questions_attributes][][id]",
                    ele.id
                  );
                }
              });
            }
            if (this.dialogic_assmnt_item) {
              if (this.variations_briefattributes.length > 0) {
                this.variations_briefattributes.forEach((ele) => {
                  // if (ele.content) {
                  if (ele.id)
                    fb.append(
                      "missed_assmnt_item[debriefs_attributes][][id]",
                      ele.id
                    );
                  fb.append(
                    "missed_assmnt_item[debriefs_attributes][][content]",
                    ele.content
                  );
                  // }
                });
              }
              api
                .updatedMissedAssessment(this.dialogic_assmnt_item, fb)
                .then((res) => {
                  if (parseInt(this.formValue) === parseInt(1)) {
                    this.formValue = 2;
                  } else {
                    this.formValue = 3;
                  }
                  this.clearmissedAssessment(
                    false,
                    res.data.data,
                    this.formValue
                  );
                  utilFunctionService.hideLoader();
                })
                .catch(() => {
                  utilFunctionService.hideLoader();
                  utilFunctionService.showErr(commonConfig.appMessages.error);
                });
            } else {
              api
                .createsMissedAssessment(this.SelectedKeyTopic.id, fb)
                .then((res) => {
                  if (parseInt(this.formValue) === parseInt(1)) {
                    this.formValue = 2;
                  } else {
                    this.formValue = 3;
                  }
                  this.clearmissedAssessment(
                    false,
                    res.data.data,
                    this.formValue
                  );
                  utilFunctionService.hideLoader();
                })
                .catch(() => {
                  utilFunctionService.hideLoader();
                  utilFunctionService.showErr(commonConfig.appMessages.error);
                });
            }
          }
        }
      });
    },
    updateAssessmentValues(status, index, synData) {
      if (status === "delete") {
        this.selectedSynonymList.attributes.asst_entity_val_synonyms.splice(
          index,
          1
        );
        this.deletedSynonymsList.push(synData.id);
        this.deleteStatus = true;
      }
      if (status === "add") {
        const index =
          this.selectedSynonymList.attributes.asst_entity_val_synonyms.findIndex(
            (elem) =>
              elem.attributes.synonym.toUpperCase() ===
              this.entitySynonymName.toUpperCase()
          );
        if (index === -1) {
          this.selectedSynonymList.attributes.asst_entity_val_synonyms.push({
            attributes: {
              synonym: this.entitySynonymName,
              asst_entity_value_id: null,
            },
          });
          this.entitySynonymName = "";
        } else {
          utilFunctionService.showErr(
            commonConfig.appMessages.sameSynonymNameError
          );
        }
      }
      if (status === "done") {
        let data = {
          asst_entity_value: {
            asst_entity_val_synonyms_attributes: [],
            value: this.selectedSynonymList.attributes.value,
          },
        };
        this.selectedSynonymList.attributes.asst_entity_val_synonyms.forEach(
          (ele) => {
            if (!ele.attributes.asst_entity_value_id)
              data.asst_entity_value.asst_entity_val_synonyms_attributes.push({
                synonym: ele.attributes.synonym,
              });
          }
        );
        if (this.deletedSynonymsList.length > 0) {
          this.deletedSynonymsList.forEach((e) => {
            data.asst_entity_value.asst_entity_val_synonyms_attributes.push({
              id: e,
              _destroy: true,
            });
          });
        }
        this.deleteStatus = false;
        api
          .updateAssessmentValues(this.selectedSynonymList.id, data)
          .then(() => {
            this.getEntityList(
              1,
              this.$route.query.id,
              true,
              this.selectedAsstEntities
            );
            this.deletedSynonymsList = [];
            utilFunctionService.showSuccess(
              commonConfig.appMessages.assestEntityUpdated
            );
          })
          .catch((error) => {
            if (error.response.status == 422) {
              if (error.response.data && error.response.data.error) {
                utilFunctionService.showErr(error.response.data.error);
              }
            }
            utilFunctionService.hideLoader();
          });
        this.$modal.hide("entityValueModal");
      }
    },
    updateAssessmentValuesPattern(status, index, synData) {
      if (status === "delete") {
        this.selectedPatternList.attributes.asst_entity_val_patterns.splice(
          index,
          1
        );
        this.deletedSynonymsList.push(synData.id);
        this.deleteStatus = true;
      }
      if (status === "add") {
        console.log("adding");
        const index =
          this.selectedPatternList.attributes.asst_entity_val_patterns.findIndex(
            (elem) =>
              elem.attributes.pattern.toUpperCase() ===
              this.entityPatternName.toUpperCase()
          );
        if (index === -1) {
          this.selectedPatternList.attributes.asst_entity_val_patterns.push({
            attributes: {
              pattern: this.entityPatternName,
              asst_entity_value_id: null,
            },
          });
          this.entityPatternName = "";
        } else {
          utilFunctionService.showErr(
            commonConfig.appMessages.sameSynonymNameError
          );
        }
      }
      if (status === "done") {
        let data = {
          asst_entity_value: {
            asst_entity_val_patterns_attributes: [],
            value: this.selectedPatternList.attributes.value,
          },
        };
        this.selectedPatternList.attributes.asst_entity_val_patterns.forEach(
          (ele) => {
            if (!ele.attributes.asst_entity_value_id)
              data.asst_entity_value.asst_entity_val_patterns_attributes.push({
                pattern: ele.attributes.pattern,
              });
          }
        );
        if (this.deletedSynonymsList.length > 0) {
          this.deletedSynonymsList.forEach((e) => {
            data.asst_entity_value.asst_entity_val_patterns_attributes.push({
              id: e,
              _destroy: true,
            });
          });
        }
        this.deleteStatus = false;
        api
          .updateAssessmentValues(this.selectedPatternList.id, data)
          .then(() => {
            this.getEntityList(
              1,
              this.$route.query.id,
              true,
              this.selectedAsstEntities
            );
            this.deletedSynonymsList = [];
            utilFunctionService.showSuccess(
              commonConfig.appMessages.assestEntityUpdated
            );
          })
          .catch((error) => {
            if (error.response.status == 422) {
              if (error.response.data && error.response.data.error) {
                utilFunctionService.showErr(error.response.data.error);
              }
            }
            utilFunctionService.hideLoader();
          });
        this.$modal.hide("entityValueModalPattern");
      }
    },
    getEntityList(
      key,
      elmId,
      status = true,
      entityId = null,
      isUpdated = true
    ) {
      if (!elmId) elmId = this.$route.query.id ? this.$route.query.id : "";
      console.log(isUpdated);
      utilFunctionService.showLoader();
      elmId &&
        key === 1 &&
        api
          .getAsstEntities(elmId)
          .then((res) => {
            this.asstEntitiesList = res.data.data;

            if (status) this.selectedAsstEntities = this.asstEntitiesList[0];
            if (entityId) {
              this.asstEntitiesList.forEach((ele) => {
                if (ele.id === entityId) this.selectedAsstEntities = ele;
              });
            }
            if (this.asstEntitiesList[0].attributes.asst_entity_values)
              this.selectedSynonymList =
                this.asstEntitiesList[0].attributes.asst_entity_values[0];
            utilFunctionService.hideLoader();
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      if (key === 2) {
        this.entitysetUp("");
      }
      if (key === 3 || this.backSection) {
        api
          .getOverallAssmntItem(this.$route.query.id)
          .then((res3) => {
            this.overallAssmntItem = res3.data.data;
            this.getAssessmentScheme();
            api
              .getQuestions(this.objectable_id)
              .then((res2) => {
                this.overallAssmntItem = res2.data.data;
                this.selectedOverallAssmntItem = this.overallAssmntItem[0];
                this.overallAssmntItemFeedback =
                  this.overallAssmntItem[0].attributes.feedback;
                this.overallAssmntItemMaxScore =
                  this.overallAssmntItem[0].attributes.max_score;
                this.overallAssmntItemMinScore =
                  this.overallAssmntItem[0].attributes.min_score;
              })
              .catch(() => {
                utilFunctionService.hideLoader();
              });
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      } else {
        utilFunctionService.hideLoader();
        this.getAssessmentScheme();
      }
    },

    entitysetUp(id) {
      api
        .getDialogicList(this.objectable_id)
        .then((res) => {
          this.getEntity();
          this.QuestionList = res.data.data.attributes.questions;
          this.QuestionList.sort(function (a, b) {
            return a.attributes.order - b.attributes.order;
          });
          console.log(
            res.data.data.attributes.questions,
            "*** res.data.data.attributes.questions"
          );
          this.overallAssmntItem = this.QuestionList;
          this.allQuestionTestList = res.data.data.attributes.questions;
          this.introduction = res.data.data.attributes.introduction;
          this.questionText = this.introduction;
          if (id)
            this.addQuestion(
              false,
              false,
              this.queId,
              this.QuestionList.length - 1
            );
          this.conclusion = res.data.data.attributes.conclusion;
          this.totalQuizQues =
            this.allQuestionTestList.length > 5
              ? 5
              : this.allQuestionTestList.length;
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
      !this.asstEntitiesQuizList &&
        api
          .getAsstEntities(this.$route.query.id)
          .then((res) => {
            this.asstEntitiesQuizList = res.data.data;
          })
          .catch();
    },
    getAssessmentScheme() {
      api
        .getAssessmentScheme(this.assessment_scheme_id)
        .then((res) => {
          this.assessmentScheme = res.data.data;
        })
        .catch();
    },
    getEntity() {
      api
        .getAsstEntities(this.$route.query.id)
        .then((res) => {
          this.asstEntitiesList = res.data.data;
          this.asstEntitiesList.forEach((ele, i) => {
            this.asstEntitiesList[i].status = false;
          });
        })
        .catch();
    },
    addVariation(isSelect, type) {
      if (type) {
        if (!isSelect) {
          this.variations_attributes.push({ response: "" });
        } else {
          this.variationsSelect = isSelect;
        }
      } else {
        if (!isSelect) {
          this.question_variations_attributes.push({ question: "" });
        } else {
          this.variationsSelect = isSelect;
        }
      }
    },
    addDebriefVariation(isSelect, type) {
      console.log(type);
      if (!isSelect) {
        this.variations_briefattributes.push({ content: "" });
      } else {
        this.variationsSelect = isSelect;
      }
    },
    selectEntity(id) {
      this.asstEntitiesList.forEach((x) => {
        if (x.id === id) {
          let index = this.addAsstEntitiesList.findIndex((y) => {
            return parseInt(y.id) === parseInt(x.id);
          });
          if (index !== -1) {
            this.addAsstEntitiesList.splice(index, 1);
          } else {
            this.addAsstEntitiesList.push(x);
          }
        }
      });
    },
    setActive(key) {
      this.isActiveTab = [];
      for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.enhanced-response-container {
  padding: 0 1em 2em 1em;
}

.enhanced-label {
  @include font-bold;
}

.enhanced-response-box {
  padding: 1em;
  border: 1px solid #ccc;
  margin-bottom: 2em;
}
</style>
