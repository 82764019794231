<template>
  <div id="QaEmail">
    <div class="container-3-column">
      <header class="header pr-0 flex-nowrap">
        <div class="header-left">
          <h2>QA Email</h2>
        </div>
      </header>
      <div>
        <div id="QaMessageContainer" v-if="emailLists.length > 0">
          <div class="learn-object" v-for="(email,i) in emailLists" :key="email.id">
            <div class="learn-object-forward">
              <div class="learn-object-tag">Subject: {{filterEmailIndex[i].sub}}</div>
              <div class="learn-object-paragraph">
                <div class="learn-object-desc">
                  <p v-html="filterEmailIndex[i].msg"></p>
                </div>
              </div>
              <div class="count-container">
                <div class="learn-object-paragraph">
                  <div class="learn-object-desc">
                    <p>Iteration: {{filterEmailIndex[i].iteration}}</p>
                    <p>QA condition hit ID(s): {{emailIterationDetails.qa_condition_hits}}</p>
                    <p>Date Sent: {{emailIterationDetails.date_sent}}</p>
                  </div>
                </div>
                <div class="drop-container">
                  <drop class="drop" @drop="handleEntentDrop">
                    <label class="title-text">Edit Entities:</label>
                    <div class="evaluation-dragbox">
                      <span
                        class="caption"
                      >{{selectedEntentValues.length == 0 ? 'Drag and Drop entities values here' : ''}}</span>
                      <div class="evaluation-chips-container">
                        <div
                          class="evaluation-chips"
                          v-for="(entent, key) in selectedEntentValues"
                          :key="key"
                        >
                          <span>{{entent.ententName}} : {{entent.ententValueName}}</span>
                          <a href="javascript:void(0);">
                            <em class="icon-close" @click="removeEntentValues(key)"></em>
                          </a>
                        </div>
                        <!-- <div class="remove-icon">
                          <img
                            alt="remove"
                            class="remove-image"
                            src="../assets/images/remove-icon.png"
                          />
                        </div> -->
                      </div>
                    </div>
                  </drop>
                  <drop class="drop" @drop="handleIntentDrop">
                    <label class="title-text">Edit Intents:</label>
                    <div class="evaluation-dragbox">
                      <span
                        class="caption"
                      >{{selectedIntentValues.length == 0 ? 'Drag and Drop intent here' : ''}}</span>
                      <div class="evaluation-chips-container">
                        <div
                          class="evaluation-chips"
                          v-for="(intent, key) in selectedIntentValues"
                          :key="key"
                        >
                          <span>{{intent.intentName}}</span>
                          <a href="javascript:void(0);">
                            <em class="icon-close" @click="removeIntentValues(key)"></em>
                          </a>
                        </div>
                        <!-- <div class="remove-icon">
                          <img
                            alt="remove"
                            class="remove-image"
                            src="../assets/images/remove-icon.png"
                          />
                        </div> -->
                      </div>
                    </div>
                  </drop>
                  <div class="generat-response-button" v-if="filterEmailIndex[i].arr[0].attributes.qa_fixed !== true">
                    <button
                      href="javascript:void(0);"
                      @click="genearteIterationResponse();"
                      class="btn primary mr-2"
                    >Generate Response</button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="learn-object-editable"
              v-for="(item) in filterEmailIndex[i].arr"
              :key="item.id"
            >
              <div class="learn-reply-head">
                <span>Reply from {{item.attributes.character.attributes.char_full_name}}</span>
              </div>
              <div class="learn-object-tag">Reply: {{email.attributes.email_subject}}</div>
              <div
                class="learn-object-editor"
                v-if="item.attributes.qa_fixed === false"
              >
                <div class="form-group">
                  <vue-editor
                    class="form-control"
                    :editorToolbar="fullToolbar"
                    v-model="item.attributes.response"
                  ></vue-editor>
                </div>
                <div class="learn-object-send">
                  <button
                    href="javascript:void(0);"
                    @click="saveResponse(item.id, item.attributes.response);"
                    class="btn primary mr-2"
                  >Save</button>
                  <button
                    href="javascript:void(0);"
                    @click="setActiveResponse(item);$modal.show('submitResponseConfirmation')"
                    class="btn primary"
                  >Mark Resolved</button>
                </div>
              </div>
              <div v-else>
                <div class="learn-object-paragraph">
                  <div class="learn-object-desc">
                    <p v-html="item.attributes.response"></p>
                  </div>
                </div>
                <div class="learn-object-paragraph" v-if="item.attributes.qa_fixed === true">
                  <div class="learn-object-desc my-4 details">
                    <button href="javascript:void(0);" class="btn primary my-3">Resolved</button>
                    <p>Resolved Date: {{item.attributes.qa_resolved_date}}</p>
                    <p>Resolved By: {{item.attributes.qa_user}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailQaSidebar ref="sideBar" :iterationDetails="emailIterationDetails" />
    </div>
    <modal name="submitResponseConfirmation" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          You won't be able to edit further and the response will be visible
          to learner once you mark resolved. Are you sure?
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('submitResponseConfirmation')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="updateResponseAndMarkResolved(),$modal.hide('submitResponseConfirmation')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import EmailQaSidebar from "@/components/EmailQaSidebar.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { VueEditor } from "vue2-editor";
import { Drop } from "vue-drag-drop";

export default {
  name: "QaEmail",
  metaInfo: {
    title: "QA email Details",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  components: {
    EmailQaSidebar,
    VueEditor,
    Drop
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      id: this.$route.params.id,
      emailIterationDetails: {},
      // toCharacters: "",
      // user: {},
      evaluationId: null,
      editWorldDetails: false,
      emailLists: [],
      activeResponseId: null,
      activeResponse: null,
      fullToolbar: [],
      filterEmailIndex: {
        0: { msg: "", arr: [], sub: "", iteration: "" },
        1: { msg: "", arr: [], sub: "", iteration: "" },
        2: { msg: "", arr: [], sub: "", iteration: "" },
        3: { msg: "", arr: [], sub: "", iteration: "" },
        4: { msg: "", arr: [], sub: "", iteration: "" }
      },
      selectedEntentValues: [],
      selectedIntentValues: [],
      iterationId: ""
    };
  },
  methods: {
    generateIterationResp() {
      api
        .genearteIterationResponse(this.iterationId)
        .then(() => {
          this.userEmailIterationDetails();
        })
        .catch(() => {});
    },
    genearteIterationResponse() {
      const fb = new FormData();
      fb.append("user_email_evaluation_id", this.evaluationId);
      this.selectedEntentValues.map(value => {
        fb.append(
          "asst_entity_value_list[]",
          value.entityId
        );
      });
      this.selectedIntentValues.map(value => {
        fb.append(
          "asst_intent_list[]",
          value.intentName
        );
      });
      api
        .userEmailIteration(fb, this.iterationId)
        .then(() => {
          this.generateIterationResp();
        })
        .catch(() => {});
    },
    handleEntentDrop(e) {
      if (e.type === "entent") {
        if (this.selectedEntentValues.length > 0) {
          let index = this.selectedEntentValues.findIndex(
            entent => entent.entityId == e.entityId
          );
          if (index == -1) this.selectedEntentValues.push(e);
        } else {
          this.selectedEntentValues.push(e);
        }
        this.genearteIterationResponse();
      }
    },
    removeEntentValues(index) {
      this.selectedEntentValues.splice(index, 1);
      this.genearteIterationResponse();
    },
    handleIntentDrop(e) {
      if (e.type === "intent") {
        if (this.selectedIntentValues.length > 0) {
          let index = this.selectedIntentValues.findIndex(
            intent => intent.intentId == e.intentId
          );
          if (index == -1) this.selectedIntentValues.push(e);
        } else {
          this.selectedIntentValues.push(e);
        }
        this.genearteIterationResponse();
      }
    },
    removeIntentValues(index) {
      this.selectedIntentValues.splice(index, 1);
      this.genearteIterationResponse();
    },
    setActiveResponse(item) {
      this.activeResponseId = item.id;
      this.activeResponse = item.attributes.response;
    },
    userEmailIterationDetails() {
      utilFunctionService.showLoader();
      api
        .getUserEmailIterationDetails(this.id)
        .then(res => {
          this.selectedEntentValues = [];
          this.selectedIntentValues = [];
          utilFunctionService.hideLoader();
          this.emailIterationDetails = {};
          utilFunctionService.hideLoader();
          this.emailIterationDetails = res.data.data.attributes;
          this.evaluationId = this.emailIterationDetails.evaluation_id;
          this.iterationId = res.data.data.id;
          this.$refs.sideBar.getQaFormulas(
            this.emailIterationDetails.lo_qa_condition_ids
          );
          this.getEmailLists();
          if (this.emailIterationDetails?.new_iteration_logic) {
          if (
            this.emailIterationDetails?.watson_enrichment_item.attributes.asst_entity_value_list.length > 0
          ) {
            this.emailIterationDetails.asst_entities.forEach(ent => {
              ent.attributes.asst_entity_values.forEach(entVal => {
                this.emailIterationDetails?.watson_enrichment_item.attributes.asst_entity_value_list.forEach(
                  val => {
                    if (val == entVal.id) {
                      this.selectedEntentValues.push({
                        ententName: ent.attributes.name,
                        entityId: entVal.id,
                        ententValueName: entVal.attributes.value,
                        type: 'intent'
                      });
                    }
                  })
              });
            });
          }
          if (
            this.emailIterationDetails?.watson_enrichment_item.attributes.asst_intent_list.length > 0
          ) {
            var filterIntent = [];
            this.emailIterationDetails?.watson_enrichment_item.attributes.asst_intent_list.forEach(val => {
              filterIntent.push(
                this.emailIterationDetails.asst_intents.filter(
                  ent => val == ent.attributes.name
                )[0]
              );
            });
            filterIntent.forEach(val => {
              this.selectedIntentValues.push({
                intentName: val.attributes.name,
                intentId: val.id,
                type: "intent"
              });
            });
           }
          } else {
          if (
            this.emailIterationDetails?.asst_entity_value_list.length > 0
          ) {
            this.emailIterationDetails.asst_entities.forEach(ent => {
              ent.attributes.asst_entity_values.forEach(entVal => {
                this.emailIterationDetails?.asst_entity_value_list.forEach(
                  val => {
                    if (val == entVal.id) {
                      this.selectedEntentValues.push({
                        ententName: ent.attributes.name,
                        entityId: entVal.id,
                        ententValueName: entVal.attributes.value,
                        type: 'intent'
                      });
                    }
                  })
              });
            });
          }
          if (
            this.emailIterationDetails?.asst_intent_list.length > 0
          ) {
            var filterIntents = [];
            this.emailIterationDetails?.asst_intent_list.forEach(val => {
              filterIntents.push(
                this.emailIterationDetails.asst_intents.filter(
                  ent => val == ent.id
                )[0]
              );
            });
            filterIntents.forEach(val => {
              this.selectedIntentValues.push({
                intentName: val.attributes.name,
                intentId: val.id,
                type: "intent"
              });
            });
          }
         }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    getEmailLists() {
      api.getEmailList(this.evaluationId).then(res => {
        this.emailLists = res.data.reverse();
        this.emailLists.forEach((z, i) => {
          let filterEmailList = [];
          z.attributes.responses.forEach(x => {
           z.attributes.responses = z.attributes.responses.reverse();
             let a = z.attributes.responses.filter(o => {
              if (
                x.attributes.character.attributes.character_id ==
                o.attributes.character.attributes.character_id
              ) {
                return o;
              }
            });
            if (a.length > 1) {
              let q = filterEmailList.find(
                x =>
                  a[0].attributes.character.attributes.character_id ==
                  x.attributes.character.attributes.character_id
              );
              if (q) {
                return;
              }
              let p = a.find(e => e.attributes.is_ooto_response == false);
              filterEmailList.push(p);
            } else {
              filterEmailList.push(a[0]);
            }
          });
          this.filterEmailIndex[i] = {
            arr: filterEmailList,
            sub: z.attributes.email_subject,
            msg: z.attributes.email,
            iteration: z.attributes.iteration
          };
        });
      });
    },
    saveResponse(id, res) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("user_email_iteration_response[response]", res);
      api
        .updateEmailIterationResponse(id, fb)
        .then(() => {
          utilFunctionService.hideLoader();
          this.userEmailIterationDetails();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    updateResponseAndMarkResolved() {
      utilFunctionService.showLoader();
      let qaUser = utilFunctionService.getLocalStorageService("user").users;
      const fb = new FormData();
      fb.append("user_email_iteration_response[response]", this.activeResponse);
      fb.append("user_email_iteration_response[qa_fixed]", true);
      fb.append("user_email_iteration_response[qa_user_id]", qaUser.id);
      api
        .updateEmailIterationResponse(this.activeResponseId, fb)
        .then(() => {
          this.activeResponseId = null;
          this.activeResponse = null;
          utilFunctionService.hideLoader();
          this.userEmailIterationDetails();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    }
  },
  created() {
    this.userEmailIterationDetails();
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.learn-object {
  margin: 35px -20px 0;
  padding: 30px 20px 40px;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06);
  background: white;
  @include media-breakpoint(md) {
    margin: 50px 0 0;
    padding: 40px 47px 60px;
  }
  .learn-object-caption {
    font-size: 12px;
    margin: 0 15px 0 0;
  }
}
#QaMessageContainer {
  margin-bottom: 5em;
}
.learn-object-tag {
  @include font-bold;
  font-size: 17px;
  line-height: 1.47;
  margin: 0 0 12px;
}
.details {
  // background-color: $white;
  padding: 25px 25px 25px;
  border-top: 1px dashed #ccc;
  // box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
  text-align: right;
}
</style>
