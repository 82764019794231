var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "csvPreview",
        height: "auto",
        width: 1000,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen, opened: _vm.open },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "modal-header modal-print-header" }, [
            _c("h1", [_vm._v("CSV Preview")]),
            _c("div", { staticClass: "modal-print-row" }),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide("csvPreview")
                  },
                },
              },
              [_c("em", { staticClass: "icon-close" })]
            ),
          ]),
          _c("div", { attrs: { id: "example-table" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }