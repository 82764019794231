var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.llmType === "llm_chat"
        ? _c("llmChat", {
            attrs: { llmChatData: _vm.llmData, llmType: _vm.llmType },
            on: { archiveLO: _vm.archiveLO },
          })
        : _vm._e(),
      _vm.llmType === "llm_dialogic"
        ? _c("llmDialogic", {
            attrs: { llmDialogicData: _vm.llmData, llmType: _vm.llmType },
            on: { archiveLO: _vm.archiveLO },
          })
        : _vm._e(),
      _vm.llmType === "llm_email"
        ? _c("llmEmail", {
            attrs: { llmEmailData: _vm.llmData, llmType: _vm.llmType },
            on: { archiveLO: _vm.archiveLO },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }