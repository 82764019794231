<template>
  <div id="ContentVideos">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Videos</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="name"
                class="form-control"
                aria-describedby="searchDoc"
                placeholder="Search"
                v-model="searchVideo"
                v-on:input="searchVideos"
              />
            </div>
          </form>
          <button
            @click="$modal.show('AddVideo')"
            class="btn header-btn primary"
          >Add Video</button>
        </div>
        <p v-if="totalVideos > 1 && !searchVideo">You have {{totalVideos}} Videos in the system.</p>
        <p
          v-else-if="totalVideos <= 1 && !searchVideo"
        >You have {{totalVideos}} Video in the system.</p>
        <p
          v-else-if="totalVideos > 1 && searchVideo"
        >You have {{totalVideos}} Videos in the search result.</p>
        <p v-else>You have {{totalVideos}} Video in the search result.</p>
      </header>
      <div class="data-content" v-if="videoLists.length">
        <!-- <v-infinite-scroll @bottom="nextPage" class="data-content-scroll"> -->
        <div>
          <div class="data-image-row" v-if="videoLists.length > 0">
            <!-- data image column repeat start-->

            <div class="data-image-col col-4 d-flex" v-for="video in videoLists" :key="video.id">
              <div class="data-image-flex">
                <a @click="$modal.show('wistiaVideo',{'wistiaId':video.attributes.wistia_code})">
                  <img v-if="video.attributes.thumbnail_url" :src='video.attributes.thumbnail_url'
                    alt="" onload="this.parentNode.style.opacity=1;"
                  />
                  <img v-else-if="video.attributes.wistia_thumbnail_url" :src='video.attributes.wistia_thumbnail_url'
                    alt="" onload="this.parentNode.style.opacity=1;"
                  />
                  <a href="#" class="video-play">
                    <em class="icon-carrot-right"></em>
                  </a>
                </a>
                <div class="data-image-content">
                  <h4 class="text-truncate">{{video.attributes.title}}</h4>
                  <div class="my-3">
                    <copy-to-clipboard  :text="getshortCode(video)" @copy="copiedMessage">
                      <a href="javascript:void(0);" class="copy-icon">Get shortcode</a>
                    </copy-to-clipboard>
                  </div>

                  <h5>
                    <span v-for="tag in video.attributes.tag_list" :key="tag">{{tag}}</span>
                    <a
                      href
                      class="data-link dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >{{video.attributes.tag_list.length > 0?'Tag' : 'Add Tag'}}</a>
                    <notesModal
                      v-bind:doc="{id : video.id , type : 'global_video' , notes :video.attributes.tag_list, page :page}"
                      :method="getVideo"
                    />
                  </h5>
                  <p>{{video.attributes.created_on}}</p>
                  <p v-if="video.attributes.transcript">Transcript: Yes</p>
                  <p v-else>Transcript: No</p>                 
                  <a
                    href="javascript:void(0);"
                    @click="editRecord(video.id)"
                    class="data-image-edit"
                  >
                    <em class="icon-edit"></em>
                  </a>
                  <a
                    href="javascript:void(0);"
                    @click="deleteRecord(video.id)"
                    class="data-image-delete"
                  >
                    <em class="icon-delete"></em>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </v-infinite-scroll> -->
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPagesPagination.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPagesPagination.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getVideo(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPagesPagination.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="videoLists.length === 0 && !searchVideo && display">
        <h3>There’s nothing here!</h3>
        <p>You have no Videos in the system yet.</p>
        <div>
          <a @click="show('AddVideo')" class="btn header-btn primary">Add a new video</a>
        </div>
      </div>
      <div class="data-no-result" v-if="videoLists.length === 0 && searchVideo">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <AddVideo />
    <EditVideo />
    <WistiaComponent />
    <modal name="deleteModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">Are you sure you want to delete this video?</div>
        <div class="modal-footer justify-content-center">
          <button type="button" @click="hide('deleteModal')" class="btn medium default">Cancel</button>
          <button type="button" class="btn medium primary" @click="deleteVideo(deleteId)">ok</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script src="fast.wistia.com/assets/external/E-v1.js" async></script>
<script>
import { commonConfig } from "@/utils/commonConfig";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import AddVideo from "../components/modal/AddVideo.vue";
import EditVideo from "../components/modal/EditVideo.vue";
import WistiaComponent from "../components/modal/WistiaComponent.vue";
import notesModal from "../components/modal/Note.vue";
import CopyToClipboard from "vue-copy-to-clipboard";
export default {
  name: "ContentVideos",
  components: {
    AddVideo,
    notesModal,
    WistiaComponent,
    EditVideo,
    CopyToClipboard
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      Video: [],
      roles: [],
      totalPagesPagination:[],
      page: 1,
      videoLists: [],
      totalVideos: "",
      totalPages: [],
      searchVideo: "",
      sortOrder: "desc",
      sortColumn: "id",
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      preIframUrl : " https://fast.wistia.com/embed/medias/",
      postIframUrl : "/swatch",
      organizationcharacters: "",
      display: false,
      selectedFile: "",
      deleteId: null,
      editId: null
    };
  },
  methods: {
    copiedMessage() {
      utilFunctionService.showSuccess(commonConfig.appMessages.shortcodeCopied);
    },
    getshortCode(video) {
      let url = video.attributes.wistia_code;
      let shortCode = `[videoPopup wistia_code="${url}"/]`;
      return shortCode;
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    show(modal) {
      this.$modal.show(modal);
    },
    searchVideos() {
      api
        .getAllVideo(
          commonConfig.setting.searchPage,
          this.searchVideo,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.videoLists = res.data.data;
          this.totalVideos = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    getVideo(page) {
      utilFunctionService.showLoader();
      this.page = page;
      api
        .getAllVideo(page, this.searchVideo, this.sortColumn, this.sortOrder)
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          if (page === 1) this.videoLists = res.data.data;
          else this.videoLists = res.data.data;
          this.totalVideos = res.data.filtered_count;
          this.totalPages = res.data.total_pages;
          this.totalPagesPagination = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPagesPagination.push(ind);
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    deleteVideo(id) {
      utilFunctionService.showLoader();
      api
        .deleteGlobalVideo(id)
        .then(() => {
          utilFunctionService.hideLoader();
          this.hide("deleteModal");
          utilFunctionService.showSuccess(commonConfig.appMessages.deleteVideo);
          this.getVideo(commonConfig.setting.searchPage);
        })
        .catch(() => {
          this.hide("deleteModal");
          utilFunctionService.showErr(commonConfig.appMessages.errDeleteVideo);
          utilFunctionService.hideLoader();
          this.getVideo(this.page);
        });
    },
    nextPage() {
      ++this.page;
      if (this.page <= this.totalPages) {
        this.getVideo(this.page);
      } else {
        return;
      }
    },
    prevPage() {
      this.page = this.page - 1;
      this.getVideo(this.page);
    },
    deleteRecord(id) {
      this.deleteId = id;
      this.show("deleteModal");
    },
    editRecord(id) {
      this.editId = id;
      console.log(this.editId);
      this.$modal.show("EditVideo", {global_video_id: this.editId});
    }
  },
  created() {
    this.getVideo(this.page);
  }
};
</script>
<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.data-video {
  width: 100%;
  height: auto;
  background: #eaf5f2 url('../assets/images/video-icon.png') no-repeat;
  position: relative;
  background-position: center;
  background-size: 100px;
  a {
    @include position(absolute, 0, 0, 0, 0);
    margin: auto;
  }
  img {
    @include imageSize;
  }
}
</style>
