<template>
  <modal name="pdfModal" height="auto" :width="1000" :scrollable="true"  @before-open="beforeOpen">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title" id="exampleModalLabel">
          <h5></h5>
        </div>
        <button type="button" class="close" @click="hide('pdfModal')">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <PDFViewer
          :source="src"
          style="display: inline-block; width: 100%"
        />
       </div>
    </div>
  </modal>
</template>

<script>
import PDFViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { utilFunctionService } from "@/utils/utils.service";
export default {
    name :'pdfModal',
    components: {
        PDFViewer
    },
    data() {
        return {
            src: '',
            numPages: undefined,
        }
    },
    mounted() {
    },
    methods : {
      beforeOpen (event) {
        utilFunctionService.showLoader();
        this.src = event.params.pdfUrl;
        utilFunctionService.hideLoader();
      },
      hide (modal) {
        this.$parent.hide(modal)
      }
    }
}
</script>