<template>
  <modal
    name="csvPreview"
    height="auto"
    :width="1000"
    :scrollable="true"
    @before-open="beforeOpen"
    @opened="open"
  >
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-header modal-print-header">
          <h1>CSV Preview</h1>
          <div class="modal-print-row"></div>
          <button type="button" class="close" @click="hide('csvPreview')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div id="example-table"></div>
      </div>
    </div>
  </modal>
</template>
<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { utilFunctionService } from "@/utils/utils.service";
export default {
  name: "csvPreview",
  data() {
    return {
      src: "",
      tabulator: null,
      tableData: [],
    };
  },
  methods: {
    error: function (err) {
      console.log(err);
    },
    beforeOpen(event) {
      utilFunctionService.showLoader();
      console.log(event.params);
      this.src = event.params.pdfUrl;
      utilFunctionService.hideLoader();
    },
    open() {
      let self = this;
      let csvData = new Promise((resolve) => {
        resolve(this.convertCSVToJson(this.src));
      });
      csvData.then((data) => {
        self.tabulator = new Tabulator("#example-table", {
          height: 205, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
          data: data,
          layout: "fitDataFill", //fit columns to width of table (optional)
          autoColumns: true,
        });
      });
    },
    hide() {
      this.$modal.hide("csvPreview");
    },
    async convertCSVToJson(url) {
      const res = await fetch(url);
      if (res.ok) {
        const data = await res.text();
        var lines = data.split("\n");
        var result = [];
        var headers = lines[0].split(",");
        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(",");
          for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
          }
          result.push(obj);
        }
        return result;
      }
    },
  },
};
</script>


