<template>
  <div id="Organisation">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>Customers</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchCust"
                placeholder="Search"
                v-model="searchCust"
                v-debounce:400ms="searchCustomer"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <a
            @click="$modal.show('createCustomer',{customer_id : '' , tab: 'address'})"
            class="btn header-btn primary"
          >Add New Customer</a>
        </div>
        <p v-if="totalCustomers > 1 && !searchCust">You have {{totalCustomers}} Customers in the system.</p>
        <p v-else-if="totalCustomers <= 1 && !searchCust">You have {{totalCustomers}} Customer in the system.</p>
        <p v-else-if="totalCustomers > 1 && searchCust">You have {{totalCustomers}} Customers in the search result.</p>
        <p v-else>You have {{totalCustomers}} Customer in the search result.</p>
      </header>
      <div class="data-content" v-if="customersLists.length !== 0">
        <div class="data-table data-column-5">
          <div class="data-head customer-data-head">
            <div class="data-col">
              Customer
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Active Courses
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'active_courses' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('active_courses','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'active_courses' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('active_courses','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Active Learners
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'active_learners_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('active_learners_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'active_learners_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('active_learners_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col text-center">
              Instructors
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'customer_instructors_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('customer_instructors_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'customer_instructors_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('customer_instructors_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              CONTACTS
            </div>
            <div class="data-col">ACTIONS</div>
          </div>
          <div class="data-items">
            <div class="data-row customer-data-row" v-for="org in customersLists" :key="org.id">
              <div class="data-col">
                <div class="data-image">
                  <div class="data-pic organise-pic cursor">
                    <img
                      :src="org.attributes.photo_url"
                      v-if="org.attributes.photo_url"
                      alt="customer image"
                    />
                  </div>
                  <div
                    class="data-text cursor"
                  ><a @click="$modal.show('createCustomer',{customer_id : org.id, tab: 'address' })">{{org.attributes.name}}</a></div>
                </div>
              </div>
              <div class="data-col text-center">{{org.attributes.active_courses}}</div>
              <div class="data-col text-center">{{org.attributes.active_learners_count}}</div>
              <div class="data-col text-center">{{org.attributes.customer_instructors_count}}</div>
              <div class="data-col">
                <div class="customer-dropdown customer-organise-dropdown">
                  <span v-if="org.attributes.contacts.length > 0">
                    {{org.attributes.contacts[0].attributes.first_name}}
                    <span
                      v-if="org.attributes.contacts.length > 1"
                    >, + {{org.attributes.contacts.length -1}}</span>
                  </span>
                  <span v-if="org.attributes.contacts.length === 0">-</span>
                  <div class="dropdown-menu dropdown-menu-right" v-if="org.attributes.contacts.length > 0">
                    <h3>Contact({{org.attributes.contacts.length}})</h3>
                    <VuePerfectScrollbar class="scroll-area" :settings="settings">
                      <ul class="dropdown-list">
                        <li
                          class="dropdown-items"
                          v-for="(customer) of org.attributes.contacts"
                          :key="customer.id"
                        >
                          <div class="dropdown-content">
                            <div class="user-name">{{customer.attributes.first_name}} {{customer.attributes.last_name}}</div>
                            <div
                              class="user-role"
                            >{{customer.attributes.contact_role_name}}</div>
                          </div>
                        </li>
                      </ul>
                    </VuePerfectScrollbar>
                  </div>
                </div>
              </div>
              <div class="data-col data-action">
                <div class="data-dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn dropdown-toggle">
                <em class="icon-menu"></em></button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul>
                      <li><a @click="$modal.show('createCustomer',{customer_id : org.id, tab: 'address' })">Edit</a></li>
                      <li><a @click="$modal.show('createCustomer',{customer_id : org.id, tab: 'contact' })">Manage Contacts</a></li>
                      <li><a @click="$modal.show('createCustomer',{customer_id : org.id, tab: 'instructor' })">Manage Instructors</a></li>
                      <li><a>Manage Courses</a></li>
                      <li><a class="dropdown-error" @click="deleteCustomer(org.id)">Delete</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getCustomers(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="customersLists.length === 0 && !searchCust && display">
        <h3>There’s nothing here!</h3>
        <p>You have no customer in the system yet.</p>
        <div>
          <a
            @click="$modal.show('createCustomer',{customer_id : '' })"
            class="btn header-btn primary"
          >Add a new customer</a>
        </div>
      </div>
      <div class="data-no-result" v-if="customersLists.length === 0 && searchCust">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <createCustomer />
  </div>
</template>

<script>
import { commonConfig } from "@/utils/commonConfig";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import createCustomer from "../components/modal/customer.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "Customers-component",
  components: {
    createCustomer,
    VuePerfectScrollbar
  },
  metaInfo: {
    title: "All Customers",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  filters: {
    upperCaseChar : function(value, onlyCharacterFirst) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyCharacterFirst) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      searchRole: "",
      roles: [],
      page: 1,
      customersLists: [],
      totalCustomers: "",
      totalPages: [],
      searchCust: "",
      sortOrder: "asc",
      sortColumn: "name",
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      display : false
    };
  },
  methods: {
    show() {
      this.$modal.show();
    },
    hide(modal) {
      this.$modal.hide(modal);
      this.getCustomers(this.page);
    },
    searchCustomer(value) {
      this.display = false;
      api
        .getCustomersList(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.customersLists = res.data.data;
          this.totalCustomers = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    getCustomers(page) {
      utilFunctionService.showLoader();
      this.page = page;
      api
        .getCustomersList(
          page,
          this.searchCust,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.customersLists = res.data.data;
          this.totalCustomers = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    toggleSort(colName, sortType) {
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getCustomers(this.page);
    },
    deleteCustomer(id) {
      utilFunctionService.showLoader();
      api.deleteCustomer(id).then(() => {
        utilFunctionService.hideLoader();
        let page;
          if (this.customersLists.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getCustomers(page);
            } else {
              page = this.page - 1;
              this.getCustomers(page);
            }
          } else {
            const page = this.page;
            this.getCustomers(page);
          }
        utilFunctionService.showSuccess(commonConfig.appMessages.deleteCustomer);
      })
      .catch(error => {
          utilFunctionService.showErr(
            error.response.data.errors
          );
          utilFunctionService.hideLoader();
      });
    },
    nextPage() {
      this.page = this.page + 1;
      this.getCustomers(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getCustomers(this.page);
    }
  },
  created() {
    this.getCustomers(this.page);
  }
};
</script>
