<template>
  <div>
    <div class="learning-form-header flex-wrap">
      <h4>Interaction(Email)</h4>
      <div class="learning-quiz-btn">
          <a
            href="javascript:void(0);"
            @click="restore('published')"
            class="btn header-btn secondary"
            v-if="status == 'published'"
          >Save</a>
          <a
            v-if="status == 'drafted'"
            href="javascript:void(0);"
            @click="restore('published')"
            class="btn header-btn primary"
          >Publish LO</a>
          <a
            href="javascript:void(0);"
            @click="$modal.show('cloneConfirmationModal')"
            class="btn header-btn ml-2 secondary"
          >Clone LO</a>
      </div>
    </div>
    <!-- Navigation tabs start here -->
    <div class="learning-lo-tabs">
      <div class="setup-container">
        <ul>
          <li @click="currentStep('basic-setup', 0)" :class="{ active: isActiveTab[0] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Basic Information</span>
            </a>
          </li>
         
          <li @click="currentStep('intent-setup', 1)" :class="{ active: isActiveTab[1] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Intents Setup</span>
            </a>
          </li>
          <li @click="currentStep('entity-setup', 2)" :class="{ active: isActiveTab[2] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Entities Setup</span>
            </a>
          </li>
          <li @click="currentStep('response-formulas', 3)" :class="{ active: isActiveTab[3] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Response & Formulas</span>
            </a>
          </li>
          <li @click="currentStep('skill-setup', 4)" :class="{ active: isActiveTab[4] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Assessment(Skill Setup)</span>
            </a>
          </li>
          <li @click="currentStep('email-testing', 5)" :class="{ active: isActiveTab[5] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Testing</span>
            </a>
          </li>
          <li @click="currentStep('qa-and-interstitial',6)" :class="{ active: isActiveTab[6] }">
            <a>
              <span class="check-box"></span>
              <span class="check-caption">QA & Interstitial</span>
            </a>
          </li>
          <li
            v-if="overallInteraction"
            :class="{ active: isActiveTab[7] }"
            @click="currentStep('overall-assessment', 7)"
          >
            <a>
              <span class="check-box"></span>
              <span class="check-caption">Overall Assessment</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="learning-quiz-content pt-4">
      <div class="setup-content" v-if="step == 'basic-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Basic Information</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Interaction title</label>
                  <ValidationProvider
                    name="Interaction Title"
                    rules="required|max:100"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="name"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Administrative notes</label>
                  <ValidationProvider
                    name="Administrative Notes"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <textarea
                      type="Description"
                      class="form-control"
                      id="notes"
                      rows="4"
                      v-model="adminNotes"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                 <div class="form-group">
                  <label>Learner iteration explanation</label>
                  <textarea
                    type="Description"
                    class="form-control"
                    id="explanation"
                    rows="4"
                    v-model="learnerExplanation"
                  ></textarea>
                </div>
                <div class="form-group mb-2">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="userOverall">
                      <input
                        type="checkbox"
                        name="userOverall"
                        v-model="overallInteraction"
                        v-bind:value="'userOverall'"
                      />
                      <span for="userOverall" class="checkmark"></span>
                      Use overall interaction assessment
                    </label>
                  </div>
                </div>
                 <div class="form-group" v-if="overallInteraction">
                   <div class="d-flex align-items-center">
                      <label>Competency code</label>
                      <input
                          type="text"
                          class="form-control form-control-md ml-3"
                          v-model="competencyCode"
                      />
                   </div>
                </div>
                <div class="form-group pt-1 mb-2" v-if="overallInteraction">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="quizAssessment">
                      <input
                        type="checkbox"
                        name="quizAssessment"
                        v-model="enableLearnerIteration"
                        v-bind:value="'userOverall'"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Enable learner iteration?
                    </label>
                  </div>
                </div>
                <div class="form-group mb-2" v-if="enableLearnerIteration && overallInteraction">
                  <div class="d-flex align-items-center">
                    <label>Iteration levels?</label>
                    <div class="w-80 ml-3">
                      <input
                        type="number"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                        min="1"
                        max="5"
                        class="form-control"
                        v-model="iterationLevel"
                        @blur="displayReduceIterationModal()"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group pt-1 mb-3" v-if="enableLearnerIteration">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="newItrLogic">
                      <input
                        type="checkbox"
                        name="newItrLogic"
                        v-model="newIterationLogic"
                        v-bind:value="'userOverall'"
                      />
                      <span for="newItrLogic" class="checkmark"></span>
                      Use new iteration logic?
                    </label>
                  </div>
                </div>
                <div class="form-group pt-1 mb-3" v-if="overallInteraction">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="displayOverallAssessment">
                      <input
                        type="checkbox"
                        name="displayOverallAssessment"
                        v-model="displayOverallAssessment"
                        v-bind:value="'userOverall'"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Display overall assessment in learner feedback?
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label>Learner Description</label>
                  <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                   <vue-editor
                        :class="[(isActive && errors[0]) ? 'form-control invalid' : 'form-control']"
                        id="Description"
                        :editorToolbar="fullTool"
                          placeholder="Add a Description"
                        v-model="description"
                    ></vue-editor>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-7">
                 <!-- <CharacterEmail
                   :selectedMenuItem="selectedMenuItem"
                   :characters="characters"
                   :updatedData="updatedData"
                   ref="emailCharacterForm"
                   v-on:selectedCharacters="selectedCharacters"
                  /> -->
                  <span>
                    <ValidationObserver ref="subform">
                      <form class="w-100">
                        <div class="row custom-row mt-0">
                          <div class="col-sm-12">
                            <div class="form-group customer-dropdown">
                              <label
                                class="form-label"
                                for="exampleInputEmail1"
                              >Select email to character</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="searchCharacter"
                                v-on:input="filterCharacter"
                                placeholder="Search by name, role & organization"
                              />
                              <span
                                :class="[isActive ? 'invalid' : '']"
                                :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                v-if="error"
                              >Select email to character is required</span>
                              <div class="chip-container pt-2 pb-0 entity-chip">
                                <div class="chip" v-for="(char, i) of selectedChar" :key="char.id">
                                  <span>{{char.char_full_name}}</span>
                                  <em class="icon-close" @click="deleteSeletedChar(i)"></em>
                                </div>
                              </div>
                              <div
                                class="user-row-scroll custom-user-dropdown"
                                v-if="searchCharacter.length > 0  && charStatus"
                              >
                                <div
                                  class="user-row"
                                  v-for="(character) in searchCharacters"
                                  :key="character.id"
                                >
                                  <div class="img-container">
                                    <div class="img-box">
                                      <img
                                        :src="character.char_photo_url"
                                        v-if="character.char_photo_url"
                                        alt="image"
                                      />
                                    </div>
                                    <div class="user-info">
                                      <div class="head">{{character.char_full_name}}</div>
                                      <div class="text">{{character.world_role}} at {{character.org_name}}</div>
                                      <p>{{character.char_age}} , {{character.char_gender}}</p>
                                    </div>
                                  </div>
                                  <div class="dropdown-action" v-if="character.status">
                                    <em class="icon-check"></em>
                                    <span>Added</span>
                                  </div>
                                  <div class="dropdown-action" v-if="!character.status">
                                    <a class="add-action" @click="addEmailChar(character)">+</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group customer-dropdown">
                              <label
                                class="form-label"
                                for="exampleInputEmail1"
                              >Select email cc character(s) (optional)</label>
                              <input
                                type="text"
                                v-model="searchCcCharacter"
                                v-on:input="filterCcCharacter"
                                class="form-control"
                                placeholder="Search by name, role & organization"
                              />
                              <div class="chip-container pt-2 pb-0 entity-chip">
                                <div class="chip" v-for="(char, i) of selectedCcChar" :key="char.id">
                                  <span>{{char.char_full_name}}</span>
                                  <em class="icon-close" @click="deleteSeletedCcChar(i)"></em>
                                </div>
                              </div>
                              <div
                                class="user-row-scroll custom-user-dropdown"
                                v-if="searchCcCharacter.length > 0  && optionalCharStatus"
                              >
                                <div
                                  class="user-row"
                                  v-for="(character) in searchOptionalCharacters"
                                  :key="character.id"
                                >
                                  <div class="img-container">
                                    <div class="img-box">
                                      <img
                                        :src="character.char_photo_url"
                                        v-if="character.char_photo_url"
                                        alt="image"
                                      />
                                    </div>
                                    <div class="user-info">
                                      <div class="head">{{character.char_full_name}}</div>
                                      <div class="text">{{character.world_role}} at {{character.org_name}}</div>
                                      <p>{{character.char_age}} , {{character.char_gender}}</p>
                                    </div>
                                  </div>
                                  <div class="dropdown-action" v-if="character.status">
                                    <em class="icon-check"></em>
                                    <span>Added</span>
                                  </div>
                                  <div class="dropdown-action" v-if="!character.status">
                                    <a class="add-action" @click="addEmailCcChar(character)">+</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </span>
                <div class="form-group">
                  <label>Select assessment scheme for the skills</label>
                  <ValidationProvider name="Assessment Scheme">
                    <div class="quiz-dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="entity"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :class="[(isActive && !assessmentListKey.id) ? 'invalid' : '']"
                      >
                        {{assessmentListKey.name}}
                        <em class="icon-carrot-down"></em>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="entity">
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          value="0"
                        >None</option>
                        <option
                          class="dropdown-item"
                          @click="selectedAssessment($event)"
                          :value="`${assessment.id}*${assessment.attributes.name}`"
                          v-for="assessment of assessmentLists"
                          :key="assessment.id"
                        >{{assessment.attributes.name}}</option>
                      </div>
                    </div>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="!assessmentListKey.id"
                    >Assessment scheme field is required</span>
                  </ValidationProvider>
                </div>
                <div class="form-group pt-1 mb-3">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="hideAssessmentLabel">
                      <input
                        type="checkbox"
                        name="hideAssessmentLabel"
                        v-model="hideAssessmentLabel"
                      />
                      <span for="hideAssessmentLabel" class="checkmark"></span>
                      Hide assessment labels in learner application?
                    </label>
                  </div>
                </div>
                <div class="form-group pt-1 mb-3">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="enableLearnerRetries">
                      <input
                        type="checkbox"
                        name="enableLearnerRetries"
                        v-model="enableLearnerRetries"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Enable learner retries
                    </label>
                  </div>
                </div>
                <div class="form-group pt-1 mb-3" v-if="enableLearnerRetries">
                  <label name="maxNumberOfRetries">
                    Max number of retries
                  </label>
                  <input
                      type="number"
                      class="form-control"
                      name="maxNumberOfRetries"
                      v-model="maxNumberOfRetries"
                    />
                </div>
                <div class="form-group pt-1 mb-3">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="displayOverallAssessment">
                      <input
                        type="checkbox"
                        name="addIntroEmail"
                        v-model="introEmailStatus"
                        v-bind:value="'userOverall'"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Add Intro Email
                    </label>
                  </div>
                </div>
                
                <!--<div class="form-group pt-1 mb-3" v-if="enableLearnerRetries">
                  <div class="form-checkboxes d-inline-flex">
                    <label class="custom-checkbox right" name="enableInstructorOverride">
                      <input
                        type="checkbox"
                        name="enableInstructorOverride"
                        v-model="enableInstructorOverride"
                        v-bind:value="'enableInstructorOverride'"
                      />
                      <span for="quizAssessment" class="checkmark"></span>
                      Enable instructor overrides on retries
                    </label>
                  </div>
                </div>-->
                <div class="form-group" v-if="introEmailStatus">
                  <label>Subject</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Add Subject"
                      v-model="introEmailSubject"
                    />
                </div>
                <div class="form-group" v-if="introEmailStatus">
                  <label>Add Email Body</label>
                   <vue-editor
                        class="form-control"
                        id="Email Body"
                        :editorToolbar="fullTool"
                        placeholder="Add Email Body"
                        v-model="introEmailBody"
                    ></vue-editor>
                </div>
              <div class="btn-container" v-if="introEmailStatus">
                <input
                  style="display: none;"
                  ref="fileInput"
                  type="file"
                  @change="onDocSelect"
                  accept=".pdf"
                />
                <a
                  class="btn ml-2 mb-2 secondary medium pdf-button"
                  @click="$refs.fileInput.click()"
                >
                <img src="../../assets/images/pin-icon.png" alt="pin" class="pin-image" />
                Attach a File</a>
              </div>
              <div class="col-sm-7" v-if="introEmailStatus">
                <div class="form-pdf-group" v-if="docs.length > 0">
                  <div class="form-pdf-item">
                    <a @click="$modal.show('pdfModal',{pdfUrl : docs[0].attributes.resource_url })">
                      <img src="../../assets/images/pdf-big-icon.png" alt="pdf" />
                    </a>
                    <a href="javascript:void(0);" @click="removeDoc()" class="close-pdf">
                      <em class="icon-close"></em>
                    </a>
                  </div>
                </div>
              </div>
            </div>
              <div class="col-12 pt-5">
                <div class="d-flex justify-content-end mb-4">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="saveEmaillo('drafted', 1)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Basic Information end here -->
      
      <!-- Intents Setup start -->
      <div class="setup-content" v-if="step === 'intent-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Intents Setup</h4>
          <div class="learning-quiz-btn">
            <a
              href="javascript:void(0);"
              class="btn btn-auto header-btn secondary"
              @click="linkIntent($event,'dialog_skill')"
            >Link to Assistant Dialog Skill</a>
            <a
              href="javascript:void(0);"
              class="btn btn-auto header-btn secondary"
              @click="linkIntent($event,'sync_assistant')"
            >Sync with Assistant</a>
            <div class="btn btn-auto header-btn secondary btn-type">
              <input type="file" @change="linkIntent" />
              Import CSV
            </div>
          </div>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label class="big-label">Intents in this interaction</label>
                  <ul class="form-entity-tabs" v-if="intentLists">
                    <li
                      v-for="item in intentLists"
                      :key="item.id"
                      :class="item.id === selectedAsstIntent.id ? 'active' :''"
                      @click="selectedIntent(item)"
                    >
                      <a href="javascript:void(0);">
                        {{item.attributes && item.attributes.name}}
                        <span>
                          <em @click="deleteIntent(item.id)" class="icon-close"></em>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="form-group pt-4">
                  <label>Add Intent</label>
                  <input type="text" class="form-control" id="name" v-model="emailIntentName" />
                  <div class="form-btn-group">
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="addEmailIntent()"
                    >Add Intent</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="entity-tabs-content mt-0">
                  <div class="form-group">
                    <label>Description</label>
                    <ValidationProvider
                      name="Description"
                      rules="required|max:100"
                      v-slot="{ errors }"
                    >
                      <textarea
                        type="text"
                        id="Description"
                        class="form-control"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        v-model="intentDesc"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Example user input</label>
                    <ValidationProvider
                      name="User input"
                      rules="required|max:300"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        id="Value"
                        class="form-control"
                        :class="[(isActive && errors[0]) ? 'invalid' : '']"
                        v-model="intentInput"
                      />
                      <span
                        :class="[isActive ? 'invalid' : '']"
                        :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                        v-if="errors[0]"
                      >{{ errors[0].replace("The ", "" )}}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <a
                      href="javascript:void(0);"
                      @click="addIntentValues"
                      class="add-more-btn w-90"
                    >+ Add</a>
                  </div>
                  <div
                    class="quiz-table-data"
                    v-if="selectedAsstIntent && selectedAsstIntent.attributes"
                  >
                    <div class="quiz-table-head row">
                      <div class="quiz-tab-col col-10">
                        <span>{{selectedAsstIntent.attributes.description}}</span>
                      </div>
                      <div class="quiz-tab-col col-2"></div>
                    </div>
                    <div class="quiz-table-content">
                      <div
                        class="quiz-table-list"
                        v-for="(values) in selectedAsstIntent.attributes.asst_intent_examples"
                        :key="values.id"
                      >
                        <div class="quiz-tabs-row row">
                          <div class="quiz-tab-col col-10">
                            <div class="d-block">{{values.attributes.example}}</div>
                          </div>
                          <div class="quiz-tab-col col-2 text-right">
                            <a href="javascript:void(0);">
                              <em class="icon-close" @click="deleteIntentValue(values)"></em>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-5">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="next('entity-setup', 4)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Intents Setup end -->
      <!-- Entities setup start -->
      <div class="setup-content" v-if="step === 'entity-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Entities Setup</h4>
          <div class="learning-quiz-btn">
            <a
              href="javascript:void(0);"
              class="btn btn-auto header-btn secondary"
              @click="linkEntitiesData($event,'dialog_skill')"
            >Link to Assistant Dialog Skill</a>
            <a
              href="javascript:void(0);"
              class="btn btn-auto header-btn secondary"
              @click="linkEntitiesData($event,'sync_assistant')"
            >Sync with Assistant</a>
            <div class="btn btn-auto header-btn secondary btn-type">
              <input type="file" class @change="linkEntitiesData" />
              Import CSV
            </div>
            <a
              href="javascript:void(0);"
              class="btn btn-auto header-btn secondary"
              @click="linkEntitiesData($event,'exportCsv')"
            >Export CSV</a>
          </div>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label class="big-label">Assistant entities in this interaction</label>
                  <ul class="form-entity-tabs" v-if="asstEntitiesList">
                    <li
                      v-for="item in asstEntitiesList"
                      :key="item.id"
                      :class="item.id === selectedAsstEntities.id ? 'active' :''"
                      @click="selectedEntent(item)"
                    >
                      <a href="javascript:void(0);">
                        {{item.attributes && item.attributes.name}}
                        <span>
                          <em @click="deleteEntent(item.id)" class="icon-close"></em>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="form-group pt-4">
                  <label>Add Assistant Entity</label>
                  <input type="text" class="form-control" id="name" v-model="emailEntityName" />
                  <div class="form-btn-group">
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      @click="addEmailEntity($event)"
                    >Create Assistant Entity</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="entity-tabs-content mt-0">
                  <div class="row">
                    <div class="col-6 form-group">
                      <label>Value</label>
                      <ValidationProvider name="Value" rules="required|max:100" v-slot="{ errors }">
                        <input
                          type="text"
                          class="form-control"
                          :class="[(isActive && errors[0]) ? 'invalid' : '']"
                          v-model="asstName"
                        />
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-6 form-group">
                      <label>Value Type</label>
                      <div>
                        <select v-model="asstType" class="form-control">
                          <option>Synonyms</option>
                          <option>Patterns</option>
                        </select>
                      </div>
                    </div>
                    <div v-if="asstType === 'Synonyms'" class="col-12 form-group">
                        <ValidationProvider
                          name="Synonyms"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="enter synonyms here"
                            :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            v-model="asstSynonyms"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="errors[0]"
                          >{{ errors[0].replace("The ", "" )}}</span>
                        </ValidationProvider>
                        <br>
                        <div class="col-12 form-group">
                          <a
                            href="javascript:void(0);"
                            class="add-more-btn"
                            @click="addAssessmentValues"
                          >Save Value</a>
                        </div>
                    </div>
                    <div v-else-if="asstType === 'Patterns'" class="col-12 form-group">
                        <ValidationProvider
                          name="Synonyms"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="enter pattern here"
                            :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            v-model="asstSynonyms"
                          />
                          <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="errors[0]"
                          >{{ errors[0].replace("The ", "" )}}</span>
                        </ValidationProvider>
                          <br>
                          <div class="col-12 form-group">
                            <a
                              href="javascript:void(0);"
                              class="add-more-btn"
                              @click="addAssessmentValuesPatterns"
                            >Save Value</a>
                        </div>
                      </div>
                  </div>
                  <div class="quiz-table-data">
                      <div class="quiz-table-head row">
                        <div class="quiz-tab-col col-4">
                          <span>Values</span>
                        </div>
                        <div class="quiz-tab-col col-7">
                          <span>Synonyms/Patterns</span>
                        </div>
                      </div>
                      <!-- quiz table content start -->
                      <div class="quiz-table-content" v-if="selectedAsstEntities">
                        <!-- quiz table list repeat -->
                        <div
                          class="quiz-table-list"
                          v-for="asstValue of selectedAsstEntities.attributes.asst_entity_values"
                          :key="asstValue.id"
                        >
                          <div class="quiz-tabs-row row" v-if="asstValue.attributes.asst_entity_val_synonyms && asstValue.attributes.asst_entity_val_synonyms.length">
                            <div class="quiz-tab-col col-4">{{asstValue.attributes.value}}</div>
                            <div class="quiz-tab-col col-7" @click="handleSynonymModal(asstValue)">
                              <a
                                class="d-block"
                              >{{$parent.getAssessmentValue(asstValue.attributes.asst_entity_val_synonyms)}}</a>
                            </div>

                            <div class="quiz-tab-col col-1 text-right">
                              <a href="javascript:void(0);">
                                <em @click="removeAsstEntities(asstValue)" class="icon-close"></em>
                              </a>
                            </div>
                          </div>
                          <!-- patterns -->
                          <div class="quiz-tabs-row row" v-if="asstValue.attributes.asst_entity_val_patterns && asstValue.attributes.asst_entity_val_patterns.length">
                            <div class="quiz-tab-col col-4">{{asstValue.attributes.value}}</div>
                            <div class="quiz-tab-col col-7" @click="handlePatternModal(asstValue)">
                              <a
                                class="d-block"
                              >{{$parent.getAssessmentValuePattern(asstValue.attributes.asst_entity_val_patterns)}}</a>
                            </div>

                            <div class="quiz-tab-col col-1 text-right">
                              <a href="javascript:void(0);">
                                <em @click="removeAsstEntities(asstValue)" class="icon-close"></em>
                              </a>
                            </div>
                          </div>



                        </div>
                      </div>
                      <!-- quiz table content end -->
                    </div>
                </div>
              </div>
              <div class="col-sm-12 mt-5">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="next('response-formulas', 5)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Entities setup end -->
      <!-- Quiz tabs content end here -->
      <modal
        name="enityValueModal"
        height="auto"
        :width="500"
        :scrollable="true"
        v-if="selectedSynonymList"
      >
        <div class="modal-content modal-default">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
              <h5>Entity value:{{selectedSynonymList.attributes.value}}</h5>
            </div>
          </div>
          <div class="modal-body">
            <form
              class="modal-form modal-min-height"
              v-on:submit.prevent="updateSynonymValues('add')"
            >
              <div class="form-group mt-2">
                <label>Synonyms</label>
                <input type="text" class="form-control" v-model="entitySynonymName" />
                <div class="chip-container entity-chip">
                  <div
                    class="chip"
                    v-for="(synonym, index) in selectedSynonymList.attributes.asst_entity_val_synonyms"
                    :key="synonym.id"
                  >
                    <span>{{synonym.attributes.synonym}}</span>
                    <em class="icon-close" @click="updateSynonymValues('delete',index, synonym )"></em>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  @click="$modal.hide('enityValueModal')"
                  data-dismiss="modal"
                  class="btn default"
                >Cancel</button>
                <button type="button" class="btn primary" @click="updateSynonymValues('done')">Done</button>
              </div>
            </form>
          </div>
        </div>
      </modal>
      <modal
          name="enityValueModalPattern"
          height="auto"
          :width="500"
          :scrollable="true"
          v-if="selectedPatternList"
        >
          <div class="modal-content modal-default">
            <div class="modal-header">
              <div class="modal-title" id="exampleModalLabel">
                <h5>Entity value:{{selectedPatternList.attributes.value}}</h5>
              </div>
            </div>
            <div class="modal-body">
              <form
                class="modal-form modal-min-height"
                v-on:submit.prevent="updateAssessmentValuesPattern('add')"
              >
                <div class="form-group mt-2">
                  <label>Patterns</label>
                  <input type="text" class="form-control" v-model="entityPatternName" />
                  <div class="chip-container entity-chip">
                    <div
                      class="chip"
                      v-for="(pattern, index) in selectedPatternList.attributes.asst_entity_val_patterns"
                      :key="pattern.id"
                    >
                      <span>{{pattern.attributes.pattern}}</span>
                      <em
                        class="icon-close"
                        @click="updateAssessmentValuesPattern('delete',index, pattern )"
                      ></em>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    @click="$modal.hide('enityValueModalPattern')"
                    data-dismiss="modal"
                    class="btn default"
                  >Cancel</button>
                  <button
                    type="button"
                    class="btn primary"
                    @click="updateAssessmentValuesPattern('done')"
                  >Done</button>
                </div>
              </form>
            </div>
          </div>
        </modal>
      <modal name="cloneConfirmationModal" height="auto" :width="350" :scrollable="true">
          <div class="modal-content modal-delete-content">
            <div
              class="modal-body modal-delete-body"
            >Are you sure you want to clone this learning object?</div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                @click="$modal.hide('cloneConfirmationModal')"
                class="btn medium default"
              >Cancel</button>
              <button type="button" @click="cloneLo(),$modal.hide('cloneConfirmationModal')" class="btn medium primary">Ok</button>
            </div>
          </div>
      </modal>
      <div class="setup-content" v-if="step === 'response-formulas'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Response & Formulas</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="nav nav-tabs" id="nav-tab" role="tablist" v-if="selectedChar">
              <a
                v-for="(char) of selectedChar"
                :key="char.id"
                :class="`nav-item nav-link flex-none px-5 ${emailSelChar.id === char.id ? 'active': ''}`"
                id="response-tab"
                data-toggle="tab"
                href="#nav-response"
                role="tab"
                aria-controls="nav-response"
                aria-selected="true"
                @click="handleCharTabChange(char)"
              >{{char.char_full_name}}</a>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <!-- response Tab -->
              <div
                class="tab-pane fade show active"
                id="nav-response"
                role="tabpanel"
                aria-labelledby="response-tab"
              >
                <div class="row custom-row">
                  <div class="col-sm-5">
                    <div class="form-group">
                      <ul
                        class="form-entity-tabs form-check-tabs2"
                        v-if="(emailResFormulas && selEmailResFormulas) || addIntro || addConclusion"
                      >
                       <li :class="addIntro ? 'active' : ''"
                        @click="markIntroActive()">
                          <a href="javascript:void(0);">Introduction</a>
                        </li>
                        <draggable
                          v-model="emailResFormulas"
                          :sort="true"
                          :move="log"
                          @start="isDragging=true"
                          @end="isDragging=true"
                        >
                          <li
                            v-for="(res) of emailResFormulas"
                            :key="res.id"
                            :class="isActiveResponse(res) ? 'active': ''"
                            @click="handleSelEmailResFormulas(res)"
                          >
                            <a href="javascript:void(0);">
                              <div>
                                {{res.attributes.name}}
                                <span
                                  class="caption"
                                >{{res.attributes.response_formulas.length}} Response Formulas</span>
                              </div>
                              <span v-if="isActiveResponse(res)">
                                <em @click="$modal.show('deleteEmailRespModal')" class="icon-close"></em>
                              </span>
                            </a>
                          </li>
                        </draggable>
                         <li :class="addConclusion ? 'active' : ''"
                          @click="markConclusionActive()">
                          <a href="javascript:void(0);">Conclusion</a>
                        </li>
                      </ul>
                    </div>
                    <div class="form-group">
                      <label>Add Response Name</label>
                      <input type="text" class="form-control" id="name" v-model="responseName" />
                      <div class="form-btn-group">
                        <button class="btn w-100 primary" @click="addEmailResName">Add Response</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-7" >
                    <div id="intro-form" v-if="addIntro">
                      <EmailRespIntroConclusion
                        :addIntro=true
                        :addConclusion=false
                        :objectableId="objectableId"
                        :emailSelChar="emailSelChar"
                        :iterationLevel="iterationLevel"
                        ref="EmailRespIntroConclusion"
                      />
                    </div>
                    <div id="character-response-form" v-if="!addIntro && !addConclusion">
                      <div class="form-group">
                        <label>Response Name</label>
                        <ValidationProvider
                          name="Value"
                          rules="required|max:100"
                          v-if="selEmailResFormulas"
                        >
                          <input
                            type="text"
                            class="form-control"
                            v-model="selEmailResFormulas.attributes.name"
                            @blur="saveResponseName()"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="assessment-response">
                        <ul class="response-tab">
                          <li
                            v-for="index in iterationLevel"
                            :key="index"
                            :class="selIterationLevel === index? 'active': ''"
                            @click="handleSelectedIteration(index)"
                          >
                            <a href="javascript:void(0);">Iteration {{index}}</a>
                          </li>
                        </ul>
                        <ul class="response-tab" v-if="selVariationList">
                          <li
                            v-for="(vari, index) of selVariationList"
                            :key="vari.id"
                            :class="selectedVariation && (selectedVariation.id === vari.id) ? 'active': ''"
                            @click="getSelectedVariation(index)"
                          >
                            <a href="javascript:void(0);">variation {{vari.attributes.variation}}</a>
                          </li>
                        </ul>
                        <div class="response-content">
                          <div class="form-group">
                            <vue-editor
                              id="Descriptiontext"
                              placeholder="Add a Variation"
                              :editorToolbar="fullTool"
                              v-model="variationName"
                            ></vue-editor>
                          </div>
                        </div>
                        <!-- <a
                          href="javascript:void(0);"
                          class="add-more-btn"
                          @click="handleAddVariation"
                        >+ Add Variation</a>-->
                      </div>
                      <!-- formulas data start-->
                      <div class="formulas-data">
                        <!-- formulas edit head start -->
                        <div class="formulas-edit">
                          <div class="formulas-title">Response Formulas</div>
                          <a
                            href="javascript:void(0);"
                            @click="openResFormulaModal"
                            class="add-more-btn"
                          >+ Add Response Formula</a>
                        </div>
                        <!-- formulas edit head end -->
                        <!-- formulas list start -->
                        <ul class="formulas-data-list" v-if="selPresentResFormula">
                          <li v-for="(res, i) of selPresentResFormula" :key="i">
                            <!-- formulas preset start  -->
                            <div class="formulas-list" v-if="res.present">
                              <div class="formulas-list-title">
                                <div class="formulas-left">
                                  Present:
                                  <span class="keywords">Keyword: at-least {{res.present_min}}</span>
                                </div>
                                <a
                                  href="javascript:void(0);"
                                  class="formulas-edit-btn"
                                  @click="editResponseFormula(res,  i)"
                                >
                                  <em class="icon-edit"></em>
                                  Edit
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  class="formulas-edit-btn"
                                  @click="setDestroyId(res, 'responseFormula');$modal.show('deleteResponseModal')"
                                >Delete</a>
                              </div>
                              <div class="formulas-list-item" v-if="res.present">
                                <div
                                  class="formula-item-flex"
                                  v-for="(formula, key, index) in res.present"
                                  :key="index"
                                >
                                  <div class="formula-name" v-if="formula">
                                    {{key}}:
                                    <span
                                      v-for="(entity) in formula"
                                      :key="entity.id"
                                    >{{entity.value}} ,</span>
                                  </div>
                                  <span
                                    class="formula-and"
                                    v-if="Object.keys(res.present).length -1 !== index"
                                  >and</span>
                                </div>
                              </div>
                            </div>
                            <!-- formulas preset end  -->
                            <!-- formulas absent start  -->
                            <div class="formulas-list" v-if="res.absent">
                              <div class="formulas-list-title">
                                <div class="formulas-left">
                                  Absent:
                                  <span class="keywords">Keyword: at-least {{res.absent_min}}</span>
                                </div>
                              </div>
                              <div class="formulas-list-item">
                                <div
                                  class="formula-item-flex"
                                  v-for="(formula, key, index) in res.absent"
                                  :key="index"
                                >
                                  <div class="formula-name" v-if="formula">
                                    {{key}}:
                                    <span
                                      v-for="(entity) in formula"
                                      :key="entity.id"
                                    >{{entity.value}} ,</span>
                                  </div>
                                  <span
                                    class="formula-and"
                                    v-if="Object.keys(res.absent).length -1 !== index"
                                  >and</span>
                                </div>
                              </div>
                            </div>
                            <!-- formulas absent end  -->
                          </li>
                        </ul>
                        <!-- formulas list end -->
                      </div>
                      <!-- formulas data end-->
                    </div>
                    <div id="conclusion-form" v-if="addConclusion">
                      <EmailRespIntroConclusion
                        :addIntro=false
                        :addConclusion=true
                        :objectableId="objectableId"
                        :emailSelChar="emailSelChar"
                        :iterationLevel="iterationLevel"
                        ref="EmailRespIntroConclusion"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-5">
              <div class="d-flex justify-content-end mt-4 mb-3">
                <button class="btn primary" @click="updateVariationOnNext(selectedVariation),next('skill-setup', 6)">Next</button>
              </div>
              <div class="btn-container justify-content-end">
                <a class="delete-btn">
                  <div class="delete-box">
                    <span class="icon-delete"></span>
                  </div>
                  <a
                    @click="$parent.archiveLO('archived')"
                    class="text"
                  >Archive this Learning Object(LO)</a>
                </a>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <modal name="responseFormulaModal" height="auto" width="700" :scrollable="true">
        <div class="modal-content modal-default">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel" v-if="selEmailResFormulas">
              <h5 v-if="step == 'response-formulas'">{{selEmailResFormulas.attributes.name}}</h5>
            </div>
          </div>
          <div class="modal-body">
            <form class="modal-form modal-min-height">
              <div class="modal-formula-content">
                <!-- formulas-tabs-list start -->
                <div class="formulas-tabs-list">
                  <div class="formulas-tabs">
                    
                    <a
                      href="javascript:void(0);"
                      @click="handleResFormula('nlu_intent')"
                      :class="setSelFormulaField === 'nlu_intent'? 'active' :''"
                    >Intent</a>
                    <a
                      href="javascript:void(0);"
                      @click="handleResFormula('assist-entity')"
                      :class="setSelFormulaField === 'assist-entity'? 'active' :''"
                    >Assistant Entity</a>
                  </div>
                  <!-- formulas-tabs-content start -->
                  <div class="formulas-tabs-content">
                    <!-- intents start -->
                    <div class="formula-tab-pane active" id="concept" v-if="selectedEvalVal">
                      <div class="formula-chips-list" v-if="setSelFormulaField === 'nlu_intent'">
                        <span class="formula-chips" v-for="nlu of selectedEvalVal" :key="nlu.id">
                          <drag class="drag" :transfer-data="nlu">{{nlu.attributes.name}}</drag>
                        </span>
                      </div>
                    </div>
                    <!-- intents end -->
                    <!-- assistant-entity start -->
                    <div
                      class="formula-tab-pane active"
                      id="assistant-entity"
                      v-if="setSelFormulaField === 'assist-entity'"
                    >
                      <!-- assistant item start -->
                      <div
                        class="formulas-assistant-item"
                        v-for="evalItem of selectedEvalVal"
                        :key="evalItem.id"
                      >
                        <div class="formula-chips-head">
                          <div class="formula-chips-left">
                            Assistant Entity:
                            <span>{{evalItem.attributes.name}}</span>
                          </div>
                          <div class="form-checkboxes d-inline-flex">
                            <label class="custom-checkbox" name="userOverall">
                              <input
                                type="checkbox"
                                @change="handleLabelEvalChange($event, evalItem)"
                              />
                              <span for="userOverall" class="checkmark circle"></span>
                              Select All
                            </label>
                          </div>
                        </div>
                        <div class="formula-chips-list">
                          <label>Values:</label>
                          <span
                            v-for="valItem of evalItem.attributes.asst_entity_values"
                            :key="valItem.id"
                            class="formula-chips"
                          >
                            <drag
                              class="drag"
                              :transfer-data="{
                                  id: valItem.id,
                                  asst_entity_id: evalItem.id,
                                  attributes: valItem.attributes,
                                  type:`nlu_Assistant-Entity`
                                 }"
                            >{{valItem.attributes.value}}</drag>
                          </span>
                        </div>
                      </div>
                      <!-- assistant item end -->
                    </div>
                    <!-- assistant-entity end -->
                    
                  </div>
                  <!-- formulas-tabs-content end -->
                </div>
                <!-- formulas-tabs-list end -->
                <!-- formulas-data-list start -->
                <ul class="formulas-data-list">
                  <li>
                    <!-- formulas preset start  -->
                    <div class="formulas-list">
                      <drop class="drop" @drop="handleEvaluationDrop($event, 'present')">
                        <div class="formulas-list-title">
                          <div class="formulas-left">
                            Present:
                            <span class="keywords">Keyword: at-least {{presentMinKeyword}}</span>
                          </div>
                          <div class="formulas-input">
                            <h5>Keyword minimum:</h5>
                            <div class="form-group">
                              <input type="text" class="form-control" v-model="presentMinKeyword" />
                            </div>
                          </div>
                        </div>
                        <div class="formulas-list-item">
                          <div
                            class="formula-item-flex"
                            v-for="(formula, key, index) in selectedPresentFormula"
                            :key="index"
                          >
                            <div class="formula-name">
                              {{key}}:
                              <span
                                v-for="(entity) in formula"
                                :key="entity.id"
                              >{{entity.value}} ,</span>
                              <a
                                href="javascript:void(0);"
                                @click="removeFormulaEntity('present', key)"
                                class="formula-cross"
                              >
                                <em class="icon-close"></em>
                              </a>
                            </div>
                            <span
                              class="formula-and"
                              v-if="Object.keys(selectedPresentFormula).length -1 !== index"
                            >and</span>
                          </div>
                        </div>
                        <div
                          class="formulas-list-placeholder"
                          v-if="Object.keys(selectedPresentFormula).length === 0"
                        >Drag and Drop here</div>
                      </drop>
                    </div>
                    <!-- formulas preset end  -->
                  </li>
                  <li>
                    <!-- formulas preset start  -->
                    <div class="formulas-list">
                      <drop class="drop" @drop="handleEvaluationDrop($event, 'absent')">
                        <div class="formulas-list-title">
                          <div class="formulas-left">
                            Absent:
                            <span class="keywords">Keyword: at-least {{absentMinKeyword}}</span>
                          </div>
                          <div class="formulas-input">
                            <h5>Keyword minimum:</h5>
                            <div class="form-group">
                              <input type="text" class="form-control" v-model="absentMinKeyword" />
                            </div>
                          </div>
                        </div>
                        <div class="formulas-list-item">
                          <div
                            class="formula-item-flex"
                            v-for="(formula, key, index) in selectedAbsentFormula"
                            :key="index"
                          >
                            <div class="formula-name">
                              {{key}}:
                              <span
                                v-for="(entity) in formula"
                                :key="entity.id"
                              >{{entity.value}},</span>
                              <a
                                href="javascript:void(0);"
                                class="formula-cross"
                                @click="removeFormulaEntity('absent', key)"
                              >
                                <em class="icon-close"></em>
                              </a>
                            </div>
                            <span
                              class="formula-and"
                              v-if="Object.keys(selectedAbsentFormula).length -1 !== index"
                            >and</span>
                          </div>
                        </div>
                        <div
                          class="formulas-list-placeholder"
                          v-if="Object.keys(selectedAbsentFormula).length === 0"
                        >Drag and Drop here</div>
                      </drop>
                    </div>
                    <!-- formulas preset end  -->
                  </li>
                </ul>
                <!-- formulas-data-list end -->
              </div>
              <div class="modal-footer mt-4">
                <button
                  type="button"
                  @click="$modal.hide('responseFormulaModal')"
                  data-dismiss="modal"
                  class="btn default"
                >Cancel</button>
                <button
                  type="button"
                  class="btn primary"
                  @click="handleAddResFormula"
                  v-if="!isFormulaEdited"
                >Done</button>
                <button type="button" class="btn primary" @click="handleEditResFormula" v-else>Done</button>
              </div>
            </form>
          </div>
        </div>
      </modal>
      <!-- Assessment Skill setup start  -->
      <div class="setup-content" v-if="step === 'skill-setup' && !assessmentFormula">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Assessment (Skill setup)</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Select Skills</label>Assessment (Skill setup)
                  <ul class="form-entity-tabs">
                    <li>
                      <div class="quiz-dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="entity"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div>Add New Skills</div>
                          <!-- <div
                            v-if="selectSkill && selectSkill.attributes"
                          >{{selectSkill.attributes.name}}</div>-->
                          <em class="icon-carrot-down"></em>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="entity">
                          <option
                            class="dropdown-item"
                            v-for="skill of globalSkills"
                            :key="skill.id"
                            @click="addSkills(skill)"
                          >
                            {{skill.attributes.name}}
                            <div @click="addSkills(skill)"></div>
                          </option>
                        </div>
                      </div>
                    </li>
                    <li
                      v-for="(item, ind) in emailSkills"
                      :key="item.id"
                      @click="selectedSkills(item, ind)"
                      :class="item.skillId == selectedSkill.skillId ? 'active' :''"
                    >
                      <a>{{item.skillName}}</a>
                    </li>
                  </ul>
                </div>
                <div class="form-group pt-4">
                  <label>Add new skill</label>
                  <input
                    class="form-control"
                    id="admin_notes"
                    v-model="newSkill"
                    :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                  />
                </div>
                <div class="form-group">
                  <div class="form-btn-group">
                    <a
                      href="javascript:void(0);"
                      class="btn w-100 primary"
                      @click="addNewSkill"
                    >Add new skill</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="skill-title my-4">
                <h5>{{this.selectedSkill.skillName}}</h5>
                    <a
                      href="javascript:void(0);"
                      class="email-delete-btn"
                      v-if="this.selectedSkill.id"
                      @click="$modal.show('deleteSkillConfirmationModal')"
                      ><em class="icon-delete"></em>
                      Delete skill
                    </a>
                  </div>
                <div class="form-group">
                  <label>Skill Description (Evaluation explanation)</label>
                  <ValidationProvider name="Administrative Notes" v-slot="{ errors }">
                    <textarea
                      class="form-control"
                      id="admin_notes"
                      rows="8"
                      v-model="skillDesc"
                      :class="[(isActive  && errors[0] ) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive && errors[0] ? 'invalid' : '']"
                      :style="[isActive && errors[0] ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group justify-content-end d-flex">
                  <button
                    href="javascript:void(0);"
                    @click="updateSkills"
                    class="add-more-btn"
                  >Save</button>
                </div>
                <div class="form-group justify-content-end d-flex">
                  <a
                    href="javascript:void(0);"
                    @click="goToAssessment"
                    class="add-more-btn"
                  >Configure assessments</a>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="updateSkills(),next('email-testing', 7)"
                  >Next</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Assessment Skill setup end  -->
      <!-- Testing start -->
      <div class="setup-content" v-if="step == 'email-testing'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Testing</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Submit test input</label>
                  <ValidationProvider name="Test Input" rules="required" v-slot="{ errors }">
                    <textarea
                      type="Description"
                      class="form-control"
                      id="notes"
                      rows="4"
                      v-model="testInput"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    ></textarea>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <div class="form-testing-group">
                    <a
                      href="javascript:void(0);"
                      class="btn default"
                      v-if="testInput.length > 0"
                      @click="clearTesting"
                    >Clear</a>
                    <a
                      href="javascript:void(0);"
                      class="btn primary"
                      v-if="testInput.length > 50"
                      @click="submitTesting"
                    >Submit</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-7" v-if="testEvalJsonScheme.attributes">
                <div
                  class="modal-test-mail"
                  v-if="testEvalJsonScheme.attributes.responses.length > 0"
                >
                  <h4>Character Response</h4>
                  <p
                    v-for="(scheme,index) in testEvalJsonScheme.attributes.responses"
                    :key="index"
                    v-html="scheme.attributes.response"
                  ></p>
                </div>
                <div class="modal-test-tabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="Test-tab"
                        data-toggle="tab"
                        href="#Test"
                        role="tab"
                        aria-controls="Test"
                        aria-selected="true"
                      >Test input Return Data</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="json-tab"
                        data-toggle="tab"
                        href="#json"
                        role="tab"
                        aria-controls="json"
                        aria-selected="false"
                      >View JSON</a>
                    </li>
                  </ul>
                  <div class="tab-content pt-2" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="Test"
                      role="tabpanel"
                      aria-labelledby="Test-tab"
                    >
                      <div class="test-items">
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.asst_entity_value_list.length">
                          <label>Assistant Entities Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.asst_entity_value_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.asst_intent_list.length">
                          <label>Assistant Intents Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.asst_intent_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.keyword_list.length">
                          <label>NLU Keywords Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.keyword_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.concept_list.length">
                          <label>NLU Concepts Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.concept_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                         <div class="mt-3" v-if="testEmailEnrichmentItems.attributes.nlu_entities_list.length">
                          <label>NLU Entities Hit:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              {{testEmailEnrichmentItems.attributes.nlu_entities_list.join(', ')}}
                            </span>
                          </div>
                        </div>
                        <div class="mt-3">

                          <div class="test-chips-container">
                            <label>NLU Emotion Scores:</label>
                            <span class="test-chips">
                              JOY: {{testEmailEnrichmentItems.attributes.joy_score}},
                              ANGER: {{testEmailEnrichmentItems.attributes.anger_score}},
                              DISGUST: {{testEmailEnrichmentItems.attributes.disgust_score}},
                              SADNESS: {{testEmailEnrichmentItems.attributes.sadness_score}},
                              FEAR: {{testEmailEnrichmentItems.attributes.fear_score}}
                            </span>
                          </div>
                        </div>
                         <div class="mt-3">
                          <label>NLU Sentiment Score:</label>
                          <div class="test-chips-container">
                            <span class="test-chips">
                              Sentiment: {{testEmailEnrichmentItems.attributes.sentiment}},
                              Score: {{testEmailEnrichmentItems.attributes.sentiment_score}}
                            </span>
                          </div>
                        </div>

                      </div>
                      <div v-if="testEvalJsonScheme && testEvalJsonScheme.attributes && testEvalJsonScheme.attributes.response_formula_hits">
                        <label
                            class="text-label"
                          >Email Response hit:</label>
                        <div
                          class="test-items"
                          v-for="(formula,index) in testEvalJsonScheme.attributes.response_formula_hits"
                          :key="index"
                        >
                          <div class="test-chips-container">
                            <span
                              class="test-chips"
                            >{{formula.attributes.email_response.name}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="json" role="tabpanel" aria-labelledby="json-tab">
                      <div class="enrichment-concept-view p-0">
                        <div class="enrichment-concept-editor">
                          <div class="enrichment-concept-code">
                            <json-viewer :value="testEvalJsonScheme" :expand-depth="5"></json-viewer>
                          </div>
                          <div class="enrichment-code-copy">
                            <copy-to-clipboard
                              :text="JSON.stringify(testEvalJsonScheme)"
                              @copy="handleCopy"
                            >
                              <a href="javascript:void(0);" class="copy-icon">Copy</a>
                            </copy-to-clipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn primary btn-large"
                    @click="next('qa-and-interstitial', 8)"
                  >Next</a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- Testing end -->
      <div v-if="step === 'qa-and-interstitial'">
        <div class="setup-content">
          <div>
            <h4 class="head-title">QA Conditions</h4>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="nav nav-tabs mb-0" id="nav-tab5" role="tablist5">
              <a
                v-for="(char,index) of qaConditions"
                :key="char.id"
                :class="`nav-item nav-link flex-none px-5 ${emailSelChar.id === char.attributes.character.id ? 'active': ''}`"
                :id="`#collapseOne${index}`"
                data-toggle="tab"
                :data-target="`#collapseOne${index}`"
                role="tab"
                :aria-controls="`${index}`"
                aria-selected="true"
                @click="getQaId(char.id, char.attributes.character.id)"
              >{{char.attributes.character.attributes.char_full_name}}</a>
            </div>
            <div class="learning-quiz-btn">
              <a
                href="javascript:void(0);"
                @click="openResFormulaModal"
                class="btn px-5 header-btn secondary"
              >Add QA Condition</a>
            </div>
          </div>
          <ValidationObserver>
            <form class="w-100">
              <div class="row custom-row">
                <div class="tab-content col-sm-12" v-if="selPresentResFormula.length>0">
                  <ul class="formulas-data-list">
                    <li v-for="(res, i) of selPresentResFormula" :key="i">
                      <div class="formulas-list" v-if="res.present">
                        <div class="formulas-list-title">
                          <div class="qa-formulas-left">
                            Present:
                            <span class="keywords">Keyword: at-least {{res.present_min}}</span>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="formulas-edit-btn"
                            @click="editResponseFormula(res,  i)"
                          >
                            <em class="icon-edit"></em>
                            Edit
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="formulas-delete-btn"
                            @click="setDestroyId(res, 'qaFormula');$modal.show('deleteResponseModal')"
                          >
                          <em class="icon-delete"></em>
                            Delete
                          </a>
                        </div>
                        <div class="formulas-list-item" v-if="res.present">
                          <div
                            class="formula-item-flex"
                            v-for="(formula, key, index) in res.present"
                            :key="index"
                          >
                            <div class="formula-name">
                              {{key}}:
                              <span
                                v-for="(entity) in formula"
                                :key="entity.id"
                              >{{entity.value}} ,</span>
                            </div>
                            <span
                              class="formula-and"
                              v-if="Object.keys(res.present).length -1 !== index"
                            >and</span>
                          </div>
                        </div>
                      </div>
                      <!-- formulas preset end  -->
                      <!-- formulas absent start  -->
                      <div class="formulas-list" v-if="res.absent">
                        <div class="formulas-list-title">
                          <div class="formulas-left">
                            Absent:
                            <span class="keywords">Keyword: at-least {{res.absent_min}}</span>
                          </div>
                        </div>
                        <div class="formulas-list-item">
                          <div
                            class="formula-item-flex"
                            v-for="(formula, key, index) in res.absent"
                            :key="index"
                          >
                            <div class="formula-name">
                              {{key}}:
                              <span
                                v-for="(entity) in formula"
                                :key="entity.id"
                              >{{entity.value}} ,</span>
                            </div>
                            <span
                              class="formula-and"
                              v-if="Object.keys(res.absent).length -1 !== index"
                            >and</span>
                          </div>
                        </div>
                      </div>
                      <!-- formulas absent end  -->
                    </li>
                  </ul>
                  <!-- formulas list end -->
                  <div class="form-group">
                    <label>Out of office response</label>
                    <ValidationProvider name="Assessment Scheme">
                      <textarea
                        class="form-control"
                        rows="6"
                        v-model="qaResponse"
                        placeholder="Search by name, role & organization"
                      ></textarea>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <button
                      class="btn primary btn-large"
                      @click="next('overall-assessment', 9);"
                    >Next</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="setup-content-border"></div>
        <div class="setup-content">
          <div class="form-section m-0 p-0">
            <div class="learning-form-header d-flex align-items-start">
              <div class="col-lg-6 px-0 pt-4 pb-1">
                <h4>Upload Interstitial Content:</h4>
                <p class="mt-1">Add Interstitial Content for the learner about the learning module.</p>
              </div>
              <input type="file" multiple="multiple" accept=".pdf, .doc" style="display: none;" />
              <div class="ml-auto pt-4 text-right learning-quiz-btn">
                <a
                  @click="$modal.show('uploadInterstitialModal',{create: true})"
                  class="btn ml-2 px-4 secondary"
                >Upload Interestitial Content</a>
              </div>
            </div>
            <div class="learning-data-list flex-column pt-3">
              <div
                class="learning-data-item learning-interstitial-data-item"
                v-for="(item, i) in interstitialLists"
                :key="item.id"
              >
                <div class="learning-data-left">
                  <div
                    class="learning-data-richText"
                    v-if="item.attributes.content_details.type == 'text_learn_obj'"
                  ></div>
                  <div
                    class="learning-data-icon"
                    v-if="item.attributes.content_details.type == 'file_learn_obj'"
                  >
                    <a>
                      <img src="../../assets/images/pdf-big-icon.png" alt="pdf" />
                    </a>
                  </div>
                  <div
                    class="learning-data-icon"
                    v-if="item.attributes.content_details.type == 'slide_learn_obj'"
                  >
                    <a v-if="item.attributes.content_details.attributes.slider_images.length > 0">
                      <img
                        :src="item.attributes.content_details.attributes.slider_images[0].attributes.resource_url.thumbnail"
                        alt="slide"
                      />
                    </a>
                  </div>
                  <div
                    class="learning-data-video"
                    v-if="item.attributes.content_details.type == 'video_learn_obj'"
                  >
                    <div
                      class="wistia_responsive_padding data-video"
                      style="padding:56.25% 0 0 0;position:relative;"
                    >
                      <div
                        class="wistia_responsive_wrapper data-video"
                        style="height:100%;left:0;position:absolute;top:0;width:100%;"
                      >
                        <iframe
                          :src="preIframUrl+item.attributes.content_details.attributes.global_video.attributes.wistia_code+postIframUrl"
                          title="Video.mp4"
                          class="wistia_embed"
                          name="wistia_embed"
                          allowtransparency="true"
                          scrolling="no"
                          allowfullscreen
                          mozallowfullscreen
                          webkitallowfullscreen
                          oallowfullscreen
                          msallowfullscreen
                          width="100%"
                          height="100%"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="learning-data-title">
                  <div class="data-heads">
                    <h4 v-html="item.attributes.title"></h4>
                    <span
                      v-if="item.attributes.content_details.type == 'file_learn_obj'"
                    >{{size(item.attributes.content_details.attributes.resource_size)}}</span>
                  </div>
                  <h4 v-html="item.attributes.content_details.attributes.title"></h4>
                  <div class="learning-data-paragraph">
                    <div>
                      <p v-html="item.attributes.description"></p>
                    </div>
                    <a
                      class="learning-edit-btn"
                      @click="$modal.show('uploadInterstitialModal',{create: false, edit: item})"
                    >Edit</a>
                    <a @click="removeInterstitialData(item.id, i)">Remove</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- QA @ Interstitial end  -->
      <div class="setup-content" id="overall-assessment" v-if="step === 'overall-assessment'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Overall Assessment</h4>
        </div>
        <ValidationObserver ref="form" v-if="overallAssmntItem">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Overall Interaction Assessment</label>
                  <ul class="form-entity-tabs">
                    <li
                      v-for="assmntItem of overallAssmntItem"
                      :key="assmntItem.id"
                      :class="assmntItem.id == selectedOverallAssmntItem.id ? 'active' : ''"
                      @click="handleOverallAssmntItem(assmntItem)"
                    >
                      <a href="javascript:void(0);">{{assmntItem.attributes.assessment_label}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="question-content" id="number-range">
                  <div class="question-row">
                    <div class="form-group">
                      <label>Evaluation Text</label>
                      <ValidationProvider name="Evaluation Text" v-slot="{ errors }">
                        <vue-editor
                          id="Descriptiontext"
                          placeholder="Add a Description"
                          :editorToolbar="fullTool"
                          v-model="overallAssmntItemFeedback"
                        ></vue-editor>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group pt-2">
                      <label>Evaluation range:</label>
                      <ValidationProvider name="Evaluation range" v-slot="{ errors }">
                        <div class="row mt-2">
                          <div class="col-6">
                            <label>Minimum</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="overallAssmntItemMinScore"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                          </div>
                          <div class="col-6">
                            <label>Maximum</label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="overallAssmntItemMaxScore"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                          </div>
                        </div>
                        <span
                          :class="[isActive ? 'invalid' : '']"
                          :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                          v-if="errors[0]"
                        >{{ errors[0].replace("The ", "" )}}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 pt-5">
                <div class="d-flex justify-content-end mt-4 mb-3">
                  <a class="btn primary" @click="restore('published')">Save & Publish</a>
                </div>
                <div class="btn-container justify-content-end">
                  <a class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <a
                      @click="$parent.archiveLO('archived')"
                      class="text"
                    >Archive this Learning Object(LO)</a>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-if="assessmentFormula">
      <div class="learning-form-header flex-wrap">
        <h4>
          <a @click="backToAssessment" class="back-btn-inner">
            <span class="icon-back"></span>
            Back to Assessment (Skill setup)
          </a>
        </h4>
      </div>
      <div class="learning-quiz-content pt-4">
        <div class="setup-content">
          <ValidationObserver>
            <form class="w-100">
              <div class="row custom-row">
                <div class="col-sm-5">
                  <div class="form-group my-3">
                    <label>{{selectedSkill.skillName}}</label>
                  </div>
                  <div class="form-group">
                    <ul class="form-entity-tabs form-check-tabs form-check-tabs2">
                      <li
                        v-for="skill
                              of emailAssessmentSkill"
                        :key="skill.id"
                        :class="skill.id == selectedAssmntItem.id ? 'active' : ''"
                        @click="selectAssessmentItem(skill)"
                      >
                        <a href="javascript:void(0);">
                          <div>
                            {{skill.attributes.assessment_label_name}}
                            <span
                              class="caption"
                            >{{skill.attributes.assessment_formulas.length}} Response Formulas</span>
                          </div>
                          <span>
                            <em class="icon-check"></em>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      :class="`nav-item nav-link flex-none active`"
                      id="response-tab"
                      data-toggle="tab"
                      href="#nav-response"
                      role="tab"
                      aria-controls="nav-response"
                      :aria-selected="true? 'false': 'true'"
                    >Debrief Content</a>
                    <a
                      class="nav-item nav-link"
                      id="adaptive-tab"
                      data-toggle="tab"
                      href="#nav-adaptive"
                      role="tab"
                      aria-controls="nav-adaptive"
                      aria-selected="false"
                    >Adaptive configuration</a>
                  </div>
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      :class="`tab-pane fade show active`"
                      id="nav-response"
                      role="tabpanel"
                      aria-labelledby="response-tab"
                    >
                      <div class="assessment-response">
                        <div
                          :class="['assessment-response', variations_briefattributes.length > 0 ? 'pt-2':'']"
                        >
                          <ul class="response-tab" v-if="variations_briefattributes.length > 0">
                            <li
                              v-for="(variations, key) in variations_briefattributes"
                              :key="key"
                              :class="variationsSelect === key+1 ? 'active' : ''"
                            >
                              <a
                                href="javascript:void(0);"
                                @click="addDebriefVariation(key+1,'debrief')"
                              >
                                Variation
                                {{variations_briefattributes.length > 1 ? key+1 : ''}}
                              </a>
                            </li>
                          </ul>
                          <div
                            class="response-content"
                            v-if="variations_briefattributes.length > 0"
                          >
                            <div class="form-group">
                              <div class="form-control p-0">
                                <ValidationProvider
                                  name="Variation"
                                  rules="max:500"
                                  v-slot="{ errors }"
                                >
                                 <vue-editor
                                    :class="['form-control',(isActive && errors[0]) ? 'invalid' : '']"
                                    :editorToolbar="fullTool"
                                    placeholder="Variations"
                                    v-model="variations_briefattributes[variationsSelect -1].content"
                                  ></vue-editor>
                                  <!-- <span
                                    :class="[isActive ? 'invalid' : '']"
                                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                                    v-if="errors[0]"
                                  >{{ errors[0].replace("The ", "" )}}</span> -->
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="add-more-btn"
                            @click="variations_briefattributes.length < 5 ? addDebriefVariation(null,'debrief') : ''"
                          >+ Add Variation</a>
                          <a
                            v-if="variations_briefattributes.length > 0"
                            href="javascript:void(0);"
                            class="add-more-btn variation-save"
                            @click="saveDebriefVariations()"
                          >Save variation(s) & points</a>
                          <!-- <span
                            :class="[isActive ? 'invalid' : '']"
                            :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                            v-if="variations_briefattributes.length <= 0"
                          >Add Variation field is required</span> -->
                        </div>
                        <div class="form-group mt-4">
                          <label>Points</label>
                          <ValidationProvider
                            name="Points"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <input
                              type="text"
                              class="form-control"
                              v-model="assessmentPoints"
                              :class="[(isActive && errors[0]) ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                              v-if="errors[0]"
                            >{{ errors[0].replace("The ", "" )}}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <!-- formulas data start-->
                      <div class="formulas-data">
                        <!-- formulas edit head start -->
                        <div class="formulas-edit">
                          <div class="formulas-title">Assessment Formula</div>
                          <a
                            href="javascript:void(0);"
                            @click="openResFormulaModal"
                            class="add-more-btn"
                          >+ Add Assessment Formula</a>
                        </div>
                        <!-- formulas edit head end -->
                        <!-- formulas list start -->
                        <ul class="formulas-data-list" v-if="selPresentResFormula">
                          <li v-for="(res, i) of selPresentResFormula" :key="i">
                            <!-- formulas preset start  -->
                            <div class="formulas-list" v-if="res.present">
                              <div class="formulas-list-title">
                                <div class="formulas-left">
                                  Present:
                                  <span
                                    class="keywords"
                                  >Keyword: at-least {{res.present_min}}</span>
                                </div>
                                <a
                                  href="javascript:void(0);"
                                  class="formulas-edit-btn"
                                  @click="editResponseFormula(res,  i)"
                                >
                                  <em class="icon-edit"></em>
                                  Edit
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  class="formulas-edit-btn"
                                  @click="setDestroyId(res, 'assessmentResponseFormula');$modal.show('deleteResponseModal')"
                                >Delete</a>
                              </div>
                              <div class="formulas-list-item" v-if="res.present">
                                <div
                                  class="formula-item-flex"
                                  v-for="(formula, key, index) in res.present"
                                  :key="index"
                                >
                                  <div class="formula-name" v-if="formula">
                                    {{key}}:
                                    <span
                                      v-for="(entity) in formula"
                                      :key="entity.id"
                                    >{{entity.value}} ,</span>
                                  </div>
                                  <span
                                    class="formula-and"
                                    v-if="Object.keys(res.present).length -1 !== index"
                                  >and</span>
                                </div>
                              </div>
                            </div>
                            <!-- formulas preset end  -->
                            <!-- formulas absent start  -->
                            <div class="formulas-list" v-if="res.absent">
                              <div class="formulas-list-title">
                                <div class="formulas-left">
                                  Absent:
                                  <span class="keywords">Keyword: at-least {{res.absent_min}}</span>
                                </div>
                              </div>
                              <div class="formulas-list-item">
                                <div
                                  class="formula-item-flex"
                                  v-for="(formula, key, index) in res.absent"
                                  :key="index"
                                >
                                  <div class="formula-name" v-if="formula">
                                    {{key}}:
                                    <span
                                      v-for="(entity) in formula"
                                      :key="entity.id"
                                    >{{entity.value}} ,</span>
                                  </div>
                                  <span
                                    class="formula-and"
                                    v-if="Object.keys(res.absent).length -1 !== index"
                                  >and</span>
                                </div>
                              </div>
                            </div>
                            <!-- formulas absent end  -->
                          </li>
                        </ul>
                        <!-- formulas list end -->
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="nav-adaptive"
                      role="tabpanel"
                      aria-labelledby="adaptive-tab"
                    >
                      <div class="d-flex align-items-center">
                        <h5 class="small-title">Add Adaptive Content</h5>
                        <div class="px-4 learning-quiz-btn">
                          <a
                            @click="$modal.show('uploadInterstitialModal',{create: true, content: 'adaptive'})"
                            class="btn ml-2 px-4 secondary"
                          >Upload</a>
                        </div>
                      </div>

                      <div
                        class="learning-data-list flex-column pt-3"
                        v-if="adaptiveContentList.length > 0"
                      >
                        <div
                          class="learning-data-item w-100 learning-interstitial-data-item"
                          v-for="(item, i) in adaptiveContentList"
                          :key="item.id"
                        >
                          <div class="learning-data-left">
                            <div
                              class="learning-data-richText"
                              v-if="item.attributes.content_details.type == 'text_learn_obj'"
                            ></div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'file_learn_obj'"
                            >
                              <a>
                                <img src="../../assets/images/pdf-big-icon.png" alt="pdf" />
                              </a>
                            </div>
                            <div
                              class="learning-data-icon"
                              v-if="item.attributes.content_details.type == 'slide_learn_obj'"
                            >
                              <a>
                                <img
                                  :src="item.attributes.content_details.attributes.slider_images[0].attributes.resource_url.thumbnail"
                                  alt="slide"
                                />
                              </a>
                            </div>
                            <div
                              class="learning-data-video"
                              v-if="item.attributes.content_details.type == 'video_learn_obj'"
                            >
                              <div
                                class="wistia_responsive_padding data-video"
                                style="padding:56.25% 0 0 0;position:relative;"
                              >
                                <div
                                  class="wistia_responsive_wrapper data-video"
                                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                                >
                                  <iframe
                                    :src="preIframUrl+item.attributes.content_details.attributes.global_video.attributes.wistia_code+postIframUrl"
                                    title="Video.mp4"
                                    class="wistia_embed"
                                    name="wistia_embed"
                                    allowtransparency="true"
                                    scrolling="no"
                                    allowfullscreen
                                    mozallowfullscreen
                                    webkitallowfullscreen
                                    oallowfullscreen
                                    msallowfullscreen
                                    width="100%"
                                    height="100%"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="learning-data-title">
                            <div class="data-heads">
                              <h4 v-html="item.attributes.title"></h4>
                              <span
                                v-if="item.attributes.content_details.type == 'file_learn_obj'"
                              >{{size(item.attributes.content_details.attributes.resource_size)}}</span>
                            </div>
                            <h4 v-html="item.attributes.content_details.attributes.title"></h4>
                            <div class="learning-data-paragraph">
                              <div>
                                <p v-html="item.attributes.description"></p>
                              </div>
                              <a
                                style="color: #3dbc9e"
                                @click="$modal.show('uploadInterstitialModal',{create: false, edit: item, content: 'adaptive'})"
                              >Edit</a>
                              <a @click="removeAdaptiveData(item.id, i)">Remove</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="d-flex justify-content-end mt-4 mb-3">
                    <a class="btn primary" @click="next('email-testing', 7)">Done</a>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <addInterstitialContent
      v-on:interstitialContents="interstitialContents"
      v-on:adaptiveContents="adaptiveContents"
      v-if="type == 'email'"
    />
    <modal name="deleteResponseModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">Are you sure you want to delete?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteResponseModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            v-if="responseFormulaId.name === 'responseFormula'"
            type="button"
            @click="deleteResponseFormula(),$modal.hide('deleteResponseModal')"
            class="btn medium primary"
          >Ok</button>
           <button
            v-if="responseFormulaId.name === 'qaFormula'"
            type="button"
            @click="deleteQaFormula(),$modal.hide('deleteResponseModal')"
            class="btn medium primary"
          >Ok</button>
          <button
            v-if="responseFormulaId.name === 'assessmentResponseFormula'"
            type="button"
            @click="deleteAssessmentResponseFormula(),$modal.hide('deleteResponseModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
    <modal name="deleteEmailRespModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
        >Are you sure you want to delete this response?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteEmailRespModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteResponse(),$modal.hide('deleteEmailRespModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
    <modal name="ReduceIteration" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
          >Reducing the iteration level removes the respective iteration responses added, if any.
          Are you sure you want to reduce the iteration level? </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="changeBackIteration(),$modal.hide('ReduceIteration')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="$modal.hide('ReduceIteration')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
    <modal name="deleteSkillConfirmationModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div
          class="modal-body modal-delete-body"
        >Are you sure you want to delete this skill?</div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteSkillConfirmationModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteSkill(),$modal.hide('deleteSkillConfirmationModal')"
            class="btn medium primary"
          >Ok</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
// import CharacterEmail from "./characterEmail.vue";
import CopyToClipboard from "vue-copy-to-clipboard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import "vue-slider-component/theme/antd.css";
import { Drag, Drop } from "vue-drag-drop";
import draggable from "vuedraggable";
import { VueEditor } from "vue2-editor";
import addInterstitialContent from "@/components/modal/addInterstitialContent.vue";
import EmailRespIntroConclusion from "@/components/modal/EmailRespIntroConclusion.vue";
export default {
  name: "interactiveEmailLo",
  props: [
    "selectedMenuItem",
    "assessmentLists",
    "id",
    "emailInteractiveData",
    "type",
    "charList"
  ],
  components: {
    ValidationProvider,
    ValidationObserver,
    CopyToClipboard,
    addInterstitialContent,
    Drag,
    Drop,
    draggable,
    VueEditor,
    EmailRespIntroConclusion
    // CharacterEmail
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
  data() {
    return {
      step: "basic-setup",
      isActive: false,
      iterationLevel: 1,
      savedIterationLevel: 1,
      enableLearnerIteration: false,
      displayOverallAssessment: false,
      competencyCode: "",
      newIterationLogic: true,
      overallInteraction: false,
      hideAssessmentLabel: false,
      name: "",
      searchCharacter: "",
      searchCcCharacter: "",
      adminNotes: "",
      editEmailloId: "",
      selectedChar: [],
      selectedCcChar: [],
      charStatus: false,
      description: "",
      learnerExplanation:
        "This email interaction provided multiple opportunities to achieve the highest overall assessment. This number indicates how many iterations you went through on this interaction.",
      optionalCharStatus: false,
      url: utilFunctionService.getImageUrl(),
      assessmentListKey: { name: "None", id: null },
      addIntro: false,
      addConclusion: false,
      searchCharacters: [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          // char_photo_url: "",
          char_age: "",
          char_gender: "",
          status: false
        }
      ],
      searchOptionalCharacters: [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          // char_photo_url: "",
          char_age: "",
          char_gender: ""
        }
      ],
      message: "",
      index: 0,
      objectableId: "",
      isActiveTab: ["active"],
      value: "",
      emailIntentName: "",
      intentLists: [],
      selectedAsstIntent: {},
      intentDesc: "",
      intentInput: "",
      emailEntityName: "",
      selectedAsstEntities: {},
      asstEntitiesList: [],
      asstName: "",
      asstType: "Synonyms",
      asstSynonyms: "",
      asstPatterns: "",
      selectedPatternList: null,
      selectedSynonymList: null,
      entitySynonymName: "",
      entityPatternName: "",
      activeEmailModule: 0,
      activeState: "",
      loId: this.$route.query.id,
      emailResFormulas: null,
      selEmailResFormulas: null,
      emailSelChar: null,
      responseName: "",
      selVariationList: null,
      variationName: "",
      storeTab: 0,
      setSelFormulaField: "nlu_intent",
      selectedEvalVal: null,
      selectedPresentFormula: {},
      selectedAbsentFormula: {},
      selIterationLevel: 1,
      selectedVariation: null,
      presentMinKeyword: 0,
      absentMinKeyword: 0,
      selPresentResFormula: null,
      emotionData: {
        anger: 0,
        disgust: 0,
        fear: 0,
        joy: 0,
        sadness: 0
      },
      sentimentData: {
        range: 0.05,
        score: 0,
        value: "",
        sentiment: null
      },
      overallAssmntItem: [],
      selectedOverallAssmntItem: [],
      assessmentSchemeId: "",
      overallAssmntItemFeedback: "",
      overallAssmntItemMaxScore: "",
      overallAssmntItemMinScore: "",
      overallAssessmentRange: "percentage",
      fullTool: [],
      interstitialLists: [],
      qaConditions: [],
      imagePreview: "",
      contentDescription: "",
      selDesPresentFormula: [],
      selDesAbsentFormula: [],
      isFormulaEdited: false,
      editFormulaIndex: null,
      selectedTabChar: null,
      allEmailResFormulas: null,
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      formulaKeys: {
        intent: ["asst_intent_id", "formula_asst_intents_attributes"],
        "Assistant-Entity": [
          "asst_entity_value_id",
          "formula_asst_entity_values_attributes"
        ]
      },
      qaFormulaKeys: {
        intent: ["asst_intent_id", "qa_formula_asst_intents_attributes"],
        "Assistant-Entity": [
          "asst_entity_value_id",
          "qa_formula_asst_entity_values_attributes"
        ]
      },
      assessmentFormulaKeys: {
        intent: [
          "asst_intent_id",
          "assessment_formula_asst_intents_attributes"
        ],
        "Assistant-Entity": [
          "asst_entity_value_id",
          "assessment_formula_asst_entity_values_attributes"
        ]
      },
      qaConditionId: "",
      qaResponse: "",
      selRangeEmotion: {
        anger: null,
        disgust: null,
        fear: null,
        joy: null,
        sadness: null
      },
      selRange: {
        anger: 0.05,
        disgust: 0.05,
        fear: 0.05,
        joy: 0.05,
        sadness: 0.05
      },
      disabledEmotion: false,
      disabledSentiment: false,
      testInput: "",
      skillLists: [],
      globalSkills: [],
      emailSkills: [],
      skillDesc: "",
      newSkill: "",
      skillId: "",
      selectedSkill: {},
      assessmentFormula: false,
      emailAssessmentSkill: [],
      selectedAssmntItem: "",
      selectSkill: "",
      adaptiveContentList: [],
      testEvalJsonScheme: "",
      testEmailEnrichmentItems: "",
      variations_briefattributes: [],
      variationsSelect: 1,
      assessmentPoints: 1,
      testEvaulationScheme: [],
      emailQaResFormulas: [],
      emailSelCharId: "",
      responseFormulaId: "",
      status: 'drafted',
      updatedData: "",
      introEmailStatus: false,
      introEmailSubject: '',
      introEmailBody: '',
      docs: [],
      enableLearnerRetries: false,
      maxNumberOfRetries: 0,
      enableInstructorOverride: false,
      characters: this.charList,
      error: ""
    };
  },
  methods: {
    removeDoc() {
      api.deleteGlobalResource(this.docs[0].id).then(() => {
          utilFunctionService.showSuccess(
              commonConfig.appMessages.deleteFile
          );
          this.docs = [];
      })
    },
    async onDocSelect(event) {
      utilFunctionService.showLoader();
      for (var i = 0; i < event.target.files.length; i++) {
        this.selectedFile = event.target.files[i];
        const fb = new FormData();
        if (this.selectedFile) {
          let tokenAccess = utilFunctionService.getAuthToken();
          fb.append("global_resource[attachment]", this.selectedFile);
          fb.append("global_resource[private]", false);
          fb.append("global_resource[resource_type]", "document");
          fb.append("global_resource[tag_list]", "");
          axios
            .post(`${this.url}/api/admin/v1/global_resources.json`, fb, {
              headers: {
                Authorization: `${tokenAccess}`,
                "content-type": "multipart/form-data"
              }
            })
            .then(
              async function(res) {
                this.docs = [];
                if (res.data.data) {
                  await this.docs.push(res.data.data);
                }
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.addDoc
                );
                utilFunctionService.hideLoader();
              }.bind(this)
            )
            .catch(() => {
              utilFunctionService.hideLoader();
            });
        }
      }
    },
    selectedCharacters(data) {
       this.selectedCcChar = data.ccCharacters;
       this.selectedChar = data.toCharacter;
    },
    log({ relatedContext, draggedContext }) {

      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      let menuListData = [...this.emailResFormulas];
      const fb = new FormData();
      let draggedCardOrder = draggedElement.attributes.response_order;
      let relatedCardOrder = relatedElement.attributes.response_order;
      console.log(draggedCardOrder)
      console.log(relatedCardOrder)
      menuListData.map((data, index) => {
        if (parseInt(data.id) === parseInt(relatedElement.id)) {
          fb.append("email_learn_obj[email_responses_attributes][][id]", data.id);
          fb.append(
            "email_learn_obj[email_responses_attributes][][response_order]",
            draggedCardOrder
          );
          data.attributes.response_order = draggedCardOrder;
        } else if (parseInt(data.id) === parseInt(draggedElement.id)) {
          fb.append("email_learn_obj[email_responses_attributes][][id]", data.id);
          fb.append(
            "email_learn_obj[email_responses_attributes][][response_order]",
            relatedCardOrder
          );
          data.attributes.response_order = relatedCardOrder;
        } else {
          fb.append("email_learn_obj[email_responses_attributes][][id]", data.id);
          fb.append(
            "email_learn_obj[email_responses_attributes][][response_order]",
            index + 1
          );
          data.attributes.response_order = index + 1;
        }
        return data;
      });
      api
        .reorderResponses(this.objectableId, fb)
        .then(() => {})
        .catch();
    },
    cloneLo() {
      utilFunctionService.showLoader();
      api
        .cloneObj(this.$route.params.id, this.$route.query.id)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(
            commonConfig.appMessages.loCloned
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showErr(commonConfig.appMessages.cloneLoError);
        });
    },
    saveResponseName(){
      if (this.selEmailResFormulas){
        let fb = { email_response: {name: this.selEmailResFormulas.attributes.name}}
        api
          .addEmailVariation(this.selEmailResFormulas.id, fb)
          .then(res => {
            this.selEmailResFormula.attributes.name = res.data.data.attributes.name;
          })
          .catch(() => {});
      }
    },
    restore() {
      if (this.step === 'overall-assessment') {
          this.updateOverallAssessment();
      }
      const fb = new FormData();
      fb.append("learn_mod_id", this.$route.params.id);
      fb.append("learning_object[status]", "published");
      fb.append("id", this.$route.query.id);
      api
        .restoreLo(this.$route.params.id, this.$route.query.id, fb)
        .then(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        })
        .catch(() => {
          this.$parent.card_type = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.LoPublichedText
          );
          this.$router.push(this.$route.params.id);
          window.location.reload();
        });

    },
    addDebriefVariation(isSelect) {
      if (!isSelect) {
        this.variations_briefattributes.push({ content: "" });
      } else {
        this.variationsSelect = isSelect;
      }
    },
    clearTesting() {
      this.testInput = "";
      this.testEvalJsonScheme = "";
      this.testEmailEnrichmentItems = "";
    },
    goToAssessment() {
      this.assessmentFormula = true;
      this.intentList(false);
      this.updateSkills();
    },
    backToAssessment() {
      this.assessmentFormula = false;
    },
    submitTesting() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("email_learn_obj_id", this.objectableId);
          fb.append("test_email_iteration[email]", this.testInput);
          api
            .emailTesting(fb)
            .then(res => {
              utilFunctionService.hideLoader();
              this.testEvalJsonScheme = res.data.data;
              this.testEmailEnrichmentItems = this.testEvalJsonScheme.attributes.enrichment_items;
            })
            .catch(() => {
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    },
    adaptiveContents(data) {
      if (this.type === "email") {
        const fb = new FormData();
        fb.append("content_type", data.tab);
        fb.append("adaptable_type", "EmailAssessmentItem");
        fb.append("adaptable_id", this.selectedAssmntItem.id);
        fb.append("adaptive_content[title]", data.name);
        fb.append("adaptive_content[description]", data.desc);
        if (data.tab === "text") fb.append("content[title]", data.text);
        if (data.tab === "file")
          fb.append("content[global_resource_id]", data.doc.id);
        if (data.tab === "video")
          fb.append("content[global_video_id]", data.video.id);
        if (data.tab === "slide")
          fb.append("content[has_caption]", data.imageCaption);
        data.slider.map((x, i) => {
          fb.append(
            "content[slider_images_attributes][" + i + "][caption]",
            x.title
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][global_resource_id]",
            parseInt(x.sliderId)
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][_destroy]",
            data.edit == false ? false : x.destroy == true ? true : false
          );
          if (x.id != undefined) {
            fb.append("content[slider_images_attributes][" + i + "][id]", x.id);
          }
        });
        if (data.edit) {
          api.editQuizAdaptiveContent(fb, data.id).then(() => {
            this.skillSetUp(false);
          });
        } else {
          api.saveQuizAdaptiveContent(fb, this.objectableId).then(() => {
            this.skillSetUp(false);
          });
        }
      }
    },
    interstitialContents(data) {
      if (this.type === "email") {
        const fb = new FormData();
        fb.append("content_type", data.tab);
        fb.append("interstitial_content[title]", data.name);
        fb.append("interstitial_content[description]", data.desc);
        if (data.tab === "text") fb.append("content[title]", data.text);
        if (data.tab === "file")
          fb.append("content[global_resource_id]", data.doc.id);
        if (data.tab === "video")
          fb.append("content[global_video_id]", data.video.id);
        if (data.tab === "slide")
          fb.append("content[has_caption]", data.imageCaption);
        data.slider.map((x, i) => {
          fb.append(
            "content[slider_images_attributes][" + i + "][caption]",
            x.title
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][global_resource_id]",
            parseInt(x.sliderId)
          );
          fb.append(
            "content[slider_images_attributes][" + i + "][_destroy]",
            data.edit == false ? false : x.destroy == true ? true : false
          );
          if (x.id != undefined) {
            fb.append("content[slider_images_attributes][" + i + "][id]", x.id);
          }
        });
        if (data.edit) {
          api.editInterstitialContent(fb, data.id).then(() => {
            this.getInterstitialContent();
          });
        } else {
          api.saveInterstitialContent(fb, this.objectableId).then(() => {
            this.getInterstitialContent();
          });
        }
      }
    },
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    next(name, key) {
      this.currentStep(name, key);
      this.step = name;
      if (name == "overall-assessment") {
        const fb = new FormData();
        fb.append("qa_condition[ooto_response]", this.qaResponse);
        api.postQaCondition(this.qaConditionId, fb).then(() => {});
      }
    },
    updateSynonymValues(status, index, synData) {
      if (status === "add") {
        const index = this.selectedSynonymList.attributes.asst_entity_val_synonyms.findIndex(
          elem => elem.attributes.synonym.toUpperCase() === this.entitySynonymName.toUpperCase()
          );
        if (index === -1) {
              this.selectedSynonymList.attributes.asst_entity_val_synonyms.push({
            attributes: {
              synonym: this.entitySynonymName,
              asst_entity_value_id: null,
            },
          });
          this.entitySynonymName = "";
        } else {
            utilFunctionService.showErr(commonConfig.appMessages.sameSynonymNameError);
        }
      }
      if (status === "delete") {
        this.selectedSynonymList.attributes.asst_entity_val_synonyms.splice(
          index,
          1
        );
        this.deletedSynonymsList.push(synData.id);
        this.deleteStatus = true;
      }

      if (status === "done") {
        let data = {
          asst_entity_value: {
            asst_entity_val_synonyms_attributes: [],
            value: this.selectedSynonymList.attributes.value
          }
        };
        this.selectedSynonymList.attributes.asst_entity_val_synonyms.forEach(
          ele => {
            if (!ele.attributes.asst_entity_value_id)
              data.asst_entity_value.asst_entity_val_synonyms_attributes.push({
                synonym: ele.attributes.synonym
              });
          }
        );
        if (this.deletedSynonymsList.length > 0) {
          this.deletedSynonymsList.forEach(e => {
            data.asst_entity_value.asst_entity_val_synonyms_attributes.push({
              id: e,
              _destroy: true
            });
          });
        }
        this.deleteStatus = false;
        api
          .updateAssessmentValues(this.selectedSynonymList.id, data)
          .then(() => {
            this.getEntityList(this.selectedAsstEntities);
            this.deletedSynonymsList = [];
            utilFunctionService.showSuccess(
              commonConfig.appMessages.assestEntityUpdated
            );
          })
          .catch((error) => {
              if (error.response.status == 422) {
                  if (error.response.data && error.response.data.error) {
                    utilFunctionService.showErr(error.response.data.error);
                  }
                }
            utilFunctionService.hideLoader();
          });
        this.$modal.hide("enityValueModal");
      }
    },
    updateAssessmentValuesPattern(status, index, synData) {
      if (status === "delete") {
        this.selectedPatternList.attributes.asst_entity_val_patterns.splice(
          index,
          1
        );
        this.deletedSynonymsList.push(synData.id);
        this.deleteStatus = true;
      }
      if (status === "add") {
        const index = this.selectedPatternList.attributes.asst_entity_val_patterns.findIndex(
          elem => elem.attributes.pattern.toUpperCase() === this.entityPatternName.toUpperCase()
        );
        if (index === -1) {
              this.selectedPatternList.attributes.asst_entity_val_patterns.push({
            attributes: {
              pattern: this.entityPatternName,
              asst_entity_value_id: null,
            },
          });
          this.entityPatternName = "";
        } else {
            utilFunctionService.showErr(commonConfig.appMessages.sameSynonymNameError);
        }
      }
      if (status === "done") {
        let data = {
          asst_entity_value: {
            asst_entity_val_patterns_attributes: [],
            value: this.selectedPatternList.attributes.value,
          },
        };
        this.selectedPatternList.attributes.asst_entity_val_patterns.forEach(
          (ele) => {
            if (!ele.attributes.asst_entity_value_id)
              data.asst_entity_value.asst_entity_val_patterns_attributes.push({
                pattern: ele.attributes.pattern,
              });
          }
        );
        if (this.deletedSynonymsList.length > 0) {
          this.deletedSynonymsList.forEach((e) => {
            data.asst_entity_value.asst_entity_val_patterns_attributes.push({
              id: e,
              _destroy: true,
            });
          });
        }
        this.deleteStatus = false;
        api
          .updateAssessmentValues(this.selectedPatternList.id, data)
          .then(() => {
            this.getEntityList(
              1,
              this.$route.query.id,
              true,
              this.selectedAsstEntities
            );
            this.deletedSynonymsList = [];
            utilFunctionService.showSuccess(
              commonConfig.appMessages.assestEntityUpdated
            );
          })
          .catch((error) => {
              if (error.response.status == 422) {
                  if (error.response.data && error.response.data.error) {
                    utilFunctionService.showErr(error.response.data.error);
                  }
                }
            utilFunctionService.hideLoader();
          });
        this.$modal.hide("enityValueModalPattern");
      }
    },
    handleSynonymModal(data) {
      this.selectedSynonymList = {};
      this.selectedSynonymList = JSON.parse(JSON.stringify(data));
      this.selectedAsstEntities = {};
      this.selectedAsstEntities = this.selectedSynonymList;
      this.entitySynonymName = "";
      this.deletedSynonymsList = [];
      this.$modal.show("enityValueModal");
    },
    handlePatternModal(data) {
      this.selectedPatternList = {};
      this.selectedPatternList = JSON.parse(JSON.stringify(data));
      this.selectedAsstEntities = {};
      this.selectedAsstEntities = this.selectedPatternList;
      this.entityPatternName = "";
      this.deletedSynonymsList = [];
      this.$modal.show("enityValueModalPattern");
    },
    removeAsstEntities(data) {
      utilFunctionService.showLoader();
      api
        .removeAssesSchemeValues(data.id)
        .then(() => {
          this.getEntityList(this.selectedAsstEntities);
          utilFunctionService.showSuccess(
            commonConfig.appMessages.assestEntityUpdated
          );
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    selectedEntent(entent) {
      this.selectedAsstEntities = entent;
    },
    addEntityValues() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.isActive = true;
          return;
        } else {
          utilFunctionService.showLoader();
          let data = {
            asst_entity_val_synonyms_attributes: [],
            value: ""
          };
          data.value = this.asstName;
          let value = this.asstSynonyms.split(",");
          value.forEach((ele, i) => {
            if (ele)
              data.asst_entity_val_synonyms_attributes[i] = { synonym: ele };
          });
          api
            .addAssessmentValues(this.selectedAsstEntities.id, data)
            .then(() => {
              this.asstName = "";
              this.asstSynonyms = "";
              this.isActive = false;
              utilFunctionService.showSuccess(
                commonConfig.appMessages.assestEntityAdd
              );
              utilFunctionService.hideLoader();
              this.getEntityList(this.selectedAsstEntities);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.sameSynonymList
              );
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    addAssessmentValues(e) {
      e.preventDefault();
      const entity_values = this.selectedAsstEntities.attributes.asst_entity_values
      let duplicatesPresent = false
      entity_values.forEach(entity_value => {
        let selectedSynonyms = entity_value.attributes.asst_entity_val_synonyms;
        selectedSynonyms.forEach(synonym => {
          if (synonym.attributes.synonym === this.asstSynonyms) {
            utilFunctionService.showErr(
              commonConfig.appMessages.duplicateSynonymList
            );
            duplicatesPresent = true
            this.isActive = false;
          }
        })
      })
        this.$refs.form.validate().then((success) => {
        if (!success || duplicatesPresent) {
          this.isActive = true;
          return;
        } else {
          utilFunctionService.showLoader();
          let data = {
            asst_entity_val_synonyms_attributes: [],
            value: "",
            is_pattern: false,
          };
          data.value = this.asstName;
          let value = this.asstSynonyms.split(",");
          value.forEach((ele, i) => {
            if (ele)
              data.asst_entity_val_synonyms_attributes[i] = { synonym: ele };
          });
          api
            .addAssessmentValues(this.selectedAsstEntities.id, data)
            .then(() => {
              this.asstName = "";
              this.asstSynonyms = "";
              this.isActive = false;
              utilFunctionService.showSuccess(
                commonConfig.appMessages.assestEntityAdd
              );
              utilFunctionService.hideLoader();
              this.getEntityList(
                1,
                this.$route.query.id,
                false,
                this.selectedAsstEntities.id
              );
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.sameSynonymList
              );
              this.isActive = false;
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    addAssessmentValuesPatterns(e) {
      e.preventDefault();
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.isActive = true;
          return;
        } else {
          utilFunctionService.showLoader();
          let data = {
            asst_entity_val_patterns_attributes: [],
            value: "",
            is_pattern: true,
          };
          data.value = this.asstName;
          let value = this.asstSynonyms;
          data.asst_entity_val_patterns_attributes[0] = { pattern: value };
          // let value = this.asstSynonyms.split(",");
          // value.forEach((ele, i) => {
          //   if (ele)
          //     data.asst_entity_val_patterns_attributes[i] = { pattern: ele };
          // });

          api
            .addAssessmentValues(this.selectedAsstEntities.id, data)
            .then(() => {
              this.asstName = "";
              this.asstSynonyms = "";
              this.isActive = false;
              utilFunctionService.showSuccess(
                commonConfig.appMessages.assestEntityAdd
              );
              utilFunctionService.hideLoader();
              this.getEntityList(
                1,
                this.$route.query.id,
                false,
                this.selectedAsstEntities.id
              );
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.sameSynonymList
              );
              this.isActive = false;
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    synonymModal(data) {
      this.selectedSynonymList = JSON.parse(JSON.stringify(data));
      this.entitySynonymName = "";
      this.deletedSynonymsList = [];
      this.$modal.show("enityValueModal");
    },
    addEmailEntity() {
      if (!this.emailEntityName) {
        utilFunctionService.showErr(commonConfig.appMessages.assestEntityErr);
      } else {
        const fb = new FormData();
        fb.append("asst_entity[name]", this.emailEntityName);
        api
          .addEmailEntity(this.$route.query.id, fb)
          .then(res => {
            this.selectedAsstEntities = res.data.data;
            this.emailEntityName = "";
            this.getEntityList(true);
          })
          .catch(() => {
            utilFunctionService.showErr(
              commonConfig.appMessages.entityNameValueError
            );
          });
      }
    },
    getEntityList(e) {
      api
        .entityLists(this.$route.query.id)
        .then(res => {
          this.step = this.activeState;
          this.asstEntitiesList = [];
          this.asstEntitiesList = res.data.data;
          if (this.asstEntitiesList.length) {
            if (!e) {
              this.selectedAsstEntities = this.asstEntitiesList[0];
            } else if (e == true) {
              this.selectedAsstEntities = this.asstEntitiesList[
                this.asstEntitiesList.length - 1
              ];
            } else {
              this.asstEntitiesList.forEach(ele => {
                if (ele.id === this.selectedAsstEntities.id)
                  this.selectedAsstEntities = ele;
              });
            }
            if (this.storeTab > this.activeEmailModule) {
              this.setActive(this.storeTab);
            } else {
              this.setActive(this.activeEmailModule);
            }
          }
          if (this.asstEntitiesList.length === 0) {
            this.asstEntitiesList = [];
            this.selectedAsstEntities = "";
            this.asstName = "";
            this.asstSynonyms = "";
            this.setActive(this.activeEmailModule);
          }
        })
        .catch(() => {});
    },
    deleteEntent(ententId) {
      api
        .destroyEntent(ententId)
        .then(() => {
          this.getEntityList(false);
        })
        .catch(() => {});
    },
    linkIntent(e, status) {
      utilFunctionService.showLoader();
      if (status === "sync_assistant")
        api
          .syncAssistantIntenties(this.$route.query.id)
          .then(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.syncAssistantSuccess
            );
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      else if (status === "dialog_skill")
        api
          .intentLinkToDialogSkill(this.$route.query.id)
          .then(res => {
            utilFunctionService.hideLoader();
            window.open(res.data.url, "_blank");
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      else {
        const csvFile = new FormData();
        csvFile.append("file", e.target.files[0]);
        api
          .importIntentCsv(this.$route.query.id, csvFile)
          .then(() => {
            utilFunctionService.showSuccess(commonConfig.appMessages.uploadCsv);
            utilFunctionService.hideLoader();
            this.getIntentList(false);
          })
          .catch(() => {
            utilFunctionService.showErr(commonConfig.appMessages.invalidCsv);
            utilFunctionService.hideLoader();
          });
      }
    },
    deleteIntentValue(values) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("asst_intent[name]", this.selectedAsstIntent.attributes.name);
      fb.append(
        "asst_intent[asst_intent_examples_attributes][][id]",
        values.id
      );
      fb.append(
        "asst_intent[asst_intent_examples_attributes][][_destroy]",
        true
      );
      api
        .addIntentData(this.selectedAsstIntent.id, fb)
        .then(() => {
          this.intentDesc = "";
          this.intentInput = "";
          this.getIntentList(this.selectedAsstIntent);
          utilFunctionService.showSuccess(
            commonConfig.appMessages.assestIntentValueDelete
          );
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    selectedIntent(intent) {
      this.selectedAsstIntent = intent;
      this.intentDesc = intent.attributes.description;
    },
    addIntentValues() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.isActive = true;
          return;
        } else {
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append(
            "asst_intent[name]",
            this.selectedAsstIntent.attributes.name
          );
          fb.append("asst_intent[description]", this.intentDesc);
          fb.append(
            "asst_intent[asst_intent_examples_attributes][][example]",
            this.intentInput
          );
          fb.append(
            "asst_intent[asst_intent_examples_attributes][][_destroy]",
            false
          );
          api
            .addIntentData(this.selectedAsstIntent.id, fb)
            .then(() => {
              this.intentDesc = "";
              this.intentInput = "";
              this.isActive = false;
              this.getIntentList(this.selectedAsstIntent);
              utilFunctionService.showSuccess(
                commonConfig.appMessages.assestIntentValueAdd
              );
              utilFunctionService.hideLoader();
            })
            .catch(() => {
              utilFunctionService.hideLoader();
            });
        }
      });
    },
    addEmailIntent() {
      if (!this.emailIntentName) {
        utilFunctionService.showErr(commonConfig.appMessages.assestIntentErr);
      } else {
        const fb = new FormData();
        fb.append("asst_intent[name]", this.emailIntentName);
        api
          .addEmailIntent(this.$route.query.id, fb)
          .then(res => {
            this.selectedAsstIntent = res.data.data;
            this.emailIntentName = "";
            this.getIntentList(true);
          })
          .catch(() => {
            utilFunctionService.showErr(
              commonConfig.appMessages.intentNameValueError
            );
          });
      }
    },
    getIntentList(e) {
        this.step = this.activeState;
        api
          .getIntentLists(this.$route.query.id)
          .then(res => {
            this.intentLists = [];
            this.intentDesc = "";
            this.intentInput = "";
            this.intentLists = res.data.data;
            if (this.intentLists.length > 0) {
              if (!e) {
                this.selectedAsstIntent = this.intentLists[0];
              } else if (e == true) {
                this.selectedAsstIntent = this.intentLists[
                  this.intentLists.length - 1
                ];
              } else {
                this.intentLists.forEach(ele => {
                  if (ele.id === this.selectedAsstIntent.id)
                    this.selectedAsstIntent = ele;
                });
              }
              this.intentDesc = this.selectedAsstIntent.attributes.description;
              if (this.storeTab > this.activeEmailModule) {
                this.setActive(this.storeTab);
              } else {
                this.storeTab = 1;
                this.setActive(this.activeEmailModule);
              }
            } else {
              this.intentLists = [];
              this.intentDesc = "";
              this.intentInput = "";
              this.selectedAsstIntent = "";
            }
          })
          .catch(() => {});
    },
    deleteIntent(intentId) {
      api
        .destroyIntent(intentId)
        .then(() => {
          this.selectedAsstEntities = {};
          this.getIntentList(false);
        })
        .catch(() => {});
    },
    handleCopy() {
      utilFunctionService.showSuccess(commonConfig.appMessages.jsonCopied);
    },
    currentStep(e, key) {
      console.log(e, "*** e in currentStep");
      console.log(this.storeTab, "*** this.storeTab in currentStep");
      this.step = e;
      this.assessmentFormula = false;
      this.activeState = e;
      this.activeEmailModule = key;
      this.loId = this.$route.query.id;
      if (e === "basic-setup") {
        this.storeTab = 0;
        if (this.storeTab > this.activeEmailModule) {
          this.setActive(this.storeTab);
        } else {
          this.setActive(this.activeEmailModule);
        }
      } else if (e === "intent-setup") {
        this.storeTab = 1;
        setTimeout(() => {
          this.getIntentList(false);
        }, 700);
      } else if (e === "entity-setup") {
        this.storeTab = 2;
        this.getEntityList(false);
      } else if (e === "response-formulas") {
        this.storeTab = 3;
        this.selPresentResFormula = null;
        this.selEmailResFormulas = null;
        this.selVariationList = null;
        this.allEmailResFormulas = [];
        this.emailResFormulas = [];
        this.setActive(key);
        this.intentList(false);
        this.markIntroActive();
        this.getEmailResList(true);
      } else if (e === "skill-setup") {
        this.storeTab = 4;
        this.setActive(key);
        this.getSkills();
      } else if (e === "email-testing") {
        this.storeTab = 5;
        this.testInput = "";
        this.setActive(key);
      } else if (e === "qa-and-interstitial") {
        this.storeTab = 6;
        this.qaResponse = "";
        this.setActive(key);
        this.intentList(false);
        this.getInterstitialContent();
      } else if (e === "overall-assessment") {
        this.storeTab = 7;
        this.skillDesc = "";
        this.setActive(key);
        this.getOverAllAssessments(true);
      } 
      console.log("*** this.storeTab at end of currentStep method", this.storeTab);
    },
    getSkills() {
      api.getSkills().then(res => {
        this.globalSkills = res.data.data;
        this.getEmailSkills();
      });
    },
    getEmailSkills() {
      api.getEmailSkills(this.objectableId).then(res => {
        this.emailSkills = res.data.data.map(x => ({
          id: x.id,
          skillName: "",
          skillId: x.attributes.global_skill_id,
          emailObjId: x.attributes.email_learn_obj_id,
          desc: x.attributes.eval_explanation
        }));
        this.emailSkills.forEach((element, i) => {
          const data = this.globalSkills.find(
            elem => elem.id == element.skillId
          );
          if (data && data.attributes) {
            this.emailSkills[i].skillName = data.attributes.name;
          }
        });
        if (this.emailSkills.length > 0) {
          this.selectedSkill = this.emailSkills[0];
          this.skillDesc = this.selectedSkill.desc;
          this.skillId = this.selectedSkill.skillId;
          this.index = 0;
          if (this.storeTab > this.activeEmailModule) {
            this.setActive(this.storeTab);
          } else {
            this.setActive(this.activeEmailModule);
          }
        }
      });
    },
    addNewSkill() {
      if (!this.newSkill) {
        utilFunctionService.showErr(commonConfig.appMessages.assestSkillErr);
      } else {
        const fb = new FormData();
        fb.append("global_skill[name]", this.newSkill);
        api.postNewSkills(fb).then(res => {
          this.newSkill = "";
          utilFunctionService.showSuccess(
            commonConfig.appMessages.newSkillAddedd
          );
          this.emailSkills.push({
            skillId: res.data.data.id,
            skillName: res.data.data.attributes.name,
            desc: ""
          });
        });
      }
    },
    selectedSkills(item, ind) {
      this.updateSkills();
      setTimeout(() => {
        this.skillDesc = item.desc;
        this.skillId = item.skillId;
        this.selectedSkill = item;
        this.index = ind;
      }, 400);
    },
    updateSkills() {
      let fb = new FormData();
      fb.append("email_skill[email_learn_obj_id]", this.objectableId);
      fb.append("email_skill[global_skill_id]", this.skillId);
      fb.append("email_skill[eval_explanation]", this.skillDesc);
      if (this.selectedSkill && this.selectedSkill.id) {
        api.updateNewSkills(fb, this.selectedSkill.id).then(res => {
          const i = this.emailSkills.findIndex(
            x => x.skillId == res.data.data.attributes.global_skill_id
          );
          this.emailSkills[i].desc = res.data.data.attributes.eval_explanation;
          if (this.assessmentFormula) {
            this.selectSkill = res.data.data;
            this.skillSetUp(true);
          }
        });
      } else {
        api.postSkillDesc(fb, this.objectableId).then(res => {
          const ind = this.emailSkills.findIndex(
            x => x.skillId == res.data.data.attributes.global_skill_id
          );
          this.emailSkills[ind].desc =
            res.data.data.attributes.eval_explanation;
          this.emailSkills[ind].id = res.data.data.id;
          if (this.assessmentFormula) {
            this.selectSkill = res.data.data;
            this.skillSetUp(true);
          }
        });
      }
    },
    addSkills(skill) {
      this.selectSkill = skill;
      let index = this.emailSkills.findIndex(x => x.skillId == skill.id);
      if (index == -1) {
        this.emailSkills.push({
          skillId: skill.id,
          skillName: skill.attributes.name,
          desc: ""
        });
      } else {
        utilFunctionService.showErr(commonConfig.appMessages.alreadyAddedSkill);
      }
    },
    saveDebriefVariations() {
      let fb = new FormData();
      if (this.variations_briefattributes.length > 0) {
        this.variations_briefattributes.forEach((ele, i) => {
          if (ele.content) {
            fb.append(
              "email_assessment_item[debriefs_attributes][][variation]",
              i + 1
            );
            fb.append(
              "email_assessment_item[debriefs_attributes][][content]",
              ele.content
            );
            fb.append(
              "email_assessment_item[debriefs_attributes][][_destroy]",
              false
            );
            if (ele.id) {
              fb.append(
                "email_assessment_item[debriefs_attributes][][id]",
                ele.id
              );
            }
          }
        });
      }
      fb.append(
        "email_assessment_item[points]", this.assessmentPoints
      );
      api.updateAssessmentVariation(this.selectedAssmntItem.id, fb).then(() => {
      });
    },
    selectAssessmentItem(data) {

        this.skillSetUp(false);
        this.emailItem(data);

    },
    emailItem(data) {
      this.variations_briefattributes = [];
      this.selectedAssmntItem = data;
      this.assessmentPoints = data.attributes.points;
      this.adaptiveContentList = data.attributes.adaptive_contents;
      this.setAssessmentFormula(data.attributes.assessment_formulas);
      if (data && data.attributes.debrief_contents.length > 0) {
        data.attributes.debrief_contents.forEach(ele => {
          this.variations_briefattributes.push({
            content: ele.attributes.content,
            id: ele.id
          });
        });
      }
    },
    compareObjects(a, b) {
      if (
        a.attributes.assessment_label_order <
        b.attributes.assessment_label_order
      ) {
        return -1;
      }
      if (
        a.attributes.assessment_label_order >
        b.attributes.assessment_label_order
      ) {
        return 1;
      }
      return 0;
    },
    skillSetUp(updated) {
      api.getEmailAssessmentSkill(this.selectedSkill.id).then(res => {
        this.emailAssessmentSkill = res.data.data.sort(this.compareObjects);
        if (updated) {
          this.selectedAssmntItem = this.emailAssessmentSkill[0];
          this.emailItem(this.emailAssessmentSkill[0]);

          if (this.emailAssessmentSkill[0] != undefined) {
            this.adaptiveContentList = this.emailAssessmentSkill[0].attributes.adaptive_contents;
            this.setAssessmentFormula(
              this.emailAssessmentSkill[0].attributes.assessment_formulas
            );
          }
        } else {
          let filterData = this.emailAssessmentSkill.filter(
            x => x.id == this.selectedAssmntItem.id
          );
          this.adaptiveContentList = filterData[0].attributes.adaptive_contents;
          this.setAssessmentFormula(
            filterData[0].attributes.assessment_formulas
          );
        }
      });
    },
    removeAdaptiveData(id) {
      utilFunctionService.showLoader();
      api.deleteAdaptiveContent(id).then(() => {
        utilFunctionService.hideLoader();
        this.skillSetUp(false);
      });
    },
    removeInterstitialData(id) {
      utilFunctionService.showLoader();
      api.deleteInterstitialContent(id).then(() => {
        utilFunctionService.hideLoader();
        this.getInterstitialContent();
      });
    },
    getInterstitialContent() {
      this.emailSelCharId = this.selectedChar[0].id;
      this.qaConditionId = this.qaConditions[0].id;
      api.interstitialContent(this.objectableId).then(res => {
        this.interstitialLists = res.data.data;
        if (this.interstitialLists.length > 0) {
          this.setActive(this.storeTab);
        }
      });
      this.getQaRes(this.selectedChar[0].id);
    },
    getQaRes(id) {
      api.qaFormula(this.objectableId, id).then(res => {
        this.qaResponse = res.data.data[0].attributes.ooto_response;
        this.setQaResFormula(res.data.data[0].attributes.qa_formulas);
      });
    },
    getQaId(id, charId) {
      this.emailSelCharId = charId;
      this.emailQaResFormulas = [];
      const fb = new FormData();
      fb.append("qa_condition[ooto_response]", this.qaResponse);
      api.postQaCondition(this.qaConditionId, fb).then(() => {
        api.getQaCondition(this.qaConditionId).then(res => {
          this.qaResponse = res.data.data.attributes.ooto_response;
        });
      });
      this.getQaRes(charId);
      this.qaConditionId = id;
    },
    getOverAllAssessments(isUpdated = true) {
      api
        .getOverallAssmntItem(this.$route.query.id)
        .then(res => {
          this.overallAssmntItem = res.data.data.sort(this.sortOrder);
          if (this.overallAssmntItem.length === 0) {
            this.assessment_scheme_id &&
              api
                .getAssessmentScheme(this.assessmentSchemeId)
                .then(res => {
                  this.generateOverAllAssessment(
                    res.data.data.attributes.assessment_labels
                  );
                  api
                    .getOverallAssmntItem(this.$route.query.id)
                    .then(res2 => {
                      this.overallAssmntItem = res2.data.data.sort(
                        this.sortOrder
                      );
                      this.selectedOverallAssmntItem = this.overallAssmntItem[0];
                      this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
                      this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
                      this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
                    })
                    .catch(() => {
                      utilFunctionService.hideLoader();
                    });
                })
                .catch(() => {
                  utilFunctionService.hideLoader();
                });
          } else {
            if (isUpdated) {
              this.selectedOverallAssmntItem = this.overallAssmntItem[0];
              this.overallAssmntItemFeedback = this.overallAssmntItem[0].attributes.feedback;
              this.overallAssmntItemMaxScore = this.overallAssmntItem[0].attributes.max_score;
              this.overallAssmntItemMinScore = this.overallAssmntItem[0].attributes.min_score;
            }
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    sortOrder(a, b) {
      if (a > b) return 1;
      if (b > a) return -1;

      return 0;
    },
    handleOverallAssmntItem(data) {
      this.updateOverallAssessment();
      setTimeout(() => {
        this.selectedOverallAssmntItem = data;
        this.overallAssmntItemFeedback = this.selectedOverallAssmntItem.attributes.feedback;
        this.overallAssmntItemMaxScore = this.selectedOverallAssmntItem.attributes.max_score;
        this.overallAssmntItemMinScore = this.selectedOverallAssmntItem.attributes.min_score;
      }, 200);
    },
    updateOverallAssessment() {
      this.$refs.form.validate().then(success => {
        if (
          !this.overallAssmntItemFeedback &&
          !this.overallAssmntItemMaxScore &&
          !this.overallAssmntItemMaxScore
        ) {
          this.isActive = true;
          return;
        }
        if (!success) {
          this.isActive = true;
          return;
        } else {
          let fb = new FormData();
          fb.append(
            "overall_assmnt_item[feedback]",
            this.overallAssmntItemFeedback
          );
          fb.append(
            "overall_assmnt_item[max_score]",
            this.overallAssmntItemMaxScore
          );
          fb.append(
            "overall_assmnt_item[min_score]",
            this.overallAssmntItemMinScore
          );
          api
            .updateOverallAssesScheme(this.selectedOverallAssmntItem.id, fb)
            .then(() => {
              this.getOverAllAssessments(false);
            })
            .catch(() => {
              utilFunctionService.showErr(
                commonConfig.appMessages.feedbackUpdateErr
              );
            });
        }
      });
    },
    generateOverAllAssessment(data) {
      data.forEach(ele => {
        let fb = new FormData();
        fb.append("overall_assmnt_item[assessment_label_id]", ele.id);
        fb.append("overall_assmnt_item[feedback]", "");
        fb.append("overall_assmnt_item[max_score]", 0);
        fb.append("overall_assmnt_item[min_score]", 0);
        api
          .createOverallAssesScheme(this.$route.query.id, fb)
          .then(() => {})
          .catch(() => {
            utilFunctionService.showErr();
          });
      });
    },
    setActive(key) {
      this.isActiveTab = [];
      for (let i = 0; i <= key; i++) this.isActiveTab.push("active");
    },
    emptyTabs() {
      this.isActiveTab = [];
      if (this.name == "" || this.description == "") {
        for (let i = 0; i <= this.activeEmailModule - 1; i++)
          this.isActiveTab.push("active");
          utilFunctionService.showErr(commonConfig.appMessages.updateTabError);
      } else if (this.intentLists.length === 0) {
        for (let i = 0; i <= this.activeEmailModule - 1; i++)
          this.isActiveTab.push("active");
          utilFunctionService.showErr(commonConfig.appMessages.updateTabError);
          this.activeEmailModule = 1;
      }
    },
    sortByOrder(a, b) {
      if (a.attributes.order_num < b.attributes.order_num) {
        return -1;
      }
      if (a.attributes.order_num > b.attributes.order_num) {
        return 1;
      }
      return 0;
    },
    basicSetupUpdate() {
      if (!this.$route.query.id) {
        console.log("*** hit basicSetupUpdate in interactiveEmailLo.vue");
        console.log("*** this.$route.query.id: ", this.$route.query.id);
        this.searchCharacters = this.characters;
        this.charactersList = this.characters;
        this.searchOptionalCharacters = this.characters;
        this.clearForm();
      } else {
        this.setActive(0);
        this.step = "basic-setup";
        this.setFieldValue();
      }
    },
    setFieldValue() {
      if (this.emailInteractiveData.id) {
        this.isActiveTab = [];
        this.status = this.emailInteractiveData.attributes.status;
        this.searchCharacters = this.characters;
        this.charactersList = this.characters;
        this.searchOptionalCharacters = this.characters;
        this.editEmailloId = this.emailInteractiveData.id;
        this.objectableId = this.emailInteractiveData.attributes.objectable_id;
        this.name = this.emailInteractiveData.attributes.name;
        this.adminNotes = this.emailInteractiveData.attributes.admin_notes;
        this.description = this.emailInteractiveData.attributes.description;
        this.competencyCode = this.emailInteractiveData.attributes.competency_code || "";
        this.iterationLevel = this.emailInteractiveData.attributes.card_detail.attributes.iteration_level;
        this.savedIterationLevel =  this.iterationLevel;
        this.learnerExplanation = this.emailInteractiveData.attributes.card_detail.attributes.iteration_explanation;
        this.enableLearnerIteration = this.emailInteractiveData.attributes.card_detail.attributes.iteration_enabled;
        this.displayOverallAssessment = this.emailInteractiveData.attributes.card_detail.attributes.display_overall_assessment;
        this.newIterationLogic = this.emailInteractiveData.attributes.card_detail.attributes.new_iteration_logic;
        this.hideAssessmentLabel = this.emailInteractiveData.attributes.card_detail.attributes.hide_assessment_label;
        this.enableLearnerRetries = this.emailInteractiveData.attributes.card_detail.attributes.enable_reattempt;
        this.maxNumberOfRetries = this.emailInteractiveData.attributes.card_detail.attributes.max_reattempts;
        this.overallInteraction = this.emailInteractiveData.attributes.overall_assessment_required;
        this.introEmailStatus = this.emailInteractiveData.attributes.card_detail.attributes.email_intro_enabled;
        this.introEmailSubject = this.emailInteractiveData.attributes.card_detail.attributes.intro_subject;
        this.introEmailBody = this.emailInteractiveData.attributes.card_detail.attributes.intro_body;
        const attachments = this.emailInteractiveData.attributes.card_detail.attributes.intro_attachment;
        if (attachments.length > 0)
          this.docs.push(attachments[0])
        this.assessmentSchemeId = this.emailInteractiveData.attributes.assessment_scheme_id;
        let QAConditions = this.emailInteractiveData.attributes.card_detail.attributes.qa_conditions
        this.qaConditions = QAConditions.reverse();
        this.getSelectedAssessment(
          this.emailInteractiveData.attributes.assessment_scheme_id
        );
        if (
          this.emailInteractiveData.attributes.card_detail.attributes
            .to_character_ids &&
          this.emailInteractiveData.attributes.card_detail.attributes
            .to_character_ids.length > 0
        ) {
          this.getCharacterData(
            this.emailInteractiveData.attributes.card_detail.attributes
              .to_characters
          );
        }
        if (
          this.emailInteractiveData.attributes.card_detail.attributes
            .cc_character_ids &&
          this.emailInteractiveData.attributes.card_detail.attributes
            .cc_character_ids.length > 0
        ) {
          this.selectedCcChar = this.emailInteractiveData.attributes.card_detail.attributes.cc_characters.map(
            x => ({
              id: x.id,
              status: true,
              world_role: x.attributes.world_role,
              org_name: x.attributes.org_name,
              // char_photo_url: x.attributes.char_photo_url.thumbnail,
              char_full_name: x.attributes.char_full_name,
              char_gender: x.attributes.char_gender,
              char_age: x.attributes.char_age
            })
          );
          this.selectedTabChar = this.selectedCcChar[0];
        }
        this.selPresentResFormula = null;
        this.selEmailResFormulas = null;
        this.selVariationList = null;
        this.allEmailResFormulas = [];
        this.emailResFormulas = [];
        this.entityList();
        this.getEmailResList(true);
        setTimeout(() => {
          // if (this.asstEntitiesList.length > 0)
          this.getSkills();
        }, 500);
        setTimeout(() => {
          if (this.emailSkills.length > 0) this.getInterstitialContent();
        }, 600);
        this.storeTab = 0;
        this.setActive(this.storeTab);
      }
    },
    entityList() {
      api.entityLists(this.$route.query.id).then(res => {
        this.asstEntitiesList = [];
        this.asstEntitiesList = res.data.data;
        if (this.asstEntitiesList.length) {
          this.setActive(this.storeTab);
        } else if (this.asstEntitiesList.length == 0) {
          this.intentList();
        }
      });
    },
    intentList(status = true) {
      api.getIntentLists(this.$route.query.id).then(res => {
        this.intentLists = [];
        this.intentLists = res.data.data;
        if (status) {
          if (this.intentLists.length > 0) {
            this.setActive(this.storeTab);
          } 
        }
      });
    },
    addEmailChar(e) {
      if (this.selectedChar.length <= 4) {
        this.selectedChar.push(e);
        this.searchCharacter = "";
        this.searchCharacters = [];
      } else {
        utilFunctionService.showErr(commonConfig.appMessages.characterLimit);
      }
    },
    addEmailCcChar(e) {
      if (this.selectedCcChar.length <= 4) {
        this.selectedCcChar.push(e);
        this.searchCcCharacter = "";
        this.searchOptionalCharacters = [];
      } else {
        utilFunctionService.showErr(commonConfig.appMessages.characterLimit);
      }
    },
    deleteSeletedChar(i) {
      this.selectedChar.splice(i, 1);
    },
    deleteSeletedCcChar(i) {
      this.selectedCcChar.splice(i, 1);
    },
    getCharacters() {
      api
        .getWorldCharacters(this.worldId)
        .then(res => {
          this.searchCharacters = [];
          this.characters = [];
          this.characters = res.data.data.map(x => ({
            id: x.id,
            world_role: x.attributes.world_role,
            org_name: x.attributes.org_name,
            // char_photo_url: x.attributes.char_photo_url.thumbnail,
            char_full_name: x.attributes.char_full_name,
            char_gender: x.attributes.char_gender,
            char_age: x.attributes.char_age
          }));
          this.searchCharacters = this.characters;
          this.charactersList = this.characters;
          this.searchOptionalCharacters = this.characters;
        })
        .catch();
    },
    filterCharacter() {
      this.charStatus = true;
      if (this.searchCharacter.length !== 0) {
        if (this.selectedChar.length > 0) {
          this.characters.forEach(char => {
            char.status = false;
          });
          this.selectedChar.forEach(element => {
            const index = this.characters.findIndex(
              elem => elem.id === element.id
            );
            if (index !== -1) {
              this.characters[index].status = true;
            } else {
              this.characters[index].status = false;
            }
          });

          this.searchCharacters = this.characters.filter(char => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase())
            );
          });
        } else {
          this.characters.forEach(char => {
            char.status = false;
          });

          this.searchCharacters = this.characters.filter(char => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCharacter.toLowerCase())
            );
          });
        }
      } else {
        return (this.searchCharacters = this.characters);
      }
    },
    filterCcCharacter() {
      this.optionalCharStatus = true;
      if (this.searchCcCharacter.length !== 0) {
        if (this.selectedCcChar.length > 0) {
          this.charactersList.forEach(char => {
            char.status = false;
          });

          this.selectedCcChar.forEach(element => {
            const index = this.charactersList.findIndex(
              elem => elem.id === element.id
            );
            if (index !== -1) {
              this.charactersList[index].status = true;
            } else {
              this.charactersList[index].status = false;
            }
          });

          this.searchOptionalCharacters = this.charactersList.filter(char => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase())
            );
          });
        } else {
          this.charactersList.forEach(char => {
            char.status = false;
          });

          this.searchOptionalCharacters = this.charactersList.filter(char => {
            return (
              char.char_full_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.world_role
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase()) ||
              char.org_name
                .toLowerCase()
                .includes(this.searchCcCharacter.toLowerCase())
            );
          });
        }
      } else {
        return (this.searchOptionalCharacters = this.charactersList);
      }
    },
    selectedAssessment(event) {
      let value = event.target.value.split("*");
      this.assessmentListKey = { name: value[1], id: value[0] };
    },
    saveEmaillo(type, key) {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const fb = new FormData();
          fb.append("learning_object[status]", type);
          fb.append("learning_object[description]", this.description);
          fb.append(
            "learning_object[overall_assessment_required]",
            this.overallInteraction
          );
          fb.append("learning_object[name]", this.name);
          fb.append("learning_object[admin_notes]", this.adminNotes);
          fb.append("learning_object[competency_code]", this.competencyCode);
          fb.append("card_type", "email");
          fb.append("card[iteration_enabled]", this.enableLearnerIteration);
          fb.append("card[display_overall_assessment]", this.displayOverallAssessment);
          fb.append("card[new_iteration_logic]", this.newIterationLogic);
          fb.append("card[hide_assessment_label]", this.hideAssessmentLabel);
          fb.append("card[enable_reattempt]", this.enableLearnerRetries);
          fb.append("card[max_reattempts]", this.maxNumberOfRetries);
          fb.append("card[iteration_level]", this.iterationLevel);
          fb.append("card[iteration_explanation]", this.learnerExplanation);
          fb.append("learning_object[learning_object_type]", "interaction");
          fb.append(
            "learning_object[assessment_scheme_id]",
            this.assessmentListKey.id
          );
          this.selectedChar.map(x => {
            fb.append("card[to_character_ids][]", parseInt(x.id));
          });
          this.selectedCcChar.map(x => {
            fb.append("card[cc_character_ids][]", parseInt(x.id));
          });
          fb.append("card[email_intro_enabled]", this.introEmailStatus);

          if (this.introEmailStatus) {
             fb.append("card[intro_subject]", this.introEmailSubject);
             fb.append("card[intro_body]", this.introEmailBody);
             if (this.docs.length > 0) {
               fb.append("card[intro_attachment_id]", this.docs[0].id);
             }
          }

          if (this.$route.query.id) {
            api
              .updateLo(this.id, this.editEmailloId, fb)
              .then(res => {
                this.loId = res.data.data.id;
                utilFunctionService.hideLoader();
                this.objectableId = res.data.data.attributes.objectable_id;
                this.activeEmailModule = key;
                this.qaConditions =
                  res.data.data.attributes.card_detail.attributes.qa_conditions;
                this.assessmentSchemeId =
                  res.data.data.attributes.assessment_scheme_id;
                this.iterationLevel =
                  res.data.data.attributes.card_detail.attributes.iteration_level;
                this.savedIterationLevel = this.iterationLevel;
                if (
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids &&
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids.length > 0
                ) {
                  this.getCharacterData(
                    res.data.data.attributes.card_detail.attributes
                      .to_characters
                  );
                }
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showErr("Internal Server Error");
              });
          } else {
            api
              .createLearningObject(this.id, fb)
              .then(res => {
                utilFunctionService.hideLoader();
                this.storeTab = 0;
                this.loId = res.data.data.id;
                this.qaConditions =
                  res.data.data.attributes.card_detail.attributes.qa_conditions;
                this.$route.query.id = this.loId;
                this.objectableId = res.data.data.attributes.objectable_id;
                this.assessmentSchemeId =
                  res.data.data.attributes.assessment_scheme_id;
                this.activeEmailModule = key;
                this.iterationLevel =
                  res.data.data.attributes.card_detail.attributes.iteration_level;
                this.savedIterationLevel = this.iterationLevel;
                api.createOverAllAssessment(this.objectableId).then(() => {});
                if (
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids &&
                  res.data.data.attributes.card_detail.attributes
                    .to_character_ids.length > 0
                ) {
                  this.getCharacterData(
                    res.data.data.attributes.card_detail.attributes
                      .to_characters
                  );
                }
              })
              .catch(() => {
                utilFunctionService.hideLoader();
                this.$emit("updateSubmissionList", false);
              });
          }
        }
      });
    },
    getSelectedAssessment(id) {
      if (this.assessmentLists) {
        this.assessmentLists.forEach(e => {
          if (id == e.id) {
            this.assessmentListKey = { name: e.attributes.name, id: e.id };
          }
        });
      }
    },
    getEmailResList(status) {
      api.getEmailResList(this.objectableId).then(res => {
        if (res.data.data.length > 0) {
          this.emailResFormulas = [];
          if (this.storeTab > this.activeEmailModule) {
            this.setActive(this.storeTab);
          } else {
            this.setActive(this.activeEmailModule);
          }
          this.allEmailResFormulas = res.data.data;
          this.allEmailResFormulas.forEach(e => {
            if (parseInt(this.emailSelChar.id) === e.attributes.character.id)
              this.emailResFormulas.push(e);
          });
          if (status) this.selEmailResFormulas = this.allEmailResFormulas[0];
          else {
            if (this.selEmailResFormulas) {
              let index = this.getAllIndexes(
                this.allEmailResFormulas,
                this.selEmailResFormulas.id,
                true
              );
              this.selEmailResFormulas = this.allEmailResFormulas[index[0]];
            } else this.selEmailResFormulas = this.allEmailResFormulas[0];
          }
        }
        if (this.selEmailResFormulas) {
          setTimeout(
            () =>
              this.setResFormula(
                this.selEmailResFormulas.attributes.response_formulas
              ),
            1000
          );
          this.getselVariationList(
            this.selEmailResFormulas.attributes.char_response_variations.sort(
              this.sortVariation
            )
          );
        }
      });
    },
    sortVariation(a, b) {
      if (a.attributes.variation < b.attributes.variation) {
        return -1;
      }
      if (a.attributes.variation > b.attributes.variation) {
        return 1;
      }
      return 0;
    },
    setResFormula(data) {
      this.selPresentResFormula = [];
      data.forEach((e, index) => {
        if (!this.selPresentResFormula[index])
          this.selPresentResFormula[index] = {};
          this.selPresentResFormula[index]["ques_id"] = e.id;
          this.selPresentResFormula[index]["absent_min"] =
            e.attributes.absent_cond_keyword_min;
          this.selPresentResFormula[index]["present_min"] =
            e.attributes.present_cond_keyword_min;
          this.selPresentResFormula[index]["present"] = {};
          this.selPresentResFormula[index]["absent"] = {};
          e.attributes.formula_asst_intents &&
            this.setFormFieldValues(
              e.attributes.formula_asst_intents,
              index,
              "intent",
              "asst_intent"
            );
          e.attributes.formula_asst_entity_values &&
            this.setFormFieldValues(
              e.attributes.formula_asst_entity_values,
              index,
              "Assistant-Entity",
              "asst_intent"
            );
      });
    },
    setQaResFormula(data) {
      this.selPresentResFormula = [];
      data.forEach((e, index) => {
        if (!this.selPresentResFormula[index])
          this.selPresentResFormula[index] = {};
          this.selPresentResFormula[index]["ques_id"] = e.id;
          this.selPresentResFormula[index]["absent_min"] =
            e.attributes.absent_cond_keyword_min;
          this.selPresentResFormula[index]["present_min"] =
            e.attributes.present_cond_keyword_min;
          this.selPresentResFormula[index]["present"] = {};
          this.selPresentResFormula[index]["absent"] = {};
          e.attributes.qa_formula_asst_intents &&
            this.setFormFieldValues(
              e.attributes.qa_formula_asst_intents,
              index,
              "intent",
              "asst_intent"
            );
          e.attributes.qa_formula_asst_entity_values &&
            this.setFormFieldValues(
              e.attributes.qa_formula_asst_entity_values,
              index,
              "Assistant-Entity",
              "asst_intent"
            );
      });
    },
    setAssessmentFormula(data) {
      this.selPresentResFormula = [];
      data.forEach((e, index) => {
        if (!this.selPresentResFormula[index])
          this.selPresentResFormula[index] = {};
          this.selPresentResFormula[index]["ques_id"] = e.id;
          this.selPresentResFormula[index]["absent_min"] =
            e.attributes.absent_cond_keyword_min;
          this.selPresentResFormula[index]["present_min"] =
            e.attributes.present_cond_keyword_min;
          this.selPresentResFormula[index]["present"] = {};
          this.selPresentResFormula[index]["absent"] = {};
          e.attributes.assessment_formula_asst_intents &&
            this.setFormFieldValues(
              e.attributes.assessment_formula_asst_intents,
              index,
              "intent",
              "asst_intent"
            );
          e.attributes.assessment_formula_asst_entity_values &&
            this.setFormFieldValues(
              e.attributes.assessment_formula_asst_entity_values,
              index,
              "Assistant-Entity",
              "asst_intent"
            );
      });
    },
    setFormFieldValues(data, index, name, key, status = false) {
      if (status) {
        if (!this.selPresentResFormula[index]["present"][name])
          this.selPresentResFormula[index]["present"][name] = [];
        this.selPresentResFormula[index]["present"][name][0] = {
          id: 0,
          name: name,
          value: data ? "enabled" : "disabled",
          isAdded: true,
          data
        };
      } else {
        data.forEach(ele => {
          if (!ele.attributes.present_cond) {
            if (!this.selPresentResFormula[index]["absent"][name])
              this.selPresentResFormula[index]["absent"][name] = [];
            this.selPresentResFormula[index]["absent"][name].push({
              id: ele.id,
              name: key,
              value: ele.attributes[key],
              isAdded: true,
              data
            });
          } else {
            if (!this.selPresentResFormula[index]["present"][name])
              this.selPresentResFormula[index]["present"][name] = [];
            this.selPresentResFormula[index]["present"][name].push({
              id: ele.id,
              name: key,
              value: ele.attributes[key],
              isAdded: true,
              data
            });
          }
        });
      }
    },
    isActiveResponse(res){
      return !this.addIntro && !this.addConclusion
             && this.selEmailResFormulas.id === res.id
    },
    markIntroActive(){
      this.addIntro = true;
      this.selEmailResFormulas = null;
      this.addConclusion = false;
    },
     markConclusionActive(){
      this.addIntro = false;
      this.addConclusion = true
    },
     changeBackIteration(){
      this.iterationLevel = this.savedIterationLevel;
     },

     displayReduceIterationModal() {
      if( this.iterationLevel < this.savedIterationLevel) {
          this.$modal.show("ReduceIteration");
      }
    },
    deleteResponse() {
      utilFunctionService.showLoader();
      api
        .deleteEmailResponse(this.selEmailResFormulas.id)
        .then(() => {
          utilFunctionService.hideLoader();
          this.getEmailResList(true);
        })
    },
    handleSelEmailResFormulas(data) {
      this.addIntro = false;
      this.addConclusion = false;

      this.selEmailResFormulas = data;
      this.setResFormula(this.selEmailResFormulas.attributes.response_formulas);
      this.getselVariationList(
        data.attributes.char_response_variations.sort(this.sortVariation)
      );
      // Set to Default(1) Iteration and Variation ...
      this.selIterationLevel = 1;
      if (this.selVariationList){
        this.selectedVariation = this.selVariationList[0];
        this.variationName = this.selectedVariation.attributes.response;
      } else{
        this.variationName = "";
      }

    },
    addEmailResName(e) {
      e.preventDefault();
      if (this.responseName) {
        let fb = new FormData();
        fb.append("email_response[name]", this.responseName);
        fb.append("email_response[character_id]", this.emailSelChar.id);
        api.postEmailResName(this.objectableId, fb).then(() => {
          this.getEmailResList(false);
          this.responseName = "";
        });
      } else {
        utilFunctionService.showErr(commonConfig.appMessages.addResFormula);
      }
    },
    addVariation() {},
    clearForm() {
      this.status = 'drafted';
      this.name = "";
      this.adminNotes = "";
      this.selectedChar = [];
      this.selectedCcChar = [];
      this.searchCharacters = "";
      this.searchCcCharacter = "";
      this.description = "";
      this.iterationLevel = 1;
      this.enableLearnerIteration = "";
      this.displayOverallAssessment = "",
      this.newIterationLogic = true;
      this.competencyCode = "";
      this.hideAssessmentLabel = false;
      this.overallInteraction = false;
      this.enableLearnerRetries = false,
      this.maxNumberOfRetries = 0,
      this.learnerExplanation =
        "This email interaction provided multiple opportunities to achieve the highest overall assessment. This number indicates how many iterations you went through on this interaction.";
      this.step = "basic-setup";
      this.isActive = false;
      this.optionalCharStatus = false;
      this.assessmentListKey = { name: "None", id: null };
      this.searchCharacters = [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          // char_photo_url: "",
          char_age: "",
          char_gender: "",
          status: false
        }
      ];
      this.searchOptionalCharacters = [
        {
          id: "",
          world_role: "",
          org_name: "",
          char_full_name: "",
          // char_photo_url: "",
          char_age: "",
          char_gender: ""
        }
      ];
      this.message = "";
      this.index = 0;
      this.objectableId = "";
      this.isActiveTab = ["active"];
      this.value = "";
      this.emailIntentName = "";
      this.intentLists = [];
      this.selectedAsstIntent = {};
      this.intentDesc = "";
      this.intentInput = "";
      this.emailEntityName = "";
      this.selectedAsstEntities = {};
      this.asstEntitiesList = [];
      this.asstName = "";
      this.asstSynonyms = "";
      this.selectedSynonymList = null;
      this.entitySynonymName = "";
      this.activeState = "";
      this.activeKey = "";
      this.skillDesc = "";
      this.setActive(0);
      this.responseFormulaId = "";
    },
    linkEntitiesData(e, status) {
      utilFunctionService.showLoader();
      if (status === "sync_assistant")
        api
          .syncAssistantEntities(this.$route.query.id)
          .then(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              commonConfig.appMessages.syncAssistantSuccess
            );
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
      else if (status === "dialog_skill")
        api
          .linkToDialogSkill(this.$route.query.id)
          .then(res => {
            utilFunctionService.hideLoader();
            window.open(res.data.url, "_blank");
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
        else if (status === "exportCsv"){
          api
          .exportCsv(this.$route.query.id)
          .then(response => {
            let res = response.data.csv_data;
            let fileName = response.data.filename;
            const url = window.URL.createObjectURL(new Blob([res], {type: 'text/csv'}))
            var link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            utilFunctionService.hideLoader();
          })
          .catch(() => {
            utilFunctionService.hideLoader();
          });
        }
      else {
        const data = new FormData();
        data.append("file", e.target.files[0]);
        api
          .importCsv(this.$route.query.id, data)
          .then(() => {
            utilFunctionService.showSuccess(commonConfig.appMessages.uploadCsv);
            utilFunctionService.hideLoader();
            this.getEntityList(false);
          })
          .catch(() => {
            utilFunctionService.showErr(commonConfig.appMessages.invalidCsv);
            utilFunctionService.hideLoader();
          });
      }
    },
    handleResFormula(field) {
      this.setSelFormulaField = field;
      //this.selectedEvalVal = this.enrichments[field];
      if (field === "assist-entity")
        this.selectedEvalVal = this.asstEntitiesList;
      if (field === "nlu_intent") 
        this.selectedEvalVal = this.intentLists;
    },
    handleEvaluationDrop(data, status) {
      if (status === "present") {
        let selectedPresentFormula = JSON.parse(
            JSON.stringify(this.selectedPresentFormula)
          ),
          type = data.type.split("_"),
          index;
          console.log(selectedPresentFormula, "*** selectedPresentFormula in handleEvaluationDrop");
        if (!selectedPresentFormula[type[1]])
          selectedPresentFormula[type[1]] = [];
        index = this.getAllIndexes(selectedPresentFormula[type[1]], data.id);
        if (index.length === 0) {
          this.selectedPresentFormula = {};
          if (data.type === "nlu_Assistant-Entity") {
            selectedPresentFormula[type[1]].push({
              id: data.id,
              name: `${type[1]}`,
              value: data.attributes.value,
              asst_entity_id: data.asst_entity_id
            });
          } else {
            selectedPresentFormula[type[1]].push({
              id: data.id,
              name: type[1],
              value: data.attributes[type[1]] || data.attributes.name
            });
          }
        }
        this.selectedPresentFormula = selectedPresentFormula;
      }
      if (status === "absent") {
        let selectedAbsentFormula = JSON.parse(
            JSON.stringify(this.selectedAbsentFormula)
          ),
          type = data.type.split("_"),
          index;
        if (!selectedAbsentFormula[type[1]])
          selectedAbsentFormula[type[1]] = [];
        index = this.getAllIndexes(selectedAbsentFormula[type[1]], data.id);
        if (index.length === 0) {
          this.selectedAbsentFormula = {};
          if (data.type === "nlu_Assistant-Entity") {
            selectedAbsentFormula[type[1]].push({
              id: data.id,
              name: `${type[1]}`,
              value: data.attributes.value,
              asst_entity_id: data.asst_entity_id
            });
          } else {
            selectedAbsentFormula[type[1]].push({
              id: data.id,
              name: type[1],
              value: data.attributes[type[1]] || data.attributes.name
            });
          }
        }
        this.selectedAbsentFormula = selectedAbsentFormula;
      }
    },
    getAllIndexes(arr, val, status = true) {
      let index = [];
      arr.forEach((e, i) => {
        if (status && e.id === val) index.push(i);
        if (!status && e.asst_entity_id === val) index.push(i);
      });
      return index;
    },
    handleSelectedIteration(level) {
      this.selIterationLevel = level;
      this.getselVariationList(
        this.selEmailResFormulas.attributes.char_response_variations
      );
       if (this.selVariationList){
        this.selectedVariation = this.selVariationList[0];
        this.variationName = this.selectedVariation.attributes.response;
      } else{
        this.variationName = "";
      }
    },
    handleAddVariation() {
      if (
        !this.emailResFormulas ||
        (this.emailResFormulas && this.emailResFormulas.length === 0)
      )
        utilFunctionService.showErr(commonConfig.appMessages.addResFormula);
      let fb = {
        email_response: {
          name: "",
          character_id: null,
          char_response_variations_attributes: [
            {
              iteration: 1,
              variation: 1,
              response: null
            }
          ]
        }
      };
      fb.email_response.name = this.selEmailResFormulas.attributes.name;
      fb.email_response.char_response_variations_attributes[0].iteration = this.selIterationLevel;
      fb.email_response.char_response_variations_attributes[0].variation =
        this.selVariationList.length + 1;
      fb.email_response.char_response_variations_attributes[0].response = this.variationName;
      fb.email_response.character_id = this.emailSelChar.id;
      if (this.selVariationList.length - 1 < 4)
        this.variationName &&
          api
            .addEmailVariation(this.selEmailResFormulas.id, fb)
            .then(() => {
              this.variationName = "";
              this.getEmailResList(false);
            })
            .catch(() => {
              if (
                !this.emailResFormulas ||
                (this.emailResFormulas && this.emailResFormulas.length === 0)
              )
                utilFunctionService.showErr(
                  commonConfig.appMessages.addResFormula
                );
            });
      else
        utilFunctionService.showErr(commonConfig.appMessages.invalidVariation);
    },
    getselVariationList(data) {
      this.selVariationList = [];
      data.forEach(ele => {
        if (ele.attributes.iteration === this.selIterationLevel)
          this.selVariationList.push(ele);
      });
    },
    getSelectedVariation(index) {
      if (this.selectedVariation) {
        this.updateVariation(this.selectedVariation);
      }
      setTimeout(() => {
        this.selectedVariation = this.selVariationList[index];
        this.variationName = this.selectedVariation.attributes.response;
      }, 1000);
    },
    updateVariationOnNext(data) {
      let fb = {
        email_response: {
          name: this.selEmailResFormulas.attributes.name,
          character_id: this.emailSelChar.id,
          char_response_variations_attributes: [
            {
              iteration: data.iteration,
              variation: data.variation,
              response: this.variationName,
              id: data.id
            }
          ]
        }
      };
      // this.variationName &&
        api.addEmailVariation(this.selEmailResFormulas.id, fb).then(() => {
        });
    },
    updateVariation(data) {
      let fb = {
        email_response: {
          name: this.selEmailResFormulas.attributes.name,
          character_id: this.emailSelChar.id,
          char_response_variations_attributes: [
            {
              iteration: data.iteration,
              variation: data.variation,
              response: this.variationName,
              id: data.id
            }
          ]
        }
      };
      // this.variationName &&
        api.addEmailVariation(this.selEmailResFormulas.id, fb).then(() => {
          this.getEmailResList(false);
        });
    },
    handleLabelEvalChange(e, data) {
      let selectedPresentFormula = JSON.parse(
        JSON.stringify(this.selectedPresentFormula)
      );
      if (!selectedPresentFormula["Assistant-Entity"])
        selectedPresentFormula["Assistant-Entity"] = [];
      let evalVal = this.getAllIndexes(
        selectedPresentFormula["Assistant-Entity"],
        data.id,
        false
      );
      if (evalVal.length !== 0) {
        for (let i = evalVal.length - 1; i >= 0; i--)
          selectedPresentFormula["Assistant-Entity"].splice(evalVal[i], 1);
      }
      if (e.target.checked) {
        this.selectedPresentFormula = {};
        selectedPresentFormula["Assistant-Entity"].push({
          id: data.id,
          name: `Assistant-Entity`,
          value: data.attributes.name,
          asst_entity_id: data.id,
          asst_entity_values: data.attributes.asst_entity_values,
          isAll: true
        });
      }
      this.selectedPresentFormula = selectedPresentFormula;
      if (this.selectedPresentFormula["Assistant-Entity"].length === 0)
        delete this.selectedPresentFormula["Assistant-Entity"];
    },
    handleAddResFormula() {
      if (this.step === "qa-and-interstitial") {
        let qaResponseFormula = {
          qa_formula: {
            present_cond_keyword_min: this.presentMinKeyword,
            absent_cond_keyword_min: this.absentMinKeyword
          }
        };
        for (let key in this.qaFormulaKeys) {
          this.getFormData(
            true,
            qaResponseFormula.qa_formula,
            this.selectedPresentFormula[key],
            this.qaFormulaKeys[key][1],
            this.qaFormulaKeys[key][0]
          );
          this.getFormData(
            false,
            qaResponseFormula.qa_formula,
            this.selectedAbsentFormula[key],
            this.qaFormulaKeys[key][1],
            this.qaFormulaKeys[key][0]
          );
        }
        api.postQaResFormula(this.qaConditionId, qaResponseFormula).then(() => {
          this.$modal.hide("responseFormulaModal");
          api.getQaCondition(this.qaConditionId).then(res => {
            this.setQaResFormula(res.data.data.attributes.qa_formulas);
          });
        });
      } else if (this.step === "skill-setup") {
        let assessmentResponseFormula = {
          assessment_formula: {
            present_cond_keyword_min: this.presentMinKeyword,
            absent_cond_keyword_min: this.absentMinKeyword
          }
        };
        for (let key in this.assessmentFormulaKeys) {
          this.getFormData(
            true,
            assessmentResponseFormula.assessment_formula,
            this.selectedPresentFormula[key],
            this.assessmentFormulaKeys[key][1],
            this.assessmentFormulaKeys[key][0]
          );
          this.getFormData(
            false,
            assessmentResponseFormula.assessment_formula,
            this.selectedAbsentFormula[key],
            this.assessmentFormulaKeys[key][1],
            this.assessmentFormulaKeys[key][0]
          );
        }
        api
          .postAssessmentResFormula(
            this.selectedAssmntItem.id,
            assessmentResponseFormula
          )
          .then(() => {
            this.$modal.hide("responseFormulaModal");
            this.skillSetUp(false);
          });
      } else {
        let fb = {
          response_formula: {
            present_cond_keyword_min: this.presentMinKeyword,
            absent_cond_keyword_min: this.absentMinKeyword
          }
        };
        for (let key in this.formulaKeys) {
          this.getFormData(
            true,
            fb.response_formula,
            this.selectedPresentFormula[key],
            this.formulaKeys[key][1],
            this.formulaKeys[key][0]
          );
          this.getFormData(
            false,
            fb.response_formula,
            this.selectedAbsentFormula[key],
            this.formulaKeys[key][1],
            this.formulaKeys[key][0]
          );
        }
        api.postResFormula(this.selEmailResFormulas.id, fb).then(() => {
          this.$modal.hide("responseFormulaModal");
          this.getEmailResList(false);
        });
      }
    },
    setDestroyId(res, formulaName) {
      this.responseFormulaId = { id: res.ques_id, name: formulaName };
    },
    deleteResponseFormula() {
      utilFunctionService.showLoader();
      api.destroyResFormula(this.responseFormulaId.id).then(() => {
        utilFunctionService.hideLoader();
        this.getEmailResList(false);
      });
    },
    deleteQaFormula() {
      utilFunctionService.showLoader();
      api.destroyQaFormula(this.responseFormulaId.id).then(() => {
        utilFunctionService.hideLoader();
          api.getQaCondition(this.qaConditionId).then(res => {
            this.setQaResFormula(res.data.data.attributes.qa_formulas);
          });
      });
    },
     deleteSkill() {
      utilFunctionService.showLoader();
      api.deleteEmailSKill(this.selectedSkill.id).then(() => {
        utilFunctionService.hideLoader();
        this.getSkills();
      });
    },

    deleteAssessmentResponseFormula() {
      utilFunctionService.showLoader();
      api.destroyAssessmentFormula(this.responseFormulaId.id).then(() => {
        utilFunctionService.hideLoader();
        this.skillSetUp(false);
      });
    },
    getFormData(objKey, fb, formula, formulaKey, key) {
      formula &&
        formula.forEach(e => {
          if (e.isAll) {
            if (!fb[formulaKey]) fb[formulaKey] = [];
            !e.isAdded &&
              e.asst_entity_values.forEach(val => {
                fb[formulaKey].push({
                  [key]: val.id,
                  present_cond: objKey
                });
              });
          } else {
            if (!fb[formulaKey]) fb[formulaKey] = [];
            !e.isAdded &&
              fb[formulaKey].push({
                [key]: e.id,
                present_cond: objKey
              });
          }
        });
    },
    openResFormulaModal() {
      if (this.step === "response-formulas") {
        if (
          !this.emailResFormulas ||
          (this.emailResFormulas && this.emailResFormulas.length === 0)
        ) {
          utilFunctionService.showErr(commonConfig.appMessages.addResFormula);
        } else {
          this.openFormulaModal();
        }
      } else {
        this.openFormulaModal();
      }
    },
    openFormulaModal() {
      this.emptyEmotionSenti();
      this.$modal.show("responseFormulaModal");
    },
    emptyEmotionSenti() {
      this.presentMinKeyword = 0;
      this.absentMinKeyword = 0;
      this.selectedPresentFormula = {};
      this.selectedAbsentFormula = {};
      this.isFormulaEdited = false;
    },
    handleRangeChange(e) {
      let rangeKey = e.target.id.split("_");
      if (parseFloat(e.target.value) > 0 && parseFloat(e.target.value) < 1) {
        this.emotionData[rangeKey[0]] = parseFloat(e.target.value);
      }
    },
    handleButtonRangeChange(e, key, status) {
      e.preventDefault();
      let rangeKey = key.split("_");
      if (status) {
        this.selRange[rangeKey[0]] = null;
        if (rangeKey[1] === "below") this.selRangeEmotion[rangeKey[0]] = "lt";
        if (rangeKey[1] === "above") this.selRangeEmotion[rangeKey[0]] = "gt";
      } else {
        this.selRangeEmotion[rangeKey[0]] = null;
        this.selRange[rangeKey[0]] = e.target.value;
      }
      this.disabledEmotion = false;
    },
    editResponseFormula(data, index) {
      this.emptyEmotionSenti();
      this.isFormulaEdited = true;
      this.selDesPresentFormula = [];
      this.selDesAbsentFormula = [];
      this.selectedPresentFormula = data.present;
      this.selectedAbsentFormula = data.absent;
      this.absentMinKeyword = data.absent_min;
      this.presentMinKeyword = data.present_min;
      this.editFormulaIndex = index;
      this.$modal.show("responseFormulaModal");
    },
    removeFormulaEntity(status, key) {
      if (status === "present") {
        let selectedPresentFormula = JSON.parse(
          JSON.stringify(this.selectedPresentFormula)
        );
        this.selectedPresentFormula = {};
        delete selectedPresentFormula[key];
        this.selectedPresentFormula = selectedPresentFormula;
      }
      if (status === "absent") {
        let selectedAbsentFormula = JSON.parse(
          JSON.stringify(this.selectedAbsentFormula)
        );
        this.selectedAbsentFormula = {};
        delete selectedAbsentFormula[key];
        this.selectedAbsentFormula = selectedAbsentFormula;
      }
    },
    handleEditResFormula() {
      let formulaKey =
        this.step === "qa-and-interstitial"
          ? this.qaFormulaKeys
          : this.step == "skill-setup"
          ? this.assessmentFormulaKeys
          : this.formulaKeys;
      this.$modal.hide("responseFormulaModal");
      let formulaPresentKeys = this.selPresentResFormula[this.editFormulaIndex][
        "present"
      ];
      let formulaAbsentKeys = this.selPresentResFormula[this.editFormulaIndex][
        "absent"
      ];
      let fbKey =
        this.step === "qa-and-interstitial"
          ? "qa_formula"
          : this.step == "skill-setup"
          ? "assessment_formula"
          : "response_formula";
      let fb = {
        [fbKey]: {
          present_cond_keyword_min: this.presentMinKeyword,
          absent_cond_keyword_min: this.absentMinKeyword
        }
      };
      Object.keys(formulaPresentKeys).length > 0 &&
        Object.keys(formulaPresentKeys).forEach(ele => {
          let sentiEmotionKey =
            ele === "emotion" ? this.disabledEmotion : this.disabledSentiment;
          this.getUpdateFormData(
            ele,
            true,
            this.selectedPresentFormula,
            fb[fbKey],
            formulaPresentKeys,
            formulaKey,
            sentiEmotionKey
          );
        });
      Object.keys(formulaAbsentKeys).length > 0 &&
        Object.keys(formulaAbsentKeys).forEach(ele => {
          this.getUpdateFormData(
            ele,
            false,
            this.selectedAbsentFormula,
            fb[fbKey],
            formulaAbsentKeys,
            formulaKey
          );
        });
      for (let key in formulaKey) {
        this.getFormData(
          true,
          fb[fbKey],
          this.selectedPresentFormula[key],
          formulaKey[key][1],
          formulaKey[key][0]
        );
        this.getFormData(
          false,
          fb[fbKey],
          this.selectedAbsentFormula[key],
          formulaKey[key][1],
          formulaKey[key][0]
        );
      }
      if (this.step === "qa-and-interstitial") {
        api
          .editResponseFormula(
            this.selPresentResFormula[this.editFormulaIndex].ques_id,
            fb
          )
          .then(() => {
            api.getQaCondition(this.qaConditionId).then(res => {
              this.setQaResFormula(res.data.data.attributes.qa_formulas);
            });
          });
      } else if (this.step === "skill-setup") {
        api
          .updateAssessmentFormula(
            this.selPresentResFormula[this.editFormulaIndex].ques_id,
            fb
          )
          .then(() => {
            this.skillSetUp(false);
          });
      } else {
        api
          .updateResFormulas(
            this.selPresentResFormula[this.editFormulaIndex].ques_id,
            fb
          )
          .then(() => {
            this.getEmailResList(false);
          });
      }
    },
    getUpdateFormData(key, objKey, data, fb, formula, obj, sentiEmotionKey) {
      if (!data[key]) {
        if (key !== "emotion" || key !== "sentiment") {
          formula[key] &&
            formula[key].forEach(e => {
              if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
              fb[obj[key][1]].push({
                _destroy: true,
                id: e.id,
                present_cond: objKey
              });
            });
        }
      } else {
        if (
          data[key][0].name === "emotion" ||
          data[key][0].name === "sentiment"
        ) {
          if (sentiEmotionKey) {
            if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
            formula[key][0].data &&
              formula[key][0].data.forEach(e => {
                fb[obj[key][1]].push({
                  _destroy: true,
                  id: e.id,
                  present_cond: objKey
                });
              });
          } else if (data[key][0].value === "enabled") {
            if (data[key][0].name === "emotion") {
              if (!this.disabledEmotion) {
                if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
                data[key][0].data &&
                  data[key][0].data.forEach(e => {
                    if (
                      this.selRangeEmotion[e.attributes.emotion] === "lt" ||
                      this.selRangeEmotion[e.attributes.emotion] === "gt"
                    )
                      fb[obj[key][1]].push({
                        emotion: e.attributes.emotion,
                        comparator: this.selRangeEmotion[e.attributes.emotion],
                        score: this.emotionData[e.attributes.emotion],
                        present_cond: true,
                        id: e.id
                      });
                    else
                      fb[obj[key][1]].push({
                        emotion: e.attributes.emotion,
                        range_value: this.selRange[e.attributes.emotion],
                        score: this.emotionData[e.attributes.emotion],
                        present_cond: true,
                        id: e.id
                      });
                  });
              }
            } else if (data[key][0].name === "sentiment") {
              if (!this.disabledSentiment) {
                if (!fb[obj[key][1]]) fb[obj[key][1]] = [];
                if (data[key][0].data) {
                  if (
                    this.sentimentData.sentiment === "lt" ||
                    this.sentimentData.sentiment === "gt"
                  )
                    fb[obj[key][1]].push({
                      sentiment: this.sentimentData.value,
                      comparator: this.sentimentData.sentiment,
                      score: this.sentimentData.score,
                      present_cond: true,
                      id: data[key][0].data[0].id
                    });
                  else
                    fb[obj[key][1]].push({
                      sentiment: this.sentimentData.value,
                      range_value: this.sentimentData.range,
                      score: this.sentimentData.score,
                      present_cond: true,
                      id: data[key][0].data[0].id
                    });
                }
              }
            }
          }
        }
      }
    },
    handleCharTabChange(data) {
      this.emailSelChar = data;
      this.markIntroActive();
      this.emailResFormulas = [];
      this.allEmailResFormulas &&
        this.allEmailResFormulas.forEach(e => {
          if (parseInt(this.emailSelChar.id) === e.attributes.character.id)
            this.emailResFormulas.push(e);
        });
      this.selEmailResFormulas = this.emailResFormulas[0];
      if (this.selEmailResFormulas) {
        this.setResFormula(
          this.selEmailResFormulas.attributes.response_formulas
        );
        this.getselVariationList(
          this.selEmailResFormulas.attributes.char_response_variations
        );
      } else {
        this.selPresentResFormula = [];
        this.selVariationList = [];
        this.selItrIntroList = [];
      }
    },
    reverseFormulaKey(obj) {
      const ret = {};
      Object.keys(obj).forEach(key => {
        ret[obj[key]] = key;
      });
      return ret;
    },
    getCharacterData(data) {
      this.selectedChar = data.map(x => ({
        id: x.id,
        status: true,
        world_role: x.attributes.world_role,
        org_name: x.attributes.org_name,
        // char_photo_url: x.attributes.char_photo_url.thumbnail,
        char_full_name: x.attributes.char_full_name,
        char_gender: x.attributes.char_gender,
        char_age: x.attributes.char_age
      }));
      this.emailSelChar = this.selectedChar[0];
    }
  }
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";
</style>
