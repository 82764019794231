var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "pdfModal",
        height: "auto",
        width: 1000,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [_c("h5")]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("pdfModal")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("PDFViewer", {
              staticStyle: { display: "inline-block", width: "100%" },
              attrs: { source: _vm.src },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }