var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c("header", { staticClass: "header" }, [
        _c("div", { staticClass: "header-left" }, [
          _c("h2", [
            _vm._v(
              _vm._s(
                this.$route.params.id
                  ? `Updating Skill Set - ${_vm.llm_skill_set_name}`
                  : "Creating Skill Set"
              )
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.create_or_update_llm_skill_set.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "skillSetName" } }, [
                    _vm._v("Skill Set Name"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.llm_skill_set_name,
                        expression: "llm_skill_set_name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", id: "skillSetName" },
                    domProps: { value: _vm.llm_skill_set_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.llm_skill_set_name = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "skill-container" },
                  [
                    _c("h3", [_vm._v("Primary Skill")]),
                    _c(
                      "draggable",
                      {
                        staticClass: "list-group",
                        attrs: { list: _vm.primary_skill, group: "skills" },
                        on: { add: _vm.checkPrimarySkill },
                      },
                      _vm._l(_vm.primary_skill, function (element, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "list-group-item" },
                          [_vm._v(" " + _vm._s(element.skill_name) + " ")]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "skills-container" },
                  [
                    _c("h3", [_vm._v("Sub Skills")]),
                    _c("nested-draggable", {
                      key: _vm.nestedKey,
                      staticClass: "list-group skill-container",
                      attrs: {
                        nested_skills: _vm.sub_skills,
                        group: "skills",
                        id: "sub_skills",
                        itemKey: "name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._m(0),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("h3", [_vm._v("All Skills")]),
              _c(
                "draggable",
                {
                  staticClass: "list-group",
                  attrs: {
                    list: _vm.all_skills,
                    id: "all_skills",
                    group: "skills",
                  },
                  on: { add: _vm.handleSkillRemoval },
                },
                _vm._l(_vm.all_skills, function (element, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "list-group-item" },
                    [_vm._v(" " + _vm._s(element.skill_name) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Save")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }