<template>
  <modal name="pdfModal" height="auto" :width="1000" :scrollable="true" @before-open="beforeOpen">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-header modal-print-header">
          <div class="modal-print-row">
            <div class="modal-print-left">
              <input class="form-control" maxlength="6" v-model.number="page" type="number" />
              /{{numPages}}
            </div>
            <div class="modal-print-right">
              <button class="btn-focus loader" @click="rotate += 90">&#x27F3;</button>
              <button class="btn-focus rotate" @click="rotate -= 90">Rotate</button>
              <button class="btn primary" @click="$refs.pdf.print()">Print</button>
            </div>
          </div>
           <button type="button" class="close" @click="hide('pdfModal')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div style="width: 100%;align-items: center">
          <div
            v-if="loadedRatio > 0 && loadedRatio < 1"
            style="background-color: green; color: white; text-align: center"
            :style="{ width: loadedRatio * 100 + '%' }"
          >{{ Math.floor(loadedRatio * 100) }}%</div>
          <PDFViewer
            :source="src"
            style="display: inline-block; width: 100%"
          />
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import PDFViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { utilFunctionService } from "@/utils/utils.service";
export default {
  components: {
    PDFViewer
  },
  data() {
    return {
      src: "",
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0
    };
  },
  methods: {
    error: function(err) {
      console.log(err);
    },
    beforeOpen(event) {
      utilFunctionService.showLoader();
      this.src = event.params.pdfUrl;
      utilFunctionService.hideLoader();
      // this.src.then(pdf => {
      //   this.page = 1;
      //   this.numPages = pdf.numPages;
      //   utilFunctionService.hideLoader();
      // });
    },
    hide(modal) {
      this.$modal.hide('pdfModal')
      this.$parent.hide(modal);
    }
  }
};
</script>
