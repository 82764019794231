var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "createProvision",
        height: "auto",
        width: 500,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "add-new-section-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "add-new-course-page" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [
                  _vm.customer_id
                    ? _c("h5", [_vm._v("Edit Section")])
                    : _c("h5", [_vm._v("Add New Section")]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide("createProvision")
                    },
                  },
                },
                [_c("em", { staticClass: "icon-close" })]
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "modal-form" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Section Name"),
                        ]),
                        _c("ValidationProvider", {
                          attrs: { name: "Name", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sectionName,
                                        expression: "sectionName",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "text",
                                      id: "customerName",
                                      "aria-describedby": "emailHelp",
                                    },
                                    domProps: { value: _vm.sectionName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.sectionName = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "exampleInputEmail1" },
                        },
                        [_vm._v("Search Customer")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchCustomers,
                            expression: "searchCustomers",
                          },
                          {
                            name: "debounce",
                            rawName: "v-debounce:700ms",
                            value: _vm.getCustomers,
                            expression: "getCustomers",
                            arg: "700ms",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "Search Customer...",
                        },
                        domProps: { value: _vm.searchCustomers },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchCustomers = $event.target.value
                          },
                        },
                      }),
                      _vm.customersList.length
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-menu",
                                class: [
                                  _vm.customersList.length !== 0 ? "show" : "",
                                ],
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "dropdown-list" },
                                  _vm._l(
                                    _vm.customersList,
                                    function (customers) {
                                      return _c(
                                        "li",
                                        {
                                          key: customers.id,
                                          staticClass: "dropdown-items",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "dropdown-content" },
                                            [
                                              _c("h4", [
                                                _vm._v(
                                                  _vm._s(
                                                    customers.attributes.name
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          !customers.status
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-action",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "add-action",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addCustomers(
                                                            customers
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("+")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          customers.status
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-action",
                                                },
                                                [
                                                  _c("em", {
                                                    staticClass: "icon-check",
                                                  }),
                                                  _c("span", [_vm._v("Added")]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.addedCustomers.name
                      ? _c("div", { staticClass: "contact-list-section" }, [
                          _c("div", { staticClass: "list-container" }, [
                            _c("div", { staticClass: "list-box" }, [
                              _c("div", { staticClass: "left-section" }, [
                                _vm.addCustomers.photo_url != null
                                  ? _c("div", { staticClass: "image-box" }, [
                                      _c("img", {
                                        staticClass: "module-img",
                                        attrs: {
                                          alt: "images",
                                          src: _vm.addCustomers.photo_url,
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                _c("h3", { staticClass: "contact-text" }, [
                                  _vm._v(_vm._s(_vm.addedCustomers.name)),
                                ]),
                              ]),
                              _c("div", { staticClass: "right-section" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "remove-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeCharcter()
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "exampleInputEmail1" },
                        },
                        [_vm._v("Search Course")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchCourse,
                            expression: "searchCourse",
                          },
                          {
                            name: "debounce",
                            rawName: "v-debounce:700ms",
                            value: _vm.getCourses,
                            expression: "getCourses",
                            arg: "700ms",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Search Course.." },
                        domProps: { value: _vm.searchCourse },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchCourse = $event.target.value
                          },
                        },
                      }),
                      _vm.coursesList.length
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-menu",
                                class: [
                                  _vm.coursesList.length !== 0 ? "show" : "",
                                ],
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "dropdown-list" },
                                  _vm._l(_vm.coursesList, function (customers) {
                                    return _c(
                                      "li",
                                      {
                                        key: customers.id,
                                        staticClass: "dropdown-items",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-content" },
                                          [
                                            _c("h4", [
                                              _vm._v(
                                                _vm._s(
                                                  customers.attributes.name
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        !customers.status
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-action",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "add-action",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addCourse({
                                                          name: customers
                                                            .attributes.name,
                                                          id: customers.id,
                                                        })
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("+")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        customers.status
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-action",
                                              },
                                              [
                                                _c("em", {
                                                  staticClass: "icon-check",
                                                }),
                                                _c("span", [_vm._v("Added")]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.addedCourses.name
                      ? _c("div", { staticClass: "contact-list-section" }, [
                          _c("div", { staticClass: "list-container" }, [
                            _c("div", { staticClass: "list-box" }, [
                              _c("div", { staticClass: "left-section" }, [
                                _c("h3", { staticClass: "contact-text" }, [
                                  _vm._v(_vm._s(_vm.addedCourses.name)),
                                ]),
                              ]),
                              _c("div", { staticClass: "right-section" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "remove-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeCourse()
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "courses-tab-section" }, [
                      _c("ul", { staticClass: "tab-section" }, [
                        _c(
                          "li",
                          {
                            class: _vm.tab === "address" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("address")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "address"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Dates")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class:
                              _vm.tab === "registration" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("registration")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "registration"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Registration")]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class:
                              _vm.tab === "instructor" ? "tab active" : "tab",
                            on: {
                              click: function ($event) {
                                return _vm.setTab("instructor")
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.tab === "instructor"
                                    ? "link link-active"
                                    : "link",
                              },
                              [_vm._v("Instructor(s)")]
                            ),
                          ]
                        ),
                      ]),
                      _vm.tab === "address"
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "address" },
                            },
                            [
                              _c("div", { staticClass: "add-module-section" }, [
                                _c("div", { staticClass: "module-input" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "form-label" },
                                            [_vm._v("Start Date")]
                                          ),
                                          _c("datepicker", {
                                            attrs: { name: "startDate" },
                                            model: {
                                              value: _vm.startDate,
                                              callback: function ($$v) {
                                                _vm.startDate = $$v
                                              },
                                              expression: "startDate",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "calendar-icon" },
                                            [
                                              _c("img", {
                                                staticClass: "calendar-img",
                                                attrs: {
                                                  alt: "images",
                                                  src: require("../../assets/images/svg/calendar-icon.svg"),
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "form-label" },
                                            [_vm._v("End Date")]
                                          ),
                                          _c("datepicker", {
                                            attrs: { name: "endDate" },
                                            model: {
                                              value: _vm.endDate,
                                              callback: function ($$v) {
                                                _vm.endDate = $$v
                                              },
                                              expression: "endDate",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "calendar-icon" },
                                            [
                                              _c("img", {
                                                staticClass: "calendar-img",
                                                attrs: {
                                                  alt: "images",
                                                  src: require("../../assets/images/svg/calendar-icon.svg"),
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "checkbox-nowrap" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-checkboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                },
                                                [
                                                  _vm._v(" Flexible end Date "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.flexibleEndDate,
                                                        expression:
                                                          "flexibleEndDate",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.flexibleEndDate
                                                      )
                                                        ? _vm._i(
                                                            _vm.flexibleEndDate,
                                                            null
                                                          ) > -1
                                                        : _vm.flexibleEndDate,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.flexibleEndDate,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.flexibleEndDate =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.flexibleEndDate =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.flexibleEndDate =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-checkboxes" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-checkbox",
                                                },
                                                [
                                                  _vm._v(" Continuous Start "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.continuousStart,
                                                        expression:
                                                          "continuousStart",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.continuousStart
                                                      )
                                                        ? _vm._i(
                                                            _vm.continuousStart,
                                                            null
                                                          ) > -1
                                                        : _vm.continuousStart,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.continuousStart,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.continuousStart =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.continuousStart =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.continuousStart =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v("Course Duration")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "quiz-dropdown course-duration-dropdown",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-secondary dropdown-toggle",
                                                attrs: {
                                                  type: "button",
                                                  id: "entity",
                                                  "data-toggle": "dropdown",
                                                  "aria-haspopup": "true",
                                                  "aria-expanded": "false",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.courseDurationType
                                                        .name
                                                    ) +
                                                    " "
                                                ),
                                                _c("em", {
                                                  staticClass:
                                                    "icon-carrot-down",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-menu",
                                                attrs: {
                                                  "aria-labelledby": "entity",
                                                },
                                              },
                                              _vm._l(
                                                _vm.courseDuration,
                                                function (type) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: type.id,
                                                      staticClass:
                                                        "dropdown-item",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectCourseDuration(
                                                            type
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(type.name))]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "col" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v("Course Duration Value")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.courseDurationValue,
                                              expression: "courseDurationValue",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Course Duration Value",
                                          },
                                          domProps: {
                                            value: _vm.courseDurationValue,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.courseDurationValue =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn default",
                                    attrs: { type: "button" },
                                    on: { click: _vm.hide },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setTab("registration")
                                      },
                                    },
                                  },
                                  [_vm._v("Next")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm.tab === "registration"
                      ? _c("div", { staticClass: "courses-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "registration" },
                            },
                            [
                              _c("div", { staticClass: "checkbox-nowrap" }, [
                                _c("div", { staticClass: "checkbox-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-checkboxes" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "custom-checkbox" },
                                        [
                                          _vm._v(" Single registration code "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.regType,
                                                expression: "regType",
                                              },
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: "single",
                                              checked: _vm._q(
                                                _vm.regType,
                                                "single"
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.regType = "single"
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "checkbox-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-checkboxes" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "custom-checkbox" },
                                        [
                                          _vm._v(
                                            " Multiple registration codes "
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.regType,
                                                expression: "regType",
                                              },
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: "multiple",
                                              checked: _vm._q(
                                                _vm.regType,
                                                "multiple"
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.regType = "multiple"
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "checkbox-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-checkboxes" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "custom-checkbox" },
                                        [
                                          _vm._v(" Instructor provided code "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.regType,
                                                expression: "regType",
                                              },
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: "instructorprovided",
                                              checked: _vm._q(
                                                _vm.regType,
                                                "instructorprovided"
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.regType =
                                                  "instructorprovided"
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.regType == "single"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "registration-detail-show single-registration",
                                    },
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v("Existing")]
                                        ),
                                        _vm.codes.length > 0
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.codes[
                                                    _vm.codes.length - 1
                                                  ].attributes.code
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v("Edit Registration Code")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.singleRegCode,
                                              expression: "singleRegCode",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            "aria-describedby": "emailHelp",
                                            placeholder:
                                              "Enter registration code",
                                          },
                                          domProps: {
                                            value: _vm.singleRegCode,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.singleRegCode =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm.regType == "multiple" &&
                                      _vm.codes.length == 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn secondary",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateCode(
                                                    "single"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Generate")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.regType == "instructorprovided"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "registration-detail-show single-registration",
                                    },
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [_vm._v("Existing")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(_vm.instructorProvidedCode)
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { staticClass: "form-label" },
                                          [
                                            _vm._v(
                                              "Edit Instructor Provided Code"
                                            ),
                                          ]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.instructorProvidedCode,
                                              expression:
                                                "instructorProvidedCode",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            "aria-describedby": "emailHelp",
                                            placeholder:
                                              "Enter registration code",
                                          },
                                          domProps: {
                                            value: _vm.instructorProvidedCode,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.instructorProvidedCode =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm.regType == "multiple" &&
                                      _vm.codes.length == 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn secondary",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateCode(
                                                    "single"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Generate")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.regType == "multiple"
                                ? _c(
                                    "div",
                                    { staticClass: "registration-detail-show" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "code-generae-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "form-label" },
                                                [
                                                  _vm._v(
                                                    "Number of codes to generate"
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.numOfCode,
                                                    expression: "numOfCode",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                  placeholder:
                                                    "Enter number of codes",
                                                },
                                                domProps: {
                                                  value: _vm.numOfCode,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.numOfCode =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "code-generae-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "form-label" },
                                                [_vm._v("Code prefix")]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.codePrefix,
                                                    expression: "codePrefix",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                  placeholder: "Enter code",
                                                },
                                                domProps: {
                                                  value: _vm.codePrefix,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.codePrefix =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn secondary",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateCode()
                                                },
                                              },
                                            },
                                            [_vm._v("Generate")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "used-code-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "detail-left" },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    "List of single-use codes"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "code-detail" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "code-text",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalCodes
                                                          ) + " "
                                                        ),
                                                      ]),
                                                      _vm._v(" Codes"),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "divider",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "code-text",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.usedCodes
                                                          ) + " "
                                                        ),
                                                      ]),
                                                      _vm._v(" Used"),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "detail-right" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "btn secondary",
                                                  attrs: {
                                                    href: "javascript:void(0);",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.exportCsv()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Export CSV")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "code-used-container" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "code-used-box" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "title-detail" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "title-left",
                                                    },
                                                    [_vm._v(" Codes ")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "title-right",
                                                    },
                                                    [_vm._v(" Used ")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-infinite-scroll",
                                                {
                                                  staticClass:
                                                    "manage-scroller",
                                                  on: { bottom: _vm.nextPage },
                                                },
                                                _vm._l(
                                                  _vm.codes,
                                                  function (code) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: code.id,
                                                        staticClass:
                                                          "code-nowrap",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-code-section",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  code
                                                                    .attributes
                                                                    .code
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-used-section",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  code
                                                                    .attributes
                                                                    .used ==
                                                                    false
                                                                    ? "No"
                                                                    : "Yes"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: { click: _vm.hide },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setTab("instructor")
                                  },
                                },
                              },
                              [_vm._v("Next")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.tab === "instructor"
                      ? _c("div", { staticClass: "courses-tab-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-detail-show",
                              attrs: { id: "instructor" },
                            },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "exampleInputEmail1" },
                                  },
                                  [_vm._v("Instructor")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.searchInstructor,
                                      expression: "searchInstructor",
                                    },
                                    {
                                      name: "debounce",
                                      rawName: "v-debounce:700ms",
                                      value: _vm.getInstructor,
                                      expression: "getInstructor",
                                      arg: "700ms",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Search Instructor...",
                                  },
                                  domProps: { value: _vm.searchInstructor },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.searchInstructor = $event.target.value
                                    },
                                  },
                                }),
                                _vm.instructorsList.length
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          class: [
                                            _vm.instructorsList.length !== 0
                                              ? "show"
                                              : "",
                                          ],
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "dropdown-list" },
                                            _vm._l(
                                              _vm.instructorsList,
                                              function (instructor) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: instructor.id,
                                                    staticClass:
                                                      "dropdown-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-content",
                                                      },
                                                      [
                                                        _c("h4", [
                                                          _vm._v(
                                                            _vm._s(
                                                              instructor
                                                                .attributes
                                                                .first_name
                                                            ) +
                                                              " " +
                                                              _vm._s(
                                                                instructor
                                                                  .attributes
                                                                  .last_name
                                                              )
                                                          ),
                                                        ]),
                                                        _c("h4", [
                                                          _vm._v(
                                                            _vm._s(
                                                              instructor
                                                                .attributes
                                                                .email
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    !instructor.status
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-action",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "add-action",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.addInstructors(
                                                                        instructor
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("+")]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    instructor.status
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-action",
                                                          },
                                                          [
                                                            _c("em", {
                                                              staticClass:
                                                                "icon-check",
                                                            }),
                                                            _c("span", [
                                                              _vm._v("Added"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "contact-list-section" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "list-container" },
                                    [
                                      _vm.addedInstructors.length > 0
                                        ? _c(
                                            "h2",
                                            { staticClass: "title-text" },
                                            [
                                              _vm._v(
                                                "Instructors (" +
                                                  _vm._s(
                                                    _vm.addedInstructors.length
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.addedInstructors,
                                        function (instr, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: instr.id,
                                              staticClass: "list-box",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "left-section" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "contact-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          instr.firstName
                                                        ) +
                                                          " " +
                                                          _vm._s(instr.lastName)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    { staticClass: "email" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(instr.email)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "right-section",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "remove-link",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteInstructor(
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Remove")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn default",
                                attrs: { type: "button" },
                                on: { click: _vm.hide },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.createSection("")
                                  },
                                },
                              },
                              [_vm._v("Save")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }