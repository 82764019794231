<template>
    <modal
      name="EditVideo"
      :width="700"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Edit Video</h5>
          </div>
          <button type="button" class="close" @click="hide('EditVideo')">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body mt-3">
          <ValidationObserver ref="form">
            <form class="modal-form organisation-form" @submit.prevent="formSubmit">
              <div class="row">
                <div class="form-group col">
                  <label class="form-label">Card Title</label>
                  <ValidationProvider
                    name="Card Title"
                    rules="required|max:100"
                    v-slot="{ errors }"
                  >
                    <input
                      type="title"
                      class="form-control"
                      id="title"
                      aria-describedby="emailHelp"
                      v-model="title"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group col">
                  <label class="form-label">Wistia Code</label>
                  <ValidationProvider
                    name="Wistia Code"
                    rules="required|max:100"
                    v-slot="{ errors }"
                  >
                    <input
                      type="wistia_code"
                      class="form-control"
                      id="wistia_code"
                      aria-describedby="emailHelp"
                      v-model="wistia_code"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      v-if="wistia_code"
                      :class="['cursor','success']"
                      @click="getWistiaInfo()"
                    >Fetch Video Meta Info</span>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Transcript Description</label>
                <vue-editor :editorToolbar="fullToolbar" v-model="transcript"></vue-editor>
              </div>
              <div class="form-group">
                <label class="form-label">Video Duration</label>
                <ValidationProvider name="Video Duration" rules="required" v-slot="{ errors }">
                  <input
                    type="duration"
                    class="form-control"
                    aria-describedby="emailHelp"
                    v-model="duration"
                    readonly
                    :class="[(isActive && errors[0]) ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                    v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group organisation-image-group flex-wrap mt-0">
                <label class="form-label w-100">Wistia Thumbnail</label>
                <!-- <div class="organisation-image-pic">
                  <img :src="image" v-if="image" alt="images" />
                </div> -->
                <div class="organisation-image-pic cursor"  @click="$refs.fileInput.click()">
                  <img :src="image" v-if="image" alt="images"/>
                </div>
                <div class="organisation-image-remove">
                  <input
                    style="display: none;"
                    ref="fileInput"
                    type="file"
                    @change="onFile"
                    accept="image/*"
                  />
                  <a class="btn add" @click="$refs.fileInput.click()" v-if="!image">Add Photo</a>
                  <a class="btn delete" @click="removeImage" v-else>Remove Photo</a>
                </div>
              </div>
              <div class="form-group organisation-image-group d-flex flex-wrap mt-0 flex-row align-items-center">
                <label class="form-label instructor-resource-label">Show in Instructor Resources?</label>
                <input
                  type="checkbox"
                  v-model="showInstructorResource"
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn default"
                  data-toggle="modal"
                  data-target="#EditVideo"
                  data-dismiss="modal"
                  @click="hide('EditVideo')"
                >Cancel</button>
                <button type="submit" class="btn primary">Save</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </modal>

</template>

<script>
import api from "../../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { VueEditor } from "vue2-editor";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "EditVideo",
  components: {
    ValidationProvider,
    ValidationObserver,
    VueEditor
  },
  data() {
    return {
      duration: "",
      wistia_code: "",
      title: "",
      fullToolbar: [],
      transcript: "",
      isActive: false,
      file_name: "",
      thumbnail: "",
      wistia_thumbnail_url: "",
      selectedFile: null,
      image: null,
      searchVideo: "",
      videoLists: [],
      totalVideos: "",
      uploadGalleryStatus: true,
      uploadVideoGallery: true,
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      video: {},
      showInstructorResource: false
    };
  },
  methods: {
    hide(modal) {
      this.$modal.hide(modal);
    },
    formSubmit() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          this.isActive = false;
          utilFunctionService.showLoader();
          const _fb = new FormData();
          _fb.append("global_video[title]", this.title);
          _fb.append("global_video[duration]", this.duration);
          _fb.append("global_video[wistia_code]", this.wistia_code);
          _fb.append("global_video[transcript]", this.transcript);
          _fb.append("global_video[video_type]", "content");
          _fb.append("global_video[show_instructor_resource]", this.showInstructorResource);
          _fb.append(
            "global_video[wistia_thumbnail_url]",
            this.wistia_thumbnail_url
          );
          if (this.selectedFile)
            _fb.append("global_video[thumbnail]", this.selectedFile);
            _fb.append("global_video[file_name]", this.file_name);
          api
            .editGlobalVideo(this.id, _fb)
            .then(res => {
              utilFunctionService.hideLoader();
              utilFunctionService.showSuccess(
                commonConfig.appMessages.editVideo
              );
              this.clearForm();
              //this.$parent.getElmVideo(res.data.data);
              //this.$parent.getVideo(1);
              console.log(res.data.data, "res.data.data log")
              this.hide("EditVideo");
              //this.$parent.selectedVideo(res.data.data);
              this.$router.go(this.$router.currentRoute);
            })
            .catch(() => {
              utilFunctionService.hideLoader();
              this.clearForm();
              this.hide("EditVideo");
            });
        }
      });
    },
    selectedVideo(e) {
      this.$parent.selectedVideo(e);
    },
    getWistiaInfo() {
      api
        .videoDetail(this.wistia_code)
        .then(res => {
          res = res.data;
          this.duration = res.duration;
          this.wistia_thumbnail_url = res.thumbnail;
          this.file_name = res.file_name;
          this.image = res.thumbnail;
        })
        .catch(() => {
          this.duration = "";
          this.wistia_thumbnail_url = "";
          this.file_name = "";
          this.image = "";
        });
    },
    clearForm() {
      this.duration = "";
      this.wistia_code = "";
      this.title = "";
      this.transcript = "";

    },
    onFile(event) {
      this.selectedFile = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.selectedFile);
      _reader.onload = e => {
        this.image = e.target.result;
      };
    },
    removeImage: function() {
      if (this.organisation_id)
        api.removeOrganizationPhoto(this.organisation_id);
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
      this.image = null;
      this.selectedFile = null;
    },
    searchVideos() {
      api
        .getAllVideo(
          commonConfig.setting.searchPage,
          this.searchVideo,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.videoLists = res.data.data;
          this.totalVideos = res.data.filtered_count;
        })
        .catch();
    },
    getVideo(page) {
      this.page = page ? page : 1;
      api
        .getAllVideo(
          this.page,
          this.searchVideo,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.videoLists = [...this.videoLists, ...res.data.data];
          this.totalVideos = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {});
    },
    nextPageVideos() {
      ++this.page;
      if (this.page <= this.totalVideos) {
        this.getVideo(this.page);
      } else {
        return;
      }
    },

    getElmVideo(video) {
      this.$parent.getElmVideo(video);
    },
    beforeOpen(event) {
      console.log(event, "beforeOpen EditVideo event");
      this.clearForm();
      this.id = event.params.global_video_id;
      console.log(this.id, "beforeOpen event.id")
      this.getVideoDetails(this.id);
    },
    getVideoDetails(id) {
      api
        .getGlobalVideo(id)
        .then(res => {
          console.log(res.data.data, "-- *** getVideoDetails response");
          const video_data = res.data.data.attributes;
          this.title = video_data.title;
          this.wistia_code = video_data.wistia_code;
          this.transcript = video_data.transcript;
          this.wistia_thumbnail_url = video_data.wistia_thumbnail_url;
          this.duration = video_data.duration;
          this.showInstructorResource = video_data.show_instructor_resource
        })
        .catch();
    }
  },
  created() {
    this.getVideo(this.page);
  }

};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";
.instructor-resource-label {
  margin: 0 16px 0 0;
}
</style>
