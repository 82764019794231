<template>
  <div>
    <modal
      name="addLearnerToCourse"
      height="auto"
      :width="850"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <div class="modal-content modal-default">
        <div class="modal-header">
          <div class="modal-title" id="exampleModalLabel">
            <h5>Add Learner to Course</h5>
          </div>
        </div>
        <div class="modal-body">
          <form class="modal-form mt-0">
            <input type="email" class="form-control mb-3" placeholder="Learner email" v-model="userEmail" />
            <select class="form-select" v-model="selectedSection">
              <option disabled value="">Please select section to register into:</option>
              <option v-for="section in courseData.attributes.course_sections" :value="section.id" :key="section.id">
                {{ section.name }}
              </option>
            </select>
          </form>
          <div class="modal-footer mt-4">
            <button
              type="button"
              @click="hide('addLearnerToCourse')"
              data-dismiss="modal"
              class="btn default"
            >Cancel</button>
            <button
              type="button"
              class="btn primary"
              @click="saveLearner()"
            >
              Save Learner
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import api from "../../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "addLearnerToCourse",
  data() {
    return {
      courseData: {
        attributes: {
          course_sections: []
        }
      },
      userEmail: "",
      selectedSection: ""
    };
  },
  methods: {
    beforeOpen(event) {
      console.log(event);
      this.courseData = event.params.course_data;
    },
    show(modal) {
      this.$modal.show(modal);
    },
    hide() {
      this.$modal.hide("addLearnerToCourse");
    },
    saveLearner() {
      utilFunctionService.showLoader();
      api.addLearnerToCourse(this.userEmail, this.selectedSection).then(res => {
        console.log(res);
        utilFunctionService.hideLoader();
        this.hide();
        utilFunctionService.showSuccess("Learner added successfully");
      })
      .catch(err => {
        console.log(err);
        utilFunctionService.hideLoader();
        this.hide();
        utilFunctionService.showErr("An error occurred");
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  margin-bottom: 32px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-form {
  min-height: auto;
  width: 100%;
  margin-bottom: 32px;
}

.form-select {
  width: 100%;
}
</style>
