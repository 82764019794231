<template>
  <div id="WorldDetail">
    <div class="container-3-column">
      <header class="header pr-0 flex-nowrap">
        <div class="header-left" v-if="worldDetails.attributes">
          <div class="header-subtitle">Unique code {{worldDetails.attributes.world_code}}</div>
          <h2>{{worldDetails.attributes.name}}</h2>
          <h4>Created on {{worldDetails.attributes.created_on}}</h4>
          <div class="header-content">
            <div class="hello">
              <read-more
                more-str="Read more"
                :text="description"
                link="#"
                less-str="Read less"
                :max-chars="270"
              ></read-more>
            </div>
          </div>
        </div>
        <div class="header-edit-dropdown">
          <button
            type="button"
            class="btn dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <em class="icon-menu"></em>
          </button>
          <div class="dropdown-menu mt-1 dropdown-menu-right">
            <ul>
              <li>
                <a
                  data-toggle="modal"
                  data-target="#createWorld"
                  @click="$modal.show('newWorld', {id: id, editOrg: false})"
                >Edit</a>
              </li>
              <li>
                <a
                  data-toggle="modal"
                  data-target="#createWorld"
                  @click="exportWorld"
                >Export</a>
              </li>
              <li>
                <a @click="$modal.show('deleteWorldModal')" class="dropdown-error">Delete</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div class="data-no-result data-no-elm">
        <div class="manage-module" v-if="!elms.length">
          <div class="manage-head">
            <div>
              <h3>All ELMs</h3>
              <p class="no-manage-text">You have no ELM in the system yet.</p>
            </div>
            <router-link class="btn manage-btn mt-0" to="/create-elm">+ Add a new ELM</router-link>
          </div>
        </div>
        <div class="manage-content" v-if="elms.length">
          <div class="manage-module">
            <div class="manage-head">
              <div class="manage-subtitle">All ELMs({{totalElmCount}})</div>
              <router-link class="btn manage-btn mt-0" to="/create-elm">+ Add a new ELM</router-link>
            </div>
            <v-infinite-scroll @bottom="nextPage" class="manage-scroller">
              <div class="manage-row">
                <div class="manage-card" v-for="(elm) of elms" :key="elm.id">
                  <router-link :to="`/edit-elm/${elm.id}`">
                    <div class="manage-card-image">
                        <img
                          :src="elm.attributes.photo_url.large_version"
                          v-if="elm.attributes.photo_url.large_version"
                          alt="image"
                        />
                    </div>
                  </router-link>
                  <div class="manage-card-content">
                    <div class="manage-card-title">{{elm.attributes.name}}</div>
                    <p>{{elm.attributes.learning_objects_count}} Learning Objects</p>
                  </div>
                </div>
              </div>
            </v-infinite-scroll>
          </div>
        </div>
      </div>
    </div>
    <modal name="deleteWorldModal" height="auto" :width="350" :scrollable="true">
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          Are you sure you want to delete
          <span
            v-if="worldDetails.attributes"
          >{{worldDetails.attributes.name}} ?</span>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('deleteWorldModal')"
            class="btn medium default"
          >Cancel</button>
          <button
            type="button"
            @click="deleteWorld(),$modal.hide('deleteWorldModal')"
            class="btn medium primary"
          >ok</button>
        </div>
      </div>
    </modal>
    <WorldDetails v-on:worldDetail="worldDetail" />
    <OrganisationSidebar v-bind:worldDetails="worldDetails" />
  </div>
</template>
<script>
import OrganisationSidebar from "@/components/OrganisationSidebar.vue";
import WorldDetails from "@/components/modal/worldDetails.vue";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import api from "../services/api";
import Axios from "axios";

export default {
  name: "WorldDetail",
  metaInfo: {
    title: "World Details",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  components: {
    OrganisationSidebar,
    WorldDetails
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      id: this.$route.params.id,
      editWorldDetails: false,
      worldDetails: {},
      addedCustomer: {},
      addedOrgList: [],
      description: "",
      elms: [],
      totalElmCount: "",
      page: 1,
      totalPages: "",
      url: utilFunctionService.getImageUrl()
    };
  },
  methods: {
     downloadItem ({ url, label }) {
      Axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/json' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    exportWorld() {
      console.log("** exportWorld method");
      api
        .exportWorld(this.id)
        .then(() => {
          utilFunctionService.showSuccess(commonConfig.appMessages.worldExportQueued);
        })
        .catch((error) => {
         console.log("error::" + error)
        });
    },
    worldDetail() {
      api
        .getWorldDetails(this.id)
        .then(res => {
          this.worldDetails = {};
          this.worldDetails = res.data.data;
          this.description = this.worldDetails.attributes.description;
        })
        .catch();
    },
    getElms() {
      utilFunctionService.showLoader();
      api
        .getElmLists(this.id, this.page)
        .then(res => {
          utilFunctionService.hideLoader();
          this.elms = [...this.elms, ...res.data.data];
          this.totalElmCount = res.data.filtered_count;
          this.totalPages = res.data.total_pages;
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    deleteWorld() {
      api
        .deleteWorld(this.id)
        .then(() => {
          this.$router.push({ name: "worlds" });
        })
        .catch();
    },
    nextPage() {
      ++this.page;
      if (this.page <= this.totalPages) {
        this.getElms();
      } else {
        return;
      }
    }
  },
  created() {
    this.worldDetail();
    this.getElms();
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

.manage-content {
  width: 100%;
}
.manage-module {
  width: 100%;
  .manage-head {
    @include flexProperty(space-between, center);
    margin: 0 0 24px;
  }
  .manage-subtitle {
    @include font-medium;
    font-size: 19px;
    line-height: 1.05;
    margin: 15px 0 0;
  }
  .manage-btn {
    @include font-medium;
    padding: 5px 17px;
    border-radius: 6px;
    min-width: 100px;
    border: solid 2px $primary-button-color;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    position: relative;
    color: $primary-button-color;
    &:hover {
      .custom-tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .manage-scroller {
    max-height: 60vh;
    overflow: auto;
    @include media-breakpoint(xl) {
      margin: 0 -20px;
    }
  }
  .manage-row {
    width: 100%;
    @include flexProperty(null, null, null, wrap);
  }
  .manage-card {
    width: calc(33.33% - 10px);
    position: relative;
    margin: 0 5px 10px;
    flex: none;
    height: 22vw;
    @include media-breakpoint(xl) {
      width: calc(33.33% - 40px);
      margin: 0 20px 38px;
    }
  }
  .no-manage-text {
    color: $theme-color;
  }
  .manage-card-image {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    height: 100%;
    background: $theme-color
      url("../assets/images/ametros-logo-placeholder.png") no-repeat;
    background-size: 90px;
    background-position: center;
    img {
      @include imageSize;
    }
  }
  .manage-card-content {
    @include position(absolute, null, null, 20px, 20px);
  }
  .manage-card-title {
    @include font-medium;
    font-size: 17px;
    line-height: 1.35;
    color: $white;
  }
  p {
    font-size: 14px;
    margin: 8px 0 0;
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>
