var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "Organisation" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchCust,
                      expression: "searchCust",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:400ms",
                      value: _vm.searchCustomer,
                      expression: "searchCustomer",
                      arg: "400ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    "aria-describedby": "searchCust",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchCust },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchCust = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("createCustomer", {
                      customer_id: "",
                      tab: "address",
                    })
                  },
                },
              },
              [_vm._v("Add New Customer")]
            ),
          ]),
          _vm.totalCustomers > 1 && !_vm.searchCust
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " Customers in the system."
                ),
              ])
            : _vm.totalCustomers <= 1 && !_vm.searchCust
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " Customer in the system."
                ),
              ])
            : _vm.totalCustomers > 1 && _vm.searchCust
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " Customers in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalCustomers) +
                    " Customer in the search result."
                ),
              ]),
        ]),
        _vm.customersLists.length !== 0
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", { staticClass: "data-table data-column-5" }, [
                _c("div", { staticClass: "data-head customer-data-head" }, [
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" Customer "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "name" && _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("name", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("name", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Active Courses "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "active_courses" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("active_courses", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "active_courses" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("active_courses", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Active Learners "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "active_learners_count" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "active_learners_count",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "active_learners_count" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "active_learners_count",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col text-center" }, [
                    _vm._v(" Instructors "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "customer_instructors_count" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "customer_instructors_count",
                                "asc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "customer_instructors_count" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort(
                                "customer_instructors_count",
                                "desc"
                              )
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v(" CONTACTS "),
                  ]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("ACTIONS")]),
                ]),
                _c(
                  "div",
                  { staticClass: "data-items" },
                  _vm._l(_vm.customersLists, function (org) {
                    return _c(
                      "div",
                      {
                        key: org.id,
                        staticClass: "data-row customer-data-row",
                      },
                      [
                        _c("div", { staticClass: "data-col" }, [
                          _c("div", { staticClass: "data-image" }, [
                            _c(
                              "div",
                              { staticClass: "data-pic organise-pic cursor" },
                              [
                                org.attributes.photo_url
                                  ? _c("img", {
                                      attrs: {
                                        src: org.attributes.photo_url,
                                        alt: "customer image",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c("div", { staticClass: "data-text cursor" }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show("createCustomer", {
                                        customer_id: org.id,
                                        tab: "address",
                                      })
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(org.attributes.name))]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(_vm._s(org.attributes.active_courses)),
                        ]),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(_vm._s(org.attributes.active_learners_count)),
                        ]),
                        _c("div", { staticClass: "data-col text-center" }, [
                          _vm._v(
                            _vm._s(org.attributes.customer_instructors_count)
                          ),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "customer-dropdown customer-organise-dropdown",
                            },
                            [
                              org.attributes.contacts.length > 0
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          org.attributes.contacts[0].attributes
                                            .first_name
                                        ) +
                                        " "
                                    ),
                                    org.attributes.contacts.length > 1
                                      ? _c("span", [
                                          _vm._v(
                                            ", + " +
                                              _vm._s(
                                                org.attributes.contacts.length -
                                                  1
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              org.attributes.contacts.length === 0
                                ? _c("span", [_vm._v("-")])
                                : _vm._e(),
                              org.attributes.contacts.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-right",
                                    },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          "Contact(" +
                                            _vm._s(
                                              org.attributes.contacts.length
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                      _c(
                                        "VuePerfectScrollbar",
                                        {
                                          staticClass: "scroll-area",
                                          attrs: { settings: _vm.settings },
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "dropdown-list" },
                                            _vm._l(
                                              org.attributes.contacts,
                                              function (customer) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: customer.id,
                                                    staticClass:
                                                      "dropdown-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-content",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "user-name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                customer
                                                                  .attributes
                                                                  .first_name
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  customer
                                                                    .attributes
                                                                    .last_name
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "user-role",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                customer
                                                                  .attributes
                                                                  .contact_role_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "data-col data-action" }, [
                          _c("div", { staticClass: "data-dropdown" }, [
                            _vm._m(1, true),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                              },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show(
                                              "createCustomer",
                                              {
                                                customer_id: org.id,
                                                tab: "address",
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show(
                                              "createCustomer",
                                              {
                                                customer_id: org.id,
                                                tab: "contact",
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Manage Contacts")]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show(
                                              "createCustomer",
                                              {
                                                customer_id: org.id,
                                                tab: "instructor",
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Manage Instructors")]
                                    ),
                                  ]),
                                  _vm._m(2, true),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-error",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteCustomer(org.id)
                                          },
                                        },
                                      },
                                      [_vm._v("Delete")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (pages) {
                            return _c(
                              "li",
                              { key: pages, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    class: [pages === _vm.page ? "active" : ""],
                                    on: {
                                      click: function ($event) {
                                        return _vm.getCustomers(pages)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(pages))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.customersLists.length === 0 && !_vm.searchCust && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no customer in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("createCustomer", {
                          customer_id: "",
                        })
                      },
                    },
                  },
                  [_vm._v("Add a new customer")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.customersLists.length === 0 && _vm.searchCust
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c("createCustomer"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("Customers")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-menu" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("a", [_vm._v("Manage Courses")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }