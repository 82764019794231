var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "deleteLlmSkillSetModal",
        width: 400,
        height: 400,
        adaptive: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content modal-default" }, [
        _c("h3", [
          _vm._v(" Are you sure you want to delete this LLM Skill Set? "),
        ]),
        _c("button", { on: { click: _vm.deleteLlmSkillSet } }, [_vm._v("Yes")]),
        _c("button", { on: { click: _vm.closeModal } }, [_vm._v("No")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }