var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "CharacterRoles" } },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("header", { staticClass: "header" }, [
            _vm._m(0),
            _c("div", { staticClass: "header-right" }, [
              _c("form", { staticClass: "header-form" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchCharacterRoles,
                        expression: "searchCharacterRoles",
                      },
                      {
                        name: "debounce",
                        rawName: "v-debounce:400ms",
                        value: _vm.search,
                        expression: "search",
                        arg: "400ms",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      "aria-describedby": "searchCharacterRoles",
                      placeholder: "Search",
                    },
                    domProps: { value: _vm.searchCharacterRoles },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchCharacterRoles = $event.target.value
                      },
                    },
                  }),
                  _c("em", { staticClass: "icon-search" }),
                ]),
              ]),
              _c(
                "a",
                {
                  staticClass: "btn header-btn primary",
                  on: {
                    click: function ($event) {
                      return _vm.$modal.show("createCharacterRole")
                    },
                  },
                },
                [_vm._v("Add a new Character Role")]
              ),
            ]),
            _vm.totalCharacterRoles > 1 && !_vm.searchCharacterRoles
              ? _c("p", [
                  _vm._v(
                    "You have " +
                      _vm._s(_vm.totalCharacterRoles) +
                      " Character Roles in the system."
                  ),
                ])
              : _vm.totalCharacterRoles <= 1 && !_vm.searchCharacterRoles
              ? _c("p", [
                  _vm._v(
                    "You have " +
                      _vm._s(_vm.totalCharacterRoles) +
                      " Character Role in the system."
                  ),
                ])
              : _vm.totalCharacterRoles > 1 && _vm.searchCharacterRoles
              ? _c("p", [
                  _vm._v(
                    "You have " +
                      _vm._s(_vm.totalCharacterRoles) +
                      " Character Roles in the search result."
                  ),
                ])
              : _c("p", [
                  _vm._v(
                    "You have " +
                      _vm._s(_vm.totalCharacterRoles) +
                      " Character Role in the search result."
                  ),
                ]),
          ]),
          _c("div", { staticClass: "data-content" }, [
            _c(
              "div",
              { staticClass: "data-table data-column-3" },
              [
                _c("div", { staticClass: "data-head" }, [
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v("Name "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "name" && _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("name", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "name" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("name", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [
                    _vm._v("Created on "),
                    _c("span", { staticClass: "data-sort" }, [
                      _c(
                        "a",
                        {
                          staticClass: "asc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "asc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "asc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-up" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "desc",
                          class: [
                            _vm.sortColumn === "created_at" &&
                            _vm.sortOrder === "desc"
                              ? "active"
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.toggleSort("created_at", "desc")
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-carrot-down" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "data-col" }, [_vm._v("Actions")]),
                ]),
                _vm._l(_vm.characterRolesList, function (role) {
                  return _c(
                    "div",
                    { key: role.id, staticClass: "data-items" },
                    [
                      _c("div", { staticClass: "data-row" }, [
                        _c("div", { staticClass: "data-col" }, [
                          _c("div", [_vm._v(_vm._s(role.attributes.name))]),
                        ]),
                        _c("div", { staticClass: "data-col" }, [
                          _c("div", [
                            _vm._v(_vm._s(role.attributes.created_on)),
                          ]),
                        ]),
                        _c("div", { staticClass: "data-col data-action" }, [
                          _c("div", { staticClass: "data-dropdown" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn dropdown-toggle",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$modal.show(
                                      "editCharacterRole",
                                      { role_id: role.id, page: _vm.page }
                                    )
                                  },
                                },
                              },
                              [_c("em", { staticClass: "icon-edit" })]
                            ),
                          ]),
                          _c("div", { staticClass: "data-delete-dropdown" }, [
                            _vm._m(1, true),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "data-popper-title" },
                                  [
                                    _vm._v(
                                      "Are you sure you want to delete this character Role?"
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "data-popper-footer" },
                                  [
                                    _c("a", { staticClass: "btn default" }, [
                                      _vm._v("cancel"),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deletecharacterRole(
                                              role.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Ok")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm.totalPages.length > 1
            ? _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPages.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPages.slice(_vm.page - 1, _vm.page + 3),
                          function (pages) {
                            return _c(
                              "li",
                              { key: pages, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    class: [pages === _vm.page ? "active" : ""],
                                    on: {
                                      click: function ($event) {
                                        return _vm.getCharacterRoles(pages)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(pages))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPages.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.characterRolesList.length === 0 &&
          !_vm.searchStatus &&
          _vm.display
            ? _c("div", { staticClass: "data-no-result" }, [
                _c("h3", [_vm._v("There’s nothing here!")]),
                _c("p", [
                  _vm._v("You have no character Roles in the system yet."),
                ]),
                _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "btn header-btn primary",
                      on: {
                        click: function ($event) {
                          return _vm.show("createCharacterRole")
                        },
                      },
                    },
                    [_vm._v("Add a character Roles")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.characterRolesList.length === 0 && _vm.searchStatus
            ? _c("div", { staticClass: "data-no-result" }, [
                _c("h3", [_vm._v("No results found")]),
                _c("p", [_vm._v("Try using a different keyword")]),
              ])
            : _vm._e(),
          _c(
            "modal",
            {
              attrs: {
                name: "createCharacterRole",
                height: "auto",
                scrollable: true,
              },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "exampleModalLabel" },
                    },
                    [_c("h5", [_vm._v("Create a new Character Role")])]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.hide("createCharacterRole")
                        },
                      },
                    },
                    [_c("em", { staticClass: "icon-close" })]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c("ValidationObserver", { ref: "form" }, [
                      _c("form", { staticClass: "modal-form scheme-form" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "name" },
                              },
                              [_vm._v("Name")]
                            ),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Name",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "name",
                                          id: "name",
                                          "aria-describedby": "emailHelp",
                                        },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.name = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn default",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.hide("createCharacterRole")
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn primary",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "data-toggle": "modal",
                                "data-target": "#addOrganisation",
                              },
                              on: { click: _vm.saveCharacterRole },
                            },
                            [_vm._v("Save")]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("editCharacterRole"),
      _vm._v("> "),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("All Character Roles")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("em", { staticClass: "icon-delete" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }