<template>
    <div class="header-dropdown">
        
        <div class="">
            <ul>
                
                <li>
                <a @click="logOut()">Log out</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import { utilFunctionService } from "@/utils/utils.service"
    export default {
        name : 'header-dropdown',
        methods: {
            logOut() {
                utilFunctionService.removeLocalStorageService("user");
                this.$router.push({ name: "login" });
            }
        }
    }
</script>
