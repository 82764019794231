<template>
  <div id="Users">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Users</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchUsers"
                placeholder="Search"
                v-model="searchUsers"
                v-debounce:400ms="search"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <a
            @click="$modal.show('createUser', { user_id: '', page: 1 })"
            class="btn header-btn primary"
            >Add a new User</a
          >
        </div>
        <p v-if="totalUsers > 1 && !searchUsers">
          You have {{ totalUsers }} Users in the system.
        </p>
        <p v-else-if="totalUsers <= 1 && !searchUsers">
          You have {{ totalUsers }} User in the system.
        </p>
        <p v-else-if="totalUsers > 1 && searchUsers">
          You have {{ totalUsers }} Users in the search result.
        </p>
        <p v-else>You have {{ totalUsers }} User in the search result.</p>
      </header>
      <div class="data-content" v-if="usersList.length !== 0">
        <div class="data-table data-column-6">
          <div class="data-head">
            <div class="data-col">
              First Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'first_name' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('first_name', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'first_name' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('first_name', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Last Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'last_name' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('last_name', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'last_name' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('last_name', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Email
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'email' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('email', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'email' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('email', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Role(s)
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'roles' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('roles', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'roles' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('roles', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Created
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'created_at' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('created_at', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'created_at' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('created_at', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Actions</div>
            <div class="data-col">LTI Login</div>
          </div>
          <div class="data-items" v-for="user in usersList" :key="user.id">
            <div class="data-row">
              <div class="data-col">
                <div class="data-image">
                  <div
                    class="data-pic circle cursor"
                    @click="$modal.show('userDetail', { user_id: user.id })"
                  >
                    <img
                      :src="user.attributes.photo_url.thumbnail"
                      v-if="user.attributes.photo_url"
                      alt
                    />
                  </div>
                  <div
                    class="data-text cursor"
                    @click="$modal.show('userDetail', { user_id: user.id })"
                  >
                    {{ user.attributes.first_name }}
                  </div>
                </div>
              </div>
              <div class="data-col">{{ user.attributes.last_name }}</div>
              <div class="data-col">{{ user.attributes.email }}</div>
              <div class="data-col">{{ user.attributes.roles }}</div>
              <div class="data-col">{{ user.attributes.created_at }}</div>

              <div class="data-col data-action">
                <div class="data-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em
                      class="icon-edit"
                      @click="
                        $modal.show('createUser', {
                          user_id: user.id,
                          page: page,
                        })
                      "
                    ></em>
                  </button>
                </div>
              </div>
              <div class="data-col data-action" v-if="user.attributes.lti_link">
                <div class="data-dropdown">
                  <a
                    :href="user.attributes.lti_link"
                    type="button"
                    class="btn dropdown-toggle"
                    target="_blank"
                  >
                    <em
                      class="icon-user"
                    ></em>
                    LTI log in
                  </a>
                </div>
              </div>
              <div class="data-col data-action" v-else>
                <p>No LTI log in available</p>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">
            Showing {{ page }} of {{ totalPages.length }}
          </div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages.slice(page - 1, page + 3)"
                :key="page"
              >
                <a class="page-link active" @click="getUsers(page)">{{
                  page
                }}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="data-no-result"
        v-if="usersList.length === 0 && !searchStatus && display"
      >
        <h3>There’s nothing here!</h3>
        <p>You have no User in the system yet.</p>
        <div>
          <a
            @click="$modal.show('createUser', { user_id: '', page: 1 })"
            class="btn header-btn primary"
            >Add a new User</a
          >
        </div>
      </div>
      <div class="data-no-result" v-if="usersList.length === 0 && searchStatus">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <userDetail />
    <createUser />
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import api from "../services/api";
import userDetail from "../components/modal/UserDetail.vue";
import createUser from "../components/modal/CreateUser.vue";

export default {
  name: "Users-component",
  components: {
    userDetail,
    createUser,
  },
  metaInfo: {
    title: "All Users",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      selectedFile: null,
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      totalUsers: "",
      searchUsers: "",
      usersList: [],
      totalPages: [],
      page: 1,
      sortColumn: "id",
      sortOrder: "desc",
      display: false,
      searchStatus: false,
      isActive: false,
      user_id: "",
    };
  },
  methods: {
    show() {
      this.$modal.show();
      this.isModalVisible = true;
    },
    clearFormData() {
      this.selectedFile = null;
      this.image = null;
      this.first_name = "";
      this.last_name = "";
      this.email = "";
    },
    search(value) {
      const environment = process.env.NODE_ENV;
      let lti_origin = "";
      if (environment === 'production') lti_origin = 'https://student.ametroslearning.com'
      else lti_origin = 'https://stage-learner.ametroslearning.com'
      api
        .getAllUsers(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then((res) => {
          this.searchStatus = true;
          this.usersList = res.data.data;
          this.usersList.forEach((user) => {
            let userID = user.id;
            let launchToken = user.attributes.lti_info[0].launch_token;
            user.attributes.lti_link = `${lti_origin}/#/login?id=${userID}&token=${launchToken}`;
          })
          this.totalUsers = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    nextPage() {
      this.page = this.page + 1;
      this.getUsers(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getUsers(this.page);
    },
    hide(modal) {
      this.clearFormData();
      this.$modal.hide(modal);
      this.isModalVisible = false;
    },
    saveUser() {
      this.$refs.form.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          utilFunctionService.showLoader();
          const fb = new FormData();

          fb.append("user[first_name]", this.first_name);
          fb.append("user[last_name]", this.last_name);
          fb.append("user[email]", this.email);
          fb.append("user[password]", this.password);
          if (this.selectedFile) {
            fb.append("user[photo]", this.selectedFile);
          }
          api
            .saveUser(fb)
            .then(() => {
              this.clearFormData();
              utilFunctionService.hideLoader();
              utilFunctionService.showSuccess(
                commonConfig.appMessages.userCreate
              );
              this.getusers(this.page);
              this.hide("createUser");
            })
            .catch(() => {
              utilFunctionService.hideLoader();
              utilFunctionService.showErr(commonConfig.appMessages.error);
            });
        }
      });
    },
    getUsers(page) {
      this.page = page;
      utilFunctionService.showLoader();
      const environment = process.env.NODE_ENV;
      let lti_origin = "";
      if (environment === 'production') lti_origin = 'https://student.ametroslearning.com'
      else lti_origin = 'https://stage-learner.ametroslearning.com'
      api
        .getAllUsers(page, this.searchUsers, this.sortColumn, this.sortOrder)
        .then((res) => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.searchStatus = false;
          this.usersList = res.data.data;
          this.usersList.forEach((user) => {
            if (user.attributes.lti_info && user.attributes.lti_info.length > 0) {
              let userID = user.attributes.lti_info[0].id;
              let launchToken = user.attributes.lti_info[0].launch_token;
              user.attributes.lti_link = `${lti_origin}/#/login?id=${userID}&token=${launchToken}`;
            }
          })
          this.totalUsers = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    toggleSort(colName, sortType) {
      let _colName = colName;
      if (colName === "first_name") {
        colName = "first_name,last_name";
      }
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getUsers(this.page);
      this.sortColumn = _colName;
    },
  },
  created() {
    this.getUsers(this.page);
  },
};
</script>
