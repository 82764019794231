var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "LearnerLO" } },
    [
      _c(
        "div",
        { staticClass: "container-3-column" },
        [
          _c("header", { staticClass: "header" }, [
            _c("div", { staticClass: "header-left" }, [
              _c("h2", [
                _vm._v(
                  "LO " +
                    _vm._s(_vm.learnObjData.attributes.card_order) +
                    ": " +
                    _vm._s(_vm.learnObjData.attributes.name)
                ),
              ]),
            ]),
          ]),
          _vm.showDialogic == true
            ? _c("learnerDialogic", {
                attrs: {
                  userLearnObj: _vm.userLearnObjData,
                  adminLearnerLoDetails: _vm.LOAdminDetails,
                },
              })
            : _vm._e(),
          _vm.showQuiz == true
            ? _c("learnerQuiz", {
                attrs: {
                  userLearnObj: _vm.userLearnObjData,
                  adminLearnerLoDetails: _vm.LOAdminDetails,
                },
              })
            : _vm._e(),
          _vm.showEmail == true
            ? _c("learnerEmail", {
                attrs: {
                  userLearnObj: _vm.userLearnObjData,
                  adminLearnerLoDetails: _vm.LOAdminDetails,
                },
              })
            : _vm._e(),
          _vm.showLlmDialogic == true
            ? _c("LearnerLlmDialogic", {
                attrs: {
                  userLearnObj: _vm.userLearnObjData,
                  adminLearnerLoDetails: _vm.LOAdminDetails,
                },
              })
            : _vm._e(),
          _vm.showLlmEmail == true
            ? _c("LearnerLlmEmail", {
                attrs: {
                  userLearnObj: _vm.userLearnObjData,
                  adminLearnerLoDetails: _vm.LOAdminDetails,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showSidebar == true
        ? _c("learnerLOSidebar", {
            ref: "sideBar",
            attrs: { adminLearnerLODetails: _vm.LOAdminDetails },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }