<template>
  <div class="dropdown-menu data-popover-dropdown dropdown-menu-right" aria-labelledby="menu1">
    <div class="data-popover">
      <div class="data-popover-title">
        <span>Add Tags</span>
        <a class="data-popover-close">
          <em class="icon-close"></em>
        </a>
      </div>
      <form class="data-popover-form">
        <div class="form-group">
          <textarea class="form-control" rows="4" v-model="docData.notes"></textarea>
        </div>
        <div class="form-group-button">
          <button type="button" class="btn btn-remove" @click="onSubmit('remove')">Remove</button>
          <button type="button" class="btn btn-save" @click="onSubmit('save')">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import api from "@/services/api";

export default {
  name: "notesModal",
  data() {
    return {
      id: "",
      type: "",
      notes: "",
      page: "",
      docData: ""
    };
  },
  props: ["method", "doc"],
  created() {
    this.docData = this.doc;
    this.id = this.docData.id;
    this.type = this.docData.type;
    this.notes = this.docData.notes;
    this.page = this.docData.page;
  },
  methods: {
    onSubmit(type) {
      const fb = new FormData();
      if (this.type === "document") {
        fb.append("global_resource[resource_type]", this.type);
        fb.append(
          "global_resource[tag_list]",
          type === "save" ? this.docData.notes : ""
        );
        fb.append("id", this.id);
        api
          .editGlobalResource(this.id, fb)
          .then(() => {
            if (type === "save") {
              utilFunctionService.showSuccess(commonConfig.appMessages.addTag);
            } else {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.removeTag
              );
            }
            this.method(this.page);
          })
          .catch(() => {
            utilFunctionService.showErr(commonConfig.appMessages.error);
            this.method(this.page);
          });
      }
      if (this.type === "global_video") {
        fb.append(
          "global_video[tag_list]",
          type === "save" ? this.docData.notes : ""
        );
        fb.append("id", this.id);
        api
          .editGlobalVideo(this.id, fb)
          .then(() => {
            if (type === "save") {
              utilFunctionService.showSuccess(commonConfig.appMessages.addTag);
            } else {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.removeTag
              );
            }
            this.method(this.page);
          })
          .catch(() => {
            utilFunctionService.showErr(commonConfig.appMessages.error);
            this.method(this.page);
          });
      }
      if (this.type !== "global_video" && this.type !== "document") {
        fb.append("global_resource[resource_type]", this.type);
        fb.append(
          "global_resource[tag_list]",
          type === "save" ? this.docData.notes : ""
        );
        fb.append("id", this.id);
        api
          .editGlobalResource(this.id, fb)
          .then(() => {
            if (type === "save") {
              utilFunctionService.showSuccess(commonConfig.appMessages.addTag);
            } else {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.removeTag
              );
            }
            this.method(this.page, type === "save" ? this.docData.notes : "");
          })
          .catch(() => {
            utilFunctionService.showErr(commonConfig.appMessages.error);
            this.method(this.page, this.docData.notes);
          });
      }
    }
  }
};
</script>
