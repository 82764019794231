var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "EditVideo",
        width: 700,
        height: "auto",
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [_c("h5", [_vm._v("Edit Video")])]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("EditVideo")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-body mt-3" },
          [
            _c("ValidationObserver", { ref: "form" }, [
              _c(
                "form",
                {
                  staticClass: "modal-form organisation-form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.formSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Card Title"),
                        ]),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "Card Title",
                            rules: "required|max:100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.title,
                                        expression: "title",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "title",
                                      id: "title",
                                      "aria-describedby": "emailHelp",
                                    },
                                    domProps: { value: _vm.title },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.title = $event.target.value
                                      },
                                    },
                                  }),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group col" },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("Wistia Code"),
                        ]),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "Wistia Code",
                            rules: "required|max:100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.wistia_code,
                                        expression: "wistia_code",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.isActive && errors[0]
                                        ? "invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      type: "wistia_code",
                                      id: "wistia_code",
                                      "aria-describedby": "emailHelp",
                                    },
                                    domProps: { value: _vm.wistia_code },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.wistia_code = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm.wistia_code
                                    ? _c(
                                        "span",
                                        {
                                          class: ["cursor", "success"],
                                          on: {
                                            click: function ($event) {
                                              return _vm.getWistiaInfo()
                                            },
                                          },
                                        },
                                        [_vm._v("Fetch Video Meta Info")]
                                      )
                                    : _vm._e(),
                                  errors[0]
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.isActive ? "invalid" : "",
                                          ],
                                          style: [
                                            _vm.isActive
                                              ? { display: "block" }
                                              : { display: "none" },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errors[0].replace("The ", "")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Transcript Description"),
                      ]),
                      _c("vue-editor", {
                        attrs: { editorToolbar: _vm.fullToolbar },
                        model: {
                          value: _vm.transcript,
                          callback: function ($$v) {
                            _vm.transcript = $$v
                          },
                          expression: "transcript",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Video Duration"),
                      ]),
                      _c("ValidationProvider", {
                        attrs: { name: "Video Duration", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.duration,
                                      expression: "duration",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: [
                                    _vm.isActive && errors[0] ? "invalid" : "",
                                  ],
                                  attrs: {
                                    type: "duration",
                                    "aria-describedby": "emailHelp",
                                    readonly: "",
                                  },
                                  domProps: { value: _vm.duration },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.duration = $event.target.value
                                    },
                                  },
                                }),
                                errors[0]
                                  ? _c(
                                      "span",
                                      {
                                        class: [_vm.isActive ? "invalid" : ""],
                                        style: [
                                          _vm.isActive
                                            ? { display: "block" }
                                            : { display: "none" },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(errors[0].replace("The ", ""))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-group organisation-image-group flex-wrap mt-0",
                    },
                    [
                      _c("label", { staticClass: "form-label w-100" }, [
                        _vm._v("Wistia Thumbnail"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "organisation-image-pic cursor",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.fileInput.click()
                            },
                          },
                        },
                        [
                          _vm.image
                            ? _c("img", {
                                attrs: { src: _vm.image, alt: "images" },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "organisation-image-remove" }, [
                        _c("input", {
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "image/*" },
                          on: { change: _vm.onFile },
                        }),
                        !_vm.image
                          ? _c(
                              "a",
                              {
                                staticClass: "btn add",
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.fileInput.click()
                                  },
                                },
                              },
                              [_vm._v("Add Photo")]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "btn delete",
                                on: { click: _vm.removeImage },
                              },
                              [_vm._v("Remove Photo")]
                            ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-group organisation-image-group d-flex flex-wrap mt-0 flex-row align-items-center",
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label instructor-resource-label" },
                        [_vm._v("Show in Instructor Resources?")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.showInstructorResource,
                            expression: "showInstructorResource",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.showInstructorResource)
                            ? _vm._i(_vm.showInstructorResource, null) > -1
                            : _vm.showInstructorResource,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.showInstructorResource,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.showInstructorResource = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.showInstructorResource = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.showInstructorResource = $$c
                            }
                          },
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn default",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#EditVideo",
                          "data-dismiss": "modal",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.hide("EditVideo")
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      { staticClass: "btn primary", attrs: { type: "submit" } },
                      [_vm._v("Save")]
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }