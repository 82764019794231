<template>
  <div id="WatsonAdmin">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>Watson Admin</h2>
        </div>
        
        
      </header>
      
      <div class="content-container">


        <div class="row">
          <div class="col-6">

            <h3>Watson Assistant status check</h3>
            <button type="button" @click="watsonAssistantCheck()" class="btn primary mt-5">Check status</button>
            <div></div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "WatsonAdmin",
  components: {},
  data() {
    return {
      learning_object_id: 65
    };
  },
  methods: {
    watsonAssistantCheck(){
      const fb = new FormData();
      fb.append("learning_object_id", this.learning_object_id);
      api.watsonAssistantStatusCheck(fb).then(res => {
        if (res.data.success) {
          utilFunctionService.showSuccess(commonConfig.appMessages.watsonAssistantSuccess)
        } else {
          utilFunctionService.showErr(commonConfig.appMessages.watsonAssistantError)
        }
      }).catch(() => {
        utilFunctionService.showErr(commonConfig.appMessages.watsonAssistantError)
      })
    },
  },
  created() {
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

</style>
