<template>
    <modal name="characterdetail" :width="430" height="auto" :scrollable="true" @before-open="beforeOpen">
      <div class="modal-content modal-edit-character">
        <div class="modal-body">
          <div class="modal-edit-image">
            <img :src="photo_url.large_version" v-if="photo_url" alt="image"/>
            <button type="button" class="close" @click="hide('characterdetail')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-edit-title">{{full_name}}</div>
          <div class="modal-edit-text">{{gender | uppercase(true)}}, {{age}}</div>
          <p>Created on {{created_on}}</p>
        </div>
        <div class="modal-footer">
            <div class="data-delete-dropdown">
              <button type="button" class="btn danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Delete
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="data-popper-title">
                  Are you sure you want to delete this character?
                </div>
                <div class="data-popper-footer">
                  <a class="btn default">cancel</a>
                  <a class="btn primary" @click="deleteCharacter(character_id)">Ok</a>
                </div>
              </div>
            </div>
          <button type="button" class="btn primary" @click="show('editCharacter',{character_id : character_id })">Edit Character</button>
        </div>
      </div>
    </modal>
</template>
<script>
import api from '../../services/api'
import { utilFunctionService } from "@/utils/utils.service";
export default {
    name: 'character-detail',
    props: {
      method: { type: Function },
    },
    filters: {
    uppercase: function(value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
    data() {
        return {
            full_name: '',
            gender:'',
            photo_url : '',
            real_world: '',
            created_on: '',
            age : '',
            character_id : '',
            url: utilFunctionService.getImageUrl()
        }
    },
    methods: {
        beforeOpen (event) {
            console.log(event, "Character Detail event");
            this.character_id = event.params.character_id
            this.getCharactersDetails(this.character_id)
        },
        getCharactersDetails(character_id)
        {
            api
            .getCharactersDetails(character_id)
            .then(res => {
            this.full_name = res.data.data.attributes.full_name
            this.gender = res.data.data.attributes.gender
            this.photo_url = res.data.data.attributes.photo_url
            this.created_on = res.data.data.attributes.created_on
            this.age = res.data.data.attributes.age
            })
            .catch();
        },
        deleteCharacter(id) {
            this.$parent.deleteCharacter(id);
            this.$parent.hide('characterdetail');
        },
        hide(modal) {
          this.clearFormdata()
          this.$parent.hide(modal)
        },
        clearFormdata() {
          this.full_name=  '';
          this.gender=  '';
          this.photo_url=  '';
          this.real_world=  '';
          this.created_on=  '';
          this.age=  '';
          this.character_id=  '';
        },
        show (model,params) {
          this.hide("characterdetail");
          this.$modal.show(model,params);
        }
    }
};
</script>
