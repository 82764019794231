var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "QaEmail" } }, [
    _c(
      "div",
      { staticClass: "admin-review-quiz" },
      [
        _c("h1", { staticClass: "learn-title" }, [
          _vm._v(_vm._s(_vm.quizName)),
        ]),
        _c("p", { staticClass: "learn-info voice-info" }, [
          _vm._v(_vm._s(_vm.quizDesc)),
        ]),
        _vm._l(_vm.questions, function (question) {
          return _c("div", { key: question.id }, [
            _c("div", { staticClass: "quiz-object" }, [
              _c("div", {}, [
                _c("div", { staticClass: "learn-object-info" }, [
                  _c("h4", {
                    domProps: {
                      innerHTML: _vm._s(question.attributes.question),
                    },
                  }),
                ]),
                _c("div", { staticClass: "quiz-response" }, [
                  question.answer
                    ? _c("p", [_vm._v(_vm._s(question.answer))])
                    : _c("p", [_vm._v("No answer provided")]),
                ]),
              ]),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }