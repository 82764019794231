<template>
  <div id="QaEmail">
    <div class="admin-review-quiz">
      <h1 class="learn-title">{{ quizName }}</h1>
      <p class="learn-info voice-info">{{ quizDesc }}</p>
      <div v-for="question in questions" :key="question.id">
        <div class="quiz-object">
          <div class="">
            <div class="learn-object-info">
              <h4 v-html="question.attributes.question"></h4>
            </div>
            <div class="quiz-response">
              <p v-if="question.answer">{{question.answer}}</p>
              <p v-else>No answer provided</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
export default {
  name: "LearnerQuiz",
  props: ["userLearnObj"],
  data() {
    return {
      quizName: "",
      quizDesc: "",
      module_id: "",
      questions: "",
    };
  },
  methods: {
    getQuizQuestions(res, userLoId) {
      this.userLoId = userLoId;
      this.questionId = 0;
      api
        .quizQuestions(
          res.attributes.learning_object.attributes.card_detail.id,
          userLoId
        )
        .then((x) => {
          this.questions = x.data.questions.data;
          this.questions.map((x) => {
            x.answer = "";
            x.status = false;
            return x;
          });
          console.log(this.questions, "*** this.questions");
          if (
            typeof x.data.responses.data === "object" &&
            x.data.responses.data.id
          ) {
            x.data.responses.data[0] = x.data.responses.data;
          }
          if (
            x.data.responses.data[0] &&
            x.data.responses.data[0].attributes.quiz_responses.length > 0
          ) {
            x.data.responses.data[0].attributes.quiz_responses.forEach(
              (element) => {
                const index = this.questions.findIndex((elem) => {
                  return (
                    parseInt(elem.id) ===
                    parseInt(element.attributes.quiz_question_id)
                  );
                });
                if (index !== -1) {
                  this.questions[index].complete = true;
                  this.questions[index].status = true;
                  this.questions[index].quizEvaluationId =
                    x.data.responses.data[0].id;
                  this.questions[index].quizId = element.id;
                  this.quizEvaluationId = x.data.responses.data[0].id;
                  if (
                    this.questions[index].attributes.question_type ==
                    "multiple_choice"
                  ) {
                    this.questions[index].answer =
                      element.attributes.learner_chosed_option.id;
                    this.questions[index].mcqStatus =
                      element.attributes.learner_chosed_option.id;
                  } else {
                    this.questions[index].answer =
                      element.attributes.learner_answer;
                  }
                }
                if (
                  x.data.responses.data[0].attributes.quiz_responses.length ==
                  this.questions.length
                ) {
                  this.leftQuizId =
                    x.data.questions.data[
                      x.data.responses.data[0].attributes.quiz_responses
                        .length - 1
                    ].id;
                  this.resultStatus = true;
                  this.questionId = this.questions.length - 1;
                } else {
                  this.leftQuizId =
                    x.data.questions.data[
                      x.data.responses.data[0].attributes.quiz_responses.length
                    ].id;
                  this.resultStatus = false;
                  this.questionId =
                    x.data.responses.data[0].attributes.quiz_responses.length;
                }
              }
            );
          } else {
            this.resultStatus = false;
          }
        });
    },
  },
  created() {
    this.getQuizQuestions(this.userLearnObj, this.userLearnObj.id);
  },
};
</script>