var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "editCharacterRole",
        width: 430,
        height: "auto",
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [_c("h5", [_vm._v("Edit Character Role")])]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("editCharacterRole")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("ValidationObserver", { ref: "form" }, [
              _c("form", { staticClass: "modal-form scheme-form" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label mb-2",
                        attrs: { for: "name" },
                      },
                      [_vm._v("Name")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "Name", rules: "required|max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "name",
                                  id: "name",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.name = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: {
                        click: function ($event) {
                          return _vm.hide("editCharacterRole")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        "data-toggle": "modal",
                        "data-target": "#addOrganisation",
                      },
                      on: { click: _vm.updateCharacterRole },
                    },
                    [_vm._v("Save")]
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }