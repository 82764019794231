export const commonConfig = {

  routerName: {
    login: '/login',
  },
  httpCodes: {
    unauthorize: 401,
    badReqestError: 400,
    notFound: 404,
    forbidden: 403,
    badRequestError: 400,
    ok: 200,
    internalServerError: 500,
    status: 'ok',
    code: 422,
    badGateway: 502,
  },
  apiName: {
    login: '/sign_in',
    logout: '/sign_out'
  },
  baseUrl: {
    user: 'users'
  },
  appMessages: {
    loginSuccess: 'You are successfully logged in',
    logoutSuccess: 'Signout successfully',
    worldCreate: 'World has been created successfully',
    deleteWorld: 'World has been deleted successfully',
    deleteOrg: 'Organization has been deleted successfully',
    sessionExpire: "Signature has expired.Please login again",
    editWorld: "World has been edited successfully",
    characterCreate: 'Character has been created successfully',
    editCharacter: 'Character has been edited successfully',
    deletecharacter: 'Character has been deleted successfully',
    orgCreate: 'Organization has been created successfully',
    editOrg: 'Organization has been edited successfully',
    error: 'Internal Server Error',
    deletecharacterError: `Character exists in world, can't be deleted`,
    successAssingOrgnization: 'Character has been assigned successfully',
    failAssingOrgnization: 'This character already assigned',
    addDoc: 'Document has been Added successfully',
    createElm: 'ELM has been created successfully',
    deleteElm: 'ELM has been deleted successfully',
    editElm: 'ELM has been edited successfully',
    errDoc: `Document can't be added`,
    deleteDocument: 'Document has been deleted successfully',
    addTag: 'Tag has been added successfully',
    removeTag: `Tag has been remove successfully`,
    addVideo: 'Video has been added successfully',
    editVideo: 'Video has been updated successfully',
    deleteVideo: 'Video has been deleted successfully',
    errDeleteVideo: `This video is being used in a learning module, can't be deleted.`,
    addNote: 'Note has been added successfully',
    removeNote: 'Note has been removed successfully',
    addImage: 'Image has been added successfully',
    removeImage: 'Image has been removed successfully',
    errImageUpload: 'Image has not been uploaded successfully',
    docUploadLimit: 'More than 5 introductory document has not been added',
    contentText: 'Content text has been added successfully',
    LoArchiveText: 'Learning Object has been archived successfully',
    LoDraftText: 'Learning Object has been saved successfully',
    LoPublichedText: 'Learning Object has been published successfully',
    LoRestoredText: 'Learning Object has been restored successfully',
    removeSliderImage: 'Image has been remove successfully',
    elmPublish: 'ELM has been published successfully',
    deleteAssessment: 'Assessment scheme has been removed successfully',
    createAssessment: 'Assessment scheme has been created successfully',
    updatedAssessment: 'Assessment scheme has been updated successfully',
    uploadCsv: 'CSV has been imported successfully',
    invalidCsv: 'Invalid CSV format',
    updateSynonymList: 'Synonym has been updated successfully',
    assestEntityErr: 'Entity cannot be empty',
    assestEntityAdd: 'Entity value has been added successfully',
    assestEntityUpdated: 'Entity value has been updated successfully',
    assestIntentErr: 'Intent cannot be empty',
    updatedError: 'Unable to update data',
    nameTakenErr: 'This name is already taken.',
    sameSynonymList: 'Synonym name cannot be same',
    duplicateSynonymList: 'Cannot save a duplicate synonym',
    entityNameValueError: "Entities can only contain word, underscore or hyphen characters, and must not start with a prefix of 'sys-'",
    syncAssistantSuccess: 'Synced with assistant',
    feedbackUpdateErr: 'Unable to update feedback',
    editDashboardConfig: 'Content has been updated successfully',
    createDashboardConfig: 'Content has been saved successfully',
    updateTabError: 'Please fill form before proceeding',
    characterLimit: 'You can not add more than 5 characters',
    nluTrainingInputs: 'Please fill all the NLU training description',
    jsonCopied: 'JSON copied successfully',
    shortcodeCopied: 'Shortcode copied successfully',
    deleteQuestion: 'Question has been removed successfully',
    createQuestion: 'Question has been created successfully',
    updatedQuestion: 'Question has been updated successfully',
    createIntroduction: 'Introduction has been saved successfully',
    updateIntroduction: 'Introduction has been updated successfully',
    createConclusion: 'Conclusion has been saved successfully',
    updateConclusion: 'Conclusion has been updated successfully',
    intentRemoved: 'Intent has been removed successfully',
    assestIntentValueAdd: 'Intent value has been added successfully',
    assestIntentValueDelete: 'Intent value has been removed successfully',
    intentNameValueError: "Intents can only contain word, underscore or hyphen characters, and must not start with a prefix of 'sys-'",
    invalidQuestion: 'Session Expired.',
    invalidVariation: 'Variation length may not be greater than 5',
    expiredTestChat: 'Test chat key expired',
    newSkillAddedd: 'Skill has been added successfully',
    editNewSkills: 'Skill has been updated successfully',
    alreadyAddedSkill: 'Skill has been already added',
    assestSkillErr: 'Skill cannot be empty',
    addResFormula: 'Please add response name',
    nameTaken: 'Elm name has already been taken',
    destroyResFormula: 'Response formula has been removed successfully',
    destroyAssessmentResFormula: 'Assessment response formula has been removed successfully',
    editUser: 'User has been edited successfully',
    userCreate: 'User has been created successfully',
    characterRoleCreate: 'Character Role has been created successfully',
    editCharacterRole: 'Character Role has been updated successfully',
    deletecharacterRole: 'Character Role has been deleted successfully',
    questionCreated: 'Question has been created successfully',
    deleteRubric: 'Rubric deleted successfully',
    resubmissionLimit: 'Maximum resubmission limit is 2',
    questionSaved: 'Question has been saved successfully',
    feedbackSaved: 'Feedback has been saved successfully',
    removeEvaluation: 'Evaluation has been removed successfully',
    sameSynonymNameError: 'Synonym name has already been taken',
    uploadWorld: 'World imported successfully',
    uploadWorldQueued:  'World is queued for import. Will be available in sometime',
    uploadElm: 'ELM imported successfully',
    uploadQueued: 'ELM is queued for import. Will be available in sometime',
    invalidJson: 'Sorry, Some technical error occured, Please try again!',
    elmCloned: 'ELM is queued for cloning. Will be available in sometime',
    cloneErr: 'There are some technical errors on Server. Please try again Sometime!',
    worldNotFound: 'ELM World not found. Please import the world and try again!',
    worldExportQueued: 'World is queued for Export. Will be available in sometime',
    ElmExportQueued: 'ELM is queued for Export. Will be available in sometime',
    loCloned: 'Learning Object cloned successfully',
    watsonAssistantSuccess: 'Watson Assistant connection successful',
    watsonAssistantError: 'Error connecting to Watson Assistant',
    cloneLoError: 'Cloning failed due to Technical/Data errors.',
    entityInUseErr: 'Cannot delete as it is in use. Please remove it from evaluation first',
    newAssmntBlockAddedd: 'New assessment block added successfully',
    externalApiTestSuccess: 'Test successful',
    deleteFile: 'File has been deleted successfully',
    deleteCustomer: 'Customer has been deleted successfully',
    customerCreate: 'Customer has been created successfully',
    customerEdit: 'Customer has been edited successfully',
    deleteCourse: 'Course has been deleted successfully',
    addPdf: 'Pdf has been Added successfully',
    courseEdit: 'Course has been edited successfully',
    courseCreate: 'Course has been created successfully',
    courseProvision: 'Provision has been created successfully',
    deleteProvision: 'Provision has been deleted successfully',
    sectionEdit: 'Section has been edited successfully',
    sectionCreate: 'Section has been created successfully',
    newCodingTestAdded: 'New test added successfully',
    codingTestUpdated: 'Test successfully updated',
    codingTestUpdated2: 'Test 222222 successfully updated',
    optimalCodeUpdated: 'Optimal code saved',
    providedCodeUpdated: 'Provided code saved',
    newCodingConceptAdded: 'New concept added successfully',
    codingConceptUpdated: 'Concept successfully updated',
    brandImageDeleted: 'Brand Image deleted successfully',
    sectionChangeSuccess: 'User section has been updated. The page will reload in 3 seconds',
    sectionChangeError: 'An error occurred while updating this users section',
  },
  setting: {
    default: 0,
    searchPage: 1,
    weightMinRange: 99.96,
    weightMaxRange: 100,
    minResubmission: 1,
    maxResubmission: 2
  }
};
