var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "learning-quiz-content" }, [
      _c("div", { staticClass: "setup-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "skillSets-container" }, [
          _vm._m(1),
          _c("div", { staticClass: "skillSets-body" }, [
            _c(
              "div",
              { staticClass: "side-menu" },
              [
                _c(
                  "draggable",
                  {
                    on: {
                      start: function ($event) {
                        _vm.isDragging = true
                      },
                      end: _vm.onEnd,
                    },
                    model: {
                      value: _vm.skillSetsData,
                      callback: function ($$v) {
                        _vm.skillSetsData = $$v
                      },
                      expression: "skillSetsData",
                    },
                  },
                  _vm._l(_vm.skillSetsData, function (skillSet, skillSetIndex) {
                    return _c(
                      "ul",
                      { key: skillSetIndex, staticClass: "side-skill-sets" },
                      [
                        _c("div", { staticClass: "skill-set-container" }, [
                          _c("li", { staticClass: "menu-item" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  skillSet.name || `skillSet: ${skillSet.id}`
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "delete-button",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteSkillSetInteraction(
                                    skillSet.id
                                  )
                                },
                              },
                            },
                            [_vm._v(" X ")]
                          ),
                        ]),
                        _c(
                          "ul",
                          { staticClass: "sub-menu-item" },
                          _vm._l(
                            skillSet.llm_skill_set_items,
                            function (skillSetItem, skillSetItemIndex) {
                              return _c(
                                "li",
                                {
                                  key: skillSetItemIndex,
                                  staticClass: "menu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseLlmSkillSetItem(
                                        skillSetIndex,
                                        skillSetItemIndex
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(skillSetItem.skill_name) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c("hr"),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.skillSetAddId,
                        expression: "skillSetAddId",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.skillSetAddId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "", default: "" } }, [
                      _vm._v("Select Skill Set"),
                    ]),
                    _vm._l(_vm.allSkillSets, function (skillSet) {
                      return _c(
                        "option",
                        { key: skillSet.id, domProps: { value: skillSet.id } },
                        [_vm._v(" " + _vm._s(skillSet.attributes.name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn add-skill-sets-btn",
                    on: { click: _vm.addSkillSet },
                  },
                  [_vm._v(" Add Skill Set ")]
                ),
              ],
              1
            ),
            _vm.showSkillSetAssessment == true
              ? _c("div", { staticClass: "skillSetss-edit" }, [
                  _c("div", { staticClass: "edit-body" }, [
                    _c("h4", [
                      _vm._v(_vm._s(this.chosenskillSets.attributes.title)),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Independent / Dependent")]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "independent" } }, [
                                _vm._v("Independent"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .dependency_type,
                                    expression:
                                      "chosenskillSets.attributes.dependency_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "independent",
                                  name: "independent",
                                },
                                domProps: {
                                  value: "independent",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .dependency_type,
                                    "independent"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "dependency_type",
                                      "independent"
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "dependent" } }, [
                                _vm._v("Dependent"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .dependency_type,
                                    expression:
                                      "chosenskillSets.attributes.dependency_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "dependent",
                                  name: "dependent",
                                },
                                domProps: {
                                  value: "dependent",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .dependency_type,
                                    "dependent"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "dependency_type",
                                      "dependent"
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Assessment Type")]),
                          _c("div", { staticClass: "d-flex g-3" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "pass/fail" } }, [
                                _vm._v("Pass/Fail"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .assessment_type,
                                    expression:
                                      "chosenskillSets.attributes.assessment_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "passfail",
                                  name: "passfail",
                                },
                                domProps: {
                                  value: "passfail",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .assessment_type,
                                    "passfail"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "assessment_type",
                                      "passfail"
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "thresold" } }, [
                                _vm._v("Threshold"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .assessment_type,
                                    expression:
                                      "chosenskillSets.attributes.assessment_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "threshold",
                                  name: "threshold",
                                },
                                domProps: {
                                  value: "threshold",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .assessment_type,
                                    "threshold"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "assessment_type",
                                      "threshold"
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _vm.chosenskillSets.attributes.assessment_type ===
                        "passfail"
                          ? _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Assessment Text")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .assessment_text,
                                    expression:
                                      "chosenskillSets.attributes.assessment_text",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "textarea" },
                                domProps: {
                                  value:
                                    _vm.chosenskillSets.attributes
                                      .assessment_text,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "assessment_text",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Trigger Response")]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Hint")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_response,
                                      expression:
                                        "chosenskillSets.attributes.trigger_response",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "hint",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_response,
                                      "hint"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_response",
                                        "hint"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Answer")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_response,
                                      expression:
                                        "chosenskillSets.attributes.trigger_response",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "answer",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_response,
                                      "answer"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_response",
                                        "answer"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("None")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_response,
                                      expression:
                                        "chosenskillSets.attributes.trigger_response",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "none",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_response,
                                      "none"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_response",
                                        "none"
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Trigger Pass")]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Sadness")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_pass,
                                      expression:
                                        "chosenskillSets.attributes.trigger_pass",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "sadness",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_pass,
                                      "sadness"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_pass",
                                        "sadness"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Happiness")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_pass,
                                      expression:
                                        "chosenskillSets.attributes.trigger_pass",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "happiness",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_pass,
                                      "happiness"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_pass",
                                        "happiness"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Fear")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_pass,
                                      expression:
                                        "chosenskillSets.attributes.trigger_pass",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "fear",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_pass,
                                      "fear"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_pass",
                                        "fear"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Surprise")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_pass,
                                      expression:
                                        "chosenskillSets.attributes.trigger_pass",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "surprise",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_pass,
                                      "surprise"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_pass",
                                        "surprise"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("None")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_pass,
                                      expression:
                                        "chosenskillSets.attributes.trigger_pass",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "none",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_pass,
                                      "none"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_pass",
                                        "none"
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Trigger Fail")]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Sadness")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_fail,
                                      expression:
                                        "chosenskillSets.attributes.trigger_fail",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "sadness",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_fail,
                                      "sadness"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_fail",
                                        "sadness"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Happiness")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_fail,
                                      expression:
                                        "chosenskillSets.attributes.trigger_fail",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "happiness",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_fail,
                                      "happiness"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_fail",
                                        "happiness"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Fear")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_fail,
                                      expression:
                                        "chosenskillSets.attributes.trigger_fail",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "fear",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_fail,
                                      "fear"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_fail",
                                        "fear"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("Surprise")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_fail,
                                      expression:
                                        "chosenskillSets.attributes.trigger_fail",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "surprise",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_fail,
                                      "surprise"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_fail",
                                        "surprise"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "trigger-item" }, [
                                _c("label", [_vm._v("None")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenskillSets.attributes
                                          .trigger_fail,
                                      expression:
                                        "chosenskillSets.attributes.trigger_fail",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: "none",
                                    checked: _vm._q(
                                      _vm.chosenskillSets.attributes
                                        .trigger_fail,
                                      "none"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.chosenskillSets.attributes,
                                        "trigger_fail",
                                        "none"
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _vm._v("Assessment Narrative Knowledge Base"),
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.chosenskillSets.attributes.narrative_base,
                                expression:
                                  "chosenskillSets.attributes.narrative_base",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { rows: "3" },
                            domProps: {
                              value:
                                _vm.chosenskillSets.attributes.narrative_base,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.chosenskillSets.attributes,
                                  "narrative_base",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _vm._v("Include this Assessment in interaction"),
                          ]),
                          _c("label", [
                            _vm._v(
                              "Checking this option will include this Assessent when assessing the answer during the interaction"
                            ),
                          ]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "yes" } }, [
                                _vm._v("Yes"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .include_in_assess,
                                    expression:
                                      "chosenskillSets.attributes.include_in_assess",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "yes",
                                  name: "include_in_interaction",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .include_in_assess,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "include_in_assess",
                                      true
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", [
                              _c("label", { attrs: { for: "no" } }, [
                                _vm._v("No"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .include_in_assess,
                                    expression:
                                      "chosenskillSets.attributes.include_in_assess",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "no",
                                  name: "include_in_interaction",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .include_in_assess,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "include_in_assess",
                                      false
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _vm._v("Include this Assessment in the feedback?"),
                          ]),
                          _c("label", [
                            _vm._v(
                              "Checking this option will include this Assessment in the feedback"
                            ),
                          ]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "yes" } }, [
                                _vm._v("Yes"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes.mandatory,
                                    expression:
                                      "chosenskillSets.attributes.mandatory",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "yes",
                                  name: "mandatory",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes.mandatory,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "mandatory",
                                      true
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "no" } }, [
                                _vm._v("No"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes.mandatory,
                                    expression:
                                      "chosenskillSets.attributes.mandatory",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "no",
                                  name: "mandatory",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes.mandatory,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "mandatory",
                                      false
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Show points on assessment")]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "yes" } }, [
                                _vm._v("Yes"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .show_points,
                                    expression:
                                      "chosenskillSets.attributes.show_points",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "yes",
                                  name: "show_points",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes.show_points,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "show_points",
                                      true
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "no" } }, [
                                _vm._v("No"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .show_points,
                                    expression:
                                      "chosenskillSets.attributes.show_points",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "no",
                                  name: "show_points",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes.show_points,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "show_points",
                                      false
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Question Needed?")]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "yes" } }, [
                                _vm._v("Yes"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .question_needed,
                                    expression:
                                      "chosenskillSets.attributes.question_needed",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "yes",
                                  name: "question_needed",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .question_needed,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "question_needed",
                                      true
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c("label", { attrs: { for: "no" } }, [
                                _vm._v("No"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .question_needed,
                                    expression:
                                      "chosenskillSets.attributes.question_needed",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "no",
                                  name: "question_needed",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .question_needed,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "question_needed",
                                      false
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Feedback Request Type")]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c(
                                "label",
                                { attrs: { for: "feedback_request_type" } },
                                [_vm._v("All")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .feedback_request_type,
                                    expression:
                                      "chosenskillSets.attributes.feedback_request_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "feedback_request_type",
                                  name: "feedback_request_type",
                                },
                                domProps: {
                                  value: "all",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .feedback_request_type,
                                    "all"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "feedback_request_type",
                                      "all"
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c(
                                "label",
                                { attrs: { for: "feedback_request_type" } },
                                [_vm._v("Hit")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .feedback_request_type,
                                    expression:
                                      "chosenskillSets.attributes.feedback_request_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "feedback_request_type",
                                  name: "feedback_request_type",
                                },
                                domProps: {
                                  value: "hit",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .feedback_request_type,
                                    "hit"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "feedback_request_type",
                                      "hit"
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c(
                                "label",
                                { attrs: { for: "feedback_request_type" } },
                                [_vm._v("None")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .feedback_request_type,
                                    expression:
                                      "chosenskillSets.attributes.feedback_request_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "feedback_request_type",
                                  name: "feedback_request_type",
                                },
                                domProps: {
                                  value: "none",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .feedback_request_type,
                                    "none"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "feedback_request_type",
                                      "none"
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Show Pass/Fail Labels")]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c(
                                "label",
                                { attrs: { for: "feedback_request_type" } },
                                [_vm._v("Show")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .show_pass_fail_labels,
                                    expression:
                                      "chosenskillSets.attributes.show_pass_fail_labels",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "show_pass_fail_labels",
                                  name: "show_pass_fail_labels",
                                },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .show_pass_fail_labels,
                                    true
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "show_pass_fail_labels",
                                      true
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c(
                                "label",
                                { attrs: { for: "feedback_request_type" } },
                                [_vm._v("Hide")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .show_pass_fail_labels,
                                    expression:
                                      "chosenskillSets.attributes.show_pass_fail_labels",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "show_pass_fail_labels",
                                  name: "show_pass_fail_labels",
                                },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .show_pass_fail_labels,
                                    false
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "show_pass_fail_labels",
                                      false
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Generative Feedback Type")]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "trigger-item" }, [
                              _c(
                                "label",
                                { attrs: { for: "generative_feedback_type" } },
                                [_vm._v("Static")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .generative_feedback_type,
                                    expression:
                                      "chosenskillSets.attributes.generative_feedback_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "generative_feedback_type",
                                  name: "generative_feedback_type",
                                },
                                domProps: {
                                  value: "static",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .generative_feedback_type,
                                    "static"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "generative_feedback_type",
                                      "static"
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "trigger-item" }, [
                              _c(
                                "label",
                                { attrs: { for: "generative_feedback_type" } },
                                [_vm._v("Dynamic")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .generative_feedback_type,
                                    expression:
                                      "chosenskillSets.attributes.generative_feedback_type",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "generative_feedback_type",
                                  name: "generative_feedback_type",
                                },
                                domProps: {
                                  value: "dynamic",
                                  checked: _vm._q(
                                    _vm.chosenskillSets.attributes
                                      .generative_feedback_type,
                                    "dynamic"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "generative_feedback_type",
                                      "dynamic"
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("hr"),
                    _vm._m(2),
                    _vm.chosenskillSets.attributes.feedback_request_items
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.chosenskillSets.attributes
                              .feedback_request_items.data,
                            function (feedback_item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c(
                                      "ul",
                                      { staticClass: "p-0 m-0 w-full" },
                                      [
                                        _c("li", { staticClass: "p-0 mb-8" }, [
                                          _c(
                                            "div",
                                            { staticClass: "d-block mb-5" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-block mb-5" },
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _vm._v(
                                                        "Assessment item label"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          feedback_item
                                                            .attributes.label,
                                                        expression:
                                                          "feedback_item.attributes.label",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control mb-5",
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        feedback_item.attributes
                                                          .label,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          feedback_item.attributes,
                                                          "label",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.chosenskillSets.attributes
                                                    .assessment_type ==
                                                  "threshold"
                                                    ? _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "d-block mb-2",
                                                        },
                                                        [_vm._v(" Level ")]
                                                      )
                                                    : _vm._e(),
                                                  _vm.chosenskillSets.attributes
                                                    .assessment_type ==
                                                  "threshold"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              feedback_item
                                                                .attributes
                                                                .level,
                                                            expression:
                                                              "feedback_item.attributes.level",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control mb-5",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            feedback_item
                                                              .attributes.level,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              feedback_item.attributes,
                                                              "level",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "label",
                                                    { staticClass: "mb-2" },
                                                    [_vm._v("Points")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          feedback_item
                                                            .attributes.points,
                                                        expression:
                                                          "feedback_item.attributes.points",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control mb-5",
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        feedback_item.attributes
                                                          .points,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          feedback_item.attributes,
                                                          "points",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.chosenskillSets.attributes
                                                    .assessment_type ==
                                                  "threshold"
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-info",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteFeedbackRequestItem(
                                                                feedback_item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Delete ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("div", { staticClass: "d-block mb-5" }, [
                                      _c("label", { staticClass: "mb-2" }, [
                                        _vm._v("Assessment Item Description"),
                                      ]),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              feedback_item.attributes
                                                .description,
                                            expression:
                                              "feedback_item.attributes.description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "textarea" },
                                        domProps: {
                                          value:
                                            feedback_item.attributes
                                              .description,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              feedback_item.attributes,
                                              "description",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.chosenskillSets.attributes.assessment_type ==
                    "threshold"
                      ? _c("div", { staticClass: "d-flex mb-5" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: {
                                click: function ($event) {
                                  return _vm.createFeedbackRequestItem()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mx-auto text-sm font-bold" },
                                [_vm._v("Add new feedback request item")]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("hr"),
                    _vm.chosenskillSets.attributes.assessment_type ==
                    "threshold"
                      ? _c("div", { staticClass: "mb-5" }, [
                          _vm._m(3),
                          _c("div", { staticClass: "d-flex pb-4 border-b-4" }, [
                            _c("div", { staticClass: "d-block mb-5" }, [
                              _c("label", { staticClass: "block mb-2" }, [
                                _vm._v("Name"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.chosenskillSets.attributes
                                        .knowbase_name,
                                    expression:
                                      "chosenskillSets.attributes.knowbase_name",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value:
                                    _vm.chosenskillSets.attributes
                                      .knowbase_name,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.chosenskillSets.attributes,
                                      "knowbase_name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._m(4),
                          _vm.chosenskillSets.attributes
                            .assessment_knowledge_items
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.chosenskillSets.attributes
                                    .assessment_knowledge_items.data,
                                  function (knowledge_item, index) {
                                    return _c(
                                      "ul",
                                      { key: index, staticClass: "row mb-5" },
                                      [
                                        _c(
                                          "li",
                                          {
                                            staticClass:
                                              "d-flex col-md-12 justify-content-between",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  knowledge_item.attributes.body
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteKnowledgeBaseItem(
                                                      knowledge_item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Delete")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex pb-10 mb-10 border-b-4 flex-column",
                            },
                            [
                              _c("label", [_vm._v("New Knowledge Base Item")]),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.new_know_base_item_text,
                                      expression: "new_know_base_item_text",
                                    },
                                  ],
                                  staticClass: "form-control mr-5",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "Add new knowledge base item here",
                                  },
                                  domProps: {
                                    value: _vm.new_know_base_item_text,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.new_know_base_item_text =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addNewKnowledgeBaseItem()
                                      },
                                    },
                                  },
                                  [_vm._v("Add ")]
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "block mb-5 w-1/3" }, [
                        _c("label", { staticClass: "mb-2" }, [_vm._v("Task")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.chosenskillSets.attributes.task,
                              expression: "chosenskillSets.attributes.task",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.chosenskillSets.attributes.task,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.chosenskillSets.attributes,
                                "task",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-5" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", { staticClass: "mb-2" }, [
                          _vm._v("Use custom skill label?"),
                        ]),
                        _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "trigger-item" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.chosenskillSets.attributes
                                      .use_skill_label,
                                  expression:
                                    "chosenskillSets.attributes.use_skill_label",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "use_skill_label-yes",
                                type: "radio",
                                value: "true",
                                name: "use_skill_label-yes",
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.chosenskillSets.attributes
                                    .use_skill_label,
                                  "true"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.chosenskillSets.attributes,
                                    "use_skill_label",
                                    "true"
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300",
                                attrs: { for: "use_skill_label-yes" },
                              },
                              [_vm._v("Yes")]
                            ),
                          ]),
                          _c("div", { staticClass: "trigger-item" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.chosenskillSets.attributes
                                      .use_skill_label,
                                  expression:
                                    "chosenskillSets.attributes.use_skill_label",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "use_skill_label-no",
                                type: "radio",
                                value: "false",
                                name: "use_skill_label-no",
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.chosenskillSets.attributes
                                    .use_skill_label,
                                  "false"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.chosenskillSets.attributes,
                                    "use_skill_label",
                                    "false"
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              { attrs: { for: "use_skill_label-no" } },
                              [_vm._v("No")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-5" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", { staticClass: "mb-2" }, [
                          _vm._v("Custom skill label"),
                        ]),
                        _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "block mr-5" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.chosenskillSets.attributes.skill_label,
                                  expression:
                                    "chosenskillSets.attributes.skill_label",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value:
                                  _vm.chosenskillSets.attributes.skill_label,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.chosenskillSets.attributes,
                                    "skill_label",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row mb-5" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 d-flex flex-column" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block text-sm font-medium text-slate-700",
                            },
                            [_vm._v("Admin Notes")]
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.chosenskillSets.attributes.admin_notes,
                                expression:
                                  "chosenskillSets.attributes.admin_notes",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.chosenskillSets.attributes.admin_notes,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.chosenskillSets.attributes,
                                  "admin_notes",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.saveAssessment },
                            },
                            [_vm._v("Save Assessment")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", { staticClass: "head-title" }, [
          _vm._v("Skill Assessment Set up"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "skillSets-header mb-3" }, [
      _c("h4", [_vm._v("Skill Sets")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("h4", { staticClass: "mb-5" }, [_vm._v("Feedback Request Items")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex" }, [
      _c("h4", { staticClass: "mb-5" }, [_vm._v("Assessment Knowledge Base")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex border-b-2 mb-6 pb-2" }, [
      _c("p", [_vm._v("Knowledge base entries")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }