var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ContentVideos" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _vm._m(0),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchVideo,
                      expression: "searchVideo",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "name",
                    "aria-describedby": "searchDoc",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchVideo },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.searchVideo = $event.target.value
                      },
                      _vm.searchVideos,
                    ],
                  },
                }),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("AddVideo")
                  },
                },
              },
              [_vm._v("Add Video")]
            ),
          ]),
          _vm.totalVideos > 1 && !_vm.searchVideo
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalVideos) +
                    " Videos in the system."
                ),
              ])
            : _vm.totalVideos <= 1 && !_vm.searchVideo
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalVideos) +
                    " Video in the system."
                ),
              ])
            : _vm.totalVideos > 1 && _vm.searchVideo
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalVideos) +
                    " Videos in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalVideos) +
                    " Video in the search result."
                ),
              ]),
        ]),
        _vm.videoLists.length
          ? _c("div", { staticClass: "data-content" }, [
              _c("div", [
                _vm.videoLists.length > 0
                  ? _c(
                      "div",
                      { staticClass: "data-image-row" },
                      _vm._l(_vm.videoLists, function (video) {
                        return _c(
                          "div",
                          {
                            key: video.id,
                            staticClass: "data-image-col col-4 d-flex",
                          },
                          [
                            _c("div", { staticClass: "data-image-flex" }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$modal.show("wistiaVideo", {
                                        wistiaId: video.attributes.wistia_code,
                                      })
                                    },
                                  },
                                },
                                [
                                  video.attributes.thumbnail_url
                                    ? _c("img", {
                                        attrs: {
                                          src: video.attributes.thumbnail_url,
                                          alt: "",
                                          onload:
                                            "this.parentNode.style.opacity=1;",
                                        },
                                      })
                                    : video.attributes.wistia_thumbnail_url
                                    ? _c("img", {
                                        attrs: {
                                          src: video.attributes
                                            .wistia_thumbnail_url,
                                          alt: "",
                                          onload:
                                            "this.parentNode.style.opacity=1;",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._m(1, true),
                                ]
                              ),
                              _c("div", { staticClass: "data-image-content" }, [
                                _c("h4", { staticClass: "text-truncate" }, [
                                  _vm._v(_vm._s(video.attributes.title)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "my-3" },
                                  [
                                    _c(
                                      "copy-to-clipboard",
                                      {
                                        attrs: {
                                          text: _vm.getshortCode(video),
                                        },
                                        on: { copy: _vm.copiedMessage },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "copy-icon",
                                            attrs: {
                                              href: "javascript:void(0);",
                                            },
                                          },
                                          [_vm._v("Get shortcode")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "h5",
                                  [
                                    _vm._l(
                                      video.attributes.tag_list,
                                      function (tag) {
                                        return _c("span", { key: tag }, [
                                          _vm._v(_vm._s(tag)),
                                        ])
                                      }
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "data-link dropdown-toggle",
                                        attrs: {
                                          href: "",
                                          "data-toggle": "dropdown",
                                          "aria-haspopup": "true",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            video.attributes.tag_list.length > 0
                                              ? "Tag"
                                              : "Add Tag"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("notesModal", {
                                      attrs: {
                                        doc: {
                                          id: video.id,
                                          type: "global_video",
                                          notes: video.attributes.tag_list,
                                          page: _vm.page,
                                        },
                                        method: _vm.getVideo,
                                      },
                                    }),
                                  ],
                                  2
                                ),
                                _c("p", [
                                  _vm._v(_vm._s(video.attributes.created_on)),
                                ]),
                                video.attributes.transcript
                                  ? _c("p", [_vm._v("Transcript: Yes")])
                                  : _c("p", [_vm._v("Transcript: No")]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "data-image-edit",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editRecord(video.id)
                                      },
                                    },
                                  },
                                  [_c("em", { staticClass: "icon-edit" })]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "data-image-delete",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRecord(video.id)
                                      },
                                    },
                                  },
                                  [_c("em", { staticClass: "icon-delete" })]
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "data-footer" }, [
                _c("div", { staticClass: "data-results" }, [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.page) +
                      " of " +
                      _vm._s(_vm.totalPagesPagination.length)
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _vm.page !== 1
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Previous" },
                                  on: { click: _vm.prevPage },
                                },
                                [_c("em", { staticClass: "icon-prev" })]
                              ),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.totalPagesPagination.slice(
                            _vm.page - 1,
                            _vm.page + 3
                          ),
                          function (pages) {
                            return _c(
                              "li",
                              { key: pages, staticClass: "page-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "page-link",
                                    class: [pages === _vm.page ? "active" : ""],
                                    on: {
                                      click: function ($event) {
                                        return _vm.getVideo(pages)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(pages))]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.page < _vm.totalPagesPagination.length
                          ? _c("li", { staticClass: "page-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": "Next" },
                                  on: { click: _vm.nextPage },
                                },
                                [_c("em", { staticClass: "icon-next" })]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.videoLists.length === 0 && !_vm.searchVideo && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no Videos in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.show("AddVideo")
                      },
                    },
                  },
                  [_vm._v("Add a new video")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.videoLists.length === 0 && _vm.searchVideo
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
      ]),
      _c("AddVideo"),
      _c("EditVideo"),
      _c("WistiaComponent"),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete this video?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide("deleteModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteVideo(_vm.deleteId)
                    },
                  },
                },
                [_vm._v("ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-left" }, [
      _c("h2", [_vm._v("All Videos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "video-play", attrs: { href: "#" } }, [
      _c("em", { staticClass: "icon-carrot-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }