<template>
  <div id="LearnerLOList">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>
            {{ learnerData.attributes.last_name }},
            {{ learnerData.attributes.first_name }} - Interaction LOs
          </h2>
          <p>{{ learnerData.attributes.section_name }}</p>
        </div>
      </header>

      <div class="data-content">
        <div class="data-table data-column-6">
          <div class="data-head">
            <div class="data-col">Learning Object</div>

            <div class="data-col">Type</div>

            <div class="data-col">Completed</div>
            <div class="data-col">Reattempt #</div>
            <div class="data-col">Reset</div>
            <div class="data-col">View Details</div>
          </div>
          <div
            class="data-items"
            v-for="learnObject in learnObjectsList"
            :key="learnObject.id"
          >
            <div class="data-row">
              <div class="data-col">
                {{
                  learnObject.attributes.learning_object.attributes.card_order
                }}: {{ learnObject.attributes.learning_object.attributes.name }}
              </div>
              <div class="data-col">
                {{
                  learnObject.attributes.learning_object.attributes.card_type
                }}
              </div>
              <div class="data-col">{{ learnObject.attributes.complete }}</div>
              <div class="data-col">{{ learnObject.attributes.reattempt_number }}</div>
              <div class="data-col">
                <div class="data-col">
                  <button
                    @click="showResetLOModal(learnObject)"
                    class="btn secondary"
                  >
                    Reset LO
                  </button>
                </div>
              </div>
              <div class="data-col">
                <div class="data-col">
                  <router-link :to="`/learner-lo/${learnObject.id}`">
                    <button type="button" class="btn secondary">
                      LO Details
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <resetLO />
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
//import { commonConfig } from "@/utils/commonConfig";
import api from "../services/api";
import resetLO from "../components/modal/resetLO.vue";
export default {
  name: "LearnerLOList",
  components: {
    resetLO
  },
  metaInfo: {
    title: "Learner Interactive Learning Objects List",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      selectedFile: null,
      lo_title: "",
      lo_card_number: "",
      lo_status: "",
      learnObjectsList: [],
      learnerData: {
        attributes: {
          first_name: "",
          last_name: ""
        }
      },
      selectedLearnData: {}
    };
  },
  methods: {
    show(modal) {
      this.$modal.show(modal);
      this.isModalVisible = true;
    },
    hide(modal) {
      this.clearFormData();
      this.$modal.hide(modal);
      this.isModalVisible = false;
    },
    getUserLOs(id) {
      utilFunctionService.showLoader();
      api
        .getUserInteractionLOs(id)
        .then((res) => {
          utilFunctionService.hideLoader();
          this.learnObjectsList = res.data.data;
        })
        .catch();
    },
    getUserData(id) {
      api.getUserSectionDetails(id).then((res) => {
        this.learnerData = res.data.data;
        console.log(this.learnerData, "this.learnerData");
      });
    },
    showResetLOModal(learnObject) {
      this.selectedLearnData = learnObject;
      this.$modal.show("resetLO", {selectedLearnData: this.selectedLearnData, userData: this.learnerData });
    },
    hideResetLO() {
      this.selectedLearnData = {
        attributes: {
          user_learn_obj_reset: []
        }
      };
      this.hide('resetLO')
    },
  },
  created() {
    this.getUserLOs(this.$route.params.id);
    this.getUserData(this.$route.params.id);
  },
};
</script>