var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "WatsonAdmin" } }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("h3", [_vm._v("Watson Assistant status check")]),
            _c(
              "button",
              {
                staticClass: "btn primary mt-5",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.watsonAssistantCheck()
                  },
                },
              },
              [_vm._v("Check status")]
            ),
            _c("div"),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "header-left" }, [
        _c("h2", [_vm._v("Watson Admin")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }