var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ContentImages" } },
    [
      _c(
        "modal",
        {
          attrs: {
            name: "cropperImages",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-cropper-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "modal-images-cropper" }, [
                _vm.image
                  ? _c(
                      "div",
                      { staticClass: "modal-cropper-large" },
                      [
                        _c("cropper", {
                          ref: "cropper",
                          attrs: {
                            src: _vm.image,
                            "stencil-props": { aspectRatio: 16 / 9 },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("cropperImages")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.crop()
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "header" }, [
          _c("div", { staticClass: "header-left" }, [
            _c("h2", [_vm._v("All Images")]),
            _vm.uploadPercentage > 0
              ? _c("div", { staticClass: "progress-bar-overlay" }, [
                  _c(
                    "div",
                    { staticClass: "progress-bar-container" },
                    [
                      _vm.uploadPercentage > 0
                        ? _c("progress-bar", {
                            attrs: {
                              options: _vm.options,
                              value: _vm.getPercentage,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "header-right" }, [
            _c("form", { staticClass: "header-form" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchImage,
                      expression: "searchImage",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:700ms",
                      value: _vm.search,
                      expression: "search",
                      arg: "700ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "exampleInputEmail1",
                    "aria-describedby": "emailHelp",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.searchImage },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchImage = $event.target.value
                    },
                  },
                }),
                _c("em", { staticClass: "icon-search" }),
              ]),
            ]),
            _c("input", {
              ref: "fileInput",
              staticStyle: { display: "none" },
              attrs: { type: "file", accept: "image/jpeg" },
              on: { change: _vm.onFileSelect },
            }),
            _c(
              "a",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.$refs.fileInput.click()
                  },
                },
              },
              [_vm._v("Upload Image")]
            ),
          ]),
          _vm.totalImages > 1 && !_vm.searchImage
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalImages) +
                    " images in the system."
                ),
              ])
            : _vm.totalImages <= 1 && !_vm.searchImage
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalImages) +
                    " image in the system."
                ),
              ])
            : _vm.totalImages > 1 && _vm.searchImage
            ? _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalImages) +
                    " images in the search result."
                ),
              ])
            : _c("p", [
                _vm._v(
                  "You have " +
                    _vm._s(_vm.totalImages) +
                    " image in the search result."
                ),
              ]),
        ]),
        _c("div", { staticClass: "data-content" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "data-image-row" },
              _vm._l(_vm.imageLists, function (images, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "data-image-col col-3" },
                  [
                    _c("div", { staticClass: "data-image" }, [
                      images.attributes.attachment_variations.large_version
                        ? _c("img", {
                            attrs: {
                              src: images.attributes.attachment_variations
                                .large_version,
                              alt: "image",
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "data-image-content" }, [
                      _c("h4", { staticClass: "text-truncate" }, [
                        _vm._v(_vm._s(images.attributes.file_name)),
                      ]),
                      _c(
                        "h5",
                        [
                          images.attributes.tag_list
                            ? _c("span", [
                                _vm._v(_vm._s(images.attributes.tag_list)),
                              ])
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "data-link dropdown-toggle",
                              attrs: {
                                href: "",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  images.attributes.tag_list ? "Tag" : "Add Tag"
                                )
                              ),
                            ]
                          ),
                          _c("notesModal", {
                            attrs: {
                              doc: {
                                id: images.id,
                                type: "image",
                                notes: images.attributes.tag_list,
                                page: i,
                              },
                              method: _vm.addTag,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("p", [_vm._v(_vm._s(images.attributes.created_on))]),
                      _c(
                        "a",
                        {
                          staticClass: "data-image-delete",
                          on: {
                            click: function ($event) {
                              return _vm.deleteImage(images.id)
                            },
                          },
                        },
                        [_c("em", { staticClass: "icon-delete" })]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "data-footer" }, [
            _c("div", { staticClass: "data-results" }, [
              _vm._v(
                "Showing " +
                  _vm._s(_vm.page) +
                  " of " +
                  _vm._s(_vm.totalPagesPagination.length)
              ),
            ]),
            _c("div", { attrs: { "aria-label": "Page navigation example" } }, [
              _c(
                "ul",
                { staticClass: "pagination" },
                [
                  _vm.page !== 1
                    ? _c("li", { staticClass: "page-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "page-link",
                            attrs: { "aria-label": "Previous" },
                            on: { click: _vm.prevPage },
                          },
                          [_c("em", { staticClass: "icon-prev" })]
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm.totalPagesPagination.slice(_vm.page - 1, _vm.page + 3),
                    function (pages) {
                      return _c(
                        "li",
                        { key: pages, staticClass: "page-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "page-link",
                              class: [pages === _vm.page ? "active" : ""],
                              on: {
                                click: function ($event) {
                                  return _vm.getAllImages(pages)
                                },
                              },
                            },
                            [_vm._v(_vm._s(pages))]
                          ),
                        ]
                      )
                    }
                  ),
                  _vm.page < _vm.totalPagesPagination.length
                    ? _c("li", { staticClass: "page-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "page-link",
                            attrs: { "aria-label": "Next" },
                            on: { click: _vm.nextPage },
                          },
                          [_c("em", { staticClass: "icon-next" })]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm.imageLists.length === 0 && _vm.searchStatus
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("No results found")]),
              _c("p", [_vm._v("Try using a different keyword")]),
            ])
          : _vm._e(),
        _vm.imageLists.length === 0 && !_vm.searchStatus && _vm.display
          ? _c("div", { staticClass: "data-no-result" }, [
              _c("h3", [_vm._v("There’s nothing here!")]),
              _c("p", [_vm._v("You have no Images in the system yet.")]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn header-btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.fileInput.click()
                      },
                    },
                  },
                  [_vm._v("Add a new Image")]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "deleteModal",
            height: "auto",
            width: 350,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content modal-delete-content" }, [
            _c("div", { staticClass: "modal-body modal-delete-body" }, [
              _vm._v("Are you sure you want to delete this image?"),
            ]),
            _c("div", { staticClass: "modal-footer justify-content-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn medium default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide("deleteModal")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn medium primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.removeImage()
                    },
                  },
                },
                [_vm._v("ok")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }