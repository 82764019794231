<template>
  <div id="World">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Worlds</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Search"
                v-model="searchWorld"
                v-debounce:700ms="search"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <a @click="$modal.show('newWorld')" class="btn header-btn primary">Add a new World</a>
          <div class="ml-2 btn header-btn primary btn-type" v-if="!this.$store.state.importWorldstatus">
            <input type="file" @change="importWorld" />
            Import World
          </div>
           <div class="data-popper-dropdown" v-if="showNotification()">
              <button
                  type="button"
                  class="btn dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >
                  <span class="clone-notification"> <em class="icon-bell"></em></span>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div>
                  <div>
                    <span>
                      World Import progress: {{this.importedFile && this.importedFile.name}}
                    </span>
                  </div>
                  <div>
                    <div class="progress-bar-container">
                        <progress-bar :options="options" :value=this.$store.state.worldImportProgress />
                    </div> 
                  </div>
                </div>
              </div>
          </div>
          <!-- <div class="header-dropdown">
            <button
              type="button"
              class="btn dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <em class="icon-user"></em>
              <em class="icon-carrot-down"></em>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <ul @click="logOut()" class="cursor-pointer">
                <li>
                  <a>Log out</a>
                </li>
              </ul>
            </div>
          </div> -->
        </div>
        <p v-if="totalWorlds > 1 && !searchWorld">You have {{totalWorlds}} worlds in the system.</p>
        <p
          v-else-if="totalWorlds <= 1 && !searchWorld"
        >You have {{totalWorlds}} world in the system.</p>
        <p
          v-else-if="totalWorlds > 1 && searchWorld"
        >You have {{totalWorlds}} worlds in the search result.</p>
        <p v-else>You have {{totalWorlds}} world in the search result.</p>
      </header>
      <div class="data-content" v-if="worldsList.length !== 0">
        <div class="data-table data-column-7">
          <div class="data-head">
            <div class="data-col" @click="toggleSort('name', sorting)">
              Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'name' && sortOrder === 'asc' ? 'active' : '']"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                   :class="[sortColumn === 'name' && sortOrder === 'desc' ? 'active' : '']"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col" @click="toggleSort('created_at', sorting)">
              Created on
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'asc' ? 'active' : '']"

                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'desc' ? 'active' : '']"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col" @click="toggleSort('is_private', sorting)">
              type
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'is_private' && sortOrder === 'asc' ? 'active' : '']"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'is_private' && sortOrder === 'desc' ? 'active' : '']"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Unique code</div>
            <div class="data-col" @click="toggleSort('learn_mods_count', sorting)">
              ELMs
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'learn_mods_count' && sortOrder === 'asc' ? 'active' : '']"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'learn_mods_count' && sortOrder === 'desc' ? 'active' : '']"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Customer</div>
            <div class="data-col">Actions</div>
          </div>
          <div class="data-items">
            <div class="data-row" v-for="world in worldsList" :key="world.id">
              <div class="data-col">
                <router-link :to="`/world-detail/${world.id}`">{{world.attributes.name}}</router-link>
              </div>
              <div class="data-col">{{world.attributes.created_at}}</div>
              <div class="data-col">
                <div class="data-popper-dropdown" v-if="!world.attributes.is_private">Public</div>
                <div class="data-popper-dropdown" v-if="world.attributes.is_private">Private</div>
              </div>

              <div class="data-col">{{world.attributes.world_code}}</div>
              <div class="data-col">
                <div class="data-popper-dropdown">
                  <span class="elms">{{world.attributes.learn_mods_count}}</span>
                </div>
              </div>

              <div class="data-col">
                <div class="customer-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    v-if="world.attributes.customer"
                  >{{world.attributes.customer.attributes.name}}</button>
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    v-if="!world.attributes.customer"
                  >-</button>
                </div>
              </div>
              <div class="data-col data-action">
                <div class="data-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-edit" @click="editWorld(world.id)"></em>
                  </button>
                </div>
                <div class="data-delete-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-delete"></em>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div class="data-popper-title">Are you sure you want to delete this world?</div>
                    <div class="data-popper-footer">
                      <a class="btn default">cancel</a>
                      <a class="btn primary" @click="deleteWorld(world.id)">Ok</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="data-footer" v-if="totalPages.length > 1">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getWorlds(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="worldsList.length === 0 && !searchStatus && display">
        <h3>There’s nothing here!</h3>
        <p>You have no Worlds in the system yet.</p>
        <div>
          <a
            href="javascript:void(0);"
            class="btn primary"
            @click="$modal.show('newWorld')"
          >Add a new World</a>
        </div>
      </div>
      <div class="data-no-result" v-if="worldsList.length === 0 && searchStatus">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <modal name="newWorld" height="auto" :scrollable="true">
      <div class="modal-content">
        <div id="AddNewWorld" v-if="!addOrganisation">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
              <h5 v-if="!editWorldStatus">Create a new world</h5>
              <h5 v-if="editWorldStatus">Edit world</h5>
              <p v-if="!editWorldStatus">Create a new world and add customers.</p>
            </div>
            <button type="button" class="close" @click="hide('newWorld')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="form">
              <form class="modal-form">
                <div class="form-group">
                  <label class="form-label">Name</label>
                  <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      aria-describedby="emailHelp"
                      v-model="name"
                      maxlength="30"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="form-label">Description</label>
                  <textarea class="form-control form-textarea-control" v-model="description" maxlength="500"></textarea>
                  <div class="form-characters">500 characters</div>
                </div>
                <div class="form-group">
                  <label class="form-label" v-if="!editWorldStatus">Type</label>
                  <label class="form-label" v-if="editWorldStatus">Type</label>
                  <p v-if="editWorldStatus" style="text-transform: capitalize">{{type}}</p>
                  <div class="form-checkboxes" v-if="!editWorldStatus">
                    <label
                      class="custom-checkbox"
                      name="public"
                      :class="[editWorldStatus ? 'disabled' : '']"
                    >
                      Public
                      <input
                        :disabled="editWorldStatus == true"
                        type="radio"
                        name="public"
                        v-model="type"
                        v-bind:value="'public'"
                      />
                      <span for="public" class="checkmark"></span>
                    </label>
                    <label
                      class="custom-checkbox"
                      name="private"
                      :class="[editWorldStatus ? 'disabled' : '']"
                    >
                      Private
                      <input
                        :disabled="editWorldStatus == true"
                        type="radio"
                        name="private"
                        v-model="type"
                        v-bind:value="'private'"
                      />
                      <span for="private" class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group" v-if="type === 'private'">
                  <label class="form-label">Customer</label>
                  <div class="customer-dropdown mb-3" v-if="!editWorldStatus">
                    <input
                      :disabled="editWorldStatus == true"
                      v-model="customer"
                      v-on:input="searchCustomer"
                      type="text"
                      placeholder="Type here"
                      class="form-control"
                      :class="[noCustomerAdded ? 'invalid' : '']"
                    />
                    <span
                      class="invalid"
                      style="display:block"
                      v-if="noCustomerAdded"
                    >Add customer is required</span>
                    <div v-if="customers.length !== 0">
                      <div class="dropdown-menu" :class="[customers.length !== 0 ? 'show' : '']">
                        <ul class="dropdown-list">
                          <li
                            class="dropdown-items"
                            v-for="(customer) of customers"
                            :key="customer.id"
                          >
                            <div class="dropdown-content">
                              <h4>{{customer.name}}</h4>
                              <p>{{customer.email}}</p>
                            </div>
                            <div class="dropdown-action" v-if="!customer.status">
                              <a class="add-action" @click="addCustomer(customer)">+</a>
                            </div>
                            <div class="dropdown-action" v-if="customer.status">
                              <em class="icon-check"></em>
                              <span>Added</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="customer-dropdown-results" v-if="addedCustomer.id">
                    <ul class="dropdown-list mt-0">
                      <li class="dropdown-items">
                        <div class="dropdown-content">
                          <h4>{{addedCustomer.name}}</h4>
                          <p>{{addedCustomer.email}}</p>
                        </div>
                        <div class="dropdown-action">
                          <a
                            v-if="!editWorldStatus"
                            @click="removeCustomer()"
                            class="delete-action"
                            :class="[editWorldStatus ? 'disabled' : '']"
                          >Remove</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn default" @click="hide('newWorld')">Cancel</button>
                  <button
                    v-if="!editWorldStatus"
                    type="button"
                    data-toggle="modal"
                    data-target="#addOrganisation"
                    data-dismiss="modal"
                    class="btn primary"
                    v-on:click="createWorld"
                  >
                    Save & add organization
                    <em class="icon-carrot-right"></em>
                  </button>
                  <button
                    v-if="editWorldStatus"
                    type="button"
                    data-toggle="modal"
                    data-target="#addOrganisation"
                    data-dismiss="modal"
                    class="btn primary"
                    v-on:click="createWorld"
                  >
                    Save & edit organization
                    <em class="icon-carrot-right"></em>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div id="AddOrganisation" v-if="addOrganisation">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
              <h5 v-if="!editWorldStatus">Add Organization to the World</h5>
              <h5 v-if="editWorldStatus">Edit Organization to the World</h5>
              <p
                v-if="worldDetails.id"
              >{{worldDetails.attributes.name}}, {{worldDetails.attributes.world_code}}</p>
            </div>
            <button type="button" class="close" @click="hide('newWorld')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body">
            <form class="modal-form">
              <div class="form-group">
                <div class="organisation-dropdown">
                  <input
                    type="text"
                    v-model="searchOrg"
                    v-on:input="searchOrganisation"
                    placeholder="Type here"
                    class="form-control btn dropdown-toggle"
                    :class="[noOrgAdded ? 'invalid' : '']"
                  />
                  <span
                    class="invalid"
                    style="display:block"
                    v-if="noOrgAdded"
                  >Atleast one organization is required.</span>
                  <span
                    v-if="noCharacterAdded"
                    class="invalid"
                    style="display:block"
                  >Minimum one character selection is required per organization.</span>
                  <div v-if="worldOrg.length && showOrgList">
                    <div class="dropdown-menu" :class="[worldOrg.length !== 0 ? 'show' : '']">
                      <ul class="dropdown-list">
                        <li class="dropdown-items" v-for="(org) of worldOrg" :key="org.id">
                          <div class="dropdown-pics" v-if="org.attributes.photo_url.thumbnail !=  false">
                            <img :src="org.attributes.photo_url.thumbnail" alt="images" />
                          </div>
                          <div class="dropdown-pics" v-if="org.attributes.photo_url.thumbnail ==  false"></div>
                          <div class="dropdown-content">
                            <h4>{{org.attributes.name}}</h4>
                            <h5>{{org.attributes.industry_name}}</h5>
                          </div>
                          <div class="dropdown-action" v-if="!org.status">
                            <a @click="addOrg(org)" class="add-action">+</a>
                          </div>
                          <div class="dropdown-action" v-if="org.status">
                            <em class="icon-check"></em>
                            <span>Added</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="organisation-dropdown-results"
                  v-if="addedOrgList.length"
                >
                  <ul class="dropdown-list">
                    <li class="dropdown-items" v-for="(addOrg,i) of addedOrgList" :key="addOrg.id">
                      <div class="dropdown-content d-flex">
                        <div>
                          <h4>{{addOrg.attributes.name}}</h4>
                          <div class="dropdown-info">{{addOrg.attributes.industry_name}}</div>
                        </div>
                      </div>
                      <label class="custom-checkbox organisation-select" name="public3">
                          <input
                            type="checkbox"
                            name="public3"
                            @change="multipleSelectOrg(i, addOrg.status)"
                            v-model="addOrg.status"
                          />
                          <span for="public3" class="checkmark circle"></span>
                          <div>Select All</div>
                      </label>
                      <div class="dropdown-action">
                        <a @click="removeOrg(i, addOrg.id)" class="delete-action">Remove</a>
                      </div>
                      <div class="w-100">
                        <div class="dropdown-user-list">
                          <div
                            class="dropdown-user-item"
                            v-for="(character,index) of addOrg.attributes.organization_characters"
                            :key="character.id"
                          >
                            <div class="user-pic">
                              <label class="custom-checkbox" name="public3">
                                <input
                                  type="checkbox"
                                  name="public3"
                                  @change="characterSelect(i, character.attributes.characters.attributes.status)"
                                  v-model="character.attributes.characters.attributes.status"
                                />
                                <span for="public3" class="checkmark circle"></span>
                              </label>
                              <img
                                alt="users"
                                :src="character.attributes.characters.attributes.photo_url.thumbnail"
                                v-if="character.attributes.characters.attributes.photo_url.thumbnail"
                              />
                            </div>
                            <div class="user-month">
                              <div>{{character.attributes.characters.attributes.full_name}}</div>
                              <span
                                class="custom-tooltip"
                              >{{character.attributes.characters.attributes.full_name}}</span>
                            </div>

                            <div
                              class="user-role dropdown-toggle p-0"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <div>{{character.attributes.world_role.attributes.name}}</div>
                              <span
                                class="custom-tooltip"
                              >{{character.attributes.world_role.attributes.name}}</span>
                            </div>
                            <div
                              class="user-gender"
                            >{{character.attributes.characters.attributes.age}} , {{character.attributes.characters.attributes.gender | uppercase(true)}}</div>
                            <div class="dropdown-menu data-popper-organise" aria-labelledby="menu1">
                              <div class="data-popover">
                                <div class="data-popover-title">
                                  <div class="d-flex align-items-center">
                                    <div class="user-pic">
                                      <img
                                        alt="users"
                                        :src="character.attributes.characters.attributes.photo_url.thumbnail"
                                        v-if="character.attributes.characters.attributes.photo_url.thumbnail != false"
                                      />
                                    </div>
                                    <div>
                                      <span>{{character.attributes.characters.attributes.full_name}}</span>
                                      <div
                                        class="user-poper-gender"
                                      >{{character.attributes.characters.attributes.age}} , {{character.attributes.characters.attributes.gender | uppercase(true)}}</div>
                                    </div>
                                  </div>
                                  <a class="data-popover-close">
                                    <em class="icon-close"></em>
                                  </a>
                                </div>
                                <form class="data-popover-form">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      v-model="searchRole"
                                      v-on:input="getRole"
                                      class="form-control"
                                      placeholder="Enter Role"
                                    />
                                    <div class="dropdown-list mt-3" v-if="roles.length">
                                      <div
                                        class="dropdown-items"
                                        v-for="(role) of roles"
                                        :key="role.id"
                                      >
                                        <div class="dropdown-content">
                                          <h4
                                            @click="setRoles(i,index,role.id, role.attributes.name)"
                                          >{{role.attributes.name}}</h4>
                                        </div>
                                        <div class="dropdown-action">
                                          <div class="dropdown-action">
                                            <a
                                              class="add-action"
                                              @click="setRoles(i,index,role.id, role.attributes.name)"
                                            >+</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div class="form-group-button">
                                    <button class="btn btn-save">Save</button>
                                  </div>-->
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn default" @click="cancel">Cancel</button>
                <button type="button" class="btn primary" @click="worldCreate">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>
    <modal name="worldImportConfirmationModal" height="auto" :width="350" :scrollable="true">
    <div class="modal-content modal-delete-content">
      <div class="modal-body modal-delete-body">This world already exists! Do you want to overwrite it?</div>
      <div class="modal-footer justify-content-center">
        <button
          type="button"
          @click="$modal.hide('worldImportConfirmationModal')"
          class="btn medium default"
        >Cancel</button>
        <button
          type="button"
          @click="importWorld(),$modal.hide('worldImportConfirmationModal')"
          class="btn medium primary"
        >Ok</button>
      </div>
    </div>
</modal>
  </div>
</template>

<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "World-component",
  metaInfo: {
    title: "All Worlds",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  filters: {
    uppercase : function(value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  data() {
    return {
      noCustomerAdded: false,
      name: "",
      searchRole: "",
      editWorldStatus: false,
      searchOrg: "",
      type: "private",
      customer: "",
      description: "",
      customers: [],
      worldsList: [],
      addedCustomer: {},
      totalPages: [],
      worldOrg: [],
      noOrgAdded: false,
      page: 1,
      roles: [],
      sortColumn: "id",
      searchStatus: false,
      addOrganisation: false,
      noCharacterAdded: false,
      showOrgList: true,
      totalWorlds: "",
      searchWorld: "",
      sortOrder: "desc",
      sorting: "desc",
      columnSorting: 'name',
      worldDetails: {},
      addedOrgList: [],
      world_organizations_attributes: [],
      world_org_characters_attributes: [],
      isActive: false,
      importedFile: null,
      settings: {
        maxScrollbarLength: false
      },
      url: utilFunctionService.getImageUrl(),
      display: false,
       options: {
      text: {
        color: "#FFF",
        shadowEnable: true,
        fontSize: 12,
        fontFamily: "Helvetica",
        dynamicPosition: false,
        hideText: false
      },
      progress: {
        color: "#2dbd2d",
        backgroundColor: "rgba(0, 0, 0, 0.09)"
      },
      layout: {
        height: 15,
        width: 400,
        verticalTextAlign: 80,
        horizontalTextAlign: 43,
        zeroOffset: 0,
        strokeWidth: 15,
        progressPadding: 0,
        type: "line"
      }
      }
    };
  },
  validations: {
    name: { required }
  },
  methods: {
    showNotification() {
      return this.$store.state.importWorldstatus === true;
    },
    importWorld(e) {
      let overwriteConfirmed = false;
      utilFunctionService.showLoader();
      if (e) {
        this.importedFile = e.target.files[0];
      } else{
        overwriteConfirmed = true;
      }
      const fb = new FormData();
      fb.append("file", this.importedFile);
      fb.append("confirmed", overwriteConfirmed);
      api
        .importWorld(fb)
        .then(() => {
          utilFunctionService.hideLoader();
          utilFunctionService.showSuccess(commonConfig.appMessages.uploadWorldQueued);
          this.$store.dispatch('startWorldImport');
        })
        .catch((error) => {
         if (error.response.data.world_exists == true){
          utilFunctionService.hideLoader();
          this.$modal.show("worldImportConfirmationModal");
         }
         else{
            utilFunctionService.showerr(commonConfig.appMessages.invalidJson);
            utilFunctionService.hideLoader(); 
         }         
        });
    },
    multipleSelectOrg(i, status) {
      if (this.addedOrgList.length > 0) {
        this.addedOrgList[i].attributes.organization_characters.forEach(
          element => (element.attributes.characters.attributes.status = status)
        );
        this.$mount();
      }
    },
    characterSelect(i, status) {
      const a = this.addedOrgList[i].attributes.organization_characters.every(
        element => element.attributes.characters.attributes.status == status
      );
      if (a) {
        this.addedOrgList[i].status = true;
      } else {
        this.addedOrgList[i].status = false;
      }
      this.$mount();
    },
    // toggleSort(colName, sortType) {
    //   this.sortOrder = sortType;
    //   this.sortColumn = colName;
    //   this.getWorlds(this.page);
    // },
    toggleSort(colName, sortType) {
      this.sortOrder = sortType;
      this.sorting = this.sortOrder == 'asc' ? 'desc' : 'asc';
      this.sortColumn = colName;
      this.columnSorting = this.sortColumn;
      this.getWorlds(this.page);
    },
    show(e) {
      this.$modal.show(e);
    },
    hide(e) {
      this.clearForm();
      this.$modal.hide(e);
    },
    clearForm() {
      this.name = "";
      this.description = "";
      this.addedCustomer = {};
      this.addOrganisation = false;
      this.addedOrgList = [];
      this.noCustomerAdded = false;
      this.noOrgAdded = false;
      this.noCharacterAdded = false;
      this.editWorldStatus = false;
      this.worldDetails = {};
    },
    logOut() {
      utilFunctionService.removeLocalStorageService("user");
      this.$router.push({ name: "login" });
    },
    setRoles(i, characterId, id, name) {
      this.addedOrgList[i].attributes.organization_characters[
        characterId
      ].attributes.world_role_id = id;
      this.addedOrgList[i].attributes.organization_characters[
        characterId
      ].attributes.world_role.attributes.name = name;
    },
    searchOrganisation() {
      if (this.searchOrg.length === 0) {
        this.worldOrg = [];
        this.showOrgList = false;
      } else {
        api
          .getWorldOrganisation(this.searchOrg)
          .then(response => {
            this.worldOrg = response.data.data;
            if (this.addedOrgList.length > 0) {
              this.addedOrgList.forEach(element => {
                const _index = this.worldOrg.findIndex(
                  elem => elem.attributes.name === element.attributes.name
                );
                if (_index !== -1) {
                  this.worldOrg[_index].status = true;
                }
              });
            }
            if (this.worldOrg.length === 0) {
              this.showOrgList = false;
            } else {
              this.showOrgList = true;
            }
          })
          .catch();
      }
    },
    getRole() {
      if (this.searchRole.length === 0) {
        this.roles = [];
      } else {
        api
          .getRoles(this.searchRole)
          .then(response => {
            this.roles = response.data.data;
          })
          .catch();
      }
    },
    worldCreate() {
      let orgLists = this.addedOrgList;
      if (orgLists.length === 0) {
        this.noOrgAdded = true;
      } else {
        this.noOrgAdded = false;

        this.world_organizations_attributes = this.addedOrgList.map(x => {
          let status = x.attributes.organization_characters
            .filter(x => x.attributes.characters.attributes.status == true)
            .map(y => {
              return {
                id: null,
                world_role_id: y.attributes.world_role_id,
                character_id: parseInt(y.attributes.characters.id)
              };
            });
          return status;
        });
        if (this.world_organizations_attributes[0].length > 0) {
          if (this.worldDetails.id) {
            this.world_organizations_attributes = this.addedOrgList.map(x => {
              let z = x.attributes.organization_characters.map(y => {
                if (
                  y.attributes.characters.attributes.status ||
                  y.attributes.characters.attributes.alreadyAdded
                ) {
                  return {
                    id: y.attributes.characters.attributes.alreadyAdded
                      ? parseInt(y.id)
                      : null,
                    world_role_id: parseInt(y.attributes.world_role_id),
                    character_id: parseInt(y.attributes.character_id),
                    _destroy: !y.attributes.characters.attributes.status
                  };
                }
              });
              return {
                id: x && x.alreadyAdded ? parseInt(x.id) : null,
                _destroy: false,
                organization_id: parseInt(x.attributes.organization_id)
                  ? parseInt(x.attributes.organization_id)
                  : parseInt(x.id),
                world_org_characters_attributes: z
              };
            });

            let worldType;
            if (this.type === "private") {
              worldType = true;
            } else {
              worldType = false;
            }

            api
              .editWorld(
                this.worldDetails.id,
                this.name,
                this.description,
                worldType,
                this.addedCustomer.id,
                this.world_organizations_attributes
              )
              .then(() => {
                this.hide("newWorld");
                this.getWorlds(this.page);
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.editWorld
                );
              })
              .catch();
          } else {
            this.world_organizations_attributes = this.addedOrgList.map(x => {
              let z = x.attributes.organization_characters
                .filter(x => x.attributes.characters.attributes.status == true)
                .map(y => {
                  return {
                    id: null,
                    world_role_id: y.attributes.world_role_id,
                    character_id: parseInt(y.attributes.characters.id)
                  };
                });
              return {
                id: null,
                organization_id: parseInt(x.id),
                world_org_characters_attributes: z
              };
            });

            let worldType;
            if (this.type === "private") {
              worldType = true;
            } else {
              worldType = false;
            }

            api
              .saveWorld(
                this.name,
                this.description,
                worldType,
                this.addedCustomer.id,
                this.world_organizations_attributes
              )
              .then(() => {
                this.hide("newWorld");
                this.getWorlds(this.page);
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.worldCreate
                );
              })
              .catch();
          }
        } else {
          this.noCharacterAdded = true;
        }
      }
    },
    createWorld() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          if (this.type === "private" && this.addedCustomer.id == undefined) {
            this.noCustomerAdded = true;
          } else {
            this.addOrganisation = !this.addOrganisation;
          }
        }
      });
    },
    deleteWorld(id) {
      utilFunctionService.showLoader();
      api
        .deleteWorld(id)
        .then(() => {
          utilFunctionService.showSuccess(commonConfig.appMessages.deleteWorld);
          utilFunctionService.hideLoader();
          let page;
          if (this.worldsList.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getWorlds(page);
            } else {
              page = this.page - 1;
              this.getWorlds(page);
            }
          } else {
            const page = this.page;
            this.getWorlds(page);
          }
        })
        .catch(error => {
          utilFunctionService.showErr(
            error.response.data.errors
          );
          utilFunctionService.hideLoader();
        });
    },
    cancel() {
      this.addOrganisation = !this.addOrganisation;
    },
    searchCustomer() {
      if (this.customer.length === 0) {
        this.customers = [];
      } else {
        api
          .getCustomers(this.customer)
          .then(res => {
            this.customers = [];
            const customersList = res.data.data.map(item => {
              const customerObj = {};
              customerObj["name"] = item.attributes.name;
              customerObj["email"] = item.attributes.email;
              customerObj["id"] = item.id;
              customerObj["status"] = false;
              return customerObj;
            });
            if (this.addedCustomer && this.addedCustomer.id) {
              const index = customersList.findIndex(
                elem => elem.id === this.addedCustomer.id
              );
              if (index !== -1) {
                customersList[index].status = true;
              }
              this.customers = customersList;
            } else {
              this.customers = customersList;
            }
          })
          .catch();
      }
    },
    addOrg(e) {
      this.worldOrg = [];
      this.searchOrg = "";
      this.addedOrgList.push(e);
      if (this.addedOrgList.length === 0) {
        this.noOrgAdded = true;
      } else {
        this.noOrgAdded = false;
      }
    },
    removeOrg(i, worldOrgId) {
      this.addedOrgList.splice(i, 1);
      if (this.worldDetails.id) {
        let worldId = this.worldDetails.id;
        api.deleteWorldOrg(worldId, worldOrgId).then();
      }
    },
    addCustomer(e) {
      this.customer = "";
      this.customers = [];
      this.addedCustomer = e;
      this.noCustomerAdded = false;
    },
    removeCustomer() {
      if (this.worldDetails.id) {
        return;
      } else {
        this.addedCustomer = {};
        this.customers = [];
      }
    },
    nextPage() {
      this.page = this.page + 1;
      this.getWorlds(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getWorlds(this.page);
    },
    getWorlds(page) {
      this.page = page;
      utilFunctionService.showLoader();
      api
        .getAllWorlds(page, this.searchWorld, this.sortColumn, this.sortOrder)
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.searchStatus = false;
          this.worldsList = res.data.data;
          this.totalWorlds = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            utilFunctionService.showErr(commonConfig.appMessages.sessionExpire);
            utilFunctionService.hideLoader();
            utilFunctionService.removeLocalStorageService("user");
            this.$router.push({ name: "login" });
          }
        });
    },
    search(value) {
      api
        .getAllWorlds(1, value, this.sortColumn, this.sortOrder)
        .then(res => {
          this.searchStatus = true;
          this.worldsList = res.data.data;
          this.totalWorlds = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    editWorld(id) {
      api
        .getWorldDetails(id)
        .then(res => {
          this.show("newWorld");
          this.worldDetails = res.data.data;
          this.editWorldStatus = true;
          this.name = this.worldDetails.attributes.name;
          this.description = this.worldDetails.attributes.description;
          if (this.worldDetails.attributes.is_private) {
            this.type = "private";
          } else {
            this.type = "public";
          }
          if (
            this.worldDetails.attributes &&
            this.worldDetails.attributes.customer
          ) {
            this.addedCustomer.name = this.worldDetails.attributes.customer.attributes.name;
            this.addedCustomer.id = this.worldDetails.attributes.customer.id;
            this.addedCustomer.email = this.worldDetails.attributes.customer.attributes.email;
            this.addedCustomer.status = true;
          }

          this.addedOrgList = this.worldDetails.attributes.world_organizations.map(
            x => {
              x.attributes.name = x.attributes.organization.attributes.name;
              x.attributes.description =
                x.attributes.organization.attributes.description;
              x.attributes.industry_name =
                x.attributes.organization.attributes.industry_name;
              x.attributes.photo_url =
                x.attributes.organization.attributes.photo_url;
              x.alreadyAdded = true;
              x.attributes.organization_character = x.attributes.organization_character.map(
                y => {
                  y.attributes.characters.attributes.status = false;
                  return y;
                }
              );
              x.attributes.world_org_characters = x.attributes.world_org_characters.map(
                z => {
                  z.attributes.world_role_id = z.attributes.world_role.id;
                  z.attributes.characters.attributes.alreadyAdded = true;
                  z.attributes.characters.attributes.status = true;
                  delete z.attributes.character;
                  return z;
                }
              );
              let a = [
                ...x.attributes.organization_character,
                ...x.attributes.world_org_characters
              ];
              x.attributes = { ...x.attributes, organization_characters: a };
              x.status = x.attributes.organization_characters.every(x => {
                const a = x.attributes.characters.attributes.status == true;
                if (a) {
                  return true;
                } else {
                  return false;
                }
              })
              delete x.attributes.world_org_characters;
              return x;
            }
          );
        })
        .catch();
    }
  },
  created() {
    this.getWorlds(this.page);
  },
  watch: {}
};
</script>

<style lang="scss">
  @import "../styles/clone-notification";
  .customer-scroll-area {
    max-height: 180px;
  }
  .activePage {
    background-color: #007bff;
    border-color: #007bff;
  }
</style>
