<template>
  <modal
    name="createUser"
    height="auto"
    :width="500"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title" id="exampleModalLabel">
          <h5 v-if="user_id">Edit User</h5>
          <h5 v-else>Create a new User</h5>
        </div>
        <button type="button" class="close" @click="hide('createUser')">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body">
        <ValidationObserver ref="form">
          <form class="modal-form organisation-form">
            <div class="organisation-image-group">
              <div class="organisation-image-pic cursor"  @click="$refs.fileInput.click()">
                <img :src="image" v-if="image" alt="images"/>
              </div>
              <div class="organisation-image-remove">
                <input style="display: none;" ref="fileInput" type="file" @change="onFileSelectImage" accept="image/*"/>
                <a class="btn add" @click="$refs.fileInput.click()" v-if="!image">Add Photo</a>
                <a class="btn delete" @click="imageRemoved" v-else>Remove Photo</a>
              </div>
            </div>
             <div class="form-group">
              <label class="form-label" for="first_name">First Name</label>
              <ValidationProvider name="First Name" rules="required|max:100" v-slot="{ errors }">
                <input
                  type="first_name"
                  class="form-control"
                  id="first_name"
                  aria-describedby="emailHelp"
                  v-model="first_name"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label" for="last_name">Last Name</label>
              <ValidationProvider name="Last Name" rules="required|max:100" v-slot="{ errors }">
                <input
                  type="last_name"
                  class="form-control"
                  id="last_name"
                  aria-describedby="emailHelp"
                  v-model="last_name"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label" for="last_name">Email</label>
              <ValidationProvider name="Email" rules="required|max:100" v-slot="{ errors }">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  v-model="email"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
             <div class="form-group">
              <label class="form-label" for="last_name">Password</label>
              <ValidationProvider name="Password" rules="max:100" v-slot="{ errors }">
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  aria-describedby="emailHelp"
                  v-model="password"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label" for="exampleInputEmail1">Add a Role</label>
              <input
                type="text"
                class="form-control"
                v-model="searchRoles"
                v-on:input="getRoles"
              />
              <div v-if="rolesList.length">
                <div class="dropdown-menu" :class="[rolesList.length !== 0 ? 'show' : '']">
                  <ul class="dropdown-list">
                    <li
                      class="dropdown-items"
                      v-for="roles in rolesList"
                      :key="roles.id"
                    >
                      <div class="dropdown-content">
                        <h4>{{roles.attributes.name}}</h4>
                      </div>
                      <div class="dropdown-action" v-if="!roles.status">
                        <a @click="addRoles(roles)" class="add-action">+</a>
                      </div>
                      <div class="dropdown-action" v-if="roles.status">
                        <em class="icon-check"></em>
                        <span>Added</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="customer-dropdown" v-if="addedRoles.length">
              <ul class="dropdown-list mt-0 mb-4">
                <!-- repeat dropdown items start  -->
                <li
                  class="dropdown-items"
                  v-for="(roles, index) in addedRoles"
                  :key="roles.id"
                >
                  <div class="dropdown-content">
                    <div class="user-name">{{roles.attributes.name}}</div>
                    <a class="btn delete" @click="removeRole(index)">Remove</a>
                  </div>
                </li>
              </ul>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn default"
          data-dismiss="modal"
          @click="hide('createUser')"
        >Cancel</button>
        <button type="button" class="btn primary" @click="saveUser" data-dismiss="modal">Save</button>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
export default {
  name: "createUser",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      selectedFile: null,
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      searchRoles: "",
      rolesList: [],
      addedRoles: [],
      removeRoles: [],
      searchRole: "",
      roles: [],
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      user_id: "",
      showRole: "",
      page: 1
    };
  },
  filters: {
    uppercase : function(value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  methods: {
    onFileSelectImage(event) {
      this.selectedFile = event.target.files[0];
      let reader = null;
      reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = e => {
        this.image = e.target.result;
      };
    },
    imageRemoved: function() {
      if (this.user_id)
        api.removeuserPhoto(this.user_id);
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
      this.selectedFile = null;
      this.image = null;
    },
    getRoles() {
      if (this.searchRoles.length === 0) {
        this.rolesList = [];
      } else {
        api
          .getAllRoles(this.searchRoles)
          .then(res => {
            console.log("In getRoles methods...");
            this.rolesList = res.data.data;
            console.log("this.rolesList ...");
            console.log(this.rolesList);
            console.log("this.addedRoles ...");
            console.log(this.addedRoles);
            if (this.addedRoles.length > 0) {
              this.addedRoles.forEach(element => {
                console.log("element" + element);
                const index = this.rolesList.findIndex(
                  (elem) => {
                   return parseInt(elem.id) === parseInt(element.id)
                  }
                );
               console.log("index" + index);
                if (index !== -1) {
                  this.rolesList[index].status = true;
                }
              });
            }
             console.log("this.rolesList");
             console.log(this.rolesList);
          })
          .catch();
      }
    },
    saveUser() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          if (this.selectedFile) {
             fb.append("user[photo]", this.selectedFile);
          }
          fb.append("user[first_name]", this.first_name);
          fb.append("user[last_name]", this.last_name);
          fb.append("user[email]", this.email);
          if (this.password && this.password.length > 0) fb.append("user[password]", this.password);
          var key = 0;
          this.addedRoles.map((x) => {
            if (x.attributes) {
              fb.append(
                "user[roles_attributes][" +
                  key +
                  "][name]",
                x.attributes.name
              );
              fb.append(
                "user[roles_attributes][" +
                  key +
                  "][_destroy]",
                false
              );
              key++;
            }
          });
          this.removeRoles.map((x) => {
              fb.append(
                "user[roles_attributes][" +
                  key +
                  "][name]",
                x.attributes.name);
              fb.append(
                "user[roles_attributes][" +
                  key +
                  "][_destroy]",
                true
              );
              key++;
          });
          if (this.user_id) {
            fb.append("id", this.user_id);
            api.updateUser(this.user_id, fb).then(() => {
              utilFunctionService.showSuccess(commonConfig.appMessages.editUser);
              this.$parent.getUsers(this.page);
              this.hide("createUser");
            });
          } else {
            api.saveUser(fb).then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.userCreate
              );
              this.$parent.getUsers(this.page);
              this.hide("createUser");
            });
          }
        }
      });
    },
    clearForm() {
      this.selectedFile = null;
      this.first_name = null;
      this.name = "";
      this.description = "";
      this.rolesList = [];
      this.addedRoles = [];
      this.removeRoles = [];
      this.roles = [];
      this.user_id = "";
      this.searchRole = "";
    },
    hide() {
      this.clearForm();
      this.$parent.hide("createUser");
    },
    addRoles(e) {
      this.rolesList = [];
      this.addedRoles.push(e);
    },
    removeRole(e) {
      if (this.addedRoles[e].id) {
        this.removeRoles.push(this.addedRoles[e]);
      }
      this.addedRoles.splice(e, 1);
    },
    beforeOpen(event) {
      this.clearForm();
      this.user_id = event.params.user_id;
      this.page = event.params.page;
      this.getUsersDetails(this.user_id);
    },
    getUsersDetails(user_id) {
      api
        .getUsersDetails(user_id)
        .then(res => {
          this.first_name = res.data.data.attributes.first_name;
          this.last_name = res.data.data.attributes.last_name;
          this.email = res.data.data.attributes.email;
          this.password = res.data.data.attributes.password;
          this.image = res.data.data.attributes.photo_url
            ? res.data.data.attributes.photo_url.large_version
            : "";
          if (res.data.data.attributes.roles) {
            res.data.data.attributes.roles.forEach(
                y  => {
                this.addedRoles.push(y)
              });
          }
        })
        .catch();
    },
  }
};
</script>
