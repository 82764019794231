<template>
  <div id="Organisation">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>All Organizations</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchOrg"
                placeholder="Search"
                v-model="searchOrg"
                v-debounce:400ms="searchOrganization"
              />
              <em class="icon-search"></em>
            </div>
          </form>
          <a
            @click="$modal.show('createOrganisation',{organisation_id : '' })"
            class="btn header-btn primary"
          >Add a new Organization</a>
        </div>
        <p v-if="totalOrgs > 1 && !searchOrg">You have {{totalOrgs}} Organizations in the system.</p>
        <p v-else-if="totalOrgs <= 1 && !searchOrg">You have {{totalOrgs}} Organization in the system.</p>
        <p v-else-if="totalOrgs > 1 && searchOrg">You have {{totalOrgs}} Organizations in the search result.</p>
        <p v-else>You have {{totalOrgs}} Organization in the search result.</p>
      </header>
      <div class="data-content" v-if="orgLists.length !== 0">
        <div class="data-table data-column-5">
          <div class="data-head">
            <div class="data-col">
              Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'organizations.name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('organizations.name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'organizations.name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('organizations.name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Created on
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('created_at','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'created_at' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('created_at','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Industry type
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'industries.name' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('industries.name','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'industries.name' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('industries.name','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Characters
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[sortColumn === 'characters_count' && sortOrder === 'asc' ? 'active' : '']"
                  @click="toggleSort('characters_count','asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[sortColumn === 'characters_count' && sortOrder === 'desc' ? 'active' : '']"
                  @click="toggleSort('characters_count','desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Actions</div>
          </div>
          <div class="data-items">
            <div class="data-row" v-for="org in orgLists" :key="org.id">
              <div class="data-col">
                <div class="data-image">
                  <div class="data-pic organise-pic cursor" @click="$modal.show('organisationDetail',{organisation_id : org.id })">
                    <img
                      :src="org.attributes.photo_url.thumbnail"
                      v-if="org.attributes.photo_url && org.attributes.photo_url.thumbnail"
                      alt="image2"
                    />
                  </div>
                  <div
                    @click="$modal.show('organisationDetail',{organisation_id : org.id })"
                    class="data-text cursor"
                  >{{org.attributes.name}}</div>
                </div>
              </div>
              <div class="data-col">{{org.attributes.created_on}}</div>
              <div class="data-col">{{org.attributes.industry_name}}</div>
              <div class="data-col">
                <div class="customer-dropdown">
                  <button
                    type="button"
                    class="btn data-multiple-btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div
                      class="data-multiple-pic"
                      v-if="org.attributes.organization_characters.length > 0"
                    >
                      <img
                        :src="org.attributes.organization_characters[0].attributes.characters.attributes.photo_url.thumbnail"
                        v-if="org.attributes.organization_characters[0].attributes.characters.attributes.photo_url && org.attributes.organization_characters[0].attributes.characters.attributes.photo_url.thumbnail"
                        alt="image2"
                      />
                    </div>
                    <div
                      class="data-multiple-pic"
                      v-if="org.attributes.organization_characters.length > 1"
                    >
                      <img
                        :src="org.attributes.organization_characters[1].attributes.characters.attributes.photo_url.thumbnail"
                        v-if="org.attributes.organization_characters[1].attributes.characters.attributes.photo_url.thumbnail"
                        alt="image2"
                      />
                    </div>
                    <div
                      class="data-multiple-pic"
                      v-if="org.attributes.organization_characters.length > 2"
                    >
                      <img
                        :src="org.attributes.organization_characters[2].attributes.characters.attributes.photo_url.thumbnail"
                        v-if="org.attributes.organization_characters[2].attributes.characters.attributes.photo_url.thumbnail"
                        alt="image2"
                      />
                    </div>
                    <div
                      class="data-multiple-pic"
                      v-if="org.attributes.organization_characters.length > 3"
                    >+{{org.attributes.organization_characters.length - 3}}</div>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    v-if="org.attributes.organization_characters.length"
                  >
                    <h3>All Characters({{org.attributes.organization_characters.length}})</h3>
                    <VuePerfectScrollbar class="scroll-area" :settings="settings">
                      <ul class="dropdown-list">
                        <li
                          class="dropdown-items"
                          v-for="(organizationcharacters) of org.attributes.organization_characters"
                          :key="organizationcharacters.id"
                        >
                          <div class="dropdown-pic">
                            <img
                              :src="organizationcharacters.attributes.characters.attributes.photo_url.thumbnail"
                              v-if="organizationcharacters.attributes.characters.attributes.photo_url && organizationcharacters.attributes.characters.attributes.photo_url.thumbnail"
                              alt="image2"
                            />
                          </div>
                          <div class="dropdown-content">
                            <div
                              class="user-name"
                            >{{organizationcharacters.attributes.characters.attributes.full_name}}</div>
                            <div
                              class="user-role"
                            >{{organizationcharacters.attributes.world_role.attributes.name}}</div>
                            <div
                              class="user-age"
                            >{{organizationcharacters.attributes.characters.attributes.age}}
                            <div class="d-inline" v-if="organizationcharacters.attributes.characters.attributes.gender != null">,</div>
                            {{organizationcharacters.attributes.characters.attributes.gender | upperCaseChar(true)}}</div>
                          </div>
                        </li>
                      </ul>
                    </VuePerfectScrollbar>
                  </div>
                </div>
              </div>
              <div class="data-col data-action">
                <div class="data-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-edit" @click="$modal.show('organisationDetail',{organisation_id : org.id })"></em>
                  </button>
                </div>
                <div class="data-delete-dropdown">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <em class="icon-delete"></em>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div class="data-popper-title">
                      Are you sure you want to delete this Organization?
                    </div>
                    <div class="data-popper-footer">
                      <a class="btn default">cancel</a>
                      <a class="btn primary" @click="deleteOrg(org.id)">Ok</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">Showing {{page}} of {{totalPages.length}}</div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li class="page-item" v-for="pages in totalPages.slice(page-1, page+3)" :key="pages">
                <a
                  class="page-link"
                  :class="[pages === page ? 'active' : '']"
                  @click="getOrganization(pages)"
                >{{pages}}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="data-no-result" v-if="orgLists.length === 0 && !searchOrg && display">
        <h3>There’s nothing here!</h3>
        <p>You have no organization in the system yet.</p>
        <div>
          <a
            @click="$modal.show('createOrganisation',{organisation_id : '' })"
            class="btn header-btn primary"
          >Add a new organization</a>
        </div>
      </div>
      <div class="data-no-result" v-if="orgLists.length === 0 && searchOrg">
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <createOrganisation />
    <organisationDetail />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { commonConfig } from "@/utils/commonConfig";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import createOrganisation from "../components/modal/CreateOrganisation.vue";
import organisationDetail from "../components/modal/OrganisationDetail.vue";

export default {
  name: "organisation-component",
  components: {
    VuePerfectScrollbar,
    createOrganisation,
    organisationDetail
  },
  metaInfo: {
    title: "All Organization",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  filters: {
    upperCaseChar : function(value, onlyCharacterFirst) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (onlyCharacterFirst) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false
      },
      searchRole: "",
      characters: [],
      roles: [],
      page: 1,
      orgLists: [],
      totalOrgs: "",
      totalPages: [],
      searchOrg: "",
      sortOrder: "desc",
      sortColumn: "organizations.id",
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      organizationcharacters: "",
      searchOrganisations: "",
      display : false
    };
  },
  methods: {
    show() {
      this.$modal.show();
    },
    hide(modal) {
      this.$modal.hide(modal);
      this.getOrganization(this.page);
    },
    searchOrganization(value) {
      this.display = false;
      api
        .getAllOrganization(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.orgLists = res.data.data;
          this.totalOrgs = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    getOrganization(page) {
      utilFunctionService.showLoader();
      this.page = page;
      api
        .getAllOrganization(
          page,
          this.searchOrg,
          this.sortColumn,
          this.sortOrder
        )
        .then(res => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.orgLists = res.data.data;
          this.totalOrgs = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    toggleSort(colName, sortType) {
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getOrganization(this.page);
    },
    deleteOrg(id) {
      utilFunctionService.showLoader();
      api.deleteOrg(id).then(() => {
        utilFunctionService.hideLoader();
        let page;
          if (this.orgLists.length === 1) {
            page = this.page;
            if (page === 1) {
              page = 1;
              this.getOrganization(page);
            } else {
              page = this.page - 1;
              this.getOrganization(page);
            }
          } else {
            const page = this.page;
            this.getOrganization(page);
          }
        utilFunctionService.showSuccess(commonConfig.appMessages.deleteOrg);
      })
      .catch(error => {
          utilFunctionService.showErr(
            error.response.data.errors
          );
          utilFunctionService.hideLoader();
      });
    },
    nextPage() {
      this.page = this.page + 1;
      this.getOrganization(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getOrganization(this.page);
    }
  },
  created() {
    this.getOrganization(this.page);
  }
};
</script>
