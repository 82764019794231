var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "World" } },
    [
      _c(
        "modal",
        {
          attrs: { name: "newWorld", height: "auto", scrollable: true },
          on: { "before-open": _vm.beforeOpen },
        },
        [
          !_vm.editOrg && !_vm.editOrgs && _vm.addedOrgList.length
            ? _c("div", { staticClass: "modal-content" }, [
                _c("div", [
                  !_vm.addOrganisation
                    ? _c("div", { attrs: { id: "AddNewWorld" } }, [
                        _c("div", { staticClass: "modal-header" }, [
                          _c(
                            "div",
                            {
                              staticClass: "modal-title",
                              attrs: { id: "exampleModalLabel" },
                            },
                            [
                              !_vm.editWorldStatus
                                ? _c("h5", [_vm._v("Create a new world")])
                                : _vm._e(),
                              _vm.editWorldStatus
                                ? _c("h5", [_vm._v("Edit world")])
                                : _vm._e(),
                              !_vm.editWorldStatus
                                ? _c("p", [
                                    _vm._v(
                                      "Create a new world and add customers."
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.hide("newWorld")
                                },
                              },
                            },
                            [_c("em", { staticClass: "icon-close" })]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-body" },
                          [
                            _c("ValidationObserver", { ref: "form" }, [
                              _c("form", { staticClass: "modal-form" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Name"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Name",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.name,
                                                      expression: "name",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    id: "name",
                                                    "aria-describedby":
                                                      "emailHelp",
                                                    maxlength: "30",
                                                  },
                                                  domProps: { value: _vm.name },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.name =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4051322429
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Description"),
                                  ]),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.description,
                                        expression: "description",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { maxlength: "500" },
                                    domProps: { value: _vm.description },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.description = $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "form-characters" },
                                    [_vm._v("500 characters")]
                                  ),
                                ]),
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Type"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "form-checkboxes" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox",
                                          class: [
                                            _vm.editWorldStatus
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: { name: "public" },
                                        },
                                        [
                                          _vm._v(" Public "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.type,
                                                expression: "type",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.editWorldStatus == true,
                                              type: "radio",
                                              name: "public",
                                            },
                                            domProps: {
                                              value: "public",
                                              checked: _vm._q(
                                                _vm.type,
                                                "public"
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.type = "public"
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "public" },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-checkbox",
                                          class: [
                                            _vm.editWorldStatus
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: { name: "private" },
                                        },
                                        [
                                          _vm._v(" Private "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.type,
                                                expression: "type",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.editWorldStatus == true,
                                              type: "radio",
                                              name: "private",
                                            },
                                            domProps: {
                                              value: "private",
                                              checked: _vm._q(
                                                _vm.type,
                                                "private"
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.type = "private"
                                              },
                                            },
                                          }),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            attrs: { for: "private" },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm.type === "private"
                                  ? _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "label",
                                        { staticClass: "form-label" },
                                        [_vm._v("Customer")]
                                      ),
                                      !_vm.editWorldStatus
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "customer-dropdown",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.customer,
                                                    expression: "customer",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.noCustomerAdded
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  disabled:
                                                    _vm.editWorldStatus == true,
                                                  type: "text",
                                                  placeholder: "Type here",
                                                },
                                                domProps: {
                                                  value: _vm.customer,
                                                },
                                                on: {
                                                  input: [
                                                    function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.customer =
                                                        $event.target.value
                                                    },
                                                    _vm.searchCustomer,
                                                  ],
                                                },
                                              }),
                                              _vm.noCustomerAdded
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "invalid",
                                                      staticStyle: {
                                                        display: "block",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Add customer is required"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.customers.length != 0
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-menu",
                                                        class: [
                                                          _vm.customers
                                                            .length !== 0
                                                            ? "show"
                                                            : "",
                                                        ],
                                                      },
                                                      [
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticClass:
                                                              "dropdown-list",
                                                          },
                                                          _vm._l(
                                                            _vm.customers,
                                                            function (
                                                              customer
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: customer.id,
                                                                  staticClass:
                                                                    "dropdown-items",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "dropdown-content",
                                                                    },
                                                                    [
                                                                      _c("h4", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            customer.name
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            customer.email
                                                                          )
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                  !customer.status
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-action",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "add-action",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addCustomer(
                                                                                      customer
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "+"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  customer.status
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "dropdown-action",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "em",
                                                                            {
                                                                              staticClass:
                                                                                "icon-check",
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Added"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.addedCustomer.id
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "customer-dropdown-results",
                                            },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass: "dropdown-list",
                                                },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "dropdown-items",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-content",
                                                        },
                                                        [
                                                          _c("h4", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .addedCustomer
                                                                  .name
                                                              )
                                                            ),
                                                          ]),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .addedCustomer
                                                                  .email
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-action",
                                                        },
                                                        [
                                                          !_vm.editWorldStatus
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "delete-action",
                                                                  class: [
                                                                    _vm.editWorldStatus
                                                                      ? "disabled"
                                                                      : "",
                                                                  ],
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeCustomer()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Remove"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "modal-footer" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn default",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.hide("newWorld")
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  !_vm.editWorldStatus
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn primary",
                                          attrs: {
                                            type: "button",
                                            "data-toggle": "modal",
                                            "data-target": "#addOrganisation",
                                            "data-dismiss": "modal",
                                          },
                                          on: { click: _vm.validateWorld },
                                        },
                                        [
                                          _vm._v(" Save & add organization "),
                                          _c("em", {
                                            staticClass: "icon-carrot-right",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.editWorldStatus
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn primary",
                                          attrs: {
                                            type: "button",
                                            "data-toggle": "modal",
                                            "data-target": "#addOrganisation",
                                            "data-dismiss": "modal",
                                          },
                                          on: { click: _vm.validateWorld },
                                        },
                                        [
                                          _vm._v(" Save & edit organization "),
                                          _c("em", {
                                            staticClass: "icon-carrot-right",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.addOrganisation
                    ? _c("div", { attrs: { id: "AddOrganisation" } }, [
                        _c("div", { staticClass: "modal-header" }, [
                          _c(
                            "div",
                            {
                              staticClass: "modal-title",
                              attrs: { id: "exampleModalLabel" },
                            },
                            [
                              !_vm.editWorldStatus
                                ? _c("h5", [
                                    _vm._v("Add Organization to the World"),
                                  ])
                                : _vm._e(),
                              _vm.editWorldStatus
                                ? _c("h5", [
                                    _vm._v("Edit Organization to the World"),
                                  ])
                                : _vm._e(),
                              _vm.worldDetails.id
                                ? _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.worldDetails.attributes.name) +
                                        ", " +
                                        _vm._s(
                                          _vm.worldDetails.attributes.world_code
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.hide("newWorld")
                                },
                              },
                            },
                            [_c("em", { staticClass: "icon-close" })]
                          ),
                        ]),
                        _c("div", { staticClass: "modal-body" }, [
                          _c("form", { staticClass: "modal-form" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "div",
                                { staticClass: "organisation-dropdown" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchOrg,
                                        expression: "searchOrg",
                                      },
                                    ],
                                    staticClass:
                                      "form-control btn dropdown-toggle",
                                    class: [_vm.noOrgAdded ? "invalid" : ""],
                                    attrs: {
                                      type: "text",
                                      placeholder: "Type here",
                                    },
                                    domProps: { value: _vm.searchOrg },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.searchOrg = $event.target.value
                                        },
                                        _vm.searchOrganisation,
                                      ],
                                    },
                                  }),
                                  _vm.noOrgAdded
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "invalid",
                                          staticStyle: { display: "block" },
                                        },
                                        [
                                          _vm._v(
                                            "Atleast one organization is required."
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.noCharacterAdded
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "invalid",
                                          staticStyle: { display: "block" },
                                        },
                                        [
                                          _vm._v(
                                            "Minimum one character selection is required per organization."
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.worldOrg.length
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            class: [
                                              _vm.worldOrg.length !== 0
                                                ? "show"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "ul",
                                              { staticClass: "dropdown-list" },
                                              _vm._l(
                                                _vm.worldOrg,
                                                function (org) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: org.id,
                                                      staticClass:
                                                        "dropdown-items",
                                                    },
                                                    [
                                                      org.attributes.photo_url
                                                        .thumbnail != false
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-pics",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: org
                                                                    .attributes
                                                                    .photo_url
                                                                    .thumbnail,
                                                                  alt: "images",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      org.attributes.photo_url
                                                        .thumbnail == false
                                                        ? _c("div", {
                                                            staticClass:
                                                              "dropdown-pics",
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-content",
                                                        },
                                                        [
                                                          _c("h4", [
                                                            _vm._v(
                                                              _vm._s(
                                                                org.attributes
                                                                  .name
                                                              )
                                                            ),
                                                          ]),
                                                          _c("h5", [
                                                            _vm._v(
                                                              _vm._s(
                                                                org.attributes
                                                                  .industry_name
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      !org.status
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "add-action",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addOrg(
                                                                          org
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("+")]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      org.status
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c("em", {
                                                                staticClass:
                                                                  "icon-check",
                                                              }),
                                                              _c("span", [
                                                                _vm._v("Added"),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm.addedOrgList.length && !_vm.worldOrg.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "organisation-dropdown-results",
                                    },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "dropdown-list" },
                                        _vm._l(
                                          _vm.addedOrgList,
                                          function (addOrg, i) {
                                            return _c(
                                              "li",
                                              {
                                                key: addOrg.id,
                                                staticClass: "dropdown-items",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-content",
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        _vm._s(
                                                          addOrg.attributes.name
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-info",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            addOrg.attributes
                                                              .industry_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-action",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "delete-action",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeOrg(
                                                              i,
                                                              addOrg.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Remove")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "w-100" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-user-list",
                                                      },
                                                      _vm._l(
                                                        addOrg.attributes
                                                          .organization_characters,
                                                        function (
                                                          character,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: character.id,
                                                              staticClass:
                                                                "dropdown-user-item",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "custom-checkbox",
                                                                  attrs: {
                                                                    name: "public3",
                                                                  },
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            character
                                                                              .attributes
                                                                              .characters
                                                                              .attributes
                                                                              .status,
                                                                          expression:
                                                                            "character.attributes.characters.attributes.status",
                                                                        },
                                                                      ],
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                      name: "public3",
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes
                                                                            .status
                                                                        )
                                                                          ? _vm._i(
                                                                              character
                                                                                .attributes
                                                                                .characters
                                                                                .attributes
                                                                                .status,
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : character
                                                                              .attributes
                                                                              .characters
                                                                              .attributes
                                                                              .status,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              character
                                                                                .attributes
                                                                                .characters
                                                                                .attributes
                                                                                .status,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes,
                                                                                  "status",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes,
                                                                                  "status",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              character
                                                                                .attributes
                                                                                .characters
                                                                                .attributes,
                                                                              "status",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "checkmark circle",
                                                                    attrs: {
                                                                      for: "public3",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "user-pic",
                                                                },
                                                                [
                                                                  character
                                                                    .attributes
                                                                    .characters
                                                                    .attributes
                                                                    .photo_url
                                                                    .thumbnail
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: character
                                                                                .attributes
                                                                                .characters
                                                                                .attributes
                                                                                .photo_url
                                                                                .thumbnail,
                                                                              alt: "image",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "user-month",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        character
                                                                          .attributes
                                                                          .characters
                                                                          .attributes
                                                                          .full_name
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "custom-tooltip",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes
                                                                            .full_name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "user-role dropdown-toggle p-0",
                                                                  attrs: {
                                                                    "data-toggle":
                                                                      "dropdown",
                                                                    "aria-haspopup":
                                                                      "true",
                                                                    "aria-expanded":
                                                                      "false",
                                                                  },
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        character
                                                                          .attributes
                                                                          .world_role
                                                                          .attributes
                                                                          .name
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "custom-tooltip",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character
                                                                            .attributes
                                                                            .world_role
                                                                            .attributes
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "user-gender",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      character
                                                                        .attributes
                                                                        .characters
                                                                        .attributes
                                                                        .age
                                                                    ) +
                                                                      " , " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "uppercase"
                                                                        )(
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes
                                                                            .gender,
                                                                          true
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-menu data-popper-organise",
                                                                  attrs: {
                                                                    "aria-labelledby":
                                                                      "menu1",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "data-popover",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "data-popover-title",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-items-center",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "user-pic",
                                                                                },
                                                                                [
                                                                                  character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes
                                                                                    .photo_url
                                                                                    .thumbnail
                                                                                    ? _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: character
                                                                                                .attributes
                                                                                                .characters
                                                                                                .attributes
                                                                                                .photo_url
                                                                                                .thumbnail,
                                                                                              alt: "image",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      character
                                                                                        .attributes
                                                                                        .characters
                                                                                        .attributes
                                                                                        .full_name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "user-poper-gender",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      character
                                                                                        .attributes
                                                                                        .characters
                                                                                        .attributes
                                                                                        .age
                                                                                    ) +
                                                                                      " , " +
                                                                                      _vm._s(
                                                                                        character
                                                                                          .attributes
                                                                                          .characters
                                                                                          .attributes
                                                                                          .gender
                                                                                      )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "data-popover-close",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "em",
                                                                                {
                                                                                  staticClass:
                                                                                    "icon-close",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "form",
                                                                        {
                                                                          staticClass:
                                                                            "data-popover-form",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm.searchRole,
                                                                                        expression:
                                                                                          "searchRole",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "form-control",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      placeholder:
                                                                                        "Enter Role",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm.searchRole,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      [
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            $event
                                                                                              .target
                                                                                              .composing
                                                                                          )
                                                                                            return
                                                                                          _vm.searchRole =
                                                                                            $event.target.value
                                                                                        },
                                                                                        _vm.getRole,
                                                                                      ],
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm
                                                                                .roles
                                                                                .length
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "dropdown-list mt-3",
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.roles,
                                                                                      function (
                                                                                        role
                                                                                      ) {
                                                                                        return _c(
                                                                                          "div",
                                                                                          {
                                                                                            key: role.id,
                                                                                            staticClass:
                                                                                              "dropdown-items",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "dropdown-content",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "h4",
                                                                                                  {
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.setRole(
                                                                                                            i,
                                                                                                            index,
                                                                                                            role.id,
                                                                                                            role
                                                                                                              .attributes
                                                                                                              .name
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        role
                                                                                                          .attributes
                                                                                                          .name
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "dropdown-action",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "dropdown-action",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "a",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "add-action",
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.setRole(
                                                                                                                i,
                                                                                                                index,
                                                                                                                role.id,
                                                                                                                role
                                                                                                                  .attributes
                                                                                                                  .name
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "+"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    0
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group-button",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn btn-save",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Save"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "modal-footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn default",
                                  attrs: { type: "button" },
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn primary",
                                  attrs: { type: "button" },
                                  on: { click: _vm.editWorldDetails },
                                },
                                [_vm._v("Save")]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.editOrg && _vm.editOrganization && _vm.addedOrgList.length
            ? _c("div", { staticClass: "modal-content" }, [
                _c("div", [
                  _vm.editOrg && _vm.editOrganization && _vm.addedOrgList.length
                    ? _c("div", { attrs: { id: "AddOrganisation" } }, [
                        _c("div", { staticClass: "modal-header" }, [
                          _c(
                            "div",
                            {
                              staticClass: "modal-title",
                              attrs: { id: "exampleModalLabel" },
                            },
                            [
                              _vm.editWorldStatus
                                ? _c("h5", [
                                    _vm._v("Edit Organization to the World"),
                                  ])
                                : _vm._e(),
                              _vm.worldDetails.id
                                ? _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.worldDetails.attributes.name) +
                                        ", " +
                                        _vm._s(
                                          _vm.worldDetails.attributes.world_code
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.hide("newWorld")
                                },
                              },
                            },
                            [_c("em", { staticClass: "icon-close" })]
                          ),
                        ]),
                        _vm.editOrg &&
                        _vm.editOrganization &&
                        _vm.addedOrgList.length
                          ? _c("div", { staticClass: "modal-body" }, [
                              _c("form", { staticClass: "modal-form" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "organisation-dropdown" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchOrg,
                                            expression: "searchOrg",
                                          },
                                        ],
                                        staticClass:
                                          "form-control btn dropdown-toggle",
                                        class: [
                                          _vm.noOrgAdded ? "invalid" : "",
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: "Type here",
                                        },
                                        domProps: { value: _vm.searchOrg },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.searchOrg =
                                                $event.target.value
                                            },
                                            _vm.searchOrganisation,
                                          ],
                                        },
                                      }),
                                      _vm.noOrgAdded
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "invalid",
                                              staticStyle: { display: "block" },
                                            },
                                            [
                                              _vm._v(
                                                "Atleast one organization is required."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.noCharacterAdded
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "invalid",
                                              staticStyle: { display: "block" },
                                            },
                                            [
                                              _vm._v(
                                                "Minimum one character selection is required per organization."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.worldOrg.length
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-menu",
                                                class: [
                                                  _vm.worldOrg.length !== 0
                                                    ? "show"
                                                    : "",
                                                ],
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "dropdown-list",
                                                  },
                                                  _vm._l(
                                                    _vm.worldOrg,
                                                    function (org) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: org.id,
                                                          staticClass:
                                                            "dropdown-items",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-pics",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: org
                                                                    .attributes
                                                                    .photo_url
                                                                    .thumbnail,
                                                                  alt: "images",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-content",
                                                            },
                                                            [
                                                              _c("h4", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    org
                                                                      .attributes
                                                                      .name
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("h5", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    org
                                                                      .attributes
                                                                      .industry_name
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          !org.status
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-action",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "add-action",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addOrg(
                                                                              org
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "+"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          org.status
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dropdown-action",
                                                                },
                                                                [
                                                                  _c("em", {
                                                                    staticClass:
                                                                      "icon-check",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Added"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.addedOrgList.length &&
                                  !_vm.worldOrg.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "organisation-dropdown-results",
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "dropdown-list" },
                                            _vm._l(
                                              _vm.addedOrgList,
                                              function (addOrg, i) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: addOrg.id,
                                                    staticClass:
                                                      "dropdown-items",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-content",
                                                      },
                                                      [
                                                        _c("h4", [
                                                          _vm._v(
                                                            _vm._s(
                                                              addOrg.attributes
                                                                .name
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-info",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                addOrg
                                                                  .attributes
                                                                  .industry_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-action",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "delete-action",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeOrg(
                                                                  i,
                                                                  addOrg.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Remove")]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "w-100" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-user-list",
                                                          },
                                                          _vm._l(
                                                            addOrg.attributes
                                                              .organization_characters,
                                                            function (
                                                              character,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: character.id,
                                                                  staticClass:
                                                                    "dropdown-user-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "custom-checkbox",
                                                                      attrs: {
                                                                        name: "public3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes
                                                                                    .status,
                                                                                expression:
                                                                                  "character.attributes.characters.attributes.status",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "checkbox",
                                                                              name: "public3",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              checked:
                                                                                Array.isArray(
                                                                                  character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes
                                                                                    .status
                                                                                )
                                                                                  ? _vm._i(
                                                                                      character
                                                                                        .attributes
                                                                                        .characters
                                                                                        .attributes
                                                                                        .status,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : character
                                                                                      .attributes
                                                                                      .characters
                                                                                      .attributes
                                                                                      .status,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                var $$a =
                                                                                    character
                                                                                      .attributes
                                                                                      .characters
                                                                                      .attributes
                                                                                      .status,
                                                                                  $$el =
                                                                                    $event.target,
                                                                                  $$c =
                                                                                    $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                if (
                                                                                  Array.isArray(
                                                                                    $$a
                                                                                  )
                                                                                ) {
                                                                                  var $$v =
                                                                                      null,
                                                                                    $$i =
                                                                                      _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                  if (
                                                                                    $$el.checked
                                                                                  ) {
                                                                                    $$i <
                                                                                      0 &&
                                                                                      _vm.$set(
                                                                                        character
                                                                                          .attributes
                                                                                          .characters
                                                                                          .attributes,
                                                                                        "status",
                                                                                        $$a.concat(
                                                                                          [
                                                                                            $$v,
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                  } else {
                                                                                    $$i >
                                                                                      -1 &&
                                                                                      _vm.$set(
                                                                                        character
                                                                                          .attributes
                                                                                          .characters
                                                                                          .attributes,
                                                                                        "status",
                                                                                        $$a
                                                                                          .slice(
                                                                                            0,
                                                                                            $$i
                                                                                          )
                                                                                          .concat(
                                                                                            $$a.slice(
                                                                                              $$i +
                                                                                                1
                                                                                            )
                                                                                          )
                                                                                      )
                                                                                  }
                                                                                } else {
                                                                                  _vm.$set(
                                                                                    character
                                                                                      .attributes
                                                                                      .characters
                                                                                      .attributes,
                                                                                    "status",
                                                                                    $$c
                                                                                  )
                                                                                }
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "checkmark circle",
                                                                          attrs:
                                                                            {
                                                                              for: "public3",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "user-pic",
                                                                    },
                                                                    [
                                                                      character
                                                                        .attributes
                                                                        .characters
                                                                        .attributes
                                                                        .photo_url
                                                                        .thumbnail
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src: character
                                                                                    .attributes
                                                                                    .characters
                                                                                    .attributes
                                                                                    .photo_url
                                                                                    .thumbnail,
                                                                                  alt: "image",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        character
                                                                          .attributes
                                                                          .characters
                                                                          .attributes
                                                                          .full_name
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "user-role dropdown-toggle p-0",
                                                                      attrs: {
                                                                        "data-toggle":
                                                                          "dropdown",
                                                                        "aria-haspopup":
                                                                          "true",
                                                                        "aria-expanded":
                                                                          "false",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character
                                                                            .attributes
                                                                            .world_role
                                                                            .attributes
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "user-gender",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          character
                                                                            .attributes
                                                                            .characters
                                                                            .attributes
                                                                            .age
                                                                        ) +
                                                                          " , " +
                                                                          _vm._s(
                                                                            character
                                                                              .attributes
                                                                              .characters
                                                                              .attributes
                                                                              .gender
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "dropdown-menu data-popper-organise",
                                                                      attrs: {
                                                                        "aria-labelledby":
                                                                          "menu1",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "data-popover",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "data-popover-title",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex align-items-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "user-pic",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          character
                                                                                            .attributes
                                                                                            .full_name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "data-popover-close",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "em",
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-close",
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "form",
                                                                            {
                                                                              staticClass:
                                                                                "data-popover-form",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-group",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "input",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              _vm.searchRole,
                                                                                            expression:
                                                                                              "searchRole",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "form-control",
                                                                                      attrs:
                                                                                        {
                                                                                          type: "text",
                                                                                          placeholder:
                                                                                            "Enter Role",
                                                                                        },
                                                                                      domProps:
                                                                                        {
                                                                                          value:
                                                                                            _vm.searchRole,
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          [
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                $event
                                                                                                  .target
                                                                                                  .composing
                                                                                              )
                                                                                                return
                                                                                              _vm.searchRole =
                                                                                                $event.target.value
                                                                                            },
                                                                                            _vm.getRole,
                                                                                          ],
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                  _vm
                                                                                    .roles
                                                                                    .length
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "dropdown-list mt-3",
                                                                                        },
                                                                                        _vm._l(
                                                                                          _vm.roles,
                                                                                          function (
                                                                                            role
                                                                                          ) {
                                                                                            return _c(
                                                                                              "div",
                                                                                              {
                                                                                                key: role.id,
                                                                                                staticClass:
                                                                                                  "dropdown-items",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "dropdown-content",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "h4",
                                                                                                      {
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.setRole(
                                                                                                                i,
                                                                                                                index,
                                                                                                                role.id,
                                                                                                                role
                                                                                                                  .attributes
                                                                                                                  .name
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            role
                                                                                                              .attributes
                                                                                                              .name
                                                                                                          )
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "dropdown-action",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "dropdown-action",
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "a",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "add-action",
                                                                                                            on: {
                                                                                                              click:
                                                                                                                function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.setRole(
                                                                                                                    i,
                                                                                                                    index,
                                                                                                                    role.id,
                                                                                                                    role
                                                                                                                      .attributes
                                                                                                                      .name
                                                                                                                  )
                                                                                                                },
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "+"
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        0
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "modal-footer" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn default",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.hide("newWorld")
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn primary",
                                      attrs: { type: "button" },
                                      on: { click: _vm.editWorldDetails },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }