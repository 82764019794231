var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "AddVideo",
            width: 700,
            height: "auto",
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Add Video")])]
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide("AddVideo")
                    },
                  },
                },
                [_c("em", { staticClass: "icon-close" })]
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-body mt-3" },
              [
                _c("ValidationObserver", { ref: "form" }, [
                  _c(
                    "form",
                    {
                      staticClass: "modal-form organisation-form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.formSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group col" },
                          [
                            _c("label", { staticClass: "form-label" }, [
                              _vm._v("Card Title"),
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Card Title",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.title,
                                            expression: "title",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "title",
                                          id: "title",
                                          "aria-describedby": "emailHelp",
                                        },
                                        domProps: { value: _vm.title },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.title = $event.target.value
                                          },
                                        },
                                      }),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group col" },
                          [
                            _c("label", { staticClass: "form-label" }, [
                              _vm._v("Wistia Code"),
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Wistia Code",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.wistia_code,
                                            expression: "wistia_code",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.isActive && errors[0]
                                            ? "invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          type: "wistia_code",
                                          id: "wistia_code",
                                          "aria-describedby": "emailHelp",
                                        },
                                        domProps: { value: _vm.wistia_code },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.wistia_code =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      _vm.wistia_code
                                        ? _c(
                                            "span",
                                            {
                                              class: ["cursor", "success"],
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getWistiaInfo()
                                                },
                                              },
                                            },
                                            [_vm._v("Fetch Video Meta Info")]
                                          )
                                        : _vm._e(),
                                      errors[0]
                                        ? _c(
                                            "span",
                                            {
                                              class: [
                                                _vm.isActive ? "invalid" : "",
                                              ],
                                              style: [
                                                _vm.isActive
                                                  ? { display: "block" }
                                                  : { display: "none" },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  errors[0].replace("The ", "")
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("Transcript Description"),
                          ]),
                          _c("vue-editor", {
                            attrs: { editorToolbar: _vm.fullToolbar },
                            model: {
                              value: _vm.transcript,
                              callback: function ($$v) {
                                _vm.transcript = $$v
                              },
                              expression: "transcript",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v("Video Duration"),
                          ]),
                          _c("ValidationProvider", {
                            attrs: {
                              name: "Video Duration",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.duration,
                                          expression: "duration",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: [
                                        _vm.isActive && errors[0]
                                          ? "invalid"
                                          : "",
                                      ],
                                      attrs: {
                                        type: "duration",
                                        "aria-describedby": "emailHelp",
                                        readonly: "",
                                      },
                                      domProps: { value: _vm.duration },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.duration = $event.target.value
                                        },
                                      },
                                    }),
                                    errors[0]
                                      ? _c(
                                          "span",
                                          {
                                            class: [
                                              _vm.isActive ? "invalid" : "",
                                            ],
                                            style: [
                                              _vm.isActive
                                                ? { display: "block" }
                                                : { display: "none" },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                errors[0].replace("The ", "")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group organisation-image-group flex-wrap mt-0",
                        },
                        [
                          _c("label", { staticClass: "form-label w-100" }, [
                            _vm._v("Wistia Thumbnail"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "organisation-image-pic cursor",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.fileInput.click()
                                },
                              },
                            },
                            [
                              _vm.image
                                ? _c("img", {
                                    attrs: { src: _vm.image, alt: "images" },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "organisation-image-remove" },
                            [
                              _c("input", {
                                ref: "fileInput",
                                staticStyle: { display: "none" },
                                attrs: { type: "file", accept: "image/*" },
                                on: { change: _vm.onFile },
                              }),
                              !_vm.image
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn add",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.fileInput.click()
                                        },
                                      },
                                    },
                                    [_vm._v("Add Photo")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "btn delete",
                                      on: { click: _vm.removeImage },
                                    },
                                    [_vm._v("Remove Photo")]
                                  ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group organisation-image-group d-flex flex-wrap mt-0 flex-row align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "form-label instructor-resource-label",
                            },
                            [_vm._v("Show in Instructor Resources?")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.showInstructorResource,
                                expression: "showInstructorResource",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.showInstructorResource)
                                ? _vm._i(_vm.showInstructorResource, null) > -1
                                : _vm.showInstructorResource,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.showInstructorResource,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.showInstructorResource = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.showInstructorResource = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.showInstructorResource = $$c
                                }
                              },
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn default",
                            attrs: {
                              type: "button",
                              "data-toggle": "modal",
                              "data-target": "#AddVideo",
                              "data-dismiss": "modal",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.hide("AddVideo")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn primary",
                            attrs: { type: "submit" },
                          },
                          [_vm._v("Save")]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "uploadVideo",
            height: "auto",
            width: 800,
            scrollable: true,
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { id: "exampleModalLabel" },
                },
                [_c("h5", [_vm._v("Upload Videos")])]
              ),
            ]),
            _c("div", { staticClass: "modal-body modal-image-body" }, [
              _c("div", { staticClass: "modal-images-tab" }, [
                _c(
                  "a",
                  {
                    class: [!_vm.uploadVideoGallery ? "active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.videoStatus()
                      },
                    },
                  },
                  [_vm._v("New Video")]
                ),
                _c(
                  "a",
                  {
                    class: [_vm.uploadVideoGallery ? "active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.videoStatus()
                      },
                    },
                  },
                  [_vm._v("Library")]
                ),
              ]),
              _vm.uploadVideoGallery
                ? _c("form", { staticClass: "header-form" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVideo,
                            expression: "searchVideo",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          "aria-describedby": "emailHelp",
                          placeholder: "Search",
                        },
                        domProps: { value: _vm.searchVideo },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.searchVideo = $event.target.value
                            },
                            _vm.searchVideos,
                          ],
                        },
                      }),
                      _c("em", { staticClass: "icon-search" }),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "modal-images-content" }, [
                !_vm.uploadVideoGallery
                  ? _c(
                      "div",
                      { staticClass: "modal-images-upload" },
                      [
                        _c("ValidationObserver", { ref: "form" }, [
                          _c(
                            "form",
                            {
                              staticClass: "modal-form organisation-form",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.formSubmit.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Card Title"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Card Title",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.title,
                                                      expression: "title",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "title",
                                                    id: "title",
                                                    "aria-describedby":
                                                      "emailHelp",
                                                  },
                                                  domProps: {
                                                    value: _vm.title,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.title =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1878447610
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col" },
                                  [
                                    _c("label", { staticClass: "form-label" }, [
                                      _vm._v("Wistia Code"),
                                    ]),
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Wistia Code",
                                        rules: "required|max:100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.wistia_code,
                                                      expression: "wistia_code",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: [
                                                    _vm.isActive && errors[0]
                                                      ? "invalid"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "wistia_code",
                                                    id: "wistia_code",
                                                    "aria-describedby":
                                                      "emailHelp",
                                                  },
                                                  domProps: {
                                                    value: _vm.wistia_code,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.wistia_code =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _vm.wistia_code
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          "success",
                                                          "cursor",
                                                        ],
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getWistiaInfo()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Fetch Video Meta Info"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                errors[0]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        class: [
                                                          _vm.isActive
                                                            ? "invalid"
                                                            : "",
                                                        ],
                                                        style: [
                                                          _vm.isActive
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              },
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            errors[0].replace(
                                                              "The ",
                                                              ""
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1456004144
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Transcript Description"),
                                  ]),
                                  _c("vue-editor", {
                                    attrs: { editorToolbar: _vm.fullToolbar },
                                    model: {
                                      value: _vm.transcript,
                                      callback: function ($$v) {
                                        _vm.transcript = $$v
                                      },
                                      expression: "transcript",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("Video Duration"),
                                  ]),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Video Duration",
                                      rules: "required|numeric",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.duration,
                                                    expression: "duration",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: [
                                                  _vm.isActive && errors[0]
                                                    ? "invalid"
                                                    : "",
                                                ],
                                                attrs: {
                                                  type: "duration",
                                                  "aria-describedby":
                                                    "emailHelp",
                                                },
                                                domProps: {
                                                  value: _vm.duration,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.duration =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.isActive
                                                          ? "invalid"
                                                          : "",
                                                      ],
                                                      style: [
                                                        _vm.isActive
                                                          ? { display: "block" }
                                                          : { display: "none" },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          errors[0].replace(
                                                            "The ",
                                                            ""
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3392174559
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group organisation-image-group flex-wrap mt-0",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-label w-100" },
                                    [_vm._v("Wistia Thumbnail")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "organisation-image-pic cursor",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.fileInput.click()
                                        },
                                      },
                                    },
                                    [
                                      _vm.image
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.image,
                                              alt: "images",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "organisation-image-remove",
                                    },
                                    [
                                      _c("input", {
                                        ref: "fileInput",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept: "image/*",
                                        },
                                        on: { change: _vm.onFile },
                                      }),
                                      !_vm.image
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "btn add",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.fileInput.click()
                                                },
                                              },
                                            },
                                            [_vm._v("Add Photo")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "btn delete",
                                              on: { click: _vm.removeImage },
                                            },
                                            [_vm._v("Remove Photo")]
                                          ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group organisation-image-group d-flex flex-wrap mt-0 flex-row align-items-center",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "form-label instructor-resource-label",
                                        },
                                        [
                                          _vm._v(
                                            "Show in Instructor Resources?"
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.showInstructorResource,
                                            expression:
                                              "showInstructorResource",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.showInstructorResource
                                          )
                                            ? _vm._i(
                                                _vm.showInstructorResource,
                                                null
                                              ) > -1
                                            : _vm.showInstructorResource,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.showInstructorResource,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.showInstructorResource =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.showInstructorResource =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.showInstructorResource = $$c
                                            }
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn default",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#AddVideo",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hide("uploadVideo")
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn primary",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.uploadVideoGallery
                  ? _c(
                      "div",
                      { staticClass: "modal-images-library" },
                      [
                        _c(
                          "v-infinite-scroll",
                          {
                            staticClass: "modal-content-scroll",
                            attrs: { offset: 20 },
                            on: { bottom: _vm.nextPageVideos },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "modal-videos-row" },
                              _vm._l(_vm.videoLists, function (video) {
                                return _c(
                                  "div",
                                  {
                                    key: video.id,
                                    staticClass: "modal-videos-col",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "modal-images-checkbox",
                                        on: {
                                          click: function ($event) {
                                            _vm.$modal.hide("uploadVideo")
                                            _vm.selectedVideo(video)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "custom-checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    video.attributes.status,
                                                  expression:
                                                    "video.attributes.status",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                name: "video",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  video.attributes.status,
                                                  null
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    video.attributes,
                                                    "status",
                                                    null
                                                  )
                                                },
                                              },
                                            }),
                                            _c("span", {
                                              staticClass: "checkmark circle",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "wistia_responsive_padding data-video",
                                                staticStyle: {
                                                  padding: "56.25% 0 0 0",
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "wistia_responsive_wrapper data-video",
                                                    staticStyle: {
                                                      height: "100%",
                                                      left: "0",
                                                      position: "absolute",
                                                      top: "0",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c("iframe", {
                                                      staticClass:
                                                        "wistia_embed",
                                                      attrs: {
                                                        src:
                                                          _vm.preIframUrl +
                                                          video.attributes
                                                            .wistia_code +
                                                          _vm.postIframUrl,
                                                        title: "Video.mp4",
                                                        name: "wistia_embed",
                                                        allowtransparency:
                                                          "true",
                                                        scrolling: "no",
                                                        allowfullscreen: "",
                                                        mozallowfullscreen: "",
                                                        webkitallowfullscreen:
                                                          "",
                                                        oallowfullscreen: "",
                                                        msallowfullscreen: "",
                                                        width: "100%",
                                                        height: "100%",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "modal-images-info",
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(
                                                      video.attributes.title
                                                    )
                                                  ),
                                                ]),
                                                _vm._l(
                                                  video.attributes.tag_list,
                                                  function (tag) {
                                                    return _c(
                                                      "p",
                                                      { key: tag },
                                                      [_vm._v(_vm._s(tag))]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c("div", {
                                              staticClass: "checked",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                        _vm.videoLists.length === 0
                          ? _c("div", { staticClass: "data-no-result" }, [
                              _c("h3", [_vm._v("No results found")]),
                              _c("p", [
                                _vm._v("Try using a different keyword"),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm.uploadVideoGallery
              ? _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.hide("uploadVideo")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$modal.hide("uploadVideo")
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }