var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "createOrganisation",
        height: "auto",
        width: 500,
        scrollable: true,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "div",
            { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
            [
              _vm.organisation_id
                ? _c("h5", [_vm._v("Edit Organization")])
                : _c("h5", [_vm._v("Create an Organization")]),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide("createOrganisation")
                },
              },
            },
            [_c("em", { staticClass: "icon-close" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("ValidationObserver", { ref: "form" }, [
              _c("form", { staticClass: "modal-form organisation-form" }, [
                _c("div", { staticClass: "organisation-image-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "organisation-image-pic cursor",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.fileInput.click()
                        },
                      },
                    },
                    [
                      _vm.image
                        ? _c("img", {
                            attrs: { src: _vm.image, alt: "images" },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "organisation-image-remove" }, [
                    _c("input", {
                      ref: "fileInput",
                      staticStyle: { display: "none" },
                      attrs: { type: "file", accept: "image/*" },
                      on: { change: _vm.onFileSelect },
                    }),
                    !_vm.image
                      ? _c(
                          "a",
                          {
                            staticClass: "btn add",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.fileInput.click()
                              },
                            },
                          },
                          [_vm._v("Add Photo")]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "btn delete",
                            on: { click: _vm.imageRemoved },
                          },
                          [_vm._v("Remove Photo")]
                        ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "exampleInputEmail1" },
                      },
                      [_vm._v("Name")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "Name", rules: "required|max:100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: {
                                  type: "text",
                                  id: "name",
                                  "aria-describedby": "emailHelp",
                                },
                                domProps: { value: _vm.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.name = $event.target.value
                                  },
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "exampleInputEmail1" },
                    },
                    [_vm._v("Description")]
                  ),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.description,
                        expression: "description",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { rows: "4" },
                    domProps: { value: _vm.description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.description = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "exampleInputEmail1" },
                      },
                      [_vm._v("Industry Type")]
                    ),
                    _c("ValidationProvider", {
                      attrs: { name: "Industry", rules: "required|max:500" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchIndustry,
                                    expression: "searchIndustry",
                                  },
                                ],
                                staticClass: "form-control",
                                class: [
                                  _vm.isActive && errors[0] ? "invalid" : "",
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.searchIndustry },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.searchIndustry = $event.target.value
                                    },
                                    _vm.getIndustry,
                                  ],
                                },
                              }),
                              errors[0]
                                ? _c(
                                    "span",
                                    {
                                      class: [_vm.isActive ? "invalid" : ""],
                                      style: [
                                        _vm.isActive
                                          ? { display: "block" }
                                          : { display: "none" },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(errors[0].replace("The ", ""))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.industryLists.length
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              class: [
                                _vm.industryLists.length !== 0 ? "show" : "",
                              ],
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "dropdown-list" },
                                _vm._l(_vm.industryLists, function (industry) {
                                  return _c(
                                    "li",
                                    {
                                      key: industry.id,
                                      staticClass: "dropdown-items",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c("h4", [
                                            _vm._v(
                                              _vm._s(industry.attributes.name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-action" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "add-action",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setIndustry(
                                                    industry.id,
                                                    industry.attributes.name
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "exampleInputEmail1" },
                    },
                    [_vm._v("Add a Character")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchCharacters,
                        expression: "searchCharacters",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.searchCharacters },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.searchCharacters = $event.target.value
                        },
                        _vm.getCharacters,
                      ],
                    },
                  }),
                  _vm.charactersList.length
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            class: [
                              _vm.charactersList.length !== 0 ? "show" : "",
                            ],
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "dropdown-list" },
                              _vm._l(_vm.charactersList, function (characters) {
                                return _c(
                                  "li",
                                  {
                                    key: characters.id,
                                    staticClass: "dropdown-items",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(
                                              characters.attributes.full_name
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    !characters.status
                                      ? _c(
                                          "div",
                                          { staticClass: "dropdown-action" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "add-action",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addCharacters(
                                                      characters
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("+")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    characters.status
                                      ? _c(
                                          "div",
                                          { staticClass: "dropdown-action" },
                                          [
                                            _c("em", {
                                              staticClass: "icon-check",
                                            }),
                                            _c("span", [_vm._v("Added")]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm.addedCharacters.length
                  ? _c("div", { staticClass: "customer-dropdown" }, [
                      _c(
                        "ul",
                        { staticClass: "dropdown-list mt-0 mb-4" },
                        _vm._l(
                          _vm.addedCharacters,
                          function (characters, index) {
                            return _c(
                              "li",
                              {
                                key: characters.id,
                                staticClass: "dropdown-items",
                              },
                              [
                                _c("div", { staticClass: "dropdown-pic" }, [
                                  characters.attributes.photo_url.thumbnail
                                    ? _c("img", {
                                        attrs: {
                                          src: characters.attributes.photo_url
                                            .thumbnail,
                                          alt: "image2",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "dropdown-content" }, [
                                  _c("div", { staticClass: "user-name" }, [
                                    _vm._v(
                                      _vm._s(characters.attributes.full_name)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "user-age" }, [
                                    _vm._v(
                                      _vm._s(characters.attributes.age) +
                                        ", " +
                                        _vm._s(
                                          _vm._f("toUpperCase")(
                                            characters.attributes.gender,
                                            true
                                          )
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCharcter(index)
                                        },
                                      },
                                    },
                                    [_vm._v("Remove")]
                                  ),
                                ]),
                                _c("div", { staticClass: "dropdown-form" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group m-0" },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "searchIndustry",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors }) {
                                                return [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          characters.attributes
                                                            .roleType,
                                                        expression:
                                                          "characters.attributes.roleType",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class: [
                                                      _vm.isActive && errors[0]
                                                        ? "invalid"
                                                        : "",
                                                    ],
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: "Enter Role",
                                                    },
                                                    domProps: {
                                                      value:
                                                        characters.attributes
                                                          .roleType,
                                                    },
                                                    on: {
                                                      input: [
                                                        function ($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            characters.attributes,
                                                            "roleType",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.getRole(
                                                            characters
                                                              .attributes
                                                              .roleType,
                                                            index
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  errors[0]
                                                    ? _c(
                                                        "span",
                                                        {
                                                          class: [
                                                            _vm.isActive
                                                              ? "invalid"
                                                              : "",
                                                          ],
                                                          style: [
                                                            _vm.isActive
                                                              ? {
                                                                  display:
                                                                    "block",
                                                                }
                                                              : {
                                                                  display:
                                                                    "none",
                                                                },
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Role is required"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm.roles.length && _vm.showRole == index
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-menu assign-role-menu",
                                                class: [
                                                  _vm.roles.length !== 0
                                                    ? "show"
                                                    : "",
                                                ],
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "dropdown-list",
                                                  },
                                                  _vm._l(
                                                    _vm.roles,
                                                    function (role) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: role.id,
                                                          staticClass:
                                                            "dropdown-items",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-content",
                                                            },
                                                            [
                                                              _c("h4", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    role
                                                                      .attributes
                                                                      .name
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dropdown-action",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "add-action",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addRole(
                                                                          index,
                                                                          role.id,
                                                                          role
                                                                            .attributes
                                                                            .name
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("+")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn default",
              attrs: { type: "button", "data-dismiss": "modal" },
              on: {
                click: function ($event) {
                  return _vm.hide("createOrganisation")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "button",
            {
              staticClass: "btn primary",
              attrs: { type: "button", "data-dismiss": "modal" },
              on: { click: _vm.saveOrg },
            },
            [_vm._v("Save")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }