<template>
  <div>
    <div class="learning-form-header flex-wrap">
      <h4>LLM Dialogic Interaction</h4>
      <div class="learning-quiz-btn">
        <button @click="publishLO('published')" class="btn header-btn secondary" v-if="status == 'published'">
          Save
        </button>
        <button v-if="status == 'drafted'" class="btn header-btn primary" @click="publishLO('published')">
          Publish LO
        </button>
      </div>
    </div>
    <div class="learning-lo-tabs">
      <div class="setup-container">
        <ul>
          <li :class="{ active: isActiveTab === 'basic-information' }">
            <button @click="switchTab('basic-information')">
              <span class="check-box"></span>
              <span>Basic Information</span>
            </button>
          </li>
          <li :class="{ active: isActiveTab === 'kb-setup' }">
            <button @click="switchTab('kb-setup')">
              <span class="check-box"></span>
              <span>Knowledge Base Setup</span>
            </button>
          </li>
          <li :class="{ active: isActiveTab === 'prompts-setup' }">
            <button @click="switchTab('prompts-setup')">
              <span class="check-box"></span>
              <span>Dialogic Questions Setup</span>
            </button>
          </li>
          <li :class="{ active: isActiveTab === 'chat-testing' }">
            <button @click="switchTab('chat-testing')">
              <span class="check-box"></span>
              <span>Testing</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="learning-quiz-content">
      <div class="setup-content" v-if="isActiveTab == 'basic-information'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Basic Information</h4>
        </div>
        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="row custom-row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label>Interaction title</label>
                  <ValidationProvider name="Interaction Title" rules="required|max:100" v-slot="{ errors }">
                    <input type="text" class="form-control" v-model="name"
                      :class="[isActive && errors[0] ? 'invalid' : '']" />
                    <span :class="[isActive ? 'invalid' : '']" :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Administrative notes</label>
                  <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                    <textarea type="Description" class="form-control" id="notes" rows="4" v-model="adminNotes"
                      :class="[isActive && errors[0] ? 'invalid' : '']"></textarea>
                    <span :class="[isActive ? 'invalid' : '']" :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Character Name</label>
                  <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                    <input type="text" class="form-control" id="character-name" v-model="characterName"
                      :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's name" />
                    <span :class="[isActive ? 'invalid' : '']" :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Character Pronouns</label>
                  <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                    <input type="text" class="form-control" id="character-name" v-model="characterPronouns"
                      :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's name" />
                    <span :class="[isActive ? 'invalid' : '']" :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Character Age</label>
                  <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                    <input type="text" class="form-control" id="character-age" v-model="characterAge"
                      :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's age" />
                    <span :class="[isActive ? 'invalid' : '']" :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Character Job</label>
                  <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                    <input type="text" class="form-control" id="character-job" v-model="characterJob"
                      :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's job" />
                    <span :class="[isActive ? 'invalid' : '']" :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Character Image</label>
                  <img v-if="characterImageURL" :src="characterImageURL" class="character-image" />
                  <button v-if="characterImageURL" @click="deleteCharacterImage" class="btn header-btn secondary small-btn">Delete Character Image</button>
                  <input v-if="!characterImageURL" style="display: none" ref="fileInput" type="file" @change="uploadImage"
                    accept="image/jpeg, image/png" />
                  <a v-if="!characterImageURL" @click="$refs.fileInput.click()" class="btn header-btn secondary small-btn"
                    >
                    Upload Character Image
                  </a>
                </div>
                <div class="form-group">
                  <label>Conversation Characteristics</label>
                  <select class="form-select" @change="setCharacteristics($event)">
                    <option v-for="option in optionsCharacteristics" :key="option.id" :value="option.text"
                      :selected="option.text === characterCharacteristics">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label">Character Stance</label>
                  <div class="d-flex justify-content-between">
                    <div class="trigger-item">
                      <label>Correct / Incorrect</label>
                      <input type="radio"
                        v-model="characterStance"
                        :value="'correct'">
                    </div>
                    <div class="trigger-item">
                      <label>Opinion / Argument</label>
                      <input type="radio"
                        v-model="characterStance"
                        :value="'opinion'">
                    </div>
                    <div class="trigger-item">
                      <label>I understand / I don't understand</label>
                      <input type="radio"
                        v-model="characterStance"
                        :value="'understand'">
                    </div>
                    <div class="trigger-item">
                      <label>GPT</label>
                      <input type="radio"
                        v-model="characterStance"
                        :value="'gpt'">
                    </div>
                    <div class="trigger-item">
                      <label>Follow up</label>
                      <input type="radio"
                        v-model="characterStance"
                        :value="'followup'">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Conclusion Message</label>
                  <input class="form-control" type="text" placeholder="Conclusion Message" v-model="conclusionMessage" />
                  <button class="btn btn-secondary" @click="saveLO('drafted')">Save Conclusion</button>
                </div>
                <div class="form-group custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="enableVoice" id="enableVoice" />
                  <label class="custom-control-label" for="enableVoice">Enable Voice</label>
                </div>
                <div class="form-group custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="reattemptsEnabled"
                    id="reattemptsEnabled" />
                  <label class="custom-control-label" for="reattemptsEnabled">Enable Reattempts</label>
                </div>
                <div class="form-group custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="enableLlmAssessments"
                    id="enableLlmAssessments" />
                  <label class="custom-control-label" for="enableLlmAssessments">Enable LLM Assessments</label>
                </div>
                <div class="form-group custom-control" v-if="reattemptsEnabled">
                  <input type="number" v-model="maxReattempts" id="maxReattempts" placeholder="Max Reattempts" />
                </div>
              </div>
              <div class="col-sm-7">
                <div class="form-group">
                  <label>Learner Description</label>
                  <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                    <vue-editor :class="[
                      isActive && errors[0]
                        ? 'form-control invalid'
                        : 'form-control',
                    ]" id="Description" placeholder="Add a Description" v-model="description"></vue-editor>
                    <span :class="[isActive ? 'invalid' : '']" :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-12 pt-5">
                <div class="d-flex justify-content-end mb-4">
                  <button href="javascript:void(0);" class="btn primary" @click="saveLO('drafted', 1)">
                    Save
                  </button>
                </div>
                <div class="btn-container justify-content-end">
                  <div class="delete-btn">
                    <div class="delete-box">
                      <span class="icon-delete"></span>
                    </div>
                    <button @click="archiveLO('archived')" class="text">
                      Archive this Learning Object(LO)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="setup-content" v-if="isActiveTab == 'kb-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Knowledge Base Setup</h4>
        </div>
        <div class="knowledge-base-container">
          <div class="knowledge-base-item">
            <h5>General Knowledge base</h5>
            <textarea class="text-area" v-model="generalKnowledgeBase"></textarea>
          </div>
          <div class="knowledge-base-item">
            <h5>Global Narrative Knowledge base</h5>
            <textarea class="text-area narrativeKnowledgeBaseItem" :placeholder="`Add narrative knowledge base`"
              v-model="narrativeKnowledgeBase"></textarea>
          </div>
        </div>
        <div class="kb-btn-container">
          <button class="btn primary" @click="saveLO('drafted', 1)">
            Save
          </button>
        </div>
      </div>
      <div class="setup-content" v-if="isActiveTab == 'prompts-setup'">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Dialogic Questions Setup</h4>
          <div class="ml-3" v-if="isMOAT">
            <input class="form-check-input" type="checkbox" value="" id="showLabels" v-model="isMOAT">
            <label class="form-check-label" for="showLabels">
              Show old Dialogic view
            </label>
          </div>
        </div>
        <div class="prompts-body" v-if="isMOAT">
          <h4>MOAT Dialogic Questions</h4>
          <ol>
            <li v-for="(dialogicQuestion, index) in dialogicQuestions" :key="index">
              - {{ dialogicQuestion }}
            </li>
          </ol>
        </div>
        <div class="prompts-body" v-if="isMOAT">
          <div class="prompts-container">
            <div class="prompts-body">
              <div class="side-menu">
                <draggable v-model="promptData" @start="isDragging = true" @end="onEnd">
                  <ul class="side-prompt" v-for="(prompt, index) in promptData" :key="prompt.id">
                    <li @click="chooseMOATPrompt(index)" class="menu-item">
                      {{ prompt.title || `Prompt: ${prompt.id}` }}
                    </li>
                  </ul>
                </draggable>
                <hr />
              </div>
              <div class="prompts-edit" v-if="showPrompts">
                <div class="edit-body">
                  <h4>Editing: {{ this.chosenPrompt.title || "New Prompt" }}</h4>
                  <form class="kb_form">
                    <div class="kb_form--row">
                      <div class="kb_form--item form-group">
                        <label>Prompt Title</label>
                        <textarea class="form-control" v-model="chosenPrompt.title"
                          placeholder="Input prompt Title here" />
                      </div>
                      <div class="kb_form--item form-group">
                        <label>Admin Notes</label>
                        <textarea class="form-control" v-model="chosenPrompt.admin_notes"
                          placeholder="Input Admin Notes here" />
                      </div>
                    </div>
                    <div class="kb_form--row">
                      <div class="kb_form--item form-group">
                        <label>Add Prompt</label>
                        <textarea class="form-control" v-model="chosenPrompt.prompt" placeholder="Input prompt here" />
                      </div>
                      <div class="kb_form--item form-group">
                        <label>Prompt Narrative Knowledge Base</label>
                        <textarea class="form-control" v-model="chosenPrompt.narrative_knowledge_base"
                          placeholder="Input Prompt Narrative Knowledge Base here" />
                      </div>
                    </div>
                    <div class="kb_form--row">
                      <div class="kb_form--item form-group">
                        <label>Prompt Type</label>
                        <select class="form-control" @change="setPassFail($event)">
                          <option v-for="option in optionsPassFail" :key="option.id" :value="option.value"
                            :selected="option.value == chosenPassFail.value">
                            {{ option.text }}
                          </option>
                        </select>
                      </div>
                      <div class="kb_form--item form-group">
                        <label>Independent / Dependendent Prompt</label>
                        <select class="form-control" @change="setIndependent($event)">
                          <option v-for="option in optionsIndependentDependent" :key="option.id" :value="option.value"
                            :selected="option.value === chosenPrompt.independent_prompt
                              ">
                            {{ option.text }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <h4>Tone/Triggers</h4>
                    <div class="kb_form--row">
                      <div class="kb_form--item form-group">
                        <label>Trigger Response</label>
                        <select ref="trigger_response" class="form-control" v-model="chosenPrompt.trigger_response">
                          <option disabled value="">
                            Please select a response
                          </option>
                          <option>Hint</option>
                          <option>Answer</option>
                          <option>None</option>
                        </select>
                      </div>
                      <div class="kb_form--item form-group">
                        <label>Trigger Pass</label>
                        <select ref="trigger_pass" class="form-control" v-model="chosenPrompt.trigger_pass">
                          <option disabled value="">
                            Please select a Pass trigger
                          </option>
                          <option>Sadness</option>
                          <option>Happiness</option>
                          <option>Fear</option>
                          <option>Anger</option>
                          <option>Surprise</option>
                          <option>None</option>
                        </select>
                      </div>
                      <div class="kb_form--item form-group">
                        <label>Trigger Fail</label>
                        <select ref="trigger_fail" class="form-control" v-model="chosenPrompt.trigger_fail">
                          <option disabled value="">
                            Please select a Fail trigger
                          </option>
                          <option>Sadness</option>
                          <option>Happiness</option>
                          <option>Fear</option>
                          <option>Anger</option>
                          <option>Surprise</option>
                          <option>None</option>
                        </select>
                      </div>
                    </div>
                    <div class="kb_form--row">
                      <div class="kb_form--item form-group form-checkbox">
                        <div>
                          <label>Include this prompt in assessment</label>
                          <div>
                            <p>
                              Checking this option will include this prompt when
                              assessing the entire ELM.
                              <input type="checkbox" v-model="chosenPrompt.optional_assessment" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="kb_form--item form-group">
                        <label>Feedback Request Type</label>
                        <div class="feedback-request-help-container">
                          <p class="feedback-request-help">
                            This option determines how much feedback is provided to
                            the user
                          </p>
                          <p class="feedback-request-help">
                            If "All" is selected, both the prompt text and the
                            appropriate feedback request item will be provided.
                          </p>
                          <p class="feedback-request-help">
                            If "Hit" is selected, the appropriate feedback request
                            item will be provided along with any part of the prompt
                            text that the user successfully hit on.
                          </p>
                          <p class="feedback-request-help">
                            If "None" is selected, only the appropriate feedback
                            request item will be provided.
                          </p>
                        </div>
                        <select ref="feedback_request_type" class="form-control"
                          v-model="chosenPrompt.feedback_assessment_type">
                          <option disabled value="">
                            Please select a feedback request type
                          </option>
                          <option>All</option>
                          <option v-if="chosenPrompt.pass_fail === false">
                            Hit
                          </option>
                          <option>None</option>
                        </select>
                      </div>
                    </div>
                    <div class="divider"></div>
                    <!-- The following can be improved in which where the v-if shows up -->
                    <div class="kb_form--row">
                      <div class="kb_form--item form-group" v-if="chosenPrompt.pass_fail === true">
                        <label>Feedback Request Items</label>
                        <ul>
                          <li class="kb kb-feedback-request">
                            <label>Pass</label>
                            <div class="kb-feedback-request-input-container">
                              <input type="text" class="form-control" placeholder="Input Pass Item Label"
                                v-model="feedback_assessment_pass_data.label" />
                              <input type="text" class="form-control" placeholder="Input Pass Item Description"
                                v-model="feedback_assessment_pass_data.description" />
                            </div>
                          </li>
                          <li class="kb kb-feedback-request">
                            <label>Fail</label>
                            <div class="kb-feedback-request-input-container">
                              <input type="text" class="form-control" placeholder="Input Fail Item Label"
                                v-model="feedback_assessment_fail_data.label" />
                              <input type="text" class="form-control" placeholder="Input Fail Item Description"
                                v-model="feedback_assessment_fail_data.description" />
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="kb_form--item form-group" v-if="chosenPrompt.pass_fail === false"
                        :key="assessmentItemsKey">
                        <h5 class="assessmentFeedbackTitle">
                          Feedback Request Items
                        </h5>
                        <div class="assessment-feedback-empty" v-if="Object.keys(assessmentItems).length === 0">
                          <p>No feedback request items have been added yet.</p>
                        </div>
                        <ul>
                          <li class="kb kb-feedback-request" v-for="(item, index) in assessmentItems" :key="index"
                            ref="assessment_items">
                            <div class="kb-feedback-request-input-container">
                              <div>
                                <label>Assessment Item Label</label>
                                <input type="text" class="form-control" placeholder="Input Assessment Item Label"
                                  v-model="item.label" :ref="`assessment-item-label-${index}`" />
                                <label>Assessment Item Description</label>
                                <textarea class="form-control" placeholder="Input Assessment Item Description"
                                  v-model="item.description" :ref="`assessment-item-description-${index}`"></textarea>
                                <label>Item Level</label>
                                <input type="number" class="form-control" placeholder="Input Assessment Item Level"
                                  :ref="`assessment-item-level-${index}`" v-model="item.assessment_level" />
                              </div>
                              <div class="feedback-item-btn-container">
                                <button class="btn primary" :ref="`assessment-item-${index}`"
                                  @click="editAssessmentItem(index)">
                                  Edit
                                </button>
                                <button class="btn delete-kb" @click="deleteAssessmentItem(index)">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-if="addingNewAssessmentItem">
                      <div class="divider"></div>
                      <div class="form-group new-assessment-item-container">
                        <p>New Feedback Request Item</p>
                        <div class="new-assessment-form">
                          <label>Assessment Item Label</label>
                          <input type="text" class="form-control" placeholder="Input Assessment Item Label"
                            v-model="newItem.label" />
                          <label>Assessment Item Description</label>
                          <textarea class="form-control" placeholder="Input Assessment Item Description"
                            v-model="newItem.description" :ref="`new-assessment-item-description`"></textarea>
                          <label>Item Level</label>
                          <input type="number" class="form-control" placeholder="Input Assessment Item Level"
                            :ref="`new-assessment-item-level`" v-model="newItem.assessment_level" />
                        </div>
                        <div class="feedback-item-btn-container">
                          <button class="btn primary" @click.prevent="saveAssessmentItem()">
                            Save
                          </button>
                          <button class="btn secondary" @click="cancelAssessmentItem()">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <h5 v-if="!chosenPrompt.pass_fail" class="formItem--title">
                      Assessment Knowledge Base
                    </h5>
                    <div v-if="!chosenPrompt.pass_fail" class="kb_form--item form-group">
                      <label>Name</label>
                      <input type="text" ref="assessment_knowledgebase_name" class="form-control"
                        v-model="chosenPrompt.assessment_knowledgebase_name"
                        placeholder="Input Assessment Knowledge Base Name here" />
                    </div>
                    <div v-if="!chosenPrompt.pass_fail" class="kb_form--item form-group">
                      <div>
                        <label>Knowledge Base Entries</label>
                        <ul>
                          <li class="kb" v-for="(
                          assessment_kb, index
                        ) in chosenPrompt.knowledge_base_items" v-bind:key="index" ref="kb_entries">
                            <div class="entry-kb" :ref="`kbitem-${index}`">
                              {{ assessment_kb }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="kb_form--item form-group">
                      <label>Task</label>
                      <input class="form-control" type="text" placeholder="Input task here" v-model="chosenPrompt.task" />
                    </div>
                    <div class="kb_form--item kb_form--itemhori">
                      <div class="kb_item--column form-group" v-if="!chosenPrompt.pass_fail">
                        <label>Threshold</label>
                        <input ref="thresholdRequired" class="form-control" type="text"
                          v-model="chosenPrompt.threshold" />
                      </div>
                      <div class="kb_item--column form-group">
                        <label>Mandatory</label>
                        <input class="form-control" type="checkbox" v-model="chosenPrompt.mandatory" />
                      </div>
                      <div class="kb_item--column">
                        <label>Question Needed</label>
                        <input type="checkbox" v-model="chosenPrompt.question_needed" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="prompts-body" v-if="!isMOAT && !enableLlmAssessments">
          <div class="prompts-container">
            <div class="prompts-body">
              <div class="side-menu">
                <draggable v-model="qaItems" @start="isDragging = true" @end="onEnd">
                  <ul class="side-prompt" v-for="(item, index) in qaItems" :key="index">
                    <li class="d-flex align-items-center">
                      <button class="btn primary question-button" @click="chooseQAItem(index)">
                        {{ item.skill_name || "New Question" }}
                      </button>
                      <button class="delete-button" @click="deleteQuestion(index)">
                        X
                      </button>
                    </li>
                  </ul>
                </draggable>
                <hr />
                <button class="btn add-question-btn" @click="addQAItem">
                  Add Dialogic Question
                </button>
              </div>
              <div class="prompts-edit" v-if="showQuestion">
                <div class="edit-body">
                  <h4>Editing: {{ this.chosenQaItem.skill_name || "New Question" }}</h4>
                  <form class="kb_form">
                    <div class="kb_form--item qaitems-container">
                      <div class="row" :key="qaItemRefreshKey">
                        <div class="qa-items--main form-group col" v-if="qaItems.length > 0">
                          <div class="d-flex flex-column form-group">
                            <label>Skill name</label>
                            <input class="form-control" type="text" placeholder="Skill Name"
                              v-model="chosenQaItem.skill_name" />
                          </div>
                          <div class="d-flex flex-column form-group">
                            <label>Question</label>
                            <input class="form-control" type="text" placeholder="Enter Question"
                              v-model="chosenQaItem.question" />
                          </div>
                          <div class="row flex-column answers-container">
                            <label>Answers</label>
                            <ul>
                              <li class="row"
                                v-for="(answerItem) in chosenQaItem.llm_dialogic_question_answers_attributes"
                                :key="answerItem.id">
                                <div class="col-10">
                                  <p>{{ answerItem.answer }}</p>
                                </div>
                                <div class="col-2">
                                  <button type="button" class="btn"
                                    @click.prevent="deleteAnswer(answerItem.id)">Delete</button>
                                </div>
                              </li>
                            </ul>
                            <div class="row">
                              <div class="col-10">
                                <input class="form-control" type="text" placeholder="Enter New Answer"
                                  v-model="newAnswer" />
                              </div>
                              <div class="col-2">
                                <button class="btn" type="button" @click="addAnswer">Add</button>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex row flex-column form-group answers-container">
                            <label>Assessments</label>
                            <ul>
                              <li
                                v-for="(assessmentItem, index) in chosenQaItem.llm_dialogic_question_assessments_attributes"
                                :key="index">
                                <div>
                                  <label>Label</label>
                                  <input class="form-control" type="text" placeholder="Enter Label"
                                    v-model="assessmentItem.label" />
                                  <label>Response</label>
                                  <input class="form-control" type="text" placeholder="Enter Response"
                                    v-model="assessmentItem.response" />
                                  <label>Debrief</label>
                                  <input class="form-control" type="text" placeholder="Enter Debrief"
                                    v-model="assessmentItem.debrief" />
                                  <label>Follow Up</label>
                                  <input class="form-control" type="text" placeholder="Enter Follow up"
                                    v-model="assessmentItem.follow_up" />
                                  <label>Threshold</label>
                                  <input class="form-control" type="text" placeholder="Enter Threshold"
                                    v-model="assessmentItem.threshold" />
                                </div>
                                <div>
                                  <button class="btn" @click.prevent="saveAssessmentItem(index)">Save</button>
                                  <button class="btn" @click.prevent="deleteAssessmentItem(index)">Delete</button>
                                </div>
                              </li>
                            </ul>
                            <button class="btn" @click.prevent="addAssessmentItem">Add Assessment</button>
                          </div>
                          <div class="row flex-column answers-container">
                            <h4>Show/Hide Options</h4>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showLabels"
                                v-model="chosenQaItem.show_labels">
                              <label class="form-check-label" for="showLabels">
                                Show Results
                              </label>
                            </div>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showResponses"
                                v-model="chosenQaItem.show_responses">
                              <label class="form-check-label" for="showResponses">
                                Show Responses
                              </label>
                            </div>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showDebriefs"
                                v-model="chosenQaItem.show_debriefs">
                              <label class="form-check-label" for="showDebriefs">
                                Show Debriefs
                              </label>
                            </div>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showFollowUps"
                                v-model="chosenQaItem.show_follow_up">
                              <label class="form-check-label" for="showFollowUps">
                                Show Follow Up
                              </label>
                            </div>
                          </div>
                          <div class="row flex-column answers-container">
                            <h4>Role Play Options</h4>
                            <div class="section-roleplay">
                              <div class="form-group-roleplay">
                                <div class="radio-group-container">
                                  <span class="form-label-roleplay">Generative AI Assessment Type</span>
                                  <div class="radio-group-roleplay">
                                    <input v-model="chosenQaItem.role_play" id="Formative" type="radio" value="Formative"
                                      name="conversation" class="radio-input-roleplay">
                                    <label for="Formative" class="radio-label-roleplay">Formative</label>
                                  </div>
                                  <div class="radio-group">
                                    <input v-model="chosenQaItem.role_play" id="Roleplay" type="radio" value="Roleplay"
                                      name="conversation" class="radio-input-roleplay">
                                    <label for="Roleplay" class="radio-label-roleplay">Role Play</label>
                                  </div>
                                </div>
                                <div class="radio-group-container">
                                  <span class="form-label-roleplay">Generative AI Hint Type</span>
                                  <div class="radio-group-roleplay">
                                    <input v-model="chosenQaItem.hint_type" id="Feedback" type="radio" value="Feedback"
                                      name="hint" class="radio-input-roleplay">
                                    <label for="Feedback" class="radio-label-roleplay">Feedback</label>
                                  </div>
                                  <div class="radio-group">
                                    <input v-model="chosenQaItem.hint_type" id="Answer" type="radio" value="Answer"
                                      name="hint" class="radio-input-roleplay">
                                    <label for="Answer" class="radio-label-roleplay">Answer</label>
                                  </div>
                                </div>
                                <div class="radio-group-container">
                                    <div class="form-group">
                                      <label>Generative Feedback Type</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="static_generative_feedback_type">Static</label>
                                          <input type="radio"
                                            v-model="chosenQaItem.generative_feedback_type"
                                            :value="'static'" id="static_generative_feedback_type"
                                            name="static_generative_feedback_type">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="dynamic_generative_feedback_type">Dynamic</label>
                                          <input type="radio"
                                            v-model="chosenQaItem.generative_feedback_type"
                                            :value="'dynamic'" id="dynamic_generative_feedback_type"
                                            name="dynamic_generative_feedback_type">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <label class="form-label-roleplay">Penalty</label>
                                <div v-for="(penalt, index) in chosenQaItem.penalty" :key="index">
                                  <input v-model="chosenQaItem.penalty[index]" type="text" class="form-input-roleplay" />
                                </div>
                              </div>
                              <div class="form-group-roleplay">
                                <label class="form-label-roleplay">Try Limitation</label>
                                <input v-model="chosenQaItem.try_limitation" type="text" class="form-input-roleplay"
                                  @change="changePenaltyNumbers()" />
                                <label v-if="chosenQaItem.hint_type == 'Answer'" class="form-label-roleplay">Answer Hint
                                  <div v-for="(hint, index) in chosenQaItem.answer_hint" :key="index">
                                    <input v-model="chosenQaItem.answer_hint[index]" type="text"
                                      class="form-input-roleplay" />
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="qa-items--main form-group col" v-else>
                          <p>There are no Q/A Items. Please add one.</p>
                        </div>
                      </div>
                    </div>
                    <button class="btn primary" @click="saveQuestion">
                      Save Question
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="prompts-body" v-if="!isMOAT && enableLlmAssessments">
          <div class="prompts-container">
            <div class="prompts-body">
              <div class="side-menu">
                <draggable v-model="qaItems" @start="isDragging = true" @end="onEnd">
                  <ul class="side-prompt" v-for="(item, index) in qaItems" :key="index">
                    <li class="d-flex align-items-center">
                      <button class="btn primary question-button" @click="chooseQAItem(index)">
                        {{ item.skill_name || "New Question" }}
                      </button>
                      <button class="delete-button" @click="deleteQuestion(index)">
                        X
                      </button>
                    </li>
                  </ul>
                </draggable>
                <hr />
                <button class="btn add-question-btn" @click="addQAItem">
                  Add Dialogic Question
                </button>
              </div>
              <div class="prompts-edit" v-if="showQuestion">
                <div class="edit-body">
                  <h4>Editing: {{ this.chosenQaItem.skill_name || "New Question" }}</h4>
                  <form class="kb_form">
                    <div class="kb_form--item qaitems-container">
                      <div class="row" :key="qaItemRefreshKey">
                        <div class="qa-items--main form-group col">
                          <div class="d-flex flex-column form-group">
                            <label>Question Name</label>
                            <input class="form-control" type="text" placeholder="Question Name"
                              v-model="chosenQaItem.skill_name" />
                          </div>
                          <div class="d-flex flex-column form-group">
                            <label>The Actual Question</label>
                            <input class="form-control" type="text" placeholder="Enter The Actual Question"
                              v-model="chosenQaItem.question" />
                          </div>
                          <div class="skillSets-body">
                            <div class="side-menu">
                              <draggable v-model="skillSetsData" @start="isDragging = true" @end="onEnd">
                                <ul class="side-skill-sets" v-for="skillItem in chosenQaItem.llm_skill_set_interaction" :key="skillItem.id">
                                  <div class="skill-set-container">
                                    <li class="menu-item">
                                      {{ skillItem.name || `skillSet: ${skillItem.id}` }}
                                    </li>
                                    <button class="delete-button" @click.prevent="deleteSkillSetInteraction(skillItem.id)">
                                      X
                                    </button>
                                  </div>
                                  <ul class="sub-menu-item">
                                    <li v-for="(skillSetItem, skillSetItemIndex) in skillItem.llm_skill_set_items"
                                      :key="skillSetItemIndex"
                                      @click="chooseLlmSkillSetItem(skillSetItem.assessment.id)" class="menu-item">
                                      {{ skillSetItem.skill_name }}
                                    </li>
                                  </ul>
                                </ul>
                              </draggable>
                              <hr />
                              <select v-model="skillSetAddId">
                                <option value="" default>Select Skill Set</option>
                                <option v-for="skillSet in allSkillSets" :key="skillSet.id" :value="skillSet.id">
                                  {{ skillSet.attributes.name }}
                                </option>
                              </select>
                              <button class="btn add-skill-sets-btn" @click.prevent="addSkillSet">
                                Add Skill Set
                              </button>
                            </div>
                            <div class="skillSetss-edit" v-if="showSkillSetAssessment == true">
                              <div class="edit-body">
                                <h4>{{ this.chosenskillSets.attributes.title }}</h4>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label>Independent / Dependent</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="independent">Independent</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.dependency_type"
                                            :value="'independent'" id="independent" name="independent">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="dependent">Dependent</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.dependency_type"
                                            :value="'dependent'" id="dependent" name="dependent">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label>Assessment Type</label>
                                      <div class="d-flex g-3">
                                        <div class="trigger-item">
                                          <label for="pass/fail">Pass/Fail</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.assessment_type"
                                            :value="'passfail'" id="passfail" name="passfail">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="thresold">Threshold</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.assessment_type"
                                            :value="'threshold'" id="threshold" name="threshold">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row" v-if="chosenskillSets.attributes.assessment_type === 'passfail'">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label>Assessment Text</label>
                                      <input type="textarea" v-model="chosenskillSets.attributes.assessment_text"
                                        class="form-control" />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label>Trigger Response</label>
                                      <div class="d-flex justify-content-between">
                                        <div class="trigger-item">
                                          <label>Hint</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_response"
                                            :value="'hint'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>Answer</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_response"
                                            :value="'answer'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>None</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_response"
                                            :value="'none'">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label>Trigger Pass</label>
                                      <div class="d-flex justify-content-between">
                                        <div class="trigger-item">
                                          <label>Sadness</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_pass"
                                            :value="'sadness'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>Happiness</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_pass"
                                            :value="'happiness'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>Fear</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_pass"
                                            :value="'fear'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>Surprise</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_pass"
                                            :value="'surprise'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>None</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_pass"
                                            :value="'none'">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label>Trigger Fail</label>
                                      <div class="d-flex justify-content-between">
                                        <div class="trigger-item">
                                          <label>Sadness</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_fail"
                                            :value="'sadness'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>Happiness</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_fail"
                                            :value="'happiness'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>Fear</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_fail"
                                            :value="'fear'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>Surprise</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_fail"
                                            :value="'surprise'">
                                        </div>
                                        <div class="trigger-item">
                                          <label>None</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.trigger_fail"
                                            :value="'none'">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label>Assessment Narrative Knowledge Base</label>
                                      <textarea v-model="chosenskillSets.attributes.narrative_base" class="form-control"
                                        rows="3"></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label>Include this Assessment in interaction</label>
                                      <label>Checking this option will include this Assessent when assessing the
                                        answer during the
                                        interaction</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="yes">Yes</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.include_in_assess"
                                            :value="true" id="yes" name="include_in_interaction">
                                        </div>
                                        <div>
                                          <label for="no">No</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.include_in_assess"
                                            :value="false" id="no" name="include_in_interaction">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Include this Assessment in the feedback?</label>
                                      <label>Checking this option will include this Assessment in the feedback</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="yes">Yes</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.mandatory" :value="true"
                                            id="yes" name="mandatory">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="no">No</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.mandatory"
                                            :value="false" id="no" name="mandatory">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Show points on assessment</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="yes">Yes</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.show_points"
                                            :value="true" id="yes" name="show_points">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="no">No</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.show_points"
                                            :value="false" id="no" name="show_points">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Question Needed?</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="yes">Yes</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.question_needed"
                                            :value="true" id="yes" name="question_needed">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="no">No</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.question_needed"
                                            :value="false" id="no" name="question_needed">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Feedback Request Type</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="feedback_request_type">All</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.feedback_request_type"
                                            :value="'all'" id="feedback_request_type" name="feedback_request_type">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="feedback_request_type">Hit</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.feedback_request_type"
                                            :value="'hit'" id="feedback_request_type" name="feedback_request_type">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="feedback_request_type">None</label>
                                          <input type="radio" v-model="chosenskillSets.attributes.feedback_request_type"
                                            :value="'none'" id="feedback_request_type" name="feedback_request_type">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                  <div class="form-group">
                                    <label>Show Pass/Fail Labels</label>
                                    <div class="d-flex">
                                      <div class="trigger-item">
                                        <label for="feedback_request_type">Show</label>
                                        <input type="radio"
                                          v-model="chosenskillSets.attributes.show_pass_fail_labels"
                                          :value=true id="show_pass_fail_labels"
                                          name="show_pass_fail_labels">
                                      </div>
                                      <div class="trigger-item">
                                        <label for="feedback_request_type">Hide</label>
                                        <input type="radio"
                                          v-model="chosenskillSets.attributes.show_pass_fail_labels"
                                          :value=false id="show_pass_fail_labels"
                                          name="show_pass_fail_labels">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                <!-- This has two view depending on whether it's pass/fail or threshold-->
                                <hr />
                                <div class="row">
                                  <div class="col-md-12">
                                    <h4 class="mb-5">Feedback Request Items</h4>
                                  </div>
                                </div>

                                <div v-if="chosenskillSets.attributes.feedback_request_items">
                                  <div class="row"
                                    v-for="(feedback_item, index) in chosenskillSets.attributes.feedback_request_items.data"
                                    :key="index">
                                    <div class="col-md-6">
                                      <ul class="p-0 m-0 w-full">
                                        <li class="p-0 mb-8">
                                          <div class="d-block mb-5">
                                            <div class="d-block mb-5">
                                              <label class="mb-2">Assessment item label</label>
                                              <input v-model="feedback_item.attributes.label" type="text"
                                                class="form-control mb-5" />
                                              <label class="d-block mb-2"
                                                v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
                                                Level
                                              </label>
                                              <input v-model="feedback_item.attributes.level" type="text"
                                                class="form-control mb-5"
                                                v-if="chosenskillSets.attributes.assessment_type == 'threshold'" />
                                              <label class="mb-2">Points</label>
                                              <input v-model="feedback_item.attributes.points" type="text"
                                                class="form-control mb-5" />
                                              <button class="btn btn-info"
                                                @click.prevent="deleteFeedbackRequestItem(feedback_item.id)"
                                                v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
                                                Delete
                                              </button>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="d-block mb-5">
                                        <label class="mb-2">Assessment Item Description</label>
                                        <textarea v-model="feedback_item.attributes.description" type="textarea"
                                          class="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="d-flex mb-5" v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
                                  <button class="btn" @click.prevent="createFeedbackRequestItem()">
                                    <span class="mx-auto text-sm font-bold">Add new feedback request item</span>
                                  </button>
                                </div>

                                <hr />

                                <div class="mb-5" v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
                                  <div class="d-flex">
                                    <h4 class="mb-5">Assessment Knowledge Base</h4>
                                  </div>
                                  <div class="d-flex pb-4 border-b-4">
                                    <div class="d-block mb-5">
                                      <label class="block mb-2">Name</label>
                                      <input v-model="chosenskillSets.attributes.knowbase_name" type="text"
                                        class="form-control" />
                                    </div>
                                  </div>
                                  <div class="d-flex border-b-2 mb-6 pb-2">
                                    <p>Knowledge base entries</p>
                                  </div>
                                  <div v-if="chosenskillSets.attributes.assessment_knowledge_items">
                                    <ul
                                      v-for="(knowledge_item, index) in chosenskillSets.attributes.assessment_knowledge_items.data"
                                      :key="index" class="row mb-5">
                                      <li class="d-flex col-md-12 justify-content-between">
                                        {{ knowledge_item.attributes.body }}
                                        <button class="btn"
                                          @click="deleteKnowledgeBaseItem(knowledge_item.id)">Delete</button>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="d-flex pb-10 mb-10 border-b-4 flex-column">
                                    <label>New Knowledge Base Item</label>
                                    <div class="d-flex">
                                      <input v-model="new_know_base_item_text" type="text" class="form-control mr-5"
                                        placeholder="Add new knowledge base item here" />
                                      <button class="btn" @click="addNewKnowledgeBaseItem()">Add
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex">
                                  <div class="block mb-5 w-1/3">
                                    <label class="mb-2">Task</label>
                                    <input v-model="chosenskillSets.attributes.task" type="text" class="form-control" />
                                  </div>
                                </div>

                                <div class="row mb-5">
                                  <div class="col-md-12">
                                    <label class="mb-2">Use custom skill label?</label>
                                    <div class="d-flex">
                                      <div class="trigger-item">
                                        <input v-model="chosenskillSets.attributes.use_skill_label"
                                          id="use_skill_label-yes" type="radio" value="true" name="use_skill_label-yes"
                                          class="form-control">
                                        <label for="use_skill_label-yes"
                                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                      </div>
                                      <div class="trigger-item">
                                        <input v-model="chosenskillSets.attributes.use_skill_label"
                                          id="use_skill_label-no" type="radio" value="false" name="use_skill_label-no"
                                          class="form-control">
                                        <label for="use_skill_label-no" class="">No</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mb-5">
                                  <div class="col-md-12">
                                    <label class="mb-2">Custom skill label</label>
                                    <div class="d-flex">
                                      <div class="block mr-5">
                                        <input v-model="chosenskillSets.attributes.skill_label" type="text"
                                          class="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mb-5">
                                  <div class="col-md-12 d-flex flex-column">
                                    <label class="block text-sm font-medium text-slate-700">Admin Notes</label>
                                    <textarea v-model="chosenskillSets.attributes.admin_notes" type="text"
                                      class="form-control" />
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <button class="btn" @click.prevent="saveAssessment">Save Assessment</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex row flex-column form-group answers-container">
                            <label>Question Assessments</label>
                            <div>
                              <label>Assessment Type</label>
                              <div class="d-flex flex-column">
                                <div class="d-flex">
                                  <label>Knowledge</label>
                                  <input type="radio" :value="'knowledge'" v-model="chosenQaItem.assessment_type" />
                                </div>
                                <div class="d-flex">
                                  <label>Skill</label>
                                  <input type="radio" :value="'skill'" v-model="chosenQaItem.assessment_type" />
                                </div>
                              </div>
                            </div>
                            <ul>
                              <li
                                v-for="(assessmentItem, index) in chosenQaItem.llm_dialogic_question_assessments_attributes"
                                :key="index">
                                <div>
                                  <label>Label</label>
                                  <input class="form-control" type="text" placeholder="Enter Label"
                                    v-model="assessmentItem.label" />
                                  <label>Response</label>
                                  <input class="form-control" type="text" placeholder="Enter Response"
                                    v-model="assessmentItem.response" />
                                  <label>Debrief</label>
                                  <input class="form-control" type="text" placeholder="Enter Debrief"
                                    v-model="assessmentItem.debrief" />
                                  <label>Follow Up</label>
                                  <input class="form-control" type="text" placeholder="Enter Follow up"
                                    v-model="assessmentItem.follow_up" />
                                  <label>Threshold</label>
                                  <input class="form-control" type="text" placeholder="Enter Threshold"
                                    v-model="assessmentItem.threshold" />
                                  <div v-if="chosenQaItem.assessment_type === 'knowledge'">
                                    <label>Points</label>
                                    <input class="form-control" type="text" placeholder="Enter Points"
                                      v-model="assessmentItem.points" />
                                  </div>
                                </div>
                                <div>
                                  <button class="btn" @click.prevent="saveAssessmentItem(index)">Save</button>
                                  <button class="btn" @click.prevent="deleteAssessmentItem(index)">Delete</button>
                                </div>
                              </li>
                            </ul>
                            <button class="btn" @click="addAssessmentItem">Add Assessment</button>
                          </div>
                          <div class="row flex-column answers-container">
                            <h4>Show/Hide Options</h4>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showLabels"
                                v-model="chosenQaItem.show_labels">
                              <label class="form-check-label" for="showLabels">
                                Show Labels
                              </label>
                            </div>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showResponses"
                                v-model="chosenQaItem.show_responses">
                              <label class="form-check-label" for="showResponses">
                                Show Responses
                              </label>
                            </div>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showDebriefs"
                                v-model="chosenQaItem.show_debriefs">
                              <label class="form-check-label" for="showDebriefs">
                                Show Debriefs
                              </label>
                            </div>
                            <div class="ml-3">
                              <input class="form-check-input" type="checkbox" value="" id="showFollowUps"
                                v-model="chosenQaItem.show_follow_up">
                              <label class="form-check-label" for="showFollowUps">
                                Show Follow Up
                              </label>
                            </div>
                          </div>
                          <div class="row flex-column answers-container">
                            <h4>Role Play Options</h4>
                            <div class="section-roleplay">
                              <div class="form-group-roleplay">
                                <div class="radio-group-container">
                                  <span class="form-label-roleplay">Generative AI Assessment Type</span>
                                  <div class="radio-group-roleplay">
                                    <input v-model="chosenQaItem.role_play" id="Formative" type="radio" value="Formative"
                                      name="conversation" class="radio-input-roleplay">
                                    <label for="Formative" class="radio-label-roleplay">Formative</label>
                                  </div>
                                  <div class="radio-group">
                                    <input v-model="chosenQaItem.role_play" id="Roleplay" type="radio" value="Roleplay"
                                      name="conversation" class="radio-input-roleplay">
                                    <label for="Roleplay" class="radio-label-roleplay">Role Play</label>
                                  </div>
                                  <div class="radio-group">
                                    <input v-model="chosenQaItem.role_play" id="Summative" type="radio" value="Summative"
                                      name="conversation" class="radio-input-roleplay">
                                    <label for="Summative" class="radio-label-roleplay">Summative</label>
                                  </div>
                                  <div class="radio-group">
                                    <input v-model="chosenQaItem.role_play" id="Discussion" type="radio"
                                      value="Discussion" name="conversation" class="radio-input-roleplay">
                                    <label for="Discussion" class="radio-label-roleplay">Discussion</label>
                                  </div>
                                </div>
                                <div class="radio-group-container">
                                  <span class="form-label-roleplay">Generative AI Hint Type</span>
                                  <div class="radio-group-roleplay">
                                    <input v-model="chosenQaItem.hint_type" id="Feedback" type="radio" value="Feedback"
                                      name="hint" class="radio-input-roleplay">
                                    <label for="Feedback" class="radio-label-roleplay">Feedback</label>
                                  </div>
                                  <div class="radio-group">
                                    <input v-model="chosenQaItem.hint_type" id="Answer" type="radio" value="Answer"
                                      name="hint" class="radio-input-roleplay">
                                    <label for="Answer" class="radio-label-roleplay">Answer</label>
                                  </div>
                                </div>
                                <div class="radio-group-container">
                                    <div class="form-group">
                                      <label>Generative Feedback Type</label>
                                      <div class="d-flex">
                                        <div class="trigger-item">
                                          <label for="static_generative_feedback_type">Static</label>
                                          <input type="radio"
                                            v-model="chosenQaItem.generative_feedback_type"
                                            :value="'static'" id="static_generative_feedback_type"
                                            name="static_generative_feedback_type">
                                        </div>
                                        <div class="trigger-item">
                                          <label for="dynamic_generative_feedback_type">Dynamic</label>
                                          <input type="radio"
                                            v-model="chosenQaItem.generative_feedback_type"
                                            :value="'dynamic'" id="dynamic_generative_feedback_type"
                                            name="dynamic_generative_feedback_type">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <label class="form-label-roleplay">Penalty</label>
                                <div v-for="(penalt, index) in chosenQaItem.penalty" :key="index">
                                  <input v-model="chosenQaItem.penalty[index]" type="text" class="form-input-roleplay" />
                                </div>
                              </div>
                              <div class="form-group-roleplay">
                                <label class="form-label-roleplay">Try Limitation</label>
                                <input v-model="chosenQaItem.try_limitation" type="text" class="form-input-roleplay"
                                  @change="changePenaltyNumbers()" />
                                <label v-if="chosenQaItem.hint_type == 'Answer'" class="form-label-roleplay">Answer
                                  Hint</label>
                                <div v-for="(hint, index) in chosenQaItem.answer_hint" :key="index">
                                  <input v-model="chosenQaItem.answer_hint[index]" type="text"
                                    class="form-input-roleplay" />
                                </div>
                                <div v-if="chosenQaItem.answer_hint">
                                  <input v-model="chosenQaItem.answer_hint[0]" type="text" class="form-input-roleplay" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button class="btn primary" @click="saveQuestion">
                      Save Question
                    </button>
                  </form>
                </div>
              </div>
              <div class="prompts-edit" v-else>
                <div class="edit-body">
                  <h4>No Dialogic Questions. Please add one.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setup-content" v-if="isActiveTab == 'chat-testing'">
        <div class="testing-header">
          <h4 class="head-title">{{ name }}</h4>
          <h5 v-html="description"></h5>
        </div>
        <div class="learner-container">
          <div class="messages-area" v-for="message in messages" :key="message.id">
            <transition name="up">
              <div v-if="message.question" class="bubble robot-bubble">
                {{ message.question }}
              </div>
            </transition>
            <transition name="up">
              <div v-if="message.answer" class="bubble learner-bubble">
                {{ message.answer }}
              </div>
            </transition>
            <transition name="up">
              <div v-if="message.response" class="bubble robot-bubble">
                {{ message.response }}
              </div>
            </transition>
          </div>
          <transition name="fade">
            <div v-if="hasStarted" class="spacer"></div>
          </transition>
          <div class="chat">
            <transition name="fade">
              <div v-if="loading">Your answer is being processed.</div>
            </transition>
            <transition name="fade">
              <div v-if="assessmentLoading">Your assessment is processing.</div>
            </transition>
            <input :disabled="loading || assessmentLoading" class="input-area" type="text" v-model="userAnswer" />
            <div class="button-container">
              <button :disabled="loading || assessmentLoading" class="button" @click="submitMessage">
                Submit your message
              </button>
              <button v-if="hasStarted" :disabled="loading || assessmentLoading" class="button"
                @click="submitAssessmentRequest">
                Get Conversation Assessment
              </button>
            </div>
          </div>
          <div class="feedback" v-if="feedback">
            <transition name="fade">
              <h2>Your Feedback</h2>
            </transition>
            <transition name="fade">
              <p>{{ feedback }}</p>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../../services/api";
import { commonConfig } from "@/utils/commonConfig";
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";
export default {
  name: "llm-dialogic",
  components: {
    VueEditor,
    draggable,
  },
  props: ["llmType"],
  data() {
    return {
      status: "drafted",
      isActiveTab: "basic-information",
      chatContentId: "",
      errors: "",
      isActive: false,
      name: "",
      adminNotes: "",
      description: "",
      displayOverallAssessment: false,
      userAnswer: "",
      optionsPassFail: [
        { text: "Pass/Fail", value: "pass_fail" },
        { text: "Threshold", value: "threshold" },
      ],
      optionsIndependentDependent: [
        { text: "Independent", value: true },
        { text: "Dependent", value: false },
      ],
      optionsCharacteristics: [{ text: "Open" }, { text: "Closed" }],
      numberOfAttempts: 0,
      attemptsLimit: 3,
      generalKnowledgeBase: "",
      narrativeKnowledgeBase: "",
      newAssKB: "",
      promptData: [],
      finishedLoading: false,
      chosenPrompt: "",
      chosenPromptIndex: "",
      chosenPromptKey: "",
      messages: [
        {
          question: "",
          answer: "",
          response: "",
        },
      ],
      loading: false,
      assessmentLoading: false,
      feedback: "",
      hasStarted: false,
      latestResponse: {},
      showQuestion: false,
      characterName: "",
      characterPronouns: "",
      characterAge: "",
      characterJob: "",
      characterStance: "",
      characterCharacteristics: "",
      promptReactionSentiment: "",
      promptReactionEmotion: "",
      narrativeKnowledgeBaseAmount: 0,
      chosenPassFail: "Pass",
      dialogicQuestionRequired: false,
      llmChatImageEnabled: false,
      lastMessage: "",
      lastResponse: "",
      dialogicQuestionKey: 0,
      llmChatImage: "",
      llmChatImageURL: "",
      assessmentItems: {},
      assessmentItemsKey: 0,
      feedback_assessment_pass_data: {
        label: "",
        description: "",
      },
      feedback_assessment_fail_data: {
        label: "",
        description: "",
      },
      newItem: {
        label: "",
        description: "",
        assessment_level: 0,
      },
      addingNewAssessmentItem: false,
      ccCharacters: [],
      newccCharacter: "",
      dialogicQuestions: [],
      chosenDialogicQuestion: {},
      chosenDialogicIndex: 0,
      newAnswer: "",
      qaItems: [],
      chosenQaItem: {},
      isMOAT: false,
      showPrompts: false,
      conclusionMessage: "",
      enableVoice: false,
      role_play: "Formative",
      hint_type: "Feedback",
      try_limitation: 0,
      answer_hint: [],
      penalty: [],
      isDragging: false,
      reattemptsEnabled: false,
      maxReattempts: 0,
      enableLlmAssessments: false,
      showSkillSetAssessment: false,
      allSkillSets: [],
      skillSetAddId: "",
      listOrder: 0,
      skillSetsData: [],
      chosenskillSets: {},
      new_know_base_item_text: "",
      qaItemRefreshKey: 0,
      characterImage: "",
      characterImageURL: "",
      characterImageKey: 0,
      uploadImageStatus: false,
      image: "",
    }
  },
  methods: {
    archiveLO() {
      this.$emit("archiveLO");
    },
    getAllSkillSets() {
      api.getAllLlmSkillSets().then((response) => {
        console.log(response);
        this.allSkillSets = response.data.data;
      });
    },
    switchTab(tab) {
      this.isActiveTab = tab;
      this.chatContentId = tab;
    },
    onEnd() {
      const fd = new FormData();
      if (this.enableLlmAssessments) {
        this.qaItems.map((data, index) => {
          fd.append("dialogicQuestions[][id]", data.id);
          fd.append("dialogicQuestions[][list_order]", index + 1);
        });
        api.reorderLlmDialogicQuestions(this.llmDialogicData.attributes.card_detail.id, fd).then(() => {
          this.qaItemRefreshKey += 1;
          this.$toast.open({
            message: "Questions Reordered",
            type: "success",
          });
        });
      } else {
        if (this.isMOAT) {
          this.promptData.map((data, index) => {
            fd.append("prompt[][id]", data.id);
            fd.append("prompt[][prompt_order]", index + 1);
          });
        } else {
          this.qaItems.map((data, index) => {
            fd.append("qaItems[][id]", data.id);
            fd.append("qaItems[][prompt_order]", index + 1);
          });
        }
        api
          .reorderPrompts(this.llmDialogicData.attributes.card_detail.id, this.llmDialogicData.attributes.card_type, fd)
          .then(() => {
            this.$toast.open({
              message: "Questions Reordered",
              type: "success",
            });
          })
          .catch();
      }
    },
    setCharacteristics(e) {
      console.log(e.target.value)
      this.characterCharacteristics = e.target.value;
    },
    deleteFeedbackRequestItem(id) {
      api.deleteLlmFeedbackRequestItem(id).then((response) => {
        console.log(response);
        api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
          console.log(res.data.data);
          this.chosenskillSets = res.data.data;
          this.showSkillSetAssessment = true;
        });
        this.$toast.open({
          message: "Feedback Request Item Deleted",
          type: "success",
        });
      });
    },
    deleteCharacterImage() {
      api.removeLlmCharacterImage(this.$route.params.id, this.$route.query.id).then((response) => {
        console.log(response);
        this.characterImage = "";
        this.characterImageURL = "";
        this.$toast.open({
          message: "Character Image Deleted",
          type: "success",
        });
      });
    },
    createFeedbackRequestItem() {
      let payload = {
        llm_skill_assessment_id: this.chosenskillSets.id,
        label: "",
        description: "",
        level: 0,
        points: 0,
      }
      api.createLlmFeedbackRequestItem(payload).then((response) => {
        console.log(response);
        api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
          console.log(res.data.data);
          this.chosenskillSets = res.data.data;
          this.showSkillSetAssessment = true;
        });
        this.$toast.open({
          message: "Feedback Request Item Created",
          type: "success",
        });
      });
    },
    saveLO(type) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("learning_object[status]", type);
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[admin_notes]", this.adminNotes);
      fb.append("learning_object[description]", this.description);
      fb.append("learning_object[learning_object_type]", this.llmType);
      fb.append("card_type", this.llmType);
      fb.append("card[llm_type]", this.llmType);
      fb.append("card[name]", this.name);
      fb.append("card[description]", this.description);
      fb.append("card[admin_notes]", this.adminNotes);
      fb.append("card[general_knowledge_base]", this.generalKnowledgeBase);
      fb.append("card[narrative_knowledge_base]", this.narrativeKnowledgeBase);
      fb.append("card[character_name]", this.characterName);
      fb.append("card[character_age]", this.characterAge);
      fb.append("card[character_job]", this.characterJob);
      if (this.characterStance == "") {
        fb.append("card[character_stance]", "correct");
      } else {
        fb.append("card[character_stance]", this.characterStance);
      }
      fb.append("card[character_pronouns]", this.characterPronouns);
      fb.append("card[characteristics]", this.characterCharacteristics);
      fb.append("card[dialogic_switch]", true);
      fb.append("card[llm_chat_image_enabled]", false);
      fb.append("card[trigger_response]", this.triggerResponse);
      fb.append("card[trigger_pass]", this.triggerPass);
      fb.append("card[trigger_fail]", this.triggerFail);
      fb.append("card[conclusion_message]", this.conclusionMessage);
      fb.append("card[llm_dialogic_questions_attributes]", JSON.stringify(this.qaItems));
      fb.append("card[enable_reattempts]", this.reattemptsEnabled);
      fb.append("card[max_reattempts]", this.maxReattempts);
      fb.append("card[enable_llm_assessments]", this.enableLlmAssessments);
      if (this.characterImage) {
        fb.append("card[character_image]", this.characterImage);
      }
      if (this.$route.query.id) {
        api
          .updateLo(this.$route.params.id, this.$route.query.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "drafted"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            this.refreshImage(res.data.data.attributes.card_detail.attributes);
          })
          .catch();
      } else {
        api
          .createLearningObject(this.$route.params.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "drafted"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            window.location.reload();
          })
          .catch();
      }
    },
    publishLO(type) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("learning_object[status]", type);
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[admin_notes]", this.adminNotes);
      fb.append("learning_object[description]", this.description);
      fb.append("learning_object[learning_object_type]", this.llmType);
      fb.append("card_type", this.llmType);
      fb.append("card[llm_type]", this.llmType);
      fb.append("card[name]", this.name);
      fb.append("card[description]", this.description);
      fb.append("card[admin_notes]", this.adminNotes);
      fb.append("card[general_knowledge_base]", this.generalKnowledgeBase);
      fb.append("card[narrative_knowledge_base]", this.narrativeKnowledgeBase);
      fb.append("card[character_name]", this.characterName);
      fb.append("card[character_age]", this.characterAge);
      fb.append("card[character_job]", this.characterJob);
      if (this.characterStance == "") {
        fb.append("card[character_stance]", "correct");
      } else {
        fb.append("card[character_stance]", this.characterStance);
      }
      fb.append("card[character_pronouns]", this.characterPronouns);
      fb.append("card[characteristics]", this.characterCharacteristics);
      fb.append("card[dialogic_switch]", true);
      fb.append("card[llm_chat_image_enabled]", false);
      fb.append("card[trigger_response]", this.triggerResponse);
      fb.append("card[trigger_pass]", this.triggerPass);
      fb.append("card[trigger_fail]", this.triggerFail);
      fb.append("card[conclusion_message]", this.conclusionMessage);
      fb.append("card[llm_dialogic_questions_attributes]", JSON.stringify(this.qaItems));
      fb.append("card[enable_reattempts]", this.reattemptsEnabled);
      fb.append("card[max_reattempts]", this.maxReattempts);
      fb.append("card[enable_llm_assessments]", this.enableLlmAssessments);
      if (this.characterImage) {
        fb.append("card[character_image]", this.characterImage);
      }
      if (this.$route.query.id) {
        api
          .updateLo(this.$route.params.id, this.$route.query.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "published"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            this.refreshImage(res.data.data.attributes.card_detail.attributes);
          })
          .catch();
      } else {
        api
          .createLearningObject(this.$route.params.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "published"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            window.location.reload();
          })
          .catch();
      }
    },
    submitMessage() {
      this.messages[this.messages.length - 1].answer = this.userAnswer;
      this.submitQuestion();
      this.userAnswer = "";
    },
    refreshImage(cardDetail) {
      this.characterImageURL = cardDetail.character_image_url;
    },
    populateNarrativeKnowledgeBase() {
      let populatedNarrativeKnowledgeBase = [];
      populatedNarrativeKnowledgeBase.push(this.narrativeKnowledgeBase);
      this.promptData.forEach(prompt => {
        populatedNarrativeKnowledgeBase.push(prompt.narrative_knowledge_base);
      })
      return populatedNarrativeKnowledgeBase;
    },
    addNewKnowledgeBaseItem() {
			let payload = {
				llm_skill_assessment_id: this.chosenskillSets.id,
				body: this.new_know_base_item_text,
			}
			api.createLlmAssessmentKnowledgeItem(payload).then((response) => {
				console.log(response);
				api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
					console.log(res.data.data);
					this.chosenskillSets = res.data.data;
					this.new_know_base_item_text = "";
					this.showSkillSetAssessment = true;
				});
				this.$toast.open({
					message: "Knowledge Base Item Created",
					type: "success",
				});
			});
		},
    uploadImage(event) {
      this.uploadImageStatus = true;
      console.log(event.target.files);
      this.characterImage = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.characterImage);
      _reader.onload = (e) => {
        this.image = e.target.result;
      };
      this.saveLO(this.status);
    },
    deleteKnowledgeBaseItem(index) {
			api.deleteLlmAssessmentKnowledgeItem(index).then((response) => {
				console.log(response);
				api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
					console.log(res.data.data);
					this.chosenskillSets = res.data.data;
					this.showSkillSetAssessment = true;
				});
				this.$toast.open({
					message: "Knowledge Base Item Deleted",
					type: "success",
				});
			});
		},
    submitQuestion() {
      utilFunctionService.showLoader();
      const answer = this.messages[this.messages.length - 1].answer;
      let dialogicQuestionsObj = [];
      this.dialogicQuestions.forEach((dialogicQuestion) => {
        dialogicQuestionsObj.push({ question: dialogicQuestion });
      });

      let genKnowledgeBase = [];
      genKnowledgeBase.push(this.generalKnowledgeBase);
      let narrKnowledgeBase = this.populateNarrativeKnowledgeBase();
      this.loading = true;
      this.hasStarted = true;
      if (this.promptData.length !== 0) this.promptData[0].threshold = 0;
      let payload = {
        character_data: {
          age: this.characterAge,
          name: this.characterName,
          job: this.characterJob,
          characteristic: this.characterCharacteristics,
          student_name: "Tom",
        },
        student_id: 1,
        lo_id: 1,
        data: {
          narrative_knowledge_base: narrKnowledgeBase,
          general_knowledge_base: genKnowledgeBase,
          prompt_data: this.promptData,
          student_answer: answer,
          number_attempts: this.numberOfAttempts,
          attempt_limit: this.attemptsLimit,
          prompt_reaction_sentiment: this.promptReactionSentiment,
          prompt_reaction_emotion: this.promptReactionEmotion,
          questions: this.qaItems,
        },
        dialogic: this.dialogicQuestionRequired,
      };
      api
        .submitLlmChatQuestion(payload)
        .then((res) => {
          console.log(res);
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    submitAssessmentRequest() {
      this.assessmentLoading = true;
      this.$modal.show("finalFeedback");
    },
    resetLlmConversation() {
      let payload = {
        student_id: 1,
        lo_id: 1,
      }
      api.resetLlmConversation(payload).then((res) => {
        console.log(res);
        this.assessmentLoading = false;
      });
    },
    refreshData() {
      const cardDetail = this.llmDialogicData.attributes.card_detail.attributes;
      this.name = cardDetail.title;
      this.adminNotes = cardDetail.admin_notes;
      this.description = cardDetail.description;
      this.narrativeKnowledgeBase = [];
      if (cardDetail.narrative_knowledge_base.length > 1) {
        this.narrativeKnowledgeBase.push(cardDetail.narrative_knowledge_base)
      } else {
        this.narrativeKnowledgeBase = [""];
      }
      this.narrativeKnowledgeBaseAmount = this.narrativeKnowledgeBase.length;
      this.generalKnowledgeBase = cardDetail.general_knowledge_base;
      this.characterName = cardDetail.character_name;
      this.characterAge = cardDetail.character_age;
      this.characterJob = cardDetail.character_job;
      this.characterStance = cardDetail.character_stance;
      this.characterCharacteristics = cardDetail.characteristics;
      this.promptReactionSentiment = cardDetail.prompt_reaction_sentiment;
      this.promptReactionEmotion = cardDetail.prompt_reaction_emotion;
      this.dialogicQuestionRequired = cardDetail.dialogic_switch;
      this.enableVoice = cardDetail.enable_voice;
      this.dialogicQuestions = cardDetail.dialogic_questions || [];
      this.conclusionMessage = cardDetail.conclusion_message || "";
      this.reattemptsEnabled = cardDetail.enable_reattempts;
      this.maxReattempts = cardDetail.max_reattempts;
      this.enableLlmAssessments = cardDetail.enable_llm_assessment;
      this.characterImageURL = ""
      this.characterImageURL = cardDetail.character_image_url;
      if (cardDetail.pass_fail) {
        this.chosenPassFail = "pass";
      } else {
        this.chosenPassFail = "fail";
      }
      if (cardDetail.prompts) {
        this.promptData = cardDetail.prompts;
      } else {
        this.promptData = [];
      }
      if (this.dialogicQuestionRequired) {
        console.log(this.messages);
        this.messages[this.messages.length - 1].question = this.dialogicQuestions[0];
        console.log(this.messages);
      }
      this.switchTab('basic-information');
    },
    addQAItem() {
      let payload = {
        llm_dialogic_learn_obj_id: this.llmDialogicData.attributes.objectable_id,
        enable_assessment: this.enableLlmAssessments
      };
      api.addLlmDialogicQuestion(payload).then((res) => {
        console.log(res);
        this.qaItems.push(res.data.data);
        this.qaItemRefreshKey++;
        this.chosenQaItem = res.data.data;
        this.showQuestion = true;
      });
    },
    chooseMOATPrompt(index) {
      this.chosenPrompt = this.promptData[index];
      this.chosenPromptIndex = index;
      this.showPrompts = true;
    },
    chooseQAItem(index) {
      // this.chosenQaItem = this.qaItems[index];
      api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then((response) => {
        this.chosenQaItem = response.data.data.attributes.card_detail.attributes.llm_dialogic_questions[this.chosenQaItemIndex];
      });
      this.chosenQaItemIndex = index;
      if (this.showSkillSetAssessment) {
        this.showSkillSetAssessment = false;
      }
      this.skillSetAddId = "";
      this.showQuestion = true;
    },
    setUpQAItems(qaItems) {
      if (qaItems === null) {
        return [];
      }
      const qaItemLength = Object.keys(qaItems);
      let qaItemsArr = [];
      for (let i = 0; i < qaItemLength.length; i++) {
        qaItemsArr.push(qaItems[i]);
      }
      return qaItemsArr;
    },
    saveQuestion() {
      let payload = {
        skill_name: this.chosenQaItem.skill_name,
        question: this.chosenQaItem.question,
        show_labels: this.chosenQaItem.show_labels,
        show_responses: this.chosenQaItem.show_responses,
        show_debriefs: this.chosenQaItem.show_debriefs,
        show_follow_up: this.chosenQaItem.show_follow_up,
        role_play: this.chosenQaItem.role_play,
        hint_type: this.chosenQaItem.hint_type,
        try_limitation: this.chosenQaItem.try_limitation,
        answer_hint: this.chosenQaItem.answer_hint,
        penalty: this.chosenQaItem.penalty,
        assessment_type: this.chosenQaItem.assessment_type,
        generative_feedback_type: this.chosenQaItem.generative_feedback_type
      };
      api.saveLlmDialogicQuestion(payload, this.chosenQaItem.id).then((res) => {
        console.log(res);
        utilFunctionService.showSuccess("Question saved");
      });
    },
    addAnswer() {
      let payload = {
        answer: this.newAnswer,
        llm_dialogic_question_id: this.chosenQaItem.id,
      };
      api
        .addLlmDialogicQuestionAnswer(payload)
        .then((res) => {
          console.log(res);
          this.newAnswer = "";
          this.getAnswers();
        })
        .catch();
    },
    deleteAnswer(index) {
      api
        .deleteLlmDialogicQuestionAnswer(index)
        .then((res) => {
          console.log(res);
          this.getAnswers();
        })
        .catch();
    },
    getAnswers() {
      api
        .getAllLlmDialogicQuestionAnswers(this.chosenQaItem.id)
        .then((res) => {
          console.log(res);
          this.chosenQaItem.llm_dialogic_question_answers_attributes = res.data.data.map((answer) => {
            return {
              llm_dialogic_question_id: answer.attributes.llm_dialogic_question_id,
              answer: answer.attributes.answer,
              id: answer.id,
            };
          });
        })
        .catch();
    },
    addAssessmentItem() {
      this.newItem = {
        label: "",
        response: "",
        threshold: "",
        debrief: "",
        follow_up: "",
        llm_dialogic_question_id: this.chosenQaItem.id,
        points: 0,
      };
      this.chosenQaItem.llm_dialogic_question_assessments_attributes.push(this.newItem);
    },
    editAssessmentItem(index) {
      this.newItem = this.chosenQaItem.llm_dialogic_question_assessments_attributes[index];
      this.addingNewAssessmentItem = true;
    },
    deleteAssessmentItem(index) {
      const assessment_id = this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].id
      api
        .deleteLlmDialogicQuestionAssessment(assessment_id)
        .then((res) => {
          console.log(res);
          utilFunctionService.showSuccess("Assessment Item deleted");
          this.getAssessments();
        })
        .catch();
    },
    saveAssessmentItem(index) {
      const assessment_id = this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].id;
      if (!assessment_id) {
        let payload = {
          label: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].label,
          response: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].response,
          threshold: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].threshold,
          debrief: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].debrief,
          follow_up: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].follow_up,
          points: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].points,
          llm_dialogic_question_id: this.chosenQaItem.id,
        };
        api
          .addLlmDialogicQuestionAssessment(payload)
          .then((res) => {
            console.log(res);
            utilFunctionService.showSuccess("Assessment Item created");
            this.getAssessments();
          })
          .catch();
      } else {
        let payload = {
          label: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].label,
          response: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].response,
          threshold: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].threshold,
          debrief: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].debrief,
          follow_up: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].follow_up,
          points: this.chosenQaItem.llm_dialogic_question_assessments_attributes[index].points,
          llm_dialogic_question_id: this.chosenQaItem.id,
        };
        api.updateLlmDialogicQuestionAssessment(payload, assessment_id).then((res) => {
          console.log(res);
          utilFunctionService.showSuccess("Assessment Item saved");
          this.getAssessments();
        });

      }
    },
    getAssessments() {
      api
        .getAllLlmDialogicQuestionAssessments(this.chosenQaItem.id)
        .then((res) => {
          let assessments = res.data.data.map((assessment) => {
            return {
              label: assessment.attributes.label,
              response: assessment.attributes.response,
              threshold: assessment.attributes.threshold,
              debrief: assessment.attributes.debrief,
              follow_up: assessment.attributes.follow_up,
              llm_dialogic_question_id: assessment.attributes.llm_dialogic_question_id,
              points: assessment.attributes.points,
              id: assessment.id,
            };
          });
          this.chosenQaItem.llm_dialogic_question_assessments_attributes = assessments;
          console.log(res);
        })
    },
    deleteQuestion(index) {
      api
        .deleteLlmDialogicQuestion(this.qaItems[index].id)
        .then((res) => {
          console.log(res);
          this.qaItems.splice(index, 1);
          this.chosenQaItem = {};
          this.showQuestion = false;
          this.qaItemRefreshKey++;
          utilFunctionService.showSuccess("Question deleted");
        })
        .catch();
    },
    changePenaltyNumbers() {
      this.chosenQaItem.penalty = []
      const numbers = parseInt(this.chosenQaItem.try_limitation, 10);
      for (let i = 0; i < numbers; i++) {
        this.chosenQaItem.penalty.push("1")
      }
    },
    addSkillSet() {
      this.createSkillSetInteraction()
    },
    createSkillSetInteraction() {
      if (this.$route.params.id) {
        if (this.interactionSkillSetsList == null) {
          this.listOrder = 0
        } else {
          this.listOrder = this.interactionSkillSetsList.length
        }
        const payload = {
          llm_skill_set_id: this.skillSetAddId,
          llm_dialogic_learn_obj_id: this.llmDialogicData.attributes.objectable_id,
          llm_dialogic_question_id: this.chosenQaItem.id,
          list_order: this.listOrder
        }

        api
          .createLlmSkillSetInteraction(payload).then((response) => {
            console.log("*** response in createSkillSetInteraction", response)
            api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then((response) => {
              this.chosenQaItem = response.data.data.attributes.card_detail.attributes.llm_dialogic_questions[this.chosenQaItemIndex];
              this.skillSetAddId = "";
              this.$toast.open({
                message: "Skill Set Interaction Created",
                type: "success",
              });
              // This isn't needed anymore
							this.skillSetsData = response.data.data.attributes.card_detail.attributes.llm_skill_set_interactions;
						});
          });
      }
    },
    saveAssessment() {
      let assessment_id = this.chosenskillSets.id;
      console.log(this.chosenskillSets.attributes.feedback_request_items)
      this.chosenskillSets.attributes.llm_feedback_request_items_attributes = this.chosenskillSets.attributes.feedback_request_items.data.map((item) => {
        return {
          id: item.id,
          label: item.attributes.label,
          description: item.attributes.description,
          level: item.attributes.level,
          points: item.attributes.points,
        }
      });
      api.updateLlmSkillAssessment(assessment_id, this.chosenskillSets.attributes).then((response) => {
        console.log(response);
        this.$toast.open({
          message: "Assessment Item Saved",
          type: "success",
        });
        api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then((response) => {
          this.skillSetsData = response.data.data.attributes.card_detail.attributes.llm_skill_set_interactions;
        });
      });
    },
    chooseLlmSkillSetItem(llmAssessmentSkillId) {
      console.log(llmAssessmentSkillId);
      this.showSkillSetAssessment = false;
      api.getLlmSkillAssessment(llmAssessmentSkillId).then((res) => {
        console.log(res.data.data);
        this.chosenskillSets = res.data.data;
        this.showSkillSetAssessment = true;
      });
    },
    deleteSkillSetInteraction(index) {
      api
        .deleteSkillSetInteraction(index)
        .then((res) => {
          console.log(res);
          api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then((response) => {
            this.chosenQaItem = response.data.data.attributes.card_detail.attributes.llm_dialogic_questions[this.chosenQaItemIndex];
            this.skillSetAddId = "";
            this.$toast.open({
              message: "Skill Set Interaction Deleted",
              type: "success",
            });
            // This isn't needed anymore
            this.skillSetsData = response.data.data.attributes.card_detail.attributes.llm_skill_set_interactions;
          });
          utilFunctionService.hideLoader();
        })
        .catch();
    },
  },
  watch: {
    llmDialogicData: function () {
      this.refreshData();
    }
  },
  mounted() {
    // const cardDetail = this.llmDialogicData.attributes.card_detail.attributes;
    this.llmDialogicData = this.$store.state.llmData;
    const cardDetail = this.$store.state.llmData.attributes.card_detail.attributes;
    this.name = cardDetail.title;
    this.adminNotes = cardDetail.admin_notes;
    this.description = cardDetail.description;
    this.narrativeKnowledgeBase = cardDetail.narrative_knowledge_base;
    // this.narrativeKnowledgeBaseAmount = this.narrativeKnowledgeBase.length;
    this.generalKnowledgeBase = cardDetail.general_knowledge_base;
    this.characterName = cardDetail.character_name;
    this.characterAge = cardDetail.character_age;
    this.characterJob = cardDetail.character_job;
    this.characterStance = cardDetail.character_stance;
    this.characterPronouns = cardDetail.character_pronouns;
    this.characterCharacteristics = cardDetail.characteristics;
    this.promptReactionSentiment = cardDetail.prompt_reaction_sentiment;
    this.promptReactionEmotion = cardDetail.prompt_reaction_emotion;
    this.dialogicQuestionRequired = cardDetail.dialogic_switch;
    this.dialogicQuestions = cardDetail.dialogic_questions || [];
    this.triggerResponse = cardDetail.trigger_response || "";
    this.triggerPass = cardDetail.trigger_pass || "";
    this.triggerFail = cardDetail.trigger_fail || "";
    this.conclusionMessage = cardDetail.conclusion_message || "";
    this.enableVoice = cardDetail.enable_voice;
    this.promptData = cardDetail.prompts;
    this.reattemptsEnabled = cardDetail.enable_reattempts;
    this.maxReattempts = cardDetail.max_reattempts;
    this.enableLlmAssessments = cardDetail.enable_llm_assessment;
    this.characterImageURL = cardDetail.character_image_url;
    try {
      this.qaItems = cardDetail.llm_dialogic_questions;
    } catch (error) {
      this.qaItems = [];
    }
    if (cardDetail.pass_fail) {
      this.chosenPassFail = "pass";
    } else {
      this.chosenPassFail = "fail";
    }
    this.chatContentId = "basic-information";

    const MOAT_LO_IDS = [563, 562, 561, 560, 557, 556, 554, 553, 552, 551, 549]
    const MOAT_LEARN_MOD_ID = this.llmDialogicData.attributes.learn_mod_id;
    if (MOAT_LO_IDS.includes(MOAT_LEARN_MOD_ID)) {
      this.isMoatCustom = true;
    }

    if (this.dialogicQuestionRequired && this.messages.length > 0 && this.dialogicQuestions.length > 0) {
      this.messages[this.messages.length - 1].question = this.dialogicQuestions[0];
    }
    this.getAllSkillSets();
    this.skillSetsData = this.llmDialogicData.attributes.card_detail.attributes.llm_skill_set_interactions;
    document.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && this.userAnswer !== "" && !this.loading && this.isActiveTab === 'chat-testing') {
        this.submitMessage();
      }
    });
    if (this.isActiveTab === 'chat-testing') this.resetLlmConversation();
    this.$cable.subscribe({
      channel: "AssessmentAnswerChannel",
      room: "1",
    });
    this.$cable.subscribe({
      channel: "AssessmentResultChannel",
      room: "1",
    });
  },
  channels: {
    AssessmentAnswerChannel: {
      connected() {
        console.log("LLM Dialogic testing connected");
      },
      received(data) {
        let responses = data.data.response;
        this.numberOfAttempts = this.numberOfAttempts + 1;
        responses.forEach((response) => {
          this.messages[this.messages.length - 1].response = response;
          this.messages.push({
            answer: "",
            response: "",
          });
        });
        this.loading = false;
      },
    },
    AssessmentResultChannel: {
      connected() {
        console.log("LLM Dialogic assessment result connected");
      },
      received(res) {
        console.log(res);
        console.log(res.data.student_id === 1);
        this.feedback = res.data.prompts.assessment_results;
      },
    },
  },
};
</script>

<style scoped>
.knowledge-base-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}

.knowledge-base-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.knowledge-base-item h5 {
  font-weight: bold;
}

.kb-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-area {
  width: 400px;
  font-family: sans-serif;
  height: 200px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}

.prompts-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 20%;
  border-right: 1px solid black;
  height: 100%;
}

.menu-item {
  max-width: 120px;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 8px;
  border-radius: 5px;
  list-style-type: none;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
}

.menu-item:hover {
  background: black;
  color: white;
}

.prompts-edit {
  width: 80%;
  padding: 16px;
}

.prompts-container {
  width: 100%;
}

.prompts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.kb_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
}

.kb_form--item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.kb_form--itemhori {
  flex-direction: row;
}

.kb_form--item label,
p {
  margin-bottom: 8px;
  font-weight: bold;
}

.kb_form--item textarea {
  width: 100%;
  font-family: sans-serif;
  height: 100px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}

.kb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.kb-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.kb-input input {
  width: 50%;
  padding: 8px;
  border-radius: 5px;
}

.btn {
  border-radius: 5px;
  border: 1px solid black;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  height: 50px;
}

.btn:hover {
  background: black;
  color: white;
}

.side-prompt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.delete-button {
  background: red;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  height: 30px;
  width: 30px;
  margin-left: 16px;
  border-radius: 5px;
  border: 1px solid black;
}

.delete-button:hover {
  background: white;
  color: red;
  border: 1px solid red;
}

.kb_item--column {
  display: flex;
  flex-direction: column;
}

.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
}

.bubble {
  border-radius: 15px;
  padding: 8px;
  margin-bottom: 16px;
  width: 50%;
}

.learner-bubble {
  background: green;
  color: white;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
}

.robot-bubble {
  background: blue;
  color: white;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
}

.input-area {
  color: black;
  width: 400px;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}

.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}

.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.up-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}

.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}

.prompt-reaction-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.prompt-reaction-container .prompts-body {
  justify-content: flex-start;
  gap: 16px;
}

.prompt-reaction-container button {
  width: 250px;
}

.form-section h4 {
  margin-bottom: 32px;
}

.form-group {
  display: flex;
  flex-direction: column
}

.kb-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kb-edit-active {
  background: rgb(37, 145, 240) !important;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.kb-edit-active:hover {
  background: rgb(13, 128, 228) !important;
  color: white;
  border: 1px solid rgb(13, 128, 228) !important;
}

.kb_form--row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 32px;
}

.qaitems-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formItem--title {
  font-weight: bold;
  margin-bottom: 32px;
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.qa-items--main {
  gap: 32px;
}

.qa-items--side {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.add-question-btn {
  width: 90%;
}

.answers-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
}

.question-button {
  max-width: 165px;
  overflow: hidden;
}

/* General container and typography styles for role play */
.container-roleplay {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.header-roleplay {
  font-size: 1.25rem;
  /* 20px */
  margin-bottom: 1rem;
  /* 16px */
  font-weight: 600;
  color: #333;
}

.section-roleplay {
  display: flex;
  flex-direction: row;
  border-top: 4px solid #ccd;
  padding: 16px 0;
  margin: 16px 0;
}

/* Form container and input styles */
.form-group-roleplay {
  margin-right: 32px;
}

.form-label-roleplay {
  display: block;
  font-size: 0.875rem;
  /* 14px */
  margin-bottom: 8px;
  font-weight: 500;
  color: #444;
}

.form-input-roleplay {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

/* Style radio inputs and their labels */
.radio-group-roleplay {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.radio-input-roleplay {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 2px solid #37f;
  background-color: #fff;
  border-radius: 50%;
}

.radio-input-roleplay:checked {
  background-color: #37f;
}

.radio-label-roleplay {
  font-size: 0.875rem;
  /* 14px */
  color: #333;
}

.character-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 16px;
}
</style>


