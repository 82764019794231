<template>
  <div id="Users">
    <div class="container">
      <header class="header">
        <div class="header-left">
          <h2>Learner Central</h2>
        </div>
        <div class="header-right">
          <form class="header-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchLearners"
                placeholder="Search"
                v-model="searchLearners"
                v-debounce:400ms="search"
              />
              <em class="icon-search"></em>
            </div>
          </form>
        </div>
        <p v-if="totalLearners > 1 && !searchLearners">
          You have {{ totalLearners }} Learners in the system.
        </p>
        <p v-else-if="totalLearners <= 1 && !searchLearners">
          You have {{ totalLearners }} Learner in the system.
        </p>
        <p v-else-if="totalLearners > 1 && searchLearners">
          You have {{ totalLearners }} Learners in the search result.
        </p>
        <p v-else>You have {{ totalLearners }} Learner in the search result.</p>
      </header>
      <div class="data-content" v-if="learnersList.length !== 0 && !isLoadingStudents">
        <div class="data-table data-column-7">
          <div class="data-head">
            <div class="data-col">
              Last Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'last_name' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('last_name', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'last_name' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('last_name', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              First Name
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'first_name' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('first_name', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'first_name' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('first_name', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              LOs Completed
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'completed_count' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('completed_count', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'completed_count' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('completed_count', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Started
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'time_started' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('time_started', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'time_started' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('time_started', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">
              Completed
              <span class="data-sort">
                <a
                  class="asc"
                  :class="[
                    sortColumn === 'time_completed' && sortOrder === 'asc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('time_completed', 'asc')"
                >
                  <em class="icon-carrot-up"></em>
                </a>
                <a
                  class="desc"
                  :class="[
                    sortColumn === 'time_completed' && sortOrder === 'desc'
                      ? 'active'
                      : '',
                  ]"
                  @click="toggleSort('time_completed', 'desc')"
                >
                  <em class="icon-carrot-down"></em>
                </a>
              </span>
            </div>
            <div class="data-col">Module</div>
            <div class="data-col">Section</div>
            <div class="data-col">Course</div>
            <div class="data-col"></div>
          </div>
          <div
            class="data-items"
            v-for="learner in learnersList"
            :key="learner.id"
          >
            <div class="data-row">
              <div class="data-col">{{ learner.attributes.last_name }}</div>
              <div class="data-col">{{ learner.attributes.first_name }}</div>
              <div class="data-col">
                {{ learner.attributes.completed_count }} of
                {{ learner.attributes.total_count }}
              </div>
              <div class="data-col">{{ learner.attributes.time_started }}</div>
              <div class="data-col">
                {{ learner.attributes.time_completed }}
              </div>
              <div class="data-col">{{ learner.attributes.module_name }}</div>
              <div class="data-col">{{ learner.attributes.section_name }}</div>
              <div class="data-col">{{ learner.attributes.course_name }}</div>
              <div class="data-col">
                <router-link :to="`/learner-lo-list/${learner.id}`">
                  <button class="btn secondary btn-small">ELM Details</button>
                </router-link>
              </div>
              <div class="data-col">
                <button
                  class="btn secondary btn-small"
                  @click="editSection(learner.attributes)"
                >
                  Edit Section
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="data-footer">
          <div class="data-results">
            Showing {{ page }} of {{ totalPages.length }}
          </div>
          <div aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-if="page !== 1">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                  <em class="icon-prev"></em>
                </a>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages.slice(page - 1, page + 3)"
                :key="page"
              >
                <a class="page-link active" @click="getLearners(page)">{{
                  page
                }}</a>
              </li>
              <li class="page-item" v-if="page < totalPages.length">
                <a class="page-link" aria-label="Next" @click="nextPage">
                  <em class="icon-next"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="data-no-result"
        v-if="learnersList.length === 0 && !searchStatus && display"
      >
        <h3>There’s nothing here!</h3>
        <p>You have no User in the system yet.</p>
      </div>
      <div
        class="data-no-result"
        v-if="learnersList.length === 0 && searchStatus"
      >
        <h3>No results found</h3>
        <p>Try using a different keyword</p>
      </div>
    </div>
    <EditSectionModal />
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import api from "../services/api";
import EditSectionModal from "../components/modal/editSection.vue";

export default {
  name: "Learner-Central",
  components: {
    EditSectionModal,
  },
  metaInfo: {
    title: "Learner Central",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      learnerAppUrl: process.env.VUE_LEARNER_URL,
      selectedFile: null,
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      time_started: "",
      time_completed: "",
      section_name: "",
      total_count: "",
      completed_count: "",
      totalLearners: "",
      searchLearners: "",
      learnersList: [],
      totalPages: [],
      page: 1,
      sortColumn: "id",
      sortOrder: "desc",
      display: false,
      searchStatus: false,
      isActive: false,
      user_id: "",
      learnerLODetails: [],
      isLoadingStudents: false,
    };
  },
  methods: {
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.clearFormData();
      this.$modal.hide(modal);
    },
    editSection(user_info) {
      this.$modal.show("EditSectionModal", { user_info });
    },
    search(value) {
      utilFunctionService.showLoader();
      this.isLoadingStudents = true;
      console.log(value);
      api
        .getAllLearners(
          commonConfig.setting.searchPage,
          value,
          this.sortColumn,
          this.sortOrder
        )
        .then((res) => {
          utilFunctionService.hideLoader();
          this.isLoadingStudents = false;
          this.searchStatus = true;
          this.learnersList = res.data.data;
          this.totalLearners = res.data.data.length;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    nextPage() {
      this.page = this.page + 1;
      this.getLearners(this.page);
    },
    prevPage() {
      this.page = this.page - 1;
      this.getLearners(this.page);
    },

    downloadScoreCsv() {
      utilFunctionService.showLoader();
      api
        .downloadUserScore()
        .then((response) => {
          let res = response.data.csv_data;
          let fileName = response.data.filename;
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "text/csv" })
          );
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    getLearners(page) {
      this.page = page;
      utilFunctionService.showLoader();
      this.isLoadingStudents = true;
      api
        .getAllLearners(
          page,
          this.searchLearners,
          this.sortColumn,
          this.sortOrder
        )
        .then((res) => {
          this.display = true;
          utilFunctionService.hideLoader();
          this.isLoadingStudents = false;
          this.searchStatus = false;
          this.learnersList = res.data.data;
          this.totalLearners = res.data.filtered_count;
          this.totalPages = [];
          for (let ind = 1; ind <= res.data.total_pages; ind++) {
            this.totalPages.push(ind);
          }
        })
        .catch();
    },
    toggleSort(colName, sortType) {
      let _colName = colName;
      if (colName === "first_name") {
        colName = "first_name,last_name";
      }
      this.sortOrder = sortType;
      this.sortColumn = colName;
      this.getLearners(this.page);
      this.sortColumn = _colName;
    },
    refreshEditSection() {
      this.search(this.searchLearners)
    }
  },
  created() {
    this.getLearners(this.page);
  },
};
</script>
