<template>
    <div>
        <Sidebar />
        
    </div>
</template>
<script>
    import Sidebar from '@/components/Sidebar.vue'
    
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name : 'Layout',
        components: {
            Sidebar
        }
    }
</script>
