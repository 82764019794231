var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "iteration-variations-form" } }, [
    _c("div", { staticClass: "assessment-response" }, [
      _c(
        "ul",
        { staticClass: "response-tab" },
        _vm._l(_vm.iterationLevel, function (index) {
          return _c(
            "li",
            {
              key: index,
              class: _vm.selIterationLevel === index ? "active" : "",
              on: {
                click: function ($event) {
                  return _vm.hanldeIterationChange(index)
                },
              },
            },
            [
              _c("a", { attrs: { href: "javascript:void(0);" } }, [
                _vm._v("Iteration " + _vm._s(index)),
              ]),
            ]
          )
        }),
        0
      ),
      _vm.selectedItrVariationRecords
        ? _c(
            "ul",
            { staticClass: "response-tab" },
            _vm._l(_vm.selectedItrVariationRecords, function (vari, index) {
              return _c(
                "li",
                {
                  key: vari.id,
                  class:
                    _vm.selectedVariation &&
                    _vm.selectedVariation.id === vari.id
                      ? "active"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.handleVariationChange(index)
                    },
                  },
                },
                [
                  _c("a", { attrs: { href: "javascript:void(0);" } }, [
                    _vm._v("variation " + _vm._s(vari.attributes.variation)),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "response-content" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("vue-editor", {
              attrs: {
                id: "Descriptiontext",
                placeholder: "Add a Variation",
                editorToolbar: _vm.fullTool,
              },
              model: {
                value: _vm.variationText,
                callback: function ($$v) {
                  _vm.variationText = $$v
                },
                expression: "variationText",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }